<template>
  <div class="interfaceCausePoints">
    <CausePointsHeader />
    <CausePointsTableHeader />
    <CausePointsList :causePoints="causePoints" v-if="!errorMessage && causePoints.length" />
    <div class="app-no-causePoints" v-if="!causePoints.length && isLoaded">
      <span class="app-no-causePoints__text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="app-no-causePoints">
      <span class="app-no-causePoints__text">Ошибка при загрузке достижений</span>
    </div>
    <CausePointsEditPopup />
    <CausePointsAddPopUp />
    <CausePointsDeletePopUp />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types'
import { GET_CAUSE_POINTS } from '@/store/getter-types'
import { REQUEST_CAUSE_POINTS } from '@/store/action-types'
import CausePointsList from '@/components/CausePoints/CausePointsList.vue'
import CausePointsHeader from '@/components/CausePoints/CausePointsHeader.vue'
import store from '@/store'
import CausePointsEditPopup from '@/components/Modals/CausePointsPopup/CausePointsEditPopup'
import CausePointsAddPopUp from '@/components/Modals/CausePointsPopup/CausePointsAddPopUp'
import CausePointsDeletePopUp from '@/components/Modals/CausePointsPopup/CausePointsDeletePopUp'
import CausePointsTableHeader from '@/components/CausePoints/TableHeader/CausePointsTableHeader.vue'

export default {
  name: 'interfaceCausePoints',

  components: {
    CausePointsList,
    CausePointsHeader,
    CausePointsEditPopup,
    CausePointsAddPopUp,
    CausePointsDeletePopUp,
    CausePointsTableHeader
  },
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_CAUSE_POINTS).then(response => {
      next(vm => {
        if (response) {
          vm.setCurrentLang('RU')
        }
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  methods: {
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    })
  },
  computed: {
    ...mapGetters({
      causePoints: GET_CAUSE_POINTS
    })
  }
}
</script>
<style lang="scss">
@import '@/components/CausePoints/CausePoints.scss';
</style>
