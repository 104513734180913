<template>
  <div class="chart">
    <div class="chart-left">
      <div class="cart__btns" v-if="button_period.length > 0" @click="buttonPeriodClickHandler">
        <button
          v-for="button in button_period"
          :key="button.id"
          :data-id="button.id"
          :variant="button.class"
          :class="[button.active ? 'btn btn-danger' : 'btn', button.class ? button.class : '']"
        >
          {{ button.title }}
        </button>
        <button variant="primary" data-type="period" class="btn btn-primary">
          Период
        </button>
      </div>
      <div class="datepicker-traffic-source">
        <i class="select__field-icon fa fa-angle-down"></i>
        <DateRangePicker
          :locale-data="picker.localeData"
          :opens="picker.localeData.position"
          :close-on-esc="true"
          :autoApply="true"
          :ranges="false"
          ref="traffic"
          v-model="datePickerControlTraffic"
        >
        </DateRangePicker>
      </div>
    </div>
    <BarChart
      :chart-data="statistic_apps_flyer"
      :options="statistic_apps_flyer_opt"
      class="highcharts-figure"
    ></BarChart>
    <BarChart
      :chart-data="statistic_apps_flyer_ios"
      :options="statistic_apps_flyer_opt_ios"
      class="highcharts-figure"
    ></BarChart>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { REQUEST_APPS_FLYER, REQUEST_APPS_FLYER_IOS } from '@/store/action-types'
const { reactiveData } = mixins
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { mixins } from 'vue-chartjs'
import 'chartjs-plugin-datalabels'
import BarChart from '@/components/Statistic/BarChart'
import pickerMixin from '@/components/Picker/pickerMixin'
export default {
  name: 'StatisticAppsFlyer',
  mixins: [reactiveData, pickerMixin],
  components: {
    BarChart,
    DateRangePicker
  },
  props: {
    appsFlyer: {
      type: Object
    },
    appsFlyerIos: {
      type: Object
    }
  },
  data: () => ({
    // loading: false,
    statistic_apps_flyer: {
      labels: [],
      datasets: []
    },
    statistic_apps_flyer_ios: {
      labels: [],
      datasets: []
    },
    statistic_apps_flyer_opt: {
      fontColor: '#fff',
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 30
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              suggestedMin: 50,
              suggestedMax: 100,
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }
        ],
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            },
            maxBarThickness: 100
          }
        ]
      },
      title: {
        display: true,
        text: 'Источник - Android'
      },
      legend: {
        display: true,
        position: 'right'
      },
      tooltips: {
        enabled: true,
        mode: 'label',
        callbacks: {
          label: function(tooltipItem, data) {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value}`
          }
        },
        xAlign: 10,
        yAlign: 10
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let datasets = ctx.chart.data.datasets
            let firstIndex = null
            let sum = 0

            for (let [index, value] of Object.entries(datasets)) {
              if (ctx.chart.isDatasetVisible(index)) {
                firstIndex = parseInt(index)
                sum += parseInt(value.data[ctx.dataIndex])
              }
            }

            if (sum === 0) {
              return 0
            }

            return ctx.datasetIndex === firstIndex ? sum.toLocaleString() : ''
          },
          font: {
            size: 12
          },
          padding: {
            top: 10,
            bottom: -10
          },
          anchor: 'end',
          align: 'end',
          rotation: 0,
          clamp: true
        }
      }
    },
    statistic_apps_flyer_opt_ios: {
      fontColor: '#fff',
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 30
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              suggestedMin: 50,
              suggestedMax: 100,
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }
        ],
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            },
            maxBarThickness: 100
          }
        ]
      },
      title: {
        display: true,
        text: 'Источник - IOS'
      },
      legend: {
        display: true,
        position: 'right'
      },
      tooltips: {
        enabled: true,
        mode: 'label',
        callbacks: {
          label: function(tooltipItem, data) {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value}`
          }
        },
        xAlign: 'left',
        yAlign: 'bottom'
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let datasets = ctx.chart.data.datasets
            let firstIndex = null
            let sum = 0

            for (let [index, value] of Object.entries(datasets)) {
              if (ctx.chart.isDatasetVisible(index)) {
                firstIndex = parseInt(index)
                sum += parseInt(value.data[ctx.dataIndex])
              }
            }

            if (sum === 0) {
              return 0
            }

            return ctx.datasetIndex === firstIndex ? sum.toLocaleString() : ''
          },
          font: {
            size: 12
          },
          padding: {
            top: 10,
            bottom: -10
          },
          anchor: 'end',
          align: 'end',
          rotation: 0,
          clamp: true
        }
      }
    },
    datepicker_dates: {
      date_from: moment()
        .subtract(14, 'days')
        .startOf('day')
        .toString(),
      date_to: moment()
        .endOf('day')
        .toString()
    },
    button_period: [
      {
        id: 1,
        class: 'btn-default',
        title: '14 дней',
        active: 1,
        date: moment()
          .subtract(14, 'days')
          .startOf('day')
      },
      {
        id: 2,
        class: 'btn-default',
        title: '30 дней',
        active: 0,
        date: moment()
          .subtract(30, 'days')
          .startOf('day')
      },
      {
        id: 3,
        class: 'btn-default',
        title: '60 дней',
        active: 0,
        date: moment()
          .subtract(60, 'days')
          .startOf('day')
      }
    ]
  }),
  computed: {
    datePickerControlTraffic: {
      get() {
        const date = {
          startDate: moment()
            .subtract(14, 'days')
            .startOf('day'),
          endDate: moment().endOf('day')
        }
        return date
      },
      set({ startDate, endDate }) {
        this.datepicker_dates.date_from = new Date(startDate)
        this.datepicker_dates.date_to = new Date(endDate)
      }
    }
  },

  beforeMount() {
    this.getData()
    this.getDataIos()
  },

  methods: {
    ...mapActions({
      getNewItems: REQUEST_APPS_FLYER,
      getNewItemsIos: REQUEST_APPS_FLYER_IOS
    }),
    newDatasets() {
      let datas = []
      const colors = this.appsFlyer.color
      for (let el in this.appsFlyer) {
        if (el != 'keys' && el != 'total' && el != 'labels' && el != 'color') {
          let data = {
            borderColor: `${colors[el]}`,
            backgroundColor: `${colors[el]}`,
            fill: true,
            label: el,
            data: this.appsFlyer[el]
          }
          datas.push(data)
        }
      }
      return datas
    },
    newDatasetsIos() {
      let datas = []
      const colors = this.appsFlyerIos.color
      for (let el in this.appsFlyerIos) {
        if (el != 'keys' && el != 'total' && el != 'labels' && el != 'color') {
          let data = {
            borderColor: `${colors[el]}`,
            backgroundColor: `${colors[el]}`,
            fill: true,
            label: el,
            data: this.appsFlyerIos[el]
          }
          datas.push(data)
        }
      }
      return datas
    },
    getData() {
      this.statistic_apps_flyer.labels = this.appsFlyer.labels
      this.statistic_apps_flyer.datasets = this.newDatasets()
    },
    getDataIos() {
      this.statistic_apps_flyer_ios.labels = this.appsFlyerIos.labels
      this.statistic_apps_flyer_ios.datasets = this.newDatasetsIos()
    },
    buttonPeriodClickHandler(event) {
      if (event.target.tagName.toLowerCase() !== 'button') {
        return false
      }

      this.button_period.map(el => {
        el.active = 0
        return el
      })
      if (event.target.dataset.type) {
        this.getNewItems({
          date_from: moment(this.datepicker_dates.date_from).format('YYYY-MM-DD'),
          date_to: moment(this.datepicker_dates.date_to).format('YYYY-MM-DD')
        }).then(response => {
          this.getData()
        })
        this.getNewItemsIos({
          date_from: moment(this.datepicker_dates.date_from).format('YYYY-MM-DD'),
          date_to: moment(this.datepicker_dates.date_to).format('YYYY-MM-DD'),
          device: 'ios'
        }).then(response => {
          this.getDataIos()
        })
        return
      }

      if (!event.target.dataset.id) {
        return false
      }

      const button = this.button_period.find(el => el.id === +event.target.dataset.id)

      if (button.active) {
        return false
      }

      button.active = 1

      this.datepicker_dates.date_from = button.date.toString()
      this.datepicker_dates.date_to = button.date_to
        ? button.date_to.toString()
        : moment()
            .endOf('day')
            .toString()
      this.getNewItems({
        date_from: moment(this.datepicker_dates.date_from).format('YYYY-MM-DD'),
        date_to: moment(this.datepicker_dates.date_to).format('YYYY-MM-DD')
      }).then(response => {
        this.getData()
      })
      this.getNewItemsIos({
        date_from: moment(this.datepicker_dates.date_from).format('YYYY-MM-DD'),
        date_to: moment(this.datepicker_dates.date_to).format('YYYY-MM-DD'),
        device: 'ios'
      }).then(response => {
        this.getDataIos()
      })
      this.$refs.traffic.start = new Date(this.datepicker_dates.date_from)
      this.$refs.traffic.end = new Date(this.datepicker_dates.date_to)
    }
  }
}
</script>
<style lang="scss">
// @import '@/components/Events/Filters/EventFilters.scss';
// @import './Chart.scss';
@import './Statistic.scss';
</style>
