<template>
  <div class="table-header-wrapper--purchases">
    <ul class="table-header">
      <li class="table-header__item table-header__item--purchases">
        <span class="table-header__item-text">Покупка</span>
      </li>
      <li class="table-header__item table-header__item--purchases">
        <span class="table-header__item-text">Значение покупки</span>
      </li>
      <li class="table-header__item table-header__item--purchases">
        <span class="table-header__item-text">Стоимость</span>
      </li>
      <li class="table-header__item table-header__item--purchases">
        <span class="table-header__item-text">Код страны</span>
      </li>
      <li class="table-header__item table-header__item--purchases">
        <span class="table-header__item-text">Дата обновления</span>
      </li>
      <li class="table-header__item table-header__item--purchases">
        <span class="table-header__item-text"></span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'PurchasesTableHeader'
}
</script>
<style lang="scss" scoped>
@import './PurchaseTableHeader.scss';
</style>
