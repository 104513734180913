<template>
  <div class="event-desc default-block">
    <span class="event-desc__title title-med">Категория события: {{ currentEvent.event_category.name }}</span>
    <div class="event-desc__head">
      <div class="event-desc__info">
        <i class="event-desc__info-icon fas fa-map-marked-alt"></i>
        <span class="event-desc__info-text"> Адрес: {{ currentEvent.event_location.address }} </span>
      </div>
      <div class="event-desc__info">
        <i class="event-desc__info-icon far fa-calendar-alt"></i>
        <span class="event-desc__info-text">
          Дата: {{ toLocaleDateWithoutHours(currentEvent.event_date) }}
        </span>
      </div>
      <div class="event-desc__info">
        <i class="event-desc__info-icon far fa-clock"></i>
        <span class="event-desc__info-text"> Время: {{ toLocaleTime(currentEvent.event_date) }} </span>
      </div>
    </div>
    <p class="event-desc__text">
      Описание события:
      {{ currentEvent.event_description ? currentEvent.event_description : '-' }}
    </p>
    <div class="event-desc__head event-desc__text">
      <span>
        Кто видит:
        <span>{{ 'Мужчины' }} </span>
      </span>
    </div>
  </div>
</template>

<script>
import toLocaleformat from '@/components/mixins/toLocaleformat'
export default {
  name: 'EventDescription',
  props: {
    currentEvent: {
      type: Object
    }
  },
  mixins: [toLocaleformat]
}
</script>
