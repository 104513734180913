<template>
  <Popup :visible="visible" @close="close" description="Удалить запись?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteHandler()">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close()">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { VIEW_DELETE_POPUP_RECORD } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_DELETE_VIEW_RECORD } from '@/store/action-types'
export default {
  name: 'ViewDeletePopup',
  components: {
    Popup
  },
  data() {
    return {
      id: ''
    }
  },
  mixins: [ModalMixin],

  methods: {
    ...mapActions({
      deleteView: REQUEST_DELETE_VIEW_RECORD
    }),
    deleteHandler() {
      const payload = {
        id: this.id
      }
      this.deleteView(payload)
      this.id = ''
      this.close()
    }
  },

  mounted() {
    this.$root.$on(VIEW_DELETE_POPUP_RECORD, id => {
      this.open()
      this.id = id
    })
  }
}
</script>
