import * as GetterTypes from './getter-types'

export default {
  [GetterTypes.GET_RECORDS]: ({ records }) => records,

  [GetterTypes.GET_LANGUAGE]: ({ lang }) => lang,

  [GetterTypes.GET_GENDER]: ({ gender }) => gender,

  [GetterTypes.GET_COUNTRY]: ({ country }) => country,

  [GetterTypes.GET_PAGE_TYPES]: ({ pages }) => pages,

  [GetterTypes.GET_RECORD_DEFAULT]: ({ recordDefault }) => recordDefault,

  [GetterTypes.GET_TMP_RECORD_FILTERS]: ({ tmpFilters }) => tmpFilters
}
