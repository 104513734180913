<template>
  <Popup :visible="visible" @close="closePopup" description="Новая должность" modalClass="popup--medium">
    <form class="test" @submit.prevent="createPositionHandler">
      <div class="popup__item">
        <span class="popup__item-title">Название должности</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="createPosition"
          ref="createposition"
          required
        />
      </div>
      <div class="popup__item">
        <div class="select popup__select">
          <span class="popup__item-title">Тип:</span>
          <div class="select__body">
            <button type="button" class="select__field popup__select-field" @click="clickClose('type')">
              <span class="select__field-value">{{ defaultFilter({ key: 'type' }) }}</span>
              <i class="select__field-icon fa fa-angle-down"></i>
            </button>
            <span class="popup_create_error" v-if="error">{{ error }}</span>
            <ul class="select__list popup__select-list" v-if="typeSelectOpen" @mouseleave="closeFilter">
              <li
                class="select__item"
                v-for="type in positionsFilters.type"
                :key="type.id"
                @click="
                  selectFilterBuilder({
                    key: 'type',
                    value: type
                  })
                "
              >
                <span class="select__text">{{ type.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="popup-list">
        <div class="popup__item" v-for="el in adminList" :key="el.title">
          <span class="popup__item-title">{{ el.title }}</span>
          <ul>
            <li v-for="permission in el.props" :key="permission.id">
              <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
              <label :for="permission.id">{{ permission.permission_name }}</label>
            </li>
          </ul>
        </div>
        <!-- <span class="popup__item-title">Главная Админ панель</span>
        <ul>
          <li v-for="permission in adminList.admin" :key="permission.id">
            <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
            <label :for="permission.id">{{ permission.permission_name }}</label>
          </li>
        </ul>
        <span class="popup__item-title">Модерация</span>
        <ul>
          <li v-for="permission in adminList.moderation" :key="permission.id">
            <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
            <label :for="permission.id">{{ permission.permission_name }}</label>
          </li>
        </ul>
        <span class="popup__item-title">Поддержка</span>
        <ul>
          <li v-for="permission in adminList.support" :key="permission.id">
            <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
            <label :for="permission.id">{{ permission.permission_name }}</label>
          </li>
        </ul>
        <span class="popup__item-title">Пользователи</span>
        <ul>
          <li v-for="permission in adminList.users" :key="permission.id">
            <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
            <label :for="permission.id">{{ permission.permission_name }}</label>
          </li>
        </ul>
        <span class="popup__item-title">Модерация: Панель</span>
        <ul>
          <li v-for="permission in adminList.moderation_panel" :key="permission.id">
            <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
            <label :for="permission.id">{{ permission.permission_name }}</label>
          </li>
        </ul>
        <span class="popup__item-title">Поддержка: Панель</span>
        <ul>
          <li v-for="permission in adminList.support_panel" :key="permission.id">
            <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
            <label :for="permission.id">{{ permission.permission_name }}</label>
          </li>
        </ul>
        <span class="popup__item-title">Справочники</span>
        <ul>
          <li v-for="permission in adminList.guides" :key="permission.id">
            <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
            <label :for="permission.id">{{ permission.permission_name }}</label>
          </li>
        </ul>
        <span class="popup__item-title">Управление</span>
        <ul>
          <li v-for="permission in adminList.managment" :key="permission.id">
            <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
            <label :for="permission.id">{{ permission.permission_name }}</label>
          </li>
        </ul>
        <span class="popup__item-title">События</span>
        <ul>
          <li v-for="permission in adminList.events" :key="permission.id">
            <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
            <label :for="permission.id">{{ permission.permission_name }}</label>
          </li>
        </ul>
        <span class="popup__item-title">Маркетинг</span>
        <ul>
          <li v-for="permission in adminList.marketing" :key="permission.id">
            <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
            <label :for="permission.id">{{ permission.permission_name }}</label>
          </li>
        </ul>
        <span class="popup__item-title">Операторы</span>
        <ul>
          <li v-for="permission in adminList.operators" :key="permission.id">
            <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
            <label :for="permission.id">{{ permission.permission_name }}</label>
          </li>
        </ul>
        <span class="popup__item-title">Статистика</span>
        <ul>
          <li v-for="permission in adminList.statistic" :key="permission.id">
            <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
            <label :for="permission.id">{{ permission.permission_name }}</label>
          </li>
        </ul>
        <span class="popup__item-title">Статистика</span>
        <ul>
          <li v-for="permission in adminList.no_group" :key="permission.id">
            <input type="checkbox" :id="permission.id" :value="permission.id" v-model="permissionOrder" />
            <label :for="permission.id">{{ permission.permission_name }}</label>
          </li>
        </ul> -->
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Добавить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_CREATE_POSITION } from '@/store/action-types'
import {
  GET_FILTERS_DEFAULT_POSITIONS,
  GET_FILTERS_POSITIONS_INFO,
  GET_TMP_POSITIONS_FILTERS
} from '@/store/getter-types'
import { SET_RESET_POSITIONS_FILTERS, SET_TMP_POSITIONS_FILTERS } from '@/store/mutation-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import translitiration from '@/components/mixins/translitiration'
import { NEW_POSITION_POPUP_EVENT } from '@/constants/event'
export default {
  name: 'NewPositionPopup',
  components: {
    Popup
  },

  mixins: [ModalMixin, translitiration],
  data: () => ({
    adminList: {},
    permissionOrder: [],
    typeSelectOpen: false,
    error: '',
    positionData: {
      name: '',
      value: ''
    }
  }),

  computed: {
    ...mapGetters({
      positionsFilters: GET_FILTERS_POSITIONS_INFO,
      defaultFilters: GET_FILTERS_DEFAULT_POSITIONS,
      tmpFilter: GET_TMP_POSITIONS_FILTERS
    }),
    createPosition: {
      get() {
        return this.positionData.name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.createposition.value = name
        this.positionData.name = name
      }
    },

    positionValue: {
      get() {
        return this.positionData.value
      },
      set(value) {
        value = value.replace(/\d/g, '')
        this.$refs.positionvalue.value = value
        this.positionData.value = value
      }
    }
  },

  methods: {
    ...mapMutations({
      resetFilters: SET_RESET_POSITIONS_FILTERS,
      addTmpFilters: SET_TMP_POSITIONS_FILTERS
    }),
    ...mapActions({
      create: REQUEST_CREATE_POSITION
    }),
    defaultFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : this.defaultFilters[key].name
    },

    defaultValueFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current !== undefined ? current : ''
    },

    selectFilterBuilder(filterItem) {
      const { key } = filterItem
      switch (key) {
        case 'type':
          this.addTmpFilters({ ...filterItem })
          break
        default:
          break
      }
      this[`${key}SelectOpen`] = false
      this.error = ''
    },

    closeFilter() {
      this.typeSelectOpen = false
    },

    clickClose(options, param) {
      let newOptions = this[`${options}SelectOpen`]
      this.closeFilter()
      this[`${options}SelectOpen`] = !newOptions
    },

    closePopup() {
      this.positionData.name = ''
      this.positionData.value = ''
      this.permissionOrder = []
      this.typeSelectOpen = false
      this.resetFilters()
      this.close()
    },
    createPositionHandler() {
      if (this.defaultValueFilter({ key: 'type' })) {
        const position = {
          position_name: this.positionData.name,
          position: this.translite(this.positionData.name),
          type: this.defaultValueFilter({ key: 'type' }),
          permissions: this.permissionOrder
        }
        this.create(position).then(response => {
          this.error = ''
          this.closePopup()
        })
      } else {
        this.error = 'Поле не может быть пустым!'
      }
    }
  },

  mounted() {
    this.$root.$on(NEW_POSITION_POPUP_EVENT, prop => {
      this.adminList = prop
      this.open()
    })
  }
}
</script>
<style lang="scss">
.popup__select {
  width: 100%;
  height: auto;
}
.popup__select-field {
  width: 100%;
}
.popup__select-list {
  top: 58px;
}
.popup_create_error {
  color: #ff3b3b;
}
.popup-list {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e3e3e3;
    border-radius: 10px;
  }
  margin: 20px 0;
  overflow-y: auto;
  height: 430px;
}
</style>
