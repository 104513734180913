<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    title=""
    description=""
    modalClass="popup--medium popup--operator popup--scroll"
  >
    <form class="operator-profile" @submit.prevent="createOperatorSubmitHandler">
      <div class="operator-profile__header">
        <span v-if="oldData" class="operator-profile__name"
          >{{ oldData.first_name }} {{ oldData.last_name }}</span
        >
        <div class="operator-profile__avatar-wrap">
          <div class="operator-profile__avatar">
            <img v-if="isAvatar" :src="preview" alt="" />
            <img
              v-else-if="operatorData.avatar"
              :src="
                getFile({
                  type: 'image',
                  file: operatorData.avatar,
                  width: `128`,
                  height: `128`
                })
              "
              class="chat__message-body--img"
              alt=""
            />
            <img v-else src="@/assets/img/anonymous_user.png" alt="" />
          </div>
          <div class="operator-profile__avatar-edit">
            <input
              class="operator-profile__avatar-edit-input"
              type="file"
              id="avatar-input"
              accept=".jpg, .jpeg, .png"
              ref="downloadavatar"
              @change="fileUploadHandler"
            />
            <label class="operator-profile__avatar-edit-label" for="avatar-input">
              <i class="fa fa-pen"></i>
            </label>
          </div>
        </div>
      </div>

      <div class="operator-profile__edit">
        <div class="operator-profile__edit-body">
          <div class="operator-profile__edit-group">
            <label for="operator-name" class="operator-profile__edit-text">Имя</label>
            <input
              id="operator-name"
              type="text"
              class="operator-profile__edit-input"
              v-model="createOperatorname"
              ref="createoperatorname"
              required
            />
          </div>
          <div class="operator-profile__edit-group">
            <label for="operator-lastname" class="operator-profile__edit-text">Фамилия</label>
            <input
              id="operator-lastname"
              type="text"
              class="operator-profile__edit-input"
              v-model="createOperatorLastname"
              ref="createoperatorlastname"
              required
            />
          </div>
          <div class="operator-profile__edit-group operator-profile__edit-group--currency">
            <div class="operator-profile__wrapper">
              <label for="operator-card" class="operator-profile__edit-text">Номер карты</label>
              <input
                id="operator-card"
                type="text"
                class="operator-profile__edit-input"
                v-model="createOperatorCard"
                maxlength="23"
                minlength="16"
                ref="createoperatorcard"
                required
              />
            </div>
            <div class="operator-profile__wrapper">
              <span class="operator-profile__edit-select-title">Валюта</span>
              <div class="operator-profile__edit-select">
                <button
                  type="button"
                  class="operator-profile__edit-value"
                  @click="currencyOptions = !currencyOptions"
                >
                  <span class="operator-profile__edit-value-text">{{
                    defaultOperatorFilter({ key: 'currency' })
                  }}</span>
                  <i class="fa fa-angle-down operator-profile__edit-select-icon"></i>
                </button>
                <ul
                  class="operator-profile__edit-select-list"
                  v-if="currencyOptions"
                  @mouseleave="currencyOptions = !currencyOptions"
                  :class="{ active: currencyOptions }"
                >
                  <li
                    v-for="currency in operatorRoles.currency"
                    :key="currency.id"
                    @click="selectItemHandler({ key: 'currency', value: currency })"
                    class="operator-profile__edit-select-item"
                  >
                    <span class="operator-profile__edit-select-text">{{ currency.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="operator-profile__edit-group operator-profile__edit-group--account">
            <div class="operator-profile__edit-group--container">
              <div>
                <span class="operator-profile__edit-text">Добавить профиль пользователя</span>
                <button
                  class="operator__btn btn-blue operator__btn--blue"
                  @click.prevent="$root.$emit('ADD_OPERATOR_USER_POPUP_EVENT')"
                >
                  <i class="operators__btn-txt fa fa-plus-square"></i>
                </button>
              </div>
              <div v-if="currentUser.length">
                <span class="operator-profile__edit-text">Передать пользователя</span>
                <button
                  class="operator__btn btn-blue operator__btn--blue"
                  @click.prevent="$root.$emit('TRANSFER_OPERATOR_TO_ANOTHER')"
                >
                  <i class="operators__btn-txt fa fa-plus-square"></i>
                </button>
              </div>
            </div>
            <ul class="operator-profile__account-list" v-if="currentUser.length">
              <li class="operator-profile__account-wrapper" v-for="current in currentUser" :key="current.id">
                <div class="operator-profile__account-info">
                  <div class="operator__avatar">
                    <img
                      v-if="current.user_avatar"
                      :src="
                        getFile({
                          type: 'image',
                          file: current.user_avatar.content_id,
                          width: 60,
                          height: 60
                        })
                      "
                      alt=""
                    />
                    <img v-else src="@/assets/img/anonymous_user.png" alt="" />
                  </div>
                  <span class="operator__name">
                    <span class="operator__text">{{ current.user_name }} </span>
                  </span>
                </div>
                <div class="operator-profile__account-btns">
                  <button
                    class="operator__btn btn-red operator__btn--red"
                    :disabled="!current"
                    @click.prevent="
                      $root.$emit('DEL_OPERATOR_USER_POPUP_EVENT', {
                        id: current.id,
                        title: 'add'
                      })
                    "
                  >
                    <i class="fa fa-times-circle"></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div class="employee-profile__edit-group employee-profile__edit-group--togle">
            <span class="employee-profile__edit-text">{{ isActiveOperator }}</span>
            <label class="toggle">
              <input class="toggle__checkbox" type="checkbox" v-model="isActive" />
              <span class="toggle__toggler"></span>
            </label>
          </div>
          <div class="operator-profile__edit-group operator-profile__edit-group--btns">
            <button
              type="submit"
              class="operator-profile__edit-action-btn operator-profile__edit-action-btn--save"
            >
              <span class="operator-profile__edit-action-btn-text">Изменить</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
  SET_OPERATOR_SETTINGS,
  SET_CLEAR_OPERATOR_DATA,
  SET_SEARCH_OPTIONS,
  SET_CURRENT_OPERATOR,
  SET_OPERATOR_CURRENT_USER
} from '@/store/mutation-types'
import { REQUEST_EDIT_OPERATORS } from '@/store/action-types'
import {
  GET_OPERATOR_ROLES,
  GET_OPERATOR_TMP_ROLES,
  GET_OPERATOR_ROLES_DEFAULT,
  GET_OPERATORS_CURRENCY,
  GET_CURRENT_OPERATOR_USER,
  GET_OPERATOR_SEARCH_OPTION
} from '@/store/getter-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import {
  EDIT_OPERATOR_POPUP_EVENT,
  DEL_OPERATOR_USER_POPUP_EVENT,
  ADD_SEARCH_OPTION_POPUP_EVENT,
  EDIT_SEARCH_OPTION_POPUP_EVENT,
  OPERATOR_NO_CLOSE_POPUP
} from '@/constants/event'

import getImgLink from '@/components/mixins/getImgCdnLink'

export default {
  name: 'EditOperatorPopup',
  data: () => ({
    EDIT_OPERATOR_POPUP_EVENT,
    OPERATOR_NO_CLOSE_POPUP,
    DEL_OPERATOR_USER_POPUP_EVENT,
    ADD_SEARCH_OPTION_POPUP_EVENT,
    EDIT_SEARCH_OPTION_POPUP_EVENT,
    currencyOptions: false,
    preview: null,
    isAvatar: false,
    oldData: null,
    weight: 0,
    searchOld: [],
    isActive: null,
    operatorData: {
      first_name: null,
      last_name: null,
      account: {},
      currency: null,
      card: null,
      avatar: null,
      file: null
    }
  }),
  components: {
    Popup
  },
  computed: {
    ...mapGetters({
      operatorRoles: GET_OPERATOR_ROLES,
      operatorTmpRoles: GET_OPERATOR_TMP_ROLES,
      operatorRolesDefault: GET_OPERATOR_ROLES_DEFAULT,
      operatorCurrency: GET_OPERATORS_CURRENCY,
      currentUser: GET_CURRENT_OPERATOR_USER,
      searchOptions: GET_OPERATOR_SEARCH_OPTION
    }),
    createOperatorname: {
      get() {
        return this.operatorData.first_name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.createoperatorname.value = name
        this.operatorData.first_name = name
      }
    },
    createOperatorLastname: {
      get() {
        return this.operatorData.last_name
      },
      set(lastname) {
        lastname = lastname.replace(/\d/g, '')
        this.$refs.createoperatorlastname.value = lastname
        this.operatorData.last_name = lastname
      }
    },
    createOperatorCard: {
      get() {
        return this.operatorData.card
      },
      set(card) {
        card = card.replace(/[^\d; ]/gi, '')
        if (this.operatorData.card && card.length > this.operatorData.card.length) {
          if (
            (card.length > this.operatorData.card.length && card.length == 4) ||
            card.length == 9 ||
            card.length == 14 ||
            card.length == 19
          ) {
            card += ' '
          }
        }
        this.$refs.createoperatorcard.value = card
        this.operatorData.card = card
      }
    },
    isActiveOperator() {
      return this.isActive ? 'Оператор активен' : 'Оператор неактивен'
    }
  },
  mixins: [ModalMixin, getImgLink],
  methods: {
    ...mapMutations({
      addTmpOperatorSettings: SET_OPERATOR_SETTINGS,
      delSearchOptions: SET_SEARCH_OPTIONS,
      setCurrentUser: SET_OPERATOR_CURRENT_USER,
      currentOper: SET_CURRENT_OPERATOR,
      clearData: SET_CLEAR_OPERATOR_DATA
    }),
    ...mapActions({
      editOperator: REQUEST_EDIT_OPERATORS
    }),

    defaultOperatorFilter(settings) {
      const { key } = settings
      const current = this.operatorTmpRoles[key].name
      return current ? current : this.operatorRolesDefault[key].name
    },

    defaultOperatorCurrency(settings) {
      const { key } = settings
      const current = this.operatorTmpRoles[key].position_name
      return current ? current : this.operatorRolesDefault[key].name
    },

    defaultOperatorValue(settings) {
      const { key } = settings
      const current = this.operatorTmpRoles[key].name
      return current ? this.operatorTmpRoles[key].id : this.operatorRolesDefault[key].id
    },

    selectItemHandler(selectItem) {
      const { key } = selectItem
      switch (key) {
        case 'currency':
          this.addTmpOperatorSettings({ ...selectItem })
          break
        default:
          break
      }
      this[`${key}Options`] = false
    },

    fileUploadHandler() {
      const avatar = this.$refs.downloadavatar.files[0]
      this.preview = URL.createObjectURL(avatar)
      this.isAvatar = true
      this.operatorData.file = avatar
    },

    addAccount(id) {
      this.operatorData.account = id
    },

    closePopup() {
      this.night_shift = false
      this.delSearchOptions([])
      this.setCurrentUser(null)
      this.clearData()
      this.operatorData.first_name = null
      this.operatorData.last_name = null
      this.operatorData.card = null
      this.operatorData.currency = null
      this.operatorData.file = null
      this.preview = null
      this.isAvatar = false
      this.isActive = false
      this.close()
    },

    createOperatorSubmitHandler() {
      let data = {
        first_name:
          this.operatorData.first_name !== this.oldData.first_name ? this.operatorData.first_name : null,
        last_name:
          this.operatorData.last_name !== this.oldData.last_name ? this.operatorData.last_name : null,
        is_active: this.isActive
      }
      this.operatorData.card = this.operatorData.card.replace(/\s/g, '')
      let payment_rate = {
        card:
          +this.operatorData.card !== this.oldData.payment_rate.card ? BigInt(this.operatorData.card) : null,
        currency:
          this.defaultOperatorValue({ key: 'currency' }) !== this.oldData.payment_rate.currency
            ? this.defaultOperatorValue({ key: 'currency' })
            : null
      }
      if (this.currentUser) {
        if (this.currentUser.length === this.oldData.accounts.length) {
          let difference = false
          this.currentUser.forEach(f => {
            this.oldData.accounts.forEach(el => {
              if (el.account.id !== f.id) {
                difference = true
              }
            })
          })
          if (difference) {
            let arrUsersId = []
            this.currentUser.forEach(f => {
              let searchData = []
              if (this.oldData.accounts) {
                let oldSearch = this.oldData.accounts.find(el => el.account.id === f.id)
                this.weight = oldSearch ? oldSearch.weight_sending : 0
                if (oldSearch) {
                  oldSearch.searches.forEach(old => {
                    searchData.push({
                      age_max: old.age_max,
                      age_min: old.age_min,
                      country_code: old.country_code,
                      gender: old.gender,
                      langs: [old.langs[0].value]
                    })
                  })
                }
              }
              arrUsersId.push({
                account_id: f.id,
                searches: searchData,
                weight_sending: this.weight === null ? 0 : this.weight
              })
            })
            data.accounts = arrUsersId
          }
        } else {
          let arrUsersId = []
          this.currentUser.forEach(f => {
            let searchData = []
            if (this.oldData.accounts) {
              let oldSearch = this.oldData.accounts.find(el => el.account.id === f.id)
              this.weight = oldSearch ? oldSearch.weight_sending : 0
              if (oldSearch) {
                oldSearch.searches.forEach(old => {
                  searchData.push({
                    age_max: old.age_max,
                    age_min: old.age_min,
                    country_code: old.country_code,
                    gender: old.gender,
                    langs: [old.langs[0].value]
                  })
                })
              }
            }
            arrUsersId.push({
              account_id: f.id,
              searches: searchData,
              weight_sending: this.weight === null ? 0 : this.weight
            })
          })
          data.accounts = arrUsersId
        }
      } else {
        data.accounts = []
      }

      if (payment_rate.card || payment_rate.currency !== null) {
        data.payment_rate = {}
        data.payment_rate.card = BigInt(this.operatorData.card).toString()
        data.payment_rate.currency = this.defaultOperatorValue({
          key: 'currency'
        })
      }

      Object.keys(data).forEach(
        key => (data[key] === null || data[key] === undefined || data[key] === 'Неважно') && delete data[key]
      )

      const formData = new FormData()
      if (this.operatorData.file) {
        formData.append('avatar', this.operatorData.file)
      }

      if (Object.keys(data).length !== 0 || this.operatorData.file) {
        formData.append('data', JSON.stringify(data))
        if (this.operatorData.file || Object.keys(data).length !== 0) {
          let data = {
            id: this.oldData.id,
            form: formData
          }
          this.editOperator(data).then(response => {
            if (
              response.serverResponse.data.error_msg ===
              'Operator link error (that user is linked to someone else)'
            ) {
              this.$root.$emit(this.OPERATOR_NO_CLOSE_POPUP)
            } else {
              this.$root.$emit('changeOperators', 'change')
              this.closePopup()
            }
          })
        }
      } else {
        this.closePopup()
      }
    },
    maskCard(value) {
      return value.toString().replace(/\d{4}(?=.)/g, '$& ')
    }
  },

  updated() {
    this.$root.$on('closePopup', () => {
      this.closePopup()
    })
  },

  mounted() {
    this.$root.$on(EDIT_OPERATOR_POPUP_EVENT, operator => {
      this.setCurrentUser(null)
      this.operatorData.first_name = operator.first_name
      this.isActive = operator.is_active
      this.operatorData.last_name = operator.last_name
      this.operatorData.card = this.maskCard(operator.payment_rate.card)
      this.operatorData.avatar = operator.avatar
      let currency = this.operatorRoles.currency.find(f => f.id === operator.payment_rate.currency)
      this.selectItemHandler({ key: 'currency', value: currency })
      let newArrUser = []
      operator.accounts.forEach(f => newArrUser.push(f.account))
      this.setCurrentUser(newArrUser)
      this.oldData = operator
      this.currentOper(this.oldData)
      this.open()
    })
  }
}
</script>
<style lang="scss" scoped>
@import '@/components/Modals/EmployeePopup/EditEmployee.scss';
@import './EditOperator.scss';
.operator-profile__edit-group--account {
  display: flex;
  flex-direction: column;
}

.operator-profile__edit-group--container {
  display: flex;
  justify-content: space-between;
}

.operator-profile__account-info {
  display: flex;
  align-items: center;
}
.operator-profile__account-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>
