<template>
  <Popup :visible="visible" @close="close" description="Удалить департамент?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="appHandler('approve')">
          Да
        </button>
        <button class="popup__btn btn-red" @click="appHandler('reject')">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions } from 'vuex'
import { REQUEST_DELETE_DEPARTMENT } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { DELETE_DEPARTMENT_POPUP_EVENT } from '@/constants/event'
export default {
  name: 'EditDepartmentPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    currentDepartment: null
  }),

  methods: {
    ...mapActions({
      delete: REQUEST_DELETE_DEPARTMENT
    }),
    appHandler(method) {
      switch (method) {
        case 'approve':
          this.delete(this.currentDepartment)
          this.close()
          break
        default:
          this.close()
          break
      }
    }
  },

  mounted() {
    this.$root.$on(DELETE_DEPARTMENT_POPUP_EVENT, department => {
      this.open()
      this.currentDepartment = department
    })
  }
}
</script>
