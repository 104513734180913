<template>
  <Popup :visible="visible" @close="closePopUp" description="Добавить подарок" modalClass="popup--small">
    <form @submit.prevent="sendNewData" class="popup__form">
      <div class="popup__avatar-wrap">
        <div class="popup__avatar">
          <img v-if="isAvatar" :src="preview" alt="" class="popup__image" />
          <img
            v-else-if="icon"
            :src="getFile({ type: 'image', file: icon })"
            class="chat__message-body--img popup__image"
            alt=""
          />
          <img v-else src="@/assets/img/anonymous_user.png" alt="" class="popup__image" />
        </div>
        <div class="popup__avatar-edit">
          <input
            class="popup__avatar-edit-input default-input"
            type="file"
            id="avatar-input"
            accept=".jpg, .jpeg, .png"
            ref="downloadavatar"
            @change="fileUploadHandler"
            name="image"
            required
          />
          <label
            class="popup__avatar-edit-label"
            for="avatar-input"
            :class="{
              'avatar-invalid': !icon
            }"
          >
            <i class="fa fa-pen"></i>
          </label>
        </div>
      </div>
      <div class="popup__item">
        <label for="achievement-name" class="popup__item-title popup__name">Цена</label>
        <input
          type="number"
          name="price"
          id="achievement-name"
          class="popup__input default-input"
          v-model.number="price"
          required
        />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--center">
          <button type="submit" class="popup__btn btn-green">Да</button>
          <button @click="closePopUp" class="popup__btn btn-red">Нет</button>
        </div>
      </div>
    </form>
  </Popup>
</template>

<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import getImgLink from '@/components/mixins/getImgCdnLink'
import { REQUEST_CREATE_NEW_GIFTS } from '@/store/action-types'
import { GIFT_ADD_POPUP_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'

export default {
  name: 'GiftsPopupAdd',
  components: {
    Popup
  },
  mixins: [ModalMixin, getImgLink],
  data() {
    return {
      price: '',
      icon: null,
      isAvatar: false
    }
  },
  methods: {
    ...mapActions({
      createNewGift: REQUEST_CREATE_NEW_GIFTS
    }),

    fileUploadHandler() {
      const avatar = this.$refs.downloadavatar.files[0]
      this.preview = URL.createObjectURL(avatar)
      this.isAvatar = true
      this.icon = avatar
    },

    sendNewData() {
      const formData = new FormData()
      formData.append('price', Number(this.price))
      formData.append('image', this.icon)
      this.createNewGift(formData)
      this.price = ''
      this.icon = null
      this.isAvatar = false
      this.close()
    },
    closePopUp() {
      this.price = ''
      this.icon = null
      this.isAvatar = false
      this.close()
    }
  },
  mounted() {
    this.$root.$on(GIFT_ADD_POPUP_EVENT, () => this.open())
  }
}
</script>

<style lang="scss">
.popup {
  &__avatar-wrap {
    display: flex;
    width: 128px;
    height: 128px;
    margin: 0 auto;
    border-radius: 50%;
    background-color: white;

    position: relative;
  }
  // .popup__avatar-edit
  &__avatar-edit {
    width: 40px;
    height: 40px;
    z-index: 2;
    right: 0;

    position: absolute;
    top: 0;
    right: -35px;
  }
  // .popup__avatar-edit-input
  &__avatar-edit-input {
    display: none;
  }
  // .popup__avatar-edit-label
  &__avatar-edit-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
  }
  // .popup__avatar
  &__avatar {
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    align-items: center;
    display: flex;
    height: 100%;
  }
  &__avatar img {
    display: block;
    max-width: 100%;
  }
  &__input {
    margin-left: 1px;
    height: 30px;
    width: 100%;
  }
}
</style>
