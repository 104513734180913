<template>
  <li class="popup__item-user" :id="user.id" @click="onClick(user)">
    <div class="popup__user-item popup__user-avatar">
      <img :src="getImgLinkCropped(user.user_avatar.content_id, 480, { ...user.user_avatar.crop })" alt="" />
    </div>
    <div class="popup__user-item popup__user-name">
      <a :href="`${link}/users/${user.id}/`" target="_blank" class="popup__user-text"
        >{{ user.user_name }},
      </a>
      <span class="popup__user-text">({{ user.age || 0 }})</span>
    </div>
    <div class="popup__user-item popup__user-email">
      <span class="popup__user-text">{{ user.user_email }}</span>
    </div>
    <div class="popup__user-item popup__user-location">
      <span class="popup__user-text">{{ user.user_city }}</span>
    </div>
  </li>
</template>

<script>
import getImgCdnLink from '@/components/mixins/getImgCdnLink'
export default {
  name: 'AddUserPopupItem',
  props: {
    user: {
      type: Object
    },
    onClick: {
      type: Function
    }
  },
  data: () => ({
    link: `${process.env.VUE_APP_DEPLOY_URL}`
  }),
  mixins: [getImgCdnLink]
}
</script>

<style lang="scss" scoped>
.popup__item {
  margin-top: 10px;
}
.popup__input {
  margin-top: 5px;
}

.popup--add-user {
  width: 1000px;
}
.popup__filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.popup__filter-item {
  margin-right: 10px;
}

.popup__filter-btn {
  padding-top: 20px;
}

.popup__item-list {
  background-color: #e7e8ec;
  justify-content: center;
  padding: 5px;
}

.popup__user-avatar img {
  height: 95px;
  width: 100px;
}

.popup__item-user {
  border: 1px solid white;
  background-color: white;

  margin-top: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    margin-top: 0;
  }
  &--active {
    border-color: #1caadc;
  }
}
.popup__user-item {
  font-size: 13px;
  width: 25%;
}

.popup__user-text {
  color: inherit;
}
</style>
