export default {
  position: 'top-right',
  theme: 'light',
  iconEnabled: 'true',
  hideProgressbar: false,
  canTimeout: true,
  draggable: false,
  canPause: false,
  successDuration: 2000,
  baseIconClass: 'icon__tutify',
  errorDuration: 2000
}
