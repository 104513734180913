<template>
  <Popup :visible="visible" @close="closeModal" description="Обновить процент" modalClass="popup--small">
    <form @submit.prevent="updateGiftEventPercentHandler" class="modal-update__question">
      <div class="form-group">
        <label for="percent" class="popup__item-title">Процент</label>
        <input
          type="number"
          id="percent"
          name="percent"
          class="popup__input default-input"
          v-model="currentPercent"
          required
        />
      </div>

      <div class="popup-footer">
        <button class="button button--add">Изменить</button>
        <button type="button" @click="closeModal" class="button button--back">
          Отмена
        </button>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapActions } from 'vuex'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { GIFTS_ENEVT_UPDATE_PERCENT_GREAT_EVENT } from '@/constants/event'

import { REQUEST_UPDATE_PERCENT_GIFTS_EVENTS } from '@/store/action-types'

export default {
  name: 'GiftsEventPopupUpdatePercentGreat',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data() {
    return {
      currentPercent: 0,
      currentGiftId: ''
    }
  },

  mounted() {
    this.$root.$on(GIFTS_ENEVT_UPDATE_PERCENT_GREAT_EVENT, (giftId, giftPercent) => {
      this.currentPercent = giftPercent
      this.currentGiftId = giftId
      this.open()
    })
  },

  methods: {
    closeModal() {
      this.currentPercent = 0
      this.currentGiftId = ''
      this.close()
    },

    ...mapActions({
      updatePercentGiftEvent: REQUEST_UPDATE_PERCENT_GIFTS_EVENTS
    }),

    updateGiftEventPercentHandler() {
      const updatingDataGift = {
        id: this.currentGiftId,
        data: {
          percent: +this.currentPercent
        }
      }

      this.updatePercentGiftEvent(updatingDataGift)

      this.closeModal()
    }
  }
}
</script>

<style lang="scss">
@import './FaqPopupStyle';
</style>
