<template>
  <div class="">
    <ul class="table-statistic__list">
      <li class="table-statistic__row" v-for="(stat, index) in statistic" :key="index">
        <TableStatisticRow :statistic="statistic" :stat="stat" />
      </li>
    </ul>
  </div>
</template>

<script>
import TableStatisticRow from '@/components/Operators/OperatorStatistic/TableStatisticRow'

export default {
  name: 'OperatorsStatistic',
  components: {
    TableStatisticRow
  },
  props: {
    statistic: {
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'OperatorStatistic';

.table-statistic__wrapp {
  display: flex;
}
</style>
