<template>
  <Popup :visible="visible" @close="close" description="Новый вопрос" modalClass="popup--medium">
    <form @submit.prevent="sendContentHandler">
      <div class="form-group">
        <label for="question" class="popup__item-title">Вопрос</label>
        <textarea
          placeholder="Вопрос"
          name="question"
          id="question"
          class="popup__input default-input"
          v-model="newContent.question"
          required
        ></textarea>
      </div>

      <div class="form-group">
        <label for="answer" class="popup__item-title">Ответ</label>
        <textarea
          placeholder="Ответ"
          name="answer"
          id="answer"
          class="popup__input default-input"
          v-model="newContent.answer"
          required
        ></textarea>
      </div>

      <div class="form-group form-group--flex">
        <label
          for="status"
          class="checkbox popup__item-title"
          :class="{ 'checkbox--active': newContent.status }"
          >Опубликован</label
        >
        <input
          type="checkbox"
          name="status"
          id="status"
          class="popup__input default-input"
          v-model="newContent.status"
          hidden
        />
      </div>

      <div class="popup-footer">
        <button class="button button--add">Добавить</button>
        <button class="button button--back" type="button" @click="closeModal">
          Отмена
        </button>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { FAQ_POPUP_NEW_QUESTIONS_EVENT } from '@/constants/event'
import { GET_CURRENT_LANGUAGES } from '@/store/getter-types'
import { REQUEST_ADD_NEW_CONTENT_BY_CATEGORY } from '@/store/action-types'
import { GET_CURRENT_CONTENT_ID } from '@/store/getter-types'

export default {
  name: 'FaqPopupAddQuestion',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data() {
    return {
      newContent: {
        question: '',
        answer: '',
        status: false
      },
      currentContentId: null
    }
  },

  mounted() {
    this.$root.$on(FAQ_POPUP_NEW_QUESTIONS_EVENT, currentContentId => {
      this.currentContentId = currentContentId
      this.open()
    })
  },

  computed: {
    ...mapGetters({
      currentCategoryId: GET_CURRENT_CONTENT_ID,
      lang: GET_CURRENT_LANGUAGES
    })
  },

  methods: {
    closeModal() {
      this.close()
    },

    ...mapActions({ createNewContent: REQUEST_ADD_NEW_CONTENT_BY_CATEGORY }),

    sendContentHandler() {
      const newContent = {
        data: {
          faq_category_id: this.currentContentId,
          lang: this.lang,
          question: this.newContent.question,
          answer: this.newContent.answer,
          active: this.newContent.status
        },
        id: this.currentContentId
      }
      this.createNewContent(newContent, this.currentCategoryId)

      this.newContent = {
        status: false
      }
      this.close()
    }
  }
}
</script>

<style lang="scss">
@import './FaqPopupStyle';
</style>
