export default {
  methods: {
    toLocaleDateUser(timestamp) {
      return timestamp ? new Date(timestamp).toLocaleString('ru-RU').slice(0, -3) : timestamp
    },
    toLocaleDateUserWithoutTime(timestamp) {
      return timestamp ? new Date(timestamp).toLocaleDateString('ru-RU') : timestamp
    },
    toLocaleDateWithoutHours(timestamp) {
      return timestamp ? new Date(timestamp * 1000).toLocaleDateString('ru-RU') : timestamp
    },
    toLocaleDatePayments(data) {
      return data ? new Date(data).toLocaleDateString('ru-RU') : new Date().toLocaleDateString('ru-RU')
    },
    toLocaleTime(timestamp) {
      return timestamp ? new Date(timestamp * 1000).toLocaleTimeString('ru-RU').slice(0, -3) : timestamp
    }
  }
}
