<template>
  <div class="table-header-wrapper">
    <ul class="table-header">
      <li class="table-header__item table-header__item--employee">
        <span class="table-header__item-text">Ф.И.О.</span>
      </li>
      <li class="table-header__item table-header__item--employee">
        <span class="table-header__item-text">Email</span>
      </li>
      <li class="table-header__item table-header__item--employee">
        <span class="table-header__item-text table-header__item-text-select">Должность</span>
      </li>
      <li class="table-header__item table-header__item--employee">
        <span class="table-header__item-text table-header__item-text-select">Статус</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'EmployeeTableHeader'
}
</script>
<style lang="scss" scoped>
@import './EmployeeTableHeader.scss';
</style>
