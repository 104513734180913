import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import VueCookies from 'vue-cookies'
import clickoutside from '@/plugins/click-outside'
import FaIcon from '@/plugins/icon'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import vuelidate from 'vuelidate'
import VueToastify from 'vue-toastify'
import toastifySettings from '@/plugins/toastify'
import VTooltip from 'v-tooltip'
import options from '@/plugins/tooltips'

// для отображения стандартных иконок маркера в карте
import L from 'leaflet'
delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(FaIcon)
Vue.use(VueToastify, toastifySettings)

Vue.use(VTooltip, options)
Vue.use(vuelidate)
Vue.directive('click-outside', clickoutside)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
