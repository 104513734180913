import * as GetterTypes from './getter-types'
export default {
  [GetterTypes.GET_EPLOYEES]: ({ employees }) => employees,

  [GetterTypes.GET_EMPLOYEE_ROLES]: ({ tmpUserFilters }) => tmpUserFilters,

  [GetterTypes.GET_EMPLOYEE_ROLES_DEFAULT]: ({ filterUsersDefault }) => filterUsersDefault,

  [GetterTypes.GET_EMPLOYEE_TMP_ROLES]: ({ employeeTmpRoles }) => employeeTmpRoles,

  [GetterTypes.GET_EMPLOYEE_CURRENT_FILTER]: ({ permissions }) => value => {
    return permissions.find(item => item.value === value)
  },

  [GetterTypes.GET_EMPLOYEE_PERMISSIONS]: ({ permissions }) => permissions,

  [GetterTypes.GET_EMPLOYEE_POSITIONS]: ({ positions }) => positions.position,

  [GetterTypes.GET_CURRENT_EMPLOYEE]: ({ currentEmployee }) => currentEmployee,

  [GetterTypes.GET_TMP_FILTER_INFO]: ({ filterUsersInfo }) => filterUsersInfo,

  [GetterTypes.GET_TMP_FILTER_INFO_ROLES]: ({ employeeRolesDefault }) => employeeRolesDefault
}
