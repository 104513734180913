<template>
  <li class="moderator-payments__table-row">
    <div class="moderator-payments__table-item">
      <span>
        {{ toLocaleDateUserWithoutTime(item.payout_period_from) }} -
        {{ toLocaleDateUserWithoutTime(item.payout_period_to) }}
      </span>
    </div>
    <div class="moderator-payments__table-item">
      <span :class="{ 'moderator-payments__text': item.photo_sum }">{{
        item.photo_sum ? item.photo_sum : '-'
      }}</span>
      <i v-if="item.photo_sum" class="moderator-payments__icon fas fa-ruble-sign"></i>
    </div>
    <div class="moderator-payments__table-item">
      <span :class="{ 'moderator-payments__text': item.video_sum }">{{
        item.video_sum ? item.video_sum : '-'
      }}</span>
      <i v-if="item.video_sum" class="moderator-payments__icon fas fa-ruble-sign"></i>
    </div>
    <div class="moderator-payments__table-item">
      <span :class="{ 'moderator-payments__text': item.event_sum }">{{
        item.event_sum ? item.event_sum : '-'
      }}</span>
      <i v-if="item.event_sum" class="moderator-payments__icon fas fa-ruble-sign"></i>
    </div>
    <div class="moderator-payments__table-item">
      <span :class="{ 'moderator-payments__text': item.ban_sum }">{{
        item.ban_sum ? item.ban_sum : '-'
      }}</span>
      <i v-if="item.ban_sum" class="moderator-payments__icon fas fa-ruble-sign"></i>
    </div>
    <div class="moderator-payments__table-item">
      <span :class="{ 'moderator-payments__text': item.surcharge_sum }">{{
        item.surcharge_sum ? item.surcharge_sum : '-'
      }}</span>
      <i v-if="item.surcharge_sum" class="moderator-payments__icon fas fa-ruble-sign"></i>
    </div>

    <div class="moderator-payments__table-item moderator-payments__table-item--award">
      <div class="moderator-payments__item-text moderator-payments__award-text">
        <span :class="{ 'moderator-payments__text': item.prize_sum }">{{
          item.prize_sum ? item.prize_sum : '-'
        }}</span>
        <i v-if="item.prize_sum" class="moderator-payments__icon fas fa-ruble-sign"></i>
      </div>

      <div
        class="moderator-payments__award-btn"
        :class="{
          'moderator-payments__award-btn--active':
            item.settlement_date && !item.payout_date && item.prize_sum === 0
        }"
      >
        <button
          @click="
            $root.$emit(PRIZE_MODERATOR_POPUP_EVENT, {
              pay: item.id
            })
          "
          class="moderators-payments__btn moderators-payments__btn--award"
        >
          <i class="fas fa-coins"></i>
        </button>
      </div>
    </div>
    <div class="moderator-payments__table-item">
      <span :class="{ 'moderator-payments__text': item.total_sum || item.pay_sum }">{{
        item.total_sum ? item.total_sum.toFixed(2) : item.pay_sum.toFixed(2)
      }}</span>
      <i class="moderator-payments__icon fas fa-ruble-sign"></i>
    </div>
    <div class="moderator-payments__table-item moderator-payments__table-item--withbtn">
      <div
        v-if="item.transaction_status !== null"
        v-tooltip="statusText(item.transaction_status)"
        class="moderator-payments__status-text"
      >
        <i
          :class="{
            'fas fa-exclamation-circle': item.transaction_status === 0,
            'fa fa-ban': item.transaction_status === 3,
            'fa fa-check': item.transaction_status === 2,
            'fa fa-exclamation-triangle': item.transaction_status === 4,
            'fa fa-spinner fa-spin': item.transaction_status === 1
          }"
        ></i>
      </div>
      <span class="moderator-payments__item-text">{{
        item.payout_date ? toLocaleDateUserWithoutTime(item.payout_date) : 'Нет'
      }}</span>
      <button
        :disabled="!checkDate"
        @click="
          $root.$emit(PAY_MODERATOR_POPUP_EVENT, {
            moderator: moderator,
            pay: item
          })
        "
        class="moderators-payments__btn moderators-payments__btn--pay"
        :class="!checkDate ? 'moderators-payments__btn--disabled' : ''"
      >
        <i class="fas fa-dollar-sign"></i>
        <span> Оплата</span>
      </button>
    </div>
  </li>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { SET_CURRENT_MODERATOR } from '@/store/mutation-types'
import getTransactionStatus from '@/components/mixins/getTransactionStatus'
import getIconCurrency from '@/components/mixins/getIconCurrency'
import { REQUEST_BILLING_MODERATORS } from '@/store/action-types'
import toLocaleDateTime from '@/components/mixins/toLocaleformat'
import moment from 'moment'

import {
  BILL_MODERATOR_POPUP_EVENT,
  PRIZE_MODERATOR_POPUP_EVENT,
  PAY_MODERATOR_POPUP_EVENT,
  CORRECTION_MODERATOR_POPUP_EVENT
} from '@/constants/event'
export default {
  name: 'ModeratorPaymentsItem',
  props: {
    moderator: {
      type: Object
    },
    item: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  data: () => ({
    BILL_MODERATOR_POPUP_EVENT,
    PRIZE_MODERATOR_POPUP_EVENT,
    PAY_MODERATOR_POPUP_EVENT,
    CORRECTION_MODERATOR_POPUP_EVENT,
    nightShift: false
  }),
  computed: {
    checkDate: function() {
      if (!this.item.payout_date && this.item.settlement_date) {
        let shortName = this.item.settlement_date.split('_')
        let twoWeeksOld = moment(shortName[0])
          .add(14, 'days')
          .startOf('day')
          .toISOString()
        if (moment().isSameOrAfter(twoWeeksOld)) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },

  mixins: [getTransactionStatus, getIconCurrency, toLocaleDateTime],
  methods: {
    ...mapActions({
      bill: REQUEST_BILLING_MODERATORS
    }),
    ...mapMutations({
      currentModerator: SET_CURRENT_MODERATOR
    }),
    changeCurrentModerator(moderator) {
      this.currentModerator(moderator)
    }
  }
}
</script>
<style lang="scss">
@import 'ModeratorsPayments';
</style>
