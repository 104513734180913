import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import ModeratorRatingService from '@/services/ModeratorRatingService'

export default {
  [ActionTypes.REQUEST_MODERATORS_RATING]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorRatingService, [userToken]).getModeratorsRating(
      formData
    )
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_MODERATORS_RATING, data.data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_MODERATOR_SETTINGS_SPEED]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorRatingService, [userToken]).getModeratorsSettingSpeed(
      formData
    )
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_MODERATOR_SETTINGS_SPEED, data.data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_MODERATOR_SETTINGS_QUALITY]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorRatingService, [userToken]).getModeratorsSettingQuality(
      formData
    )
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_MODERATOR_SETTINGS_QUALITY, data.data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_UPDATE_MODERATOR_SETTINGS_QUALITY]: async (
    { rootGetters, commit, dispatch },
    settingsData
  ) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorRatingService, [
      userToken
    ]).updateModeratorsSettingQuality(settingsData)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_MODERATOR_SETTINGS_QUALITY)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_UPDATE_MODERATOR_SETTINGS_SPEED]: async (
    { rootGetters, commit, dispatch },
    settingsData
  ) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorRatingService, [
      userToken
    ]).updateModeratorsSettingSpeed(settingsData)
    const { status } = response

    if (status) {
      await dispatch(ActionTypes.REQUEST_MODERATOR_SETTINGS_SPEED)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  }
}
