import Request from '../helpers/Request'
import BaseService from './BaseService'

class ReasonsService extends BaseService {
  constructor(token) {
    super(token)
  }
  // REASON DELETE
  async getReasonDelete(lang) {
    const getReasonDeleteUrl = `/api/v1/localization/delreason/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }

    return await Request.get(getReasonDeleteUrl, {
      headers,
      params: { lang }
    })
  }

  async addReasonDelete(payload) {
    const addReasonDeleteUrl = '/api/v1/localization/delreason/add/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(addReasonDeleteUrl, payload, { headers })
  }

  async updateReasonDelete(payload) {
    const updateReasonDeleteUrl = `/api/v1/localization/delreason/${payload.get('id')}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(updateReasonDeleteUrl, payload, { headers })
  }

  async deleteReasonDelete(id) {
    const deleteReasonDeleteUrl = `/api/v1/localization/delreason/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteReasonDeleteUrl, { headers })
  }
  // REASON BLOCK
  async getReasonBlock(lang) {
    const getReasonBlockUrl = `/api/v1/localization/blockreason/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }

    return await Request.get(getReasonBlockUrl, {
      headers,
      params: { lang }
    })
  }

  async addReasonBlock(payload) {
    const addReasonBlockUrl = '/api/v1/localization/blockreason/add/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(addReasonBlockUrl, payload, { headers })
  }

  async updateReasonBlock(payload) {
    const updateReasonBlockUrl = `/api/v1/localization/blockreason/${payload.get('id')}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(updateReasonBlockUrl, payload, { headers })
  }

  async deleteReasonBlock(id) {
    const deleteReasonBlockUrl = `/api/v1/localization/blockreason/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteReasonBlockUrl, { headers })
  }
}

export default ReasonsService
