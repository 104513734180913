var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Popup',{attrs:{"visible":_vm.visible,"title":"","description":"Добавить/Изменить изображение","modalClass":"popup"},on:{"close":_vm.closePopup}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendData($event)}}},[_c('div',[(_vm.isAvatar)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.preview,"alt":""}}):_c('img',{staticClass:"avatar",attrs:{"src":_vm.getFile({
            type: 'image',
            file: _vm.file,
            width: "auto",
            height: "auto"
          }),"alt":""}})]),_c('div',{staticClass:"popup__item"},[_c('div',{staticClass:"popup__btns popup__btns--right"},[_c('div',{staticClass:"addfile-btn"},[_c('input',{ref:"uploadImageCategory",staticClass:"addfile-btn__send-input",attrs:{"id":"event-file-input","type":"file","required":""},on:{"change":_vm.fileUploadHandler}}),_c('label',{staticClass:"addfile-btn__send-label",class:{
              'avatar-invalid': !_vm.isAvatar
            },attrs:{"for":"event-file-input"}},[(_vm.isAvatar)?[_c('span',{staticClass:"addfile-btn__send-file"})]:_vm._e(),_c('i',{staticClass:"fa fa-paperclip"})],2)]),_c('button',{staticClass:"popup__btn btn-green",attrs:{"type":"submit"}},[_vm._v("Изменить")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }