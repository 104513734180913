import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import EmployeeService from '@/services/EmployeeService'

export default {
  [ActionTypes.REQUEST_EMPLOYEE]: async ({ commit, dispatch }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const response = await dispatch(ActionTypes.REQUEST_ALL_EMPLOYEE)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_PERMISISONS)
      await dispatch(ActionTypes.REQUEST_ALL_POSITIONS)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_ALL_EMPLOYEE]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EmployeeService, [userToken]).getEmployees()
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_EMPLOYEES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_EMPLOYEE_FILTER]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EmployeeService, [userToken]).getEmployeesFilter(formData)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_EMPLOYEES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_ALL_PERMISISONS]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EmployeeService, [userToken]).getPermisions()
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_EMPLOYEE_PERMISSIONS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_ALL_POSITIONS]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EmployeeService, [userToken]).getPositions()
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_EMPLOYEE_POSITIONS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_DELETE_EMPLOYEE]: async ({ rootGetters, commit, dispatch }, employeeId) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EmployeeService, [userToken]).deleteEmployeeById(employeeId)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_EMPLOYEE)
    // }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EDIT_EMPLOYEE]: async ({ rootGetters, commit, dispatch }, { formData, id }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EmployeeService, [userToken]).editEmployee(formData, id)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_EMPLOYEE)
    // }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CREATE_EMPLOYEE]: async ({ rootGetters, commit, dispatch }, employeeData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EmployeeService, [userToken]).addEmployee(employeeData)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_EMPLOYEE)
    // }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CHECK_EMAIL]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EmployeeService, [userToken]).checkEmail(formData)
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
