import Request from '../helpers/Request'
import BaseService from './BaseService'

class PermissionService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getPermissions() {
    const permissionsUrl = `/api/v1/management/permissions/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }

    return await Request.get(permissionsUrl, { headers })
  }

  async createPermission(permission) {
    const permissionAddUrl = '/api/v1/management/permissions/add/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(permissionAddUrl, permission, { headers })
  }

  async deletePermissionById(id) {
    const permissionDestroyUrl = `/api/v1/management/permissions/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(permissionDestroyUrl, { headers })
  }

  async updatePermission(permission) {
    const permissionUpdateUrl = `/api/v1/management/permissions/${permission.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(permissionUpdateUrl, permission.permissionData, {
      headers
    })
  }

  async getPermissionsGroup() {
    const getPermissionsGroupUrl = `/api/v1/management/permissions-group/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getPermissionsGroupUrl, {
      headers
    })
  }
}

export default PermissionService
