<template>
  <div class="event-info-header">
    <div class="event-info-header__group">
      <div class="event-info-header__item">
        <span class="event-info-header__item-key">ID события:</span>
        <span class="event-info-header__item-value">{{ currentEvent.event_id }}</span>
      </div>
      <div class="event-info-header__item">
        <span class="event-info-header__item-key">Статус модерации:</span>
        <span class="event-info-header__item-value">{{
          currentEvent.event_status === 1 ? 'Одобрено' : '-'
        }}</span>
      </div>
    </div>
    <div class="event-info-header__group">
      <div class="event-info-header__item">
        <span class="event-info-header__item-key">Модератор:</span>
        <span v-if="currentEvent.event_moderator" class="event-info-header__item-value">{{
          currentEvent.event_moderator.first_name
        }}</span>
        <span v-else class="event-info-header__item-value">-</span>
      </div>
      <div class="event-info-header__item">
        <span class="event-info-header__item-key">Дата создания:</span>
        <span class="event-info-header__item-value">{{
          toLocaleDateWithoutHours(currentEvent.event_create)
        }}</span>
      </div>
    </div>
    <div class="event-info-header__group">
      <div class="event-info-header__item">
        <span class="event-info-header__item-key">Статус события:</span>
        <span class="event-info-header__item-value">{{
          currentEvent.event_status === 1 ? 'Создано' : '-'
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import toLocaleformat from '@/components/mixins/toLocaleformat'
export default {
  name: 'EventHeader',
  props: {
    currentEvent: {
      type: Object
    }
  },
  mixins: [toLocaleformat]
}
</script>
