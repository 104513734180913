// BalanceTransactionKind

export default [
  {
    code: 0,
    name: 'KIND_ADMIN',
    description: 'Подарочные баллы от администратора'
  },
  {
    code: 1,
    name: 'KIND_GIFT',
    description: 'Подарки'
  },
  {
    code: 2,
    name: 'KIND_REWARD',
    description: 'Регистрация, верификация, привязка, соц.сети'
  },
  {
    code: 3,
    name: 'KIND_LIKE',
    description: 'Поставил/получил лайк'
  },
  {
    code: 4,
    name: 'KIND_SHOW',
    description: 'Показать пользователя'
  },
  {
    code: 5,
    name: 'KIND_ENTER',
    description: 'Ежедневный вход'
  },
  {
    code: 6,
    name: 'KIND_PURCHASE',
    description: 'Покупка баллов'
  },
  {
    code: 7,
    name: 'KIND_CHAT_ROOM_CREATE',
    description: 'Создание группового чата'
  },
  {
    code: 8,
    name: 'KIND_CHAT_ROOM_UPDATE',
    description: 'Обновление параметров группового чата'
  },
  {
    code: 9,
    name: 'KIND_CHAT_ROOM_TAKEOVER',
    description: 'Выкуп группового чата'
  },
  {
    code: 10,
    name: 'KIND_CHAT_SEND_MASS',
    description: 'Массовая рассылка избранным пользователям'
  },
  {
    code: 11,
    name: 'KIND_CHAT_SEND_WITHOUT_MUTUAL_LIKE',
    description: 'Сообщение пользователю без взаимного лайка'
  },
  {
    code: 12,
    name: 'KIND_EVENT_CREATE',
    description: 'Создание события'
  },
  {
    code: 13,
    name: 'KIND_EVENT_ACCEPTED',
    description: 'Заявка на участие в событии'
  },
  {
    code: 14,
    name: 'KIND_EVENT_BID',
    description: 'Повышение ставки на событие'
  },
  {
    code: 15,
    name: 'KIND_CALL',
    description: 'Минута звонка'
  },
  {
    code: 16,
    name: 'KIND_CHAT_SEND_TOP',
    description: 'Сообщение ТОП пользователю'
  }
]
