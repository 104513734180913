var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"cause-points__item"},[_c('img',{staticClass:"cause-points__icon",attrs:{"src":_vm.getFile({ type: 'image', file: _vm.point.icon }),"alt":""}}),_c('div',{staticClass:"cause-points__name cause-points__element"},[_c('span',{staticClass:"cause-points__text"},[_vm._v("Название:")]),_c('span',{staticClass:"cause-points__name-text"},[_vm._v(_vm._s(_vm.point.title))])]),_c('div',{staticClass:"cause-points__kind cause-points__element"},[_c('span',{staticClass:"cause-points__text"},[_vm._v("Kind:")]),_c('span',[_vm._v(_vm._s(_vm.point.kind))])]),_c('div',{staticClass:"cause-points__points-wrap cause-points__element"},[_c('span',{staticClass:"cause-points__text"},[_vm._v("Количество баллов:")]),(_vm.filterMalePoint[0])?_c('span',{class:{
        'cause-points__points-plus': _vm.filterMalePoint[0].price >= 0,
        'cause-points__points-minus': _vm.filterMalePoint[0].price < 0
      }},[_vm._v(" "+_vm._s(Math.abs(_vm.filterMalePoint[0].price))+" "),_c('i',{staticClass:"fas fa-mars cause-points__gender"})]):_vm._e(),(_vm.filterFemalePoint[0])?_c('span',{class:{
        'cause-points__points-plus': _vm.filterFemalePoint[0].price >= 0,
        'cause-points__points-minus': _vm.filterFemalePoint[0].price < 0
      }},[_vm._v(" "+_vm._s(Math.abs(_vm.filterFemalePoint[0].price))+" "),_c('i',{staticClass:"fas fa-venus cause-points__gender"})]):_vm._e(),(_vm.filterTwoGenderPoint[0])?_c('span',{class:{
        'cause-points__points-plus': _vm.filterTwoGenderPoint[0].price >= 0,
        'cause-points__points-minus': _vm.filterTwoGenderPoint[0].price < 0
      }},[_vm._v(" "+_vm._s(Math.abs(_vm.filterTwoGenderPoint[0].price))+" "),_c('i',{staticClass:"fas fa-venus cause-points__gender"})]):_vm._e()]),_c('div',{staticClass:"cause-points__btn-wrap cause-points__element"},[_c('button',{staticClass:"cause-points__btn cause-points__btn-change cause-points__btn--green",on:{"click":function($event){return _vm.$root.$emit('POINTS_POPUP_EVENT', {
          point: _vm.point,
          filterMalePoint: _vm.filterMalePoint,
          filterFemalePoint: _vm.filterFemalePoint,
          filterTwoGenderPoint: _vm.filterTwoGenderPoint
        })}}},[_c('i',{staticClass:"fa fa-edit"})]),_c('button',{staticClass:"cause-points__btn cause-points__btn-delete cause-points__btn--red",on:{"click":function($event){return _vm.$root.$emit('POINTS_DELETE_POPUP_EVENT', {
          filterMalePoint: _vm.filterMalePoint,
          filterFemalePoint: _vm.filterFemalePoint,
          filterTwoGenderPoint: _vm.filterTwoGenderPoint
        })}}},[_c('i',{staticClass:"fa fa-times-circle"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }