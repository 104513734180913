<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Отвязать пользователя?"
    modalClass="popup--small"
  >
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="delHandler()">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { SET_OPERATOR_CURRENT_USER_DEL } from '@/store/mutation-types'
import { REQUEST_DELETE_OPERATOR_USER } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { DEL_OPERATOR_USER_POPUP_EVENT } from '@/constants/event'
export default {
  name: 'DelUserPopup',
  components: {
    Popup
  },
  data: () => ({
    operator_id: null,
    title: '',
    operator: null
  }),
  mixins: [ModalMixin],

  methods: {
    ...mapActions({
      deleteOperatorUser: REQUEST_DELETE_OPERATOR_USER
    }),
    ...mapMutations({
      setCurrentUser: SET_OPERATOR_CURRENT_USER_DEL
    }),
    closePopup() {
      this.operator_id = null
      this.close()
    },
    delHandler() {
      if (this.title === 'del') {
        const deleteData = {
          data: {
            accounts: [this.operator_id]
          },
          id: this.operator
        }

        this.deleteOperatorUser(deleteData)
      } else {
        this.setCurrentUser(this.operator_id)
      }
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(DEL_OPERATOR_USER_POPUP_EVENT, payload => {
      if (payload.operator) {
        this.operator = payload.operator
      }
      this.operator_id = payload.id
      this.title = payload.title
      this.open()
    })
  }
}
</script>
