<template>
  <Popup :visible="visible" @close="close" title="" description="" modalClass="">
    <form class="modal-update__question">
      <h2 class="pop-up__title pop-up__title--error">
        Список не прошедших транзакций:
      </h2>
      <div>
        <ul class="error__list">
          <li class="error__item" v-for="item in arr" :key="item.id">
            <span
              >{{ item.info.first_name }} {{ item.info.last_name }}, c
              {{ toLocaleDateUserWithoutTime(item.info.payout_period_from) }} по
              {{ toLocaleDateUserWithoutTime(item.info.payout_period_to) }}</span
            >
            <span class="error__status error__status--error" v-if="item.status === 4">Ошибка</span>
            <span class="error__status error__status--warning" v-if="item.status === 3">Отмена</span>
          </li>
        </ul>
      </div>
      <div class="pop-up__btn-wrapper">
        <button type="button" @click="closeModal" class="popup__btn btn-red button--center">
          Закрыть
        </button>
      </div>
    </form>
  </Popup>
</template>

<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { ERROR_MODERATOR_PAY_EVENT } from '@/constants/event'
import toLocaleDateTime from '@/components/mixins/toLocaleformat'
export default {
  name: 'PayModeratorErrorPopup',
  components: {
    Popup
  },
  mixins: [toLocaleDateTime, ModalMixin],
  data() {
    return {
      ERROR_MODERATOR_PAY_EVENT,
      arr: []
    }
  },
  mounted() {
    this.$root.$on(ERROR_MODERATOR_PAY_EVENT, prop => {
      this.arr = prop
      this.open()
    })
  },
  methods: {
    closeModal() {
      this.arr = []
      this.close()
    }
  }
}
</script>

<style lang="scss">
.pop-up__title {
  text-align: center;
  font-weight: bold;
}
.pop-up__btn-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.error__list {
  margin-top: 20px;
}
.error__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.error__status {
  margin-left: 10px;
  &--warning {
    color: orange;
  }
  &--error {
    color: #f67a6e;
  }
}
</style>
