import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_AUTO_MESSAGES]: (state, payload) => {
    state.autoMessages = payload
  },
  [MutationTypes.SET_AUTO_MESSAGE_COUNT]: (state, payload) => {
    state.countMessages = payload
  },
  [MutationTypes.SET_AUTO_MESSAGES_PAGE]: (state, payload) => {
    state.pageNow = payload
  },
  [MutationTypes.SET_AUTO_MESSAGES_PAGINATION]: (state, payload) => {
    if (payload.next) {
      let shortNextLink = payload.next.split(`.su/`)
      state.nextLinkMessages = shortNextLink[1]
    } else {
      state.nextLinkMessages = ''
    }
    if (payload.previous) {
      let shortPreviousLink = payload.previous.split(`.su/`)
      state.previousLinkMessages = shortPreviousLink[1]
    } else {
      state.previousLinkMessages = ''
    }
  },
  [MutationTypes.SET_AUTO_MESSAGES_NOW_LINK]: (state, payload) => {
    state.nowLink = payload
  }
}
