<template>
  <Popup :visible="visible" @close="closePopup" :description="descriptionText" modalClass="popup--small">
    <div class="popup__item" v-if="!accept">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="clickHandlerCall(true)">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close()">Нет</button>
      </div>
    </div>
    <div class="popup__item" v-else>
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="delVipCall(false)">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close()">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_VIP_CALL } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_UPDATE_VIP_CALL } from '@/store/action-types'

export default {
  name: 'UserVipCallOnlyPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    id: '',
    accept: ''
  }),

  computed: {
    descriptionText: function() {
      return this.accept ? 'Принимать звонки от всех?' : 'Принимать звонки только от VIP?'
    }
  },

  methods: {
    ...mapActions({
      updateVip: REQUEST_UPDATE_VIP_CALL
    }),
    closePopup() {
      this.id = ''
      this.close()
    },
    clickHandlerCall(isAccept) {
      const payload = {
        user_id: this.id,
        accept: isAccept
      }
      this.updateVip(payload)
      this.id = ''
      this.closePopup()
    },
    delVipCall(isAccept) {
      const payload = {
        user_id: this.id,
        accept: isAccept
      }
      this.updateVip(payload)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(USER_VIP_CALL, user => {
      this.id = user.id
      this.accept = user.confidential.incoming_call_vip
      this.open()
    })
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/vars.scss';
.popup__item--btn {
  flex-direction: row;
}
.popup__btn--left {
  margin-right: auto;
  padding: 4px;
  min-width: 80px;
  height: 34px;
  border-radius: 4px;
}
.sl-btn {
  padding: 0;
  right: 110px;
  margin-top: 2px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  position: absolute;
  &__label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../assets/img/sl.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid $white;
    border-radius: 50%;
    cursor: pointer;
  }
  &__input {
    display: none;
    &:checked {
      & ~ .sl-btn__label {
        border: 2px solid $blue;
      }
    }
  }
}
</style>
