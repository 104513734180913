<template>
  <div class="purchase">
    <ul class="purchase__list">
      <PurchaseItem v-for="item in purchases" :key="item.id" :item="item" />
    </ul>
  </div>
</template>

<script>
import PurchaseItem from './PurchaseItem.vue'

export default {
  name: 'AchievementsList',
  props: {
    purchases: {
      type: Array
    }
  },
  components: {
    PurchaseItem
  }
}
</script>

<style lang="scss"></style>
