const REQUEST_ADD_SUPPORT_CONTACT_TYPES = 'REQUEST_ADD_SUPPORT_CONTACT_TYPES'
const REQUEST_DELETE_SUPPORT_CONTACT_TYPES = 'REQUEST_DELETE_SUPPORT_CONTACT_TYPES'
const REQUEST_SUPPORT_CONTACT_TYPES = 'REQUEST_SUPPORT_CONTACT_TYPES'
const REQUEST_EDIT_CONTACT_TYPES = 'REQUEST_EDIT_CONTACT_TYPES'

export {
  REQUEST_ADD_SUPPORT_CONTACT_TYPES,
  REQUEST_DELETE_SUPPORT_CONTACT_TYPES,
  REQUEST_EDIT_CONTACT_TYPES,
  REQUEST_SUPPORT_CONTACT_TYPES
}
