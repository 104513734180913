import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_EMPLOYEES]: (state, payload) => {
    state.employees = payload
  },

  [MutationTypes.SET_EMPLOYEE_PERMISSIONS]: (state, payload) => {
    state.permissions = payload
  },

  [MutationTypes.SET_EMPLOYEE_POSITIONS]: (state, payload) => {
    // state.positions.position = payload.map(el => {
    //   return {
    //     position_name: el.position_name,
    //     position_value: el.id
    //   }
    // })
    let pos = payload.map(el => {
      return {
        position_name: el.position_name,
        position_value: el.id
      }
    })
    state.positions.position = pos
    state.filterUsersInfo.positions.push(...pos)
  },

  [MutationTypes.SET_TMP_ROLE_EMPLOYEE]: (state, payload) => {
    const { key, value } = payload
    const settingObj = {
      key,
      ...value
    }
    state.employeeTmpRoles[key] = settingObj
  },

  [MutationTypes.SET_TMP_ROLE_EMPLOYEE_RESET]: state => {
    for (const key in state.employeeTmpRoles) {
      state.employeeTmpRoles[key] = {}
    }
  },

  [MutationTypes.SET_EMPLOYEE_SETTINGS]: (state, payload) => {
    const { key, value } = payload
    const settingObj = {
      key,
      ...value
    }
    state.tmpUserFilters[key] = settingObj
  },

  [MutationTypes.SET_CLEAR_EMPLOYEE_DATA]: state => {
    for (const key in state.tmpUserFilters) {
      state.tmpUserFilters[key] = {}
    }
  },

  [MutationTypes.SET_CURRENT_EMPLOYEE]: (state, payload) => {
    state.currentEmployee = payload
  },

  [MutationTypes.SET_UPDATE_EMPLOYEE_FIELD]: (state, { key, value }) => {
    state.currentEmployee[key] = value
  }
}
