<template>
  <div class="overlay">
    <div class="lds-ring" :class="{ 'lds-ring_open': menuOpen }">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loader',
  props: {
    menuOpen: {
      type: Boolean
    }
  }
}
</script>

<style>
@import './Loader.css';
</style>
