export default {
  employees: [],
  currentEmployee: {},
  permissions: [],

  positions: {
    position: []
  },

  tmpUserFilters: {
    position: {},
    activity: {},
    employeeDeleted: {}
  },

  filterUsersDefault: {
    position: {
      position_name: 'Неважно',
      value: 'Неважно'
    },
    employeeDeleted: {
      position_name: 'Неважно',
      value: -1
    },
    activity: {
      position_name: 'Активные',
      value: true
    }
  },

  filterUsersInfo: {
    positions: [
      {
        position_name: 'Неважно',
        value: ''
      }
    ],
    activity: [
      {
        position_name: 'Неважно',
        value: -1
      },
      {
        position_name: 'Активные',
        value: true
      },
      {
        position_name: 'Неактивные',
        value: false
      }
    ],
    employeeDeleted: [
      {
        position_name: 'Неважно',
        value: -1
      },
      {
        position_name: 'Да',
        value: true
      },
      {
        position_name: 'Нет',
        value: false
      }
    ]
  },

  employeeTmpRoles: {
    lang: {},
    permissions: {},
    position: {}
  },

  employeeRoles: {
    lang: [
      {
        name: 'ru',
        value: 'ru',
        id: 0
      },
      {
        name: 'en',
        value: 'en',
        id: 1
      }
    ]
  },

  employeeRolesDefault: {
    lang: {
      name: 'ru',
      value: 'ru'
    },

    permissions: {
      name: 'Выберите уровень доступа',
      value: ''
    },

    position: {
      position_name: 'Выберите должность',
      value: ''
    }
  }
}
