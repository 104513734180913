// 3.13 Виды цифровых покупок (PurchaseTarget)

export default [
  {
    code: 0,
    name: 'CHAT_TRANSLATE',
    description: 'Перевод чата'
  },
  {
    code: 1,
    name: 'REVEAL_LIKES',
    description: 'Открыть все лайки сейчас'
  },
  {
    code: 2,
    name: 'REVEAL_VIEWS',
    description: 'Открыть все просмотры сейчас'
  },
  {
    code: 3,
    name: 'PURCHASE_USER_GREETING',
    description: 'Создание приветствия'
  },
  {
    code: 4,
    name: 'VIP_WEEK',
    description: 'VIP-статус на N нед.'
  },
  {
    code: 5,
    name: 'VIP_WEEK_TRIAL',
    description: 'VIP-статус на N нед.(пробный)'
  },
  {
    code: 6,
    name: 'VIP_WEEK_GIFT',
    description: 'Подарочный VIP-статус на N нед.'
  },
  {
    code: 7,
    name: 'VIP_MONTH',
    description: 'VIP-статус на N мес.'
  },
  {
    code: 8,
    name: 'VIP_MONTH_TRIAL',
    description: 'VIP-статус на N мес.(пробный)'
  },
  {
    code: 9,
    name: 'VIP_MONTH_GIFT',
    description: 'Подарочный VIP-статус на N мес.'
  },
  {
    code: 10,
    name: 'VIP_YEAR',
    description: 'VIP-статус на N лет.'
  },
  {
    code: 11,
    name: 'VIP_YEAR_TRIAL',
    description: 'VIP-статус на N лет.(пробный)'
  },
  {
    code: 12,
    name: 'VIP_YEAR_GIFT',
    description: 'Подарочный VIP-статус на N лет'
  },
  {
    code: 13,
    name: 'POINTS_X1',
    description: 'Покупка N баллов'
  },
  {
    code: 14,
    name: 'POINTS_X2',
    description: 'Покупка N двойных баллов'
  }
]
