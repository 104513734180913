const REQUEST_MODERATOR_PAYMENTS_SETTING = 'REQUEST_MODERATOR_PAYMENTS_SETTING'
const REQUEST_MODERATOR_EDIT_PAYMENTS_SETTING = 'REQUEST_MODERATOR_EDIT_PAYMENTS_SETTING'
const REQUEST_MODERATOR_CREATE_PAYMENTS_SETTING = 'REQUEST_MODERATOR_CREATE_PAYMENTS_SETTING'
const REQUEST_ALL_MODERATORS = 'REQUEST_ALL_MODERATORS'
const REQUEST_MODERATOR_STATISTIC = 'REQUEST_MODERATOR_STATISTIC'
const REQUEST_MODERATOR_PAYOUT = 'REQUEST_MODERATOR_PAYOUT'
const REQUEST_BILLING_MODERATORS = 'REQUEST_BILLING_MODERATORS'
const REQUEST_PRIZE_MODERATORS = 'REQUEST_PRIZE_MODERATORS'
const REQUEST_EXECUTE_MODERATORS = 'REQUEST_EXECUTE_MODERATORS'
const REQUEST_DELETE_MODERATOR_PAYMENTS_SETTING = 'REQUEST_DELETE_MODERATOR_PAYMENTS_SETTING'
const REQUEST_MODERATOR_DEL_PAYMENTS_ACTIVE = 'REQUEST_MODERATOR_DEL_PAYMENTS_ACTIVE'
const REQUEST_CORRECTION_MODERATORS = 'REQUEST_CORRECTION_MODERATORS'

export {
  REQUEST_MODERATOR_PAYMENTS_SETTING,
  REQUEST_MODERATOR_EDIT_PAYMENTS_SETTING,
  REQUEST_MODERATOR_CREATE_PAYMENTS_SETTING,
  REQUEST_ALL_MODERATORS,
  REQUEST_MODERATOR_STATISTIC,
  REQUEST_MODERATOR_PAYOUT,
  REQUEST_BILLING_MODERATORS,
  REQUEST_PRIZE_MODERATORS,
  REQUEST_EXECUTE_MODERATORS,
  REQUEST_DELETE_MODERATOR_PAYMENTS_SETTING,
  REQUEST_MODERATOR_DEL_PAYMENTS_ACTIVE,
  REQUEST_CORRECTION_MODERATORS
}
