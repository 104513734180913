const GET_RECORDS = 'GET_RECORDS'
const GET_LANGUAGE = 'GET_LANGUAGE'
const GET_GENDER = 'GET_GENDER'
const GET_COUNTRY = 'GET_COUNTRY'
const GET_PAGE_TYPES = 'GET_PAGE_TYPES'
const GET_RECORD_DEFAULT = 'GET_RECORD_DEFAULT'
const GET_TMP_RECORD_FILTERS = 'GET_TMP_RECORD_FILTERS'
export {
  GET_RECORDS,
  GET_LANGUAGE,
  GET_GENDER,
  GET_COUNTRY,
  GET_PAGE_TYPES,
  GET_RECORD_DEFAULT,
  GET_TMP_RECORD_FILTERS
}
