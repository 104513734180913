import Request from '../helpers/Request'
import BaseService from './BaseService'

class CausePointsService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getAllCausePoints(lang) {
    const getAllCausePointsUrl = `/api/v1/localization/points/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }
    return await Request.get(getAllCausePointsUrl, {
      headers,
      params: { lang }
    })
  }

  async addCausePoints(causePoints) {
    const causePointsEditUrl = `/api/v1/localization/points/add/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Content-Type': 'multipart/form-data'
    }
    return await Request.post(causePointsEditUrl, causePoints, { headers })
  }

  async deleteCausePoints(id) {
    const deleteAchievementUrl = `/api/v1/localization/points/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteAchievementUrl, { headers })
  }

  async updateCausePoints(achievement) {
    const updateAchievementUrl = `/api/v1/localization/points/${achievement.get('id')}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Content-Type': 'multipart/form-data'
    }
    return await Request.put(updateAchievementUrl, achievement, { headers })
  }
}

export default CausePointsService
