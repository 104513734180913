<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description=""
    modalClass="popup--medium popup--operator popup--scroll"
  >
    <div class="popup__item">
      <span class="operator-profile-banner">{{ title }}</span>
      <div class="operator-profile__edit-group">
        <span class="operator-profile__item-text">Заголовок</span>
        <input type="text" class="operator-message-input" v-model="banners.title" />
      </div>
      <div class="operator-profile__edit-group">
        <span class="operator-profile__item-text">Текст под заголовком</span>
        <textarea type="text" class="operator-message-textarea" v-model="banners.description" />
      </div>
      <div class="operator-profile__edit-group">
        <span class="operator-profile__item-text">Текст на кнопке</span>
        <input type="text" class="operator-message-input" v-model="banners.button_text" />
      </div>
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="saveOperatorsBanner" :disabled="buttonDis">
          Сохранить
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Закрыть
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions } from 'vuex'
import { REQUEST_EDIT_OPERATORS_BANNER, REQUEST_CHANGE_OPERATORS_BANNER } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EDIT_OPERATOR_BANNER } from '@/constants/event'

export default {
  name: 'OperatorPopupBanner',
  components: {
    Popup
  },
  data: () => ({
    banners: {},
    languagesId: '',
    oldBanners: {},
    bannersId: '',
    device: ''
  }),
  mixins: [ModalMixin],
  mounted() {
    this.$root.$on(EDIT_OPERATOR_BANNER, (data, id, device) => {
      this.setBanners(data, id, device)
      this.open(EDIT_OPERATOR_BANNER)
    })
  },
  computed: {
    title() {
      return this.bannersId ? 'Редактировать.' : 'Добавить.'
    },
    buttonDis() {
      return (
        JSON.stringify(this.banners) == JSON.stringify(this.oldBanners) ||
        Object.values(this.banners).includes('')
      )
    }
  },
  methods: {
    ...mapActions({
      editOperatorsBanner: REQUEST_EDIT_OPERATORS_BANNER,
      changeOperatorsBanner: REQUEST_CHANGE_OPERATORS_BANNER
    }),
    saveOperatorsBanner() {
      const id = this.bannersId
      const payload = {
        lang: this.languagesId,
        title: this.banners.title,
        description: this.banners.description,
        button_text: this.banners.button_text,
        device: this.device
      }
      this.bannersId ? this.changeOperatorsBanner({ payload, id }) : this.editOperatorsBanner(payload)
      this.close()
    },
    setBanners(data, id, device) {
      const yes = data.id
      this.device = device
      this.bannersId = yes
      this.banners = {
        button_text: yes ? data.button_text : '',
        description: yes ? data.description : '',
        title: yes ? data.title : ''
      }
      Object.assign(this.oldBanners, this.banners)
      this.languagesId = id
    },
    closePopup() {
      this.close()
    }
  }
}
</script>
<style lang="scss" scoped>
@import './EditOperatorBanner.scss';
</style>
