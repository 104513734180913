<template>
  <div class="faq-questions-wrapper" v-if="currentContent.length">
    <ul class="faq-questions-list" v-if="!currentContent[0].message">
      <li
        class="faq-questions-list__item"
        v-for="(content, index) in currentContent"
        :key="content.id + content.active"
      >
        <span class="faq-questions-list__property">
          <a
            @click="$root.$emit('FAQ_POPUP_UPDATE_QUESTION_EVENT', content)"
            class="faq-questions-list__property-item faq-questions-list__property-item--update"
            title="Редактировать"
          >
            <i class="fa fa-edit"></i>
          </a>
          <a
            @click="removeQuestion(content.id)"
            class="faq-questions-list__property-item faq-questions-list__property-item--delete"
            title="Удалить"
          >
            <i class="fa fa-times-circle"></i>
          </a>
        </span>

        <h2
          class="faq-questions-list__title"
          :class="{
            'faq-questions-list__title--active': index === currentIndexAcordion
          }"
          @click="openAccordionHandler(index)"
        >
          <i v-if="content.active" class="fa fa-check" :style="{ color: '#0c0', paddingRight: '2px' }"></i
          >{{ content.question }}
        </h2>

        <p class="faq-question-list__content">
          {{ content.answer }}
        </p>
      </li>
    </ul>
    <ul v-else class="faq-questions-list-stub">
      <li>{{ currentContent[0].message }}</li>
    </ul>
    <div class="faq-question-btns">
      <button
        style="width: 100%; "
        type="button"
        class="button--add-category"
        @click="$root.$emit(FAQ_POPUP_NEW_QUESTIONS_EVENT, currentContentId)"
        :disabled="!currentCategoryId"
      >
        <i class="fa fa-plus"></i> Добавить вопрос
      </button>
    </div>

    <faq-popup-add-question />
    <faq-popup-update-question :currentContentId="currentContentId" />
    <faq-popup-remove-element-question-by-id />
  </div>
  <div class="faq-questions-wrapper" v-else>
    <div class="faq-question-btns">
      <div class="intarface-faq__content-error" v-if="!currentContent.length">
        <span class="intarface-faq__content-error-text">Нет вопросов в выбранной категории...</span>
      </div>
      <button
        style="width: 100%;"
        type="button"
        class="button--add-category"
        @click="$root.$emit(FAQ_POPUP_NEW_QUESTIONS_EVENT, currentContentId)"
        :disabled="!currentCategoryId"
      >
        <i class="fa fa-plus"></i> Добавить вопрос
      </button>
    </div>
    <faq-popup-add-question />
    <faq-popup-update-question :currentContentId="currentContentId" />
    <faq-popup-remove-element-question-by-id />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { GET_CURRENT_CONTENT_ID } from '@/store/getter-types'
import { FAQ_POPUP_NEW_QUESTIONS_EVENT } from '@/constants/event'
import FaqPopupAddQuestion from '../Modals/FaqPopup/FaqPopupAddQuestion.vue'
import FaqPopupUpdateQuestion from '../Modals/FaqPopup/FaqPopupUpdateQuestion.vue'
import FaqPopupRemoveElementQuestionById from '../Modals/FaqPopup/FaqPopupRemoveElementQuestionById.vue'
export default {
  components: {
    FaqPopupAddQuestion,
    FaqPopupUpdateQuestion,
    FaqPopupRemoveElementQuestionById
  },
  name: 'FaqQuestionsList',

  data() {
    return {
      newContent: {},
      currentIndexAcordion: null,
      FAQ_POPUP_NEW_QUESTIONS_EVENT
    }
  },

  props: {
    currentContent: {
      type: Array
    },

    currentContentId: {
      required: true
    }
  },

  computed: {
    ...mapGetters({ currentCategoryId: GET_CURRENT_CONTENT_ID })
  },
  watch: {
    currentContent: function() {
      this.currentIndexAcordion = null
    }
  },
  methods: {
    openAccordionHandler(index) {
      if (index === this.currentIndexAcordion) this.currentIndexAcordion = null
      else this.currentIndexAcordion = index
    },

    removeQuestion(id) {
      const dataId = {
        questionId: id,
        categoryId: this.currentCategoryId
      }

      this.$root.$emit('FAQ_POPUP_REMOVE_ELEMENT_QUESTION', dataId)
    }
  }
}
</script>

<style lang="scss">
@import './FaqQuestionsList';
</style>
