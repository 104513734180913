const SET_EMPLOYEES = 'SET_EMPLOYEES'
const SET_EMPLOYEE_PERMISSIONS = 'SET_EMPLOYEE_PERMISSIONS'
const SET_EMPLOYEE_POSITIONS = 'SET_EMPLOYEE_POSITIONS'
const SET_EMPLOYEE_SETTINGS = 'SET_EMPLOYEE_SETTINGS'
const SET_CLEAR_EMPLOYEE_DATA = 'SET_CLEAR_EMPLOYEE_DATA'
const SET_CURRENT_EMPLOYEE = 'SET_CURRENT_EMPLOYEE'
const SET_UPDATE_EMPLOYEE_FIELD = 'SET_UPDATE_EMPLOYEE_FIELD'
const SET_TMP_ROLE_EMPLOYEE = 'SET_TMP_ROLE_EMPLOYEE'
const SET_TMP_ROLE_EMPLOYEE_RESET = 'SET_TMP_ROLE_EMPLOYEE_RESET'

export {
  SET_EMPLOYEES,
  SET_EMPLOYEE_PERMISSIONS,
  SET_EMPLOYEE_POSITIONS,
  SET_EMPLOYEE_SETTINGS,
  SET_CLEAR_EMPLOYEE_DATA,
  SET_CURRENT_EMPLOYEE,
  SET_UPDATE_EMPLOYEE_FIELD,
  SET_TMP_ROLE_EMPLOYEE,
  SET_TMP_ROLE_EMPLOYEE_RESET
}
