<template>
  <div class="ev-cat__header">
    <span class="ev-cat__title title-big">Причины блокировки</span>
    <div class="ev-cat__btns">
      <select class="ev-cat__select" v-model="selected" @change="getReasonsByLang(selected)">
        <option v-for="lang in languages" :key="lang.value" :value="lang.value">
          {{ lang.name }}
        </option>
      </select>
      <button class="ev-cat__btn-add" @click="$root.$emit(REASON_BLOCK_ADD_POPUP)">
        <i class="ev-cat__btn-txt fa fa-plus-square"></i>
        <span class="ev-cat__btn-title">Добавить</span>
      </button>
    </div>
  </div>
</template>

<script>
import { REASON_BLOCK_ADD_POPUP } from '@/constants/event'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_REASON_BLOCK } from '@/store/action-types'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types.js'
import { GET_LANGUAGES, GET_CURRENT_LANGUAGES } from '@/store/getter-types'
export default {
  name: 'ReasonBlockHeader',
  data() {
    return { REASON_BLOCK_ADD_POPUP }
  },
  methods: {
    ...mapActions({
      reasonByLang: REQUEST_REASON_BLOCK
    }),
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    }),
    getReasonsByLang(value) {
      this.reasonByLang(value)
    }
  },
  computed: {
    ...mapGetters({
      languages: GET_LANGUAGES,
      currentLang: GET_CURRENT_LANGUAGES
    }),
    selected: {
      get() {
        return this.currentLang
      },
      set(newValue) {
        this.setCurrentLang(newValue)
      }
    }
  }
}
</script>
<style lang="scss"></style>
