<template>
  <li class="cause-points__item">
    <img class="cause-points__icon" :src="getFile({ type: 'image', file: point.icon })" alt="" />
    <div class="cause-points__name cause-points__element">
      <span class="cause-points__text">Название:</span>
      <span class="cause-points__name-text">{{ point.title }}</span>
    </div>
    <div class="cause-points__kind cause-points__element">
      <span class="cause-points__text">Kind:</span>
      <span>{{ point.kind }}</span>
    </div>
    <div class="cause-points__points-wrap cause-points__element">
      <span class="cause-points__text">Количество баллов:</span>
      <span
        v-if="filterMalePoint[0]"
        :class="{
          'cause-points__points-plus': filterMalePoint[0].price >= 0,
          'cause-points__points-minus': filterMalePoint[0].price < 0
        }"
      >
        {{ Math.abs(filterMalePoint[0].price) }}
        <i class="fas fa-mars cause-points__gender"></i>
      </span>
      <span
        v-if="filterFemalePoint[0]"
        :class="{
          'cause-points__points-plus': filterFemalePoint[0].price >= 0,
          'cause-points__points-minus': filterFemalePoint[0].price < 0
        }"
      >
        {{ Math.abs(filterFemalePoint[0].price) }}
        <i class="fas fa-venus cause-points__gender"></i>
      </span>
      <span
        v-if="filterTwoGenderPoint[0]"
        :class="{
          'cause-points__points-plus': filterTwoGenderPoint[0].price >= 0,
          'cause-points__points-minus': filterTwoGenderPoint[0].price < 0
        }"
      >
        {{ Math.abs(filterTwoGenderPoint[0].price) }}
        <i class="fas fa-venus cause-points__gender"></i>
      </span>
    </div>
    <div class="cause-points__btn-wrap cause-points__element">
      <button
        @click="
          $root.$emit('POINTS_POPUP_EVENT', {
            point,
            filterMalePoint,
            filterFemalePoint,
            filterTwoGenderPoint
          })
        "
        class="cause-points__btn cause-points__btn-change cause-points__btn--green"
      >
        <i class="fa fa-edit"></i>
      </button>
      <button
        @click="
          $root.$emit('POINTS_DELETE_POPUP_EVENT', {
            filterMalePoint,
            filterFemalePoint,
            filterTwoGenderPoint
          })
        "
        class="cause-points__btn cause-points__btn-delete cause-points__btn--red"
      >
        <i class="fa fa-times-circle"></i>
      </button>
    </div>
  </li>
</template>

<script>
import getImgLink from '@/components/mixins/getImgCdnLink'
export default {
  name: 'CausePointsItem',
  props: {
    point: {
      type: Object
    },
    malePoints: {
      type: Array
    },
    femalePoints: {
      type: Array
    },
    twoGenderPoints: {
      type: Array
    }
  },
  data: () => ({
    priceMale: null,
    priceFemale: null,
    priceTwoGender: null
  }),
  mixins: [getImgLink],
  computed: {
    filterMalePoint() {
      let tableHeaders = []
      tableHeaders = this.malePoints.filter(x => x.title === this.point.title)
      return tableHeaders
    },
    filterFemalePoint() {
      let tableHeaders = []
      tableHeaders = this.femalePoints.filter(n => n.title === this.point.title)
      return tableHeaders
    },
    filterTwoGenderPoint() {
      let tableHeaders = []
      tableHeaders = this.twoGenderPoints.filter(y => y.title === this.point.title)
      return tableHeaders
    }
  },
  watch: {
    filterMalePoint: {
      deep: true,
      handler: function(newVal) {
        this.priceMale = newVal
      }
    },
    filterFemalePoint: {
      deep: true,
      handler: function(newVal) {
        this.priceFemale = newVal
      }
    },
    filterTwoGenderPoint: {
      deep: true,
      handler: function(newVal) {
        this.priceTwoGender = newVal
      }
    }
  }
}
</script>

<style lang="scss"></style>
