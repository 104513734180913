<template>
  <div class="push-wrap">
    <div class="ev-cat">
      <PushHeader />
      <PushTableHeader @sorted="sorted($event)" />
      <div class="ev-cat__list" v-if="isLoaded && pushes.length">
        <Push v-for="push in pushes" :key="push.id" :push="push" />
      </div>
      <div v-if="!pushes.length && isLoaded" class="ev-cat__no-reasons">
        <span class="ev-cat__no-reasons-text">Список уведомлений пуст. Добавьте уведомления</span>
      </div>
      <div v-if="errorMessage" class="ev-cat__no-reasons">
        <span class="ev-cat__no-reasons-text">Ошибка при загрузке уведомлений!</span>
      </div>
    </div>
    <PushAddPopup />
    <PushDeletePopup />
    <PushUpdatePopup />
  </div>
</template>
<script>
import PushAddPopup from '@/components/Modals/PushesPopup/PushAddPopup'
import PushUpdatePopup from '@/components/Modals/PushesPopup/PushUpdatePopup'
import PushDeletePopup from '@/components/Modals/PushesPopup/PushDeletePopup'
import { mapGetters, mapMutations } from 'vuex'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types'
import { GET_PUSHES } from '@/store/getter-types'
import { REQUEST_PUSHES } from '@/store/action-types'
import PushHeader from '@/components/Pushes/PushHeader'
import PushTableHeader from '@/components/Pushes/PushTableHeader'
import Push from '@/components/Pushes/Push'

import store from '@/store'
export default {
  name: 'interfaceNotice',
  components: {
    PushHeader,
    PushTableHeader,
    Push,
    PushAddPopup,
    PushDeletePopup,
    PushUpdatePopup
  },
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_PUSHES).then(response => {
      next(vm => {
        if (response) {
          vm.setCurrentLang('RU')
        }
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      pushes: GET_PUSHES
    })
  },
  methods: {
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    }),
    sorted(sort) {
      if (sort) {
        this.pushes.sort((a, b) => a.type_push - b.type_push)
      } else {
        this.pushes.sort((a, b) => b.type_push - a.type_push)
      }
    }
  },

  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
.push-wrap {
  padding: 16px;

  .ev-cat__item-title span {
    font-weight: 700;
  }
  .ev-cat__item-name {
    flex-direction: column;
    align-items: flex-start;
  }
  .ev-cat__item-btn {
    width: 27px;
    min-width: 27px;
    height: 22px;
    border-radius: 4px;
    .ev-cat__item-btn-icon {
      font-size: 15px;
    }
    &--red {
      padding-top: 5px;
    }
  }
}
#table-push {
  width: 100%;
}
@import '@/components/Events/EventCategory.scss';
</style>
