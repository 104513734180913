<template>
  <div class="chart">
    <div class="chart-left">
      <div class="cart__btns" v-if="button_period.length > 0" @click="buttonPeriodClickHandler">
        <button
          v-for="button in button_period"
          :key="button.id"
          :data-id="button.id"
          :variant="button.class"
          :class="[button.active ? 'btn btn-danger' : 'btn', button.class ? button.class : '']"
        >
          {{ button.title }}
        </button>
        <button variant="primary" data-type="period" class="btn btn-primary">
          Период
        </button>
      </div>
      <div class="datepicker-traffic-source">
        <i class="select__field-icon fa fa-angle-down"></i>
        <DateRangePicker
          :locale-data="picker.localeData"
          :opens="picker.localeData.position"
          :close-on-esc="true"
          :autoApply="true"
          :ranges="false"
          ref="traffic"
          v-model="datePickerControlTraffic"
        >
        </DateRangePicker>
      </div>
    </div>
    <BarChart
      :chart-data="statistic_purchases_vip"
      :options="statistic_purchases_vip_opt"
      class="highcharts-figure"
    ></BarChart>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { REQUEST_PURCHASES_VIP_STATUS } from '@/store/action-types'
const { reactiveData } = mixins
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { mixins } from 'vue-chartjs'
import 'chartjs-plugin-datalabels'
import BarChart from '@/components/Statistic/BarChart'
import pickerMixin from '@/components/Picker/pickerMixin'
export default {
  name: 'StatisticPurchasesVip',
  mixins: [reactiveData, pickerMixin],
  components: {
    BarChart,
    DateRangePicker
  },
  props: {
    purchasesVip: {
      type: Object
    }
  },
  data: () => ({
    statistic_purchases_vip: {
      labels: [],
      datasets: []
    },
    statistic_purchases_vip_opt: {
      fontColor: '#fff',
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 30
        }
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              suggestedMin: 50,
              suggestedMax: 100,
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }
        ],
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            },
            maxBarThickness: 100
          }
        ]
      },
      title: {
        display: true
      },
      legend: {
        display: true,
        position: 'right'
      },
      tooltips: {
        enabled: true,
        mode: 'label',
        callbacks: {
          label: function(tooltipItem, data) {
            return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value}`
          }
        },
        xAlign: 'right',
        yAlign: 'bottom'
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let datasets = ctx.chart.data.datasets
            let firstIndex = null
            let sum = 0

            for (let [index, value] of Object.entries(datasets)) {
              if (ctx.chart.isDatasetVisible(index)) {
                firstIndex = parseInt(index)
                sum += parseInt(value.data[ctx.dataIndex])
              }
            }

            if (sum === 0) {
              return 0
            }

            return ctx.datasetIndex === firstIndex ? sum.toLocaleString() : ''
          },
          font: {
            size: 12
          },
          padding: {
            top: 10,
            bottom: -10
          },
          anchor: 'end',
          align: 'end',
          rotation: 0,
          clamp: true
        }
      }
    },
    datepicker_dates: {
      date_from: moment()
        .subtract(14, 'days')
        .startOf('day')
        .toString(),
      date_to: moment()
        .endOf('day')
        .toString()
    },
    button_period: [
      {
        id: 1,
        class: 'btn-default',
        title: '14 дней',
        active: 1,
        date: moment()
          .subtract(14, 'days')
          .startOf('day')
      },
      {
        id: 2,
        class: 'btn-default',
        title: '30 дней',
        active: 0,
        date: moment()
          .subtract(30, 'days')
          .startOf('day')
      },
      {
        id: 3,
        class: 'btn-default',
        title: '60 дней',
        active: 0,
        date: moment()
          .subtract(60, 'days')
          .startOf('day')
      }
      // {
      //   id: 4,
      //   class: 'btn-white',
      //   title: 'Все',
      //   active: 0,
      //   date: moment('2017-01-01').startOf('day')
      // }
    ]
  }),
  computed: {
    datePickerControlTraffic: {
      get() {
        const date = {
          startDate: moment()
            .subtract(14, 'days')
            .startOf('day'),
          endDate: moment().endOf('day')
        }
        return date
      },
      set({ startDate, endDate }) {
        this.datepicker_dates.date_from = new Date(startDate)
        this.datepicker_dates.date_to = new Date(endDate)
      }
    }
  },

  beforeMount() {
    this.getData()
  },

  methods: {
    ...mapActions({
      getNewItems: REQUEST_PURCHASES_VIP_STATUS
    }),
    newDatasets() {
      let datas = []
      let counter = 0
      const colors = [
        '#d9d9d9',
        '#6666ff',
        '#8cd98c',
        '#b3b3b3',
        '#ffcc00',
        '#ff9980',
        '#ff99cc',
        '#ff6666',
        '#ff944d',
        '#e6a5fa',
        '#4e99cc',
        '#7ab6bf'
      ]
      // const colors = this.purchasesBalls.color
      for (let el in this.purchasesVip) {
        if (el != 'keys' && el != 'total' && el != 'labels' && el != 'color') {
          let data = {
            borderColor: `${colors[counter]}`,
            backgroundColor: `${colors[counter]}`,
            fill: true,
            label: el,
            data: this.purchasesVip[el]
          }
          ++counter
          datas.push(data)
        }
      }
      return datas
    },
    getData() {
      this.statistic_purchases_vip.labels = this.purchasesVip.labels
      this.statistic_purchases_vip.datasets = this.newDatasets()
    },
    buttonPeriodClickHandler(event) {
      if (event.target.tagName.toLowerCase() !== 'button') {
        return false
      }

      this.button_period.map(el => {
        el.active = 0
        return el
      })
      if (event.target.dataset.type) {
        this.getNewItems({
          date_from: moment(this.datepicker_dates.date_from).format('YYYY-MM-DD'),
          date_to: moment(this.datepicker_dates.date_to).format('YYYY-MM-DD'),
          delta: new Date().getTimezoneOffset() / -60
        }).then(response => {
          this.getData()
        })
        return
      }

      if (!event.target.dataset.id) {
        return false
      }

      const button = this.button_period.find(el => el.id === +event.target.dataset.id)

      if (button.active) {
        return false
      }

      button.active = 1

      this.datepicker_dates.date_from = button.date.toString()
      this.datepicker_dates.date_to = button.date_to
        ? button.date_to.toString()
        : moment()
            .endOf('day')
            .toString()
      this.getNewItems({
        date_from: moment(this.datepicker_dates.date_from).format('YYYY-MM-DD'),
        date_to: moment(this.datepicker_dates.date_to).format('YYYY-MM-DD'),
        delta: new Date().getTimezoneOffset() / -60
      }).then(response => {
        this.getData()
      })
      this.$refs.traffic.start = new Date(this.datepicker_dates.date_from)
      this.$refs.traffic.end = new Date(this.datepicker_dates.date_to)
    }
  }
}
</script>
<style lang="scss">
@import '@/components/Events/Filters/EventFilters.scss';
@import './Chart.scss';
.chart {
  //width: 100%;
  margin: 10px 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  &-left {
    display: flex;
    align-items: center;
  }
}
.highcharts-series {
  max-width: 200px;
}

.graphic-title {
  font-size: 20px;
  align-self: center;
}

.highcharts-figure {
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 400px;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  &:first-child {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.btn-default {
  background-color: #bec3c7;
  border-color: #bec3c7;
  color: #fff;
}

.btn-danger {
  background-color: #ff6c60;
  border-color: #ff6c60;
  color: #fff;
}

.btn-white {
  background-clip: padding-box;
  border-color: rgba(150, 160, 180, 0.3);
  background-color: #fff;
}

.btn-primary {
  border-radius: 4px;
  background-color: #41cac0;
  border-color: #41cac0;
  color: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn.btn-danger.btn-white {
  color: #2a3542;
}

.conteiner-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
  &__name {
    width: 50%;
  }

  &__percen {
    text-align: right;
    width: 25%;
    font-weight: 700;
  }
  &__sum {
    text-align: right;
    width: 25%;
    font-weight: 700;
  }

  &__icon {
    margin-right: 5px;
  }
}

.tooltip {
  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 300px;
    height: 30px;
    align-items: center;
    border-bottom: 1px solid grey;
  }
  &__date {
    font-weight: 700;
    width: 50%;
  }
  &__percen {
    text-align: right;
    font-size: 10px;
    width: 25%;
  }

  &__plus {
    padding: 2px;
    border-radius: 4px;
    color: green;
    background-color: lightgreen;
    border: none;
  }
  &__minus {
    padding: 2px;
    border-radius: 4px;
    color: white;
    border: none;
    background-color: lightcoral;
  }

  &__null {
    padding: 2px;
    border-radius: 4px;
    text-align: right;
    color: grey;
  }

  &__all {
    text-align: right;
    width: 25%;
    font-weight: 700;
  }
}
.highcharts-credits {
  display: none;
}

.highcharts-container {
  width: 100%;
}

.highcharts-root {
  width: 100%;
}

//.highcharts-figure .highcharts-container {
//  width: calc(100% + 210px) !important;
//  transition: 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
//}

.highcharts-background {
  width: 100%;
}

.device-btn__wrapper {
  align-self: flex-end;
}

.user-active__btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-color: #57c8f2;
  background-color: #57c8f2;
  color: #fff;
  &--default {
    background-color: #bec3c7;
    border-color: #bec3c7;
  }
  &--primary {
    background-color: #39b2a9;
    border-color: #39b2a9;
  }
  &--danger {
    background-color: #ff6c60;
    border-color: #ff6c60;
  }
  &--right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &--left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &--center {
    border-radius: 0;
  }
}
</style>
