<template>
  <div class="user-counts">
    <ul class="user-counts__list">
      <li class="user-counts__item">
        <span class="user-counts__img user-counts__img--all"
          ><i class="user-counts__icon fas fa-users"></i
        ></span>
        <div class="user-counts__wrap">
          <span class="user-counts__number">{{ usersCount.all ? usersCount.all : '-' }}</span>
          <span class="user-counts__text">Всего пользователей</span>
        </div>
      </li>
      <li class="user-counts__item">
        <span class="user-counts__img user-counts__img--female"
          ><i class="user-counts__icon fas fa-female"></i
        ></span>
        <div class="user-counts__wrap">
          <span class="user-counts__number">{{ usersCount.female ? usersCount.female : '-' }}</span>
          <span class="user-counts__text">Женщины</span>
        </div>
      </li>
      <li class="user-counts__item">
        <span class="user-counts__img user-counts__img--male"
          ><i class="user-counts__icon fas fa-male"></i
        ></span>
        <div class="user-counts__wrap">
          <span class="user-counts__number">{{ usersCount.male ? usersCount.male : '-' }}</span>
          <span class="user-counts__text">Мужчины</span>
        </div>
      </li>
      <li class="user-counts__item">
        <span class="user-counts__img user-counts__img--operator"
          ><i class="user-counts__icon fas fa-street-view"></i
        ></span>
        <div class="user-counts__wrap">
          <span class="user-counts__number">{{ usersCount.operator ? usersCount.operator : '-' }}</span>
          <span class="user-counts__text">Операторов</span>
        </div>
      </li>
      <li class="user-counts__item" v-if="usersCount.unknown">
        <span class="user-counts__img user-counts__img--unknown"
          ><i class="user-counts__icon fa fa-question"></i
        ></span>
        <div class="user-counts__wrap">
          <span class="user-counts__number">{{ usersCount.unknown }}</span>
          <span class="user-counts__text">Неизвестный</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'StatisticUserCounts',
  props: {
    usersCount: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
.user-counts {
  display: flex;
  padding: 16px;
  color: #797979;
  &__list {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    // flex-wrap: wrap;
    @media (max-width: 1048px) {
      flex-wrap: wrap;
    }
  }
  &__item {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // min-width: 250px;
    // width: 19%;
    // border-radius: 4px;
    // min-height: 1px;
    // margin-right: 30px;
    // background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 200px;
    width: 24%;
    border-radius: 4px;
    min-height: 1px;
    margin-right: 20px;
    background-color: white;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 1048px) {
      margin-top: 10px;
      margin-right: 0;
      width: 100%;
    }
  }
  &__img {
    text-align: center;
    width: 40%;
    padding: 25px 15px;
    border-radius: 4px 0 0 4px;
    height: auto;
    &--all {
      background: #6ccac9;
    }
    &--female {
      background: #ff6c60;
    }
    &--male {
      background: #57c8f2;
    }
    &--operator {
      background: #e39726b8;
    }
    &--unknown {
      background: #9fb0ba;
    }
  }
  &__icon {
    color: #fff;
    font-size: 50px;
  }
  &__wrap {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__number {
    margin: 0;
    padding: 0;
    color: #c6cad6;
    font-weight: 300;
    font-size: 36px;
    align-items: center;
  }
  &__text {
    margin: 0;
    padding: 0;
    color: #c6cad6;
    text-align: center;
  }
}
</style>
