const REQUEST_ADD_CATEGORY_EVENT = 'REQUEST_ADD_CATEGORY_EVENT'
const REQUEST_UPDATE_CATEGORY_EVENT = 'REQUEST_UPDATE_CATEGORY_EVENT'
const REQUEST_DELETE_CATEGORY_EVENT = 'REQUEST_DELETE_CATEGORY_EVENT'
const REQUEST_ADD_SUBCATEGORY_EVENT = 'REQUEST_ADD_SUBCATEGORY_EVENT'
const REQUEST_UPDATE_SUBCATEGORY_EVENT = 'REQUEST_UPDATE_SUBCATEGORY_EVENT'
const REQUEST_DELETE_SUBCATEGORY_EVENT = 'REQUEST_DELETE_SUBCATEGORY_EVENT'
const REQUEST_ALL_CATEGORIES = 'REQUEST_ALL_CATEGORIES'
const REQUEST_ALL_EVENT_REFBOOK = 'REQUEST_ALL_EVENT_REFBOOK'
const REQUEST_ADD_EVENT_REFBOOK = 'REQUEST_ADD_EVENT_REFBOOK'
const REQUEST_UPDATE_EVENT_REFBOOK = 'REQUEST_UPDATE_EVENT_REFBOOK'
const REQUEST_DELETE_EVENT_REFBOOK = 'REQUEST_DELETE_EVENT_REFBOOK'
const REQUEST_EVENTS = 'REQUEST_EVENTS'
const REQUEST_ALL_EVENTS = 'REQUEST_ALL_EVENTS'
const REQUEST_DELETE_EVENT = 'REQUEST_DELETE_EVENT'
const REQUEST_PAGE_USERS = 'REQUEST_PAGE_USERS'
const REQUEST_EVENT_BY_ID = 'REQUEST_EVENT_BY_ID'
export {
  REQUEST_ADD_CATEGORY_EVENT,
  REQUEST_UPDATE_CATEGORY_EVENT,
  REQUEST_DELETE_CATEGORY_EVENT,
  REQUEST_ADD_SUBCATEGORY_EVENT,
  REQUEST_UPDATE_SUBCATEGORY_EVENT,
  REQUEST_DELETE_SUBCATEGORY_EVENT,
  REQUEST_ALL_CATEGORIES,
  REQUEST_ALL_EVENT_REFBOOK,
  REQUEST_ADD_EVENT_REFBOOK,
  REQUEST_UPDATE_EVENT_REFBOOK,
  REQUEST_DELETE_EVENT_REFBOOK,
  REQUEST_EVENTS,
  REQUEST_ALL_EVENTS,
  REQUEST_DELETE_EVENT,
  REQUEST_PAGE_USERS,
  REQUEST_EVENT_BY_ID
}
