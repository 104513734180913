<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Удалить авто сообщение?"
    modalClass="popup--small"
  >
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteMessage">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { AUTO_MESSAGE_DELETE_POPUP } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_DELETE_AUTO_MESSAGES } from '@/store/action-types'

export default {
  name: 'SupportContactTypeDelPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data() {
    return {
      id: '',
      lang: '',
      page: ''
    }
  },
  methods: {
    ...mapActions({
      deleteAutoMessage: REQUEST_DELETE_AUTO_MESSAGES
    }),
    closePopup() {
      this.id = ''
      this.close()
    },
    deleteMessage() {
      const data = {
        id: this.id,
        lang: this.lang,
        page: this.page
      }
      this.deleteAutoMessage(data)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(AUTO_MESSAGE_DELETE_POPUP, (value, lang, page) => {
      this.id = value
      this.lang = lang
      this.page = page
      this.open()
    })
  }
}
</script>
