export default {
  paymentsSetting: [],
  paymentDefaultSetting: {},
  moderators: [],
  moderatorUsers: [],
  currentModerator: {},
  currentModeratorUser: null,
  searchOptions: [],
  moderatorStatistic: {},
  moderatorPayout: [],
  currency: [
    {
      name: '$',
      value: 0,
      id: 0
    },
    {
      name: '€',
      value: 1,
      id: 1
    },
    {
      name: '₽',
      value: 2,
      id: 2
    },
    {
      name: '£',
      value: 3,
      id: 3
    }
  ],

  moderatorTmpRoles: {
    currency: {},
    status: {}
  },

  moderatorRoles: {
    currency: [
      {
        name: '$',
        value: 0,
        id: 0
      },
      {
        name: '€',
        value: 1,
        id: 1
      },
      {
        name: '₽',
        value: 2,
        id: 2
      },
      {
        name: '£',
        value: 3,
        id: 3
      }
    ],
    status: [
      {
        name: 'Все',
        value: -1,
        id: 0
      },
      {
        name: 'Активные',
        value: true,
        id: 1
      },
      {
        name: 'Неактивные',
        value: false,
        id: 2
      }
    ]
  },

  moderatorRolesDefault: {
    currency: {
      name: 'Выберите валюту',
      value: '999'
    },
    status: {
      name: 'Активные',
      value: true,
      id: 1
    }
  }
}
