<template>
  <div>
    <ul class="table-statistic__list">
      <li v-for="(stat, ind) in statistic" :key="ind">
        <ModeratorsStatisticRate :stat="stat" />
        <ModeratorsStatisticTableHeader />
        <div class="table-statistic__row" v-for="(date, i) in stat.data" :key="i">
          <div class="table-statistic__item table-statistic__item--more-left">
            {{ isDate(i) }} ({{ dateStr(i) }})
          </div>
          <!-- Отложено -->
          <div class="table-statistic__item table-statistic__item--more">
            <span
              >Фото:
              <span :class="{ 'table-statistic__item--orange': date.photo.deferred }">{{
                date.photo.deferred
              }}</span></span
            >
            <span
              >Видео:
              <span :class="{ 'table-statistic__item--orange': date.video.deferred }">{{
                date.video.deferred
              }}</span></span
            >
          </div>
          <!-- Сброшено -->
          <!-- <div class="table-statistic__item table-statistic__item--more">
            <span :class="{ 'table-statistic__item--grey': date.reset }">{{ date.reset }}</span>
          </div> -->
          <!-- Временная блокировка -->
          <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category"
              >Фото:
              <span :class="{ 'table-statistic__item--blue': date.photo.blocked_photo }">{{
                date.photo.blocked_photo
              }}</span></span
            >
            <span
              >Видео:
              <span :class="{ 'table-statistic__item--blue': date.video.blocked_video }">{{
                date.video.blocked_video
              }}</span></span
            >
          </div>
          <!-- Одобрено -->
          <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category"
              >Фото гл.:
              <span :class="{ 'table-statistic__item--green': date.photo.accept_main }">{{
                date.photo.accept_main
              }}</span></span
            >
            <span class="table-statistic__item-category"
              >Фото вн.:
              <span :class="{ 'table-statistic__item--green': date.photo.accept_inner }">{{
                date.photo.accept_inner
              }}</span></span
            >
            <span class="table-statistic__item-category"
              >Видео гл.:
              <span :class="{ 'table-statistic__item--green': date.video.accept_main }">{{
                date.video.accept_main
              }}</span></span
            >
          </div>
          <!-- Запр. для аватара -->
          <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category"
              >Фото гл.:
              <span :class="{ 'table-statistic__item--red': date.photo.forbidden_ava_main }">{{
                date.photo.forbidden_ava_main
              }}</span></span
            >
            <span class="table-statistic__item-category"
              >Фото вн.:
              <span :class="{ 'table-statistic__item--red': date.photo.forbidden_ava_inner }">{{
                date.photo.forbidden_ava_inner
              }}</span></span
            >
            <span class="table-statistic__item-category"
              >Видео гл.:
              <span :class="{ 'table-statistic__item--red': date.video.forbidden_ava_main }">{{
                date.video.forbidden_ava_main
              }}</span></span
            >
            <span class="table-statistic__item-category"
              >Запр. для показа:
              <span :class="{ 'table-statistic__item--red': date.photo.forbidden_ava_main }">{{
                date.photo.forbidden_ava_main
              }}</span></span
            >
          </div>
          <!-- Забананено фото -->
          <!-- <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category"
              >По аккаунту гл.:
              <span :class="{ 'table-statistic__item--red': date.photo.banned_acc_main }">{{
                date.photo.banned_acc_main
              }}</span></span
            >
            <span class="table-statistic__item-category"
              >По девайсу гл.:
              <span :class="{ 'table-statistic__item--red': date.photo.banned_dev_main }">{{
                date.photo.banned_dev_main
              }}</span></span
            >
            <span class="table-statistic__item-category"
              >По аккаунту вн.:
              <span :class="{ 'table-statistic__item--red': date.photo.banned_acc_inner }">{{
                date.photo.banned_acc_inner
              }}</span></span
            >
            <span class="table-statistic__item-category"
              >По девайсу вн.:
              <span :class="{ 'table-statistic__item--red': date.photo.banned_dev_inner }">{{
                date.photo.banned_dev_inner
              }}</span></span
            >
          </div> -->
          <!-- Забананено видео -->
          <!-- <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category"
              >По аккаунту гл.:
              <span :class="{ 'table-statistic__item--red': date.video.banned_acc_main }">{{
                date.video.banned_acc_main
              }}</span></span
            >
            <span class="table-statistic__item-category"
              >По девайсу гл.:
              <span :class="{ 'table-statistic__item--red': date.video.banned_dev_main }">{{
                date.video.banned_dev_main
              }}</span></span
            >
            <span class="table-statistic__item-category"
              >По аккаунту вн.:
              <span :class="{ 'table-statistic__item--red': date.video.banned_acc_inner }">{{
                date.video.banned_acc_inner
              }}</span></span
            >
            <span class="table-statistic__item-category"
              >По девайсу вн.:
              <span :class="{ 'table-statistic__item--red': date.video.banned_dev_inner }">{{
                date.video.banned_dev_inner
              }}</span></span
            >
          </div> -->
          <!-- Забананено фото вн. -->
          <!-- <div class="table-statistic__item table-statistic__item--more">
            <span
              >По аккаунту вн.:
              <span :class="{ 'table-statistic__item--red': date.photo.banned_acc_inner }">{{
                date.photo.banned_acc_inner
              }}</span></span
            >
            <span
              >По девайсу вн.:
              <span :class="{ 'table-statistic__item--red': date.photo.banned_dev_inner }">{{
                date.photo.banned_dev_inner
              }}</span></span
            >
          </div> -->
          <!-- Забананено -->
          <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category"
              >По аккаунту:
              <span :class="{ 'table-statistic__item--red': date.banned_acc_video }">{{
                date.banned_acc_video
              }}</span></span
            >
            <span class="table-statistic__item-category"
              >По девайсу:
              <span :class="{ 'table-statistic__item--red': date.banned_dev_video }">{{
                date.banned_dev_video
              }}</span></span
            >
          </div>
          <!-- Запр. для показа -->
          <!-- <div class="table-statistic__item table-statistic__item--more">
            <span :class="{ 'table-statistic__item--red': date.photo.forbidden_show }">{{
              date.photo.forbidden_show
            }}</span>
          </div> -->
          <!-- События -->
          <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category"
              >Отклоненно:
              <span :class="{ 'table-statistic__item--red': date.event.reject_event_check }">{{
                date.event.reject_event_check
              }}</span>
            </span>
            <span class="table-statistic__item-category"
              >Принято:
              <span :class="{ 'table-statistic__item--green': date.event.approve_event_check }">{{
                date.event.approve_event_check
              }}</span>
            </span>
            <span class="table-statistic__item-category"
              >Редактирование:
              <span
                class="table-statistic__item-category"
                :class="{ 'table-statistic__item--grey': date.event.edited_event_check }"
                >{{ date.event.edited_event_check }}</span
              >
            </span>
          </div>
          <!-- Оплата в главной -->
          <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category">Фото : {{ date.paid_main_photo }}</span>
            <span class="table-statistic__item-category">Видео : {{ date.paid_main_video }}</span>
          </div>
          <!-- Оплата во внутренней -->
          <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category">Фото: {{ date.paid_inner_photo }}</span>
            <span class="table-statistic__item-category">Видео: {{ date.paid_inner_video }}</span>
          </div>
          <!-- Оплата за баны фото -->
          <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category">По аккаунту: {{ date.banned_acc_photo }}</span>
            <span class="table-statistic__item-category">По девайсу: {{ date.banned_dev_photo }}</span>
          </div>
          <!-- Оплата за баны видео -->
          <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category">По аккаунту: {{ date.banned_acc_video }}</span>
            <span class="table-statistic__item-category">По девайсу: {{ date.banned_dev_video }}</span>
          </div>
          <!-- Оплата за баны -->
          <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category">Фото: {{ date.paid_ban_photo }}</span>
            <span class="table-statistic__item-category">Видео: {{ date.paid_ban_video }}</span>
          </div>
          <!-- Оплата событий -->
          <!-- <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category">Проверка: {{ date.paid_events_check }}</span>
            <span class="table-statistic__item-category">Редактирование: {{ date.paid_events_edited }}</span>
          </div> -->
          <!-- Оплата общая -->
          <div class="table-statistic__item table-statistic__item--more" v-for="el in stat.rates" :key="el">
            <span class="table-statistic__item-category"
              >Главная: {{ el.main_photo_price.toFixed(2) }}р.</span
            >
            <span class="table-statistic__item-category">Внутренняя: {{ el.inner_photo_price }}р.</span>
            <span class="table-statistic__item-category">Видео гл.: {{ el.main_video_price }}р.</span>
            <span class="table-statistic__item-category">Пр. событий: {{ el.event_price }}р.</span>
            <span class="table-statistic__item-category">Ред. событий: {{ el.event_edited_price }}р.</span>
            <span class="table-statistic__item-category">Баны: {{ el.ban_price.toFixed(2) }}р.</span>
          </div>
          <div class="table-statistic__item table-statistic__item--more">
            <span class="table-statistic__item-category"
              >Главная: {{ calcPayout(date.paid_main_photo, date.paid_main_video) }}</span
            >
            <span class="table-statistic__item-category"
              >Внутренняя: {{ calcPayout(date.paid_inner_photo, date.paid_inner_video) }}</span
            >
            <span class="table-statistic__item-category">Пр. событий: {{ date.paid_events_check }}</span>
            <span class="table-statistic__item-category">Ред. событий: {{ date.paid_events_edited }}</span>
            <span class="table-statistic__item-category"
              >Баны: {{ calcPayout(date.paid_ban_photo, date.paid_ban_video) }}</span
            >
          </div>
        </div>
        <!-- <ModeratorStatisticTotal :stat="stat" /> -->
      </li>
    </ul>
  </div>
</template>

<script>
// <span class="table-statistic__item table-statistic__item--more">
//             {{ date.photo.deferred }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.reset }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.photo.accept_main }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.photo.accept_inner }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.video.accept_main }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.video.deferred }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.video.forbidden_ava_main }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.banned_acc_video }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.banned_dev_video }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.photo.forbidden_ava_main }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.photo.forbidden_ava_inner }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.photo.forbidden_show }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.photo.blocked_photo }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.paid_main_photo }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.paid_inner_video }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.banned_acc_photo }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.banned_dev_photo }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.photo.banned_acc_main }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.photo.banned_dev_main }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.photo.banned_acc_inner }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.photo.banned_dev_inner }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.paid_ban_photo }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.event.reject_event_check }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.event.approve_event_check }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.event.edited_event_check }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.paid_events_check }}
//           </span>
//           <span class="table-statistic__item table-statistic__item--more">
//             {{ date.paid_events_edited }}
//           </span>

import moment from 'moment'
import ModeratorsStatisticTableHeader from '@/components/Moderators/Statistic/ModeratorsStatisticTableHeader'
import ModeratorsStatisticRate from '@/components/Moderators/Statistic/ModeratorsStatisticRate'
import ModeratorStatisticTotal from '@/components/Moderators/Statistic/ModeratorStatisticTotal'
export default {
  name: 'ModeratorsStatistic',
  components: {
    ModeratorsStatisticTableHeader,
    ModeratorsStatisticRate,
    ModeratorStatisticTotal
  },
  props: {
    statistic: {
      type: Array
    }
  },
  // computed: {
  //   calcMain: function() {
  //     let result =
  //       this.date.photo.accept_main +
  //       this.date.video.accept_main +
  //       this.date.photo.forbidden_ava_main +
  //       this.date.video.forbidden_ava_main +
  //       this.date.photo.banned_acc_main +
  //       this.date.photo.banned_dev_main +
  //       this.date.video.banned_acc_main +
  //       this.date.video.banned_dev_main
  //     return result.toFixed(2)
  //   }
  // },
  methods: {
    dateStr(date) {
      let numberDay = moment(date).day()
      switch (numberDay) {
        case 0:
          return 'вс'
        case 1:
          return 'пн'
        case 2:
          return 'вт'
        case 3:
          return 'ср'
        case 4:
          return 'чт'
        case 5:
          return 'пт'
        case 6:
          return 'сб'
        default:
          break
      }
    },
    calcPayout(main, inner) {
      let result = main + inner
      return result
    },
    isDate(date) {
      return date
        .split('-')
        .reverse()
        .join('.')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'ModeratorStatistic';
.table-statistic__total-row {
  border-top: 1px solid #ddd;
  background-color: #d9edf7;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.table-statistic__total-text {
  font-size: 13px;
  margin-right: 5px;
  color: #444;
  font-weight: 700;
}
.table-statistic {
  &__item {
    font-size: 12px;
    display: flex;
    // width: 7%;
    // align-items: center;
    // flex-direction: column;
    padding: 10px;
    &-category {
      margin-bottom: 4px;
    }
    &--more {
      align-items: flex-start;
      flex-direction: column;
      text-align: left;
      font-weight: 600;
      // &:nth-child(2n) {
      //   color: #2700ff;
      // }
    }
    &--orange {
      color: #eea83e;
      font-weight: 700;
    }
    &--grey {
      color: #686868;
      font-weight: 700;
    }
    &--green {
      color: #5cda46;
      font-weight: 700;
    }
    &--red {
      color: #ff0000;
      font-weight: 700;
    }
    &--blue {
      color: #22d5e6;
      font-weight: 700;
    }
    &--more-left {
      width: 6%;
      align-items: flex-start;
      font-weight: 600;
    }
    &--wrapp {
      display: flex;
      flex-direction: column;
    }
    &--more-rate {
      width: 11%;
      align-items: flex-start;
    }
    .rate-title {
      color: rgb(3, 3, 36);
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
}
</style>
