<template>
  <div class="search-header">
    Список пользователей
    <ul class="table-header search-header">
      <li class="search-header__item-event">
        <span class="table-header__item-text">Информация об аккаунте</span>
      </li>
      <li class="search-header__item-event">
        <span class="table-header__item-text">Личный поиск</span>
      </li>
      <li class="search-header__item-event">
        <span class="table-header__item-text">Пол</span>
        <span class="table-header__item-text">Возраст от</span>
        <span class="table-header__item-text">Возраст до</span>
        <span class="table-header__item-text">Страна поиска</span>
        <span class="table-header__item-text">Язык</span>
        <span class="table-header__item-text">Локация</span>
      </li>
      <li class="search-header__item-event">
        <span class="table-header__item-text">Напишут пользователю-мужчине</span>
      </li>
      <li class="search-header__item-event"></li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'SearchOperatorHeader'
}
</script>
<style lang="scss">
@import 'Operators';
.table-header__item-text {
  width: 25%;
}
</style>
