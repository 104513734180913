<template>
  <div
    :class="{
      'user-chat_active': chatMessages.length
    }"
  >
    <div class="chat-body" v-if="chatMessages.length">
      <div class="chat-header">
        <span class="chat-header__text">Диалог</span>
        <span class="chat-header__text"
          >Дата начала чата: {{ selectedChat.create ? toLocaleDateUser(selectedChat.create) : '-' }}</span
        >
        <span class="chat-header__text"
          >Продолжительность чата: {{ messagesChat.length && selectedChat.create ? durationChat : '-' }}</span
        >
      </div>
      <!-- @scroll="scroll($event) -->
      <div class="chat" ref="viewScrollContent">
        <ul ref="chat" class="chat__list">
          <li v-for="message in messagesChat" :key="message.message_id" ref="messageItem">
            <Message :message="message" :selectedChat="selectedChat" :currentUser="currentUser" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import dateFormat from '@/components/mixins/toLocaleformat'
import { USER_MAP_EVENT } from '@/constants/event'
import { REQUEST_CHAT_OLD_MESSAGES } from '@/store/action-types'
import { SET_USERCHATS_STYLE } from '@/store/mutation-types'
import { GET_USERCHATS_STYLES, GET_CHAT_MESSAGES } from '@/store/getter-types'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Message from '@/components/App-user/Message/Message'

export default {
  name: 'AppUserChat',
  mixins: [dateFormat],
  components: {
    Message
  },
  props: {
    messagesChat: {
      type: Array
    },
    currentUser: {
      type: Object
    },
    selectedChat: {
      type: Object
    }
  },
  data: () => ({
    USER_MAP_EVENT,
    soundStart: false,
    loadDataByScroll: true,
    loadingChat: false,
    chatActive: false,
    link: `${process.env.VUE_APP_DEPLOY_URL}`
  }),
  computed: {
    ...mapGetters({
      stylesChat: GET_USERCHATS_STYLES,
      chatMessages: GET_CHAT_MESSAGES
    }),
    durationChat() {
      let duration = (
        (+Date.parse(this.messagesChat[this.messagesChat.length - 1].create) / 1000 -
          +Date.parse(this.selectedChat.create) / 1000) /
        60
      ).toFixed(0)
      return `${duration} минут`
    }
  },

  beforeMount() {
    setTimeout(() => {
      this.$nextTick(() => {
        if (this.$refs.viewScrollContent) {
          const list = this.$refs.viewScrollContent
          this.$refs.viewScrollContent.scrollTo({
            top: this.$refs.viewScrollContent.scrollHeight - this.$refs.viewScrollContent.offsetHeight,
            behavior: 'smooth'
          })
        }
      })
    }, 1000)
    if (this.selectedChat) {
      let data = {
        isOpened: true
      }
      //   const design = {
      //     windowChat: {
      //       backgroundColor: '#fff',
      //       borderRadius: '4px',
      //       fontSize: '13px',
      //       width: '53%',
      //       padding: '16px',
      //       margin: '0 0 0 auto',
      //       position: 'absolute',
      //       right: '16px',
      //       top: '92%',
      //       height: '64vh'
      //     },
      //     photoLine: {
      //       backgroundColor: '#fff',
      //       borderRadius: '4px',
      //       marginBottom: '8px',
      //       padding: '16px',
      //       width: '45%'
      //     },
      //     heightChat: {
      //       height: '750px'
      //     }
      //   }
      this.setStyles(data)
    }
  },
  methods: {
    ...mapActions({
      newChatMessage: REQUEST_CHAT_OLD_MESSAGES
    }),
    ...mapMutations({
      setStyles: SET_USERCHATS_STYLE
    })
    // scroll(event) {
    //   const list = this.$refs.viewScrollContent;
    //   const scrollBottom =
    //     list.scrollHeight - (list.scrollTop + list.offsetHeight);
    //   const scrollTop = list.scrollTop;

    //   const firstNodeOffset = this.$refs.messageItem[0].offsetTop;
    //   const firstNodeScrollHeight = this.$refs.messageItem[0].scrollHeight;

    //   if (
    //     scrollTop + firstNodeOffset <
    //     firstNodeOffset + firstNodeScrollHeight
    //   ) {
    //     // const firstMessageCreateDate = Date.parse(this.messagesChat[0].create);
    //     const loadOldChat = {
    //       id: this.selectedChat.chat_id,
    //       user: this.currentUser.id,
    //       limit: 10,
    //       start: 0,
    //       message_date: +Date.parse(this.messagesChat[this.messagesChat.length - 1].create)
    //     };
    //     if (this.loadDataByScroll && !this.loadingChat) {
    //       this.loadingChat = true;
    //       this.newChatMessage(loadOldChat)
    //       this.newChatMessage(loadOldChat).then(response => {
    //         if (response.data.length === 0) {
    //           this.loadDataByScroll = false;
    //         }
    //         this.$nextTick(() => {
    //           list.scrollTo({
    //             top: list.scrollHeight - (list.offsetHeight + scrollBottom)
    //           });
    //           this.loadingChat = false;
    //         });
    //       });
    //     }
    //   }
    // },
  }
}
</script>
<style lang="scss">
@import './AppUserChat.scss';
.app-user-map__message {
  margin-top: 10px;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1750px) {
    margin-right: 0;
    margin-bottom: 8px;
  }
  @media (max-width: 1020px) {
    min-width: 100%;
  }
}
.chat__message-audio {
  font-size: 30px;
  align-items: center;
}
.chat__message-download {
  text-decoration: none;
  color: inherit;
}
.no-img {
  min-width: 300px;
  min-height: 150px;
  background-color: white;
}
</style>
