<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Совершить выплату оператору?"
    modalClass="popup--small"
  >
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="payHandler()" :disabled="isLoading">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_EXECUTE_OPERATORS } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { PAY_OPERATOR_POPUP_EVENT } from '@/constants/event'
import { GET_OPERATOR_TMP_ROLES } from '@/store/getter-types'

export default {
  name: 'PayOperatorPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    PAY_OPERATOR_POPUP_EVENT,
    operatorId: null,
    pay: null,
    isLoading: false
  }),
  computed: {
    ...mapGetters({
      tmpFilter: GET_OPERATOR_TMP_ROLES
    })
  },

  methods: {
    ...mapActions({
      payOperator: REQUEST_EXECUTE_OPERATORS
    }),
    closePopup() {
      this.operatorId = null
      this.isLoading = false
      this.close()
    },
    payHandler() {
      this.isLoading = true
      let formData = {
        data: {
          operator_id: this.operatorId,
          pay_id: this.pay
        },
        active: null
      }

      if (this.tmpFilter.status.name && this.tmpFilter.status.name !== 'Все') {
        formData.active = {
          is_active: this.tmpFilter.status.value
        }
      }
      this.payOperator(formData).then(response => {
        this.closePopup()
      })
    }
  },

  mounted() {
    this.$root.$on(PAY_OPERATOR_POPUP_EVENT, data => {
      this.operatorId = data.operator
      this.pay = data.pay
      this.open()
    })
  }
}
</script>
