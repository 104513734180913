const REQUEST_GET_ALL_GIFTS = 'REQUEST_GET_ALL_GIFTS'
const REQUEST_DELETE_GIFTS_BY_ID = 'REQUEST_DELETE_GIFTS_BY_ID'
const REQUEST_CREATE_NEW_GIFTS = 'REQUEST_CREATE_NEW_GIFTS'
const REQUEST_UPDATE_GIFTS_BY_ID = 'REQUEST_UPDATE_GIFTS_BY_ID'

export {
  REQUEST_GET_ALL_GIFTS,
  REQUEST_DELETE_GIFTS_BY_ID,
  REQUEST_CREATE_NEW_GIFTS,
  REQUEST_UPDATE_GIFTS_BY_ID
}
