const REQUEST_APPLY_FILTERS = 'REQUEST_APPLY_FILTERS'
const REQUEST_ID_USER = 'REQUEST_ID_USER'
const REQUEST_USER_VERIFICATION = 'REQUEST_USER_VERIFICATION'
const REQUEST_GET_VERIFY_AGE = 'REQUEST_GET_VERIFY_AGE'
const REQUEST_BLOCK_MAIN_PHOTO = 'REQUEST_BLOCK_MAIN_PHOTO'
const REQUEST_UPDATE_VIP = 'REQUEST_UPDATE_VIP'
const REQUEST_UPDATE_VIP_CALL = 'REQUEST_UPDATE_VIP_CALL'
const REQUEST_BAN_USER = 'REQUEST_BAN_USER'
const REQUEST_DELETE_USER = 'REQUEST_DELETE_USER'
const REQUEST_UPDATE_POINTS = 'REQUEST_UPDATE_POINTS'
const REQUEST_HIDE_USER_APP = 'REQUEST_HIDE_USER_APP'
const REQUEST_HIDE_USER_ADMIN = 'REQUEST_HIDE_USER_ADMIN'
const REQUEST_UPDATE_RATING = 'REQUEST_UPDATE_RATING'
const REQUEST_UPDATE_GENDER = 'REQUEST_UPDATE_GENDER'
const REQUEST_USER_DEPOSIT = 'REQUEST_USER_DEPOSIT'
const REQUEST_USER_PAYOUTS = 'REQUEST_USER_PAYOUTS'
const REQUEST_USER_TRANSACTION_KIND = 'REQUEST_USER_TRANSACTION_KIND'
const REQUEST_USER_PHOTOS = 'REQUEST_USER_PHOTOS'
const REQUEST_USER_CHATS = 'REQUEST_USER_CHATS'
const REQUEST_CHAT_MESSAGES = 'REQUEST_CHAT_MESSAGES'
const REQUEST_DELETE_VIP = 'REQUEST_DELETE_VIP'
const REQUEST_CHAT_OLD_MESSAGES = 'REQUEST_CHAT_OLD_MESSAGES'
const REQUEST_DELETE_PHOTO = 'REQUEST_DELETE_PHOTO'
const REQUEST_FILTER_UTM = 'REQUEST_FILTER_UTM'
const REQUEST_VISIBLE_MAP_USER = 'REQUEST_VISIBLE_MAP_USER'
const REQUEST_ALL_USERS = 'REQUEST_ALL_USERS'
const REQUEST_USER_PURCHASES = 'REQUEST_USER_PURCHASES'
const REQUEST_UPDATE_USER_PHOTO_STATUS = 'REQUEST_UPDATE_USER_PHOTO_STATUS'
const REQUEST_SEND_ALERT_TO_USER = 'REQUEST_SEND_ALERT_TO_USER'

export {
  REQUEST_APPLY_FILTERS,
  REQUEST_ID_USER,
  REQUEST_USER_VERIFICATION,
  REQUEST_BLOCK_MAIN_PHOTO,
  REQUEST_GET_VERIFY_AGE,
  REQUEST_UPDATE_VIP,
  REQUEST_UPDATE_VIP_CALL,
  REQUEST_BAN_USER,
  REQUEST_DELETE_USER,
  REQUEST_UPDATE_POINTS,
  REQUEST_HIDE_USER_APP,
  REQUEST_SEND_ALERT_TO_USER,
  REQUEST_HIDE_USER_ADMIN,
  REQUEST_UPDATE_RATING,
  REQUEST_UPDATE_GENDER,
  REQUEST_USER_DEPOSIT,
  REQUEST_USER_TRANSACTION_KIND,
  REQUEST_USER_PAYOUTS,
  REQUEST_USER_PHOTOS,
  REQUEST_USER_CHATS,
  REQUEST_CHAT_MESSAGES,
  REQUEST_DELETE_VIP,
  REQUEST_CHAT_OLD_MESSAGES,
  REQUEST_DELETE_PHOTO,
  REQUEST_FILTER_UTM,
  REQUEST_VISIBLE_MAP_USER,
  REQUEST_ALL_USERS,
  REQUEST_USER_PURCHASES,
  REQUEST_UPDATE_USER_PHOTO_STATUS
}
