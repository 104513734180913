<template>
  <FullCalendar :ref="index" :options="getUpdateOptions()" />
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import {
  REQUEST_CALENDAR_EVENTS,
  REQUEST_ADD_CALENDAR_EVENT,
  REQUEST_ADD_MODERATOR_EVENT,
  REQUEST_SHIFT_OPTIONS,
  REQUEST_NEW_SHIFT_OPTIONS,
  REQUEST_DELL_MODERATOR_EVENT,
  REQUEST_CHANGE_OPERATOR_EVENT,
  REQUEST_MOD_OPERATOR_EVENTS
} from '@/store/action-types'

import { GET_CALENDAR_EVENTS, GET_PERMISSIONS } from '@/store/getter-types'
import { CALENDAR_ADD_POPUP, CALENDAR_EDIT_POPUP, CALENDAR_ERROR_POPUP } from '@/constants/event'

export default {
  name: 'CalendarItem',
  components: {
    FullCalendar
  },
  props: {
    index: {
      type: String
    },
    week: {
      type: Object
    },
    events: {
      type: Array
    },
    newOptions: {
      type: Object
    },
    moderators: {
      type: Array
    },
    start_calendar: {},
    end_calendar: {}
  },
  data() {
    return {
      CALENDAR_ADD_POPUP,
      CALENDAR_EDIT_POPUP,
      CALENDAR_ERROR_POPUP,
      eventDrag: null,
      movingEl: null,
      eventDropElement: null,
      errorDrag: false,
      updateOptions: {},
      options: {
        events: [],
        plugins: [timeGridPlugin, interactionPlugin, momentTimezonePlugin, interactionPlugin],
        visibleRange: {
          start: '',
          end: ''
        },
        height: 'auto',
        headerToolbar: { left: '', center: '', right: '' },
        initialView: 'timeGrid',
        locale: 'ru',
        firstDay: 1,
        fixedWeekCount: false,
        dayMaxEventRows: true,
        dayCellClassNames: this.dayRenderHandler,
        views: {
          timeGrid: {
            dayMaxEventRows: 4,
            showNonCurrentDates: false,
            dayHeaderFormat: {
              weekday: 'short',
              month: 'short',
              day: 'numeric'
            },
            slotLabelFormat: this.slotLabelFormatHandler,
            titleFormat: {
              month: 'long',
              year: 'numeric',
              day: 'numeric',
              omitCommas: false
            }
          }
        },
        slotLabelInterval: { hours: 1 },
        defaultTimedEventDuration: { hours: 1 },
        slotDuration: { hours: 1 },
        snapDuration: { hours: 1 },
        rerenderDelay: 10,
        eventResize: this.handleEventResize,
        eventDurationEditable: true,
        eventStartEditable: true,
        forceEventDuration: true,
        allDaySlot: false,
        nowIndicator: false,
        selectable: true,
        editable: true,
        displayEventEnd: true,
        displayEventTime: true,
        droppable: true,
        drop: this.dropHandler,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        datesSet: this.eventDatesRenderHandler,
        dayMaxEvents: 50,
        eventClassNames: 'eventHeight',
        aspectRatio: 4,
        eventMinHeight: 'auto',
        eventDragMinDistance: 5,
        eventDrop: this.handledrop,
        eventOverlap: this.handleeventOverlap,
        eventDragStart: this.eventDragStartHandle,
        eventReceive: this.eventReceiveHandler,
        eventAdd: function(addInfo) {
          addInfo.revert()
        },
        eventLeave: this.eventLeaveHandle,
        eventContent: function(arg) {
          let arrayOfDomNodes = []
          arg.event.extendedProps.operators.map(operator => {
            let italicIcon = document.createElement('i')
            italicIcon.className = 'fa fa-user user-icon'
            italicIcon.style.color = `${operator.color}`
            let time = document.createElement('p')
            time.className = 'event__range-time'
            time.innerHTML = `${arg.timeText}`
            let italicEl = document.createElement('i')
            italicEl.className = 'italicEl'
            italicEl.innerHTML = `${operator.first_name} ${operator.last_name}`
            return arrayOfDomNodes.push(italicIcon, italicEl, time)
          })
          return { domNodes: arrayOfDomNodes }
        },
        eventDidMount: function(arg) {
          arg.el.style.borderTopColor = arg.event.extendedProps.operators[0].color
          if (!arg.isFuture) {
            arg.el.classList.add('fc-event-past')
          } else {
            const now = moment()
            if (now.unix() > moment(arg.event.start).unix()) {
              if (
                now.unix() >= moment(arg.event.start).unix() &&
                now.unix() <= moment(arg.event.end).unix()
              ) {
                arg.el.classList.add('fc-event-active')
              } else {
                arg.el.classList.add('fc-event-past')
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      allEvents: GET_CALENDAR_EVENTS,
      permissions: GET_PERMISSIONS
    })
  },
  methods: {
    ...mapActions({
      getAllEvents: REQUEST_CALENDAR_EVENTS,
      addEventCalendar: REQUEST_ADD_CALENDAR_EVENT,
      addModerator: REQUEST_ADD_MODERATOR_EVENT,
      getOptions: REQUEST_SHIFT_OPTIONS,
      changeOptions: REQUEST_NEW_SHIFT_OPTIONS,
      delEventCalendar: REQUEST_DELL_MODERATOR_EVENT,
      changeOperator: REQUEST_CHANGE_OPERATOR_EVENT,
      modOperatorEvent: REQUEST_MOD_OPERATOR_EVENTS
    }),
    getUpdateOptions() {
      this.options.visibleRange = this.week
      this.options.events = this.events
      this.options.slotLabelInterval.hours = this.newOptions.shift_time_period
      this.options.defaultTimedEventDuration.hours = this.newOptions.shift_time_period
      this.options.slotDuration.hours = this.newOptions.shift_time_period
      this.options.snapDuration.hours = this.newOptions.shift_time_period
      return this.options
    },
    slotLabelFormatHandler(date) {
      const leadZero = '0'
      const midnightZero = '00'

      let hourSince = date.date.hour,
        hourTill = hourSince + this.newOptions.shift_time_period

      if (hourSince < 10) hourSince = leadZero + hourSince
      if (hourTill < 10) hourTill = leadZero + hourTill

      if (midnightZero && hourTill > 23) hourTill = midnightZero

      return `${hourSince}⁰⁰ - ${hourTill}⁰⁰`
    },
    dayRenderHandler(arg) {
      if (!arg.isFuture) {
        return 'fc-day-disabled'
      }
      return ''
    },
    // Клик на пустую дату
    handleDateSelect(selectInfo) {
      let today = moment()
      if (selectInfo.start > today) {
        const moderatorList = this.moderators
        const params = {
          start: moment(this.start_calendar).format('YYYY-MM-DDTHH:MM:SS'),
          end: moment(this.end_calendar).format('YYYY-MM-DDTHH:MM:SS')
        }
        this.$root.$emit('CALENDAR_ADD_POPUP', {
          selectInfo,
          moderatorList,
          params
        })
      } else {
        let causeError = 'periodNotAvailable'
        this.$root.$emit('CALENDAR_ERROR_POPUP', { selectInfo, causeError })
      }
    },
    // Клик на событие
    handleEventClick(info) {
      let today = moment()
      if (info.event.start > today) {
        const currentEvent = info.event
        const moderatorList = this.moderators
        this.$root.$emit('CALENDAR_EDIT_POPUP', {
          currentEvent,
          moderatorList
        })
      } else {
        let causeError = 'cantChangeCurrentEvent'
        this.$root.$emit('CALENDAR_ERROR_POPUP', { info, causeError })
      }
    },

    getDateformat(date) {
      // const dateFirst = date.slice(0, 2);
      // const dateMiddle = date.slice(3, 5);
      // const dateLast = date.slice(6, 10);
      // const dateLost = date.slice(12, 20);
      // let formatDate = `${dateLast}-${dateFirst}-${dateMiddle} ${dateLost}`;
      return date.slice(0, -7).replace('T', ' ')
    },
    // присвоение цвета внутри событий
    getColorEvent(operators) {
      let colorEmp = []
      operators.map(m => {
        let moderatorColor = this.moderators.find(f => f.id === m.id)
        if (moderatorColor) {
          m.color = moderatorColor.color
        } else {
          m.color = '#000000'
        }
        colorEmp.push(m)
      })
      return colorEmp
    },
    handleEventResize(e) {
      setTimeout(() => {
        var da = e.el.fcSeg
        let start = da.start.toISOString()
        let end = da.end.toISOString()
        if (start && end) {
          const event = {
            schedule_id: e.event._def.publicId,
            start: start.slice(0, -5),
            end: end.slice(0, -5)
          }
          this.modOperatorEvent(event).then(res => {
            if (res.status) {
              this.getEvents()
            } else {
              e.revert()
            }
          })
        }
      }, 100)
    },
    // событие сьрасывания элемента из вне, стоит проверка на календарные события, т.к. для них отдельная функция
    dropHandler(selectInfo) {
      if (!this.eventDrag && !this.movingEl) {
        let today = moment()
        if (selectInfo.date > today) {
          const start_date = moment(selectInfo.dateStr).format('YYYY-MM-DD HH:mm:ss')
          let repeatEvent = {
            scheduleId: null,
            userId: {
              operator_id: null
            },
            moderatorList: []
          }
          this.options.events.map(event => {
            if (start_date === event.start) {
              repeatEvent.scheduleId = event.id
              repeatEvent.moderatorList = event.extendedProps.operators
            }
          })
          if (repeatEvent.scheduleId) {
            if (repeatEvent.moderatorList.find(x => x.id === selectInfo.draggedEl.dataset.id)) {
              let causeError = 'repeatModerator'
              this.$root.$emit('CALENDAR_ERROR_POPUP', {
                selectInfo,
                causeError
              })
            } else {
              repeatEvent.userId.operator_id = selectInfo.draggedEl.dataset.id
              this.addModerator(repeatEvent).then(response => {
                this.$emit('my-event')
              })
            }
          } else {
            const formData = {
              operator_id: selectInfo.draggedEl.parentNode.dataset.id,
              date: start_date
            }
            if (formData.operator_id) {
              this.addEventCalendar(formData).then(response => {
                this.$emit('my-event')
              })
            }
          }
        } else {
          let causeError = 'isNotCorrectDate'
          this.$root.$emit('CALENDAR_ERROR_POPUP', { selectInfo, causeError })
        }
      } else {
        this.$emit('my-event')
        // const start_date = moment(selectInfo.dateStr).format(
        //     "YYYY-MM-DD HH:mm:ss"
        // );
        // if(selectInfo.draggedEl.classList[0] === 'drop-el') {
        //   const formData = {
        //     operator_id: selectInfo.draggedEl.parentNode.dataset.id,
        //     date: start_date
        //   };
        //   if (formData.operator_id) {
        //     this.addEventCalendar(formData).then(response => {
        //       this.$emit("my-event");
        //     });
        //   }
        // } else {
        //   this.$emit("my-event");
        // }
      }
    },
    // Начало перетаскивания
    eventDragStartHandle(e) {
      if (e.event.extendedProps.operators.length > 1) {
        let causeError = 'isNotCorrectShift'
        this.$root.$emit('CALENDAR_ERROR_POPUP', { e, causeError })
        this.errorDrag = true
      } else {
        this.eventDrag = e
      }
    },
    // Перетаскивания на другой календарь событий
    eventReceiveHandler(e) {
      if (!this.errorDrag && e.event.extendedProps.operators.length === 1) {
        if (e.event.start < new Date()) {
          let causeError = 'isNotCorrectDate'
          this.$root.$emit('CALENDAR_ERROR_POPUP', { e, causeError })
          this.$emit('my-event')
        } else if (this.movingEl && this.eventDropElement) {
          const start1 = moment.utc(e.draggedEl.fcSeg.start).format('YYYY-MM-DD HH:mm:ss')
          const start2 = moment(this.eventDropElement.start).format('YYYY-MM-DD HH:mm:ss')
          if (start2 === moment(this.movingEl.start).format('YYYY-MM-DD HH:mm:ss')) {
            if (this.eventDropElement.extendedProps.operators.length === 1) {
              const formDel1 = {
                userId: {
                  operator_id: this.movingEl.extendedProps.operators[0].id
                },
                scheduleId: this.movingEl.id
              }
              const formDel2 = {
                userId: {
                  operator_id: this.eventDropElement.extendedProps.operators[0].id
                },
                scheduleId: this.eventDropElement.id
              }

              const formAdd1 = {
                operator_id: this.movingEl.extendedProps.operators[0].id,
                date: start2
              }
              const formAdd2 = {
                operator_id: this.eventDropElement.extendedProps.operators[0].id,
                date: start1
              }
              this.delEventCalendar(formDel1).then(response => {
                this.addEventCalendar(formAdd2).then(response => {
                  this.delEventCalendar(formDel2).then(response => {
                    this.addEventCalendar(formAdd1).then(response => {
                      this.$emit('my-event')
                    })
                  })
                })
              })
            } else {
              let causeError = 'isNotCorrectShift'
              this.$root.$emit('CALENDAR_ERROR_POPUP', { e, causeError })
              this.$emit('my-event')
            }
          } else {
            //перенос в пустую клетку после пересечения
            const formDel = {
              userId: {
                operator_id: e.oldEvent.extendedProps.operators[0].id
              },
              scheduleId: e.oldEvent.id
            }
            const formAdd = {
              operator_id: e.oldEvent.extendedProps.operators[0].id,
              date: moment(e.event.start).format('YYYY-MM-DD HH:mm:ss')
            }
            this.delEventCalendar(formDel).then(response => {
              this.addEventCalendar(formAdd).then(response => {
                this.$emit('my-event')
              })
            })
          }
        }
        //перенос в пустую без пересечения
        else if (!this.eventDropElement) {
          const formDel = {
            userId: {
              operator_id: e.event.extendedProps.operators[0].id
            },
            scheduleId: e.event.id
          }
          const formAdd = {
            operator_id: e.event.extendedProps.operators[0].id,
            date: moment(e.event.start).format('YYYY-MM-DD HH:mm:ss')
          }
          this.delEventCalendar(formDel).then(response => {
            this.addEventCalendar(formAdd).then(response => {
              this.$emit('my-event')
            })
          })
        }
      } else {
        this.$emit('my-event')
        this.errorDrag = false
      }
      this.errorDrag = false
      this.eventDropElement = null
      this.eventDrag = null
      this.movingEl = null
    },
    // перетаскивание смен друг на друга меняет их местами(только для смен с 1 модератором в одном календаре)
    handledrop(e) {
      if (!this.errorDrag) {
        if (e.event.start < new Date()) {
          let causeError = 'isNotCorrectDate'
          this.$root.$emit('CALENDAR_ERROR_POPUP', { e, causeError })
          this.$emit('my-event')
        } else if (this.eventDrag && this.eventDropElement) {
          const start1 = moment(this.eventDrag.event.start).format('YYYY-MM-DD HH:mm:ss')
          const start2 = moment(this.eventDropElement.start).format('YYYY-MM-DD HH:mm:ss')
          if (start2 === moment(e.event.start).format('YYYY-MM-DD HH:mm:ss')) {
            if (this.eventDropElement.extendedProps.operators.length === 1) {
              const changeSchedule = {
                first_schedule_id: this.eventDrag.event.id,
                second_schedule_id: this.eventDropElement.id
              }
              this.changeOperator(changeSchedule).then(r => {
                if (r.status) this.$emit('my-event')
              })
            } else {
              let causeError = 'isNotCorrectShift'
              this.$root.$emit('CALENDAR_ERROR_POPUP', { e, causeError })
              this.$emit('my-event')
            }
          } else {
            //перенос в пустую клетку после пересечения
            const formDel = {
              userId: {
                operator_id: e.oldEvent.extendedProps.operators[0].id
              },
              scheduleId: e.oldEvent.id
            }
            const formAdd = {
              operator_id: e.oldEvent.extendedProps.operators[0].id,
              date: moment(e.event.start).format('YYYY-MM-DD HH:mm:ss')
            }
            this.delEventCalendar(formDel).then(response => {
              this.addEventCalendar(formAdd).then(response => {
                this.$emit('my-event')
              })
            })
          }
        } else if (!this.eventDropElement) {
          //перенос в пустую без пересечения
          const formDel = {
            userId: {
              operator_id: e.oldEvent.extendedProps.operators[0].id
            },
            scheduleId: e.oldEvent.id
          }
          const formAdd = {
            operator_id: e.oldEvent.extendedProps.operators[0].id,
            date: moment(e.event.start).format('YYYY-MM-DD HH:mm:ss')
          }
          this.delEventCalendar(formDel).then(response => {
            this.addEventCalendar(formAdd).then(response => {
              this.$emit('my-event')
            })
          })
        }
      } else {
        this.$emit('my-event')
        this.errorDrag = false
      }
      this.errorDrag = false
      this.eventDropElement = null
      this.eventDrag = null
      this.movingEl = null
    },
    handleeventOverlap(stillEvent, movingEvent) {
      this.movingEl = movingEvent
      this.eventDropElement = stillEvent
      return true
    }
  }
}
</script>

<style scoped></style>
