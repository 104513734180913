import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import AutoMessageService from '@/services/AutoMessagesService'

export default {
  [ActionTypes.REQUEST_AUTO_MESSAGES]: async ({ rootGetters, commit }, lang = 'RU') => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(AutoMessageService, [userToken]).getAutoMessage(lang)
    const { data, status } = response
    if (status) {
      let links = {
        next: data.next,
        previous: data.previous
      }
      commit(MutationTypes.SET_AUTO_MESSAGE_COUNT, data.count)
      commit(MutationTypes.SET_AUTO_MESSAGES_PAGINATION, links)
      commit(MutationTypes.SET_AUTO_MESSAGES_PAGE, data.page_number)
      commit(MutationTypes.SET_AUTO_MESSAGES, data.results)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_AUTO_MESSAGES_PAGINATION]: async ({ rootGetters, commit }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(AutoMessageService, [userToken]).getAutoMessageByPage(payload)
    const { data, status } = response
    if (status) {
      let links = {
        next: data.next,
        previous: data.previous
      }
      commit(MutationTypes.SET_AUTO_MESSAGE_COUNT, data.count)
      commit(MutationTypes.SET_AUTO_MESSAGES_PAGINATION, links)
      commit(MutationTypes.SET_AUTO_MESSAGES_PAGE, data.page_number)
      commit(MutationTypes.SET_AUTO_MESSAGES, data.results)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_ADD_AUTO_MESSAGES]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(AutoMessageService, [userToken]).addAutoMessage(payload)
    // const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      const data = {
        lang: payload.lang,
        page: payload.page
      }
      await dispatch(ActionTypes.REQUEST_AUTO_MESSAGES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_CHANGE_AUTO_MESSAGES]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(AutoMessageService, [userToken]).changeAutoMessage(payload)
    // const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      const data = {
        lang: payload.lang,
        page: payload.page
      }
      await dispatch(ActionTypes.REQUEST_AUTO_MESSAGES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_DELETE_AUTO_MESSAGES]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(AutoMessageService, [userToken]).deleteAutoMessage(payload)
    // const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      const data = {
        lang: payload.lang,
        page: payload.page
      }
      await dispatch(ActionTypes.REQUEST_AUTO_MESSAGES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
