import * as GetterTypes from './getter-types'
export default {
  [GetterTypes.GET_MODERATOR_DEFAULT_PAYMENT_SETTING]: ({ paymentDefaultSetting }) => paymentDefaultSetting,

  [GetterTypes.GET_MODERATORS]: ({ moderators }) => moderators,

  [GetterTypes.GET_CURRENT_MODERATOR]: ({ currentModerator }) => currentModerator,

  [GetterTypes.GET_MODERATOR_STATISTIC]: ({ moderatorStatistic }) => moderatorStatistic,

  [GetterTypes.GET_MODERATOR_PAYOUT]: ({ moderatorPayout }) => moderatorPayout,

  [GetterTypes.GET_MODERATOR_SEARCH_OPTION]: ({ searchOptions }) => searchOptions,

  [GetterTypes.GET_MODERATORS_CURRENCY]: ({ currency }) => currency,

  [GetterTypes.GET_MODERATOR_ROLES]: ({ moderatorRoles }) => moderatorRoles,

  [GetterTypes.GET_MODERATOR_ROLES_DEFAULT]: ({ moderatorRolesDefault }) => moderatorRolesDefault,

  [GetterTypes.GET_MODERATOR_TMP_ROLES]: ({ moderatorTmpRoles }) => moderatorTmpRoles
}
