<template>
  <div class="app-user-map">
    <l-map
      class="map"
      @click="$root.$emit(USER_MAP_EVENT, currentUser)"
      ref="myMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
    >
      <l-tile-layer :url="url" />
      <l-marker :lat-lng="marker"> </l-marker>
    </l-map>
  </div>
</template>

<script>
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'

import { USER_MAP_EVENT } from '@/constants/event'
export default {
  name: 'AppUserMap',
  props: {
    currentUser: {
      type: Object
    }
  },
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data() {
    return {
      USER_MAP_EVENT,
      zoom: 15,
      center: latLng(0, 0),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      marker: latLng(0, 0),
      mapOptions: {
        zoomSnap: 0.5
      }
    }
  },
  mounted() {
    this.center = latLng(this.currentUser.location.lat, this.currentUser.location.lng)
    this.marker = latLng(this.currentUser.location.lat, this.currentUser.location.lng)
  }
}
</script>

<style lang="scss">
@import './App-user-map';
</style>
