<template>
  <div class="login-wrap">
    <Login />
  </div>
</template>
<script>
import Login from '@/components/Login/Login'
export default {
  name: 'Loginpage',
  components: {
    Login
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>
