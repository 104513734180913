import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_MERGED_CATEGORIES]: (state, payload) => {
    state.mergedCategories = payload
  },
  [MutationTypes.SET_CATEGORIES_BY_LANG]: (state, payload) => {
    state.eventsCategoryLang = payload
  },
  [MutationTypes.SET_EVENTS_REFBOOK]: (state, payload) => {
    state.eventRefBook = payload
  },
  [MutationTypes.SET_EVENTS]: (state, payload) => {
    state.events = payload
  },

  [MutationTypes.SET_EVENT]: (state, payload) => {
    state.currentEvent = payload
  },

  [MutationTypes.SET_ALL_EVENTS]: (state, payload) => {
    state.events = state.events.concat(payload)
  },
  [MutationTypes.SET_EVENT_COUNTS]: (state, count) => {
    state.eventsCount = count
  },
  [MutationTypes.SET_EVENTS_ARHIVE]: (state, id) => {
    state.eventsCount = state.eventsCount - 1
    state.events = state.events.filter(f => f.event_id !== id)
  },
  [MutationTypes.SET_EVENT_DATE_FILTER]: (state, payload) => {
    const { key, timeStampDateObject } = payload
    const newDateObject = {
      key,
      ...timeStampDateObject
    }
    state.tmpDateFilter[key] = newDateObject
  },

  [MutationTypes.SET_EVENT_TMP_FILTERS]: (state, payload) => {
    const { key, value } = payload
    const newObject = {
      key,
      ...value
    }
    state.tmpEventFilters[key] = newObject
  },

  [MutationTypes.SET_EVENT_RESET_FILTERS]: state => {
    for (const field in state.tmpEventFilters) {
      state.tmpEventFilters[field] = {}
    }

    for (const date in state.tmpDateFilter) {
      state.tmpDateFilter[date] = {}
    }
  },
  [MutationTypes.SET_EVENTS_FILTERS]: (state, payload) => {
    state.filtersEvents = payload
  }
}
