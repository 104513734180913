<template>
  <div class="event-categories-wrap reasons-categories-wrap">
    <div class="ev-cat">
      <ReasonDeleteHeader />
      <div class="ev-cat__list">
        <div class="ev-cat__list-title">Пользовательские причины</div>
        <div class="reasons__table">
          <span>Номер причины</span>
          <span>Причина</span>
        </div>
        <div v-if="isLoaded && reasonUser.length">
          <ReasonDeleteItem v-for="reason in reasonUser" :key="reason.id" :reason="reason" />
        </div>

        <div class="ev-cat__no-reasons" v-if="!reasonUser.length && isLoaded">
          <span class="ev-cat__text">Нет данных...</span>
        </div>
        <div v-if="errorMessage" class="ev-cat__no-reasons">
          <span class="ev-cat__text">Ошибка при загрузке причин удаления!</span>
        </div>
      </div>
      <div class="ev-cat__list-title">Админские причины</div>
      <div v-if="isLoaded && reasonAdmin.length">
        <ReasonDeleteItem v-for="reason in reasonAdmin" :key="reason.id" :reason="reason" />
      </div>
      <div class="ev-cat__no-reasons" v-if="!reasonAdmin.length && isLoaded">
        <span class="ev-cat__text">Нет данных...</span>
      </div>
      <div v-if="errorMessage" class="ev-cat__no-reasons">
        <span class="ev-cat__text">Ошибка при загрузке причин удаления!</span>
      </div>
    </div>
    <ReasonDeletePopup />
    <ReasonDeleteAddPopup />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types'
import { GET_REASON_DELETE, GET_REASON_DELETE_ADMIN } from '@/store/getter-types'
import { REQUEST_REASON_DELETE } from '@/store/action-types'
import ReasonDeleteHeader from '@/components/Reasons/ReasonDeleteHeader'
import ReasonDeleteItem from '@/components/Reasons/ReasonDeleteItem'
import ReasonDeletePopup from '@/components/Modals/ReasonsPopup/ReasonDeletePopup'
import ReasonDeleteAddPopup from '@/components/Modals/ReasonsPopup/ReasonDeleteAddPopup'

import store from '@/store'
export default {
  name: 'interfaceCauseDelete',
  components: {
    ReasonDeleteHeader,
    ReasonDeleteItem,
    ReasonDeletePopup,
    ReasonDeleteAddPopup
  },
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_REASON_DELETE).then(response => {
      next(vm => {
        if (response) {
          vm.setCurrentLang('RU')
        }
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  methods: {
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    })
  },
  computed: {
    ...mapGetters({
      reasonAdmin: GET_REASON_DELETE_ADMIN,
      reasonUser: GET_REASON_DELETE
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
@import '@/components/Events/Event.scss';
@import '@/components/Events/EventCategory.scss';
.event-categories-wrap {
  padding: 16px;
}
.ev-cat__list-title {
  text-align: left;
  margin: 15px 0 15px 15px;
  font-weight: bold;
}

.ev-cat__no-reasons {
  min-height: 64px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
}
.ev-cat__text {
  margin: 0 auto;
}
.reasons-categories-wrap {
  max-width: 1172px;
  margin: 0 auto;
  .ev-cat__item-name {
    &:first-child {
      padding-left: 30px;
      width: 50%;
    }
    &:nth-child(2) {
      width: 50%;
    }
  }
  .ev-cat__item-content {
    justify-content: flex-start;
  }
}
</style>
