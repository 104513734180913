const operator = [
  {
    name: 'Неважно',
    value: 'Неважно',
    id: 1
  },
  {
    name: 'Да',
    value: 1,
    id: 2
  },
  {
    name: 'Нет',
    value: 0,
    id: 3
  }
]

export { operator }
