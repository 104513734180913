const pageCodes = {
  FIRST_SCREEN_COMPASS_PEOPLE_AROUND: 0,
  FIRST_SCREEN_NEW_PEOPLE: 1,
  FIRST_SCREEN_POPULAR: 2,
  FIRST_SCREEN_LAST_SEEN_RECENTLY: 3,
  FIRST_SCREEN_MAP: 4,
  FIRST_SCREEN_LIKES: 5,
  FIRST_SCREEN_EVENTS: 6,
  FIRST_SCREEN_MESSAGES: 7
}

export { pageCodes }
