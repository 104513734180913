<template>
  <Popup :visible="visible" @close="close" description="Удалить фото пользователя?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteHandler()">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close()">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_DELETE_PHOTO } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_DELETE_PHOTO } from '@/store/action-types'

export default {
  name: 'UserPhotoDelPopup',
  components: {
    Popup
  },
  data() {
    return {
      user_id: '',
      photo_id: ''
    }
  },
  mixins: [ModalMixin],

  methods: {
    ...mapActions({
      deletePhoto: REQUEST_DELETE_PHOTO
    }),
    closePopup() {
      this.user_id = ''
      this.photo_id = ''
      this.close()
    },

    deleteHandler() {
      const payload = {
        user_id: this.user_id,
        photo_id: this.photo_id
      }
      this.deletePhoto(payload)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(USER_DELETE_PHOTO, data => {
      this.user_id = data.user_id
      this.photo_id = data.photo_id
      this.open()
    })
  }
}
</script>
