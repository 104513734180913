<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Добавить причину блокировки"
    modalClass="popup--add-category popup--medium"
  >
    <form @submit.prevent="addReason">
      <div class="popup__item">
        <span class="popup__item-title">Причина</span>
        <input type="text" class="popup__input default-input" v-model="reason_text" required />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Номер причины</span>
        <input type="number" class="popup__input default-input" v-model.number="reason_code" required />
      </div>
      <div class="popup__item">
        <span class="toggle__desc">Причина блокировки фото</span>
        <label class="toggle">
          <input class="toggle__checkbox" type="checkbox" v-model="photo" />
          <span class="toggle__toggler"></span>
        </label>
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Добавить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { REASON_BLOCK_ADD_POPUP } from '@/constants/event'
import { mapActions, mapGetters } from 'vuex'
import { GET_CURRENT_LANGUAGES } from '@/store/getter-types'
import { REQUEST_ADD_REASON_BLOCK } from '@/store/action-types'

export default {
  name: 'ReasonBlockAddPopup',
  data() {
    return {
      reason_text: '',
      reason_code: '',
      photo: false
    }
  },
  components: {
    Popup
  },
  mixins: [ModalMixin],

  computed: {
    ...mapGetters({
      lang: GET_CURRENT_LANGUAGES
    })
  },
  mounted() {
    this.$root.$on(REASON_BLOCK_ADD_POPUP, eventcat => {
      this.open()
    })
  },
  methods: {
    ...mapActions({
      addReasonRequest: REQUEST_ADD_REASON_BLOCK
    }),
    closePopup() {
      this.photo = false
      this.reason_text = ''
      this.reason_code = ''
      this.close()
    },
    addReason() {
      const payload = {
        reason_text: this.reason_text.trim(),
        lang: this.lang,
        reason_code: +this.reason_code,
        block_type: this.photo ? 1 : 0
      }
      this.addReasonRequest(payload)
      this.closePopup()
    }
  }
}
</script>

<style lang="scss" scoped>
.popup__item:first-child {
  margin-top: 10px;
}
</style>
