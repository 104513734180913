<template>
  <div>
    <div class="operators__header">
      <span class="operators__title title-big">Авто сообщения</span>
      <button class="operators__btn-add" @click="editMessage">
        <i class="operators__btn-txt fa fa-plus-square"></i>
        <span class="operators__btn-title">Добавить</span>
      </button>
    </div>
    <div class="table-header-wrapper-oper">
      <ul class="table-header table-event-header">
        <li class="table-header-banner__item-event">
          <span class="table-header-banner__item-text table-header-banner__item-text--info">№</span>
        </li>
        <li class="table-header-banner__item-event">
          <span class="table-header-banner__item-text">Отправлять</span>
        </li>
        <li class="table-header-banner__item-event">
          <span class="table-header-banner__item-text">Время задержки</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    editMessage() {
      const key = 1
      this.$root.$emit('OPERATOR_EDIT_AUTO_MESSAGE', key, key)
    }
  }
}
</script>
