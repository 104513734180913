const GET_CONTROL_PERMISSIONS = 'GET_CONTROL_PERMISSIONS'
const GET_PERMISSIONS_GROUP = 'GET_PERMISSIONS_GROUP'
const GET_FILTERS_PERMISSION_DEFAULT = 'GET_FILTERS_PERMISSION_DEFAULT'
const GET_TMP_PERMISSION_FILERS = 'GET_TMP_PERMISSION_FILERS'

export {
  GET_CONTROL_PERMISSIONS,
  GET_PERMISSIONS_GROUP,
  GET_FILTERS_PERMISSION_DEFAULT,
  GET_TMP_PERMISSION_FILERS
}
