<template>
  <Popup :visible="visible" @close="closePopup" description="Удалить тариф?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="delHandler()">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions } from 'vuex'
import { REQUEST_DELETE_PAYMENTS_SETTING } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { DEL_RATE_OPTION_POPUP_EVENT } from '@/constants/event'
export default {
  name: 'DelPaymentSetting',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    DEL_RATE_OPTION_POPUP_EVENT,
    searchOption: null
  }),

  methods: {
    ...mapActions({
      delOptions: REQUEST_DELETE_PAYMENTS_SETTING
    }),
    closePopup() {
      this.searchOption = null
      this.close()
    },
    delHandler() {
      let data = {
        operator_payout_settings_id: this.searchOption
      }
      this.delOptions(data)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(DEL_RATE_OPTION_POPUP_EVENT, data => {
      this.searchOption = data
      this.open()
    })
  }
}
</script>
