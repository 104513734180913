import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import AutoMessagesListService from '@/services/AutoMessagesListService'

export default {
  [ActionTypes.REQUEST_AUTO_MESSAGES_LIST]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(AutoMessagesListService, [userToken]).getAllAutoMessages(
      formData
    )
    const { data, status } = response
    if (status) {
      commit(MutationTypes.SET_AUTO_MESSAGES_LIST, data.user)
      commit(MutationTypes.SET_MESSAGES_COUNT, data.count)
      // commit(MutationTypes.SET_AUTO_MESSAGES_LIST_FILTER_COUNT, data.user.length)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_AUTO_MESSAGES_LIST_APPLY_FILTERS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(AutoMessagesListService, [userToken]).getAllAutoMessages(
      formData
    )
    const { data, status } = response
    if (status) {
      commit(MutationTypes.SET_AUTO_MESSAGES_LIST_FILTER, data.user)
      commit(MutationTypes.SET_MESSAGES_COUNT, data.count)
      // commit(MutationTypes.SET_AUTO_MESSAGES_LIST_FILTER_COUNT, data.user.length)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  }
}
