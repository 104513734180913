<template>
  <Popup :visible="visible" @close="close" description="Удалить пользователя?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteHandler()">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close()">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_DELETE_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_DELETE_USER } from '@/store/action-types'
export default {
  name: 'UserDeletePopup',
  components: {
    Popup
  },
  data() {
    return {
      id: ''
    }
  },
  mixins: [ModalMixin],

  methods: {
    ...mapActions({
      deleteUser: REQUEST_DELETE_USER
    }),
    deleteHandler() {
      const payload = {
        user_id: this.id
      }
      this.deleteUser(payload)
      this.id = ''
      this.close()
    }
  },

  mounted() {
    this.$root.$on(USER_DELETE_EVENT, user => {
      this.open()
      this.id = user.id
    })
  }
}
</script>
