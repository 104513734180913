import { render, staticRenderFns } from "./UserAlertPopup.vue?vue&type=template&id=ce460512&scoped=true&"
import script from "./UserAlertPopup.vue?vue&type=script&lang=js&"
export * from "./UserAlertPopup.vue?vue&type=script&lang=js&"
import style0 from "./UserAlertPopup.vue?vue&type=style&index=0&id=ce460512&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce460512",
  null
  
)

export default component.exports