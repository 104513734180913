import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import MarketService from '@/services/MarketService'

export default {
  // MARKET UTM
  [ActionTypes.REQUEST_MARKET_UTM]: async ({ rootGetters, commit }, lang = 'RU') => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(MarketService, [userToken]).getMarketUtm()
    const { data, status } = response
    if (status) {
      commit(MutationTypes.SET_MARKET_UTM, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_ADD_MARKET_UTM]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(MarketService, [userToken]).addMarketUtm(payload)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_MARKET_UTM)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_UPDATE_MARKET_UTM]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(MarketService, [userToken]).updateMarketUtm(payload)

    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_MARKET_UTM)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_DELETE_MARKET_UTM]: async ({ rootGetters, commit, dispatch }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(MarketService, [userToken]).deleteMarketUtm(id)

    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_MARKET_UTM)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
