<template>
  <li class="operator-payments">
    <div class="operator-payments__header">
      <span class="operator-payments__title">{{ operator.first_name }} {{ operator.last_name }}</span>
    </div>
    <div class="operator-payments__body">
      <div>
        <router-link
          :to="{
            name: 'operators-statistic',
            params: { operator_id: operator.id }
          }"
          class="operator-payments__body-item operators-payments__btn operators-payments__btn--statistic"
        >
          <i class="fas fa-chart-line"></i>
          <span> Статистика</span>
        </router-link>
      </div>
      <div class="operator-payments__body-item">
        <span class="operator-payments__body-title">Сумма выплаты:</span>
        <span class="operator-payments__body-value"
          >{{ sumPay.toFixed(2) }} {{ moneyIcon(reverseList[0].currency) }}</span
        >
      </div>
      <div class="operator-payments__body-item">
        <span class="operator-payments__body-title">Сумма за текущий период: </span>
        <span class="operator-payments__body-value"
          >{{ reverseList[0].stats ? reverseList[0].stats.pay.toFixed(2) : 0 }}
          {{ moneyIcon(reverseList[0].currency) }}</span
        >
      </div>
      <div class="operator-payments__body-item operators-payments__item operators-payments__item--">
        <!--        <div v-if="operator.id === `612e19e88ba55822817b8ebe`">-->
        <!--          <span class="operator-payments__body-title">Премировать руководителя</span>-->
        <!--          <button-->
        <!--              @click="-->
        <!--            $root.$emit(PRIZE_OPERATOR_POPUP_EVENT, {-->
        <!--              operator: operator.id,-->
        <!--              pay: item.pay_id-->
        <!--            })-->
        <!--          "-->
        <!--              class="operators-payments__btn operators-payments__btn&#45;&#45;award"-->
        <!--          >-->
        <!--            <i class="fas fa-coins"></i>-->
        <!--          </button>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="operator-payments__table">
      <div class="operator-payments__table-header">
        <span class="table-header__item">Период</span>
        <span class="table-header__item">Входящих</span>
        <span class="table-header__item">Среднее время ответа</span>
        <span class="table-header__item">Длительность видеозвонков</span>
        <span class="table-header__item">Сумма видеозвонков</span>
        <span class="table-header__item">Премия</span>
        <span class="table-header__item">К выплате</span>
        <span class="table-header__item">Дата выплаты</span>
      </div>
      <ul
        class="operator-payments__table-list"
        ref="scroll"
        :class="{ 'operator-payments__table-list--open': loadingOn }"
      >
        <OperatorPaymentsItem
          v-for="(item, index) in reverseList"
          :item="item"
          :index="index"
          :operator="operator"
          :key="item.pay_id"
        />
      </ul>
      <!-- <ul v-if="loadingOn" class="operator-payments__table-list">
        <OperatorPaymentsItem
          v-for="(item, index) in reverseList.slice(2, reverseList.length)"
          :item="item"
          :index="index"
          :operator="operator"
          :key="item.pay_id"
        />
      </ul> -->
    </div>
    <div class="operator-payments__footer" v-if="operator.payout_history.length > 2">
      <button
        class="operators-payments__btn operator-payments__footer-link"
        v-if="!loadingOn"
        @click="openList"
      >
        Показать все
      </button>
      <button class="operators-payments__btn operator-payments__footer-link" v-else @click="openList">
        Скрыть
      </button>
    </div>
  </li>
</template>
<script>
import { mapActions } from 'vuex'
import getTransactionStatus from '@/components/mixins/getTransactionStatus'
import getIconCurrency from '@/components/mixins/getIconCurrency'
import { REQUEST_BILLING_OPERATORS } from '@/store/action-types'
import {
  BILL_OPERATOR_POPUP_EVENT,
  PRIZE_OPERATOR_POPUP_EVENT,
  PAY_OPERATOR_POPUP_EVENT
} from '@/constants/event'
import OperatorPaymentsItem from './OperatorPaymentsItem'

export default {
  name: 'OperatorPayments',
  components: { OperatorPaymentsItem },
  props: {
    operator: {
      type: Object
    }
  },
  data: () => ({
    BILL_OPERATOR_POPUP_EVENT,
    PRIZE_OPERATOR_POPUP_EVENT,
    PAY_OPERATOR_POPUP_EVENT,
    nightShift: false,
    loadingOn: false
  }),
  mixins: [getTransactionStatus, getIconCurrency],
  computed: {
    reverseList() {
      let newList = this.operator.payout_history.map(payout => payout).reverse()
      return newList
    },
    sumPay() {
      let sum = null
      this.operator.payout_history.forEach(f => {
        if (!f.payout_date) {
          sum = sum + f.pay_sum
        }
      })
      return sum
    }
  },
  methods: {
    ...mapActions({
      bill: REQUEST_BILLING_OPERATORS
    }),
    openList() {
      this.loadingOn = !this.loadingOn
      if (!this.loadingOn) {
        this.$refs.scroll.scrollTop = 0
      }
    }
  }
}
</script>
<style lang="scss">
@import 'OperatorsPayments';
</style>
