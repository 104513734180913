const REQUEST_CONTROL_DEPARTMENTS = 'REQUEST_CONTROL_DEPARTMENTS'
const REQUEST_DELETE_DEPARTMENT = 'REQUEST_DELETE_DEPARTMENT'
const REQUEST_EDIT_DEPARTMENT = 'REQUEST_EDIT_DEPARTMENT'
const REQUEST_CREATE_DEPARTMENT = 'REQUEST_CREATE_DEPARTMENT'
const REQUEST_UPDATE_DEPARTMENT = 'REQUEST_UPDATE_DEPARTMENT'
const REQUEST_DEPARTMENTS_BY_FILTER = 'REQUEST_DEPARTMENTS_BY_FILTER'

export {
  REQUEST_CONTROL_DEPARTMENTS,
  REQUEST_DELETE_DEPARTMENT,
  REQUEST_EDIT_DEPARTMENT,
  REQUEST_CREATE_DEPARTMENT,
  REQUEST_DEPARTMENTS_BY_FILTER,
  REQUEST_UPDATE_DEPARTMENT
}
