const REQUEST_CAUSE_POINTS = 'REQUEST_CAUSE_POINTS'
const REQUEST_ADD_CAUSE_POINTS = 'REQUEST_ADD_CAUSE_POINTS'
const REQUEST_DELETE_CAUSE_POINTS = 'REQUEST_DELETE_CAUSE_POINTS'
const REQUEST_UPDATE_CAUSE_POINTS = 'REQUEST_UPDATE_CAUSE_POINTS'

export {
  REQUEST_CAUSE_POINTS,
  REQUEST_ADD_CAUSE_POINTS,
  REQUEST_DELETE_CAUSE_POINTS,
  REQUEST_UPDATE_CAUSE_POINTS
}
