<template>
  <div class="table-push-header-wrapper">
    <ul class="table-push-header">
      <li class="table-push-header__item">
        <span class="table-push-header__item-text table-push-header__item-text-select" @click="sorted()"
          >Тип пуша</span
        >
      </li>
      <li class="table-push-header__item">
        <span class="table-push-header__item-text">Системный заголовок</span>
      </li>
      <li class="table-push-header__item">
        <span class="table-push-header__item-text">Системное сообщение</span>
      </li>
      <li class="table-push-header__item">
        <span class="table-push-header__item-text">Внутренний заголовок</span>
      </li>
      <li class="table-push-header__item">
        <span class="table-push-header__item-text">Внутреннее сообщение</span>
      </li>
      <li class="table-push-header__item">
        <span class="table-push-header__item-text">Тип сообщения</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'PushTableHeader',
  data() {
    return {
      sortBy: true
    }
  },
  methods: {
    sorted() {
      this.$emit('sorted', this.sortBy)
      this.sortBy = !this.sortBy
    }
  }
}
</script>
<style lang="scss">
@import './TicketsTableHeader.scss';
</style>
