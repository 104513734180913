<template>
  <Popup
    :visible="visible"
    @close="closePopUp"
    description="Редактирование достижения"
    modalClass="popup--small"
  >
    <form @submit.prevent="sendEditData" class="popup__form">
      <div class="popup__avatar-wrap">
        <div class="popup__avatar">
          <img v-if="isAvatar" :src="preview" alt="" />
          <img
            v-else-if="icon"
            :src="getFile({ type: 'image', file: icon })"
            class="chat__message-body--img"
            alt=""
          />
          <img v-else src="@/assets/img/anonymous_user.png" alt="" />
        </div>
        <div class="popup__avatar-edit">
          <input
            class="popup__avatar-edit-input popup__input default-input"
            type="file"
            id="avatar-input"
            accept=".jpg, .jpeg, .png"
            ref="downloadavatar"
            @change="fileUploadHandler"
          />
          <label
            class="popup__avatar-edit-label"
            for="avatar-input"
            :class="{
              'avatar-invalid': !icon
            }"
          >
            <i class="fa fa-pen"></i>
          </label>
        </div>
      </div>
      <div class="popup__item">
        <label for="achievement-name" class="popup__item-title popup__name">Имя</label>
        <input
          type="text"
          name="name"
          id="achievement-name"
          class="popup__input default-input"
          v-model="title"
          required
        />
      </div>
      <div class="popup__item">
        <label for="achievement-description" class="popup__item-title popup__description">Описание</label>
        <textarea
          name="description"
          id="achievement-description"
          class="popup__textarea popup__input default-input"
          v-model="description"
        />
      </div>
      <div class="popup__item">
        <label for="achievement-kind" class="popup__item-title popup__kind">Kind</label>
        <input
          type="number"
          name="kind"
          id="achievement-kind"
          class="popup__input default-input"
          v-model.number="kind"
          required
        />
      </div>
      <div class="popup__item">
        <label for="achievement-points" class="popup__item-title popup__points">Количество баллов</label>
        <input
          type="number"
          name="points"
          id="achievement-points"
          class="popup__input default-input"
          v-model.number="price"
          required
        />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--center">
          <button type="submit" class="popup__btn btn-green">Сохранить</button>
          <button @click="closePopUp" class="popup__btn btn-red">Отмена</button>
        </div>
      </div>
    </form>
  </Popup>
</template>

<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import getImgLink from '@/components/mixins/getImgCdnLink'
import { ACHIEVEMENT_POPUP_EVENT } from '@/constants/event'
import { GET_CURRENT_LANGUAGES } from '@/store/getter-types'
import { REQUEST_UPDATE_ACHIEVEMENTS } from '@/store/action-types'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AchievementEditPopUp',
  components: {
    Popup
  },
  mixins: [ModalMixin, getImgLink],
  data() {
    return {
      title: '',
      description: '',
      price: '',
      isAvatar: false,
      preview: null,
      icon: null,
      kind: '',
      id: null
    }
  },
  computed: {
    ...mapGetters({
      lang: GET_CURRENT_LANGUAGES
    })
  },
  mounted() {
    this.$root.$on(ACHIEVEMENT_POPUP_EVENT, eventcat => {
      this.open()
      this.title = eventcat.title
      this.description = eventcat.description
      this.price = eventcat.price
      this.icon = eventcat.icon
      this.kind = eventcat.kind
      this.id = eventcat.id
    })
  },
  methods: {
    ...mapActions({
      updateAchievement: REQUEST_UPDATE_ACHIEVEMENTS
    }),

    fileUploadHandler() {
      let avatar = this.$refs.downloadavatar.files[0]
      this.preview = URL.createObjectURL(avatar)
      this.isAvatar = true
      this.icon = avatar
    },

    sendEditData() {
      const formData = new FormData()
      if (this.icon) {
        formData.append('icon', this.icon) // обязательное поле
      }
      formData.append('id', this.id)
      formData.append('lang', this.lang)
      formData.append('title', this.title)
      formData.append('description', this.description)
      formData.append('price', Number(this.price))
      formData.append('kind', this.kind)
      this.updateAchievement(formData)
      this.title = ''
      this.description = ''
      this.price = ''
      this.icon = null
      this.kind = ''
      this.id = ''
      this.isAvatar = false
      this.preview = null
      this.close()
    },
    closePopUp() {
      this.title = ''
      this.description = ''
      this.price = ''
      this.icon = null
      this.kind = ''
      this.id = ''
      this.isAvatar = false
      this.preview = null
      this.close()
    }
  }
}
</script>

<style lang="scss">
.popup {
  &__input {
    margin-left: 1px;
    height: 33px;
    width: 100%;
  }
  &__textarea {
    margin: 1px 0 0 1px;
    width: 100%;
    height: 40px;
    padding: 5px;
    align-items: center;
    font: 400 13.3333px Arial;
  }
}
</style>
