<template>
  <Popup
    :visible="visible"
    @close="close"
    description="Добавить UTM метку"
    modalClass="popup--add-category popup--medium"
  >
    <form @submit.prevent="sendData">
      <div class="popup__item">
        <label class="popup__item-title">
          Название
          <input type="text" class="popup__input default-input" v-model="utm_name" required />
        </label>
      </div>
      <div class="popup__item">
        <label class="popup__item-title">
          Источник
          <input type="text" class="popup__input default-input" v-model="utm_source" required />
        </label>
      </div>
      <div class="popup__item">
        <label class="popup__item-title">
          Платформа
          <select type="text" class="popup__input default-input" v-model="selected" required>
            <option v-for="platform in utm_platform" :key="platform">{{ platform }}</option>
          </select>
        </label>
      </div>
      <div class="popup__item">
        <label class="popup__item-title">
          Цвет
          <input type="color" class="popup__input default-input" v-model="utm_color_scheme" required />
        </label>
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Добавить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { MARKET_UTM_ADD_POPUP } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_ADD_MARKET_UTM } from '@/store/action-types'

export default {
  name: 'MarketUtmAddPopup',
  data() {
    return {
      utm_source: '',
      utm_name: '',
      utm_platform: {
        ALL: 'ALL',
        IOS: 'IOS',
        ANDROID: 'ANDROID'
      },
      selected: 'ALL',
      utm_color_scheme: '#ffffff'
    }
  },
  components: {
    Popup
  },
  mixins: [ModalMixin],
  mounted() {
    this.$root.$on(MARKET_UTM_ADD_POPUP, utm => {
      this.open()
    })
  },
  methods: {
    ...mapActions({
      addUTM: REQUEST_ADD_MARKET_UTM
    }),
    sendData() {
      const payload = {
        utm_source: this.utm_source,
        utm_name: this.utm_name,
        utm_platform: this.selected,
        utm_color_scheme: this.utm_color_scheme
      }
      this.addUTM(payload)
      this.utm_color_scheme = '#ffffff'
      this.selected = 'ALL'
      this.utm_source = ''
      this.utm_name = ''
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.popup__item:first-child {
  margin-top: 10px;
}
.popup__input {
  margin-top: 5px;
}
</style>
