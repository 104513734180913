<template>
  <section ref="filtersWrap" class="filters-user-wrap">
    <div
      class="filters app-users-filters"
      ref="mobilefilters"
      v-click-outside="close"
      :class="{ opened: mobileFilterOpen }"
    >
      <div class="filters__mobile-nav">
        <button type="button" class="filters__link" @click="mobileFilterOpen = !mobileFilterOpen">
          <i class="filters__icon fa fa-filter"></i>
          <span class="filters__button-text">Фильтры</span>
        </button>
        <button v-if="isMobile && windowTop > 200" @click="toTop" class="btn-to-top--mobile">
          <FaIcon :iconClass="'fas fa-arrow-up'" />
        </button>
      </div>
      <form class="filters__items--user" @submit.prevent="applyUserFiltersHanlder">
        <div class="filters__wrapper">
          <div class="filters__group">
            <div class="filters__item">
              <span class="filters__item-title">ID оператора:</span>
              <input
                type="text"
                class="filters__input filters__input--id"
                v-model="operID"
                ref="operid"
                @click="mouseLeave"
              />
            </div>
            <div class="filters__item">
              <span class="filters__item-title">ID пользователя:</span>
              <input
                type="text"
                class="filters__input filters__input--id"
                v-model="userID"
                ref="userid"
                @click="mouseLeave"
              />
            </div>
            <!-- <div class="filters__item">
              <span class="filters__item-title">Email:</span>
              <input
                type="email"
                class="filters__input"
                v-model="filterEmail"
                ref="filteremail"
                @click="mouseLeave"
              />
            </div> -->
          </div>
          <div class="filters__group">
            <div class="filters__item">
              <span class="filters__item-title">Имя оператора:</span>
              <input
                type="text"
                class="filters__input"
                v-model="filterOperName"
                ref="filteropername"
                @click="mouseLeave"
              />
            </div>
            <div class="filters__item">
              <span class="filters__item-title">Имя пользователя:</span>
              <input
                type="text"
                class="filters__input"
                v-model="filterName"
                ref="filtername"
                @click="mouseLeave"
              />
            </div>
          </div>
          <div class="filters__group">
            <div class="filters__item">
              <span class="filters__item-title">Возраст опер.:</span>
              <input
                type="number"
                class="filters__input filters__input--age_message"
                placeholder="от"
                v-model="ageStartOper"
                min="18"
                ref="agestartoper"
                :required="isAgeMaxOper"
                @click="mouseLeave"
              />

              <input
                type="number"
                class="filters__input filters__input--age_message"
                placeholder="до"
                v-model="ageEndOper"
                min="18"
                ref="ageendoper"
                :required="isAgeMinOper"
                @click="mouseLeave"
              />
            </div>
            <div class="filters__item">
              <span class="filters__item-title">Возраст польз.:</span>
              <input
                type="number"
                class="filters__input filters__input--age_message"
                placeholder="от"
                v-model="ageStart"
                min="18"
                ref="agestart"
                :required="isAgeMax"
                @click="mouseLeave"
              />

              <input
                type="number"
                class="filters__input filters__input--age_message"
                placeholder="до"
                v-model="ageEnd"
                min="18"
                ref="ageend"
                :required="isAgeMin"
                @click="mouseLeave"
              />
            </div>
          </div>
          <div class="filters__group">
            <div class="filters__item">
              <span class="filters__item-title">Город опер.:</span>
              <input
                type="text"
                class="filters__input"
                v-model.trim="filterCity"
                ref="filtercity"
                @click="mouseLeave"
              />
            </div>
            <div class="filters__item">
              <span class="filters__item-title">Город польз.:</span>
              <input
                type="text"
                class="filters__input"
                v-model.trim="filterCityUser"
                ref="filtercityuser"
                @click="mouseLeave"
              />
            </div>
          </div>
          <div class="filters__group">
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Страна опер.:</span>
                <div class="select__body" v-tooltip="defaultFilter({ key: 'country' })">
                  <button type="button" class="select__field" @click="clickClose('country')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'country' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul
                    class="select__list select__list--country"
                    v-if="countrySelectOpen"
                    @mouseleave="mouseLeave"
                  >
                    <li
                      class="select__item"
                      v-for="country in usersHeader.country"
                      :key="country.id"
                      @click="
                        selectFilterBuilder({
                          key: 'country',
                          value: country
                        })
                      "
                    >
                      <span class="select__text">{{ country.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Страна польз.:</span>
                <div class="select__body" v-tooltip="defaultFilter({ key: 'countryUser' })">
                  <button type="button" class="select__field" @click="clickClose('countryUser')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'countryUser' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul
                    class="select__list select__list--country"
                    v-if="countryUserSelectOpen"
                    @mouseleave="mouseLeave"
                  >
                    <li
                      class="select__item"
                      v-for="country in usersHeader.country"
                      :key="country.id"
                      @click="
                        selectFilterBuilder({
                          key: 'countryUser',
                          value: country
                        })
                      "
                    >
                      <span class="select__text">{{ country.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="filters__group">
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Пол опер.:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('gender')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'gender' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="genderSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="gender in usersHeader.gender"
                      :key="gender.id"
                      @click="
                        selectFilterBuilder({
                          key: 'gender',
                          value: gender
                        })
                      "
                    >
                      <span class="select__text"> {{ gender.name }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Пол польз.:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('genderUser')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'genderUser' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="genderUserSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="gender in usersHeader.gender"
                      :key="gender.id"
                      @click="
                        selectFilterBuilder({
                          key: 'genderUser',
                          value: gender
                        })
                      "
                    >
                      <span class="select__text"> {{ gender.name }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Диалог:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('count')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'count' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="countSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="count in usersHeader.count"
                      :key="count.id"
                      @click="
                        selectFilterBuilder({
                          key: 'count',
                          value: count
                        })
                      "
                    >
                      <span class="select__text"> {{ count.name }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Тип сообщения:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('type')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'type' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="typeSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="type in usersHeader.type"
                      :key="type.id"
                      @click="
                        selectFilterBuilder({
                          key: 'type',
                          value: type
                        })
                      "
                    >
                      <span class="select__text"> {{ type.name }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="filters__group filters__group--text">
            <div class="filters__item">
              <p class="filters__text">
                Автосообщения
                <span v-if="countMessages" class="filters__text--count">({{ countMessages }}) из</span>
                <span class="filters__text--count"> ({{ countMessages }})</span>
              </p>
            </div>
          </div>
        </div>

        <div class="filters__buttons">
          <button type="button" @click="resetFiltersHandler" class="filters__btn-clear">
            <i class="fa fa-eraser"></i>
          </button>
          <button type="submit" class="filters__btn-search">
            <span>Поиск</span>
          </button>
        </div>
      </form>
      <button
        v-if="!mobileFilterOpen && windowTop > 200 && !isMobile"
        @click="toTop"
        class="btn-to-top-filters"
      >
        <FaIcon :iconClass="'fas fa-arrow-up'" />
      </button>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  // GET_FILTERS_INFO,
  // GET_FILTERS_DEFAULT,
  // GET_TMP_FILERS,
  GET_FILTERS_DEFAULT_AUTO_MESSAGE,
  GET_TMP_FILERS_AUTO_MESSAGE,
  GET_FILTERS_INFO_AUTO_MESSAGE,
  GET_VALUE_PICKER,
  GET_PERMISSIONS,
  GET_AUTO_MESSAGES_LIST,
  GET_MESSAGES_COUNT,
  GET_FILTERS_COUNT
} from '@/store/getter-types'
import {
  SET_RESET_FILTERS_AUTO_MESSAGE,
  SET_DATE_FILTER,
  SET_TMP_FILTERS_AUTO_MESSAGE,
  SET_CURRENT_FILTER_AUTO_MESSAGE,
  SET_AUTO_MESSAGES_LIST,
  SET_AUTO_MESSAGES_LIST_FILTER_COUNT,
  SET_AUTO_MESSAGES_LIST_FILTER
} from '@/store/mutation-types'
import { REQUEST_AUTO_MESSAGES_LIST, REQUEST_AUTO_MESSAGES_LIST_APPLY_FILTERS } from '@/store/action-types'
// import DateRangePicker from 'vue2-daterange-picker'
import createLocaleDateFormat from '@/components/mixins/timeFormat'
import pickerMixin from '@/components/Picker/pickerMixin'
export default {
  name: 'FilterAutoMessages',
  // components: {
  //   DateRangePicker
  // },
  props: [`count`, `picked_date`, `user_city`],
  mixins: [createLocaleDateFormat, pickerMixin],
  data: () => ({
    isAgeMax: false,
    isAgeMin: false,
    isAgeMaxOper: false,
    isAgeMinOper: false,
    osSelectOpen: false,
    countrySelectOpen: false,
    countryUserSelectOpen: false,
    genderSelectOpen: false,
    genderUserSelectOpen: false,
    countSelectOpen: false,
    typeSelectOpen: false,
    userDeletedSelectOpen: false,
    operatorSelectOpen: false,
    userIncognitoSelectOpen: false,
    vipSelectOpen: false,
    onlineSelectOpen: false,
    utmSelectOpen: false,
    banSelectOpen: false,
    verificateSelectOpen: false,
    sortSelectOpen: false,
    autodatingSelectOpen: false,
    pushSelectOpen: false,
    confidentialSelectOpen: false,
    sortOrder: [],
    pushOrder: [],
    confidentialOrder: [],
    windowTop: 0,

    mobileFilterOpen: false,
    filters: {
      user_id: '',
      oper_id: '',
      email: '',
      name: '',
      oper_name: '',
      city: '',
      userCity: '',
      country: '',
      countryUser: '',
      age_start: null,
      age_end: null,
      age_startOper: null,
      age_endOper: null,
      user_vkontakte: false,
      user_facebook: false
    }
  }),

  watch: {
    mobileFilterOpen: function() {
      if (!this.mobileFilterOpen) this.$refs.mobilefilters.scrollTop = 0
    }
  },

  computed: {
    ...mapGetters({
      usersHeader: GET_FILTERS_INFO_AUTO_MESSAGE,
      defaultFilters: GET_FILTERS_DEFAULT_AUTO_MESSAGE,
      tmpFilter: GET_TMP_FILERS_AUTO_MESSAGE,
      getDatePickerValues: GET_VALUE_PICKER,
      getPermissions: GET_PERMISSIONS,
      countMessages: GET_MESSAGES_COUNT,
      messages: GET_AUTO_MESSAGES_LIST,
      count: GET_FILTERS_COUNT
    }),
    showPush: function() {
      return this.pushOrder.join(', ')
    },
    showConf: function() {
      return this.confidentialOrder.join(', ')
    },
    ageStart: {
      get() {
        return this.filters.age_start
      },
      set(age) {
        if (age) {
          this.isAgeMin = true
          this.$refs.agestart.value = age
          this.filters.age_start = age
        } else {
          this.isAgeMin = false
          this.$refs.agestart.value = age
          this.filters.age_start = age
        }
      }
    },

    ageEnd: {
      get() {
        return this.filters.age_end
      },
      set(end) {
        if (end) {
          this.isAgeMax = true
          this.$refs.ageend.value = end
          this.filters.age_end = end
        } else {
          this.isAgeMax = false
          this.$refs.ageend.value = end
          this.filters.age_end = end
        }
      }
    },

    ageStartOper: {
      get() {
        return this.filters.age_startOper
      },
      set(age) {
        if (age) {
          this.isAgeMinOper = true
          this.$refs.agestartoper.value = age
          this.filters.age_startOper = age
        } else {
          this.isAgeMinOper = false
          this.$refs.agestartoper.value = age
          this.filters.age_startOper = age
        }
      }
    },

    ageEndOper: {
      get() {
        return this.filters.age_endOper
      },
      set(end) {
        if (end) {
          this.isAgeMaxOper = true
          this.$refs.ageendoper.value = end
          this.filters.age_endOper = end
        } else {
          this.isAgeMaxOper = false
          this.$refs.ageendoper.value = end
          this.filters.age_endOper = end
        }
      }
    },

    userID: {
      get() {
        return this.filters.user_id
      },
      set(id) {
        this.$refs.userid.value = id
        this.filters.user_id = id
      }
    },

    operID: {
      get() {
        return this.filters.oper_id
      },
      set(id) {
        this.$refs.operid.value = id
        this.filters.oper_id = id
      }
    },

    filterEmail: {
      get() {
        return this.filters.email
      },
      set(email) {
        this.$refs.filteremail.value = email
        this.filters.email = email
      }
    },

    filterName: {
      get() {
        return this.filters.name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.filtername.value = name
        this.filters.name = name
      }
    },

    filterOperName: {
      get() {
        return this.filters.oper_name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.filteropername.value = name
        this.filters.oper_name = name
      }
    },

    filterCity: {
      get() {
        return this.filters.city
      },
      set(city) {
        city = city.replace(/\d/g, '')
        this.$refs.filtercity.value = city
        this.filters.city = city
      }
    },

    isMobile() {
      return window.matchMedia('(max-width: 1025px)').matches ? true : false
    },

    filterCityUser: {
      get() {
        return this.filters.userCity
      },
      set(city) {
        city = city.replace(/\d/g, '')
        this.$refs.filtercityuser.value = city
        this.filters.userCity = city
      }
    }

    // filterCountry: {
    //   get() {
    //     return this.filters.country
    //   },
    //   set(country) {
    //     country = country.replace(/\d/g, '')
    //     // this.$refs.filtercountry.value = country
    //     this.filters.country = country
    //   }
    // },

    // filterCountryUser: {
    //   get() {
    //     return this.filters.countryUser
    //   },
    //   set(country) {
    //     country = country.replace(/\d/g, '')
    //     // this.$refs.filtercountryUser.value = country
    //     this.filters.countryUser = country
    //   }
    // },
  },
  beforeDestroy() {
    this.resetFiltersHandler()
  },

  methods: {
    ...mapMutations({
      resetFilters: SET_RESET_FILTERS_AUTO_MESSAGE,
      addTmpFilters: SET_TMP_FILTERS_AUTO_MESSAGE,
      dateFilter: SET_DATE_FILTER,
      setFilters: SET_CURRENT_FILTER_AUTO_MESSAGE,
      delUsers: SET_AUTO_MESSAGES_LIST_FILTER,
      resetCount: SET_AUTO_MESSAGES_LIST_FILTER_COUNT
    }),

    ...mapActions({
      applyFilters: REQUEST_AUTO_MESSAGES_LIST_APPLY_FILTERS,
      allUsers: REQUEST_AUTO_MESSAGES_LIST_APPLY_FILTERS
    }),
    onScroll(e) {
      this.windowTop = e.target.scrollTop
    },
    filterDisabled(object) {
      const { key, ref, item } = object
      const sortName = key.value.split(':')
      switch (item) {
        case 'push':
          if (sortName[1] === 'off') {
            sortName[1] = 'on'
          } else {
            sortName[1] = 'off'
          }

          break
        case 'confidential':
          if (sortName[1] === 'off') {
            sortName[1] = 'on'
          } else {
            sortName[1] = 'off'
          }
          break
        default:
          break
      }
      if (this[`${item}Order`].find(f => f === key.value)) {
        let str = this.$refs[`${ref}`].find(f => f.value === sortName.join(':'))
        str.disabled = true
      } else {
        let str = this.$refs[`${ref}`].find(f => f.value === sortName.join(':'))
        str.disabled = false
      }
    },

    close() {
      this.osSelectOpen = false
      this.vipSelectOpen = false
      this.onlineSelectOpen = false
      this.countrySelectOpen = false
      this.countryUserSelectOpen = false
      this.genderSelectOpen = false
      this.genderUserSelectOpen = false
      this.countSelectOpen = false
      this.typeSelectOpen = false
      this.userDeletedSelectOpen = false
      this.operatorSelectOpen = false
      this.userIncognitoSelectOpen = false
      this.utmSelectOpen = false
      this.banSelectOpen = false
      this.verificateSelectOpen = false
      this.sortSelectOpen = false
      this.autodatingSelectOpen = false
      this.pushSelectOpen = false
      this.confidentialSelectOpen = false
    },

    defaultFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : this.defaultFilters[key].name
    },

    defaultNameFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : ''
    },
    defaultSortFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current ? current : ''
    },

    defaultValueFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current !== undefined ? current : ''
    },

    selectFilterBuilder(filterItem) {
      const { key } = filterItem
      switch (key) {
        case 'userDeleted':
          this.addTmpFilters({ ...filterItem })
          break
        case 'gender':
          this.addTmpFilters({ ...filterItem })
          break
        case 'genderUser':
          this.addTmpFilters({ ...filterItem })
          break
        case 'os':
          this.addTmpFilters({ ...filterItem })
          break
        case 'vip':
          this.addTmpFilters({ ...filterItem })
          break
        case 'online':
          this.addTmpFilters({ ...filterItem })
          break
        case 'utm':
          this.addTmpFilters({ ...filterItem })
          break
        case 'ban':
          this.addTmpFilters({ ...filterItem })
          break
        case 'verificate':
          this.addTmpFilters({ ...filterItem })
          break
        case 'sort':
          this.addTmpFilters({ ...filterItem })
          break
        case 'userIncognito':
          this.addTmpFilters({ ...filterItem })
          break
        case 'autodating':
          this.addTmpFilters({ ...filterItem })
          break
        case 'operator':
          this.addTmpFilters({ ...filterItem })
          break
        case 'country':
          if (filterItem.value.id !== 0) {
            this.addTmpFilters({ ...filterItem })
          }
          break
        case 'countryUser':
          if (filterItem.value.id !== 0) {
            this.addTmpFilters({ ...filterItem })
          }
          break
        case 'count':
          this.addTmpFilters({ ...filterItem })
          break
        case 'type':
          this.addTmpFilters({ ...filterItem })
          break

        default:
          break
      }
      this[`${key}SelectOpen`] = false
    },

    resetFiltersHandler() {
      this.isAgeMax = false
      this.isAgeMin = false
      this.isAgeMaxOper = false
      this.isAgeMinOper = false
      this.resetCount(0)
      this.resetFilters({ page: 0 })
      this.resetData(this.filters)
      this.delUsers([])
      const delta = new Date().getTimezoneOffset() / -60
      this.allUsers({ page: 0, delta: delta })
      // this.$router.replace({})
    },

    resetData(data) {
      for (const key in data) {
        const item = data[key]
        if (item) {
          switch (item.constructor) {
            case Object:
              this.resetData(item)
              break
            default:
              data[key] = ''
          }
        }
      }
    },

    validateData(data) {
      let verifyHandler = {
        value: false
      }

      function validObject(obj, verifyer) {
        for (const key in obj) {
          const item = obj[key]

          if (item) {
            switch (item.constructor) {
              case Object:
                validObject(item, verifyer)
                break
              default:
                verifyer.value = true
            }
          }
        }
      }

      validObject(data, verifyHandler)

      return verifyHandler.value
    },

    applyUserFiltersHanlder() {
      this.resetCount(0)
      const delta = new Date().getTimezoneOffset() / -60
      const userFilterData = {
        'user.user_id	': this.filters.user_id,
        'operator_user.user_id': this.filters.oper_id,
        'user.user_name': this.filters.name,
        'operator_user.user_name': this.filters.oper_name,
        'user.user_city': this.filters.userCity,
        'operator_user.user_city': this.filters.city,
        delta: delta,
        'user.user_country.code': this.defaultValueFilter({ key: 'countryUser' }),
        'operator_user.user_country.code': this.defaultValueFilter({ key: 'country' }),
        'user.user_gender': this.defaultValueFilter({ key: 'genderUser' }),
        'operator_user.user_gender': this.defaultValueFilter({ key: 'gender' }),
        'message.message_content.Type': this.defaultValueFilter({ key: 'type' }),
        count: this.defaultValueFilter({ key: 'count' }),
        page: 0
      }
      if (this.isAgeMax || this.isAgeMin) {
        userFilterData['user.user_birthday'] = `${this.filters.age_start},${this.filters.age_end}`
      }
      if (this.isAgeMaxOper || this.isAgeMinOper) {
        userFilterData[
          'operator_user.user_birthday'
        ] = `${this.filters.age_startOper},${this.filters.age_endOper}`
      }
      this.delUsers([])
      this.setFilters(userFilterData)
      this.applyFilters(userFilterData)
    },

    mouseLeave() {
      this.close()
    },
    toTop() {
      this.$emit('toTop')
    },

    clickClose(options, param) {
      let newOptions = this[`${options}SelectOpen`]
      this.close()
      this[`${options}SelectOpen`] = !newOptions
      if ((newOptions === false && options === 'sort') || options === 'push' || options === 'confidential') {
        if (this[`${options}Order`].length) {
          this[`${options}Order`].forEach(el => {
            let sortName = el.split(':')
            switch (param) {
              case 'desc':
                if (sortName[1] === 'desc') {
                  sortName[1] = 'asc'
                } else {
                  sortName[1] = 'desc'
                }
                break
              case 'off':
                if (sortName[1] === 'off') {
                  sortName[1] = 'on'
                } else {
                  sortName[1] = 'off'
                }
                break
              default:
                break
            }
            setTimeout(() => {
              let str = Array.from(document.querySelectorAll('.select__input')).find(
                f => f.value === sortName.join(':')
              )
              if (str) {
                str.disabled = true
              }
            }, 0)
          })
        }
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$root.$emit('filtersHeight', this.$refs.filtersWrap.clientHeight)
    })
    this.$root.$on('openaside', value => {
      if (this.mobileFilterOpen) this.mobileFilterOpen = !this.mobileFilterOpen
    })
    setTimeout(() => {
      let el = document.getElementsByClassName('app-messages')[0]
      el.addEventListener('scroll', this.onScroll)
    }, 1500)
    // this.$root.$on('submitFilter', data => {
    //   this.filters.city = data.city
    //   this.applyUserFiltersHanlder()
    // })
  }
}
</script>

<style lang="scss" scoped>
@import './Filters.scss';
.btn-to-top-filters {
  background-color: #a2a2a2a8;
  border-radius: 60px;
  color: #ffffff;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.btn-to-top--mobile {
  background-color: #a2a2a2a8;
  border-radius: 60px;
  color: #ffffff;
  position: absolute;
  right: 18px;
  top: 20px;
}
.filters__group--text {
  margin-top: 25px;
  border-left: 1px solid #ddd;
  padding-left: 5px;
}

.filters__text {
  align-items: center;
  font-size: 18px;
  line-height: 34px;
  font-weight: 700;
  &--count {
    color: #318bcc;
  }
}
</style>
