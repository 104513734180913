<template>
  <Popup :visible="visible" @close="closePopup" description="" modalClass="popup--medium">
    <div class="popup__add-header">
      <span class="popup__header-title">Дополнительные настройки</span>
    </div>
    <div class="popup__item popup__item-row">
      <span class="row__item">Минимальное количество сообщений за период:</span>
      <div class="row__item" v-if="!isEdit">
        <span class="row__item-input">{{ settings.min_in_messages_for_pay }}</span>
        <button
          v-if="!isEdit"
          key="edit"
          class="setting__edit-btn"
          @click.prevent="openEdit('count_message')"
        >
          <i class="fa fa-edit"></i>
        </button>
      </div>
      <div class="row__item" v-else>
        <input
          type="number"
          class="popup__input default-input row__item-input"
          v-model="messageValue"
          ref="message"
          required
        />
        <button key="save" class="setting__edit-btn" @click.prevent="editSetting">
          <i class="fa fa-save"></i>
        </button>
        <button class="setting__delete-btn" @click.prevent="closeRow">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    <div class="popup__item popup__item-row">
      <span class="row__item">Время простоя для назначения штрафа(за сутки):</span>
      <div class="row__item" v-if="!isEditMinute">
        <span class="row__item-input">{{ settings.fine_minute_count }}</span>
        <button
          v-if="!isEditMinute"
          key="edit"
          class="setting__edit-btn"
          @click.prevent="openEdit('minute_count')"
        >
          <i class="fa fa-edit"></i>
        </button>
      </div>
      <div class="row__item" v-else>
        <input
          type="number"
          class="popup__input default-input row__item-input"
          v-model="messageValueFineTime"
          ref="message"
          required
        />
        <button key="save" class="setting__edit-btn" @click.prevent="editSetting">
          <i class="fa fa-save"></i>
        </button>
        <button class="setting__delete-btn" @click.prevent="closeRow">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    <div class="popup__item popup__item-row">
      <span class="row__item">Сумма штрафа за сутки(руб):</span>
      <div class="row__item" v-if="!isEditPrice">
        <span class="row__item-input">{{ settings.fine_price_rub }}</span>
        <button
          v-if="!isEditPrice"
          key="edit"
          class="setting__edit-btn"
          @click.prevent="openEdit('price_rub')"
        >
          <i class="fa fa-edit"></i>
        </button>
      </div>
      <div class="row__item" v-else>
        <input
          type="number"
          class="popup__input default-input row__item-input"
          v-model="messageValueFinePrice"
          ref="message"
          required
        />
        <button key="save" class="setting__edit-btn" @click.prevent="editSetting">
          <i class="fa fa-save"></i>
        </button>
        <button class="setting__delete-btn" @click.prevent="closeRow">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    <div class="popup__item">
      <div class="popup__btns popup__btns--right">
        <button @click="closePopup" class="popup__btn btn-red">Закрыть</button>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EDIT_OTHER_OPTION_POPUP_EVENT } from '@/constants/event'

import { mapActions } from 'vuex'
import { REQUEST_EDIT_DEFAULT_SETTING } from '@/store/action-types'
export default {
  name: 'EditPaymentOtherSettings',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data: () => ({
    settings: {},
    min_in_messages_for_pay: '',
    fine_minute_count: '',
    fine_price_rub: '',
    isEdit: false,
    isEditMinute: false,
    isEditPrice: false,
    oldData: {}
  }),
  computed: {
    messageValue: {
      get() {
        return this.settings.min_in_messages_for_pay
      },
      set(value) {
        // value = value.replace(/[^A-Z]/ig, "").toUpperCase();
        this.$refs.message.value = value
        this.min_in_messages_for_pay = value
      }
    },
    messageValueFineTime: {
      get() {
        return this.settings.fine_minute_count
      },
      set(value) {
        // value = value.replace(/[^A-Z]/ig, "").toUpperCase();
        this.$refs.message.value = value
        this.fine_minute_count = value
      }
    },
    messageValueFinePrice: {
      get() {
        return this.settings.fine_price_rub
      },
      set(value) {
        // value = value.replace(/[^A-Z]/ig, "").toUpperCase();
        this.$refs.message.value = value
        this.fine_price_rub = value
      }
    }
  },

  methods: {
    ...mapActions({
      editPaymentSetting: REQUEST_EDIT_DEFAULT_SETTING
    }),
    openEdit(key) {
      this.oldData = JSON.parse(JSON.stringify(this.settings))
      switch (key) {
        case 'count_message':
          this.isEdit = true
          break
        case 'minute_count':
          this.isEditMinute = true
          break
        case 'price_rub':
          this.isEditPrice = true
          break
      }
    },
    closeRow() {
      this.oldData = {}
      this.min_in_messages_for_pay = ''
      this.fine_minute_count = ''
      this.fine_price_rub = ''
      this.isEditMinute = false
      this.isEditPrice = false
      this.isEdit = false
    },
    closePopup() {
      this.close()
    },
    editSetting() {
      let newDate = {}
      newDate.min_in_messages_for_pay = +this.min_in_messages_for_pay
        ? +this.min_in_messages_for_pay
        : +this.settings.min_in_messages_for_pay
      newDate.fine_minute_count = +this.fine_minute_count
        ? +this.fine_minute_count
        : +this.settings.fine_minute_count
      newDate.fine_price_rub = +this.fine_price_rub ? +this.fine_price_rub : +this.settings.fine_price_rub
      newDate.coefficient = +this.settings.coefficient
      newDate.female_point_price = +this.settings.female_point_price
      newDate.male_point_price = +this.settings.male_point_price
      newDate.free_messages = +this.settings.free_messages
      if (newDate.min_in_messages_for_pay || newDate.fine_minute_count || newDate.fine_price_rub) {
        this.editPaymentSetting(newDate).then(response => {
          this.settings.min_in_messages_for_pay = newDate.min_in_messages_for_pay
          this.settings.fine_minute_count = newDate.fine_minute_count
          this.settings.fine_price_rub = newDate.fine_price_rub
          this.closeRow()
        })
      }
    }
  },
  mounted() {
    this.$root.$on(EDIT_OTHER_OPTION_POPUP_EVENT, value => {
      this.settings = value
      this.open(EDIT_OTHER_OPTION_POPUP_EVENT)
    })
  }
}
</script>

<style lang="scss">
.popup__item-row {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.popup__add-header {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  padding: 5px;
  background: #00a8b3;
  color: white;
}
.popup__item {
  position: relative;
}
.popup__btn-edit {
  color: #689bcc;
  right: 0;
  top: 28px;
}
.row__item {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.row__item-input {
  font-family: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 34px;
  padding: 4px 6px;
  font-size: 12px;
  color: #318bcc;
  border: 1px solid #e7e8ec;
  border-radius: 2px;
  background-color: #e7e8ec;
}
.setting__edit-btn {
  color: #318bcc;
}
.setting__delete-btn {
  color: #f67a6e;
}
</style>
