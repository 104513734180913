import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import UserService from '@/services/UserService'

export default {
  [ActionTypes.REQUEST_APPLY_FILTERS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).getFilterUsers(formData)
    const { data } = response
    if (data) {
      if (rootGetters['users/GET_USER_COUNTS'] === 0) commit(MutationTypes.SET_USER_COUNTS, data.count)
      commit(MutationTypes.SET_USER_FILTER_COUNTS, data.user.length ? data.user.length : data.count)
      commit(MutationTypes.SET_USERS, data.user)
      commit(RootMutationTypes.SET_LOADER, false, { root: true })
    }
    return response
  },

  [ActionTypes.REQUEST_UPDATE_VIP_CALL]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).userCallOnliVipUpdate(data)
    if (response.status) {
      dispatch(ActionTypes.REQUEST_ID_USER, data.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_ALL_USERS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).getFilterUsers(formData)
    const { data } = response
    if (data) {
      if (rootGetters['users/GET_USER_COUNTS'] === 0) commit(MutationTypes.SET_USER_COUNTS, data.count)
      commit(MutationTypes.SET_USER_FILTER_COUNTS, data.user.length ? data.user.length : data.count)
      commit(MutationTypes.SET_ALL_USERS, data.user)
      commit(RootMutationTypes.SET_LOADER, false, { root: true })
    }
    return response
  },

  [ActionTypes.REQUEST_GET_VERIFY_AGE]: async ({ rootGetters, commit, dispatch }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).getAgeVerification(formData)
    if (response.status) {
      dispatch(ActionTypes.REQUEST_USER_PHOTOS, formData.user_id)
      dispatch(ActionTypes.REQUEST_ID_USER, formData.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_ID_USER]: async ({ rootGetters, commit }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).getUser(id)
    const { data, status } = response
    if (status) {
      commit(MutationTypes.SET_CURRENT_USER, data)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_USER_VERIFICATION]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).userVerification(payload)
    if (response.status) {
      await dispatch(ActionTypes.REQUEST_ID_USER, payload.user_id)
      await dispatch(ActionTypes.REQUEST_USER_PHOTOS, payload.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_BLOCK_MAIN_PHOTO]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).blockMainPhotoUser(payload)
    if (response.status) {
      await dispatch(ActionTypes.REQUEST_ID_USER, payload.user_id)
      await dispatch(ActionTypes.REQUEST_USER_PHOTOS, payload.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_UPDATE_VIP]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN

    const response = await Reflect.construct(UserService, [userToken]).updateVipStatus(payload)
    if (response.status) {
      await dispatch(ActionTypes.REQUEST_ID_USER, payload.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_DELETE_VIP]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).delVipStatus(payload)
    if (response.status) {
      await dispatch(ActionTypes.REQUEST_ID_USER, payload.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_BAN_USER]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN

    const response = await Reflect.construct(UserService, [userToken]).banUser(payload)
    if (response.status) {
      await dispatch(ActionTypes.REQUEST_ID_USER, payload.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_DELETE_USER]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN

    const response = await Reflect.construct(UserService, [userToken]).deleteUser(payload)
    if (response.status) {
      await dispatch(ActionTypes.REQUEST_ID_USER, payload.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_UPDATE_POINTS]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN

    const response = await Reflect.construct(UserService, [userToken]).updateUserPoints(payload)
    if (response.status) {
      await dispatch(ActionTypes.REQUEST_ID_USER, payload.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_HIDE_USER_APP]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).hideUserApp(payload)
    if (response.status) {
      await dispatch(ActionTypes.REQUEST_ID_USER, payload.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_HIDE_USER_ADMIN]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).hideUserAdmin(payload)
    if (response.status) {
      await dispatch(ActionTypes.REQUEST_ID_USER, payload.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_UPDATE_RATING]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).updateUserRating(payload)
    if (response.status) {
      await dispatch(ActionTypes.REQUEST_ID_USER, payload.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_UPDATE_GENDER]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).updateUserGender(payload)
    if (response.status) {
      await dispatch(ActionTypes.REQUEST_ID_USER, payload.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_USER_DEPOSIT]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).getUserDeposits(formData)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_USER_DEPOSIT, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_USER_PAYOUTS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).getUserPayouts(formData)
    const { data } = response

    if (data) {
      commit(MutationTypes.SET_USER_PAYOUTS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_USER_TRANSACTION_KIND]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).getUserTransactionKind(formData)
    const { data } = response

    if (data) {
      commit(MutationTypes.SET_TRANSACTION_KIND, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_USER_PHOTOS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).getUserPhotos(formData)
    const { data } = response

    if (data) {
      commit(MutationTypes.SET_USER_PHOTOS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_USER_CHATS]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).getUserChats(payload)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_USER_CHATS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CHAT_MESSAGES]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).getUserChatMessages(formData)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_CHAT_MESSAGES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CHAT_OLD_MESSAGES]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN

    const response = await Reflect.construct(UserService, [userToken]).getUserChatMessages(formData)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_CHAT_OLD_MESSAGES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_DELETE_PHOTO]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).delUserPhoto(payload)
    if (response.status) {
      await dispatch(ActionTypes.REQUEST_USER_PHOTOS, payload.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_FILTER_UTM]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).getFilterUtm()
    const { data, status } = response
    if (status) {
      commit(MutationTypes.SET_UTM_FILTER, data)
      commit(RootMutationTypes.SET_LOADER, false, { root: true })
    }

    return response
  },

  [ActionTypes.REQUEST_VISIBLE_MAP_USER]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).changeVisibleMap(payload)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ID_USER, payload.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_USER_PURCHASES]: async ({ rootGetters, commit }, userID) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).getUserPurchases(userID)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_USER_PURCHASES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_UPDATE_USER_PHOTO_STATUS]: async ({ rootGetters, commit, dispatch }, photoStatus) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).updatePhotoStatus(
      photoStatus.updateStatus
    )
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ID_USER, photoStatus.user_id)
      await dispatch(ActionTypes.REQUEST_USER_PHOTOS, photoStatus.user_id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_SEND_ALERT_TO_USER]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserService, [userToken]).sendNotificationtUser(formData)
    const { status } = response
    if (status) {
      commit(RootMutationTypes.SET_LOADER, false, { root: true })
    }
    return response
  }
}
