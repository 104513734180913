<template>
  <div class="event-presents-wrap">
    <GiftsEventHeader />

    <h2 class="event-presents-wrap__title">Стоимость до двух часов</h2>
    <GiftsEventTableLower :allGiftsEvent="getLowerPrice" v-if="getLowerPrice.length && isLoaded" />
    <div class="app-no-events" v-if="!getLowerPrice.length && isLoaded">
      <span class="app-no-events__text">Нет данных...</span>
    </div>

    <h2 class="event-presents-wrap__title">Стоимость после двух часов</h2>

    <GiftsEventTableGreat :allGiftsEvent="getGreatPrice" v-if="getGreatPrice.length && isLoaded" />
    <div class="app-no-events" v-if="!getGreatPrice.length && isLoaded">
      <span class="app-no-events__text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="app-no-events">
      <span class="app-no-events__text">Ошибка при загрузке стоимости событий</span>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import { REQUEST_ALL_GIFTS_EVENTS } from '@/store/action-types'
import { GET_ALL_GIFTS_EVENTS } from '@/store/getter-types'

import GiftsEventHeader from '@/components/GiftsEvent/GiftsEventHeader'
import GiftsEventTableLower from '@/components/GiftsEvent/GiftsEventTableLower'
import GiftsEventTableGreat from '@/components/GiftsEvent/GiftsEventTableGreat'

export default {
  name: 'EventsPresents',
  components: {
    GiftsEventHeader,
    GiftsEventTableLower,
    GiftsEventTableGreat
  },
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_ALL_GIFTS_EVENTS).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },

  computed: {
    ...mapGetters({ allGiftsEvents: GET_ALL_GIFTS_EVENTS }),

    getGreatPrice() {
      return this.allGiftsEvents.filter(item => item.with_time === true)
    },
    getLowerPrice() {
      return this.allGiftsEvents.filter(item => item.with_time === false)
    }
  }
}
</script>

<style lang="scss">
.event-presents-wrap {
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 500px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.event-presents-wrap__title {
  background-color: #ccc;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;

  margin-bottom: 10px;
}

.app-no-events {
  min-height: 64px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  &__text {
    margin: 0 auto;
  }
}
</style>
