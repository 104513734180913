<template>
  <div class="operators-payments__header">
    <span class="operators-payments__header-title">Платежи операторам</span>
    <div class="operators-payments__btns">
      <button
        class="operators-payments__btn operators-payments__btn--setting"
        @click="$root.$emit(BILL_ALL_OPERATOR_POPUP_EVENT)"
      >
        Рассчитать всех
        <i class="fas fa-hand-holding-usd"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { BILL_ALL_OPERATOR_POPUP_EVENT } from '@/constants/event'

export default {
  name: 'OperatorsPaymentsHeaders',
  data() {
    return { BILL_ALL_OPERATOR_POPUP_EVENT }
  }
}
</script>
<style lang="scss">
@import 'OperatorsPayments';
</style>
