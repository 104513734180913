import Request from '../helpers/Request'
import BaseService from './BaseService'

class ModeratorRatingService extends BaseService {
  constructor(token) {
    super(token)
  }
  //Получить рейтинг
  async getModeratorsRating(formData) {
    const getModeratorsRatingUrl = `/api/v1/stats/moderator-stats/rating_speed_and_count/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getModeratorsRatingUrl, {
      headers,
      params: formData
    })
  }
  //Получить настройки рейтинга качества проверки модераторов
  async getModeratorsSettingQuality() {
    const getModeratorsSettingQualityUrl = `/api/v1/management/moderator-rating-settings/count/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getModeratorsSettingQualityUrl, { headers })
  }
  //Добавить настройки рейтинга качества проверки модераторов
  async addModeratorsSettingQuality(payload) {
    const addModeratorsSettingQualityUrl = `/api/v1/management/moderator-rating-settings/count/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(addModeratorsSettingQualityUrl, payload.data, {
      headers
    })
  }

  //Обновить настройки рейтинга качества проверки модераторов
  async updateModeratorsSettingQuality(payload) {
    const updateModeratorsSettingQualityUrl = `/api/v1/management/moderator-rating-settings/count/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.put(updateModeratorsSettingQualityUrl, payload, {
      headers
    })
  }

  //Получить настройки рейтинга скорости проверки модераторов
  async getModeratorsSettingSpeed() {
    const getModeratorsSettingSpeedUrl = `/api/v1/management/moderator-rating-settings/speed/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getModeratorsSettingSpeedUrl, { headers })
  }

  //Добавить настройки рейтинга скорости проверки модераторов
  async addModeratorsSettingSpeed(payload) {
    const addModeratorsSettingSpeedUrl = `/api/v1/management/moderator-rating-settings/speed/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(addModeratorsSettingSpeedUrl, payload.data, {
      headers
    })
  }

  //Обновить настройки рейтинга скорости проверки модераторов
  async updateModeratorsSettingSpeed(payload) {
    const updateModeratorsSettingSpeedUrl = `/api/v1/management/moderator-rating-settings/speed/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.put(updateModeratorsSettingSpeedUrl, payload, {
      headers
    })
  }
}

export default ModeratorRatingService
