<template>
  <div class="moderators__wrap">
    <ModeratorsPaymentsHeaders />
    <ModeratorsPaymentsFilters />
    <ul class="moderators__pay-list" v-if="isLoaded && moderators.length">
      <ModeratorsPayments v-for="moderator in moderators" :key="moderator.id" :moderator="moderator" />
    </ul>
    <BillModeratorPopup />
    <PrizeModeratorPopup />
    <PayModeratorPopup />
    <PaymentsSettingPopup />
    <DelModeratorPopup />
    <CorrectionModeratorPopup />
    <BillAllModeratorPopup />
    <PrizeAllModeratorPopup />
    <PayModeratorErrorPopup />
    <div class="moderators__error" v-if="isLoaded && !moderators.length">
      <span class="moderators__error-text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="moderators__error">
      <span class="moderators__error-text">Ошибка при загрузке </span>
    </div>
  </div>
</template>
<script>
import ModeratorsPayments from '@/components/Moderators/Payments/ModeratorsPayments'
import ModeratorsPaymentsHeaders from '@/components/Moderators/Payments/ModeratorsPaymentsHeaders'
import ModeratorsPaymentsFilters from '@/components/Moderators/Payments/ModeratorsPaymentsFilters'
import { mapGetters } from 'vuex'
import { REQUEST_MODERATOR_PAYOUT, REQUEST_MODERATOR_PAYMENTS_SETTING } from '@/store/action-types'
import { GET_MODERATOR_PAYOUT } from '@/store/getter-types'
import store from '@/store'
import PrizeModeratorPopup from '@/components/Modals/ModeratorsPopup/Payments/PrizeModeratorPopup'
import PayModeratorPopup from '@/components/Modals/ModeratorsPopup/Payments/PayModeratorPopup'
import BillModeratorPopup from '@/components/Modals/ModeratorsPopup/Payments/BillModeratorPopup'
import PaymentsSettingPopup from '@/components/Modals/ModeratorsPopup/Payments/PaymentsSettingPopup'
import DelModeratorPopup from '@/components/Modals/ModeratorsPopup/Payments/DelModeratorPopup'
import CorrectionModeratorPopup from '@/components/Modals/ModeratorsPopup/Payments/CorrectionModeratorPopup'
import BillAllModeratorPopup from '@/components/Modals/ModeratorsPopup/Payments/BillAllModeratorPopup'
import PrizeAllModeratorPopup from '@/components/Modals/ModeratorsPopup/Payments/PrizeAllModeratorPopup'
import PayModeratorErrorPopup from '@/components/Modals/ModeratorsPopup/Payments/PayModeratorErrorPopup'

export default {
  name: 'moderatorPayments',
  components: {
    PayModeratorErrorPopup,
    PrizeAllModeratorPopup,
    BillAllModeratorPopup,
    CorrectionModeratorPopup,
    DelModeratorPopup,
    PaymentsSettingPopup,
    PayModeratorPopup,
    PrizeModeratorPopup,
    BillModeratorPopup,
    ModeratorsPaymentsHeaders,
    ModeratorsPaymentsFilters,
    ModeratorsPayments
  },
  data: () => ({
    errorMessage: false,
    isLoaded: false
  }),

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_MODERATOR_PAYOUT).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
        store.dispatch(REQUEST_MODERATOR_PAYMENTS_SETTING).then(response => {
          next(vm => {
            if (!response.status) {
              vm.errorMessage = true
            } else {
              vm.isLoaded = true
            }
          })
        })
      })
    })
  },

  computed: {
    ...mapGetters({
      moderators: GET_MODERATOR_PAYOUT
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
.moderators {
  &__wrap {
    padding: 0 16px;
  }
  &__pay-list {
    margin-top: 20px;
    padding: 0 20px;
  }
  &__error {
    margin-top: 20px;
    padding: 0 16px 0 16px;
    min-height: 64px;
    border-radius: 2px;

    position: relative;
    display: flex;
    align-items: center;
    background-color: white;

    &-text {
      margin: 0 auto;
    }
    &--big {
      width: 100%;
      margin: 0;
    }
  }
}

.moderators-payments__btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  &--billall {
    margin-right: 5px;
    background-color: #78cd51;
    border-color: #78cd51;
    color: #fff;
  }
  &--prizeall {
    margin-right: 5px;
    background-color: #f1c500;
    border-color: #f1c500;
    color: #fff;
  }
  &--success {
    background-color: #78cd51;
    border-color: #78cd51;
    color: #fff;
  }
  &--setting {
    //padding: 4px 8px;
    background-color: #ff6c60;
    border-color: #ff6c60;
    color: #fff;
  }
  &--statistic {
    background-color: #58c9f3;
    border-color: #58c9f3;
    color: #fff;
  }
  &--pay {
    background-color: #78cd51;
    border-color: #78cd51;
    color: #fff;
    padding: 0 5px;
  }
  &--award {
    background-color: #f1c500;
    border-color: #f1c500;
    color: #fff;
    width: 30px;
    height: 30px;
    padding: 3px;
  }
  &--disabled {
    background-color: #ddd !important;
    border-color: #ddd !important;
    cursor: not-allowed;
  }
}
</style>
