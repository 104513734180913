<template>
  <div ref="scroll" class="moderators__wrap">
    <ModeratorsStatisticHeaders :currentModerator="currentModerator" />
    <ModeratorsStatistic v-if="isLoaded && currentModerator" :statistic="statistic" />
    <div v-else class="moderators__error">
      <span class="moderators__error-text">Ошибка при загрузке статистики модератора</span>
    </div>
  </div>
</template>
<script>
import ModeratorsStatisticHeaders from '@/components/Moderators/Statistic/ModeratorsStatisticHeaders'
import ModeratorsStatistic from '@/components/Moderators/Statistic/ModeratorsStatistic'
import { mapGetters } from 'vuex'
import { REQUEST_MODERATOR_STATISTIC } from '@/store/action-types'
import { GET_MODERATOR_STATISTIC, GET_CURRENT_MODERATOR } from '@/store/getter-types'
import store from '@/store'

export default {
  name: 'moderatorsStatistic',
  components: {
    ModeratorsStatisticHeaders,
    ModeratorsStatistic
  },
  data: () => ({
    errorMessage: false,
    isLoaded: false
  }),

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_MODERATOR_STATISTIC, to.params.moderator_id).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      statistic: GET_MODERATOR_STATISTIC,
      currentModerator: GET_CURRENT_MODERATOR
    })
  },
  mounted() {
    this.$refs.scroll.scrollTop = 0
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
.moderators {
  &__wrap {
    padding: 0 16px;
  }
  &__list {
    margin-top: 20px;
    padding: 0 20px;
  }
}

.moderators-payments__btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  &--success {
    background-color: #78cd51;
    border-color: #78cd51;
    color: #fff;
  }
  &--setting {
    padding: 4px 8px;
    background-color: #ff6c60;
    border-color: #ff6c60;
    color: #fff;
  }
  &--statistic {
    background-color: #58c9f3;
    border-color: #58c9f3;
    color: #fff;
  }
  &--pay {
    background-color: #78cd51;
    border-color: #78cd51;
    color: #fff;
    padding: 0 5px;
  }
  &--award {
    background-color: #f1c500;
    border-color: #f1c500;
    color: #fff;
    width: 30px;
    height: 30px;
    padding: 3px;
  }
}
</style>
