<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Удалить настройку поиска?"
    modalClass="popup--small"
  >
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="delHandler()">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapMutations } from 'vuex'
import { SET_DEL_SEARCH_OPTIONS } from '@/store/mutation-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { DEL_SEARCH_OPTION_POPUP_EVENT } from '@/constants/event'
export default {
  name: 'DelSearchOptionPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    searchOption: null
  }),

  methods: {
    ...mapMutations({
      delOptions: SET_DEL_SEARCH_OPTIONS
    }),
    closePopup() {
      this.searchOption = null
      this.close()
    },
    delHandler() {
      this.delOptions(this.searchOption)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(DEL_SEARCH_OPTION_POPUP_EVENT, data => {
      this.searchOption = data
      this.open()
    })
  }
}
</script>
