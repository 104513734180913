import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_MODERATORS_RATING]: (state, payload) => {
    state.rating = payload
  },

  [MutationTypes.SET_MODERATOR_SETTINGS_QUALITY]: (state, payload) => {
    state.ratingSettingQuality = payload
  },

  [MutationTypes.SET_MODERATOR_SETTINGS_SPEED]: (state, payload) => {
    state.ratingSettingSpeed = payload
  },

  [MutationTypes.SET_MODERATOR_RATING_SETTINGS]: (state, payload) => {
    const { key, value } = payload
    const settingObj = {
      key,
      ...value
    }
    state.moderatorTmpRoles[key] = settingObj
  },
  [MutationTypes.SET_CLEAR_MODERATOR_RATING_DATA]: state => {
    for (const key in state.moderatorTmpRoles) {
      state.moderatorTmpRoles[key] = {}
    }
  }
}
