<template>
  <div class="support-contact__item">
    <div class="support-contact__item-body">
      <div class="support-contact__item-content">
        <div class="support-contact__item-name">
          <span class="support-contactt__item-title">{{ contact.reason_code }}</span>
        </div>
        <div class="support-contact__item-name">
          <span class="support-contact__item-title">{{ contact.reason_text }}</span>
        </div>
        <div class="support-contact__item-btns">
          <button
            class="support-contact__item-btn support-contact__item-btn--blue"
            @click="$root.$emit(SUPPORT_CONTACT_EDIT_POPUP, contact)"
          >
            <i class="support-contact__item-btn-icon fa fa-edit"></i>
          </button>
          <button
            class="support-contact__item-btn support-contact__item-btn--red"
            @click="$root.$emit(SUPPORT_CONTACT_DELETE_POPUP, contact.id)"
          >
            <i class="support-contact__item-btn-icon fa fa-times-circle"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SUPPORT_CONTACT_DELETE_POPUP, SUPPORT_CONTACT_EDIT_POPUP } from '@/constants/event'
export default {
  data() {
    return {
      SUPPORT_CONTACT_EDIT_POPUP,
      SUPPORT_CONTACT_DELETE_POPUP
    }
  },
  name: 'TypesSupportContactItem',
  props: {
    contact: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped></style>
