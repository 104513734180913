<template>
  <div class="statistics">
    <h2 class="statistics__title">Статистика сообщений операторов</h2>
    <StatisticOperatorsMessage
      v-if="isLoadedModeratorsPhotoCheck"
      :moderatorsPhotoCheck="moderatorsPhotoCheck"
    />
    <div v-if="isLoadedModeratorsPhotoCheckError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Статистика выручки операторов</h2>
    <StatisticOperatorCashes v-if="isLoadedoperatorCashes" :moderatorsPhotoCheck="operatorCashes" />
    <div v-if="isLoadedoperatorCashesError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>
    <div v-if="errorMessage" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке </span>
    </div>
  </div>
</template>
<script>
import StatisticOperatorsMessage from '@/components/Statistic/StatisticOperatorsMessage'
import StatisticOperatorCashes from '@/components/Statistic/StatisticOperatorCahses'
import { mapGetters } from 'vuex'
import { REQUEST_OPERATORS_MESSAGE, REQUEST_OPERATORS_CASHES } from '@/store/action-types'
import { GET_OPERATORS_MESSAGE, GET_OPERATORS_CASHES } from '@/store/getter-types'
import store from '@/store'
import moment from 'moment'

export default {
  name: 'StatisticsOperators',
  components: {
    StatisticOperatorsMessage,
    StatisticOperatorCashes
  },
  data: () => ({
    errorMessage: false,
    isLoadedModeratorsPhotoCheckError: false,
    isLoadedModeratorsPhotoCheck: false,
    isLoadedoperatorCashes: false,
    isLoadedoperatorCashesError: false,
    countCityDefault: {
      city_count: 20
    }
  }),
  beforeRouteEnter(to, from, next) {
    let date = {
      date_from: moment()
        .subtract(14, 'days')
        .startOf('day')
        .format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      delta: new Date().getTimezoneOffset() / -60
    }
    store.dispatch(REQUEST_OPERATORS_MESSAGE, { date: date.date_to, delta: date.delta }).then(response => {
      next(vm => {
        if (!response.status) {
          vm.isLoadedModeratorsPhotoCheckError = true
        } else {
          vm.isLoadedModeratorsPhotoCheck = true
        }
        store.dispatch(REQUEST_OPERATORS_CASHES, { date: date.date_to, delta: date.delta }).then(response => {
          if (!response.status) {
            vm.isLoadedoperatorCashesError = true
          } else {
            vm.isLoadedoperatorCashes = true
          }
        })
      })
    })
  },

  computed: {
    ...mapGetters({
      moderatorsPhotoCheck: GET_OPERATORS_MESSAGE,
      operatorCashes: GET_OPERATORS_CASHES
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>
<style lang="scss">
.statistics {
  width: 100%;
  &__title {
    margin: 10px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #c9cdd7;
  }
  &__error {
    margin-top: 20px;
    padding: 0 16px 0 16px;
    min-height: 64px;
    border-radius: 2px;

    position: relative;
    display: flex;
    align-items: center;
    background-color: white;

    &-text {
      margin: 0 auto;
    }
  }
}
.regenterstats-chart {
  height: 290px;
}
.table-condensed {
  td:hover {
    background: #318bcc;
    color: #fff;
  }
  .today {
    background: #48a7eb;
    color: #fff;
  }
}
</style>
