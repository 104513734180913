<template>
  <div class="auto-messages__item">
    <div class="auto-messages__item-body">
      <div class="auto-messages__item-content">
        <div class="auto-messages__item-name">
          <i
            :class="{
              'fa fa-mars auto-messages__male': message.user_gender === 1,
              'fa fa-venus auto-messages__female': message.user_gender === 2,
              'fa fa-genderless auto-messages__no-gender': message.user_gender === 0
            }"
            class="auto-messages__item-title"
          />
        </div>
        <div class="auto-messages__item-name">
          <i
            :class="{
              'fa fa-mars auto-messages__male': message.defendant_gender === 1,
              'fa fa-venus auto-messages__female': message.defendant_gender === 2,
              'fa fa-genderless auto-messages__no-gender': message.defendant_gender === 0
            }"
            class="auto-messages__item-title"
          />
        </div>
        <div class="auto-messages__item-name auto-messages__item-name--text">
          <span class="auto-messages__item-title">{{ message.message_text }}</span>
        </div>
        <div class="auto-messages__item-btns">
          <button
            class="auto-messages__item-btn auto-messages__item-btn--blue"
            @click="$root.$emit(AUTO_MESSAGE_CHANGE_POPUP, message, currentLang, currentPage)"
          >
            <i class="auto-messages__item-btn-icon fa fa-edit"></i>
          </button>
          <button
            class="auto-messages__item-btn auto-messages__item-btn--red"
            @click="$root.$emit(AUTO_MESSAGE_DELETE_POPUP, message.id, currentLang, currentPage)"
          >
            <i class="auto-messages__item-btn-icon fa fa-times-circle"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AUTO_MESSAGE_DELETE_POPUP, AUTO_MESSAGE_CHANGE_POPUP } from '@/constants/event'
import { GET_CURRENT_LANGUAGES, GET_AUTO_MESSAGES_PAGE } from '@/store/getter-types'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      AUTO_MESSAGE_DELETE_POPUP,
      AUTO_MESSAGE_CHANGE_POPUP
    }
  },
  name: 'AutoMessagesItem',
  props: {
    message: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      currentLang: GET_CURRENT_LANGUAGES,
      currentPage: GET_AUTO_MESSAGES_PAGE
    })
  }
}
</script>
<style lang="scss" scoped>
.auto-messages__male {
  color: dodgerblue;
}

.auto-messages__female {
  color: hotpink;
}

.auto-messages__no-gender {
  color: #2a3542;
}
</style>
