<template>
  <div class="table-header__row">
    <span class="table-header__row-item">Дата начала</span>
    <span class="table-header__row-item">Дата конца</span>
    <span class="table-header__row-item">Заработано</span>
    <span class="table-header__row-item">Среднее время ответа</span>
    <span class="table-header__row-item">Входящих сообщений</span>
    <span class="table-header__row-item">Исходящих сообщений</span>
    <span class="table-header__row-item">Длительность видеозвонков</span>
    <span class="table-header__row-item">Сумма видеозвонков</span>
    <span class="table-header__row-item">Сумма штрафа</span>
    <span class="table-header__row-item">Категория стран</span>
    <span class="table-header__row-item">Валюта</span>
  </div>
</template>

<script>
export default {
  name: 'OperatorsStatisticTableHeader'
}
</script>

<style lang="scss" scoped>
@import 'OperatorStatistic';
</style>
