const REQUEST_EMPLOYEE = 'REQUEST_EMPLOYEE'
const REQUEST_ALL_EMPLOYEE = 'REQUEST_ALL_EMPLOYEE'
const REQUEST_ALL_PERMISISONS = 'REQUEST_ALL_PERMISISONS'
const REQUEST_ALL_POSITIONS = 'REQUEST_ALL_POSITIONS'
const REQUEST_DELETE_EMPLOYEE = 'REQUEST_DELETE_EMPLOYEE'
const REQUEST_EDIT_EMPLOYEE = 'REQUEST_EDIT_EMPLOYEE'
const REQUEST_CREATE_EMPLOYEE = 'REQUEST_CREATE_EMPLOYEE'
const REQUEST_CHECK_EMAIL = 'REQUEST_CHECK_EMAIL'
const REQUEST_EMPLOYEE_FILTER = 'REQUEST_EMPLOYEE_FILTER'

export {
  REQUEST_EMPLOYEE,
  REQUEST_ALL_EMPLOYEE,
  REQUEST_ALL_PERMISISONS,
  REQUEST_ALL_POSITIONS,
  REQUEST_DELETE_EMPLOYEE,
  REQUEST_EDIT_EMPLOYEE,
  REQUEST_CREATE_EMPLOYEE,
  REQUEST_EMPLOYEE_FILTER,
  REQUEST_CHECK_EMAIL
}
