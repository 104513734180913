<template>
  <li class="purchase__item">
    <div class="purchase__name purchase__element">
      <span class="purchase__text">Покупка:</span>
      <span>{{ purchaseText(item.target) }}</span>
    </div>
    <div class="purchase__description purchase__element">
      <span class="purchase__text">Значение покупки:</span>
      <span>{{ item.target_value }}</span>
    </div>
    <div class="purchase__kind purchase__element">
      <span class="purchase__text">Стоимость:</span>
      <span>{{ item.price }} {{ moneyIcon(item.currency) }}</span>
    </div>
    <div class="purchase__points purchase__element">
      <span class="purchase__text">Код страны:</span>
      <span class="purchase__point"> {{ item.country_code }} </span>
    </div>
    <div class="purchase__points purchase__element">
      <span class="purchase__text">Дата обновления:</span>
      <span class="purchase__point">
        {{ new Date(item.date_update).toLocaleString() }}
      </span>
    </div>
    <div class="purchase__btn-wrap purchase__element">
      <button
        @click="$root.$emit('PURCHASE_CLONE_POPUP_EVENT', item)"
        class="purchase__btn purchase__btn-change purchase__btn--green"
        v-tooltip="'Скопировать'"
      >
        <i class="far fa-copy"></i>
      </button>
      <button
        @click="$root.$emit('PURCHASE_POPUP_EVENT', item)"
        class="purchase__btn purchase__btn-change purchase__btn--green"
        v-tooltip="'Редактировать'"
      >
        <i class="fa fa-edit"></i>
      </button>
      <button
        @click="$root.$emit('PURCHASE_DELETE_POPUP_EVENT', item.id)"
        class="purchase__btn purchase__btn-delete purchase__btn--red"
        v-tooltip="'Удалить'"
      >
        <i class="fa fa-times-circle"></i>
      </button>
    </div>
  </li>
</template>

<script>
import { PURCHASE_DELETE_POPUP_EVENT, PURCHASE_POPUP_EVENT } from '@/constants/event'
import getPurchases from '@/components/mixins/getPurchases'
import getIconCurrency from '@/components/mixins/getIconCurrency'

export default {
  name: 'PurchaseItem',
  data() {
    return {
      PURCHASE_DELETE_POPUP_EVENT,
      PURCHASE_POPUP_EVENT
    }
  },
  mixins: [getPurchases, getIconCurrency],
  props: {
    item: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
.purchase__text {
  display: none;
  @media (max-width: 500px) {
    display: flex;
  }
}
</style>
