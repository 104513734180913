export default {
  methods: {
    purchaseText(status) {
      let purchase = null
      switch (status) {
        case 0:
          purchase = 'Перевод чата'
          break
        case 1:
          purchase = 'Открыть все лайки одноразово'
          break
        case 2:
          purchase = 'Открыть все просмотры одноразово'
          break
        case 3:
          purchase = 'Создание приветствия'
          break
        case 4:
          purchase = 'VIP статус на N недель'
          break
        case 5:
          purchase = 'VIP статус на N недель (пробный период)'
          break
        case 6:
          purchase = 'Подарочный VIP статус на N недель'
          break
        case 7:
          purchase = 'VIP статус на N месяцев'
          break
        case 8:
          purchase = 'VIP статус на N месяцев (пробный период)'
          break
        case 9:
          purchase = 'Подарочный VIP статус на N месяцев'
          break
        case 10:
          purchase = 'VIP статус на N лет'
          break
        case 11:
          purchase = 'VIP статус на N лет (пробный период)'
          break
        case 12:
          purchase = 'Подарочный VIP статус на N лет'
          break
        case 13:
          purchase = 'Покупка баллов'
          break
        case 14:
          purchase = 'Покупка двойных баллов'
          break
        default:
          break
      }
      return purchase
    }
  }
}
