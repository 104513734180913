<template>
  <div class="user-active">
    <div class="user-active__menu">
      <div class="user-active__left">
        <span class="user-active__left-text">Дата:</span>
        <div class="datepicker-traffic-source">
          <datepicker
            class="datepicker-online"
            v-model="online_stats_form.date"
            input-class="datepicker__input"
            :language="ru"
          >
          </datepicker>
          <i class="select__field-icon fa fa-angle-down"></i>
        </div>
        <button class="user-active__btn" @click="getOnlineStats">
          Показать
        </button>
        <div @click="openUsers" class="user-online">
          <span class="">Online</span>
          <span class="user-online__icon"><i class="fas fa-users"></i></span>
        </div>
        <div class="user-online__right">
          <span class="user-online__count">{{ online_stats_current }}</span>
        </div>
      </div>
      <div class="user-active__right">
        <ServerTimeFilter :utcFlag="form.utc" @changeUtc="changeUtc($event)" />
      </div>
    </div>
    <div>
      <LineChart :chart-data="online_stats" :options="online_stats_opt" class="regenterstats-chart">
      </LineChart>
    </div>
  </div>
</template>

<script>
import LineChart from './LineChart'
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import { ru } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import 'chartjs-plugin-datalabels'
import { REQUEST_USERS_ONLINE_ONE_DAY } from '@/store/action-types'
import { mapActions } from 'vuex'
import ServerTimeFilter from '@/components/Statistic/Servertimefilter/ServerTimeFilter'
export default {
  name: 'StatisticOnline',
  components: {
    LineChart,
    Datepicker,
    ServerTimeFilter
  },
  props: {
    onlineByDay: {
      type: Object
    }
  },
  data: () => ({
    ru: ru,
    form: {
      type: 0,
      utc: 1
    },
    online_stats: {
      labels: [],
      datasets: [
        {
          borderColor: 'rgba(255,108,96,0)',
          backgroundColor: 'rgba(255,108,96,0.5)',
          fill: true,
          label: 'Женщины',
          data: [],
          datalabels: {
            display: false
          }
        },
        {
          borderColor: 'rgba(151,187,205,0)',
          backgroundColor: 'rgba(151,187,205,0.5)',
          fill: true,
          label: 'Мужчины',
          data: []
        },
        {
          borderColor: 'rgba(75,192,192,1)',
          backgroundColor: 'rgba(75,192,192,1)',
          borderDash: [5, 5],
          fill: false,
          label: 'Всего',
          data: []
        }
      ]
    },
    online_stats_opt: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'label',
        itemSort: function(a, b) {
          return b.datasetIndex - a.datasetIndex
        },
        intersect: false,
        caretSize: 0
      },
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: false,
              callback: function(tick) {
                return /^[0-9]{2}:(00)$/.test(tick) ? tick : ''
              },
              maxRotation: 0
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              maxTicksLimit: 8,
              beginAtZero: true,
              maxRotation: 0
            }
          }
        ]
      },
      elements: {
        point: {
          radius: 0
        }
      },
      plugins: {
        datalabels: {
          display: false
        }
      }
    },
    online_stats_current: 0,
    online_stats_form: {
      date: moment().format('YYYY-MM-DD')
    }
  }),
  watch: {
    'form.type': function() {
      this.getOnlineStats()
    },
    'form.utc': function() {
      this.getOnlineStats()
    }
  },
  mounted() {
    this.reLoaded()
    this.setDefaultOnlineDate()
  },
  methods: {
    ...mapActions({
      getUserOnline: REQUEST_USERS_ONLINE_ONE_DAY
    }),
    setDefaultOnlineDate: function() {
      this.online_stats_form.date = moment().format('YYYY-MM-DD')
    },
    openUsers() {
      let route = this.$router.resolve({
        name: 'Users',
        query: {
          online: 1,
          sort: 'user_online:desc',
          operator: 0,
          page: 0,
          delta: new Date().getTimezoneOffset() / -60
        }
      })
      window.open(route.href, '_blank')
    },
    getOnlineStats() {
      this.getUserOnline({
        date: moment(this.online_stats_form.date).format('YYYY-MM-DD'),
        delta: this.form.utc ? new Date().getTimezoneOffset() / -60 : null
      }).then(response => {
        this.reLoaded()
      })
    },
    reLoaded() {
      this.online_stats.labels = []
      this.online_stats.datasets[0].data = []
      this.online_stats.datasets[1].data = []
      this.online_stats.datasets[2].data = []
      this.online_stats_current = this.onlineByDay.count_online
      if (this.onlineByDay.period) {
        let period = this.onlineByDay.period
        for (let key in period) {
          this.online_stats.labels.push(moment(key).format('HH:mm'))
          this.online_stats.datasets[0].data.push(period[key].w)
          this.online_stats.datasets[1].data.push(period[key].m)
          this.online_stats.datasets[2].data.push(period[key].t)
        }
      }
    },
    changeUtc(number) {
      this.form.utc = number
    }
  }
}
</script>

<style lang="scss">
@import './Statistic.scss';
</style>
