<template>
  <div class="moderators-payments__header">
    <span class="moderators-payments__header-title">Платежи модераторам</span>
    <div class="moderators-payments__btns">
      <button
        class="moderators-payments__btn moderators-payments__btn--billall"
        @click="$root.$emit(BILL_ALL_MODERATOR_POPUP_EVENT)"
      >
        Оплатить
        <i class="far fa-money-bill-alt"></i>
      </button>
      <button
        class="moderators-payments__btn moderators-payments__btn--prizeall"
        @click="$root.$emit(PRIZE_ALL_MODERATOR_POPUP_EVENT)"
      >
        Премия
        <i class="fas fa-award"></i>
      </button>
      <button
        class="moderators-payments__btn moderators-payments__btn--setting"
        @click="$root.$emit(SETTING_MODERATOR_POPUP_EVENT)"
      >
        Настройки
        <i class="fa fa-cog"></i>
      </button>
    </div>
  </div>
</template>

<script>
import {
  SETTING_MODERATOR_POPUP_EVENT,
  BILL_ALL_MODERATOR_POPUP_EVENT,
  PRIZE_ALL_MODERATOR_POPUP_EVENT
} from '@/constants/event'

export default {
  name: 'ModeratorsPaymentsHeaders',
  data() {
    return {
      SETTING_MODERATOR_POPUP_EVENT,
      BILL_ALL_MODERATOR_POPUP_EVENT,
      PRIZE_ALL_MODERATOR_POPUP_EVENT
    }
  }
}
</script>
<style lang="scss">
@import 'ModeratorsPayments';
</style>
