<template>
  <div>
    <h2 class="statistics__title">{{ statsTitle }}</h2>
    <div class="online-country">
      <div class="online-country__wrapper">
        <div>
          <button
            class="user-active__btn user-active__btn--default user-active__btn--left "
            @click="toggleStat('years')"
            :class="[statsActive === 'years' ? 'user-active__btn--danger' : '']"
          >
            По возрасту
          </button>
          <button
            class="user-active__btn user-active__btn--default user-active__btn--right "
            @click="toggleStat('group')"
            :class="[statsActive === 'group' ? 'user-active__btn--danger' : '']"
          >
            Соотношение в группе
          </button>
        </div>
      </div>
      <div>
        <BarChart :chart-data="statsData" :options="statsOpt" class="regenterstats-chart"></BarChart>
      </div>
    </div>
  </div>
</template>

<script>
import 'chartjs-plugin-datalabels'
import BarChart from '@/components/Statistic/BarChart'
import { mapActions } from 'vuex'
import { REQUEST_USERS_AGE } from '@/store/action-types'

export default {
  name: 'StatisticUsersAge',
  components: {
    BarChart
  },
  props: {
    usersAge: {
      type: Object
    }
  },
  data: () => ({
    statsActive: 'years',
    yearsStats: {
      labels: [],
      datasets: [
        {
          borderColor: 'rgba(255,108,96,1)',
          backgroundColor: 'rgba(255,108,96,0.7)',
          fill: true,
          label: 'Женщины',
          data: []
        },
        {
          borderColor: 'rgba(151,187,205,1)',
          backgroundColor: 'rgba(151,187,205,0.7)',
          fill: true,
          label: 'Мужчины',
          data: []
        }
      ],
      count: [[], []]
    },
    groupStats: {
      labels: [],
      datasets: [
        {
          borderColor: 'rgba(255,108,96,1)',
          backgroundColor: 'rgba(255,108,96,0.7)',
          fill: true,
          label: 'Женщины',
          data: []
        },
        {
          borderColor: 'rgba(151,187,205,1)',
          backgroundColor: 'rgba(151,187,205,0.7)',
          fill: true,
          label: 'Мужчины',
          data: []
        }
      ],
      count: [[], []]
    },
    statsOpt: {
      scales: {
        yAxes: [
          {
            stacked: false,
            beforeBuildTicks: function(axis) {
              return (axis.max = axis.max + 1)
            },
            ticks: {
              suggestedMax: 10,
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }
        ],
        xAxes: [
          {
            stacked: false,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            },
            maxBarThickness: 100
          }
        ]
      },
      legend: {
        display: true
      },
      tooltips: {
        enabled: true,
        mode: 'label',
        callbacks: {
          label: function(tooltipItem, data) {
            const count = data.count[tooltipItem.datasetIndex][tooltipItem.index]

            return [data.datasets[tooltipItem.datasetIndex].label + ': ' + count]
          }
        }
      },
      plugins: {
        datalabels: {
          font: {
            size: 12
          },
          padding: {
            bottom: -10
          },
          anchor: 'end',
          align: 'end',
          formatter: function(value) {
            return value + '%'
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      height: 200
    }
  }),
  computed: {
    statsData: function() {
      return this.statsActive === 'years' ? this.yearsStats : this.groupStats
    },
    statsTitle: function() {
      return this.statsActive === 'years'
        ? 'Статистика по возрасту'
        : 'Соотношение в каждой возрастной группе'
    }
  },

  mounted() {
    this.reLoaded()
  },

  methods: {
    ...mapActions({
      getUsersAge: REQUEST_USERS_AGE
    }),
    //Данная функция необходима для переноса ключа возраста "56" со своими значениями в конец списка
    changeOrderArr(arr) {
      let [first, ...rest] = arr
      return (arr = [...rest, first])
    },
    reLoaded() {
      let labels = {
        years: [],
        group: []
      }

      for (let row of Object.keys(this.usersAge.stats_on_group)) {
        labels.group.push(row)
      }
      for (let row of Object.keys(this.usersAge.stats_on_age)) {
        labels.years.push(row)
      }
      let male = {
        years: { percent: [], count: [] },
        group: { percent: [], count: [] }
      }
      let female = {
        years: { percent: [], count: [] },
        group: { percent: [], count: [] }
      }

      for (let row of Object.values(this.usersAge.stats_on_group)) {
        male.group.count.push(row.count_men)
        male.group.percent.push(row.men)
        female.group.count.push(row.count_woman)
        female.group.percent.push(row.woman)
      }
      for (let row of Object.values(this.usersAge.stats_on_age)) {
        male.years.count.push(row.count_men)
        male.years.percent.push(row.men)
        female.years.count.push(row.count_woman)
        female.years.percent.push(row.woman)
      }

      labels.group = this.changeOrderArr(labels.group)
      labels.years = this.changeOrderArr(labels.years)
      male.group.count = this.changeOrderArr(male.group.count)
      male.group.percent = this.changeOrderArr(male.group.percent)
      female.group.count = this.changeOrderArr(female.group.count)
      female.group.percent = this.changeOrderArr(female.group.percent)

      male.years.count = this.changeOrderArr(male.years.count)
      male.years.percent = this.changeOrderArr(male.years.percent)
      female.years.count = this.changeOrderArr(female.years.count)
      female.years.percent = this.changeOrderArr(female.years.percent)

      this.yearsStats.labels = labels.years
      this.yearsStats.datasets[0].data = female.years.percent
      this.yearsStats.datasets[1].data = male.years.percent
      this.yearsStats.count[0] = female.years.count
      this.yearsStats.count[1] = male.years.count

      this.groupStats.labels = labels.group
      this.groupStats.datasets[0].data = female.group.percent
      this.groupStats.datasets[1].data = male.group.percent
      this.groupStats.count[0] = female.group.count
      this.groupStats.count[1] = male.group.count
    },
    toggleStat: function(active) {
      if (this.statsActive !== active) {
        this.statsActive = active
      }
      this.reLoaded()
    }
  }
}
</script>

<style lang="scss">
@import './Statistic.scss';
.online-country {
  padding: 16px;
}
.online-country__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
</style>
