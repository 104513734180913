<template>
  <div class="utm-market__item">
    <div class="utm-market__item-body">
      <div class="utm-market__item-content">
        <div class="utm-market__item-name">
          <span class="utm-market__item-title">{{ utm.utm_name }}</span>
        </div>
        <div class="utm-market__item-name">
          <span class="utm-market__item-title">{{ utm.utm_source }}</span>
        </div>
        <div class="utm-market__item-name">
          <span class="utm-market__item-title">{{ utm.utm_platform }}</span>
        </div>
        <div class="utm-market__item-name">
          <div class="color_scheme" v-bind:style="bgColor"></div>
        </div>
        <div class="utm-market__item-btns">
          <button class="utm-market__item-btn utm-blue" @click="$root.$emit(MARKET_UTM_UPDATE_POPUP, utm)">
            <i class="utm-market__item-btn-icon fa fa-edit"></i>
          </button>
          <button class="utm-market__item-btn utm-red" @click="$root.$emit(MARKET_UTM_DELETE_POPUP, utm.id)">
            <i class="utm-market__item-btn-icon fa fa-times-circle"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="utm-market__line" v-bind:style="bgColor"></div>
  </div>
</template>
<script>
import { MARKET_UTM_DELETE_POPUP, MARKET_UTM_UPDATE_POPUP } from '@/constants/event'
export default {
  data() {
    return {
      MARKET_UTM_DELETE_POPUP,
      MARKET_UTM_UPDATE_POPUP
    }
  },
  name: 'MarketUtmItem',
  props: {
    utm: {
      required: true,
      type: Object
    }
  },
  computed: {
    bgColor() {
      return {
        backgroundColor: this.utm.utm_color_scheme
      }
    }
  }
}
</script>

<style lang="scss">
@import './MarketUtm.scss';
.utm-red {
  color: #ff4a4f;
}
.utm-blue {
  color: #689bcc;
}
.color_scheme {
  width: 50px;
  height: 15px;
  margin-left: -8%;
  background-color: #000;
}

.utm-market__line {
  display: none;
  position: relative;
  top: 10px;
  height: 8px;
  border-radius: 0 0 8px 8px;
  @media (max-width: 425px) {
    display: block;
  }
}
</style>
