const GET_MODERATORS = 'GET_MODERATORS'
const GET_MODERATORS_CURRENCY = 'GET_MODERATORS_CURRENCY'
const GET_MODERATOR_ROLES = 'GET_MODERATOR_ROLES'
const GET_MODERATOR_TMP_ROLES = 'GET_MODERATOR_TMP_ROLES'
const GET_MODERATOR_ROLES_DEFAULT = 'GET_MODERATOR_ROLES_DEFAULT'
const GET_MODERATOR_SEARCH_OPTION = 'GET_MODERATOR_SEARCH_OPTION'
const GET_MODERATOR_STATISTIC = 'GET_MODERATOR_STATISTIC'
const GET_CURRENT_MODERATOR = 'GET_CURRENT_MODERATOR'
const GET_MODERATOR_PAYOUT = 'GET_MODERATOR_PAYOUT'
const GET_MODERATOR_DEFAULT_PAYMENT_SETTING = 'GET_MODERATOR_DEFAULT_PAYMENT_SETTING'

export {
  GET_MODERATORS,
  GET_MODERATORS_CURRENCY,
  GET_MODERATOR_ROLES,
  GET_MODERATOR_TMP_ROLES,
  GET_MODERATOR_ROLES_DEFAULT,
  GET_MODERATOR_SEARCH_OPTION,
  GET_MODERATOR_STATISTIC,
  GET_CURRENT_MODERATOR,
  GET_MODERATOR_PAYOUT,
  GET_MODERATOR_DEFAULT_PAYMENT_SETTING
}
