import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import ModeratorPaymentsService from '@/services/ModeratorPaymentsService'

export default {
  [ActionTypes.REQUEST_MODERATOR_PAYMENTS_SETTING]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorPaymentsService, [userToken]).getModeratorPayments()
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_DEFAULT_PAYMENT_MODERATOR_OPTION, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_DELETE_MODERATOR_PAYMENTS_SETTING]: async (
    { rootGetters, commit, dispatch },
    data
  ) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorPaymentsService, [userToken]).deleteModeratorPayment(
      data
    )
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_MODERATOR_PAYMENTS_SETTING)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_MODERATOR_DEL_PAYMENTS_ACTIVE]: async ({ rootGetters, commit }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })

    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorPaymentsService, [userToken]).editModeratorPayment(data)

    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_MODERATOR_EDIT_PAYMENTS_SETTING]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })

    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorPaymentsService, [userToken]).editModeratorPayment(data)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_MODERATOR_PAYMENTS_SETTING)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_MODERATOR_CREATE_PAYMENTS_SETTING]: async (
    { rootGetters, commit, dispatch },
    data
  ) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorPaymentsService, [userToken]).addModeratorPayment(data)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_MODERATOR_PAYMENTS_SETTING)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_ALL_MODERATORS]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorPaymentsService, [userToken]).getModerators()
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_MODERATORS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_MODERATOR_STATISTIC]: async ({ rootGetters, commit }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorPaymentsService, [userToken]).getModeratorStatistic(id)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_MODERATOR_STATISTIC, data.stat)
      commit(MutationTypes.SET_CURRENT_MODERATOR, data.info)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_MODERATOR_PAYOUT]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorPaymentsService, [userToken]).getModeratorsPayout(
      formData
    )
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_MODERATOR_PAYOUT, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_BILLING_MODERATORS]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorPaymentsService, [userToken]).billingModerator(
      data.data
    )
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_MODERATOR_PAYOUT, data.active)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_PRIZE_MODERATORS]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorPaymentsService, [userToken]).prizeModerator(data.data)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_MODERATOR_PAYOUT, data.active)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CORRECTION_MODERATORS]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorPaymentsService, [userToken]).correctionModerator(
      data.data
    )
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_MODERATOR_PAYOUT, data.active)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EXECUTE_MODERATORS]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ModeratorPaymentsService, [userToken]).executeModerator(
      data.data
    )
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_MODERATOR_PAYOUT, data.active)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
