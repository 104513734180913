<template>
  <div class="department">
    <div class="department__field department__field--avatar">
      <span class="department__name">
        <span class="department__text">{{
          permission.permission_group ? permission.permission_group : 'Группа не выбрана'
        }}</span>
      </span>
    </div>
    <div class="department__field department__field--avatar">
      <span class="department__name">
        <span class="department__text">{{ permission.permission_name }}</span>
      </span>
    </div>
    <div class="department__field department__field--code">
      <span class="department__name">
        <span class="department__text">{{ permission.permission_level }}</span>
      </span>
    </div>
    <div class="department__field department__field--btns">
      <button
        @click="$root.$emit(EDIT_PERMISSION_POPUP_EVENT, permission)"
        class="department__btn btn-blue department__btn--blue"
      >
        <i class="fa fa-edit"></i>
      </button>
      <button
        @click="$root.$emit(DELETE_PERMISSION_POPUP_EVENT, permission.id)"
        class="department__btn btn-red department__btn--red"
      >
        <i class="fa fa-times-circle"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { EDIT_PERMISSION_POPUP_EVENT, DELETE_PERMISSION_POPUP_EVENT } from '@/constants/event'
export default {
  name: 'Permission',
  props: {
    permission: {
      type: Object
    }
  },
  data: () => ({
    EDIT_PERMISSION_POPUP_EVENT,
    DELETE_PERMISSION_POPUP_EVENT
  }),
  computed: {
    rightName() {
      return `${this.permission.permission_name.split('|')[0]} | ${
        this.permission.permission_name.split('|')[1]
      }`
    }
  }
}
</script>
<style lang="scss" scoped>
@import './Permission.scss';
</style>
