import { render, staticRenderFns } from "./SupportContactTypeAddPopup.vue?vue&type=template&id=f803cb64&scoped=true&"
import script from "./SupportContactTypeAddPopup.vue?vue&type=script&lang=js&"
export * from "./SupportContactTypeAddPopup.vue?vue&type=script&lang=js&"
import style0 from "./SupportContactTypeAddPopup.vue?vue&type=style&index=0&id=f803cb64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f803cb64",
  null
  
)

export default component.exports