import * as GetterTypes from './getter-types'
export default {
  [GetterTypes.GET_USERS]: ({ users }) => users,

  [GetterTypes.GET_USER_COUNTS]: ({ userCount }) => userCount,

  [GetterTypes.GET_USER_FILTER_COUNTS]: ({ userFilterCount }) => userFilterCount,

  [GetterTypes.GET_USER_DEPOSIT]: ({ userDeposit }) => userDeposit.reverse(),

  [GetterTypes.GET_USER_PAYOUTS]: ({ userPayouts }) => userPayouts.reverse(),

  [GetterTypes.GET_USER_PURCHASES]: ({ userPurchases }) => userPurchases,

  [GetterTypes.GET_USER_PHOTOS]: ({ userPhotos }) => userPhotos,

  [GetterTypes.GET_USER_CHATS]: ({ userChats }) => userChats,

  [GetterTypes.GET_CHAT_MESSAGES]: ({ userChatMessages }) => userChatMessages,

  [GetterTypes.GET_CURRENT_USER]: ({ currentUser }) => currentUser,

  [GetterTypes.GET_VALUE_PICKER]: ({ tmpDateFilter }) => tmpDateFilter,

  [GetterTypes.GET_FILTERS_INFO]: ({ filtersUserInfo }) => filtersUserInfo,

  [GetterTypes.GET_FILTERS_DEFAULT]: ({ filterUsersDefault }) => filterUsersDefault,

  [GetterTypes.GET_TMP_FILERS]: ({ tmpUserFilters }) => tmpUserFilters,

  [GetterTypes.GET_CURRENT_FILERS]: ({ currentFilter }) => currentFilter,

  [GetterTypes.GET_TRANSACTION_KIND]: ({ userTransaction }) => userTransaction,

  [GetterTypes.GET_USER_GENDER]: ({ userGender }) => value => {
    if (value !== undefined && value !== null) return userGender.find(item => item.value === value)
    else return ''
  },
  [GetterTypes.GET_FILTER_PAGE]: ({ searchFilters }) => searchFilters,

  [GetterTypes.GET_USERCHATS_STYLES]: ({ userChatsSyles }) => userChatsSyles
}
