<template>
  <div class="popup__item">
    <div>{{ eventarr.name }}</div>
    <div v-for="(item, index) in eventarr.payload" :key="index">
      <input
        class="popup__input default-input refbook-input"
        type="text"
        name="name"
        v-model="item.value"
        required
      />
      <button type="button" @click="deleteValue(index)">
        <i class="fa fa-times-circle"></i>
      </button>
    </div>
    <button type="button" @click="addValue" title="Добавить value">
      <i class="fa fa-plus-square"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'EventRefBookAddItem',
  props: {
    eventarr: {
      required: true
    }
  },
  methods: {
    addValue() {
      return this.eventarr.payload.push({
        key: this.eventarr.payload.length,
        value: ''
      })
    },
    deleteValue(index) {
      return this.eventarr.payload.splice(index, 1)
    }
  }
}
</script>
