<template>
  <div class="InterfaceAchievement">
    <AchievementsHeader />
    <AchievementTableHeader />
    <AchievementsList :achievements="achievements" v-if="isLoaded && achievements.length" />
    <div class="app-no-achievements" v-if="!achievements.length && isLoaded">
      <span class="app-no-achievements__text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="app-no-achievements">
      <span class="app-no-achievements__text">Ошибка при загрузке достижений</span>
    </div>
    <AchievementAddPopUp />
    <AchievementEditPopUp />
    <AchievementDeletePopUp />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types'
import { GET_ACHIEVEMENTS } from '@/store/getter-types'
import { REQUEST_ACHIEVEMENTS } from '@/store/action-types'
import AchievementsList from '@/components/Achievements/AchievementsList.vue'
import AchievementsHeader from '@/components/Achievements/AchievementsHeader.vue'
import AchievementEditPopUp from '@/components/Modals/AchievementPopup/AchievementEditPopUp'
import AchievementAddPopUp from '@/components/Modals/AchievementPopup/AchievementAddPopUp'
import AchievementDeletePopUp from '@/components/Modals/AchievementPopup/AchievementDeletePopUp'
import AchievementTableHeader from '@/components/Achievements/TableHeader/AchievementTableHeader.vue'
import store from '@/store'

export default {
  name: 'InterfaceAchievement',
  components: {
    AchievementsList,
    AchievementsHeader,
    AchievementAddPopUp,
    AchievementEditPopUp,
    AchievementDeletePopUp,
    AchievementTableHeader
  },
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },
  methods: {
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    })
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_ACHIEVEMENTS).then(response => {
      next(vm => {
        if (response.status) {
          vm.setCurrentLang('RU')
        }
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      achievements: GET_ACHIEVEMENTS
    })
  }
}
</script>

<style lang="sass">
@import "@/components/Achievements/Achievement.scss"
</style>
