import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_OPERATORS]: (state, payload) => {
    state.operators = payload
  },

  [MutationTypes.SET_CURRENT_OPERATOR]: (state, payload) => {
    state.currentOperator = payload
  },

  [MutationTypes.SET_OPERATOR_STATISTIC]: (state, payload) => {
    state.operatorStatistic = payload
  },

  [MutationTypes.SET_OPERATOR_PAYOUT]: (state, payload) => {
    state.operatorPayout = payload
  },

  [MutationTypes.SET_OPERATOR_USERS]: (state, payload) => {
    // state.currentOperator.map(el => {
    //   el.accounts.map(item => {
    //     payload.filter(f => f.id !== item.id)
    //   })
    // })
    state.operatorUsers = payload
  },

  [MutationTypes.SET_SEARCH_OPTIONS]: (state, payload) => {
    state.searchOptions = payload
  },

  [MutationTypes.SET_ADD_SEARCH_OPTIONS]: (state, payload) => {
    if (payload !== null) {
      state.searchOptions = state.searchOptions.concat(payload)
    } else {
      state.searchOptions = []
    }
  },

  [MutationTypes.SET_EDIT_SEARCH_OPTIONS]: (state, payload) => {
    let indexOption = state.searchOptions.findIndex(f => f === payload.old)
    state.searchOptions.splice(indexOption, 1, payload.new)
  },

  [MutationTypes.SET_DEL_SEARCH_OPTIONS]: (state, payload) => {
    state.searchOptions = state.searchOptions.filter(f => f !== payload)
  },

  [MutationTypes.SET_OPERATOR_CURRENT_USER]: (state, payload) => {
    if (payload !== null) {
      state.currentOperatorUser = state.currentOperatorUser.concat(payload)
    } else {
      state.currentOperatorUser = []
    }
  },

  [MutationTypes.SET_OPERATOR_CURRENT_USER_DEL]: (state, payload) => {
    state.currentOperatorUser = state.currentOperatorUser.filter(f => f.id !== payload)
  },

  [MutationTypes.SET_OPERATOR_SETTINGS]: (state, payload) => {
    const { key, value } = payload
    const settingObj = {
      key,
      ...value
    }
    state.operatorTmpRoles[key] = settingObj
  },
  [MutationTypes.SET_TMP_FILTERS_OPERATORS]: (state, payload) => {
    const { key, value } = payload
    const newObject = {
      key,
      ...value
    }
    state.operatorFilter[key] = newObject
  },
  [MutationTypes.SET_RESET_FILTERS_OPERATORS]: state => {
    for (const field in state.operatorFilter) {
      state.operatorFilter[field] = {}
    }
  },

  [MutationTypes.SET_RESET_FILTERS_OPERATORS_OPERATOR]: state => {
    state.operatorFilter.operatorTransfer = {}
  },

  [MutationTypes.SET_CLEAR_OPERATOR_DATA]: state => {
    for (const key in state.operatorTmpRoles) {
      state.operatorTmpRoles[key] = {}
    }
  },

  [MutationTypes.SET_OPERATOR_BANNER]: (state, payload) => {
    state.operatorBanner = payload
  },

  [MutationTypes.SET_EDIT_OPERATOR_BANNER]: (state, payload) => {
    state.operatorBanner.push(payload)
  },

  [MutationTypes.SET_OPERATORS_AUTO_MESSAGE]: (state, payload) => {
    payload.sort((a, b) => a.delay_seconds - b.delay_seconds)
    state.operatorAutoMessage = payload
  },

  [MutationTypes.SET_ACTIVE_OPERATOR]: (state, payload) => {
    state.activeOper = payload
  }
}
