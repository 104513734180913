<template>
  <div class="event-my-wrap">
    <div class="ev-cat" v-if="getAllEventRefBook && isLoaded">
      <EventRefBookHeader :eventref="getAllEventRefBook" />
      <div class="ev-cat__list">
        <EventRefBook :eventref="getAllEventRefBook" />
      </div>
    </div>
    <EventRefBookDeletePopup />
    <EventRefBookAddPopup />
    <EventRefBookUpdatePopup />
    <div v-if="errorMessage" class="app-no-events">
      <span class="app-no-events__text">Ошибка при загрузке моих событий</span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types'
import { GET_EVENTS_REFBOOK } from '@/store/getter-types'
import { REQUEST_ALL_EVENT_REFBOOK } from '@/store/action-types'
import EventRefBookHeader from '@/components/Events/EventRefBookHeader'
import EventRefBook from '@/components/Events/EventRefBook'
import EventRefBookUpdatePopup from '@/components/Modals/EventPopup/EventRefBookUpdatePopup'
import EventRefBookDeletePopup from '@/components/Modals/EventPopup/EventRefBookDeletePopup'
import EventRefBookAddPopup from '@/components/Modals/EventPopup/EventRefBookAddPopup'
import store from '@/store'
export default {
  name: 'EventMy',
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },
  components: {
    EventRefBookHeader,
    EventRefBook,
    EventRefBookUpdatePopup,
    EventRefBookDeletePopup,
    EventRefBookAddPopup
  },
  computed: {
    ...mapGetters({
      getAllEventRefBook: GET_EVENTS_REFBOOK
    })
  },
  methods: {
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    })
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_ALL_EVENT_REFBOOK).then(response => {
      next(vm => {
        if (response) {
          vm.setCurrentLang('RU')
        }
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
.event-my-wrap {
  padding: 16px;
}
.app-no-events {
  min-height: 64px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  &__text {
    margin: 0 auto;
  }
}
@import '@/components/Events/EventCategory.scss';
</style>
