import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import PositionService from '@/services/PositionService'

export default {
  [ActionTypes.REQUEST_CONTROL_POSITIONS]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(PositionService, [userToken]).getPositions()
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_POSITIONS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CREATE_POSITION]: async ({ rootGetters, commit, dispatch }, position) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(PositionService, [userToken]).createPosition(position)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_CONTROL_POSITIONS)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_DELETE_POSITION]: async ({ rootGetters, commit, dispatch }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(PositionService, [userToken]).deletePositionById(id)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_CONTROL_POSITIONS)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_UPDATE_POSITION]: async ({ rootGetters, commit, dispatch }, updatedeposition) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(PositionService, [userToken]).updatePosition(updatedeposition)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_CONTROL_POSITIONS)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
