//user
const USER_VERIFY_EVENT = 'USER_VERIFY_EVENT'
const USER_GET_VERIFY = 'USER_GET_VERIFY'
const USER_DECLINE_BY_AGE = 'USER_DECLINE_BY_AGE'
const USER_BLOCK_MAIN_PHOTO_EVENT = 'USER_BLOCK_MAIN_PHOTO_EVENT'
const USER_SEND_ALERT_EVENT = 'USER_SEND_ALERT_EVENT'
const USER_VIP_EVENT = 'USER_VIP_EVENT'
const USER_VIP_CALL = 'USER_VIP_CALL'
const USER_RATING_EVENT = 'USER_RATING_EVENT'
const USER_EVENTS_RATING_EVENT = 'USER_EVENTS_RATING_EVENT'
const USER_SCORE_EVENT = 'USER_SCORE_EVENT'
const USER_MAP_EVENT = 'USER_MAP_EVENT'
const USER_GENDER_EVENT = 'USER_GENDER_EVENT'
const USER_PROFILE_BAN_EVENT = 'USER_PROFILE_BAN_EVENT'
const USER_DEVICE_BAN_EVENT = 'USER_DEVICE_BAN_EVENT'
const USER_DELETE_EVENT = 'USER_DELETE_EVENT'
const USER_PANEL_EVENT = 'USER_PANEL_EVENT'
const USER_APP_EVENT = 'USER_APP_EVENT'
const USER_DELETE_PHOTO = 'USER_DELETE_PHOTO'
const USER_BUY_LIST_PURCHASES = 'USER_BUY_LIST_PURCHASES'
const USER_TRANSACTION_KIND_LIST = 'USER_TRANSACTION_KIND_LIST'
const USER_HIDE_MAP = 'USER_HIDE_MAP'
//user actions
const USER_PHOTO_ACTION_PHOTO_STATUS_EVENT = 'USER_PHOTO_ACTION_PHOTO_STATUS_EVENT'
//views mobile app
const VIEW_ADD_POPUP_RECORD = 'VIEW_ADD_POPUP_RECORD'
const VIEW_DELETE_POPUP_RECORD = 'VIEW_DELETE_POPUP_RECORD'
//control employee
const EMPLOYEE_POPUP_EVENT = 'EMPLOYEE_POPUP_EVENT'
const EDIT_EMPLOYEE_POPUP_EVENT = 'EDIT_EMPLOYEE_POPUP_EVENT'
const DELETE_EMPLOYEE_POPUP_EVENT = 'DELETE_EMPLOYEE_POPUP_EVENT'
const EMAIL_ERROR_POPUP = 'EMAIL_ERROR_POPUP'
//control department
const NEW_DEPARTMENT_POPUP_EVENT = 'NEW_DEPARTMENT_POPUP_EVENT'
const EDIT_DEPARTMENT_POPUP_EVENT = 'EDIT_DEPARTMENT_POPUP_EVENT'
const DELETE_DEPARTMENT_POPUP_EVENT = 'DELETE_DEPARTMENT_POPUP_EVENT'
//control permission
const NEW_PERMISSION_POPUP_EVENT = 'NEW_PERMISSION_POPUP_EVENT'
const DELETE_PERMISSION_POPUP_EVENT = 'DELETE_PERMISSION_POPUP_EVENT'
const EDIT_PERMISSION_POPUP_EVENT = 'EDIT_PERMISSION_POPUP_EVENT'
//control positions
const NEW_POSITION_POPUP_EVENT = 'NEW_POSITION_POPUP_EVENT'
const EDIT_POSITION_POPUP_EVENT = 'EDIT_POSITION_POPUP_EVENT'
const DELETE_POSITION_POPUP_EVENT = 'DELETE_POSITION_POPUP_EVENT'
//event
const EVENT_CATEGORY_ADD_POPUP = 'EVENT_CATEGORY_ADD_POPUP'
const EVENT_CATEGORY_NAME_POPUP = 'EVENT_CATEGORY_NAME_POPUP'
const EVENT_CATEGORY_IMAGE_POPUP = 'EVENT_CATEGORY_IMAGE_POPUP'
const EVENT_SUBCATEGORY_ADD_POPUP = 'EVENT_SUBCATEGORY_ADD_POPUP'
const EVENT_SUBCATEGORY_NAME_POPUP = 'EVENT_SUBCATEGORY_NAME_POPUP'
const EVENT_SUBCATEGORY_IMAGE_POPUP = 'EVENT_SUBCATEGORY_IMAGE_POPUP'
const EVENT_CATEGORY_DELETE = 'EVENT_CATEGORY_DELETE'
const EVENT_SUBCATEGORY_DELETE = 'EVENT_SUBCATEGORY_DELETE'
const EVENT_REFBOOK_ADD_POPUP = 'EVENT_REFBOOK_ADD_POPUP'
const EVENT_REFBOOK_DELETE = 'EVENT_REFBOOK_DELETE'
const EVENT_REFBOOK_UPDATE_POPUP = 'EVENT_REFBOOK_UPDATE_POPUP'
const EVENT_DELETE_POPUP = 'EVENT_DELETE_POPUP'

//achievements
const ACHIEVEMENT_POPUP_EVENT = 'ACHIEVEMENT_POPUP_EVENT'
const ACHIEVEMENT_ADD_POPUP_EVENT = 'ACHIEVEMENT_ADD_POPUP_EVENT'
const ACHIEVEMENT_DELETE_POPUP_EVENT = 'ACHIEVEMENT_DELETE_POPUP_EVENT'
const POINTS_POPUP_EVENT = 'POINTS_POPUP_EVENT'
const POINTS_POPUP_ADD_EVENT = 'POINTS_POPUP_ADD_EVENT'
const POINTS_DELETE_POPUP_EVENT = 'POINTS_DELETE_POPUP_EVENT'

// FAQ
const FAQ_POPUP_NEW_QUESTIONS_EVENT = 'FAQ_POPUP_NEW_QUESTIONS_EVENT'
const FAQ_POPUP_NEW_CATEGORY_EVENT = 'FAQ_POPUP_NEW_CATEGORY_EVENT'
const FAQ_POPUP_UPDATE_CATEGORY_EVENT = 'FAQ_POPUP_UPDATE_CATEGORY_EVENT'
const FAQ_POPUP_UPDATE_QUESTION_EVENT = 'FAQ_POPUP_UPDATE_QUESTION_EVENT'
const FAQ_POPUP_REMOVE_ELEMENT_QUESTION = 'FAQ_POPUP_REMOVE_ELEMENT_QUESTION'
const FAQ_POPUP_REMOVE_ELEMENT_CATEGORY = 'FAQ_POPUP_REMOVE_ELEMENT_CATEGORY'

// Gifts Event
const GIFTS_ENEVT_ADD_NEW_EVENT = 'GIFTS_ENEVT_ADD_NEW_EVENT'
const GIFTS_ENEVT_UPDATE_PERCENT_LOWER_EVENT = 'GIFTS_ENEVT_UPDATE_PERCENT_LOWER_EVENT'
const GIFTS_ENEVT_UPDATE_PERCENT_GREAT_EVENT = 'GIFTS_ENEVT_UPDATE_PERCENT_GREAT_EVENT'
const GIFTS_ENEVT_UPDATE_LOWER_EVENT = 'GIFTS_ENEVT_UPDATE_LOWER_EVENT'
const GIFTS_ENEVT_UPDATE_GREAT_EVENT = 'GIFTS_ENEVT_UPDATE_GREAT_EVENT'
const GIFTS_ENEVT_DETELE_LOWER_EVENT = 'GIFTS_ENEVT_DETELE_LOWER_EVENT'
const GIFTS_ENEVT_DETELE_GREAT_EVENT = 'GIFTS_ENEVT_DETELE_GREAT_EVENT'

// Gifts
const GIFT_ADD_POPUP_EVENT = 'GIFTS_ADD_POPUP_EVENT'
const GIFT_DELETE_POPUP_EVENT = 'GIFT_DELETE_POPUP_EVENT'
const GIFT_UPDATE_POPUP_EVENT = 'GIFT_UPDATE_POPUP_EVENT'
//Reason Delete
const REASON_DELETE_POPUP = 'REASON_DELETE_POPUP'
const REASON_DELETE_ADD_POPUP = 'REASON_DELETE_ADD_POPUP'
const REASON_BLOCK_POPUP = 'REASON_BLOCK_POPUP'
const REASON_BLOCK_ADD_POPUP = 'REASON_BLOCK_ADD_POPUP'
//Support contact types
const SUPPORT_CONTACT_ADD_POPUP = 'SUPPORT_CONTACT_ADD_POPUP'
const SUPPORT_CONTACT_EDIT_POPUP = 'SUPPORT_CONTACT_EDIT_POPUP'
const SUPPORT_CONTACT_DELETE_POPUP = 'SUPPORT_CONTACT_DELETE_POPUP'
//Market UTM
const MARKET_UTM_DELETE_POPUP = 'MARKET_UTM_DELETE_POPUP'
const MARKET_UTM_UPDATE_POPUP = 'MARKET_UTM_UPDATE_POPUP'
const MARKET_UTM_ADD_POPUP = 'MARKET_UTM_ADD_POPUP'
//Push
const PUSH_DELETE_POPUP = 'PUSH_DELETE_POPUP'
const PUSH_UPDATE_POPUP = 'PUSH_UPDATE_POPUP'
const PUSH_ADD_POPUP = 'PUSH_ADD_POPUP'
//Auto message
const AUTO_MESSAGE_CHANGE_POPUP = 'AUTO_MESSAGE_CHANGE_POPUP'
const AUTO_MESSAGE_DELETE_POPUP = 'AUTO_MESSAGE_DELETE_POPUP'
const AUTO_MESSAGE_ADD_POPUP = 'AUTO_MESSAGE_ADD_POPUP'
//Operator
const ADD_OPERATOR_POPUP_EVENT = 'ADD_OPERATOR_POPUP_EVENT'
const DELETE_OPERATOR_POPUP_EVENT = 'DELETE_OPERATOR_POPUP_EVENT'
const DELETE_OPERATOR_USER_POPUP_EVENT = 'DELETE_OPERATOR_USER_POPUP_EVENT'
const EDIT_OPERATOR_POPUP_EVENT = 'EDIT_OPERATOR_POPUP_EVENT'
const ADD_OPERATOR_USER_POPUP_EVENT = 'ADD_OPERATOR_USER_POPUP_EVENT'
const TRANSFER_OPERATOR_TO_ANOTHER = 'TRANSFER_OPERATOR_TO_ANOTHER'
const DEL_OPERATOR_USER_POPUP_EVENT = 'DEL_OPERATOR_USER_POPUP_EVENT'
const ADD_SEARCH_OPTION_POPUP_EVENT = 'ADD_SEARCH_OPTION_POPUP_EVENT'
const DEL_SEARCH_OPTION_POPUP_EVENT = 'DEL_SEARCH_OPTION_POPUP_EVENT'
const EDIT_SEARCH_OPTION_POPUP_EVENT = 'EDIT_SEARCH_OPTION_POPUP_EVENT'
const OPERATOR_SETTING_POPUP_EVENT = 'OPERATOR_SETTING_POPUP_EVENT'
const ADD_CODE_OPTION_POPUP_EVENT = 'ADD_CODE_OPTION_POPUP_EVENT'
const EDIT_RATE_OPTION_POPUP_EVENT = 'EDIT_RATE_OPTION_POPUP_EVENT'
const DEL_RATE_OPTION_POPUP_EVENT = 'DEL_RATE_OPTION_POPUP_EVENT'
const BILL_OPERATOR_POPUP_EVENT = 'BILL_OPERATOR_POPUP_EVENT'
const BILL_ALL_OPERATOR_POPUP_EVENT = 'BILL_ALL_OPERATOR_POPUP_EVENT'
const PRIZE_OPERATOR_POPUP_EVENT = 'PRIZE_OPERATOR_POPUP_EVENT'
const PAY_OPERATOR_POPUP_EVENT = 'PAY_OPERATOR_POPUP_EVENT'
const EDIT_OTHER_OPTION_POPUP_EVENT = 'EDIT_OTHER_OPTION_POPUP_EVENT'
const EDIT_USER_OPERATOR_POPUP_EVENT = 'EDIT_USER_OPERATOR_POPUP_EVENT'
const OPERATOR_NO_CLOSE_POPUP = 'OPERATOR_NO_CLOSE_POPUP'
const EDIT_USER_OPERATOR_NAME = 'EDIT_USER_OPERATOR_NAME'
const EDIT_AUTO_MESSAGE_DELAY = 'EDIT_AUTO_MESSAGE_DELAY'
const EDIT_OPERATOR_BANNER = 'EDIT_OPERATOR_BANNER'
const DELETE_OPERATOR_BANNER = 'DELETE_OPERATOR_BANNER'
const OPERATOR_EDIT_AUTO_MESSAGE = 'OPERATOR_EDIT_AUTO_MESSAGE'

//Calendar
const CALENDAR_ADD_POPUP = 'CALENDAR_ADD_POPUP'
const CALENDAR_EDIT_POPUP = 'CALENDAR_EDIT_POPUP'
const CALENDAR_DEL_MODERATOR_POPUP = 'CALENDAR_DEL_MODERATOR_POPUP'
const CALENDAR_CLOSE_POPUP = 'CALENDAR_CLOSE_POPUP'
const CALENDAR_NO_CLOSE_POPUP = 'CALENDAR_NO_CLOSE_POPUP'
const CALENDAR_ERROR_POPUP = 'CALENDAR_ERROR_POPUP'

const CHECK_SHIFT_ERROR = 'CHECK_SHIFT_ERROR'

//Moderator
const BILL_MODERATOR_POPUP_EVENT = 'BILL_MODERATOR_POPUP_EVENT'
const PAY_MODERATOR_POPUP_EVENT = 'PAY_MODERATOR_POPUP_EVENT'
const PRIZE_MODERATOR_POPUP_EVENT = 'PRIZE_MODERATOR_POPUP_EVENT'
const SETTING_MODERATOR_POPUP_EVENT = 'SETTING_MODERATOR_POPUP_EVENT'
const DEL_MODERATION_OPTION_POPUP_EVENT = 'DEL_MODERATION_OPTION_POPUP_EVENT'
const CORRECTION_MODERATOR_POPUP_EVENT = 'CORRECTION_MODERATOR_POPUP_EVENT'
const BILL_ALL_MODERATOR_POPUP_EVENT = 'BILL_ALL_MODERATOR_POPUP_EVENT'
const PRIZE_ALL_MODERATOR_POPUP_EVENT = 'PRIZE_ALL_MODERATOR_POPUP_EVENT'
const ERROR_MODERATOR_PAY_EVENT = 'ERROR_MODERATOR_PAY_EVENT'

//purchase
const PURCHASE_POPUP_EVENT = 'PURCHASE_POPUP_EVENT'
const PURCHASE_DELETE_POPUP_EVENT = 'PURCHASE_DELETE_POPUP_EVENT'
const PURCHASE_ADD_POPUP_EVENT = 'PURCHASE_ADD_POPUP_EVENT'
const PURCHASE_CLONE_POPUP_EVENT = 'PURCHASE_CLONE_POPUP_EVENT'

//user claims
const CLAIM_ADD_POPUP_EVENT = 'CLAIM_ADD_POPUP_EVENT'
const CLAIM_DELETE_POPUP = 'CLAIM_DELETE_POPUP'
const CLAIM_EDIT_POPUP_EVENT = 'CLAIM_EDIT_POPUP_EVENT'

//lang
const LANG_ADD_POPUP = 'LANG_ADD_POPUP'
const LANG_DELETE_POPUP = 'LANG_DELETE_POPUP'

export {
  //user
  USER_VERIFY_EVENT,
  USER_DECLINE_BY_AGE,
  USER_GET_VERIFY,
  USER_BLOCK_MAIN_PHOTO_EVENT,
  USER_SEND_ALERT_EVENT,
  USER_VIP_EVENT,
  USER_VIP_CALL,
  USER_RATING_EVENT,
  USER_EVENTS_RATING_EVENT,
  USER_SCORE_EVENT,
  USER_MAP_EVENT,
  USER_GENDER_EVENT,
  USER_PROFILE_BAN_EVENT,
  USER_DEVICE_BAN_EVENT,
  USER_DELETE_EVENT,
  USER_PANEL_EVENT,
  USER_APP_EVENT,
  USER_DELETE_PHOTO,
  USER_HIDE_MAP,
  //user actions
  USER_PHOTO_ACTION_PHOTO_STATUS_EVENT,
  //views mobile app
  VIEW_ADD_POPUP_RECORD,
  VIEW_DELETE_POPUP_RECORD,
  //user claim
  CLAIM_ADD_POPUP_EVENT,
  CLAIM_EDIT_POPUP_EVENT,
  CLAIM_DELETE_POPUP,
  //control employee
  EMPLOYEE_POPUP_EVENT,
  EDIT_EMPLOYEE_POPUP_EVENT,
  DELETE_EMPLOYEE_POPUP_EVENT,
  EMAIL_ERROR_POPUP,
  EDIT_USER_OPERATOR_POPUP_EVENT,
  EDIT_AUTO_MESSAGE_DELAY,
  EDIT_OPERATOR_BANNER,
  DELETE_OPERATOR_BANNER,
  OPERATOR_EDIT_AUTO_MESSAGE,
  //control department
  NEW_DEPARTMENT_POPUP_EVENT,
  EDIT_DEPARTMENT_POPUP_EVENT,
  DELETE_DEPARTMENT_POPUP_EVENT,
  //control permission
  NEW_PERMISSION_POPUP_EVENT,
  DELETE_PERMISSION_POPUP_EVENT,
  EDIT_PERMISSION_POPUP_EVENT,
  //control positions
  NEW_POSITION_POPUP_EVENT,
  EDIT_POSITION_POPUP_EVENT,
  DELETE_POSITION_POPUP_EVENT,
  //event
  EVENT_CATEGORY_ADD_POPUP,
  EVENT_CATEGORY_NAME_POPUP,
  EVENT_CATEGORY_IMAGE_POPUP,
  EVENT_SUBCATEGORY_ADD_POPUP,
  EVENT_SUBCATEGORY_NAME_POPUP,
  EVENT_SUBCATEGORY_IMAGE_POPUP,
  EVENT_CATEGORY_DELETE,
  EVENT_SUBCATEGORY_DELETE,
  EVENT_REFBOOK_ADD_POPUP,
  EVENT_REFBOOK_DELETE,
  EVENT_REFBOOK_UPDATE_POPUP,
  EVENT_DELETE_POPUP,
  //achievements
  ACHIEVEMENT_POPUP_EVENT,
  POINTS_POPUP_EVENT,
  ACHIEVEMENT_ADD_POPUP_EVENT,
  ACHIEVEMENT_DELETE_POPUP_EVENT,
  POINTS_POPUP_ADD_EVENT,
  POINTS_DELETE_POPUP_EVENT,
  // FAQ
  FAQ_POPUP_NEW_QUESTIONS_EVENT,
  FAQ_POPUP_NEW_CATEGORY_EVENT,
  FAQ_POPUP_UPDATE_CATEGORY_EVENT,
  FAQ_POPUP_UPDATE_QUESTION_EVENT,
  // Gifts
  GIFT_ADD_POPUP_EVENT,
  GIFT_DELETE_POPUP_EVENT,
  GIFT_UPDATE_POPUP_EVENT,
  FAQ_POPUP_REMOVE_ELEMENT_QUESTION,
  FAQ_POPUP_REMOVE_ELEMENT_CATEGORY,
  // Gifts Events
  GIFTS_ENEVT_ADD_NEW_EVENT,
  GIFTS_ENEVT_UPDATE_PERCENT_GREAT_EVENT,
  GIFTS_ENEVT_UPDATE_PERCENT_LOWER_EVENT,
  GIFTS_ENEVT_UPDATE_GREAT_EVENT,
  GIFTS_ENEVT_UPDATE_LOWER_EVENT,
  GIFTS_ENEVT_DETELE_LOWER_EVENT,
  GIFTS_ENEVT_DETELE_GREAT_EVENT,
  //Reason Delete
  REASON_DELETE_POPUP,
  REASON_DELETE_ADD_POPUP,
  REASON_BLOCK_POPUP,
  REASON_BLOCK_ADD_POPUP,
  //support contact types
  SUPPORT_CONTACT_ADD_POPUP,
  SUPPORT_CONTACT_DELETE_POPUP,
  SUPPORT_CONTACT_EDIT_POPUP,
  //Market UTM
  MARKET_UTM_DELETE_POPUP,
  MARKET_UTM_UPDATE_POPUP,
  MARKET_UTM_ADD_POPUP,
  //Push
  PUSH_DELETE_POPUP,
  PUSH_UPDATE_POPUP,
  PUSH_ADD_POPUP,
  //Auto messages
  AUTO_MESSAGE_ADD_POPUP,
  AUTO_MESSAGE_CHANGE_POPUP,
  AUTO_MESSAGE_DELETE_POPUP,
  //Operators
  ADD_OPERATOR_POPUP_EVENT,
  DELETE_OPERATOR_POPUP_EVENT,
  DELETE_OPERATOR_USER_POPUP_EVENT,
  EDIT_OPERATOR_POPUP_EVENT,
  ADD_OPERATOR_USER_POPUP_EVENT,
  TRANSFER_OPERATOR_TO_ANOTHER,
  DEL_OPERATOR_USER_POPUP_EVENT,
  ADD_SEARCH_OPTION_POPUP_EVENT,
  DEL_SEARCH_OPTION_POPUP_EVENT,
  EDIT_SEARCH_OPTION_POPUP_EVENT,
  OPERATOR_SETTING_POPUP_EVENT,
  ADD_CODE_OPTION_POPUP_EVENT,
  EDIT_RATE_OPTION_POPUP_EVENT,
  DEL_RATE_OPTION_POPUP_EVENT,
  BILL_OPERATOR_POPUP_EVENT,
  BILL_ALL_OPERATOR_POPUP_EVENT,
  PRIZE_OPERATOR_POPUP_EVENT,
  PAY_OPERATOR_POPUP_EVENT,
  EDIT_OTHER_OPTION_POPUP_EVENT,
  OPERATOR_NO_CLOSE_POPUP,
  EDIT_USER_OPERATOR_NAME,
  //calendar
  CALENDAR_ADD_POPUP,
  CALENDAR_EDIT_POPUP,
  CALENDAR_DEL_MODERATOR_POPUP,
  CALENDAR_CLOSE_POPUP,
  CALENDAR_NO_CLOSE_POPUP,
  CALENDAR_ERROR_POPUP,
  CHECK_SHIFT_ERROR,
  USER_TRANSACTION_KIND_LIST,
  USER_BUY_LIST_PURCHASES,
  //moderator
  BILL_MODERATOR_POPUP_EVENT,
  PRIZE_MODERATOR_POPUP_EVENT,
  SETTING_MODERATOR_POPUP_EVENT,
  PAY_MODERATOR_POPUP_EVENT,
  DEL_MODERATION_OPTION_POPUP_EVENT,
  CORRECTION_MODERATOR_POPUP_EVENT,
  BILL_ALL_MODERATOR_POPUP_EVENT,
  PRIZE_ALL_MODERATOR_POPUP_EVENT,
  ERROR_MODERATOR_PAY_EVENT,
  //purchase
  PURCHASE_POPUP_EVENT,
  PURCHASE_DELETE_POPUP_EVENT,
  PURCHASE_ADD_POPUP_EVENT,
  PURCHASE_CLONE_POPUP_EVENT,
  //lang
  LANG_ADD_POPUP,
  LANG_DELETE_POPUP
}
