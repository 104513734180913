import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import ReasonsService from '@/services/ReasonsService'

export default {
  // REASON DELETE
  [ActionTypes.REQUEST_REASON_DELETE]: async ({ rootGetters, commit }, lang = 'RU') => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ReasonsService, [userToken]).getReasonDelete(lang)
    const { data, status } = response

    if (status) {
      const reasonAdmin = data.filter(el => {
        return el.admin === true
      })
      const reasonUser = data.filter(el => {
        return el.admin === false
      })
      commit(MutationTypes.SET_REASON_DELETE, reasonUser)
      commit(MutationTypes.SET_REASON_DELETE_ADMIN, reasonAdmin)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_ADD_REASON_DELETE]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ReasonsService, [userToken]).addReasonDelete(payload)
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_REASON_DELETE, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_UPDATE_REASON_DELETE]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ReasonsService, [userToken]).updateReasonDelete(payload)

    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_REASON_DELETE, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_DELETE_REASON_DELETE]: async ({ rootGetters, commit, dispatch }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ReasonsService, [userToken]).deleteReasonDelete(id)
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_REASON_DELETE, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  // REASON BLOCK
  [ActionTypes.REQUEST_REASON_BLOCK]: async ({ rootGetters, commit }, lang = 'RU') => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ReasonsService, [userToken]).getReasonBlock(lang)
    const { data, status } = response
    if (status) {
      const reasonBlockUser = data.filter(el => {
        return el.block_type === 0
      })
      const reasonBlockPhoto = data.filter(el => {
        return el.block_type === 1
      })
      commit(MutationTypes.SET_REASON_BLOCK_USER, reasonBlockUser)
      commit(MutationTypes.SET_REASON_BLOCK_PHOTO, reasonBlockPhoto)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_ADD_REASON_BLOCK]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ReasonsService, [userToken]).addReasonBlock(payload)
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_REASON_BLOCK, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_UPDATE_REASON_BLOCK]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ReasonsService, [userToken]).updateReasonBlock(payload)

    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_REASON_BLOCK, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_DELETE_REASON_BLOCK]: async ({ rootGetters, commit, dispatch }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ReasonsService, [userToken]).deleteReasonBlock(id)
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_REASON_BLOCK, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
