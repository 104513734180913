<template>
  <li class="rating-items">
    <div class="rating-items__header">
      <div class="rating-items__header-wrap">
        <span class="rating-items__title">Модератор – {{ rating.fio ? rating.fio : 'Ошибка' }}</span>
        <span
          class="rating-items__active"
          :class="{
            'rating-items__active--on': rating.is_active,
            'rating-items__active--off': !rating.is_active
          }"
          >{{ rating.is_active ? 'активен' : 'неактивен' }}</span
        >
      </div>
      <div class="rating-items__stars">
        <div class="rating-items__stars-item">
          <span
            class="rating-items__stars-icon"
            :class="{
              'rating-items__stars-icon--plus': rating.rating_count > 0,
              'rating-items__stars-icon--minus': rating.rating_count < 0
            }"
          >
            <i class="fas fa-star-half-alt"></i>
          </span>
          <span class="rating-items__stars-text">{{ number_name(rating.rating_count) }}</span>
        </div>
        <div class="rating-items__stars-item">
          <span
            class="rating-items__stars-icon"
            :class="{
              'rating-items__stars-icon--plus': rating.rating_speed > 0,
              'rating-items__stars-icon--minus': rating.rating_speed < 0
            }"
          >
            <i class="fas fa-star-half-alt"></i>
          </span>
          <span class="rating-items__stars-text">{{ number_name(rating.rating_speed) }}</span>
        </div>
      </div>
    </div>
    <div class="rating-items__info">
      <div class="rating-items__info-item">
        <h2 class="rating-items__info-title rating-items__info-title--green">
          Качество проверки
        </h2>
        <div class="rating-items__info-wraper">
          <ul class="rating-items__info-list">
            <li class="rating-items__info-el info-el">
              <span>Одобрено</span>
              <div class="">
                <span class="info-el__count">{{ number_name(rating.count.approved.approved) }}</span>
                <span v-tooltip="'Перемещено контролером'" class="info-el__count">{{
                  movedController(`approved`, number_name)
                }}</span>
              </div>
            </li>
            <li class="rating-items__info-el">
              <span>Запрещено для аватара</span>
              <div>
                <span class="info-el__count">{{
                  number_name(rating.count.forbidden_for_avatar.forbidden_for_avatar)
                }}</span>
                <span v-tooltip="'Перемещено контролером'" class="info-el__count">{{
                  movedController(`forbidden_for_avatar`, number_name)
                }}</span>
              </div>
            </li>
            <li class="rating-items__info-el">
              <span>Запрещено для показа</span>
              <div>
                <span class="info-el__count">{{
                  number_name(rating.count.forbidden_for_display.forbidden_for_display)
                }}</span>
                <span v-tooltip="'Перемещено контролером'" class="info-el__count">{{
                  movedController(`forbidden_for_display`, number_name)
                }}</span>
              </div>
            </li>
            <li class="rating-items__info-el">
              <span>Врем. заблокировано</span>
              <div>
                <span class="info-el__count">{{
                  number_name(rating.count.temporarily_blocked.temporarily_blocked)
                }}</span>
                <span v-tooltip="'Перемещено контролером'" class="info-el__count">{{
                  movedController(`temporarily_blocked`, number_name)
                }}</span>
              </div>
            </li>
            <li class="rating-items__info-el">
              <span>Бан аккаунта</span>
              <div>
                <span class="info-el__count">{{
                  number_name(rating.count.banned_account.banned_account)
                }}</span>
                <span v-tooltip="'Перемещено контролером'" class="info-el__count">{{
                  movedController(`banned_account`, number_name)
                }}</span>
              </div>
            </li>
            <li class="rating-items__info-el">
              <span>Бан устройства</span>
              <div>
                <span class="info-el__count">{{
                  number_name(rating.count.banned_device.banned_device)
                }}</span>
                <span v-tooltip="'Перемещено контролером'" class="info-el__count">{{
                  movedController(`banned_device`, number_name)
                }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="rating-items__info-item">
        <h2 class="rating-items__info-title rating-items__info-title--blue">
          Скорость проверки
        </h2>
        <div class="rating-items__info-wraper">
          <ul class="rating-items__info-list">
            <li class="rating-items__info-el info-el">
              <span>5 минут</span>
              <span
                :class="{
                  'info-el__speed': rating.speed[5] > 0
                }"
                >{{ number_name(rating.speed[5]) }}</span
              >
            </li>
            <li class="rating-items__info-el">
              <span>10 минут</span>
              <span
                :class="{
                  'info-el__slow': rating.speed[10] > 0
                }"
                >{{ number_name(rating.speed[10]) }}</span
              >
            </li>
            <li class="rating-items__info-el">
              <span>15 минут</span>
              <span
                :class="{
                  'info-el__slow': rating.speed[15] > 0
                }"
                >{{ number_name(rating.speed[15]) }}</span
              >
            </li>
            <li class="rating-items__info-el">
              <span>20 минут</span>
              <span
                :class="{
                  'info-el__slow': rating.speed[20] > 0
                }"
                >{{ number_name(rating.speed[20]) }}</span
              >
            </li>
            <li class="rating-items__info-el">
              <span>25 минут</span>
              <span
                :class="{
                  'info-el__slow': rating.speed[25] > 0
                }"
                >{{ number_name(rating.speed[25]) }}</span
              >
            </li>
            <li class="rating-items__info-el">
              <span>30 минут</span>
              <span
                :class="{
                  'info-el__slow': rating.speed[30] > 0
                }"
                >{{ number_name(rating.speed[30]) }}</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'ModeratorsRating',
  props: {
    rating: {
      type: Object
    }
  },
  methods: {
    movedController(item, callback) {
      let sum = 0
      for (let key in this.rating.count[item]) {
        if (key !== item) {
          sum = sum + this.rating.count[item][key]
        }
      }

      return callback(sum, 'call')
    },
    number_name(num, key) {
      let count = ['', 'K', 'M', 'T', 'Q']

      let i = 0
      while (Math.abs(num) > 1000) {
        num /= 1000
        i++
      }

      if (key == 'call') {
        return `${num}${count[i]}`
      } else {
        return `${num.toFixed(2)}${count[i]}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'ModeratorStatistic';
</style>
