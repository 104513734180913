import * as ActionTypes from './actions-types'
import * as MutationTypes from './mutations-types'
import * as RootMutationTypes from '@/store/mutation-types'
import GiftEventService from '@/services/GiftEventService'

export default {
  [ActionTypes.REQUEST_ALL_GIFTS_EVENTS]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN

    const response = await Reflect.construct(GiftEventService, [userToken]).getAllGiftEvent()
    const { data } = response

    if (data) {
      commit(MutationTypes.SET_ALL_GIFT_ENENTS, data)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_NEW_GIFTS_EVENTS]: async ({ rootGetters, commit, dispatch }, newGiftData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(GiftEventService, [userToken]).addNewGiftEvent(newGiftData)
    const { status } = response

    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_GIFTS_EVENTS)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_DELETE_GIFTS_EVENTS]: async ({ rootGetters, commit, dispatch }, currentGiftId) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(GiftEventService, [userToken]).deleteGiftEvents(currentGiftId)
    const { status } = response

    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_GIFTS_EVENTS)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_UPDATE_GIFTS_EVENTS]: async ({ rootGetters, commit, dispatch }, newGiftData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(GiftEventService, [userToken]).updateGiftEvents(newGiftData)
    const { status } = response

    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_GIFTS_EVENTS)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_UPDATE_PERCENT_GIFTS_EVENTS]: async (
    { rootGetters, commit, dispatch },
    newGiftPercentData
  ) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(GiftEventService, [userToken]).updateGiftPercentEvents(
      newGiftPercentData
    )
    const { status } = response

    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_GIFTS_EVENTS)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  }
}
