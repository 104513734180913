import { country } from '@/constants/country'
import { operator } from '@/constants/operator'
export default {
  records: [],

  gender: [
    {
      name: 'Женский',
      value: 2,
      id: 0
    },
    {
      name: 'Мужской',
      value: 1,
      id: 1
    }
  ],

  operator,

  country,

  pages: [
    {
      name: 'Компас - Люди рядом',
      value: 0,
      id: 0
    },
    {
      name: 'Компас - Новые лица',
      value: 1,
      id: 1
    },
    {
      name: 'Компас - Популярные',
      value: 2,
      id: 2
    },
    {
      name: 'Компас - Были недавно',
      value: 3,
      id: 3
    },
    {
      name: 'Компас - Карта',
      value: 4,
      id: 4
    }
  ],

  tmpFilters: {
    lang: {},
    gender: {},
    country: {},
    page: {}
  },

  recordDefault: {
    lang: {
      name: 'Русский',
      id: '606437bc8c39cfd3d51f07bf'
    },
    gender: {
      name: 'Мужской',
      value: 1
    },
    country: {
      name: 'Россия',
      value: 'RU'
    },
    page: {
      name: 'Компас - Люди рядом',
      value: 0
    }
  }
}
