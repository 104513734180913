<template>
  <div class="ev-cat__item-content">
    <div class="ev-cat__item-name">
      <div class="ev-cat__item-img">
        <img
          :src="
            getFile({
              type: 'image',
              file: eventsubcat.image,
              width: 100,
              height: 100
            })
          "
          alt=""
        />
      </div>
      <span class="ev-cat__item-title"> {{ eventsubcat.locale.title }} </span>
    </div>
    <div class="ev-cat__item-btns">
      <button
        class="ev-cat__item-btn ev-cat__item-btn--yellow"
        @click="$root.$emit(EVENT_SUBCATEGORY_IMAGE_POPUP, eventsubcat)"
      >
        <i class="ev-cat__item-btn-icon far fa-image"></i>
      </button>
      <button
        class="ev-cat__item-btn ev-cat__item-btn--blue"
        @click="$root.$emit(EVENT_SUBCATEGORY_NAME_POPUP, eventsubcat)"
      >
        <i class="ev-cat__item-btn-icon far fa-keyboard"></i>
      </button>
      <button
        class="ev-cat__item-btn ev-cat__item-btn--red"
        @click="$root.$emit(EVENT_SUBCATEGORY_DELETE, eventsubcat.id)"
      >
        <i class="ev-cat__item-btn-icon far fa-trash-alt"></i>
      </button>
    </div>
  </div>
</template>
<script>
import getImgLink from '@/components/mixins/getImgCdnLink'
import {
  EVENT_SUBCATEGORY_IMAGE_POPUP,
  EVENT_SUBCATEGORY_NAME_POPUP,
  EVENT_SUBCATEGORY_DELETE
} from '@/constants/event'
export default {
  name: 'EventSubCategory',
  data() {
    return {
      EVENT_SUBCATEGORY_IMAGE_POPUP,
      EVENT_SUBCATEGORY_NAME_POPUP,
      EVENT_SUBCATEGORY_DELETE
    }
  },
  props: {
    eventsubcat: {
      required: true,
      type: Object
    }
  },
  mixins: [getImgLink]
}
</script>

<style lang="scss"></style>
