<template>
  <div class="app-user-btns">
    <div class="app-user-btns__group">
      <button
        @click="$root.$emit(USER_VERIFY_EVENT, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--verify btn-white"
        v-tooltip="`${isVerificate}`"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon fa fa-shield-alt"></i>
      </button>
      <button
        @click="$root.$emit(USER_GET_VERIFY, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--baby btn-white"
        v-tooltip="`Верификация по возрасту`"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon fas fa-baby"></i>
      </button>
      <button
        @click="$root.$emit(USER_BLOCK_MAIN_PHOTO_EVENT, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--warning btn-white"
        v-tooltip="'Заблокировать основное фото'"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon fas fa-exclamation-triangle"></i>
      </button>
      <button
        @click="$root.$emit(USER_RATING_EVENT, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--rating btn-white"
        v-tooltip="'Рейтинг пользователя'"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon fas fa-star"></i>
      </button>
      <button
        @click="$root.$emit(USER_EVENTS_RATING_EVENT, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--rating btn-white"
        v-tooltip="'Рейтинг событий'"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon fas fa-award"></i>
      </button>
      <button
        @click="$root.$emit(USER_VIP_EVENT, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--vip btn-white"
        v-tooltip="'Подарить VIP'"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon fas fa-crown"></i>
      </button>
      <button
        @click="$root.$emit(USER_VIP_CALL, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--vip btn-white"
        v-tooltip="`${callVipText}`"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon fas fa-phone"></i>
      </button>
      <button
        @click="$root.$emit(USER_SCORE_EVENT, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--slcoins btn-white"
        v-tooltip="'Подарить баллы'"
        :disabled="currentUser.is_deleted"
      >
        <svg
          class="app-user-btns__btn-icon app-user-btns__btn-icon--slcoins"
          width="16"
          height="13"
          viewBox="0 0 27 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.80449 22.9982L2.8042 23L12.833 23L12.4997 25H16.4997L16.8333 22.9982C19.3028 22.9634 21.2558 22.4073 22.6921 21.3301C24.1862 20.2168 25.0016 18.6299 25.1383 16.5693C25.2067 15.4951 25.0065 14.5771 24.5378 13.8154C24.0788 13.0439 23.4342 12.5117 22.6042 12.2187C24.9186 11.2812 26.1491 9.68945 26.2956 7.44336C26.4128 5.62695 25.8122 4.21582 24.4938 3.20996C23.472 2.43032 22.0423 1.94989 20.2049 1.76868L20.4997 0H16.4997L16.219 1.68413L6.19193 1.67365L6.17609 1.76868C8.01349 1.94988 9.44313 2.43031 10.465 3.20996C11.7834 4.21582 12.384 5.62695 12.2668 7.44336C12.1203 9.68945 10.8898 11.2812 8.57536 12.2188C9.40544 12.5117 10.05 13.0439 10.509 13.8154C10.9777 14.5771 11.1779 15.4951 11.1095 16.5693C10.9728 18.6299 10.1574 20.2168 8.66325 21.3301C7.22694 22.4073 5.27402 22.9634 2.80449 22.9982ZM13.7272 19.0449L14.6208 13.9326L18.3122 13.9473C19.8356 14.0254 20.4606 14.8799 20.1872 16.5107C20.07 17.2822 19.6989 17.9023 19.0739 18.3711C18.4489 18.8301 17.6676 19.0596 16.7301 19.0596L13.7272 19.0449ZM17.5505 10.5781L15.1921 10.5635L16.0563 5.6416L18.7223 5.65625C20.6169 5.66601 21.4469 6.47168 21.2126 8.07324C20.9489 9.69434 19.7282 10.5293 17.5505 10.5781ZM0 19.0532L0.893555 13.9409L4.58496 13.9556C6.1084 14.0337 6.7334 14.8882 6.45996 16.519C6.34277 17.2905 5.97168 17.9106 5.34668 18.3794C4.72168 18.8384 3.94043 19.0679 3.00293 19.0679L0 19.0532ZM3.82324 10.5864L1.46484 10.5718L2.3291 5.6499L4.99512 5.66455C6.88965 5.67432 7.71973 6.47998 7.48535 8.08154C7.22168 9.70264 6.00098 10.5376 3.82324 10.5864Z"
            fill=""
          />
        </svg>
      </button>
      <!--      <router-link-->
      <!--        :to="{ name: 'DialogsUser', params: { userId: currentUser.id, currentUser } }"-->
      <!--        class="app-user-btns__btn app-user-btns__btn&#45;&#45;chat btn-white"-->
      <!--        v-tooltip="'Отправить сообщение'"-->
      <!--        ><i class="app-user-btns__btn-icon far fa-comments"></i></router-link>-->
      <!--        @click="$root.$emit(USER_MESSAGE_EVENT, currentUser)"-->
      <button
        disabled
        class="app-user-btns__btn app-user-btns__btn--chat btn-white"
        v-tooltip="'Отправить сообщение'"
      >
        <i class="app-user-btns__btn-icon far fa-comments"></i>
      </button>
    </div>
    <div class="app-user-btns__group">
      <button
        @click="$root.$emit(USER_GENDER_EVENT, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--gender btn-white"
        v-tooltip="'Сменить пол'"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon app-user-btns__btn-icon--female fas fa-venus"></i>
      </button>
      <button
        @click="$root.$emit(USER_SEND_ALERT_EVENT, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--warning btn-white"
        v-tooltip="'Отправить предупреждение пользователю'"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon app-user-btns__btn-icon--alert fas fa-exclamation"></i>
      </button>
      <button
        @click="$root.$emit(USER_PROFILE_BAN_EVENT, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--user-block btn-white"
        v-tooltip="`${isBanned}`"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon fas fa-user-slash"></i>
      </button>
      <button
        @click="$root.$emit(USER_DEVICE_BAN_EVENT, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--user-block btn-white"
        v-tooltip="`${isBannedDev}`"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon fas fa-mobile-alt"></i>
      </button>
      <button
        v-if="currentUser.delete_info.reason_code === -1"
        @click="$root.$emit(USER_DELETE_EVENT, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--device-block btn-white"
        v-tooltip="'Удалить профиль'"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon app-user-btns__btn-icon--small fas fa-mobile-alt"></i>
        <i class="app-user-btns__btn-icon app-user-btns__btn-icon--small far fa-trash-alt"></i>
      </button>
      <button
        v-if="!currentUser.incognito_admin"
        key="adminnoshow"
        @click="$root.$emit(USER_PANEL_EVENT, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--hide-red btn-white"
        v-tooltip="'Скрыть в админ панели'"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon fas fa-eye-slash"></i>
      </button>
      <button
        v-else-if="currentUser.incognito_admin"
        key="adminshow"
        @click="$root.$emit(USER_PANEL_EVENT, currentUser)"
        class="app-user-btns__btn btn-red"
        v-tooltip="'Показывать в админ панели'"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon fas fa-eye-slash"></i>
      </button>
      <button
        v-if="currentUser.incognito_app"
        key="appshow"
        @click="$root.$emit(USER_APP_EVENT, currentUser)"
        class="app-user-btns__btn btn-orange "
        v-tooltip="'Показывать в приложении'"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon far fa-eye-slash"></i>
      </button>
      <button
        v-else
        key="appnoshow"
        @click="$root.$emit(USER_APP_EVENT, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--hide-orange btn-white"
        v-tooltip="'Скрыть в приложении'"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon far fa-eye-slash "></i>
      </button>
      <button
        v-if="
          currentUser.confidential.map_visible === false &&
            currentUser.confidential.vip_map_invisible === true
        "
        @click="$root.$emit(USER_HIDE_MAP, currentUser)"
        key="mapnoshow"
        class="app-user-btns__btn btn-green"
        v-tooltip="'Показать на карте'"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon far fa-compass"></i>
      </button>
      <button
        v-else
        key="mapshow"
        @click="$root.$emit(USER_HIDE_MAP, currentUser)"
        class="app-user-btns__btn app-user-btns__btn--hide-green btn-white"
        v-tooltip="'Скрыть на карте'"
        :disabled="currentUser.is_deleted"
      >
        <i class="app-user-btns__btn-icon far fa-compass"></i>
      </button>
    </div>
  </div>
</template>

<script>
import {
  USER_VERIFY_EVENT,
  USER_GET_VERIFY,
  USER_BLOCK_MAIN_PHOTO_EVENT,
  USER_SEND_ALERT_EVENT,
  USER_VIP_EVENT,
  USER_VIP_CALL,
  USER_RATING_EVENT,
  USER_SCORE_EVENT,
  USER_MAP_EVENT,
  USER_GENDER_EVENT,
  USER_PROFILE_BAN_EVENT,
  USER_DEVICE_BAN_EVENT,
  USER_DELETE_EVENT,
  USER_PANEL_EVENT,
  USER_APP_EVENT,
  USER_EVENTS_RATING_EVENT,
  USER_HIDE_MAP
} from '@/constants/event'
import { GET_PERMISSIONS } from '@/store/getter-types'
import { mapGetters } from 'vuex'
export default {
  name: 'App-user-btns',

  data() {
    return {
      USER_VERIFY_EVENT,
      USER_SEND_ALERT_EVENT,
      USER_GET_VERIFY,
      USER_BLOCK_MAIN_PHOTO_EVENT,
      USER_VIP_EVENT,
      USER_VIP_CALL,
      USER_RATING_EVENT,
      USER_SCORE_EVENT,
      USER_MAP_EVENT,
      USER_GENDER_EVENT,
      USER_PROFILE_BAN_EVENT,
      USER_DEVICE_BAN_EVENT,
      USER_DELETE_EVENT,
      USER_PANEL_EVENT,
      USER_APP_EVENT,
      USER_EVENTS_RATING_EVENT,
      USER_HIDE_MAP
    }
  },
  props: {
    currentUser: {
      required: true,
      type: Object
    },
    userPhotos: {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: GET_PERMISSIONS
    }),
    isBannedDev() {
      if (this.currentUser.ban) {
        return 'Разблокировать по устройству'
      } else {
        return 'Заблокировать по устройству'
      }
    },
    callVipText() {
      if (this.currentUser.confidential.incoming_call_vip) {
        return 'Принимать звонки от всех'
      } else {
        return 'Принимать звонки только от VIP'
      }
    },
    isBanned() {
      if (this.currentUser.ban) {
        return 'Разблокировать пользователя?'
      } else {
        return 'Заблокировать пользователя?'
      }
    },
    isVerificate() {
      if (this.currentUser.verification !== 2) {
        return 'Верифицировать пользователя'
      } else {
        return 'Снять верификацию'
      }
    }
  }
}
</script>

<style lang="scss">
@import './App-user-btns.scss';
</style>
