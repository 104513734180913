<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Редактирование департамента"
    modalClass="popup--medium"
  >
    <form @submit.prevent="editDepartmentHandler">
      <div class="popup__item">
        <span class="popup__item-title">Название департамента</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="editNamedepartment"
          ref="editnamedepartment"
          required
        />
      </div>
      <!-- <div class="popup__item">
        <span class="popup__item-title">Иконка департамента</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="editIcondepartment"
          ref="editicondepartment"
          required
        />
      </div> -->
      <div class="popup__item">
        <span class="popup__item-title">Значение департамента</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="editValuedepartment"
          ref="editvaluedepartment"
          required
        />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Изменить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>

<script>
import { mapActions } from 'vuex'
import { REQUEST_UPDATE_DEPARTMENT } from '@/store/action-types'

import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EDIT_DEPARTMENT_POPUP_EVENT } from '@/constants/event'

export default {
  name: 'EditDepartmentPopup',
  components: {
    Popup
  },
  data: () => ({
    departmentData: {
      name: '',
      icon: 'icon',
      value: '',
      id: ''
    }
  }),
  mixins: [ModalMixin],

  computed: {
    editNamedepartment: {
      get() {
        return this.departmentData.name
      },

      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.editnamedepartment.value = name
        this.departmentData.name = name
      }
    },

    // editIcondepartment: {
    //   get() {
    //     return this.departmentData.icon
    //   },

    //   set(icon) {
    //     icon = icon.replace(/\d/g, '')
    //     this.$refs.editicondepartment.value = icon
    //     this.departmentData.icon = icon
    //   }
    // },

    editValuedepartment: {
      get() {
        return this.departmentData.value
      },

      set(value) {
        value = value.replace(/\d/g, '')
        this.$refs.editvaluedepartment.value = value
        this.departmentData.value = value
      }
    }
  },

  methods: {
    ...mapActions({
      update: REQUEST_UPDATE_DEPARTMENT
    }),

    closePopup() {
      this.departmentData.name = ''
      this.departmentData.icon = ''
      this.departmentData.value = ''
      this.departmentData.id = ''
      this.close()
    },

    editDepartmentHandler() {
      const editDepartment = {
        departmentData: {
          name: this.departmentData.name,
          icon: this.departmentData.icon,
          value: this.departmentData.value
        },
        id: this.departmentData.id
      }
      this.update(editDepartment)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(EDIT_DEPARTMENT_POPUP_EVENT, department => {
      this.departmentData.name = department.name
      this.departmentData.icon = department.icon
      this.departmentData.value = department.value
      this.departmentData.id = department.id
      this.open()
    })
  }
}
</script>
