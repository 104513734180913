const SET_MERGED_CATEGORIES = 'SET_MERGED_CATEGORIES'
const SET_EVENTS_REFBOOK = 'SET_EVENTS_REFBOOK'
const SET_EVENTS = 'SET_EVENTS'
const SET_EVENT = 'SET_EVENT'
const SET_EVENT_DATE_FILTER = 'SET_EVENT_DATE_FILTER'
const SET_EVENT_TMP_FILTERS = 'SET_EVENT_TMP_FILTERS'
const SET_EVENT_RESET_FILTERS = 'SET_EVENT_RESET_FILTERS'
const SET_CATEGORIES_BY_LANG = 'SET_CATEGORIES_BY_LANG'
const SET_EVENTS_FILTERS = 'SET_EVENTS_FILTERS'
const SET_ALL_EVENTS = 'SET_ALL_EVENTS'
const SET_EVENTS_ARHIVE = 'SET_EVENTS_ARHIVE'
const SET_EVENT_COUNTS = 'SET_EVENT_COUNTS'

export {
  SET_MERGED_CATEGORIES,
  SET_EVENTS_REFBOOK,
  SET_EVENTS,
  SET_EVENT,
  SET_ALL_EVENTS,
  SET_EVENT_DATE_FILTER,
  SET_EVENT_TMP_FILTERS,
  SET_EVENT_RESET_FILTERS,
  SET_CATEGORIES_BY_LANG,
  SET_EVENTS_FILTERS,
  SET_EVENTS_ARHIVE,
  SET_EVENT_COUNTS
}
