<template>
  <div class="app-user-info-body app-user-info-body--pay" v-click-outside="close">
    <div class="app-user-info__item-title">
      <div class="app-user-info__key app-user-info__key--pay">
        <!--        <FaIcon-->
        <!--          :iconClass="'fas fa-ruble-sign'"-->
        <!--          class="app-user-info__key-icon app-user-info__key-icon&#45;&#45;gift"-->
        <!--        />-->

        <i
          class="
            fas
            fa-ruble-sign
            app-user-info__key-icon app-user-info__key-icon--gift
          "
        ></i>
        <span class="app-user-info__key-text">Депозиты:</span>
      </div>
      <span class="app-user-info__value app-user-info__value--pay">{{ depositSum(0) }} $</span>
      <span class="app-user-info__value app-user-info__value--pay">{{ depositSum(1) }} €</span>
      <span class="app-user-info__value app-user-info__value--pay">{{ depositSum(2) }} ₽</span>
      <span class="app-user-info__value app-user-info__value--pay">{{ depositSum(3) }} £</span>
      <div class="app-user-info__angle">
        <button
          v-if="!openDeposit"
          key="open"
          @click="$emit('changeActive', 'deposit')"
          title="Показать историю"
        >
          <i class="fa fa-angle-down"></i>
        </button>
        <button v-else key="close" @click="$emit('changeActive', 'deposit')" title="Скрыть историю">
          <i class="fa fa-angle-up"></i>
        </button>
      </div>
    </div>
    <transition name="fade">
      <div class="app-user-info__list" v-if="openDeposit && userDeposit.length">
        <div
          class="app-user-info__pay"
          v-for="deposit in userDeposit"
          :key="deposit.id"
          :deposit="deposit"
          :class="{
            'app-user-info__pay--cancel': deposit.status === 3,
            'app-user-info__pay--ok': deposit.status === 2,
            'app-user-info__pay--error': deposit.status === 4,
            'app-user-info__pay--pending': deposit.status === 1
          }"
        >
          <div v-if="deposit.status !== 0" class="app-user-info__pay-icon">
            <i
              :class="{
                'fa fa-ban': deposit.status === 3,
                'fa fa-check': deposit.status === 2,
                'fa fa-exclamation-triangle': deposit.status === 4,
                'fa fa-spinner fa-spin': deposit.status === 1
              }"
            ></i>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--id">
            <span class="app-user-info__key-text">ID транзакции: </span>
            <span class="app-user-info__item-value">{{ deposit.id }}</span>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--pay-id">
            <span class="app-user-info__key-text">RoyalPay id: </span>
            <span class="app-user-info__item-value">{{ deposit.royal_pay_id }}</span>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--pay-id">
            <span class="app-user-info__key-text">Статус: </span>
            <span class="app-user-info__item-value">{{ statusText(deposit.status) }}</span>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--pay-id">
            <span class="app-user-info__key-text">Создано: </span>
            <span class="app-user-info__item-value">{{ toLocaleDateUser(deposit.create) }}</span>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--pay-id">
            <span class="app-user-info__key-text">Обновлено: </span>
            <span class="app-user-info__item-value">{{ toLocaleDateUser(deposit.update) }}</span>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--system">
            <span class="app-user-info__key-text">Платежная система: </span>
            <span class="app-user-info__item-value">{{ paymentSystemText(deposit.payment_system) }}</span>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--pay">
            <span class="app-user-info__key-text">Сумма транзакции при создании: </span>
            <span class="app-user-info__item-value"
              >{{ Number(deposit.amount_pay) }}{{ moneyIcon(deposit.currency) }}</span
            >
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--merchant">
            <span class="app-user-info__key-text">Комиссия RoyalPay: </span>
            <span class="app-user-info__item-value"
              >{{ Number(deposit.amount_merchant) }}{{ moneyIcon(deposit.currency) }}</span
            >
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--client">
            <span class="app-user-info__key-text">Сумма зачисленная пользователю: </span>
            <span class="app-user-info__item-value"
              >{{ Number(deposit.amount_client) }}{{ moneyIcon(deposit.currency) }}</span
            >
          </div>
        </div>
      </div>
      <div v-else-if="openDeposit && !userDeposit.length" class="app-user-info__list">
        <span class="app-user-info__no-data">Депозиты отсутствуют</span>
      </div>
    </transition>
  </div>
</template>

<script>
import dateFormat from '@/components/mixins/toLocaleformat'
import getImgLink from '@/components/mixins/getImgCdnLink'
import getIconCurrency from '@/components/mixins/getIconCurrency'
import getTransactionStatus from '@/components/mixins/getTransactionStatus'
export default {
  name: 'AppUserDeposit',
  props: {
    userDeposit: {
      type: Array
    },
    changeActive: {
      type: Function
    },
    openDeposit: {
      type: Boolean
    },
    // statusText: {
    //   type: Function
    // },
    paymentSystemText: {
      type: Function
    }
  },
  mixins: [dateFormat, getImgLink, getIconCurrency, getTransactionStatus],

  methods: {
    close() {
      this.versionOpen = false
    },
    depositSum(currency) {
      let filterArr = this.userDeposit.filter(f => f.currency === currency)
      let sumCurrency = null
      filterArr.map(m => {
        sumCurrency = (sumCurrency * 100 + Number(m.amount_pay) * 100) / 100
      })
      if (sumCurrency) {
        return sumCurrency.toFixed(2)
      } else {
        return 0
      }
    }
  }
}
</script>

<style lang="scss">
@import './App-user-info';
</style>
