<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Совершить выплату модератору?"
    modalClass="popup--small"
  >
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="payHandler()" :disabled="isLoading">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_EXECUTE_MODERATORS } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { ERROR_MODERATOR_PAY_EVENT, PAY_MODERATOR_POPUP_EVENT } from '@/constants/event'
import { GET_MODERATOR_TMP_ROLES } from '@/store/getter-types'

export default {
  name: 'PayModeratorPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    PAY_MODERATOR_POPUP_EVENT,
    ERROR_MODERATOR_PAY_EVENT,
    moderatorId: null,
    pay: null,
    isLoading: false
  }),
  computed: {
    ...mapGetters({
      tmpFilter: GET_MODERATOR_TMP_ROLES
    })
  },

  methods: {
    ...mapActions({
      payModerator: REQUEST_EXECUTE_MODERATORS
    }),
    closePopup() {
      this.moderatorId = null
      this.pay = null
      this.isLoading = false
      this.close()
    },
    payHandler() {
      this.isLoading = true
      let formData = {
        data: {
          pays: [
            {
              moderator_id: this.moderatorId.id,
              pay_id: this.pay.id
            }
          ]
        },
        active: null
      }

      if (this.tmpFilter.status.name && this.tmpFilter.status.name !== 'Все') {
        formData.active = {
          is_active: this.tmpFilter.status.value
        }
      }

      this.payModerator(formData).then(response => {
        let errorArr = []
        response.data.results.forEach(f => {
          if (f.status === 3 || f.status === 4) {
            errorArr.push({
              info: {
                first_name: this.moderatorId.first_name,
                last_name: this.moderatorId.last_name,
                payout_period_from: this.pay.payout_period_from,
                payout_period_to: this.pay.payout_period_to
              },
              status: f.status
            })
          }
        })
        if (errorArr.length) {
          this.$root.$emit(ERROR_MODERATOR_PAY_EVENT, errorArr)
        }
        this.closePopup()
      })
    }
  },

  mounted() {
    this.$root.$on(PAY_MODERATOR_POPUP_EVENT, data => {
      this.moderatorId = data.moderator
      this.pay = data.pay
      this.open()
    })
  }
}
</script>
