<template>
  <div class="moderator-panel">
    <span>Страница Панели модераторов</span>
  </div>
</template>
<script>
export default {
  name: 'moderatorpanel'
}
</script>
