<template>
  <Popup
    :visible="visible"
    @close="close"
    title=""
    description="Редактировать свое событие"
    modalClass="popup--small"
  >
    <form @submit.prevent="sendData">
      <div class="popup__item">
        <div v-for="(item, index) in payload" :key="index">
          <input
            class="popup__input default-input refbook-input"
            type="text"
            name="name"
            v-model="item.value"
            required
          />
          <button type="button" @click="deleteValue(index)">
            <i class="fa fa-times-circle"></i>
          </button>
        </div>
        <button type="button" @click="addValue" title="Добавить value">
          <i class="fa fa-plus-square"></i>
        </button>
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Изменить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EVENT_REFBOOK_UPDATE_POPUP } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_UPDATE_EVENT_REFBOOK } from '@/store/action-types'

export default {
  name: 'EventRefBookUpdatePopup',
  data() {
    return {
      id: '',
      payload: [],
      array: '',
      lang: ''
    }
  },
  components: {
    Popup
  },
  mixins: [ModalMixin],
  mounted() {
    this.$root.$on(EVENT_REFBOOK_UPDATE_POPUP, payload => {
      this.lang = payload.lang
      this.payload = payload.values
      this.array = payload.array
      this.id = payload.id
      this.open()
    })
  },
  methods: {
    ...mapActions({
      updateRefBook: REQUEST_UPDATE_EVENT_REFBOOK
    }),
    addValue() {
      return this.payload.push({ key: this.payload.length, value: '' })
    },
    deleteValue(index) {
      return this.payload.splice(index, 1)
    },
    sendData() {
      const array = this.array
      const data = {
        id: this.id,
        [array]: this.payload,
        lang: this.lang
      }
      this.updateRefBook(data)
      this.payload = []
      this.array = ''
      this.id = ''
      this.close()
    }
  }
}
</script>

<style lang="scss">
.refbook-input {
  margin-bottom: 5px;
  width: 90%;
}
</style>
