<template>
  <div class="table-header-wrapper--cause-points">
    <ul class="table-header">
      <li class="table-header__item table-header__item--cause-points">
        <span class="table-header__item-text">Название</span>
      </li>
      <li class="table-header__item table-header__item--cause-points">
        <span class="table-header__item-text">Kind</span>
      </li>
      <li class="table-header__item table-header__item--cause-points">
        <div class="table-header__gender">
          <i class="fas fa-mars table-header__male"></i>
          <span>/</span>
          <i class="fas fa-venus table-header__female"></i>
          <span>/</span>
          <i class="fas fa-mars-stroke table-header__female"></i>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'CausePointsTableHeader'
}
</script>
<style lang="scss">
@import './CausePointsTableHeader.scss';
.table-header__item {
  display: flex;
  flex-direction: row;
  @media (max-width: 700px) {
    flex-direction: column;
  }
}
.table-header__gender {
  display: flex;
  width: 150px;
  justify-content: space-between;
  align-items: center;
}
</style>
