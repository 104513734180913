import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import StatisticsService from '@/services/StatisticsService'

export default {
  [ActionTypes.REQUEST_APPS_FLYER]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getAppsFlyer(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_APPS_FLYER, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_APPS_FLYER_IOS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getAppsFlyerIos(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_REQUEST_APPS_FLYER_IOS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_PURCHASES_POINTS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getPurchasesPoints(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_PURCHASES_POINTS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_ROYAL_USERS_INSTALL]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getistallLoyalUsers(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_ROYAL_USERS_INSTALL, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_PURCHASES_VIP_STATUS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getPurchasesVip(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_PURCHASES_VIP_STATUS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_PURCHASES_BY_DATE]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getPurchasesByDate(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_PURCHASES_BY_DATE, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_ROYAL_USERS_INSTALL_IOS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getistallLoyalUsersIos(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_ROYAL_USERS_INSTALL_IOS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_USERS_ACTIVITY]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getActivityUsers(formData)
    let { data } = response
    if (data) {
      commit(MutationTypes.SET_USERS_ACTIVITY, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_USERS_COUNT]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getUsersCount()
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_USERS_COUNT, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_USERS_ONLINE_BY_CITY]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getOnlineByCityUsers(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_USERS_ONLINE_BY_CITY, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_USERS_ONLINE_ONE_DAY]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getOnlineOneDay(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_USERS_ONLINE_ONE_DAY, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_USERS_ONLINE_ONE_DAY_SITE_AND_APP]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getOnlineOneDayAppSite(formData)
    let { data } = response
    if (response.status) {
      commit(MutationTypes.SET_USERS_ONLINE_ONE_DAY_SITE_AND_APP, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_PURCHASES_MARKET_PLACES]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getMarketPlacesPurchases(
      formData
    )
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_PURCHASES_MARKET_PLACES, data.data2)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_PURCHASES_BY_CITY]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getPurchasesByCity(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_PURCHASES_BY_CITY, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_PURCHASES_BY_COUNTRY]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getPurchasesByCountry(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_PURCHASES_BY_COUNTRY, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_PURCHASE_TOTAL]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getPurchaseTotal(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_PURCHASE_TOTAL, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_PURCHASES_BY_TIME]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getPurchasesByTime(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_PURCHASES_BY_TIME, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_PURCHASES_BY_PAYERS_PAYMENTS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getPurchasesByPayersPayments(
      formData
    )
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_PURCHASES_BY_PAYERS_PAYMENTS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_NEW_USER_ACTIVE_BY_DATE]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getNewUserActiveByDate(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_NEW_USER_ACTIVE_BY_DATE, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_MODERATORS_PHOTO_CHECK]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getStatsModeratorPhotoCheck(
      formData
    )
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_MODERATORS_PHOTO_CHECK, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_MODARATION_VIDEO]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getStatsModeratorVideoCheck(
      formData
    )
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_MODERATOTS_VIDEO_CHECK, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_ACTIVE_NEW_USER]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getNewUserActive(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_NEW_USER_ACTIVE, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_USERS_GENDER]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getStatsUsersGender(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_USERS_GENDER, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_USERS_PAYING]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getStatsUsersPaying(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_USERS_PAYING, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_USERS_AGE]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getStatsUsersAge(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_USERS_AGE, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_USER_LIFETIME]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getStatsLifetime(formData)
    let { data } = response

    if (data) {
      commit(MutationTypes.SET_USER_LIFETIME, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUSET_REGS_NEW_USERS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getStatsRegsNewUsers(formData)

    let { data } = response

    if (data) {
      commit(MutationTypes.SET_REGS_NEW_USERS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_OPERATORS_MESSAGE]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getStatsMessagesOperator(
      formData
    )

    let { data } = response

    if (data) {
      commit(MutationTypes.SET_OPERATORS_MESSAGE, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_OPERATORS_CASHES]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getStatsCashesOperator(formData)

    let { data } = response

    if (data) {
      commit(MutationTypes.SET_OPERATORS_CASHES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_COUNTERS_MESSAGES_OPERATORS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getCountersMessagesOperators(
      formData
    )

    let { data, status } = response

    if (status) {
      commit(MutationTypes.SET_COUNTERS_MESSAGES_OPERATORS, data)
    }
    // commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_STATISTICS_PROFILE_MESSAGES]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getStatisticsByOperatorsProfile(
      formData
    )

    let { data, status } = response

    if (status) {
      commit(MutationTypes.SET_STATISTICS_PROFILE_MESSAGES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_STATISTICS_PROFILE_MESSAGES_PAGINATION]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getStatisticsByOperatorsProfile(
      formData
    )

    let { data, status } = response

    if (status) {
      commit(MutationTypes.SET_STATISTICS_PROFILE_MESSAGES_PAGINATION, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS]: async (
    { rootGetters, commit },
    formData
  ) => {
    // commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getUnhandledCall(formData)

    let { data, status } = response

    if (status) {
      commit(MutationTypes.SET_STATISTICS_PROFILE_MESSAGES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS_PAGINATION]: async (
    { rootGetters, commit },
    formData
  ) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getUnhandledCall(formData)

    let { data, status } = response

    if (status) {
      commit(MutationTypes.SET_STATISTICS_PROFILE_MESSAGES_PAGINATION, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS]: async ({ rootGetters, commit }, formData) => {
    // commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getDialogs(formData)

    let { data, status } = response

    if (status) {
      commit(MutationTypes.SET_STATISTICS_PROFILE_MESSAGES, data)
      commit(RootMutationTypes.SET_LOADER, false, { root: true })
    }
    return response
  },
  [ActionTypes.REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS_PAGINATION]: async (
    { rootGetters, commit },
    formData
  ) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getDialogs(formData)

    let { data, status } = response

    if (status) {
      commit(MutationTypes.SET_STATISTICS_PROFILE_MESSAGES_PAGINATION, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES]: async ({ rootGetters, commit }, formData) => {
    // commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getUnreadMessages(formData)

    let { data, status } = response

    if (status) {
      commit(MutationTypes.SET_STATISTICS_PROFILE_MESSAGES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES_PAGINATION]: async (
    { rootGetters, commit },
    formData
  ) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getUnreadMessages(formData)

    let { data, status } = response

    if (status) {
      commit(MutationTypes.SET_STATISTICS_PROFILE_MESSAGES_PAGINATION, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD]: async ({ rootGetters, commit }, formData) => {
    // commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getNotProcessedDelaeyd(formData)

    let { data, status } = response

    if (status) {
      commit(MutationTypes.SET_STATISTICS_PROFILE_MESSAGES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD_PAGINATION]: async (
    { rootGetters, commit },
    formData
  ) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(StatisticsService, [userToken]).getNotProcessedDelaeyd(formData)

    let { data, status } = response

    if (status) {
      commit(MutationTypes.SET_STATISTICS_PROFILE_MESSAGES_PAGINATION, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
