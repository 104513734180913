import Request from '../helpers/Request'
import BaseService from './BaseService'

class StatisticsService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getAppsFlyer(data) {
    const getAppsFlyerUrl = `/api/v1/stats/traffic_source/install-reports/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getAppsFlyerUrl, { headers, params: data })
  }

  async getAppsFlyerIos(data) {
    const getAppsFlyerUrl = `/api/v1/stats/traffic_source/install-reports/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getAppsFlyerUrl, { headers, params: data })
  }

  async getPurchasesPoints(data) {
    const getPurchasesPointsUrl = `/api/v1/stats/purchases_stat/balls/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getPurchasesPointsUrl, { headers, params: data })
  }

  async getistallLoyalUsers(data) {
    const getistallLoyalUsersUrl = `/api/v1/stats/traffic_source/loyal-users-reports/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getistallLoyalUsersUrl, { headers, params: data })
  }

  async getPurchasesVip(data) {
    const getPurchasesVipUrl = `/api/v1/stats/purchases_stat/vips/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getPurchasesVipUrl, { headers, params: data })
  }

  async getPurchasesByDate(data) {
    const getPurchasesByDateUrl = `/api/v1/stats/purchases_stat/all/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getPurchasesByDateUrl, { headers, params: data })
  }

  async getistallLoyalUsersIos(data) {
    const getistallLoyalUsersIosUrl = `/api/v1/stats/traffic_source/loyal-users-reports/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getistallLoyalUsersIosUrl, { headers, params: data })
  }

  async getActivityUsers(data) {
    const getActivityUsersUrl = `/api/v1/stats/user_stat/activity/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getActivityUsersUrl, { headers, params: data })
  }

  async getUsersCount() {
    const getUsersCountUrl = `/api/v1/stats/user_stat/count/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getUsersCountUrl, { headers })
  }

  async getOnlineByCityUsers(data) {
    const getOnlineByCityUsersUrl = `/api/v1/stats/user_stat/online-by-city/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getOnlineByCityUsersUrl, {
      headers,
      params: data
    })
  }

  async getOnlineOneDay(data) {
    const getOnlineOneDayUrl = `/api/v1/stats/user_stat/online-one-day/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getOnlineOneDayUrl, {
      headers,
      params: data
    })
  }

  async getOnlineOneDayAppSite(data) {
    const getOnlineOneDayAppSiteUrl = `/api/v1/stats/user_stat/online-one-day-from-another-source/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getOnlineOneDayAppSiteUrl, {
      headers,
      params: data
    })
  }

  async getMarketPlacesPurchases(data) {
    const getMarketPlacesPurchasesUrl = `/api/v1/stats/payments_source/purchase-reports/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getMarketPlacesPurchasesUrl, {
      headers,
      params: data
    })
  }

  async getPurchasesByCity(data) {
    const getPurchasesByCityUrl = `/api/v1/stats/payments_source/purchase-reports/city/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getPurchasesByCityUrl, { headers, params: data })
  }

  async getPurchasesByCountry(data) {
    const getPurchasesByCountryUrl = `/api/v1/stats/payments_source/purchase-reports/country/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getPurchasesByCountryUrl, {
      headers,
      params: data
    })
  }

  async getPurchasesByTime(data) {
    const getPurchasesByTimeUrl = `/api/v1/stats/payments_source/purchase-reports/by-time/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getPurchasesByTimeUrl, { headers, params: data })
  }

  async getNewUserActiveByDate(data) {
    const getNewUserActiveByDateUrl = `/api/v1/stats/user_stat/new-user-active-by-date/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getNewUserActiveByDateUrl, { headers, params: data })
  }

  async getPurchasesByPayersPayments(data) {
    const getPurchasesByTimeUrl = `/api/v1/stats/payments_source/purchase-reports/by-payers-and-payments/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getPurchasesByTimeUrl, { headers, params: data })
  }

  async getStatsModeratorPhotoCheck(data) {
    const getStatsModeratorPhotoCheckUrl = `/api/v1/stats/moderator-stats/photo-check-stat-by-day/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getStatsModeratorPhotoCheckUrl, { headers, params: data })
  }

  async getStatsModeratorVideoCheck(data) {
    const getStatsModeratorVideoCheckUrl = `/api/v1/stats/moderator-stats/video-check-stat-by-day/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getStatsModeratorVideoCheckUrl, { headers, params: data })
  }

  async getStatsUsersGender(data) {
    const getStatsUsersGenderUrl = `/api/v1/stats/user_stat/gender-stat-by-reg/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getStatsUsersGenderUrl, { headers, params: data })
  }

  async getNewUserActive(data) {
    const getNewUserActiveUrl = `/api/v1/stats/user_stat/new-user-active-since-create/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getNewUserActiveUrl, { headers, params: data })
  }

  async getStatsUsersPaying(data) {
    const getStatsUsersPayingUrl = `/api/v1/stats/user_stat/payout-user-by-period/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getStatsUsersPayingUrl, { headers, params: data })
  }

  async getStatsUsersAge(data) {
    const getStatsUsersAgeUrl = `/api/v1/stats/user_stat/user-stat-by-age-and-gender/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getStatsUsersAgeUrl, { headers, params: data })
  }

  async getStatsLifetime(data) {
    const getStatsLifetimeAgeUrl = `/api/v1/stats/user_stat/time-to-live-users/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getStatsLifetimeAgeUrl, { headers, params: data })
  }

  async getStatsRegsNewUsers(data) {
    const getSatsRegsNewUsersUrl = `/api/v1/stats/user_stat/activity_from_site/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getSatsRegsNewUsersUrl, { headers, params: data })
  }

  async getStatsMessagesOperator(data) {
    const getStatsMessagesOperatorUrl = `/api/v1/stats/operator_stat/messages/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getStatsMessagesOperatorUrl, { headers, params: data })
  }

  async getPurchaseTotal(data) {
    const getPurchaseTotalUrl = `/api/v1/stats/purchases_stat/total/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getPurchaseTotalUrl, { headers, params: data })
  }

  async getStatsCashesOperator(data) {
    const getStatsCashesOperatorUrl = `/api/v1/stats/operator_stat/cashes/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getStatsCashesOperatorUrl, { headers, params: data })
  }

  async getCountersMessagesOperators(data) {
    const getCountersMessagesOperatorsUrl = `/api/v1/stats/operator_stat/counters/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getCountersMessagesOperatorsUrl, { headers, params: data })
  }

  async getStatisticsByOperatorsProfile(data) {
    const getStatisticsByOperatorsProfileUrl = `/api/v1/stats/operator_stat/profile-messages/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getStatisticsByOperatorsProfileUrl, { headers, params: data })
  }

  async getUnhandledCall(data) {
    const getUnhandledCallUrl = `/api/v1/stats/operator_stat/broken-video-call/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getUnhandledCallUrl, { headers, params: data })
  }

  async getDialogs(data) {
    const getDialogsUrl = `/api/v1/stats/operator_stat/profile-dialogs/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getDialogsUrl, { headers, params: data })
  }

  async getUnreadMessages(data) {
    const getUnreadMessagesUrl = `/api/v1/stats/operator_stat/unread_messages/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getUnreadMessagesUrl, { headers, params: data })
  }

  async getNotProcessedDelaeyd(data) {
    const getUnreadMessagesUrl = `/api/v1/stats/operator_stat/unread_messages_with_time/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getUnreadMessagesUrl, { headers, params: data })
  }
}

export default StatisticsService
