<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Рассчитать модератора?"
    modalClass="popup--small"
  >
    <div class="popup__item">
      <span class="popup__item-text">Рассчитать до:</span>
      <input class="popup__item-date" type="date" v-model="date" />
      <!--      <input class="popup__item-date" type="time" v-model ="time">-->
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="billHandler()">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Нет
        </button>
      </div>
      <span class="popup__item--error" v-if="isError">Дата должна быть не позже текущей</span>
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_BILLING_MODERATORS } from '@/store/action-types'
import { GET_MODERATOR_TMP_ROLES } from '@/store/getter-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { BILL_MODERATOR_POPUP_EVENT } from '@/constants/event'

export default {
  name: 'BillModeratorPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    BILL_MODERATOR_POPUP_EVENT,
    moderatorId: null,
    pay: null,
    date: null,
    time: null,
    isError: false
  }),
  computed: {
    ...mapGetters({
      tmpFilter: GET_MODERATOR_TMP_ROLES
    })
  },

  watch: {
    date: function() {
      this.isError = false
    },
    time: function() {
      this.isError = false
    }
  },

  methods: {
    ...mapActions({
      billModerator: REQUEST_BILLING_MODERATORS
    }),
    closePopup() {
      this.moderatorId = null
      this.pay = null
      this.date = null
      this.time = null
      this.isError = false
      this.close()
    },
    billHandler() {
      let formData = {
        data: {
          pays: [
            {
              moderator_id: this.moderatorId,
              id: this.pay.id,
              payout_period_from: this.pay.payout_period_from,
              payout_period_to: this.pay.payout_period_to,
              photo_sum: this.pay.photo_sum,
              event_sum: this.pay.event_sum,
              pay_sum: this.pay.pay_sum,
              surcharge_sum: this.pay.surcharge_sum
            }
          ]
        },
        active: null
      }
      if (this.date) {
        if (this.time) {
          let formatDate = +Date.parse([this.date, this.time].join(' '))
            .toString()
            .slice(0, -3)
          if (
            +Date.parse(new Date())
              .toString()
              .slice(0, -3) > formatDate
          ) {
            formData.data.pays[0].payout_period_to = this.date + '_' + this.time + ':00.000000'
          } else {
            this.isError = true
          }
        } else {
          let formatDate = +Date.parse(this.date)
            .toString()
            .slice(0, -3)
          if (
            +Date.parse(new Date())
              .toString()
              .slice(0, -3) > formatDate
          ) {
            formData.data.pays[0].payout_period_to = this.date + '_00:00:00.000000'
          } else {
            this.isError = true
          }
        }
      }
      if (this.tmpFilter.status.name && this.tmpFilter.status.name !== 'Все') {
        formData.active = {
          is_active: this.tmpFilter.status.value
        }
      }
      if (!this.isError) {
        this.billModerator(formData)
        this.closePopup()
      }
    }
  },

  mounted() {
    this.$root.$on(BILL_MODERATOR_POPUP_EVENT, data => {
      this.moderatorId = data.moderator
      this.pay = data.pay
      this.open()
    })
  }
}
</script>
<style lang="scss">
.popup__item-date {
  width: 100%;
  height: 30px;
  font-family: inherit;
  margin-bottom: 10px;
}
</style>
