import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_PURCHASES]: (state, payload) => {
    state.purchases = payload
  },
  [MutationTypes.SET_CLEAR_PURCHASES_DATA]: state => {
    for (const key in state.purchaseTmpRoles) {
      state.purchaseTmpRoles[key] = {}
    }
  },
  [MutationTypes.SET_PURCHASE_SETTINGS]: (state, payload) => {
    const { key, value } = payload
    const settingObj = {
      key,
      ...value
    }
    state.purchaseTmpRoles[key] = settingObj
  }
}
