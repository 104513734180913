<template>
  <section ref="filtersWrap" class="filters-wrap filters-wrap--short">
    <form @submit.prevent="submitDepartmentHandler" class="filters " :class="{ opened: mobileFilterOpen }">
      <div class="filters__mobile-nav">
        <button type="button" class="filters__link" @click="mobileFilterOpen = !mobileFilterOpen">
          <i class="filters__icon fa fa-filter"></i>
          <span class="filters__button-text">Фильтры</span>
        </button>
      </div>
      <div class="filters__items">
        <div class="filters__group">
          <div class="filters__item">
            <span class="filters__item-title">Id:</span>
            <input
              type="text"
              class="filters__input filters__input--id"
              v-model="departmentID"
              ref="departmentid"
            />
          </div>
          <div class="filters__item">
            <span class="filters__item-title">Название:</span>
            <input type="text" class="filters__input" v-model="departmentName" ref="departmentname" />
          </div>
        </div>

        <div class="filters__buttons">
          <button type="button" class="filters__btn-clear" @click="resetFiltershandler">
            <i class="fa fa-eraser"></i>
          </button>
          <button type="submit" class="filters__btn-search">
            <span>Поиск</span>
          </button>
        </div>
      </div>
      <button type="button" class="filters__new-ticket" @click="showNewDepartmentModal">
        <i class="filters__new-ticket-icon fa fa-plus-square"></i>
        <span class="filters__button-text">Добавить</span>
      </button>
    </form>
  </section>
</template>

<script>
import { NEW_DEPARTMENT_POPUP_EVENT } from '@/constants/event'
import { REQUEST_DEPARTMENTS_BY_FILTER, REQUEST_CONTROL_DEPARTMENTS } from '@/store/action-types'
import { mapActions } from 'vuex'
export default {
  name: 'Filters',
  data: () => ({
    statusFilterOpen: false,
    appealFilterOpen: false,
    priorityFilterOpen: false,
    mobileFilterOpen: false,

    departmentFilters: {
      department_id: '',
      department_name: ''
    }
  }),

  computed: {
    departmentID: {
      get() {
        return this.departmentFilters.department_id
      },
      set(id) {
        // id = id.replace(/\D/g, '')
        this.$refs.departmentid.value = id
        this.departmentFilters.department_id = id
      }
    },
    departmentName: {
      get() {
        return this.departmentFilters.department_name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.departmentname.value = name
        this.departmentFilters.department_name = name
      }
    }
  },

  methods: {
    ...mapActions({
      applyFilters: REQUEST_DEPARTMENTS_BY_FILTER,
      resetFilters: REQUEST_CONTROL_DEPARTMENTS
    }),
    showNewDepartmentModal() {
      this.$root.$emit(NEW_DEPARTMENT_POPUP_EVENT)
    },

    resetFiltershandler() {
      this.departmentFilters = {}
      this.resetFilters()
    },

    submitDepartmentHandler() {
      const departmentFiltersData = {
        id: this.departmentFilters.department_id,
        name: this.departmentFilters.department_name
      }

      this.applyFilters(departmentFiltersData)

      // for (let key in departmentFiltersData) {
      //   if (departmentFiltersData[key]) {
      //     this.departmentFilters.department_id = ''
      //     this.departmentFilters.department_name = ''
      //     break
      //   }
      // }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$emit('filtersHeight', this.$refs.filtersWrap.clientHeight)
    })
  }
}
</script>

<style lang="scss" scoped>
@import './Filters.scss';
</style>
