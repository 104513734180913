//Права Доступа
export default {
  UserListWithTransition: 101, // | Доступ к списку пользователей, с возможностью перехода к операторы расширенная
  UserListWithoutTransition: 102, // | Доступ к списку пользователей, без возможности перехода
  UserProfileAccess: 103, // | Доступ к профилю пользователей
  UserChatAccess: 104, // | Доступ к чатам пользователей
  UserProfileChangeAccess: 105, // | Доступ к редактированию профиля пользователя
  HiddenUser: 106, // | Доступ к скрытым пользователям админки
  StatisticsUser: 201, // | Доступ к статистике пользователей
  StatisticsPayments: 202, // | Доступ к статистике платежей
  StatisticsPurchases: 203, // | Доступ к статистике покупок
  StatisticsTraffic: 204, // | Доступ к статистике трафика
  StatisticsModeration: 205, // | Доступ к статистике модерации
  StatisticsOperatorsAll: 206, // | Доступ к статистике операторов общая
  StatisticsOperatorExtend: 207, // | Доступ к статистике операторов расширенная
  SupportSources: 301, // | Доступ к поддержке источники         |
  SupportTickets: 302, // | Доступ к тикетам поддержки
  SupportClaim: 303, // | Доступ к жалобам поддержки
  SupportFeedbackAndroid: 304, // | Доступ к отзывам андроида         |
  SupportManagement: 305, // | Доступ к управлению поддержки
  SupportFeedbackIOS: 306, // | Доступ к отзывам iOS         |
  ModerationAnalytics: 401, // | Доступ к аналитике модераторов
  ModerationManagement: 402, // | Доступ к управлению модерации
  ModerationEvent: 403, // | Доступ к модерации событий
  ModerationPhoto: 404, // | Доступ к модерации фото
  ModerationVideo: 405, // | Доступ к модерации видео
  ModerationVerification: 406, // | Доступ к верификации
  ModerationCheckControl: 407, // | Доступ к контролю проверки фотографий
  ModerationActivityOfModerators: 408, // | Доступ к активности модераторов
  ModerationCalendarOfShifts: 409, // | Доступ к календарю смен
  ModerationPayoutsForModerators: 410, // | Доступ к выплатам модераторов
  ModerationRatingOfModerators: 411, // | Доступ к рейтингу модераторов
  ModerationForWork: 412, // | Доступ к работе для простого модератора
  LocalizationAchievements: 501, // | Доступ к справочнику достижений         |
  LocalizationGifts: 502, // | Доступ к справочнику подарков
  LocalizationNotifications: 503, // | Доступ к справочнику уведомлений
  LocalizationWarnings: 504, // | Доступ к справочнику предупреждений
  LocalizationFAQ: 505, // | Доступ к справочнику FAQ
  LocalizationReasonsToDelete: 506, // | Доступ к справочнику причин удаления
  LocalizationReasonsToBan: 507, // | Доступ к справочнику причин блокировки
  LocalizationBalls: 508, // | Доступ к справочнику баллов
  LocalizationTypesOfAppeals: 509, // | Доступ к справочнику типов обращений
  LocalizationAutomessages: 510, // | Доступ к справочнику автосообщений
  LocalizationDigitalPurchases: 511, // | Доступ к справочнику цифровых покупок
  LocalizationLanguages: 512, // | Доступ к справочнику языков
  LocalizationPolicy: 513, // | Доступ к пользовательскому соглашению
  ManagementEmployees: 601, // | Доступ к управлению сотрудников
  ManagementDepartments: 602, // | Доступ к управлению департаментов
  ManagementPositions: 603, // | Доступ к управлению должностей
  ManagementChangePermissionsOfBigBoss: 604, // | Доступ к управлению правами доступа руководителя
  ManagementChangeEmployees: 605, // | Доступ к изменению сотрудников
  ManagementPermissions: 606, // | Доступ к изменению прав доступа
  ManagementChangePositions: 607, // | Доступ к изменению сотрудников
  EventCategory: 701, // | Доступ к категориям событий
  EventListOfEvents: 702, // | Доступ к списку событий
  EventGiftsOfEvents: 703, // | Доступ к подаркам событий
  EventYourEvent: 704, // | Доступ к своему событию
  MarketingUTM: 801, // | Доступ к utm меткам
  MarketingSocialMedia: 802, // | Доступ к социальным медиа
  OperatorListOfOperators: 901, // | Доступ к списку операторов
  OperatorCalendar: 902, // | Доступ к календарю смен операторов
  OperatorPayouts: 903, // | Доступ к выплатам операторов
  OperatorSettings: 904, // | Доступ к настройкам операторов
  OperatorBanners: 905, // | Доступ к баннерам операторов
  OperatorAutomessages: 906, // | Доступ к автосообщениям операторов
  ManagementApplication: 1001, // | Доступ к управлению приложениями
  Automessages: 1101 // | Доступ к автосообщениям
}
