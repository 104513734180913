<template>
  <div>
    <div class="moderators-rating__header">
      <span class="moderators-rating__header-title">Рейтинг модераторов </span>
      <button class="setting__header-btn--settings" @click="isOpen = true">
        <i class="fas fa-cogs"></i>
      </button>
    </div>
    <ModeratorsSettings v-if="isOpen" :closeSideBar="closeSideBar" />
  </div>
</template>

<script>
import ModeratorsSettings from './ModeratorsSettings'
export default {
  name: 'ModeratorsRatingHeaders',
  components: { ModeratorsSettings },
  data: () => ({
    isOpen: false
  }),
  methods: {
    closeSideBar() {
      this.isOpen = false
    }
  }
}
</script>
<style lang="scss">
@import 'ModeratorStatistic';
</style>
