<template>
  <div class="ev-cat__header">
    <span class="ev-cat__title title-big">Свое событие</span>
    <div class="ev-cat__btns">
      <select class="ev-cat__select" v-model="select" @change="getCategoriesByLang(select)">
        <option v-for="lang in languages" :key="lang.value" :value="lang.value">
          {{ lang.name }}
        </option>
      </select>
      <button class="ev-cat__btn-add" @click="$root.$emit(EVENT_REFBOOK_ADD_POPUP, eventref, select)">
        <i class="ev-cat__btn-txt fa fa-plus-square"></i>
        <span class="ev-cat__btn-title">Добавить</span>
      </button>
      <!-- <button
        class="ev-cat__item-btn ev-cat__item-btn--red ev-cat__item-delete"
        @click="$root.$emit(EVENT_REFBOOK_DELETE, eventref.id, eventref.lang)"
      >
        <i class="ev-cat__item-btn-icon far fa-trash-alt"></i>
      </button> -->
    </div>
  </div>
</template>

<script>
import { EVENT_REFBOOK_ADD_POPUP, EVENT_REFBOOK_DELETE } from '@/constants/event'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_ALL_EVENT_REFBOOK } from '@/store/action-types'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types.js'
import { GET_LANGUAGES, GET_CURRENT_LANGUAGES } from '@/store/getter-types'
export default {
  name: 'EventRefBookHeader',
  data() {
    return {
      EVENT_REFBOOK_ADD_POPUP,
      EVENT_REFBOOK_DELETE,
      select: 'RU'
    }
  },
  methods: {
    ...mapActions({
      categoryByLang: REQUEST_ALL_EVENT_REFBOOK
    }),
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    }),
    getCategoriesByLang(value) {
      this.categoryByLang(value)
    }
  },
  props: {
    eventref: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      languages: GET_LANGUAGES,
      currentLang: GET_CURRENT_LANGUAGES
    }),
    selected: {
      get() {
        return this.currentLang
      },
      set(newValue) {
        this.setCurrentLang(newValue)
      }
    }
  }
}
</script>
<style lang="scss">
.ev-cat__item-delete {
  margin-top: 4px;
}
</style>
