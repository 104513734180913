<template>
  <Popup :visible="visible" @close="closeModal" title="" class="pop-up">
    <form @submit.prevent="addEventCalendar" class="modal-add__event">
      <h1 class="modal-add__title">Добавить смену</h1>
      <h1 class="pop-up__title">Выберите оператора:</h1>
      <ul class="pop-up__list">
        <li class="pop-up__el" v-for="(moderator, index) in moderators" :key="index">
          <label :id="moderator.first_name"
            ><i class="fa fa-user" :style="`color: ${moderator.color}`" /> {{ moderator.first_name }}
            {{ moderator.last_name }}</label
          >
          <input
            :id="moderator.first_name"
            :value="moderator.id"
            class="active-filter popup__input"
            type="radio"
            v-model="chekedId"
          />
        </li>
      </ul>
      <div class="pop-up__btn-wrapper">
        <button class="button button--add">Сохранить</button>
        <button type="button" @click="closeModal" class="button button--back">
          Закрыть
        </button>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { CALENDAR_ADD_POPUP } from '@/constants/event'
import { REQUEST_ADD_CALENDAR_EVENT } from '@/store/action-types'
import moment from 'moment'
import { mapActions } from 'vuex'
import pickerMixin from '@/components/Picker/pickerMixin'
export default {
  name: 'CalendarAddEventPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin, pickerMixin],

  data() {
    return {
      moderators: [],
      event: {},
      start: '',
      end: '',
      value2: [],
      chekedId: []
    }
  },

  props: {},

  computed: {
    datePickerControlreg: {
      get() {
        const date = {
          startDate: '',
          endDate: ''
        }
        return date
      },
      set({ startDate, endDate }) {
        const timestampStartDate = Math.round(new Date(startDate.setHours(0, 0, 0, 0)).getTime() / 1000.0)
        const timestampEndDate = Math.round(new Date(endDate.setHours(23, 59, 59, 59)).getTime() / 1000.0)
        const timeStampDateObject = {
          startDate: timestampStartDate,
          endDate: timestampEndDate
        }
        this.dateFilter({ timeStampDateObject, key: 'register' })
      }
    }
  },

  mounted() {
    this.$root.$on(CALENDAR_ADD_POPUP, content => {
      this.event = content.selectInfo
      this.moderators = content.moderatorList
      this.start = moment(this.event.start).format('YYYY-MM-DD HH:mm:ss')
      this.end = moment(this.event.end).format('YYYY-MM-DD HH:mm:ss')
      this.open()
    })
  },

  methods: {
    ...mapActions({
      addEvent: REQUEST_ADD_CALENDAR_EVENT
    }),
    closeModal() {
      this.event = null
      this.moderators = null
      this.start = ''
      this.end = ''
      this.chekedId = []
      this.close()
    },
    addEventCalendar() {
      const formData = {
        operator_id: this.chekedId,
        date: this.start
      }
      this.addEvent(formData).then(response => {
        this.$emit('my-event')
      })
      this.event = null
      this.moderators = null
      this.start = ''
      this.end = ''
      this.chekedId = []
      this.close()
    }
  }
}
</script>

<style lang="scss">
@import './CalendarPopup.scss';
@import '@/components/App-users/Filters/Filters.scss';
.modal-add__event {
  width: 650px;
}
</style>
