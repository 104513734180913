import Request from '../helpers/Request'
import BaseService from './BaseService'

class DepartmentService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getDepartments() {
    const employeesUrl = `/api/v1/management/departments/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }
    return await Request.get(employeesUrl, { headers })
  }

  async getDepartmentByFilter(formData) {
    const departamentByFilter = `support/api/v1/departments/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    Object.keys(formData).forEach(
      key => (formData[key] === '' || formData[key] === undefined) && delete formData[key]
    )

    return await Request.get(departamentByFilter, { headers, params: formData })
  }

  async createDepartment(department) {
    const departmentAddUrl = '/api/v1/management/departments/add/'

    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(departmentAddUrl, department, { headers })
  }

  async deleteDepartmentById(id) {
    const employeeDestroyUrl = `/api/v1/management/departments/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(employeeDestroyUrl, { headers })
  }

  async updateDepartment(department) {
    const departmentUpdateUrl = `/api/v1/management/departments/${department.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(departmentUpdateUrl, department.departmentData, {
      headers
    })
  }
}

export default DepartmentService
