<template>
  <Popup :visible="visible" @close="close" description="Удалить причину?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteReasonDelete">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { REASON_DELETE_POPUP } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_DELETE_REASON_DELETE } from '@/store/action-types'

export default {
  name: 'ReasonDeletePopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data() {
    return {
      id: ''
    }
  },
  methods: {
    ...mapActions({
      deleteReason: REQUEST_DELETE_REASON_DELETE
    }),
    deleteReasonDelete() {
      this.deleteReason(this.id)
      this.id = ''
      this.close()
    }
  },

  mounted() {
    this.$root.$on(REASON_DELETE_POPUP, reasondel => {
      this.open()
      this.id = reasondel
    })
  }
}
</script>
