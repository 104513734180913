<template>
  <div class="department">
    <div class="department__field department__field--avatar">
      <!-- <div class="department__avatar">
        <FaIcon :iconClass="department.icon" />
      </div> -->
      <span class="department__name">
        <!-- <span class="department__mobtitle">Ф.И.О.:</span> -->
        <span class="department__text">{{ department.name }}</span>
      </span>
    </div>
    <div class="department__field department__field--btns">
      <button
        @click="$root.$emit(EDIT_DEPARTMENT_POPUP_EVENT, department)"
        class="department__btn btn-blue department__btn--blue"
      >
        <i class="fa fa-edit"></i>
      </button>
      <button
        @click="$root.$emit(DELETE_DEPARTMENT_POPUP_EVENT, department.id)"
        class="department__btn btn-red department__btn--red"
      >
        <i class="fa fa-times-circle"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { EDIT_DEPARTMENT_POPUP_EVENT, DELETE_DEPARTMENT_POPUP_EVENT } from '@/constants/event'
export default {
  name: 'Department',
  props: {
    department: {
      type: Object
    }
  },
  data: () => ({
    EDIT_DEPARTMENT_POPUP_EVENT,
    DELETE_DEPARTMENT_POPUP_EVENT
  })
}
</script>
<style lang="scss">
@import './Department.scss';
</style>
