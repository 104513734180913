import Request from '../helpers/Request'
import BaseService from './BaseService'

class MarketService extends BaseService {
  constructor(token) {
    super(token)
  }
  // MARKET UTM
  async getMarketUtm() {
    const getMarketUtmUrl = `/api/v1/marketing/utm/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }
    return await Request.get(getMarketUtmUrl, {
      headers
    })
  }

  async addMarketUtm(payload) {
    const addMarketUtmUrl = '/api/v1/marketing/utm/add/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(addMarketUtmUrl, payload, { headers })
  }

  async updateMarketUtm(payload) {
    const updateMarketUtmUrl = `/api/v1/marketing/utm/${payload.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(updateMarketUtmUrl, payload, { headers })
  }

  async deleteMarketUtm(id) {
    const deleteMarketUtmUrl = `/api/v1/marketing/utm/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteMarketUtmUrl, { headers })
  }
}

export default MarketService
