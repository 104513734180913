import Request from '../helpers/Request'
import BaseService from './BaseService'

class PushService extends BaseService {
  constructor(token) {
    super(token)
  }
  // Pushes
  async getPush(lang) {
    const getPushUrl = `/api/v1/localization/push/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }
    return await Request.get(getPushUrl, {
      headers,
      params: { lang }
    })
  }
  async addPush(payload) {
    const addPushUrl = '/api/v1/localization/push/add/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(addPushUrl, payload, { headers })
  }
  async updatePush(payload) {
    const updatePushUrl = `/api/v1/localization/push/${payload.id}/update/`
    delete payload.id
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(updatePushUrl, payload, { headers })
  }
  async deletePusht(id) {
    const deletePushtUrl = `/api/v1/localization/push/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deletePushtUrl, { headers })
  }
}

export default PushService
