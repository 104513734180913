<template>
  <div class="achievement">
    <ul class="achievement__list">
      <AchievementItem v-for="item in achievements" :key="item.id" :item="item" />
    </ul>
  </div>
</template>

<script>
import AchievementItem from './AchievementItem.vue'

export default {
  name: 'AchievementsList',
  props: {
    achievements: {
      type: Array
    }
  },
  components: {
    AchievementItem
  }
}
</script>

<style lang="scss"></style>
