<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

import 'chartjs-plugin-datalabels'
export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  mounted() {
    this.renderChartLine()
  },
  methods: {
    renderChartLine: function() {
      this.renderChart(this.chartData, this.options)
    },
    updateChartLine: function() {
      this.$data._chart.update()
    }
  },
  computed: {
    reRenderLabels() {
      return this.chartData.labels
    },
    reRenderDataLabels() {
      return this.chartData.datasets[0].datalabels.display
    }
  },
  watch: {
    reRenderLabels() {
      this.renderChartLine()
    },
    reRenderDataLabels() {
      this.updateChartLine()
    }
  }
}
</script>
