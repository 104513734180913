const MessageContentType = {
  TEXT: 0,
  IMAGE: 1,
  VIDEO: 2,
  AUDIO: 3,
  MUSIC: 4,
  FILE: 5,
  GEO: 6,
  GIFT: 7,
  VIDEO_CALL: 8,
  VIDEO_CALL_CANCELED: 9, //Отменил звонящий
  VIDEO_CALL_REJECTED: 10 // Отменил принимающий
}

export { MessageContentType }
