import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import PermissionService from '@/services/PermissionService'

export default {
  [ActionTypes.REQUEST_CONTROL_PERMISSIONS]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(PermissionService, [userToken]).getPermissions()
    const { data } = response

    if (data) {
      let sortArr = data.sort((a, b) => (a.permission_level > b.permission_level ? 1 : -1))
      commit(MutationTypes.SET_PERMISSIONS, sortArr)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CREATE_PERMISSION]: async ({ rootGetters, commit, dispatch }, position) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(PermissionService, [userToken]).createPermission(position)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_CONTROL_PERMISSIONS)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_DELETE_PERMISSION]: async ({ rootGetters, commit, dispatch }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(PermissionService, [userToken]).deletePermissionById(id)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_CONTROL_PERMISSIONS)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_UPDATE_PERMISSION]: async ({ rootGetters, commit, dispatch }, updatedepermission) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(PermissionService, [userToken]).updatePermission(
      updatedepermission
    )
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_CONTROL_PERMISSIONS)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_PERMISSIONS_GROUP]: async ({ rootGetters, commit, dispatch }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(PermissionService, [userToken]).getPermissionsGroup()
    const { status, data } = response
    if (status) {
      commit(MutationTypes.SET_PERMISSIONS_GROUP, data.permissions_group)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
