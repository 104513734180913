<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description=""
    modalClass="popup--small popup--operator popup--scroll"
  >
    <div class="popup__item">
      <div class="operator-profile__edit-group">
        <span>{{ title }}</span>
        <input
          v-if="!autoMesssage"
          type="text"
          class="operator-message-input"
          v-model.number="messageDelay"
        />
      </div>
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="saveAutoMesssage" :disabled="buttonDis">
          {{ buttonSave }}
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Закрыть
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions } from 'vuex'
import { REQUEST_EDIT_OPERATORS_AUTO_MESSAGE } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EDIT_AUTO_MESSAGE_DELAY } from '@/constants/event'

export default {
  name: 'OperatorAutoMessageDelay',
  components: {
    Popup
  },
  data: () => ({
    autoMesssage: false,
    messageDelay: 0,
    accountId: '',
    operatorId: ''
  }),
  mixins: [ModalMixin],
  mounted() {
    this.$root.$on(EDIT_AUTO_MESSAGE_DELAY, (data, id) => {
      this.operatorId = id
      this.accountId = data.account.id
      this.autoMesssage = data.auto_message
      this.open(EDIT_AUTO_MESSAGE_DELAY)
    })
  },
  computed: {
    title() {
      return this.autoMesssage ? 'Отменить отправку сообщений' : 'Задайте время задержки в мин.'
    },
    buttonSave() {
      return this.autoMesssage ? 'Отменить' : 'Сохранить'
    },
    buttonDis() {
      return !this.autoMesssage && this.messageDelay <= 0
    }
  },
  methods: {
    ...mapActions({
      editAutoMesssage: REQUEST_EDIT_OPERATORS_AUTO_MESSAGE
    }),
    saveAutoMesssage() {
      const payload = {
        operator: this.operatorId,
        data: {
          accounts: [
            {
              account_id: this.accountId,
              auto_message: !this.autoMesssage,
              auto_message_delay: +this.messageDelay
            }
          ]
        }
      }
      this.editAutoMesssage(payload).then(res => {
        if (res.status) this.$root.$emit('changeOperators', 'editDelay')
      })
      this.messageDelay = 0
      this.close()
    },
    closePopup() {
      this.close()
    }
  }
}
</script>
<style lang="scss" scoped>
@import './EditOperator.scss';
.operator-message-input {
  margin: auto;
  margin-top: 20px;
  height: 34px;
  text-align: center;
  font-size: 14px;
  color: #717171;
  font-family: inherit;
  width: 40%;
  border-radius: 5px;
}
.popup__btn {
  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
}
</style>
