var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"user-block"},[_c('div',{staticClass:"user-block__body"},[_c('div',{staticClass:"user-block__avatar"},[_c('img',{attrs:{"src":_vm.getFile({
            type: 'image',
            file: _vm.currentEvent.event_user.user_avatar.content_id,
            width: 100,
            height: 100
          }),"alt":""}})]),_c('div',{staticClass:"user-block__user"},[_c('div',{staticClass:"user-block__user-header"},[_c('div',{staticClass:"user-block__user-header-item"},[_c('div',{staticClass:"user-block__user-name"},[_c('i',{class:{
                'fa fa-male': _vm.currentEvent.event_user.user_gender === 1,
                'fa fa-female': _vm.currentEvent.event_user.user_gender === 2
              }}),_c('span',{staticClass:"user-block__user-name-text"},[_vm._v("Имя:")]),_c('router-link',{staticClass:"app-user__name-link",attrs:{"to":{
                name: 'UserItem',
                params: {
                  userId: _vm.currentEvent.event_user.id,
                  user: _vm.currentEvent.event_user
                }
              }}},[_c('span',{staticClass:"user-block__user-name-value"},[_vm._v(_vm._s(_vm.currentEvent.event_user.user_name)+", ")]),_c('span',{staticClass:"user-block__user-name-age"},[_vm._v("("+_vm._s(_vm.currentEvent.event_user.age || '0')+")")])])],1)]),_vm._m(0)]),_c('div',{staticClass:"user-block__user-name-body"},[_c('div',{staticClass:"user-block__location"},[_c('span',{staticClass:"user-block__user-city"},[_vm._v(_vm._s(_vm.currentEvent.event_user.user_city))])]),_c('span',{staticClass:"event__user-events-score"},[_c('FaIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Рейтинг'),expression:"'Рейтинг'"}],staticClass:"event__user-name-icon",attrs:{"iconClass":'fa fa-star'}}),_c('span',{staticClass:"event__user-name-count"},[_vm._v(_vm._s("-"))])],1),_c('span',{staticClass:"event__user-events-score"},[_c('FaIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Созданные события'),expression:"'Созданные события'"}],staticClass:"event__user-name-icon",attrs:{"iconClass":'fa fa-calendar-plus'}}),_c('span',{staticClass:"event__user-name-count"},[_vm._v(_vm._s(_vm.currentEvent.event_count.outbid))])],1),_c('span',{staticClass:"event__user-events-score"},[_c('FaIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Посещенные события'),expression:"'Посещенные события'"}],staticClass:"event__user-name-icon",attrs:{"iconClass":'fa fa-calendar-check'}}),_c('span',{staticClass:"event__user-name-count"},[_vm._v(_vm._s(_vm.currentEvent.event_count.invite))])],1)])])]),_c('div',{staticClass:"user-block__user-social"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('span',{staticClass:"user-block__user-mail",attrs:{"href":"#"}},[_c('i',{staticClass:"user-block__user-mail-icon fa fa-envelope"}),_c('span',{staticClass:"user-block__user-mail-text"},[_vm._v(_vm._s(_vm.currentEvent.event_user.user_email))])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-block__user-header-item"},[_c('div',{staticClass:"user-block__claims-count"},[_c('span',{staticClass:"user-block__mobtitle"},[_vm._v("Входящих жалоб:")]),_c('i',{staticClass:"fas fa-sign-out-alt"}),_c('i',{staticClass:"fa fa-bullhorn"}),_c('span',{staticClass:"user-block__claims-count-num"},[_vm._v("1")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"user-block__user-link user-block__user-vk"},[_c('i',{staticClass:"fab fa-vk"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"user-block__user-link user-block__user-fb"},[_c('i',{staticClass:"fab fa-facebook"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"user-block__user-link user-block__user-apple"},[_c('i',{staticClass:"fa fa-crown"})])}]

export { render, staticRenderFns }