<template>
  <div class="ev-cat__header">
    <span class="ev-cat__title title-big">UTM метки</span>
    <div class="ev-cat__btns">
      <button class="ev-cat__btn-add" @click="$root.$emit(MARKET_UTM_ADD_POPUP)">
        <i class="ev-cat__btn-txt fa fa-plus-square"></i>
        <span class="ev-cat__btn-title">Добавить</span>
      </button>
    </div>
  </div>
</template>

<script>
import { MARKET_UTM_ADD_POPUP } from '@/constants/event'
export default {
  name: 'MarketUtmHeader',
  data() {
    return { MARKET_UTM_ADD_POPUP }
  }
}
</script>
<style lang="scss"></style>
