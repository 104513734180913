<template>
  <div class="wrapper-interface-faq">
    <faq-header />
    <div class="intarface-faq__content" v-if="!error">
      <!-- <faq-select :currentLangTitle="currentLang" :allSelectData="languages" /> -->
      <faq-questions-list :currentContent="currentContent" :currentContentId="currentContentId" />
      <faq-categoryes-list :allCategory="currentCategory" />
    </div>
    <div class="intarface-faq__content-error" v-else>
      <span class="intarface-faq__content-error-text">Ошибка при загрузке FAQ</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  GET_LANGUAGES,
  GET_CURRENT_LANGUAGES,
  GET_ALL_CATEGORY_BY_LANG,
  GET_CONTENT_BY_ID,
  GET_CURRENT_CONTENT_ID
} from '@/store/getter-types.js'

import FaqCategoryesList from './FaqCategoryesList.vue'
import FaqHeader from './FaqHeader'
import FaqQuestionsList from './FaqQuestionsList'

// import FaqSelect from "./FaqSelect.vue";

export default {
  name: 'Faq',
  components: {
    FaqHeader,
    FaqQuestionsList,
    FaqCategoryesList
    // FaqSelect
  },

  props: {
    error: {
      type: Boolean
    }
  },

  data() {
    return {
      allCategory: null
    }
  },

  computed: {
    ...mapGetters({
      languages: GET_LANGUAGES,
      currentLang: GET_CURRENT_LANGUAGES,
      currentCategory: GET_ALL_CATEGORY_BY_LANG,
      currentContent: GET_CONTENT_BY_ID,
      currentContentId: GET_CURRENT_CONTENT_ID
    })
  }
}
</script>

<style lang="scss">
@import './Faq.scss';
</style>
