<template>
  <div class="user-active">
    <div class="user-active__menu">
      <div class="user-active__left">
        <span class="user-active__left-text">Дата:</span>
        <div class="datepicker-traffic-source">
          <datepicker
            class="datepicker-online"
            v-model="activity_stats_form.date"
            input-class="datepicker__input"
            :language="ru"
          >
          </datepicker>
          <i class="select__field-icon fa fa-angle-down"></i>
        </div>
        <button class="user-active__btn" @click="getActivityStats">
          Показать
        </button>
        <div class="user-active__btn-group user-active__btn-group--values">
          <button
            class="user-active__btn user-active__btn--default"
            @click="changeStatsShowLabels"
            :class="{
              'user-active__btn--primary': active_reg_data_show_labels
            }"
          >
            Значения
          </button>
        </div>
      </div>
    </div>
    <div>
      <LineChart
        :chart-data="active_reg_data_statistic"
        :options="active_reg_data_statistic_opt"
        class="regenterstats-chart"
      >
      </LineChart>
    </div>
  </div>
</template>

<script>
import LineChart from './LineChart'
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import { ru } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import 'chartjs-plugin-datalabels'
import { REQUEST_NEW_USER_ACTIVE_BY_DATE } from '@/store/action-types'
import { mapActions } from 'vuex'

export default {
  name: 'StatisticActiveNewUserByDate',
  components: {
    LineChart,
    Datepicker
  },
  props: {
    newUserActiveByDate: {
      type: Object
    }
  },
  data: () => ({
    ru: ru,
    active_reg_data_show_labels: false,
    active_reg_data_statistic: {
      labels: [],
      datasets: [
        {
          borderColor: 'rgba(255,108,96,1)',
          backgroundColor: 'rgba(255,108,96,0.3)',
          pointBackgroundColor: 'rgba(255,108,96,1)',
          fill: true,
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverBorderWidth: 2,
          label: '28 день',
          data: [],
          datalabels: {
            align: 'bottom',
            display: false
          }
        },
        {
          borderColor: 'rgba(47,56,96,1)',
          backgroundColor: 'rgba(87, 101, 175,0.5)',
          pointBackgroundColor: 'rgba(47,56,96,1)',
          fill: true,
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverBorderWidth: 2,
          label: '14 день',
          data: [],
          datalabels: {
            align: 'top',
            display: false
          }
        },
        {
          borderColor: 'rgba(123, 180, 99, 1)',
          backgroundColor: 'rgba(123, 180, 99, 0.5)',
          pointBackgroundColor: 'rgba(123, 180, 99, 1)',
          fill: true,
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverBorderWidth: 2,
          label: '7 день',
          data: [],
          datalabels: {
            align: 'bottom',
            display: false
          }
        },
        {
          borderColor: 'rgba(74,92,106,0.5)',
          backgroundColor: 'rgba(151,187,205,0.5)',
          pointBackgroundColor: 'rgba(74,92,106,0.72)',
          fill: true,
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverBorderWidth: 2,
          label: '1 день',
          data: [],
          datalabels: {
            align: 'top',
            display: false
          }
        }
      ]
    },
    active_reg_data_statistic_opt: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function(value) {
                return `${value}%`
              }
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              fontColor: []
            }
          }
        ]
      },
      tooltips: {
        mode: 'label',
        itemSort: function(a, b) {
          return b.datasetIndex - a.datasetIndex
        },
        enabled: true,
        callbacks: {
          label: (tooltipItem, data) => {
            let label = data.datasets[tooltipItem.datasetIndex].label || ''

            if (label) {
              label += ': '
            }

            return `${label}${tooltipItem.yLabel}%`
          }
        }
      },
      elements: {
        point: {
          radius: 4,
          hoverRadius: 6
        }
      },
      plugins: {
        datalabels: {
          display: false,
          offset: 8,
          backgroundColor: function(context) {
            return context.dataset.backgroundColor
          },
          borderRadius: 4,
          borderColor: 'white',
          borderWidth: 1,
          color: '#fff',
          font: {
            weight: 'bold'
          }
        }
      }
    },
    activity_stats_form: {
      date: moment().format('YYYY-MM-DD')
    }
  }),
  watch: {
    active_reg_data_show_labels: function() {
      this.setRegEnterStatsShowLabels()
    }
  },
  mounted() {
    this.reLoaded()
    this.setDefaultOnlineDate()
  },
  methods: {
    ...mapActions({
      getUserActivity: REQUEST_NEW_USER_ACTIVE_BY_DATE
    }),
    changeStatsShowLabels() {
      this.active_reg_data_show_labels = !this.active_reg_data_show_labels
    },
    setRegEnterStatsShowLabels: function() {
      let datasets = this.active_reg_data_statistic.datasets
      for (let dataset of datasets) {
        dataset.datalabels.display = this.active_reg_data_show_labels
      }
      this.active_reg_data_statistic.datasets = datasets
    },
    setDefaultOnlineDate: function() {
      this.activity_stats_form.date = moment().format('YYYY-MM-DD')
    },
    getActivityStats() {
      this.getUserActivity({
        date: moment(this.activity_stats_form.date).format('YYYY-MM-DD')
      }).then(response => {
        this.reLoaded()
      })
    },
    reLoaded() {
      this.active_reg_data_statistic.datasets[0].data = this.newUserActiveByDate.four_week
      this.active_reg_data_statistic.datasets[1].data = this.newUserActiveByDate.two_week
      this.active_reg_data_statistic.datasets[2].data = this.newUserActiveByDate.one_week
      this.active_reg_data_statistic.datasets[3].data = this.newUserActiveByDate.one_day
      // подсвечиваем дни недели (сб и вс)
      let labels_color = []
      let labels_format = []
      for (let label of this.newUserActiveByDate.labels) {
        let day = moment(new Date(label)).format('dd')
        if (day === 'Su' || day === 'Sa') {
          labels_color.push('#ff6c60')
        } else {
          labels_color.push('#666')
        }
        labels_format.push(moment(new Date(label)).format('DD.MM'))
      }
      this.active_reg_data_statistic.labels = labels_format
      this.active_reg_data_statistic_opt.scales.xAxes[0].ticks.fontColor = labels_color
    }
  }
}
</script>

<style lang="scss">
@import './Statistic.scss';
</style>
