<template>
  <Popup :visible="visible" @close="closeModal" title="" modalClass="pop-up">
    <!--    <form @submit.prevent="addNewModerator" class="modal-add__event">-->
    <form class="modal-add__event">
      <h1 class="modal-add__title">Редактирование смены c {{ start }} по {{ end }}</h1>
      <div class="pop-up__moderators">
        <h1 class="pop-up__title">Операторы в смене:</h1>
        <ul v-if="currentEvent" class="pop-up__list">
          <li class="pop-up__el" v-for="(employee, index) in currentModerators" :key="index">
            <span>{{ employee.first_name }} {{ employee.last_name }}</span>
            <button type="button" @click="deleteModerator(employee.id)" class="pop-up__del-btn"></button>
          </li>
        </ul>
      </div>
      <div v-if="needAdd">
        <h1 class="pop-up__title">Выберите оператора:</h1>
        <ul class="pop-up__list">
          <li class="pop-up__el" v-for="(moderator, index) in moderators" :key="index">
            <label :id="moderator.id"
              ><i class="fa fa-user" :style="`color: ${moderator.color}`" /> {{ moderator.first_name }}
              {{ moderator.last_name }}</label
            >
            <input
              :id="moderator.id"
              :value="moderator.id"
              class="active-filter popup__input"
              type="radio"
              v-model="chekedId"
            />
          </li>
        </ul>
      </div>
      <div class="pop-up__btn-wrapper">
        <button v-if="needAdd" @click.stop.prevent="addNewModerator" class="button button--add">
          Сохранить
        </button>
        <button
          v-if="!needAdd"
          type="button"
          @click.stop.prevent="needAdd = !needAdd"
          class="button button--add-moderator"
        >
          <div class="button--add-icon">
            <i class="far fa-plus-square"></i>
            <span class="button--add-text--mini">Оператора</span>
          </div>
          <span class="button--add-text">Добавить оператора в смену</span>
        </button>

        <button type="button" @click="closeModal" class="button button--back">
          Закрыть
        </button>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import {
  CALENDAR_EDIT_POPUP,
  CALENDAR_DEL_MODERATOR_POPUP,
  CALENDAR_ERROR_POPUP,
  CALENDAR_CLOSE_POPUP,
  CALENDAR_NO_CLOSE_POPUP
} from '@/constants/event'
import { REQUEST_ADD_MODERATOR_EVENT } from '@/store/action-types'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'CalendarEditEventPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data() {
    return {
      moderators: [],
      currentEvent: null,
      currentModerators: null,
      start: '',
      end: '',
      chekedId: null,
      needAdd: false,
      CALENDAR_DEL_MODERATOR_POPUP,
      CALENDAR_ERROR_POPUP
    }
  },
  computed: {},

  props: {},

  mounted() {
    this.$root.$on(CALENDAR_EDIT_POPUP, content => {
      this.currentEvent = content.currentEvent
      this.currentModerators = content.currentEvent.extendedProps.operators
      this.moderators = content.moderatorList
      // this.currentModerators.map(current => {
      //   this.moderators = this.moderators.filter(m => m.id !== current.id);
      //   return this.moderators;
      // })
      this.start = moment(this.currentEvent.start).format('YYYY-MM-DD HH:mm:ss')
      this.end = moment(this.currentEvent.end).format('YYYY-MM-DD HH:mm:ss')
      this.open()
    })
  },
  methods: {
    ...mapActions({
      addModerator: REQUEST_ADD_MODERATOR_EVENT
    }),
    openModerator() {
      this.needAdd = !this.needAdd
    },
    closeModal() {
      this.currentEvent = null
      this.currentModerators = null
      this.moderators = null
      this.start = ''
      this.end = ''
      this.chekedId = null
      this.needAdd = false
      // this.$emit('my-event');
      this.close()
    },
    addNewModerator() {
      if (this.needAdd && this.currentModerators) {
        if (this.currentModerators.find(x => x.id === this.chekedId)) {
          let causeError = 'repeatModerator'
          let info = this.start
          this.$root.$emit(this.CALENDAR_ERROR_POPUP, { info, causeError })
        } else {
          const formData = {
            userId: {
              operator_id: this.chekedId
            },
            scheduleId: this.currentEvent.id,
            params: {
              start: this.start,
              end: this.end
            }
          }
          this.addModerator(formData).then(response => {
            if (response.status) {
              this.$emit('my-event')
              setTimeout(this.closeModal(), 300)
            }
          })
        }
      } else {
        this.closeModal()
      }
    },
    deleteModerator(id) {
      let formDate = {
        scheduleId: this.currentEvent.id,
        userId: id,
        endShift: Boolean()
      }
      if (this.currentModerators.length > 1) {
        formDate.endShift = false
      } else {
        formDate.endShift = true
      }
      this.$root.$emit(this.CALENDAR_DEL_MODERATOR_POPUP, formDate)
      this.$root.$on(CALENDAR_NO_CLOSE_POPUP, moderatorId => {
        this.currentModerators = this.currentModerators.filter(n => n.id !== moderatorId)
        formDate = {}
      })
      this.$root.$on(CALENDAR_CLOSE_POPUP, () => {
        // this.$emit('my-event');
        this.closeModal()
        // setTimeout(this.closeModal(), 1000000)
      })
    }
  }
}
</script>

<style lang="scss">
@import './CalendarPopup.scss';
.modal-add__title {
  margin-top: 20px;
  font-weight: 700;
  text-align: center;
}

.button--add-moderator {
  display: block;
  border: 1px solid transparent;
  border-radius: 0.25em;
  background-color: #2c3e50;
  color: white;

  &:hover {
    background-color: #151e27;
  }
}
.button--del-moderator {
  background-color: red;
}

.button--add-text {
  @media (max-width: 1000px) {
    display: none;
  }
}
.button--add-icon {
  display: none;
  @media (max-width: 1000px) {
    display: block;
    @media (max-width: 755px) {
      .button--add-text--mini {
        display: none;
      }
    }
  }
}
</style>
