const REQUEST_MODERATORS_RATING = 'REQUEST_MODERATORS_RATING'
const REQUEST_MODERATOR_SETTINGS_SPEED = 'REQUEST_MODERATOR_SETTINGS_SPEED'
const REQUEST_MODERATOR_SETTINGS_QUALITY = 'REQUEST_MODERATOR_SETTINGS_QUALITY'
const REQUEST_UPDATE_MODERATOR_SETTINGS_QUALITY = 'REQUEST_UPDATE_MODERATOR_SETTINGS_QUALITY'
const REQUEST_UPDATE_MODERATOR_SETTINGS_SPEED = 'REQUEST_UPDATE_MODERATOR_SETTINGS_SPEED'

export {
  REQUEST_MODERATORS_RATING,
  REQUEST_MODERATOR_SETTINGS_SPEED,
  REQUEST_MODERATOR_SETTINGS_QUALITY,
  REQUEST_UPDATE_MODERATOR_SETTINGS_QUALITY,
  REQUEST_UPDATE_MODERATOR_SETTINGS_SPEED
}
