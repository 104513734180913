<template>
  <Popup :visible="visible" @close="close" description="Удалить достижение?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteAchievementEvent">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { ACHIEVEMENT_DELETE_POPUP_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_DELETE_ACHIEVEMENT } from '@/store/action-types'

export default {
  name: 'AchievementDeletePopUp',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data() {
    return {
      id: ''
    }
  },
  methods: {
    ...mapActions({
      deleteAchievement: REQUEST_DELETE_ACHIEVEMENT
    }),
    deleteAchievementEvent() {
      this.deleteAchievement(this.id)
      this.id = ''
      this.close()
    }
  },

  mounted() {
    this.$root.$on(ACHIEVEMENT_DELETE_POPUP_EVENT, eventcat => {
      this.open()
      this.id = eventcat
    })
  }
}
</script>
