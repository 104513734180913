<template>
  <header class="header" v-click-outside="close">
    <div class="header__left">
      <Burger @asideHandler="asidebarHandler" />
      <div class="header__logo">
        <router-link v-if="isOperator" to="/users" class="header__logo-text"
          >Skylove<span class="header__logo-text--orange">Admin</span>
        </router-link>
        <router-link v-else to="/operators" class="header__logo-text"
          >Skylove<span class="header__logo-text--orange">Admin</span>
        </router-link>
      </div>
    </div>
    <div class="header__right">
      <a v-if="user" class="header__select" @click="dropdown = !dropdown">
        <img
          v-if="user.avatar"
          :src="
            getFile({
              type: 'image',
              file: user.avatar
            })
          "
          alt=""
          class="header__select-img"
        />
        <img v-else src="@/assets/img/avatar1_small.jpg" alt="" class="header__select-img" />
        <span class="header__select-text">{{ `${user.first_name} ${user.last_name}` }}</span>
        <span :class="['header__select-icon', { 'header__select-icon--active': dropdown }]" />
      </a>
      <transition name="fade">
        <ul class="dropdown" v-if="dropdown">
          <li v-for="item in dropdownItems" :key="item.id" class="dropdown__item">
            <router-link
              :to="item.link"
              :class="{ 'dropdown__btn--logout': item.id === 4 }"
              class="dropdown__btn"
            >
              <i class="dropdown__btn-icon fa" :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </router-link>
          </li>
        </ul>
      </transition>
    </div>
  </header>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { REQUEST_ALL_LANGUAGES } from '@/store/action-types'
import { GET_USER, GET_TOKEN, GET_PERMISSIONS_USER } from '@/store/getter-types'
import getImgCdnLink from '@/components/mixins/getImgCdnLink'
import Burger from './Burger/Burger'

export default {
  name: 'Header',
  components: {
    Burger
  },
  data: () => ({
    openmenu: false,
    dropdown: false,
    dropdownItems: [
      {
        title: 'Выход',
        link: '/logout',
        icon: 'fa-key',
        id: 3
      }
    ]
  }),

  computed: {
    ...mapGetters({
      user: GET_USER,
      tokken: GET_TOKEN,
      permission_lvl: GET_PERMISSIONS_USER
    }),
    isOperator: function() {
      if (this.permission_lvl && this.permission_lvl.includes(45) && this.permission_lvl.length === 2) {
        return false
      } else {
        return true
      }
    }
  },
  // beforeMount() {
  //   if(this.tokken)
  //   this.userData().then(response => {
  //     this.permission();
  //   })
  // },

  mounted() {
    if (this.user) {
      this.langsRequest()
    }
  },
  mixins: [getImgCdnLink],
  methods: {
    ...mapActions({
      langsRequest: REQUEST_ALL_LANGUAGES
      // userData: REQUEST_PROFILE_ID,
      // permission: REQUEST_PERMISSION_USER
    }),
    asidebarHandler() {
      let filterStyle = document.querySelectorAll('.filters-wrap--short')[0]
      let headerStyle = document.querySelectorAll('.table-header-wrapper--short')[0]
      if (filterStyle) {
        filterStyle.style.transition = `0.55s cubic-bezier(0.645, 0.045, 0.355, 1)`
        if (headerStyle) {
          headerStyle.style.transition = `0.55s cubic-bezier(0.645, 0.045, 0.355, 1)`
          setTimeout(() => {
            headerStyle.style.transition = 'null'
          }, 550)
        }
        setTimeout(() => {
          filterStyle.style.transition = 'null'
        }, 550)
      }
      this.openmenu = !this.openmenu
      this.$root.$emit('openaside', this.openmenu)
    },
    close() {
      this.dropdown = false
    }
  }
}
</script>
<style lang="scss">
@import './Header.scss';
</style>
