<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Новый уровень доступа"
    modalClass="popup--medium-permission"
  >
    <form class="form_new-permission" @submit.prevent="createPermissionHandler">
      <div class="filters__group">
        <div class="filters__item">
          <div class="select">
            <span class="select__title">Группа:</span>
            <div class="select__body">
              <button type="button" class="select__field" @click="clickClose('permissions')">
                <span class="select__field-value">{{ defaultFilter({ key: 'permissions' }) }}</span>
                <i class="select__field-icon fa fa-angle-down"></i>
              </button>
              <ul class="select__list" v-if="permissionsSelectOpen" @mouseleave="mouseLeave">
                <li
                  class="select__item"
                  v-for="perm in permissions_group"
                  :key="perm.id"
                  @click="
                    selectFilterBuilder({
                      key: 'permissions',
                      value: perm
                    })
                  "
                >
                  <span class="select__text"> {{ perm.name }} </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Название доступа</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="createPermission"
          ref="createpermission"
          required
        />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Уровень доступа</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="createLevel"
          ref="createlevel"
          required
        />
      </div>
      <!-- <div class="popup__item">
        <span class="popup__item-title">Значение доступа</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="createValue"
          ref="createvalue"
          placeholder="permission value"
          required
        />
      </div> -->
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">
            Добавить
          </button>
        </div>
      </div>
    </form>
  </Popup>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_CREATE_PERMISSION } from '@/store/action-types'
import { GET_FILTERS_PERMISSION_DEFAULT, GET_TMP_PERMISSION_FILERS } from '@/store/getter-types'
import { SET_TMP_FILTERS_PERMISSION, SET_RESET_PERMISSION_FILTERS } from '@/store/mutation-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import translitiration from '@/components/mixins/translitiration'
import { NEW_PERMISSION_POPUP_EVENT } from '@/constants/event'
export default {
  name: 'NewDepartmentPopup',
  components: {
    Popup
  },

  mixins: [ModalMixin, translitiration],
  data: () => ({
    permissionData: {
      name: '',
      level: '',
      value: ''
    },
    permissionsSelectOpen: false
  }),
  props: {
    permissions_group: {
      type: Array
    }
  },

  computed: {
    ...mapGetters({
      defaultFilters: GET_FILTERS_PERMISSION_DEFAULT,
      tmpFilter: GET_TMP_PERMISSION_FILERS
    }),
    createPermission: {
      get() {
        return this.permissionData.name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.createpermission.value = name
        this.permissionData.name = name
      }
    },

    createLevel: {
      get() {
        return this.permissionData.level
      },
      set(level) {
        level = level.replace(/\D/g, '')
        this.$refs.createlevel.value = level
        this.permissionData.level = level
      }
    },

    createValue: {
      get() {
        return this.permissionData.value
      },
      set(value) {
        value = value.replace(/\d/g, '')
        this.$refs.createvalue.value = value
        this.permissionData.value = value
      }
    }
  },

  methods: {
    ...mapActions({
      create: REQUEST_CREATE_PERMISSION
    }),
    ...mapMutations({
      resetFilters: SET_RESET_PERMISSION_FILTERS,
      addTmpFilters: SET_TMP_FILTERS_PERMISSION
    }),
    mouseLeave() {
      this.closeList()
    },
    closeList() {
      this.permissionsSelectOpen = false
    },

    clickClose(options) {
      let newOptions = this[`${options}SelectOpen`]
      this.closeList()
      this[`${options}SelectOpen`] = !newOptions
    },
    defaultFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : this.defaultFilters[key].name
    },

    defaultNameFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : ''
    },
    defaultSortFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current ? current : ''
    },

    defaultValueFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current !== undefined ? current : ''
    },

    selectFilterBuilder(filterItem) {
      const { key } = filterItem
      switch (key) {
        case 'permissions':
          this.addTmpFilters({ ...filterItem })
          break
        default:
          break
      }
      this[`${key}SelectOpen`] = false
    },

    closePopup() {
      this.permissionData.name = ''
      this.permissionData.level = ''
      this.permissionData.value = ''
      this.resetFilters()
      this.close()
    },
    createPermissionHandler() {
      const permission = {
        permission_name: this.permissionData.name,
        permission_level: this.permissionData.level,
        permission: this.translite(this.permissionData.name).replace('|_', ''),
        permission_group: this.defaultValueFilter({ key: 'permissions' })
      }
      this.create(permission)
      this.closePopup()
    }
  },
  mounted() {
    this.$root.$on(NEW_PERMISSION_POPUP_EVENT, () => {
      this.open()
    })
  }
}
</script>

<style lang="scss" scoped>
.form_new-permission {
  margin: 6% auto;
}
.filters__item {
  margin: 25px 0;
}
.select__field,
.select__list {
  width: 150px;
}
</style>
