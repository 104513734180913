import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import UserClaimsService from '@/services/UserClaimsService'

export default {
  [ActionTypes.REQUEST_ALL_USERS_CLAIMS]: async ({ rootGetters, commit }, lang = { lang: 'RU' }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserClaimsService, [userToken]).getAllUsersClaims(lang)
    const { data, status } = response
    if (status) {
      commit(MutationTypes.SET_ALL_USERS_CLAIMS, data)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUSET_ADD_NEW_CLAIM]: async ({ rootGetters, commit, dispatch }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserClaimsService, [userToken]).addUserClaim(formData)
    const { status } = response
    if (status) {
      dispatch(ActionTypes.REQUEST_ALL_USERS_CLAIMS, { lang: formData.lang }).then(response => {
        const { data } = response
        commit(MutationTypes.SET_ALL_USERS_CLAIMS, data)
      })
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUSET_DEL_CLAIM]: async ({ rootGetters, commit, dispatch }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserClaimsService, [userToken]).delUserClaim(formData.params.id)
    const { status } = response
    if (status) {
      dispatch(ActionTypes.REQUEST_ALL_USERS_CLAIMS, { lang: formData.lang }).then(response => {
        const { data } = response
        commit(MutationTypes.SET_ALL_USERS_CLAIMS, data)
      })
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_EDIT_CLAIM]: async ({ rootGetters, commit, dispatch }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserClaimsService, [userToken]).editUserClaim(formData)
    const { status } = response
    if (status) {
      dispatch(ActionTypes.REQUEST_ALL_USERS_CLAIMS, { lang: formData.lang }).then(response => {
        const { data } = response
        commit(MutationTypes.SET_ALL_USERS_CLAIMS, data)
      })
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  }
}
