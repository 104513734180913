<template>
  <div class="table-header-wrapper-user">
    <ul class="table-header table-user-header">
      <li class="table-header__item-user">
        <span class="table-header__item-text table-header__item-info">Информация о пользователе</span>
        <span class="table-header__item-text table-header__item-info">Пол</span>
        <span class="table-header__item-text table-header__item-info">Статус</span>
      </li>
      <li class="table-header__item-user">
        <span class="table-header__item-text">Город </span>
      </li>
      <li class="table-header__item-user">
        <span class="table-header__item-text">Баллы / Покупки</span>
      </li>
      <li class="table-header__item-user">
        <span class="table-header__item-text">Депозиты / Выводы</span>
      </li>
      <li class="table-header__item-user">
        <span class="table-header__item-text">Сообщения / Чаты</span>
      </li>
      <li class="table-header__item-user">
        <span class="table-header__item-text">Жалобы / Предупреждения</span>
      </li>
      <li class="table-header__item-user">
        <span class="table-header__item-text">Подарки / События</span>
      </li>
      <li class="table-header__item-user">
        <span class="table-header__item-text">Конфидециальность / Автознакомства / Уведомления</span>
      </li>
      <li class="table-header__item-user">
        <span class="table-header__item-text">Регистрация / Вход</span>
      </li>
      <li class="table-header__item-user">
        <span class="table-header__item-text">UTM</span>
      </li>
      <li class="table-header__item-user">
        <span class="table-header__item-text">Устройство</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'AppUsersTableHeader'
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/tableHeader.scss';

.table-header {
  display: grid;
  grid-template-columns: 0.55fr 0.18fr 0.2fr 0.2fr 0.17fr 0.3fr 0.1fr 0.35fr 0.2fr 0.22fr 0.25fr;
  width: 100%;
  top: 170px;
  background-color: #fff;
  border-radius: 4px;
  align-items: center;
}

.table-header-wrapper-user {
  display: flex;
  align-items: center;

  //padding: 5px 16px;
  width: calc(100% - 38px);
  margin: 90px 16px 0 16px;
  padding-bottom: 3px;
  background-color: #f1f2f7;
  transition: 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  z-index: 2;
  @media (max-width: 3904px) {
    margin-top: 154px;
  }
  @media (max-width: 3494px) {
    margin-top: 159px;
  }
  @media (max-width: 2177px) {
    margin-top: 214px;
  }
  @media (max-width: 1618px) {
    margin-top: 278px;
  }
  @media (max-width: 1341px) {
    margin-top: 346px;
  }
  @media (max-width: 1215px) {
    margin-top: 282px;
  }
  @media (max-width: 1131px) {
    margin-top: 345px;
  }
  @media (max-width: 1020px) {
    display: none;
  }
}
// .table-user-header {
//   justify-content: space-between;
// }
.table-header__item-user {
  display: flex;
  text-align: center;
  padding: 7px 5px 9px 5px;
  &:nth-child(1) {
    padding: 7px 5px 9px 8px;
    display: grid;
    grid-template-columns: 1.2fr 0.3fr 0.4fr;
    align-items: center;
  }
  // &:nth-child(2) {
  //   left: 10px;
  //   width: 3%;
  // }
  // &:nth-child(3) {
  //   width: 8%;
  // }
  // &:nth-child(4) {
  //   width: 8%;
  // }
  // &:nth-child(5) {
  //   width: 8%;
  // }
  // &:nth-child(6) {
  //   width: 8%;
  // }
  // &:nth-child(7) {
  //   width: 8%;
  //   text-align: center;
  // }
  // &:nth-child(8) {
  //   width: 16%;
  //   text-align: center;
  // }
  // &:nth-child(9) {
  //   width: 8%;
  //   text-align: center;
  // }
  // &:nth-child(10) {
  //   width: 4%;
  //   text-align: right;
  // }
  // &:nth-child(11) {
  //   width: 8%;
  //   text-align: center;
  // }
  // &:nth-child(12) {
  //   width: 6%;
  //   text-align: center;
  // }
  //&:nth-child(7) {
  //  width: 6%;
  //}
}
.table-header__item-info {
  &:nth-child(1) {
    min-width: 30%;
    width: 54%;
  }
  &:nth-child(2) {
    width: 26%;
  }
  &:nth-child(3) {
    width: 10%;
  }
}
</style>
<!--main.sidebar-hidden .filters-user-wrap-->
<!--width: calc(100% - 38px);-->
