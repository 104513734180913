import Request from '../helpers/Request'
import BaseService from './BaseService'

class OperatorsService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getOperators(params) {
    const operatorsUrl = `/api/v1/management/operators/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(operatorsUrl, { headers })
  }
  async getEmployeesFilter(formData) {
    const operatorsUrl = `/api/v1/management/operators/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
      // 'Cache-Control': 'no-cache'
    }

    Object.keys(formData).forEach(
      key =>
        (formData[key] === '' || formData[key] === undefined || formData[key] === 'Неважно') &&
        delete formData[key]
    )

    return await Request.get(operatorsUrl, { headers, params: formData })
  }

  async deleteOperator(id) {
    const operatorDestroyUrl = `/api/v1/management/operators/${id}/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(operatorDestroyUrl, { headers })
  }

  async deleteUserOperator(payload) {
    const operatorUserDestroyUrl = `/api/v1/management/operators/${payload.id}/users/delete/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(operatorUserDestroyUrl, payload.data, { headers })
  }

  async editOperatorSearchOptions(payload) {
    const editOperatorSearchOptionsUrl = `api/v1/management/operators/${payload.operator}/users/change-search/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(editOperatorSearchOptionsUrl, payload.data, { headers })
  }

  // async deleteUserOperator(id) {
  //   const operatorUserDestroyUrl = `/api/v1/management/operators/${id}/user/`;
  //   const headers = {
  //     Authorization: `Bearer ${this.userToken}`
  //   };
  //   return await Request.delete(operatorUserDestroyUrl, { headers });
  // }

  async editOperator(data) {
    const operatorEditUrl = `/api/v1/management/operators/${data.id}/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Content-Type': 'multipart/form-data'
    }
    return await Request.put(operatorEditUrl, data.form, { headers })
  }

  async addOperator(operator) {
    const operatorAddUrl = '/api/v1/management/operators/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Content-Type': 'multipart/form-data'
    }
    return await Request.post(operatorAddUrl, operator, { headers })
  }

  async getFilterOperatorUsers(formData) {
    const getEventsUrl = `/api/v1/users/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    Object.keys(formData).forEach(
      key =>
        (formData[key] === '' || formData[key] === undefined || formData[key] === 'Неважно') &&
        delete formData[key]
    )
    return await Request.get(getEventsUrl, {
      headers,
      params: formData
    })
  }

  async getOperatorStatistic(id) {
    const operatorStatisticUrl = `/api/v1/stats/operator_stat/full/?operator_id=${id}`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(operatorStatisticUrl, { headers })
  }

  async getOperatorsPayout(formData) {
    const operatorsUrl = `/api/v1/management/operator/payout/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(operatorsUrl, { headers, params: formData })
  }

  async billingOperator(value) {
    const operatorBillingUrl = '/api/v1/management/operator/payout/billing/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    if (!value) {
      value = {}
    }

    return await Request.post(operatorBillingUrl, value, { headers })
  }

  async prizeOperator(value) {
    const operatorPrizeUrl = '/api/v1/management/operator/payout/prize/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(operatorPrizeUrl, value, { headers })
  }

  async executeOperator(value) {
    const executeOperatorUrl = '/api/v1/management/operator/payout/execute/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(executeOperatorUrl, value, { headers })
  }

  async changeName(data) {
    const changeNameUrl = '/api/v1/user/change-name/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(changeNameUrl, data, { headers })
  }

  async changeAge(data) {
    const changeAgeUrl = '/api/v1/user/change-age/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(changeAgeUrl, data, { headers })
  }

  async changeSearch(data) {
    const changeAgeUrl = '/api/v1/user/change-search/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(changeAgeUrl, data, { headers })
  }

  async changeUserCityCountryCode(data) {
    const changeUserCityCountryCodeUrl = `/api/v1/management/operators/users/change-city/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(changeUserCityCountryCodeUrl, data, { headers })
  }

  async editOperatorAutoMessage(payload) {
    const editOperatorAutoMessageUrl = `api/v1/management/operators/${payload.operator}/users/change-auto-message/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(editOperatorAutoMessageUrl, payload.data, { headers })
  }

  async getOperatorsBanner() {
    const operatorsBannerUrl = `/api/v1/localization/operator-banner/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(operatorsBannerUrl, { headers })
  }

  async editOperatorsBanner(payload) {
    const editOperatorsBannerUrl = `/api/v1/localization/operator-banner/add/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(editOperatorsBannerUrl, payload, { headers })
  }

  async deleteOperatorBanner(id) {
    const deleteOperatorBannerUrl = `/api/v1/localization/operator-banner/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteOperatorBannerUrl, { headers })
  }

  async changeOperatorsBanner(payload) {
    const changeOperatorsBannerUrl = `/api/v1/localization/operator-banner/${payload.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(changeOperatorsBannerUrl, payload.payload, { headers })
  }

  async getOperatorsAutoMessage() {
    const OperatorsAutoMessageUrl = `/api/v1/management/user-auto-message-delay/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(OperatorsAutoMessageUrl, { headers })
  }

  async editOperatorsAutoMessage(payload) {
    const editOperatorsAutoMessageUrl = `/api/v1/management/user-auto-message-delay/add/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(editOperatorsAutoMessageUrl, payload, { headers })
  }

  async changeOperatorsAutoMessage(payload) {
    const changeOperatorsAutoMessageUrl = `/api/v1/management/user-auto-message-delay/${payload.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(changeOperatorsAutoMessageUrl, payload.payload, { headers })
  }

  async deleteOperatorsAutoMessage(id) {
    const deleteOperatorsAutoMessageUrl = `/api/v1/management/user-auto-message-delay/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteOperatorsAutoMessageUrl, { headers })
  }

  async editLocationOperator(paylaod) {
    const editLocationOperatorUrl = `/api/v1/management/operators/users/change-geo/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(editLocationOperatorUrl, paylaod, { headers })
  }

  async transferOperator(payload) {
    const transferOperatorUrl = `/api/v1/management/operators/users/move/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(transferOperatorUrl, payload, { headers })
  }

  async changeWeightSending({ id, data }) {
    const changeWeightSendingUrl = `/api/v1/management/operators/${id}/users/change-weight-sending/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(changeWeightSendingUrl, data, { headers })
  }

  async changeTypeMessages({ id, data }) {
    const changeTypeMessagesUrl = `/api/v1/management/operators/${id}/users/change-auto-message-use/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(changeTypeMessagesUrl, data, { headers })
  }

  async getOperatorInfoOnShift(payload) {
    const getOperatorInfoOnShiftUrl = `/api/v1/management/operator/info-by-shift-date/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(getOperatorInfoOnShiftUrl, payload, { headers })
  }
}

export default OperatorsService
