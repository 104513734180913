var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Popup',{attrs:{"visible":_vm.visible,"description":"Добавление новой записи","modalClass":"popup--medium"},on:{"close":_vm.closeModalHandler}},[_c('form',{staticClass:"popup__form",on:{"submit":function($event){$event.preventDefault();return _vm.sendNewRecord($event)}}},[(_vm.recordErrorText)?_c('div',{staticClass:"popup__item"},[_c('div',{staticClass:"view-record__error"},[_c('span',[_vm._v("Ошибка: "+_vm._s(_vm.recordErrorText))])])]):_vm._e(),_c('div',{staticClass:"popup__item"},[_c('div',{staticClass:"view-record__group"},[_c('div',{staticClass:"form__item"},[_c('span',{staticClass:"form__item-title"},[_vm._v("Язык:")]),_c('div',{staticClass:"form__select"},[_c('button',{staticClass:"form__value",attrs:{"type":"button"},on:{"click":function($event){return _vm.clickClose('openlang')}}},[_c('span',{staticClass:"form__value-text"},[_vm._v(_vm._s(_vm.setDefaultFilter({ key: 'lang' })))]),_c('i',{staticClass:"fa fa-angle-down form__select-icon"})]),(_vm.selects.openlangSelect)?_c('ul',{staticClass:"form__select-list",on:{"mouseleave":function($event){return _vm.mouseLeave('openlang')}}},_vm._l((_vm.langs),function(lang){return _c('li',{key:lang.id,staticClass:"form__select-item",on:{"click":function($event){return _vm.selectSettingFilter({
                    key: 'lang',
                    value: lang
                  })}}},[_c('span',{staticClass:"form__select-text"},[_vm._v(_vm._s(lang.name))])])}),0):_vm._e()])]),_c('div',{staticClass:"form__item"},[_c('span',{staticClass:"form__item-title"},[_vm._v("Страна:")]),_c('div',{staticClass:"form__select"},[_c('button',{staticClass:"form__value",attrs:{"type":"button"},on:{"click":function($event){return _vm.clickClose('opencountry')}}},[_c('span',{staticClass:"form__value-text"},[_vm._v(_vm._s(_vm.setDefaultFilter({ key: 'country' })))]),_c('i',{staticClass:"fa fa-angle-down form__select-icon"})]),(_vm.selects.opencountrySelect)?_c('ul',{staticClass:"form__select-list form__select-list--country",on:{"mouseleave":function($event){return _vm.mouseLeave('opencountry')}}},_vm._l((_vm.countries),function(country){return _c('li',{key:country.id,staticClass:"form__select-item",on:{"click":function($event){return _vm.selectSettingFilter({
                    key: 'country',
                    value: country
                  })}}},[_c('span',{staticClass:"form__select-text"},[_vm._v(_vm._s(country.name))])])}),0):_vm._e()])]),_c('div',{staticClass:"form__item"},[_c('span',{staticClass:"form__item-title"},[_vm._v("Пол:")]),_c('div',{staticClass:"form__select"},[_c('button',{staticClass:"form__value",attrs:{"type":"button"},on:{"click":function($event){return _vm.clickClose('opengender')}}},[_c('span',{staticClass:"form__value-text"},[_vm._v(_vm._s(_vm.setDefaultFilter({ key: 'gender' })))]),_c('i',{staticClass:"fa fa-angle-down form__select-icon"})]),(_vm.selects.opengenderSelect)?_c('ul',{staticClass:"form__select-list",on:{"mouseleave":function($event){return _vm.mouseLeave('opengender')}}},_vm._l((_vm.genders),function(gender){return _c('li',{key:gender.id,staticClass:"form__select-item",on:{"click":function($event){return _vm.selectSettingFilter({
                    key: 'gender',
                    value: gender
                  })}}},[_c('span',{staticClass:"form__select-text"},[_vm._v(_vm._s(gender.name))])])}),0):_vm._e()])]),_c('div',{staticClass:"form__item"},[_c('span',{staticClass:"form__item-title"},[_vm._v("Тип страницы:")]),_c('div',{staticClass:"form__select"},[_c('button',{staticClass:"form__value",attrs:{"type":"button"},on:{"click":function($event){return _vm.clickClose('openpage')}}},[_c('span',{staticClass:"form__value-text"},[_vm._v(_vm._s(_vm.setDefaultFilter({ key: 'page' })))]),_c('i',{staticClass:"fa fa-angle-down form__select-icon"})]),(_vm.selects.openpageSelect)?_c('ul',{staticClass:"form__select-list",on:{"mouseleave":function($event){return _vm.mouseLeave('openpage')}}},_vm._l((_vm.pages),function(page){return _c('li',{key:page.id,staticClass:"form__select-item",on:{"click":function($event){return _vm.selectSettingFilter({
                    key: 'page',
                    value: page
                  })}}},[_c('span',{staticClass:"form__select-text"},[_vm._v(_vm._s(page.name))])])}),0):_vm._e()])]),_c('label',{staticClass:"view-record__new-text"},[_vm._v("Возраст от: "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.age_min),expression:"age_min"},{name:"tooltip",rawName:"v-tooltip",value:('Возраст должен быть больше минимального возраста и быть в диапозоне от 18 до 99'),expression:"'Возраст должен быть больше минимального возраста и быть в диапозоне от 18 до 99'"}],staticClass:"view-record__new-input",attrs:{"type":"number","min":"18","max":"99","required":""},domProps:{"value":(_vm.age_min)},on:{"input":function($event){if($event.target.composing){ return; }_vm.age_min=$event.target.value}}})]),_c('label',{staticClass:"view-record__new-text"},[_vm._v("Возраст до: "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.age_max),expression:"age_max"},{name:"tooltip",rawName:"v-tooltip",value:('Возраст должен быть больше минимального возраста и быть в диапозоне от 18 до 99'),expression:"'Возраст должен быть больше минимального возраста и быть в диапозоне от 18 до 99'"}],staticClass:"view-record__new-input",attrs:{"type":"number","min":"18","max":"99","required":""},domProps:{"value":(_vm.age_max)},on:{"input":function($event){if($event.target.composing){ return; }_vm.age_max=$event.target.value}}})])])]),_c('div',{staticClass:"popup__item"},[_c('div',{staticClass:"popup__btns popup__btns--center"},[_c('button',{staticClass:"popup__btn btn-green",attrs:{"type":"submit"}},[_vm._v("Сохранить")]),_c('button',{staticClass:"popup__btn btn-red",attrs:{"type":"button"},on:{"click":_vm.closeModalHandler}},[_vm._v(" Отмена ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }