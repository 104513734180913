<template>
  <div class="event-info" v-if="!errorMessage && isLoaded">
    <EventHeader :currentEvent="currentEvent" />
    <div class="event-info-body">
      <div class="event-info-body__col">
        <div class="event-user">
          <EventUser :currentEvent="currentEvent" />
        </div>
        <div class="event-spec">
          <EventSpec :currentEvent="currentEvent" />
        </div>
      </div>
      <div class="event-info-body__col">
        <EventDescription :currentEvent="currentEvent" />

        <div class="app-user-map">
          <EventMap :event="currentEvent.event_location.point.coordinates" />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    Ошибка при загрузке события
  </div>
</template>

<script>
import toLocaleformat from '@/components/mixins/toLocaleformat'
import getImgCdnLink from '@/components/mixins/getImgCdnLink'
import EventHeader from '@/components/Events/EventInfo/EventHeader'
import EventUser from '@/components/Events/EventInfo/EventUser'
import EventSpec from '@/components/Events/EventInfo/EventSpec'
import EventDescription from '@/components/Events/EventInfo/EventDescription'
import EventMap from '@/components/Events/EventInfo/EventMap'
import { mapGetters } from 'vuex'
import { GET_CATEGORIES_BY_LANG, GET_EVENT } from '@/store/getter-types'
import { SET_EVENT } from '@/store/mutation-types'
import { REQUEST_EVENT_BY_ID } from '@/store/action-types'
import store from '@/store'
export default {
  name: 'EventInfo',
  components: {
    EventHeader,
    EventUser,
    EventSpec,
    EventDescription,
    EventMap
  },
  data() {
    return {
      isLoaded: false,
      errorMessage: false
    }
  },
  mixins: [toLocaleformat, getImgCdnLink],

  beforeRouteEnter(to, from, next) {
    if (to.params.event) {
      store.commit(SET_EVENT, to.params.event)
      next(vm => {
        vm.isLoaded = true
      })
    } else {
      store.dispatch(REQUEST_EVENT_BY_ID, to.params.eventId).then(response => {
        next(vm => {
          if (!response.status) {
            vm.errorMessage = true
          } else {
            vm.isLoaded = true
          }
        })
      })
    }
  },
  computed: {
    ...mapGetters({
      getAllCategories: GET_CATEGORIES_BY_LANG,
      currentEvent: GET_EVENT
    })
  },

  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
.app-user-map img {
  width: 100%;
  height: auto;
}
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/user-block';
@import '@/assets/scss/event-info';
.spec-payment {
  width: 50%;
}
.event-spec__spec-payment {
  display: flex;
  margin-top: 10px;
}
.event-spec-status-red {
  color: $red-400;
}
.event-spec-status-green {
  color: $green-400;
}
.event-desc__head {
  flex-direction: column;
}
.event-desc__info {
  margin-top: 5px;
}
</style>
