<template>
  <aside class="sidebar" :class="{ hide: !openaside }">
    <MenuList v-if="isLoading" :list="permissionMenu" :lvl="0" />
  </aside>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_PERMISSION_USER } from '@/store/action-types'
import { GET_PERMISSIONS_USER, GET_TOKEN } from '@/store/getter-types'
import Permissions from '@/constants/permissions.js'

import MenuList from './MenuList'

export default {
  name: 'Asidebar',
  components: {
    MenuList
  },
  props: {
    openaside: {
      type: Boolean
    }
  },
  data: () => ({
    isLoading: false,
    menu: {
      listclass: 'sidebar__list',
      items: [
        {
          itemClass: 'users',
          itemIcon: 'fas fa-user',
          itemText: 'Пользователи',
          itemLink: '/users',
          id: 0
        },
        {
          itemClass: 'firstscreen',
          itemIcon: 'fas fa-mobile-alt',
          itemText: 'Управление приложением',
          itemLink: '/first-screen'
        },
        {
          itemClass: 'statistics',
          itemIcon: 'far fa-chart-bar',
          itemText: 'Статистика',
          id: 1,
          notLink: true,
          isOpen: true,
          list: {
            items: [
              {
                itemLink: `/statistics-users`,
                itemIcon: 'fas fa-users',
                itemText: 'Пользователи',
                notLink: false,
                id: 41
              },
              {
                itemLink: `/statistics-payments-user`,
                itemIcon: 'fas fa-chart-line',
                itemText: 'Платежи',
                notLink: false,
                id: 42
              },
              {
                itemLink: `/statistics-total-purchase`,
                itemIcon: 'fas fa-credit-card',
                itemText: 'Покупки',
                notLink: false,
                id: 43
              },
              {
                itemLink: `/statistics-traffic`,
                itemIcon: 'fas fa-chart-pie',
                itemText: 'Трафик',
                notLink: false,
                id: 44
              },
              {
                itemLink: `/statistics-moderation`,
                itemIcon: 'fas fa-user-check',
                itemText: 'Модерация',
                notLink: false,
                id: 45
              },
              {
                itemLink: `/statistics-operators`,
                itemIcon: 'fa fa-user-plus',
                itemText: 'Операторы общая',
                notLink: false,
                id: 46
              },
              {
                itemLink: `/statistics-advanced-operators`,
                itemIcon: 'fas fa-book',
                itemText: 'Операторы расширенная',
                notLink: false,
                id: 48
              }
            ]
          }
        },
        // {
        //   itemClass: 'payments',
        //   itemIcon: 'far fa-money-bill-alt',
        //   itemText: 'Платежи',
        //   itemLink: '/payments',
        //   id: 2
        // },
        {
          itemClass: 'support',
          itemIcon: 'fas fa-headset',
          itemText: 'Поддержка',
          itemBadge: 'support',
          notLink: true,
          isOpen: true,
          id: 3,
          list: {
            items: [
              {
                itemLinkURL: `${process.env.VUE_APP_DEPLOY_URL}/support/`,
                itemIcon: 'fas fa-users',
                itemText: 'Панель',
                permissions: [
                  Permissions.SupportSources,
                  Permissions.SupportTickets,
                  Permissions.SupportClaim,
                  Permissions.SupportFeedbackAndroid,
                  Permissions.SupportFeedbackIOS,
                  Permissions.SupportManagement
                  // 62,
                  // 64,
                  // 65
                ],
                isOpen: true,
                id: 9
              }
              // {
              //   itemLinkURL: `${process.env.VUE_APP_DEPLOY_URL}/support/`,
              //   itemIcon: 'fas fa-chart-line',
              //   itemText: 'Аналитика',
              //   permissions: [62, 64, 65],
              //   isOpen: true,
              //   id: 10
              // }
            ]
          }
        },
        {
          itemClass: 'moderators',
          itemIcon: 'fas fa-images',
          itemText: 'Модерация',
          notLink: true,
          isOpen: true,
          id: 4,
          list: {
            items: [
              {
                itemLinkURL: `${process.env.VUE_APP_DEPLOY_URL}/moderator/`,
                itemIcon: 'fas fa-users',
                itemText: 'Панель',
                permissions: [
                  Permissions.ModerationForWork,
                  Permissions.ModerationAnalytics,
                  Permissions.ModerationManagement,
                  Permissions.ModerationEvent,
                  Permissions.ModerationPhoto,
                  Permissions.ModerationVideo,
                  Permissions.ModerationVerification,
                  Permissions.ModerationCheckControl,
                  Permissions.ModerationActivityOfModerators
                  // 55
                ],
                isOpen: true,
                id: 11
              },
              // {
              //   itemLinkURL: `${process.env.VUE_APP_DEPLOY_URL}/moderator/`,
              //   itemIcon: 'fas fa-chart-line',
              //   itemText: 'Аналитика',
              //   permissions: [55],
              //   isOpen: true,
              //   id: 12
              // },
              {
                itemIcon: 'fa fa-money-bill-wave-alt',
                itemText: 'Выплаты модераторам',
                itemLink: '/moderator-payments',
                notLink: false,
                id: 37
              },
              {
                itemIcon: 'fas fa-star-half-alt',
                itemText: 'Рейтинг модераторов',
                itemLink: '/moderator-rating',
                notLink: false,
                id: 40
              }
            ]
          }
        },
        {
          itemClass: 'interface',
          itemIcon: 'fas fa-sitemap',
          itemText: 'Справочники',
          notLink: true,
          isOpen: true,
          id: 5,
          list: {
            items: [
              {
                itemLink: '/interface-achievement',
                itemIcon: 'fas fa-medal',
                itemText: 'Достижения',
                notLink: false,
                id: 13
              },
              {
                itemLink: '/interface-gift',
                itemIcon: 'fas fa-gift',
                itemText: 'Подарки',
                notLink: false,
                id: 14
              },
              {
                itemLink: '/interface-notice',
                itemIcon: 'far fa-comment-dots',
                itemText: 'Уведомления',
                notLink: false,
                id: 15
              },
              {
                itemLink: '/interface-user-claims',
                itemIcon: 'fas fa-hand-point-up',
                itemText: 'Предупреждения',
                notLink: false,
                id: 47
              },
              {
                itemLink: '/interface-faq',
                itemIcon: 'far fa-question-circle',
                itemText: 'F.A.Q.',
                notLink: false,
                id: 16
              },
              {
                itemLink: '/interface-cause-delete',
                itemIcon: 'far fa-trash-alt',
                itemText: 'Причины удаления',
                notLink: false,
                id: 17
              },
              {
                itemLink: '/interface-cause-block',
                itemIcon: 'fas fa-ban',
                itemText: 'Причина блокировки',
                notLink: false,
                id: 18
              },
              {
                itemLink: '/interface-cause-points',
                itemIcon: 'fas fa-coins',
                itemText: 'Баллы',
                notLink: false,
                id: 19
              },
              {
                itemLink: '/interface-support-contact',
                itemIcon: 'fas fa-handshake',
                itemText: 'Типы обращений',
                notLink: false,
                id: 30
              },
              {
                itemLink: '/auto-messages',
                itemIcon: 'fa fa-comments',
                itemText: 'Автосообщения',
                notLink: false,
                id: 31
              },
              {
                itemLink: '/purchases',
                itemIcon: 'fas fa-shopping-cart',
                itemText: 'Цифровые покупки',
                notLink: false,
                id: 38
              },
              {
                itemLink: '/languages',
                itemIcon: 'fa fa-language',
                itemText: 'Языки',
                notLink: false,
                id: 39
              }
            ]
          }
        },
        {
          itemClass: 'management',
          itemIcon: 'fa fa-wrench',
          itemText: 'Управление',
          itemBadge: 'management',
          notLink: true,
          isOpen: true,
          id: 6,
          list: {
            items: [
              {
                itemLink: '/management-rights',
                itemIcon: 'fas fa-list',
                itemText: 'Список прав доступа',
                notLink: false,
                id: 89
              },
              {
                itemLink: '/management-employee',
                itemIcon: 'fas fa-users',
                itemText: 'Сотрудники',
                notLink: false,
                id: 20
              },
              {
                itemLink: '/management-departments',
                itemIcon: 'fas fa-chart-line',
                itemText: 'Департаменты',
                notLink: false,
                id: 21
              },
              {
                itemLink: '/management-position',
                itemIcon: 'fas fa-users',
                itemText: 'Должности',
                notLink: false,
                id: 23
              }
            ]
          }
        },
        {
          itemClass: 'events',
          itemIcon: 'fas fa-bolt',
          itemText: 'События',
          itemBadge: 'events',
          notLink: true,
          isOpen: true,
          id: 7,
          list: {
            items: [
              {
                itemLink: '/events-category',
                itemIcon: 'fas fa-calendar',
                itemText: 'Категории',
                notLink: false,
                id: 24
              },
              {
                itemLink: '/events-list',
                itemIcon: 'fas fa-list',
                itemText: 'Список событий',
                notLink: false,
                id: 25
              },
              {
                itemLink: '/events-presents',
                itemIcon: 'fas fa-gift',
                itemText: 'Подарки событий',
                notLink: false,
                id: 26
              },
              {
                itemLink: '/events-my',
                itemIcon: 'fas fa-user-edit',
                itemText: 'Свое событие',
                notLink: false,
                id: 27
              }
            ]
          }
        },
        {
          itemClass: 'market',
          itemIcon: 'fas fa-trademark',
          itemText: 'Маркетинг',
          itemBadge: 'market',
          notLink: true,
          isOpen: true,
          id: 8,
          list: {
            items: [
              {
                itemLink: '/market-utm',
                itemIcon: 'fas fa-ad',
                itemText: 'UTM метки',
                notLink: false,
                id: 28
              },
              {
                itemLink: '/market-media',
                itemIcon: 'fas fa-share-square',
                itemText: 'Соц. медиа',
                notLink: false,
                id: 29
              }
            ]
          }
        },
        {
          itemClass: 'operators',
          itemIcon: 'fas fa-robot',
          itemText: 'Автосообщения',
          itemBadge: 'operators',
          itemLink: '/auto-message-list',
          id: 49
        },
        {
          itemClass: 'operators',
          itemIcon: 'fa fa-users',
          itemText: 'Операторы',
          itemBadge: 'operators',
          notLink: true,
          isOpen: true,
          id: 32,
          list: {
            items: [
              {
                itemIcon: 'fa fa-user-plus',
                itemText: 'Список операторов',
                itemLink: '/operators',
                notLink: false,
                id: 33
              },
              {
                itemIcon: 'fa fa-calendar',
                itemText: 'Календарь',
                itemLink: '/operators-calendar',
                notLink: false,
                id: 34
              },
              {
                itemIcon: 'fa fa-money-bill-wave-alt',
                itemText: 'Выплаты операторам',
                itemLink: '/operators-payments',
                notLink: false,
                id: 35
              },
              {
                itemIcon: 'fa fa-cogs',
                itemText: 'Настройки операторов',
                itemLink: '/operators-settings',
                notLink: false,
                id: 36
              },
              {
                itemIcon: 'fa fa-address-card',
                itemText: 'Баннеры',
                itemLink: '/operators-banners',
                notLink: false,
                id: 37
              },
              {
                itemIcon: 'fa fa-comments',
                itemText: 'Авто сообщения',
                itemLink: '/operators-auto-messages',
                notLink: false,
                id: 38
              }
            ]
          }
        }
      ]
    }
  }),
  watch: {
    permission_lvl: function() {
      this.isLoading = true
    }
  },
  created() {
    if (this.tokken && this.permission_lvl.length) {
      this.isLoading = true
    }
  },
  computed: {
    ...mapGetters({
      tokken: GET_TOKEN,
      permission_lvl: GET_PERMISSIONS_USER
    }),

    permissionMenu() {
      const copy = this.menu.items.map(i => i)
      const verifyItem = item => {
        if (item.notLink) return true

        const route = this.$router.resolve({ path: item.itemLink }).route

        const permission = item.itemLink ? (route.meta || {}).permission_lvl : item.permissions

        if (!permission) return true

        const permissionAccess = permission.some(permissionItem =>
          this.permission_lvl.includes(permissionItem)
        )

        if ((permissionAccess && route.name === `Mainpage`) || (permissionAccess && route.name === `Users`)) {
          if (
            this.permission_lvl.length === 2 &&
            this.permission_lvl.includes(30) &&
            this.permission_lvl.includes(45)
          ) {
            return false
          }
        }

        if (permissionAccess) return true

        return false
      }
      return copy.filter(item => {
        const childrenList = item.list

        if (!childrenList) return verifyItem(item)

        childrenList.items = childrenList.items.filter(childItem => verifyItem(childItem))
        if (childrenList.items.length > 0) return true
      })
    }
  },

  methods: {
    ...mapActions({
      requestPermission: REQUEST_PERMISSION_USER
    })
  }
}
</script>

<style lang="scss">
@import './Asidebar.scss';
</style>
