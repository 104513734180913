import Request from '../helpers/Request'
import BaseService from './BaseService'

class EventService extends BaseService {
  constructor(token) {
    super(token)
  }
  // EVENTS
  async getAllEvents() {
    const getEventsUrl = `/api/v1/events/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getEventsUrl, { headers })
  }

  async getEvents(formData) {
    const getEventsUrl = `/api/v1/events/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    // let noEmptyDate = Object.keys(formData).forEach((key) => (formData[key] === "" || formData[key] === "Неважно" || formData[key] === undefined) && delete formData[key]);
    return await Request.get(getEventsUrl, {
      headers,
      params: formData
    })
  }

  async getEventId(id) {
    const getEventIdUrl = `/api/v1/events/${id}/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getEventIdUrl, { headers })
  }

  async deleteEvent(id) {
    const deleteCategoryEventUrl = `/api/v1/events/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteCategoryEventUrl, { headers })
  }
  // CATEGORY EVENTS
  async getCategoryEvents() {
    const getCategoryEventsUrl = `/api/v1/localization/events/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getCategoryEventsUrl, {
      headers,
      params: { lang: 'RU' }
    })
  }

  async addCategoryEvent(payload) {
    const addCategoryEventUrl = '/api/v1/localization/event/category/add/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Content-Type': 'multipart/form-data'
    }
    return await Request.post(addCategoryEventUrl, payload, { headers })
  }

  async updateCategoryEvent(payload) {
    const updateCategoryEventUrl = `/api/v1/localization/event/category/${payload.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Content-Type': 'multipart/form-data'
    }
    return await Request.put(updateCategoryEventUrl, payload.data, { headers })
  }

  async deleteCategoryEvent(id) {
    const deleteCategoryEventUrl = `/api/v1/localization/event/category/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteCategoryEventUrl, { headers })
  }
  // SUBCATEGORY EVENTS
  async getEventsSubCategory(lang) {
    const getEventsSubCategoryUrl = `/api/v1/localization/event/content/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getEventsSubCategoryUrl, {
      headers,
      params: { lang }
    })
  }

  async addSubCategoryEvent(payload) {
    const addSubCategoryEventUrl = '/api/v1/localization/event/content/add/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Content-Type': 'multipart/form-data'
    }

    return await Request.post(addSubCategoryEventUrl, payload, { headers })
  }
  async updateSubCategoryEvent(payload) {
    const updateSubCategoryEventUrl = `/api/v1/localization/event/content/${payload.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Content-Type': 'multipart/form-data'
    }
    return await Request.put(updateSubCategoryEventUrl, payload.data, {
      headers
    })
  }
  async deleteSubCategoryEvent(id) {
    const deleteSubCategoryEventUrl = `/api/v1/localization/event/content/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteSubCategoryEventUrl, { headers })
  }
  // EVENTREFBOOK
  async getEventReferenceBook(lang) {
    const getEventReferenceBookUrl = `/api/v1/localization/eventrefbook/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getEventReferenceBookUrl, {
      headers,
      params: { lang }
    })
  }

  async addEventReferenceBook(payload) {
    const addEventReferenceBookUrl = '/api/v1/localization/eventrefbook/add/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(addEventReferenceBookUrl, payload, { headers })
  }
  async updateEventReferenceBook(payload) {
    const updateEventReferenceBookUrl = `/api/v1/localization/eventrefbook/${payload.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(updateEventReferenceBookUrl, payload, { headers })
  }
  async deleteEventReferenceBook(payload) {
    const deleteEventReferenceBookUrl = `/api/v1/localization/eventrefbook/${payload.id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteEventReferenceBookUrl, { headers })
  }
}

export default EventService
