<template>
  <div class="purchase__header">
    <h1 class="purchase__title">Предупреждения пользователям</h1>
    <div class="purchase__wrap">
      <select class="purchase__wrap__select" v-model="selected" @change="getCategoriesByLang(selected)">
        <option v-for="lang in langs" :key="lang.value" :value="lang.value">
          {{ lang.name }}
        </option>
      </select>
      <button type="button" class="purchase__btn-add" @click="$root.$emit(CLAIM_ADD_POPUP_EVENT, selected)">
        <i class="purchase__new-points-icon fa fa-plus-square"></i>
        <span class="purchase__btn-title">Добавить</span>
      </button>
    </div>
  </div>
</template>

<script>
import { CLAIM_ADD_POPUP_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_ALL_USERS_CLAIMS } from '@/store/action-types'
// import { SET_CURRENT_LANGUAGES } from "@/store/mutation-types.js";
import { GET_LANGUAGES } from '@/store/getter-types'
import { mapGetters } from 'vuex'
export default {
  name: 'ClaimHeader',
  data() {
    return {
      CLAIM_ADD_POPUP_EVENT,
      selected: 'RU'
    }
  },
  computed: {
    ...mapGetters({
      langs: GET_LANGUAGES
    })
  },
  methods: {
    ...mapActions({
      categoryByLang: REQUEST_ALL_USERS_CLAIMS
    }),
    // ...mapMutations({
    //   setCurrentLang: SET_CURRENT_LANGUAGES,
    // }),
    getCategoriesByLang(value) {
      let data = {
        lang: value
      }
      this.categoryByLang(data)
    }
  }
  // computed: {
  //   ...mapGetters({
  //     languages: GET_LANGUAGES,
  //     currentLang: GET_CURRENT_LANGUAGES,
  //   }),
  //   selected: {
  //     get() {
  //       return this.currentLang;
  //     },
  //     set(newValue) {
  //       this.setCurrentLang(newValue);
  //     },
  //   },
  // },
}
</script>

<style lang="scss">
@import './ClaimHeader.scss';
</style>
