<template>
  <Popup :visible="visible" @close="close" description="Покупки">
    <div class="popup__buy" v-if="purchases.purchases">
      <div class="popup__header">
        <span class="popup__header-item">Дата платежа</span>
        <span class="popup__header-item">Платежная система</span>
        <span class="popup__header-item">Цель покупки</span>
        <span class="popup__header-item">Стоимость покупки</span>
      </div>
      <ul class="popup__buy-list">
        <UserBuyListPopupPurchases
          class="popup__buy-item"
          v-for="(elem, i) in purchases.purchases"
          :key="i"
          :create="elem.create"
          :gateway="elem.gateway"
          :target="elem.target"
          :currency="elem.currency"
          :amount="elem.amount"
          :value="elem.value"
        />
      </ul>
    </div>
    <div class="popup__buy" v-else>
      <h1>У пользователя нет покупок</h1>
    </div>
  </Popup>
</template>
<script>
import { mapGetters } from 'vuex'
import { GET_USER_PURCHASES } from '@/store/getter-types'
import Popup from '@/components/Modals/Popup'
import UserBuyListPopupPurchases from '@/components/Modals/UserPopup/UserBuyListPopupPurchases'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_BUY_LIST_PURCHASES } from '@/constants/event'
export default {
  name: 'UserBuyListPopup',
  components: {
    Popup,
    UserBuyListPopupPurchases
  },
  mixins: [ModalMixin],

  computed: {
    ...mapGetters({
      purchases: GET_USER_PURCHASES
    })
  },

  mounted() {
    this.$root.$on(USER_BUY_LIST_PURCHASES, () => {
      this.open(USER_BUY_LIST_PURCHASES)
    })
  }
}
</script>

<style lang="scss" scoped>
.popup__buy {
  width: 900px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1020px) {
    font-size: 13px;
    min-width: 280px;
    width: 100%;
  }
}
.popup__warning {
  border-color: red;
}
.popup__buy-list {
  background-color: #e7e8ec;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 700px;
  &::-webkit-scrollbar {
    background-color: #fff;
    z-index: 0;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(232, 64, 63);
    border-radius: 10px;
  }
}
.popup__buy-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 1px 1px 1px;
  background-color: white;
  &:first-child {
    margin-top: 1px;
  }
  @media (max-width: 1020px) {
    flex-direction: column;
    margin: 2px 1px 1px 1px;
  }
}
.popup__item-info {
  width: 25%;
  text-align: center;
  border: 1px solid #e7e8ec;
  padding: 5px;
  @media (max-width: 1020px) {
    text-align: start;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.popup__item-text {
  display: none;
  @media (max-width: 1020px) {
    display: block;
    margin: 0;
  }
}
.popup__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  @media (max-width: 1020px) {
    display: none;
  }
}
.popup__header-item {
  width: 25%;
  text-align: center;
}
</style>
