<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  mounted() {
    this.renderChartBar()
  },
  methods: {
    renderChartBar: function() {
      this.renderChart(this.chartData, this.options)
    }
  },
  computed: {
    reRenderBar() {
      return this.chartData.datasets[0].data
    }
  },
  watch: {
    reRenderBar() {
      this.renderChartBar()
    }
  }
}
</script>
