<template>
  <div class="table-header-wrapper-message">
    <ul class="table-header table-message-header">
      <li class="table-header__item-message">
        <span class="table-header__item-text">Оператор</span>
      </li>
      <li class="table-header__item-message">
        <span class="table-header__item-text">Пользователь</span>
      </li>
      <li class="table-header__item-message">
        <span class="table-header__item-text">Автосообщение</span>
      </li>
      <li class="table-header__item-message">
        <span class="table-header__item-text">Диалог</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'MessageTableHeader'
}
</script>
<style lang="scss" scoped>
@import '@/components/AutoMessagesList/messageList-table-header.scss';
.table-header-wrapper-message {
  display: flex;
  align-items: center;

  //padding: 5px 16px;
  width: calc(100% - 38px);
  margin: 90px 16px 0 16px;
  padding-bottom: 3px;
  background-color: #f1f2f7;
  transition: 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  z-index: 2;
  @media (max-width: 3904px) {
    margin-top: 94px;
  }
  @media (max-width: 3494px) {
    margin-top: 96px;
  }
  @media (max-width: 2456px) {
    margin-top: 155px;
  }
  @media (max-width: 2246px) {
    margin-top: 155px;
  }
  @media (max-width: 2177px) {
    margin-top: 150px;
  }
  @media (max-width: 1967px) {
    margin-top: 150px;
  }
  @media (max-width: 1813px) {
    margin-top: 155px;
  }
  @media (max-width: 1618px) {
    margin-top: 160px;
  }
  @media (max-width: 1570px) {
    margin-top: 222px;
  }
  @media (max-width: 1341px) {
    margin-top: 222px;
  }
  @media (max-width: 1215px) {
    margin-top: 218px;
  }
  @media (max-width: 1131px) {
    margin-top: 223px;
  }
  @media (max-width: 1020px) {
    display: none;
  }
}
.table-user-header {
  justify-content: space-between;
}
.table-header__item-user {
  display: flex;
  padding: 7px 5px 9px 5px;
  &:nth-child(1) {
    width: 22%;
    padding: 7px 5px 9px 8px;
  }
  &:nth-child(2) {
    left: 10px;
    width: 6%;
  }
  &:nth-child(3) {
    width: 8%;
  }
  &:nth-child(4) {
    width: 8%;
  }
  &:nth-child(5) {
    width: 8%;
  }
  &:nth-child(6) {
    width: 8%;
  }
  &:nth-child(7) {
    width: 8%;
    text-align: center;
  }
  &:nth-child(8) {
    width: 16%;
    text-align: center;
  }
  &:nth-child(9) {
    width: 8%;
    text-align: center;
  }
  &:nth-child(10) {
    width: 9%;
    text-align: center;
  }
  &:nth-child(11) {
    width: 6%;
    text-align: center;
  }
  &:nth-child(12) {
    width: 6%;
    text-align: center;
  }
  //&:nth-child(7) {
  //  width: 6%;
  //}
}
.table-header__item-info {
  &:nth-child(1) {
    min-width: 60%;
    width: 72%;
  }
  &:nth-child(2) {
    width: 15%;
  }
  &:nth-child(3) {
    width: 35%;
  }
}
</style>
<!--main.sidebar-hidden .filters-user-wrap-->
<!--width: calc(100% - 38px);-->
