<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description=""
    modalClass="popup--small popup--operator popup--scroll"
  >
    <div v-if="key === 3" class="popup__item">
      <div class="operator-profile__edit-group">
        <span class="operator-profile-banner__item-popup-red">Удалить сообщение?</span>
      </div>
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteMessage">
          Удалить
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Закрыть
        </button>
      </div>
    </div>
    <div v-else class="popup__item">
      <div class="operator-profile__edit-group">
        <span class="operator-profile-banner__item-popup">{{ title }}</span>
        <input
          type="text"
          class="operator-message-input"
          @click="messageDelay = ''"
          :placeholder="messageDelay"
          v-model="messageDelay"
        />
      </div>
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="saveAutoMesssage" :disabled="buttonDis">
          {{ buttonSave }}
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Закрыть
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions } from 'vuex'
import {
  REQUEST_EDIT_AUTO_MESSAGE_DELAY,
  REQUEST_CHANGE_OPERATORS_AUTO_MESSAGE,
  REQUEST_DELETE_OPERATORS_AUTO_MESSAGE
} from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { OPERATOR_EDIT_AUTO_MESSAGE } from '@/constants/event'

export default {
  name: 'OperatorAutoMessageDelay',
  components: {
    Popup
  },
  data: () => ({
    message: {},
    messageDelay: 0,
    key: 0,
    pattern: /^\d+$/
  }),
  mixins: [ModalMixin],
  watch: {
    messageDelay() {
      if (!this.isValid) this.messageDelay = ''
    }
  },
  mounted() {
    this.$root.$on(OPERATOR_EDIT_AUTO_MESSAGE, (data, key) => {
      this.setData(data, key)
      this.open(OPERATOR_EDIT_AUTO_MESSAGE)
    })
  },
  computed: {
    title() {
      return this.key === 1 ? 'Задайте время задержки в секундах.' : 'Отредактировать время.'
    },
    buttonSave() {
      return this.key === 1 ? 'Создать' : 'Изменить'
    },
    buttonDis() {
      return +this.messageDelay <= 0 || +this.messageDelay === this.message.delay_seconds
    },
    isValid() {
      return this.pattern.test(this.messageDelay)
    }
  },
  methods: {
    ...mapActions({
      editAutoMesssage: REQUEST_EDIT_AUTO_MESSAGE_DELAY,
      changeAutoMesssage: REQUEST_CHANGE_OPERATORS_AUTO_MESSAGE,
      deleteAutoMesssage: REQUEST_DELETE_OPERATORS_AUTO_MESSAGE
    }),
    saveAutoMesssage() {
      const id = this.message.id
      const payload = {
        enabled: true,
        delay_seconds: +this.messageDelay
      }
      this.key === 1 ? this.editAutoMesssage(payload) : this.changeAutoMesssage({ payload, id })
      this.close()
    },
    deleteMessage() {
      this.deleteAutoMesssage(this.message)
      this.close()
    },
    closePopup() {
      this.close()
    },
    setData(data, key) {
      this.message = data
      this.messageDelay = 0
      if (data.delay_seconds) this.messageDelay = data.delay_seconds
      this.key = key
    }
  }
}
</script>
<style lang="scss" scoped>
@import './EditOperator.scss';
.operator-message-input {
  margin: auto;
  margin-top: 20px;
  height: 34px;
  text-align: center;
  font-size: 14px;
  color: #717171;
  font-family: inherit;
  width: 40%;
  border-radius: 5px;
  &::placeholder {
    color: #999999;
  }
}
.popup__btn {
  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
}
</style>
