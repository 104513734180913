import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import CalendarEventService from '@/services/CalendarEventService'

export default {
  [ActionTypes.REQUEST_SHIFT_OPTIONS]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN

    const response = await Reflect.construct(CalendarEventService, [userToken]).getShiftOptions()

    const { data } = response
    if (data) {
      commit(MutationTypes.SET_SHIFT_OPTIONS, data)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_NEW_SHIFT_OPTIONS]: async ({ rootGetters, commit, dispatch }, time) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })

    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(CalendarEventService, [userToken]).changeShiftOptions(time)

    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_SHIFT_OPTIONS)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CALENDAR_EVENTS]: async ({ rootGetters, commit }, params) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(CalendarEventService, [userToken]).getCalendarEvent(params)

    const { data } = response
    if (data) {
      commit(MutationTypes.SET_EVENTS_CALENDAR, data)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_ADD_CALENDAR_EVENT]: async ({ rootGetters }, eventData) => {
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(CalendarEventService, [userToken]).addCalendarEvent(eventData)

    const { status } = response
    if (status) {
      console.log(status)
    }

    return response
  },
  [ActionTypes.REQUEST_DELL_MODERATOR_EVENT]: async ({ rootGetters }, params) => {
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(CalendarEventService, [userToken]).deleteModerator(params)
    const { status } = response
    if (status) {
      console.log(status)
    }
    return response
  },

  [ActionTypes.REQUEST_ADD_MODERATOR_EVENT]: async ({ rootGetters }, data) => {
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(CalendarEventService, [userToken]).addModerator(data)
    const { status } = response
    if (status) {
      console.log(status)
    }
    return response
  },

  [ActionTypes.REQUEST_CHANGE_OPERATOR_EVENT]: async ({ rootGetters }, params) => {
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(CalendarEventService, [userToken]).changeOperator(params)
    const { status } = response
    if (status) {
      console.log(status)
    }
    return response
  },

  [ActionTypes.REQUEST_MOD_OPERATOR_EVENTS]: async ({ rootGetters, dispatch }, params) => {
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(CalendarEventService, [userToken]).modOperatorEvent(params)

    const { status } = response
    if (status) {
      dispatch(ActionTypes.REQUEST_CALENDAR_EVENTS, params)
    }
    return response
  }
}
