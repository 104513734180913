<template>
  <Popup :visible="visible" @close="close" description="Удалить тариф?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="delHandler()">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions } from 'vuex'
import { REQUEST_DELETE_MODERATOR_PAYMENTS_SETTING } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { DEL_MODERATION_OPTION_POPUP_EVENT } from '@/constants/event'

export default {
  name: 'DelModeratorPopup',
  components: {
    Popup
  },
  data: () => ({
    DEL_MODERATION_OPTION_POPUP_EVENT,
    id: null
  }),
  mixins: [ModalMixin],

  methods: {
    ...mapActions({
      deleteOperator: REQUEST_DELETE_MODERATOR_PAYMENTS_SETTING
    }),

    delHandler() {
      this.deleteOperator({ moderator_payment_settings_id: this.id })
      this.id = null
      this.close()
    }
  },

  mounted() {
    this.$root.$on(DEL_MODERATION_OPTION_POPUP_EVENT, id => {
      this.id = id
      this.open()
    })
  }
}
</script>
