<template>
  <Popup :visible="visible" @close="close" title="" description="Ошибка:" modalClass="popup--small">
    <div class="modal-update__question">
      <h2 class="pop-up__title pop-up__title--error">
        Данный Email уже занят!
      </h2>
      <div class="pop-up__btn-wrapper">
        <button type="button" @click="close" class="button--center">
          Закрыть
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'

import { EMAIL_ERROR_POPUP } from '@/constants/event'
export default {
  name: 'EmailErrorPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  mounted() {
    this.$root.$on(EMAIL_ERROR_POPUP, prop => {
      this.open()
    })
  }
}
</script>

<style lang="scss">
@import './EditEmployee.scss';
.button--center {
  display: flex;
  border-radius: 0.25rem;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  border: 1px solid transparent;
  color: #fff;
  margin: 10px auto;
  background-color: #ec6459;
}
.pop-up__title--error {
  text-align: center;
}
</style>
