<template>
  <div>
    <div class="table-statistic__wrapp">
      <span class="table-statistic__item">
        <button @click="isHide = !isHide">
          <FaIcon class="table-statistic__item--cross" :iconClass="'fa-light fa-plus'" />
        </button>
        {{ stat.start }}
      </span>
      <span class="table-statistic__item">{{ stat.end }}</span>
      <span class="table-statistic__item">{{ stat.pay }}</span>
      <span class="table-statistic__item">{{ secondsToMinute }}</span>
      <span class="table-statistic__item">{{ stat.in }}</span>
      <span class="table-statistic__item">{{ stat.out }}</span>
      <span class="table-statistic__item">{{ stat.meeting_duration }}</span>
      <span class="table-statistic__item">{{ stat.meeting_sum }}</span>
      <span class="table-statistic__item" :class="{ 'table-statistic__item--red': stat.fine_sum > 0 }">{{
        stat.fine_sum
      }}</span>
      <span class="table-statistic__item">{{ stat.country_category }}</span>
      <span class="table-statistic__item">{{ stat.currency }}</span>
    </div>
    <OperatorStatisticList :statistic="stat" :isHide="isHide" />
  </div>
</template>

<script>
import OperatorStatisticList from '@/components/Operators/OperatorStatistic/OperatorStatisticList'
import moment from 'moment'

export default {
  name: 'TableStatisticRow',
  components: {
    OperatorStatisticList
  },
  data() {
    return {
      isHide: false
    }
  },
  computed: {
    secondsToMinute() {
      return moment.utc(this.stat.average_time * 1000).format('Dд. HHч. mmмин. sscек.')
    }
  },
  props: {
    stat: {
      type: Object
    },
    statistic: {
      type: Array
    }
  }
}
</script>

<style lang="scss">
@import 'OperatorStatistic';

.table-statistic__wrapp {
  display: flex;
}

.operators__error {
  margin-top: 20px;
  padding: 0 16px 0 16px;
  min-height: 64px;
  border-radius: 2px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  &-text {
    margin: 0 auto;
  }
}
</style>
