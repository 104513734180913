<template>
  <div class="event-user-map">
    <l-map class="map" ref="myMap" :zoom="zoom" :center="center" :options="mapOptions">
      <l-tile-layer :url="url" />
      <l-marker :lat-lng="marker"> </l-marker>
    </l-map>
  </div>
</template>

<script>
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
export default {
  name: 'EventMap',
  props: {
    event: {
      type: Array
    }
  },
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data() {
    return {
      zoom: 15,
      center: latLng(0, 0),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      marker: latLng(0, 0),
      mapOptions: {
        zoomSnap: 0.5
      }
    }
  },
  mounted() {
    this.center = latLng(this.event[1], this.event[0])
    this.marker = latLng(this.event[1], this.event[0])
  }
}
</script>

<style lang="scss">
.event-user-map {
  z-index: 0;
  //width: 100%;
  height: 200px;
  margin-top: 10px;
  margin-right: 10px;
  //max-width: 980px;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  //@media (max-width: 2210px) {
  //  width: 100%;
  //  margin-right: 0;
  //  margin-bottom: 8px;
  //}
  @media (max-width: 1020px) {
    min-width: 100%;
  }
}
.map {
  z-index: 0;
  border-radius: 5px;
}
</style>
