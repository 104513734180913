<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description=""
    modalClass="popup--medium popup--operator popup--scroll"
  >
    <div class="popup__item">
      <div class="operator-profile__edit-group">
        <div for="operator-search" class="operator-profile__edit-text">
          <span>Параметры поиска автосообщений</span>
          <button
            class="operator__btn btn-blue operator__btn--blue"
            @click.prevent="$root.$emit('ADD_SEARCH_OPTION_POPUP_EVENT')"
          >
            <i class="operators__btn-txt fa fa-plus-square"></i>
          </button>
        </div>
        <ul class="operator-profile__list" v-if="searchOptions.length">
          <li class="operator-profile__item" v-for="(search, ind) in searchOptions" :key="ind">
            <div class="operator-profile__item-info">
              <div :key="search.gender" class="operator-profile__item-text">
                Пол:
                <i
                  :class="{
                    'fa fa-mars auto-messages__male': search.gender === 1,
                    'fa fa-venus auto-messages__female': search.gender === 2,
                    'fa fa-genderless auto-messages__no-gender': search.gender === 0
                  }"
                ></i>
              </div>
              <span class="operator-profile__item-text"
                >Возраст: {{ search.age_min }} - {{ search.age_max }}</span
              >
              <span class="operator-profile__item-text">Страна: {{ search.country_code }}</span>
              <span class="operator-profile__item-text">Язык: {{ langString(search.langs) }}</span>
            </div>
            <div class="operator-profile__item-btns">
              <button
                class="operator__btn btn-blue operator__btn--blue"
                @click.prevent="$root.$emit('EDIT_SEARCH_OPTION_POPUP_EVENT', search)"
              >
                <i class="fa fa-edit"></i>
              </button>
              <button
                class="operator__btn btn-red operator__btn--red"
                @click.prevent="$root.$emit('DEL_SEARCH_OPTION_POPUP_EVENT', search)"
              >
                <i class="fa fa-times-circle"></i>
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="operator-profile__edit-group">
        <div class="operator-profile__edit-text">
          <div class="operator-profile__input-form">
            <span>Изменить приоритет отправки:</span>
            <input class="operator-profile__input-form-input" v-model="weight_sending" type="number" />
          </div>
        </div>
      </div>

      <div class="operator-profile__edit-group">
          <div class="type-message">
            <span>Типы автосоощений:</span>
            <div class="type-message__item">
              <p class="type-message__title">Аудио</p>
              <input class="type-message__input" v-model="audio" type="checkbox" />
            </div>
            <div class="type-message__item">
              <p class="type-message__title">Видео</p>
              <input class="type-message__input" v-model="video" type="checkbox" />
            </div>
            <div class="type-message__item">
              <p class="type-message__title">Изображения</p>
              <input class="type-message__input" v-model="photo" type="checkbox" />
            </div>
          </div>
        </div>
      </div>

      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="editSearchOptions">
          Сохранить
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Закрыть
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SET_ADD_SEARCH_OPTIONS } from '@/store/mutation-types'
import { GET_OPERATOR_SEARCH_OPTION } from '@/store/getter-types'
import { REQUEST_EDIT_OPERATORS_SEARCH_OPTIONS, REQUEST_CHANGE_WEIGHT_SENDING, REQUEST_CHANGE_TYPE_MESSAGE } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EDIT_USER_OPERATOR_POPUP_EVENT } from '@/constants/event'

export default {
  name: 'EditUserSearchOptions',
  components: {
    Popup
  },
  data: () => ({
    operator_id: '',
    account_id: '',
    searchOld: [],
    weight_sending: 0,
    audio: false,
    video: false,
    photo: false
  }),
  mixins: [ModalMixin],

  computed: {
    ...mapGetters({
      searchOptions: GET_OPERATOR_SEARCH_OPTION
    })
  },
  methods: {
    ...mapActions({
      editOptions: REQUEST_EDIT_OPERATORS_SEARCH_OPTIONS,
      editWeightSending: REQUEST_CHANGE_WEIGHT_SENDING,
      editTypeMessages: REQUEST_CHANGE_TYPE_MESSAGE
    }),
    ...mapMutations({
      addOptions: SET_ADD_SEARCH_OPTIONS
    }),
    closePopup() {
      this.searchOld = []
      this.operator_id = ''
      this.account_id = ''
      this.addOptions(null)
      this.close()
    },
    langString(langs) {
      let newArr = []
      langs.forEach(f => {
        newArr.push(f.value)
      })
      return newArr.join(', ')
    },
    editSearchOptions() {
      let responses = []
      let formData = {
        data: {
          account_id: this.account_id,
          searches: []
        },
        operator: this.operator_id
      }
      let r = []
      let newFormatOptions = []
      this.searchOptions.forEach(f => {
        newFormatOptions.push({
          age_max: f.age_max,
          age_min: f.age_min,
          country_code: f.country_code,
          gender: f.gender,
          langs: [f.langs[0].value]
        })
      })
      let newWeight = {
        id: this.operator_id,
        data: {
          account_id: this.account_id,
          weight_sending: this.weight_sending <= 2000000000 ? this.weight_sending : 2000000000
        }
      }
      let newTypes = {
        id: this.operator_id,
        data: {
          account_id: this.account_id,
          auto_message_use: {
            photo: this.photo,
            audio: this.audio,
            video: this.video
          }
        }
      }
      if (this.searchOptions.length === this.searchOld.length) {
        for (let i = 0; i < this.searchOptions.length; i++) {
          if (
            this.searchOptions[i].age_max !== this.searchOld[i].age_max ||
            this.searchOptions[i].age_min !== this.searchOld[i].age_min ||
            this.searchOptions[i].country_code !== this.searchOld[i].country_code ||
            this.searchOptions[i].gender !== this.searchOld[i].gender ||
            this.searchOptions[i].langs[0].value !== this.searchOld[i].langs[0].value
          )
            r.push(this.searchOptions[i])
        }
        if (r.length) {
          formData.data.searches = newFormatOptions
          this.editWeightSending(newWeight).then(res => {
            if(res.status) responses.push(true)
            else(false)
          })
          this.editTypeMessages(newTypes).then(res => {
            if(res.status) responses.push(true)
            else(false)
          })
          this.editOptions(formData).then(res => {
            if(res.status) responses.push(true)
            else(false)
          })
        }
        // else {
        //   this.closePopup();
        // }
      } else {
        formData.data.searches = newFormatOptions
        this.editWeightSending(newWeight).then(res => {
            if(res.status) responses.push(true)
            else(false)
          })
        this.editTypeMessages(newTypes).then(res => {
            if(res.status) responses.push(true)
            else(false)
          })
        this.editOptions(formData).then(res => {
            if(res.status) responses.push(true)
            else(false)
          })
      }
      this.editWeightSending(newWeight).then(res => {
            if(res.status) responses.push(true)
            else(false)
          })
      this.editTypeMessages(newTypes).then(res => {
            if(res.status) responses.push(true)
            else(false)
          })
      setTimeout(() => {

        if(responses.every(value => value)) this.$root.$emit('changeOperators', 'editSearchOpt')
        this.closePopup()
      }, 500)
    }
  },

  mounted() {
    this.$root.$on(EDIT_USER_OPERATOR_POPUP_EVENT, data => {
      this.operator_id = data.operator
      this.account_id = data.account.id
      data.searches.forEach(m => {
        this.searchOld.push(m)
      })
      this.audio = data.auto_message_use.audio
      this.video = data.auto_message_use.video
      this.photo = data.auto_message_use.photo
      this.weight_sending = data.weight_sending != null ? data.weight_sending : 0
      this.addOptions(data.searches)
      this.open()
    })
  }
}
</script>
<style lang="scss" scoped>
@import './EditOperator.scss';
.operator-profile__input-form {
  display: flex;
  flex-direction: column;
  &-input {
    margin-top: 8px;
    width: 40%;
    height: 34px;
    border: none;
    border-radius: 5px;
    padding: 8px;
    color: #717171;
    background-color: #e7e8ec;
    outline: none;
  }
}

.type-message {
  display: flex;
  flex-direction: column;
  color:#a6aec0;
  &__item {
    display: flex;
    align-content: center;
    font-size: 16px;
  }
  &__input {
    height: 18px;
  }
}
</style>
