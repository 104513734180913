<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    title=""
    description=""
    modalClass="popup--medium popup--purchase popup--scroll"
  >
    <form class="purchase-profile" @submit.prevent="createPurchaseSubmitHandler">
      <span class="purchase-profile__edit-text purchase-profile__header">Редактировать цифровую покупку</span>
      <div class="purchase-profile__edit">
        <div class="purchase-profile__edit-body">
          <div class="purchase-profile__edit-group">
            <span class="purchase-profile__edit-select-title">Покупка</span>
            <div class="purchase-profile__edit-select">
              <button
                type="button"
                class="purchase-profile__edit-value"
                @click="targetOptions = !targetOptions"
              >
                <span class="purchase-profile__edit-value-text">{{
                  defaultPurchaseFilter({ key: 'target' })
                }}</span>
                <i class="fa fa-angle-down purchase-profile__edit-select-icon"></i>
              </button>
              <ul
                class="purchase-profile__edit-select-list"
                v-if="targetOptions"
                @mouseleave="targetOptions = !targetOptions"
                :class="{ active: targetOptions }"
              >
                <li
                  v-for="target in purchaseRoles.target"
                  :key="target.id"
                  @click="selectItemHandler({ key: 'target', value: target })"
                  class="purchase-profile__edit-select-item"
                >
                  <span class="purchase-profile__edit-select-text">{{ target.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="purchase-profile__edit-group">
            <label for="target-value" class="purchase-profile__edit-text">Значение покупки</label>
            <input
              id="target-value"
              type="text"
              class="purchase-profile__edit-input"
              v-model="createPurchaseTargetValue"
              ref="createpurchasetargetvalue"
              required
            />
          </div>
          <div class="purchase-profile__edit-group">
            <label for="purchase-price" class="purchase-profile__edit-text">Стоимость</label>
            <input
              id="purchase-price"
              type="text"
              class="purchase-profile__edit-input"
              v-model="createPurchasePrice"
              ref="createpurchaseprice"
              required
            />
          </div>
          <div class="purchase-profile__edit-group">
            <span class="purchase-profile__edit-select-title">Валюта</span>
            <div class="purchase-profile__edit-select">
              <button
                type="button"
                class="purchase-profile__edit-value"
                @click="currencyOptions = !currencyOptions"
              >
                <span class="purchase-profile__edit-value-text">{{
                  defaultPurchaseFilter({ key: 'currency' })
                }}</span>
                <i class="fa fa-angle-down purchase-profile__edit-select-icon"></i>
              </button>
              <ul
                class="purchase-profile__edit-select-list"
                v-if="currencyOptions"
                @mouseleave="currencyOptions = !currencyOptions"
                :class="{ active: currencyOptions }"
              >
                <li
                  v-for="currency in purchaseRoles.currency"
                  :key="currency.id"
                  @click="selectItemHandler({ key: 'currency', value: currency })"
                  class="purchase-profile__edit-select-item"
                >
                  <span class="purchase-profile__edit-select-text">{{ currency.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="purchase-profile__edit-group">
            <label for="purchase-countrycode" class="purchase-profile__edit-text">Код страны</label>
            <input
              id="purchase-countrycode"
              type="text"
              class="purchase-profile__edit-input"
              v-model="createPurchaseCountryCode"
              maxlength="2"
              ref="createpurchasecountrycode"
            />
          </div>
          <div class="purchase-profile__edit-group purchase-profile__edit-group--btns">
            <button
              type="submit"
              class="purchase-profile__edit-action-btn purchase-profile__edit-action-btn--save"
            >
              <span class="purchase-profile__edit-action-btn-text">Изменить</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { SET_CLEAR_PURCHASES_DATA, SET_PURCHASE_SETTINGS } from '@/store/mutation-types'
import { REQUEST_UPDATE_PURCHASES } from '@/store/action-types'
import {
  GET_PURCHASES_ROLES,
  GET_PURCHASES_TMP_ROLES,
  GET_PURCHASES_ROLES_DEFAULT
} from '@/store/getter-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { PURCHASE_POPUP_EVENT } from '@/constants/event'

export default {
  name: 'PurchasesEditPopUp',
  data: () => ({
    PURCHASE_POPUP_EVENT,
    targetOptions: false,
    currencyOptions: false,
    oldData: null,
    purchaseData: {
      price: null,
      country_code: null,
      target_value: null
    }
  }),
  components: {
    Popup
  },
  computed: {
    ...mapGetters({
      purchaseRoles: GET_PURCHASES_ROLES,
      purchaseTmpRoles: GET_PURCHASES_TMP_ROLES,
      purchaseRolesDefault: GET_PURCHASES_ROLES_DEFAULT
    }),

    createPurchaseTargetValue: {
      get() {
        return this.purchaseData.target_value
      },
      set(value) {
        value = value.replace(/[^0-9\.]/g, '')
        this.$refs.createpurchasetargetvalue.value = value
        this.purchaseData.target_value = value
      }
    },

    createPurchasePrice: {
      get() {
        return this.purchaseData.price
      },
      set(name) {
        name = name.replace(/[^0-9\.]/g, '')
        this.$refs.createpurchaseprice.value = name
        this.purchaseData.price = name
      }
    },

    createPurchaseCountryCode: {
      get() {
        return this.purchaseData.country_code
      },
      set(country) {
        country = country.replace(/[^A-Z]/gi, '').toUpperCase()
        this.$refs.createpurchasecountrycode.value = country
        this.purchaseData.country_code = country
      }
    }
  },
  mixins: [ModalMixin],

  methods: {
    ...mapMutations({
      clearData: SET_CLEAR_PURCHASES_DATA,
      addTmpPurchaseSettings: SET_PURCHASE_SETTINGS
    }),
    ...mapActions({
      editNewPurchase: REQUEST_UPDATE_PURCHASES
    }),

    defaultPurchaseFilter(settings) {
      const { key } = settings
      const current = this.purchaseTmpRoles[key].name
      return current ? current : this.purchaseRolesDefault[key].name
    },

    defaultPurchaseCurrency(settings) {
      const { key } = settings
      const current = this.purchaseTmpRoles[key].position_name
      return current ? current : this.purchaseRolesDefault[key].name
    },

    defaultPurchaseValue(settings) {
      const { key } = settings
      const current = this.purchaseTmpRoles[key].name
      return current ? this.purchaseTmpRoles[key].id : this.purchaseRolesDefault[key].id
    },

    selectItemHandler(selectItem) {
      const { key } = selectItem
      switch (key) {
        case 'target':
          this.addTmpPurchaseSettings({ ...selectItem })
          break
        case 'currency':
          this.addTmpPurchaseSettings({ ...selectItem })
          break
        default:
          break
      }
      this[`${key}Options`] = false
    },

    closePopup() {
      this.clearData()
      this.purchaseData.price = null
      this.purchaseData.country_code = null
      this.purchaseData.target_value = null
      this.close()
    },

    createPurchaseSubmitHandler() {
      const data = {
        purchase_id: this.oldData.id,
        price: this.purchaseData.price !== this.oldData.price ? +this.purchaseData.price : null,
        country_code:
          this.purchaseData.country_code !== this.oldData.country_code
            ? this.purchaseData.country_code
            : null,
        target_value:
          this.purchaseData.target_value !== this.oldData.target_value
            ? +this.purchaseData.target_value
            : null,
        currency:
          this.defaultPurchaseValue({ key: 'currency' }) !== this.oldData.currency
            ? this.defaultPurchaseValue({ key: 'currency' })
            : null,
        target:
          this.defaultPurchaseValue({ key: 'target' }) !== this.oldData.target
            ? this.defaultPurchaseValue({ key: 'target' })
            : null
      }
      Object.keys(data).forEach(
        key => (data[key] === null || data[key] === undefined || data[key] === 'Неважно') && delete data[key]
      )
      this.editNewPurchase(data)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(PURCHASE_POPUP_EVENT, purchase => {
      this.purchaseData.country_code = purchase.country_code
      this.purchaseData.price = purchase.price
      this.purchaseData.target_value = purchase.target_value
      let target = this.purchaseRoles.target.find(f => f.value === purchase.target)
      this.selectItemHandler({ key: 'target', value: target })
      let currency = this.purchaseRoles.currency.find(f => f.id === purchase.currency)
      this.selectItemHandler({ key: 'currency', value: currency })
      this.oldData = purchase
      this.open()
    })
  }
}
</script>
<style lang="scss" scoped>
@import './Purchase.scss';
.purchase__header {
  margin-left: 10px;
}
</style>
