<template>
  <div class="table-header-wrapper-view">
    <ul class="table-header table-header-view">
      <li class="table-header__item">
        <span class="table-header__item-text">
          Страна
        </span>
      </li>
      <li class="table-header__item">
        <span class="table-header__item-text">
          Язык
        </span>
      </li>
      <li class="table-header__item">
        <span class="table-header__item-text">
          Пол
        </span>
      </li>
      <li class="table-header__item">
        <span class="table-header__item-text">
          Возрастной диапазон
        </span>
      </li>
      <li class="table-header__item">
        <span class="table-header__item-text">
          Тип страницы
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ViewHeaderTable'
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/tableHeader.scss';
@import './ViewTableHeder.scss';
</style>
