export default {
  methods: {
    statusText(status) {
      let statusText = null
      switch (status) {
        case 0:
          statusText = 'Транзакция создана в платежной системе'
          break
        case 1:
          statusText = 'Транзакция в процессе выполнения'
          break
        case 2:
          statusText = 'Транзакция выполнена успешно'
          break
        case 3:
          statusText = 'Транзакция отменена'
          break
        case 4:
          statusText = 'Ошибка при выполнении транзакции'
          break
        default:
          break
      }
      return statusText
    }
  }
}
