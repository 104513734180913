<template>
  <Popup :visible="visible" @close="closePopup" description="Начислить премию?" modalClass="popup--small">
    <form @submit.prevent="prizeHandler" class="popup__form">
      <div class="popup__item">
        <label for="achievement-name" class="popup__item-title popup__name">Премия(%):</label>
        <input id="achievement-name" class="popup__item-date" type="number" v-model="percentage" />
      </div>
      <div class="popup__item">
        <label for="achievement-name" class="popup__item-title popup__name">Описание:</label>
        <input class="popup__item-date" type="text" v-model="description" />
      </div>
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" type="submit">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Нет
        </button>
      </div>
    </form>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_PRIZE_OPERATORS } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { PRIZE_OPERATOR_POPUP_EVENT } from '@/constants/event'
import { GET_OPERATOR_TMP_ROLES } from '@/store/getter-types'

export default {
  name: 'PrizeOperatorPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    PRIZE_OPERATOR_POPUP_EVENT,
    operatorId: null,
    pay: null,
    percentage: null,
    description: null
  }),
  computed: {
    ...mapGetters({
      tmpFilter: GET_OPERATOR_TMP_ROLES
    })
  },

  methods: {
    ...mapActions({
      prizeOperator: REQUEST_PRIZE_OPERATORS
    }),
    closePopup() {
      this.pay = null
      this.operatorId = null
      this.percentage = null
      this.description = null
      this.close()
    },
    prizeHandler() {
      let formData = {
        data: {
          operator_id: this.operatorId,
          pay_id: this.pay,
          prize_percentage: +this.percentage,
          prize_description: this.description
        },
        active: null
      }
      if (this.tmpFilter.status.name && this.tmpFilter.status.name !== 'Все') {
        formData.active = {
          is_active: this.tmpFilter.status.value
        }
      }
      this.prizeOperator(formData)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(PRIZE_OPERATOR_POPUP_EVENT, data => {
      this.operatorId = data.operator
      this.pay = data.pay
      this.open()
    })
  }
}
</script>
<style lang="scss">
.popup__item-date {
  width: 100%;
  height: 30px;
  font-family: inherit;
  margin-bottom: 10px;
}
</style>
