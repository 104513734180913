<template>
  <div class="user-active">
    <div class="user-active__menu">
      <div class="user-active__left">
        <span class="user-active__left-text">Дата:</span>
        <div class="datepicker-traffic-source">
          <datepicker
            class="datepicker-online"
            v-model="photos_review_stats_form.date"
            input-class="datepicker__input"
            :language="ru"
          >
          </datepicker>
          <i class="select__field-icon fa fa-angle-down"></i>
        </div>
        <button class="user-active__btn" @click="geModerationPhotosStats">
          Показать
        </button>
      </div>
      <ServerTimeFilter :utcFlag="photos_review_stats_form.utc" @changeUtc="changeUtc($event)" />
    </div>
    <div class="user-gender__stats">
      <div class="user-gender__stats-item">
        <LineChart
          :chart-data="gender_stats"
          :options="gender_stats_opt"
          class="regenterstats-chart regenterstats-chart--with-pie-chart"
        >
        </LineChart>
      </div>
      <div class="user-gender__stats-item">
        <PieChart
          :chart-data="gender_stats_current"
          :options="gender_stats_current_opt"
          class="pie-chart"
        ></PieChart>
        <div class="gender-stat-charts-male-ratio">
          <div class="gender-stat-charts--wrapp">
            <div class="ratio-female">
              <i class="fas fa-female"></i>
            </div>
            <div class="ratio-sep">/</div>
            <div class="ratio-male">
              <span class="ratio-male--text">{{ gender_stats_current_male_ratio }} </span
              ><i class="fas fa-male"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from './PieChart'
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import { ru } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import 'chartjs-plugin-datalabels'
import { REQUEST_USERS_GENDER } from '@/store/action-types'
import { mapActions } from 'vuex'
import LineChart from './LineChart'
import ServerTimeFilter from '@/components/Statistic/Servertimefilter/ServerTimeFilter'
export default {
  name: 'StatisticUserGender',
  components: {
    LineChart,
    PieChart,
    Datepicker,
    ServerTimeFilter
  },
  props: {
    usersGender: {
      type: Object
    }
  },
  data: () => ({
    ru: ru,
    gender_stats_current_male_ratio: 1,
    gender_stats_current: {
      labels: ['Мужчины', 'Женщины'],
      datasets: [
        {
          backgroundColor: ['rgba(151,187,205,1)', 'rgba(255,108,96,1)'],
          data: [50, 50]
        }
      ]
    },
    gender_stats_current_opt: {
      legend: {
        display: false
      },
      plugins: {
        datalabels: {
          display: true,
          color: 'white',
          font: {
            size: 13,
            weight: 'bold'
          },
          align: 'center',
          formatter: function(value) {
            return value + '%'
          }
        }
      }
    },

    gender_stats: {
      labels: [],
      datasets: [
        {
          borderColor: 'rgba(255,108,96,1)',
          backgroundColor: 'rgba(255,108,96,0.5)',
          pointBackgroundColor: 'rgba(255,108,96,1)',
          fill: true,
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverBorderWidth: 2,
          label: 'Женщины',
          data: [],
          datalabels: {
            align: 'top',
            display: false
          }
        },
        {
          borderColor: 'rgba(151,187,205,1)',
          backgroundColor: 'rgba(151,187,205,0.5)',
          pointBackgroundColor: 'rgba(151,187,205,1)',
          fill: true,
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverBorderWidth: 2,
          label: 'Мужчины',
          data: [],
          datalabels: {
            align: 'bottom',
            display: true
          }
        }
      ]
    },
    gender_stats_opt: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              fontColor: []
            }
          }
        ]
      },
      tooltips: {
        mode: 'label',
        itemSort: function(a, b) {
          return b.datasetIndex - a.datasetIndex
        },
        enabled: true
      },
      elements: {
        point: {
          radius: 4,
          hoverRadius: 6
        }
      },
      plugins: {
        datalabels: {
          display: false,
          offset: 8,
          backgroundColor: function(context) {
            return context.dataset.backgroundColor
          },
          borderRadius: 4,
          borderColor: 'white',
          borderWidth: 1,
          color: '#fff',
          font: {
            weight: 'bold'
          }
        }
      }
    },
    photos_review_stats_form: {
      utc: 1,
      date: moment().format('YYYY-MM-DD'),
      main_photo: 0,
      deferred: 0
    }
  }),
  mounted() {
    this.reLoaded()
    this.setDefaultOnlineDate()
  },
  watch: {
    'photos_review_stats_form.utc': function() {
      this.geModerationPhotosStats()
    }
  },
  methods: {
    ...mapActions({
      getModerationPhotos: REQUEST_USERS_GENDER
    }),
    setDefaultOnlineDate: function() {
      this.photos_review_stats_form.date = moment().format('YYYY-MM-DD')
    },
    geModerationPhotosStats() {
      let newData = {
        date: moment(this.photos_review_stats_form.date).format('YYYY-MM-DD')
      }
      if (this.photos_review_stats_form.utc) newData.delta = new Date().getTimezoneOffset() / -60
      this.getModerationPhotos(newData).then(response => {
        this.reLoaded()
      })
    },
    reLoaded() {
      this.gender_stats.labels = []
      this.gender_stats.datasets[0].data = []
      this.gender_stats.datasets[1].data = []

      this.usersGender.labels.forEach(f => {
        this.gender_stats.labels.push(moment(f).format('DD.MM'))
      })
      this.gender_stats.datasets[1].data = this.usersGender.men
      this.gender_stats.datasets[0].data = this.usersGender.woman
      let sum = this.usersGender.total_men + this.usersGender.total_woman
      let man = (this.usersGender.total_men / sum) * 100
      let woman = (this.usersGender.total_woman / sum) * 100
      this.gender_stats_current.datasets[0].data = [man.toFixed(2), woman.toFixed(2)]
      this.gender_stats_current_male_ratio = (man / woman).toFixed(2)
      this.usersGender.total_men
      this.usersGender.total_woman
    },
    changeUtc(number) {
      this.photos_review_stats_form.utc = number
    }
  }
}
</script>

<style lang="scss">
@import './Statistic.scss';

.gender-stat-charts--wrapp {
  display: flex;
  align-items: center;
  width: 110px;
  justify-content: space-around;
}

.user-active__btn-group--values {
  margin-left: 10px;
}
.user-gender__stats {
  display: flex;
  align-items: center;
  width: 100%;
  &-item {
    display: flex;
    width: 90%;
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      width: 220px;
      margin-left: 10px;
    }
  }
}
.pie-chart {
  width: 220px;
}
.regenterstats-chart--with-pie-chart {
  width: 100%;
}
.gender-stat-charts-male-ratio {
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  align-items: center;
}
.ratio-male {
  display: flex;
  align-items: center;
  font-size: 38px;
  color: #57c8f2;
  text-align: center;
  &--text {
    font-size: 22px;
    margin-right: 5px;
  }
}

.ratio-female {
  font-size: 38px;
  color: #ff6c60;
}
</style>
