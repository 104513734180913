<template>
  <Popup
    :visible="visible"
    @close="closePopUp"
    description="Редактирование начисления баллов"
    modalClass="popup--small"
  >
    <form @submit.prevent="sendData" class="popup__form">
      <div class="popup__avatar-wrap">
        <div class="popup__avatar">
          <img v-if="isAvatar" :src="preview" alt="" />
          <img
            v-else-if="icon"
            :src="getFile({ type: 'image', file: icon })"
            class="chat__message-body--img"
            alt=""
          />
          <img v-else src="@/assets/img/anonymous_user.png" alt="" />
        </div>
        <div class="popup__avatar-edit">
          <input
            class="popup__avatar-edit-input"
            type="file"
            id="avatar-input"
            accept=".jpg, .jpeg, .png"
            ref="downloadavatar"
            @change="fileUploadHandler"
          />
          <label
            class="popup__avatar-edit-label"
            for="avatar-input"
            :class="{
              'avatar-invalid': !icon
            }"
          >
            <i class="fa fa-pen"></i>
          </label>
        </div>
      </div>
      <div class="popup__item">
        <label for="achievement-name" class="popup__item-title popup__name">Имя</label>
        <input
          type="text"
          name="name"
          id="achievement-name"
          class="popup__input default-input"
          v-model="title"
          required
        />
      </div>
      <div class="popup__item">
        <label for="achievement-kind" class="popup__item-title popup__kind">Kind</label>
        <input
          type="number"
          name="kind"
          id="achievement-kind"
          class="popup__input default-input"
          v-model.number="kind"
          required
        />
      </div>
      <div class="popup__item">
        <label for="achievement-points-male" class="popup__item-title popup__points"
          >Количество баллов для мужчин</label
        >
        <input
          type="number"
          name="points"
          id="achievement-points-male"
          class="popup__input default-input"
          v-model.number="pointsMale"
          required
        />
      </div>
      <div class="popup__item">
        <label for="achievement-points-female" class="popup__item-title popup__points"
          >Количество баллов для женщин</label
        >
        <input
          type="number"
          name="points"
          id="achievement-points-female"
          class="popup__input default-input"
          v-model.number="pointsFemale"
          required
        />
      </div>
      <div class="popup__item">
        <label for="achievement-points-twogender" class="popup__item-title popup__points"
          >Количество баллов для обоих полов</label
        >
        <input
          type="number"
          name="points"
          id="achievement-points-twogender"
          class="popup__input default-input"
          v-model.number="twoGender"
        />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--center">
          <button type="submit" class="popup__btn btn-green">Сохранить</button>
          <button @click="closePopUp" class="popup__btn btn-red">Отмена</button>
        </div>
      </div>
    </form>
  </Popup>
</template>

<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import getImgLink from '@/components/mixins/getImgCdnLink'
import { POINTS_POPUP_EVENT } from '@/constants/event'
import { REQUEST_UPDATE_CAUSE_POINTS, REQUEST_ADD_CAUSE_POINTS } from '@/store/action-types'
import { mapActions, mapGetters } from 'vuex'
import { GET_CURRENT_LANGUAGES } from '@/store/getter-types'
export default {
  name: 'CausePointsEditPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin, getImgLink],
  data() {
    return {
      title: '',
      pointsMale: '',
      pointsFemale: '',
      twoGender: '',
      isAvatar: false,
      preview: null,
      icon: null,
      idMale: '',
      idFemale: '',
      idTwoGender: '',
      kind: ''
    }
  },
  computed: {
    ...mapGetters({
      lang: GET_CURRENT_LANGUAGES
    })
  },
  mounted() {
    this.$root.$on(POINTS_POPUP_EVENT, eventcat => {
      this.title = eventcat.point.title
      this.pointsMale = eventcat.filterMalePoint[0].price
      this.pointsFemale = eventcat.filterFemalePoint[0].price
      if (eventcat && eventcat.filterTwoGenderPoint[0] && eventcat.filterTwoGenderPoint[0].price) {
        this.twoGender = eventcat.filterTwoGenderPoint[0].price
      }
      this.idMale = eventcat.filterMalePoint[0].id
      this.idFemale = eventcat.filterFemalePoint[0].id
      if (eventcat && eventcat.filterTwoGenderPoint[0] && eventcat.filterTwoGenderPoint[0].id) {
        this.idTwoGender = eventcat.filterTwoGenderPoint[0].id
      }
      this.icon = eventcat.point.icon
      this.kind = eventcat.point.kind
      this.open()
    })
  },
  methods: {
    ...mapActions({
      updateCausePoints: REQUEST_UPDATE_CAUSE_POINTS,
      addCausePoints: REQUEST_ADD_CAUSE_POINTS
    }),

    fileUploadHandler() {
      const avatar = this.$refs.downloadavatar.files[0]
      this.preview = URL.createObjectURL(avatar)
      this.isAvatar = true
      this.icon = avatar
    },

    sendData() {
      const formDataMale = new FormData()
      formDataMale.append('id', this.idMale)
      formDataMale.append('lang', this.lang)
      formDataMale.append('title', this.title)
      formDataMale.append('description', null)
      formDataMale.append('price', Number(this.pointsMale))
      formDataMale.append('gender', 1)
      formDataMale.append('kind', this.kind)
      if (this.icon) {
        formDataMale.append('icon', this.icon)
      }
      const formDataFemale = new FormData()
      formDataFemale.append('id', this.idFemale)
      formDataFemale.append('lang', this.lang)
      formDataFemale.append('title', this.title)
      formDataFemale.append('description', this.description)
      formDataFemale.append('price', Number(this.pointsFemale))
      formDataFemale.append('gender', 2)
      formDataFemale.append('kind', this.kind)
      if (this.icon) {
        formDataFemale.append('icon', this.icon)
      }
      const formDataTwoGender = new FormData()
      formDataTwoGender.append('id', this.idTwoGender)
      formDataTwoGender.append('lang', this.lang)
      formDataTwoGender.append('title', this.title)
      formDataTwoGender.append('description', this.description)
      formDataTwoGender.append('price', this.twoGender)
      formDataTwoGender.append('icon', this.icon)
      formDataTwoGender.append('gender', 0)
      formDataTwoGender.append('kind', this.kind)
      if (this.icon) {
        formDataTwoGender.append('icon', this.icon)
      }
      this.updateCausePoints(formDataMale)
      this.updateCausePoints(formDataFemale)
      if (this.idTwoGender) {
        this.updateCausePoints(formDataTwoGender)
      } else {
        this.addCausePoints(formDataTwoGender)
      }
      this.closePopUp()
    },
    closePopUp() {
      this.title = ''
      this.pointsMale = ''
      this.pointsFemale = ''
      this.twoGender = ''
      this.kind = ''
      this.isAvatar = false
      this.preview = null
      this.icon = null
      this.close()
    }
  }
}
</script>
<style lang="scss"></style>
