<template>
  <div class="payments-time">
    <div class="payments-time__wrapper">
      <div @click="buttonPeriodClickHandler" class="payments-time__btn-group" v-if="button_period.length > 0">
        <button
          v-for="button in button_period"
          :key="button.id"
          :data-id="button.id"
          :class="[button.active ? 'btn btn-danger' : 'btn', button.class ? button.class : '']"
        >
          {{ button.title }}
        </button>
        <button class="btn-primary" data-type="period">Период</button>
      </div>
      <div class="datepicker-traffic-source">
        <i class="select__field-icon fa fa-angle-down"></i>
        <DateRangePicker
          :locale-data="picker.localeData"
          :opens="picker.localeData.position"
          :close-on-esc="true"
          :autoApply="true"
          :ranges="false"
          ref="traffic"
          v-model="datePickerControlTraffic"
        >
        </DateRangePicker>
      </div>
    </div>
    <div>
      <LineChart :chart-data="payments_stats" :options="payments_stats_opt" class="regenterstats-chart">
      </LineChart>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import 'chartjs-plugin-datalabels'
import LineChart from '@/components/Statistic/LineChart'
import { mapActions } from 'vuex'
import { REQUEST_USER_LIFETIME } from '@/store/action-types'

export default {
  name: 'StatisticLifetime',
  components: {
    LineChart,
    DateRangePicker
  },
  props: {
    usersLifetime: {
      type: Object
    }
  },
  data: () => ({
    payments_country_loading: false,
    payments_country_form: {
      start_date: '',
      end_date: ''
    },
    button_period: [
      {
        id: 1,
        class: 'btn-default',
        title: '1 мес.',
        active: 0,
        date: moment()
          .subtract(1, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 2,
        class: 'btn-default',
        title: '2 мес.',
        active: 0,
        date: moment()
          .subtract(2, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 3,
        class: 'btn-default',
        title: '3 мес.',
        active: 1,
        date: moment()
          .subtract(3, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 4,
        class: 'btn-default',
        title: '4 мес.',
        active: 0,
        date: moment()
          .subtract(4, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 5,
        class: 'btn-default',
        title: '5 мес.',
        active: 0,
        date: moment()
          .subtract(5, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 6,
        class: 'btn-default',
        title: '6 мес.',
        active: 0,
        date: moment()
          .subtract(6, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 7,
        class: 'btn-white',
        title: 'Всё время',
        active: 0,
        date: moment()
          .subtract(6, 'months')
          .startOf('day')
      }
    ],
    picker: {
      localeData: {
        position: 'right',
        format: 'dd/mm/yyyy',
        separator: ' - ',
        applyLabel: 'Принять',
        cancelLabel: 'Отменить',
        fromLabel: 'От',
        toLabel: 'До',
        customRangeLabel: 'Custom',
        weekLabel: 'W',
        firstDay: 1,
        daysOfWeek: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        monthNames: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь'
        ]
      }
    },
    datepicker_dates: {
      date_from: moment()
        .subtract(3, 'months')
        .startOf('day')
        .toString(),
      date_to: moment()
        .endOf('day')
        .toString()
    },
    payments_stats: {
      labels: [],
      datasets: [
        {
          borderColor: '#ff6c60',
          backgroundColor: 'rgba(255, 108, 96, 0.5)',
          pointBackgroundColor: '#ff6c60',
          fill: true,
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverBorderWidth: 2,
          label: 'Зарегистрировано',
          data: [],
          datalabels: {
            align: 'top',
            display: true
          }
        },
        {
          borderColor: '#57c8f2',
          backgroundColor: 'rgba(87, 200, 242, 0.5)',
          pointBackgroundColor: '#97bbcd',
          fill: true,
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverBorderWidth: 2,
          label: 'Вошло (из числа зарегистрированных за период)',
          data: [],
          datalabels: {
            align: 'top',
            display: true
          }
        }
      ]
    },
    payments_stats_opt: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true
      },
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMax: 10,
              beginAtZero: true,
              fontColor: '#000'
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              fontColor: [],
              maxRotation: 360,
              minRotation: 360
            }
          }
        ]
      },
      tooltips: {
        enabled: true,
        callbacks: {
          title: (tooltipItem, data) => {
            return `День: ${tooltipItem[0].index}`
            //return moment(this.datepicker_dates.date_from).add(tooltipItem[0].index, 'days').format('YYYY-MM-DD');
          },
          label: function(tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || ''

            if (label) {
              label += ': '
            }
            label += Math.round(tooltipItem.yLabel * 100) / 100
            return label
          }
        }
      },
      elements: {
        point: {
          radius: 4,
          hoverRadius: 6
        }
      },
      plugins: {
        datalabels: {
          display: false,
          formatter: value => ''
        }
      }
    }
  }),

  mounted() {
    this.getTimeLabels()
  },

  computed: {
    datePickerControlTraffic: {
      get() {
        const date = {
          startDate: this.datepicker_dates.date_from,
          endDate: this.datepicker_dates.date_to
        }
        return date
      },
      set({ startDate, endDate }) {
        this.datepicker_dates.date_from = new Date(startDate)
        this.datepicker_dates.date_to = new Date(endDate)
        this.payments_country_form.start_date = this.datepicker_dates.date_from
        this.payments_country_form.end_date = this.datepicker_dates.date_to
      }
    }
  },
  methods: {
    ...mapActions({
      getPaymentByTime: REQUEST_USER_LIFETIME
    }),

    getTimeLabels() {
      const start_date = moment(this.datepicker_dates.date_from)
      const end_date = moment(this.datepicker_dates.date_to)

      let marks = []
      let registered = []
      let entered = []

      let number_day = 0

      while (start_date.unix() < end_date.unix()) {
        const date_info = this.usersLifetime[start_date.format('YYYY-MM-DD')]
        marks.push(number_day % 7 == 0 ? number_day : '')
        registered.push(date_info && date_info.count_registered ? date_info.count_registered : 0)
        entered.push(date_info && date_info.count_entered ? date_info.count_entered : 0)
        start_date.add(1, 'days')
        number_day += 1
      }

      this.payments_stats.labels = marks
      this.payments_stats.datasets[0].data = registered
      this.payments_stats.datasets[1].data = entered
    },
    buttonPeriodClickHandler(event) {
      if (event.target.tagName.toLowerCase() !== 'button') {
        return false
      }
      this.button_period.map(el => {
        el.active = 0
        return el
      })
      if (event.target.dataset.type) {
        this.getData()
        return
      }
      if (!event.target.dataset.id) {
        return false
      }
      const button = this.button_period.find(el => el.id === +event.target.dataset.id)
      if (button.active) {
        return false
      }
      button.active = 1
      this.datepicker_dates.date_from = button.date.toLocaleString()
      this.datepicker_dates.date_to = button.date_to
        ? button.date_to.toLocaleString()
        : moment()
            .endOf('day')
            .toLocaleString()
      this.getData()
    },
    getData() {
      let dateThreeMounth = {
        date_from: moment(this.datepicker_dates.date_from).format('YYYY-MM-DD'),
        date_to: moment(this.datepicker_dates.date_to).format('YYYY-MM-DD')
      }
      this.getPaymentByTime(dateThreeMounth).then(response => {
        this.getTimeLabels()
      })
    }
  }
}
</script>

<style lang="scss">
@import './Statistic.scss';
.payments-time {
  padding: 16px;
}
.payments-time__wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.payments-time__input {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  background-color: #41cac0;
  border-color: #41cac0;
  color: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  &--left {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &--right {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.btn-sum-active {
  background-color: #ff6c60;
  color: #fff;
}

.btn-default {
  background-color: #bec3c7;
  border-color: #bec3c7;
  color: #fff;
}

.btn-danger {
  background-color: #ff6c60;
  border-color: #ff6c60;
  color: #fff;
}

.btn-white {
  background-clip: padding-box;
  border-color: rgba(150, 160, 180, 0.3);
  background-color: #fff;
}

.btn-count-active {
  background-color: #57c8f2;
  color: #fff;
}

.btn-primary {
  border-radius: 4px;
  background-color: #41cac0;
  border-color: #41cac0;
  color: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn.btn-danger.btn-white {
  color: #2a3542;
}
</style>
