import * as GetterTypes from './getter-types'
export default {
  [GetterTypes.GET_MODERATORS_RATING]: ({ rating }) => rating,

  [GetterTypes.GET_MODERATOR_SETTINGS_SPEED]: ({ ratingSettingSpeed }) => ratingSettingSpeed,

  [GetterTypes.GET_MODERATOR_SETTINGS_QUALITY]: ({ ratingSettingQuality }) => ratingSettingQuality,

  [GetterTypes.GET_MODERATOR_RATING_ROLES]: ({ moderatorRoles }) => moderatorRoles,

  [GetterTypes.GET_MODERATOR_ROLES_RATING_DEFAULT]: ({ moderatorRolesDefault }) => moderatorRolesDefault,

  [GetterTypes.GET_MODERATOR_RATING_TMP_ROLES]: ({ moderatorTmpRoles }) => moderatorTmpRoles
}
