<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Откорректировать сумму?"
    modalClass="popup--small"
  >
    <form @submit.prevent="correctionHandler" class="popup__form">
      <div class="popup__item">
        <label for="achievement-name" class="popup__item-title popup__name">Сумма коррекции:</label>
        <input id="achievement-name" class="popup__item-date" type="number" v-model="correctionSum" />
      </div>
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" type="submit">
          Да
        </button>
        <button class="popup__btn btn-red" @click.prevent="closePopup">
          Нет
        </button>
      </div>
    </form>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_CORRECTION_MODERATORS } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { CORRECTION_MODERATOR_POPUP_EVENT } from '@/constants/event'
import { GET_MODERATOR_TMP_ROLES } from '@/store/getter-types'

export default {
  name: 'CorrectionModeratorPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    CORRECTION_MODERATOR_POPUP_EVENT,
    pay: null,
    correctionSum: null
  }),
  computed: {
    ...mapGetters({
      tmpFilter: GET_MODERATOR_TMP_ROLES
    })
  },

  methods: {
    ...mapActions({
      correctionModerator: REQUEST_CORRECTION_MODERATORS
    }),
    closePopup() {
      this.pay = null
      this.correctionSum = null
      this.close()
    },
    correctionHandler() {
      let formData = {
        data: {
          pay_history_id: this.pay,
          correction_sum: +this.correctionSum
        },
        active: null
      }
      if (this.tmpFilter.status.name && this.tmpFilter.status.name !== 'Все') {
        formData.active = {
          is_active: this.tmpFilter.status.value
        }
      }
      this.correctionModerator(formData)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(CORRECTION_MODERATOR_POPUP_EVENT, data => {
      this.pay = data.pay
      this.open()
    })
  }
}
</script>
<style lang="scss">
.popup__item-date {
  width: 100%;
  height: 31px;
  font-family: inherit;
  margin-bottom: 10px;
}
</style>
