<template>
  <div class="panel">
    <span>Страница Панели</span>
  </div>
</template>
<script>
export default {
  name: 'Suportpanel'
}
</script>
