import * as ActionTypes from './actions-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import FaqService from '@/services/FaqService'

export default {
  /* Category */
  [ActionTypes.REQUEST_ALL_CATEGORY_BY_LANG]: async ({ rootGetters, commit, dispatch }, lang = 'RU') => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(FaqService, [userToken]).getAllCatagoryByLang(lang)

    const { data } = response

    if (data) {
      commit(MutationTypes.SET_ALL_CATEGORY_BY_LANG, data)

      if (data[0]) {
        commit(MutationTypes.SET_CURRENT_CONTENT_ID, data[0].id)
        commit(MutationTypes.SET_CURRENT_ITEM_ACTIVE, 0)
        await dispatch(ActionTypes.REQUEST_CONTENT_BY_ID, data[0].id)
      } else {
        commit(MutationTypes.SET_CURRENT_CONTENT_ID, '')
        commit(MutationTypes.SET_CONTENT_BY_ID, [])
      }
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_CREATE_NEW_CATEGORY]: async ({ rootGetters, commit, dispatch }, newCategory) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(FaqService, [userToken]).createCategory(newCategory)

    const { status } = response
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    if (status) {
      await dispatch(ActionTypes.REQUEST_CATEGORY_BY_CURRENT_LANG, lang)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_CATEGORY_BY_CURRENT_LANG]: async ({ rootGetters, commit, dispatch }, lang = 'RU') => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(FaqService, [userToken]).getAllCatagoryByLang(lang)
    const { data } = response
    if (data) {
      let current = null
      if (data.length) {
        current = data.find(f => f.id === rootGetters['faq/GET_CURRENT_CONTENT_ID'])
      }
      commit(MutationTypes.SET_ALL_CATEGORY_BY_LANG, data)
      if (data.length === 1 || (!current && data.length)) {
        await dispatch(ActionTypes.REQUEST_CONTENT_BY_ID, data[0].id)
        commit(MutationTypes.SET_CURRENT_CONTENT_ID, data[0].id)
        commit(MutationTypes.SET_CURRENT_ITEM_ACTIVE, 0)
      } else if (!data.length) {
        commit(MutationTypes.SET_CURRENT_CONTENT_ID, '')
        commit(MutationTypes.SET_CONTENT_BY_ID, [])
      }
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_DELETE_CATEGORY_BY_ID]: async ({ rootGetters, commit, dispatch }, categoryId) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(FaqService, [userToken]).deleteCategoryById(categoryId)
    const { status } = response
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    if (status) {
      await dispatch(ActionTypes.REQUEST_CATEGORY_BY_CURRENT_LANG, lang)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_UPDATE_CATEGORY_BY_ID]: async ({ rootGetters, commit, dispatch }, categoryData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(FaqService, [userToken]).updateCategoryById(categoryData)
    const { status } = response
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    if (status) {
      await dispatch(ActionTypes.REQUEST_CATEGORY_BY_CURRENT_LANG, lang)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  /* Content */
  [ActionTypes.REQUEST_CONTENT_BY_ID]: async ({ rootGetters, commit }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(FaqService, [userToken]).getAllContentById(id)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_CONTENT_BY_ID, data)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_ADD_NEW_CONTENT_BY_CATEGORY]: async (
    { rootGetters, commit, dispatch },
    newCategoryData
  ) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(FaqService, [userToken]).createContent(newCategoryData.data)

    const { status } = response

    if (status) {
      await dispatch(ActionTypes.REQUEST_CONTENT_BY_ID, newCategoryData.id)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_DATELE_QUESTION_BY_ID]: async ({ rootGetters, commit, dispatch }, dataId) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(FaqService, [userToken]).deleteContentById(dataId.questionId)
    const { status } = response

    if (status) {
      await dispatch(ActionTypes.REQUEST_CONTENT_BY_ID, dataId.categoryId)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_UPDATE_QUESTION_BY_ID]: async ({ rootGetters, commit, dispatch }, questionData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(FaqService, [userToken]).updateContentById(questionData)
    const { status } = response

    if (status) {
      await dispatch(ActionTypes.REQUEST_CONTENT_BY_ID, questionData.contentId)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  }
}
