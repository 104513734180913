export default {
  data: () => ({
    picker: {
      localeData: {
        position: 'right',
        format: 'dd/mm/yyyy',
        separator: ' - ',
        applyLabel: 'Принять',
        cancelLabel: 'Отменить',
        fromLabel: 'От',
        toLabel: 'До',
        customRangeLabel: 'Custom',
        weekLabel: 'W',
        firstDay: 1,
        daysOfWeek: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        monthNames: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь'
        ]
      },
      events: {
        'hover-date': function(params) {
          console.log(params)
        }
      }
    }
  })
}
