<template>
  <div class="event-categories-wrap">
    <div class="ev-cat">
      <EventCategoryHeader />
      <div class="ev-cat__list" v-if="isLoaded && getAllCategories.length">
        <EventCategory v-for="eventcat in getAllCategories" :key="eventcat.id" :eventcat="eventcat" />
      </div>
      <div v-if="!getAllCategories.length && isLoaded" class="ev-cat__error">
        <span class="ev-cat__error-text">Список категорий пуст. Добавьте категории</span>
      </div>
      <div v-if="errorMessage" class="ev-cat__error">
        <span class="ev-cat__error-text">Ошибка при загрузке категорий!</span>
      </div>
    </div>
    <EventCategoryAddPopup />
    <EventCategoryNamePopup />
    <EventCategoryImagePopup />
    <EventSubCategoryAddPopup />
    <EventSubCategoryNamePopup />
    <EventSubCategoryImagePopup />
    <EventCategoryDeletePopup />
    <EventSubCategoryDeletePopup />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types'
import { GET_CATEGORIES_BY_LANG, GET_CURRENT_LANGUAGES } from '@/store/getter-types'
import { REQUEST_ALL_CATEGORIES } from '@/store/action-types'
import EventCategoryHeader from '@/components/Events/EventCategoryHeader'
import EventCategory from '@/components/Events/EventCategory'
import EventCategoryAddPopup from '@/components/Modals/EventPopup/EventCategoryAddPopup'
import EventSubCategoryAddPopup from '@/components/Modals/EventPopup/EventSubCategoryAddPopup'
import EventCategoryNamePopup from '@/components/Modals/EventPopup/EventCategoryNamePopup'
import EventSubCategoryNamePopup from '@/components/Modals/EventPopup/EventSubCategoryNamePopup'
import EventCategoryImagePopup from '@/components/Modals/EventPopup/EventCategoryImagePopup'
import EventSubCategoryImagePopup from '@/components/Modals/EventPopup/EventSubCategoryImagePopup'
import EventCategoryDeletePopup from '@/components/Modals/EventPopup/EventCategoryDeletePopup'
import EventSubCategoryDeletePopup from '@/components/Modals/EventPopup/EventSubCategoryDeletePopup'

import store from '@/store'
export default {
  name: 'EventsCategory',
  components: {
    EventCategoryHeader,
    EventCategory,
    EventCategoryAddPopup,
    EventSubCategoryAddPopup,
    EventCategoryNamePopup,
    EventSubCategoryNamePopup,
    EventCategoryImagePopup,
    EventSubCategoryImagePopup,
    EventCategoryDeletePopup,
    EventSubCategoryDeletePopup
  },
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_ALL_CATEGORIES).then(response => {
      next(vm => {
        if (response) {
          vm.setCurrentLang('RU')
        }
        if (!response) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      getAllCategories: GET_CATEGORIES_BY_LANG,
      currentLang: GET_CURRENT_LANGUAGES
    })
  },
  methods: {
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    })
  },

  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
.event-categories-wrap {
  padding: 16px;
  max-width: 1172px;
  margin: 0 auto;
}
.ev-cat {
  &__error {
    min-height: 64px;
    border-radius: 2px;

    position: relative;
    display: flex;
    align-items: center;
    background-color: white;
  }
  &__error-text {
    margin: 0 auto;
  }
}
@import '@/components/Events/EventCategory.scss';
</style>
