<template>
  <div class="gifts-header">
    <h1 class="gifts__title">Подарки</h1>
    <button type="button" class="gifts__btn-add" @click="$root.$emit(GIFT_ADD_POPUP_EVENT)">
      <i class="gifts__new-gift fa fa-plus-square"></i>
      <span class="gifts__button-text">Добавить</span>
    </button>
  </div>
</template>
<script>
import { GIFT_ADD_POPUP_EVENT } from '@/constants/event'
export default {
  name: 'GiftsHeader',

  data() {
    return {
      GIFT_ADD_POPUP_EVENT
    }
  }
}
</script>
<style lang="scss">
@import './GiftsHeader.scss';
</style>
