<template>
  <section ref="filtersWrap" class="filters-user-wrap">
    <div
      class="filters app-users-filters"
      ref="mobilefilters"
      v-click-outside="close"
      :class="{ opened: mobileFilterOpen }"
    >
      <div class="filters__mobile-nav">
        <button type="button" class="filters__link" @click="mobileFilterOpen = !mobileFilterOpen">
          <i class="filters__icon fa fa-filter"></i>
          <span class="filters__button-text">Фильтры</span>
        </button>
        <button v-if="isMobile && windowTop > 200" @click="toTop" class="btn-to-top--mobile">
          <FaIcon :iconClass="'fas fa-arrow-up'" />
        </button>
      </div>
      <form class="filters__items--user" @submit.prevent="applyUserFiltersHanlder">
        <div class="filters__wrapper">
          <div class="filters__group">
            <div class="filters__item">
              <span class="filters__item-title">ID:</span>
              <input
                type="text"
                class="filters__input filters__input--id"
                v-model="userID"
                ref="userid"
                @click="mouseLeave"
              />
            </div>
            <div class="filters__item">
              <span class="filters__item-title">Email:</span>
              <input
                type="email"
                class="filters__input"
                v-model="filterEmail"
                ref="filteremail"
                @click="mouseLeave"
              />
            </div>
          </div>
          <div class="filters__group">
            <div class="filters__item">
              <span class="filters__item-title">Имя:</span>
              <input
                type="text"
                class="filters__input"
                v-model="filterName"
                ref="filtername"
                @click="mouseLeave"
              />
            </div>
            <div class="filters__item">
              <span class="filters__item-title">Возраст:</span>
              <input
                type="number"
                class="filters__input filters__input--age"
                placeholder="от"
                v-model="ageStart"
                min="18"
                ref="agestart"
                :required="isAgeMax"
                @click="mouseLeave"
              />

              <input
                type="number"
                class="filters__input filters__input--age"
                placeholder="до"
                v-model="ageEnd"
                min="18"
                ref="ageend"
                :required="isAgeMin"
                @click="mouseLeave"
              />
            </div>
          </div>
          <div class="filters__group">
            <div class="filters__item">
              <span class="filters__item-title">Город:</span>
              <input
                type="text"
                class="filters__input"
                v-model.trim="filterCity"
                ref="filtercity"
                @click="mouseLeave"
              />
            </div>
            <div class="filters__item">
              <!--              <span class="filters__item-title">Страна:</span>-->
              <div class="select">
                <span class="select__title">Страна:</span>
                <div class="select__body" v-tooltip="defaultFilter({ key: 'country' })">
                  <button type="button" class="select__field" @click="clickClose('country')">
                    <!--                    @click="osSelectOpen = !osSelectOpen"-->
                    <span class="select__field-value">{{ defaultFilter({ key: 'country' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul
                    class="select__list select__list--country"
                    v-if="countrySelectOpen"
                    @mouseleave="mouseLeave"
                  >
                    <li
                      class="select__item"
                      v-for="country in usersHeader.country"
                      :key="country.id"
                      @click="
                        selectFilterBuilder({
                          key: 'country',
                          value: country
                        })
                      "
                    >
                      <span class="select__text">{{ country.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!--              <input-->
              <!--                  type="text"-->
              <!--                  class="filters__input"-->
              <!--                  v-model.trim="filterCountry"-->
              <!--                  ref="filtercountry"-->
              <!--                  @click="mouseLeave"-->
              <!--              />-->
            </div>
          </div>
          <div class="filters__group">
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Пол:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('gender')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'gender' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="genderSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="gender in usersHeader.gender"
                      :key="gender.id"
                      @click="
                        selectFilterBuilder({
                          key: 'gender',
                          value: gender
                        })
                      "
                    >
                      <span class="select__text"> {{ gender.name }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Устройство:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('os')">
                    <!--                    @click="osSelectOpen = !osSelectOpen"-->
                    <span class="select__field-value">{{ defaultFilter({ key: 'os' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="osSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="os in usersHeader.os"
                      :key="os.id"
                      @click="
                        selectFilterBuilder({
                          key: 'os',
                          value: os
                        })
                      "
                    >
                      <span class="select__text">{{ os.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="filters__group">
            <div class="filters__item">
              <div class="select">
                <span class="select__title">VIP:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('vip')">
                    <!--                    @click="vipSelectOpen = !vipSelectOpen"-->
                    <span class="select__field-value">{{ defaultFilter({ key: 'vip' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="vipSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="vip in usersHeader.vip"
                      :key="vip.id"
                      @click="
                        selectFilterBuilder({
                          key: 'vip',
                          value: vip
                        })
                      "
                    >
                      <span class="select__text">{{ vip.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Online:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('online')">
                    <!--                    @click="mouseLeave(), onlineSelectOpen = !onlineSelectOpen"-->
                    <span class="select__field-value">{{ defaultFilter({ key: 'online' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="onlineSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="online in usersHeader.online"
                      :key="online.id"
                      @click="
                        selectFilterBuilder({
                          key: 'online',
                          value: online
                        })
                      "
                    >
                      <span class="select__text">{{ online.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="filters__group filters__group--dates">
            <div class="filters__item">
              <div class="calendar-select" @click="mouseLeave()">
                <span class="select__title">Регистрация:</span>
                <i class="select__field-icon fa fa-angle-down"></i>
                <DateRangePicker
                  :locale-data="picker.localeData"
                  :opens="picker.localeData.position"
                  :close-on-esc="true"
                  :autoApply="true"
                  :ranges="false"
                  ref="pickerreg"
                  v-model="datePickerControlreg"
                />
              </div>
            </div>
          </div>
          <div class="filters__group filters__group--dates">
            <div class="filters__item">
              <div class="calendar-select" @click="mouseLeave()">
                <span class="select__title">Вход:</span>
                <i class="select__field-icon fa fa-angle-down"></i>
                <DateRangePicker
                  :locale-data="picker.localeData"
                  :opens="picker.localeData.position"
                  :close-on-esc="true"
                  :autoApply="true"
                  :ranges="false"
                  ref="pickerenter"
                  v-model="datePickerControlenter"
                />
              </div>
            </div>
          </div>
          <div class="filters__group filters__group--dates">
            <div class="filters__item">
              <div class="calendar-select" @click="mouseLeave()">
                <span class="select__title">Платеж:</span>
                <i class="select__field-icon fa fa-angle-down"></i>
                <DateRangePicker
                  :locale-data="picker.localeData"
                  :opens="picker.localeData.position"
                  :close-on-esc="true"
                  :autoApply="true"
                  :ranges="false"
                  ref="pickerpayment"
                  v-model="datePickerControlpayment"
                  @click="mouseLeave"
                  @hoverDate="logEvent($event)"
                />
              </div>
            </div>
          </div>
          <div class="filters__group">
            <div class="filters__item">
              <div class="select">
                <span class="select__title">UTM:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('utm')">
                    <!--                    @click="utmSelectOpen = !utmSelectOpen"-->
                    <span class="select__field-value">{{ defaultFilter({ key: 'utm' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="utmSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="utmItem in usersHeader.utm"
                      :key="utmItem.id"
                      @click="
                        selectFilterBuilder({
                          key: 'utm',
                          value: utmItem
                        })
                      "
                    >
                      <span class="select__text">{{ utmItem.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Бан:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('ban')">
                    <!--                    @click="banSelectOpen = !banSelectOpen"-->
                    <span class="select__field-value">{{ defaultFilter({ key: 'ban' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="banSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="ban in usersHeader.ban"
                      :key="ban.id"
                      @click="
                        selectFilterBuilder({
                          key: 'ban',
                          value: ban
                        })
                      "
                    >
                      <span class="select__text">{{ ban.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="filters__group">
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Верификация:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('verificate')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'verificate' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="verificateSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="verificate in usersHeader.verificate"
                      :key="verificate.id"
                      @click="
                        selectFilterBuilder({
                          key: 'verificate',
                          value: verificate
                        })
                      "
                    >
                      <span class="select__text">{{ verificate.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="filters__item filters__item--sort">
              <div class="select">
                <span class="select__title">Сортировка:</span>
                <div class="select__body">
                  <button
                    type="button"
                    class="select__field select__field--sort"
                    @click="clickClose('sort', 'desc')"
                  >
                    <span class="select__field-value">{{ defaultFilter({ key: 'sort' }) }}</span>
                    <!-- <span v-if="sortOrder.length" class="select__field-value"
                      >Выбрано: {{ sortOrder.length }}</span
                    > -->
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list select__list--sort" v-if="sortSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="sort in usersHeader.sort"
                      :key="sort.id"
                      @click="
                        selectFilterBuilder({
                          key: 'sort',
                          value: sort
                        })
                      "
                    >
                      <!-- <input
                        ref="inputSort"
                        :id="sort.id"
                        :value="sort.value"
                        class="select__input"
                        type="checkbox"
                        v-model="sortOrder"
                        @change="
                          filterDisabled({
                            key: sort,
                            ref: 'inputSort',
                            item: 'sort'
                          })
                        "
                      />
                      <label :for="sort.id" class="select__text">{{
                        sort.name
                      }}</label> -->
                      <span class="select__text">{{ sort.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="filters__group">
            <div class="filters__item filters__item--status">
              <div class="select">
                <span class="select__title">Статус фото:</span>
                <div class="select__body">
                  <button
                    type="button"
                    class="select__field select__field--status"
                    @click="clickClose('statusPhoto')"
                  >
                    <span class="select__field-value"
                      >{{ defaultFilter({ key: 'statusPhoto' }) }}
                      <FaIcon
                        v-show="
                          tmpFilter.statusPhoto.value !== '' &&
                            Object.keys(tmpFilter.statusPhoto).length !== 0
                        "
                        v-bind:class="{
                          'filter_icon filter_icon__photo-clock': tmpFilter.statusPhoto.value === -1,
                          'filter_icon filter_icon__photo-wait': tmpFilter.statusPhoto.value === 0,
                          'filter_icon filter_icon__photo-verificate': tmpFilter.statusPhoto.value === 1,
                          'filter_icon filter_icon__photo-times': tmpFilter.statusPhoto.value === 2,
                          'filter_icon filter_icon__photo-slash': tmpFilter.statusPhoto.value === 3,
                          'filter_icon filter_icon__photo-triangle': tmpFilter.statusPhoto.value === 4,
                          'filter_icon filter_icon__photo-banned': tmpFilter.statusPhoto.value === 5,
                          'filter_icon filter_icon__photo-baby': tmpFilter.statusPhoto.value === 6
                        }"
                        :iconClass="`${tmpFilter.statusPhoto.icon}`"
                    /></span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul
                    class="select__list select__list--status"
                    v-if="statusPhotoSelectOpen"
                    @mouseleave="mouseLeave"
                  >
                    <li
                      class="select__item"
                      v-for="status in usersHeader.statusPhoto"
                      :key="status.id"
                      @click="
                        selectFilterBuilder({
                          key: 'statusPhoto',
                          value: status
                        })
                      "
                    >
                      <span class="select__text"
                        >{{ status.name }}
                        <FaIcon
                          v-if="status.name !== 'Неважно'"
                          v-bind:class="{
                            'filter_icon filter_icon__photo-clock': status.value === -1,
                            'filter_icon filter_icon__photo-wait': status.value === 0,
                            'filter_icon filter_icon__photo-verificate': status.value === 1,
                            'filter_icon filter_icon__photo-times': status.value === 2,
                            'filter_icon filter_icon__photo-slash': status.value === 3,
                            'filter_icon filter_icon__photo-triangle': status.value === 4,
                            'filter_icon filter_icon__photo-banned': status.value === 5,
                            'filter_icon filter_icon__photo-baby': status.value === 6
                          }"
                          :iconClass="`${status.icon}`"
                      /></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Удален:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('userDeleted')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'userDeleted' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="userDeletedSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="userDeleted in usersHeader.userDeleted"
                      :key="userDeleted.id"
                      @click="
                        selectFilterBuilder({
                          key: 'userDeleted',
                          value: userDeleted
                        })
                      "
                    >
                      <span class="select__text"> {{ userDeleted.name }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- TODO Убрать после тестов -->
          <!-- <div class="filters__group">
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Удален:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('userDeleted')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'userDeleted' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="userDeletedSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="userDeleted in usersHeader.userDeleted"
                      :key="userDeleted.id"
                      @click="
                        selectFilterBuilder({
                          key: 'userDeleted',
                          value: userDeleted
                        })
                      "
                    >
                      <span class="select__text"> {{ userDeleted.name }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->

          <div class="filters__group">
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Оператор:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('operator')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'operator' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="operatorSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="operator in usersHeader.operator"
                      :key="operator.id"
                      @click="
                        selectFilterBuilder({
                          key: 'operator',
                          value: operator
                        })
                      "
                    >
                      <span class="select__text"> {{ operator.name }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Чат с оператором:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('chatWithOperator')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'chatWithOperator' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="chatWithOperatorSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="isChat in usersHeader.chatWithOperator"
                      :key="isChat.id"
                      @click="
                        selectFilterBuilder({
                          key: 'chatWithOperator',
                          value: isChat
                        })
                      "
                    >
                      <span class="select__text"> {{ isChat.name }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- TODO Убрать после тестов -->
          <!-- <div class="filters__group">
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Чат с оператором:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('chatWithOperator')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'chatWithOperator' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="chatWithOperatorSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="isChat in usersHeader.chatWithOperator"
                      :key="isChat.id"
                      @click="
                        selectFilterBuilder({
                          key: 'chatWithOperator',
                          value: isChat
                        })
                      "
                    >
                      <span class="select__text"> {{ isChat.name }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->

          <div class="filters__group">
            <div class="filters__item filters__item--social">
              <input
                id="vk"
                class="filters__input--social"
                type="checkbox"
                v-model="filters.user_vkontakte"
                @click="mouseLeave"
              />
              <label for="vk" class="filters__label--social"><i class="fab fa-vk fa-lg"></i></label>
            </div>
            <div class="filters__item filters__item--social">
              <input
                id="fb"
                class="filters__input--social"
                type="checkbox"
                v-model="filters.user_facebook"
                @click="mouseLeave"
              />
              <label for="fb" class="filters__label--social"><i class="fab fa-facebook-f fa-lg"></i></label>
            </div>
          </div>

          <div class="filters__group">
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Автознакомства:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('autodating')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'autodating' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="autodatingSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="autodating in usersHeader.autodating"
                      :key="autodating.id"
                      @click="
                        selectFilterBuilder({
                          key: 'autodating',
                          value: autodating
                        })
                      "
                    >
                      <span class="select__text">{{ autodating.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="filters__item filters__item--sort">
              <div class="select">
                <span class="select__title">Уведомления:</span>
                <div class="select__body" v-tooltip="showPush">
                  <button
                    type="button"
                    class="select__field select__field--sort"
                    @click="clickClose('push', 'off')"
                  >
                    <span v-if="!pushOrder.length" class="select__field-value">{{
                      defaultFilter({ key: 'push' })
                    }}</span>
                    <span v-if="pushOrder.length" class="select__field-value"
                      >Выбрано: {{ pushOrder.length }}</span
                    >
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list select__list--sort" v-if="pushSelectOpen" @mouseleave="mouseLeave">
                    <li class="select__item" v-for="push in usersHeader.push" :key="push.id">
                      <input
                        ref="inputPush"
                        :id="push.id"
                        :value="push.value"
                        class="select__input"
                        type="checkbox"
                        v-model="pushOrder"
                        @change="
                          filterDisabled({
                            key: push,
                            ref: 'inputPush',
                            item: 'push'
                          })
                        "
                      />
                      <!--                        :disabled="sortFilter(sort.value)"-->
                      <label :for="push.id" class="select__text">{{ push.name }}</label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="filters__group">
            <div class="filters__item filters__item--sort">
              <div class="select">
                <span class="select__title">Конфиденциальность:</span>
                <div class="select__body" v-tooltip="showConf">
                  <button
                    type="button"
                    class="select__field select__field--sort"
                    @click="clickClose('confidential', 'off')"
                  >
                    <span v-if="!confidentialOrder.length" class="select__field-value">{{
                      defaultFilter({ key: 'confidential' })
                    }}</span>
                    <span v-if="confidentialOrder.length" class="select__field-value"
                      >Выбрано: {{ confidentialOrder.length }}</span
                    >
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul
                    class="select__list select__list--sort"
                    v-if="confidentialSelectOpen"
                    @mouseleave="mouseLeave"
                  >
                    <li
                      class="select__item"
                      v-for="confidential in usersHeader.confidential"
                      :key="confidential.id"
                    >
                      <input
                        ref="inputConfidential"
                        :id="confidential.id"
                        :value="confidential.value"
                        class="select__input"
                        type="checkbox"
                        v-model="confidentialOrder"
                        @change="
                          filterDisabled({
                            key: confidential,
                            ref: 'inputConfidential',
                            item: 'confidential'
                          })
                        "
                      />
                      <label :for="confidential.id" class="select__text">{{ confidential.name }}</label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="filters__group filters__group--text">
            <div class="filters__item">
              <p class="filters__text">
                Пользователи
                <span v-if="countFilter" class="filters__text--count">({{ countFilter }}) из</span>
                <span class="filters__text--count"> ({{ count }})</span>
              </p>
            </div>
          </div>
        </div>

        <div class="filters__buttons">
          <button type="button" @click="resetFiltersHandler" class="filters__btn-clear">
            <i class="fa fa-eraser"></i>
          </button>
          <button type="submit" class="filters__btn-search">
            <span>Поиск</span>
          </button>
        </div>
      </form>
      <button
        v-if="!mobileFilterOpen && windowTop > 200 && !isMobile"
        @click="toTop"
        class="btn-to-top-filters"
      >
        <FaIcon :iconClass="'fas fa-arrow-up'" />
      </button>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  GET_FILTERS_INFO,
  GET_FILTERS_DEFAULT,
  GET_TMP_FILERS,
  GET_VALUE_PICKER,
  GET_PERMISSIONS
} from '@/store/getter-types'
import {
  SET_RESET_FILTERS,
  SET_TMP_FILTERS,
  SET_DATE_FILTER,
  SET_CURRENT_FILTER,
  SET_USER_FILTER_COUNTS,
  SET_USER_COUNTS,
  SET_USERS
} from '@/store/mutation-types'
import { REQUEST_APPLY_FILTERS } from '@/store/action-types'
import DateRangePicker from 'vue2-daterange-picker'
import createLocaleDateFormat from '@/components/mixins/timeFormat'
import pickerMixin from '@/components/Picker/pickerMixin'
export default {
  name: 'Filters',
  components: {
    DateRangePicker
  },
  props: [`count`, `countFilter`, `picked_date`, `user_city`],
  mixins: [createLocaleDateFormat, pickerMixin],
  data: () => ({
    isAgeMax: false,
    isAgeMin: false,
    osSelectOpen: false,
    countrySelectOpen: false,
    genderSelectOpen: false,
    userDeletedSelectOpen: false,
    operatorSelectOpen: false,
    userIncognitoSelectOpen: false,
    vipSelectOpen: false,
    onlineSelectOpen: false,
    utmSelectOpen: false,
    banSelectOpen: false,
    verificateSelectOpen: false,
    sortSelectOpen: false,
    statusPhotoSelectOpen: false,
    autodatingSelectOpen: false,
    pushSelectOpen: false,
    chatWithOperatorSelectOpen: false,
    confidentialSelectOpen: false,
    sortOrder: [],
    pushOrder: [],
    confidentialOrder: [],
    windowTop: 0,

    mobileFilterOpen: false,
    filters: {
      user_id: '',
      email: '',
      name: '',
      city: '',
      country: '',
      age_start: null,
      age_end: null,
      user_vkontakte: false,
      user_facebook: false,
      delta: ''
    }
  }),

  computed: {
    ...mapGetters({
      usersHeader: GET_FILTERS_INFO,
      defaultFilters: GET_FILTERS_DEFAULT,
      tmpFilter: GET_TMP_FILERS,
      getDatePickerValues: GET_VALUE_PICKER,
      getPermissions: GET_PERMISSIONS
    }),
    // showSort: function() {
    //   return this.sortOrder.join(", ");
    // },
    showPush: function() {
      return this.pushOrder.join(', ')
    },
    showConf: function() {
      return this.confidentialOrder.join(', ')
    },
    ageStart: {
      get() {
        return this.filters.age_start
      },
      set(age) {
        if (age) {
          this.isAgeMin = true
          this.$refs.agestart.value = age
          this.filters.age_start = age
        } else {
          this.isAgeMin = false
          this.$refs.agestart.value = age
          this.filters.age_start = age
        }
      }
    },

    ageEnd: {
      get() {
        return this.filters.age_end
      },
      set(end) {
        if (end) {
          this.isAgeMax = true
          this.$refs.ageend.value = end
          this.filters.age_end = end
        } else {
          this.isAgeMax = false
          this.$refs.ageend.value = end
          this.filters.age_end = end
        }
      }
    },

    userID: {
      get() {
        return this.filters.user_id
      },
      set(id) {
        this.$refs.userid.value = id
        this.filters.user_id = id
      }
    },

    filterEmail: {
      get() {
        return this.filters.email
      },
      set(email) {
        this.$refs.filteremail.value = email
        this.filters.email = email
      }
    },

    filterName: {
      get() {
        return this.filters.name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.filtername.value = name
        this.filters.name = name
      }
    },

    filterCity: {
      get() {
        return this.filters.city
      },
      set(city) {
        city = city.replace(/\d/g, '')
        this.$refs.filtercity.value = city
        this.filters.city = city
      }
    },

    filterCountry: {
      get() {
        return this.filters.country
      },
      set(country) {
        country = country.replace(/\d/g, '')
        this.$refs.filtercountry.value = country
        this.filters.country = country
      }
    },

    datePickerControlreg: {
      get() {
        const date = {
          startDate: '',
          endDate: ''
        }
        return date
      },
      set({ startDate, endDate }) {
        const timestampStartDate = Math.round(new Date(startDate.setHours(0, 0, 0, 0)).getTime() / 1000.0)
        const timestampEndDate = Math.round(new Date(endDate.setHours(23, 59, 59, 59)).getTime() / 1000.0)
        const timeStampDateObject = {
          startDate: timestampStartDate,
          endDate: timestampEndDate
        }
        this.dateFilter({ timeStampDateObject, key: 'register' })
      }
    },
    datePickerControlenter: {
      get() {
        const date = {
          startDate: '',
          endDate: ''
        }
        return date
      },
      set({ startDate, endDate }) {
        const timestampStartDate = Math.round(new Date(startDate).setUTCHours(0, 0, 0) / 1000.0)
        const timestampEndDate = Math.round(new Date(endDate).setUTCHours(23, 59, 59) / 1000.0)
        const timeStampDateObject = {
          startDate: timestampStartDate,
          endDate: timestampEndDate
        }
        this.dateFilter({ timeStampDateObject, key: 'enter' })
      }
    },
    isMobile() {
      return window.matchMedia('(max-width: 1025px)').matches ? true : false
    },
    datePickerControlpayment: {
      get() {
        const { startDate, endDate } = this.getDatePickerValues.purchase_date
        const date = {
          startDate: startDate ? new Date(startDate * 1000) : '',
          endDate: endDate ? new Date(endDate * 1000) : ''
        }
        return date
      },
      set({ startDate, endDate }) {
        const timestampStartDate = Math.round(new Date(startDate.setHours(0, 0, 0, 0)).getTime() / 1000.0)
        const timestampEndDate = Math.round(new Date(endDate.setHours(23, 59, 59, 59)).getTime() / 1000.0)
        const timeStampDateObject = {
          startDate: timestampStartDate,
          endDate: timestampEndDate
        }
        this.dateFilter({ timeStampDateObject, key: 'purchase_date' })
      }
    }
  },
  watch: {
    mobileFilterOpen: function() {
      if (!this.mobileFilterOpen) this.$refs.mobilefilters.scrollTop = 0
    }
  },
  beforeDestroy() {
    this.destroyFilters()
  },

  methods: {
    ...mapMutations({
      resetFilters: SET_RESET_FILTERS,
      addTmpFilters: SET_TMP_FILTERS,
      dateFilter: SET_DATE_FILTER,
      setFilters: SET_CURRENT_FILTER,
      countFilterReset: SET_USER_FILTER_COUNTS,
      allCount: SET_USER_COUNTS,
      delUsers: SET_USERS
    }),
    ...mapActions({
      applyFilters: REQUEST_APPLY_FILTERS,
      allUsers: REQUEST_APPLY_FILTERS
    }),
    toTop() {
      this.$emit('toTop')
    },
    onScroll(e) {
      this.windowTop = e.target.scrollTop
    },
    logEvent(value) {
      console.log(value)
    },
    filterDisabled(object) {
      const { key, ref, item } = object
      const sortName = key.value.split(':')
      switch (item) {
        // case "sort":
        //   if (sortName[1] === "desc") {
        //     sortName[1] = "asc";
        //   } else {
        //     sortName[1] = "desc";
        //   }
        //   break;
        case 'push':
          if (sortName[1] === 'off') {
            sortName[1] = 'on'
          } else {
            sortName[1] = 'off'
          }

          break
        case 'confidential':
          if (sortName[1] === 'off') {
            sortName[1] = 'on'
          } else {
            sortName[1] = 'off'
          }
          break
        default:
          break
      }
      if (this[`${item}Order`].find(f => f === key.value)) {
        let str = this.$refs[`${ref}`].find(f => f.value === sortName.join(':'))
        str.disabled = true
      } else {
        let str = this.$refs[`${ref}`].find(f => f.value === sortName.join(':'))
        str.disabled = false
      }
    },

    close() {
      this.osSelectOpen = false
      this.vipSelectOpen = false
      this.onlineSelectOpen = false
      this.countrySelectOpen = false
      this.genderSelectOpen = false
      this.userDeletedSelectOpen = false
      this.operatorSelectOpen = false
      this.userIncognitoSelectOpen = false
      this.utmSelectOpen = false
      this.banSelectOpen = false
      this.verificateSelectOpen = false
      this.sortSelectOpen = false
      this.statusPhotoSelectOpen = false
      this.autodatingSelectOpen = false
      this.chatWithOperatorSelectOpen = false
      this.pushSelectOpen = false
      this.confidentialSelectOpen = false
    },

    defaultFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : this.defaultFilters[key].name
    },

    defaultNameFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : ''
    },
    defaultSortFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current ? current : ''
    },

    defaultValueFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current !== undefined ? current : ''
    },

    selectFilterBuilder(filterItem) {
      const { key } = filterItem
      switch (key) {
        case 'userDeleted':
          this.addTmpFilters({ ...filterItem })
          break
        case 'gender':
          this.addTmpFilters({ ...filterItem })
          break
        case 'os':
          this.addTmpFilters({ ...filterItem })
          break
        case 'vip':
          this.addTmpFilters({ ...filterItem })
          break
        case 'online':
          this.addTmpFilters({ ...filterItem })
          break
        case 'utm':
          this.addTmpFilters({ ...filterItem })
          break
        case 'ban':
          this.addTmpFilters({ ...filterItem })
          break
        case 'verificate':
          this.addTmpFilters({ ...filterItem })
          break
        case 'sort':
          this.addTmpFilters({ ...filterItem })
          break
        case 'userIncognito':
          this.addTmpFilters({ ...filterItem })
          break
        case 'autodating':
          this.addTmpFilters({ ...filterItem })
          break
        case 'operator':
          this.addTmpFilters({ ...filterItem })
          break
        case 'country':
          if (filterItem.value.id !== 0) {
            this.addTmpFilters({ ...filterItem })
          }
          break
        case 'chatWithOperator':
          this.addTmpFilters({ ...filterItem })
          break
        case 'statusPhoto':
          this.addTmpFilters({ ...filterItem })
          break

        default:
          break
      }
      this[`${key}SelectOpen`] = false
    },

    resetFiltersHandler() {
      this.isAgeMax = false
      this.isAgeMin = false
      this.resetFilters()
      this.resetData(this.filters)
      this.$refs.pickerreg.start = null
      this.$refs.pickerreg.end = null
      this.$refs.pickerenter.start = null
      this.$refs.pickerenter.end = null
      this.$refs.pickerpayment.start = null
      this.$refs.pickerpayment.end = null
      // this.sortOrder = [];
      this.confidentialOrder = []
      this.pushOrder = []
      this.setValuePicker = {}
      this.delUsers([])
      const delta = new Date().getTimezoneOffset() / -60
      this.countFilterReset(0)
      this.allCount(0)
      this.allUsers({ user_ban: false, user_deleted: -1, delta: delta, page: 0 })
      this.$router.replace({})
    },

    destroyFilters() {
      this.isAgeMax = false
      this.isAgeMin = false
      this.resetFilters()
      this.resetData(this.filters)
      this.$refs.pickerreg.start = null
      this.$refs.pickerreg.end = null
      this.$refs.pickerenter.start = null
      this.$refs.pickerenter.end = null
      this.$refs.pickerpayment.start = null
      this.$refs.pickerpayment.end = null
      // this.sortOrder = [];
      this.confidentialOrder = []
      this.pushOrder = []
      this.setValuePicker = {}
      this.delUsers([])
      const delta = new Date().getTimezoneOffset() / -60
      this.countFilterReset(0)
      this.allCount(0)
      this.$router.replace({})
    },

    resetData(data) {
      for (const key in data) {
        const item = data[key]
        if (item) {
          switch (item.constructor) {
            case Object:
              this.resetData(item)
              break
            default:
              data[key] = ''
          }
        }
      }
    },

    validateData(data) {
      let verifyHandler = {
        value: false
      }

      function validObject(obj, verifyer) {
        for (const key in obj) {
          const item = obj[key]

          if (item) {
            switch (item.constructor) {
              case Object:
                validObject(item, verifyer)
                break
              default:
                verifyer.value = true
            }
          }
        }
      }

      validObject(data, verifyHandler)

      return verifyHandler.value
    },

    applyUserFiltersHanlder() {
      const userFilterData = {
        user_email: this.filters.email,
        id: this.filters.user_id,
        user_name: this.filters.name,
        user_city: this.filters.city,
        delta: this.filters.delta ? this.filters.delta : 0,
        'user_country.code': this.defaultValueFilter({ key: 'country' }),
        user_deleted: this.defaultValueFilter({ key: 'userDeleted' }),
        incognito_in_admin: this.defaultValueFilter({ key: 'userIncognito' }),
        user_verification: this.defaultValueFilter({ key: 'verificate' }),
        'user_vip.expire': this.defaultValueFilter({ key: 'vip' }),
        user_online: this.defaultValueFilter({ key: 'online' }),
        user_ban: this.defaultValueFilter({ key: 'ban' }),
        user_gender: this.defaultValueFilter({ key: 'gender' }),
        operator_user_dialogs: this.defaultValueFilter({ key: 'chatWithOperator' }),
        utm: this.defaultValueFilter({ key: 'utm' }),
        device: this.defaultValueFilter({ key: 'os' }),
        order_by: this.defaultSortFilter({ key: 'sort' }),
        'user_avatar.moderation.status': this.defaultValueFilter({ key: 'statusPhoto' }),
        user_is_operator: this.defaultValueFilter({ key: 'operator' }),
        // order_by: this.sortOrder.join(","),
        user_push: this.pushOrder.join(','),
        user_confidential: this.confidentialOrder.join(','),
        user_auto_dating: this.defaultValueFilter({ key: 'autodating' }),
        page: 0
      }
      if (this.filters.user_vkontakte) {
        userFilterData.user_vkontakte = 1
      }
      if (this.filters.user_facebook) {
        userFilterData.user_facebook = 1
      }
      if (this.isAgeMax || this.isAgeMin) {
        userFilterData.age = `${this.filters.age_start},${this.filters.age_end}`
      }

      if (this.getDatePickerValues.enter.startDate && this.getDatePickerValues.enter.endDate) {
        userFilterData.last_visit = `${this.getDatePickerValues.enter.startDate},${this.getDatePickerValues.enter.endDate}`
      }
      if (this.getDatePickerValues.register.startDate && this.getDatePickerValues.register.endDate) {
        userFilterData.user_create = `${this.getDatePickerValues.register.startDate},${this.getDatePickerValues.register.endDate}`
      }
      if (
        this.getDatePickerValues.purchase_date.startDate &&
        this.getDatePickerValues.purchase_date.endDate
      ) {
        userFilterData.purchase_date = `${this.getDatePickerValues.purchase_date.startDate},${this.getDatePickerValues.purchase_date.endDate}`
      }
      this.setFilters(userFilterData)
      this.countFilterReset(0)
      this.allCount(0)
      this.delUsers([])
      this.applyFilters(userFilterData)
    },

    mouseLeave() {
      this.close()
    },

    clickClose(options, param) {
      let newOptions = this[`${options}SelectOpen`]
      this.close()
      this[`${options}SelectOpen`] = !newOptions
      if ((newOptions === false && options === 'sort') || options === 'push' || options === 'confidential') {
        if (this[`${options}Order`].length) {
          this[`${options}Order`].forEach(el => {
            let sortName = el.split(':')
            switch (param) {
              case 'desc':
                if (sortName[1] === 'desc') {
                  sortName[1] = 'asc'
                } else {
                  sortName[1] = 'desc'
                }
                break
              case 'off':
                if (sortName[1] === 'off') {
                  sortName[1] = 'on'
                } else {
                  sortName[1] = 'off'
                }
                break
              default:
                break
            }
            setTimeout(() => {
              let str = Array.from(document.querySelectorAll('.select__input')).find(
                f => f.value === sortName.join(':')
              )
              if (str) {
                str.disabled = true
              }
            }, 0)
          })
        }
      }
    }
  },

  mounted() {
    this.countFilterReset(0)
    this.allCount(0)
    this.$root.$on('openaside', value => {
      if (this.mobileFilterOpen) this.mobileFilterOpen = !this.mobileFilterOpen
    })
    this.$root.$on('submitFilter', data => {
      this.filters.city = data.city
      this.filters.delta = data.delta
    })
    this.$nextTick(() => {
      this.$root.$emit('filtersHeight', this.$refs.filtersWrap.clientHeight)
      this.applyUserFiltersHanlder()
    })
    setTimeout(() => {
      let el = document.getElementsByClassName('app-users')[0]
      el.addEventListener('scroll', this.onScroll)
    }, 2000)
  }
}
</script>

<style lang="scss">
@import './Filters.scss';
.btn-to-top-filters {
  background-color: #a2a2a2a8;
  border-radius: 60px;
  color: #ffffff;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.btn-to-top--mobile {
  background-color: #a2a2a2a8;
  border-radius: 60px;
  color: #ffffff;
  position: absolute;
  right: 18px;
  top: 20px;
}

.table-condensed {
  td:hover {
    background: #318bcc;
    color: #fff;
  }
  .today {
    background: #48a7eb;
    color: #fff;
  }
}
.filters__group--text {
  margin-top: 25px;
  border-left: 1px solid #ddd;
  padding-left: 5px;
}

.filters__text {
  align-items: center;
  font-size: 18px;
  line-height: 34px;
  font-weight: 700;
  &--count {
    color: #318bcc;
  }
}
.filter_icon {
  width: 100%;
  position: relative;
  &__photo {
    &-verificate {
      color: #78cd51;
    }
    &-times {
      color: #ffa837;
    }
    &-clock {
      color: #e48800;
    }
    &-slash {
      color: #ec1515;
    }
    &-triangle {
      color: #41cac0;
    }
    &-banned {
      color: #ec1515;
    }
    &-baby {
      color: #ff8adc;
      font-size: 15px;
    }
    &-wait {
      color: #e48800;
    }
  }
}
</style>
