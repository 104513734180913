<template>
  <div class="operators-payments__filter">
    <form class="operators-payments__form" @submit.prevent="applyUserFiltersHanlder">
      <div class="operators-payments__item">
        <span class="operators-payments__title">Статус операторов:</span>
        <div class="operators-payments__input select">
          <div class="select__body">
            <button
              type="button"
              class="select__field select__field--operator"
              @click="statusSelectOpen = !statusSelectOpen"
            >
              <span class="select__field-value">{{ defaultFilter({ key: 'status' }) }}</span>
              <i class="select__field-icon fa fa-angle-down"></i>
            </button>
            <ul class="select__list" v-if="statusSelectOpen" @mouseleave="mouseLeave">
              <li
                class="select__item"
                v-for="status in operatorRoles.status"
                :key="status.id"
                @click="
                  selectFilterBuilder({
                    key: 'status',
                    value: status
                  })
                "
              >
                <span class="select__text"> {{ status.name }} </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button class="operators-payments__btn operators-payments__btn--success">
        Показать
      </button>
    </form>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { GET_OPERATOR_ROLES, GET_OPERATOR_ROLES_DEFAULT, GET_OPERATOR_TMP_ROLES } from '@/store/getter-types'
import { SET_OPERATOR_SETTINGS } from '@/store/mutation-types'

import { REQUEST_OPERATOR_PAYOUT } from '@/store/action-types'

export default {
  name: 'OperatorsPaymentsFilters',
  data: () => ({
    statusSelectOpen: false
  }),
  computed: {
    ...mapGetters({
      operatorRoles: GET_OPERATOR_ROLES,
      defaultFilters: GET_OPERATOR_ROLES_DEFAULT,
      tmpFilter: GET_OPERATOR_TMP_ROLES
    })
  },
  methods: {
    ...mapActions({
      getPayOuts: REQUEST_OPERATOR_PAYOUT
    }),
    ...mapMutations({
      addTmpOperatorSettings: SET_OPERATOR_SETTINGS
    }),
    defaultFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : this.defaultFilters[key].name
    },

    defaultValueFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current || current === false ? current : this.defaultFilters[key].value
    },

    selectFilterBuilder(filterItem) {
      const { key } = filterItem
      switch (key) {
        case 'status':
          this.addTmpOperatorSettings({ ...filterItem })
          break
        default:
          break
      }
      this[`${key}SelectOpen`] = false
    },

    close() {
      this.statusSelectOpen = false
    },
    mouseLeave() {
      this.close()
    },

    applyUserFiltersHanlder() {
      const userFilterData = {}
      userFilterData.is_active = this.defaultValueFilter({ key: 'status' })
      this.getPayOuts(userFilterData)
      this.close()
    }
  }
}
</script>
<style lang="scss">
@import 'OperatorsPayments';
</style>
