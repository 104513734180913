export default Vue => {
  Vue.component('FaIcon', {
    props: {
      iconClass: {
        type: [String, Array]
      }
    },

    data: () => ({
      visible: true
    }),

    render(h) {
      const { iconClass } = this.$props

      return this.visible
        ? h('span', {}, [
            h('i', {
              staticClass: 'fa',
              class: iconClass
            }),
            this.$slots.default
          ])
        : null
    },

    watch: {
      iconClass() {
        this.visible = false

        this.$nextTick(() => {
          this.visible = true
        })
      }
    }
  })
}
