<template>
  <Popup :visible="visible" @close="closeModal" description="Рейтинг пользователя" modalClass="popup--small">
    <form @submit.prevent="addRatingHandler">
      <div class="popup__item">
        <span class="popup__item-title">Изменить рейтинг пользователя</span>
        <input type="text" class="popup__input default-input" ref="ratingvalue" v-model="ratingValue" />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Изменить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_RATING_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_UPDATE_RATING } from '@/store/action-types'
export default {
  name: 'UserRatingPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data: () => ({
    rating: '',
    id: ''
  }),

  computed: {
    ratingValue: {
      get() {
        return this.rating
      },

      set(n) {
        n = n.replace(/[^\d-]/g, '')
        this.$refs.ratingvalue.value = n
        this.rating = n
      }
    }
  },

  methods: {
    ...mapActions({
      updateRating: REQUEST_UPDATE_RATING
    }),
    closeModal() {
      this.rating = ''
      this.error = false
      this.id = ''
      this.close()
    },
    addRatingHandler() {
      const ratingDataUser = {
        user_id: this.id,
        value: +this.rating
      }
      this.updateRating(ratingDataUser)
      this.id = ''
      this.rating = ''
      this.close()
    }
  },

  mounted() {
    this.$root.$on(USER_RATING_EVENT, user => {
      this.open()
      this.id = user.id
    })
  }
}
</script>

<style lang="scss">
.popup__warning {
  border-color: red;
}
</style>
