import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_USERS]: (state, payload) => {
    state.users = payload
  },

  [MutationTypes.SET_ALL_USERS]: (state, payload) => {
    state.users.push(...payload)
  },

  [MutationTypes.SET_USER_COUNTS]: (state, payload) => {
    state.userCount = payload
  },

  [MutationTypes.SET_USER_FILTER_COUNTS]: (state, payload) => {
    if (state.userCount === payload) {
      state.userFilterCount = payload
    } else if (payload) {
      state.userFilterCount += payload
    } else {
      state.userFilterCount = 0
    }
  },
  [MutationTypes.SET_USER_DEPOSIT]: (state, payload) => {
    state.userDeposit = payload
  },
  [MutationTypes.SET_USER_PAYOUTS]: (state, payload) => {
    state.userPayouts = payload
  },
  [MutationTypes.SET_USER_PURCHASES]: (state, payload) => {
    state.userPurchases = payload
  },
  [MutationTypes.SET_USER_PHOTOS]: (state, payload) => {
    const sorted = payload.sort((isTrue, isFalse) => {
      return isTrue.is_main_photo === isFalse.is_main_photo ? 0 : isTrue.is_main_photo ? -1 : 1
    })
    state.userPhotos = sorted
  },

  [MutationTypes.SET_CURRENT_USER]: (state, payload) => {
    state.currentUser = payload
  },

  [MutationTypes.SET_USER_CHATS]: (state, payload) => {
    state.userChats = state.userChats.concat(payload.chats)
  },

  [MutationTypes.SET_USER_CHATS_CLEAR]: state => {
    state.userChats = []
  },

  [MutationTypes.SET_CHAT_MESSAGES]: (state, payload) => {
    // state.userChatMessages.unshift(...payload)
    state.userChatMessages = payload
  },

  [MutationTypes.SET_CHAT_OLD_MESSAGES]: (state, payload) => {
    state.userChatMessages.unshift(...payload)
  },

  [MutationTypes.SET_TRANSACTION_KIND]: (state, payload) => {
    state.userTransaction = payload
  },

  [MutationTypes.SET_DATE_FILTER]: (state, payload) => {
    const { key, timeStampDateObject } = payload
    const newDateObject = {
      key,
      ...timeStampDateObject
    }
    state.tmpDateFilter[key] = newDateObject
  },

  [MutationTypes.SET_UTM_FILTER]: (state, payload) => {
    payload.forEach(el => {
      if (!state.filtersUserInfo.utm.find(f => f.id === el.id)) {
        if (el.utm_name === 'Google Play | Organic') {
          let newDateObject = {
            name: el.utm_name,
            value: 'Organic,Android',
            id: el.id
          }
          state.filtersUserInfo.utm.push(newDateObject)
        } else if (el.utm_name === 'App Store | Organic') {
          let newDateObject = {
            name: el.utm_name,
            value: 'Organic,IOS',
            id: el.id
          }
          state.filtersUserInfo.utm.push(newDateObject)
        } else {
          let newDateObject = {
            name: el.utm_name,
            value: el.utm_source,
            id: el.id
          }
          state.filtersUserInfo.utm.push(newDateObject)
        }
      }
    })
  },

  [MutationTypes.SET_CURRENT_FILTER]: (state, payload) => {
    state.currentFilter = payload
  },

  [MutationTypes.SET_TMP_FILTERS]: (state, payload) => {
    const { key, value } = payload
    const newObject = {
      key,
      ...value
    }
    state.tmpUserFilters[key] = newObject
  },

  [MutationTypes.SET_RESET_FILTERS]: state => {
    state.currentFilter = null
    state.currentFilter = { ban: false, user_deleted: -1 }
    for (const field in state.tmpUserFilters) {
      state.tmpUserFilters[field] = {}
      ;(state.tmpUserFilters.userDeleted = {
        key: 'userDeleted',
        name: 'нет',
        value: -1,
        id: '0'
      }),
        (state.tmpUserFilters.ban = {
          key: 'ban',
          name: 'Нет',
          value: false,
          id: 5
        })
    }
    for (const date in state.tmpDateFilter) {
      state.tmpDateFilter[date] = {}
    }
  },

  [MutationTypes.SET_UPDATE_PHOTO_STATUS]: (state, payload) => {
    const currentPhoto = state.userPhotos.find(photo => photo.id === payload.photo_id)
    currentPhoto.status = payload.status
  },

  [MutationTypes.SET_USERCHATS_STYLE]: (state, payload) => {
    state.userChatsSyles = payload
  }
}
