const SET_MODERATORS = 'SET_MODERATORS'
const SET_MODERATOR_SETTINGS = 'SET_MODERATOR_SETTINGS'
const SET_CLEAR_MODERATOR_DATA = 'SET_CLEAR_MODERATOR_DATA'
const SET_SEARCH_MODERATOR_OPTIONS = 'SET_SEARCH_MODERATOR_OPTIONS'
const SET_ADD_MODERATOR_SEARCH_OPTIONS = 'SET_ADD_MODERATOR_SEARCH_OPTIONS'
const SET_DEL_MODERATOR_SEARCH_OPTIONS = 'SET_DEL_MODERATOR_SEARCH_OPTIONS'
const SET_EDIT_MODERATOR_SEARCH_OPTIONS = 'SET_EDIT_MODERATOR_SEARCH_OPTIONS'
const SET_MODERATOR_STATISTIC = 'SET_MODERATOR_STATISTIC'
const SET_CURRENT_MODERATOR = 'SET_CURRENT_MODERATOR'
const SET_MODERATOR_PAYOUT = 'SET_MODERATOR_PAYOUT'
const SET_DEFAULT_PAYMENT_MODERATOR_OPTION = 'SET_DEFAULT_PAYMENT_MODERATOR_OPTION'

export {
  SET_MODERATORS,
  SET_MODERATOR_SETTINGS,
  SET_CLEAR_MODERATOR_DATA,
  SET_SEARCH_MODERATOR_OPTIONS,
  SET_ADD_MODERATOR_SEARCH_OPTIONS,
  SET_DEL_MODERATOR_SEARCH_OPTIONS,
  SET_EDIT_MODERATOR_SEARCH_OPTIONS,
  SET_MODERATOR_STATISTIC,
  SET_CURRENT_MODERATOR,
  SET_MODERATOR_PAYOUT,
  SET_DEFAULT_PAYMENT_MODERATOR_OPTION
}
