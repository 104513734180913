<template>
  <div class="moderator-analytics">
    <span>Страница Аналитики модераторов</span>
  </div>
</template>
<script>
export default {
  name: 'moderatoranalytics'
}
</script>
