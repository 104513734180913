<template>
  <li>
    <div class="popup__item-info popup__item-date">
      <span>{{ isCreate }}</span>
    </div>
    <div class="popup__item-info popup__item-payment">
      <span>{{ isGateway }}</span>
    </div>
    <div class="popup__item-info popup__item-purpose">
      <span>{{ isTarget.replace('N', this.value) }}</span>
    </div>
    <div class="popup__item-info popup__item-currency">
      <span v-tooltip="`${isDescription}`">{{ isCurrency }}</span>
    </div>
  </li>
</template>
<script>
import paymentGateway from '@/constants/paymentGateway'
import purchaseTarget from '@/constants/purchaseTarget'
import currency from '@/constants/currency'
export default {
  name: 'UserBuyListPopupPurchases',
  props: {
    create: {
      type: String
    },
    gateway: {
      type: Number
    },
    target: {
      type: Number
    },
    currency: {
      type: String
    },
    amount: {
      type: Number
    },
    value: {
      type: Number
    }
  },
  computed: {
    isCreate() {
      return new Date(this.create).toLocaleString()
    },
    isGateway() {
      return paymentGateway.find(i => i.code === this.gateway).description
    },
    isTarget() {
      return purchaseTarget.find(i => i.code === this.target).description
    },
    isCurrency() {
      const label = currency.find(i => i.name === this.currency)
        ? currency.find(i => i.name === this.currency).sign
        : '?'
      return `${this.amount} ${label}`
    },
    isDescription() {
      const description = currency.find(i => i.name === this.currency)
        ? currency.find(i => i.name === this.currency).description
        : '-'
      return description
    }
  }
}
</script>
<style lang="scss">
.popup__item-currency {
  cursor: default;
}
</style>
