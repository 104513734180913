<template>
  <div class="lang__header">
    <span class="lang__title title-big">Языки</span>
    <div class="lang__btns">
      <button class="lang__btn-add" @click="$root.$emit(LANG_ADD_POPUP)">
        <i class="lang__btn-txt fa fa-plus-square"></i>
        <span class="lang__btn-title">Добавить</span>
      </button>
    </div>
  </div>
</template>

<script>
import { LANG_ADD_POPUP } from '@/constants/event'

export default {
  name: 'LanguagesHeader',
  data() {
    return { LANG_ADD_POPUP }
  }
}
</script>
<style lang="scss"></style>
