<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    title=""
    description=""
    modalClass="popup--medium popup--operator popup--scroll"
  >
    <form class="operator-profile" @submit.prevent="createOperatorSubmitHandler">
      <div class="operator-profile__header">
        <span class="operator-profile__name"> Новый оператор</span>
        <div class="operator-profile__avatar-wrap">
          <div class="operator-profile__avatar">
            <img v-if="isAvatar" :src="preview" alt="" />
            <img
              v-else-if="operatorData.avatar"
              :src="
                getFile({
                  type: 'image',
                  file: operatorData.avatar,
                  width: `128`,
                  height: `128`
                })
              "
              class="chat__message-body--img"
              alt=""
            />
            <img v-else src="@/assets/img/anonymous_user.png" alt="" />
          </div>
          <div class="operator-profile__avatar-edit">
            <input
              class="operator-profile__avatar-edit-input"
              type="file"
              id="avatar-input"
              accept=".jpg, .jpeg, .png"
              ref="downloadavatar"
              @change="fileUploadHandler"
            />
            <label class="operator-profile__avatar-edit-label" for="avatar-input">
              <i class="fa fa-pen"></i>
            </label>
          </div>
        </div>
      </div>

      <div class="operator-profile__edit">
        <div class="operator-profile__edit-body">
          <div class="operator-profile__edit-group">
            <label for="operator-name" class="operator-profile__edit-text">Имя</label>
            <input
              id="operator-name"
              type="text"
              class="operator-profile__edit-input"
              v-model="createOperatorname"
              ref="createoperatorname"
              required
            />
          </div>
          <div class="operator-profile__edit-group">
            <label for="operator-lastname" class="operator-profile__edit-text">Фамилия</label>
            <input
              id="operator-lastname"
              type="text"
              class="operator-profile__edit-input"
              v-model="createOperatorLastname"
              ref="createoperatorlastname"
              required
            />
          </div>
          <div class="operator-profile__edit-group operator-profile__edit-group--currency">
            <div class="operator-profile__wrapper">
              <label for="operator-card" class="operator-profile__edit-text">Номер карты</label>
              <input
                id="operator-card"
                type="text"
                class="operator-profile__edit-input"
                v-model="createOperatorCard"
                maxlength="23"
                minlength="16"
                ref="createoperatorcard"
                required
              />
            </div>
            <div class="operator-profile__wrapper">
              <span class="operator-profile__edit-select-title">Валюта</span>
              <div class="operator-profile__edit-select">
                <button
                  type="button"
                  class="operator-profile__edit-value"
                  @click="currencyOptions = !currencyOptions"
                >
                  <span class="operator-profile__edit-value-text">{{
                    defaultOperatorFilter({ key: 'currency' })
                  }}</span>
                  <i class="fa fa-angle-down operator-profile__edit-select-icon"></i>
                </button>
                <ul
                  class="operator-profile__edit-select-list"
                  v-if="currencyOptions"
                  @mouseleave="currencyOptions = !currencyOptions"
                  :class="{ active: currencyOptions }"
                >
                  <li
                    v-for="currency in operatorRoles.currency"
                    :key="currency.id"
                    @click="selectItemHandler({ key: 'currency', value: currency })"
                    class="operator-profile__edit-select-item"
                  >
                    <span class="operator-profile__edit-select-text">{{ currency.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="operator-profile__edit-group operator-profile__edit-group--account">
            <div>
              <span class="operator-profile__edit-text">Добавить профиль пользователя</span>
              <button
                class="operator__btn btn-blue operator__btn--blue"
                @click.prevent="$root.$emit('ADD_OPERATOR_USER_POPUP_EVENT')"
              >
                <i class="operators__btn-txt fa fa-plus-square"></i>
              </button>
            </div>
            <ul class="operator-profile__account-list" v-if="currentUsers.length">
              <li
                class="operator-profile__account-wrapper"
                v-for="(currentUser, index) in currentUsers"
                :key="index"
              >
                <div class="operator-profile__account-info">
                  <div class="operator__avatar">
                    <img
                      v-if="currentUser.user_avatar"
                      :src="
                        getFile({
                          type: 'image',
                          file: currentUser.user_avatar.content_id,
                          width: 60,
                          height: 60
                        })
                      "
                      alt=""
                    />
                    <img v-else src="@/assets/img/anonymous_user.png" alt="" />
                  </div>
                  <span class="operator__name">
                    <span class="operator__text">{{ currentUser.user_name }} </span>
                  </span>
                </div>
                <div class="operator-profile__account-btns">
                  <button
                    class="operator__btn btn-red operator__btn--red"
                    :disabled="!currentUser"
                    @click.prevent="
                      $root.$emit('DEL_OPERATOR_USER_POPUP_EVENT', { id: currentUser.id, title: 'add' })
                    "
                  >
                    <i class="fa fa-times-circle"></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div class="operator-profile__edit-group operator-profile__edit-group--btns">
            <button
              type="submit"
              class="operator-profile__edit-action-btn operator-profile__edit-action-btn--save"
            >
              <span class="operator-profile__edit-action-btn-text">Добавить</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
  SET_OPERATOR_SETTINGS,
  SET_CLEAR_OPERATOR_DATA,
  SET_SEARCH_OPTIONS,
  SET_OPERATOR_CURRENT_USER
} from '@/store/mutation-types'
import { REQUEST_CREATE_OPERATORS } from '@/store/action-types'
import {
  GET_OPERATOR_ROLES,
  GET_OPERATOR_TMP_ROLES,
  GET_OPERATOR_ROLES_DEFAULT,
  GET_OPERATORS_CURRENCY,
  GET_CURRENT_OPERATOR_USER,
  GET_OPERATOR_SEARCH_OPTION
} from '@/store/getter-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import getImgLinkFront from '@/components/mixins/getImgCdnLink'
import {
  ADD_OPERATOR_POPUP_EVENT,
  DEL_OPERATOR_USER_POPUP_EVENT,
  ADD_SEARCH_OPTION_POPUP_EVENT,
  EDIT_SEARCH_OPTION_POPUP_EVENT
} from '@/constants/event'

export default {
  name: 'NewOperatorPopup',
  data: () => ({
    ADD_OPERATOR_POPUP_EVENT,
    DEL_OPERATOR_USER_POPUP_EVENT,
    ADD_SEARCH_OPTION_POPUP_EVENT,
    EDIT_SEARCH_OPTION_POPUP_EVENT,
    currencyOptions: false,
    preview: null,
    isAvatar: false,
    operatorData: {
      first_name: '',
      last_name: '',
      account: {},
      currency: '',
      card: null,
      avatar: null,
      file: null
    }
  }),
  components: {
    Popup
  },
  computed: {
    ...mapGetters({
      operatorRoles: GET_OPERATOR_ROLES,
      operatorTmpRoles: GET_OPERATOR_TMP_ROLES,
      operatorRolesDefault: GET_OPERATOR_ROLES_DEFAULT,
      operatorCurrency: GET_OPERATORS_CURRENCY,
      currentUsers: GET_CURRENT_OPERATOR_USER,
      searchOptions: GET_OPERATOR_SEARCH_OPTION
    }),

    createOperatorname: {
      get() {
        return this.operatorData.first_name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.createoperatorname.value = name
        this.operatorData.first_name = name
      }
    },
    createOperatorLastname: {
      get() {
        return this.operatorData.last_name
      },
      set(lastname) {
        lastname = lastname.replace(/\d/g, '')
        this.$refs.createoperatorlastname.value = lastname
        this.operatorData.last_name = lastname
      }
    },
    createOperatorCard: {
      get() {
        return this.operatorData.card
      },
      set(card) {
        card = card.replace(/[^\d; ]/gi, '')
        if (this.operatorData.card && card.length > this.operatorData.card.length) {
          if (
            (card.length > this.operatorData.card.length && card.length == 4) ||
            card.length == 9 ||
            card.length == 14 ||
            card.length == 19
          ) {
            card += ' '
          }
        }
        this.$refs.createoperatorcard.value = card
        this.operatorData.card = card
      }
    }
  },
  mixins: [ModalMixin, getImgLinkFront],

  methods: {
    ...mapMutations({
      addTmpOperatorSettings: SET_OPERATOR_SETTINGS,
      delSearchOptions: SET_SEARCH_OPTIONS,
      setCurrentUser: SET_OPERATOR_CURRENT_USER,
      clearData: SET_CLEAR_OPERATOR_DATA
    }),
    ...mapActions({
      createNewOperator: REQUEST_CREATE_OPERATORS
    }),

    defaultOperatorFilter(settings) {
      const { key } = settings
      const current = this.operatorTmpRoles[key].name
      return current ? current : this.operatorRolesDefault[key].name
    },

    defaultOperatorCurrency(settings) {
      const { key } = settings
      const current = this.operatorTmpRoles[key].position_name
      return current ? current : this.operatorRolesDefault[key].name
    },

    defaultOperatorValue(settings) {
      const { key } = settings
      const current = this.operatorTmpRoles[key].name
      return current ? this.operatorTmpRoles[key].id : this.operatorRolesDefault[key].id
    },

    selectItemHandler(selectItem) {
      const { key } = selectItem
      switch (key) {
        case 'currency':
          this.addTmpOperatorSettings({ ...selectItem })
          break
        default:
          break
      }
      this[`${key}Options`] = false
    },

    fileUploadHandler() {
      const avatar = this.$refs.downloadavatar.files[0]
      this.preview = URL.createObjectURL(avatar)
      this.isAvatar = true
      this.operatorData.file = avatar
    },

    addAccount(id) {
      this.operatorData.account = id
    },

    closePopup() {
      this.night_shift = false
      this.delSearchOptions([])
      this.setCurrentUser(null)
      this.clearData()
      this.operatorData.first_name = ''
      this.operatorData.last_name = ''
      this.operatorData.card = ''
      this.operatorData.currency = ''
      this.operatorData.file = null
      this.preview = null
      this.isAvatar = false
      this.close()
    },

    createOperatorSubmitHandler() {
      let newFormatOptions = []
      this.searchOptions.forEach(f => {
        newFormatOptions.push({
          age_max: f.age_max,
          age_min: f.age_min,
          country_code: f.country_code,
          gender: f.gender,
          lang: f.lang.value
        })
      })
      const newOperatorData = {
        data: {
          payment_rate: {
            card: +this.operatorData.card.replace(/\s/g, ''),
            currency: this.defaultOperatorValue({ key: 'currency' })
          },
          first_name: this.operatorData.first_name,
          last_name: this.operatorData.last_name,
          is_deleted: false
        },
        avatar: this.operatorData.file
      }
      if (this.currentUsers.length) {
        let arrUsersId = []
        this.currentUsers.forEach(f => arrUsersId.push({ account_id: f.id, searches: [] }))
        newOperatorData.data.accounts = arrUsersId
      } else {
        newOperatorData.data.accounts = []
      }
      const formData = new FormData()
      formData.append('avatar', newOperatorData.avatar)
      formData.append('data', JSON.stringify(newOperatorData.data))
      this.createNewOperator(formData).then(res => {
        if (res.status) this.$root.$emit('changeOperators', 'newOperator')
      })
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(ADD_OPERATOR_POPUP_EVENT, () => {
      this.open()
    })
  }
}
</script>
<style lang="scss" scoped>
@import './EditOperator.scss';
.operator-profile__edit-group--account {
  display: flex;
  flex-direction: column;
}
.operator-profile__account-info {
  display: flex;
  align-items: center;
}
.operator-profile__account-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>
