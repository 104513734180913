<template>
  <div v-if="operator" class="operator-wrapper" :class="searchOperatorOpen ? `operator-wrapper--open` : ``">
    <div class="operator">
      <div v-if="operator.number_of_accounts">
        <button
          v-if="!searchOperatorOpen && operator.number_of_accounts"
          key="open"
          @click="searchOperatorOpen = true"
          title="Показать"
        >
          <i class="fa fa-angle-up"></i>
        </button>
        <button v-else key="close" @click="searchOperatorOpen = false" title="Скрыть">
          <i class="fa fa-angle-down"></i>
        </button>
      </div>
      <div v-else class="no-accounts-operator">
        <span></span>
      </div>
      <div class="operator__field operator__field--avatar">
        <div class="operator__avatar">
          <img v-if="operator.avatar" :src="getFile({ type: 'image', file: operator.avatar })" alt="" />
          <img v-else src="@/assets/img/anonymous_user.png" alt="" />
        </div>
        <span class="operator__name">
          <span class="operator__text">{{ operator.first_name }} </span>
          <span class="operator__text">{{ operator.last_name }}</span>
        </span>
      </div>
      <div class="operator__field">
        {{ operator.number_of_accounts }}
      </div>
      <div class="operator__field operator__field--online">
        <div v-if="operatorInShift === 'Online'" class="operator__online">
          <div class="operator__online-inner"></div>
        </div>
        {{ operatorInShift }}
      </div>
      <div class="operator__field">{{ langString }}</div>

      <div class="operator__field" v-if="operator.payment_rate">
        <span class="operator__mobtitle">Валюта:</span>
        <span class="operator__text">{{ moneyIcon(operator.payment_rate.currency) }}</span>
      </div>
      <div class="operator__field" v-else>Валюта не задана</div>
      <div class="operator__field">
        <span class="operator__mobtitle">Валюта:</span>
        <span class="operator__text">{{ isActiveOperator }}</span>
      </div>
      <div class="operator__field operator__field--btns">
        <!-- :class="{ opacity: !operator.is_active }"
          :disabled="!operator.is_active" -->
        <button
          class="operator__btn btn-blue operator__btn--blue"
          @click="$root.$emit(EDIT_OPERATOR_POPUP_EVENT, operator)"
        >
          <i class="fa fa-edit"></i>
        </button>
        <!-- :class="{ opacity: !operator.is_active }"
          :disabled="!operator.is_active" -->
        <button
          class="operator__btn btn-red operator__btn--red"
          @click="$root.$emit(DELETE_OPERATOR_POPUP_EVENT, operator.id)"
        >
          <i class="fa fa-times-circle"></i>
        </button>
      </div>
    </div>
    <SearchOperator v-if="searchOperatorOpen" :operator="operator" />
  </div>
  <div v-else class="error">Ошибка подгрузки оператора</div>
</template>
<script>
import getImgLink from '@/components/mixins/getImgCdnLink'
import SearchOperator from './SearchOperator'
import getIconCurrency from '@/components/mixins/getIconCurrency'
import {
  DELETE_OPERATOR_POPUP_EVENT,
  EDIT_OPERATOR_POPUP_EVENT,
  DELETE_OPERATOR_USER_POPUP_EVENT
} from '@/constants/event'
export default {
  name: 'Operator',
  components: {
    SearchOperator
  },
  data: () => ({
    permListOpen: false,
    DELETE_OPERATOR_POPUP_EVENT,
    EDIT_OPERATOR_POPUP_EVENT,
    DELETE_OPERATOR_USER_POPUP_EVENT,
    searchOperatorOpen: false,
    link: `${process.env.VUE_APP_DEPLOY_URL}`
  }),
  props: {
    operator: {
      type: Object
    }
  },
  computed: {
    operatorInShift: function() {
      if (this.operator.on_shift_statistic) {
        return 'Online'
      } else {
        return 'Offline'
      }
    },
    langString: function() {
      let newArr = []
      this.operator.all_used_langs.forEach(f => {
        newArr.push(f.value)
      })

      return newArr.join(', ')
    },
    isActiveOperator() {
      return this.operator.is_active ? 'Активен' : 'Неактивен'
    }
  },
  mixins: [getImgLink, getIconCurrency],
  mounted() {
    this.$root.$on('closePopup', () => {
      this.searchOperatorOpen = false
    })
  }
}
</script>
<style lang="scss">
@import './Operators.scss';
.operator__online {
  position: absolute;
  left: -15px;
  bottom: 3px;
}
.operator__field--online {
  position: relative;
}
.operator__online-inner {
  min-width: 14px;
  width: 14px;
  height: 14px;
  border: 4px solid transparent;
  border-radius: 50%;
  border-color: #78cd51;
  background-color: #78cd51;
}
.no-accounts-operator {
  width: 19px;
}
</style>
