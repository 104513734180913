<template>
  <Popup :visible="visible" @close="close" description="Поменять пол" modalClass="popup--small">
    <form @submit.prevent="genderHandler">
      <div class="popup__item">
        <input id="radio-2" type="radio" name="radio" value="1" v-model="gender" />
        <label for="radio-2">Мужской</label>
      </div>
      <div class="popup__item">
        <input id="radio-3" type="radio" name="radio" value="2" v-model="gender" />
        <label for="radio-3">Женский</label>
      </div>

      <div class="popup__btns popup__btns--right">
        <button type="submit" class="popup__btn btn-green">Изменить</button>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_GENDER_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_UPDATE_GENDER } from '@/store/action-types'
export default {
  name: 'UserGenderPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data() {
    return {
      id: '',
      gender: null
    }
  },

  methods: {
    ...mapActions({
      updateGender: REQUEST_UPDATE_GENDER
    }),
    genderHandler() {
      const payload = {
        user_id: this.id,
        gender: +this.gender
      }
      this.updateGender(payload)
      this.gender = ''
      this.id = ''
      this.close()
    }
  },
  mounted() {
    this.$root.$on(USER_GENDER_EVENT, user => {
      this.open()
      this.id = user.id
      this.gender = user.gender
    })
  }
}
</script>

<style lang="scss" scoped>
.popup__item {
  display: flex;
  justify-content: center;
}
.popup__item input[type='radio'] {
  display: none;
}
.popup__item label {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0px 10px;
  line-height: 34px;
  border-radius: 4px;
  user-select: none;
}

/* Checked */
.popup__item input[type='radio']:checked + label {
  background: #689bcc;
  color: white;
}
</style>
