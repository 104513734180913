<template>
  <Popup :visible="visible" @close="close" description="Отправить событие в архив?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteEvent">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EVENT_DELETE_POPUP } from '@/constants/event'
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_DELETE_EVENT } from '@/store/action-types'
import { GET_EVENTS_FILTERS } from '@/store/getter-types'

export default {
  name: 'EventDeletePopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data() {
    return {
      id: ''
    }
  },
  computed: {
    ...mapGetters({
      getFilters: GET_EVENTS_FILTERS
    })
  },
  methods: {
    ...mapActions({
      deleteCategory: REQUEST_DELETE_EVENT
    }),
    deleteEvent() {
      this.deleteCategory({
        id: this.id,
        data: this.getFilters
      })
      this.id = ''
      this.close()
    }
  },

  mounted() {
    this.$root.$on(EVENT_DELETE_POPUP, eventcat => {
      this.open()
      this.id = eventcat
    })
  }
}
</script>
