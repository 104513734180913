<template>
  <Popup :visible="visible" @close="close" description="Удалить профиль?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteHandler('approve')">
          Да
        </button>
        <button class="popup__btn btn-red" @click="deleteHandler('reject')">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>
<script>
import { mapActions } from 'vuex'
import { REQUEST_DELETE_EMPLOYEE } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { DELETE_EMPLOYEE_POPUP_EVENT } from '@/constants/event'
export default {
  name: 'DeleteEmployeePopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    currentEmployeeId: null
  }),

  mounted() {
    this.$root.$on(DELETE_EMPLOYEE_POPUP_EVENT, id => {
      this.currentEmployeeId = id
      this.open()
    })
  },

  methods: {
    ...mapActions({
      approveDelete: REQUEST_DELETE_EMPLOYEE
    }),

    closePopup() {
      this.close()
    },
    deleteHandler(method) {
      switch (method) {
        case 'approve':
          this.approveDelete(this.currentEmployeeId).then(response => {
            if (response.status) {
              this.$root.$emit('ChangeEmployee', 'deleteEmployee')
              this.close()
            }
          })
          break
        case 'reject':
          this.close()
          break
        default:
          break
      }
    }
  }
}
</script>
