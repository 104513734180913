<template>
  <div class="faq-categoryes-wrapper">
    <ul class="faq-categoryes-list" v-if="allCategory.length">
      <li
        class="faq-categoryes-list__item"
        :class="{ 'faq-categoryes-list__link--active': index == itemActive }"
        v-for="(item, index) in allCategory"
        :key="item.id + item.icon"
        @click="getContentById(item.id, index)"
      >
        <a href="#" class="faq-categoryes-list__link">
          <h3 class="faq-categoryes-list__title">
            <i :class="item.icon ? item.icon : 'fa fa-user'"></i>
            {{ item.title }}
          </h3>

          <span class="faq-categoryes-list__property">
            <a
              @click.stop="$root.$emit('FAQ_POPUP_UPDATE_CATEGORY_EVENT', item)"
              class="faq-categoryes-list__property-item faq-categoryes-list__property-item--active-update"
              title="Редактировать"
            >
              <i class="fa fa-edit"></i>
            </a>
            <a
              @click.stop="$root.$emit('FAQ_POPUP_REMOVE_ELEMENT_CATEGORY', item.id)"
              class="faq-categoryes-list__property-item faq-categoryes-list__property-item--active-delete"
              title="Удалить"
            >
              <i class="fa fa-times-circle"></i>
            </a>
          </span>
        </a>
      </li>
    </ul>
    <div class="intarface-faq__content-error" v-if="!allCategory.length">
      <span class="intarface-faq__content-error-text">Нет категорий...</span>
    </div>
    <button
      style="width: 100%"
      type="button"
      class="button--add-category"
      @click="$root.$emit('FAQ_POPUP_NEW_CATEGORY_EVENT')"
    >
      <i class="fa fa-plus"></i> Добавить категорию
    </button>

    <faq-popup-update-category :allLang="allLang" :listIcons="listIcons" />

    <faq-popup-add-new-category :allLang="allLang" :listIcons="listIcons" :categories="categories" />

    <faq-popup-remove-element-category-by-id />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

import {
  GET_CONTENT_BY_ID,
  GET_CURRENT_CONTENT_ID,
  GET_LANGUAGES,
  GET_CURRENT_ITEM_ACTIVE,
  GET_ALL_CATEGORY_BY_LANG
} from '@/store/getter-types'

import { SET_CURRENT_CONTENT_ID, SET_CURRENT_ITEM_ACTIVE } from '@/store/mutation-types'

import {
  REQUEST_CONTENT_BY_ID,
  REQUEST_UPDATE_CATEGORY_BY_ID,
  REQUEST_CREATE_NEW_CATEGORY
} from '@/store/action-types'
import FaqPopupAddNewCategory from '../Modals/FaqPopup/FaqPopupAddNewCategory.vue'
import FaqPopupUpdateCategory from '../Modals/FaqPopup/FaqPopupUpdateCategory.vue'

import store from '@/store'
import FaqPopupRemoveElementCategoryById from '../Modals/FaqPopup/FaqPopupRemoveElementCategoryById.vue'

export default {
  components: {
    FaqPopupAddNewCategory,
    FaqPopupUpdateCategory,
    FaqPopupRemoveElementCategoryById
  },
  name: 'FaqCategoryesList',

  props: {
    allCategory: {
      type: Array
    }
  },

  data() {
    return {
      newCategory: {},
      newCategoryData: {},
      currentCategoryId: null,
      testIco: 'user-secret',
      listIcons: ['fa fa-user', 'fa fa-user-secret', 'fa fa-search', 'fa fa-shopping-cart', 'far fa-comments']
    }
  },

  // beforeRouteEnter(to, from, next) {
  //   store
  //     .dispatch(REQUEST_CONTENT_BY_ID, this.currentContentId)
  //     .then(response => {
  //       next(vm => {
  //         if (!response.status) {
  //           vm.errorMessage = true;
  //         }
  //       });
  //     });
  // },

  // Если через created то страница будет грузиться после прихода данных и прелоадер пропадает только после этого.
  // created() {
  //   this.getContent(this.currentContentId);
  // },

  computed: {
    ...mapGetters({
      currentContent: GET_CONTENT_BY_ID,
      currentContentId: GET_CURRENT_CONTENT_ID,
      allLang: GET_LANGUAGES,
      itemActive: GET_CURRENT_ITEM_ACTIVE,
      categories: GET_ALL_CATEGORY_BY_LANG
    })
  },

  methods: {
    ...mapActions({
      getContent: REQUEST_CONTENT_BY_ID,
      updateCategoryId: REQUEST_UPDATE_CATEGORY_BY_ID,
      createNewCategory: REQUEST_CREATE_NEW_CATEGORY
    }),

    ...mapMutations({
      setCurrentContentId: SET_CURRENT_CONTENT_ID,
      setItemActive: SET_CURRENT_ITEM_ACTIVE
    }),

    getContentById(id, index) {
      this.setItemActive(index)
      this.setCurrentContentId(id)
      this.getContent(id)
    },

    removeCategory(id) {
      this.removeLangById(id)
    }
  }
}
</script>

<style lang="scss">
@import './FaqCategoryesList';
</style>
