<template>
  <Popup :visible="visible" @close="close" description="Удалить подарок?" modalClass="popup--small">
    <form @submit.prevent="deleteGift">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green">Удалить</button>
        <button type="button" @click="closeModal" class="popup__btn btn-red">
          Отмена
        </button>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapActions } from 'vuex'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { GIFT_DELETE_POPUP_EVENT } from '@/constants/event'

import { REQUEST_DELETE_GIFTS_BY_ID } from '@/store/action-types'

export default {
  name: 'GiftsPopupDelete',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data() {
    return {
      giftId: null
    }
  },

  props: {
    allLang: {
      type: Array
    },

    listIcons: {
      type: Array
    }
  },

  mounted() {
    this.$root.$on(GIFT_DELETE_POPUP_EVENT, giftId => {
      this.giftId = giftId
      this.open()
    })
  },

  methods: {
    closeModal() {
      this.giftId = null
      this.close()
    },

    ...mapActions({ deleteGiftById: REQUEST_DELETE_GIFTS_BY_ID }),

    deleteGift() {
      this.deleteGiftById(this.giftId)

      this.giftId = null

      this.close()
    }
  }
}
</script>

<style lang="scss">
@import './FaqPopupStyle.scss';

.button--remove {
  background-color: red;
}
</style>
