<template>
  <div class="table-statistic__row_sublist">
    <span class="table-statistic__item_subitem">
      {{ timeStart }}
    </span>
    <span class="table-statistic__item_subitem">{{ statistic.pay }}</span>
    <span class="table-statistic__item_subitem">{{ secondsToMinute }}</span>
    <span class="table-statistic__item_subitem">{{ statistic.in }}</span>
    <span class="table-statistic__item_subitem">{{ statistic.out }}</span>
    <span class="table-statistic__item_subitem">{{ statistic.meeting_duration }}</span>
    <span class="table-statistic__item_subitem">{{ statistic.meeting_sum }}</span>
    <!-- <span class="table-statistic__item_subitem">{{ statistic.country_category }}</span>
      <span class="table-statistic__item_subitem">{{ statistic.currency }}</span> -->
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'OperatorStatisticListItem',
  props: {
    statistic: {
      type: Object
    }
  },
  computed: {
    timeStart() {
      return this.statistic.date.replace('T', ' ')
    },
    secondsToMinute() {
      return moment.utc(this.statistic.average_time * 1000).format('Dд. HHч. mmмин. sscек.')
    }
  }
}
</script>

<style lang="scss" scoped>
// @import 'OperatorStatistic';

.table-statistic {
  //table-statistic__row
  &__row_sublist {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: rgba(255, 255, 255, 0.829);
    &:nth-child(2n) {
      // background-color: rgba(204, 240, 205, 0.859);
      background-color: rgba(159, 252, 254, 0.275);
      // background-color: rgba(254, 226, 159, 0.801);
      // background-color: rgba(252, 230, 177, 0.653);
    }
  }
  //table-statistic__item
  &__item_subitem {
    font-size: 13px;
    display: flex;
    width: 9%;
    align-items: center;
    padding: 10px 2px;
    justify-content: center;
    &:first-child {
      align-items: center;
      width: 19%;
    }
    &--cross {
      font-size: 11px;
      color: #28c232;
    }
  }
}
</style>
