<template>
  <div class="statistics">
    <h2 class="statistics__title">Количество пользователей</h2>
    <StatisticUserCounts v-if="isLoadedUsersCount" :usersCount="usersCount" />
    <div v-if="isLoadedUsersCountError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке данных</span>
    </div>

    <h2 class="statistics__title">
      Статистика регистрации и активности пользователей
    </h2>
    <StatisticUserActivitys v-if="isLoadedActivity" :usersActivity="usersActivity" />
    <div v-if="isLoadedActivityError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Статистика регистрации и активности пользователей с сайта и приложения</h2>
    <StatisticNewUsers v-if="isLoadedNewUsers" :newUsers="newUsers" />
    <div v-if="isLoadedNewUsersError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Статистика online</h2>
    <StatisticOnline v-if="isLoadedOnlineOneDay" :onlineByDay="onlineByDay" />
    <div v-if="isLoadedOnlineOneDayError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Статистика online с сайта и приложения</h2>
    <StatisticOnlineSiteAndApp v-if="isLoadedOnlineOneDay" :usersOnlinesource="usersOnlinesource" />
    <div v-if="isLoadedOnlineOneDayError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Статистика online по городам</h2>
    <StatisticOnlineByCity v-if="isLoadedOnlineByCity" :onlineByCity="onlineByCity" />

    <h2 class="statistics__title">
      Статистика по активности новых пользователей
    </h2>
    <StatisticActiveNewUser v-if="isLoadedActiveNewUser" :activeNewUser="activeNewUser" />
    <div v-if="isLoadedActiveNewUserError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">
      Статистика активности новых пользователей по дате
    </h2>
    <StatisticActiveNewUserByDate
      v-if="isLoadedActiveNewUserByDate"
      :newUserActiveByDate="newUserActiveByDate"
    />
    <div v-if="isLoadedActiveNewUserByDateError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Время жизни пользователя</h2>
    <StatisticLifetime v-if="isLoadedOnlineAppSite" :usersLifetime="usersLifetime" />
    <div v-if="isLoadedOnlineAppSiteError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Статистика по полу</h2>
    <StatisticUserGender v-if="isLoadedUserGender" :usersGender="usersGender" />
    <div v-if="isLoadedUserGenderError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <StatisticUsersAge v-if="isLoadedAge" :usersAge="usersAge" />
    <div v-if="isLoadedAgeError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <div v-if="errorMessage" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке </span>
    </div>
  </div>
</template>
<script>
import StatisticUserCounts from '@/components/Statistic/StatisticUserCounts'
import StatisticUserActivitys from '@/components/Statistic/StatisticUserActivitys'
import StatisticOnlineByCity from '@/components/Statistic/StatisticOnlineByCity'
import StatisticOnline from '@/components/Statistic/StatisticOnline'
import StatisticActiveNewUserByDate from '@/components/Statistic/StatisticActiveNewUserByDate'
import StatisticUserGender from '@/components/Statistic/StatisticUserGender'
import StatisticActiveNewUser from '@/components/Statistic/StatisticActiveNewUser'
import StatisticUsersAge from '@/components/Statistic/StatisticUsersAge'
import StatisticOnlineSiteAndApp from '@/components/Statistic/StatisticOnlineSiteAndApp'
import StatisticLifetime from '@/components/Statistic/StatisticLifetime'
import StatisticNewUsers from '@/components/Statistic/StatisticNewUsers'
import { mapGetters } from 'vuex'
import {
  REQUEST_USERS_ACTIVITY,
  REQUEST_USERS_COUNT,
  REQUEST_USERS_ONLINE_BY_CITY,
  REQUEST_USERS_ONLINE_ONE_DAY,
  REQUEST_NEW_USER_ACTIVE_BY_DATE,
  REQUEST_USERS_GENDER,
  REQUEST_ACTIVE_NEW_USER,
  REQUEST_USERS_AGE,
  REQUEST_USER_LIFETIME,
  REQUEST_USERS_ONLINE_ONE_DAY_SITE_AND_APP,
  REQUSET_REGS_NEW_USERS
} from '@/store/action-types'

import {
  GET_USERS_COUNT,
  GET_USERS_ACTIVITY,
  GET_USERS_ONLINE_BY_CITY,
  GET_USERS_ONLINE_ONE_DAY,
  GET_NEW_USER_ACTIVE_BY_DATE,
  GET_USERS_GENDER,
  GET_ACTIVE_NEW_USER,
  GET_USERS_AGE,
  GET_USER_LIFETIME,
  GET_USERS_ONLINE_ONE_DAY_SITE_AND_APP,
  GET_REGS_NEW_USERS
} from '@/store/getter-types'
import store from '@/store'
import moment from 'moment'

export default {
  name: 'StatisticsUsers',
  components: {
    StatisticLifetime,
    StatisticOnline,
    StatisticOnlineByCity,
    StatisticUserCounts,
    StatisticUserActivitys,
    StatisticActiveNewUserByDate,
    StatisticUserGender,
    StatisticActiveNewUser,
    StatisticUsersAge,
    StatisticNewUsers,
    StatisticOnlineSiteAndApp
  },
  data: () => ({
    errorMessage: false,
    isLoadedUsersCount: false,
    isLoadedUsersCountError: false,
    isLoadedOnlineAppSiteError: false,
    isLoadedOnlineAppSite: false,
    isLoadedActivity: false,
    isLoadedActivityError: false,
    isLoadedAppsFlyer: false,
    isLoadedAppsFlyerError: false,
    isLoadedOnlineByCityError: false,
    isLoadedOnlineByCity: false,
    isLoadedPayments: false,
    isLoadedPaymentsError: false,
    isLoadedOnlineOneDayError: false,
    isLoadedOnlineOneDay: false,
    isLoadedActiveNewUserByDateError: false,
    isLoadedActiveNewUserByDate: false,
    isLoadedModeratorsPhotoCheckError: false,
    isLoadedModeratorsPhotoCheck: false,
    isLoadedUserGender: false,
    isLoadedUserGenderError: false,
    isLoadedUsersGenderError: false,
    isLoadedUsersGender: false,
    isLoadedActiveNewUser: false,
    isLoadedActiveNewUserError: false,
    isLoadedUsersPaying: false,
    isLoadedUsersPayingError: false,
    isLoadedAge: false,
    isLoadedAgeError: false,
    isLoadedUsersLifetime: false,
    isLoadedUsersLifetimeError: false,
    isLoadedNewUsers: false,
    isLoadedNewUsersError: false,
    countCityDefault: {
      city_count: 20
    }
  }),
  beforeRouteEnter(to, from, next) {
    let date = {
      date_from: moment()
        .subtract(14, 'days')
        .startOf('day')
        .format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      delta: new Date().getTimezoneOffset() / -60
    }
    let dateThreeMounth = {
      date_from: moment()
        .subtract(3, 'months')
        .startOf('day')
        .format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD')
    }
    store.dispatch(REQUEST_USERS_COUNT).then(response => {
      next(vm => {
        if (!response.status) {
          vm.isLoadedUsersCountError = true
        } else {
          vm.isLoadedUsersCount = true
        }
        store.dispatch(REQUEST_USERS_ACTIVITY, date).then(response => {
          if (!response.status) {
            vm.isLoadedActivityError = true
          } else {
            vm.isLoadedActivity = true
          }
        })
        store.dispatch(REQUEST_USERS_ONLINE_BY_CITY, vm.countCityDefault).then(response => {
          if (!response.status) {
            vm.isLoadedOnlineByCityError = true
          } else {
            vm.isLoadedOnlineByCity = true
          }
        })
        store
          .dispatch(REQUEST_USERS_ONLINE_ONE_DAY, { date: date.date_to, delta: date.delta })
          .then(response => {
            if (!response.status) {
              vm.isLoadedOnlineOneDayError = true
            } else {
              vm.isLoadedOnlineOneDay = true
            }
          })

        store.dispatch(REQUEST_NEW_USER_ACTIVE_BY_DATE, { date: date.date_to }).then(response => {
          if (!response.status) {
            vm.isLoadedActiveNewUserByDateError = true
          } else {
            vm.isLoadedActiveNewUserByDate = true
          }
        })
        store.dispatch(REQUEST_USERS_GENDER, { date: date.date_to, delta: date.delta }).then(response => {
          if (!response.status) {
            vm.isLoadedUserGenderError = true
          } else {
            vm.isLoadedUserGender = true
          }
        })
        store
          .dispatch(REQUEST_USERS_ONLINE_ONE_DAY_SITE_AND_APP, {
            date: date.date_to,
            delta: date.delta,
            source_user: 'site'
          })
          .then(response => {
            if (!response.status) {
              vm.isLoadedOnlineAppSiteError = true
            } else {
              vm.isLoadedOnlineAppSite = true
            }
          })
        store.dispatch(REQUEST_USERS_AGE).then(response => {
          if (!response.status) {
            vm.isLoadedAgeError = true
          } else {
            vm.isLoadedAge = true
          }
        })
        store.dispatch(REQUEST_ACTIVE_NEW_USER, { date: date.date_to }).then(response => {
          if (!response.status) {
            vm.isLoadedActiveNewUserError = true
          } else {
            vm.isLoadedActiveNewUser = true
          }
        })

        store.dispatch(REQUEST_USER_LIFETIME, dateThreeMounth).then(response => {
          if (!response.status) {
            vm.isLoadedUsersLifetimeError = true
          } else {
            vm.isLoadedUsersLifetime = true
          }
        })
        store.dispatch(REQUSET_REGS_NEW_USERS, date).then(response => {
          if (!response.status) {
            vm.isLoadedNewUsersError = true
          } else {
            vm.isLoadedNewUsers = true
          }
        })
      })
    })
  },

  computed: {
    ...mapGetters({
      usersCount: GET_USERS_COUNT,
      usersActivity: GET_USERS_ACTIVITY,
      onlineByCity: GET_USERS_ONLINE_BY_CITY,
      onlineByDay: GET_USERS_ONLINE_ONE_DAY,
      newUserActiveByDate: GET_NEW_USER_ACTIVE_BY_DATE,
      usersGender: GET_USERS_GENDER,
      activeNewUser: GET_ACTIVE_NEW_USER,
      usersAge: GET_USERS_AGE,
      usersLifetime: GET_USER_LIFETIME,
      usersOnlinesource: GET_USERS_ONLINE_ONE_DAY_SITE_AND_APP,
      newUsers: GET_REGS_NEW_USERS
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>
<style lang="scss">
.statistics {
  width: 100%;
  &__title {
    margin: 10px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #c9cdd7;
  }
  &__error {
    margin-top: 20px;
    padding: 0 16px 0 16px;
    min-height: 64px;
    border-radius: 2px;

    position: relative;
    display: flex;
    align-items: center;
    background-color: white;

    &-text {
      margin: 0 auto;
    }
  }
}
.regenterstats-chart {
  height: 290px;
}
.table-condensed {
  td:hover {
    background: #318bcc;
    color: #fff;
  }
  .today {
    background: #48a7eb;
    color: #fff;
  }
}
</style>
