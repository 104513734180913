<template>
  <div class="app-user-info-body" v-click-outside="close">
    <div class="app-user-main-photo">
      <div class="app-user-info-photo">
        <video
          v-if="currentUser.avatar_video"
          class="users-content_video"
          :src="getMediaLink(currentUser.avatar_video.content_id, 'video')"
          ref="videocontainer"
          poster
          loop="loop"
          muted="muted"
          autoplay
          no-controls
        ></video>
        <img
          v-else
          :src="
            getFile({
              type: 'image',
              file: currentUser.avatar.id
            })
          "
          alt=""
        />
      </div>
      <div class="app-user-info">
        <div class="app-user-info__item">
          <span class="app-user-info__key">
            <FaIcon
              :iconClass="'fas fa-award'"
              class="app-user-info__key-icon app-user-info__key-icon--buy"
            />
            <span class="app-user-info__key-text">Рейтинг событий:</span>
          </span>
          <span class="app-user-info__value">-</span>
        </div>
        <div class="app-user-info__item">
          <span class="app-user-info__key">
            <FaIcon
              :iconClass="'fas fa-map-marked-alt'"
              class="app-user-info__key-icon app-user-info__key-icon--buy"
            />
            <span class="app-user-info__key-text">Количество посещенных событий:</span>
          </span>
          <span v-if="currentUser.count.event_invite" class="app-user-info__value">{{
            currentUser.count.event_invite
          }}</span>
          <span v-else class="app-user-info__value">-</span>
        </div>
        <div class="app-user-info__item">
          <span class="app-user-info__key">
            <FaIcon
              :iconClass="'fas fa-map-marked-alt'"
              class="app-user-info__key-icon app-user-info__key-icon--gift"
            />
            <span class="app-user-info__key-text">Количество созданных событий:</span>
          </span>
          <span v-if="currentUser.count.event" class="app-user-info__value">{{
            currentUser.count.event
          }}</span>
          <span v-else class="app-user-info__value">-</span>
        </div>
        <div class="app-user-info__item">
          <span class="app-user-info__key">
            <FaIcon :iconClass="'fas fa-star'" class="app-user-info__key-icon app-user-info__key-icon--buy" />
            <span class="app-user-info__key-text">Рейтинг:</span>
          </span>
          <span class="app-user-info__value" v-if="currentUser.rating">{{ currentUser.rating }}</span>
          <span v-else class="app-user-info__value">-</span>
        </div>
      </div>
    </div>

    <div class="app-user-info">
      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <FaIcon
            :iconClass="'far fa-envelope'"
            class="app-user-info__key-icon app-user-info__key-icon--mail"
          />
          <span class="app-user-info__key-text">Email:</span>
        </span>
        <span class="app-user-info__value" v-if="currentUser.email">{{ currentUser.email }}</span>
        <span v-else class="app-user-info__value">-</span>
      </div>
      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <FaIcon
            :iconClass="'fab fa-facebook-square'"
            class="app-user-info__key-icon app-user-info__key-icon--fb"
          />
          <span class="app-user-info__key-text">Facebook:</span>
        </span>
        <a
          v-if="currentUser.social.fb"
          :href="`http://facebook.com/profile.php?id=${currentUser.social.fb}`"
          target="_blank"
          class="app-user-info__value"
          >facebook</a
        >
        <span v-else class="app-user-info__value">-</span>
      </div>
      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <FaIcon :iconClass="'fab fa-vk'" class="app-user-info__key-icon app-user-info__key-icon--vk" />
          <span class="app-user-info__key-text">Вконтакте:</span>
        </span>
        <a
          v-if="currentUser.social.vK"
          :href="`http://vk.com/id${currentUser.social.vK}`"
          target="_blank"
          class="app-user-info__value"
          >Вконтакте</a
        >
        <span v-else class="app-user-info__value">-</span>
      </div>
      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <FaIcon
            :iconClass="'fas fa-map-marked-alt'"
            class="app-user-info__key-icon app-user-info__key-icon--loca"
          />
          <span class="app-user-info__key-text">Местоположение:</span>
        </span>
        <template v-if="currentUser.location.address">
          <span class="app-user-info__value">{{ currentUser.location.address }}</span>
        </template>
        <template v-else>
          <span class="app-user-info__value">-</span>
        </template>
      </div>
      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <FaIcon
            :iconClass="'fas fa-calendar-day'"
            class="app-user-info__key-icon app-user-info__key-icon--bday"
          />
          <span class="app-user-info__key-text">Дата рождения:</span>
        </span>
        <template v-if="currentUser.birthday">
          <span class="app-user-info__value">{{ toLocaleDateUserWithoutTime(currentUser.birthday) }}</span>
        </template>
        <template v-else>
          <span class="app-user-info__value">-</span>
        </template>
      </div>
      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <FaIcon
            :iconClass="'fas fa-user-tag'"
            class="app-user-info__key-icon app-user-info__key-icon--reg"
          />
          <span class="app-user-info__key-text">Регистрация:</span>
        </span>
        <template v-if="currentUser.reg_date">
          <span class="app-user-info__value"
            >{{ toLocaleDateUserWithoutTime(currentUser.reg_date) }} {{ timeReg }}</span
          >
        </template>
        <template v-else>
          <span class="app-user-info__value">-</span>
        </template>
      </div>
      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <FaIcon
            :iconClass="'fas fa-user-clock'"
            class="app-user-info__key-icon app-user-info__key-icon--last-visit"
          />
          <span class="app-user-info__key-text">Посл. вход: </span>
        </span>
        <span class="app-user-info__value"
          >{{ toLocaleDateUserWithoutTime(currentUser.online.lastVisit) }} {{ lastIn }}</span
        >
      </div>
      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <FaIcon
            :iconClass="'fas fa-heartbeat'"
            class="app-user-info__key-icon app-user-info__key-icon--online"
          />
          <span class="app-user-info__key-text">Онлайн:</span>
        </span>
        <template v-if="currentUser.online.lastVisit">
          <span class="app-user-info__value">{{ textOnline }}</span>
        </template>
        <template v-else>
          <span class="app-user-info__value">-</span>
        </template>
      </div>
      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <FaIcon
            :iconClass="'fas fa-mobile-alt'"
            class="app-user-info__key-icon app-user-info__key-icon--version"
          />
          <span class="app-user-info__key-text">Устройство:</span>
        </span>
        <div class="app-user-info__version-body" v-if="currentUser.devices.length > 0">
          <button class="app-user-info__version-btn" @click="versionOpen = !versionOpen">
            {{ currentUser.devices[currentUser.devices.length - 1].os }}
            {{ currentUser.devices[currentUser.devices.length - 1].app }}
          </button>
          <ul class="app-user-info__version-list" v-if="versionOpen && currentUser.devices > 1">
            <li class="app-user-info__version-item" v-for="device in currentUser.devices" :key="device.id">
              {{ device.device }} {{ device.app_version }}
            </li>
          </ul>
        </div>
        <div class="app-user-info__version-body" v-else>-</div>
      </div>
      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <FaIcon
            :iconClass="'fas fa-shopping-basket'"
            class="app-user-info__key-icon app-user-info__key-icon--buy"
          />
          <span class="app-user-info__key-text">Покупки:</span>
        </span>
        <!--        <span v-if="???">-</span>-->
        <span
          v-if="purchases.purchases"
          v-tooltip="'Список покупок'"
          @click="purchases.total.amount != 0 ? $root.$emit(USER_BUY_LIST_PURCHASES) : ''"
          class="app-user-info__value app-user-info__value--buy"
          >{{ purchaseAmount }}</span
        >
        <span v-else> - </span>
      </div>
      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <FaIcon :iconClass="'fa fa-users'" class="app-user-info__key-icon app-user-info__key-icon--buy" />
          <span class="app-user-info__key-text">Знак зодиака:</span>
        </span>
        <span class="app-user-info__value" v-if="currentUser.zodiac">{{ zodiacText }}</span>
        <span class="app-user-info__value" v-else>-</span>
      </div>

      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <FaIcon :iconClass="'fas fa-gift'" class="app-user-info__key-icon app-user-info__key-icon--gift" />
          <span class="app-user-info__key-text">Подарки:</span>
        </span>
        <span v-if="currentUser.gifts.length" class="app-user-info__value">{{
          currentUser.gifts.length
        }}</span>
        <span class="app-user-info__value" v-else>-</span>
      </div>
      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <svg
            class="app-user-info__key-icon app-user-info__key-icon--slcoins"
            width="16"
            height="13"
            viewBox="0 0 27 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.80449 22.9982L2.8042 23L12.833 23L12.4997 25H16.4997L16.8333 22.9982C19.3028 22.9634 21.2558 22.4073 22.6921 21.3301C24.1862 20.2168 25.0016 18.6299 25.1383 16.5693C25.2067 15.4951 25.0065 14.5771 24.5378 13.8154C24.0788 13.0439 23.4342 12.5117 22.6042 12.2187C24.9186 11.2812 26.1491 9.68945 26.2956 7.44336C26.4128 5.62695 25.8122 4.21582 24.4938 3.20996C23.472 2.43032 22.0423 1.94989 20.2049 1.76868L20.4997 0H16.4997L16.219 1.68413L6.19193 1.67365L6.17609 1.76868C8.01349 1.94988 9.44313 2.43031 10.465 3.20996C11.7834 4.21582 12.384 5.62695 12.2668 7.44336C12.1203 9.68945 10.8898 11.2812 8.57536 12.2188C9.40544 12.5117 10.05 13.0439 10.509 13.8154C10.9777 14.5771 11.1779 15.4951 11.1095 16.5693C10.9728 18.6299 10.1574 20.2168 8.66325 21.3301C7.22694 22.4073 5.27402 22.9634 2.80449 22.9982ZM13.7272 19.0449L14.6208 13.9326L18.3122 13.9473C19.8356 14.0254 20.4606 14.8799 20.1872 16.5107C20.07 17.2822 19.6989 17.9023 19.0739 18.3711C18.4489 18.8301 17.6676 19.0596 16.7301 19.0596L13.7272 19.0449ZM17.5505 10.5781L15.1921 10.5635L16.0563 5.6416L18.7223 5.65625C20.6169 5.66601 21.4469 6.47168 21.2126 8.07324C20.9489 9.69434 19.7282 10.5293 17.5505 10.5781ZM0 19.0532L0.893555 13.9409L4.58496 13.9556C6.1084 14.0337 6.7334 14.8882 6.45996 16.519C6.34277 17.2905 5.97168 17.9106 5.34668 18.3794C4.72168 18.8384 3.94043 19.0679 3.00293 19.0679L0 19.0532ZM3.82324 10.5864L1.46484 10.5718L2.3291 5.6499L4.99512 5.66455C6.88965 5.67432 7.71973 6.47998 7.48535 8.08154C7.22168 9.70264 6.00098 10.5376 3.82324 10.5864Z"
            />
          </svg>
          <span class="app-user-info__key-text">Баллы:</span>
        </span>
        <span @click="$root.$emit(USER_TRANSACTION_KIND_LIST)" class="app-user-info__value--points">{{
          discharge(currentUser.finance.points)
        }}</span>
      </div>
      <div class="app-user-info__item">
        <span class="app-user-info__key">
          <FaIcon
            :iconClass="'fas fa-search'"
            class="app-user-info__key-icon app-user-info__key-icon--search"
          />
          <span class="app-user-info__key-text">Параметры поиска:</span>
        </span>
        <span class="app-user-info__value"
          >{{ genderSearch }} от {{ currentUser.search.age_min }} до {{ currentUser.search.age_max }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_USER_PURCHASES } from '@/store/getter-types'
import dateFormat from '@/components/mixins/toLocaleformat'
import getImgLink from '@/components/mixins/getImgCdnLink'
import { USER_BUY_LIST_PURCHASES, USER_TRANSACTION_KIND_LIST } from '@/constants/event'

export default {
  name: 'AppUserInfo',
  props: {
    currentUser: {
      type: Object
    }
  },
  mixins: [dateFormat, getImgLink],

  data: () => ({
    USER_BUY_LIST_PURCHASES,
    USER_TRANSACTION_KIND_LIST,
    versionOpen: false,
    openDeposit: false
  }),
  computed: {
    ...mapGetters({
      purchases: GET_USER_PURCHASES
    }),
    textOnline() {
      return this.currentUser.online.enabled ? 'В сети' : 'Не в сети'
      //в сети - не в сети enabled переписать true/
      // const dateNow = parseInt(+new Date() / 1000);
      // const date =
      //   dateNow - +Date.parse(this.currentUser.online.lastVisit) / 1000;
      // let text;
      // if (date >= 900) {
      //   text = "Нет в сети";
      // } else if (date > 300 && date < 900) {
      //   text = "Был недавно";
      // } else {
      //   text = "В сети";
      // }
      // return text;
    },

    timeReg() {
      const date = new Date(this.currentUser.reg_date).toLocaleTimeString()
      const newDate = date.slice(0, 5)
      return newDate
    },

    lastIn() {
      const date = new Date(this.currentUser.online.lastVisit).toLocaleTimeString()
      const newDate = date.slice(0, 5)
      return newDate
    },

    genderSearch() {
      let gender = ''
      switch (this.currentUser.search.gender) {
        case 1:
          gender = 'Муж'
          break
        case 2:
          gender = 'Жен'
          break
        case 0:
          gender = 'Все'
          break
        default:
          break
      }
      return gender
    },
    zodiacText() {
      let zodiac = ''
      switch (this.currentUser.zodiac) {
        case 1:
          zodiac = 'Овен'
          break
        case 2:
          zodiac = 'Телец'
          break
        case 3:
          zodiac = 'Близнецы'
          break
        case 4:
          zodiac = 'Рак'
          break
        case 5:
          zodiac = 'Лев'
          break
        case 6:
          zodiac = 'Дева'
          break
        case 7:
          zodiac = 'Весы'
          break
        case 8:
          zodiac = 'Скорпион'
          break
        case 9:
          zodiac = 'Стрелец'
          break
        case 10:
          zodiac = 'Козерог'
          break
        case 11:
          zodiac = 'Водолей'
          break
        case 12:
          zodiac = 'Рыбы'
          break
        default:
          break
      }
      return zodiac
    },

    purchaseAmount() {
      return `${this.purchases.total.amount.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')} ${
        this.purchases.total.currency
      }`
    }
  },
  methods: {
    close() {
      this.versionOpen = false
    },
    discharge(number) {
      return number.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
    }
  }
}
</script>

<style lang="scss">
@import './App-user-info';
.users-content_video {
  width: 100%;
  height: 605px;
  object-fit: cover;
}
</style>
