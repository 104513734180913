<template>
  <li class="left-bar__main" v-if="property !== setting.settings_name">
    <span class="left-bar__text">{{ nameFields(property) }}</span>
    <span class="left-bar__value">{{ value }}</span>
    <input
      class="left-bar__input"
      v-model.number="newValue"
      :placeholder="oldData"
      type="text"
      ref="coefficient"
    />
    <button v-if="isNew" key="save" class="left-bar__btn left-bar__btn--green" @click="saveData">
      <i class="fas fa-save"></i>
    </button>
    <button v-if="!isNew" key="disabled" class="left-bar__btn">
      <i class="fas fa-save"></i>
    </button>
  </li>
</template>

<script>
import { mapActions } from 'vuex'
import { REQUEST_UPDATE_MODERATOR_SETTINGS_QUALITY } from '@/store/action-types'

export default {
  name: 'ModeratorsSettingItem',
  props: {
    value: {
      type: Number
    },
    property: {
      type: String
    },
    nameFields: {
      type: Function
    },
    setting: {
      type: Object
    }
  },
  data: () => ({
    oldData: 0,
    newValue: null,
    isNew: false
  }),
  computed: {
    coefficientSetting: {
      get() {
        return this.newValue ? this.newValue : this.oldData
      },
      set(value) {
        this.$refs.coefficient.value = +value
        this.newValue = +value
      }
    }
  },
  watch: {
    newValue: function() {
      if (this.newValue !== this.oldData) {
        this.isNew = true
      } else {
        this.isNew = false
      }
    },
    value: function() {
      this.oldData = this.value
      // this.newValue = 0
    }
  },
  mounted() {
    this.oldData = this.value
    this.newValue = this.value
  },
  methods: {
    ...mapActions({
      getNewOptions: REQUEST_UPDATE_MODERATOR_SETTINGS_QUALITY
    }),
    saveData() {
      let newData = {
        id: this.setting.id,
        controller_select: this.setting.controller_select
      }
      newData.controller_select[this.property] = this.newValue
      this.getNewOptions(newData).then(response => {
        this.isNew = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.left-bar__input {
  &::placeholder {
    color: #318bcc;
  }
}
</style>
