const REQUEST_ADD_REASON_DELETE = 'REQUEST_ADD_REASON_DELETE'
const REQUEST_UPDATE_REASON_DELETE = 'REQUEST_UPDATE_REASON_DELETE'
const REQUEST_DELETE_REASON_DELETE = 'REQUEST_DELETE_REASON_DELETE'
const REQUEST_REASON_DELETE = 'REQUEST_REASON_DELETE'
const REQUEST_ADD_REASON_BLOCK = 'REQUEST_ADD_REASON_BLOCK'
const REQUEST_UPDATE_REASON_BLOCK = 'REQUEST_UPDATE_REASON_BLOCK'
const REQUEST_DELETE_REASON_BLOCK = 'REQUEST_DELETE_REASON_BLOCK'
const REQUEST_REASON_BLOCK = 'REQUEST_REASON_BLOCK'

export {
  REQUEST_ADD_REASON_DELETE,
  REQUEST_UPDATE_REASON_DELETE,
  REQUEST_DELETE_REASON_DELETE,
  REQUEST_REASON_DELETE,
  REQUEST_ADD_REASON_BLOCK,
  REQUEST_UPDATE_REASON_BLOCK,
  REQUEST_DELETE_REASON_BLOCK,
  REQUEST_REASON_BLOCK
}
