<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Снять верификацию по возрасту?"
    modalClass="popup--small"
  >
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="verifyHandler('approve')">
          Да
        </button>
        <button class="popup__btn btn-red" @click="declineAgeVerification">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_DECLINE_BY_AGE } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_USER_VERIFICATION } from '@/store/action-types'

export default {
  name: 'DeclineVerifyByAge',
  components: {
    Popup
  },
  data() {
    return {
      id: '',
      userVerification: null
    }
  },
  mixins: [ModalMixin],

  methods: {
    ...mapActions({
      verifyUser: REQUEST_USER_VERIFICATION
    }),
    closePopup() {
      this.id = ''
      this.userVerification = false
      this.close()
    },
    verifyHandler(method) {
      let payload
      payload = {
        user_id: this.id,
        verification: this.userVerification !== 2 ? false : true,
        by_age: true
      }
      this.verifyUser(payload)
      this.closePopup()
    },
    declineAgeVerification() {
      let payload
      payload = {
        user_id: this.id,
        verification: this.userVerification !== 2 ? false : true
      }
      this.verifyUser(payload)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(USER_DECLINE_BY_AGE, user => {
      this.userVerification = user.verification
      this.id = user.user_id
      this.open()
    })
  }
}
</script>
