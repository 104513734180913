import * as MutationTypes from './mutation-types'

export default {
  [MutationTypes.SET_RECORDS]: (state, payload) => {
    state.records = payload
  },

  [MutationTypes.SET_CURRENT_RECORD_FILTER]: (state, payload) => {
    const { key, value, method } = payload
    const newObject = {
      key,
      ...value
    }

    state.tmpFilters[method] = newObject
  },

  [MutationTypes.SET_CLEAR_RECORD_FILTERS]: state => {
    for (const key in state.tmpFilters) {
      state.tmpFilters[key] = {}
    }
  },

  [MutationTypes.SET_CURRENT_LANGUAGE_BY_ID]: (state, payload) => {
    state.recordDefault.langDefault.id = payload
  }
}
