import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import OperatorPaymentsService from '@/services/OperatorPaymentsService'

export default {
  [ActionTypes.REQUEST_PAYMENTS_SETTING]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorPaymentsService, [userToken]).getOperatorPayments()
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_PAYMENTS_OPTIONS, data.other)
      commit(MutationTypes.SET_DEFAULT_PAYMENT_OPTION, data.default)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_DELETE_PAYMENTS_SETTING]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorPaymentsService, [userToken]).deleteOperatorPayment(data)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_PAYMENTS_SETTING)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EDIT_PAYMENTS_SETTING]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })

    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorPaymentsService, [userToken]).editOperatorPayment(data)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_PAYMENTS_SETTING)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CREATE_PAYMENTS_SETTING]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorPaymentsService, [userToken]).addOperatorPayment(data)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_PAYMENTS_SETTING)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EDIT_DEFAULT_SETTING]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })

    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorPaymentsService, [userToken]).editOperatorPaymentDefault(
      data
    )
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_PAYMENTS_SETTING)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CREATE_DEFAULT_SETTING]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorPaymentsService, [userToken]).addOperatorPaymentDefault(
      data
    )
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_PAYMENTS_SETTING)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
