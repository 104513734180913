<template>
  <Popup :visible="visible" @close="closePopup" title="" description="" modalClass="popup--small">
    <div class="popup__item" v-if="!errorMessage">
      <span class="popup__photo-text">Заблокировать основное фото?</span>
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="blockHandler()">Да</button>
        <button class="popup__btn btn-red" @click="closePopup()">Нет</button>
      </div>
    </div>
    <div class="popup__item" v-else>
      <span class="popup__photo-text">Главное фото заблокироано</span>
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-red" @click="closePopup()">
          Закрыть
        </button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_BLOCK_MAIN_PHOTO_EVENT } from '@/constants/event'
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_BLOCK_MAIN_PHOTO, REQUEST_USER_PHOTOS } from '@/store/action-types'
import { GET_USER_PHOTOS } from '@/store/getter-types'

export default {
  name: 'UserBlockMainPhotoPopup',
  components: {
    Popup
  },
  data() {
    return {
      id: '',
      mainPhoto: '',
      errorMessage: false
    }
  },
  mixins: [ModalMixin],
  computed: {
    ...mapGetters({
      userPhotos: GET_USER_PHOTOS
    })
  },

  methods: {
    ...mapActions({
      blockPhoto: REQUEST_BLOCK_MAIN_PHOTO,
      getUserPhotos: REQUEST_USER_PHOTOS
    }),
    closePopup() {
      this.mainPhoto = ''
      this.id = ''
      this.close()
    },
    blockHandler() {
      let payload
      payload = {
        user_id: this.id,
        photo_id: this.mainPhoto.id
      }
      this.blockPhoto(payload)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(USER_BLOCK_MAIN_PHOTO_EVENT, user => {
      this.id = user.id
      if (this.userPhotos.length) {
        this.mainPhoto = this.userPhotos.find(item => item.is_main_photo === true) || ''
        if (!this.mainPhoto || !this.mainPhoto.status === 2) {
          this.errorMessage = true
        }
        this.open()
      } else {
        this.getUserPhotos(user.id).then(response => {
          this.mainPhoto = this.userPhotos.find(item => item.is_main_photo === true) || ''
          if (!this.mainPhoto || !this.mainPhoto.status === 2) {
            this.errorMessage = true
          }
          this.open()
        })
      }
    })
  }
}
</script>

<style lang="scss">
.popup__photo-text {
  display: flex;
  justify-content: center;
  margin: 0 auto 10px auto;
}
</style>
