import { country } from '@/constants/country'
// import { countryUser } from '@/constants/country'

export default {
  autoMessagesList: [],
  messagesCount: 0,
  filterCount: 0,
  currentFilter: { page: 0 },
  userCount: 0,
  userFilterCount: 0,
  searchFilters: {
    page: 1
  },

  currentUser: {},
  tmpUserFilters: {
    gender: {},
    genderUser: {},
    country: {},
    countryUser: {},
    count: {},
    type: {}
  },
  filterUsersDefault: {
    gender: {
      name: 'Неважно',
      value: 0,
      id: 0
    },

    genderUser: {
      name: 'Неважно',
      value: 0,
      id: 0
    },

    country: {
      name: 'Неважно',
      value: 0,
      id: 1
    },

    countryUser: {
      name: 'Неважно',
      value: 0,
      id: 1
    },
    count: {
      name: 'Неважно',
      value: 0,
      id: 1
    },
    type: {
      name: 'Неважно',
      value: -1,
      id: 1
    }
  },
  filtersUserInfo: {
    gender: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Мужской',
        value: 1,
        id: 1
      },
      {
        name: 'Женский',
        value: 2,
        id: 2
      }
    ],

    genderUser: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Мужской',
        value: 1,
        id: 1
      },
      {
        name: 'Женский',
        value: 2,
        id: 2
      }
    ],

    count: [
      {
        name: 'Неважно',
        value: 0,
        id: 1
      },
      {
        name: 'Начался',
        value: true,
        id: 2
      },
      {
        name: 'Не начался',
        value: false,
        id: 3
      }
    ],
    type: [
      {
        name: 'Неважно',
        value: -1,
        id: 1
      },
      {
        name: 'Текст',
        value: 0,
        id: 2
      },
      {
        name: 'Изображение',
        value: 1,
        id: 3
      },
      {
        name: 'Видео',
        value: 2,
        id: 4
      },
      {
        name: 'Аудио',
        value: 3,
        id: 5
      },
      {
        name: 'Музыкальная композиция',
        value: 4,
        id: 6
      },
      {
        name: 'Файл',
        value: 5,
        id: 7
      },
      {
        name: 'Координаты',
        value: 6,
        id: 8
      },
      {
        name: 'Подарок',
        value: 7,
        id: 9
      }
    ],
    country
  }
}
