<template>
  <Popup :visible="visible" @close="close" :description="statusText" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="approveHandler">Да</button>
        <button class="popup__btn btn-red" @click="closePopup">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_PHOTO_ACTION_PHOTO_STATUS_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_UPDATE_USER_PHOTO_STATUS, REQUEST_USER_PHOTOS } from '@/store/action-types'
export default {
  name: 'UserActionApproveStatusPopup',
  components: {
    Popup
  },
  data() {
    return {
      photoData: {}
    }
  },
  mixins: [ModalMixin],
  computed: {
    statusText() {
      return `Изменить статус на ${this.photoData.text}`
    }
  },
  methods: {
    ...mapActions({
      updatePhotoStatus: REQUEST_UPDATE_USER_PHOTO_STATUS,
      photos: REQUEST_USER_PHOTOS
    }),
    closePopup() {
      this.photoData = {}
      this.close()
    },
    approveHandler() {
      const payload = {
        photo_id: this.photoData.id,
        status: this.photoData.status
      }
      this.updatePhotoStatus({ updateStatus: payload, user_id: this.photoData.user_id }).then(res => {
        if (res.error === 0) {
          this.closePopup()
        }
      })
    }
  },

  mounted() {
    this.$root.$on(USER_PHOTO_ACTION_PHOTO_STATUS_EVENT, photoObject => {
      this.photoData = photoObject
      this.open()
    })
  }
}
</script>
