<template>
  <div class="control-position-wrap">
    <div>
      <div class="control-position__header">
        <h1 class="control-position__title">Должности</h1>
        <button
          type="button"
          class="filters__new-ticket"
          @click="$root.$emit(NEW_POSITION_POPUP_EVENT, permissionsAdmin)"
        >
          <i class="filters__new-ticket-icon fa fa-plus-square"></i>
          <span class="filters__button-text">Добавить</span>
        </button>
      </div>
      <!--    <Filters />-->
      <div>
        <ul class="positions__list" v-if="positions.length && isLoaded">
          <li class="positions__item"></li>
          <li class="positions__item" v-for="position in positions" :key="position.id" :position="position">
            <div class="position-btn-wrapp" v-if="position.type === 'owner'">
              <span>{{ position.position_name }}</span>
              <button
                v-if="user_perm.includes(604)"
                @click="
                  $root.$emit(EDIT_POSITION_POPUP_EVENT, {
                    position: position,
                    adminList: permissionsAdmin
                  })
                "
                class="positions__item-btn__edit"
              >
                <i class="fa fa-edit"></i>
              </button>
              <button
                v-if="user_perm.includes(604)"
                @click="$root.$emit(DELETE_POSITION_POPUP_EVENT, position.id)"
                class="positions__item-btn__delete"
              >
                <i class="fa fa-times-circle"></i>
              </button>
            </div>
            <div class="position-btn-wrapp" v-else>
              <span>{{ position.position_name }}</span>
              <button
                @click="
                  $root.$emit(EDIT_POSITION_POPUP_EVENT, {
                    position: position,
                    adminList: permissionsAdmin
                  })
                "
                class="positions__item-btn__edit"
              >
                <i class="fa fa-edit"></i>
              </button>
              <button
                @click="$root.$emit(DELETE_POSITION_POPUP_EVENT, position.id)"
                class="positions__item-btn__delete"
              >
                <i class="fa fa-times-circle"></i>
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="list_wrapp">
        <div v-for="el in permissionsAdmin" :key="el.id">
          <h1 class="positions_title">{{ el.title }}</h1>
          <div :adminList="el.props" :positions="el.positions" :key="el.id" :is="el.type"></div>
          <!-- <h1 class="positions_title">Главная Админ панель</h1>
        <PositionsByAdminList :adminList="permissionsAdmin.admin" :positions="positions" />
        <h1 class="positions_title">Пользователи</h1>
        <PositionsByAdminList :adminList="permissionsAdmin.users" :positions="positions" />
        <h1 class="positions_title">Модерация</h1>
        <PositionsByAdminList :adminList="permissionsAdmin.moderation" :positions="positions" />
        <h1 class="positions_title">Модерация: Панель</h1>
        <PositionsByAdminList :adminList="permissionsAdmin.moderation_panel" :positions="positions" />
        <h1 class="positions_title">Поддержка: Панель</h1>
        <PositionsByAdminList :adminList="permissionsAdmin.support_panel" :positions="positions" />
        <h1 class="positions_title">Справочники</h1>
        <PositionsByAdminList :adminList="permissionsAdmin.guides" :positions="positions" />
        <h1 class="positions_title">Управление</h1>
        <PositionsByAdminList :adminList="permissionsAdmin.managment" :positions="positions" />
        <h1 class="positions_title">События</h1>
        <PositionsByAdminList :adminList="permissionsAdmin.events" :positions="positions" />
        <h1 class="positions_title">Маркетинг</h1>
        <PositionsByAdminList :adminList="permissionsAdmin.marketing" :positions="positions" />
        <h1 class="positions_title">Операторы</h1>
        <PositionsByAdminList :adminList="permissionsAdmin.operators" :positions="positions" />
        <h1 class="positions_title">Статистика</h1>
        <PositionsByAdminList :adminList="permissionsAdmin.statistic" :positions="positions" />
        <h1 class="positions_title">Без группы</h1>
        <PositionsByAdminList :adminList="permissionsAdmin.no_group" :positions="positions" /> -->
          <!-- <div v-for="el in test" :adminList="el.props" :positions="el.pos" :key="el.id" :is="el.type"></div> -->
        </div>
      </div>
    </div>

    <div class="control-position-list" v-if="!positions.length && isLoaded">
      <span class="control-position-list__text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="control-position-list">
      <span class="control-position-list__text">Ошибка при загрузке должностей</span>
    </div>
    <NewPositionPopup />
    <EditPositionPopup />
    <DeletePositionPopup />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  REQUEST_CONTROL_POSITIONS,
  REQUEST_CONTROL_PERMISSIONS,
  REQUEST_PERMISSIONS_GROUP
} from '@/store/action-types'
import {
  GET_CONTROL_POSITIONS,
  GET_CONTROL_PERMISSIONS,
  GET_PERMISSIONS_GROUP,
  GET_PERMISSIONS_USER
} from '@/store/getter-types'
import {
  NEW_POSITION_POPUP_EVENT,
  DELETE_POSITION_POPUP_EVENT,
  EDIT_POSITION_POPUP_EVENT
} from '@/constants/event'
import NewPositionPopup from '@/components/Modals/PositionPopup/NewPositionPopup'
import EditPositionPopup from '@/components/Modals/PositionPopup/EditPositionPopup'
import DeletePositionPopup from '@/components/Modals/PositionPopup/DeletePositionPopup'
import store from '@/store'
import PositionsByAdminList from '@/components/Controls/Position/PositionsByAdminList'
import Permissions from '@/constants/permissions.js'

export default {
  name: 'managmentPosition',
  components: {
    PositionsByAdminList,
    NewPositionPopup,
    EditPositionPopup,
    DeletePositionPopup
  },

  data: () => ({
    errorMessage: false,
    isLoaded: false,
    NEW_POSITION_POPUP_EVENT,
    DELETE_POSITION_POPUP_EVENT,
    EDIT_POSITION_POPUP_EVENT
  }),

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_CONTROL_POSITIONS).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
        store.dispatch(REQUEST_CONTROL_PERMISSIONS).then(response => {
          if (!response.status) {
            vm.errorMessage = true
          } else {
            vm.isLoaded = true
          }
        })
        store.dispatch(REQUEST_PERMISSIONS_GROUP).then(response => {
          if (!response.status) {
            vm.errorMessage = true
          } else {
            vm.isLoaded = true
          }
        })
      })
    })
  },
  computed: {
    ...mapGetters({
      positions: GET_CONTROL_POSITIONS,
      permissions: GET_CONTROL_PERMISSIONS,
      permissions_group: GET_PERMISSIONS_GROUP,
      user_perm: GET_PERMISSIONS_USER
    }),
    permissionsAdmin() {
      // let newPermissions = {
      //   admin: [],
      //   support: [],
      //   moderation: [],
      //   users: [],
      //   moderation_panel: [],
      //   support_panel: [],
      //   guides: [],
      //   managment: [],
      //   events: [],
      //   marketing: [],
      //   operators: [],
      //   statistic: [],
      //   no_group: []
      // }
      // TEST 2 РАБОЧИЙ
      let filtered = []

      filtered.push({
        type: PositionsByAdminList,
        title: 'Не выбрано',
        props: this.permissions.filter(i => i.permission_group === null),
        positions: this.positions
      })
      for (let item of this.permissions_group) {
        if (item.name !== 'Не выбрано') {
          filtered.push({
            type: PositionsByAdminList,
            title: item.name,
            props: this.permissions.filter(i => i.permission_group === item.name),
            positions: this.positions
          })
        }
      }

      // РАБОЧИЙ ВАРИАНТ(СТАРЫЙ. УБАРТЬ ПОСЛЕ ТЕСТОВ)
      // this.permissions.forEach(f => {
      //   let sortName = f.permission_name.split(' | ')
      //   let sortGroupName = f.permission_group
      //   if (sortName.length === 1) {
      //     switch (sortGroupName) {
      //       case 'Пользователи':
      //         newPermissions.users.push({
      //           id: f.id,
      //           permission: f.permission,
      //           permission_level: f.permission_level,
      //           permission_name: sortName[0]
      //         })
      //         break
      //       case 'Поддержка: Панель':
      //         newPermissions.support_panel.push({
      //           id: f.id,
      //           permission: f.permission,
      //           permission_level: f.permission_level,
      //           permission_name: sortName[0]
      //         })
      //         break
      //       case 'Модерация: Панель':
      //         newPermissions.moderation_panel.push({
      //           id: f.id,
      //           permission: f.permission,
      //           permission_level: f.permission_level,
      //           permission_name: sortName[0]
      //         })
      //         break
      //       case 'Справочники':
      //         newPermissions.guides.push({
      //           id: f.id,
      //           permission: f.permission,
      //           permission_level: f.permission_level,
      //           permission_name: sortName[0]
      //         })
      //         break
      //       case 'Управление':
      //         newPermissions.managment.push({
      //           id: f.id,
      //           permission: f.permission,
      //           permission_level: f.permission_level,
      //           permission_name: sortName[0]
      //         })
      //         break
      //       case 'События':
      //         newPermissions.events.push({
      //           id: f.id,
      //           permission: f.permission,
      //           permission_level: f.permission_level,
      //           permission_name: sortName[0]
      //         })
      //         break
      //       case 'Маркетинг':
      //         newPermissions.marketing.push({
      //           id: f.id,
      //           permission: f.permission,
      //           permission_level: f.permission_level,
      //           permission_name: sortName[0]
      //         })
      //         break
      //       case 'Операторы':
      //         newPermissions.operators.push({
      //           id: f.id,
      //           permission: f.permission,
      //           permission_level: f.permission_level,
      //           permission_name: sortName[0]
      //         })
      //         break
      //       case 'Статистика':
      //         newPermissions.statistic.push({
      //           id: f.id,
      //           permission: f.permission,
      //           permission_level: f.permission_level,
      //           permission_name: sortName[0]
      //         })
      //         break
      //       case null:
      //         newPermissions.no_group.push({
      //           id: f.id,
      //           permission: f.permission,
      //           permission_level: f.permission_level,
      //           permission_name: sortName[0]
      //         })
      //         break
      //       //   break
      //       default:
      //         break
      //     }
      //   }
      //   switch (sortName[1]) {
      //     case 'Администрирование':
      //       newPermissions.admin.push({
      //         id: f.id,
      //         permission: f.permission,
      //         permission_level: f.permission_level,
      //         permission_name: sortName[0]
      //       })
      //       break
      //     case 'Модерация':
      //       newPermissions.moderation.push({
      //         id: f.id,
      //         permission: f.permission,
      //         permission_level: f.permission_level,
      //         permission_name: sortName[0]
      //       })
      //       break
      //     case 'Поддержка':
      //       newPermissions.support.push({
      //         id: f.id,
      //         permission: f.permission,
      //         permission_level: f.permission_level,
      //         permission_name: sortName[0]
      //       })
      //       break
      //     default:
      //       break
      //   }
      // })
      // return newPermissions
      return filtered
    }
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
@import '../../components/Controls/Position/Filters/Filters.scss';

.positions_title {
  background-color: #fff;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #dddddd;
}

.control-position__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  background: white;
  margin-bottom: 16px;
  border-radius: 5px;
}
.control-position__title {
  font-size: 27px;
  color: #717171;
  font-weight: 700;
}

.positions__list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #ddd;
  width: 100%;
  background-color: #fff;
  padding: 4px;
}
.control-position {
  &-wrap {
    padding: 16px 16px;
    //max-width: 1172px;
    margin: 0 auto;
  }
  &-list {
    position: relative;
    @media (max-width: 1020px) {
      margin-top: 70px;
    }
    &__text {
      margin: 0 auto;
    }
  }
  &-list--error {
    position: relative;
    display: flex;
    min-height: 64px;
    align-items: center;
    background-color: white;
    @media (max-width: 1020px) {
      margin-top: 70px;
    }
  }
}
.positions__item {
  text-align: center;
  font-weight: bold;
  width: 200px;
  //border: 1px solid #ddd;
  &:first-child {
    display: flex;
    width: 200px;
  }
  &-btn {
    font-size: 12px;
    &__edit {
      color: #58c9f3;
    }
    &__delete {
      color: #f67a6e;
    }
  }
}

.list_wrapp {
  position: relative;
}
</style>
