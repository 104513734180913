<template>
  <div class="interface-faq">
    <Faq :error="errorMessage" />
  </div>
</template>
<script>
import Faq from '@/components/FAQ/Faq'
import { mapMutations } from 'vuex'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types'
import { REQUEST_ALL_CATEGORY_BY_LANG } from '@/store/action-types'
import store from '@/store'
export default {
  name: 'interfaceFaq',
  components: {
    Faq
  },
  data: () => ({
    errorMessage: false
  }),

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_ALL_CATEGORY_BY_LANG).then(response => {
      next(vm => {
        if (response) {
          vm.setCurrentLang('RU')
        }
        if (!response.status) {
          vm.errorMessage = true
        }
      })
    })
  },

  methods: {
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    })
  }
}
</script>
