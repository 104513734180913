<template>
  <Popup :visible="visible" @close="close" description="Редактирование вопроса" modalClass="popup--medium">
    <form @submit.prevent="updateQuestionHandler" class="modal-update__question">
      <div class="form-group">
        <label for="question" class="popup__item-title">Вопрос</label>
        <textarea
          placeholder="Вопрос"
          name="question"
          id="question"
          class="popup__input default-input"
          v-model="newQuestion.question"
          required
        ></textarea>
      </div>
      <div class="form-group">
        <label for="answer" class="popup__item-title">Ответ</label>
        <textarea
          placeholder="Ответ"
          name="answer"
          class="popup__input default-input"
          v-model="newQuestion.answer"
          required
        ></textarea>
      </div>
      <div class="form-group form-group--flex">
        <label
          for="status"
          class="checkbox popup__item-title"
          :class="{ 'checkbox--active': newQuestion.active }"
          >Опубликован</label
        >
        <input
          type="checkbox"
          name="status"
          id="status"
          class="popup__input default-input"
          v-model="newQuestion.active"
          hidden
        />
      </div>
      <div class="popup-footer">
        <button class="button button--add">Изменить</button>
        <button type="button" @click="closeModal" class="button button--back">
          Отмена
        </button>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { FAQ_POPUP_UPDATE_QUESTION_EVENT } from '@/constants/event'
import { REQUEST_UPDATE_QUESTION_BY_ID } from '@/store/action-types'
import { GET_CURRENT_CONTENT_ID } from '@/store/getter-types'

export default {
  name: 'FaqPopupUpdateCategory',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data() {
    return {
      newQuestion: {
        id: '',
        question: '',
        answer: '',
        active: false
      }
    }
  },

  props: {
    currentContentId: {
      required: true
    },

    listIcons: {
      type: Array
    }
  },

  computed: {
    ...mapGetters({
      currentCategoryId: GET_CURRENT_CONTENT_ID
    })
  },

  mounted() {
    this.$root.$on(FAQ_POPUP_UPDATE_QUESTION_EVENT, category => {
      this.newQuestion.id = category.id
      this.newQuestion.question = category.question
      this.newQuestion.answer = category.answer
      this.newQuestion.active = category.active
      this.open()
    })
  },

  methods: {
    closeModal() {
      this.close()
    },

    ...mapActions({ updtaeQuestionById: REQUEST_UPDATE_QUESTION_BY_ID }),

    updateQuestionHandler() {
      const newQuestion = {
        id: this.newQuestion.id,
        contentId: this.currentCategoryId,
        data: {
          category: this.currentContentId,
          question: this.newQuestion.question,
          answer: this.newQuestion.answer,
          active: this.newQuestion.active
        }
      }

      this.updtaeQuestionById(newQuestion)
      this.close()
    }
  }
}
</script>
<style lang="scss">
@import './FaqPopupStyle';
</style>
