import Request from '../helpers/Request'
import BaseService from './BaseService'

class CalendarEventService extends BaseService {
  constructor(token) {
    super(token)
  }
  async getShiftOptions() {
    const getShiftOptionsUrl = `/api/v1/management/operator/scheduleconf/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getShiftOptionsUrl, { headers })
  }

  async changeShiftOptions(time) {
    const changeShiftOptionsUrl = `/api/v1/management/operator/scheduleconf/set/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(changeShiftOptionsUrl, time, { headers })
  }

  async getCalendarEvent(params) {
    const getCalendarEventUrl = `/api/v1/management/operator/schedule/?start=${params.start}&end=${params.end}`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getCalendarEventUrl, { headers })
  }

  async addCalendarEvent(event) {
    const addCalendarEventUrl = `/api/v1/management/operator/schedule/add/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(addCalendarEventUrl, event, { headers })
  }

  async deleteModerator(params) {
    const deleteModeratortUrl = `/api/v1/management/operator/schedule/${params.scheduleId}/operator-del/`

    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.put(deleteModeratortUrl, params.userId, { headers })
  }

  async addModerator(params) {
    const deleteModeratortUrl = `/api/v1/management/operator/schedule/${params.scheduleId}/operator-add/`

    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.put(deleteModeratortUrl, params.userId, { headers })
  }

  async modOperatorEvent(params) {
    const deleteModeratortUrl = `/api/v1/management/operator/schedule/add/`

    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.put(deleteModeratortUrl, params, { headers })
  }

  async changeOperator(params) {
    const changeOperatortUrl = `/api/v1/management/operator/schedule/move/`

    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.put(changeOperatortUrl, params, { headers })
  }
}

export default CalendarEventService
