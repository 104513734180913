<template>
  <li class="moderator-payments">
    <div class="moderator-payments__header">
      <span class="moderator-payments__title">{{ moderator.first_name }} {{ moderator.last_name }}</span>
    </div>
    <div class="moderator-payments__body">
      <div>
        <router-link
          :to="{
            name: 'moderators-statistic',
            params: { moderator_id: moderator.id }
          }"
          class="moderator-payments__body-item moderators-payments__btn moderators-payments__btn--statistic"
        >
          <i class="fas fa-chart-line"></i>
          <span> Статистика</span>
        </router-link>
      </div>
      <div class="moderator-payments__body-item">
        <span class="moderator-payments__body-title">Сумма выплаты:</span>
        <div class="moderator-payments__body-value">
          <span :class="{ 'moderator-payments__text': sumPay }">{{ sumPay ? sumPay : '' }}</span>
          <i class="moderator-payments__icon fas fa-ruble-sign"></i>
        </div>
      </div>
      <div class="moderator-payments__body-item">
        <span class="moderator-payments__body-title">Сумма за текущий период: </span>
        <div class="moderator-payments__body-value">
          <span
            :class="{
              'moderator-payments__text': reverseList.length && reverseList[0].pay_sum
            }"
            >{{ reverseList.length && reverseList[0].pay_sum ? reverseList[0].pay_sum.toFixed(2) : 0 }}
          </span>
          <i class="moderator-payments__icon fas fa-ruble-sign"></i>
        </div>
      </div>
      <div class="moderator-payments__body-item moderators-payments__item filters__item--togle">
        <div class="moderators-payments__night" v-if="moderator.is_night_shift">
          <span class="moderators-payments__title moderators-payments__title--moon">Ночная смена</span>
          <i class="fas fa-moon"></i>
        </div>
        <div class="moderators-payments__sun" v-else>
          <span class="moderators-payments__title moderators-payments__title--sun">Дневная смена</span>
          <i class="fas fa-sun"></i>
        </div>
      </div>
    </div>
    <div class="moderator-payments__table" :class="{ 'moderator-payments__table--open': loadingOn }">
      <div class="moderator-payments__table-header">
        <span class="table-header__item">Период</span>
        <span class="table-header__item">Обработка фотографий</span>
        <span class="table-header__item">Обработка видео</span>
        <span class="table-header__item">Обработка событий</span>
        <span class="table-header__item">Бан аккаунта</span>
        <span class="table-header__item">Доплата</span>
        <span class="table-header__item">Премия</span>
        <span class="table-header__item">Сумма</span>
        <span class="table-header__item">Дата выплаты</span>
      </div>
      <ul
        class="moderator-payments__table-list"
        :class="{ 'moderator-payments__table-list--open': loadingOn }"
        ref="scroll"
      >
        <ModeratorPaymentsItem
          v-for="(item, index) in reverseList"
          :item="item"
          :index="index"
          :moderator="moderator"
          :key="item.id"
        />
      </ul>
      <!-- <ul
        class="moderator-payments__table-list"
        :class="{ 'operator-payments__table-list--open': loadingOn }"
        ref="scroll"
      >
        <ModeratorPaymentsItem
          v-for="(item, index) in reverseList.slice(0, 3)"
          :item="item"
          :index="index"
          :moderator="moderator"
          :key="item.id"
        /> -->
      <!-- <ul v-if="loadingOn" class="moderator-payments__table-list">
        <ModeratorPaymentsItem
          v-for="(item, index) in reverseList.slice(3, reverseList.length)"
          :item="item"
          :index="index"
          :moderator="moderator"
          :key="item.id"
        />
      </ul> -->
    </div>
    <div class="moderator-payments__footer" v-if="moderator.pays.length > 3">
      <button
        class="moderators-payments__btn moderator-payments__footer-link"
        v-if="!loadingOn"
        @click="openList"
      >
        Показать все
      </button>
      <button class="moderators-payments__btn moderator-payments__footer-link" v-else @click="openList">
        Скрыть
      </button>
    </div>
  </li>
</template>
<script>
import { mapActions } from 'vuex'
import getTransactionStatus from '@/components/mixins/getTransactionStatus'
import getIconCurrency from '@/components/mixins/getIconCurrency'
import { REQUEST_BILLING_MODERATORS } from '@/store/action-types'
import {
  BILL_MODERATOR_POPUP_EVENT,
  PRIZE_MODERATOR_POPUP_EVENT,
  PAY_MODERATOR_POPUP_EVENT
} from '@/constants/event'
import ModeratorPaymentsItem from './ModeratorPaymentsItem'

export default {
  name: 'ModeratorsPayments',
  components: { ModeratorPaymentsItem },
  props: {
    moderator: {
      type: Object
    }
  },
  data: () => ({
    BILL_MODERATOR_POPUP_EVENT,
    PRIZE_MODERATOR_POPUP_EVENT,
    PAY_MODERATOR_POPUP_EVENT,
    nightShift: false,
    loadingOn: false
  }),
  mixins: [getTransactionStatus, getIconCurrency],
  computed: {
    reverseList() {
      let newList = this.moderator.pays.map(p => p).reverse()
      return newList
    },
    sumPay() {
      let sum = 0
      if (this.moderator.pays.length) {
        this.moderator.pays.forEach(f => {
          if (!f.payout_date && f.settlement_date) {
            sum = sum + f.pay_sum
          }
        })
      }
      return sum
    }
  },
  methods: {
    ...mapActions({
      bill: REQUEST_BILLING_MODERATORS
    }),
    openList() {
      this.loadingOn = !this.loadingOn
      if (!this.loadingOn) {
        this.$refs.scroll.scrollTop = 0
      }
    }
  }
}
</script>
<style lang="scss">
@import 'ModeratorsPayments';
</style>
