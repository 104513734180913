<template>
  <Popup
    :visible="visible"
    @close="close"
    description="Вы точно хотите удалить настройки для данного языка?"
    modalClass="popup--small"
  >
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteCategoryEvent">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EVENT_REFBOOK_DELETE } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_DELETE_EVENT_REFBOOK } from '@/store/action-types'
import LanguagesHeaderVue from '../../Lang/LanguagesHeader.vue'

export default {
  name: 'EventRefBookDeletePopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data() {
    return {
      id: '',
      lang: ''
    }
  },
  methods: {
    ...mapActions({
      deleteCategory: REQUEST_DELETE_EVENT_REFBOOK
    }),
    deleteCategoryEvent() {
      const data = {
        id: this.id,
        lang: this.lang
      }
      this.deleteCategory(data)
      this.id = ''
      this.lang = ''
      this.close()
    }
  },

  mounted() {
    this.$root.$on(EVENT_REFBOOK_DELETE, (eventref, lang) => {
      this.open()
      this.id = eventref
      this.lang = lang
    })
  }
}
</script>
