const SET_AUTO_MESSAGES = 'SET_AUTO_MESSAGES'
const SET_AUTO_MESSAGE_COUNT = 'SET_AUTO_MESSAGE_COUNT'
const SET_AUTO_MESSAGES_PAGE = 'SET_AUTO_MESSAGES_PAGE'
const SET_AUTO_MESSAGES_PAGINATION = 'SET_AUTO_MESSAGES_PAGINATION'
const SET_AUTO_MESSAGES_NOW_LINK = 'SET_AUTO_MESSAGES_NOW_LINK'

export {
  SET_AUTO_MESSAGES,
  SET_AUTO_MESSAGES_PAGE,
  SET_AUTO_MESSAGES_PAGINATION,
  SET_AUTO_MESSAGES_NOW_LINK,
  SET_AUTO_MESSAGE_COUNT
}
