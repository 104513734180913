<template>
  <div class="custom-bar">
    <div class="user-active__menu">
      <div class="user-active__left">
        <span class="user-active__left-text">Дата:</span>
        <div class="datepicker-traffic-source">
          <datepicker
            class="datepicker-online"
            v-model="active_stat_form.reg_date"
            input-class="datepicker__input"
            :language="ru"
          >
          </datepicker>
          <i class="select__field-icon fa fa-angle-down"></i>
        </div>
        <button class="user-active__btn" @click="getActiveStats">Показать</button>
      </div>
    </div>
    <div class="custom-bar-chart activestat-bar-chart">
      <ul class="y-axis">
        <li v-for="(value, index) in arrValue" :key="index">
          <span>{{ value }}</span>
        </li>
      </ul>
      <template v-if="active_stat">
        <div class="bar" v-for="(value, index) in active_stat" :key="index">
          <div class="title">
            <span>{{ value.date }}</span>
            <span v-if="value.hasOwnProperty('today') && value.today === 0">{{ index }}<br />день</span>
            <span v-else class="title-today">сегодня</span>
          </div>
          <div
            :style="{
              height: value.percent
            }"
            class="value tooltips tooltip"
            :class="{
              'value-today': value.hasOwnProperty('today') && value.today === 1
            }"
            :data-tooltip="value.percent"
          >
            <span
              class="value-left"
              :style="{ position: 'relative', bottom: `${value.numeric < 5 ? 17 : 0}px` }"
              >{{ value.numeric }}</span
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import { ru } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import 'chartjs-plugin-datalabels'
import { REQUEST_ACTIVE_NEW_USER } from '@/store/action-types'
import { mapActions } from 'vuex'
export default {
  name: 'StatisticActiveNewUser',
  components: {
    Datepicker
  },
  props: {
    activeNewUser: {
      type: Object
    }
  },
  data: () => ({
    arrValue: [100, 80, 60, 40, 20, 0],
    ru: ru,
    active_stat_form: {
      reg_date: moment().format('YYYY-MM-DD')
    },
    active_stat: {},
    reg_enter_stats_form: {
      reg_date: moment().format('YYYY-MM-DD'),
      type: 0,
      utc: 0
    }
  }),
  mounted() {
    this.reLoaded()
    this.setDefaultActiveStatDate()
  },
  methods: {
    ...mapActions({
      getUserActivity: REQUEST_ACTIVE_NEW_USER
    }),
    setDefaultActiveStatDate: function() {
      this.active_stat_form.reg_date = moment()
        .add(-29, 'days')
        .format('YYYY-MM-DD')
      this.getUserActivity({ date: this.active_stat_form.reg_date }).then(response => {
        this.reLoaded()
      })
    },
    getActiveStats() {
      this.active_stat_form.reg_date = moment(this.active_stat_form.reg_date).format('YYYY-MM-DD')
      this.getUserActivity({ date: this.active_stat_form.reg_date }).then(response => {
        this.reLoaded()
      })
    },
    getActivityStats() {
      this.getUserActivity({
        date: moment(this.activity_stats_form.date).format('YYYY-MM-DD')
      }).then(response => {
        this.reLoaded()
      })
    },
    reLoaded() {
      let stat = {}
      let keys = Object.keys(this.activeNewUser.active_stats)
      for (let val of keys) {
        let tmp = this.activeNewUser.active_stats[val]
        stat[val] = {
          date: tmp.date,
          percent: tmp.percent + '%',
          numeric: tmp.numeric,
          today: tmp.today
        }
      }
      this.active_stat = stat
    }
  }
}
</script>
<style lang="scss">
.value-left {
  color: #ffffff;
}
.custom-bar {
  padding: 16px;
  margin-bottom: 20px;
}
.custom-bar-chart {
  height: 290px;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 40px;
  position: relative;
  border-bottom: 1px solid #c9cdd7;
  .bar {
    height: 100%;
    position: relative;
    width: 6%;
    margin: 0 2%;
    float: left;
    text-align: center;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    z-index: 10;
    .title {
      position: absolute;
      bottom: -4rem;
      width: 100%;
      text-align: center;
      font-size: 12px;
      line-height: 1;
      .title-today:before {
        display: block;
        content: '\a0';
      }
    }
    .title span {
      display: block;
      &:last-child {
        margin-top: 5px;
        color: #999;
      }
    }
    .value {
      position: absolute;
      bottom: 0;
      background: #bfc2cd;
      color: #bfc2cd;
      width: 45%;
      -webkit-border-radius: 5px 5px 0 0;
      -moz-border-radius: 5px 5px 0 0;
      border-radius: 5px 5px 0 0;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      &:hover {
        background: #e8403f;
        color: #fff;
      }
    }
  }
}
.activestat-bar-chart {
  .bar {
    width: 3%;
    margin: 0 0.16665%;
    height: 100%;
    position: relative;
    float: left;
    text-align: center;
    border-radius: 5px 5px 0 0;
    z-index: 10;
    &:first-of-type .value {
      background: #7bb463;
      &:hover {
        background: #e8403f;
        color: #fff;
      }
    }
    .value {
      position: absolute;
      width: 100%;
      // bottom: 0;
      background: #92bca3;
      color: #fff;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.864);
      font-size: 13px;
    }
    .value-today {
      background: #57c8f2;
    }
  }
}

.y-axis {
  color: #555;
  text-align: right;
  position: absolute;
  width: 100%;
  left: -10px;
  li {
    border-top: 1px dashed #dbdce0;
    display: block;
    height: 58px;
    width: 100%;
    span {
      display: block;
      margin: -10px 0 0 -25px;
      padding: 0 10px;
      width: 40px;
      font-size: 13px;
    }
    &:last-child {
      border-top: none;
    }
  }
}
.regenterstats-block {
  margin-bottom: 20px;
}

.regenterstats-chart {
  height: 290px;
}

.age-stats-btns {
  float: right;
  .btn-group {
    width: 100%;
  }
  button {
    width: 50%;
  }
}

.tooltip {
  border-bottom: 1px dotted #0077aa;
}

.tooltip::after {
  left: 25%;
  width: 50px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  content: attr(data-tooltip);
  margin-top: -40px;
  opacity: 0;
  position: absolute;
  visibility: hidden;
}

.tooltip::before {
  left: calc(25% + 15px);

  margin: 0 0.25rem;
  margin-top: -15px;
  content: '';
  position: absolute;

  border: 5px solid transparent;
  border-top: 10px solid #000;
  visibility: hidden;
}

.tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}

.tooltip:hover::before {
  opacity: 1;
  visibility: visible;
}
</style>
