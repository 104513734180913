var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-wrapper"},[_c('div',{staticClass:"user-confidential"},[_c('h1',{staticClass:"user-confidential__title"},[_vm._v("Конфиденциальность")]),_c('ul',{staticClass:"user-confidential__list"},[_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Определять местоположение")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.confidential.locate,
            'fa fa-times user-icon--red': !_vm.currentUser.confidential.locate
          }})]),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Другие видят меня на карте")]),(_vm.visibleMap)?_c('FaIcon',{attrs:{"iconClass":['fa-check user-icon--green']}}):_c('FaIcon',{attrs:{"iconClass":['fa-times user-icon--red']}})],1),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Другие видят от кого подарок")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.confidential.gifts_visible,
            'fa fa-times user-icon--red': !_vm.currentUser.confidential.gifts_visible
          }})]),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Просмотр анкет инкогнито")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.confidential.incognito,
            'fa fa-times user-icon--red': !_vm.currentUser.confidential.incognito
          }})]),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Принимать звонки только от VIP")]),(_vm.onlyVipCall)?_c('FaIcon',{attrs:{"iconClass":['fa-check user-icon--green']}}):_c('FaIcon',{attrs:{"iconClass":['fa-times user-icon--red']}})],1),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("VIP не видят меня на карте")]),(_vm.visibleVip)?_c('FaIcon',{attrs:{"iconClass":['fa fa-check user-icon--green']}}):_c('FaIcon',{attrs:{"iconClass":['fa fa-times user-icon--red']}})],1),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Принимать сообщения только от VIP пользователей")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.confidential.message_from_vip,
            'fa fa-times user-icon--red': !_vm.currentUser.confidential.message_from_vip
          }})]),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Принимать сообщения только от верифицированных пользоватлей")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.confidential.message_from_verified,
            'fa fa-times user-icon--red': !_vm.currentUser.confidential.message_from_verified
          }})])])]),_c('div',{staticClass:"user-pushes"},[_c('h1',{staticClass:"user-pushes__title"},[_vm._v("Настройки уведомлений")]),_c('ul',[_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Твои совпадения")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.push.match,
            'fa fa-times user-icon--red': !_vm.currentUser.push.match
          }})]),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Сообщения в чате")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.push.message,
            'fa fa-times user-icon--red': !_vm.currentUser.push.message
          }})]),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Вы понравились")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.push.like,
            'fa fa-times user-icon--red': !_vm.currentUser.push.like
          }})]),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Просмотры")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.push.watch,
            'fa fa-times user-icon--red': !_vm.currentUser.push.watch
          }})]),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Подарки")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.push.gift,
            'fa fa-times user-icon--red': !_vm.currentUser.push.gift
          }})]),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Достижения")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.push.achieve,
            'fa fa-times user-icon--red': !_vm.currentUser.push.achieve
          }})]),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Ваши симпатии онлайн")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.push.like_online,
            'fa fa-times user-icon--red': !_vm.currentUser.push.like_online
          }})]),_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Избранный пользователь онлайн")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.push.favorite_online,
            'fa fa-times user-icon--red': !_vm.currentUser.push.favorite_online
          }})])])]),_c('div',{staticClass:"user-auto-date"},[_c('h1',{staticClass:"user-auto-date__title"},[_vm._v("Автознакомства")]),_c('ul',[_c('li',{staticClass:"user-confidential__item"},[_c('span',{staticClass:"user-confidential__text"},[_vm._v("Автознакомства")]),_c('i',{class:{
            'fa fa-check user-icon--green': _vm.currentUser.auto_dating,
            'fa fa-times user-icon--red': !_vm.currentUser.auto_dating
          }})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }