<template>
  <li class="search-item">
    <div class="search-item__element">
      <div :key="search.gender" class="operator-profile__item-text">
        <i
          :class="{
            'fa fa-mars search__male': search.gender === 1,
            'fa fa-venus search__female': search.gender === 2,
            'fa fa-genderless auto-messages__no-gender': search.gender === 0
          }"
        ></i>
      </div>
    </div>
    <div class="search-item__element search-item__age">
      <span class="">{{ search.age_min }}</span>
    </div>
    <div class="search-item__element search-item__age">
      <span class="">{{ search.age_max }}</span>
    </div>
    <div class="search-item__element search-item__country">
      {{ search.country_code }}
    </div>
    <div class="search-item__element search-item__lang">{{ langString }}</div>
    <div class="search-item__element search-item__location">
      <div class="">
        <span v-if="!editOpen">Ш: {{ this.latitude.toFixed(6) }}</span>
        <input class="search-item__location-input" v-else v-model.number="latitude" type="text" />
      </div>
      <span v-if="!editOpen">Д: {{ this.longitude.toFixed(6) }}</span>
      <input class="search-item__location-input" v-else v-model.number="longitude" type="text" />
    </div>
    <div class="edit">
      <button @click.prevent="openEdit">
        <i class="fa fa-edit"></i>
      </button>
      <button v-if="editOpen" @click.prevent="saveEdits">
        <i class="fa fa-save"></i>
      </button>
    </div>
  </li>
</template>

<script>
import { mapActions } from 'vuex'
import { REQUEST_EDIT_LOCATION_SETTING } from '@/store/action-types'
export default {
  name: 'SearchItem',
  props: {
    search: {
      type: Object
    },
    location: {
      type: Object
    },
    user_id: {
      type: String
    }
  },
  data() {
    return {
      editOpen: false,
      latitude: this.location.coordinates[1],
      longitude: this.location.coordinates[0]
    }
  },
  methods: {
    ...mapActions({
      saveEditsLocation: REQUEST_EDIT_LOCATION_SETTING
    }),
    openEdit() {
      this.editOpen = !this.editOpen
    },
    saveEdits() {
      const new_location = {
        user_id: this.user_id,
        latitude: this.latitude,
        longitude: this.longitude
      }
      this.saveEditsLocation(new_location).then(res => {
        const { status } = res
        if (status) {
          this.editOpen = !this.editOpen
          this.$root.$emit('changeOperators', 'change')
        }
      })
    }
  },
  computed: {
    langString: function() {
      let newArr = []
      this.search.langs.forEach(f => {
        newArr.push(f.value)
      })

      return newArr.join(', ')
    }
  }
}
</script>

<style lang="scss" scoped>
.search__male {
  color: dodgerblue;
}
.search__female {
  color: hotpink;
}
.search-item {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.search-item__element {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 16%;
}

.search-item__location-input {
  width: 150px;
  color: #696969;
  border: 1px solid #969696;
  margin-bottom: 5px;
}
.edit {
  color: #689bcc;
}
</style>
