<template>
  <div class="ev-cat__item">
    <div class="ev-cat__item-body">
      <div class="ev-cat__item-content">
        <div class="ev-cat__item-name">
          <span class="ev-cat__item-title">{{ reason.reason_code }}</span>
        </div>
        <div class="ev-cat__item-name">
          <span class="ev-cat__item-title">{{ reason.reason_text }}</span>
        </div>
        <div class="ev-cat__item-btns">
          <button
            class="ev-cat__item-btn ev-cat__item-btn--red"
            @click="$root.$emit(REASON_DELETE_POPUP, reason.id)"
          >
            <i class="ev-cat__item-btn-icon fa fa-times-circle"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { REASON_DELETE_POPUP } from '@/constants/event'
export default {
  data() {
    return {
      REASON_DELETE_POPUP
    }
  },
  name: 'ReasonDeleteItem',
  props: {
    reason: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss"></style>
