<template>
  <div class="interfacePurchase">
    <PurchasesHeader />
    <PurchaseTableHeader />
    <PurchasesList :purchases="purchases" v-if="isLoaded && purchases.length" />
    <div class="app-no-purchases" v-if="!purchases.length && isLoaded">
      <span class="app-no-purchases__text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="app-no-purchases">
      <span class="app-no-purchases__text">Ошибка при загрузке достижений</span>
    </div>
    <PurchasesAddPopUp />
    <PurchasesEditPopUp />
    <PurchasesDeletePopUp />
    <PurchasesClonePopUp />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { GET_PURCHASES } from '@/store/getter-types'
import { REQUEST_PURCHASES } from '@/store/action-types'
import PurchasesList from '@/components/Purchase/PurchasesList.vue'
import PurchasesHeader from '@/components/Purchase/PurchasesHeader.vue'
import PurchasesEditPopUp from '@/components/Modals/PurchasesPopup/PurchasesEditPopUp'
import PurchasesAddPopUp from '@/components/Modals/PurchasesPopup/PurchasesAddPopUp'
import PurchasesDeletePopUp from '@/components/Modals/PurchasesPopup/PurchasesDeletePopUp'
import PurchaseTableHeader from '@/components/Purchase/TableHeader/PurchaseTableHeader.vue'
import store from '@/store'
import PurchasesClonePopUp from '@/components/Modals/PurchasesPopup/PurchasesClonePopUp'

export default {
  name: 'interfacePurchase',
  components: {
    PurchasesClonePopUp,
    PurchasesList,
    PurchasesHeader,
    PurchasesEditPopUp,
    PurchasesAddPopUp,
    PurchasesDeletePopUp,
    PurchaseTableHeader
  },
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_PURCHASES).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      purchases: GET_PURCHASES
    })
  }
}
</script>

<style lang="sass">
@import "@/components/Purchase/Purchases.scss"
</style>
