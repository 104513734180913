export default {
  ratingSettingQuality: [],
  ratingSettingSpeed: [],
  rating: [],

  moderatorTmpRoles: {
    status: {}
  },

  moderatorRolesDefault: {
    status: {
      name: 'Все',
      value: 0
    }
  },

  moderatorRoles: {
    status: [
      {
        name: 'Все',
        value: 0,
        id: 0
      },
      {
        name: 'Активные',
        value: 1,
        id: 1
      },
      {
        name: 'Неактивные',
        value: 2,
        id: 2
      }
    ]
  }
}
