<template>
  <li class="popup__setting">
    <div class="popup__table-text">
      <span class="">Действует от</span>
      <span class="">Главная</span>
      <span class="item">Внутреняя</span>
      <span class="">Проверка события</span>
      <span class="">Редактирование события</span>
      <span class="">Бан аккаунта</span>
      <span class="item">Дневная смена</span>
      <span class="">Ночная смена</span>
      <span class="">Активен</span>
      <span class=""></span>
    </div>
    <div class="popup__table-setting">
      <div class="popup__table-item date">
        <span>{{ new Date(setting.acts_from).toLocaleDateString() }}</span>
        <span v-if="setting.acts_to"> - {{ new Date(setting.acts_to).toLocaleDateString() }}</span>
      </div>
      <div class="popup__table-item">
        <span v-if="!editOpen">{{ setting.main_photo_price }}</span>
        <input
          v-else
          type="number"
          class="popup__add-input"
          v-model="mainCoefficientSetting"
          ref="maincoefficientsetting"
          required
        />
        <svg
          class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="ruble-sign"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
          ></path>
        </svg>
      </div>
      <div class="popup__table-item">
        <span v-if="!editOpen">{{ setting.inner_photo_price }}</span>
        <input
          v-else
          type="number"
          class="popup__add-input"
          v-model="inCoefficientSetting"
          ref="incoefficientsetting"
          required
        />
        <svg
          class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="ruble-sign"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
          ></path>
        </svg>
      </div>
      <div class="popup__table-item">
        <span v-if="!editOpen">{{ setting.main_video_price }}</span>
        <input
          v-else
          type="number"
          class="popup__add-input"
          v-model="mainCoefficientVideoPrice"
          ref="maincofVideoPrice"
          required
        />
        <svg
          class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="ruble-sign"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
          ></path>
        </svg>
      </div>
      <div class="popup__table-item">
        <span v-if="!editOpen">{{ setting.event_price }}</span>
        <input
          v-else
          type="number"
          class="popup__add-input"
          v-model="checkCoefficientSetting"
          ref="checkcoefficientsetting"
          required
        />
        <svg
          class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="ruble-sign"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
          ></path>
        </svg>
      </div>
      <div class="popup__table-item">
        <span v-if="!editOpen">{{ setting.event_edit_price }}</span>
        <input
          v-else
          type="number"
          class="popup__add-input"
          v-model="editCoefficientSetting"
          ref="editcoefficientsetting"
          required
        />
        <svg
          class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="ruble-sign"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
          ></path>
        </svg>
      </div>
      <div class="popup__table-item">
        <span v-if="!editOpen">{{ setting.ban_price }}</span>
        <input
          v-else
          type="number"
          class="popup__add-input"
          v-model="editBanPriceSetting"
          ref="editbanpricesetting"
          required
        />
        <svg
          class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="ruble-sign"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
          ></path>
        </svg>
      </div>
      <div class="popup__table-item">
        <span v-if="!editOpen">{{ setting.day_pay }}</span>
        <input
          v-else
          type="number"
          class="popup__add-input"
          v-model="editDaySetting"
          ref="editdaysetting"
          required
        />
        <svg
          class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="ruble-sign"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
          ></path>
        </svg>
      </div>
      <div class="popup__table-item">
        <span v-if="!editOpen">{{ setting.night_pay }}</span>
        <input
          v-else
          type="number"
          class="popup__add-input"
          v-model="editNightSetting"
          ref="editnightsetting"
          required
        />
        <svg
          class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="ruble-sign"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
          ></path>
        </svg>
      </div>
      <div class="popup__table-item filters__item--togle">
        <label class="moderators-payments__input toggle">
          <input class="toggle__checkbox" type="checkbox" :checked="setting.acts_to === null" disabled />
          <span class="toggle__toggler toggle__toggler--moderators"></span>
        </label>
      </div>
      <div class="popup__table-item">
        <button
          v-if="!editOpen && !setting.acts_to"
          key="edit"
          class="popup__edit-btn"
          @click.prevent="openEdit"
        >
          <i class="fa fa-edit"></i>
        </button>
        <button
          v-if="!editOpen && setting.acts_to"
          class="popup__delete-btn"
          @click.prevent="$root.$emit('DEL_MODERATION_OPTION_POPUP_EVENT', setting.id)"
        >
          <i class="fa fa-times-circle"></i>
        </button>
        <div v-else-if="editOpen" class="popup__group">
          <button key="save" class="popup__edit-btn" @click.prevent="editSetting">
            <i class="fa fa-save"></i>
          </button>
          <button class="popup__delete-btn" @click.prevent="editOpen = !editOpen">
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { GET_MODERATOR_DEFAULT_PAYMENT_SETTING } from '@/store/getter-types'
import {
  REQUEST_MODERATOR_EDIT_PAYMENTS_SETTING,
  REQUEST_MODERATOR_DEL_PAYMENTS_ACTIVE
} from '@/store/action-types'
import { DEL_MODERATION_OPTION_POPUP_EVENT } from '@/constants/event'
export default {
  name: 'SettingsPaymentPopup',
  props: {
    setting: {
      type: Object
    }
  },
  data() {
    return {
      DEL_MODERATION_OPTION_POPUP_EVENT,
      settingId: null,
      mainCoefficient: null,
      inCoefficient: null,
      checkCoefficient: null,
      editCoefficient: null,
      editBanPrice: null,
      editOpen: false,
      oldData: {},
      isActive: false,
      mainVideoPrice: null,
      innerVideoPrice: 0.1,
      editNightPay: '',
      editDayPay: ''
    }
  },
  mounted() {
    this.isActive = this.setting.is_active
    this.settingId = this.setting.id
  },
  computed: {
    ...mapGetters({
      settings: GET_MODERATOR_DEFAULT_PAYMENT_SETTING
    }),
    mainCoefficientSetting: {
      get() {
        return Number(this.oldData.main_photo_price)
      },
      set(value) {
        this.$refs.maincoefficientsetting.value = value
        this.mainCoefficient = value
      }
    },

    inCoefficientSetting: {
      get() {
        return Number(this.oldData.inner_photo_price)
      },
      set(value) {
        this.$refs.incoefficientsetting.value = value
        this.inCoefficient = value
      }
    },

    mainCoefficientVideoPrice: {
      get() {
        return Number(0)
      },
      set(value) {
        this.$refs.maincofVideoPrice.value = value
        this.mainVideoPrice = value
      }
    },

    checkCoefficientSetting: {
      get() {
        return Number(this.oldData.event_price)
      },
      set(value) {
        this.$refs.checkcoefficientsetting.value = value
        this.checkCoefficient = value
      }
    },

    editCoefficientSetting: {
      get() {
        return Number(this.oldData.event_edit_price)
      },
      set(value) {
        this.$refs.editcoefficientsetting.value = value
        this.editCoefficient = value
      }
    },

    editBanPriceSetting: {
      get() {
        return Number(this.oldData.ban_price)
      },
      set(value) {
        this.$refs.editbanpricesetting.value = value
        this.editBanPrice = value
      }
    },

    editNightSetting: {
      get() {
        return Number(this.oldData.night_pay)
      },
      set(value) {
        this.$refs.editnightsetting.value = value
        this.editNightPay = value
      }
    },
    editDaySetting: {
      get() {
        return Number(this.oldData.day_pay)
      },
      set(value) {
        this.$refs.editdaysetting.value = value
        this.editDayPay = value
      }
    }
  },
  methods: {
    ...mapActions({
      editPaymentSetting: REQUEST_MODERATOR_EDIT_PAYMENTS_SETTING,
      delActive: REQUEST_MODERATOR_DEL_PAYMENTS_ACTIVE
    }),
    openEdit() {
      this.oldData = JSON.parse(JSON.stringify(this.setting))
      this.editOpen = true
    },
    closeRow() {
      this.oldData = {}
      this.mainCoefficient = null
      this.inCoefficient = null
      this.checkCoefficient = null
      this.editCoefficient = null
      this.editBanPrice = null
      this.editNightPay = null
      this.mainVideoPrice = null
      this.editDayPay = null
      this.editOpen = false
    },
    editSetting() {
      let newDate = {}
      if (this.mainCoefficient) {
        newDate.main_photo_price = this.mainCoefficient
      }
      if (this.inCoefficient) {
        newDate.inner_photo_price = this.inCoefficient
      }
      if (this.checkCoefficient) {
        newDate.event_price = this.checkCoefficient
      }
      if (this.editCoefficient) {
        newDate.event_edit_price = this.editCoefficient
      }
      if (this.editBanPrice) {
        newDate.ban_price = this.editBanPrice
      }
      if (this.editNightPay) {
        newDate.night_pay = this.editNightPay
      }
      if (this.mainVideoPrice) {
        newDate.main_video_price = +this.mainVideoPrice
        newDate.inner_video_price = +this.innerVideoPrice
      }
      if (this.editDayPay) {
        newDate.day_pay = this.editDayPay
      }
      if (Object.keys(newDate).length !== 0) {
        newDate.moderator_payment_settings_id = this.oldData.id
        this.editPaymentSetting(newDate).then(response => {
          this.closeRow()
        })
      } else {
        this.closeRow()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'SettingsPayment';

.popup__table-text {
  display: none;
  @media (max-width: 1300px) {
    display: flex;
  }
}

.date {
  display: flex;
  flex-direction: column;
}

.popup__setting {
  flex-direction: column;
  @media (max-width: 1300px) {
    flex-direction: row;
  }
}
</style>
