import * as MutationTypes from './mutation-types'

export default {
  [MutationTypes.SET_AUTO_MESSAGES_LIST_FILTER]: (state, payload) => {
    state.autoMessagesList = payload
  },
  [MutationTypes.SET_AUTO_MESSAGES_LIST]: (state, payload) => {
    state.autoMessagesList.push(...payload)
  },
  [MutationTypes.SET_MESSAGES_COUNT]: (state, payload) => {
    state.messagesCount = payload
  },
  [MutationTypes.SET_CURRENT_FILTER_AUTO_MESSAGE]: (state, payload) => {
    state.currentFilter = payload
  },

  [MutationTypes.SET_TMP_FILTERS_AUTO_MESSAGE]: (state, payload) => {
    const { key, value } = payload
    const newObject = {
      key,
      ...value
    }
    state.tmpUserFilters[key] = newObject
  },
  [MutationTypes.SET_RESET_FILTERS_AUTO_MESSAGE]: state => {
    state.currentFilter = null
    state.currentFilter = { page: 0 }
    for (const field in state.tmpUserFilters) {
      state.tmpUserFilters[field] = {}
    }
  },
  [MutationTypes.SET_AUTO_MESSAGES_LIST_FILTER_COUNT]: (state, payload) => {
    if (payload > 0) {
      state.filterCount = state.filterCount + payload
    } else {
      state.filterCount = payload
    }
  }
}
