const GET_MERGED_CATEGORIES = 'GET_MERGED_CATEGORIES'
const GET_EVENTS = 'GET_EVENTS'
const GET_EVENT = 'GET_EVENT'
const GET_EVENTS_REFBOOK = 'GET_EVENTS_REFBOOK'
const GET_EVENT_VALUE_PICKER = 'GET_EVENT_VALUE_PICKER'
const GET_EVENT_FILTERS_INFO = 'GET_EVENT_FILTERS_INFO'
const GET_EVENT_FILTERS_DEFAULT = 'GET_EVENT_FILTERS_DEFAULT'
const GET_EVENT_TMP_FILERS = 'GET_EVENT_TMP_FILERS'
const GET_CATEGORIES_BY_LANG = 'GET_CATEGORIES_BY_LANG'
const GET_EVENTS_FILTERS = 'GET_EVENTS_FILTERS'
const GET_EVENT_COUNTS = 'GET_EVENT_COUNTS'

export {
  GET_MERGED_CATEGORIES,
  GET_EVENTS,
  GET_EVENT,
  GET_EVENTS_REFBOOK,
  GET_EVENT_VALUE_PICKER,
  GET_EVENT_FILTERS_INFO,
  GET_EVENT_FILTERS_DEFAULT,
  GET_EVENT_TMP_FILERS,
  GET_CATEGORIES_BY_LANG,
  GET_EVENTS_FILTERS,
  GET_EVENT_COUNTS
}
