const SET_OPERATORS = 'SET_OPERATORS'
const SET_OPERATOR_SETTINGS = 'SET_OPERATOR_SETTINGS'
const SET_CLEAR_OPERATOR_DATA = 'SET_CLEAR_OPERATOR_DATA'
const SET_OPERATOR_USERS = 'SET_OPERATOR_USERS'
const SET_OPERATOR_CURRENT_USER = 'SET_OPERATOR_CURRENT_USER'
const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS'
const SET_ADD_SEARCH_OPTIONS = 'SET_ADD_SEARCH_OPTIONS'
const SET_DEL_SEARCH_OPTIONS = 'SET_DEL_SEARCH_OPTIONS'
const SET_EDIT_SEARCH_OPTIONS = 'SET_EDIT_SEARCH_OPTIONS'
const SET_OPERATOR_STATISTIC = 'SET_OPERATOR_STATISTIC'
const SET_CURRENT_OPERATOR = 'SET_CURRENT_OPERATOR'
const SET_OPERATOR_PAYOUT = 'SET_OPERATOR_PAYOUT'
const SET_OPERATOR_CURRENT_USER_DEL = 'SET_OPERATOR_CURRENT_USER_DEL'
const SET_OPERATOR_BANNER = 'SET_OPERATOR_BANNER'
const SET_EDIT_OPERATOR_BANNER = 'SET_EDIT_OPERATOR_BANNER'
const SET_OPERATORS_AUTO_MESSAGE = 'SET_OPERATORS_AUTO_MESSAGE'
const SET_ACTIVE_OPERATOR = 'SET_ACTIVE_OPERATOR'
const SET_TMP_FILTERS_OPERATORS = 'SET_TMP_FILTERS_OPERATORS'
const SET_RESET_FILTERS_OPERATORS = 'SET_RESET_FILTERS_OPERATORS'
const SET_RESET_FILTERS_OPERATORS_OPERATOR = 'SET_RESET_FILTERS_OPERATORS_OPERATOR'

export {
  SET_OPERATORS,
  SET_OPERATOR_SETTINGS,
  SET_CLEAR_OPERATOR_DATA,
  SET_OPERATOR_USERS,
  SET_OPERATOR_CURRENT_USER,
  SET_SEARCH_OPTIONS,
  SET_ADD_SEARCH_OPTIONS,
  SET_DEL_SEARCH_OPTIONS,
  SET_EDIT_SEARCH_OPTIONS,
  SET_ACTIVE_OPERATOR,
  SET_OPERATOR_STATISTIC,
  SET_CURRENT_OPERATOR,
  SET_OPERATOR_PAYOUT,
  SET_OPERATOR_CURRENT_USER_DEL,
  SET_OPERATOR_BANNER,
  SET_EDIT_OPERATOR_BANNER,
  SET_OPERATORS_AUTO_MESSAGE,
  SET_TMP_FILTERS_OPERATORS,
  SET_RESET_FILTERS_OPERATORS,
  SET_RESET_FILTERS_OPERATORS_OPERATOR
}
