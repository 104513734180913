const REQUEST_PAYMENTS_SETTING = 'REQUEST_PAYMENTS_SETTING'
const REQUEST_DELETE_PAYMENTS_SETTING = 'REQUEST_DELETE_PAYMENTS_SETTING'
const REQUEST_EDIT_PAYMENTS_SETTING = 'REQUEST_EDIT_PAYMENTS_SETTING'
const REQUEST_CREATE_PAYMENTS_SETTING = 'REQUEST_CREATE_PAYMENTS_SETTING'
const REQUEST_EDIT_DEFAULT_SETTING = 'REQUEST_EDIT_DEFAULT_SETTING'
const REQUEST_CREATE_DEFAULT_SETTING = 'REQUEST_CREATE_DEFAULT_SETTING'

export {
  REQUEST_PAYMENTS_SETTING,
  REQUEST_DELETE_PAYMENTS_SETTING,
  REQUEST_EDIT_PAYMENTS_SETTING,
  REQUEST_CREATE_PAYMENTS_SETTING,
  REQUEST_EDIT_DEFAULT_SETTING,
  REQUEST_CREATE_DEFAULT_SETTING
}
