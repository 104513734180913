<template>
  <div class="wrap">
    <div class="header_wrapper">
      <ClaimHeader />
    </div>
    <div class="header_wrapper-table">
      <ClaimTableHeader />
    </div>
    <div v-for="claim in claims" :key="claim.id" class="header_wrapper-list">
      <UserClaim :claim="claim" :key="claim.id" />
    </div>
    <UserClaimAddPopup />
    <ClaimDeletePopup />
    <UserClaimEditPopup />
  </div>
</template>

<script>
import ClaimHeader from '@/components/UserClaims/Header/Claim-header'
import UserClaim from '@/components/UserClaims/User-claim'
import ClaimTableHeader from '@/components/UserClaims/Header/Claim-table-header'
import { REQUEST_ALL_USERS_CLAIMS } from '@/store/action-types'
import { GET_ALL_USERS_CALIMS } from '@/store/getter-types'
import ClaimDeletePopup from '@/components/Modals/UserClaimAddPopup/ClaimDeletePopup'
import UserClaimAddPopup from '@/components/Modals/UserClaimAddPopup/UserClaimAddPopup'
import UserClaimEditPopup from '@/components/Modals/UserClaimAddPopup/UserClaimEditPopup'
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  name: 'interfaceUserClaimsNotification',
  components: {
    ClaimHeader,
    ClaimTableHeader,
    UserClaim,
    UserClaimAddPopup,
    ClaimDeletePopup,
    UserClaimEditPopup
  },
  data() {
    return {
      langs: []
    }
  },
  computed: {
    ...mapGetters({
      claims: GET_ALL_USERS_CALIMS
    })
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_ALL_USERS_CLAIMS).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  }
  // mounted() {
  //   Array.from(this.claims, el => this.langs.push(el.lang))
  // }
}
</script>

<style lang="scss">
.header_wrapper {
  min-height: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  padding: 15px 15px;
  background-color: #fff;
  border-radius: 4px;
}

.header_wrapper-table {
  margin: 10px;
}

.header_wrapper-list {
  margin: 10px;
}
</style>
