<template>
  <Popup :visible="visible" @close="close" description="Удалить категорию?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteCategoryEvent">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EVENT_CATEGORY_DELETE } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_DELETE_CATEGORY_EVENT } from '@/store/action-types'

export default {
  name: 'EventCategoryDeletePopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data() {
    return {
      id: ''
    }
  },
  methods: {
    ...mapActions({
      deleteCategory: REQUEST_DELETE_CATEGORY_EVENT
    }),
    deleteCategoryEvent() {
      this.deleteCategory(this.id)
      this.id = ''
      this.close()
    }
  },

  mounted() {
    this.$root.$on(EVENT_CATEGORY_DELETE, eventcat => {
      this.open()
      this.id = eventcat
    })
  }
}
</script>
