import Request from '../helpers/Request'
import BaseService from './BaseService'

class PurchasesService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getAllPurchases() {
    const getAllPurchasesUrl = `/api/v1/localization/purchase/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getAllPurchasesUrl, { headers })
  }

  async editPurchase(achievement) {
    const purchaseEditUrl = `/api/v1/localization/purchase/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(purchaseEditUrl, achievement, { headers })
  }

  async deletePurchase(data) {
    const deletePurchaseUrl = `/api/v1/localization/purchase/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deletePurchaseUrl, { data, headers })
  }

  async updatePurchase(purchase) {
    const updatePurchaseUrl = `/api/v1/localization/purchase/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.put(updatePurchaseUrl, purchase, { headers })
  }
}

export default PurchasesService
