<template>
  <div class="operators-wrap">
    <OperatorsHeaders />
    <OperatorsTabletHeader />
    <div v-if="operators.length && isLoaded">
      <Operator v-for="operator in operators" :key="operator.id" :operator="operator" />
    </div>
    <div class="operators__error" v-if="isLoaded && !operators.length">
      <span class="operators__error-text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="operators__error">
      <span class="operators__error-text">Ошибка при загрузке операторов</span>
    </div>
    <NewOperatorPopup />
    <EditOperatorPopup />
    <DeleteOperatorPopup />
    <AddUserPopup />
    <DelUserPopup />
    <TransferOpertaorPopup />
    <EditUserSearchOptions />
    <AddSearchOptionPopup />
    <DelSearchOptionPopup />
    <EditSearchOptionPopup />
    <OperatorNoClosePopup />
    <EditOperatorUserName />
    <OperatorAutoMessageDelay />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { REQUEST_ALL_OPERATORS } from '@/store/action-types'
import { GET_OPERATORS } from '@/store/getter-types'

import Operator from '@/components/Operators/Operator'

import store from '@/store'
import OperatorsHeaders from '@/components/Operators/OperatorsHeaders'
import OperatorsTabletHeader from '@/components/Operators/OperatorsTabletHeader'
import NewOperatorPopup from '@/components/Modals/OperatorsPopup/NewOperatorPopup'
import AddUserPopup from '@/components/Modals/OperatorsPopup/AddUserPopup'
import DelUserPopup from '@/components/Modals/OperatorsPopup/DelUserPopup'
import AddSearchOptionPopup from '@/components/Modals/OperatorsPopup/AddSearchOptionPopup'
import DelSearchOptionPopup from '@/components/Modals/OperatorsPopup/DelSearchOptionPopup'
import EditSearchOptionPopup from '@/components/Modals/OperatorsPopup/EditSearchOptionPopup'
import EditOperatorPopup from '@/components/Modals/OperatorsPopup/EditOperatorPopup'
import TransferOpertaorPopup from '@/components/Modals/OperatorsPopup/TransferOpertaorPopup'
import DeleteOperatorPopup from '@/components/Modals/OperatorsPopup/DeleteOperatorPopup'
import EditUserSearchOptions from '@/components/Modals/OperatorsPopup/EditUserSearchOptions'
import OperatorNoClosePopup from '@/components/Modals/OperatorsPopup/OperatorNoClosePopup'
import EditOperatorUserName from '../../components/Modals/OperatorsPopup/EditOperatorUserName'
import OperatorAutoMessageDelay from '@/components/Modals/OperatorsPopup/OperatorAutoMessageDelay'

export default {
  name: 'Operators',
  components: {
    EditOperatorUserName,
    OperatorNoClosePopup,
    EditUserSearchOptions,
    DeleteOperatorPopup,
    EditOperatorPopup,
    EditSearchOptionPopup,
    DelSearchOptionPopup,
    AddSearchOptionPopup,
    AddUserPopup,
    OperatorsTabletHeader,
    OperatorsHeaders,
    Operator,
    TransferOpertaorPopup,
    NewOperatorPopup,
    DelUserPopup,
    OperatorAutoMessageDelay
  },

  data: () => ({
    errorMessage: false,
    isLoaded: false
  }),

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_ALL_OPERATORS).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },

  computed: {
    ...mapGetters({
      operators: GET_OPERATORS
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
.operators {
  &-wrap {
    padding: 16px;
  }
  &-list {
    position: relative;
    @media (max-width: 1020px) {
      margin-top: 70px;
    }
    &__text {
      margin: 0 auto;
    }
  }

  &-list--error {
    position: relative;
    display: flex;
    min-height: 64px;
    align-items: center;
    background-color: white;
    @media (max-width: 1020px) {
      margin-top: 70px;
    }
  }
}

.operators__error {
  padding: 0 16px 0 16px;
  min-height: 64px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;

  &-text {
    margin: 0 auto;
  }
  &--big {
    width: 100%;
    margin: 0;
  }
}
</style>
