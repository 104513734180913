import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_APPS_FLYER]: (state, payload) => {
    state.appsFlyer = payload
  },
  [MutationTypes.SET_REQUEST_APPS_FLYER_IOS]: (state, payload) => {
    state.appsFlyerIos = payload
  },
  [MutationTypes.SET_ROYAL_USERS_INSTALL_IOS]: (state, payload) => {
    state.loyalInstalIos = payload
  },
  [MutationTypes.SET_ROYAL_USERS_INSTALL]: (state, payload) => {
    state.loyalInstal = payload
  },
  [MutationTypes.SET_USERS_ACTIVITY]: (state, payload) => {
    state.usersActivity = payload
  },
  [MutationTypes.SET_USERS_COUNT]: (state, payload) => {
    state.usersCount = payload
  },
  [MutationTypes.SET_USERS_ONLINE_BY_CITY]: (state, payload) => {
    state.usersOnlineByCity = payload
  },
  [MutationTypes.SET_USERS_ONLINE_ONE_DAY]: (state, payload) => {
    state.usersOnlineOneDay = payload
  },
  [MutationTypes.SET_USERS_ONLINE_ONE_DAY_SITE_AND_APP]: (state, payload) => {
    state.usersOnlineOneDaySource = payload
  },
  [MutationTypes.SET_PURCHASES_MARKET_PLACES]: (state, payload) => {
    state.purchasesMarketPlaces = payload
  },
  [MutationTypes.SET_PURCHASES_BY_CITY]: (state, payload) => {
    state.purchasesByCity = payload
  },
  [MutationTypes.SET_PURCHASES_BY_COUNTRY]: (state, payload) => {
    state.purchasesByCountry = payload
  },
  [MutationTypes.SET_PURCHASES_BY_TIME]: (state, payload) => {
    state.purchasesByTime = payload
  },
  [MutationTypes.SET_PURCHASES_BY_PAYERS_PAYMENTS]: (state, payload) => {
    state.purchasesByPayersPayments = payload
  },
  [MutationTypes.SET_NEW_USER_ACTIVE_BY_DATE]: (state, payload) => {
    state.newUserActiveByDate = payload
  },

  [MutationTypes.SET_MODERATORS_PHOTO_CHECK]: (state, payload) => {
    state.statsModeratorPhotoCheck = payload
  },
  [MutationTypes.SET_MODERATOTS_VIDEO_CHECK]: (state, payload) => {
    state.videoModaration = payload
  },
  [MutationTypes.SET_USERS_GENDER]: (state, payload) => {
    state.usersGender = payload
  },
  [MutationTypes.SET_PURCHASES_POINTS]: (state, paylaod) => {
    state.ballsPurchases = paylaod
  },

  [MutationTypes.SET_NEW_USER_ACTIVE]: (state, payload) => {
    state.newUserActive = payload
  },
  [MutationTypes.SET_USERS_PAYING]: (state, payload) => {
    state.usersPaying = payload
  },

  [MutationTypes.SET_USERS_AGE]: (state, payload) => {
    state.usersAge = payload
  },
  [MutationTypes.SET_USER_LIFETIME]: (state, payload) => {
    state.usersLifetime = payload
  },
  [MutationTypes.SET_REGS_NEW_USERS]: (state, payload) => {
    state.activityFromSite = payload
  },
  [MutationTypes.SET_PURCHASES_VIP_STATUS]: (state, payload) => {
    state.vipStatusPurchases = payload
  },
  [MutationTypes.SET_OPERATORS_MESSAGE]: (state, payload) => {
    state.operatorMessages = payload
  },
  [MutationTypes.SET_OPERATORS_CASHES]: (state, payload) => {
    state.operatorCaches = payload
  },
  [MutationTypes.SET_PURCHASE_TOTAL]: (state, payload) => {
    state.purchaseTotal = payload
  },
  [MutationTypes.SET_PURCHASES_BY_DATE]: (state, payload) => {
    state.purchasesByDate = payload
  },
  [MutationTypes.SET_COUNTERS_MESSAGES_OPERATORS]: (state, payload) => {
    let users = []
    for (let item in payload) {
      users.push(payload[item])
    }
    state.messageCounters = users.sort((prev, next) => next.counters['Входящие'] - prev.counters['Входящие'])
  },

  //Поменять после изменений
  [MutationTypes.SET_STATISTICS_PROFILE_MESSAGES]: (state, payload) => {
    // let user = []
    // for (let item in payload.data) {
    //   user.push(payload.users[item])
    // }
    state.messageStatistics = payload.data
  },
  [MutationTypes.SET_STATISTICS_PROFILE_MESSAGES_PAGINATION]: (state, payload) => {
    // let user = []
    // for (let item in payload.users) {
    //   user.push(payload.users[item])
    // }
    state.messageStatistics.push(...payload.data)
  },
  [MutationTypes.SET_SEASON_OPERATOR]: (state, paylaod) => {
    state.operatorSeason = paylaod.season
  }
}
