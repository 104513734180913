<template>
  <li class="achievement__item">
    <img :src="getFile({ type: 'image', file: item.icon })" alt="" />
    <div class="achievement__name achievement__element">
      <span class="achievement__text">Название:</span>
      <span>{{ item.title }}</span>
    </div>
    <div class="achievement__description achievement__element" title="item.description">
      <span class="achievement__text">Описание:</span>
      <span>{{ item.description }}</span>
    </div>
    <div class="achievement__kind achievement__element">
      <span class="achievement__text">Kind:</span>
      <span>{{ item.kind }}</span>
    </div>
    <div class="achievement__points achievement__element">
      <span class="achievement__text">Количество баллов:</span>
      <span class="achievement__point">
        {{ item.price }}
      </span>
    </div>
    <div class="achievement__btn-wrap achievement__element">
      <button
        @click="$root.$emit('ACHIEVEMENT_POPUP_EVENT', item)"
        class="achievement__btn achievement__btn-change achievement__btn--green"
      >
        <i class="fa fa-edit"></i>
      </button>
      <button
        @click="$root.$emit('ACHIEVEMENT_DELETE_POPUP_EVENT', item.id)"
        class="achievement__btn achievement__btn-delete achievement__btn--red"
      >
        <i class="fa fa-times-circle"></i>
      </button>
    </div>
  </li>
</template>

<script>
import getImgLink from '@/components/mixins/getImgCdnLink'
export default {
  name: 'AchievementItem',
  props: {
    item: {
      type: Object
    }
  },
  mixins: [getImgLink]
}
</script>

<style lang="scss">
.achievement__text {
  display: none;
  @media (max-width: 500px) {
    display: flex;
  }
}
.achievement__item {
  img {
    width: 100px;
    height: 100px;
  }
}
</style>
