<template>
  <div class="error__wrapper">
    <div class="error__body">
      <img src="@/assets/img/404_icon.png" alt="" class="error__icon" />
      <h1 class="error__text error__text--num">404</h1>
      <h2 class="error__text">Страница не найдена</h2>
      <router-link to="/" class="error__back">Вернуться на главную</router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Errorpage',
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vars.scss';
.error__wrapper {
  width: 100%;
  height: calc(100vh - 102px);
  text-align: center;
  background-color: $turquoise-400;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 60px;
    top: 0;
    left: 0;
    background-color: $turquoise-400;
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 42px;
    bottom: 0;
    left: 0;
    background-color: $turquoise-400;
  }
}
.error__icon {
  display: block;
  position: absolute;
  padding-left: 25px;
  left: 50%;
  top: 170px;
  transform: translate(-50%, 0);
  @media (max-width: 640px) {
    top: 50px;
  }
}
.error__text {
  color: $white;
  text-align: center;
  font-size: 30px;
}
.error__text--num {
  padding-top: 350px;
  font-size: 90px;
  @media (max-width: 640px) {
    padding-top: 230px;
  }
}
.error__home {
  text-decoration: none;
  color: #000;
  text-align: center;
}

.error__back {
  display: inline-block;
  margin-top: 4px;
  text-decoration: underline;
  font-size: 15px;
}
.router-link-active {
  color: #fff;
}
</style>
