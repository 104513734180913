import Request from '../helpers/Request'
import BaseService from './BaseService'

class ModeratorPaymentsService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getModeratorPayments() {
    const moderatorsUrl = `/api/v1/management/payout-moderator/settings/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(moderatorsUrl, { headers })
  }

  async editModeratorPayment(data) {
    const moderatorEditUrl = `/api/v1/management/payout-moderator/settings/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.put(moderatorEditUrl, data, { headers })
  }

  async deleteModeratorPayment(data) {
    const moderatorDestroyUrl = `/api/v1/management/payout-moderator/settings/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(moderatorDestroyUrl, { data, headers })
  }

  async addModeratorPayment(data) {
    const moderatorAddUrl = '/api/v1/management/payout-moderator/settings/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(moderatorAddUrl, data, { headers })
  }

  async getModerators() {
    const operatorsUrl = `/api/v1/management/operators/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(operatorsUrl, { headers })
  }

  async getModeratorStatistic(id) {
    const moderatorStatisticUrl = `/api/v1/stats/moderator-stats/full-work-moderator-stats/?moderator_id=${id}`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(moderatorStatisticUrl, { headers })
  }

  async getModeratorsPayout(formData) {
    const moderatorsUrl = `/api/v1/management/payout-moderator/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(moderatorsUrl, { headers, params: formData })
  }

  async billingModerator(value) {
    const moderatorBillingUrl = '/api/v1/management/payout-moderator/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    if (!value) {
      value = {}
    }

    return await Request.post(moderatorBillingUrl, value, { headers })
  }

  async prizeModerator(value) {
    const moderatorPrizeUrl = '/api/v1/management/payout-moderator/prize/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(moderatorPrizeUrl, value, { headers })
  }

  async correctionModerator(value) {
    const moderatorPrizeUrl = '/api/v1/management/payout-moderator/edit/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(moderatorPrizeUrl, value, { headers })
  }

  async executeModerator(value) {
    const executeModeratorUrl = '/api/v1/management/payout-moderator/execute/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(executeModeratorUrl, value, { headers })
  }
}

export default ModeratorPaymentsService
