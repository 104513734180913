<template>
  <div class="statistics">
    <h2 class="statistics__title">Источник установок</h2>
    <StatisticAppsFlyer v-if="isLoadedAppsFlyer" :appsFlyer="appsFlyer" :appsFlyerIos="appsFlyerIos" />
    <div v-if="isLoadedAppsFlyerError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <div class="statistics__title">Лояльные пользователи</div>
    <StatisticLoyalUsers v-if="isLoadedLoyalUsers" :royalUsers="royalUsers" :royalUsersIos="royalUsersIos" />
    <div v-if="isLoadedLoyalUsersError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <div v-if="errorMessage" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке </span>
    </div>
  </div>
</template>
<script>
import StatisticLoyalUsers from '@/components/Statistic/StatisticLoyalUsers'
import StatisticAppsFlyer from '@/components/Statistic/StatisticAppsFlyer'
import { mapGetters } from 'vuex'
import {
  REQUEST_APPS_FLYER,
  REQUEST_ROYAL_USERS_INSTALL,
  REQUEST_APPS_FLYER_IOS,
  REQUEST_ROYAL_USERS_INSTALL_IOS
} from '@/store/action-types'

import {
  GET_APPS_FLYER,
  GET_ROYAL_USERS_INSTALL,
  GET_APPS_FLYER_IOS,
  GET_ROYAL_USERS_INSTALL_IOS
} from '@/store/getter-types'
import store from '@/store'
import moment from 'moment'

export default {
  name: 'StatisticsTraffic',
  components: {
    StatisticAppsFlyer,
    StatisticLoyalUsers
  },
  data: () => ({
    errorMessage: false,
    isLoadedAppsFlyer: false,
    isLoadedAppsFlyerError: false,
    isLoadedLoyalUsers: false,
    isLoadedLoyalUsersError: false
  }),
  beforeRouteEnter(to, from, next) {
    let date = {
      date_from: moment()
        .subtract(14, 'days')
        .startOf('day')
        .format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD')
    }
    let date_ios = {
      date_from: moment()
        .subtract(14, 'days')
        .startOf('day')
        .format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      device: 'ios'
    }
    next(vm => {
      store.dispatch(REQUEST_APPS_FLYER, date).then(response => {
        if (!response.status) {
          vm.isLoadedAppsFlyerError = true
        } else {
          store.dispatch(REQUEST_APPS_FLYER_IOS, date_ios).then(res => {
            vm.isLoadedAppsFlyer = true
          })
        }
      })
      store.dispatch(REQUEST_ROYAL_USERS_INSTALL, date).then(response => {
        if (!response.status) {
          vm.isLoadedLoyalUsersError = true
        } else {
          store.dispatch(REQUEST_ROYAL_USERS_INSTALL_IOS, date_ios).then(res => {
            vm.isLoadedLoyalUsers = true
          })
        }
      })
    })
  },

  computed: {
    ...mapGetters({
      appsFlyer: GET_APPS_FLYER,
      appsFlyerIos: GET_APPS_FLYER_IOS,
      royalUsersIos: GET_ROYAL_USERS_INSTALL_IOS,
      royalUsers: GET_ROYAL_USERS_INSTALL
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>
<style lang="scss">
.statistics {
  width: 100%;
  &__title {
    margin: 10px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #c9cdd7;
  }
  &__error {
    margin-top: 20px;
    padding: 0 16px 0 16px;
    min-height: 64px;
    border-radius: 2px;

    position: relative;
    display: flex;
    align-items: center;
    background-color: white;

    &-text {
      margin: 0 auto;
    }
  }
}
.regenterstats-chart {
  height: 290px;
}
.table-condensed {
  td:hover {
    background: #318bcc;
    color: #fff;
  }
  .today {
    background: #48a7eb;
    color: #fff;
  }
}
</style>
