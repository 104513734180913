import * as MutationTypes from './mutations-types'
export default {
  [MutationTypes.SET_ALL_LANGUAGES]: (state, payload) => {
    state.languages = payload
  },

  [MutationTypes.SET_CURRENT_LANGUAGES]: (state, payload) => {
    state.currentLang = payload
  },

  [MutationTypes.SET_CURRENT_LANG_ID]: (state, payload) => {
    state.currentLangId = payload
  }
}
