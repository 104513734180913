<template>
  <Popup :visible="visible" @close="closePopup" description="Рассчитать оператора?" modalClass="popup--small">
    <div class="popup__item">
      <span class="popup__item-text">Рассчитать до:</span>
      <input class="popup__item-date" type="date" v-model="date" />
      <input class="popup__item-date" type="time" v-model="time" />
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="billHandler()">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_BILLING_OPERATORS } from '@/store/action-types'
import { GET_OPERATOR_TMP_ROLES } from '@/store/getter-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { BILL_OPERATOR_POPUP_EVENT } from '@/constants/event'
import moment from 'moment'
export default {
  name: 'BillOperator',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    BILL_OPERATOR_POPUP_EVENT,
    operatorId: null,
    pay: null,
    date: null,
    time: null
  }),
  computed: {
    ...mapGetters({
      tmpFilter: GET_OPERATOR_TMP_ROLES
    })
  },

  methods: {
    ...mapActions({
      billOperator: REQUEST_BILLING_OPERATORS
    }),
    closePopup() {
      this.operatorId = null
      this.pay = null
      this.date = null
      this.time = null
      this.close()
    },
    billHandler() {
      let formData = {
        data: {
          operator_id: this.operatorId,
          pay_id: this.pay
        },
        active: null
      }
      if (this.date) {
        if (this.time) {
          formData.data.payout_period_to = moment([this.date, this.time].join(' ')).format(
            'YYYY-MM-DDTHH:mm:ss'
          )
        } else {
          formData.data.payout_period_to = moment(this.date).format('YYYY-MM-DDTHH:mm:ss')
        }
      }
      if (this.tmpFilter.status.name && this.tmpFilter.status.name !== 'Все') {
        formData.active = {
          is_active: this.tmpFilter.status.value
        }
      }
      this.billOperator(formData)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(BILL_OPERATOR_POPUP_EVENT, data => {
      this.operatorId = data.operator
      this.pay = data.pay

      this.open()
    })
  }
}
</script>
<style lang="scss">
.popup__item-date {
  width: 100%;
  height: 30px;
  font-family: inherit;
  margin-bottom: 10px;
}
</style>
