<template>
  <div class="payments-country">
    <div class="payments-country__wrapper">
      <div class="payments-country__btn-group">
        <button
          class="user-active__btn user-active__btn--left user-active__btn--default"
          @click="payments_country_form.day = 1"
          :class="{ 'user-active__btn--danger': payments_country_form.day === 1 }"
          :disabled="payments_country_loading === true"
        >
          Вчера
        </button>
        <button
          class="user-active__btn user-active__btn--center user-active__btn--default"
          @click="payments_country_form.day = 2"
          :class="{ 'user-active__btn--danger': payments_country_form.day === 2 }"
          :disabled="payments_country_loading === true"
        >
          Сегодня
        </button>
        <button
          class="user-active__btn user-active__btn--center user-active__btn--default"
          @click="payments_country_form.day = 3"
          :class="{ 'user-active__btn--danger': payments_country_form.day === 3 }"
          :disabled="payments_country_loading === true"
        >
          7 дней
        </button>
        <button
          class="user-active__btn user-active__btn--center user-active__btn--default"
          @click="payments_country_form.day = 4"
          :class="{ 'user-active__btn--danger': payments_country_form.day === 4 }"
          :disabled="payments_country_loading === true"
        >
          14 дней
        </button>
        <button
          class="user-active__btn user-active__btn--center user-active__btn--default"
          @click="payments_country_form.day = 5"
          :class="{ 'user-active__btn--danger': payments_country_form.day === 5 }"
          :disabled="payments_country_loading === true"
        >
          30 дней
        </button>
        <button @click="periodBtn" class="btn btn-primary">
          Период
        </button>
      </div>
      <div class="datepicker-traffic-source">
        <i class="select__field-icon fa fa-angle-down"></i>
        <DateRangePicker
          :locale-data="picker.localeData"
          :opens="picker.localeData.position"
          :close-on-esc="true"
          :autoApply="true"
          :ranges="false"
          ref="traffic"
          v-model="datePickerControlTraffic"
        >
        </DateRangePicker>
      </div>
      <div class="payments-country__item">
        <input
          type="text"
          class="payments-country__input form-control"
          v-model="payments_country_form.city"
          placeholder="Страна"
        />
      </div>

      <div class="flex-1">
        <div class="online-stat-current">
          <div>
            <span v-if="paymentByCountry.all_sum">{{
              'Общая: ' +
                Math.ceil(payments_country_statistic['sum_country']) +
                'руб. / С комиссией: ' +
                Math.ceil(payments_country_statistic['sum_country'] * 0.7) +
                ' руб.'
            }}</span>
            <span v-else>
              Общая: 0 руб. / С комиссией: 0 руб.
            </span>
          </div>
        </div>
      </div>
      <div class="user-active__right">
        <ServerTimeFilter :utcFlag="form.utc" @changeUtc="changeUtc($event)" />
        <div>
          <button
            class="user-active__btn user-active__btn--default user-active__btn--left "
            @click="payments_country_form.count = 1"
            :class="{ 'user-active__btn--danger': payments_country_form.count === 1 }"
            :disabled="payments_country_loading === true"
          >
            20 стран
          </button>
          <button
            class="user-active__btn user-active__btn--default user-active__btn--center "
            @click="payments_country_form.count = 2"
            :class="{ 'user-active__btn--danger': payments_country_form.count === 2 }"
            :disabled="payments_country_loading === true"
          >
            40 стран
          </button>
          <button
            class="user-active__btn user-active__btn--default user-active__btn--right "
            @click="payments_country_form.count = 3"
            :class="{ 'user-active__btn--danger': payments_country_form.count === 3 }"
            :disabled="payments_country_loading === true"
          >
            60 стран
          </button>
        </div>
      </div>
    </div>

    <div>
      <BarChart
        :chart-data="payments_country_statistic"
        :options="payments_country_statistic_opt"
        class="regenterstats-chart"
      ></BarChart>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import 'chartjs-plugin-datalabels'
import BarChart from '@/components/Statistic/BarChart'
import { mapActions } from 'vuex'
import { REQUEST_PURCHASES_BY_COUNTRY } from '@/store/action-types'
import pickerMixin from '@/components/Picker/pickerMixin'
import ServerTimeFilter from '@/components/Statistic/Servertimefilter/ServerTimeFilter'
export default {
  name: 'StatisticPaymentsByCountry',
  components: {
    BarChart,
    DateRangePicker,
    ServerTimeFilter
  },
  props: {
    paymentByCountry: {
      type: Object
    }
  },
  mixins: [pickerMixin],
  data: () => ({
    payments_country_loading: false,
    form: {
      type: 0,
      utc: 1
    },
    payments_country_form: {
      start_date: '',
      end_date: '',
      count: 1,
      day: 2,
      city: ''
    },
    datepicker_dates: {
      date_from: moment()
        .endOf('day')
        .toString(),
      date_to: moment()
        .endOf('day')
        .toString()
    },
    payments_country_statistic: {
      datasets: [
        {
          borderColor: 'rgba(255,108,96,0)',
          backgroundColor: 'rgba(123, 180, 99, 0.5)',
          fill: true,
          label: 'Google',
          data: [],
          male: [],
          feMale: [],
          maxBarThickness: 100
        },
        {
          borderColor: 'rgba(151,187,205,0)',
          backgroundColor: 'rgba(255,35,45,0.5)',
          fill: true,
          label: 'iTunes',
          data: [],
          male: [],
          feMale: [],
          maxBarThickness: 100
        },
        {
          borderColor: 'rgba(108,202,201,0)',
          backgroundColor: 'rgba(108,202,201,0.5)',
          fill: true,
          label: 'ROYAL PAY',
          data: [],
          male: [],
          feMale: [],
          maxBarThickness: 100
        }
      ],
      sum_country: 0,
      date_from: 0,
      date_to: 0,
      delta: 0
    },
    payments_country_statistic_opt: {
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              suggestedMax: 10,
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }
        ],
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            }
          }
        ]
      },
      legend: {
        display: true
      },
      tooltips: {
        enabled: true,
        mode: 'label',
        itemSort: function(a, b) {
          return b.datasetIndex - a.datasetIndex
        },
        callbacks: {
          label: function(tooltipItems, data) {
            let index1, index2, men, women, array
            index1 = data.datasets[tooltipItems.datasetIndex]
            index2 = data.labels.indexOf(tooltipItems.xLabel)
            men = isNaN(Number(index1.male[index2])) ? 0 : index1.male[index2]
            women = isNaN(Number(index1.feMale[index2])) ? 0 : index1.feMale[index2]
            array = [
              data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.yLabel,
              'М: ' + men + ' / Ж: ' + women
            ]
            return array
          }
        }
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let datasets = ctx.chart.data.datasets
            let firstIndex = null
            let sum = 0

            for (let [index, value] of Object.entries(datasets)) {
              if (ctx.chart.isDatasetVisible(index)) {
                firstIndex = parseInt(index)
                sum += parseInt(value.data[ctx.dataIndex])
              }
            }

            if (sum === 0) {
              return 0
            }

            return ctx.datasetIndex === firstIndex ? sum.toLocaleString() : ''
          },
          font: {
            size: 12
          },
          padding: {
            top: 0,
            bottom: -10
          },
          anchor: 'end',
          align: 'end',
          rotation: 0,
          clamp: true
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      height: 200,
      onClick: function(ent, item) {
        if (item.length != 0) {
          const chartData = item[0]['_chart'].config.data
          const idx = item[0]['_index']
          const country = chartData.labels[idx]
          const delta = item[0]['_chart'].config.data.delta
          const timestampFrom = new Date(chartData.date_from).setUTCHours(0, 0, 0)
          const timestampTo = new Date(chartData.date_to).setUTCHours(23, 59, 59)
          window.location = `/users/?page=0&purchase_date=${timestampFrom / 1000},${timestampTo /
            1000}&delta=${delta}&userDeleted=Неважно&country=${country}`
        }
      }
    }
  }),

  mounted() {
    this.reLoaded()
  },

  computed: {
    datePickerControlTraffic: {
      get() {
        const date = {
          startDate: this.datepicker_dates.date_from,
          endDate: this.datepicker_dates.date_to
        }
        return date
      },
      set({ startDate, endDate }) {
        this.datepicker_dates.date_from = new Date(startDate)
        this.datepicker_dates.date_to = new Date(endDate)
        this.payments_country_form.start_date = this.datepicker_dates.date_from
        this.payments_country_form.end_date = this.datepicker_dates.date_to
      }
    }
  },
  watch: {
    'payments_country_form.count': function() {
      this.getPaymentsCityStatistic()
    },
    'payments_country_form.day': function() {
      this.formatPaymentsCountryDate()
    },
    'form.type': function() {
      this.getPaymentsCityStatistic()
    },
    'form.utc': function() {
      this.formatPaymentsCountryDate()
    }
  },

  methods: {
    ...mapActions({
      getPaymentByCity: REQUEST_PURCHASES_BY_COUNTRY
    }),
    reLoaded() {
      this.payments_country_statistic.labels = this.paymentByCountry.country
      this.payments_country_statistic.datasets[0].data = this.paymentByCountry.gc
      this.payments_country_statistic.datasets[1].data = this.paymentByCountry.ac
      this.payments_country_statistic.datasets[2].data = this.paymentByCountry.rc
      this.payments_country_statistic.datasets[0].male = this.paymentByCountry.gm
      this.payments_country_statistic.datasets[1].male = this.paymentByCountry.am
      this.payments_country_statistic.datasets[2].male = this.paymentByCountry.rm
      this.payments_country_statistic.datasets[0].feMale = this.paymentByCountry.gw
      this.payments_country_statistic.datasets[1].feMale = this.paymentByCountry.aw
      this.payments_country_statistic.datasets[2].feMale = this.paymentByCountry.rw
      this.payments_country_statistic.sum_country = this.paymentByCountry.all_sum
      this.payments_country_statistic.date_from = this.payments_country_form.start_date
        ? this.payments_country_form.start_date
        : moment().format('YYYY-MM-DD')
      this.payments_country_statistic.date_to = this.payments_country_form.end_date
        ? this.payments_country_form.end_date
        : moment().format('YYYY-MM-DD')
      this.payments_country_statistic.delta = this.form.utc
        ? `${this.form.utc ? new Date().getTimezoneOffset() / -60 : 0}`
        : 0
    },
    formatPaymentsCountryDate() {
      let date = {}
      switch (this.payments_country_form.day) {
        case 1:
          date = {
            date_from: moment()
              .subtract(1, 'days')
              .startOf('day'),
            date_to: moment()
              .subtract(1, 'days')
              .endOf('day')
          }
          break
        case 2:
          date = { date_from: moment().startOf('day'), date_to: moment().endOf('day') }
          break
        case 3:
          date = {
            date_from: moment()
              .subtract(7, 'days')
              .startOf('day'),
            date_to: moment().endOf('day')
          }
          break
        case 4:
          date = {
            date_from: moment()
              .subtract(14, 'days')
              .startOf('day'),
            date_to: moment().endOf('day')
          }
          break
        case 5:
          date = {
            date_from: moment()
              .subtract(30, 'days')
              .startOf('day'),
            date_to: moment().endOf('day')
          }
          break
        default:
          if (!this.payments_country_form.start_date) {
            date = { date_from: moment().startOf('day'), date_to: moment().endOf('day') }
          } else {
            date = {
              date_from: this.payments_country_form.start_date.toString(),
              date_to: this.payments_country_form.end_date.toString()
            }
          }
      }
      this.payments_country_form.start_date = moment(date.date_from).format('YYYY-MM-DD')
      this.payments_country_form.end_date = moment(date.date_to).format('YYYY-MM-DD')
      this.$refs.traffic.start = new Date(this.payments_country_form.start_date)
      this.$refs.traffic.end = new Date(this.payments_country_form.end_date)
      this.getPaymentsCityStatistic()
    },
    periodBtn() {
      if (this.payments_country_form.day === 6) {
        this.formatPaymentsCountryDate()
      } else {
        this.payments_country_form.day = 6
      }
    },
    getPaymentsCityStatistic() {
      let count = 0
      switch (this.payments_country_form.count) {
        case 1:
          count = 20
          break
        case 2:
          count = 40
          break
        case 3:
          count = 60
          break
        default:
          break
      }

      let data = {
        city_count: count,
        date_from: this.payments_country_form.start_date
          ? this.payments_country_form.start_date
          : moment().format('YYYY-MM-DD'),
        date_to: this.payments_country_form.end_date
          ? this.payments_country_form.end_date
          : moment().format('YYYY-MM-DD')
      }

      if (this.payments_country_form.city) {
        data.city = this.payments_country_form.city
      }
      if (this.form.utc) data.delta = new Date().getTimezoneOffset() / -60
      this.getPaymentByCity(data).then(response => this.reLoaded())
    },
    changeUtc(number) {
      this.form.utc = number
    }
  }
}
</script>

<style lang="scss">
@import './Statistic.scss';
.payments-country {
  padding: 16px;
}
.payments-country__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.payments-country__input {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.btn-primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  background-color: #41cac0;
  border-color: #41cac0;
  color: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
