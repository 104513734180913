import * as GetterTypes from './getter-types'
export default {
  [GetterTypes.GET_PURCHASES]: ({ purchases }) => purchases,

  [GetterTypes.GET_PURCHASES_ROLES]: ({ purchaseRoles }) => purchaseRoles,

  [GetterTypes.GET_PURCHASES_ROLES_DEFAULT]: ({ purchaseRolesDefault }) => purchaseRolesDefault,

  [GetterTypes.GET_PURCHASES_TMP_ROLES]: ({ purchaseTmpRoles }) => purchaseTmpRoles
}
