<template>
  <li class="left-bar__item">
    <div class="left-bar__main">
      <span class="left-bar__main-text">{{ nameFields(setting.settings_name) }}</span>
      <span class="left-bar__main-value">{{ setting.controller_select[setting.settings_name] }}</span>
      <input
        class="left-bar__main-input"
        v-model.number="newValue"
        :placeholder="oldData"
        type="text"
        ref="coefficient"
      />
      <button v-if="isNew" key="save" class="left-bar__btn left-bar__btn--green" @click="saveData">
        <i class="fas fa-save"></i>
      </button>
      <button v-if="!isNew" key="disabled" class="left-bar__btn">
        <i class="fas fa-save"></i>
      </button>
    </div>
    <ul class="left-bar__secondary-list">
      <ModeratorsSettingItemQuality
        v-for="(value, property) in setting.controller_select"
        :value="value"
        :property="property"
        :key="property"
        :setting="setting"
        :nameFields="nameFields"
      />
    </ul>
  </li>
</template>

<script>
import ModeratorsSettingItemQuality from './ModeratorsSettingItemQuality'
import { mapActions } from 'vuex'
import { REQUEST_UPDATE_MODERATOR_SETTINGS_QUALITY } from '@/store/action-types'

export default {
  name: 'ModeratorsSettingMainItemQuality',
  components: {
    ModeratorsSettingItemQuality
  },
  props: {
    setting: {
      type: Object
    }
  },
  data: () => ({
    ratingQualityList: [
      { key: 'approved', title: 'Одобрено' },
      { key: 'forbidden_for_avatar', title: 'Запрещено для аватара' },
      { key: 'forbidden_for_display', title: 'Запрещено для показа' },
      { key: 'temporarily_blocked', title: 'Врем. заблокировано' },
      { key: 'banned_account', title: 'Бан аккаунта' },
      { key: 'banned_device', title: 'Бан устройства' }
    ],
    oldData: 0,
    newValue: '',
    isNew: false
  }),
  mounted() {
    this.oldData = this.setting.controller_select[this.setting.settings_name]
    this.newValue = this.setting.controller_select[this.setting.settings_name]
  },
  updated() {
    this.oldData = this.setting.controller_select[this.setting.settings_name]
  },
  watch: {
    newValue: function() {
      if (this.newValue !== this.oldData) {
        this.isNew = true
      } else {
        this.isNew = false
      }
    },
    value: function() {
      this.oldData = this.newValue
    }
  },
  computed: {
    coefficientSetting: {
      get() {
        return this.newValue ? this.newValue : this.oldData
      },
      set(value) {
        this.$refs.coefficient.value = +value
        this.newValue = +value
      }
    }
  },
  methods: {
    ...mapActions({
      getNewOptions: REQUEST_UPDATE_MODERATOR_SETTINGS_QUALITY
    }),
    nameFields(name) {
      return this.ratingQualityList.find(f => f.key === name).title
    },
    saveData() {
      let newData = {
        id: this.setting.id,
        controller_select: this.setting.controller_select
      }
      newData.controller_select[this.setting.settings_name] = +this.newValue
      this.getNewOptions(newData).then(response => {
        this.isNew = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.left-bar__main-input {
  &::placeholder {
    color: #318bcc;
  }
}
</style>
