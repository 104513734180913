export default {
  activityFromSite: {},
  appsFlyer: null,
  appsFlyerIos: null,
  loyalInstal: null,
  loyalInstalIos: null,
  usersActivity: {},
  videoModaration: {},
  ballsPurchases: {},
  vipStatusPurchases: {},
  purchasesByDate: {},
  usersCount: {},
  operatorMessages: {},
  operatorCaches: {},
  usersOnlineByCity: null,
  usersOnlineOneDay: {},
  purchaseTotal: {},
  usersOnlineOneDaySource: {},
  purchasesMarketPlaces: {},
  purchasesByCity: {},
  purchasesByCountry: {},
  purchasesByTime: null,
  newUserActiveByDate: {},
  purchasesByPayersPayments: null,
  statsModeratorPhotoCheck: {},
  usersGender: {},
  newUserActive: {},
  usersPaying: {},
  messageCounters: [],
  messageStatistics: [],
  operatorSeason: {},
  usersAge: {},
  usersLifetime: {}
}
