<template>
  <Popup
    :visible="visible"
    @close="
      closeRow()
      close()
    "
    description=""
  >
    <div class="popup__header">
      <span class="popup__header-title">Список тарифов</span>
    </div>

    <div class="popup__table">
      <div class="popup__table-header">
        <span class="popup__header-item">Период</span>
        <span class="popup__header-item">Главная (фото)</span>
        <span class="popup__header-item">Внутреняя (фото)</span>
        <span class="popup__header-item">Видеоаватар</span>
        <span class="popup__header-item">Проверка события</span>
        <span class="popup__header-item">Редактирование события</span>
        <span class="popup__header-item">Бан аккаунта</span>
        <span class="popup__header-item">Дневная смена</span>
        <span class="popup__header-item">Ночная смена</span>
        <span class="popup__header-item">Активен</span>
        <span class="popup__header-item"></span>
      </div>
      <ul class="popup__table-list">
        <ItemPaymentPopup
          v-for="setting in settings"
          :key="`${setting.id}+${setting.is_active}`"
          :setting="setting"
        />
      </ul>
      <form v-if="addOpen" class="popup__table-add" @submit.prevent="addSetting">
        <div class="popup__add-item popup__add-item--calendar">
          <span>{{ toLocaleDatePayments() }}</span>
        </div>
        <div class="popup__add-item">
          <input
            type="number"
            class="popup__add-input"
            v-model="mainCoefficientSetting"
            ref="maincoefficientsetting"
            required
          />
          <svg
            class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="ruble-sign"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
            ></path>
          </svg>
        </div>
        <div class="popup__add-item">
          <input
            type="number"
            class="popup__add-input"
            v-model="inCoefficientSetting"
            ref="incoefficientsetting"
            required
          />
          <svg
            class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="ruble-sign"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
            ></path>
          </svg>
        </div>
        <div class="popup__add-item">
          <input
            type="number"
            class="popup__add-input"
            v-model="mainCoefficientVideoPrice"
            ref="mainvideoprice"
            required
          />
          <svg
            class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="ruble-sign"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
            ></path>
          </svg>
        </div>
        <div class="popup__add-item">
          <input
            type="number"
            class="popup__add-input"
            v-model="checkCoefficientSetting"
            ref="checkcoefficientsetting"
            required
          />
          <svg
            class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="ruble-sign"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
            ></path>
          </svg>
        </div>
        <div class="popup__add-item">
          <input
            type="number"
            class="popup__add-input"
            v-model="editCoefficientSetting"
            ref="editcoefficientsetting"
            required
          />
          <svg
            class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="ruble-sign"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
            ></path>
          </svg>
        </div>
        <div class="popup__add-item">
          <input
            type="number"
            class="popup__add-input"
            v-model="editBanPriceSetting"
            ref="editbanpricesetting"
            required
          />
          <svg
            class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="ruble-sign"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
            ></path>
          </svg>
        </div>
        <div class="popup__add-item">
          <input
            type="number"
            class="popup__add-input"
            v-model="editDaySetting"
            ref="editdaysetting"
            required
          />
          <svg
            class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="ruble-sign"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
            ></path>
          </svg>
        </div>
        <div class="popup__add-item">
          <input
            type="number"
            class="popup__add-input"
            v-model="editNightSetting"
            ref="editnightsetting"
            required
          />
          <svg
            class="svg-inline--fa fa-ruble-sign fa-w-12 popup__table-icon"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="ruble-sign"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
            ></path>
          </svg>
        </div>
        <div class="popup__add-item">
          <button class="popup__delete-btn" @click.prevent="closeRow">
            <i class="fa fa-times"></i>
          </button>
        </div>
      </form>
    </div>

    <div class="popup__btns-group">
      <button v-if="!addOpen" class="popup__btn popup__btn--add" @click="addOpen = !addOpen" key="add">
        <span class="popup__btn-text">Добавить</span>
        <i class="popup__btn-icon fa fa-plus"></i>
      </button>

      <button
        type="submit"
        v-else-if="addOpen"
        class="popup__btn popup__btn--save"
        @click="addSetting()"
        key="save"
      >
        <span class="popup__btn-text">Сохранить</span>

        <i class="popup__btn-icon fa fa-save"></i>
      </button>

      <button
        @click="
          closeRow()
          close()
        "
        class="popup__btn popup__btn--close "
      >
        <span>Закрыть</span>
      </button>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Modals/Popup'

import ModalMixin from '@/components/Modals/mixins/modalMixin'

import { SETTING_MODERATOR_POPUP_EVENT } from '@/constants/event'

import ItemPaymentPopup from './ItemPaymentPopup'

import { mapGetters, mapActions } from 'vuex'

import { GET_MODERATOR_DEFAULT_PAYMENT_SETTING } from '@/store/getter-types'

import { REQUEST_MODERATOR_CREATE_PAYMENTS_SETTING } from '@/store/action-types'

import toLocaleDateTime from '@/components/mixins/toLocaleformat'

export default {
  name: 'PaymentsSettingPopup',
  data() {
    return {
      SETTING_MODERATOR_POPUP_EVENT,
      mainCoefficient: null,
      inCoefficient: null,
      checkCoefficient: null,
      editCoefficient: null,
      addOpen: false,
      editBanPrice: null,
      mainVideoPrice: null,
      innerVideoPrice: 0.1
      // date: null,
    }
  },
  mixins: [ModalMixin, toLocaleDateTime],
  components: {
    ItemPaymentPopup,
    Popup
  },
  computed: {
    ...mapGetters({
      settings: GET_MODERATOR_DEFAULT_PAYMENT_SETTING
    }),
    mainCoefficientSetting: {
      get() {
        return ''
      },
      set(value) {
        this.$refs.maincoefficientsetting.value = value
        this.mainCoefficient = value
      }
    },

    mainCoefficientVideoPrice: {
      get() {
        return ''
      },
      set(value) {
        this.$refs.mainvideoprice.value = value
        this.mainVideoPrice = value
      }
    },

    inCoefficientSetting: {
      get() {
        return ''
      },
      set(value) {
        this.$refs.incoefficientsetting.value = value
        this.inCoefficient = value
      }
    },

    checkCoefficientSetting: {
      get() {
        return ''
      },
      set(value) {
        this.$refs.checkcoefficientsetting.value = value
        this.checkCoefficient = value
      }
    },

    editCoefficientSetting: {
      get() {
        return ''
      },
      set(value) {
        this.$refs.editcoefficientsetting.value = value
        this.editCoefficient = value
      }
    },

    editBanPriceSetting: {
      get() {
        return ''
      },
      set(value) {
        this.$refs.editbanpricesetting.value = value
        this.editBanPrice = value
      }
    },

    editNightSetting: {
      get() {
        return ''
      },
      set(value) {
        this.$refs.editnightsetting.value = value
        this.editNightPay = value
      }
    },

    editDaySetting: {
      get() {
        return ''
      },
      set(value) {
        this.$refs.editdaysetting.value = value
        this.editDayPay = value
      }
    }
  },
  methods: {
    ...mapActions({
      addPaymentSetting: REQUEST_MODERATOR_CREATE_PAYMENTS_SETTING
    }),
    closeRow() {
      this.editBanPrice = null
      this.mainCoefficient = null
      this.inCoefficient = null
      this.checkCoefficient = null
      this.editCoefficient = null
      this.editNightPay = null
      this.editDayPay = null
      this.mainVideoPrice = null
      // this.date = null;
      this.addOpen = false
    },
    addSetting() {
      let newData = {
        // acts_from: `${this.date.getFullYear()}-${this.date.getMonth()+1}-${this.date.getDate()}`, // +1 т.к. месяц с нуля
        main_photo_price: +this.mainCoefficient,
        inner_photo_price: +this.inCoefficient,
        event_price: +this.checkCoefficient,
        event_edit_price: +this.editCoefficient,
        night_pay: +this.editNightPay,
        day_pay: +this.editDayPay,
        ban_price: +this.editBanPrice,
        main_video_price: +this.mainVideoPrice,
        inner_video_price: +this.innerVideoPrice
      }
      if (this.settings.length) {
        newData.settings_to_archive = this.settings.find(f => f.acts_to === null).id
      }

      this.addPaymentSetting(newData)
      this.closeRow()
    }
  },
  mounted() {
    this.$root.$on(SETTING_MODERATOR_POPUP_EVENT, operator => {
      this.open()
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'SettingsPayment';
@import '../../../../assets/scss/daterange.scss';
@import '../../../../assets/scss/vue2-daterange-picker.scss';
.popup__add-item--calendar .vue-daterange-picker[data-v-2359713c][data-v-20ffd541] {
  border: 1px solid #ddd;
  width: 90%;
  text-align: start;
}
.datepicker--popup {
  border: 1px solid #ddd;
  width: 90%;
  text-align: start;
}
</style>
