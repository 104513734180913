<template>
  <div class="utm-categories-wrap">
    <div class="ev-cat">
      <MarketUtmHeader />
      <div class="utm__table">
        <span>Название</span>
        <span>Источник</span>
        <span>Платформа</span>
        <span>Цвет</span>
      </div>
      <div class="ev-cat__list" v-if="utms.length && isLoaded">
        <MarketUtmItem v-for="utm in utms" :key="utm.id" :utm="utm" />
      </div>
      <div class="ev-cat__error" v-if="!utms.length && isLoaded">
        <span class="ev-cat__error-text">Нет данных...</span>
      </div>
      <div v-if="errorMessage" class="ev-cat__error">
        <span class="ev-cat__error-text">Ошибка при загрузке UTM меток!</span>
      </div>
    </div>
    <MarketUtmDeletePopup />
    <MarketUtmAddPopup />
    <MarketUtmUpdatePopup />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { GET_MARKET_UTM } from '@/store/getter-types'
import { REQUEST_MARKET_UTM } from '@/store/action-types'
import MarketUtmHeader from '@/components/Market/MarketUtmHeader'
import MarketUtmItem from '@/components/Market/MarketUtmItem'
import MarketUtmDeletePopup from '@/components/Modals/MarketPopup/MarketUtmDeletePopup'
import MarketUtmAddPopup from '@/components/Modals/MarketPopup/MarketUtmAddPopup'
import MarketUtmUpdatePopup from '@/components/Modals/MarketPopup/MarketUtmUpdatePopup'

import store from '@/store'
export default {
  name: 'marketUTM',
  components: {
    MarketUtmHeader,
    MarketUtmItem,
    MarketUtmDeletePopup,
    MarketUtmAddPopup,
    MarketUtmUpdatePopup
  },
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_MARKET_UTM).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      utms: GET_MARKET_UTM
    })
  },

  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
@import '@/components/Events/Event.scss';
@import '@/components/Events/EventCategory.scss';
.utm-categories-wrap {
  max-width: 1140px;
  margin: 0 auto;
  padding-top: 15px;
  @media (max-width: 425px) {
    margin: 0 8px;
  }
}
.utm__table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 45px;
  margin: 8px 0;
  background-color: white;
  transition: 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  @media (max-width: 425px) {
    padding: 10px;
    justify-content: space-evenly;
  }
  span:first-child {
    @media (max-width: 425px) {
      width: 25%;
    }
  }
  span:nth-child(2) {
    @media (max-width: 425px) {
      width: 23%;
    }
  }
  span:nth-child(3) {
    @media (max-width: 425px) {
      width: 36%;
    }
  }
  span:last-child {
    flex-basis: 29%;
    @media (max-width: 425px) {
      display: none;
    }
  }
}

.ev-cat {
  &__error {
    min-height: 64px;
    border-radius: 2px;

    position: relative;
    display: flex;
    align-items: center;
    background-color: white;
  }
  &__error-text {
    margin: 0 auto;
  }
}
</style>
