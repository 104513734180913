<template>
  <div class="table-header-wrapper--achievements">
    <ul class="table-header">
      <li class="table-header__item table-header__item--achievements">
        <span class="table-header__item-text">Название</span>
      </li>
      <li class="table-header__item table-header__item--achievements">
        <span class="table-header__item-text">Описание</span>
      </li>
      <li class="table-header__item table-header__item--achievements">
        <span class="table-header__item-text">Kind</span>
      </li>
      <li class="table-header__item table-header__item--achievements">
        <span class="table-header__item-text">Количество баллов</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'AchievementTableHeader'
}
</script>
<style lang="scss">
@import './AchievementTableHeader.scss';
</style>
