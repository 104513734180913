<template>
  <div class="payments-time">
    <div class="payments-time__wrapper">
      <div @click="buttonPeriodClickHandler" class="payments-time__btn-group" v-if="button_period.length > 0">
        <button
          v-for="button in button_period"
          :key="button.id"
          :data-id="button.id"
          :class="[button.active ? 'btn btn-danger' : 'btn', button.class ? button.class : '']"
        >
          {{ button.title }}
        </button>
        <button class="btn-primary" data-type="period">Период</button>
      </div>
      <div class="datepicker-traffic-source">
        <i class="select__field-icon fa fa-angle-down"></i>
        <DateRangePicker
          :locale-data="picker.localeData"
          :opens="picker.localeData.position"
          :close-on-esc="true"
          :autoApply="true"
          :ranges="false"
          ref="traffic"
          v-model="datePickerControlTraffic"
        >
        </DateRangePicker>
      </div>
      <div class="payments-time__btn-group chart__btn-group">
        <div class="chart__edit-group chart__edit-group--togle">
          <span class="chart__edit-text">Пользователи</span>
          <label class="toggle">
            <input class="toggle__checkbox" type="checkbox" v-model="users" />
            <span class="toggle__toggler"></span>
          </label>
        </div>
        <div class="chart__edit-group chart__edit-group--togle">
          <span class="chart__edit-text">Активные</span>
          <label class="toggle">
            <input class="toggle__checkbox" type="checkbox" v-model="active" />
            <span class="toggle__toggler"></span>
          </label>
        </div>
        <div class="chart__edit-group chart__edit-group--togle">
          <span class="chart__edit-text">Платящие</span>
          <label class="toggle">
            <input class="toggle__checkbox" type="checkbox" v-model="payment" />
            <span class="toggle__toggler"></span>
          </label>
        </div>
      </div>
    </div>
    <div class="doughnut-chart__group">
      <div class="doughnut-chart__item" v-if="active">
        <span class="doughnut-chart__title doughnut-chart__title--percent">{{ activeCircle }}%</span>
        <Doughnut class="doughnut-chart" :chart-data="chartDataActive" :options="options"> </Doughnut>
        <span class="doughnut-chart__title"
          >Активные (<span class="doughnut-chart__count">{{ usersPaying.active_period }}</span
          >)</span
        >
      </div>
      <div class="doughnut-chart__item" v-if="payment">
        <span class="doughnut-chart__title doughnut-chart__title--red doughnut-chart__title--percent"
          >{{ paymentCircle }}%</span
        >
        <Doughnut class="doughnut-chart" :chart-data="chartDataPay" :options="options"> </Doughnut>
        <span class="doughnut-chart__title doughnut-chart__title--red"
          >Платящие (<span class="doughnut-chart__count">{{ usersPaying.paying_period }}</span
          >)</span
        >
      </div>
    </div>
    <div>
      <BarChart :chartData="datacollection" :options="optionsPaying" class="regenterstats-chart"> </BarChart>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import 'chartjs-plugin-datalabels'
import BarChart from '@/components/Statistic/BarChart'
import Doughnut from '@/components/Statistic/Doughnut'
import { mapActions } from 'vuex'
import { REQUEST_USERS_PAYING } from '@/store/action-types'
import pickerMixin from '@/components/Picker/pickerMixin'
export default {
  name: 'StatisticUsersPaying',
  components: {
    BarChart,
    DateRangePicker,
    Doughnut
  },
  props: {
    usersPaying: {
      type: Object
    }
  },
  mixins: [pickerMixin],
  data: () => ({
    users: true,
    active: true,
    payment: true,
    chartDataActive: {
      datasets: [
        {
          backgroundColor: ['#41cac0', 'white'],
          data: [5, 95],
          datalabels: {
            display: false
          }
        }
      ]
    },
    chartDataPay: {
      datasets: [
        {
          backgroundColor: ['#ff6c60', 'white'],
          data: [10, 90],
          datalabels: {
            display: false
          },
          borderWidth: 2
        }
      ]
    },
    options: {
      tooltips: { enabled: false },
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        animateRotate: false
      }
    },

    payments_country_form: {
      start_date: '',
      end_date: ''
    },
    type: 2,
    button_period: [
      {
        id: 0,
        class: 'btn-default',
        title: 'Неделя',
        active: 0,
        date: moment()
          .subtract(1, 'weeks')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 1,
        class: 'btn-default',
        title: '1 мес.',
        active: 0,
        date: moment()
          .subtract(1, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 2,
        class: 'btn-default',
        title: '2 мес.',
        active: 0,
        date: moment()
          .subtract(2, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 3,
        class: 'btn-default',
        title: '3 мес.',
        active: 1,
        date: moment()
          .subtract(3, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 4,
        class: 'btn-default',
        title: '4 мес.',
        active: 0,
        date: moment()
          .subtract(4, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 5,
        class: 'btn-default',
        title: '5 мес.',
        active: 0,
        date: moment()
          .subtract(5, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 6,
        class: 'btn-default',
        title: '6 мес.',
        active: 0,
        date: moment()
          .subtract(6, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 7,
        class: 'btn-white',
        title: 'Все',
        active: 0,
        date: moment()
          .subtract(18, 'months')
          .startOf('day')
      }
    ],
    datepicker_dates: {
      date_from: moment()
        .subtract(3, 'months')
        .startOf('day')
        .toString(),
      date_to: moment()
        .endOf('day')
        .toString()
    },
    datacollection: {
      labels: ['Пользователи', 'Активные', 'Платящие'],
      datasets: [
        {
          label: 'За выбранные период',
          backgroundColor: ['#57c8f2', '#41cac0', '#ff6c60'],
          pointBackgroundColor: 'white',
          borderWidth: 1,
          pointBorderColor: '#249EBF',
          datalabels: {
            display: false
          },
          data: []
        }
      ]
    },
    optionsPaying: {
      scales: {
        yAxes: [
          {
            //stacked: true,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }
        ],
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }
        ]
      },
      offset: true,
      legend: {
        display: false
      },
      tooltips: {
        enabled: true
      },
      plugins: {
        datalabels: {
          //display: false,
          font: {
            size: 12
          },
          padding: {
            top: 0,
            bottom: -10
          },
          anchor: 'end',
          align: 'end',
          rotation: 0,
          clamp: true,
          formatter: (value, ctx) => {
            if (this.statistic.period.total === 0 || ctx.dataIndex === 0 || value <= 0) {
              return ''
            }

            return `${((value / ctx.chart.data.datasets[ctx.datasetIndex].data[0]) * 100).toFixed(1)}%`
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 25
        }
      }
    }
  }),
  watch: {
    active: function() {
      let activeArr = this.usersPaying.active_period
      let paymentArr = this.usersPaying.paying_period
      let usersArr = this.usersPaying.total_period

      if (!this.active) {
        activeArr = 0
      }
      if (!this.payment) {
        paymentArr = 0
      }
      if (!this.users) {
        usersArr = 0
      }
      this.datacollection.datasets[0].data = [usersArr, activeArr, paymentArr]
    },
    payment: function() {
      let activeArr = this.usersPaying.active_period
      let paymentArr = this.usersPaying.paying_period
      let usersArr = this.usersPaying.total_period

      if (!this.active) {
        activeArr = 0
      }
      if (!this.payment) {
        paymentArr = 0
      }
      if (!this.users) {
        usersArr = 0
      }
      this.datacollection.datasets[0].data = [usersArr, activeArr, paymentArr]
    },
    users: function() {
      let activeArr = this.usersPaying.active_period
      let paymentArr = this.usersPaying.paying_period
      let usersArr = this.usersPaying.total_period

      if (!this.active) {
        activeArr = 0
      }
      if (!this.payment) {
        paymentArr = 0
      }
      if (!this.users) {
        usersArr = 0
      }
      this.datacollection.datasets[0].data = [usersArr, activeArr, paymentArr]
    }
  },
  mounted() {
    this.getTimeLabels()
  },

  computed: {
    datePickerControlTraffic: {
      get() {
        const date = {
          startDate: this.datepicker_dates.date_from,
          endDate: this.datepicker_dates.date_to
        }
        return date
      },
      set({ startDate, endDate }) {
        this.datepicker_dates.date_from = new Date(startDate)
        this.datepicker_dates.date_to = new Date(endDate)
        this.payments_country_form.start_date = this.datepicker_dates.date_from
        this.payments_country_form.end_date = this.datepicker_dates.date_to
      }
    },
    activeCircle: function() {
      let proc = ((this.usersPaying.active_period / this.usersPaying.total_period) * 100).toFixed(2)
      return `${proc}`
    },
    paymentCircle: function() {
      let proc = ((this.usersPaying.paying_period / this.usersPaying.total_period) * 100).toFixed(2)
      return `${proc}`
    }
  },
  methods: {
    ...mapActions({
      getPaymentByTime: REQUEST_USERS_PAYING
    }),
    setType(type = 1) {
      this.type = type
      this.getData()
    },
    getTimeLabels() {
      this.datacollection.datasets[0].data = [
        this.usersPaying.total_period,
        this.usersPaying.active_period,
        this.usersPaying.paying_period
      ]
      this.chartDataActive.datasets[0].data = [this.activeCircle, 100 - this.activeCircle]
      this.chartDataPay.datasets[0].data = [this.paymentCircle, 100 - this.paymentCircle]
    },
    buttonPeriodClickHandler(event) {
      if (event.target.tagName.toLowerCase() !== 'button') {
        return false
      }
      this.button_period.map(el => {
        el.active = 0
        return el
      })
      if (event.target.dataset.type) {
        this.getData()
        return
      }
      if (!event.target.dataset.id) {
        return false
      }
      const button = this.button_period.find(el => el.id === +event.target.dataset.id)
      if (button.active) {
        return false
      }
      button.active = 1
      this.datepicker_dates.date_from = button.date.toLocaleString()
      this.datepicker_dates.date_to = button.date_to
        ? button.date_to.toLocaleString()
        : moment()
            .endOf('day')
            .toLocaleString()
      this.getData()
    },
    getData() {
      let dateThreeMounth = {
        date_from: moment(this.datepicker_dates.date_from).format('YYYY-MM-DD'),
        date_to: moment(this.datepicker_dates.date_to).format('YYYY-MM-DD')
      }
      this.getPaymentByTime(dateThreeMounth).then(response => {
        this.getTimeLabels()
      })
    }
  }
}
</script>

<style lang="scss">
@import './Statistic.scss';
.payments-time {
  padding: 16px;
}
.payments-time__wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.payments-time__input {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  background-color: #41cac0;
  border-color: #41cac0;
  color: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  &--left {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &--right {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.btn-sum-active {
  background-color: #ff6c60;
  color: #fff;
}

.btn-default {
  background-color: #bec3c7;
  border-color: #bec3c7;
  color: #fff;
}

.btn-danger {
  background-color: #ff6c60;
  border-color: #ff6c60;
  color: #fff;
}

.btn-white {
  background-clip: padding-box;
  border-color: rgba(150, 160, 180, 0.3);
  background-color: #fff;
}

.btn-count-active {
  background-color: #57c8f2;
  color: #fff;
}

.btn-primary {
  border-radius: 4px;
  background-color: #41cac0;
  border-color: #41cac0;
  color: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn.btn-danger.btn-white {
  color: #2a3542;
}
.doughnut-chart {
  width: 120px;
  height: 120px;
}
.doughnut-chart__group {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.doughnut-chart__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.doughnut-chart__title {
  color: #41cac0;
  font-size: 13px;
  &--red {
    color: #ff6c60;
  }
  &--percent {
    top: 55px;
    position: absolute;
  }
}
.doughnut-chart__count {
  font-weight: 700;
}
.chart__edit-group {
  display: flex;
  padding: 5px;
}
.chart__btn-group {
  display: flex;
}
.chart__edit-text {
  margin: 0 5px 0 15px;
  font-weight: 700;
  font-size: 13px;
}
</style>
