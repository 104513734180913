<template>
  <div class="moderators__wrap">
    <ModeratorsRatingHeaders />
    <ModeratorsRatingTableHeader />
    <ul v-if="isLoaded" class="moderators-rating__list">
      <ModeratorsRating v-for="rating in ratings" :rating="rating" :key="rating.fio" />
    </ul>
    <div v-else class="moderators__error">
      <span class="moderators__error-text">Ошибка при загрузке рейтинга модераторов</span>
    </div>
  </div>
</template>
<script>
import ModeratorsRatingHeaders from '@/components/Moderators/Rating/ModeratorsRatingHeaders'
import ModeratorsRatingTableHeader from '@/components/Moderators/Rating/ModeratorsRatingTableHeader'
import ModeratorsRating from '@/components/Moderators/Rating/ModeratorsRating'
import { mapGetters } from 'vuex'
import {
  REQUEST_MODERATORS_RATING,
  REQUEST_MODERATOR_SETTINGS_SPEED,
  REQUEST_MODERATOR_SETTINGS_QUALITY
} from '@/store/action-types'
import { GET_MODERATORS_RATING } from '@/store/getter-types'
import store from '@/store'

export default {
  name: 'moderatorRating',
  components: {
    ModeratorsRatingHeaders,
    ModeratorsRatingTableHeader,
    ModeratorsRating
  },
  data: () => ({
    errorMessage: false,
    isLoaded: false
  }),

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_MODERATORS_RATING, { is_active: 1 }).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
          store.dispatch(REQUEST_MODERATOR_SETTINGS_SPEED).then(response => {
            if (!response.status) {
              vm.errorMessage = true
            }
          })
          store.dispatch(REQUEST_MODERATOR_SETTINGS_QUALITY).then(response => {
            if (!response.status) {
              vm.errorMessage = true
            }
          })
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      ratings: GET_MODERATORS_RATING
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
.moderators {
  &__wrap {
    padding: 0 16px;
  }
  &__list {
    margin-top: 20px;
    padding: 0 20px;
  }
}

.moderators-rating__btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  &--success {
    background-color: #78cd51;
    border-color: #78cd51;
    color: #fff;
  }
  &--setting {
    padding: 4px 8px;
    background-color: #ff6c60;
    border-color: #ff6c60;
    color: #fff;
  }
  &--statistic {
    background-color: #58c9f3;
    border-color: #58c9f3;
    color: #fff;
  }
  &--pay {
    background-color: #78cd51;
    border-color: #78cd51;
    color: #fff;
    padding: 0 5px;
  }
  &--award {
    background-color: #f1c500;
    border-color: #f1c500;
    color: #fff;
    width: 30px;
    height: 30px;
    padding: 3px;
  }
}
.moderators-rating__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}
</style>
