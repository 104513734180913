import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'

import CausePointsService from '@/services/CausePointsService'

export default {
  [ActionTypes.REQUEST_CAUSE_POINTS]: async ({ rootGetters, commit }, lang = 'RU') => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(CausePointsService, [userToken]).getAllCausePoints(lang)
    let { data } = response
    if (response.status) {
      commit(MutationTypes.SET_CAUSE_POINTS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_ADD_CAUSE_POINTS]: async ({ rootGetters, commit, dispatch }, achievementData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(CausePointsService, [userToken]).addCausePoints(achievementData)
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_CAUSE_POINTS, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_DELETE_CAUSE_POINTS]: async ({ rootGetters, commit, dispatch }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(CausePointsService, [userToken]).deleteCausePoints(id)
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_CAUSE_POINTS, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_UPDATE_CAUSE_POINTS]: async ({ rootGetters, commit, dispatch }, achievement) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(CausePointsService, [userToken]).updateCausePoints(achievement)
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_CAUSE_POINTS, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
