<template>
  <div class="event-categories-wrap reasons-categories-wrap">
    <div class="ev-cat">
      <ReasonBlockHeader />
      <div class="ev-cat__list">
        <div class="ev-cat__list-title">Причины блокировки пользователя</div>
        <div class="reasons__table">
          <span>Номер причины</span>
          <span>Причина</span>
        </div>
        <div class="ev-cat__list" v-if="isLoaded && reasonBlockUser.length">
          <ReasonBlockItem v-for="reason in reasonBlockUser" :key="reason.id" :reason="reason" />
        </div>
        <div class="ev-cat__no-reasons" v-if="!reasonBlockUser.length && isLoaded">
          <span class="ev-cat__text">Нет данных...</span>
        </div>
        <div v-if="errorMessage" class="ev-cat__no-reasons">
          <span class="ev-cat__text">Ошибка при загрузке причин блокировки!</span>
        </div>
        <div class="ev-cat__list-title">Причины блокировки фото</div>
        <div class="reasons__table">
          <span>Номер причины</span>
          <span>Причина</span>
        </div>
        <div class="ev-cat__list" v-if="isLoaded && reasonBlockPhoto.length">
          <ReasonBlockItem v-for="reason in reasonBlockPhoto" :key="reason.id" :reason="reason" />
        </div>
        <div class="ev-cat__no-reasons" v-if="!reasonBlockPhoto.length && isLoaded">
          <span class="ev-cat__text">Нет данных...</span>
        </div>
        <div v-if="errorMessage" class="ev-cat__no-reasons">
          <span class="ev-cat__text">Ошибка при загрузке причин блокировки!</span>
        </div>
      </div>
    </div>
    <ReasonBlockPopup />
    <ReasonBlockAddPopup />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { GET_REASON_BLOCK_USER, GET_REASON_BLOCK_PHOTO } from '@/store/getter-types'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types'
import { REQUEST_REASON_BLOCK } from '@/store/action-types'
import ReasonBlockHeader from '@/components/Reasons/ReasonBlockHeader'
import ReasonBlockItem from '@/components/Reasons/ReasonBlockItem'
import ReasonBlockPopup from '@/components/Modals/ReasonsPopup/ReasonBlockPopup'
import ReasonBlockAddPopup from '@/components/Modals/ReasonsPopup/ReasonBlockAddPopup'

import store from '@/store'
export default {
  name: 'interfaceCauseBlock',
  components: {
    ReasonBlockHeader,
    ReasonBlockItem,
    ReasonBlockPopup,
    ReasonBlockAddPopup
  },
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_REASON_BLOCK).then(response => {
      next(vm => {
        if (response) {
          vm.setCurrentLang('RU')
        }
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      reasonBlockUser: GET_REASON_BLOCK_USER,
      reasonBlockPhoto: GET_REASON_BLOCK_PHOTO
    })
  },
  methods: {
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
@import '@/components/Events/Event.scss';
@import '@/components/Events/EventCategory.scss';
.event-categories-wrap {
  padding: 16px;
}
.ev-cat__no-reasons {
  min-height: 64px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
}
.ev-cat__text {
  margin: 0 auto;
}
.reasons-categories-wrap {
  max-width: 1140px;
  margin: 0 auto;
  .ev-cat__item-name {
    &:first-child {
      padding-left: 30px;
      width: 50%;
    }
    &:nth-child(2) {
      width: 50%;
    }
  }
  .ev-cat__item-content {
    justify-content: flex-start;
  }
}
</style>
