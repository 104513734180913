export default {
  props: {
    title: {
      type: String
    },

    description: {
      type: String
    },

    modalClass: {
      type: String
    }
  }
}
