<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description=""
    modalClass="popup--small popup--operator popup--scroll"
  >
    <div class="popup__item">
      <div class="operator-profile__edit-group">
        <span class="operator-profile__item-text">Удалить баннер?</span>
      </div>
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteBanner">
          Удалить
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Закрыть
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions } from 'vuex'
import { REQUEST_DELETE_OPERATORS_BANNER } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { DELETE_OPERATOR_BANNER } from '@/constants/event'

export default {
  name: 'DeleteOperatorPopupBanner',
  components: {
    Popup
  },
  data: () => ({
    bannersId: ''
  }),
  mixins: [ModalMixin],
  mounted() {
    this.$root.$on(DELETE_OPERATOR_BANNER, data => {
      this.bannersId = data
      this.open(DELETE_OPERATOR_BANNER)
    })
  },
  methods: {
    ...mapActions({
      deleteOperatorsBanner: REQUEST_DELETE_OPERATORS_BANNER
    }),
    deleteBanner() {
      this.deleteOperatorsBanner(this.bannersId)
      this.close()
    },
    closePopup() {
      this.close()
    }
  }
}
</script>
<style lang="scss" scoped>
@import './EditOperatorBanner.scss';
.operator-profile {
  &__edit-group {
    margin-bottom: 40px;
    font-size: 16px;
  }
}
.operator-banner {
  &__field {
    &:nth-child(4) {
      width: 15%;
      justify-content: flex-start;
      @media (max-width: 1020px) {
        justify-content: flex-start;
        width: 100%;
      }
    }
  }
}
</style>
