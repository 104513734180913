import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_POSITIONS]: (state, payload) => {
    state.positions = payload
  },
  [MutationTypes.SET_TMP_POSITIONS_FILTERS]: (state, payload) => {
    const { key, value } = payload
    const newObject = {
      key,
      ...value
    }
    state.tmpPositionsFilters[key] = newObject
  },
  [MutationTypes.SET_RESET_POSITIONS_FILTERS]: state => {
    for (const field in state.tmpPositionsFilters) {
      state.tmpPositionsFilters[field] = {}
    }
  }
}
