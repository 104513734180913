<template>
  <div class="left-bar">
    <div class="left-bar__title">
      <span>НАСТРОЙКИ <i class="fas fa-cogs"></i></span>
      <span class="left-bar__close-btn" @click="closeSideBar()"></span>
    </div>
    <div class="left-bar__wrapper">
      <ul class="left-bar__list">
        <ModeratorsSettingMainItemQuality
          v-for="setting in getSettingsQuality"
          :setting="setting"
          :key="setting.id"
        />
        <li class="left-bar__item">
          <div class="left-bar__main">
            <span class="left-bar__main-text">СКОРОСТЬ ПРОВЕРКИ.</span>
          </div>
          <ul class="left-bar__secondary-list">
            <ModeratorsSettingItemSpeed
              v-for="(value, property) in getSettingsSpeed[0]"
              :value="value"
              :property="property"
              :key="property"
              :id="getSettingsSpeed[0].id"
              :allData="getSettingsSpeed[0]"
              :nameSpeed="nameSpeed"
            />
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_MODERATOR_SETTINGS_SPEED, GET_MODERATOR_SETTINGS_QUALITY } from '@/store/getter-types'
import ModeratorsSettingMainItemQuality from './ModeratorsSettingMainItemQuality'
import ModeratorsSettingItemSpeed from './ModeratorsSettingItemSpeed'

export default {
  name: 'ModeratorsSettings',
  props: {
    closeSideBar: {
      type: Function
    }
  },
  components: {
    ModeratorsSettingItemSpeed,
    ModeratorsSettingMainItemQuality
  },
  data: () => ({
    ratingShiftList: [
      { key: 'time_5', title: '5 минут' },
      { key: 'time_10', title: '10 минут' },
      { key: 'time_15', title: '15 минут' },
      { key: 'time_20', title: '20 минут' },
      { key: 'time_25', title: '25 минут' },
      { key: 'time_30', title: '25+ минут' }
    ]
  }),
  computed: {
    ...mapGetters({
      getSettingsSpeed: GET_MODERATOR_SETTINGS_SPEED,
      getSettingsQuality: GET_MODERATOR_SETTINGS_QUALITY
    })
  },
  methods: {
    nameSpeed(name) {
      return this.ratingShiftList.find(f => f.key === name).title
    }
  }
}
</script>

<style lang="scss">
@import 'ModeratorStatistic';
</style>
