<template>
  <Popup :visible="visible" @close="close" description="Удалить предупреждение?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteClaimDelete">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { CLAIM_DELETE_POPUP } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUSET_DEL_CLAIM } from '@/store/action-types'

export default {
  name: 'ClaimDeletePopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data() {
    return {
      id: '',
      lang: ''
    }
  },
  methods: {
    ...mapActions({
      deleteClaim: REQUSET_DEL_CLAIM
    }),
    deleteClaimDelete() {
      let data = {
        lang: this.lang,
        params: {
          id: this.id
        }
      }
      this.deleteClaim(data)
      this.id = ''
      this.close()
    }
  },

  mounted() {
    this.$root.$on(CLAIM_DELETE_POPUP, claim => {
      this.open()
      this.lang = claim.lang.value
      this.id = claim.id
    })
  }
}
</script>
