const GET_AUTO_MESSAGES = 'GET_AUTO_MESSAGES'
const GET_AUTO_MESSAGES_COUNT = 'GET_AUTO_MESSAGES_COUNT'
const GET_AUTO_MESSAGES_PAGINATION_NEXT = 'GET_AUTO_MESSAGES_PAGINATION_NEXT'
const GET_AUTO_MESSAGES_PAGINATION_PREVIOUS = 'GET_AUTO_MESSAGES_PAGINATION_PREVIOUS'
const GET_AUTO_MESSAGES_PAGE = 'GET_AUTO_MESSAGES_PAGE'
const GET_AUTO_MESSAGES_NOW_LINK = 'GET_AUTO_MESSAGES_NOW_LINK'

export {
  GET_AUTO_MESSAGES,
  GET_AUTO_MESSAGES_COUNT,
  GET_AUTO_MESSAGES_PAGINATION_NEXT,
  GET_AUTO_MESSAGES_PAGINATION_PREVIOUS,
  GET_AUTO_MESSAGES_PAGE,
  GET_AUTO_MESSAGES_NOW_LINK
}
