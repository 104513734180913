<template>
  <div class="user-active__btn-group">
    <button
      v-for="(button, index) in buttons"
      :key="index"
      @click="$emit(button.emitKey, button.emitValue)"
      :class="[
        { 'user-active__btn--danger': utcFlag === button.utcValue },
        `user-active__btn user-active__btn--${button.class} user-active__btn--default`
      ]"
    >
      {{ button.buttonText }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'ServerTimeFilter',
  props: {
    utcFlag: {
      type: Number
    }
  },
  data: () => ({
    buttons: [
      { emitKey: 'changeUtc', emitValue: 1, buttonText: 'Местное время', utcValue: 1, class: 'left' },
      { emitKey: 'changeUtc', emitValue: 0, buttonText: 'Серверное время', utcValue: 0, class: 'right' }
    ]
  })
}
</script>
