const REQUEST_ALL_GIFTS_EVENTS = 'REQUEST_ALL_GIFTS_EVENTS'
const REQUEST_NEW_GIFTS_EVENTS = 'REQUEST_NEW_GIFTS_EVENTS'
const REQUEST_DELETE_GIFTS_EVENTS = 'REQUEST_DELETE_GIFTS_EVENTS'
const REQUEST_UPDATE_GIFTS_EVENTS = 'REQUEST_UPDATE_GIFTS_EVENTS'
const REQUEST_UPDATE_PERCENT_GIFTS_EVENTS = 'REQUEST_UPDATE_PERCENT_GIFTS_EVENTS'

export {
  REQUEST_ALL_GIFTS_EVENTS,
  REQUEST_NEW_GIFTS_EVENTS,
  REQUEST_DELETE_GIFTS_EVENTS,
  REQUEST_UPDATE_GIFTS_EVENTS,
  REQUEST_UPDATE_PERCENT_GIFTS_EVENTS
}
