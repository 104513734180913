export default {
  purchases: [],
  purchaseTmpRoles: {
    target: {},
    currency: {}
  },
  purchaseRolesDefault: {
    target: {
      name: 'Выберите покупку',
      value: '-1'
    },
    currency: {
      name: 'Выберите валюту',
      value: '-1'
    }
  },
  purchaseRoles: {
    target: [
      {
        name: 'Перевод чата',
        value: 0,
        id: 0
      },
      {
        name: 'Открыть все лайки одноразово',
        value: 1,
        id: 1
      },
      {
        name: 'Открыть все просмотры одноразово',
        value: 2,
        id: 2
      },
      {
        name: 'Создание приветствия',
        value: 3,
        id: 3
      },
      {
        name: 'VIP статус на N недель',
        value: 4,
        id: 4
      },
      {
        name: 'VIP статус на N недель (пробный период)',
        value: 5,
        id: 5
      },
      {
        name: 'Подарочный VIP статус на N недель',
        value: 6,
        id: 6
      },
      {
        name: 'VIP статус на N месяцев',
        value: 7,
        id: 7
      },
      {
        name: 'VIP статус на N месяцев (пробный период)',
        value: 8,
        id: 8
      },
      {
        name: 'Подарочный VIP статус на N месяцев',
        value: 9,
        id: 9
      },
      {
        name: 'VIP статус на N лет',
        value: 10,
        id: 10
      },
      {
        name: 'VIP статус на N лет (пробный период)',
        value: 11,
        id: 11
      },
      {
        name: 'Подарочный VIP статус на N лет',
        value: 12,
        id: 12
      },
      {
        name: 'Покупка баллов',
        value: 13,
        id: 13
      },
      {
        name: 'Покупка двойных баллов',
        value: 14,
        id: 14
      }
    ],
    currency: [
      {
        name: '$',
        value: 0,
        id: 0
      },
      {
        name: '€',
        value: 1,
        id: 1
      },
      {
        name: '₽',
        value: 2,
        id: 2
      },
      {
        name: '£',
        value: 3,
        id: 3
      }
    ]
  }
}
