import * as GetterTypes from './getter-types'
export default {
  [GetterTypes.GET_MERGED_CATEGORIES]: ({ mergedCategories }) => mergedCategories,
  [GetterTypes.GET_CATEGORIES_BY_LANG]: ({ eventsCategoryLang }) => eventsCategoryLang,
  [GetterTypes.GET_EVENTS]: ({ events }) => events,
  [GetterTypes.GET_EVENT]: ({ currentEvent }) => currentEvent,
  [GetterTypes.GET_EVENT_COUNTS]: ({ eventsCount }) => eventsCount,
  [GetterTypes.GET_EVENTS_REFBOOK]: ({ eventRefBook }) => eventRefBook,
  [GetterTypes.GET_EVENT_VALUE_PICKER]: ({ tmpDateFilter }) => tmpDateFilter,
  [GetterTypes.GET_EVENT_FILTERS_INFO]: ({ filtersEventsInfo }) => filtersEventsInfo,
  [GetterTypes.GET_EVENT_FILTERS_DEFAULT]: ({ filterEventsDefault }) => filterEventsDefault,
  [GetterTypes.GET_EVENT_TMP_FILERS]: ({ tmpEventFilters }) => tmpEventFilters,
  [GetterTypes.GET_EVENTS_FILTERS]: ({ filtersEvents }) => filtersEvents
}
