import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'

import * as RootMutationTypes from '@/store/mutation-types'

import ViewService from '@/services/ViewService'

export default {
  [ActionTypes.REQUEST_VIEWS]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ViewService, [userToken]).getViews()
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_RECORDS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CREATE_VIEW]: async ({ rootGetters, commit, dispatch }, view) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ViewService, [userToken]).createView(view)
    const { data } = response
    if (data) {
      await dispatch(ActionTypes.REQUEST_VIEWS)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_DELETE_VIEW_RECORD]: async ({ rootGetters, commit, dispatch }, viewId) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(ViewService, [userToken]).destroyView(viewId.id)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_VIEWS)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
