<template>
  <div class="user-active">
    <div class="stats_avatar">
      <img class="stats_avatar__content" :src="avatar(profileStats.info.user_avatar.content_id)" alt="" />
      <div class="stats_links">
        <router-link
          v-if="user_permissions.includes(103)"
          :to="{ name: 'UserItem', params: { userId: profileStats.info.user_id } }"
          class="app-user__name-link"
          target="_blank"
        >
          {{ profileStats.info.user_name }}
          <span>({{ profileStats.info.age }})</span>
          <div class="app-user__address" v-if="profileStats.info.user_city">
            <span>{{ profileStats.info.user_country.name }}/{{ profileStats.info.user_city }}</span>
          </div>
        </router-link>
        <div v-else class="app-user__name-not-link">
          {{ profileStats.info.user_name }}
          <span>({{ profileStats.info.age }})</span>
          <div class="app-user__address" v-if="profileStats.info.user_city">
            <span>{{ profileStats.info.user_country.name }}/{{ profileStats.info.user_city }}</span>
          </div>
        </div>
        <div v-if="profileStats.info.counters" class="stats_name-devide">
          <FaIcon :iconClass="'sublist-item__outgoing fas fas fa-arrow-down'" v-tooltip="'Входящие'">
            {{ profileStats.info.counters['Входящие'] }}
          </FaIcon>
          <FaIcon :iconClass="'sublist-item__incoming fas fas fa-arrow-up'" v-tooltip="'Исходящие'">
            {{ profileStats.info.counters['Исходящие'] }}
          </FaIcon>
        </div>
        <!-- <span class="stats_name-devide">—</span> -->
        <img class="stats_avatar__content" :src="operatorAvatar(operatorInfo.avatar.content_id)" alt="" />
        <router-link
          v-if="user_permissions.includes(103)"
          :to="{ name: 'UserItem', params: { userId: operatorInfo.id } }"
          class="app-user__name-link"
          target="_blank"
        >
          {{ operatorInfo.user_name }} (оператор)
        </router-link>
        <div v-else class="app-user__name-not-link">{{ operatorInfo.user_name }} (оператор)</div>
      </div>
      <div v-if="infoOper" class="operator__info">
        <img v-if="infoOper.avatar" :src="operatorAvatar(infoOper.avatar)" class="stats_avatar__content" />
        <img v-else class="stats_avatar__content" src="../../assets/img/anonymous_user.png" alt="" />
        <div class="operator__info-wrapp">
          <p>На смене была: {{ fullName }}</p>
          <p>Время активности: {{ timeShift }}</p>
        </div>
      </div>
      <div class="app-user__gender">
        <i
          :class="{
            'fa fa-mars app-user__gender-icon--male': profileStats.info.user_gender === 1,
            'fa fa-venus app-user__gender-icon--female': profileStats.info.user_gender === 2
          }"
          class="app-user-profile__gender-icon"
        >
        </i>
      </div>
    </div>
    <div class="chart__wrapp">
      <BarChart
        :chart-data="video_review_stats_main"
        :options="video_review_stats_opt"
        class="regenterstats-chart"
        ref="chart_main"
      >
      </BarChart>
      <div class="balance" v-if="status === 'dialogues' || status === 'all-day'">
        <span class="balance__user">
          Баланс пользователя:
        </span>
        <span v-tooltip="'Баланс на конец дня'">
          {{ getBillInfoForUser.balance_end_day }}
        </span>
        <BarChart
          :chart-data="video_review_stats_inner"
          :options="video_review_stats_opt_balance"
          class="regenterstats-chart-balance"
          ref="chart"
        >
        </BarChart>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from './BarChart'
import { ru } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import 'chartjs-plugin-datalabels'
import { REQUEST_MODARATION_VIDEO, REQUEST_GET_OPERATOR_INFO_ON_SHIFT } from '@/store/action-types'
import getImgCdnLink from '@/components/mixins/getImgCdnLink'
import {
  GET_COUNTERS_MESSAGES_OPERATORS,
  GET_STATISTICS_PROFILE_MESSAGES,
  GET_PERMISSIONS_USER
} from '@/store/getter-types'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProfileStatistic',
  components: {
    BarChart
  },
  mixins: [getImgCdnLink],
  props: {
    profileStats: {
      type: Object
    },
    user_id: {
      type: String
    },
    account_id: {
      type: String
    },
    operID: {
      type: String
    },
    operatorInfo: {
      type: Object
    },
    status: {
      type: String
    },
    balanceHistory: {
      type: Array
    },
    info: {
      type: Object
    },
    validOper_id: {
      type: String
    },
    date: {
      type: Object,
      default: () => {}
    },
    utc: {
      type: Number
    }
  },
  data() {
    return {
      infoOper: '',
      timeShift: '',
      ru: ru,
      load: false,
      profile: {},
      historyBalance: {},
      video_review_stats_main: {
        labels: [],
        datasets: []
      },
      video_review_stats_inner: {
        labels: [],
        datasets: []
      },
      video_review_stats_opt: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0
        },
        tooltips: {
          mode: 'label',
          intersect: true,
          callbacks: {
            label: (tooltipItem, data) => {
              const tooltip = data.datasets[tooltipItem.datasetIndex]
              const value = tooltip.data[tooltipItem.index]

              let date = this.date.date_from
              let time = tooltipItem.label
              this.timeShift = time
              let today = new Date(`${date} ${time} UTC`)
              let newData = {
                date: today.toISOString(),
                delta: this.utc ? new Date().getTimezoneOffset() / -60 : 0
              }
              if (this.validOper_id === '617ac798341e342d1a75047b') {
                this.getOperatorInfoOnShift(newData).then(res => {
                  if (res.status) {
                    this.infoOper = res.data
                  } else {
                    this.infoOper = ''
                  }
                })
              }
              if (tooltip.label !== 'Онлайн пользователя' && tooltip.label !== 'Онлайн оператора') {
                if (value === null) {
                  return 0
                } else if (value < 0) {
                  return tooltip.label
                } else if (value > 0) {
                  return tooltip.label + ': ' + value
                }
              } else {
                return
              }
            }
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                autoSkip: false,
                callback: function(tick) {
                  return /^[0-9]{2}:(00)$/.test(tick.substring(0, tick.length - 3))
                    ? tick.substring(0, tick.length - 3)
                    : ''
                },
                maxRotation: 0
              }
            }
          ],
          yAxes: [
            {
              stacked: false,
              beforeBuildTicks: function(axis) {
                if (axis.max <= 10) {
                  return (axis.max = 10)
                } else {
                  return axis.max
                }
              },
              ticks: {
                maxTicksLimit: 12,
                beginAtZero: true,
                maxRotation: 0
              }
            }
          ]
        },
        plugins: {
          datalabels: {
            display: false
          }
        }
      },
      video_review_stats_opt_balance: {
        responsive: true,
        maintainAspectRatio: false,
        // animation: {
        //   duration: 0
        // },
        tooltips: {
          mode: 'label',
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
              const tooltip = data.datasets[tooltipItem.datasetIndex]
              const value = tooltip.data[tooltipItem.index]

              return value === null ? 0 : tooltip.label + ': ' + value
            }
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                autoSkip: false,
                callback: function(tick) {
                  return /^[0-9]{2}:(00)$/.test(tick.substring(0, tick.length - 3))
                    ? tick.substring(0, tick.length - 3)
                    : ''
                },
                maxRotation: 0
              }
            }
          ],
          yAxes: [
            {
              stacked: false,
              ticks: {
                maxTicksLimit: 12,
                beginAtZero: true,
                maxRotation: 0
              }
            }
          ]
        },
        plugins: {
          datalabels: {
            display: false
          }
        }
      },
      video_review_stats_form: {
        date: moment().format('YYYY-MM-DD'),
        main_video: 0,
        deferred: 0,
        utc: 1
      },
      video_review_stats_loading: false
    }
  },
  watch: {
    status: function() {
      this.infoOper = ''
      this.timeShift = ''
    },
    'video_review_stats_form.utc': function() {
      this.geModerationPhotosStats()
    },
    profileStats: function() {
      this.reLoaded()
      if (this.$refs.chart_main._data._chart) this.$refs.chart_main._data._chart.update()
    }
  },
  beforeMount() {
    this.reLoaded()
    this.setDefaultOnlineDate()
  },
  computed: {
    ...mapGetters({
      counters: GET_COUNTERS_MESSAGES_OPERATORS,
      profileStatistic: GET_STATISTICS_PROFILE_MESSAGES,
      user_permissions: GET_PERMISSIONS_USER
    }),
    getBillInfoForUser() {
      let data
      if (this.profileStats) {
        let obj = this.profileStats.users_balls.data
        let keys = Object.keys(obj)
        data = {
          balans_star_day: obj[keys[0]]['Баланс пользователя'],
          balance_end_day: obj[keys[keys.length - 1]]['Баланс пользователя']
        }
      }
      return data
    },
    fullName() {
      return `${this.infoOper.first_name} ${this.infoOper.last_name}`
    }
  },
  methods: {
    ...mapActions({
      getModerationVideos: REQUEST_MODARATION_VIDEO,
      getOperatorInfoOnShift: REQUEST_GET_OPERATOR_INFO_ON_SHIFT
    }),
    setDefaultOnlineDate: function() {
      this.video_review_stats_form.date = moment().format('YYYY-MM-DD')
    },
    geModerationPhotosStats() {
      let newData = {
        date_from: moment().format('YYYY-MM-DD')
      }
      if (this.video_review_stats_form.utc) newData.delta = new Date().getTimezoneOffset() / -60
      this.getModerationVideos(newData).then(response => {
        this.reLoaded()
      })
    },
    avatar(id) {
      if (id !== '615439f99d5494963d03c64c.jpg') {
        if (
          this.profileStats.info.user_avatar.crop.x === 0 &&
          this.profileStats.info.user_avatar.crop.y === 0
        ) {
          return this.getFile({ file: this.profileStats.info.user_avatar.content_id, type: 'image' })
        }
        return this.getImgLinkCropped(this.profileStats.info.user_avatar.content_id, 480, {
          ...this.profileStats.info.user_avatar.crop
        })
      } else {
        return this.getFile({ file: this.profileStats.info.user_avatar.content_id, type: 'image' })
      }
    },
    operatorAvatar(id) {
      if (id !== '615439f99d5494963d03c64c.jpg') {
        if (this.operatorInfo.avatar.crop.x === 0 && this.operatorInfo.avatar.crop.y === 0) {
          return this.getFile({ file: this.operatorInfo.avatar.content_id, type: 'image' })
        }
        return this.getImgLinkCropped(this.operatorInfo.avatar.content_id, 480, {
          ...this.operatorInfo.avatar.crop
        })
      } else {
        return this.getFile({ file: this.operatorInfo.avatar.content_id, type: 'image' })
      }
    },
    getPhotosReviewStatsColors(index) {
      let colors = {
        'Исходящие: Простой текст': '188, 143, 143',
        'Исходящие: Изображение': '245, 58, 58',
        'Исходящие: Аудио сообщение': '228, 62, 52',
        'Исходящие: Видео файл': '49, 37, 146',
        'Исходящие: Музыкальная композиция': '182, 114, 0',
        'Исходящие: Файл': '194, 164, 74',
        'Исходящие: Координаты': '123, 25, 134',
        'Исходящие: Подарок': '185, 129, 72',
        'Исходящие: Видеозвонок отменил звонящий': '255,192,203',
        'Исходящие: Видеозвонок отменил принимающий': '255,192,203',
        'Исходящие: Видеозвонок отменил оператор': '188,143,143',
        'Исходящие: Видеозвонок отменил пользователь': '188,143,143',
        'Исходящие: Видеофайл': '70, 200, 119',
        'Исходящие: Пропущенный видеозвонок': '21, 0, 255',
        'Исходящий видеозвонок': '185, 129, 72',

        'Входящие: Простой текст': '88, 201, 243',
        'Входящие: Аудио сообщение': '248, 67, 164',
        'Входящие: Изображение': '228, 162, 52',
        'Входящие: Видеофайл': '149, 137, 146',
        'Входящие: Музыкальная композиция': '182, 114, 110',
        'Входящие: Файл': '167, 140, 176',
        'Входящие: Координаты': '123, 125, 34',
        'Входящие: Подарок': '185, 139, 172',
        'Простой текст': '88, 201, 243',
        'Аудио сообщение': '248, 67, 164',
        Изображение: '228, 162, 52',
        'Видео файл': '149, 137, 146',
        'Музыкальная композиция': '182, 114, 110',
        Файл: '167, 140, 176',
        Координаты: '123, 125, 34',
        Подарок: '185, 139, 172',
        Видеофайл: '119, 54, 68',
        'Входящие: Видеозвонок отменил звонящий': '218,112,214',
        'Входящие: Видеозвонок отменил принимающий': '218,112,214',
        'Входящие: Видеозвонок отменил оператор': '106,90,205',
        'Входящие: Видеозвонок отменил пользователь': '106,90,205',
        'Входящие: Пропущенный видеозвонок': '255, 0, 0',
        'Входящий видеозвонок': '77, 153, 147',

        'Видеозвонок отменил звонящий': '218,112,214',
        'Видеозвонок отменил принимающий': '218,112,214',
        'Видеозвонок отменил оператор': '106,90,205',
        'Видеозвонок отменил пользователь': '106,90,205',

        'Автосообщения: Простой текст': '123, 104, 238',
        'Автосообщения: Аудио сообщение': '98, 97, 164',
        'Автосообщения: Изображение': '28, 62, 152',
        'Автосообщения: Видеофайл': '49, 137, 14',
        'Автосообщения: Музыкальная композиция': '82, 114, 50',
        'Автосообщения: Файл': '109, 122, 95',
        'Автосообщения: Координаты': '163, 95, 134',
        'Автосообщения: Подарок': '125, 29, 22',

        'Онлайн оператора': '199, 21, 133',
        'Оператор пропустил видеозвонок': '54,148, 189',
        'Онлайн пользователя': '97, 204, 84',
        'Баланс пользователя': '170, 255, 161',
        'Линия крайней суммы': '255, 18, 18'
      }

      return colors[index]
    },
    getPhotosReviewStatsAdminName(admin_id, admins = {}) {
      // eslint-disable-next-line no-prototype-builtins
      if (admins.hasOwnProperty(admin_id)) {
        return admins[admin_id]
      }
      return admin_id
    },
    reLoaded() {
      const id = this.user_id
      this.profile = {
        [id]: {
          data: this.profileStats.data,
          labels: this.profileStats.labels
        }
      }

      this.video_review_stats_main.labels = []
      this.video_review_stats_main.datasets = []

      for (let index in this.profile) {
        const labels = this.profile[index].labels
        const period = this.profile[index].data
        let datasets = []
        let stats = {}

        if (index) {
          stats = this.video_review_stats_main
        }

        for (let key in period) {
          // eslint-disable-next-line no-prototype-builtins
          if (period.hasOwnProperty(key)) {
            stats.labels.push(key)

            for (let adm in period[key]) {
              // eslint-disable-next-line no-prototype-builtins
              if (period[key].hasOwnProperty(adm)) {
                // if (!labels.find(f => f === adm)) {
                //   continue
                // }
                let data_idx = datasets.indexOf(adm)
                if (data_idx === -1) {
                  data_idx = datasets.push(adm) - 1
                }
                // eslint-disable-next-line no-prototype-builtins
                if (!stats.datasets.hasOwnProperty(data_idx)) {
                  let dataset = {}
                  if (
                    adm !== 'Онлайн оператора' &&
                    adm !== 'Онлайн пользователя' &&
                    adm !== 'Входящий видео звонок'
                  ) {
                    dataset = {
                      borderColor: `rgba(${this.getPhotosReviewStatsColors(adm)},1)`,
                      backgroundColor: `rgba(${this.getPhotosReviewStatsColors(adm)},0.5)`,
                      label: this.getPhotosReviewStatsAdminName(adm, labels),
                      categoryPercentage: 1.0,
                      barPercentage: 1.0,
                      barThickness: 'flex',
                      data: []
                    }
                  } else if (adm === 'Входящий видео звонок') {
                    dataset = {
                      borderColor: `rgba(${this.getPhotosReviewStatsColors(adm)},1)`,
                      backgroundColor: `rgba(${this.getPhotosReviewStatsColors(adm)},1)`,
                      label: this.getPhotosReviewStatsAdminName(adm, labels),
                      data: [],
                      categoryPercentage: 1.0,
                      type: 'line',
                      fill: 'none',
                      pointRadius: 2,
                      pointStyle: 'line',
                      pointBorderWidth: 5,
                      order: 3
                    }
                  } else if (adm === 'Онлайн оператора') {
                    dataset = {
                      borderColor: `rgba(${this.getPhotosReviewStatsColors(adm)},1)`,
                      backgroundColor: `rgba(${this.getPhotosReviewStatsColors(adm)},1)`,
                      label: this.getPhotosReviewStatsAdminName(adm, labels),
                      data: [],
                      categoryPercentage: 1.0,
                      type: 'line',
                      fill: 'none',
                      pointRadius: 4,
                      pointBorderWidth: 4,
                      pointStyle: 'line',
                      order: 2
                    }
                  } else if (adm === 'Онлайн пользователя') {
                    dataset = {
                      borderColor: `rgba(${this.getPhotosReviewStatsColors(adm)},1)`,
                      backgroundColor: `rgba(${this.getPhotosReviewStatsColors(adm)},1)`,
                      label: this.getPhotosReviewStatsAdminName(adm, labels),
                      data: [],
                      categoryPercentage: 1.0,
                      type: 'line',
                      fill: 'none',
                      pointRadius: 4,
                      pointBorderWidth: 4,
                      pointStyle: 'line',
                      order: 1
                    }
                  }
                  stats.datasets[data_idx] = dataset
                }
                stats.datasets[data_idx].data.push(period[key][adm])
              }
            }
          }
        }
      }

      if (this.status === 'dialogues' || this.status === 'all-day') {
        this.historyBalance = {
          [id]: {
            data: this.profileStats.users_balls.data,
            labels: this.profileStats.users_balls.labels
          }
        }

        this.video_review_stats_inner.labels = []
        this.video_review_stats_inner.datasets = []
        for (let index in this.historyBalance) {
          const labels = this.historyBalance[index].labels
          const period = this.historyBalance[index].data
          let datasets = []
          let stats = {}

          if (index) {
            stats = this.video_review_stats_inner
          }

          for (let key in period) {
            // eslint-disable-next-line no-prototype-builtins
            if (period.hasOwnProperty(key)) {
              stats.labels.push(key)

              for (let adm in period[key]) {
                // eslint-disable-next-line no-prototype-builtins
                if (period[key].hasOwnProperty(adm)) {
                  if (!labels.find(f => f === adm)) {
                    continue
                  }
                  let data_idx = datasets.indexOf(adm)
                  if (data_idx === -1) {
                    data_idx = datasets.push(adm) - 1
                  }
                  // eslint-disable-next-line no-prototype-builtins
                  if (!stats.datasets.hasOwnProperty(data_idx)) {
                    let dataset = {}
                    if (adm !== 'Линия крайней суммы') {
                      dataset = {
                        borderColor: `rgba(${this.getPhotosReviewStatsColors(adm)},1)`,
                        backgroundColor: `rgba(${this.getPhotosReviewStatsColors(adm)},0.5)`,
                        label: this.getPhotosReviewStatsAdminName(adm, labels),
                        categoryPercentage: 1.0,
                        barPercentage: 1.0,
                        barThickness: 'flex',
                        order: 2,
                        data: []
                      }
                    } else {
                      dataset = {
                        borderColor: `rgba(${this.getPhotosReviewStatsColors(adm)},1)`,
                        backgroundColor: `rgba(${this.getPhotosReviewStatsColors(adm)},0.5)`,
                        label: this.getPhotosReviewStatsAdminName(adm, labels),
                        categoryPercentage: 1.0,
                        barPercentage: 1.0,
                        barThickness: 'flex',
                        type: 'line',
                        fill: 'none',
                        pointRadius: 0,
                        order: 1,
                        pointStyle: 'line',
                        data: []
                      }
                    }
                    stats.datasets[data_idx] = dataset
                  }
                  stats.datasets[data_idx].data.push(period[key][adm])
                }
              }
            }
          }
        }
      }
      this.$root.$emit('ready', true)
    }
  }
}
</script>

<style lang="scss">
@import './Statistic.scss';

.balance {
  margin-top: 8px;
  background-color: #f0f0f0bb;
  padding: 6px;
  &__user {
    color: #759aff;
  }
}

.app-user {
  &__gender-icon {
    font-size: 17px;
    margin-right: 2px;
    &--male {
      color: $blue;
    }
    &--female {
      color: #ff99cc;
      margin-right: 5px;
    }
    &--question {
      color: $grey;
      margin-right: 5px;
    }
  }
}

.messages_counter {
  margin-left: 5%;
}

.stats_avatar {
  display: flex;
  align-items: center;
  margin-top: 12px;
  width: 100%;
  position: relative;
}

.chart__wrapp {
  margin-top: 20px;
}

.stats_avatar__content {
  width: 70px;
  height: 70px;
  margin-right: 8px;
  border-radius: 80px;
}

.stats_links {
  display: flex;
  align-items: center;
}

.stats_name-devide {
  margin: 0 12px 0 6px;
  align-self: center;
}

.app-user__name-link {
  color: #505d70;
  margin-right: 6px;
}
a {
  text-decoration: none;
}
//tooltip

.operator__info {
  position: absolute;
  right: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: fit-content;
  border: 1px solid #2b2b2b;
  background-color: #e5e7e7;
  padding: 8px;
  border-radius: 6px;
  color: #000000;
  &-wrapp {
    display: flex;
    flex-direction: column;
  }
}
</style>
