import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'

import AchievementsService from '@/services/AchievementsService'

export default {
  [ActionTypes.REQUEST_ACHIEVEMENTS]: async ({ rootGetters, commit }, lang = 'RU') => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(AchievementsService, [userToken]).getAllAchievements(lang)
    let { data } = response
    if (data) {
      // let trueAchievement = data.filter(m => m.gender === 0); // поменяли запрос
      commit(MutationTypes.SET_ACHIEVEMENTS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EDIT_ACHIEVEMENTS]: async ({ rootGetters, commit, dispatch }, achievementData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(AchievementsService, [userToken]).editAchievement(
      achievementData
    )
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ACHIEVEMENTS, lang)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_DELETE_ACHIEVEMENT]: async ({ rootGetters, commit, dispatch }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(AchievementsService, [userToken]).deleteAchievement(id)

    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ACHIEVEMENTS, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_UPDATE_ACHIEVEMENTS]: async ({ rootGetters, commit, dispatch }, achievement) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(AchievementsService, [userToken]).updateAchievement(achievement)
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ACHIEVEMENTS, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
