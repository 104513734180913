export default {
  data: () => ({
    visible: false,
    keyMixin: ''
  }),

  methods: {
    close() {
      this.visible = false
      document.body.removeEventListener('keydown', this.closeByEsc.bind(this))
    },
    open(key) {
      this.keyMixin = key
      document.body.addEventListener('keydown', this.closeByEsc.bind(this))
      this.visible = true
    },
    closeByEsc(e) {
      if (e.keyCode === 27) this.close()
    }
  },
  beforeDestroy() {
    this.$root.$off(this.keyMixin)
  }
}
