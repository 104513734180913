<template>
  <div>
    <div v-if="isLoaded">
      <div class="operators__header">
        <span class="operators__title title-big">Баннеры Ios</span>
      </div>
      <div class="table-header-wrapper-oper">
        <ul class="table-header table-event-header">
          <li class="table-header-banner__item-event">
            <span class="table-header-banner__item-text table-header-banner__item-text--info">Локаль</span>
          </li>
          <li class="table-header-banner__item-event">
            <span class="table-header-banner__item-text">Заголовок</span>
          </li>
          <li class="table-header-banner__item-event table-header-banner__item-event-text">
            <span class="table-header-banner__item-text">Текст под заголовком</span>
          </li>
          <li class="table-header-banner__item-event">
            <span class="table-header-banner__item-text">Текст на кнопке</span>
          </li>
        </ul>
      </div>
      <Banner
        v-for="(lang, index) in languages"
        :key="index"
        :languages="lang.name"
        :languagesId="lang.id"
        :device="ios"
      />
      <div class="operators__header">
        <span class="operators__title title-big">Баннеры Android</span>
      </div>
      <div class="table-header-wrapper-oper">
        <ul class="table-header table-event-header">
          <li class="table-header-banner__item-event">
            <span class="table-header-banner__item-text table-header-banner__item-text--info">Локаль</span>
          </li>
          <li class="table-header-banner__item-event">
            <span class="table-header-banner__item-text">Заголовок</span>
          </li>
          <li class="table-header-banner__item-event table-header-banner__item-event-text">
            <span class="table-header-banner__item-text">Текст под заголовком</span>
          </li>
          <li class="table-header-banner__item-event">
            <span class="table-header-banner__item-text">Текст на кнопке</span>
          </li>
        </ul>
      </div>
      <Banner
        v-for="(lang, index) in languages"
        :key="index + 'ss'"
        :languages="lang.name"
        :languagesId="lang.id"
        :device="android"
      />
      <OperatorPopupBanner />
      <DeleteOperatorPopupBanner />
    </div>
    <div v-if="errorMessage" class="operators__error">
      <span class="operators__error-text">Ошибка при загрузке Баннеров</span>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import { GET_LANGUAGES } from '@/store/getter-types'
import { REQUEST_OPERATORS_BANNER } from '@/store/action-types'
import Banner from '@/components/Operators/Banners/Banner'
import OperatorPopupBanner from '@/components/Modals/OperatorsPopup/OperatorPopupBanner'
import DeleteOperatorPopupBanner from '@/components/Modals/OperatorsPopup/DeleteOperatorPopupBanner'
export default {
  name: 'operatorsBanners',
  components: {
    Banner,
    OperatorPopupBanner,
    DeleteOperatorPopupBanner
  },
  data: () => ({
    errorMessage: false,
    isLoaded: false,
    ios: 'IOS',
    android: 'Android'
  }),
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_OPERATORS_BANNER).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      languages: GET_LANGUAGES
    })
  }
}
</script>
<style lang="scss">
@import '@/components/Operators/Banners/Banner.scss';
</style>
