<template>
  <div class="table-header-wrapper-event">
    <ul class="table-header table-event-header">
      <li class="table-header__item-event">
        <span class="table-header__item-text">Организатор</span>
      </li>
      <li class="table-header__item-event">
        <span class="table-header__item-text">Дата и место встречи события </span>
      </li>
      <li class="table-header__item-event">
        <span class="table-header__item-text">Статус события</span>
      </li>
      <li class="table-header__item-event">
        <span class="table-header__item-text">Кол-во приглашенных</span>
      </li>
      <li class="table-header__item-event">
        <span class="table-header__item-text">Кол-во откликнувшихся</span>
      </li>
      <li class="table-header__item-event">
        <span class="table-header__item-text">Депозит</span>
      </li>
      <li class="table-header__item-event">
        <span class="table-header__item-text">Категория события</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'EventTableHeader'
}
</script>
<style lang="scss">
@import '@/components/Controls/Employee/TableHeader/EmployeeTableHeader.scss';
.table-header-wrapper-event {
  // display: flex;
  // align-items: center;
  // width: 100%;
  // padding: 5px 0;
  // background-color: #f1f2f7;
  // transition: 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
  // @media (max-width: 1020px) {
  //   display: none;
  // }
  display: flex;
  align-items: center;

  //padding: 5px 16px;
  width: calc(100% - 30px);
  margin: 90px 16px 0 16px;
  padding-bottom: 3px;
  background-color: #f1f2f7;
  transition: 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  z-index: 1;
  @media (max-width: 3904px) {
    margin-top: 90px;
  }
  @media (max-width: 3494px) {
    margin-top: 96px;
  }
  @media (max-width: 2177px) {
    margin-top: 96px;
  }
  @media (max-width: 1618px) {
    margin-top: 160px;
  }
  @media (max-width: 1341px) {
    margin-top: 160px;
  }
  @media (max-width: 1215px) {
    margin-top: 160px;
  }
  @media (max-width: 1131px) {
    margin-top: 160px;
  }
  @media (max-width: 1020px) {
    display: none;
  }
}
.table-event-header {
  justify-content: space-around;
}

.table-header__item-event {
  padding: 7px 5px 9px 5px;
  &:nth-child(1) {
    width: 27%;
    padding: 7px 5px 9px 8px;
  }
  &:nth-child(2) {
    width: 15%;
  }
  &:nth-child(3) {
    width: 6%;
  }
  &:nth-child(4) {
    width: 9%;
  }
  &:nth-child(5) {
    width: 9%;
  }
  &:nth-child(6) {
    width: 9%;
  }
  &:nth-child(7) {
    width: 15.5%;
  }
}
</style>
