<template>
  <div class="control-departments-wrap">
    <Filters :permissions_group="permissions_group" />
    <PermissionTableHeader />
    <div class="control-departments-list" v-if="permissions.length && isLoaded">
      <Permission v-for="permission in permissions" :key="permission.id" :permission="permission" />
    </div>
    <div
      class="control-departments-list control-departments-list--error"
      v-if="!permissions.length && isLoaded"
    >
      <span class="control-departments-list__text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="control-departments-list control-departments-list--error">
      <span class="control-departments-list__text">Ошибка при загрузке департментов</span>
    </div>
    <DeletePermissionPopup />
    <EditPermissionPopup :permissions_group="permissions_group" />
    <NewPermissionPopup :permissions_group="permissions_group" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { REQUEST_CONTROL_PERMISSIONS, REQUEST_PERMISSIONS_GROUP } from '@/store/action-types'
import { GET_CONTROL_PERMISSIONS, GET_PERMISSIONS_GROUP } from '@/store/getter-types'
import Filters from '@/components/Permissions/Filters/Filters'
import Permission from '@/components/Permissions/Permission'
import PermissionTableHeader from '@/components/Permissions/Permisson-table-header'
import DeletePermissionPopup from '@/components/Modals/PermissionPopup/DeletePermissionPopup'
import EditPermissionPopup from '@/components/Modals/PermissionPopup/EditPermissionPopup'
import NewPermissionPopup from '@/components/Modals/PermissionPopup/NewPermissionPopup'
import store from '@/store'

export default {
  name: 'managmentDepartments',
  components: {
    Filters,
    Permission,
    DeletePermissionPopup,
    EditPermissionPopup,
    NewPermissionPopup,
    PermissionTableHeader
  },

  data: () => ({
    errorMessage: false,
    isLoaded: false
  }),

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_CONTROL_PERMISSIONS).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
    store.dispatch(REQUEST_PERMISSIONS_GROUP)
  },

  computed: {
    ...mapGetters({
      permissions: GET_CONTROL_PERMISSIONS,
      permissions_group: GET_PERMISSIONS_GROUP
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
    this.$root.$on('filtersHeight', h => {
      const margin = `${h + 35}` + 'px'
      document.querySelector('.control-departments-list').style.marginTop = margin
      document.querySelector('.simplebar-vertical').style.marginTop = `${h + 50}` + 'px'
    })
  }
}
</script>

<style lang="scss" scoped>
.control-departments {
  &-wrap {
    padding: 16px 16px;
    margin: 0 auto;
  }
  &-list {
    position: relative;
    @media (max-width: 1020px) {
      margin-top: 70px;
    }
    &__text {
      margin: 0 auto;
    }
  }
  &-list--error {
    position: relative;
    display: flex;
    min-height: 64px;
    align-items: center;
    background-color: white;
    @media (max-width: 1020px) {
      margin-top: 70px;
    }
  }
}
</style>
