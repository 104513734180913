<template>
  <div class="cause-points">
    <ul class="cause-points__list">
      <CausePointItem
        v-for="point in filterTitlePoint"
        :key="point.id"
        :point="point"
        :malePoints="filterMale"
        :femalePoints="filterFemale"
        :twoGenderPoints="twoGender"
      />
    </ul>
  </div>
</template>

<script>
import CausePointItem from './CausePointItem.vue'

export default {
  name: 'CausePointsList',
  props: {
    causePoints: {
      type: Array
    }
  },
  data: () => ({
    malePoints: [],
    femalePoints: [],
    twoGenderPoints: [],
    titles: []
  }),
  components: {
    CausePointItem
  },
  computed: {
    twoGender() {
      let tableHeaders = []
      tableHeaders = this.causePoints.filter(m => m.gender === 0)
      return tableHeaders
    },
    filterMale() {
      let tableHeaders = []
      tableHeaders = this.causePoints.filter(m => m.gender === 1)
      return tableHeaders
    },
    filterFemale() {
      let tableHeaders = []
      tableHeaders = this.causePoints.filter(m => m.gender === 2)
      return tableHeaders
    },
    filterTitlePoint() {
      let tableHeaders = []
      tableHeaders = this.causePoints.filter((v, i, a) => a.findIndex(t => t.title === v.title) === i)
      return tableHeaders
    }
  },
  watch: {
    filterMale: {
      deep: true,
      handler: function(newVal) {
        this.malePoints = newVal
      }
    },
    twoGender: {
      deep: true,
      handler: function(newVal) {
        this.twoGenderPoints = newVal
      }
    },
    filterFemale: {
      deep: true,
      handler: function(newVal) {
        this.femalePoints = newVal
      }
    },
    filterTitlePoint: {
      deep: true,
      handler: function(newVal) {
        this.titles = newVal
      }
    }
  }
}
</script>

<style lang="scss"></style>
