var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"claim"},[_c('div',{staticClass:"claim__field"},[_c('div',{staticClass:"claim__avatar"},[(_vm.message._source.operator_user.user_avatar.content_id)?_c('img',{attrs:{"src":_vm.getImgLinkCropped(_vm.message._source.operator_user.user_avatar.content_id, 480, Object.assign({}, _vm.message._source.operator_user.user_avatar.crop)),"alt":""}}):_c('img',{attrs:{"src":require("../../assets/img/anonymous_user.png"),"alt":""}})]),_c('div',{staticClass:"claim__user-info"},[_c('div',{staticClass:"claim__name"},[(_vm.user_permissions.includes(103))?_c('router-link',{staticClass:"app-user__name-link",attrs:{"to":{ name: 'UserItem', params: { userId: _vm.message._source.operator_user.user_id } },"target":"_blank"}},[_c('span',{staticClass:"claim__text claim__user-link"},[_vm._v(_vm._s(_vm.message._source.operator_user.user_name))])]):_c('div',{staticClass:"app-user__name-link"},[_c('span',{staticClass:"claim__text claim__user-link"},[_vm._v(_vm._s(_vm.message._source.operator_user.user_name))])]),_c('div',{staticClass:"claim__age"},[_vm._v("("+_vm._s(_vm.message._source.operator_user.age)+")")]),_c('i',{class:{
            'fa fa-mars app-user__gender-icon--male': _vm.message._source.operator_user.user_gender === 1,
            'fa fa-venus app-user__gender-icon--female': _vm.message._source.operator_user.user_gender === 2
          }})],1)])]),_vm._m(0),_c('div',{staticClass:"claim__field"},[_c('div',{staticClass:"claim__avatar"},[(_vm.message._source.user.user_avatar.content_id !== '61543a209d5494963d03c64e.jpg')?_c('img',{attrs:{"src":_vm.getImgLinkCropped(_vm.message._source.user.user_avatar.content_id, 480, Object.assign({}, _vm.message._source.user.user_avatar.crop)),"alt":""}}):_c('img',{attrs:{"src":require("../../assets/img/anonymous_user.png"),"alt":""}})]),_c('div',{staticClass:"claim__user-info"},[_c('div',{staticClass:"claim__name"},[(_vm.user_permissions.includes(103))?_c('router-link',{staticClass:"app-user__name-link",attrs:{"to":{ name: 'UserItem', params: { userId: _vm.message._source.user.user_id } },"target":"_blank"}},[_c('span',{staticClass:"claim__text claim__user-link"},[_vm._v(_vm._s(_vm.message._source.user.user_name))])]):_c('div',{staticClass:"app-user__name-link"},[_c('span',{staticClass:"claim__text claim__user-link"},[_vm._v(_vm._s(_vm.message._source.user.user_name))])]),_c('div',{staticClass:"claim__age"},[_vm._v("("+_vm._s(_vm.message._source.user.age)+")")]),_c('i',{class:{
            'fa fa-mars app-user__gender-icon--male': _vm.message._source.user.user_gender === 1,
            'fa fa-venus app-user__gender-icon--female': _vm.message._source.user.user_gender === 2
          }})],1)])]),(
      _vm.message._source.message.message_content_type === 3 ||
        _vm.message._source.message.message_content_type === 4
    )?_c('div',{staticClass:"claim__field"},[_c('div',[_c('audio',{attrs:{"controls":"","src":_vm.getMediaLink(_vm.message._source.message.message_content.DataID, 'audio')}})])]):_vm._e(),(_vm.message._source.message.message_content_type === 0)?_c('div',{staticClass:"claim__field"},[_c('div',{staticClass:"claim__message"},[_c('p',{staticClass:"claim__message--content"},[_vm._v(_vm._s(_vm.message._source.message.message_content.Text.Original))])])]):_vm._e(),(_vm.message._source.message.message_content_type === 1)?_c('div',{staticClass:"claim__field"},[_c('div',{staticClass:"claim__message-img"},[(_vm.message._source.message.message_content.DataID)?_c('img',{ref:_vm.message._source.message.message_id,staticClass:"claim__message-picture",attrs:{"src":_vm.getFile({
            type: 'image',
            file: _vm.message._source.message.message_content.DataID
          }),"alt":""},on:{"click":function($event){return _vm.resizeImg(_vm.message._source.message.message_id)}}}):_c('img',{staticClass:"no-img"})])]):_vm._e(),(_vm.message._source.message.error)?_c('div',{staticClass:"claim__field"},[_vm._v(" Автосообщение отсутвует! ")]):_vm._e(),_c('div',{staticClass:"claim__field"},[_c('div',{staticClass:"claim__dialog"},[(_vm.message._source.count > 1)?_c('p',{staticClass:"claim__dialog-status"},[_vm._v(" "+_vm._s(("" + (_vm.message._source.message.error ? _vm.message._source.message.error : 'Диалог начался')))+" ")]):_c('p',{staticClass:"claim__dialog-status"},[_vm._v("Диалог не начался")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"claim__field"},[_c('i',{staticClass:"fas fa-arrow-right claim__to-right"})])}]

export { render, staticRenderFns }