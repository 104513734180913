<template>
  <section ref="filtersWrap" class="filters-wrap">
    <form @submit.prevent="submitEmployeeHandler" class="filters" :class="{ opened: mobileFilterOpen }">
      <div class="filters__mobile-nav">
        <button type="button" class="filters__link" @click="mobileFilterOpen = !mobileFilterOpen">
          <i class="filters__icon fa fa-filter"></i>
          <span class="filters__button-text">Фильтры</span>
        </button>
      </div>
      <div class="filters__items">
        <div class="filters__group">
          <div class="filters__item">
            <span class="filters__item-title">Id:</span>
            <input
              type="text"
              class="filters__input filters__input--id"
              v-model="employeeId"
              ref="employeeid"
            />
          </div>
          <div class="filters__item">
            <span class="filters__item-title">Email:</span>
            <input
              type="text"
              class="filters__input filters__input--id"
              v-model="employeeEmail"
              ref="employeemail"
            />
          </div>
        </div>
        <div class="filters__group">
          <div class="filters__item">
            <span class="filters__item-title">Имя:</span>
            <input type="text" class="filters__input" v-model="employeeName" ref="employeename" />
          </div>
          <div class="filters__item">
            <span class="filters__item-title">Фамилия:</span>
            <input type="text" class="filters__input" v-model="employeeLastName" ref="employeelastname" />
          </div>
        </div>
        <div class="filters__group">
          <div class="filters__item">
            <div class="select">
              <span class="select__title">Должность:</span>
              <div class="select__body">
                <button type="button" class="select__field" @click="clickClose('position')">
                  <span class="select__field-value">{{ defaultFilter({ key: 'position' }) }}</span>
                  <i class="select__field-icon fa fa-angle-down"></i>
                </button>
                <ul
                  class="select__list select__list--country"
                  v-if="positionSelectOpen"
                  @mouseleave="mouseLeave"
                >
                  <li
                    class="select__item"
                    v-for="item in tmpFilterInfo.positions"
                    :key="item.id"
                    @click="
                      selectFilterBuilder({
                        key: 'position',
                        value: item
                      })
                    "
                  >
                    <span class="select__text"> {{ item.position_name }} </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="filters__group">
          <div class="filters__item">
            <div class="select select--status">
              <span class="select__title">Статус:</span>
              <div class="select__body">
                <button
                  type="button"
                  class="select__field select__field--status"
                  @click="clickClose('activity')"
                >
                  <span class="select__field-value">{{ defaultFilter({ key: 'activity' }) }}</span>
                  <i class="select__field-icon fa fa-angle-down"></i>
                </button>
                <ul class="select__list" v-if="activitySelectOpen" @mouseleave="mouseLeave">
                  <li
                    class="select__item"
                    v-for="item in tmpFilterInfo.activity"
                    :key="item.id"
                    @click="
                      selectFilterBuilder({
                        key: 'activity',
                        value: item
                      })
                    "
                  >
                    <span class="select__text"> {{ item.position_name }} </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="filters__item">
            <div class="select select--status">
              <span class="select__title">Удален:</span>
              <div class="select__body">
                <button
                  type="button"
                  class="select__field select__field--status"
                  @click="clickClose('employeeDeleted')"
                >
                  <span class="select__field-value">{{ defaultFilter({ key: 'employeeDeleted' }) }}</span>
                  <i class="select__field-icon fa fa-angle-down"></i>
                </button>
                <ul class="select__list" v-if="employeeDeletedSelectOpen" @mouseleave="mouseLeave">
                  <li
                    class="select__item"
                    v-for="item in tmpFilterInfo.employeeDeleted"
                    :key="item.id"
                    @click="
                      selectFilterBuilder({
                        key: 'employeeDeleted',
                        value: item
                      })
                    "
                  >
                    <span class="select__text"> {{ item.position_name }} </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="filters__buttons">
          <button type="button" class="filters__btn-clear" @click="resetFiltershandler">
            <i class="fa fa-eraser"></i>
          </button>
          <button type="submit" class="filters__btn-search">
            <span>Поиск</span>
          </button>
        </div>
      </div>
      <button type="button" class="filters__new-ticket" @click="showEmployeeModal">
        <i class="filters__new-ticket-icon fa fa-plus-square"></i>
        <span class="filters__button-text">Добавить</span>
      </button>
    </form>
  </section>
</template>

<script>
import { EMPLOYEE_POPUP_EVENT } from '@/constants/event'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_EMPLOYEE_FILTER, REQUEST_ALL_EMPLOYEE } from '@/store/action-types'
import {
  GET_EMPLOYEE_POSITIONS,
  GET_EMPLOYEE_ROLES_DEFAULT,
  GET_EMPLOYEE_ROLES,
  GET_TMP_FILTER_INFO
} from '@/store/getter-types'
import { SET_EMPLOYEE_SETTINGS, SET_CLEAR_EMPLOYEE_DATA } from '@/store/mutation-types'
export default {
  name: 'Filters',
  data: () => ({
    statusFilterOpen: false,
    appealFilterOpen: false,
    priorityFilterOpen: false,
    mobileFilterOpen: false,
    positionSelectOpen: false,
    activitySelectOpen: false,
    employeeDeletedSelectOpen: false,

    employeeFilters: {
      employee_id: '',
      fisrt_name: '',
      last_name: '',
      employee_position: '',
      email: ''
    }
  }),

  computed: {
    ...mapGetters({
      positions: GET_EMPLOYEE_POSITIONS,
      tmpFilter: GET_EMPLOYEE_ROLES,
      defaultFilters: GET_EMPLOYEE_ROLES_DEFAULT,
      tmpFilterInfo: GET_TMP_FILTER_INFO
    }),

    employeeEmail: {
      get() {
        return this.employeeFilters.email
      },
      set(mail) {
        this.$refs.employeemail.value = mail
        this.employeeFilters.email = mail
      }
    },

    employeeId: {
      get() {
        return this.employeeFilters.employee_id
      },
      set(id) {
        this.$refs.employeeid.value = id
        this.employeeFilters.employee_id = id
      }
    },
    employeeName: {
      get() {
        return this.employeeFilters.fisrt_name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.employeename.value = name
        this.employeeFilters.fisrt_name = name
      }
    },
    employeeLastName: {
      get() {
        return this.employeeFilters.last_name
      },
      set(last_name) {
        last_name = last_name.replace(/\d/g, '')
        this.$refs.employeename.value = last_name
        this.employeeFilters.last_name = last_name
      }
    },
    employeePosition: {
      get() {
        return this.employeeFilters.employee_position
      },
      set(position) {
        position = position.replace(/\d/g, '')
        this.$refs.employeeposition.value = position
        this.employeeFilters.employee_position = position
      }
    }
  },

  methods: {
    ...mapActions({
      getEmplFilter: REQUEST_EMPLOYEE_FILTER,
      getEmployees: REQUEST_ALL_EMPLOYEE
    }),
    ...mapMutations({
      addTmpFilters: SET_EMPLOYEE_SETTINGS,
      resetFilters: SET_CLEAR_EMPLOYEE_DATA
    }),
    showEmployeeModal() {
      this.$root.$emit(EMPLOYEE_POPUP_EVENT)
    },

    resetFiltershandler() {
      this.resetData(this.employeeFilters)
      this.resetFilters()
      // this.getEmployees()
      this.submitEmployeeHandler()
    },

    resetData(data) {
      for (const key in data) {
        data[key] = ''
      }
    },

    defaultValueFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].position_value
      return current !== undefined ? current : ''
    },

    defaultValueFilterStatus(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current !== undefined ? current : this.defaultFilters[key].value
    },

    submitEmployeeHandler() {
      const data = {
        id: this.employeeFilters.employee_id,
        first_name: this.employeeFilters.fisrt_name,
        last_name: this.employeeFilters.last_name,
        email: this.employeeFilters.email,
        position: this.defaultValueFilter({ key: 'position' }),
        is_active: this.defaultValueFilterStatus({ key: 'activity' }),
        is_deleted: this.defaultValueFilterStatus({ key: 'employeeDeleted' })
      }
      this.getEmplFilter(data)
    },
    defaultFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].position_name
      return current ? current : this.defaultFilters[key].position_name
    },
    selectFilterBuilder(filterItem) {
      const { key } = filterItem
      switch (key) {
        case 'position':
          this.addTmpFilters({ ...filterItem })
          break
        case 'activity':
          this.addTmpFilters({ ...filterItem })
          break
        case 'employeeDeleted':
          this.addTmpFilters({ ...filterItem })
          break

        default:
          break
      }
      this[`${key}SelectOpen`] = false
    },
    close() {
      this.positionSelectOpen = false
      this.activitySelectOpen = false
      this.employeeDeletedSelectOpen = false
    },
    mouseLeave() {
      this.close()
    },
    clickClose(options) {
      let newOptions = this[`${options}SelectOpen`]
      this.close()
      this[`${options}SelectOpen`] = !newOptions
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$emit('filtersHeight', this.$refs.filtersWrap.clientHeight)
    })
    this.$root.$on('ChangeEmployee', key => {
      if (key === 'newEmployee') {
        this.resetFiltershandler()
      }
      if (key === 'deleteEmployee' || key === 'editEmployee') {
        this.submitEmployeeHandler()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import './Filters.scss';
.select__list--country {
  width: 200px;
  height: 150px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    background-color: #e7e8ec;
    z-index: 0;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(232, 64, 63);
    border-radius: 10px;
  }
}
</style>
