import * as ActionTypes from './actions-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import GiftsService from '@/services/GiftsService'

export default {
  [ActionTypes.REQUEST_GET_ALL_GIFTS]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN

    const response = await Reflect.construct(GiftsService, [userToken]).getAllGifts()

    const { data } = response

    if (data) {
      commit(MutationTypes.SET_ALL_GIFTS, data)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_DELETE_GIFTS_BY_ID]: async ({ rootGetters, commit, dispatch }, giftsId) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN

    const response = await Reflect.construct(GiftsService, [userToken]).deleteGiftsById(giftsId)

    const { status } = response

    if (status) {
      await dispatch(ActionTypes.REQUEST_GET_ALL_GIFTS)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_CREATE_NEW_GIFTS]: async ({ rootGetters, commit, dispatch }, giftsData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN

    const response = await Reflect.construct(GiftsService, [userToken]).createGifts(giftsData)
    const { status } = response

    if (status) {
      await dispatch(ActionTypes.REQUEST_GET_ALL_GIFTS)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_UPDATE_GIFTS_BY_ID]: async ({ rootGetters, commit, dispatch }, giftsData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(GiftsService, [userToken]).updateGiftsById(
      giftsData.id,
      giftsData.data
    )
    const { status } = response

    if (status) {
      await dispatch(ActionTypes.REQUEST_GET_ALL_GIFTS)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  }
}
