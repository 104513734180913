import Request from '../helpers/Request'
import BaseService from './BaseService'

class GiftsService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getAllGifts() {
    const getAllGuftsUrl = `/api/v1/localization/gift/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }
    return await Request.get(getAllGuftsUrl, { headers })
  }

  async createGifts(newGifts) {
    const createGiftsUrl = `/api/v1/localization/gift/add/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Content-Type': 'multipart/form-data'
    }
    return await Request.post(createGiftsUrl, newGifts, { headers })
  }

  async updateGiftsById(giftsId, newGiftData) {
    const updateGiftsByIdUrl = `/api/v1/localization/gift/${giftsId}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(updateGiftsByIdUrl, newGiftData, { headers })
  }

  async deleteGiftsById(giftsId) {
    const deleteGiftsByIdUrl = `/api/v1/localization/gift/${giftsId}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteGiftsByIdUrl, { headers })
  }
}

export default GiftsService
