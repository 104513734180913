<template>
  <div class="table-header__row">
    <span class="table-header__row-item table-header__row-item--left">Дата</span>
    <span class="table-header__row-item-statis item--orange">Отложено</span>
    <!-- <span class="table-header__row-item-statis">Сброшено</span> -->
    <span class="table-header__row-item-statis item--blue">Временная блокировка</span>
    <span class="table-header__row-item-statis item--green">Одобрено</span>
    <span class="table-header__row-item-statis item--orange">Запр. для аватара</span>
    <!-- <span class="table-header__row-item-statis item--red">Забананено фото</span>
    <span class="table-header__row-item-statis item--red">Забананено видео</span> -->
    <span class="table-header__row-item-statis item--red">Забананено</span>
    <!-- <span class="table-header__row-item-statis">Забан. по аккаунту видео</span>
    <span class="table-header__row-item-statis">Забан по девайсу видео</span> -->
    <!-- <span class="table-header__row-item-statis">Запр. для аватара гл.</span>
    <span class="table-header__row-item-statis">Запр. для аватара вн.</span> -->
    <!-- <span class="table-header__row-item-statis item--red">Запр. для показа</span> -->
    <span class="table-header__row-item-statis">События</span>
    <span class="table-header__row-item-statis item--dark_blue">Оплата главной</span>
    <span class="table-header__row-item-statis item--dark_blue">Оплата внутренней</span>
    <span class="table-header__row-item-statis item--dark_blue">Оплата за баны фото</span>
    <span class="table-header__row-item-statis item--dark_blue">Оплата за баны видео</span>
    <span class="table-header__row-item-statis item--dark_blue">Оплата за баны</span>
    <!-- <span class="table-header__row-item-statis item--dark_blue">Оплата событий</span> -->
    <span class="table-header__row-item-statis item--dark_blue">Тарифы</span>
    <span class="table-header__row-item-statis item--dark_blue">Оплата общая</span>
    <!-- <span class="table-header__row-item table-header__row-item--left">Дата</span>
    <span class="table-header__row-item-statis">Отложенные фото</span>
    <span class="table-header__row-item-statis">Отложено</span>
    <span class="table-header__row-item-statis">Сброшенные фото</span>
    <span class="table-header__row-item-statis">Сброшено</span>
    <span class="table-header__row-item-statis">Одобренные фото гл.</span>
    <span class="table-header__row-item-statis">Одобренные фото вн.</span>
    <span class="table-header__row-item-statis">Одобренные видео гл.</span>
    <span class="table-header__row-item-statis">Отложенные видео</span>
    <span class="table-header__row-item-statis">Запр. видео гл.</span>
    <span class="table-header__row-item-statis">Забан. по аккаунту видео</span>
    <span class="table-header__row-item-statis">Забан по девайсу видео</span>
    <span class="table-header__row-item-statis">Запр. для аватара гл.</span>
    <span class="table-header__row-item-statis">Запр. для аватара вн.</span>
    <span class="table-header__row-item-statis">Запр. для показа</span>
    <span class="table-header__row-item-statis">Временная блокировка</span>
    <span class="table-header__row-item-statis">Оплата в главной</span>
    <span class="table-header__row-item-statis">Оплата во внутренней</span>
    <span class="table-header__row-item-statis">Забан. по аккаунту</span>
    <span class="table-header__row-item-statis">Забан. по девайсу</span>
    <span class="table-header__row-item-statis">Забан. по аккаунту гл.</span>
    <span class="table-header__row-item-statis">Забан. по девайсу гл.</span>
    <span class="table-header__row-item-statis">Забан. по аккаунту вн.</span>
    <span class="table-header__row-item-statis">Забан. по девайсу вн.</span>
    <span class="table-header__row-item-statis">Оплата за баны</span>
    <span class="table-header__row-item-statis">Отклонённые события</span>
    <span class="table-header__row-item-statis">Принятые события</span>
    <span class="table-header__row-item-statis">Отредактир. события</span>
    <span class="table-header__row-item-statis">Оп. проверки событий</span>
    <span class="table-header__row-item-statis">Оп. отредакт. событий</span> -->
  </div>
</template>

<script>
export default {
  name: 'ModeratorsStatisticTableHeader'
}
</script>

<style lang="scss" scoped>
@import 'ModeratorStatistic';
.item {
  &--orange {
    color: #eea83e;
    font-weight: 700;
  }
  &--grey {
    color: #686868;
    font-weight: 700;
  }
  &--green {
    color: #5cda46;
    font-weight: 700;
  }
  &--red {
    color: #ff0000;
    font-weight: 700;
  }
  &--blue {
    color: #22d5e6;
    font-weight: 700;
  }
  &--dark_blue {
    color: #0711d7;
    //color: #2530ff;
    font-weight: 700;
  }
}
</style>
