<template>
  <Popup
    :visible="visible"
    @close="close"
    description="Добавить уведомление"
    modalClass="popup--add-category popup--medium"
  >
    <form @submit.prevent="sendData">
      <div class="popup__item">
        <span class="popup__item-title">Тип пуша</span>
        <input type="number" class="popup__input default-input" v-model.number="type_push" required />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Системный заголовок</span>
        <input type="text" class="popup__input default-input" v-model="title" />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Системное сообщение</span>
        <textarea class="popup__input default-input" v-model="body" />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Внутренний заголовок</span>
        <input type="text" class="popup__input default-input" v-model="data_title" />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Внутреннее сообщение</span>
        <input type="text" class="popup__input default-input" v-model="data_body" />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Тип сообщения</span>
        <input type="number" class="popup__input default-input" v-model.number="type_msg" />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Добавить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { PUSH_ADD_POPUP } from '@/constants/event'
import { mapActions, mapGetters } from 'vuex'
import { GET_CURRENT_LANGUAGES } from '@/store/getter-types'
import { REQUEST_ADD_PUSHES } from '@/store/action-types'

export default {
  name: 'PushAddPopup',
  data() {
    return {
      type_push: '',
      type_msg: null,
      title: '',
      body: '',
      data_title: '',
      data_body: ''
    }
  },
  components: {
    Popup
  },
  mixins: [ModalMixin],

  computed: {
    ...mapGetters({
      lang: GET_CURRENT_LANGUAGES
    })
  },
  mounted() {
    this.$root.$on(PUSH_ADD_POPUP, push => {
      this.open()
    })
  },
  methods: {
    ...mapActions({
      addPushes: REQUEST_ADD_PUSHES
    }),
    sendData() {
      let payload = {
        lang: this.lang,
        type_push: this.type_push,
        title: this.title,
        body: this.body,
        data_title: this.data_title,
        data_body: this.data_body,
        type_msg: this.type_msg
      }

      this.addPushes(payload)
      this.type_push = ''
      this.type_msg = null
      this.title = ''
      this.body = ''
      this.data_title = ''
      this.data_body = ''
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.popup__item:first-child {
  margin-top: 10px;
}
</style>
