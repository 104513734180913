import Request from '../helpers/Request'
import BaseService from './BaseService'

class ViewService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getViews() {
    const firstScreenUrl = '/api/v1/localization/first-screen/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(firstScreenUrl, { headers })
  }

  async createView(record) {
    const firstScreenCreateUrl = '/api/v1/localization/first-screen/add/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(firstScreenCreateUrl, record, { headers })
  }

  async destroyView(id) {
    const firstScreenCreateUrl = `/api/v1/localization/first-screen/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.delete(firstScreenCreateUrl, { headers })
  }
}

export default ViewService
