<template>
  <div class="auto-messages-wrap">
    <div class="auto-messages">
      <AutoMessagesHeader />
      <div class="auto-messages__table">
        <span class="auto-messages__table-item">Пол отправителя</span>
        <span class="auto-messages__table-item">Пол получателя</span>
        <span class="auto-messages__table-item">Текст сообщения</span>
      </div>
      <div class="auto-messages__list" v-if="isLoaded && autoMessages.length">
        <AutoMessagesItem
          v-for="message in autoMessages"
          :key="`${message.id}${message.user_gender}${message.defendant_gender}`"
          :message="message"
        />
      </div>
      <div class="auto-messages__no-reasons" v-if="!autoMessages.length && isLoaded">
        <span class="auto-messages__text">Нет данных...</span>
      </div>
      <div v-if="errorMessage" class="auto-messages__no-reasons">
        <span class="auto-messages__text"
          >Ошибка при загрузке типов обращений. Обратитесь в техническую поддержку!</span
        >
      </div>
    </div>
    <AutoMessagesDelPopup />
    <AutoMessagesAddPopup />
    <AutoMessagesChangePopup />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { GET_AUTO_MESSAGES, GET_CURRENT_LANGUAGES } from '@/store/getter-types'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types'
import { REQUEST_AUTO_MESSAGES } from '@/store/action-types'
import AutoMessagesHeader from '@/components/AutoMessages/AutoMessagesHeader'
import AutoMessagesItem from '@/components/AutoMessages/AutoMessagesItem'
import AutoMessagesDelPopup from '@/components/Modals/AutoMessagesPopup/AutoMessagesDelPopup'
import AutoMessagesAddPopup from '@/components/Modals/AutoMessagesPopup/AutoMessagesAddPopup'
import AutoMessagesChangePopup from '@/components/Modals/AutoMessagesPopup/AutoMessagesChangePopup'

import store from '@/store'
export default {
  name: 'interfaceAutoMessages',
  components: {
    AutoMessagesHeader,
    AutoMessagesItem,
    AutoMessagesDelPopup,
    AutoMessagesAddPopup,
    AutoMessagesChangePopup
  },
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_AUTO_MESSAGES, { lang: 'RU', page: 1 }).then(response => {
      next(vm => {
        if (response) {
          vm.setCurrentLang('RU')
        }
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      autoMessages: GET_AUTO_MESSAGES,
      currentLang: GET_CURRENT_LANGUAGES
    })
  },
  methods: {
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
@import '@/components/AutoMessages/AutoMessages.scss';

.auto-messages__no-reasons {
  min-height: 64px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
}
.auto-messages__text {
  margin: 0 auto;
}
.auto-messages-wrap {
  padding: 16px;
  max-width: 1140px;
  margin: 0 auto;
  .auto-messages__item-name {
    &:first-child {
      width: 25%;
    }
    &:nth-child(2) {
      width: 25%;
    }
    &:nth-child(3) {
      width: 21%;
    }
    &:nth-child(4) {
      width: 10%;
    }
  }
  // .auto-messages__item-content {
  //   justify-content: flex-start;
  // }
}
</style>
