<template>
  <div class="setting-wrap">
    <ul class="setting__table-list">
      <OperatorSettingItem v-for="setting in settings" :key="setting.id" :setting="setting" />
    </ul>
    <OperatorsSettingAdd />
  </div>
</template>

<script>
import OperatorSettingItem from './OperatorSettingItem'
import OperatorsSettingAdd from './OperatorsSettingAdd'
export default {
  name: 'OperatorsSettingsList',
  components: { OperatorSettingItem, OperatorsSettingAdd },
  props: {
    settings: {
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'PaymentsSetting';
</style>
