<template>
  <Popup :visible="visible" @close="close" title="" description="Ошибка:" modalClass="popup--small">
    <form class="modal-update__question">
      <h2 class="pop-up__title pop-up__title--error">{{ statusError }}</h2>
      <div class="pop-up__btn-wrapper">
        <button type="button" @click="closeModal" class="button button--back button--center">
          Закрыть
        </button>
      </div>
    </form>
  </Popup>
</template>

<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import dateFormat from '@/components/mixins/toLocaleDateAndTime'
import { CALENDAR_ERROR_POPUP } from '@/constants/event'
export default {
  name: 'CalendarErrorPopup',
  components: {
    Popup
  },
  mixins: [dateFormat, ModalMixin],
  data() {
    return {
      dateStr: null,
      statusError: null
    }
  },
  mounted() {
    this.$root.$on(CALENDAR_ERROR_POPUP, prop => {
      this.dateStr = prop.selectInfo
      this.statusError = this.statusStateText(prop.causeError)
      this.open()
    })
  },
  methods: {
    closeModal() {
      this.dateStr = null
      this.statusError = null
      this.close()
    },
    statusStateText(value) {
      let status = null
      switch (value) {
        case 'periodNotAvailable':
          status = `Период c ${this.toLocaleDateTime(this.dateStr.start)} по ${this.toLocaleDateTime(
            this.dateStr.end
          )}  уже недоступен!`
          break
        case 'eventInThisTime':
          status = `На период c ${this.toLocaleDateTime(this.dateStr.start)} по ${this.toLocaleDateTime(
            this.dateStr.end
          )} уже назначена смена!`
          break
        case 'isNotCorrectDate':
          status = `Нельзя назначить/отредактировать данный временной период!`
          break
        case 'cantChangeCurrentEvent':
          status = `Редактирование данного временного периода заблокированно!`
          break
        case 'repeatModerator':
          status = `Данный оператор уже добавлен в смену!`
          break
        case 'remainderError':
          status = `При деление суток на смены, остаток после деления должен быть равен 0!`
          break
        case 'isNotCorrectShift':
          status = `Нельзя перемещать смену в которой больше чем один оператор!`
          break
        default:
          null
      }
      return status
    }
  }
}
</script>

<style lang="scss">
@import './CalendarPopup.scss';
.button--center {
  margin: 0 auto;
}
.pop-up__title--error {
}
</style>
