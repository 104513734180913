<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Добавить пользователя"
    modalClass="popup--add-user"
  >
    <div v-show="!different" class="attention-wrapp">
      <transition name="fade">
        <div class="attention-wrapp_content">
          <p>Вы не можете добавить пользователя, так как он уже есть у данного оператора!</p>
        </div>
      </transition>
    </div>
    <form @submit.prevent="sendData" class="popup--add-user">
      <div class="popup__filter">
        <label class="popup__item-title popup__filter-item">
          ID пользователя
          <input
            type="text"
            class="popup__input default-input popup__filter-input"
            v-model="userID"
            ref="userid"
          />
        </label>
        <label class="popup__item-title popup__filter-item">
          Email пользователя
          <input
            type="email"
            class="popup__input default-input popup__filter-input"
            v-model="filterEmail"
            ref="filteremail"
          />
        </label>
        <div class="popup__btns popup__filter-item popup__filter-btn">
          <button @click.prevent="searchData" type="submit" class="popup__btn btn-blue">
            Поиск
          </button>
        </div>
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Пользователи</span>
        <ul v-if="users.length" class="popup__item-list">
          <AddUserPopupItem v-for="user in users" :key="user.id" :user="user" :onClick="addUser" />
        </ul>
        <div v-else class="popup__item-list">
          <span class="popup__no-data">Пользователь не найден</span>
        </div>
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button :disabled="!different" type="submit" class="popup__btn btn-green">Добавить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { ADD_OPERATOR_USER_POPUP_EVENT } from '@/constants/event'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_FIND_USERS } from '@/store/action-types'
import { GET_OPERATOR_USERS, GET_CURRENT_OPERATOR_USER } from '@/store/getter-types'
import { SET_OPERATOR_CURRENT_USER, SET_OPERATOR_USERS } from '@/store/mutation-types'
import getImgCdnLink from '@/components/mixins/getImgCdnLink'
import AddUserPopupItem from './AddUserPopupItem'

export default {
  name: 'AddUserPopup',
  data() {
    return {
      different: true,
      userCurrent: null,
      filters: {
        user_id: '',
        email: ''
      }
    }
  },
  components: {
    AddUserPopupItem,
    Popup
  },
  mixins: [ModalMixin, getImgCdnLink],
  mounted() {
    this.$root.$on(ADD_OPERATOR_USER_POPUP_EVENT, operator => {
      if (operator) {
        this.filters.user_id = operator.id
        const userFilterData = {
          user_id: this.filters.user_id
        }
        this.applyFilters(userFilterData)
      }
      this.open()
    })
  },
  computed: {
    ...mapGetters({
      users: GET_OPERATOR_USERS,
      current: GET_CURRENT_OPERATOR_USER
    }),

    userID: {
      get() {
        return this.filters.user_id
      },
      set(id) {
        this.$refs.userid.value = id
        this.filters.user_id = id
      }
    },

    filterEmail: {
      get() {
        return this.filters.email
      },
      set(email) {
        this.$refs.filteremail.value = email
        this.filters.email = email
      }
    }
  },
  methods: {
    ...mapActions({
      applyFilters: REQUEST_FIND_USERS
    }),
    ...mapMutations({
      setCurrentUser: SET_OPERATOR_CURRENT_USER,
      setUsers: SET_OPERATOR_USERS
    }),
    addUser(user) {
      let selectedUser = null
      if (this.userCurrent) {
        selectedUser = document.getElementById(this.userCurrent.id)
      }
      if (selectedUser) {
        selectedUser.classList.remove('popup__item-user--active')
      }

      if (this.userCurrent && this.userCurrent.id === user.id) {
        this.userCurrent = null
      } else {
        this.userCurrent = user
        document.getElementById(this.userCurrent.id).classList.add('popup__item-user--active')
      }
    },
    searchData() {
      if (this.userCurrent) {
        document.getElementById(this.userCurrent.id).classList.remove('popup__item-user--active')
        this.userCurrent = null
      }
      const userFilterData = {
        user_email: this.filters.email,
        id: this.filters.user_id,
        user_deleted: -1,
        user_ban: false
      }
      this.applyFilters(userFilterData)
    },
    closePopup() {
      this.userCurrent = null
      this.filters.email = ''
      this.filters.user_id = ''
      this.setUsers([])
      this.close()
    },
    checkId() {},
    sendData() {
      let flag = true
      this.current.map(el => {
        if (el.id == this.userCurrent.id) {
          console.log(el.id + ' ' + this.userCurrent.id)
          return (flag = false)
        }
        //else {
        // this.different = false
        // setTimeout(() => {
        //   this.different = true
        // }, 3000)
        // }
      })
      if (flag) {
        this.setCurrentUser(this.userCurrent)
        this.closePopup()
      } else {
        this.different = false
        setTimeout(() => {
          this.different = true
        }, 2300)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup__item {
  margin-top: 10px;
}
.popup__input {
  margin-top: 5px;
}

.popup--add-user {
  width: 1000px;
  overflow: hidden;
}
.popup__filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.popup__filter-item {
  margin-right: 10px;
}

.popup__filter-btn {
  padding-top: 20px;
}

.popup__item-list {
  max-height: 500px;
  overflow-y: auto;

  font-size: 13px;
  background-color: #e7e8ec;
  justify-content: center;
  padding: 5px;
  &::-webkit-scrollbar {
    z-index: 0;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(232, 64, 63);
    border-radius: 10px;
  }
}

.popup__item-user {
  border: 1px solid white;
  background-color: white;
  margin-top: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &:first-child {
    margin-top: 0;
  }
  &--active {
    border-color: #1caadc;
  }
}
.popup__user-item {
  width: 25%;
}

.popup__user-text {
  color: inherit;
}
.attention-wrapp_content {
  display: flex;
  justify-content: center;
  border-radius: 4px;
  color: #ff5656;
  align-items: center;
  position: absolute;
  height: 50px;
  top: 90%;
  left: -4%;
  background-color: #ffffff;
  width: 70%;

  opacity: 0;
  transition: 0.5s;
  animation: show 2.5s 1;
  animation-fill-mode: backwards;
}
@keyframes show {
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  50% {
    opacity: 1;
    transform: translateX(30%);
  }
  100% {
    opacity: 0;
    transform: translateX(0);
  }
}

// .slide-fade-enter-active {
//   transition: all 2s ease;
//   opacity: 0;
// }
// .slide-fade-leave-active {
//   transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
//   opacity: 1;
// }
// .slide-fade-enter, .slide-fade-leave-to
// /* .slide-fade-leave-active до версии 2.1.8 */ {
//   transform: translateX(10px);
//   opacity: 0;
// }
</style>
