<template>
  <div class="modal__wrap">
    <template v-if="visible">
      <div class="modal__overlay" @click="close"></div>
      <template>
        <div class="modal">
          <slot name="default" v-if="visible"></slot>
          <span class="modal__close-btn" @click="close"></span>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import RootModalMixin from '@/components/Modals/mixins/RootModalMixin'
export default {
  name: 'ModalGenerator',
  mixins: [RootModalMixin]
}
</script>
