var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-spec__spec-block spec-block"},[_c('div',{staticClass:"spec-block__head"},[_c('div',{staticClass:"spec-block__head-img"},[(
          !_vm.currentEvent.event_image.banner.own ||
            _vm.currentEvent.event_image.banner.id === "60d5bde2baf18cdc0678e8e4.jpg"
        )?_c('img',{attrs:{"src":_vm.getFile({
            type: 'image',
            file: _vm.currentEvent.event_image.banner.id,
            width: _vm.currentEvent.event_image.banner.crop.width
              ? _vm.currentEvent.event_image.banner.crop.width
              : "",
            height: _vm.currentEvent.event_image.banner.crop.height
              ? _vm.currentEvent.event_image.banner.crop.height
              : ""
          }),"alt":""}}):_c('img',{attrs:{"src":_vm.getFile({
            type: 'image',
            file: _vm.currentEvent.event_image.banner.id,
            width: _vm.currentEvent.event_image.banner.crop.width
              ? _vm.currentEvent.event_image.banner.crop.width
              : "",
            height: _vm.currentEvent.event_image.banner.crop.height
              ? _vm.currentEvent.event_image.banner.crop.height
              : ""
          }),"alt":""}})])]),_c('div',{staticClass:"spec-block__body"},[_c('div',{staticClass:"spec-block__item"},[_c('span',{staticClass:"spec-block__key"},[_vm._v("Кто платит:")]),_c('span',{staticClass:"spec-block__val"},[_vm._v(_vm._s(_vm.currentEvent.event_payer === 1 ? 'Гость' : '-'))])]),_c('div',{staticClass:"spec-block__item"},[_c('span',{staticClass:"spec-block__key"},[_vm._v("Бюджет:")]),_c('span',{staticClass:"spec-block__val"},[_vm._v(_vm._s(_vm.currentEvent.event_budget.value)+" "+_vm._s(_vm.moneyIcon(_vm.currentEvent.event_budget.currency)))])]),_c('div',{staticClass:"spec-block__item"},[_c('span',{staticClass:"spec-block__key"},[_vm._v("Количество откликнувшихся:")]),_c('span',{staticClass:"spec-block__val"},[_vm._v(_vm._s(_vm.currentEvent.event_count.wish))])]),(_vm.currentEvent.event_count.wish)?_c('div',[_vm._m(0),_c('div',{staticClass:"spec-block__item"},[_c('span',{staticClass:"spec-block__key"},[_vm._v("ID гостя:")]),_c('span',{staticClass:"spec-block__val"},[_vm._v(_vm._s("-"))])]),_c('div',{staticClass:"spec-block__item"},[_c('span',{staticClass:"spec-block__key"},[_vm._v("Имя:")]),_c('span',{staticClass:"spec-block__val"},[_vm._v(_vm._s("-"))])]),_c('div',{staticClass:"spec-block__item"},[_c('span',{staticClass:"spec-block__key"},[_vm._v("Возраст:")]),_c('span',{staticClass:"spec-block__val"},[_vm._v(_vm._s("-"))])]),_c('div',{staticClass:"spec-block__item"},[_c('span',{staticClass:"spec-block__key"},[_vm._v("Email:")]),_c('span',{staticClass:"spec-block__val"},[_vm._v(_vm._s("-"))])])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spec-block__item"},[_c('span',{staticClass:"spec-block__key"},[_vm._v("Гость")]),_c('span',{staticClass:"spec-block__val"})])}]

export { render, staticRenderFns }