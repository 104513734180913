<template>
  <Popup :visible="visible" @close="closePopup" description="Добавить VIP-статус" modalClass="popup--small">
    <form @submit.prevent="addDayHandler">
      <div class="popup__item">
        <span class="popup__item-title">Количество дней</span>
        <input type="text" class="popup__input default-input" ref="dayvalue" v-model="dayValue" required />
      </div>
      <div class="popup__item popup__item--btn">
        <div class="popup__btns popup__btns--right">
          <button v-if="vip" @click.stop.prevent="delVip" class="popup__btn--left btn-red">
            Удалить VIP
          </button>
          <button type="button" class="sl-btn" v-tooltip="'Для своих'">
            <input id="sl-btn-input" class="sl-btn__input" type="checkbox" v-model="privat" />
            <label for="sl-btn-input" class="sl-btn__label"></label>
          </button>
          <button type="submit" class="popup__btn btn-green">Добавить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_VIP_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_UPDATE_VIP, REQUEST_DELETE_VIP } from '@/store/action-types'

export default {
  name: 'UserVipPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    id: '',
    day: '',
    vip: false,
    privat: false
  }),

  computed: {
    dayValue: {
      get() {
        return this.day
      },

      set(n) {
        n = n.replace(/\D/g, '')
        this.$refs.dayvalue.value = n
        this.day = n
      }
    }
  },

  methods: {
    ...mapActions({
      updateVip: REQUEST_UPDATE_VIP,
      deleteVip: REQUEST_DELETE_VIP
    }),
    closePopup() {
      this.id = ''
      this.day = ''
      this.privat = false
      this.vip = false
      this.close()
    },
    addDayHandler() {
      const days = parseInt(this.day * 86400)
      const payload = {
        user_id: this.id,
        expire: parseInt(+new Date() / 1000 + days),
        entrance: !this.privat
      }
      this.updateVip(payload)
      this.id = ''
      this.day = ''
      this.privat = false
      this.closePopup()
    },
    delVip() {
      const payload = {
        user_id: this.id
      }
      this.deleteVip(payload)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(USER_VIP_EVENT, user => {
      this.open()
      this.vip = user.vip.enabled
      this.id = user.id
    })
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/vars.scss';
.popup__item--btn {
  flex-direction: row;
}
.popup__btn--left {
  margin-right: auto;
  padding: 4px;
  min-width: 80px;
  height: 34px;
  border-radius: 4px;
}
.sl-btn {
  padding: 0;
  right: 110px;
  margin-top: 2px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  position: absolute;
  &__label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../assets/img/sl.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid $white;
    border-radius: 50%;
    cursor: pointer;
  }
  &__input {
    display: none;
    &:checked {
      & ~ .sl-btn__label {
        border: 2px solid $blue;
      }
    }
  }
}
</style>
