<template>
  <Popup
    :visible="visible"
    @close="closeModal"
    description="Новая стоимость подарков"
    modalClass="popup--medium"
  >
    <form @submit.prevent="addNewGiftEventHandler" class="modal-update__question">
      <div class="form-group">
        <label for="name" class="popup__item-title"
          >Название валюты: <span class="form-group__name">$</span>
          <span class="form-group__name">€</span>
          <span class="form-group__name">₽</span></label
        >
        <input
          type="text"
          id="name"
          v-model="newGiftEvent.name"
          name="name"
          class="popup__input default-input"
          required
        />
      </div>
      <div class="form-group">
        <label for="currency" class="popup__item-title">Нумерация валюты</label>
        <input
          type="number"
          id="currency"
          v-model.number="newGiftEvent.currency"
          name="currency"
          class="popup__input default-input"
          :class="{ 'input__currency--red-border': requireInput }"
          @change="changeCurrencyClass"
          required
        />
      </div>
      <div class="form-group">
        <label for="percent" class="popup__item-title">Коммисия</label>
        <input
          type="number"
          id="percent"
          name="percent"
          class="popup__input default-input"
          v-model.number="newGiftEvent.percent"
          required
        />
      </div>

      <div class="form-group form-group--flex">
        <label
          for="with_time"
          class="checkbox popup__item-title"
          :class="{ 'checkbox--active': newGiftEvent.with_time }"
          >Стоимость {{ getCurrentWithTime }} двух часов</label
        >
        <input
          type="checkbox"
          name="with_time"
          id="with_time"
          class="popup__input default-input"
          v-model="newGiftEvent.with_time"
          hidden
        />
      </div>
      <div class="form-group">
        <span class="popup__item-title">Значение цены</span>
        <ul>
          <li>
            <div class="form-group__item" v-for="(deposit, index) in deposits" :key="index">
              <input
                type="number"
                class="input_values popup__input default-input"
                v-model="deposits[index]"
                required
              />
              <button type="button" class="form-group__button" @click="deleteDepositHandler(index)">
                <i class="fa fa-times-circle"></i>
              </button>
            </div>
          </li>
        </ul>
        <button type="button" @click="createDeposit" class="button button--add-input" title="Добавить цену">
          <i class="fa fa-plus-square"></i>
        </button>
      </div>

      <div class="form-group">
        <span class="popup__item-title">Значение локализации</span>
        <ul>
          <li>
            <div class="form-group__item" v-for="(locale, index) in locales" :key="index">
              <input
                type="text"
                class="input_values popup__input default-input popup__input--locale"
                v-model="locales[index]"
                maxlength="2"
                required
              />
              <button type="button" class="form-group__button" @click="deleteLocaleHandler(index)">
                <i class="fa fa-times-circle"></i>
              </button>
            </div>
          </li>
        </ul>

        <button type="button" @click="createLocale" class="button button--add-input" title="Добавить страну">
          <i class="fa fa-plus-square"></i>
        </button>
      </div>
      <div class="popup-footer">
        <button class="button button--add">Добавить</button>
        <button type="button" @click="closeModal" class="button button--back">
          Отмена
        </button>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapActions } from 'vuex'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { GIFTS_ENEVT_ADD_NEW_EVENT } from '@/constants/event'

import { REQUEST_NEW_GIFTS_EVENTS } from '@/store/action-types'

export default {
  name: 'GiftsEventPopupAddNew',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data() {
    return {
      deposits: [''],
      locales: [''],
      newGiftEvent: {
        with_time: false
      },
      requireInput: false
    }
  },

  computed: {
    getCurrentWithTime() {
      return this.newGiftEvent.with_time ? 'после' : 'до'
    }
  },

  mounted() {
    this.$root.$on(GIFTS_ENEVT_ADD_NEW_EVENT, () => {
      this.open()
    })
  },

  methods: {
    closeModal() {
      this.newGiftEvent = {}
      this.deposits = ['']
      this.locales = ['']
      this.requireInput = false
      this.close()
    },

    changeCurrencyClass() {
      this.requireInput = false
    },

    ...mapActions({ createNewGiftEvent: REQUEST_NEW_GIFTS_EVENTS }),

    addNewGiftEventHandler() {
      let newGiftEvent = {
        name: this.newGiftEvent.name,
        currency: +this.newGiftEvent.currency,
        percent: +this.newGiftEvent.percent,
        with_time: this.newGiftEvent.with_time,
        deposit_values: this.deposits.map(Number),
        locales: this.locales.map(function(e) {
          return e.toUpperCase()
        })
      }

      if (newGiftEvent.currency || newGiftEvent.currency === 0) {
        this.createNewGiftEvent(newGiftEvent)
        this.closeModal()
      } else {
        this.requireInput = true
        newGiftEvent = {}
      }
    },

    deleteDepositHandler(id) {
      if (this.deposits.length > 1) {
        this.deposits.splice(id, 1)
      } else {
        this.deposits.splice(id, 1)
        this.deposits[0] = ''
      }
    },
    createDeposit() {
      this.deposits.push('')
    },

    deleteLocaleHandler(id) {
      if (this.locales.length > 1) {
        this.locales.splice(id, 1)
      } else {
        this.locales.splice(id, 1)
        this.locales[0] = ''
      }
    },
    createLocale() {
      this.locales.push('')
    }
  }
}
</script>

<style lang="scss">
@import './FaqPopupStyle';

.input_values {
  margin-bottom: 10px;

  max-width: 351px;
  display: block;
}

.form-group__item {
  position: relative;
}

.form-group__button {
  position: absolute;

  right: -32px;
  top: 5px;
}

.input-currency__text {
  margin-bottom: 10px;
  color: red;
}

.input__currency--red-border {
  border: 1px solid red;
}

.button--add-input {
  color: #6dbb4a;

  font-size: 22px;
  padding: 0;
}

.form-group__name {
  margin-left: 10px;
}
.popup__input--locale {
  text-transform: uppercase;
}
</style>
