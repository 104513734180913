//  3.2 Валюта (Currency)

export default [
  {
    currency: 0,
    name: 'USD',
    description: 'Доллар США',
    sign: '$'
  },
  {
    currency: 1,
    name: 'EUR',
    description: 'Евро',
    sign: '€'
  },
  {
    currency: 2,
    name: 'RUB',
    description: 'Российский рубль',
    sign: '₽'
  },
  {
    currency: 3,
    name: 'GBP',
    description: 'Фунт стерлингов',
    sign: '£'
  },
  {
    currency: 4,
    name: 'INR',
    description: 'Индийская рупия',
    sign: '₹'
  },
  {
    currency: 5,
    name: 'CHF',
    description: 'Швейцарский франк',
    sign: '₣'
  },
  {
    currency: 6,
    name: 'JPY',
    description: 'Японская йена',
    sign: '¥'
  },
  {
    currency: 7,
    name: 'PLN',
    description: 'Польский злотый',
    sign: 'Zł'
  },
  {
    currency: 8,
    name: 'NGN',
    description: 'Нигерийская найра',
    sign: '₦'
  },
  {
    currency: 9,
    name: 'AZN',
    description: 'Азербайджанскйи манат',
    sign: '₼'
  },
  {
    currency: 10,
    name: 'AUD',
    description: 'Австралийский доллар',
    sign: '$'
  },
  {
    currency: 11,
    name: 'ALL',
    description: 'Лек',
    sign: 'L'
  },
  {
    currency: 12,
    name: 'DZD',
    description: 'Алжирский динар',
    sign: 'DA'
  },
  {
    currency: 13,
    name: 'USD',
    description: 'Американское Самоа',
    sign: '$'
  },
  {
    currency: 14,
    name: 'XCD',
    description: 'Восточно-карибский доллар',
    sign: '$'
  },
  {
    currency: 15,
    name: 'EUR',
    description: 'Андора',
    sign: '€'
  },
  {
    currency: 16,
    name: 'AOA',
    description: 'Кванза',
    sign: 'Kz'
  },
  {
    currency: 17,
    name: 'ARS',
    description: 'Аргентинское песо',
    sign: '$'
  },
  {
    currency: 18,
    name: 'AWG',
    description: 'Арубанский флорин',
    sign: 'ƒ'
  },
  {
    currency: 19,
    name: 'AMD',
    description: 'Армянский драм',
    sign: '֏'
  },
  {
    currency: 20,
    name: 'AFN',
    description: 'Афгани',
    sign: 'Af'
  },
  {
    currency: 21,
    name: 'BSD',
    description: 'Багамский доллар',
    sign: '$'
  },
  {
    currency: 22,
    name: 'BDT',
    description: 'Така',
    sign: '৳'
  },
  {
    currency: 23,
    name: 'BBD',
    description: 'Барбадосский доллар',
    sign: '$'
  },
  {
    currency: 24,
    name: 'BHD',
    description: 'Бархейский динар',
    sign: 'BD'
  },
  {
    currency: 25,
    name: 'BZD',
    description: 'Белизский доллар',
    sign: '$'
  },
  {
    currency: 26,
    name: 'BYN',
    description: 'Белорусский рубль',
    sign: 'Br'
  },
  {
    currency: 27,
    name: 'XOF',
    description: 'Франк',
    sign: '₣'
  },
  {
    currency: 28,
    name: 'BMD',
    description: 'Бермудский доллар',
    sign: '$'
  },
  {
    currency: 29,
    name: 'BGN',
    description: 'Болгарский лев',
    sign: 'лв'
  },
  {
    currency: 30,
    name: 'BOB',
    description: 'Боливиано',
    sign: '$'
  },
  {
    currency: 31,
    name: 'BWP',
    description: 'Пула',
    sign: 'P'
  },
  {
    currency: 32,
    name: 'BRL',
    description: 'Бразильский реал',
    sign: '$'
  },
  {
    currency: 33,
    name: 'BND',
    description: 'Брунейский доллар',
    sign: '$'
  },
  {
    currency: 34,
    name: 'BIF',
    description: 'Бурундийский франк',
    sign: '₣'
  },
  {
    currency: 35,
    name: 'BTN',
    description: 'Нгултрум',
    sign: 'Nu'
  },
  {
    currency: 36,
    name: 'VUV',
    description: 'Вату',
    sign: 'Vt'
  },
  {
    currency: 37,
    name: 'HUF',
    description: 'Форинт',
    sign: 'Ft'
  },
  {
    currency: 38,
    name: 'VES',
    description: 'Суверенный боливар',
    sign: 'Bs'
  },
  {
    currency: 39,
    name: 'VND',
    description: 'Донг',
    sign: '₫'
  },
  {
    currency: 40,
    name: 'XAF',
    description: 'Франк',
    sign: '₣'
  },
  {
    currency: 41,
    name: 'HTG',
    description: 'Гурд',
    sign: 'G'
  },
  {
    currency: 42,
    name: 'GYD',
    description: 'Гайанский доллар',
    sign: '$'
  },
  {
    currency: 43,
    name: 'GMD',
    description: 'Даласи',
    sign: 'D'
  },
  {
    currency: 44,
    name: 'GHS',
    description: 'Ганский седи',
    sign: '₵'
  },
  {
    currency: 45,
    name: 'GTQ',
    description: 'Кетсаль',
    sign: 'Q'
  },
  {
    currency: 46,
    name: 'GNF',
    description: 'Гвинейский франк',
    sign: '₣'
  },
  {
    currency: 47,
    name: 'GIP',
    description: 'Гибралтарский фунт',
    sign: '£'
  },
  {
    currency: 48,
    name: 'HNL',
    description: 'Лемпира',
    sign: 'L'
  },
  {
    currency: 49,
    name: 'HKD',
    description: 'Гонконский доллар',
    sign: '$'
  },
  {
    currency: 50,
    name: 'DKK',
    description: 'Крона',
    sign: 'kr'
  },
  {
    currency: 51,
    name: 'GEL',
    description: 'Лари',
    sign: '₾'
  },
  {
    currency: 52,
    name: 'DJF',
    description: 'Франк Джибути',
    sign: '₣'
  },
  {
    currency: 53,
    name: 'DOP',
    description: 'Доминиканское песо',
    sign: '$'
  },
  {
    currency: 54,
    name: 'EGP',
    description: 'Египетский фунт',
    sign: '£'
  },
  {
    currency: 55,
    name: 'ZMW',
    description: 'Замбийская квача',
    sign: 'K'
  },
  {
    currency: 56,
    name: 'MAD',
    description: 'Марокканский дирхам',
    sign: 'Dh'
  },
  {
    currency: 57,
    name: 'ZWL',
    description: 'Доллар Зимбабве',
    sign: 'Z$'
  },
  {
    currency: 58,
    name: 'ILS',
    description: 'Шекель',
    sign: '₪'
  },
  {
    currency: 59,
    name: 'IDR',
    description: 'Индонезийская рупия',
    sign: 'Rp'
  },
  {
    currency: 60,
    name: 'JOD',
    description: 'Иорданский динар',
    sign: 'JD'
  },
  {
    currency: 61,
    name: 'IQD',
    description: 'Иракский динар',
    sign: '$'
  },
  {
    currency: 62,
    name: 'IRR',
    description: 'Иракский реал',
    sign: 'IR'
  },
  {
    currency: 63,
    name: 'ISK',
    description: 'Исландская крона',
    sign: 'kr'
  },
  {
    currency: 64,
    name: 'YER',
    description: 'Йеменский риал',
    sign: 'YR'
  },
  {
    currency: 65,
    name: 'CVE',
    description: 'Эскудо',
    sign: '$'
  },
  {
    currency: 66,
    name: 'KZT',
    description: 'Тенге',
    sign: '₸'
  },
  {
    currency: 67,
    name: 'KYD',
    description: 'Кайманский доллар',
    sign: '$'
  },
  {
    currency: 68,
    name: 'KHR',
    description: 'Риель',
    sign: '៛'
  },
  {
    currency: 69,
    name: 'CAD',
    description: 'Канадский доллар',
    sign: '$'
  },
  {
    currency: 70,
    name: 'QAR',
    description: 'Катарский риал',
    sign: 'QR'
  },
  {
    currency: 71,
    name: 'KES',
    description: 'Шиллинг',
    sign: 'KSh'
  },
  {
    currency: 72,
    name: 'KGS',
    description: 'Сом',
    sign: 'с'
  },
  {
    currency: 73,
    name: 'CNY',
    description: 'Юань',
    sign: '¥'
  },
  {
    currency: 74,
    name: 'COP',
    description: 'Колумбийское песо',
    sign: '$'
  },
  {
    currency: 75,
    name: 'KMF',
    description: 'Франк Коморы',
    sign: '₣'
  },
  {
    currency: 76,
    name: 'CDF',
    description: 'Конголезский франк',
    sign: '₣'
  },
  {
    currency: 77,
    name: 'KPW',
    description: 'Вона',
    sign: '₩'
  },
  {
    currency: 78,
    name: 'CRC',
    description: 'Коста-риканский колон',
    sign: '₡'
  },
  {
    currency: 79,
    name: 'CUP',
    description: 'Кубинское песо',
    sign: '$'
  },
  {
    currency: 80,
    name: 'KWD',
    description: 'Кувейтский динар',
    sign: 'KD'
  },
  {
    currency: 81,
    name: 'ANG',
    description: 'Гульден',
    sign: 'ƒ'
  },
  {
    currency: 82,
    name: 'LAK',
    description: 'Кип',
    sign: '₭'
  },
  {
    currency: 83,
    name: 'LSL',
    description: 'Лоти',
    sign: 'L'
  },
  {
    currency: 84,
    name: 'LRD',
    description: 'Либерийский доллар',
    sign: '$'
  },
  {
    currency: 85,
    name: 'LBP',
    description: 'Ливанский фунт',
    sign: '.ل.ل'
  },
  {
    currency: 86,
    name: 'LYD',
    description: 'Ливийский динар',
    sign: 'LD'
  },
  {
    currency: 87,
    name: 'MUR',
    description: 'Маврикийская рупия',
    sign: 'Re'
  },
  {
    currency: 88,
    name: 'MRU',
    description: 'Угия',
    sign: 'UM'
  },
  {
    currency: 89,
    name: 'MGA',
    description: 'Ариари',
    sign: 'Ar.'
  },
  {
    currency: 90,
    name: 'MOP',
    description: 'Патака',
    sign: '$'
  },
  {
    currency: 91,
    name: 'MKD',
    description: 'Денар',
    sign: 'ден.'
  },
  {
    currency: 92,
    name: 'MWK',
    description: 'Квача',
    sign: 'K'
  },
  {
    currency: 93,
    name: 'CZK',
    description: 'Чешская крона',
    sign: 'Kč'
  },
  {
    currency: 94,
    name: 'UAH',
    description: 'Украинская гривна',
    sign: '₴'
  },
  {
    currency: 95,
    name: 'AED',
    description: 'Дирха́м ОАЭ',
    sign: 'Dhs'
  }
]
