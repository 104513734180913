import { country } from '@/constants/country'
import { countryUser } from '@/constants/country'
export default {
  users: [],
  userDeposit: [],
  userPayouts: [],
  userPurchases: [],
  userTransaction: {},
  userChatsSyles: {
    isOpened: false
  },
  userPhotos: [],
  userChats: [],
  userChatMessages: [],
  currentFilter: { user_ban: false, user_deleted: -1 },
  userCount: 0,
  userFilterCount: 0,
  searchFilters: {
    page: 1
  },

  userGender: [
    {
      name: 'Неопределенно',
      value: 0,
      icon: 'fa',
      class: 'question',
      id: 0
    },
    {
      name: 'Мужской',
      value: 1,
      icon: 'fa-mars',
      class: 'male',
      id: 1
    },
    {
      name: 'Женский',
      value: 2,
      icon: 'fa-venus',
      class: 'female',
      id: 2
    }
  ],
  currentUser: {},

  tmpDateFilter: {
    register: {},
    enter: {},
    purchase_date: {},
    traffic: {}
  },
  tmpUserFilters: {
    gender: {},
    genderUser: {},
    userIncognito: {},
    userDeleted: {
      key: 'userDeleted',
      name: 'нет',
      value: -1,
      id: '0'
    },
    os: {},
    operator: {},
    vip: {},
    online: {},
    utm: {},
    ban: {
      key: 'ban',
      name: 'Нет',
      value: false,
      id: 5
    },
    verificate: {},
    sort: {},
    autodating: {},
    push: {},
    confidential: {},
    country: {},
    countryUser: {},
    chatWithOperator: {},
    statusPhoto: {}
  },
  filterUsersDefault: {
    userIncognito: {
      name: 'Неважно',
      value: 'Неважно',
      id: 'Неважно'
    },
    chatWithOperator: {
      name: 'Неважно',
      value: -1,
      id: 0
    },
    userDeleted: {
      name: 'нет',
      value: -1,
      id: '0'
    },
    gender: {
      name: 'Неважно',
      value: 0,
      id: 0
    },

    statusPhoto: {
      name: 'Неважно',
      value: '',
      id: '',
      icon: ''
    },

    genderUser: {
      name: 'Неважно',
      value: 0,
      id: 0
    },

    operator: {
      name: 'Неважно',
      value: 0,
      id: 0
    },
    os: {
      name: 'Неважно',
      value: 0,
      id: 1
    },

    country: {
      name: 'Неважно',
      value: 0,
      id: 1
    },

    countryUser: {
      name: 'Неважно',
      value: 0,
      id: 1
    },

    vip: {
      name: 'Неважно',
      value: 0,
      id: 2
    },

    online: {
      name: 'Неважно',
      value: 0,
      id: 0
    },

    utm: {
      name: 'Неважно',
      value: 0,
      id: 4
    },

    ban: {
      name: 'Нет',
      value: false,
      id: 5
    },

    verificate: {
      name: 'Неважно',
      value: 0,
      id: 6
    },

    sort: {
      name: 'Неважно',
      value: 0,
      id: 99
    },
    autodating: {
      name: 'Неважно',
      value: 0,
      id: 0
    },
    confidential: {
      name: 'Неважно',
      value: 0,
      id: 0
    },
    push: {
      name: 'Неважно',
      value: 0,
      id: 0
    }
  },
  filtersUserInfo: {
    userIncognito: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Да',
        value: 1,
        id: 1
      }
    ],
    userDeleted: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Нет',
        value: -1,
        id: 1
      },
      {
        name: 'Да',
        value: `0-12`,
        id: 2
      }
    ],
    chatWithOperator: [
      {
        name: 'Неважно',
        value: -1,
        id: 0
      },
      {
        name: 'Да',
        value: 1,
        id: 1
      },
      {
        name: 'Нет',
        value: 0,
        id: 2
      }
    ],
    gender: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Мужской',
        value: 1,
        id: 1
      },
      {
        name: 'Женский',
        value: 2,
        id: 2
      }
    ],

    genderUser: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Мужской',
        value: 1,
        id: 1
      },
      {
        name: 'Женский',
        value: 2,
        id: 2
      }
    ],

    operator: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Да',
        value: 1,
        id: 1
      },
      {
        name: 'Нет',
        value: 0,
        id: 2
      }
    ],
    os: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Android',
        value: 'Android',
        id: 1
      },
      {
        name: 'IOS',
        value: 'IOS',
        id: 2
      },
      {
        name: 'Сайт',
        value: 'web site',
        id: 3
      },
      {
        name: 'Эмулятор',
        value: 'Emulator',
        id: 4
      }
    ],
    vip: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Да',
        value: true,
        id: 1
      },
      {
        name: 'Нет',
        value: false,
        id: 2
      }
    ],
    statusPhoto: [
      {
        name: 'Неважно',
        value: '',
        id: ''
      },
      {
        name: 'Ожидает модерации',
        value: 0,
        id: 0,
        icon: 'fa-solid fa-stopwatch'
      },
      {
        name: 'Отложено',
        value: -1,
        id: 1,
        icon: 'fa fa-clock'
      },
      {
        name: 'Одобрено',
        value: 1,
        id: 2,
        icon: 'fa fa-user-check'
      },
      {
        name: 'Запрещено для аватара',
        value: 2,
        id: 3,
        icon: 'fa fa-user-times'
      },
      {
        name: 'Запрещено для показа',
        value: 3,
        id: 4,
        icon: 'fa fa-user-slash'
      },
      {
        name: 'Временная блокировка',
        value: 4,
        id: 5,
        icon: 'fa fa-exclamation-triangle'
      },
      {
        name: 'Верификация по возрасту',
        value: 6,
        id: 6,
        icon: 'fa-solid fa-baby'
      }
    ],
    online: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Оффлайн',
        value: 0,
        id: 0
      },
      {
        name: 'Онлайн',
        value: 1,
        id: 1
      },
      {
        name: 'Был недавно',
        value: 2,
        id: 2
      }
    ],
    utm: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Organic',
        value: 'Organic',
        id: '1'
      }
    ],
    ban: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Да',
        value: true,
        id: 1
      },
      {
        name: 'Нет',
        value: false,
        id: 2
      }
    ],
    verificate: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Да',
        value: true,
        id: 1
      },
      {
        name: 'Нет',
        value: false,
        id: 2
      }
    ],
    sort: [
      {
        name: 'Баллы по убыванию',
        value: 'user_finance.points:desc',
        id: 1
      },
      {
        name: 'Баллы по возрастанию',
        value: 'user_finance.points:asc',
        id: 2
      },
      {
        name: 'Чаты по убыванию',
        value: 'user_count.chat:desc',
        id: 3
      },
      {
        name: 'Чаты по возрастанию',
        value: 'user_count.chat:asc',
        id: 4
      },
      {
        name: 'События по убыванию',
        value: 'user_count.event:desc',
        id: 5
      },
      {
        name: 'События по возрастанию',
        value: 'user_count.event:asc',
        id: 6
      },
      {
        name: 'Регистрация по убыванию',
        value: 'user_create:desc',
        id: 7
      },
      {
        name: 'Регистрация по возрастанию',
        value: 'user_create:asc',
        id: 8
      },
      {
        name: 'Дата входа по убыванию',
        value: 'user_online:desc',
        id: 9
      },
      {
        name: 'Дата входа по возрастанию',
        value: 'user_online:asc',
        id: 10
      },
      {
        name: 'Подарки по возрастанию',
        value: 'user_count.gift:asc',
        id: 11
      },
      {
        name: 'Подарки по убыванию',
        value: 'user_count.gift:desc',
        id: 12
      },
      {
        name: 'Покупки по возрастанию',
        value: 'purchases.sum.USD:asc',
        id: 13
      },
      {
        name: 'Покупки по убыванию',
        value: 'purchases.sum.USD:desc',
        id: 14
      }
    ],
    autodating: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Да',
        value: true,
        id: 1
      },
      {
        name: 'Нет',
        value: false,
        id: 2
      }
    ],
    confidential: [
      {
        name: 'Определять местоположение вкл',
        value: 'locate:on',
        id: 1
      },
      {
        name: 'Определять местоположение выкл',
        value: 'locate:off',
        id: 2
      },
      {
        name: 'Видимость на карте вкл',
        value: 'map_visible:on',
        id: 3
      },
      {
        name: 'Видимость на карте выкл',
        value: 'map_visible:off',
        id: 4
      },
      {
        name: 'От кого подарок вкл',
        value: 'gifts_visible:on',
        id: 5
      },
      {
        name: 'От кого подарок выкл',
        value: 'gifts_visible:off',
        id: 6
      },
      {
        name: 'Просмотр анкет инкогнито вкл',
        value: 'incognito:on',
        id: 7
      },
      {
        name: 'Просмотр анкет инкогнито выкл',
        value: 'incognito:off',
        id: 8
      },
      {
        name: 'VIP не видят меня на карте вкл',
        value: 'vip_map_invisible:on',
        id: 9
      },
      {
        name: 'VIP не видят меня на карте выкл',
        value: 'vip_map_invisible:off',
        id: 10
      },
      {
        name: 'Сообщения только от VIP вкл',
        value: 'message_from_vip:on',
        id: 11
      },
      {
        name: 'Сообщения только от VIP выкл',
        value: 'message_from_vip:off',
        id: 12
      },
      {
        name: 'Cообщения только от верифицированных вкл',
        value: 'message_from_verified:on',
        id: 13
      },
      {
        name: 'Cообщения только от верифицированных выкл',
        value: 'message_from_verified:off',
        id: 14
      },
      {
        name: 'Принимать звонки только от VIP вкл',
        value: 'incoming_call:on',
        id: 15
      },
      {
        name: 'Принимать звонки только от VIP выкл',
        value: 'incoming_call:off',
        id: 16
      }
    ],
    push: [
      {
        name: 'Твои совпадения вкл',
        value: 'match:on',
        id: 1
      },
      {
        name: 'Твои совпадения выкл',
        value: 'match:off',
        id: 2
      },
      {
        name: 'Сообщения в чате вкл',
        value: 'message:on',
        id: 3
      },
      {
        name: 'Сообщения в чате выкл',
        value: 'message:off',
        id: 4
      },
      {
        name: 'Вы понравились вкл',
        value: 'like:on',
        id: 5
      },
      {
        name: 'Вы понравились выкл',
        value: 'like:off',
        id: 6
      },
      {
        name: 'Просмотры вкл',
        value: 'watch:on',
        id: 7
      },
      {
        name: 'Просмотры выкл',
        value: 'watch:off',
        id: 8
      },
      {
        name: 'Подарки вкл',
        value: 'gift:on',
        id: 9
      },
      {
        name: 'Подарки выкл',
        value: 'gift:off',
        id: 10
      },
      {
        name: 'Достижения вкл',
        value: 'achieve:on',
        id: 11
      },
      {
        name: 'Достижения выкл',
        value: 'achieve:off',
        id: 12
      },
      {
        name: 'Ваши симпатии онлайн вкл',
        value: 'like_online:on',
        id: 13
      },
      {
        name: 'Ваши симпатии онлайн выкл',
        value: 'like_online:off',
        id: 14
      },
      {
        name: 'Избранный пользователь онлайн вкл',
        value: 'favorite_online:on',
        id: 15
      },
      {
        name: 'Избранный пользователь онлайн выкл',
        value: 'favorite_online:off',
        id: 16
      }
    ],

    country,
    countryUser
  }
}
