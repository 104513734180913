<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Рассчитать всех операторов?"
    modalClass="popup--small"
  >
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="billHandler()">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_BILLING_OPERATORS } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { BILL_ALL_OPERATOR_POPUP_EVENT } from '@/constants/event'
import { GET_OPERATOR_TMP_ROLES } from '@/store/getter-types'

export default {
  name: 'BillAllOperatorsPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    BILL_ALL_OPERATOR_POPUP_EVENT
  }),
  computed: {
    ...mapGetters({
      tmpFilter: GET_OPERATOR_TMP_ROLES
    })
  },

  methods: {
    ...mapActions({
      billOperator: REQUEST_BILLING_OPERATORS
    }),
    closePopup() {
      this.close()
    },
    billHandler() {
      let formData = {
        active: null
      }
      if (this.tmpFilter.status.name && this.tmpFilter.status.name !== 'Все') {
        formData.active = {
          is_active: this.tmpFilter.status.value
        }
      }
      this.billOperator(formData)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(BILL_ALL_OPERATOR_POPUP_EVENT, () => {
      this.open()
    })
  }
}
</script>
