<template>
  <div class="table-header-wrapper-oper">
    <ul class="table-header table-event-header">
      <li class="table-header__item-event">
        <span class="table-header__item-text table-header__item-text--info">Информация об операторе</span>
      </li>
      <li class="table-header__item-event">
        <span class="table-header__item-text">Количество аккаунтов</span>
      </li>
      <li class="table-header__item-event">
        <span class="table-header__item-text">Аккаунт в смене</span>
      </li>
      <li class="table-header__item-event">
        <span class="table-header__item-text">Все языки</span>
      </li>
      <li class="table-header__item-event">
        <span class="table-header__item-text">Валюта</span>
      </li>
      <li class="table-header__item-event">
        <span class="table-header__item-text">Статус</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'OperatorsTabletHeader'
}
</script>
<style lang="scss">
@import 'Operators';
</style>
