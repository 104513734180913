<template>
  <Popup :visible="visible" @close="close" description="Удалить UTM метку?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteMarketUtm">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { MARKET_UTM_DELETE_POPUP } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_DELETE_MARKET_UTM } from '@/store/action-types'

export default {
  name: 'MarketUtmDeletePopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data() {
    return {
      id: ''
    }
  },
  methods: {
    ...mapActions({
      deleteUTM: REQUEST_DELETE_MARKET_UTM
    }),
    deleteMarketUtm() {
      this.deleteUTM(this.id)
      this.id = ''
      this.close()
    }
  },

  mounted() {
    this.$root.$on(MARKET_UTM_DELETE_POPUP, utm => {
      this.open()
      this.id = utm
    })
  }
}
</script>
