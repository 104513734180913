import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import DepartmentService from '@/services/DepartmentService'

export default {
  [ActionTypes.REQUEST_CONTROL_DEPARTMENTS]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(DepartmentService, [userToken]).getDepartments()
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_DEPARTMENTS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_DEPARTMENTS_BY_FILTER]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(DepartmentService, [userToken]).getDepartmentByFilter(formData)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_DEPARTMENTS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CREATE_DEPARTMENT]: async ({ rootGetters, commit, dispatch }, department) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(DepartmentService, [userToken]).createDepartment(department)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_CONTROL_DEPARTMENTS)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_DELETE_DEPARTMENT]: async ({ rootGetters, commit, dispatch }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(DepartmentService, [userToken]).deleteDepartmentById(id)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_CONTROL_DEPARTMENTS)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_UPDATE_DEPARTMENT]: async ({ rootGetters, commit, dispatch }, updatedepartment) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(DepartmentService, [userToken]).updateDepartment(
      updatedepartment
    )
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_CONTROL_DEPARTMENTS)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
