<template>
  <Popup
    :visible="visible"
    @close="closeModal"
    description="Редактирование категории"
    modalClass="popup--medium"
  >
    <form @submit.prevent="updateCategory">
      <div class="form-group">
        <label for="content" class="popup__item-title">Контент</label>
        <input
          type="text"
          v-model="newCategoryData.title"
          class="popup__input default-input"
          id="content"
          required
        />
      </div>

      <div class="form-group">
        <a for="icon" class="icon-select popup__item-title"> Выбрать иконку </a>

        <span v-show="currentIcon">
          <i :class="getCurrentIconModal"></i>
        </span>

        <ul class="icon-select-list">
          <li
            v-for="(icon, index) in listIcons"
            :key="index"
            class="icon-select-list__item"
            :class="{
              'icon-select-list__item--active': index === currentIndexIco
            }"
            @click="changeIconHandler(icon, index)"
          >
            <i :class="icon"></i>
          </li>
        </ul>
      </div>

      <div class="popup-footer">
        <button class="button button--add">Изменить</button>
        <button type="button" @click="closeModal" class="button button--back">
          Отмена
        </button>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { FAQ_POPUP_UPDATE_CATEGORY_EVENT } from '@/constants/event'
import { GET_CURRENT_LANGUAGES } from '@/store/getter-types'
import { REQUEST_UPDATE_CATEGORY_BY_ID } from '@/store/action-types'

export default {
  name: 'FaqPopupUpdateCategory',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data() {
    return {
      newCategoryData: {},
      currentIcon: null,
      currentIndexIco: null,
      isIconSelect: false
    }
  },

  props: {
    allLang: {
      type: Array
    },

    listIcons: {
      type: Array
    }
  },

  computed: {
    ...mapGetters({
      lang: GET_CURRENT_LANGUAGES
    }),
    getCurrentIconModal() {
      return this.currentIcon
    }
  },

  mounted() {
    this.$root.$on(FAQ_POPUP_UPDATE_CATEGORY_EVENT, category => {
      this.newCategoryData.title = category.title
      this.currentCategoryId = category.id
      this.newCategoryData.icon = category.icon
      this.open()
    })
  },

  methods: {
    closeModal() {
      this.newCategoryData = {}
      this.currentIcon = null
      this.currentIndexIco = null
      this.isIconSelect = null
      this.close()
    },

    changeIconHandler(icon, index) {
      this.currentIndexIco = index
      this.currentIcon = icon
      this.isIconSelect = false
    },

    ...mapActions({ updateCategoryId: REQUEST_UPDATE_CATEGORY_BY_ID }),

    updateCategory() {
      const newCategoryData = {
        id: this.currentCategoryId,
        data: {
          lang: this.lang,
          title: this.newCategoryData.title,
          icon: this.currentIcon
        }
      }

      this.updateCategoryId(newCategoryData)
      this.closeModal()
    }
  }
}
</script>

<style lang="scss">
@import './FaqPopupStyle';
</style>
Нет такого языка!
