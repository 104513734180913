export default {
  methods: {
    getFile({ type, file, width }) {
      if (type == 'image') {
        return `https://cdn.skylove.su/${type}/${file}`
      }
      return `${process.env.VUE_APP_CDN_MEDIA_LINK}/${type}/${file}`
    },
    getImgLinkAdmin(content_id, width) {
      return `${process.env.VUE_APP_CDN_MEDIA_LINK}/image/resize/${width}/${content_id}`
    },
    getMediaLink(content_id, bucket) {
      return `${process.env.VUE_APP_CDN_MEDIA}/${bucket}/${content_id}`
    },
    getImgLinkOriginal(content_id) {
      return `https://cdn.skylove.su/image/${content_id}`
    },
    getImgLinkCropped(file, widthImg, { x, y, width, height }, bucket = 'image') {
      return `https://cdn.skylove.su/${bucket}/crop/${x}x${y}_${width}x${height}/${widthImg}/${file}`
    }
  }
}
