<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Редактирование авто сообщения"
    modalClass="popup--add-category popup--medium"
  >
    <form @submit.prevent="addMessage">
      <div class="popup__item">
        <span class="popup__item-title">Пол отправителя</span>
        <div class="popup__radio">
          <input checked type="radio" id="user_gender-zero" value="0" v-model="user_gender" />
          <label for="user_gender-zero">Неопределенный</label>
        </div>
        <div class="popup__radio">
          <input checked type="radio" id="user_gender-one" value="1" v-model="user_gender" />
          <label for="user_gender-one">Мужской</label>
        </div>
        <div class="popup__radio">
          <input type="radio" id="user_gender-two" value="2" v-model="user_gender" />
          <label for="user_gender-two">Женский</label>
        </div>
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Пол получателя</span>
        <div class="popup__radio">
          <input checked type="radio" id="defendant_gender-zero" value="0" v-model="defendant_gender" />
          <label for="defendant_gender-zero">Неопределенный</label>
        </div>
        <div class="popup__radio">
          <input checked type="radio" id="defendant_gender-one" value="1" v-model="defendant_gender" />
          <label for="defendant_gender-one">Мужской</label>
        </div>
        <div class="popup__radio">
          <input type="radio" id="defendant_gender-two" value="2" v-model="defendant_gender" />
          <label for="defendant_gender-two">Женский</label>
        </div>
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Текст сообщения</span>
        <input type="text" class="popup__input default-input" v-model="message_text" required />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Изменить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { AUTO_MESSAGE_CHANGE_POPUP } from '@/constants/event'
import { mapActions, mapGetters } from 'vuex'
import { GET_CURRENT_LANGUAGES } from '@/store/getter-types'
import { REQUEST_CHANGE_AUTO_MESSAGES } from '@/store/action-types'

export default {
  name: 'SupportContactTypeAddPopup',
  data() {
    return {
      user_gender: 1,
      defendant_gender: 1,
      message_text: '',
      id: '',
      oldMessage: '',
      lang: '',
      page: 0
    }
  },
  components: {
    Popup
  },
  mixins: [ModalMixin],

  computed: {
    ...mapGetters({
      // lang: GET_CURRENT_LANGUAGES
    })
  },
  mounted() {
    this.$root.$on(AUTO_MESSAGE_CHANGE_POPUP, (value, lang, page) => {
      this.user_gender = value.user_gender
      this.defendant_gender = value.defendant_gender
      this.message_text = this.oldMessage = value.message_text
      this.id = value.id
      this.lang = lang
      this.page = page
      this.open()
    })
  },
  methods: {
    ...mapActions({
      addAutoMessage: REQUEST_CHANGE_AUTO_MESSAGES
    }),
    closePopup() {
      this.message_text = ''
      this.user_gender = 1
      this.defendant_gender = 1
      this.lang = ''
      this.page = 0
      this.close()
    },
    addMessage() {
      const payload = {
        user_gender: +this.user_gender,
        lang: this.lang,
        page: this.page,
        defendant_gender: +this.defendant_gender,
        id: this.id
      }
      if (this.message_text !== this.oldMessage) {
        payload.message_text = this.message_text
      }
      this.addAutoMessage(payload)
      this.closePopup()
    }
  }
}
</script>

<style lang="scss" scoped>
.popup__item:first-child {
  margin-top: 10px;
}

.popup__radio {
  display: flex;
  label {
    padding: 5px;
    border-radius: 5px;
  }
}

.popup__radio input[type='radio'] {
  display: none;
}

.popup__radio input[type='radio']:checked + label {
  background: #689bcc;
  color: white;
}
</style>
