<template>
  <div class="operators__header">
    <!-- <span class="operators__title title-big">Операторы</span> -->
    <section ref="filtersWrap" class="filters-wrap">
      <form @submit.prevent="submitEmployeeHandler" class="filters" :class="{ opened: mobileFilterOpen }">
        <div class="filters__mobile-nav">
          <button type="button" class="filters__link" @click="mobileFilterOpen = !mobileFilterOpen">
            <i class="filters__icon fa fa-filter"></i>
            <span class="filters__button-text">Фильтры</span>
          </button>
        </div>
        <div class="filters__items">
          <div class="filters__group">
            <!-- <div class="filters__item">
              <span class="filters__item-title">Id:</span>
              <input
                type="text"
                class="filters__input filters__input--id"
                v-model="employeeId"
                ref="employeeid"
              />
            </div>
            <div class="filters__item">
              <span class="filters__item-title">Email:</span>
              <input
                type="text"
                class="filters__input filters__input--id"
                v-model="employeeEmail"
                ref="employeemail"
              />
            </div> -->
            <div class="filters__item">
              <span class="filters__item-title">Имя:</span>
              <input type="text" class="filters__input" v-model="employeeName" ref="employeename" />
            </div>
            <div class="filters__item">
              <span class="filters__item-title">Фамилия:</span>
              <input type="text" class="filters__input" v-model="employeeLastName" ref="employeelastname" />
            </div>
          </div>

          <div class="filters__group">
            <div class="filters__item">
              <div class="select select--status">
                <span class="select__title">Статус:</span>
                <div class="select__body">
                  <button
                    type="button"
                    class="select__field select__field--status"
                    @click="clickClose('activity')"
                  >
                    <span class="select__field-value">{{ defaultFilter({ key: 'activity' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="activitySelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="item in tmpFilterInfo.activity"
                      :key="item.id"
                      @click="
                        selectFilterBuilder({
                          key: 'activity',
                          value: item
                        })
                      "
                    >
                      <span class="select__text"> {{ item.position_name }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- TODO Вернуть как только будет утверждена логика удаленных операторов и расширен функционал -->
          <!-- <div class="filters__group">
            <div class="filters__item">
              <div class="select">
                <span class="select__title">Удален:</span>
                <div class="select__body">
                  <button type="button" class="select__field" @click="clickClose('employeeDeleted')">
                    <span class="select__field-value">{{ defaultFilter({ key: 'employeeDeleted' }) }}</span>
                    <i class="select__field-icon fa fa-angle-down"></i>
                  </button>
                  <ul class="select__list" v-if="employeeDeletedSelectOpen" @mouseleave="mouseLeave">
                    <li
                      class="select__item"
                      v-for="item in tmpFilterInfo.employeeDeleted"
                      :key="item.id"
                      @click="
                        selectFilterBuilder({
                          key: 'employeeDeleted',
                          value: item
                        })
                      "
                    >
                      <span class="select__text"> {{ item.position_name }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->

          <div class="operators__btns">
            <button class="operators__btn-add" @click.prevent="$root.$emit(ADD_OPERATOR_POPUP_EVENT)">
              <i class="operators__btn-txt fa fa-plus-square"></i>
              <span class="operators__btn-title">Добавить</span>
            </button>
            <div class="operators__btn-wrapp">
              <button type="button" class="filters__btn-clear" @click="resetFiltershandler">
                <i class="fa fa-eraser"></i>
              </button>
              <button type="submit" class="filters__btn-search">
                <span>Поиск</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { ADD_OPERATOR_POPUP_EVENT } from '@/constants/event'
import { REQUEST_FILTERS_OPERATORS, REQUEST_ALL_EMPLOYEE } from '@/store/action-types'
import {
  GET_EMPLOYEE_POSITIONS,
  GET_TMP_FILTERS_OPERATOR,
  GET_TMP_DEFAULT_FILTERS_OPERATOR,
  GET_TMP_FILTER_INFO
} from '@/store/getter-types'
import { SET_TMP_FILTERS_OPERATORS, SET_RESET_FILTERS_OPERATORS } from '@/store/mutation-types'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'OperatorsHeaders',
  data() {
    return {
      ADD_OPERATOR_POPUP_EVENT,
      statusFilterOpen: false,
      appealFilterOpen: false,
      priorityFilterOpen: false,
      mobileFilterOpen: false,
      positionSelectOpen: false,
      activitySelectOpen: false,
      employeeDeletedSelectOpen: false,

      employeeFilters: {
        employee_id: '',
        fisrt_name: '',
        last_name: '',
        employee_position: '',
        email: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      positions: GET_EMPLOYEE_POSITIONS,
      tmpFilter: GET_TMP_FILTERS_OPERATOR,
      defaultFilters: GET_TMP_DEFAULT_FILTERS_OPERATOR,
      tmpFilterInfo: GET_TMP_FILTER_INFO
    }),
    employeeEmail: {
      get() {
        return this.employeeFilters.email
      },
      set(mail) {
        this.$refs.employeemail.value = mail
        this.employeeFilters.email = mail
      }
    },

    employeeId: {
      get() {
        return this.employeeFilters.employee_id
      },
      set(id) {
        this.$refs.employeeid.value = id
        this.employeeFilters.employee_id = id
      }
    },
    employeeName: {
      get() {
        return this.employeeFilters.fisrt_name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.employeename.value = name
        this.employeeFilters.fisrt_name = name
      }
    },
    employeeLastName: {
      get() {
        return this.employeeFilters.last_name
      },
      set(last_name) {
        last_name = last_name.replace(/\d/g, '')
        this.$refs.employeename.value = last_name
        this.employeeFilters.last_name = last_name
      }
    }
  },
  methods: {
    ...mapActions({
      getEmplFilter: REQUEST_FILTERS_OPERATORS,
      getEmployees: REQUEST_ALL_EMPLOYEE
    }),
    ...mapMutations({
      addTmpFilters: SET_TMP_FILTERS_OPERATORS,
      resetFilters: SET_RESET_FILTERS_OPERATORS
    }),

    resetFiltershandler() {
      this.resetData(this.employeeFilters)
      this.resetFilters()
      // this.getEmployees()
      this.submitEmployeeHandler()
    },

    resetData(data) {
      for (const key in data) {
        data[key] = ''
      }
    },

    // defaultValueFilter(filterObject) {
    //   const { key } = filterObject
    //   const current = this.tmpFilter[key].position_value
    //   return current !== undefined ? current : ''
    // },

    defaultValueFilterStatus(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current !== undefined ? current : this.defaultFilters[key].value
    },

    submitEmployeeHandler() {
      const data = {
        //Todo убрать после тестов
        // id: this.employeeFilters.employee_id,
        // email: this.employeeFilters.email,
        first_name: this.employeeFilters.fisrt_name,
        last_name: this.employeeFilters.last_name,
        is_active: this.defaultValueFilterStatus({ key: 'activity' })
        // is_deleted_oper: this.defaultValueFilterStatus({ key: 'employeeDeleted' })
      }
      this.getEmplFilter(data)
    },
    defaultFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].position_name
      return current ? current : this.defaultFilters[key].position_name
    },
    selectFilterBuilder(filterItem) {
      const { key } = filterItem
      switch (key) {
        case 'position':
          this.addTmpFilters({ ...filterItem })
          break
        case 'activity':
          this.addTmpFilters({ ...filterItem })
          break
        case 'employeeDeleted':
          this.addTmpFilters({ ...filterItem })
          break

        default:
          break
      }
      this[`${key}SelectOpen`] = false
    },
    close() {
      this.positionSelectOpen = false
      this.activitySelectOpen = false
      this.employeeDeletedSelectOpen = false
    },
    mouseLeave() {
      this.close()
    },
    clickClose(options) {
      let newOptions = this[`${options}SelectOpen`]
      this.close()
      this[`${options}SelectOpen`] = !newOptions
    }
  },
  mounted() {
    this.$root.$on('changeOperators', key => {
      if (key === 'newOperator') this.resetFiltershandler()
      if (key === 'change' || key === 'delete' || key === 'editDelay' || key === 'editSearchOpt')
        this.submitEmployeeHandler()
    })
  }
}
</script>
<style lang="scss" scoped>
@import './Operators.scss';
@import './Filters.scss';
</style>
