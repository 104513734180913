var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.count.chat)?_c('div',{staticClass:"app-user__chats"},[_c('div',{staticClass:"user-chats"},[_c('ul',{ref:"viewScrollContent",staticClass:"user-chats__list",on:{"scroll":function($event){return _vm.scroll($event)}}},_vm._l((_vm.chats),function(chat){return _c('li',{key:chat.chat_id,ref:"userItem",refInFor:true,staticClass:"user-chats__item",class:{
          selected: _vm.selectedChat == chat
        },on:{"click":function($event){return _vm.changeSelected(chat)}}},[_c('div',{staticClass:"user-chats__user"},[_c('div',{staticClass:"user-chats__image"},[(chat.user.avatar.status !== null)?_c('div',{staticClass:"app-user-photo__badge-block-top"},[_c('div',{staticClass:"app-user-photo__badge",class:{
                  postponed: chat.user.avatar.status === -1,
                  verification: chat.user.avatar.status === 1,
                  'forbidden-avatar': chat.user.avatar.status === 2,
                  'forbidden-show': chat.user.avatar.status === 3,
                  'deferred-block': chat.user.avatar.status === 4,
                  'status-age': chat.user.avatar.status === 6
                }}),_c('div',{staticClass:"app-user-photo__badge-icon"},[(chat.user.avatar.status !== 0 || chat.user.avatar.status !== null)?_c('FaIcon',{staticClass:"app-user-profile__gender-icon",attrs:{"iconClass":[
                    chat.user.avatar.status === -1 ? 'fa fa-clock' : '',
                    chat.user.avatar.status === 1 ? 'fa fa-user-check' : '',
                    chat.user.avatar.status === 2 ? 'fa fa-user-times' : '',
                    chat.user.avatar.status === 3 ? 'fa fa-user-slash' : '',
                    chat.user.avatar.status === 4 ? 'fa fa-exclamation-triangle' : '',
                    chat.user.avatar.status === 6 ? 'fa-solid fa-baby' : ''
                  ]}}):_vm._e()],1)]):_vm._e(),(chat.messages_count && chat.messages_count.unread)?_c('div',{staticClass:"user-chats__unread"},[_vm._v(" "+_vm._s(chat.messages_count.unread > 99 ? '99+' : chat.messages_count.unread)+" ")]):_vm._e(),_c('img',{staticClass:"user-chats__avatar",attrs:{"src":_vm.getFile({
                  type: 'image',
                  file: chat.user.avatar.id,
                  width: 150,
                  height: 100
                }),"alt":""}})]),_c('div',{staticClass:"user-chats__text"},[_c('div',{staticClass:"user-chats__companion"},[(_vm.isOperator)?_c('a',{staticClass:"user-chats__companion-name",attrs:{"href":(_vm.link + "/users/" + (chat.user.id) + "/"),"target":"_blank"}},[_vm._v(_vm._s(chat.user.name)+" ("+_vm._s(chat.user.age)+")")]):_c('span',{staticClass:"user-chats__companion-name"},[_vm._v(_vm._s(chat.user.name)+" ("+_vm._s(chat.user.age)+")")]),_c('span',{staticClass:"user-chats__count user-chats__companion-message"},[_vm._v(_vm._s(chat.messages_count ? chat.messages_count.in : 0))])]),_c('div',{staticClass:"user-chats__current-user"},[_c('span',{staticClass:"user-chats__current-user-name"},[_vm._v(_vm._s(_vm.currentUser.name))]),_c('span',{staticClass:"user-chats__count user-chats__current-user-message"},[_vm._v(_vm._s(chat.messages_count ? chat.messages_count.out : 0))])])])])])}),0)]),(_vm.chats.length)?_c('AppUserChat',{key:_vm.newCount,attrs:{"messagesChat":_vm.messagesChat,"currentUser":_vm.currentUser,"selectedChat":_vm.selectedChat}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }