import Request from '../helpers/Request'
import BaseService from './BaseService'

class FaqService extends BaseService {
  constructor(token) {
    super(token)
  }

  /* Category */

  async getAllCatagoryByLang(languages) {
    const getAllCatagoryForLangUrl = `/api/v1/localization/faq/category/?lang=${languages}`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }
    return await Request.get(getAllCatagoryForLangUrl, { headers })
  }

  async createCategory(categoryData) {
    const createCategoryUrl = `/api/v1/localization/faq/category/add/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(createCategoryUrl, categoryData, { headers })
  }

  async updateCategoryById(categoryData) {
    const createCategoryUrl = `/api/v1/localization/faq/category/${categoryData.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.put(createCategoryUrl, categoryData.data, { headers })
  }

  async deleteCategoryById(categoryId) {
    const deleteCategoryByIdUrl = `/api/v1/localization/faq/category/${categoryId}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteCategoryByIdUrl, { headers })
  }

  /* Content */

  async getAllContentById(categoryId) {
    const getAllContentForTitleUrl = `/api/v1/localization/faq/content/?category=${categoryId}`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getAllContentForTitleUrl, { headers })
  }

  async createContent(contentData) {
    const createContentUrl = `/api/v1/localization/faq/content/add/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(createContentUrl, contentData, { headers })
  }

  // async updateContentById({ contentId, contentData }) {
  //   const updateContentByIdUrl = `/api/v1/localization/faq/content/${contentId}/update/`;
  //   const headers = {
  //     Authorization: `Bearer ${this.userToken}`
  //   };
  //   return await Request.put(updateContentByIdUrl, contentData, {
  //     headers
  //   });
  // }

  async deleteContentById(contentId) {
    const deleteContentByIdUrl = `/api/v1/localization/faq/content/${contentId}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteContentByIdUrl, { headers })
  }

  async updateContentById(questionData) {
    const updateContentByIdUrl = `/api/v1/localization/faq/content/${questionData.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(updateContentByIdUrl, questionData.data, {
      headers
    })
  }
}

export default FaqService
