<template>
  <div class="cause-points__header">
    <h1 class="cause-points__title">Начисление баллов</h1>
    <div class="cause-points__wrap">
      <!-- <select class="ev-cat__select" v-model="selected" @change="getCategoriesByLang(selected)">
        <option v-for="lang in languages" :key="lang.value" :value="lang.value">
          {{ lang.name }}
        </option>
      </select> -->
      <button type="button" class="cause-points__btn-add" @click="$root.$emit(POINTS_POPUP_ADD_EVENT)">
        <i class="cause-points__new-points-icon fa fa-plus-square"></i>
        <span class="cause-points__btn-title">Добавить</span>
      </button>
    </div>
  </div>
</template>

<script>
import { POINTS_POPUP_ADD_EVENT } from '@/constants/event'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_CAUSE_POINTS } from '@/store/action-types'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types.js'
import { GET_LANGUAGES, GET_CURRENT_LANGUAGES } from '@/store/getter-types'
export default {
  name: 'PointsHeader',
  data() {
    return {
      POINTS_POPUP_ADD_EVENT
    }
  },
  methods: {
    ...mapActions({
      categoryByLang: REQUEST_CAUSE_POINTS
    }),
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    }),
    getCategoriesByLang(value) {
      this.categoryByLang(value)
    }
  },
  computed: {
    ...mapGetters({
      languages: GET_LANGUAGES,
      currentLang: GET_CURRENT_LANGUAGES
    }),
    selected: {
      get() {
        return this.currentLang
      },
      set(newValue) {
        this.setCurrentLang(newValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ev-cat__select {
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  margin-right: 12px;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #000000;
  height: 34px;
  @media (max-width: 510px) {
    padding: 3px;
    font-size: 12px;
  }
}
</style>
