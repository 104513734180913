<template>
  <div class="">
    <div class="operator__statistic__controll">
      <div class="user-active__left">
        <span class="user-active__left-text">Дата:</span>
        <div class="datepicker-traffic-source">
          <datepicker
            class="datepicker-online"
            v-model="video_review_stats_form.date"
            input-class="datepicker__input"
            :language="ru"
          >
          </datepicker>
          <i class="select__field-icon fa fa-angle-down"></i>
        </div>
        <button class="user-active__btn" @click="submitBtnHandler">
          Показать
        </button>
      </div>
      <StatisticCenterFilters
        :status="statusFilter"
        :online="online"
        :onlineRight="onlineRight"
        :side="side"
        @baseFilterHandler="filtersHandler($event)"
        @onlineFilterHandler="onlineFilterHandler"
        @onlineFilterHandlerRight="onlineFilterHandlerRight"
      />
      <ServerTimeFilter :utcFlag="video_review_stats_form.utc" @changeUtc="changeUtc($event)" />
    </div>
    <div class="container vue">
      <div class="container-list" v-if="online">
        <transition name="slide">
          <ul class="list">
            <OperatorMain
              ref="el"
              v-for="operator in operators"
              :key="operator.id"
              :operator="operator"
              :openOper="openOper"
              :date="date"
              :online="operator.online"
              :isOnline="online"
              :status="statusFilter"
            />
          </ul>
        </transition>
      </div>
      <div class="container-list" v-else>
        <transition name="slide">
          <ul class="list">
            <OperatorMainAll
              ref="el"
              v-for="operator in operators"
              :key="operator.id"
              :operator="operator"
              :openOper="openOper"
              :date="date"
              :online="operator.online"
              :status="statusFilter"
            />
          </ul>
        </transition>
      </div>

      <div class="operator__statistic">
        <ul class="chart-list" ref="viewScrollContent" v-if="resStatus" @scroll="scroll($event)">
          <li class="chart-item" v-for="(stat, idx) in profileStatisticUpdate" :key="`${idx}`">
            <!-- :account_id="index" -->
            <StatisticOperatorsProfile
              v-for="item in stat"
              :profileStats="item"
              :key="item.id"
              :user_id="accountId"
              :operID="oper_id"
              :validOper_id="item.operator_id"
              :operatorInfo="item.account_info"
              :utc="video_review_stats_form.utc"
              :status="statusFilter"
              :date="date"
              ref="chart"
            />
          </li>
        </ul>
        <div v-else class="chart-list">
          <p class="chart-list__error">
            Выберите аккаунт что бы посмотреть график!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  REQUEST_STATISTICS_PROFILE_MESSAGES_PAGINATION,
  REQUEST_COUNTERS_MESSAGES_OPERATORS,
  REQUEST_STATISTICS_PROFILE_MESSAGES,
  REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS,
  REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS_PAGINATION,
  REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS,
  REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS_PAGINATION,
  REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES,
  REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES_PAGINATION,
  REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD_PAGINATION,
  REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD
} from '@/store/action-types'
import {
  GET_COUNTERS_MESSAGES_OPERATORS,
  GET_STATISTICS_PROFILE_MESSAGES,
  GET_ACTIVE_OPERATOR
} from '@/store/getter-types'
import {
  SET_ACTIVE_OPERATOR,
  SET_STATISTICS_PROFILE_MESSAGES,
  SET_COUNTERS_MESSAGES_OPERATORS
} from '@/store/mutation-types'
import { ru } from 'vuejs-datepicker/dist/locale'
import StatisticOperatorsProfile from '@/components/Statistic/StatisticOperatorsProfile'
import 'chartjs-plugin-datalabels'
import OperatorMain from '@/components/Statistic/OperatorAdvanced/OperatorMain'
import OperatorMainAll from '@/components/Statistic/OperatorAdvanced/OperatorMainAll'
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import moment from 'moment'
import getImgLink from '@/components/mixins/getImgCdnLink'
import ServerTimeFilter from '@/components/Statistic/Servertimefilter/ServerTimeFilter'
import StatisticCenterFilters from '@/components/Statistic/OperatorAdvanced/StatisticCenterFilters'
import pickerMixin from '@/components/Picker/pickerMixin'
export default {
  name: 'AdvancedStatisticsOperators',
  components: {
    OperatorMain,
    Datepicker,
    StatisticOperatorsProfile,
    OperatorMainAll,
    ServerTimeFilter,
    StatisticCenterFilters
  },
  mixins: [pickerMixin, getImgLink],
  data() {
    return {
      side: 'left',
      ru: ru,
      load: false,
      online: true,
      onlineRight: false,
      openOper: true,
      isDropped: false,
      page: 0,
      accountId: null,
      oper_id: null,
      statusFilter: 'dialogues',
      resStatus: true,
      disableScroll: false,
      loadDataByScroll: true,
      loadingUsers: false,
      operName: '',
      video_review_stats_form: {
        date: moment().format('YYYY-MM-DD'),
        main_video: 0,
        deferred: 0,
        utc: 1
      },
      date: {}
    }
  },
  watch: {
    'video_review_stats_form.utc': function() {
      // this.statusFilter = null
      this.page = 0
      this.moveScroll()
      this.loadDataByScroll = true
      this.disableScroll = false
      this.loadingUsers = false
      if (this.side === 'right') {
        this.geModerationPhotosStatsRight()
      } else {
        this.geModerationPhotosStats()
      }
    },
    active: function() {
      // this.statusFilter = null
      this.page = 0
      this.moveScroll()
      this.loadDataByScroll = true
      this.disableScroll = false
      this.loadingUsers = false
      if (this.side === 'right') {
        this.geModerationPhotosStatsRight()
      } else {
        this.geModerationPhotosStats()
      }
    },
    statusFilter: function() {
      this.page = 0
      this.moveScroll()
      this.loadDataByScroll = true
      this.disableScroll = false
      this.loadingUsers = false
    }
  },
  methods: {
    ...mapActions({
      getNewUsers: REQUEST_COUNTERS_MESSAGES_OPERATORS,
      sendData: REQUEST_STATISTICS_PROFILE_MESSAGES,
      paginationStats: REQUEST_STATISTICS_PROFILE_MESSAGES_PAGINATION,
      unhandleCalls: REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS,
      unhadledCallsPagination: REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS_PAGINATION,
      dialogs: REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS,
      dialogsPagination: REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS_PAGINATION,
      unreadMessage: REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES,
      unreadMessagePagination: REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES_PAGINATION,
      not_processed_delaeyd: REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD,
      not_processed_delaeyd_pagination: REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD_PAGINATION
    }),
    ...mapMutations({
      setActive: SET_ACTIVE_OPERATOR,
      resetGraphics: SET_STATISTICS_PROFILE_MESSAGES,
      resetCounter: SET_COUNTERS_MESSAGES_OPERATORS
    }),
    submitBtnHandler() {
      this.page = 0
      this.moveScroll()
      this.loadDataByScroll = true
      this.disableScroll = false
      this.loadingUsers = false
      if (this.side === 'right') {
        this.geModerationPhotosStatsRight()
      } else {
        this.geModerationPhotosStats()
      }
    },
    //! LEFT
    onlineFilterHandler() {
      this.moveScroll()
      if (
        this.statusFilter === 'not-processed' ||
        this.statusFilter === 'unhandled-calls' ||
        this.statusFilter === 'unhandled-calls-delayed'
      ) {
        this.statusFilter = 'dialogues'
      }
      if (!this.online) {
        this.online = !this.online
        this.onlineRight = false
        this.side = 'left'
        this.video_review_stats_form.date = moment().format('YYYY-MM-DD')
        let newData = {
          date_from: moment().format('YYYY-MM-DD'),
          page: 0,
          online: this.online ? 1 : 0
        }
        if (this.video_review_stats_form.utc) newData.delta = new Date().getTimezoneOffset() / -60

        this.date = newData
        if (this.statusFilter === 'dialogues') {
          newData.mode = this.statusFilter ? 'dialog' : 'all'
        } else if (this.statusFilter === null || this.statusFilter === 'all-day') {
          newData.mode = this.statusFilter ? 'all' : 'all'
        }

        this.getNewUsers(newData).then(response => {
          let { data } = response
          if (this.active) {
            let n = false
            if (!n) {
              for (let el in data) {
                if (data[el].accounts.some(acc => acc.id === this.accountId)) {
                  n = true
                }
              }
            }
            if (!n) {
              this.setActive('')
              this.accountId = null
            }
          }
        })
        delete newData.mode
        newData.user_id = this.active ? this.accountId : -1

        this.getStatsByFilter(newData)
      } else {
        this.online = !this.online
        this.onlineRight = false
        this.geModerationPhotosStats()
      }
    },
    //! RIGHT
    onlineFilterHandlerRight() {
      this.moveScroll()
      if (this.statusFilter === 'dialogues' || this.statusFilter === 'all-day') {
        this.statusFilter = 'not-processed'
      }
      if (!this.onlineRight) {
        this.onlineRight = !this.onlineRight
        this.online = false
        this.side = 'right'
        this.video_review_stats_form.date = moment().format('YYYY-MM-DD')
        let newData = {
          date_from: moment().format('YYYY-MM-DD'),
          page: 0,
          online: this.onlineRight ? 1 : 0
        }
        if (this.video_review_stats_form.utc) newData.delta = new Date().getTimezoneOffset() / -60

        this.date = newData
        if (this.statusFilter === 'unhandled-calls') {
          newData.mode = this.statusFilter ? 'video' : 'all'
        } else if (this.statusFilter === 'not-processed') {
          newData.mode = this.statusFilter ? 'unread' : 'all'
        } else if (this.statusFilter === 'unhandled-calls-delayed') {
          newData.mode = this.statusFilter ? 'unread_with_time' : 'all'
        }

        this.getNewUsers(newData).then(response => {
          let { data } = response
          if (this.active) {
            let n = false
            if (!n) {
              for (let el in data) {
                if (data[el].accounts.some(acc => acc.id === this.accountId)) {
                  n = true
                }
              }
            }
            if (!n) {
              this.setActive('')
              this.accountId = null
            }
          }
        })
        delete newData.mode
        newData.user_id = this.active ? this.accountId : -1
        this.getStatsByFilter(newData)
      } else {
        this.onlineRight = !this.onlineRight
        this.online = false
        this.geModerationPhotosStatsRight()
      }
    },
    //! LEFT
    geModerationPhotosStats() {
      if (moment(this.video_review_stats_form.date).format('YYYY-MM-DD') != moment().format('YYYY-MM-DD')) {
        this.online = false
        this.side = 'left'
      }
      let newData = {
        date_from: moment(this.video_review_stats_form.date).format('YYYY-MM-DD'),
        page: 0,
        online: this.online ? 1 : 0
      }
      if (this.video_review_stats_form.utc) newData.delta = new Date().getTimezoneOffset() / -60

      this.date = newData
      if (this.statusFilter === 'dialogues') {
        newData.mode = this.statusFilter ? 'dialog' : 'all'
      } else if (this.statusFilter === null || this.statusFilter === 'all-day') {
        newData.mode = this.statusFilter ? 'all' : 'all'
      }

      this.getNewUsers(newData).then(response => {
        let { data } = response
        if (this.active) {
          let n = false
          if (!n) {
            for (let el in data) {
              if (data[el].accounts.some(acc => acc.id === this.accountId)) {
                n = true
              }
            }
          }
          if (!n) {
            this.setActive('')
            this.accountId = null
          }
        }
      })
      delete newData.mode
      newData.user_id = this.active ? this.accountId : -1
      this.getStatsByFilter(newData)
    },
    //! RIGHT
    geModerationPhotosStatsRight() {
      if (moment(this.video_review_stats_form.date).format('YYYY-MM-DD') != moment().format('YYYY-MM-DD')) {
        this.onlineRight = false
        this.side = 'right'
      }
      let newData = {
        date_from: moment(this.video_review_stats_form.date).format('YYYY-MM-DD'),
        page: 0,
        online: this.onlineRight ? 1 : 0
      }
      if (this.video_review_stats_form.utc) newData.delta = new Date().getTimezoneOffset() / -60

      this.date = newData
      if (this.statusFilter === 'unhandled-calls') {
        newData.mode = this.statusFilter ? 'video' : 'all'
      } else if (this.statusFilter === 'not-processed') {
        newData.mode = this.statusFilter ? 'unread' : 'all'
      } else if (this.statusFilter === 'unhandled-calls-delayed') {
        newData.mode = this.statusFilter ? 'unread_with_time' : 'all'
      }

      this.getNewUsers(newData).then(response => {
        let { data } = response
        if (this.active) {
          let n = false
          if (!n) {
            for (let el in data) {
              if (data[el].accounts.some(acc => acc.id === this.accountId)) {
                n = true
              }
            }
          }
          if (!n) {
            this.setActive('')
            this.accountId = null
          }
        }
      })
      delete newData.mode
      newData.user_id = this.active ? this.accountId : -1
      this.getStatsByFilter(newData)
    },
    getStatsByFilter(newData) {
      if (this.statusFilter === 'unhandled-calls') {
        this.unhandleCalls(newData)
        this.resStatus = true
      } else if (this.statusFilter === 'unhandled-calls-delayed') {
        this.not_processed_delaeyd(newData)
        this.resStatus = true
      } else if (this.statusFilter === 'not-processed') {
        this.unreadMessage(newData)
        this.resStatus = true
      } else if (this.statusFilter === 'dialogues') {
        this.dialogs(newData)
        this.resStatus = true
      } else if (this.statusFilter === null || this.statusFilter === 'all-day') {
        this.sendData(newData)
        this.resStatus = true
      }
    },
    scroll(event) {
      // Проверка стороны!
      let online = this.online
      if (this.side === 'left') {
        online = this.online
      } else {
        online = this.onlineRight
      }
      let data = {
        date_from: moment(this.video_review_stats_form.date).format('YYYY-MM-DD'),
        user_id: this.active ? this.accountId : -1,
        delta: this.video_review_stats_form.utc ? new Date().getTimezoneOffset() / -60 : 0,
        online: online ? 1 : 0
      }
      let response
      //Проверка выбранного фильтра для пагинации
      if (this.statusFilter === 'unhandled-calls') {
        response = this.unhadledCallsPagination
      } else if (this.statusFilter === 'dialogues') {
        response = this.dialogsPagination
      } else if (this.statusFilter === 'not-processed') {
        response = this.unreadMessagePagination
      } else if (this.statusFilter === null || this.statusFilter === 'all-day') {
        response = this.paginationStats
      } else if (this.statusFilter === 'unhandled-calls-delayed') {
        response = this.not_processed_delaeyd_pagination
      }

      if (!this.disableScroll) {
        const list = this.$refs.viewScrollContent
        const scrollBottom = list.scrollHeight - (list.scrollTop + list.offsetHeight)
        const scrollTop = list.scrollTop

        const firstNodeOffset = this.$refs.chart[0]._vnode.elm.offsetTop
        const firstNodeScrollHeight = this.$refs.chart[0]._vnode.elm.scrollHeight
        if (scrollBottom + firstNodeOffset < firstNodeOffset + firstNodeScrollHeight) {
          this.disableScroll = true
          if (this.loadDataByScroll && !this.loadingUsers) {
            if (!this.page) {
              data.page = 0
              this.disableScroll = false
            }
            data.page = ++this.page
            this.loadingUsers = true
            response(data).then(res => {
              if (res.data.data.length === 0) {
                this.loadDataByScroll = false
              }
              this.$nextTick(() => {
                let newbottom = list.scrollHeight - (list.offsetHeight + scrollTop + scrollBottom)
                list.scrollTo({
                  bottom: newbottom
                })
                this.loadingUsers = false
                this.disableScroll = false
              })
            })
          }
        }
      }
    },
    moveScroll() {
      let list = this.$refs.viewScrollContent
      list.scrollTop = 0
    },
    filtersHandler(value) {
      this.moveScroll()
      switch (value.key) {
        case 'all-day':
          this.statusFilter = 'all-day'
          this.side = 'left'
          this.onlineRight = false
          this.geModerationPhotosStats()
          break
        case 'dialogues':
          this.statusFilter = 'dialogues'
          this.side = 'left'
          this.onlineRight = false
          this.geModerationPhotosStats()
          break
        case 'not-processed':
          this.statusFilter = 'not-processed'
          this.side = 'right'
          this.online = false
          this.geModerationPhotosStatsRight()
          break
        case 'unhandled-calls':
          this.statusFilter = 'unhandled-calls'
          this.side = 'right'
          this.online = false
          this.geModerationPhotosStatsRight()
          break
        case 'unhandled-calls-delayed':
          this.statusFilter = 'unhandled-calls-delayed'
          this.side = 'right'
          this.online = false
          this.geModerationPhotosStatsRight()
          break
        default:
          this.statusFilter = 'dialogues'
          break
      }
    },
    changeUtc(number) {
      this.video_review_stats_form.utc = number
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.isLoaded = true
      vm.geModerationPhotosStats()
    })
  },
  computed: {
    ...mapGetters({
      operators: GET_COUNTERS_MESSAGES_OPERATORS,
      profileStatistic: GET_STATISTICS_PROFILE_MESSAGES,
      active: GET_ACTIVE_OPERATOR
    }),
    profileStatisticUpdate() {
      return this.profileStatistic
    }
  },
  mounted() {
    this.$root.$on('set_id_account', (id, status, operId, userName) => {
      this.oper_id = operId
      this.accountId = id
      this.resStatus = status
      this.operName = userName
    })
  },
  beforeDestroy() {
    this.setActive('')
    this.resetGraphics([])
    this.resetCounter([])
  }
}
</script>

<style lang="scss">
.container-list {
  max-width: 500px;
}
.container {
  display: flex;
  margin: 0 1% 0 1%;
  background-color: #fff;
}

.operator__statistic__controll {
  margin: 1% 1% 0 1%;
  background-color: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.chart-list {
  overflow: hidden;
  overflow-y: auto;
  max-height: 80vh;
  min-height: 20vh;
  &::-webkit-scrollbar {
    background-color: #fff;
    z-index: 0;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(232, 64, 63);
    border-radius: 10px;
  }
}

.chart-list__error {
  margin: 10% 0;
  text-align: center;
}

.list {
  padding: 0;
  margin: 0;
  width: 450px;
  list-style-type: none;
  transform-origin: top;
  transition: transform 0.4s ease-in-out;
  overflow: hidden;
  overflow-y: auto;
  max-height: 83vh;
  &::-webkit-scrollbar {
    background-color: #fff;
    z-index: 0;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(232, 64, 63);
    border-radius: 10px;
  }
  &-header {
    padding: 20px 60px;
    width: 100%;
    text-align: center;
    background: #12d4ee;
    color: #eee;
    cursor: default;
  }
  &-item {
    padding: 10px;
    background: white;
    transition: 0.4s ease-in-out;
    border-bottom: solid thin #eee;
    display: flex;
    flex-direction: column;

    &__wrapp {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      &-name {
        position: relative;
        margin-left: 8px;
        margin-right: 16px;
      }
    }

    &__avatar {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin: 6px 0;
      img {
        width: 60px;
      }
    }
  }
}
.sublist {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transform-origin: top;
  transition: transform 0.4s ease-in-out;
  padding: 0 7px;
  line-height: 20px;
  color: #369;
  border-left: 2px solid rgb(100, 100, 100);
  &-item {
    &::before {
      position: relative;
      top: 40px;
      height: 1em;
      width: 19px;
      color: white;

      border-bottom: 2px solid rgb(0, 0, 0);
      content: '';
      display: inline-block;
      left: -18px;
    }

    &:last-child::before {
      border-left: 1px solid rgb(0, 0, 0);
    }
    padding: 10px;
    background: white;
    border-bottom: solid thin #eee;
    &__name {
      margin: 0;
    }
  }
}

.operator__statistic {
  width: 100%;
  // height: 100%;
  padding: 10px;
  background-color: #fff;
  position: sticky;
  top: 20px;
}

.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}
.table-condensed {
  td:hover {
    background: #318bcc;
    color: #fff;
  }
  .today {
    background: #48a7eb;
    color: #fff;
  }
}
</style>
