import Request from '../helpers/Request'
import BaseService from './BaseService'

class AutoMessageService extends BaseService {
  constructor(token) {
    super(token)
  }
  async getAutoMessage(payload) {
    const getAutoMessageUrl = `/api/v1/localization/auto-messages/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
      // 'Pragma': 'no-cache',
      // 'Expires': '0',
    }
    // let date = new Date();
    // return await Request.get(getAutoMessageUrl, {
    //     headers, params: { lang, date },
    // });
    return await Request.get(getAutoMessageUrl, {
      headers,
      params: payload
    })
  }
  async getAutoMessageByPage(payload) {
    const getAutoMessageByPageUrl = `${payload}`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getAutoMessageByPageUrl, {
      headers
    })
  }

  async addAutoMessage(payload) {
    const addAutoMessageUrl = '/api/v1/localization/auto-message/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    delete payload.page
    return await Request.post(addAutoMessageUrl, payload, { headers })
  }

  async changeAutoMessage(payload) {
    const changeAutoMessageUrl = '/api/v1/localization/auto-message/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    delete payload.page
    return await Request.put(changeAutoMessageUrl, payload, { headers })
  }

  async deleteAutoMessage(data) {
    const deleteAutoMessageUrl = `/api/v1/localization/auto-message/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.delete(deleteAutoMessageUrl, { data, headers })
  }
}

export default AutoMessageService
