<template>
  <div class="header__burger" @click="asidebarHandler">
    <span class="header__burger-el"></span>
    <span class="header__burger-el"></span>
    <span class="header__burger-el"></span>
  </div>
</template>
<script>
export default {
  name: 'Burger',
  data: () => ({
    opened: true
  }),
  methods: {
    asidebarHandler() {
      this.$emit('asideHandler', true)
    }
  }
}
</script>
<style lang="scss">
@import './Burger.scss';
</style>
