<template>
  <section class="user-block">
    <div class="user-block__body">
      <div class="user-block__avatar">
        <img
          :src="
            getFile({
              type: 'image',
              file: currentEvent.event_user.user_avatar.content_id,
              width: 100,
              height: 100
            })
          "
          alt=""
        />
      </div>
      <div class="user-block__user">
        <div class="user-block__user-header">
          <div class="user-block__user-header-item">
            <div class="user-block__user-name">
              <i
                :class="{
                  'fa fa-male': currentEvent.event_user.user_gender === 1,
                  'fa fa-female': currentEvent.event_user.user_gender === 2
                }"
              ></i>
              <span class="user-block__user-name-text">Имя:</span>
              <router-link
                :to="{
                  name: 'UserItem',
                  params: {
                    userId: currentEvent.event_user.id,
                    user: currentEvent.event_user
                  }
                }"
                class="app-user__name-link"
              >
                <span class="user-block__user-name-value">{{ currentEvent.event_user.user_name }}, </span>
                <span class="user-block__user-name-age">({{ currentEvent.event_user.age || '0' }})</span>
              </router-link>
            </div>
          </div>
          <div class="user-block__user-header-item">
            <div class="user-block__claims-count">
              <span class="user-block__mobtitle">Входящих жалоб:</span>
              <i class="fas fa-sign-out-alt"></i>
              <i class="fa fa-bullhorn"></i>
              <span class="user-block__claims-count-num">1</span>
            </div>
          </div>
        </div>
        <div class="user-block__user-name-body">
          <!--                  <div class="user-block__user-name">-->
          <!--                    <i-->
          <!--                        :class="{-->
          <!--                          'fa fa-male': currentEvent.event_user.user_gender === 1,-->
          <!--                          'fa fa-female': currentEvent.event_user.user_gender === 2,-->

          <!--                        }"-->
          <!--                    ></i>-->
          <!--                    <span class="user-block__user-name-text">Имя:</span>-->
          <!--                    <router-link-->
          <!--                        :to="{ name: 'UserItem', params: { userId: currentEvent.event_user.id, user: currentEvent.event_user } }"-->
          <!--                        class="app-user__name-link"-->
          <!--                    >-->
          <!--                      <span class="user-block__user-name-value">{{ currentEvent.event_user.user_name }}, </span>-->
          <!--                      <span class="user-block__user-name-age">({{ currentEvent.event_user.age || "0" }})</span>-->
          <!--                    </router-link>-->
          <!--                  </div>-->
          <div class="user-block__location">
            <span class="user-block__user-city">{{ currentEvent.event_user.user_city }}</span>
          </div>
          <span class="event__user-events-score">
            <FaIcon :iconClass="'fa fa-star'" class="event__user-name-icon" v-tooltip="'Рейтинг'" />
            <span class="event__user-name-count">{{ `-` }}</span>
          </span>
          <span class="event__user-events-score">
            <FaIcon
              :iconClass="'fa fa-calendar-plus'"
              class="event__user-name-icon"
              v-tooltip="'Созданные события'"
            />
            <span class="event__user-name-count">{{ currentEvent.event_count.outbid }}</span>
          </span>
          <span class="event__user-events-score">
            <FaIcon
              :iconClass="'fa fa-calendar-check'"
              class="event__user-name-icon"
              v-tooltip="'Посещенные события'"
            />
            <span class="event__user-name-count">{{ currentEvent.event_count.invite }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="user-block__user-social">
      <span class="user-block__user-link user-block__user-vk">
        <i class="fab fa-vk"></i>
      </span>
      <span class="user-block__user-link user-block__user-fb">
        <i class="fab fa-facebook"></i>
      </span>
      <span class="user-block__user-link user-block__user-apple">
        <i class="fa fa-crown"></i>
      </span>
      <span href="#" class="user-block__user-mail">
        <i class="user-block__user-mail-icon fa fa-envelope"></i>
        <span class="user-block__user-mail-text">{{ currentEvent.event_user.user_email }}</span>
      </span>
    </div>
  </section>
</template>

<script>
import getImgCdnLink from '@/components/mixins/getImgCdnLink'
export default {
  name: 'EventUser',
  props: {
    currentEvent: {
      type: Object
    }
  },
  mixins: [getImgCdnLink]
}
</script>
