import Request from '../helpers/Request'
import BaseService from './BaseService'

class RequestsTypesService extends BaseService {
  constructor(token) {
    super(token)
  }
  // REASON DELETE
  async getRequestsTypes(lang) {
    const getRequestsTypesUrl = `/api/v1/localization/requesttypes/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }

    return await Request.get(getRequestsTypesUrl, {
      headers,
      params: { lang }
    })
  }

  async addRequestsTypes(payload) {
    const addRequestsTypesUrl = '/api/v1/localization/requesttypes/add/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(addRequestsTypesUrl, payload, { headers })
  }

  async editRequestsTypes({ id, payload }) {
    const editRequestsTypesUrl = `/api/v1/localization/requesttypes/${id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(editRequestsTypesUrl, payload, { headers })
  }

  async deleteRequestsTypes(id) {
    const deleteRequestsTypesUrl = `/api/v1/localization/requesttypes/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteRequestsTypesUrl, { headers })
  }
}

export default RequestsTypesService
