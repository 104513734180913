import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_ALL_CATEGORY_BY_LANG]: (state, payload) => (state.category = payload),

  [MutationTypes.SET_CONTENT_BY_ID]: (state, payload) => (state.content = payload),

  [MutationTypes.SET_CURRENT_CONTENT_ID]: (state, payload) => (state.currendIdContent = payload),

  [MutationTypes.SET_CURRENT_ITEM_ACTIVE]: (state, payload) => {
    state.itemActive = payload
  }
}
