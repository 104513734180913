<template>
  <Popup :visible="visible" @close="closePopup" description="Новый департамент" modalClass="popup--medium">
    <form @submit.prevent="createDepartmentHandler">
      <div class="popup__item">
        <span class="popup__item-title">Название департамента</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="createDepartment"
          ref="createdepartment"
          required
        />
      </div>
      <!-- <div class="popup__item">
        <span class="popup__item-title">Название иконки</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="createIcon"
          ref="createicon"
          placeholder="fa icon"
          required
        />
      </div> -->
      <div class="popup__item">
        <span class="popup__item-title">Значение департамента</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="createValue"
          ref="createvalue"
          placeholder="department value"
          required
        />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Добавить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>

<script>
import { mapActions } from 'vuex'
import { REQUEST_CREATE_DEPARTMENT } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { NEW_DEPARTMENT_POPUP_EVENT } from '@/constants/event'
export default {
  name: 'NewDepartmentPopup',
  components: {
    Popup
  },

  mixins: [ModalMixin],
  data: () => ({
    departmentData: {
      name: '',
      icon: 'icon',
      value: ''
    }
  }),

  computed: {
    createDepartment: {
      get() {
        return this.departmentData.name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.createdepartment.value = name
        this.departmentData.name = name
      }
    },

    // createIcon: {
    //   get() {
    //     return this.departmentData.icon
    //   },
    //   set(icon) {
    //     icon = icon.replace(/\d/g, '')
    //     this.$refs.createicon.value = icon
    //     this.departmentData.icon = icon
    //   }
    // },

    createValue: {
      get() {
        return this.departmentData.value
      },
      set(value) {
        value = value.replace(/\d/g, '')
        this.$refs.createvalue.value = value
        this.departmentData.value = value
      }
    }
  },

  methods: {
    ...mapActions({
      create: REQUEST_CREATE_DEPARTMENT
    }),
    closePopup() {
      this.departmentData.name = ''
      this.departmentData.icon = ''
      this.departmentData.value = ''
      this.close()
    },
    createDepartmentHandler() {
      const department = {
        name: this.departmentData.name,
        icon: this.departmentData.icon,
        value: this.departmentData.value
      }
      this.create(department)
      this.departmentData.name = ''
      this.departmentData.icon = ''
      this.departmentData.value = ''
      this.closePopup()
    }
  },
  mounted() {
    this.$root.$on(NEW_DEPARTMENT_POPUP_EVENT, () => this.open())
  }
}
</script>
