<template>
  <Popup :visible="visible" @close="close" modalClass="popup--medium">
    <div class="popup__item">
      <div class="popup__form">
        <div class="popup__form__item">
          <span class="popup__form__item--title">Заголовок:</span>
          <input
            class="popup__input default-input popup__form__input--title"
            maxlength="50"
            v-model.lazy="titleInput"
            ref="reftitle"
            type="text"
            @change="validateForm()"
            :class="{ error_field: error_title }"
          />
          <span :class="{ error: error_title }" v-if="error_title">{{ error_title }}</span>
        </div>
        <div class="popup__form__item">
          <span class="popup__form__item--title">Сообщение предупреждения:</span>
          <textarea
            class="popup__input default-input popup__form__input--message"
            v-model.lazy="messageInput"
            maxlength="1000"
            ref="refmessage"
            @change="validateForm()"
            type="text"
            :class="{ error_field: error_message }"
          />
          <span :class="{ error: error_message }" v-if="error_message">{{ error_message }}</span>
        </div>
        <div class="popup__form__item_btns">
          <span class="popup__form__item--title">Приориет:</span>
          <div class="form_toggle">
            <div class="form_toggle-item item-1">
              <input id="fid-1" type="radio" name="radio" v-model="priority" value="0" />
              <label for="fid-1">Низкий</label>
            </div>
            <div class="form_toggle-item item-2">
              <input id="fid-2" type="radio" name="radio" v-model="priority" value="1" />
              <label for="fid-2">Средний</label>
            </div>
            <div class="form_toggle-item item-3">
              <input id="fid-3" type="radio" name="radio" v-model="priority" value="2" />
              <label for="fid-3">Высокий</label>
            </div>
          </div>
        </div>
        <div class="popup__warn" v-if="isVisible">
          <p>{{ warn_message }}</p>
        </div>
      </div>
      <div class="popup__btns popup__btns--right">
        <button class="popup__btn btn-green" @click="handleClick()" :disabled="isVisible">
          Отправить
        </button>
        <button class="popup__btn btn-red" @click="closePopup()">Закрыть</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_SEND_ALERT_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_SEND_ALERT_TO_USER } from '@/store/action-types'
export default {
  name: 'UserAlertPopup',
  components: {
    Popup
  },
  data() {
    return {
      id: '',
      title: '',
      message: '',
      priority: 0,
      isVisible: false,
      warn_message: '',
      error_title: '',
      error_message: '',
      isValidTitle: false,
      isValidMessage: false
    }
  },
  mixins: [ModalMixin],
  watch: {
    titleInput: function() {
      if (this.titleInput === '') {
        this.isValidTitle = false
      } else {
        this.isValidTitle = true
      }
    },
    messageInput: function() {
      if (this.messageInput === '') {
        this.isValidMessage = false
      } else {
        this.isValidMessage = true
      }
    }
  },
  computed: {
    titleInput: {
      get() {
        return this.title
      },
      set(title) {
        this.$refs.reftitle.value = title
        this.title = title
      }
    },
    messageInput: {
      get() {
        return this.message
      },
      set(messgae) {
        this.$refs.refmessage.value = messgae
        this.message = messgae
      }
    }
  },
  methods: {
    ...mapActions({
      sendData: REQUEST_SEND_ALERT_TO_USER
    }),
    handleClick() {
      this.validateForm()
      if (this.isValidTitle && this.isValidMessage) {
        const data = {
          user_id: this.id,
          title: this.title,
          message: this.message,
          priority: +this.priority
        }
        this.sendData(data).then(res => {
          const { data } = res
          if (data.error_code) {
            this.warnMessage(data.error_code)
          } else {
            this.closePopup()
          }
        })
      }
    },
    validateForm() {
      if (this.titleInput === '') {
        this.error_title = 'Поле заголовка не заполнено!'
      } else {
        this.error_title = ''
      }
      if (this.messageInput === '') {
        this.error_message = 'Поле предупреждения должно быть заполенено!'
      } else {
        this.error_message = ''
      }
    },
    closePopup() {
      this.id = ''
      this.title = ''
      this.message = ''
      this.priority = 0
      this.error_title = ''
      this.error_message = ''
      this.isVisible = false
      this.warn_message = ''
      this.close()
    },
    warnMessage(code) {
      this.warn_message = `Уведомление не отправлено. Код ошибки: ${code}`
      this.isVisible = true
      setTimeout(() => {
        this.isVisible = false
      }, 3500)
    }
  },
  mounted() {
    this.$root.$on(USER_SEND_ALERT_EVENT, user => {
      this.open()
      this.id = user.id
    })
  }
}
</script>

<style lang="scss" scoped>
.popup__form {
  position: relative;
  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    &--title {
      margin-bottom: 8px;
    }
  }
  &__item_btns {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
  &__input {
    &--title {
      width: 70%;
    }
    &--message {
      height: 150px;
      resize: none;
    }
  }
}

.form_toggle {
  display: inline-block;
  overflow: hidden;
}
.form_toggle-item {
  display: inline-block;
}
.form_toggle-item input[type='radio'] {
  display: none;
}
.form_toggle-item label {
  display: inline-block;
  padding: 0px 15px;
  line-height: 34px;
  border: 1px solid #999;
  border-right: none;
  cursor: pointer;
  user-select: none;
}

.form_toggle .item-1 label {
  border-radius: 6px 0 0 6px;
}
.form_toggle .item-3 label {
  border-radius: 0 6px 6px 0;
  border-right: 1px solid #999;
}

.form_toggle .item-1 input[type='radio']:checked + label {
  color: #fff;
  background: #78cd51;
}
.form_toggle .item-2 input[type='radio']:checked + label {
  color: #000;
  background: #f2e428;
}
.form_toggle .item-3 input[type='radio']:checked + label {
  color: #fff;
  background: #f67a6e;
}

.popup__warn {
  opacity: 0;
  transition: 0.5s;
  animation: show 3s 1;
  animation-fill-mode: backwards;
  position: absolute;
  top: 50%;
  left: 20%;
  padding: 6px;
  border-radius: 2px;
  background-color: #fff;
  color: #f67a6e;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.error {
  color: #ff2d2d;
}

.error_field {
  border: 1px solid #ff2d2d;
}
</style>
