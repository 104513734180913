<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    title=""
    description=""
    modalClass="popup--medium popup--operator popup--scroll"
  >
    <form class="operator-profile" @submit.prevent="createOperatorSubmitHandler">
      <div class="operator-profile__header">
        <span class="operator-profile__name">Редактирование профиля</span>
        <div class="operator-profile__avatar-wrap">
          <div class="operator-profile__avatar">
            <img
              v-if="oldData.user_avatar"
              :src="
                getFile({
                  type: 'image',
                  file: oldData.user_avatar.content_id,
                  width: `128`,
                  height: `128`
                })
              "
              class="chat__message-body--img"
              alt=""
            />
            <img v-else src="@/assets/img/anonymous_user.png" alt="" />
          </div>
        </div>
      </div>

      <div class="operator-profile__edit">
        <div class="operator-profile__edit-body">
          <div class="operator-profile__edit-group">
            <label for="operator-name" class="operator-profile__edit-text">Имя</label>
            <input
              id="operator-name"
              type="text"
              class="operator-profile__edit-input"
              v-model="createOperatorname"
              ref="createoperatorname"
              required
            />
          </div>
          <div class="operator-profile__edit-group">
            <label for="operator-lastname" class="operator-profile__edit-text">Возраст</label>
            <input
              id="operator-lastname"
              type="number"
              class="operator-profile__edit-input"
              v-model="createOperatorAge"
              ref="createoperatorage"
              required
            />
          </div>
          <div class="operator-profile__edit-group">
            <label for="operator-lastname" class="operator-profile__edit-text">Страна</label>
            <input
              id="operator-country"
              type="text"
              class="operator-profile__edit-input"
              v-model="createOperatorCountry"
              ref="createoperatorcountry"
              required
            />
          </div>
          <div class="operator-profile__edit-group">
            <label for="operator-lastname" class="operator-profile__edit-text">Город</label>
            <input
              id="operator-city"
              type="text"
              class="operator-profile__edit-input"
              v-model="createoperatorCity"
              ref="createoperatorcity"
              required
            />
          </div>
          <div class="operator-profile__edit-group">
            <label for="operator-lastname" class="operator-profile__edit-text">Код страны</label>
            <input
              id="operator-code"
              type="text"
              class="operator-profile__edit-input"
              v-model="createoperatorCityCode"
              ref="createoperatorcitycode"
              required
            />
          </div>
          <h2 class="operator-profile__search-title">Параметры личного поиска</h2>
          <div class="operator-profile__edit-group operator-profile__edit-group--age">
            <span class="operator-profile__edit-text">Возраст от</span>
            <input
              id="operator-search-start"
              type="number"
              min="18"
              max="99"
              class="operator-profile__edit-input operator-profile__edit-input--age"
              v-model="createSearchStart"
              ref="createsearchstart"
              required
            />
            <span class="operator-profile__edit-text operator-profile__edit-text--age">до</span>
            <input
              id="operator-search-finish"
              type="number"
              min="18"
              max="99"
              class="operator-profile__edit-input operator-profile__edit-input--age"
              v-model="createSearchFinish"
              ref="createsearchfinish"
              required
            />
            <div class="operator-profile__edit-group operator-profile__edit-group--gender">
              <span class="operator-profile__edit-text">Пол:</span>
              <div class="popup__radio">
                <input required type="radio" id="user_gender-zero" value="0" v-model="operatorData.gender" />
                <label for="user_gender-zero" class="operator-profile__edit-text">Все</label>
              </div>
              <div class="popup__radio">
                <input required type="radio" id="user_gender-one" value="1" v-model="operatorData.gender" />
                <label for="user_gender-one" class="operator-profile__edit-text">М</label>
              </div>
              <div class="popup__radio">
                <input required type="radio" id="user_gender-two" value="2" v-model="operatorData.gender" />
                <label for="user_gender-two" class="operator-profile__edit-text">Ж</label>
              </div>
            </div>
          </div>
          <div class="operator-profile__edit-group operator-profile__edit-group--btns">
            <button
              type="submit"
              class="operator-profile__edit-action-btn operator-profile__edit-action-btn--save"
            >
              <span class="operator-profile__edit-action-btn-text">Изменить</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapActions } from 'vuex'
import {
  REQUEST_EDIT_OPERATOR_NAME,
  REQUEST_EDIT_OPERATOR_AGE,
  REQUEST_EDIT_OPERATOR_SEARCH,
  REQUEST_CHANGE_COUNTRY_OPERATOR
} from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import getImgLinkFront from '@/components/mixins/getImgCdnLink'
import { EDIT_USER_OPERATOR_NAME } from '@/constants/event'

export default {
  name: 'EditOperatorUserName',
  data: () => ({
    EDIT_USER_OPERATOR_NAME,
    currencyOptions: false,
    oldData: {},
    operatorData: {
      name: '',
      age: 0,
      finish: 0,
      start: 0,
      gender: 0,
      country: '',
      city: '',
      country_code: ''
    }
  }),
  components: {
    Popup
  },
  computed: {
    createOperatorname: {
      get() {
        return this.oldData.user_name
      },
      set(name) {
        this.$refs.createoperatorname.value = name
        this.operatorData.name = name
      }
    },
    createOperatorAge: {
      get() {
        return this.oldData.age
      },
      set(age) {
        this.$refs.createoperatorage.value = age
        this.operatorData.age = age
      }
    },
    createoperatorCity: {
      get() {
        return this.oldData.user_city
      },
      set(city) {
        this.$refs.createoperatorcity.value = city
        this.operatorData.city = city
      }
    },
    createoperatorCityCode: {
      get() {
        return this.oldData.user_country.code
      },
      set(code) {
        this.$refs.createoperatorcitycode.value = code
        this.operatorData.country_code = code
      }
    },
    createOperatorCountry: {
      get() {
        return this.oldData.user_country.name
      },
      set(country) {
        this.$refs.createoperatorcountry.value = country
        this.operatorData.country = country
      }
    },
    createSearchFinish: {
      get() {
        if (this.oldData.user_search) {
          return this.oldData.user_search.age_max
        } else {
          return this.operatorData.finish ? this.operatorData.finish : '0'
        }
      },
      set(finish) {
        this.$refs.createsearchfinish.value = finish
        this.operatorData.finish = finish
      }
    },
    createSearchStart: {
      get() {
        if (this.oldData.user_search) {
          return this.oldData.user_search.age_min
        } else {
          return this.operatorData.start ? this.operatorData.start : '0'
        }
      },
      set(start) {
        this.$refs.createsearchstart.value = start
        this.operatorData.start = start
      }
    }
  },
  mixins: [ModalMixin, getImgLinkFront],

  methods: {
    ...mapActions({
      changeName: REQUEST_EDIT_OPERATOR_NAME,
      changeAge: REQUEST_EDIT_OPERATOR_AGE,
      changeSearch: REQUEST_EDIT_OPERATOR_SEARCH,
      changeCityCountryCode: REQUEST_CHANGE_COUNTRY_OPERATOR
    }),
    closePopup() {
      this.oldData = {}
      this.operatorData.name = ''
      this.country = ''
      this.city = ''
      this.country_code = ''
      this.operatorData.age = 0
      this.operatorData.finish = 0
      this.operatorData.start = 0
      this.operatorData.gender = 0
      this.close()
    },

    async createOperatorSubmitHandler() {
      let responses = []
      if (this.operatorData.name && this.operatorData.name !== this.oldData.user_name) {
        let newData = {
          user_id: this.oldData.id,
          name: this.operatorData.name
        }
        await this.changeName(newData).then(res => {
          if (res.status) responses.push(true)
          else false
        })
      }
      if (this.operatorData.age && +this.operatorData.age !== this.oldData.age) {
        let newData = {
          user_id: this.oldData.id,
          age: +this.operatorData.age
        }
        await this.changeAge(newData).then(res => {
          if (res.status) responses.push(true)
          else false
        })
      }
      if (
        this.operatorData.city !== this.oldData.user_city ||
        this.operatorData.country !== this.oldData.user_country.name ||
        this.operatorData.country_code !== this.oldData.user_country.code
      ) {
        let newData = {
          user_id: this.oldData.id,
          city: this.operatorData.city ? this.operatorData.city : this.oldData.user_city,
          country: this.operatorData.country ? this.operatorData.country : this.oldData.user_country.name,
          country_code: this.operatorData.country_code
            ? this.operatorData.country_code
            : this.oldData.user_country.code
        }

        await this.changeCityCountryCode(newData).then(res => {
          if (res.status) responses.push(true)
          else false
        })
      }
      if (
        (this.operatorData.finish && +this.operatorData.finish !== this.oldData.user_search.age_max) ||
        (this.operatorData.start && +this.operatorData.start !== this.oldData.user_search.age_min) ||
        +this.operatorData.gender !== undefined
      ) {
        let newData = {
          user_id: this.oldData.id,
          gender:
            +this.operatorData.gender !== this.oldData.user_search.gender
              ? +this.operatorData.gender
              : this.oldData.user_search.gender,
          age_max:
            this.operatorData.finish && +this.operatorData.finish !== this.oldData.user_search.age_max
              ? +this.operatorData.finish
              : this.oldData.user_search.age_max,
          age_min:
            this.operatorData.start && +this.operatorData.start !== this.oldData.user_search.age_min
              ? +this.operatorData.start
              : this.oldData.user_search.age_min
        }
        await this.changeSearch(newData).then(res => {
          if (res.status) responses.push(true)
          else false
        })
      }
      setTimeout(() => {
        if (responses.every(value => value)) this.$root.$emit('changeOperators', 'editSearchOpt')
        this.closePopup()
      }, 1200)
    }
  },

  mounted() {
    this.$root.$on(EDIT_USER_OPERATOR_NAME, data => {
      this.operatorData.gender = data.account.user_search.gender
      this.oldData = data.account
      this.open()
    })
  }
}
</script>
<style lang="scss" scoped>
@import './EditOperator.scss';
.operator-profile__edit-group {
  &--account {
    display: flex;
    flex-direction: column;
  }

  &--age {
    flex-direction: row;
    align-items: center;
  }
  &--gender {
    margin-left: 10px;
    flex-direction: row;
    align-items: center;
  }
}

.operator-profile__account-info {
  display: flex;
  align-items: center;
}
.operator-profile__account-wrapper {
  display: flex;
  justify-content: space-between;
}
.operator-profile__search-title {
  font-weight: 700;
  color: #a6aec0;
}
.operator-profile__edit-input--age {
  width: 20%;
  margin-left: 10px;
}
.operator-profile__edit-text--age {
  margin-left: 10px;
}
</style>
