<template>
  <ModalGenerator :visible="visible" @close="close">
    <div class="modal__content popup" :class="modalClass">
      <div class="modal__header popup-header">
        <span class="popup-header__title title-big">
          {{ title }}
        </span>
        <span v-if="description" class="popup-header__description">{{ description }}</span>
      </div>
      <div class="popup__content">
        <slot name="default"></slot>
      </div>
    </div>
  </ModalGenerator>
</template>
<script>
import RootModalMixin from '@/components/Modals/mixins/RootModalMixin'
import PopupMixin from '@/components/Modals/mixins/PopupMixin'
import ModalGenerator from '@/components/Modals/ModalGenerator'

export default {
  name: 'Popup',
  components: {
    ModalGenerator
  },
  mixins: [RootModalMixin, PopupMixin]
}
</script>

<style lang="scss">
@import './Popup.scss';
</style>
