<template>
  <div class="app-user-pay">
    <div class="app-user-info app-user-info--deposit">
      <AppUserDeposit
        v-if="!errorMessageDeposit"
        :userDeposit="userDeposit"
        :openDeposit="openDeposit"
        @changeActive="changeActive"
        :paymentSystemText="paymentSystemText"
      />
      <div v-else class="app-user__error app-user__error--big">
        <span class="app-user__error-text">Ошибка при загрузке депозитов</span>
      </div>
    </div>
    <div class="app-user-info app-user-info--payouts">
      <AppUserPayouts
        v-if="!errorMessagePayouts"
        :userPayouts="userPayouts"
        :openPayout="openPayout"
        @changeActive="changeActive"
        :paymentSystemText="paymentSystemText"
      />
      <div v-else class="app-user__error app-user__error--big">
        <span class="app-user__error-text">Ошибка при загрузке выводов</span>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from '@/components/mixins/toLocaleformat'
import getImgLink from '@/components/mixins/getImgCdnLink'
import AppUserDeposit from '@/components/App-user/AppUserDeposit'
import AppUserPayouts from '@/components/App-user/AppUserPayouts'

export default {
  name: 'AppUserPay',
  components: {
    AppUserDeposit,
    AppUserPayouts
  },
  props: {
    userPayouts: {
      type: Array
    },
    userDeposit: {
      type: Array
    },
    errorMessageDeposit: {
      type: Boolean
    },
    errorMessagePayouts: {
      type: Boolean
    }
  },
  mixins: [dateFormat, getImgLink],

  data: () => ({
    openDeposit: false,
    openPayout: false
  }),

  computed: {},
  methods: {
    changeActive(data) {
      if (this.openDeposit && data === 'deposit') {
        this.openDeposit = false
      } else if (!this.openDeposit && data === 'deposit') {
        this.openDeposit = true
        this.openPayout = false
      }
      if (this.openPayout && data === 'payout') {
        this.openPayout = false
      } else if (!this.openPayout && data === 'payout') {
        this.openPayout = true
        this.openDeposit = false
      }
    },

    paymentSystemText(status) {
      let statusText = ''
      switch (status) {
        case 0:
          statusText = 'Банковская карта'
          break
        case 1:
          statusText = 'Qiwi кошелек'
          break
        case 2:
          statusText = 'Yandex Money'
          break
        default:
          break
      }
      return statusText
    }
  }
}
</script>

<style lang="scss">
@import './App-user-info';
.fade-enter-active,
.fade-leave-active {
  transition: height 0.8s;
}
.fade-enter,
.fade-leave-to {
  height: 0;
}

.app-user-pay {
  width: 40%;
  @media (max-width: 1020px) {
    width: 100%;
    margin-top: 10px;
  }
}
.app-user-info-body--pay {
  margin-right: 0;
  display: flex;
  flex-direction: column;
}
.app-user-info--deposit {
  margin-right: 0;
}
.app-user-info--payouts {
  margin-top: 10px;
  margin-right: 0;
}
</style>
