import cookies from 'vue-cookies'
import * as RootActionTypes from './action-types'
import * as RootMutationTypes from './mutation-types'
import AuthService from '@/services/AuthService'
export default {
  [RootActionTypes.REQUEST_AUTH]: async ({ commit, dispatch }, user) => {
    try {
      commit(RootMutationTypes.SET_LOADER, true, { root: true })
      const responseAuth = await new AuthService().auth(user)
      const { data } = responseAuth
      if (data.access_token) {
        window.localStorage.setItem('access_token', data.access_token)
        cookies.set('refresh_token', data.refresh_token)
        commit(RootMutationTypes.SET_USER_TOKEN, data.access_token)
        window.localStorage.setItem('userExist', true)
        commit(RootMutationTypes.SET_LOGIN_USER, true)

        await dispatch(RootActionTypes.REQUEST_PROFILE_ID, data.access_token)

        commit(RootMutationTypes.SET_LOADER, false, { root: true })

        return data
      } else {
        return data
      }
    } catch (e) {
      window.localStorage.removeItem('access_token')
      commit(RootMutationTypes.SET_LOADER, false, { root: true })

      return { error: 'Connection error' }
    }
  },

  [RootActionTypes.REQUEST_LOGOUT]: async ({ rootGetters, commit }) => {
    const access = rootGetters.GET_TOKEN
    if (access) {
      await new AuthService(access).logout({})
      cookies.remove('refresh_token')
      window.localStorage.clear()
      commit(RootMutationTypes.LOGOUT)
      window.location.reload()
    }
  },

  async [RootActionTypes.REQUEST_REFRESH_TOKEN]({ commit }) {
    const refreshToken = () => cookies.get('refresh_token')
    const response = await new AuthService(refreshToken()).refresh()
    const { data } = response
    cookies.set('refresh_token', data.refresh_token)
    window.localStorage.setItem('access_token', data.access_token)
    commit(RootMutationTypes.SET_USER_TOKEN, data.access_token)
  },

  [RootActionTypes.REQUEST_PROFILE_ID]: async ({ rootGetters, commit }) => {
    const userToken = rootGetters.GET_TOKEN
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const response = await new AuthService(userToken).getEmployee()
    const { data, status } = response
    if (status) {
      // window.localStorage.setItem("user", JSON.stringify(data));
      commit(RootMutationTypes.SET_USER_DATA, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [RootActionTypes.REQUEST_PERMISSION_USER]: async ({ rootGetters, commit }) => {
    const userToken = rootGetters.GET_TOKEN
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const response = await new AuthService(userToken).getPermission()

    const { data, status } = response
    if (status) {
      commit(RootMutationTypes.SET_PERMISSION_USER, data.permissions)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  }
}
