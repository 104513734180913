export default {
  props: {
    visible: {
      type: Boolean
    }
  },

  methods: {
    close() {
      this.$emit('close')
    }
  }
}
