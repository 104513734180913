<template>
  <div :class="'app-user-photo'">
    <div class="app-user-photo__img">
      <div class="app-user-photo__badge-block-top">
        <div
          class="app-user-photo__badge"
          :class="{
            postponed: photo.status === -1,
            verification: photo.status === 1,
            'forbidden-avatar': photo.status === 2,
            'forbidden-show': photo.status === 3,
            'deferred-block': photo.status === 4,
            'status-age': photo.status === 6
          }"
        ></div>
        <div class="app-user-photo__badge-icon">
          <FaIcon
            v-if="photo.status !== 0"
            :iconClass="[
              photo.status === -1 ? 'fa fa-clock' : '',
              photo.status === 1 ? 'fa fa-user-check' : '',
              photo.status === 2 ? 'fa fa-user-times' : '',
              photo.status === 3 ? 'fa fa-user-slash' : '',
              photo.status === 4 ? 'fa fa-exclamation-triangle' : '',
              photo.status === 6 ? 'fa-solid fa-baby' : ''
            ]"
            class="app-user-profile__gender-icon"
          />
          <!--          <i class="app-user-photo__badge-icon-i"-->
          <!--             :class="{-->
          <!--                'fa fa-clock': photo.status === -1,-->
          <!--                'fa fa-user-check': photo.status === 1,-->
          <!--                'fa fa-user-times': photo.status === 2,-->
          <!--                'fa fa-user-slash': photo.status === 3,-->
          <!--                'fa fa-exclamation-triangle': photo.status === 4,-->
          <!--              }"-->
          <!--          ></i>-->
        </div>
      </div>
      <div v-if="photo.like_count" class="app-user-photo__badge-block-bottom">
        <div class="app-user-photo__badge"></div>
        <div class="app-user-photo__badge-icon">
          <i class="app-user-photo__badge-icon-i fa fa-heart"></i>
          <span class="app-user-photo__badge-text">{{ photo.like_count }}</span>
        </div>
      </div>
      <img
        ref="userImg"
        @click="resizeImg()"
        :src="
          getFile({
            type: 'image',
            file: photo.content_id,
            width: `100%`,
            height: `100%`
          })
        "
        alt=""
      />
    </div>
    <div class="app-user-photo__description">
      <div class="app-user-photo__employee">
        <span v-if="photo.updated_at" class="app-user-photo__moderator">{{
          new Date(photo.updated_at).toLocaleString('ru-RU')
        }}</span>
        <span v-if="photo.employee" class="app-user-photo__moderator">
          <span v-if="photo.employee.type === 'moderator'" class="app-user-photo__moderator--moderation"
            >Модерация:</span
          >
          <span v-if="photo.employee.type === 'support'" class="app-user-photo__moderator--support"
            >Поддержка:</span
          >
          {{ photo.employee.handler_name }}
        </span>
      </div>
      <button
        v-if="!photo.is_main_photo"
        class="app-user-photo__btn"
        @click="
          $root.$emit(USER_DELETE_PHOTO, {
            user_id: currentUser.id,
            photo_id: photo.id
          })
        "
      >
        <i class="app-user-photo__badge-icon-i fa fa-trash-alt"></i>
      </button>
    </div>
    <div
      v-if="photo.employee.handler_name && permissions.includes(407)"
      class="app-user-photo__actions"
      :class="{ 'app-user-photo__actions--descr-empty': photo.employee.id === null }"
    >
      <button
        @click="
          $root.$emit(USER_PHOTO_ACTION_PHOTO_STATUS_EVENT, {
            id: photo.id,
            status: 1,
            user_id: currentUser.id,
            text: 'одобрено'
          })
        "
        class="app-user-btns__btn app-user-photo__actions--approve"
        v-tooltip="'Одобрить фотографию'"
        :class="{ 'app-user-photo__actions--active opacity': photo.status === 1 }"
        :disabled="isCheckDisabled(photo.status === 1)"
      >
        <i class="fa fa-user-check"></i>
      </button>
      <button
        @click="
          $root.$emit(USER_PHOTO_ACTION_PHOTO_STATUS_EVENT, {
            id: photo.id,
            status: 2,
            user_id: currentUser.id,
            text: 'запрещено для аватара'
          })
        "
        class="app-user-btns__btn app-user-photo__actions--avatar"
        v-tooltip="'Запретить для аватара'"
        :class="{ 'app-user-photo__actions--active opacity': photo.status === 2 }"
        :disabled="isCheckDisabled(photo.status === 2)"
      >
        <i class="fa fa-user-times"></i>
      </button>
      <button
        @click="
          $root.$emit(USER_PHOTO_ACTION_PHOTO_STATUS_EVENT, {
            id: photo.id,
            status: 3,
            user_id: currentUser.id,
            text: 'запрещено для показа'
          })
        "
        class="app-user-btns__btn app-user-photo__actions--slash"
        v-tooltip="'Запретить для показа'"
        :class="{ 'app-user-photo__actions--active opacity': photo.status === 3 }"
        :disabled="isCheckDisabled(photo.status === 3)"
      >
        <i class="far fa-eye-slash"></i>
      </button>
      <button
        @click="
          $root.$emit(USER_PHOTO_ACTION_PHOTO_STATUS_EVENT, {
            id: photo.id,
            status: 4,
            user_id: currentUser.id,
            text: 'временная блокировка фото'
          })
        "
        class="app-user-btns__btn app-user-photo__actions--block"
        v-tooltip="'Временно заблокировать фото'"
        :class="{ 'app-user-photo__actions--active opacity': photo.status === 4 }"
        :disabled="isCheckDisabled(photo.status === 4)"
      >
        <i class="fa fa-exclamation-triangle"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { USER_PHOTO_ACTION_PHOTO_STATUS_EVENT } from '@/constants/event'
import dateFormat from '@/components/mixins/toLocaleformat'
import { GET_USERCHATS_STYLES, GET_PERMISSIONS_USER } from '@/store/getter-types'
import getImgLink from '@/components/mixins/getImgCdnLink'
import BigPicture from 'bigpicture'
import { USER_DELETE_PHOTO } from '@/constants/event'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserPhoto',
  data: () => ({
    USER_DELETE_PHOTO,
    USER_PHOTO_ACTION_PHOTO_STATUS_EVENT
  }),
  props: {
    photo: {
      type: Object
    },
    currentUser: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      stylesChat: GET_USERCHATS_STYLES,
      permissions: GET_PERMISSIONS_USER
    })
  },
  mixins: [dateFormat, getImgLink],
  methods: {
    resizeImg() {
      BigPicture({ el: this.$refs.userImg })
    },
    isCheckDisabled(par) {
      return par
    }
  }
}
</script>

<style lang="scss">
@import './AppUserPhoto';
</style>
