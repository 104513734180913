const REQUEST_ADD_AUTO_MESSAGES = 'REQUEST_ADD_AUTO_MESSAGES'
const REQUEST_DELETE_AUTO_MESSAGES = 'REQUEST_DELETE_AUTO_MESSAGES'
const REQUEST_CHANGE_AUTO_MESSAGES = 'REQUEST_CHANGE_AUTO_MESSAGES'
const REQUEST_AUTO_MESSAGES = 'REQUEST_AUTO_MESSAGES'
const REQUEST_AUTO_MESSAGES_PAGINATION = 'REQUEST_AUTO_MESSAGES_PAGINATION'

export {
  REQUEST_ADD_AUTO_MESSAGES,
  REQUEST_AUTO_MESSAGES_PAGINATION,
  REQUEST_DELETE_AUTO_MESSAGES,
  REQUEST_CHANGE_AUTO_MESSAGES,
  REQUEST_AUTO_MESSAGES
}
