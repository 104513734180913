<template>
  <div>
    <div class="operators-statistic__header">
      <span class="operators-statistic__header-title">Статистика оператора — {{ currentOperator.fio }} </span>
    </div>
    <router-link
      :to="{ name: 'operators-payments' }"
      class="operators-statistic__btn operators-statistic__btn--default"
    >
      <i class="fas fa-arrow-circle-left"></i>
      Список операторов
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'OperatorsPaymentsHeaders',
  props: {
    currentOperator: {
      type: Object
    }
  }
}
</script>
<style lang="scss">
@import 'OperatorStatistic';
</style>
