<template>
  <div class="app-user" v-click-outside="close">
    <div class="app-user__field">
      <div class="app-user__avatar">
        <div class="app-user-photos">
          <span>{{ user.user_count.photo }}</span>
        </div>
        <div class="app-user-verifications" v-if="user.user_verification === 2">
          <FaIcon :iconClass="'fa fa-shield-alt'" class="app-user-verification__badge-icon-i" />
        </div>
        <div class="app-user-delete" v-if="user.user_deleted >= 0">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </div>
        <div v-if="user.user_deleted == -1" class="app-user__online">
          <div
            class="app-user__online-inner"
            :class="{
              'app-user__online-off': dateOnline >= 900,
              'app-user__online-recently': dateOnline > 300 && dateOnline < 900,
              'app-user__online-on': dateOnline <= 300
            }"
          ></div>
        </div>
        <img
          :src="getImgLinkCropped(user.user_avatar.content_id, 480, { ...user.user_avatar.crop })"
          alt=""
        />
      </div>
      <div class="app-user__name">
        <router-link
          v-if="user_permissions.includes(103)"
          :to="{ name: 'UserItem', params: { userId: user.id, user } }"
          class="app-user__name-link"
          target="_blank"
        >
          <span class="app-user__name-text">{{ user.user_name }}, </span>
          <span class="app-user__name-text">({{ user.age || 0 }})</span>
        </router-link>
        <div v-else class="app-user__name-not-link">
          <span class="app-user__name-text">{{ user.user_name }}, </span>
          <span class="app-user__name-text">({{ user.age || 0 }})</span>
        </div>
        <span v-if="user.user_is_operator" class="app-user__name-operator">
          <FaIcon :iconClass="'fa-solid fa-street-view'" v-tooltip="'Оператор'" />
        </span>
        <div class="app-user__socials">
          <a
            v-if="user.user_vkontakte"
            class="app-user__socials-btn info-item"
            :href="`http://vk.com/id${user.user_vkontakte}`"
            target="_blank"
          >
            <i class="app-user__socials-icon fab fa-vk"></i>
          </a>
          <a
            v-if="user.user_facebook"
            class="app-user__socials-btn info-item"
            :href="`http://facebook.com/profile.php?id=${user.user_facebook}`"
            target="_blank"
          >
            <i class="app-user__socials-icon fab fa-facebook"></i>
          </a>
          <div v-if="isVip(user.user_vip.expire)" class="app-user__socials-btn info-item">
            <i class="fas fa-crown app-user__socials-icon"></i>
            <!--            :class="{-->
            <!--            'app-user__socials-icon-vip': user.user_vip.expire > 0-->
            <!--            }"-->
            <!--            <FaIcon-->
            <!--              :iconClass="'fas fa-crown'"-->
            <!--              class="app-user__socials-icon"-->
            <!--              :class="{-->
            <!--                'app-user__socials-icon-vip': user.user_vip.expire > 0,-->
            <!--              }"-->
            <!--              v-tooltip="'VIP-статус'"-->
            <!--            />-->
          </div>
          <div
            class="app-user-moderator"
            v-if="user.user_avatar.moderation !== null || user.user_ban !== null"
          >
            {{ isBanned !== null ? isBanned : '' }}
          </div>
        </div>
      </div>
      <div class="app-user__wrapper">
        <div class="app-user__gender">
          <i
            :class="{
              'fa fa-mars app-user__gender-icon--male': user.user_gender === 1,
              'fa fa-venus app-user__gender-icon--female': user.user_gender === 2
            }"
            class="app-user-profile__gender-icon"
          >
          </i>
        </div>
        <div class="app-user__ban" v-if="user.user_ban && currentBan">
          <div app-user__ban-item>
            <i class="app-user-profile__ban-icon fas fa-portrait fa-lg"></i>
            <i class="app-user-profile__ban-icon fas fa-mobile-alt fa-lg"></i>
          </div>

          <span class="app-user-profile__ban-text">{{ toLocaleDateUser(user.user_ban) }}</span>
        </div>
        <div v-else class="app-user__ban">
          <div
            v-bind:class="{
              'app-user__ban-photo-wait': user.user_avatar.moderation.status === 0,
              'app-user__ban-photo-clock': user.user_avatar.moderation.status === -1,
              'app-user__ban-photo-verificate': user.user_avatar.moderation.status === 1,
              'app-user__ban-photo-times': user.user_avatar.moderation.status === 2,
              'app-user__ban-photo-slash': user.user_avatar.moderation.status === 3,
              'app-user__ban-photo-triangle': user.user_avatar.moderation.status === 4,
              'app-user__ban-photo-banned': user.user_avatar.moderation.status === 5,
              'app-user__ban-photo-baby': user.user_avatar.moderation.status === 6
            }"
            v-if="user.user_avatar.moderation"
          >
            <!-- class="app-user__ban-photo-verificate" -->
            <FaIcon
              class="app-user__ban-photo"
              :iconClass="[
                user.user_avatar.moderation.status === 0 ? 'fa-solid fa-stopwatch' : '',
                user.user_avatar.moderation.status === -1 ? 'fa fa-clock' : '',
                user.user_avatar.moderation.status === 1 ? 'fa fa-user-check' : '',
                user.user_avatar.moderation.status === 2 ? 'fa fa-user-times' : '',
                user.user_avatar.moderation.status === 3 ? 'fa fa-user-slash' : '',
                user.user_avatar.moderation.status === 4 ? 'fa fa-exclamation-triangle' : '',
                user.user_avatar.moderation.status === 5 ? 'fa fa-mobile' : '',
                user.user_avatar.moderation.status === 6 ? 'fa-solid fa-baby' : ''
              ]"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="app-user__field">
      <span class="app-user__adress-text info-item">{{ user.user_city }}</span>
    </div>
    <div class="app-user__field">
      <div class="app-user__icons-group">
        <div class="app-user__icons-subgroup app-user__icons-subgroup--one-col">
          <span class="app-user__item info-item">
            <svg
              v-tooltip="'Баллов Skylove'"
              class="app-user__item-icon app-user__item-icon--small app-user__item-icon--slcoins"
              width="21"
              height="16"
              viewBox="0 0 27 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.80449 22.9982L2.8042 23L12.833 23L12.4997 25H16.4997L16.8333 22.9982C19.3028 22.9634 21.2558 22.4073 22.6921 21.3301C24.1862 20.2168 25.0016 18.6299 25.1383 16.5693C25.2067 15.4951 25.0065 14.5771 24.5378 13.8154C24.0788 13.0439 23.4342 12.5117 22.6042 12.2187C24.9186 11.2812 26.1491 9.68945 26.2956 7.44336C26.4128 5.62695 25.8122 4.21582 24.4938 3.20996C23.472 2.43032 22.0423 1.94989 20.2049 1.76868L20.4997 0H16.4997L16.219 1.68413L6.19193 1.67365L6.17609 1.76868C8.01349 1.94988 9.44313 2.43031 10.465 3.20996C11.7834 4.21582 12.384 5.62695 12.2668 7.44336C12.1203 9.68945 10.8898 11.2812 8.57536 12.2188C9.40544 12.5117 10.05 13.0439 10.509 13.8154C10.9777 14.5771 11.1779 15.4951 11.1095 16.5693C10.9728 18.6299 10.1574 20.2168 8.66325 21.3301C7.22694 22.4073 5.27402 22.9634 2.80449 22.9982ZM13.7272 19.0449L14.6208 13.9326L18.3122 13.9473C19.8356 14.0254 20.4606 14.8799 20.1872 16.5107C20.07 17.2822 19.6989 17.9023 19.0739 18.3711C18.4489 18.8301 17.6676 19.0596 16.7301 19.0596L13.7272 19.0449ZM17.5505 10.5781L15.1921 10.5635L16.0563 5.6416L18.7223 5.65625C20.6169 5.66601 21.4469 6.47168 21.2126 8.07324C20.9489 9.69434 19.7282 10.5293 17.5505 10.5781ZM0 19.0532L0.893555 13.9409L4.58496 13.9556C6.1084 14.0337 6.7334 14.8882 6.45996 16.519C6.34277 17.2905 5.97168 17.9106 5.34668 18.3794C4.72168 18.8384 3.94043 19.0679 3.00293 19.0679L0 19.0532ZM3.82324 10.5864L1.46484 10.5718L2.3291 5.6499L4.99512 5.66455C6.88965 5.67432 7.71973 6.47998 7.48535 8.08154C7.22168 9.70264 6.00098 10.5376 3.82324 10.5864Z"
                fill="#A6AEC0"
              />
            </svg>
            <span class="app-user__item-value">{{
              user.user_finance.points ? user.user_finance.points : '0'
            }}</span>
          </span>
          <span class="app-user__item info-item">
            <FaIcon
              :iconClass="'fas fa-wallet'"
              class="app-user__item-icon app-user__item-icon--rub"
              v-tooltip="'Сумма покупок'"
            />
            <div class="app-user__item-value">
              <span class="app-user__item-value--green">{{
                user.purchases.sum.RUB ? Math.round(user.purchases.today_sum.RUB * 100) / 100 : '0'
              }}</span>
              <span class="app-user__item-value--blue">{{
                user.purchases.sum.RUB ? Math.round(user.purchases.sum.RUB * 100) / 100 : '0'
              }}</span>
            </div>
          </span>
        </div>
      </div>

      <div class="app-user__icons-group">
        <div class="app-user__icons-subgroup app-user__icons-subgroup--one-col">
          <span class="app-user__item info-item">
            <FaIcon
              :iconClass="'fas fa-donate'"
              class="app-user__item-icon app-user__item-icon--rub"
              v-tooltip="'Сумма депозитов'"
            />
            <span class="app-user__item-value">{{
              user.deposits.sum.RUB ? Math.round(user.deposits.sum.RUB * 100) / 100 : '0'
            }}</span>
          </span>
          <span class="app-user__item info-item">
            <FaIcon
              :iconClass="'fas fa-hand-holding-usd'"
              class="app-user__item-icon app-user__item-icon--rub"
              v-tooltip="'Сумма выводов'"
            />
            <span class="app-user__item-value">{{
              user.payouts.sum.RUB ? Math.round(user.payouts.sum.RUB * 100) / 100 : '0'
            }}</span>
          </span>
        </div>
      </div>

      <div class="app-user__icons-group">
        <div class="app-user__icons-subgroup app-user__icons-subgroup--one-col">
          <span class="app-user__item info-item">
            <FaIcon
              :iconClass="'fa-solid fa-comment-dots'"
              class="app-user__item-icon app-user__item-icon--list"
              v-tooltip="'Кол-во отправленных сообщений'"
            />
            <span class="app-user__item-value">{{ user.user_count.chat_out }}</span>
          </span>
          <span class="app-user__item info-item">
            <FaIcon
              :iconClass="'far fa-list-alt'"
              class="app-user__item-icon app-user__item-icon--list"
              v-tooltip="'Чаты'"
            />
            <span class="app-user__item-value">{{ user.user_count.chat }}</span>
          </span>
        </div>
      </div>
      <div class="app-user__icons-group">
        <div class="app-user__icons-subgroup app-user__icons-subgroup--one-col">
          <span class="app-user__item info-item">
            <FaIcon
              :iconClass="'fas fa-bullhorn'"
              class="app-user__item-icon app-user__item-icon--bullhorn"
              v-tooltip="'Жалобы'"
            />
            <span class="app-user__item-value">{{
              user.user_count.clain + user.user_count.clain_event
            }}</span>
          </span>
          <span class="app-user__item info-item">
            <FaIcon
              :iconClass="'fas fa-exclamation-triangle'"
              class="app-user__item-icon"
              v-tooltip="'Предупреждения'"
            />
            <span class="app-user__item-value">{{ user.user_count.ban }}</span>
          </span>
        </div>
      </div>
      <div class="app-user__icons-group">
        <div class="app-user__icons-subgroup app-user__icons-subgroup--one-col">
          <span class="app-user__item info-item">
            <FaIcon
              :iconClass="'fas fa-gift'"
              class="app-user__item-icon app-user__item-icon--gift"
              v-tooltip="'Подарки'"
            />
            <span class="app-user__item-value">{{ user.user_count.gift }}</span>
          </span>
          <span class="app-user__item info-item">
            <FaIcon
              :iconClass="'fas fa-user-plus'"
              class="app-user__item-icon app-user__item-icon--event"
              v-tooltip="'События'"
            />
            <span class="app-user__item-value">{{ user.user_count.event }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="app-user__field">
      <div class="app-user__upper">
        <div class="app-user_confidential">
          <div class="app-user_confidential-wrapp">
            <span class="app-user__confidential_icon">
              <FaIcon
                :iconClass="'fa-solid fa-location-arrow'"
                :class="
                  user.user_confidential.locate
                    ? 'app-user__confidential_icon-green'
                    : 'app-user__confidential_icon-grey'
                "
                v-tooltip="'Определять местоположени'"
              />
            </span>
            <span class="app-user__confidential_icon">
              <FaIcon
                :iconClass="'fa-solid fa-map'"
                :class="
                  user.user_confidential.map_visible
                    ? 'app-user__confidential_icon-green'
                    : 'app-user__confidential_icon-grey'
                "
                v-tooltip="'Другие видят меня на карте'"
              />
            </span>
          </div>
          <div class="app-user_confidential-wrapp">
            <span class="app-user__confidential_icon">
              <FaIcon
                :iconClass="'fa-solid fa-gift'"
                :class="
                  user.user_confidential.gifts_visible
                    ? 'app-user__confidential_icon-green'
                    : 'app-user__confidential_icon-grey'
                "
                v-tooltip="'Другие видят от кого подарок'"
              />
            </span>
            <span class="app-user__confidential_icon">
              <FaIcon
                :iconClass="'fa-solid fa-user-secret'"
                :class="
                  user.user_confidential.incognito
                    ? 'app-user__confidential_icon-green'
                    : 'app-user__confidential_icon-grey'
                "
                v-tooltip="'Просмотр анкет инкогнито'"
              />
            </span>
          </div>
          <div class="app-user_confidential-wrapp">
            <span class="app-user__confidential_icon">
              <FaIcon
                :iconClass="'fa-solid fa-globe'"
                :class="
                  user.user_confidential.vip_map_invisible
                    ? 'app-user__confidential_icon-green'
                    : 'app-user__confidential_icon-grey'
                "
                v-tooltip="'VIP не видят меня на карте'"
              />
            </span>
            <span class="app-user__confidential_icon">
              <FaIcon
                :iconClass="'fa-solid fa-comment-dollar'"
                :class="
                  user.user_confidential.message_from_vip
                    ? 'app-user__confidential_icon-green'
                    : 'app-user__confidential_icon-grey'
                "
                v-tooltip="'Принимать сообщения только от VIP пользователей'"
              />
            </span>
          </div>
          <div class="app-user_confidential-wrapp">
            <span class="app-user__confidential_icon">
              <FaIcon
                :iconClass="'fa-solid fa-envelope'"
                :class="
                  user.user_confidential.message_from_verified
                    ? 'app-user__confidential_icon-green'
                    : 'app-user__confidential_icon-grey'
                "
                v-tooltip="'Принимать сообщения только от верифицированных пользователей'"
              />
            </span>
            <span class="app-user__confidential_icon">
              <FaIcon
                :iconClass="'fa-solid fa-robot'"
                :class="
                  user.user_auto_dating
                    ? 'app-user__confidential_icon-green'
                    : 'app-user__confidential_icon-grey'
                "
                v-tooltip="'Автознакомства'"
              />
            </span>
          </div>
          <div class="app-user_confidential-wrapp">
            <span class="app-user__notificate_icon">
              <FaIcon
                :iconClass="'fa-solid fa-handshake'"
                :class="
                  user.user_push.match ? 'app-user__notificate_icon-green' : 'app-user__notificate_icon-grey'
                "
                v-tooltip="'Твои совпадения'"
              />
            </span>
            <span class="app-user__notificate_icon">
              <FaIcon
                :iconClass="'fa-solid fa-comment'"
                :class="
                  user.user_push.message
                    ? 'app-user__notificate_icon-green'
                    : 'app-user__notificate_icon-grey'
                "
                v-tooltip="'Сообщение в чате'"
              />
            </span>
          </div>
          <div class="app-user_confidential-wrapp">
            <span class="app-user__notificate_icon">
              <FaIcon
                :iconClass="'fa-solid fa-heart'"
                :class="
                  user.user_push.like ? 'app-user__notificate_icon-green' : 'app-user__notificate_icon-grey'
                "
                v-tooltip="'Вы понравились'"
              />
            </span>
            <span class="app-user__notificate_icon">
              <FaIcon
                :iconClass="'fa-solid fa-eye'"
                :class="
                  user.user_push.like ? 'app-user__notificate_icon-green' : 'app-user__notificate_icon-grey'
                "
                v-tooltip="'Просмотры'"
              />
            </span>
          </div>
          <div class="app-user_confidential-wrapp">
            <span class="app-user__notificate_icon">
              <FaIcon
                :iconClass="'fa-solid fa-gifts'"
                :class="
                  user.user_push.gift ? 'app-user__notificate_icon-green' : 'app-user__notificate_icon-grey'
                "
                v-tooltip="'Подарки'"
              />
            </span>
            <span class="app-user__notificate_icon">
              <FaIcon
                :iconClass="'fa-solid fa-trophy'"
                :class="
                  user.user_push.achieve
                    ? 'app-user__notificate_icon-green'
                    : 'app-user__notificate_icon-grey'
                "
                v-tooltip="'Достижения'"
              />
            </span>
          </div>
          <div class="app-user_confidential-wrapp">
            <span class="app-user__notificate_icon">
              <FaIcon
                :iconClass="'fa-solid fa-signal'"
                :class="
                  user.user_push.like_online
                    ? 'app-user__notificate_icon-green'
                    : 'app-user__notificate_icon-grey'
                "
                v-tooltip="'Ваша симпатия в сети'"
              />
            </span>
            <span class="app-user__notificate_icon">
              <FaIcon
                :iconClass="'fa-solid fa-child'"
                :class="
                  user.user_push.favorite_online
                    ? 'app-user__notificate_icon-green'
                    : 'app-user__notificate_icon-grey'
                "
                v-tooltip="'Избранный пользователь в сети'"
              />
            </span>
          </div>
          <div class="app-user_confidential-wrapp">
            <span class="app-user__notificate_icon">
              <FaIcon
                :iconClass="'fas fa-phone'"
                :class="
                  user.user_confidential.incoming_call
                    ? 'app-user__notificate_icon-green'
                    : 'app-user__notificate_icon-grey'
                "
                v-tooltip="'Принимать звонки только от VIP'"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="app-user__field">
      <span class="app-user__date-group info-item">
        <span class="app-user__key">Рег.:</span>
        <span class="app-user__value"
          >{{ toLocaleDateUserWithoutTime(user.user_create) }} {{ localTime }}</span
        >
      </span>
      <span class="app-user__date-group info-item">
        <span class="app-user__key">Вход:</span>
        <span class="app-user__value">{{ toLocaleDateUserWithoutTime(user.user_online) }} {{ lastIn }} </span>
      </span>
    </div>
    <div class="app-user__field">
      <div class="app-user__field-utm">
        <p>{{ user.user_utm.media_source }}</p>
      </div>
    </div>
    <div class="app-user__field">
      <span class="app-user__version-group">
        <span class="app-user__value info-item"
          >{{ user.device ? user.device : '-' }} / {{ user.app_version ? user.app_version : '-' }}</span
        >
      </span>
      <div class="app-user__version-body" v-if="user.devices && user.devices.length > 0">
        <button
          @click="versionsListOpen = !versionsListOpen"
          v-if="user.devices.length > 1"
          class="app-user__version-btn info-item"
        >
          показать ещё...
        </button>
        <ul v-if="versionsListOpen" class="app-user__version-list">
          <li class="app-user__version-item" v-for="device in user.devices" :key="device.id">
            {{ device.device }} {{ device.app_version }}
          </li>
        </ul>
      </div>
    </div>
    <div class="app-user__field">
      <div class="app-user__field--btns">
        <router-link
          v-if="user_permissions.includes(103)"
          class="app-user__active"
          title="Профиль пользователя"
          :to="{ name: 'UserItem', params: { userId: user.id }, user }"
          target="_blank"
        >
          <FaIcon :iconClass="'fas fa-pencil-alt'" />
        </router-link>
        <div v-else class="app-user__active"></div>
        <button
          class="app-user__active"
          v-if="user.operator_user_dialogs && user_permissions.includes(101)"
          :disabled="!different"
          @click="redirectHandler(user.id)"
        >
          <FaIcon :iconClass="'fas fa-book'" />
        </button>
        <!-- <FaIcon
          v-if="user.operator_user_dialogs"
          @click="redirectHandler(user.id)"
          :iconClass="'fas fa-book'"
        /> -->
        <!-- </router-link> -->
        <div v-if="!different" class="attention-wrapp">
          <transition name="fade">
            <div class="attention-wrapp_content">
              <p>
                Данные по пользователю {{ user.user_name }} ещё обрабатываются! Подождите некоторое время!
              </p>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import dateFormat from '@/components/mixins/toLocaleformat'
import getImgCdnLink from '@/components/mixins/getImgCdnLink'
import { GET_USER_GENDER, GET_PERMISSIONS_USER } from '@/store/getter-types'
import { REQUEST_USER_STATISTIC_ADVANCED } from '@/store/action-types'
import { SET_LOADER } from '@/store/mutation-types'
import moment from 'moment'
import Permissions from '@/constants/permissions.js'
export default {
  name: 'appUser',
  props: {
    user: {
      type: Object
    }
  },
  data: () => ({
    versionsListOpen: false,
    employeeBan: '',
    employeeVerificate: '',
    different: true
  }),

  computed: {
    ...mapGetters({
      gender: GET_USER_GENDER,
      user_permissions: GET_PERMISSIONS_USER
    }),
    dateOnline() {
      let date1 = new Date().getTime() / 1000
      let date2 = Date.parse(moment(this.user.user_online)) / 1000
      return date1 - date2
    },
    localTime() {
      const date = new Date(this.user.user_create).toLocaleTimeString()
      const newDate = date.slice(0, 5)
      return newDate
    },
    lastIn() {
      const date = new Date(this.user.user_online).toLocaleTimeString()
      const newDate = date.slice(0, 5)
      return newDate
    },
    currentBan() {
      const ban = new Date(this.user.user_ban).getTime()
      const dateNow = new Date().getTime()
      if (ban < dateNow) {
        return false
      } else {
        return true
      }
    },
    isBanned() {
      const ban = new Date(this.user.user_ban).getTime()
      const dateNow = new Date().getTime()
      if (ban > dateNow) {
        return this.user.user_banned.admin_fio != null ? `Модератор: ${this.user.user_banned.admin_fio}` : ''
      } else {
        return this.user.user_avatar.moderation !== null && this.user.user_avatar.moderation.moderator
          ? `Модератор: ${this.user.user_avatar.moderation.moderator}`
          : ''
      }
    }
  },
  mixins: [dateFormat, getImgCdnLink],
  methods: {
    ...mapActions({
      checkData: REQUEST_USER_STATISTIC_ADVANCED
    }),
    ...mapMutations({
      loader: SET_LOADER
    }),
    close() {
      this.versionsListOpen = false
    },
    isVip(vip) {
      return vip > new Date().getTime() / 1000.0 ? true : false
    },
    redirectHandler(user_id) {
      this.checkData({ user_id: user_id }).then(res => {
        const { data, status } = res
        if (status && data.length !== 0) {
          let newTab = this.$router.resolve({
            name: 'UserStatisticAdvanced',
            params: { id: user_id }
          })
          this.loader(false)
          window.open(newTab.href, '_blank')
        } else {
          this.different = false
          setTimeout(() => {
            this.different = true
          }, 4500)
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import './App-user.scss';
.app-user__item-value--small,
.app-user__item-icon--small {
  font-size: 12px;
  width: 20px;
}

.attention-wrapp_content {
  display: flex;
  justify-content: center;
  border-radius: 4px;
  color: #ff5656;
  z-index: 100;
  align-items: center;
  position: absolute;
  height: 50px;
  top: 20px;
  right: 34%;
  background-color: #ffffff;
  width: 32%;

  opacity: 1;
  animation: show 4.5s 1;
  animation-fill-mode: backwards;
}
@keyframes show {
  0% {
    opacity: 0;
    transform: translateX(90%);
  }
  25% {
    opacity: 1;
    transform: translateX(100%);
  }
  50% {
    opacity: 1;
    transform: translateX(100%);
  }
  75% {
    opacity: 1;
    transform: translateX(100%);
  }
  100% {
    opacity: 0;
    transform: translateX(90%);
  }
}

.info-item--bold {
  //width: 100%;
  color: #428bca;
  font-weight: 700;
  padding-top: 2px;
  //margin-top: 5px;
  border-top: 1px solid #ccc;
}

.app-user__icons-small {
  align-items: flex-start;
}
.app-user__name {
  width: 46%;
}
.app-user__wrapper {
  width: 30%;
  font-size: 20px;
  display: flex;
}

.app-user__gender {
  margin-top: 15px;
}
// .app-user__ban {
//   font-size: 11px;
//   margin-right: 10px;
//   display: flex;
//   width: 62%;
//   flex-direction: column;
//   justify-content: center;
//   align-items: flex-end;
//   &-photo {
//     &-verificate {
//       display: flex;
//       align-items: center;
//       color: #78cd51;
//       font-size: 20px;
//     }
//     &-times {
//       span {
//         font-size: 20px;
//         color: #ffa837;
//       }
//     }
//     &-clock {
//       span {
//         color: #e48800;
//         font-size: 20px;
//       }
//     }
//     &-slash {
//       span {
//         color: #ec1515;
//         font-size: 20px;
//       }
//     }
//     &-triangle {
//       span {
//         font-size: 20px;
//         color: #41cac0;
//       }
//     }
//     &-banned {
//       span {
//         color: #ec1515;
//         font-size: 20px;
//       }
//     }
//     &-baby {
//       span {
//         color: #ff8adc;
//         font-size: 20px;
//       }
//     }
//   }
// }
.app-user-profile__ban-icon {
  margin-right: 2px;
  color: #b5430d;
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.app-user-profile__ban-text {
  display: block;
  margin-top: 4px;
  font-size: 11px;
  color: #b5430d;
  text-align: center;
  margin-right: -30px;
}
.app-user_confidential-wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20px;
}

.app-user__field--btns {
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: space-around;
  margin-left: 6px;
}
</style>
