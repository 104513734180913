<template>
  <div class="user-wrapper">
    <div class="user-confidential">
      <h1 class="user-confidential__title">Конфиденциальность</h1>
      <ul class="user-confidential__list">
        <li class="user-confidential__item">
          <span class="user-confidential__text">Определять местоположение</span>
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.confidential.locate,
              'fa fa-times user-icon--red': !currentUser.confidential.locate
            }"
          ></i>
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Другие видят меня на карте</span>
          <FaIcon :iconClass="['fa-check user-icon--green']" v-if="visibleMap" />
          <FaIcon :iconClass="['fa-times user-icon--red']" v-else />
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Другие видят от кого подарок</span>
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.confidential.gifts_visible,
              'fa fa-times user-icon--red': !currentUser.confidential.gifts_visible
            }"
          ></i>
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Просмотр анкет инкогнито</span>
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.confidential.incognito,
              'fa fa-times user-icon--red': !currentUser.confidential.incognito
            }"
          ></i>
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Принимать звонки только от VIP</span>
          <FaIcon :iconClass="['fa-check user-icon--green']" v-if="onlyVipCall" />
          <FaIcon :iconClass="['fa-times user-icon--red']" v-else />
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text">VIP не видят меня на карте</span>
          <FaIcon :iconClass="['fa fa-check user-icon--green']" v-if="visibleVip" />
          <FaIcon :iconClass="['fa fa-times user-icon--red']" v-else />
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Принимать сообщения только от VIP пользователей</span>
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.confidential.message_from_vip,
              'fa fa-times user-icon--red': !currentUser.confidential.message_from_vip
            }"
          ></i>
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text"
            >Принимать сообщения только от верифицированных пользоватлей</span
          >
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.confidential.message_from_verified,
              'fa fa-times user-icon--red': !currentUser.confidential.message_from_verified
            }"
          ></i>
        </li>
      </ul>
    </div>
    <div class="user-pushes">
      <h1 class="user-pushes__title">Настройки уведомлений</h1>
      <ul>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Твои совпадения</span>
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.push.match,
              'fa fa-times user-icon--red': !currentUser.push.match
            }"
          ></i>
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Сообщения в чате</span>
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.push.message,
              'fa fa-times user-icon--red': !currentUser.push.message
            }"
          ></i>
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Вы понравились</span>
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.push.like,
              'fa fa-times user-icon--red': !currentUser.push.like
            }"
          ></i>
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Просмотры</span>
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.push.watch,
              'fa fa-times user-icon--red': !currentUser.push.watch
            }"
          ></i>
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Подарки</span>
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.push.gift,
              'fa fa-times user-icon--red': !currentUser.push.gift
            }"
          ></i>
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Достижения</span>
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.push.achieve,
              'fa fa-times user-icon--red': !currentUser.push.achieve
            }"
          ></i>
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Ваши симпатии онлайн</span>
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.push.like_online,
              'fa fa-times user-icon--red': !currentUser.push.like_online
            }"
          ></i>
        </li>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Избранный пользователь онлайн</span>
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.push.favorite_online,
              'fa fa-times user-icon--red': !currentUser.push.favorite_online
            }"
          ></i>
        </li>
      </ul>
    </div>
    <div class="user-auto-date">
      <h1 class="user-auto-date__title">Автознакомства</h1>
      <ul>
        <li class="user-confidential__item">
          <span class="user-confidential__text">Автознакомства</span>
          <i
            :class="{
              'fa fa-check user-icon--green': currentUser.auto_dating,
              'fa fa-times user-icon--red': !currentUser.auto_dating
            }"
          ></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppUserPushes',
  props: {
    currentUser: {
      type: Object
    }
  },

  data: () => ({
    visibleMap: false,
    visibleVip: false
  }),
  computed: {
    onlyVipCall() {
      if (this.currentUser.confidential.incoming_call_vip) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    'currentUser.confidential.vip_map_invisible'() {
      if (this.currentUser.confidential.vip_map_invisible === true) this.visibleVip = true
      else this.visibleVip = false
    },
    'currentUser.confidential.map_visible'() {
      if (this.currentUser.confidential.map_visible === true) this.visibleMap = true
      else this.visibleMap = false
    },
    'currentUser.confidential.incoming_call_vip': function() {
      return this.onlyVipCall
    }
  },

  mounted() {
    if (this.currentUser.confidential.vip_map_invisible === true) this.visibleVip = true
    else this.visibleVip = false
    if (this.currentUser.confidential.map_visible === true) this.visibleMap = true
    else this.visibleMap = false
  }
}
</script>

<style lang="scss">
.user-wrapper {
  width: 20%;
  display: flex;
  flex-direction: column;
  color: #a6aec0;
  @media (max-width: 912px) {
    margin-top: 12px;
    width: 100%;
  }
}
.user-confidential {
  background-color: white;
  border-radius: 4px;
  margin-right: 8px;
  padding: 10px;
  @media (max-width: 912px) {
    margin-right: 0;
    width: 100%;
  }
}
.user-auto-date,
.user-pushes {
  margin-top: 10px;
  background-color: white;
  border-radius: 4px;
  margin-right: 8px;
  padding: 10px;
  @media (max-width: 912px) {
    margin-top: 12px;
    margin-right: 0;
    width: 100%;
  }
}
.user-auto-date__title,
.user-confidential__title {
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
}
.user-pushes__title {
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
}
.user-confidential__list {
  margin-top: 10px;
}
.user-confidential__item {
  margin-top: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.user-confidential__text {
  font-size: 13px;
}

.user-icon--green {
  color: #78cd51;
  font-size: 12px;
}

.user-icon--red {
  /*color: #f67a6e;*/
  font-size: 12px;
}
</style>
