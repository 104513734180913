<template>
  <Popup :visible="visible" @close="closePopup" description="Параметры поиска" modalClass="popup--medium">
    <form @submit.prevent="createHandler">
      <div class="popup__item">
        <span class="popup__item-title">Пол:</span>
        <div class="popup__radio">
          <input checked type="radio" id="user_gender-one" value="1" v-model="searchOptions.gender" />
          <label for="user_gender-one">Мужской</label>
        </div>
        <div class="popup__radio">
          <input type="radio" id="user_gender-two" value="2" v-model="searchOptions.gender" />
          <label for="user_gender-two">Женский</label>
        </div>
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Возраст от:</span>
        <input
          type="number"
          min="18"
          max="99"
          class="popup__input default-input"
          v-model="searchAgeMin"
          ref="searchagemin"
          required
        />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Возраст до:</span>
        <input
          type="number"
          min="18"
          max="99"
          class="popup__input default-input"
          v-tooltip="'Возраст должен быть больше минимального возраста и быть в диапозоне от 18 до 99'"
          v-model="searchAgeMax"
          ref="searchagemax"
          required
        />
      </div>
      <div class="popup__item popup__item--group">
        <div class="operator-profile__wrapper">
          <span class="operator-profile__edit-select-title">Cтрана</span>
          <div class="operator-profile__edit-select">
            <button
              type="button"
              class="operator-profile__edit-value"
              @click="countriesOptions = !countriesOptions"
            >
              <span class="operator-profile__edit-value-text">{{
                defaultOperatorFilter({ key: 'country' })
              }}</span>
              <i class="fa fa-angle-down operator-profile__edit-select-icon"></i>
            </button>
            <ul
              class="operator-profile__edit-select-list operator-profile__edit--country"
              v-if="countriesOptions"
              @mouseleave="countriesOptions = !countriesOptions"
              :class="{ active: countriesOptions }"
            >
              <li
                v-for="country in operatorRoles.country"
                :key="country.id"
                @click="selectItemHandler({ key: 'country', value: country })"
                class="operator-profile__edit-select-item"
              >
                <span class="operator-profile__edit-select-text">{{ country.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="operator-profile__wrapper">
          <span class="operator-profile__edit-select-title">Язык</span>
          <div class="operator-profile__edit-select">
            <button type="button" class="operator-profile__edit-value" @click="langsOptions = !langsOptions">
              <span class="operator-profile__edit-value-text">{{
                defaultOperatorFilter({ key: 'langs' })
              }}</span>
              <i class="fa fa-angle-down operator-profile__edit-select-icon"></i>
            </button>
            <ul
              class="operator-profile__edit-select-list"
              v-if="langsOptions"
              @mouseleave="langsOptions = !langsOptions"
              :class="{ active: langsOptions }"
            >
              <li
                v-for="lang in langs"
                :key="lang.id"
                @click="selectItemHandler({ key: 'langs', value: lang })"
                class="operator-profile__edit-select-item"
              >
                <span class="operator-profile__edit-select-text">{{ lang.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Изменить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { SET_EDIT_SEARCH_OPTIONS, SET_OPERATOR_SETTINGS } from '@/store/mutation-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import {
  GET_OPERATOR_ROLES,
  GET_OPERATOR_TMP_ROLES,
  GET_OPERATOR_ROLES_DEFAULT,
  GET_LANGUAGES
} from '@/store/getter-types'
import { EDIT_SEARCH_OPTION_POPUP_EVENT } from '@/constants/event'

export default {
  name: 'EditSearchOptionPopupSetting',
  components: {
    Popup
  },

  mixins: [ModalMixin],
  data: () => ({
    countriesOptions: false,
    langsOptions: false,
    searchOptions: {
      gender: null,
      age_min: '',
      age_max: '',
      oldData: {}
    }
  }),

  computed: {
    ...mapGetters({
      langs: GET_LANGUAGES,
      operatorRoles: GET_OPERATOR_ROLES,
      operatorTmpRoles: GET_OPERATOR_TMP_ROLES,
      operatorRolesDefault: GET_OPERATOR_ROLES_DEFAULT
    }),
    searchAgeMin: {
      get() {
        return this.searchOptions.age_min
      },
      set(card) {
        card = card.replace(/[^\d.]/gi, '')
        this.$refs.searchagemin.value = card
        this.searchOptions.age_min = card
      }
    },
    searchAgeMax: {
      get() {
        return this.searchOptions.age_max
      },
      set(age) {
        this.$refs.searchagemax.style.borderColor = '#318bcc'
        age = age.replace(/[^\d.]/gi, '')
        this.$refs.searchagemax.value = age
        this.searchOptions.age_max = age
      }
    }
  },

  methods: {
    ...mapMutations({
      editOptions: SET_EDIT_SEARCH_OPTIONS,
      addTmpOperatorSettings: SET_OPERATOR_SETTINGS
    }),
    selectItemHandler(selectItem) {
      const { key } = selectItem
      switch (key) {
        case 'country':
          this.addTmpOperatorSettings({ ...selectItem })
          break
        case 'langs':
          this.addTmpOperatorSettings({ ...selectItem })
          break
        default:
          break
      }
      this[`${key}Options`] = false
    },

    defaultOperatorFilter(settings) {
      const { key } = settings
      const current = this.operatorTmpRoles[key].name
      return current ? current : this.operatorRolesDefault[key].name
    },

    defaultOperatorValue(settings) {
      const { key } = settings
      const current = this.operatorTmpRoles[key].name
      return current ? this.operatorTmpRoles[key].value : this.operatorRolesDefault[key].value
    },

    closePopup() {
      this.searchOptions.gender = null
      this.searchOptions.age_min = ''
      this.searchOptions.age_max = ''
      this.addTmpOperatorSettings({
        key: 'country',
        value: this.operatorRolesDefault['country'].name
      })
      this.addTmpOperatorSettings({
        key: 'langs',
        value: this.operatorRolesDefault['langs'].name
      })
      this.close()
    },
    createHandler() {
      const data = {
        new: {
          gender: +this.searchOptions.gender,
          age_min: +this.searchOptions.age_min,
          age_max: +this.searchOptions.age_max,
          country_code: this.defaultOperatorValue({ key: 'country' })
        },
        old: this.searchOptions.oldData
      }
      if (this.defaultOperatorValue({ key: 'langs' })) {
        data.new.langs = [
          {
            value: ''
          }
        ]
        data.new.langs[0].value = this.defaultOperatorValue({ key: 'langs' })
      }
      if (data.new.age_min >= data.new.age_max) {
        this.$refs.searchagemax.style.borderColor = 'red'
      } else {
        this.editOptions(data)
        this.closePopup()
      }
    }
  },
  mounted() {
    this.$root.$on(EDIT_SEARCH_OPTION_POPUP_EVENT, option => {
      this.searchOptions.gender = option.gender
      this.searchOptions.age_min = option.age_min
      this.searchOptions.age_max = option.age_max
      let country = this.operatorRoles.country.find(f => f.value === option.country_code)
      this.selectItemHandler({ key: 'country', value: country })
      let lang = this.langs.find(f => f.value === option.langs[0].value)
      this.selectItemHandler({ key: 'langs', value: lang })
      this.searchOptions.oldData = option
      this.open()
    })
  }
}
</script>
<style lang="scss" scoped>
@import './EditOperator.scss';
</style>
