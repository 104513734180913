const REQUEST_ACHIEVEMENTS = 'REQUEST_ACHIEVEMENTS'
const REQUEST_EDIT_ACHIEVEMENTS = 'REQUEST_EDIT_ACHIEVEMENTS'
const REQUEST_DELETE_ACHIEVEMENT = 'REQUEST_DELETE_ACHIEVEMENT'
const REQUEST_UPDATE_ACHIEVEMENTS = 'REQUEST_UPDATE_ACHIEVEMENTS'

export {
  REQUEST_ACHIEVEMENTS,
  REQUEST_EDIT_ACHIEVEMENTS,
  REQUEST_DELETE_ACHIEVEMENT,
  REQUEST_UPDATE_ACHIEVEMENTS
}
