const SET_LOGIN_USER = 'SET_LOGIN_USER'
const SET_USER_TOKEN = 'SET_USER_TOKEN'
const LOGOUT = 'LOGOUT'
const SET_OPENED_MENU_ITEM = 'SET_OPENED_MENU_ITEM'
const SET_OPENED_MENU_ITEM_CLOSE = 'SET_OPENED_MENU_ITEM_CLOSE'
const SET_PERMISSION_USER = 'SET_PERMISSION_USER'

const SET_LOADER = 'SET_LOADER'
const SET_USER_DATA = 'SET_USER_DATA'

//users
const SET_USERS = 'users/SET_USERS'
const SET_CURRENT_USER = 'users/SET_CURRENT_USER'
const SET_DATE_FILTER = 'users/SET_DATE_FILTER'
const SET_TMP_FILTERS = 'users/SET_TMP_FILTERS'
const SET_RESET_FILTERS = 'users/SET_RESET_FILTERS'
const SET_USER_DEPOSIT = 'users/SET_USER_DEPOSIT'
const SET_USER_PAYOUTS = 'users/SET_USER_PAYOUTS'
const SET_USER_PURCHASES = 'users/SET_USER_PURCHASES'
const SET_USER_PHOTOS = 'users/SET_USER_PHOTOS'
const SET_TRANSACTION_KIND = 'users/SET_TRANSACTION_KIND'
const SET_USER_CHATS = 'users/SET_USER_CHATS'
const SET_CHAT_MESSAGES = 'users/SET_CHAT_MESSAGES'
const SET_CHAT_OLD_MESSAGES = 'users/SET_CHAT_OLD_MESSAGES'
const SET_UTM_FILTER = 'users/SET_UTM_FILTER'
const SET_CURRENT_FILTER = 'users/SET_CURRENT_FILTER'
const SET_USER_COUNTS = 'users/SET_USER_COUNTS'
const SET_USER_FILTER_COUNTS = 'users/SET_USER_FILTER_COUNTS'
const SET_ALL_USERS = 'users/SET_ALL_USERS'
const SET_UPDATE_PHOTO_STATUS = 'users/SET_UPDATE_PHOTO_STATUS'
const SET_USERCHATS_STYLE = 'users/SET_USERCHATS_STYLE'
const SET_USER_CHATS_CLEAR = 'users/SET_USER_CHATS_CLEAR'

//user claims
const SET_ALL_USERS_CLAIMS = 'usercalims/SET_ALL_USERS_CLAIMS'

//views mobile
const SET_RECORDS = 'view/SET_RECORDS'
const SET_CURRENT_RECORD_FILTER = 'view/SET_CURRENT_RECORD_FILTER'
const SET_CLEAR_RECORD_FILTERS = 'view/SET_CLEAR_RECORD_FILTERS'
const SET_CURRENT_LANGUAGE_BY_ID = 'view/SET_CURRENT_LANGUAGE_BY_ID'

//events
const SET_MERGED_CATEGORIES = 'events/SET_MERGED_CATEGORIES'
const SET_EVENTS_REFBOOK = 'events/SET_EVENTS_REFBOOK'
const SET_EVENTS = 'events/SET_EVENTS'
const SET_EVENT_DATE_FILTER = 'events/SET_EVENT_DATE_FILTER'
const SET_EVENT_TMP_FILTERS = 'events/SET_EVENT_TMP_FILTERS'
const SET_EVENT_RESET_FILTERS = 'events/SET_EVENT_RESET_FILTERS'
const SET_CATEGORIES_BY_LANG = 'events/SET_CATEGORIES_BY_LANG'
const SET_EVENTS_FILTERS = 'events/SET_EVENTS_FILTERS'
const SET_ALL_EVENTS = 'events/SET_ALL_EVENTS'
const SET_EVENTS_ARHIVE = 'events/SET_EVENTS_ARHIVE'
const SET_EVENT_COUNTS = 'events/SET_EVENT_COUNTS'
const SET_EVENT = 'events/SET_EVENT'

// Languages
const SET_ALL_LANGUAGES = 'languages/SET_ALL_LANGUAGES'
const SET_CURRENT_LANGUAGES = 'languages/SET_CURRENT_LANGUAGES'
const SET_CURRENT_LANG_ID = 'languages/SET_CURRENT_LANG_ID'

// FAQ
const SET_CONTENT_BY_ID = 'faq/SET_CONTENT_BY_ID'
const SET_CURRENT_CONTENT_ID = 'faq/SET_CURRENT_CONTENT_ID'
const SET_CURRENT_ITEM_ACTIVE = 'faq/SET_CURRENT_ITEM_ACTIVE'

// control employees
const SET_EMPLOYEES = 'employee/SET_EMPLOYEES'
const SET_EMPLOYEE_PERMISSIONS = 'employee/SET_EMPLOYEE_PERMISSIONS'
const SET_EMPLOYEE_POSITIONS = 'employee/SET_EMPLOYEE_POSITIONS'
const SET_EMPLOYEE_SETTINGS = 'employee/SET_EMPLOYEE_SETTINGS'
const SET_CLEAR_EMPLOYEE_DATA = 'employee/SET_CLEAR_EMPLOYEE_DATA'
const SET_CURRENT_EMPLOYEE = 'employee/SET_CURRENT_EMPLOYEE'
const SET_UPDATE_EMPLOYEE_FIELD = 'employee/SET_UPDATE_EMPLOYEE_FIELD'
const SET_TMP_ROLE_EMPLOYEE = 'employee/SET_TMP_ROLE_EMPLOYEE'
const SET_TMP_ROLE_EMPLOYEE_RESET = 'employee/SET_TMP_ROLE_EMPLOYEE_RESET'

//control departments
const SET_DEPARTMENTS = 'departments/SET_DEPARTMENTS'
//control permissions
const SET_PERMISSIONS = 'permissions/SET_PERMISSIONS'
const SET_PERMISSIONS_GROUP = 'permissions/SET_PERMISSIONS_GROUP'
const SET_TMP_FILTERS_PERMISSION = 'permissions/SET_TMP_FILTERS_PERMISSION'
const SET_RESET_PERMISSION_FILTERS = 'permissions/SET_RESET_PERMISSION_FILTERS'
//control positions
const SET_POSITIONS = 'positions/SET_POSITIONS'
const SET_RESET_POSITIONS_FILTERS = 'positions/SET_RESET_POSITIONS_FILTERS'
const SET_TMP_POSITIONS_FILTERS = 'positions/SET_TMP_POSITIONS_FILTERS'
// achievement
const SET_ACHIEVEMENTS = 'achievements/SET_ACHIEVEMENTS'
//cause points
const SET_CAUSE_POINTS = 'causePoints/REQUEST_CAUSE_POINTS'

// Gifts
const SET_ALL_GIFTS = 'gifts/SET_ALL_GIFTS'
// Gifts Events
const SET_ALL_GIFT_ENENTS = 'giftEvent/SET_ALL_GIFT_ENENTS'
//Reason Delete
const SET_REASON_DELETE = 'reasons/SET_REASON_DELETE'
const SET_REASON_DELETE_ADMIN = 'reasons/SET_REASON_DELETE_ADMIN'
//Reason Block
const SET_REASON_BLOCK_PHOTO = 'reasons/SET_REASON_BLOCK_PHOTO'
const SET_REASON_BLOCK_USER = 'reasons/SET_REASON_BLOCK_USER'
//Support contact
const SET_SUPPORT_CONTACT_TYPES = 'supportContacts/SET_SUPPORT_CONTACT_TYPES'
//Market Utm
const SET_MARKET_UTM = 'market/SET_MARKET_UTM'
// Pushes
const SET_PUSHES = 'push/SET_PUSHES'
//Auto messages
const SET_AUTO_MESSAGES = 'autoMessages/SET_AUTO_MESSAGES'
const SET_AUTO_MESSAGE_COUNT = 'autoMessages/SET_AUTO_MESSAGE_COUNT'
const SET_AUTO_MESSAGES_PAGE = 'autoMessages/SET_AUTO_MESSAGES_PAGE'
const SET_AUTO_MESSAGES_PAGINATION = 'autoMessages/SET_AUTO_MESSAGES_PAGINATION'
const SET_AUTO_MESSAGES_NOW_LINK = 'autoMessages/SET_AUTO_MESSAGES_NOW_LINK'
//operators
const SET_OPERATORS = 'operators/SET_OPERATORS'
const SET_OPERATOR_SETTINGS = 'operators/SET_OPERATOR_SETTINGS'
const SET_CLEAR_OPERATOR_DATA = 'operators/SET_CLEAR_OPERATOR_DATA'
const SET_OPERATOR_USERS = 'operators/SET_OPERATOR_USERS'
const SET_OPERATOR_CURRENT_USER = 'operators/SET_OPERATOR_CURRENT_USER'
const SET_SEARCH_OPTIONS = 'operators/SET_SEARCH_OPTIONS'
const SET_ADD_SEARCH_OPTIONS = 'operators/SET_ADD_SEARCH_OPTIONS'
const SET_DEL_SEARCH_OPTIONS = 'operators/SET_DEL_SEARCH_OPTIONS'
const SET_ACTIVE_OPERATOR = 'operators/SET_ACTIVE_OPERATOR'
const SET_EDIT_SEARCH_OPTIONS = 'operators/SET_EDIT_SEARCH_OPTIONS'
const SET_OPERATOR_STATISTIC = 'operators/SET_OPERATOR_STATISTIC'
const SET_CURRENT_OPERATOR = 'operators/SET_CURRENT_OPERATOR'
const SET_OPERATOR_PAYOUT = 'operators/SET_OPERATOR_PAYOUT'
const SET_OPERATOR_BANNER = 'operators/SET_OPERATOR_BANNER'
const SET_EDIT_OPERATOR_BANNER = 'operators/SET_EDIT_OPERATOR_BANNER'
const SET_OPERATORS_AUTO_MESSAGE = 'operators/SET_OPERATORS_AUTO_MESSAGE'
const SET_TMP_FILTERS_OPERATORS = 'operators/SET_TMP_FILTERS_OPERATORS'
const SET_RESET_FILTERS_OPERATORS = 'operators/SET_RESET_FILTERS_OPERATORS'
const SET_RESET_FILTERS_OPERATORS_OPERATOR = 'operators/SET_RESET_FILTERS_OPERATORS_OPERATOR'
//calendar
const SET_EVENTS_CALENDAR = 'calendar/SET_EVENTS_CALENDAR'
const SET_SHIFT_OPTIONS = 'calendar/SET_SHIFT_OPTIONS'
const SET_DELETE_EVENT = 'calendar/SET_DELETE_EVENT'
//payments setting
const SET_PAYMENTS_OPTIONS = 'operatorPayments/SET_PAYMENTS_OPTIONS'
const SET_DEFAULT_PAYMENT_OPTION = 'operatorPayments/SET_DEFAULT_PAYMENT_OPTION'
const SET_OPERATOR_CURRENT_USER_DEL = 'operators/SET_OPERATOR_CURRENT_USER_DEL'

//autoMessagesList
const SET_AUTO_MESSAGES_LIST = 'autoMessagesList/SET_AUTO_MESSAGES_LIST'
const SET_MESSAGES_COUNT = 'autoMessagesList/SET_MESSAGES_COUNT'
const SET_SEASON_OPERATOR = 'autoMessagesList/SET_SEASON_OPERATOR'
const SET_CURRENT_FILTER_AUTO_MESSAGE = 'autoMessagesList/SET_CURRENT_FILTER_AUTO_MESSAGE'
const SET_TMP_FILTERS_AUTO_MESSAGE = 'autoMessagesList/SET_TMP_FILTERS_AUTO_MESSAGE'
const SET_RESET_FILTERS_AUTO_MESSAGE = 'autoMessagesList/SET_RESET_FILTERS_AUTO_MESSAGE'
const SET_AUTO_MESSAGES_LIST_FILTER = 'autoMessagesList/SET_AUTO_MESSAGES_LIST_FILTER'
const SET_AUTO_MESSAGES_LIST_FILTER_COUNT = 'autoMessagesList/SET_AUTO_MESSAGES_LIST_FILTER_COUNT'

//moderator
const SET_MODERATORS = 'moderatorPayments/SET_MODERATORS'
const SET_MODERATOR_SETTINGS = 'moderatorPayments/SET_MODERATOR_SETTINGS'
const SET_CLEAR_MODERATOR_DATA = 'moderatorPayments/SET_CLEAR_MODERATOR_DATA'
const SET_SEARCH_MODERATOR_OPTIONS = 'moderatorPayments/SET_SEARCH_MODERATOR_OPTIONS'
const SET_ADD_MODERATOR_SEARCH_OPTIONS = 'moderatorPayments/SET_ADD_MODERATOR_SEARCH_OPTIONS'
const SET_DEL_MODERATOR_SEARCH_OPTIONS = 'moderatorPayments/SET_DEL_MODERATOR_SEARCH_OPTIONS'
const SET_EDIT_MODERATOR_SEARCH_OPTIONS = 'moderatorPayments/SET_EDIT_MODERATOR_SEARCH_OPTIONS'
const SET_MODERATOR_STATISTIC = 'moderatorPayments/SET_MODERATOR_STATISTIC'
const SET_CURRENT_MODERATOR = 'moderatorPayments/SET_CURRENT_MODERATOR'
const SET_MODERATOR_PAYOUT = 'moderatorPayments/SET_MODERATOR_PAYOUT'
const SET_DEFAULT_PAYMENT_MODERATOR_OPTION = 'moderatorPayments/SET_DEFAULT_PAYMENT_MODERATOR_OPTION'

//moderator Rating
const SET_MODERATORS_RATING = 'moderatorRating/SET_MODERATORS_RATING'
const SET_MODERATOR_SETTINGS_QUALITY = 'moderatorRating/SET_MODERATOR_SETTINGS_QUALITY'
const SET_MODERATOR_SETTINGS_SPEED = 'moderatorRating/SET_MODERATOR_SETTINGS_SPEED'
const SET_CLEAR_MODERATOR_RATING_DATA = 'moderatorRating/SET_CLEAR_MODERATOR_RATING_DATA'
const SET_MODERATOR_RATING_SETTINGS = 'moderatorRating/SET_MODERATOR_RATING_SETTINGS'

//purchases
const SET_PURCHASES = 'purchases/SET_PURCHASES'
const SET_CLEAR_PURCHASES_DATA = 'purchases/SET_CLEAR_PURCHASES_DATA'
const SET_PURCHASE_SETTINGS = 'purchases/SET_PURCHASE_SETTINGS'
//statistics
const SET_APPS_FLYER = 'statistics/SET_APPS_FLYER'
const SET_REQUEST_APPS_FLYER_IOS = 'statistics/SET_REQUEST_APPS_FLYER_IOS'
const SET_PURCHASES_BY_DATE = 'statistics/SET_PURCHASES_BY_DATE'
const SET_ROYAL_USERS_INSTALL = 'statistics/SET_ROYAL_USERS_INSTALL'
const SET_ROYAL_USERS_INSTALL_IOS = 'statistics/  SET_ROYAL_USERS_INSTALL_IOS'
const SET_USERS_ACTIVITY = 'statistics/SET_USERS_ACTIVITY'
const SET_USERS_COUNT = 'statistics/SET_USERS_COUNT'
const SET_USERS_ONLINE_BY_CITY = 'statistics/SET_USERS_ONLINE_BY_CITY'
const SET_PURCHASES_MARKET_PLACES = 'statistics/SET_PURCHASES_MARKET_PLACES'
const SET_PURCHASES_BY_CITY = 'statistics/SET_PURCHASES_BY_CITY'
const SET_PURCHASES_BY_COUNTRY = 'statistics/SET_PURCHASES_BY_COUNTRY'
const SET_PURCHASES_BY_TIME = 'statistics/SET_PURCHASES_BY_TIME'
const SET_COUNTERS_MESSAGES_OPERATORS = 'statistics/SET_COUNTERS_MESSAGES_OPERATORS'
const SET_STATISTICS_PROFILE_MESSAGES = 'statistics/SET_STATISTICS_PROFILE_MESSAGES'
const SET_PURCHASES_BY_PAYERS_PAYMENTS = 'statistics/SET_PURCHASES_BY_PAYERS_PAYMENTS'
const SET_USERS_ONLINE_ONE_DAY = 'statistics/SET_USERS_ONLINE_ONE_DAY'
const SET_USERS_ONLINE_ONE_DAY_SITE_AND_APP = 'statistics/SET_USERS_ONLINE_ONE_DAY_SITE_AND_APP'
const SET_MODERATOTS_VIDEO_CHECK = 'statistics/SET_MODERATOTS_VIDEO_CHECK'
const SET_NEW_USER_ACTIVE_BY_DATE = 'statistics/SET_NEW_USER_ACTIVE_BY_DATE'
const SET_MODERATORS_PHOTO_CHECK = 'statistics/SET_MODERATORS_PHOTO_CHECK'
const SET_USERS_GENDER = 'statistics/SET_USERS_GENDER'
const SET_NEW_USER_ACTIVE = 'statistics/SET_NEW_USER_ACTIVE'
const SET_USERS_PAYING = 'statistics/SET_USERS_PAYING'
const SET_USERS_AGE = 'statistics/SET_USERS_AGE'
const SET_USER_LIFETIME = 'statistics/SET_USER_LIFETIME'
const SET_OPERATORS_MESSAGE = 'statistics/SET_OPERATORS_MESSAGE'
const SET_PURCHASE_TOTAL = 'statistics/SET_PURCHASE_TOTAL'
const SET_OPERATORS_CASHES = 'statistics/SET_OPERATORS_MESSAGE'
const SET_PURCHASES_POINTS = 'statistics/SET_PURCHASES_POINTS'
const SET_PURCHASES_VIP_STATUS = 'statistics/SET_PURCHASES_VIP_STATUS'
const SET_STATISTICS_PROFILE_MESSAGES_PAGINATION = 'statistics/SET_STATISTICS_PROFILE_MESSAGES_PAGINATION'

//USER STATISTIC
const SET_USER_STATISTIC_ADVANCED = 'userStatistic/SET_USER_STATISTIC_ADVANCED'
const SET_STATISTIC_USER_BY_DATE = 'userStatistic/SET_STATISTIC_USER_BY_DATE'
const SET_STATISTIC_USER_BY_DATE_PAGINATION = 'userStatistic/SET_STATISTIC_USER_BY_DATE_PAGINATION'
export {
  SET_LOGIN_USER,
  SET_USER_TOKEN,
  SET_USER_DATA,
  LOGOUT,
  SET_OPENED_MENU_ITEM,
  SET_OPENED_MENU_ITEM_CLOSE,
  SET_LOADER,
  SET_PERMISSION_USER,
  //users
  SET_USERS,
  SET_USER_COUNTS,
  SET_CURRENT_USER,
  SET_DATE_FILTER,
  SET_TMP_FILTERS,
  SET_TRANSACTION_KIND,
  SET_RESET_FILTERS,
  SET_USER_DEPOSIT,
  SET_USER_PAYOUTS,
  SET_USER_PURCHASES,
  SET_USER_PHOTOS,
  SET_USER_CHATS,
  SET_CHAT_MESSAGES,
  SET_CHAT_OLD_MESSAGES,
  SET_UTM_FILTER,
  SET_CURRENT_FILTER,
  SET_USER_FILTER_COUNTS,
  SET_ALL_USERS,
  SET_UPDATE_PHOTO_STATUS,
  SET_USERCHATS_STYLE,
  SET_USER_CHATS_CLEAR,
  //user claims
  SET_ALL_USERS_CLAIMS,
  //views mobile
  SET_RECORDS,
  SET_CURRENT_RECORD_FILTER,
  SET_CLEAR_RECORD_FILTERS,
  SET_CURRENT_LANGUAGE_BY_ID,
  //events
  SET_MERGED_CATEGORIES,
  SET_EVENTS_REFBOOK,
  SET_EVENTS,
  SET_EVENT_DATE_FILTER,
  SET_EVENT_TMP_FILTERS,
  SET_EVENT_RESET_FILTERS,
  SET_CATEGORIES_BY_LANG,
  SET_EVENTS_FILTERS,
  SET_ALL_EVENTS,
  SET_EVENTS_ARHIVE,
  SET_EVENT_COUNTS,
  SET_EVENT,
  // Languages
  SET_ALL_LANGUAGES,
  SET_CURRENT_LANGUAGES,
  SET_CURRENT_LANG_ID,
  // FAQ
  SET_CONTENT_BY_ID,
  SET_CURRENT_CONTENT_ID,
  SET_CURRENT_ITEM_ACTIVE,
  //control employees
  SET_EMPLOYEES,
  SET_EMPLOYEE_PERMISSIONS,
  SET_EMPLOYEE_POSITIONS,
  SET_EMPLOYEE_SETTINGS,
  SET_CLEAR_EMPLOYEE_DATA,
  SET_CURRENT_EMPLOYEE,
  SET_UPDATE_EMPLOYEE_FIELD,
  SET_TMP_ROLE_EMPLOYEE,
  SET_TMP_ROLE_EMPLOYEE_RESET,
  //control departments
  SET_DEPARTMENTS,
  //control permissions
  SET_PERMISSIONS,
  SET_PERMISSIONS_GROUP,
  SET_TMP_FILTERS_PERMISSION,
  SET_RESET_PERMISSION_FILTERS,
  //control positions
  SET_POSITIONS,
  SET_RESET_POSITIONS_FILTERS,
  SET_TMP_POSITIONS_FILTERS,
  // achievements
  SET_ACHIEVEMENTS,
  //cause points
  SET_CAUSE_POINTS,
  // Gifts
  SET_ALL_GIFTS,
  // Gifts Event
  SET_ALL_GIFT_ENENTS,
  //Reason Delete
  SET_REASON_DELETE,
  SET_REASON_DELETE_ADMIN,
  //Reason Block
  SET_REASON_BLOCK_PHOTO,
  SET_REASON_BLOCK_USER,
  //support contact
  SET_SUPPORT_CONTACT_TYPES,
  //Market Utm
  SET_MARKET_UTM,
  //Push
  SET_PUSHES,
  //Auto messages
  SET_AUTO_MESSAGES,
  SET_AUTO_MESSAGE_COUNT,
  SET_AUTO_MESSAGES_PAGE,
  SET_AUTO_MESSAGES_PAGINATION,
  SET_AUTO_MESSAGES_NOW_LINK,
  //operators
  SET_OPERATORS,
  SET_OPERATOR_SETTINGS,
  SET_CLEAR_OPERATOR_DATA,
  SET_OPERATOR_USERS,
  SET_OPERATOR_CURRENT_USER,
  SET_SEARCH_OPTIONS,
  SET_ADD_SEARCH_OPTIONS,
  SET_DEL_SEARCH_OPTIONS,
  SET_EDIT_SEARCH_OPTIONS,
  SET_ACTIVE_OPERATOR,
  SET_OPERATOR_STATISTIC,
  SET_CURRENT_OPERATOR,
  SET_OPERATOR_PAYOUT,
  SET_OPERATOR_CURRENT_USER_DEL,
  SET_OPERATOR_BANNER,
  SET_EDIT_OPERATOR_BANNER,
  SET_OPERATORS_AUTO_MESSAGE,
  SET_TMP_FILTERS_OPERATORS,
  SET_RESET_FILTERS_OPERATORS,
  SET_RESET_FILTERS_OPERATORS_OPERATOR,
  //calendar
  SET_EVENTS_CALENDAR,
  SET_SHIFT_OPTIONS,
  SET_DELETE_EVENT,
  //payments setting
  SET_PAYMENTS_OPTIONS,
  SET_DEFAULT_PAYMENT_OPTION,
  //AutoMessagesList
  SET_AUTO_MESSAGES_LIST,
  SET_MESSAGES_COUNT,
  SET_SEASON_OPERATOR,
  SET_CURRENT_FILTER_AUTO_MESSAGE,
  SET_TMP_FILTERS_AUTO_MESSAGE,
  SET_AUTO_MESSAGES_LIST_FILTER,
  SET_RESET_FILTERS_AUTO_MESSAGE,
  SET_AUTO_MESSAGES_LIST_FILTER_COUNT,
  //moderator
  SET_MODERATORS,
  SET_MODERATOR_SETTINGS,
  SET_CLEAR_MODERATOR_DATA,
  SET_SEARCH_MODERATOR_OPTIONS,
  SET_ADD_MODERATOR_SEARCH_OPTIONS,
  SET_DEL_MODERATOR_SEARCH_OPTIONS,
  SET_EDIT_MODERATOR_SEARCH_OPTIONS,
  SET_MODERATOR_STATISTIC,
  SET_CURRENT_MODERATOR,
  SET_MODERATOR_PAYOUT,
  SET_DEFAULT_PAYMENT_MODERATOR_OPTION,
  // Moderators Rating
  SET_MODERATORS_RATING,
  SET_MODERATOR_SETTINGS_QUALITY,
  SET_MODERATOR_SETTINGS_SPEED,
  SET_CLEAR_MODERATOR_RATING_DATA,
  SET_MODERATOR_RATING_SETTINGS,
  //purchases
  SET_PURCHASES,
  SET_CLEAR_PURCHASES_DATA,
  SET_PURCHASE_SETTINGS,
  //statistics
  SET_APPS_FLYER,
  SET_REQUEST_APPS_FLYER_IOS,
  SET_ROYAL_USERS_INSTALL,
  SET_ROYAL_USERS_INSTALL_IOS,
  SET_PURCHASES_POINTS,
  SET_USERS_ACTIVITY,
  SET_PURCHASES_BY_DATE,
  SET_USERS_COUNT,
  SET_USERS_ONLINE_BY_CITY,
  SET_COUNTERS_MESSAGES_OPERATORS,
  SET_STATISTICS_PROFILE_MESSAGES,
  SET_STATISTICS_PROFILE_MESSAGES_PAGINATION,
  SET_PURCHASES_VIP_STATUS,
  SET_PURCHASES_MARKET_PLACES,
  SET_PURCHASES_BY_CITY,
  SET_OPERATORS_MESSAGE,
  SET_OPERATORS_CASHES,
  SET_PURCHASES_BY_COUNTRY,
  SET_MODERATOTS_VIDEO_CHECK,
  SET_PURCHASES_BY_TIME,
  SET_PURCHASE_TOTAL,
  SET_PURCHASES_BY_PAYERS_PAYMENTS,
  SET_USERS_ONLINE_ONE_DAY,
  SET_USERS_ONLINE_ONE_DAY_SITE_AND_APP,
  SET_NEW_USER_ACTIVE_BY_DATE,
  SET_MODERATORS_PHOTO_CHECK,
  SET_USERS_GENDER,
  SET_NEW_USER_ACTIVE,
  SET_USERS_PAYING,
  SET_USERS_AGE,
  SET_USER_LIFETIME,
  //USER STATISTIC
  SET_USER_STATISTIC_ADVANCED,
  SET_STATISTIC_USER_BY_DATE,
  SET_STATISTIC_USER_BY_DATE_PAGINATION
}
