const GET_EPLOYEES = 'GET_EPLOYEES'
const GET_EMPLOYEE_ROLES = 'GET_EMPLOYEE_ROLES'
const GET_EMPLOYEE_ROLES_DEFAULT = 'GET_EMPLOYEE_ROLES_DEFAULT'
const GET_EMPLOYEE_TMP_ROLES = 'GET_EMPLOYEE_TMP_ROLE'
const GET_EMPLOYEE_CURRENT_FILTER = 'GET_EMPLOYEE_CURRENT_FILTER'
const GET_EMPLOYEE_PERMISSIONS = 'GET_EMPLOYEE_PERMISSIONS'
const GET_EMPLOYEE_POSITIONS = 'GET_EMPLOYEE_POSITIONS'
const GET_CURRENT_EMPLOYEE = 'GET_CURRENT_EMPLOYEE'
const GET_TMP_FILTER_INFO = 'GET_TMP_FILTER_INFO'
const GET_TMP_FILTER_INFO_ROLES = 'GET_TMP_FILTER_INFO_ROLES'

export {
  GET_EPLOYEES,
  GET_EMPLOYEE_ROLES,
  GET_EMPLOYEE_ROLES_DEFAULT,
  GET_EMPLOYEE_TMP_ROLES,
  GET_EMPLOYEE_CURRENT_FILTER,
  GET_EMPLOYEE_PERMISSIONS,
  GET_EMPLOYEE_POSITIONS,
  GET_CURRENT_EMPLOYEE,
  GET_TMP_FILTER_INFO,
  GET_TMP_FILTER_INFO_ROLES
}
