import Request from '../helpers/Request'
import BaseService from './BaseService'

class GiftEventService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getAllGiftEvent() {
    const getGiftAllEventUrl = `/api/v1/localization/gifteventcurrency/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(getGiftAllEventUrl, { headers })
  }

  async addNewGiftEvent(newGiftData) {
    const addNewGiftEventUrl = `/api/v1/localization/gifteventcurrency/add/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(addNewGiftEventUrl, newGiftData, { headers })
  }

  async updateGiftEvents(newGiftData) {
    const updateGiftEventsUrl = `/api/v1/localization/gifteventcurrency/${newGiftData.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.put(updateGiftEventsUrl, newGiftData.data, {
      headers
    })
  }

  async updateGiftPercentEvents(newGiftPercentData) {
    const updateGiftPercentEventsUrl = `/api/v1/localization/gifteventcurrency/${newGiftPercentData.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.put(updateGiftPercentEventsUrl, newGiftPercentData.data, { headers })
  }

  async deleteGiftEvents(eventId) {
    const deleteGiftEventsUrl = `/api/v1/localization/gifteventcurrency/${eventId}/destroy/`

    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.delete(deleteGiftEventsUrl, { headers })
  }
}

export default GiftEventService
