<template>
  <li>
    <div class="popup__item-info popup__item-date">
      <span>{{ isCreate }}</span>
    </div>
    <div class="popup__item-info popup__item-purpose">
      <span>{{ isTarget }}</span>
    </div>
    <div class="popup__item-info">
      <span
        :class="checkTransactionValue() === -1 ? 'popup__item-currency--green' : 'popup__item-currency--red'"
        >{{ this.points }}</span
      >
    </div>
  </li>
</template>
<script>
import transactionKind from '@/constants/TransactionKind'
export default {
  name: 'UserBuyListPopupPurchases',
  props: {
    create: {
      type: String
    },
    kind: {
      type: Number
    },
    points: {
      type: Number
    }
  },
  computed: {
    isCreate() {
      return new Date(this.create).toLocaleString()
    },
    isTarget() {
      return transactionKind.find(i => i.code === this.kind).description
    }
  },
  methods: {
    checkTransactionValue() {
      return this.points.toString().indexOf('-')
    }
  }
}
</script>
<style lang="scss">
.popup__item-currency--red {
  font-weight: 700;
  color: #ff3333;
}
.popup__item-currency--green {
  font-weight: 700;
  color: #27d966;
}
</style>
