<template>
  <div class="moderators-payments__filter">
    <form class="moderators-payments__form" @submit.prevent="applyUserFiltersHanlder">
      <div class="moderators-payments__item">
        <span class="moderators-payments__title">Статус модераторов:</span>
        <div class="moderators-payments__input select">
          <div class="select__body">
            <button
              type="button"
              class="select__field select__field--moderator"
              @click="statusSelectOpen = !statusSelectOpen"
            >
              <span class="select__field-value">{{ defaultFilter({ key: 'status' }) }}</span>
              <i class="select__field-icon fa fa-angle-down"></i>
            </button>
            <ul class="select__list" v-if="statusSelectOpen" @mouseleave="mouseLeave">
              <li
                class="select__item"
                v-for="status in moderatorRoles.status"
                :key="status.id"
                @click="
                  selectFilterBuilder({
                    key: 'status',
                    value: status
                  })
                "
              >
                <span class="select__text"> {{ status.name }} </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="moderators-payments__item filters__item--togle">
        <span class="moderators-payments__title">Не оплаченные: </span>
        <label class="moderators-payments__input toggle">
          <input class="toggle__checkbox" type="checkbox" v-model="noPayments" />
          <span class="toggle__toggler toggle__toggler--moderators"></span>
        </label>
      </div>
      <button class="moderators-payments__btn moderators-payments__btn--success">
        Показать
      </button>
    </form>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import {
  GET_MODERATOR_ROLES,
  GET_MODERATOR_ROLES_DEFAULT,
  GET_MODERATOR_TMP_ROLES
} from '@/store/getter-types'
import { SET_MODERATOR_SETTINGS } from '@/store/mutation-types'

import { REQUEST_MODERATOR_PAYOUT } from '@/store/action-types'

export default {
  name: 'ModeratorsPaymentsFilters',
  data: () => ({
    statusSelectOpen: false,
    noPayments: false
  }),
  computed: {
    ...mapGetters({
      moderatorRoles: GET_MODERATOR_ROLES,
      defaultFilters: GET_MODERATOR_ROLES_DEFAULT,
      tmpFilter: GET_MODERATOR_TMP_ROLES
    })
  },
  methods: {
    ...mapActions({
      getPayOuts: REQUEST_MODERATOR_PAYOUT
    }),
    ...mapMutations({
      addTmpModeratorSettings: SET_MODERATOR_SETTINGS
    }),
    defaultFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : this.defaultFilters[key].name
    },

    defaultValueFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current || current === false ? current : this.defaultFilters[key].value
    },

    selectFilterBuilder(filterItem) {
      const { key } = filterItem
      switch (key) {
        case 'status':
          this.addTmpModeratorSettings({ ...filterItem })
          break
        default:
          break
      }
      this[`${key}SelectOpen`] = false
    },

    close() {
      this.statusSelectOpen = false
    },
    mouseLeave() {
      this.close()
    },

    applyUserFiltersHanlder() {
      const userFilterData = {}
      userFilterData.is_active = this.defaultValueFilter({ key: 'status' })
      this.close()
      this.getPayOuts(userFilterData)
    }
  }
}
</script>
<style lang="scss">
@import 'ModeratorsPayments';
</style>
