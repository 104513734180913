export default {
  positions: [],
  tmpPositionsFilters: {
    type: {}
  },
  filterPositionsDefault: {
    type: {
      name: 'Неважно',
      value: 'Неважно',
      id: 'Неважно'
    }
  },
  filtersPositionsInfo: {
    type: [
      {
        name: 'Разработчик',
        value: 'developer',
        id: 0
      },
      {
        name: 'Менеджер',
        value: 'manager',
        id: 1
      },
      {
        name: 'Маркетолог',
        value: 'marketer',
        id: 2
      },
      {
        name: 'Руководитель модераторов',
        value: 'leader_moderator',
        id: 4
      },
      {
        name: 'Модератор',
        value: 'moderator',
        id: 5
      },
      {
        name: 'Руководитель операторов',
        value: 'leader_operator',
        id: 6
      },
      {
        name: 'Оператор',
        value: 'operator',
        id: 7
      },
      {
        name: 'Владелец',
        value: 'owner',
        id: 8
      },
      {
        name: 'Руководитель тех. поддержки',
        value: 'leader_support',
        id: 9
      },
      {
        name: 'Поддержка',
        value: 'support',
        id: 10
      }
    ]
  }
}
