<template>
  <div id="app">
    <section class="body" :class="{ [`${routeName}`]: routeName }">
      <Header v-if="!isHidden" />
      <Asidebar v-if="!isHidden" :openaside="!menuOpen" />
      <div class="main__wrapper">
        <simplebar
          class="main"
          ref="scrollTop"
          :class="{ 'sidebar-hidden': menuOpen }"
          data-simplebar-auto-hide="true"
        >
          <router-view />
        </simplebar>
        <Footer v-if="!isHidden" @toTop="toTop" :class="{ nosidebar: !menuOpen }" />
      </div>

      <Loader :menuOpen="!menuOpen" v-if="loading" />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { GET_LOADING, GET_TOKEN } from '@/store/getter-types'
import { REQUEST_PERMISSION_USER, REQUEST_PROFILE_ID, REQUEST_ALL_LANGUAGES } from '@/store/action-types'
import Header from '@/components/Header/Header'
import Asidebar from '@/components/Asidebar/Asidebar'
import Footer from '@/components/Footer/Footer'
import Loader from '@/components/Loader/Loader'
import simplebar from 'simplebar-vue'
// import "simplebar/dist/simplebar.min.css";

// import store from "@/store";
export default {
  name: 'app',
  components: {
    Header,
    Asidebar,
    Footer,
    Loader,
    simplebar
  },
  data: () => ({
    menuOpen: false,
    routeName: ''
  }),

  computed: {
    ...mapGetters({
      loading: GET_LOADING,
      tokken: GET_TOKEN
    }),

    isHidden() {
      return this.$route.meta.hidden
    }
  },
  beforeMount() {
    if (this.tokken) {
      this.userData().then(response => {
        this.langsRequest()
      })
    }
  },
  mounted() {
    if (window.matchMedia('(max-width: 1025px)').matches) {
      this.menuOpen = true
    }
    this.$root.$on('openaside', () => {
      this.menuOpen = !this.menuOpen
    })
    this.$root.$on('routeName', routename => {
      this.routeName = this.setRouteClass(routename)
    })
  },

  methods: {
    ...mapActions({
      requestPermission: REQUEST_PERMISSION_USER,
      userData: REQUEST_PROFILE_ID,
      langsRequest: REQUEST_ALL_LANGUAGES
    }),
    toTop() {
      this.$refs.scrollTop.scrollElement.scrollTop = 0
    },
    setRouteClass(routename) {
      switch (routename) {
        case 'Tickets':
        case 'TicketsNew':
        case 'Ticketsprogress':
        case 'Ticketspostponed':
        case 'Ticketsclose':
          return 'tickets-page'

        case 'Userclaims':
          return 'userclaims-page'

        case 'Reviews':
        case 'ReviewsNew':
          return 'reviews-page'

        case 'Verification':
          return 'verification-page'

        case 'Login':
        case 'logout':
          return 'login-page'

        case 'Errorpage':
          return 'error-page'

        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/reset.scss';
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/vars.scss';
@import '@/assets/scss/global.scss';
@import '@/assets/scss/transition.scss';
@import '@/assets/scss/simplebar.scss';
@import '@/assets/scss/simplebar-custom.scss';
@import '@/assets/scss/tooltips.scss';
@import '@/assets/scss/form.scss';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

::selection {
  background: #ff6c60;
  color: #fff;
}
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #717171;
}
body {
  font-family: 'Open Sans', sans-serif;
  // background-color: $main-bg-color;
}
</style>
