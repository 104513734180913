<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    :description="'Снять верификацию по возрасту?'"
    modalClass="popup--small"
  >
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="verifyHandler('approve')">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_GET_VERIFY } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_GET_VERIFY_AGE } from '@/store/action-types'

export default {
  name: 'UserGetVerifyPopup',
  components: {
    Popup
  },
  data() {
    return {
      id: '',
      userVerification: null
    }
  },
  mixins: [ModalMixin],
  computed: {
    title() {
      return 'Снять верификацию пользователя?'
    }
  },

  methods: {
    ...mapActions({
      verifyUser: REQUEST_GET_VERIFY_AGE
    }),
    closePopup() {
      this.id = ''
      this.userVerification = false
      this.close()
    },
    verifyHandler(method) {
      let payload
      payload = {
        user_id: this.id
      }
      this.verifyUser(payload)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(USER_GET_VERIFY, user => {
      this.userVerification = user.verification
      this.id = user.id
      this.open()
    })
  }
}
</script>
