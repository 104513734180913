const GET_LOADING = 'GET_LOADING'
const GET_USER = 'GET_USER'
const GET_TOKEN = 'GET_TOKEN'
const GET_LOGIN = 'GET_LOGIN'
const GET_PERMISSIONS = 'GET_PERMISSIONS'
const GET_PERMISSIONS_USER = 'GET_PERMISSIONS_USER'

// users
const GET_USERS = 'users/GET_USERS'
const GET_CURRENT_USER = 'users/GET_CURRENT_USER'
const GET_VALUE_PICKER = 'users/GET_VALUE_PICKER'
const GET_FILTERS_INFO = 'users/GET_FILTERS_INFO'
const GET_FILTERS_DEFAULT = 'users/GET_FILTERS_DEFAULT'
const GET_TMP_FILERS = 'users/GET_TMP_FILERS'
const GET_USER_GENDER = 'users/GET_USER_GENDER'
const GET_USER_DEPOSIT = 'users/GET_USER_DEPOSIT'
const GET_USER_PAYOUTS = 'users/GET_USER_PAYOUTS'
const GET_USER_PURCHASES = 'users/GET_USER_PURCHASES'
const GET_USER_PHOTOS = 'users/GET_USER_PHOTOS'
const GET_TRANSACTION_KIND = 'users/GET_TRANSACTION_KIND'
const GET_USER_CHATS = 'users/GET_USER_CHATS'
const GET_CHAT_MESSAGES = 'users/GET_CHAT_MESSAGES'
const GET_CURRENT_FILERS = 'users/GET_CURRENT_FILERS'
const GET_USER_COUNTS = 'users/GET_USER_COUNTS'
const GET_USER_FILTER_COUNTS = 'users/GET_USER_FILTER_COUNTS'
const GET_FILTER_PAGE = 'users/GET_FILTER_PAGE'
const GET_USERCHATS_STYLES = 'users/GET_USERCHATS_STYLES'

//user calims
const GET_ALL_USERS_CALIMS = 'userclaims/GET_ALL_USERS_CALIMS'

//view mobile
const GET_RECORDS = 'view/GET_RECORDS'
const GET_LANGUAGE = 'view/GET_LANGUAGE'
const GET_GENDER = 'view/GET_GENDER'
const GET_COUNTRY = 'view/GET_COUNTRY'
const GET_PAGE_TYPES = 'view/GET_PAGE_TYPES'
const GET_RECORD_DEFAULT = 'view/GET_RECORD_DEFAULT'
const GET_TMP_RECORD_FILTERS = 'view/GET_TMP_RECORD_FILTERS'

//events
const GET_MERGED_CATEGORIES = 'events/GET_MERGED_CATEGORIES'
const GET_EVENTS = 'events/GET_EVENTS'
const GET_EVENT = 'events/GET_EVENT'
const GET_EVENTS_REFBOOK = 'events/GET_EVENTS_REFBOOK'
const GET_EVENT_VALUE_PICKER = 'events/GET_EVENT_VALUE_PICKER'
const GET_EVENT_FILTERS_INFO = 'events/GET_EVENT_FILTERS_INFO'
const GET_EVENT_FILTERS_DEFAULT = 'events/GET_EVENT_FILTERS_DEFAULT'
const GET_EVENT_TMP_FILERS = 'events/GET_EVENT_TMP_FILERS'
const GET_CATEGORIES_BY_LANG = 'events/GET_CATEGORIES_BY_LANG'
const GET_EVENTS_FILTERS = 'events/GET_EVENTS_FILTERS'
const GET_EVENT_COUNTS = 'events/GET_EVENT_COUNTS'

// Languages
const GET_LANGUAGES = 'languages/GET_LANGUAGES'
const GET_CURRENT_LANGUAGES = 'languages/GET_CURRENT_LANGUAGES'
const GET_CURRENT_LANGUAGES_ID = 'languages/GET_CURRENT_LANGUAGES_ID'

// FAQ
const GET_ALL_CATEGORY_BY_LANG = 'faq/GET_ALL_CATEGORY_BY_LANG'
const GET_CONTENT_BY_ID = 'faq/GET_CONTENT_BY_ID'
const GET_CURRENT_CONTENT_ID = 'faq/GET_CURRENT_CONTENT_ID'
const GET_CURRENT_ITEM_ACTIVE = 'faq/GET_CURRENT_ITEM_ACTIVE'

// control employees
const GET_EPLOYEES = 'employee/GET_EPLOYEES'
const GET_EMPLOYEE_ROLES = 'employee/GET_EMPLOYEE_ROLES'
const GET_EMPLOYEE_ROLES_DEFAULT = 'employee/GET_EMPLOYEE_ROLES_DEFAULT'
const GET_EMPLOYEE_TMP_ROLES = 'employee/GET_EMPLOYEE_TMP_ROLE'
const GET_EMPLOYEE_CURRENT_FILTER = 'employee/GET_EMPLOYEE_CURRENT_FILTER'
const GET_EMPLOYEE_PERMISSIONS = 'employee/GET_EMPLOYEE_PERMISSIONS'
const GET_EMPLOYEE_POSITIONS = 'employee/GET_EMPLOYEE_POSITIONS'
const GET_CURRENT_EMPLOYEE = 'employee/GET_CURRENT_EMPLOYEE'
const GET_TMP_FILTER_INFO = 'employee/GET_TMP_FILTER_INFO'
const GET_TMP_FILTER_INFO_ROLES = 'employee/GET_TMP_FILTER_INFO_ROLES'

//control departments
const GET_CONTROL_DEPARTMENTS = 'departments/GET_CONTROL_DEPARTMENTS'
//control permissions
const GET_CONTROL_PERMISSIONS = 'permissions/GET_CONTROL_PERMISSIONS'
const GET_PERMISSIONS_GROUP = 'permissions/GET_PERMISSIONS_GROUP'
const GET_FILTERS_PERMISSION_DEFAULT = 'permissions/GET_FILTERS_PERMISSION_DEFAULT'
const GET_TMP_PERMISSION_FILERS = 'permissions/GET_TMP_PERMISSION_FILERS'
//control positions
const GET_CONTROL_POSITIONS = 'positions/GET_CONTROL_POSITIONS'
const GET_FILTERS_DEFAULT_POSITIONS = 'positions/GET_FILTERS_DEFAULT_POSITIONS'
const GET_FILTERS_POSITIONS_INFO = 'positions/GET_FILTERS_POSITIONS_INFO'
const GET_TMP_POSITIONS_FILTERS = 'positions/GET_TMP_POSITIONS_FILTERS'
//achievement
const GET_ACHIEVEMENTS = 'achievements/GET_ACHIEVEMENTS'
//cause points
const GET_CAUSE_POINTS = 'causePoints/GET_CAUSE_POINTS'

// Gifts
const GET_ALL_GIFTS = 'gifts/GET_ALL_GIFTS'
// Gifts Events
const GET_ALL_GIFTS_EVENTS = 'giftEvent/GET_ALL_GIFTS_EVENTS'
//Reason Delete
const GET_REASON_DELETE = 'reasons/GET_REASON_DELETE'
const GET_REASON_DELETE_ADMIN = 'reasons/GET_REASON_DELETE_ADMIN'
//Reason Block
const GET_REASON_BLOCK_USER = 'reasons/GET_REASON_BLOCK_USER'
const GET_REASON_BLOCK_PHOTO = 'reasons/GET_REASON_BLOCK_PHOTO'
//Support contact
const GET_SUPPORT_CONTACT_TYPES = 'supportContacts/GET_SUPPORT_CONTACT_TYPES'
//Market Utm
const GET_MARKET_UTM = 'market/GET_MARKET_UTM'
//Push
const GET_PUSHES = 'push/GET_PUSHES'
//Auto messages
const GET_AUTO_MESSAGES = 'autoMessages/GET_AUTO_MESSAGES'
const GET_AUTO_MESSAGES_COUNT = 'autoMessages/GET_AUTO_MESSAGES_COUNT'
const GET_AUTO_MESSAGES_PAGINATION_NEXT = 'autoMessages/GET_AUTO_MESSAGES_PAGINATION_NEXT'
const GET_AUTO_MESSAGES_PAGINATION_PREVIOUS = 'autoMessages/GET_AUTO_MESSAGES_PAGINATION_PREVIOUS'
const GET_AUTO_MESSAGES_PAGE = 'autoMessages/GET_AUTO_MESSAGES_PAGE'
const GET_AUTO_MESSAGES_NOW_LINK = 'autoMessages/GET_AUTO_MESSAGES_NOW_LINK'
//AutoMessagesList

const GET_AUTO_MESSAGES_LIST = 'autoMessagesList/GET_AUTO_MESSAGES_LIST'
const GET_MESSAGES_COUNT = 'autoMessagesList/GET_MESSAGES_COUNT'
const GET_FILTERS_DEFAULT_AUTO_MESSAGE = 'autoMessagesList/GET_FILTERS_DEFAULT_AUTO_MESSAGE'
const GET_TMP_FILERS_AUTO_MESSAGE = 'autoMessagesList/GET_TMP_FILERS_AUTO_MESSAGE'
const GET_CURRENT_FILERS_AUTO_MESSAGE = 'autoMessagesList/GET_CURRENT_FILERS_AUTO_MESSAGE'
const GET_USER_GENDER_AUTO_MESSAGE = 'autoMessagesList/GET_USER_GENDER_AUTO_MESSAGE'
const GET_FILTER_PAGE_AUTO_MESSAGE = 'autoMessagesList/GET_FILTER_PAGE_AUTO_MESSAGE'
const GET_FILTERS_INFO_AUTO_MESSAGE = 'autoMessagesList/GET_FILTERS_INFO_AUTO_MESSAGE'
const GET_FILTERS_COUNT = 'autoMessagesList/GET_FILTERS_COUNT'

//operators
const GET_OPERATORS = 'operators/GET_OPERATORS'
const GET_OPERATOR_ROLES = 'operators/GET_OPERATOR_ROLES'
const GET_OPERATOR_TMP_ROLES = 'operators/GET_OPERATOR_TMP_ROLES'
const GET_OPERATOR_ROLES_DEFAULT = 'operators/GET_OPERATOR_ROLES_DEFAULT'
const GET_OPERATORS_CURRENCY = 'operators/GET_OPERATORS_CURRENCY'
const GET_OPERATOR_USERS = 'operators/GET_OPERATOR_USERS'
const GET_CURRENT_OPERATOR_USER = 'operators/GET_CURRENT_OPERATOR_USER'
const GET_ACTIVE_OPERATOR = 'operators/GET_ACTIVE_OPERATOR'
const GET_OPERATOR_SEARCH_OPTION = 'operators/GET_OPERATOR_SEARCH_OPTION'
const GET_OPERATOR_STATISTIC = 'operators/GET_OPERATOR_STATISTIC'
const GET_CURRENT_OPERATOR = 'operators/GET_CURRENT_OPERATOR'
const GET_OPERATOR_PAYOUT = 'operators/GET_OPERATOR_PAYOUT'
const GET_OPERATOR_BANNER = 'operators/GET_OPERATOR_BANNER'
const GET_OPERATORS_AUTO_MESSAGE = 'operators/GET_OPERATORS_AUTO_MESSAGE'
const GET_TMP_DEFAULT_FILTERS_OPERATOR = 'operators/GET_TMP_DEFAULT_FILTERS_OPERATOR'
const GET_TMP_FILTERS_OPERATOR = 'operators/GET_TMP_FILTERS_OPERATOR'

//calendar
const GET_SHIFT_OPTIONS = 'calendar/GET_SHIFT_OPTIONS'
const GET_CALENDAR_EVENTS = 'calendar/GET_CALENDAR_EVENTS'
//payments setting
const GET_PAYMENTS_SETTING = 'operatorPayments/GET_PAYMENTS_SETTING'
const GET_DEFAULT_PAYMENT_SETTING = 'operatorPayments/GET_DEFAULT_PAYMENT_SETTING'

//moderator
const GET_MODERATORS = 'moderatorPayments/GET_MODERATORS'
const GET_MODERATORS_CURRENCY = 'moderatorPayments/GET_MODERATORS_CURRENCY'
const GET_MODERATOR_ROLES = 'moderatorPayments/GET_MODERATOR_ROLES'
const GET_MODERATOR_TMP_ROLES = 'moderatorPayments/GET_MODERATOR_TMP_ROLES'
const GET_MODERATOR_ROLES_DEFAULT = 'moderatorPayments/GET_MODERATOR_ROLES_DEFAULT'
const GET_MODERATOR_SEARCH_OPTION = 'moderatorPayments/GET_MODERATOR_SEARCH_OPTION'
const GET_MODERATOR_STATISTIC = 'moderatorPayments/GET_MODERATOR_STATISTIC'
const GET_CURRENT_MODERATOR = 'moderatorPayments/GET_CURRENT_MODERATOR'
const GET_MODERATOR_PAYOUT = 'moderatorPayments/GET_MODERATOR_PAYOUT'
const GET_MODERATOR_DEFAULT_PAYMENT_SETTING = 'moderatorPayments/GET_MODERATOR_DEFAULT_PAYMENT_SETTING'
//moderator rating
const GET_MODERATORS_RATING = 'moderatorRating/GET_MODERATORS_RATING'
const GET_MODERATOR_SETTINGS_SPEED = 'moderatorRating/GET_MODERATOR_SETTINGS_SPEED'
const GET_MODERATOR_SETTINGS_QUALITY = 'moderatorRating/GET_MODERATOR_SETTINGS_QUALITY'
const GET_MODERATOR_RATING_ROLES = 'moderatorRating/GET_MODERATOR_RATING_ROLES'
const GET_MODERATOR_ROLES_RATING_DEFAULT = 'moderatorRating/GET_MODERATOR_ROLES_RATING_DEFAULT'
const GET_MODERATOR_RATING_TMP_ROLES = 'moderatorRating/GET_MODERATOR_RATING_TMP_ROLES'

//purchases
const GET_PURCHASES = 'purchases/GET_PURCHASES'
const GET_PURCHASES_ROLES = 'purchases/GET_PURCHASES_ROLES'
const GET_PURCHASES_ROLES_DEFAULT = 'purchases/GET_PURCHASES_ROLES_DEFAULT'
const GET_PURCHASES_TMP_ROLES = 'purchases/GET_PURCHASES_TMP_ROLES'
//statistics
const GET_APPS_FLYER = 'statistics/GET_APPS_FLYER'
const GET_APPS_FLYER_IOS = 'statistics/GET_APPS_FLYER_IOS'
const GET_ROYAL_USERS_INSTALL_IOS = 'statistics/GET_ROYAL_USERS_INSTALL_IOS'
const GET_ROYAL_USERS_INSTALL = 'statistics/GET_ROYAL_USERS_INSTALL'
const GET_USERS_ACTIVITY = 'statistics/GET_USERS_ACTIVITY'
const GET_USERS_COUNT = 'statistics/GET_USERS_COUNT'
const GET_USERS_ONLINE_BY_CITY = 'statistics/GET_USERS_ONLINE_BY_CITY'
const GET_PURCHASES_MARKET_PLACES = 'statistics/GET_PURCHASES_MARKET_PLACES'
const GET_PURCHASES_BY_CITY = 'statistics/GET_PURCHASES_BY_CITY'
const GET_PURCHASES_BY_COUNTRY = 'statistics/GET_PURCHASES_BY_COUNTRY'
const GET_COUNTERS_MESSAGES_OPERATORS = 'statistics/GET_COUNTERS_MESSAGES_OPERATORS'
const GET_STATISTICS_PROFILE_MESSAGES = 'statistics/GET_STATISTICS_PROFILE_MESSAGES'
const GET_MODERATORS_VIDEO_CHECK = 'statistics/GET_MODERATORS_VIDEO_CHECK'
const GET_PURCHASES_BY_DATE = 'statistics/GET_PURCHASES_BY_DATE'
const GET_PURCHASES_BY_TIME = 'statistics/GET_PURCHASES_BY_TIME'
const GET_PURCHASES_BY_PAYERS_PAYMENTS = 'statistics/GET_PURCHASES_BY_PAYERS_PAYMENTS'
const GET_USERS_ONLINE_ONE_DAY = 'statistics/GET_USERS_ONLINE_ONE_DAY'
const GET_USERS_ONLINE_ONE_DAY_SITE_AND_APP = 'statistics/GET_USERS_ONLINE_ONE_DAY_SITE_AND_APP'
const GET_NEW_USER_ACTIVE_BY_DATE = 'statistics/GET_NEW_USER_ACTIVE_BY_DATE'
const GET_MODERATORS_PHOTO_CHECK = 'statistics/GET_MODERATORS_PHOTO_CHECK'
const GET_PURCHASE_TOTAL = 'statistics/GET_PURCHASE_TOTAL'
const GET_USERS_GENDER = 'statistics/GET_USERS_GENDER'
const GET_ACTIVE_NEW_USER = 'statistics/GET_ACTIVE_NEW_USER'
const GET_USERS_PAYING = 'statistics/GET_USERS_PAYING'
const GET_USERS_AGE = 'statistics/GET_USERS_AGE'
const GET_USER_LIFETIME = 'statistics/GET_USER_LIFETIME'
const GET_REGS_NEW_USERS = 'statistics/GET_REGS_NEW_USERS'
const GET_OPERATORS_MESSAGE = 'statistics/GET_OPERATORS_MESSAGE'
const GET_OPERATORS_CASHES = 'statistics/GET_OPERATORS_CASHES'
const GET_PURCHASES_POINTS = 'statistics/GET_PURCHASES_POINTS'
const GET_PURCHASES_VIP_STATUS = 'statistics/GET_PURCHASES_VIP_STATUS'

// USER STATISTIC
const GET_USER_STATISTIC_ADVANCED = 'userStatistic/GET_USER_STATISTIC_ADVANCED'
const GET_STATISTIC_USER_BY_DATE = 'userStatistic/GET_STATISTIC_USER_BY_DATE'

export {
  GET_LOADING,
  GET_USER,
  GET_TOKEN,
  GET_LOGIN,
  GET_PERMISSIONS,
  GET_PERMISSIONS_USER,
  //users
  GET_USERS,
  GET_CURRENT_USER,
  GET_VALUE_PICKER,
  GET_FILTERS_INFO,
  GET_FILTERS_DEFAULT,
  GET_TMP_FILERS,
  GET_USER_GENDER,
  GET_USER_DEPOSIT,
  GET_USER_PAYOUTS,
  GET_TRANSACTION_KIND,
  GET_USER_PURCHASES,
  GET_USER_PHOTOS,
  GET_USER_CHATS,
  GET_CHAT_MESSAGES,
  GET_CURRENT_FILERS,
  GET_USER_COUNTS,
  GET_USER_FILTER_COUNTS,
  GET_FILTER_PAGE,
  GET_USERCHATS_STYLES,
  //user claims
  GET_ALL_USERS_CALIMS,
  //view mobile app
  GET_RECORDS,
  GET_LANGUAGE,
  GET_GENDER,
  GET_COUNTRY,
  GET_PAGE_TYPES,
  GET_RECORD_DEFAULT,
  GET_TMP_RECORD_FILTERS,
  //events
  GET_MERGED_CATEGORIES,
  GET_EVENTS,
  GET_EVENT,
  GET_EVENTS_REFBOOK,
  GET_EVENT_VALUE_PICKER,
  GET_EVENT_FILTERS_INFO,
  GET_EVENT_FILTERS_DEFAULT,
  GET_EVENT_TMP_FILERS,
  GET_CATEGORIES_BY_LANG,
  GET_EVENTS_FILTERS,
  GET_EVENT_COUNTS,
  // languages
  GET_LANGUAGES,
  GET_CURRENT_LANGUAGES,
  GET_CURRENT_LANGUAGES_ID,
  // FAQ
  GET_ALL_CATEGORY_BY_LANG,
  GET_CONTENT_BY_ID,
  GET_CURRENT_CONTENT_ID,
  GET_CURRENT_ITEM_ACTIVE,
  // control employee
  GET_EPLOYEES,
  GET_EMPLOYEE_ROLES,
  GET_EMPLOYEE_ROLES_DEFAULT,
  GET_EMPLOYEE_TMP_ROLES,
  GET_EMPLOYEE_CURRENT_FILTER,
  GET_EMPLOYEE_PERMISSIONS,
  GET_EMPLOYEE_POSITIONS,
  GET_CURRENT_EMPLOYEE,
  GET_TMP_FILTER_INFO,
  GET_TMP_FILTER_INFO_ROLES,
  // control departments
  GET_CONTROL_DEPARTMENTS,
  //control permissions
  GET_CONTROL_PERMISSIONS,
  GET_PERMISSIONS_GROUP,
  GET_FILTERS_PERMISSION_DEFAULT,
  GET_TMP_PERMISSION_FILERS,
  //control positions
  GET_CONTROL_POSITIONS,
  GET_FILTERS_DEFAULT_POSITIONS,
  GET_FILTERS_POSITIONS_INFO,
  GET_TMP_POSITIONS_FILTERS,
  // achievements
  GET_ACHIEVEMENTS,
  //cause points
  GET_CAUSE_POINTS,
  // Gifts
  GET_ALL_GIFTS,
  // Gifts Event
  GET_ALL_GIFTS_EVENTS,
  //Reason Delete
  GET_REASON_DELETE,
  GET_REASON_DELETE_ADMIN,
  //support contact
  GET_SUPPORT_CONTACT_TYPES,
  //Reason Block
  GET_REASON_BLOCK_USER,
  GET_REASON_BLOCK_PHOTO,
  //Market Utm
  GET_MARKET_UTM,
  //Push
  GET_PUSHES,
  //Auto messages
  GET_AUTO_MESSAGES,
  GET_AUTO_MESSAGES_COUNT,
  GET_AUTO_MESSAGES_PAGINATION_NEXT,
  GET_AUTO_MESSAGES_PAGINATION_PREVIOUS,
  GET_AUTO_MESSAGES_PAGE,
  GET_AUTO_MESSAGES_NOW_LINK,
  //AutoMessagesList
  GET_AUTO_MESSAGES_LIST,
  GET_MESSAGES_COUNT,
  GET_FILTERS_DEFAULT_AUTO_MESSAGE,
  GET_TMP_FILERS_AUTO_MESSAGE,
  GET_CURRENT_FILERS_AUTO_MESSAGE,
  GET_USER_GENDER_AUTO_MESSAGE,
  GET_FILTER_PAGE_AUTO_MESSAGE,
  GET_FILTERS_COUNT,
  GET_FILTERS_INFO_AUTO_MESSAGE,
  //operators
  GET_OPERATORS,
  GET_OPERATOR_ROLES,
  GET_OPERATOR_TMP_ROLES,
  GET_OPERATOR_ROLES_DEFAULT,
  GET_OPERATORS_CURRENCY,
  GET_OPERATOR_USERS,
  GET_CURRENT_OPERATOR_USER,
  GET_OPERATOR_SEARCH_OPTION,
  GET_ACTIVE_OPERATOR,
  GET_OPERATOR_STATISTIC,
  GET_CURRENT_OPERATOR,
  GET_OPERATOR_PAYOUT,
  GET_OPERATOR_BANNER,
  GET_OPERATORS_AUTO_MESSAGE,
  GET_TMP_DEFAULT_FILTERS_OPERATOR,
  GET_TMP_FILTERS_OPERATOR,
  //calendar
  GET_SHIFT_OPTIONS,
  GET_CALENDAR_EVENTS,
  //payments setting
  GET_PAYMENTS_SETTING,
  GET_DEFAULT_PAYMENT_SETTING,
  GET_MODERATORS,
  GET_MODERATORS_CURRENCY,
  GET_MODERATOR_ROLES,
  GET_MODERATOR_TMP_ROLES,
  GET_MODERATOR_ROLES_DEFAULT,
  GET_MODERATOR_SEARCH_OPTION,
  GET_MODERATOR_STATISTIC,
  GET_CURRENT_MODERATOR,
  //moderation
  GET_MODERATOR_PAYOUT,
  GET_MODERATOR_DEFAULT_PAYMENT_SETTING,
  GET_MODERATORS_RATING,
  GET_MODERATOR_SETTINGS_SPEED,
  GET_MODERATOR_SETTINGS_QUALITY,
  GET_MODERATOR_RATING_ROLES,
  GET_MODERATOR_ROLES_RATING_DEFAULT,
  GET_MODERATOR_RATING_TMP_ROLES,
  //purchases
  GET_PURCHASES,
  GET_PURCHASES_ROLES,
  GET_PURCHASES_ROLES_DEFAULT,
  GET_PURCHASES_TMP_ROLES,
  //statistics
  GET_APPS_FLYER,
  GET_PURCHASES_POINTS,
  GET_APPS_FLYER_IOS,
  GET_PURCHASES_VIP_STATUS,
  GET_ROYAL_USERS_INSTALL,
  GET_ROYAL_USERS_INSTALL_IOS,
  GET_COUNTERS_MESSAGES_OPERATORS,
  GET_STATISTICS_PROFILE_MESSAGES,
  GET_USERS_ACTIVITY,
  GET_USERS_COUNT,
  GET_PURCHASES_BY_DATE,
  GET_USERS_ONLINE_BY_CITY,
  GET_PURCHASE_TOTAL,
  GET_PURCHASES_MARKET_PLACES,
  GET_PURCHASES_BY_CITY,
  GET_OPERATORS_MESSAGE,
  GET_OPERATORS_CASHES,
  GET_MODERATORS_VIDEO_CHECK,
  GET_PURCHASES_BY_COUNTRY,
  GET_PURCHASES_BY_TIME,
  GET_PURCHASES_BY_PAYERS_PAYMENTS,
  GET_USERS_ONLINE_ONE_DAY,
  GET_USERS_ONLINE_ONE_DAY_SITE_AND_APP,
  GET_NEW_USER_ACTIVE_BY_DATE,
  GET_MODERATORS_PHOTO_CHECK,
  GET_USERS_GENDER,
  GET_ACTIVE_NEW_USER,
  GET_USERS_PAYING,
  GET_USERS_AGE,
  GET_USER_LIFETIME,
  GET_REGS_NEW_USERS,
  // USER STATISTIC
  GET_USER_STATISTIC_ADVANCED,
  GET_STATISTIC_USER_BY_DATE
}
