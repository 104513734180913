import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import { GET_TOKEN, GET_PERMISSIONS_USER, GET_USER } from '@/store/getter-types'
import { REQUEST_LOGOUT, REQUEST_PERMISSION_USER } from '@/store/action-types'
import { routerDuplicatedFix } from '@/polyfills'
import Permissions from '@/constants/permissions.js'

routerDuplicatedFix()

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Mainpage',
    component: () => import('@/views/users/Users.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.UserListWithTransition, Permissions.UserListWithoutTransition]
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/users/Users.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.UserListWithTransition, Permissions.UserListWithoutTransition]
    }
  },
  {
    path: '/auto-message-list',
    name: 'autoMessage',
    component: () => import('@/views/automessages/autoMessages.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.Automessages]
    }
  },
  {
    path: '/users/:userId',
    name: 'UserItem',
    component: () => import('@/views/users/UserItem.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.UserProfileAccess]
    }
  },
  {
    path: '/first-screen',
    name: 'FirstScreen',
    component: () => import('@/views/firstScreen/FirstScreenControl.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.ManagementApplication]
    }
  },
  // {
  //   path: "/user-dialogs/:userId",
  //   name: "DialogsUser",
  //   component: () => import("@/views/dialogs/DialogsUser.vue"),
  //   meta: {
  //     auth: true,
  //     permission_lvl: [30]
  //   }
  // },
  {
    path: '/statistics-users',
    name: 'StatisticsUsers',
    component: () => import('@/views/statistics/StatisticsUsers.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.StatisticsUser]
    }
  },
  {
    path: '/statistics-payments-user',
    name: 'StatisticsPaymentsUser',
    component: () => import('@/views/statistics/StatisticsPaymentsUser.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.StatisticsPayments]
    }
  },
  {
    path: '/statistics-total-purchase',
    name: 'StatisticsTotalPurchase',
    component: () => import('@/views/statistics/StatisticsTotalPurchase.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.StatisticsPurchases]
    }
  },
  {
    path: '/statistics-traffic',
    name: 'StatisticsTraffic',
    component: () => import('@/views/statistics/StatisticsTraffic.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.StatisticsTraffic]
    }
  },
  {
    path: '/statistics-moderation',
    name: 'StatisticsModeration',
    component: () => import('@/views/statistics/StatisticsModeration.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.StatisticsModeration]
    }
  },
  {
    path: '/statistics-advanced-operators',
    name: 'StatisticsAdvancedOperators',
    component: () => import('@/views/statistics/operatorsAdvanced.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.StatisticsOperatorExtend]
    }
  },
  {
    path: '/statistics-user-advanced/:id',
    name: 'UserStatisticAdvanced',
    component: () => import('@/views/statistics/userStatisticAdvanced.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.StatisticsOperatorExtend, Permissions.UserListWithTransition]
    }
  },
  // {
  //   path: '/payments',
  //   name: 'Payments',
  //   component: () => import('@/views/payments/Payments.vue'),
  //   meta: {
  //     auth: true,
  //     permission_lvl: [25]
  //   }
  // },
  {
    path: '/support-panel',
    name: 'Supportpanel',
    component: () => import('@/views/support/SupportPanel.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.SupportManagement]
    }
  },
  {
    path: '/support-analytics',
    name: 'Supportanalytics',
    component: () => import('@/views/support/SupportAnalytics.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.SupportManagement]
    }
  },
  {
    path: '/moderator-panel',
    name: 'Moderatorpanel',
    component: () => import('@/views/moderator/moderatorPanel.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.ModerationManagement]
    }
  },
  {
    path: '/moderator-analytics',
    name: 'Moderatoranalytics',
    component: () => import('@/views/moderator/moderatorAnalytics.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.ModerationAnalytics]
    }
  },
  {
    path: '/moderator-payments',
    name: 'moderator-payments',
    component: () => import('@/views/moderator/moderatorPayments.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.ModerationPayoutsForModerators]
    }
  },
  {
    path: '/moderator-stats/:moderator_id',
    name: 'moderators-statistic',
    component: () => import('@/views/moderator/moderatorsStatistic.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.ModerationPayoutsForModerators]
    }
  },
  {
    path: '/moderator-rating',
    name: 'moderator-rating',
    component: () => import('@/views/moderator/moderatorRating.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.ModerationRatingOfModerators]
    }
  },
  {
    path: '/interface-achievement',
    name: 'interfaceAchievement',
    component: () => import('@/views/interface/interfaceAchievement.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.LocalizationAchievements]
    }
  },
  {
    path: '/interface-gift',
    name: 'interfaceGift',
    component: () => import('@/views/interface/interfaceGift.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.LocalizationGifts]
    }
  },
  {
    path: '/interface-notice',
    name: 'interfaceNotice',
    component: () => import('@/views/interface/interfaceNotice.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.LocalizationNotifications]
    }
  },
  {
    path: '/interface-faq',
    name: 'interfaceFaq',
    component: () => import('@/views/interface/interfaceFaq.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.LocalizationFAQ]
    }
  },
  {
    path: '/interface-cause-delete',
    name: 'interfaceCauseDelete',
    component: () => import('@/views/interface/interfaceCauseDelete.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.LocalizationReasonsToDelete]
    }
  },
  {
    path: '/interface-cause-block',
    name: 'interfaceCauseBlock',
    component: () => import('@/views/interface/interfaceCauseBlock.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.LocalizationReasonsToBan]
    }
  },
  {
    path: '/interface-cause-points',
    name: 'interfaceCausePoints',
    component: () => import('@/views/interface/interfaceCausePoints.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.LocalizationBalls]
    }
  },
  {
    path: '/auto-messages',
    name: 'interfaceAutoMessages',
    component: () => import('@/views/interface/interfaceAutoMessages.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.LocalizationAutomessages]
    }
  },
  {
    path: '/interface-support-contact',
    name: 'interfaceSupportContact',
    component: () => import('@/views/interface/SupportContact.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.LocalizationTypesOfAppeals]
    }
  },
  {
    path: '/purchases',
    name: 'interfacePurchase',
    component: () => import('@/views/interface/interfacePurchase.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.LocalizationDigitalPurchases]
    }
  },
  {
    path: '/interface-user-claims',
    name: 'Interface-user-claims',
    component: () => import('@/views/interface/interfaceUserClaimsNotification.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.LocalizationWarnings]
    }
  },
  {
    path: '/languages',
    name: 'interfaceLanguages',
    component: () => import('@/views/interface/interfaceLanguages.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.LocalizationLanguages]
    }
  },
  {
    path: '/management-employee',
    name: 'managementEmployee',
    component: () => import('@/views/management/managementEmployee.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.ManagementEmployees]
    }
  },
  {
    path: '/management-rights',
    name: 'managmentAccessRight',
    component: () => import('@/views/management/managmentAccessRight.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.ManagementPermissions]
    }
  },
  {
    path: '/management-departments',
    name: 'managementDepartments',
    component: () => import('@/views/management/managementDepartments.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.ManagementDepartments]
    }
  },
  {
    path: '/management-position',
    name: 'managementPosition',
    component: () => import('@/views/management/managementPosition.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.ManagementPositions]
    }
  },
  {
    path: '/events-category',
    name: 'EventsCategory',
    component: () => import('@/views/events/EventsCategory.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.EventCategory]
    }
  },
  {
    path: '/events-list',
    name: 'EventsList',
    component: () => import('@/views/events/EventsList.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.EventListOfEvents]
    }
  },
  {
    path: '/events-list/:eventId',
    name: 'EventInfo',
    props: true,
    component: () => import('@/views/events/EventInfo.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.EventListOfEvents]
    }
  },
  {
    path: '/events-presents',
    name: 'EventsPresents',
    component: () => import('@/views/events/EventPresent.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.EventGiftsOfEvents]
    }
  },
  {
    path: '/events-my',
    name: 'EventMy',
    component: () => import('@/views/events/EventMy.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.EventYourEvent]
    }
  },
  {
    path: '/market-utm',
    name: 'marketUTM',
    component: () => import('@/views/market/marketUTM.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.MarketingUTM]
    }
  },
  {
    path: '/market-media',
    name: 'marketMedia',
    component: () => import('@/views/market/marketMedia.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.MarketingSocialMedia]
    }
  },
  {
    path: '/operators',
    name: 'operators',
    component: () => import('@/views/operators/operators.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.OperatorListOfOperators]
    }
  },
  {
    path: '/operators-calendar',
    name: 'operators-calendar',
    component: () => import('@/views/operators/operatorsСalendar.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.OperatorCalendar]
    }
  },
  {
    path: '/operators-payments',
    name: 'operators-payments',
    component: () => import('@/views/operators/operatorsPayments.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.OperatorPayouts]
    }
  },
  {
    path: '/operators-stats/:operator_id',
    name: 'operators-statistic',
    component: () => import('@/views/operators/operatorsStatistic.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.OperatorPayouts]
    }
  },
  {
    path: '/statistics-operators',
    name: 'StatisticsOperators',
    component: () => import('@/views/statistics/StatisticsOperators.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.StatisticsOperatorsAll]
    }
  },
  {
    path: '/operators-settings',
    name: 'operators-settings',
    component: () => import('@/views/operators/operatorsSettings.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.OperatorSettings]
    }
  },
  {
    path: '/operators-banners',
    name: 'operators-banners',
    component: () => import('@/views/operators/operatorsBanners.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.OperatorBanners]
    }
  },
  {
    path: '/operators-auto-messages',
    name: 'operators-auto-messages',
    component: () => import('@/views/operators/operatorsAutoMessages.vue'),
    meta: {
      auth: true,
      permission_lvl: [Permissions.OperatorAutomessages]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Loginpage.vue'),
    meta: {
      notUser: true,
      hidden: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter(to, from, next) {
      store.dispatch(REQUEST_LOGOUT)
      setTimeout(() => {
        next('/')
      }, 200)
    }
  },
  {
    path: '*',
    name: 'Errorpage',
    component: () => import('@/views/404.vue'),
    meta: {
      hidden: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (!store.getters[GET_TOKEN]) {
      return next({ path: '/login' })
    }
    if (!store.getters[GET_PERMISSIONS_USER].length) {
      store.dispatch(REQUEST_PERMISSION_USER).then(response => {
        setTimeout(() => {
          if (response.status) {
            let userPosition = store.getters.GET_USER.position_code
            if (
              // store.getters[GET_PERMISSIONS_USER].length === 4 &&
              // store.getters[GET_PERMISSIONS_USER].includes(50)
              userPosition === 'moderator'
            ) {
              window.location = `${process.env.VUE_APP_DEPLOY_URL}/moderator/photo`
              return
            } else if (userPosition === 'operator') {
              next({ path: '/logout' })
              window.location = 'https://operator.skylove.su/login'
              return
            }
            if (to.matched.some(record => record.meta.permission_lvl)) {
              const userPermisions = store.getters[GET_PERMISSIONS_USER]
              const routesToVerifyPermission = to.matched.filter(record => record.meta.permission_lvl)
              let accessPermission = false
              // Когда уже авторизован
              if (
                // (routesToVerifyPermission[0].name === 'Mainpage' &&
                //   userPermisions.includes(45) &&
                //   userPermisions.length === 3) ||
                // (routesToVerifyPermission[0].name === 'Users' &&
                //   userPermisions.includes(45) &&
                //   userPermisions.length === 3)
                userPosition === 'operator'
              ) {
                next({ path: '/logout' })
                window.location = 'https://operator.skylove.su/login'
                return
              } else if (userPosition === 'moderator') {
                window.location = `${process.env.VUE_APP_DEPLOY_URL}/moderator/photo`
                return
              } else if (userPosition === 'operator' || userPosition === 'moderator') {
                accessPermission = false
              } else {
                accessPermission = routesToVerifyPermission.every(route => {
                  const routePermissions = route.meta.permission_lvl
                  const verifyPermission = routePermissions.some(permission =>
                    userPermisions.includes(permission)
                  )
                  return verifyPermission
                })
              }
              if (!accessPermission) {
                let rout = []
                store.getters[GET_PERMISSIONS_USER].map(m => {
                  router.options.routes.map(f => {
                    if (f.meta && f.meta.permission_lvl) {
                      f.meta.permission_lvl.map(item => {
                        if (item === m) {
                          rout.push(f.path)
                        }
                      })
                    }
                  })
                })
                if (rout.length) {
                  let unique = [...new Set(rout)]
                  let arrRoutes = []
                  let isOperator =
                    // store.getters[GET_PERMISSIONS_USER].length === 3 &&
                    // store.getters[GET_PERMISSIONS_USER].includes(45) &&
                    // store.getters[GET_PERMISSIONS_USER].includes(30)
                    store.getters[GET_PERMISSIONS_USER].includes(Permissions.UserListWithTransition) ||
                    store.getters[GET_PERMISSIONS_USER].includes(Permissions.UserListWithoutTransition)
                  if (isOperator) {
                    unique = unique.filter(f => f !== '/' || f !== '/users')
                  }
                  router.options.routes.map(m => {
                    arrRoutes.push(m.path)
                  })
                  unique.sort((a, b) => arrRoutes.indexOf(a) - arrRoutes.indexOf(b))
                  if (unique[0]) {
                    if (isOperator) {
                      return next({ path: unique[1] })
                    } else {
                      return next({ path: unique[0] })
                    }
                  }
                }
                return next({ path: '/users' })
              }
            }
          }
        }, 10)
      })
    } else {
      if (store.getters[GET_USER]) {
        const userPosition = store.getters[GET_USER].position_code
        if (
          // store.getters[GET_PERMISSIONS_USER].length === 4 &&
          // store.getters[GET_PERMISSIONS_USER].includes(50)
          userPosition === 'moderator'
        ) {
          window.location = `${process.env.VUE_APP_DEPLOY_URL}/moderator/photo`
          return
        } else if (userPosition === 'operator') {
          next({ path: '/logout' })
          window.location = 'https://operator.skylove.su/login'
          return
        }
        if (to.matched.some(record => record.meta.permission_lvl)) {
          const userPermisions = store.getters[GET_PERMISSIONS_USER]
          const routesToVerifyPermission = to.matched.filter(record => record.meta.permission_lvl)
          let accessPermission = false
          // Во время авторизации
          if (
            // (routesToVerifyPermission[0].name === 'Mainpage' &&
            //   userPermisions.includes(45) &&
            //   userPermisions.length === 3) ||
            // (routesToVerifyPermission[0].name === 'Users' &&
            //   userPermisions.includes(45) &&
            //   userPermisions.length === 3)
            userPosition === 'operator'
          ) {
            // accessPermission = false
            next({ path: '/logout' })
            window.location = 'https://operator.skylove.su/login'
            return
          } else if (userPosition === 'moderator') {
            window.location = `${process.env.VUE_APP_DEPLOY_URL}/moderator/photo`
            return
          } else if (userPosition !== 'operator' && userPosition === 'moderator') {
            accessPermission = false
          } else {
            accessPermission = routesToVerifyPermission.every(route => {
              const routePermissions = route.meta.permission_lvl
              const verifyPermission = routePermissions.some(permission =>
                userPermisions.includes(permission)
              )
              return verifyPermission
            })
          }
          if (!accessPermission) {
            let rout = []
            store.getters[GET_PERMISSIONS_USER].map(m => {
              router.options.routes.map(f => {
                if (f.meta && f.meta.permission_lvl) {
                  f.meta.permission_lvl.map(item => {
                    if (item === m) {
                      rout.push(f.path)
                    }
                  })
                }
              })
            })
            if (rout) {
              let unique = [...new Set(rout)]
              let arrRoutes = []
              let isOperator =
                // store.getters[GET_PERMISSIONS_USER].length === 2 &&
                // store.getters[GET_PERMISSIONS_USER].includes(45) &&
                // store.getters[GET_PERMISSIONS_USER].includes(30)
                store.getters[GET_PERMISSIONS_USER].includes(Permissions.UserListWithTransition) ||
                store.getters[GET_PERMISSIONS_USER].includes(Permissions.UserListWithoutTransition)
              if (!isOperator) {
                unique = unique.filter(f => f !== '/' && f !== '/users')
              }
              router.options.routes.map(m => {
                arrRoutes.push(m.path)
              })
              unique.sort((a, b) => arrRoutes.indexOf(a) - arrRoutes.indexOf(b))
              if (unique[0]) {
                if (isOperator) {
                  return next({ path: unique[1] })
                } else {
                  return next({ path: unique[0] })
                }
              }
            }
            return next({ path: '/users' })
          }
        }
      }
    }
  } else if (to.matched.some(record => record.meta.notUser)) {
    if (store.getters[GET_TOKEN]) {
      return next({ path: '/' })
    }
  }
  next()
})

export default router
