<template>
  <div class="user-active">
    <div class="user-active__menu">
      <div class="user-active__left">
        <span class="user-active__left-text">Дата:</span>
        <div class="datepicker-traffic-source">
          <datepicker
            class="datepicker-online"
            v-model="photos_review_stats_form.date"
            input-class="datepicker__input"
            :language="ru"
          >
          </datepicker>
          <i class="select__field-icon fa fa-angle-down"></i>
        </div>
        <button class="user-active__btn" @click="geModerationPhotosStats">
          Показать
        </button>
      </div>
      <ServerTimeFilter :utcFlag="photos_review_stats_form.utc" @changeUtc="changeUtc($event)" />
    </div>
    <div>
      <div v-if="photos_review_stats_main.datasets.length > 0">
        <h5 class="moderation-stats__title--main" style="color:#3a9215; margin-top: 20px">
          <strong>Выручка</strong>
        </h5>
        <BarChart
          :chart-data="photos_review_stats_main"
          :options="photos_review_stats_opt"
          class="regenterstats-chart"
        >
        </BarChart>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from './BarChart'
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import { ru } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import 'chartjs-plugin-datalabels'
import { REQUEST_OPERATORS_CASHES } from '@/store/action-types'
import { mapActions } from 'vuex'
import ServerTimeFilter from '@/components/Statistic/Servertimefilter/ServerTimeFilter'
export default {
  name: 'StatisticOperatorCahses',
  components: {
    BarChart,
    Datepicker,
    ServerTimeFilter
  },
  props: {
    moderatorsPhotoCheck: {
      type: Object
    }
  },
  data: () => ({
    ru: ru,
    newArr: null,
    photos_review_stats_main: {
      labels: [],
      datasets: []
    },
    photos_review_stats_inner: {
      labels: [],
      datasets: []
    },
    photos_review_stats_deferred: {
      labels: [],
      datasets: []
    },
    photos_review_stats_opt: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'label',
        intersect: false,
        callbacks: {
          label: function(tooltipItem, data) {
            const tooltip = data.datasets[tooltipItem.datasetIndex]
            const value = tooltip.data[tooltipItem.index]

            return value === 0 ? null : tooltip.label + ': ' + value
          }
        }
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              autoSkip: false,
              callback: function(tick) {
                return /^[0-9]{2}:(00)$/.test(tick.substring(0, tick.length - 3))
                  ? tick.substring(0, tick.length - 3)
                  : ''
              },
              maxRotation: 0
            }
          }
        ],
        yAxes: [
          {
            stacked: false,
            ticks: {
              maxTicksLimit: 8,
              beginAtZero: true,
              maxRotation: 0
            }
          }
        ]
      },
      legend: {
        display: true
      },
      plugins: {
        datalabels: {
          display: false
        }
      }
    },
    photos_review_stats_form: {
      date: moment().format('YYYY-MM-DD'),
      main_photo: 0,
      deferred: 0,
      utc: 1
    },
    photos_review_stats_loading: false
  }),
  watch: {
    'photos_review_stats_form.utc': function() {
      this.geModerationPhotosStats()
    }
  },
  mounted() {
    this.reLoaded()
    this.setDefaultOnlineDate()
  },
  methods: {
    ...mapActions({
      getModerationPhotos: REQUEST_OPERATORS_CASHES
    }),
    setDefaultOnlineDate: function() {
      this.photos_review_stats_form.date = moment().format('YYYY-MM-DD')
    },
    geModerationPhotosStats() {
      let newData = {
        date: moment(this.photos_review_stats_form.date).format('YYYY-MM-DD')
      }
      if (this.photos_review_stats_form.utc) newData.delta = new Date().getTimezoneOffset() / -60

      this.getModerationPhotos(newData).then(response => {
        this.reLoaded()
      })
    },
    getPhotosReviewStatsColors(index) {
      let colors = {
        'Model Test': '58, 146, 21',
        'Alina Alina': '148, 67, 164',
        'Andrea Andrea': '228, 62, 52',
        'Eternal Star': '49, 37, 146',
        'Katerina Katerina': '182, 114, 0',
        'Kim Личный': '67, 140, 76',
        'Личный Влада': '123, 25, 134',
        'Lilia Gordienko': '185, 129, 72',
        'Mei Li Личный': '158, 146, 21',
        'Yuna Личный': '228, 162, 52',
        'Анастасия Тарасова': '149, 137, 146',
        'Анастасия Гоголева': '182, 114, 110',
        'Анна Личный': '178,214,131',
        'Влада Личный': '123, 125, 34',
        'Галина Личный': '185, 139, 172',
        'Евгения Франция': '38, 46, 21',
        'Ирина Личный': '98, 97, 164',
        'Маааш Мааш': '28, 62, 152',
        'Маша Маша': '49, 137, 14',
        'Нана Личный': '82, 114, 50',
        'Россия  Россия': '127, 120, 86',
        'США  Канада': '163, 95, 134',
        'Франция Франция': '125, 29, 22',
        'Юлия Личный': '0, 143, 129',
        'Нет данных': '255, 100, 100'
      }

      return colors[index]
    },
    getPhotosReviewStatsAdminName(admin_id, admins = {}) {
      // eslint-disable-next-line no-prototype-builtins
      if (admins.hasOwnProperty(admin_id)) {
        return admins[admin_id]
      }
      return admin_id
    },
    newData() {
      const data = {
        main: {
          data: this.moderatorsPhotoCheck.amount_pay,
          labels: this.moderatorsPhotoCheck.labels
        }
      }
      this.newArr = data
    },
    reLoaded() {
      this.newData()
      this.photos_review_stats_main.datasets = []
      this.photos_review_stats_main.labels = []
      this.photos_review_stats_inner.labels = []
      this.photos_review_stats_inner.datasets = []
      this.photos_review_stats_deferred.labels = []
      this.photos_review_stats_deferred.datasets = []

      for (let index in this.newArr) {
        const labels = this.newArr[index].labels
        const period = this.newArr[index].data
        let datasets = []
        let stats = {}

        if (index === 'main') {
          stats = this.photos_review_stats_main
        } else if (index === 'inner') {
          stats = this.photos_review_stats_inner
        } else if (index === 'deferred') {
          stats = this.photos_review_stats_deferred
        } else {
          return false
        }
        for (let key in period) {
          // eslint-disable-next-line no-prototype-builtins
          if (period.hasOwnProperty(key)) {
            stats.labels.push(key)

            for (let adm in period[key]) {
              // eslint-disable-next-line no-prototype-builtins
              if (period[key].hasOwnProperty(adm)) {
                if (!labels.find(f => f === adm)) {
                  continue
                }
                let data_idx = datasets.indexOf(adm)
                if (data_idx === -1) {
                  data_idx = datasets.push(adm) - 1
                }
                // eslint-disable-next-line no-prototype-builtins
                if (!stats.datasets.hasOwnProperty(data_idx)) {
                  let dataset = {}
                  if (adm === 'Не проверенно') {
                    dataset = {
                      borderColor: `rgba(151,187,205,1)`,
                      backgroundColor: `rgba(151,187,205,0.5)`,
                      label: this.getPhotosReviewStatsAdminName(adm, labels),
                      barPercentage: 1.0,
                      categoryPercentage: 1.0,
                      barThickness: 'flex',
                      data: []
                    }
                  } else {
                    dataset = {
                      borderColor: `rgba(${this.getPhotosReviewStatsColors(adm)},1)`,
                      backgroundColor: `rgba(${this.getPhotosReviewStatsColors(adm)},0.5)`,
                      label: this.getPhotosReviewStatsAdminName(adm, labels),
                      barPercentage: 1.0,
                      categoryPercentage: 1.0,
                      barThickness: 'flex',
                      data: []
                    }
                  }

                  stats.datasets[data_idx] = dataset
                }
                stats.datasets[data_idx].data.push(period[key][adm])
              }
            }
          }
        }
      }
    },
    changeUtc(number) {
      this.photos_review_stats_form.utc = number
    }
  }
}
</script>

<style lang="scss">
@import './Statistic.scss';
</style>
