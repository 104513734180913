<template>
  <Popup
    :visible="visible"
    @close="closeModalHandler"
    description="Добавление новой записи"
    modalClass="popup--medium"
  >
    <form @submit.prevent="sendNewRecord" class="popup__form">
      <div class="popup__item" v-if="recordErrorText">
        <div class="view-record__error">
          <span>Ошибка: {{ recordErrorText }}</span>
        </div>
      </div>
      <div class="popup__item">
        <div class="view-record__group">
          <div class="form__item">
            <span class="form__item-title">Язык:</span>
            <div class="form__select">
              <button type="button" class="form__value" @click="clickClose('openlang')">
                <span class="form__value-text">{{ setDefaultFilter({ key: 'lang' }) }}</span>
                <i class="fa fa-angle-down form__select-icon"></i>
              </button>
              <ul
                class="form__select-list"
                v-if="selects.openlangSelect"
                @mouseleave="mouseLeave('openlang')"
              >
                <li
                  v-for="lang in langs"
                  :key="lang.id"
                  class="form__select-item"
                  @click="
                    selectSettingFilter({
                      key: 'lang',
                      value: lang
                    })
                  "
                >
                  <span class="form__select-text">{{ lang.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="form__item">
            <span class="form__item-title">Страна:</span>
            <div class="form__select">
              <button type="button" class="form__value" @click="clickClose('opencountry')">
                <span class="form__value-text">{{ setDefaultFilter({ key: 'country' }) }}</span>
                <i class="fa fa-angle-down form__select-icon"></i>
              </button>
              <ul
                class="form__select-list form__select-list--country"
                v-if="selects.opencountrySelect"
                @mouseleave="mouseLeave('opencountry')"
              >
                <li
                  v-for="country in countries"
                  :key="country.id"
                  class="form__select-item"
                  @click="
                    selectSettingFilter({
                      key: 'country',
                      value: country
                    })
                  "
                >
                  <span class="form__select-text">{{ country.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="form__item">
            <span class="form__item-title">Пол:</span>
            <div class="form__select">
              <button type="button" class="form__value" @click="clickClose('opengender')">
                <span class="form__value-text">{{ setDefaultFilter({ key: 'gender' }) }}</span>
                <i class="fa fa-angle-down form__select-icon"></i>
              </button>
              <ul
                class="form__select-list"
                v-if="selects.opengenderSelect"
                @mouseleave="mouseLeave('opengender')"
              >
                <li
                  v-for="gender in genders"
                  :key="gender.id"
                  class="form__select-item"
                  @click="
                    selectSettingFilter({
                      key: 'gender',
                      value: gender
                    })
                  "
                >
                  <span class="form__select-text">{{ gender.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="form__item">
            <span class="form__item-title">Тип страницы:</span>
            <div class="form__select">
              <button type="button" class="form__value" @click="clickClose('openpage')">
                <span class="form__value-text">{{ setDefaultFilter({ key: 'page' }) }}</span>
                <i class="fa fa-angle-down form__select-icon"></i>
              </button>
              <ul
                class="form__select-list"
                v-if="selects.openpageSelect"
                @mouseleave="mouseLeave('openpage')"
              >
                <li
                  v-for="page in pages"
                  :key="page.id"
                  class="form__select-item"
                  @click="
                    selectSettingFilter({
                      key: 'page',
                      value: page
                    })
                  "
                >
                  <span class="form__select-text">{{ page.name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <label class="view-record__new-text"
            >Возраст от:
            <input
              v-model="age_min"
              type="number"
              min="18"
              max="99"
              class="view-record__new-input"
              v-tooltip="'Возраст должен быть больше минимального возраста и быть в диапозоне от 18 до 99'"
              required
            />
          </label>
          <label class="view-record__new-text"
            >Возраст до:
            <input
              v-model="age_max"
              type="number"
              min="18"
              max="99"
              class="view-record__new-input"
              v-tooltip="'Возраст должен быть больше минимального возраста и быть в диапозоне от 18 до 99'"
              required
            />
          </label>
        </div>
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--center">
          <button type="submit" class="popup__btn btn-green">Сохранить</button>
          <button type="button" @click="closeModalHandler" class="popup__btn btn-red">
            Отмена
          </button>
        </div>
      </div>
    </form>
  </Popup>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_CREATE_VIEW } from '@/store/action-types'
import { SET_CURRENT_RECORD_FILTER, SET_CLEAR_RECORD_FILTERS } from '@/store/mutation-types'
import {
  GET_LANGUAGES,
  GET_GENDER,
  GET_COUNTRY,
  GET_PAGE_TYPES,
  GET_RECORD_DEFAULT,
  GET_TMP_RECORD_FILTERS
} from '@/store/getter-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { VIEW_ADD_POPUP_RECORD } from '@/constants/event'
export default {
  name: 'ViewPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data: () => ({
    selects: {
      openlangSelect: false,
      opengenderSelect: false,
      opencountrySelect: false,
      openpageSelect: false
    },
    age_min: '',
    age_max: '',
    recordErrorText: ''
  }),
  computed: {
    ...mapGetters({
      langs: GET_LANGUAGES,
      genders: GET_GENDER,
      countries: GET_COUNTRY,
      pages: GET_PAGE_TYPES,
      defaultRecordFilter: GET_RECORD_DEFAULT,
      tmpFilters: GET_TMP_RECORD_FILTERS
    })
  },
  mounted() {
    this.$root.$on(VIEW_ADD_POPUP_RECORD, () => {
      this.open()
    })
  },
  beforeDestroy() {
    this.closeModalHandler()
  },
  methods: {
    ...mapActions({
      createNewView: REQUEST_CREATE_VIEW
    }),
    ...mapMutations({
      setCurrentFilterValue: SET_CURRENT_RECORD_FILTER,
      clearFilters: SET_CLEAR_RECORD_FILTERS
    }),
    closeSelect() {
      for (let select in this.selects) {
        this.selects[select] = false
      }
    },
    clickClose(option) {
      let newOption = this.selects[`${option}Select`]
      this.closeSelect()
      this.selects[`${option}Select`] = !newOption
    },
    mouseLeave(option) {
      this.selects[`${option}Select`] = false
    },
    sendNewRecord() {
      const createRecordData = {
        lang: this.setDefaultLangFilter({ key: 'lang' }),
        country_code: this.setDefaultFilter({
          key: 'country',
          value: 'country'
        }),
        gender: this.setDefaultFilter({ key: 'gender', value: 'gender' }),
        age_min: Number(this.age_min),
        age_max: Number(this.age_max),
        page: this.setDefaultFilter({ key: 'page', value: 'page' })
      }
      this.createNewView(createRecordData).then(response => {
        if (!response.status) {
          this.recordErrorText = 'Поля записи не могут быть одинаковыми'
        } else {
          this.close()
          this.clearFilters()
          this.age_min = ''
          this.age_max = ''
        }
      })
    },
    closeModalHandler() {
      this.closeSelect()
      this.clearFilters()
      this.age_min = ''
      this.age_max = ''
      this.close()
    },

    selectSettingFilter(obj) {
      const { key } = obj
      switch (key) {
        case 'lang':
          this.setCurrentFilterValue({ ...obj, method: 'lang' })
          break
        case 'gender':
          this.setCurrentFilterValue({ ...obj, method: 'gender' })
          break
        case 'country':
          this.setCurrentFilterValue({ ...obj, method: 'country' })
          break
        case 'page':
          this.setCurrentFilterValue({ ...obj, method: 'page' })
          break
        default:
          break
      }
      this.selects[`open${key}Select`] = false
    },

    setDefaultFilter(param) {
      const { key, value } = param
      if (value) {
        const field = this.tmpFilters[key].value
        return field ? field : this.defaultRecordFilter[`${key}`].value
      } else {
        const field = this.tmpFilters[key].name
        return field ? field : this.defaultRecordFilter[`${key}`].name
      }
    },

    setDefaultLangFilter(param) {
      const { key } = param
      const field = this.tmpFilters[key].id
      return field ? field : this.defaultRecordFilter[`${key}`].id
    }
  }
}
</script>
<style lang="scss">
@import './ViewPopup.scss';
</style>
