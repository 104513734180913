<template>
  <Popup :visible="visible" @close="close" :description="question" modalClass="popup--small">
    <div>
      <!-- <div class="popup__item">
        <span class="popup__item-title">Количество дней</span>
        <input type="text" class="popup__input default-input" v-model="day" required />
      </div> -->
      <div class="popup__item">
        <div class="popup__btns popup__btns--middle">
          <button type="submit" @click="sendData" class="popup__btn btn-green">
            Да
          </button>
          <button type="submit" @click="cancelBan" class="popup__btn btn-red">
            Нет
          </button>
        </div>
      </div>
    </div>
  </Popup>
</template>
<script>
import { mapActions } from 'vuex'
import { REQUEST_BAN_USER } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_DEVICE_BAN_EVENT } from '@/constants/event'
export default {
  name: 'UserDeviceBanPopup',
  components: {
    Popup
  },
  data() {
    return {
      id: '',
      day: 50000,
      isBan: null
    }
  },
  mixins: [ModalMixin],
  computed: {
    question() {
      if (this.isBan) {
        return 'Разблокировать пользователя по устройству?'
      } else {
        return 'Заблокировать пользователя по устройству?'
      }
    }
  },
  methods: {
    ...mapActions({
      banDevice: REQUEST_BAN_USER
    }),
    sendData() {
      const days = parseInt(this.day * 86400)
      const payload = {
        user_id: this.id,
        expire: this.isBan ? 0 : parseInt(+new Date() / 1000 + days),
        by_device: true
      }
      this.banDevice(payload)
      this.id = ''
      this.day = 50000
      this.isBan = null
      this.close()
    },
    cancelBan() {
      this.id = ''
      this.day = 50000
      this.isBan = null
      this.close()
    }
  },

  mounted() {
    this.$root.$on(USER_DEVICE_BAN_EVENT, user => {
      this.isBan = user.ban
      this.open()
      this.id = user.id
    })
  }
}
</script>

<style lang="scss">
.popup__btns--middle {
  display: flex;
  justify-content: space-between;
}
</style>
