import Request from '../helpers/Request'
import BaseService from './BaseService'

class EmployeeService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getEmployees() {
    const employeesUrl = `/api/v1/management/employee/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
      // 'Cache-Control': 'no-cache'
    }

    return await Request.get(employeesUrl, { headers })
  }

  async getEmployeesFilter(formData) {
    const employeesUrl = `/api/v1/management/employee/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
      // 'Cache-Control': 'no-cache'
    }

    Object.keys(formData).forEach(
      key =>
        (formData[key] === '' || formData[key] === undefined || formData[key] === 'Неважно') &&
        delete formData[key]
    )

    return await Request.get(employeesUrl, { headers, params: formData })
  }

  async getPermisions() {
    const permissionUrl = `/api/v1/management/permissions/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }
    let date = new Date()
    return await Request.get(permissionUrl, { headers, params: { date } })
  }

  async getPositions() {
    const positionUrl = `/api/v1/management/positions/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }
    let date = new Date()
    return await Request.get(positionUrl, { headers, params: { date } })
  }

  async deleteEmployeeById(id) {
    const employeeDestroyUrl = `/api/v1/management/employee/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(employeeDestroyUrl, { headers })
  }

  async editEmployee(formData, id) {
    const employeeEditUrl = `/api/v1/management/employee/${id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Content-Type': 'multipart/form-data'
    }
    return await Request.put(employeeEditUrl, formData, { headers })
  }

  async checkEmail(formData) {
    const employeeEditUrl = `/api/v1/management/employee/check-email/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(employeeEditUrl, formData, { headers })
  }

  async addEmployee(employee) {
    const employeeAddUrl = '/api/v1/management/employee/add/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(employeeAddUrl, employee, { headers })
  }
}

export default EmployeeService
