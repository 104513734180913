const REQUEST_CONTROL_PERMISSIONS = 'REQUEST_CONTROL_PERMISSIONS'
const REQUEST_CREATE_PERMISSION = 'REQUEST_CREATE_PERMISSION'
const REQUEST_DELETE_PERMISSION = 'REQUEST_DELETE_PERMISSION'
const REQUEST_UPDATE_PERMISSION = 'REQUEST_UPDATE_PERMISSION'
const REQUEST_PERMISSIONS_GROUP = 'REQUEST_PERMISSIONS_GROUP'
export {
  REQUEST_CONTROL_PERMISSIONS,
  REQUEST_CREATE_PERMISSION,
  REQUEST_DELETE_PERMISSION,
  REQUEST_UPDATE_PERMISSION,
  REQUEST_PERMISSIONS_GROUP
}
