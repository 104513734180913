<template>
  <div>
    <div
      v-if="message.content.content_type === MessageContentType.TEXT"
      class="chat__message"
      :class="{ 'chat__message--user': message.sender_id === currentUser.id }"
    >
      <div class="chat__message-image">
        <img :src="avatar(message.sender_id)" alt class="chat__message-img" />
      </div>
      <div class="chat__message-body">
        <p class="chat__message-info">
          <a
            v-if="isOperator"
            :href="`${link}/users/${message.sender_id}/`"
            target="_blank"
            class="chat__message-name"
            >{{ messageName(message.sender_id) }}</a
          >
          <span v-else class="chat__message-name">{{ messageName(message.sender_id) }}</span>
          <span class="chat__message-time">{{ toLocaleDateUser(message.create) }}</span>
        </p>
        <p class="chat__message-text" v-html="message.content.text.original"></p>
      </div>
    </div>
    <div
      v-if="message.content.content_type === MessageContentType.IMAGE"
      class="chat__message"
      :class="{ 'chat__message--user': message.sender_id === currentUser.id }"
    >
      <div class="chat__message-image">
        <img class="chat__message-img" :src="avatar(message.sender_id)" alt />
      </div>
      <div class="chat__message-body">
        <p class="chat__message-info">
          <a
            v-if="isOperator"
            :href="`${link}/users/${message.sender_id}/`"
            target="_blank"
            class="chat__message-name"
            >{{ messageName(message.sender_id) }}</a
          >
          <span v-else class="chat__message-name">{{ messageName(message.sender_id) }}</span>
          <span class="chat__message-time">{{ toLocaleDateUser(message.create) }}</span>
        </p>
        <p class="chat__message-text">{{ message.content.text.original }}</p>
        <div class="chat__message-picture">
          <img
            class="chat__message-picture"
            v-if="message.content.attachment_id"
            @click="resizeImg(message.message_id)"
            :ref="message.message_id"
            :src="
              getFile({
                type: 'image',
                file: message.content.attachment_id,
                width: `100%`,
                height: `100%`
              })
            "
            alt
          />
          <img v-else class="no-img" />
        </div>
      </div>
    </div>
    <div
      v-if="message.content.content_type === MessageContentType.VIDEO"
      class="chat__message"
      :class="{
        'chat__message--user': message.sender_id === currentUser.id
      }"
    >
      <div class="chat__message-image">
        <img class="chat__message-img" :src="avatar(message.sender_id)" alt />
      </div>
      <div class="chat__message-body">
        <p class="chat__message-info">
          <a
            v-if="isOperator"
            :href="`${link}/users/${message.sender_id}/`"
            target="_blank"
            class="chat__message-name"
            >{{ messageName(message.sender_id) }}</a
          >
          <span v-else class="chat__message-name">{{ messageName(message.sender_id) }}</span>
          <span class="chat__message-time">{{ toLocaleDateUser(message.create) }}</span>
        </p>
        <video
          class="chat__message-video"
          :src="getMediaLink(message.content.attachment_id, 'video')"
          ref="videocontainer"
          controls
        ></video>
      </div>
    </div>
    <div
      v-if="
        message.content.content_type === MessageContentType.AUDIO ||
          message.content.content_type === MessageContentType.MUSIC
      "
      class="chat__message"
      :class="{
        'chat__message--user': message.sender_id === currentUser.id
      }"
    >
      <div class="chat__message-image">
        <img class="chat__message-img" :src="avatar(message.sender_id)" alt />
      </div>
      <div class="chat__message-body">
        <p class="chat__message-info">
          <a
            v-if="isOperator"
            :href="`${link}/users/${message.sender_id}/`"
            target="_blank"
            class="chat__message-name"
            >{{ messageName(message.sender_id) }}</a
          >
          <span v-else class="chat__message-name">{{ messageName(message.sender_id) }}</span>
          <span class="chat__message-time">{{ toLocaleDateUser(message.create) }}</span>
        </p>
        <span>{{ message.content.text.original.messageText }}</span>
        <audio controls :src="getMediaLink(message.content.attachment_id, 'audio')"></audio>
        <span>{{ message.content.text.original.audioTitle }}</span>
      </div>
    </div>
    <div
      v-if="message.content.content_type === MessageContentType.FILE"
      class="chat__message"
      :class="{
        'chat__message--user': message.sender_id === currentUser.id
      }"
    >
      <div class="chat__message-image">
        <img class="chat__message-img" :src="avatar(message.sender_id)" alt />
      </div>
      <div class="chat__message-body">
        <p class="chat__message-info">
          <a
            v-if="isOperator"
            :href="`${link}/users/${message.sender_id}/`"
            target="_blank"
            class="chat__message-name"
            >{{ messageName(message.sender_id) }}</a
          >
          <span v-else class="chat__message-name">{{ messageName(message.sender_id) }}</span>
          <span class="chat__message-time">{{ toLocaleDateUser(message.create) }}</span>
        </p>
        <a
          class="chat__message-download"
          :href="
            getFile({
              type: 'file',
              file: message.content.attachment_id
            })
          "
          download="file"
          title="скачать файл"
        >
          <i class="fa fa-file"></i> Cкачать файл
        </a>
      </div>
    </div>
    <div
      v-if="message.content.content_type === MessageContentType.GEO"
      class="chat__message"
      :class="{
        'chat__message--user': message.sender_id === currentUser.id
      }"
    >
      <div class="chat__message-image">
        <img class="chat__message-img" :src="avatar(message.sender_id)" alt />
      </div>
      <div class="chat__message-body">
        <p class="chat__message-info">
          <a
            v-if="isOperator"
            :href="`${link}/users/${message.sender_id}/`"
            target="_blank"
            class="chat__message-name"
            >{{ messageName(message.sender_id) }}</a
          >
          <span v-else class="chat__message-name">{{ messageName(message.sender_id) }}</span>
          <span class="chat__message-time">{{ toLocaleDateUser(message.create) }}</span>
        </p>
        <AppUserMap class="chat__message-map" :currentUser="getCoord(message.content)" />
      </div>
    </div>
    <div
      v-if="message.content.content_type === MessageContentType.GIFT"
      class="chat__message"
      :class="{
        'chat__message--user': message.sender_id === currentUser.id
      }"
    >
      <div class="chat__message-image">
        <img class="chat__message-img" :src="avatar(message.sender_id)" alt />
      </div>
      <div class="chat__message-body">
        <p class="chat__message-info">
          <a
            v-if="isOperator"
            :href="`${link}/users/${message.sender_id}/`"
            target="_blank"
            class="chat__message-name"
            >{{ messageName(message.sender_id) }}</a
          >
          <span v-else class="chat__message-name">{{ messageName(message.sender_id) }}</span>
          <span class="chat__message-time">{{ toLocaleDateUser(message.create) }}</span>
        </p>
        <p class="chat__message-text">{{ message.content.text.original }}</p>
        <div class="chat__message-img__content">
          <img
            class="chat__message-picture"
            v-if="message.content.attachment_id"
            @click="resizeImg(message.message_id)"
            :ref="message.message_id"
            :src="getMediaLink(message.content.attachment_preview_id, 'image')"
            alt
          />
          <div v-else class="no-img" />
        </div>
      </div>
    </div>

    <div
      class="chat__message"
      v-if="
        message.content.content_type === MessageContentType.VIDEO_CALL ||
          message.content.content_type === MessageContentType.VIDEO_CALL_CANCELED ||
          message.content.content_type === MessageContentType.VIDEO_CALL_REJECTED
      "
      :class="[
        `chat__message--${videoCall.icon}`,
        { 'chat__message--user': message.sender_id === currentUser.id }
      ]"
    >
      <div class="chat__message-image">
        <img class="chat__message-img" :src="avatar(message.sender_id)" alt />
      </div>
      <div
        :class="[
          'chat__message-body chat__message-body__video',
          `chat__message-body__video--${videoCall.icon}`,
          { 'chat__message-body__video--companion': message.sender_id === currentUser.id }
        ]"
      >
        <p class="chat__message-info chat__message-body__video-info">
          <a
            v-if="isOperator"
            :href="`${link}/users/${message.sender_id}/`"
            target="_blank"
            class="chat__message-name"
            >{{ messageName(message.sender_id) }}</a
          >
          <span v-else class="chat__message-name">{{ messageName(message.sender_id) }}</span>
          <span class="chat__message-time">{{ toLocaleDateUser(message.create) }}</span>
        </p>
        <div class="chat__message-body__video-status">
          <div
            :class="[
              'chat__message-video-status__icons',
              { 'chat__message-video-status__icons--companion': message.sender_id === currentUser.id },
              { 'chat__message-video-status__icons--cancelled': videoCall.icon === 'cancelled' },
              {
                'chat__message-video-status__icons--cancelled-companion':
                  videoCall.icon === 'cancelled' && message.sender_id !== currentUser.id
              }
            ]"
          >
            <MessageVideoIcon :svgName="videoCall.icon" />
            <div class="chat__message-call chat__message-body__video-call">
              <span class="chat__message-text">{{ videoCall.text }}</span>
              <span class="chat__message-duration" v-if="videoCall.attachment_time"
                >Длительность звонка: {{ videoCall.attachment_time }}</span
              >
            </div>
            <i
              v-if="
                videoCall.icon === 'incoming' || videoCall.icon === 'cancelled' || videoCall.icon === 'missed'
              "
              class="fas fa-arrow-circle-right chat__message-video-status__icon-arrow"
            />
          </div>
        </div>
        <div
          :class="[
            'chat__message-body__video-avatar',
            { 'chat__message-body__video-avatar--companion': message.sender_id !== currentUser.id }
          ]"
        >
          <div class="chat__message-image">
            <img class="chat__message-img" :src="avatar(selectСompanionAvatarById)" alt="no-image" />
          </div>
          <span>{{ selectСompanionNameById }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MessageVideoIcon from '@/components/App-user/Message/MessageVideoIcon'
import AppUserMap from '@/components/App-user/App-user-map'
import getImgCdnLink from '@/components/mixins/getImgCdnLink'
import dateFormat from '@/components/mixins/toLocaleformat'
import videoCallName from '@/components/App-user/mixins/videoCallName'
import { mapGetters } from 'vuex'
import { GET_PERMISSIONS_USER } from '@/store/getter-types'
import { MessageContentType } from '@/constants/messageType'
import BigPicture from 'bigpicture'

export default {
  name: 'Message',
  components: {
    AppUserMap,
    MessageVideoIcon
  },
  props: {
    message: {
      type: Object
    },
    selectedChat: {
      type: Object
    },
    currentUser: {
      type: Object
    }
  },
  mixins: [getImgCdnLink, dateFormat, videoCallName],
  data: () => ({
    link: `${process.env.VUE_APP_DEPLOY_URL}`,
    MessageContentType
  }),
  computed: {
    ...mapGetters({
      permission_lvl: GET_PERMISSIONS_USER
    }),
    isOperator() {
      if (this.permission_lvl && this.permission_lvl.includes(103)) {
        return true
      } else {
        return false
      }
    },
    selectСompanionAvatarById() {
      if (this.message.sender_id !== this.currentUser.id) {
        return this.currentUser.id
      } else {
        return this.selectedChat.user.id
      }
    },
    selectСompanionNameById() {
      if (this.message.sender_id !== this.currentUser.id) {
        return this.currentUser.name
      } else {
        return this.selectedChat.user.name
      }
    }
  },
  methods: {
    avatar(id) {
      if (id === this.selectedChat.user.id) {
        //Не у всех юзеров есть кроп
        if (this.selectedChat.user.avatar.crop.x == null || this.selectedChat.user.avatar.crop.y == null) {
          return this.getFile({ file: this.selectedChat.user.avatar.id, type: 'image' })
        }
        return this.getImgLinkCropped(this.selectedChat.user.avatar.id, 480, {
          ...this.selectedChat.user.avatar.crop
        })
      } else {
        return this.getImgLinkCropped(this.currentUser.avatar.id, 480, { ...this.currentUser.avatar.crop })
      }
    },

    messageName(id) {
      if (id === this.selectedChat.user.id) {
        return this.selectedChat.user.name
      } else {
        return this.currentUser.name
      }
    },

    resizeImg(id) {
      BigPicture({ el: this.$refs[id] })
    },

    getCoord(data) {
      let newCoord = {
        location: {
          lng: data.longitude,
          lat: data.latitude
        }
      }
      return newCoord
    }
  }
}
</script>
