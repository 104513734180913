import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import EventService from '@/services/EventService'

export default {
  //EVENTS
  [ActionTypes.REQUEST_ALL_EVENTS]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EventService, [userToken]).getAllEvents()
    const { data } = response
    if (data.events) {
      commit(MutationTypes.SET_EVENTS, data.events)
      commit(MutationTypes.SET_EVENT_COUNTS, data.count)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EVENT_BY_ID]: async ({ rootGetters, commit }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EventService, [userToken]).getEventId(id)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_EVENT, data.event)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_PAGE_USERS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN

    const response = await Reflect.construct(EventService, [userToken]).getEvents(formData)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_ALL_EVENTS, data.events)
      commit(MutationTypes.SET_EVENT_COUNTS, data.count)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EVENTS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN

    const response = await Reflect.construct(EventService, [userToken]).getEvents(formData)
    const { data } = response
    if (data.events) {
      commit(MutationTypes.SET_EVENTS, data.events)
      commit(MutationTypes.SET_EVENT_COUNTS, data.count)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_DELETE_EVENT]: async ({ rootGetters, commit }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EventService, [userToken]).deleteEvent(data.id)

    const { status } = response
    if (status) {
      commit(MutationTypes.SET_EVENTS_ARHIVE, data.id)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  //CATEGORIES
  [ActionTypes.REQUEST_ALL_CATEGORIES]: async ({ rootGetters, commit }, lang = 'RU') => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN

    const categories = await Reflect.construct(EventService, [userToken]).getCategoryEvents()
    const allCategories = JSON.parse(JSON.stringify(categories))
    commit(MutationTypes.SET_MERGED_CATEGORIES, allCategories.data.events)
    if (categories.data.events) {
      let newCategory = categories.data.events.map(m => {
        m.locale = m.locale.find(f => f.lang === lang)
        m.subcategories.map(m => {
          m.locale = m.locale.find(f => f.lang === lang)
          return m
        })
        return m
      })
      newCategory = newCategory.filter(f => f.locale !== undefined)
      commit(MutationTypes.SET_CATEGORIES_BY_LANG, newCategory)
      commit(RootMutationTypes.SET_LOADER, false, { root: true })
    }
    return categories.data.events
  },
  // category events
  [ActionTypes.REQUEST_ADD_CATEGORY_EVENT]: async ({ rootGetters, commit, dispatch }, categoryPayload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EventService, [userToken]).addCategoryEvent(categoryPayload)
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_CATEGORIES, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_UPDATE_CATEGORY_EVENT]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EventService, [userToken]).updateCategoryEvent(payload)
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_CATEGORIES, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_DELETE_CATEGORY_EVENT]: async ({ rootGetters, commit, dispatch }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EventService, [userToken]).deleteCategoryEvent(id)

    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_CATEGORIES, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  // SUBCATEGORY events
  [ActionTypes.REQUEST_ADD_SUBCATEGORY_EVENT]: async (
    { rootGetters, commit, dispatch },
    subcategoryPayload
  ) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EventService, [userToken]).addSubCategoryEvent(
      subcategoryPayload
    )
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_CATEGORIES, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_UPDATE_SUBCATEGORY_EVENT]: async (
    { rootGetters, commit, dispatch },
    subcategoryPayload
  ) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EventService, [userToken]).updateSubCategoryEvent(
      subcategoryPayload
    )

    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_CATEGORIES, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_DELETE_SUBCATEGORY_EVENT]: async ({ rootGetters, commit, dispatch }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EventService, [userToken]).deleteSubCategoryEvent(id)

    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_CATEGORIES, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  // EVENTREFBOOK
  [ActionTypes.REQUEST_ALL_EVENT_REFBOOK]: async ({ rootGetters, commit }, lang = 'RU') => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EventService, [userToken]).getEventReferenceBook(lang)
    const { data, status } = response

    if (status) {
      commit(MutationTypes.SET_EVENTS_REFBOOK, data)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_ADD_EVENT_REFBOOK]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EventService, [userToken]).addEventReferenceBook(payload)
    // const lang = rootGetters["languages/GET_CURRENT_LANGUAGES"];
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_EVENT_REFBOOK, payload.lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_UPDATE_EVENT_REFBOOK]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EventService, [userToken]).updateEventReferenceBook(payload)
    // const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_EVENT_REFBOOK, payload.lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_DELETE_EVENT_REFBOOK]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(EventService, [userToken]).deleteEventReferenceBook(payload)
    // const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_EVENT_REFBOOK, payload.lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
