import { SET_LOADER } from '@/store/mutation-types'
import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import OperatorsService from '@/services/OperatorsService'

export default {
  [ActionTypes.REQUEST_ALL_OPERATORS]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).getOperators()
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_OPERATORS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_FILTERS_OPERATORS]: async ({ rootGetters, commit }, params) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).getEmployeesFilter(params)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_OPERATORS, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_DELETE_OPERATORS]: async ({ rootGetters, commit, dispatch }, employeeId) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).deleteOperator(employeeId)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_OPERATORS)
    // }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_DELETE_OPERATOR_USER]: async ({ rootGetters, commit, dispatch }, employeeId) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).deleteUserOperator(employeeId)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_OPERATORS)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EDIT_OPERATORS]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })

    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).editOperator(data)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_OPERATORS)
    // }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EDIT_OPERATOR_AGE]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })

    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).changeAge(data)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_OPERATORS)
    // }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EDIT_OPERATOR_NAME]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })

    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).changeName(data)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_OPERATORS)
    // }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CHANGE_COUNTRY_OPERATOR]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })

    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).changeUserCityCountryCode(data)
    // const { status } = response
    // if (status) {
    //   await setTimeout(() => {
    //     dispatch(ActionTypes.REQUEST_ALL_OPERATORS).then(res => {
    //       if (res.status) {
    //         const { data } = res
    //         commit(MutationTypes.SET_OPERATORS, data)
    //       }
    //     })
    //   }, 1000)
    // }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EDIT_OPERATOR_SEARCH]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })

    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).changeSearch(data)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_OPERATORS)
    // }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EDIT_OPERATORS_SEARCH_OPTIONS]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })

    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).editOperatorSearchOptions(data)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_OPERATORS)
    // }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CREATE_OPERATORS]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).addOperator(data)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_OPERATORS)
    // }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_FIND_USERS]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).getFilterOperatorUsers(formData)
    const { data } = response

    if (data) {
      commit(MutationTypes.SET_OPERATOR_USERS, data.user)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_OPERATOR_STATISTIC]: async ({ rootGetters, commit }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).getOperatorStatistic(id)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_CURRENT_OPERATOR, data.operator)
      commit(MutationTypes.SET_OPERATOR_STATISTIC, data.stats)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_OPERATOR_PAYOUT]: async ({ rootGetters, commit }, formData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).getOperatorsPayout(formData)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_OPERATOR_PAYOUT, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_BILLING_OPERATORS]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).billingOperator(data.data)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_OPERATOR_PAYOUT, data.active)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_PRIZE_OPERATORS]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).prizeOperator(data.data)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_OPERATOR_PAYOUT, data.active)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EXECUTE_OPERATORS]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).executeOperator(data.data)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_OPERATOR_PAYOUT, data.active)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EDIT_OPERATORS_AUTO_MESSAGE]: async ({ rootGetters, commit, dispatch }, data) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })

    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).editOperatorAutoMessage(data)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_OPERATORS)
    // }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_OPERATORS_BANNER]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).getOperatorsBanner()
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_OPERATOR_BANNER, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_EDIT_OPERATORS_BANNER]: async ({ rootGetters, commit }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).editOperatorsBanner(payload)
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_EDIT_OPERATOR_BANNER, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_DELETE_OPERATORS_BANNER]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).deleteOperatorBanner(payload)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_OPERATORS_BANNER)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CHANGE_OPERATORS_BANNER]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).changeOperatorsBanner(payload)
    const { data } = response
    if (data) {
      await dispatch(ActionTypes.REQUEST_OPERATORS_BANNER)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_OPERATORS_AUTO_MESSAGE]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).getOperatorsAutoMessage()
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_OPERATORS_AUTO_MESSAGE, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_EDIT_AUTO_MESSAGE_DELAY]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).editOperatorsAutoMessage(payload)
    const { data } = response
    if (data) {
      await dispatch(ActionTypes.REQUEST_OPERATORS_AUTO_MESSAGE)
    }
    commit(SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_CHANGE_OPERATORS_AUTO_MESSAGE]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).changeOperatorsAutoMessage(
      payload
    )
    const { data } = response
    if (data) {
      await dispatch(ActionTypes.REQUEST_OPERATORS_AUTO_MESSAGE)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_DELETE_OPERATORS_AUTO_MESSAGE]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).deleteOperatorsAutoMessage(
      payload
    )
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_OPERATORS_AUTO_MESSAGE)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EDIT_LOCATION_SETTING]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).editLocationOperator(payload)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_OPERATORS)
    // }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_TRANSFER_OPERATOR]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).transferOperator(payload)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_OPERATORS)
    // }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_CHANGE_WEIGHT_SENDING]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).changeWeightSending(payload)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_OPERATORS)
    // }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_CHANGE_TYPE_MESSAGE]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).changeTypeMessages(payload)
    // const { status } = response
    // if (status) {
    //   await dispatch(ActionTypes.REQUEST_ALL_OPERATORS)
    // }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_GET_OPERATOR_INFO_ON_SHIFT]: async ({ rootGetters, commit, dispatch }, payload) => {
    // commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(OperatorsService, [userToken]).getOperatorInfoOnShift(payload)
    // commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
