import Request from '../helpers/Request'
import BaseService from './BaseService'

class OperatorPaymentsService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getOperatorPayments() {
    const operatorsUrl = `/api/v1/management/operator/payout-settings/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(operatorsUrl, { headers })
  }

  async deleteOperatorPayment(data) {
    const operatorDestroyUrl = `/api/v1/management/operator/payout-settings/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(operatorDestroyUrl, { data, headers })
  }

  async editOperatorPayment(data) {
    const operatorEditUrl = `/api/v1/management/operator/payout-settings/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.put(operatorEditUrl, data, { headers })
  }

  async editOperatorPaymentDefault(data) {
    const operatorEditUrl = `/api/v1/management/operator/default-payout-settings/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(operatorEditUrl, data, { headers })
  }

  async addOperatorPayment(data) {
    const operatorAddUrl = '/api/v1/management/operator/payout-settings/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(operatorAddUrl, data, { headers })
  }

  async addOperatorPaymentDefault(data) {
    const operatorAddUrl = '/api/v1/management/operator/default-payout-settings/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(operatorAddUrl, data, { headers })
  }
}

export default OperatorPaymentsService
