<template>
  <div class="calendar">
    <div class="calendar__wrapper">
      <!-- <div v-if="permissions.find(f => f > 50)" class="calendar__options options">
        <span class="options__title calendar__title">Количество смен в сутках: </span>
        <div>
          <input
            class="options__input"
            type="number"
            v-model="newOptionCalendar"
            :placeholder="newOptionCalendar"
            ref="newoptioncalendar"
            min="1"
          />
          <button class="options__btn" type="button" @click="setNewOptions">
            Изменить
          </button>
        </div>
      </div> -->
      <div class="calendar__moderators">
        <div class="moderators">
          <h3 class="moderators__title calendar__title">Список операторов:</h3>
          <ul class="moderators__list" v-if="moderators.length > 0">
            <li v-for="moderator in moderators" :key="moderator.id" :data-id="moderator.id">
              <span class="drop-el" :data-id="moderator.id">
                <i class="fa fa-user" :style="`color: ${moderator.color}`"></i>
                <span class="first-name">{{ moderator.first_name }} </span>
                <span class="last-name">{{ moderator.last_name }}</span>
              </span>
            </li>
          </ul>
        </div>
        <div class="moderators__list app-no-data" v-if="!moderators.length">
          <span class="app-no-data__text">Нет активных операторов...</span>
        </div>
      </div>
    </div>
    <div v-if="!visible" class="calendar__mounths mounths">
      <div class="mounths__wrapper">
        <div class="mounths__left">
          <div class="mounths__button-group">
            <button
              @click="prevMonthGreed"
              type="button"
              class="calendar__button calendar__button--radius-right"
              aria-label="prev"
            >
              <span class="fc-icon fc-icon-chevron-left calendar__button-text"></span>
            </button>
            <button
              @click="nextMonthGreed"
              type="button"
              class="calendar__button calendar__button--radius-left"
              aria-label="next"
            >
              <span class="fc-icon fc-icon-chevron-right calendar__button-text"></span>
            </button>
          </div>
        </div>
        <div>
          <h2 class="mounths__title">{{ monthCalendarTitle }}</h2>
        </div>
        <div>
          <div>
            <button
              @click="acivateCalendar"
              type="button"
              class="calendar__button calendar__button--radius-right"
              aria-label="prev"
            >
              Неделя
            </button>
            <button
              type="button"
              class="
                calendar__button
                calendar__button--radius-left
                calendar__button--active
              "
              aria-label="next"
            >
              Месяц
            </button>
          </div>
        </div>
      </div>
      <div v-for="(week, index) in monthGreed.weeks" :key="index">
        <h5 class="mounths__week">{{ `${index + 1}-я неделя` }}</h5>
        <CalendarItem
          v-model="calendarOptions.events"
          :index="`calendar_${index + 1}`"
          :week="week"
          :events="calendarOptions.events"
          :options="monthGreed"
          :newOptions="optionsCalendar"
          :moderators="moderators"
          :start_calendar="start_calendar"
          :end_calendar="end_calendar"
          @my-event="getEvents"
        />
      </div>
    </div>
    <div v-else-if="visible" class="calendar__mounths">
      <FullCalendar :options="calendarOptions" ref="calendar" />
    </div>
    <CalendarAddEventPopup @my-event="getEvents" />
    <CalendarEditEventPopup @my-event="getEvents" />
    <CalendarDelModeratorPopup @my-event="getEvents" />
    <CalendarErrorPopup />
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from 'moment'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import {
  REQUEST_CALENDAR_EVENTS,
  REQUEST_ADD_CALENDAR_EVENT,
  REQUEST_ADD_MODERATOR_EVENT,
  REQUEST_SHIFT_OPTIONS,
  REQUEST_NEW_SHIFT_OPTIONS,
  REQUEST_DELL_MODERATOR_EVENT,
  REQUEST_CHANGE_OPERATOR_EVENT,
  REQUEST_MOD_OPERATOR_EVENTS
} from '@/store/action-types'
import { mapActions, mapGetters } from 'vuex'
import { CALENDAR_ADD_POPUP, CALENDAR_EDIT_POPUP, CALENDAR_ERROR_POPUP } from '@/constants/event'
import CalendarItem from './CalendarItem'
import CalendarAddEventPopup from '@/components/Modals/CalendarPopup/CalendarAddEventPopup'
import CalendarEditEventPopup from '@/components/Modals/CalendarPopup/CalendarEditEventPopup'
import CalendarDelModeratorPopup from '@/components/Modals/CalendarPopup/CalendarDelModeratorPopup'
import CalendarErrorPopup from '@/components/Modals/CalendarPopup/CalendarErrorPopup'
import { GET_CALENDAR_EVENTS, GET_PERMISSIONS_USER } from '@/store/getter-types'

export default {
  name: 'CalendarAll',
  components: {
    CalendarEditEventPopup,
    CalendarAddEventPopup,
    FullCalendar,
    CalendarItem,
    CalendarDelModeratorPopup,
    CalendarErrorPopup
  },
  props: {
    moderators: {
      type: Array
    },
    optionsCalendar: {
      type: Object
    }
  },
  data() {
    return {
      eventDrag: null,
      eventDropElement: null,
      CALENDAR_ADD_POPUP,
      CALENDAR_EDIT_POPUP,
      CALENDAR_ERROR_POPUP,
      shift: this.options,
      visible: false,
      start_calendar: null,
      end_calendar: null,
      timezone: 'UTC',
      month_calendar: null,
      errorDrag: false,
      newOption: null,

      draggableEventsAreExists: false,
      calendarOptions: {
        dayCellClassNames: this.dayRenderHandler,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        drop: this.dropHandler,
        eventReceive: function() {},
        datesSet: this.eventDatesRenderHandler,
        dayMaxEvents: 50,
        events: [],
        eventDidMount: function(arg) {
          arg.el.style.borderTopColor = arg.event.extendedProps.operators[0].color
          if (!arg.isFuture) {
            arg.el.classList.add('fc-event-past')
          } else {
            const now = moment()
            if (now.unix() > moment(arg.event.start).unix()) {
              if (
                now.unix() >= moment(arg.event.start).unix() &&
                now.unix() <= moment(arg.event.end).unix()
              ) {
                arg.el.classList.add('fc-event-active')
              } else {
                arg.el.classList.add('fc-event-past')
              }
            }
          }
        },
        eventResize: this.handleEventResize,
        eventContent: function(arg) {
          let arrayOfDomNodes = []
          arg.event.extendedProps.operators.map(operator => {
            let italicIcon = document.createElement('i')
            italicIcon.className = 'fa fa-user user-icon'
            italicIcon.style.color = `${operator.color}`
            let time = document.createElement('p')
            time.className = 'event__range-time'
            time.innerHTML = `${arg.timeText}`
            let italicEl = document.createElement('i')
            italicEl.className = 'italicEl'
            italicEl.innerHTML = `${operator.first_name} ${operator.last_name}`
            return arrayOfDomNodes.push(italicIcon, italicEl, time)
          })
          return { domNodes: arrayOfDomNodes }
        },
        eventClassNames: 'eventHeight',
        // create: false,
        plugins: [timeGridPlugin, interactionPlugin, momentTimezonePlugin, dayGridPlugin],
        height: 'auto',
        aspectRatio: 4,
        eventMinHeight: 'auto',
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'timeGridDay,timeGridWeek,monthGreedButton'
        },
        buttonText: {
          today: 'Сегодня',
          month: 'Месяц',
          week: 'Неделя',
          day: 'День',
          list: 'Список'
        },
        customButtons: {
          monthGreedButton: {
            text: 'Месяц',
            click: this.activateMonthCalendar,
            views: {
              timeGridDay: {
                dayHeaders: true,
                dayHeaderFormat: {
                  month: 'short',
                  weekday: 'short',
                  day: 'numeric'
                },
                // slotLabelFormat: this.slotLabelFormatHandler,
                titleFormat: {
                  month: 'long',
                  year: 'numeric',
                  day: 'numeric',
                  omitCommas: false
                }
              }
            }
          }
        },
        locale: 'ru',
        firstDay: 1,
        initialView: 'timeGridWeek',
        fixedWeekCount: false,
        visibleRange: {
          start: '',
          end: ''
        },
        views: {
          dayGridMonth: {
            dayHeaders: true,
            dayHeaderFormat: {
              month: 'short',
              weekday: 'short',
              day: 'numeric'
            },
            // slotLabelFormat: this.slotLabelFormatHandler,
            titleFormat: {
              month: 'long',
              year: 'numeric',
              day: 'numeric',
              omitCommas: false
            }
          },
          timeGridDay: {
            dayHeaders: true,
            dayHeaderFormat: {
              month: 'short',
              weekday: 'short',
              day: 'numeric'
            },
            slotLabelFormat: this.slotLabelFormatHandler,
            titleFormat: {
              month: 'long',
              year: 'numeric',
              day: 'numeric',
              omitCommas: false
            }
          },
          timeGridWeek: {
            dayHeaders: true,
            dayHeaderFormat: {
              month: 'short',
              weekday: 'short',
              day: 'numeric'
            },
            slotLabelFormat: this.slotLabelFormatHandler,
            titleFormat: {
              month: 'long',
              year: 'numeric',
              day: 'numeric',
              omitCommas: false
            }
          }
        },
        slotLabelInterval: { hours: 1 },
        defaultTimedEventDuration: { hours: 1 },
        slotDuration: { hours: 1 },
        snapDuration: { hours: 1 },
        rerenderDelay: 10,
        eventDurationEditable: true,
        eventStartEditable: true,
        forceEventDuration: true,
        allDaySlot: false,
        nowIndicator: false,
        displayEventEnd: true,
        displayEventTime: true,
        selectable: true,
        editable: true,
        droppable: true,
        eventDragMinDistance: 5,
        eventDrop: this.handledrop,
        eventOverlap: this.handleeventOverlap,
        eventDragStart: this.eventDragStartHandle
      },
      monthGreed: {
        visible: false,
        weeks: []
      },
      errorMessage: false
    }
  },
  computed: {
    ...mapGetters({
      allEvents: GET_CALENDAR_EVENTS,
      permissions: GET_PERMISSIONS_USER
    }),
    newOptionCalendar: {
      get() {
        return this.newOption
      },
      set(option) {
        this.$refs.newoptioncalendar.value = option
        this.newOption = option
      }
    },
    monthCalendarTitle() {
      let title = this.month_calendar
        ? moment(this.month_calendar)
            .locale('ru')
            .format('MMMM YYYY', 'ru')
        : ''

      if (title) {
        title = title[0].toUpperCase() + title.slice(1)
      }

      return title
    }
  },

  beforeRouteEnter() {
    this.getNewOptions()
  },

  mounted() {
    setTimeout(this.acivateCalendar, 300)
    this.newOption = this.optionsCalendar.shifts_in_day
  },

  beforeUpdate() {
    this.getNewOptions()
  },

  methods: {
    ...mapActions({
      getAllEvents: REQUEST_CALENDAR_EVENTS,
      addEventCalendar: REQUEST_ADD_CALENDAR_EVENT,
      addModerator: REQUEST_ADD_MODERATOR_EVENT,
      getOptions: REQUEST_SHIFT_OPTIONS,
      changeOptions: REQUEST_NEW_SHIFT_OPTIONS,
      delEventCalendar: REQUEST_DELL_MODERATOR_EVENT,
      changeOperator: REQUEST_CHANGE_OPERATOR_EVENT,
      modOperatorEvent: REQUEST_MOD_OPERATOR_EVENTS
    }),

    getNewOptions() {
      this.calendarOptions.slotLabelInterval.hours = this.optionsCalendar.shift_time_period
      this.calendarOptions.defaultTimedEventDuration.hours = this.optionsCalendar.shift_time_period
      this.calendarOptions.snapDuration.hours = this.optionsCalendar.shift_time_period
      this.calendarOptions.slotDuration.hours = this.optionsCalendar.shift_time_period
    },

    setNewOptions() {
      let remainder = 24 % this.newOption
      if (!remainder) {
        const formData = {
          shifts: this.newOption
        }
        this.changeOptions(formData).then(async response => {
          if (response.status) {
            this.visible = false
            this.getNewOptions()
            this.visible = true
            setTimeout(this.acivateCalendar, 100)
          }
        })
        // this.newOption = null;
      } else {
        let causeError = 'remainderError'
        let info = new Date()
        this.$root.$emit('CALENDAR_ERROR_POPUP', { info, causeError })
      }
    },
    // ДЛЯ ОТРИСОВКИ ПРОШЕДШИХ ДНЕЙ КАЛЕНДАРЯ
    dayRenderHandler(arg) {
      if (!arg.isFuture) {
        return 'fc-day-disabled'
      }
      return ''
    },
    // ОТОБРАЗИТЬ КАЛЕНДАРЬ НЕДЕЛЯ
    acivateCalendar() {
      if (!this.visible) {
        this.monthGreed.visible = false
        this.visible = true
        this.month_calendar = null
      }
      // создание перетаскиваемых элементов
      if (!this.draggableEventsAreExists) {
        new Draggable(document.querySelector('.moderators__list'), {
          itemSelector: '.drop-el',
          eventData: function(eventEl) {
            return {
              title: `Создать смену`,
              extendedProps: {
                operators: [
                  {
                    first_name: eventEl.children[1].innerText,
                    last_name: eventEl.children[2].innerText
                    // color: '#FF6633'
                  }
                ]
              },
              create: false
            }
          }
        })
        this.draggableEventsAreExists = true
      }
      if (this.optionsCalendar.default) {
        this.newOption = 'Введите количество'
      } else {
        this.newOption = this.optionsCalendar.shifts_in_day
      }
      // this.newOption = this.optionsCalendar.shifts_in_day;
    },
    // отобразить календарь Месяц
    async activateMonthCalendar() {
      this.visible = false
      this.month_calendar = null

      this.monthGreed.visible = true
      this.setMonthsGreed()
      this.getEvents()
    },
    // Создание календаря месяц, подсчет недель
    setMonthsGreed() {
      const start = moment(this.start_calendar).startOf('month')
      if (!this.month_calendar) {
        this.month_calendar = start.format('YYYY-MM')
      }
      const start_day = start.subtract(start.isoWeekday() - 1, 'days')
      const end = moment(this.start_calendar).endOf('month')
      const end_month_weekday = end.endOf('month').isoWeekday()
      const end_day = end_month_weekday > 0 ? end.add(7 - end_month_weekday, 'days') : end
      this.start_calendar = start_day.format('YYYY-MM-DD HH:mm:ss')
      this.end_calendar = end_day.format('YYYY-MM-DD HH:mm:ss')
      //получаем недели
      let diff = (end_day.diff(start_day, 'days') + 1) / 7
      this.monthGreed.weeks = []
      while (diff > 0) {
        this.monthGreed.weeks.push({
          start: start_day.format('YYYY-MM-DD HH:mm:ss'),
          end: start_day
            .add(6, 'days')
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
        })
        start_day.add(1, 'days').startOf('day')
        diff--
      }
    },
    // СЛЕДУЮЩИЙ МЕСЯЦ
    nextMonthGreed() {
      this.month_calendar = moment(this.month_calendar)
        .add(1, 'month')
        .format('YYYY-MM')
      this.start_calendar = moment(this.month_calendar)
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss')
      this.setMonthsGreed()
      this.getEvents()
    },
    // ПРЕДЫДУЩИЙ МЕСЯЦ
    prevMonthGreed() {
      this.month_calendar = moment(this.month_calendar)
        .subtract(1, 'month')
        .startOf('month')
        .format('YYYY-MM')
      this.start_calendar = moment(this.month_calendar)
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss')
      this.setMonthsGreed()
      this.getEvents()
    },

    // Подстановка нулей в левом столбце времени
    slotLabelFormatHandler(date) {
      const leadZero = '0'
      const midnightZero = '00'

      let hourSince = date.date.hour,
        hourTill = hourSince + this.optionsCalendar.shift_time_period

      if (hourSince < 10) hourSince = leadZero + hourSince
      if (hourTill < 10) hourTill = leadZero + hourTill

      if (midnightZero && hourTill > 23) hourTill = midnightZero

      return `${hourSince}⁰⁰ - ${hourTill}⁰⁰`
    },
    //Задает новый диапозон
    eventDatesRenderHandler(info) {
      const start = moment(info.view.currentStart)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      const end = moment(info.view.currentEnd)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      if (this.start_calendar == start && this.end_calendar == end) {
        return
      }
      this.start_calendar = start
      this.end_calendar = end
      this.getEvents()
    },
    // получение эвентов
    getEvents() {
      const params = {
        start: moment(this.start_calendar).format('YYYY-MM-DDTHH:MM:SS'),
        end: moment(this.end_calendar).format('YYYY-MM-DDTHH:MM:SS')
      }
      this.getAllEvents(params).then(response => {
        if (response.status) {
          this.calendarOptions.events = this.allEvents.map(event => {
            // ДАННЫЕ ФОРМАТА КАЛЕНДАРЯ
            return {
              id: event.id,
              title: 'Смена №' + event.title,
              start: this.getDateformat(event.start),
              end: this.getDateformat(event.end),
              extendedProps: {
                operators: this.getColorEvent(event.operators)
              }
            }
          })
        }
      })
    },
    toTimeZone(time) {
      let tz = moment.tz.guess()
      let format = 'YYYY/MM/DD HH:mm:ss ZZ'
      return moment(time, format)
        .tz(tz)
        .format(format)
    },
    getDateformat(date) {
      return date.slice(0, -7).replace(',', '')
    },
    // присвоение цвета внутри событий
    getColorEvent(operators) {
      let colorEmp = []
      operators.map(m => {
        let moderatorColor = this.moderators.find(f => f.id === m.id)
        if (moderatorColor) {
          m.color = moderatorColor.color
        } else {
          m.color = '#000000'
        }
        colorEmp.push(m)
      })
      return colorEmp
    },

    // нажатие на пустую дату
    handleDateSelect(selectInfo) {
      let today = moment()
      if (selectInfo.start > today) {
        const moderatorList = this.moderators
        const params = {
          start: moment(this.start_calendar).format('YYYY-MM-DDTHH:MM:SS'),
          end: moment(this.end_calendar).format('YYYY-MM-DDTHH:MM:SS')
        }
        this.$root.$emit('CALENDAR_ADD_POPUP', {
          selectInfo,
          moderatorList,
          params
        })
      } else {
        let causeError = 'periodNotAvailable'
        this.$root.$emit('CALENDAR_ERROR_POPUP', { selectInfo, causeError })
      }
      // }
    },
    // нажатие на событие
    handleEventClick(info) {
      let today = moment()
      if (info.event.start > today) {
        const currentEvent = info.event
        const moderatorList = this.moderators
        this.$root.$emit('CALENDAR_EDIT_POPUP', {
          currentEvent,
          moderatorList
        })
      } else {
        let causeError = 'cantChangeCurrentEvent'
        this.$root.$emit('CALENDAR_ERROR_POPUP', { info, causeError })
      }
    },
    eventDragStartHandle(e) {
      if (e.event.extendedProps.operators.length > 1) {
        let causeError = 'isNotCorrectShift'
        this.$root.$emit('CALENDAR_ERROR_POPUP', { e, causeError })
        this.errorDrag = true
      } else {
        this.eventDrag = e
      }
    },
    handleEventResize(e) {
      setTimeout(() => {
        var da = e.el.fcSeg
        let start = da.start.toISOString()
        let end = da.end.toISOString()
        if (start && end) {
          const event = {
            schedule_id: e.event._def.publicId,
            start: start.slice(0, -5),
            end: end.slice(0, -5)
          }
          this.modOperatorEvent(event).then(res => {
            if (res.status) {
              this.getEvents()
            } else {
              e.revert()
            }
          })
        }
      }, 100)
    },
    // перетаскивание смен друг на друга меняет их местами(только для смен с 1 оператором)
    handledrop(e) {
      if (!this.errorDrag) {
        if (e.event.start < new Date()) {
          let causeError = 'isNotCorrectDate'
          this.$root.$emit('CALENDAR_ERROR_POPUP', { e, causeError })
          this.getEvents()
        } else if (this.eventDrag && this.eventDropElement) {
          const start1 = moment(this.eventDrag.event.start).format('YYYY-MM-DD HH:mm:ss')
          const start2 = moment(this.eventDropElement.start).format('YYYY-MM-DD HH:mm:ss')
          if (start2 === moment(e.event.start).format('YYYY-MM-DD HH:mm:ss')) {
            if (this.eventDropElement.extendedProps.operators.length === 1) {
              const changeSchedule = {
                first_schedule_id: this.eventDrag.event.id,
                second_schedule_id: this.eventDropElement.id
              }
              this.changeOperator(changeSchedule).then(r => {
                if (r.status) this.getEvents()
              })
            } else {
              let causeError = 'isNotCorrectShift'
              this.$root.$emit('CALENDAR_ERROR_POPUP', { e, causeError })
              this.getEvents()
            }
          } else {
            //перенос в пустую клетку после пересечения
            const formDel = {
              userId: {
                operator_id: e.oldEvent.extendedProps.operators[0].id
              },
              scheduleId: e.oldEvent.id
            }
            const formAdd = {
              operator_id: e.oldEvent.extendedProps.operators[0].id,
              date: moment(e.event.start).format('YYYY-MM-DD HH:mm:ss')
            }
            this.delEventCalendar(formDel).then(response => {
              this.addEventCalendar(formAdd).then(response => {
                this.getEvents()
              })
            })
          }
        } else if (!this.eventDropElement) {
          //перенос в пустую без пересечения
          const formDel = {
            userId: {
              operator_id: e.oldEvent.extendedProps.operators[0].id
            },
            scheduleId: e.oldEvent.id
          }
          const formAdd = {
            operator_id: e.oldEvent.extendedProps.operators[0].id,
            date: moment(e.event.start).format('YYYY-MM-DD HH:mm:ss')
          }
          this.delEventCalendar(formDel).then(response => {
            this.addEventCalendar(formAdd).then(response => {
              if (response.status) this.getEvents()
            })
          })
        }
      } else {
        this.getEvents()
        this.errorDrag = false
      }
      this.errorDrag = false
      this.eventDropElement = null
      this.eventDrag = null
    },
    handleeventOverlap(stillEvent, movingEvent) {
      this.eventDropElement = stillEvent
      return true
    },
    // перетаскивание операторов в календарь, создает смену
    dropHandler(selectInfo) {
      let today = moment()
      if (selectInfo.date > today) {
        const start_date = moment(selectInfo.dateStr).format('YYYY-MM-DD HH:mm:ss')

        // let newEvents = Boolean();
        let repeatEvent = {
          scheduleId: null,
          userId: {
            operator_id: null
          },
          moderatorList: []
        }
        this.calendarOptions.events.map(event => {
          if (start_date === event.start) {
            repeatEvent.scheduleId = event.id
            repeatEvent.moderatorList = event.extendedProps.operators
          }
        })
        if (repeatEvent.scheduleId) {
          if (repeatEvent.moderatorList.find(x => x.id === selectInfo.draggedEl.dataset.id)) {
            let causeError = 'repeatModerator'
            this.$root.$emit('CALENDAR_ERROR_POPUP', {
              selectInfo,
              causeError
            })
          } else {
            repeatEvent.userId.operator_id = selectInfo.draggedEl.dataset.id
            this.addModerator(repeatEvent).then(response => {
              if (response.status) this.getEvents()
            })
          }
        } else {
          const formData = {
            operator_id: selectInfo.draggedEl.parentNode.dataset.id,
            date: start_date
          }
          this.addEventCalendar(formData).then(response => {
            if (response.status) this.getEvents()
          })
        }
      } else {
        let causeError = 'isNotCorrectDate'
        this.$root.$emit('CALENDAR_ERROR_POPUP', { selectInfo, causeError })
      }
    }
  }
}
</script>

<style lang="scss">
@import './Calendar.scss';
</style>
