import Request from '../helpers/Request'
import BaseService from './BaseService'

class UserClaimsService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getAllUsersClaims(lang) {
    const AllUsersClaimsUrl = `/api/v1/localization/user-claim-notification/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.get(AllUsersClaimsUrl, {
      headers,
      params: lang
    })
  }

  async addUserClaim(data) {
    const AllUsersClaimsUrl = `/api/v1/localization/user-claim-notification/add/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(AllUsersClaimsUrl, data, {
      headers
    })
  }

  async delUserClaim(id) {
    const AllUsersClaimsUrl = `/api/v1/localization/user-claim-notification/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.delete(AllUsersClaimsUrl, {
      headers
    })
  }

  async editUserClaim(data) {
    const AllUsersClaimsUrl = `/api/v1/localization/user-claim-notification/${data.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.put(AllUsersClaimsUrl, data.params, {
      headers
    })
  }
}

export default UserClaimsService
