const REQUEST_ALL_OPERATORS = 'REQUEST_ALL_OPERATORS'
const REQUEST_DELETE_OPERATORS = 'REQUEST_DELETE_OPERATORS'
const REQUEST_DELETE_OPERATOR_USER = 'REQUEST_DELETE_OPERATOR_USER'
const REQUEST_EDIT_OPERATORS = 'REQUEST_EDIT_OPERATORS'
const REQUEST_CREATE_OPERATORS = 'REQUEST_CREATE_OPERATORS'
const REQUEST_FIND_USERS = 'REQUEST_FIND_USERS'
const REQUEST_OPERATOR_STATISTIC = 'REQUEST_OPERATOR_STATISTIC'
const REQUEST_OPERATOR_PAYOUT = 'REQUEST_OPERATOR_PAYOUT'
const REQUEST_BILLING_OPERATORS = 'REQUEST_BILLING_OPERATORS'
const REQUEST_PRIZE_OPERATORS = 'REQUEST_PRIZE_OPERATORS'
const REQUEST_EXECUTE_OPERATORS = 'REQUEST_EXECUTE_OPERATORS'
const REQUEST_EDIT_OPERATORS_SEARCH_OPTIONS = 'REQUEST_EDIT_OPERATORS_SEARCH_OPTIONS'
const REQUEST_EDIT_OPERATOR_AGE = 'REQUEST_EDIT_OPERATOR_AGE'
const REQUEST_EDIT_OPERATOR_NAME = 'REQUEST_EDIT_OPERATOR_NAME'
const REQUEST_EDIT_OPERATOR_SEARCH = 'REQUEST_EDIT_OPERATOR_SEARCH'
const REQUEST_EDIT_OPERATORS_AUTO_MESSAGE = 'REQUEST_EDIT_OPERATORS_AUTO_MESSAGE'
const REQUEST_OPERATORS_BANNER = 'REQUEST_OPERATORS_BANNER'
const REQUEST_EDIT_OPERATORS_BANNER = 'REQUEST_EDIT_OPERATORS_BANNER'
const REQUEST_DELETE_OPERATORS_BANNER = 'REQUEST_DELETE_OPERATORS_BANNER'
const REQUEST_CHANGE_OPERATORS_BANNER = 'REQUEST_CHANGE_OPERATORS_BANNER'
const REQUEST_OPERATORS_AUTO_MESSAGE = 'REQUEST_OPERATORS_AUTO_MESSAGE'
const REQUEST_EDIT_AUTO_MESSAGE_DELAY = 'REQUEST_EDIT_AUTO_MESSAGE_DELAY'
const REQUEST_CHANGE_OPERATORS_AUTO_MESSAGE = 'REQUEST_CHANGE_OPERATORS_AUTO_MESSAGE'
const REQUEST_DELETE_OPERATORS_AUTO_MESSAGE = 'REQUEST_DELETE_OPERATORS_AUTO_MESSAGE'
const REQUEST_EDIT_LOCATION_SETTING = 'REQUEST_EDIT_LOCATION_SETTING'
const REQUEST_TRANSFER_OPERATOR = 'REQUEST_TRANSFER_OPERATOR'
const REQUEST_CHANGE_COUNTRY_OPERATOR = 'REQUEST_CHANGE_COUNTRY_OPERATOR'
const REQUEST_CHANGE_WEIGHT_SENDING = 'REQUEST_CHANGE_WEIGHT_SENDING'
const REQUEST_CHANGE_TYPE_MESSAGE = 'REQUEST_CHANGE_TYPE_MESSAGE'
const REQUEST_GET_OPERATOR_INFO_ON_SHIFT = 'REQUEST_GET_OPERATOR_INFO_ON_SHIFT'
const REQUEST_FILTERS_OPERATORS = 'REQUEST_FILTERS_OPERATORS'

export {
  REQUEST_ALL_OPERATORS,
  REQUEST_DELETE_OPERATORS,
  REQUEST_DELETE_OPERATOR_USER,
  REQUEST_EDIT_OPERATORS,
  REQUEST_CREATE_OPERATORS,
  REQUEST_FIND_USERS,
  REQUEST_OPERATOR_STATISTIC,
  REQUEST_CHANGE_TYPE_MESSAGE,
  REQUEST_OPERATOR_PAYOUT,
  REQUEST_BILLING_OPERATORS,
  REQUEST_PRIZE_OPERATORS,
  REQUEST_EXECUTE_OPERATORS,
  REQUEST_EDIT_OPERATORS_SEARCH_OPTIONS,
  REQUEST_EDIT_OPERATOR_AGE,
  REQUEST_EDIT_OPERATOR_NAME,
  REQUEST_EDIT_OPERATOR_SEARCH,
  REQUEST_EDIT_OPERATORS_AUTO_MESSAGE,
  REQUEST_OPERATORS_BANNER,
  REQUEST_EDIT_OPERATORS_BANNER,
  REQUEST_DELETE_OPERATORS_BANNER,
  REQUEST_CHANGE_OPERATORS_BANNER,
  REQUEST_OPERATORS_AUTO_MESSAGE,
  REQUEST_EDIT_AUTO_MESSAGE_DELAY,
  REQUEST_CHANGE_COUNTRY_OPERATOR,
  REQUEST_CHANGE_OPERATORS_AUTO_MESSAGE,
  REQUEST_DELETE_OPERATORS_AUTO_MESSAGE,
  REQUEST_EDIT_LOCATION_SETTING,
  REQUEST_TRANSFER_OPERATOR,
  REQUEST_CHANGE_WEIGHT_SENDING,
  REQUEST_GET_OPERATOR_INFO_ON_SHIFT,
  REQUEST_FILTERS_OPERATORS
}
