const REQUEST_CONTROL_POSITIONS = 'REQUEST_CONTROL_POSITIONS'
const REQUEST_CREATE_POSITION = 'REQUEST_CREATE_POSITION'
const REQUEST_DELETE_POSITION = 'REQUEST_DELETE_POSITION'
const REQUEST_UPDATE_POSITION = 'REQUEST_UPDATE_POSITION'
export {
  REQUEST_CONTROL_POSITIONS,
  REQUEST_CREATE_POSITION,
  REQUEST_DELETE_POSITION,
  REQUEST_UPDATE_POSITION
}
