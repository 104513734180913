import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import state from './state'

import users from './users/index'
import view from './view/index'
import events from './events/index'

import achievements from './achievements/index'
import faq from './faq/index'
import languages from './languages/index'
import causePoints from './causePoints/index'
import autoMessages from './autoMessages/index'
import autoMessagesList from './autoMessagesList/index'
import employee from './employee/index'
import departments from './departmens/index'
import permissions from './permissions/index'
import positions from './positions/index'
import purchases from './purchases/index'

import gifts from './gifts'
import giftEvent from './giftEvent/index'

import reasons from './reasons/index'

import supportContacts from './supportContacts/index'

import market from './market/index'

import operators from './operators/index'
import calendar from './calendar/index'
import operatorPayments from './operatorPayments/index'
import moderatorPayments from './moderatorPayments/index'
import moderatorRating from './moderatorRating/index'
import userclaims from './userclaims/index'
import statistics from './statistics/index'
import userStatistic from './userStatistic/index'

import push from './push/index'
Vue.use(Vuex)

export default new Vuex.Store({
  mutations,
  actions,
  getters,
  state,

  modules: {
    users,
    view,
    events,
    achievements,
    faq,
    causePoints,
    autoMessages,
    autoMessagesList,
    languages,
    employee,
    departments,
    supportContacts,
    userStatistic,
    purchases,
    positions,
    gifts,
    permissions,
    giftEvent,
    reasons,
    market,
    push,
    operators,
    calendar,
    operatorPayments,
    moderatorPayments,
    statistics,
    userclaims,
    moderatorRating
  }
})
