import Request from '../helpers/Request'
import BaseService from './BaseService'

class UserService extends BaseService {
  constructor(token) {
    super(token)
  }
  // USERS

  async getFilterUsers(formData) {
    const getEventsUrl = `/api/v1/users/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    Object.keys(formData).forEach(
      key =>
        (formData[key] === '' || formData[key] === undefined || formData[key] === 'Неважно') &&
        delete formData[key]
    )

    return await Request.get(getEventsUrl, {
      headers,
      params: formData
    })
  }

  async userCallOnliVipUpdate(data) {
    const userCallOnliVipUpdateUrl = `/api/v1/user/change-incoming-call-vip/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(userCallOnliVipUpdateUrl, data, {
      headers
    })
  }

  // USER
  async getUser(id) {
    const getUserUrl = `/api/v1/users/${id}/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getUserUrl, {
      headers
    })
  }
  async userVerification(payload) {
    const userVerificationUrl = '/api/v1/user/verification/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(userVerificationUrl, payload, { headers })
  }
  async getAgeVerification(payload) {
    const userVerificationUrl = '/api/v1/user/get-verification/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(userVerificationUrl, payload, { headers })
  }
  async blockMainPhotoUser(payload) {
    const blockMainPhotoUserUrl = '/api/v1/user/block-main-photo/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(blockMainPhotoUserUrl, payload, { headers })
  }
  async updateVipStatus(payload) {
    const updateVipStatusUrl = '/api/v1/user/vip/update/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(updateVipStatusUrl, payload, { headers })
  }
  async delVipStatus(data) {
    const delVipStatusUrl = '/api/v1/user/vip/update/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(delVipStatusUrl, { data, headers })
  }

  async banUser(payload) {
    const banUserUrl = '/api/v1/user/ban/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(banUserUrl, payload, { headers })
  }
  async deleteUser(payload) {
    const deleteUserUrl = '/api/v1/user/delete/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(deleteUserUrl, payload, { headers })
  }
  async updateUserPoints(payload) {
    const updateUserPointsUrl = '/api/v1/user/points/update/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(updateUserPointsUrl, payload, { headers })
  }
  async hideUserApp(payload) {
    const hideUserAppUrl = '/api/v1/user/incognito/app/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(hideUserAppUrl, payload, { headers })
  }
  async hideUserAdmin(payload) {
    const hideUserAdminUrl = '/api/v1/user/incognito/admin/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(hideUserAdminUrl, payload, { headers })
  }
  async updateUserRating(payload) {
    const updateUserRatingUrl = '/api/v1/user/rating/update/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(updateUserRatingUrl, payload, { headers })
  }
  async updateUserGender(payload) {
    const updateUserGenderUrl = '/api/v1/user/gender/update/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(updateUserGenderUrl, payload, { headers })
  }
  async getUserDeposits(id) {
    const getUserDepositsUrl = `/api/v1/users/${id}/deposits/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getUserDepositsUrl, { headers })
  }
  async getUserPayouts(id) {
    const getUserPayoutsUrl = `/api/v1/users/${id}/payouts/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getUserPayoutsUrl, { headers })
  }

  async getUserTransactionKind(id) {
    const getUserTransactionKindUrl = `/api/v1/users/${id}/balance-history/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getUserTransactionKindUrl, { headers })
  }

  async getUserPhotos(id) {
    const getUserPhotosUrl = `/api/v1/users/${id}/photos/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getUserPhotosUrl, { headers })
  }

  async getUserChats(id) {
    const getUserChatsUrl = `/api/v1/users/${id.id}/chats/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(getUserChatsUrl, id.page ? { page: id.page } : { page: 1 }, { headers })
  }

  async getUserChatMessages(chat) {
    const getUserChatMessagesUrl = `/api/v1/users/${chat.user}/chats/${chat.id}/?limit=${chat.limit}&message_date=${chat.start}`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getUserChatMessagesUrl, { headers })
  }

  async delUserPhoto(data) {
    const delVipStatusUrl = '/api/v1/user/delete-photo/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(delVipStatusUrl, data, { headers })
  }

  async getFilterUtm() {
    const getMarketUtmUrl = `/api/v1/marketing/utm/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    // let date = new Date();
    // return await Request.get(getAutoMessageUrl, {
    //     headers, params: { lang, date },
    // });

    return await Request.get(getMarketUtmUrl, {
      headers
    })
  }

  async changeVisibleMap(data) {
    const visibleMapUrl = '/api/v1/user/change-visible-map/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(visibleMapUrl, data, { headers })
  }

  async getUserPurchases(id) {
    const getUserPurchasesUrl = `/api/v1/users/${id}/purchases/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getUserPurchasesUrl, { headers })
  }

  async updatePhotoStatus(data) {
    const updatePhotoStatusUrl = `/api/v1/user/set-photo-status/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(updatePhotoStatusUrl, data, { headers })
  }

  async sendNotificationtUser(data) {
    const sendNotificationtUserUrl = `/api/v1/users/send-notification/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(sendNotificationtUserUrl, data, { headers })
  }
}

export default UserService
