<template>
  <div>
    <div>
      <CalendarAll
        v-if="!errorMessage && isLoaded"
        :moderators="getEmployeeColor"
        :optionsCalendar="optionsCalendar ? optionsCalendar : defaultOptions"
      />
    </div>
    <div v-if="errorMessage" class="app-no-data">
      <span class="app-no-data__text">Ошибка при загрузке календаря</span>
    </div>
  </div>
</template>

<script>
import CalendarAll from '@/components/Operators/Calendar/CalendarAll'
import { mapGetters } from 'vuex'
import { GET_OPERATORS, GET_SHIFT_OPTIONS } from '@/store/getter-types'
import store from '@/store'
import { REQUEST_ALL_OPERATORS } from '@/store/action-types'

export default {
  name: 'operatorsCalendar',
  components: { CalendarAll },
  data() {
    return {
      isLoaded: false,
      defaultOptions: {},
      errorMessage: false,
      moderatorsColors: [
        '#FF6633',
        '#FFB399',
        '#E6B333',
        '#B34D4D',
        '#80B300',
        '#E6B3B3',
        '#FF1A66',
        '#991AFF',
        '#1AB399',
        '#E666B3',
        '#33991A',
        '#CC9999',
        '#00E680',
        '#4D8066',
        '#809980',
        '#CCCC00',
        '#4D80CC',
        '#99E6E6',
        '#24E6E5',
        '#49E4E6',
        '#71E7E7',
        '#39E676',
        '#98E8E8'
      ]
    }
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
    // правка стилей календаря при открытии и закрытии меню
    this.$root.$on('openaside', () => {
      let calendar = document.querySelectorAll('.fc-timegrid-body')
      let calendar2 = document.querySelectorAll('.fc-timegrid-cols table')
      let calendar3 = document.querySelectorAll('.fc-col-header')
      let calendar4 = document.querySelectorAll('.fc-timegrid-slots')
      let calendar5 = document.querySelectorAll('.fc-timegrid-slots table')
      for (let i = 0; i < calendar.length; i++) {
        calendar[i].style.width = '100%'
        calendar2[i].style.width = '100%'
        calendar3[i].style.width = '100%'
        calendar4[i].style.width = '100%'
        calendar5[i].style.width = '100%'
      }
    })
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_ALL_OPERATORS).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        }
        vm.defaultOptions = {
          shift_time_period: 1,
          shifts_in_day: 24,
          default: true
        }
        // store.dispatch(REQUEST_SHIFT_OPTIONS).then(response => {
        //   if (!response.status) {
        //     vm.defaultOptions = {
        //       shift_time_period: 1,
        //       shifts_in_day: 24,
        //       default: true
        //     }
        //   }
        vm.isLoaded = true
        // })
      })
    })
  },
  computed: {
    ...mapGetters({
      employees: GET_OPERATORS,
      optionsCalendar: GET_SHIFT_OPTIONS
    }),
    getEmployeeColor() {
      let colors = []
      let colorEmployees = []
      return (colorEmployees = this.employees.map(el => {
        if (this.moderatorsColors.length <= colors.length) {
          return el
        }

        let isColorChoosen = false

        while (!isColorChoosen) {
          const randInt = Math.floor(Math.random() * this.moderatorsColors.length)
          if (colors.indexOf(this.moderatorsColors[randInt]) === -1) {
            colors.push(this.moderatorsColors[randInt])
            el.color = this.moderatorsColors[randInt]
            isColorChoosen = true
          }
        }
        return el
      }))
    }
  }
}
</script>

<style lang="scss">
.app-no-data {
  margin: 10px 10px;
  min-height: 64px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  &__text {
    margin: 0 auto;
  }
}
</style>
