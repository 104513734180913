<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Добавить язык"
    modalClass="popup--add-category popup--medium"
  >
    <form @submit.prevent="addLang">
      <div class="popup__item">
        <span class="popup__item-title">Язык</span>
        <input type="text" class="popup__input default-input" v-model="lang_text" required />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Код языка</span>
        <input type="text" class="popup__input default-input" maxlength="2" v-model="lang_code" required />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Добавить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { LANG_ADD_POPUP } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_CREATE_NEW_LANGUAGES } from '@/store/action-types'

export default {
  name: 'LanguagesAddPopup',
  data() {
    return {
      lang_text: '',
      lang_code: ''
    }
  },
  components: {
    Popup
  },
  mixins: [ModalMixin],

  computed: {},
  mounted() {
    this.$root.$on(LANG_ADD_POPUP, eventcat => {
      this.open()
    })
  },
  methods: {
    ...mapActions({
      addLangRequest: REQUEST_CREATE_NEW_LANGUAGES
    }),
    closePopup() {
      this.lang_text = ''
      this.lang_code = ''
      this.close()
    },
    addLang() {
      const payload = {
        name: this.lang_text,
        value: this.lang_code
      }
      this.addLangRequest(payload)
      this.closePopup()
    }
  }
}
</script>

<style lang="scss" scoped>
.popup__item:first-child {
  margin-top: 10px;
}
</style>
