<template>
  <Popup :visible="visible" @close="close" :description="title" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="verifyHandler">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_APP_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_HIDE_USER_APP } from '@/store/action-types'
export default {
  name: 'UserAppHidePopup',
  components: {
    Popup
  },
  data() {
    return {
      id: '',
      incognito: false
    }
  },
  mixins: [ModalMixin],
  computed: {
    title() {
      if (!this.incognito) {
        return 'Скрыть в приложении?'
      } else {
        return 'Показывать в приложении?'
      }
    }
  },

  methods: {
    ...mapActions({
      hideUser: REQUEST_HIDE_USER_APP
    }),
    closePopup() {
      this.id = ''
      this.incognito = ''
      this.close()
    },
    verifyHandler() {
      const payload = {
        user_id: this.id,
        enable: !this.incognito
      }
      this.hideUser(payload)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(USER_APP_EVENT, user => {
      this.incognito = user.incognito_app
      this.id = user.id
      this.open()
    })
  }
}
</script>
