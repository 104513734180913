<template>
  <Popup :visible="visible" @close="closePopup" description="" modalClass="popup--medium">
    <form @submit.prevent="createHandler">
      <div class="popup__add-header">
        <span class="popup__header-title">Список стран:</span>
      </div>
      <ul v-if="countries.length">
        <li v-for="country in countries" :key="country.id">
          <span>{{ country }}</span>
          <button class="popup__delete-btn" @click.prevent="delCountry(country)">
            <i class="fa fa-times"></i>
          </button>
        </li>
      </ul>
      <div class="popup__item">
        <span>Добавить страну:</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="countryValue"
          ref="countryvalue"
          maxlength="2"
          required
        />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Добавить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>

<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { ADD_CODE_OPTION_POPUP_EVENT } from '@/constants/event'

export default {
  name: 'AddPaymentSettingPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data: () => ({
    countries: [],
    country_code: ''
  }),
  computed: {
    countryValue: {
      get() {
        return ''
      },
      set(value) {
        value = value.replace(/[^A-Z]/gi, '').toUpperCase()
        this.$refs.countryvalue.value = value
        this.country_code = value
      }
    }
  },

  methods: {
    closePopup() {
      this.close()
    },
    delCountry(value) {
      let index = this.countries.findIndex(t => t === value)
      this.countries.splice(index, 1)
    },
    createHandler() {
      this.countries.push(this.country_code)
    }
  },
  mounted() {
    this.$root.$on(ADD_CODE_OPTION_POPUP_EVENT, value => {
      this.countries = value
      this.open()
    })
  }
}
</script>

<style lang="scss">
.popup__add-header {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  padding: 5px;
  background: #00a8b3;
  color: white;
}
.popup__item {
  position: relative;
}
.popup__btn-edit {
  position: absolute;
  color: #689bcc;
  right: 0;
  top: 28px;
}
</style>
