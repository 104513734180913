<template>
  <div class="positions__wrap">
    <div>
      <ul class="coincidence__list">
        <li class="coincidence__item" v-for="(item, i) in listItem" :key="i">
          <span class="coincidence__name">{{ item.name }}</span>
          <!-- Пока только так смог избавиться от варна в консоле из-за дупликата -->
          <!-- :key="element + Math.random() * idx" -->
          <div class="coincidence__element" v-for="element in item.data" :key="random(element)">
            <i
              :class="{
                'fa fa-check user-icon--green': element,
                'fa fa-times user-icon--red': !element
              }"
            ></i>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PositionsByAdminList',
  props: ['adminList', 'positions'],
  watch: {
    adminList() {
      return this.listItem
    }
  },
  computed: {
    listItem() {
      let positionsByList = []
      //   Object.entries(this.adminList).forEach(item => {
      //     if (item[1].length !== 0) {
      //       item[1].forEach(f => {
      //         let itemList = {
      //           name: f.permission_name,
      //           data: []
      //         }
      //         this.positions.forEach(m => {
      //           if (m.permissions.find(item => item.permission_level === f.permission_level)) {
      //             itemList.data.push(1)
      //           } else {
      //             itemList.data.push(0)
      //           }
      //         })
      //         positionsByList.push(itemList)
      //       })
      //     }
      //   })
      //   for (let el in this.adminList) {
      //     for (let item of this.adminList[el]) {
      //       let data = {
      //         name: item.permission_name,
      //         data: item.data
      //       }
      //       positionsByList.push(data)
      //     }
      //   }

      this.adminList.forEach(f => {
        let itemList = {
          name: f.permission_name,
          data: []
        }
        this.positions.forEach(m => {
          if (m.permissions.find(item => item.permission_level === f.permission_level)) {
            itemList.data.push(1)
          } else {
            itemList.data.push(0)
          }
        })
        positionsByList.push(itemList)
      })
      return positionsByList
    }
  },
  methods: {
    random() {
      return Math.floor(Math.random() * 1000000)
    }
  }
}
</script>

<style lang="scss" scoped>
.positions__wrap {
  display: flex;
  flex-direction: column;
  background: white;
}
.positions__list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #ddd;
  width: 100%;
}
.permissions__list {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
}
.coincidence__list {
  display: flex;
  flex-direction: column;
}
.coincidence__name {
  display: flex;
  width: 200px;
  text-align: start;
}
.coincidence__item {
  justify-content: space-between;
  border: 1px solid #ddd;
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 2px 8px;
}

.coincidence__element {
  width: 200px;
}

.user-icon--green {
  color: #78cd51;
  font-size: 12px;
}

.user-icon--red {
  color: #ff1500;
  font-size: 12px;
}
</style>
