<template>
  <div class="payments-time">
    <div>
      <BarChart
        v-if="datacollection.datasets"
        :chartData="datacollection"
        :options="optionsPaying"
        class="regenterstats-chart"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import 'chartjs-plugin-datalabels'
import BarChart from '@/components/Statistic/BarChart'
const { reactiveData } = mixins
import { mixins } from 'vue-chartjs'
import { mapActions } from 'vuex'
import { REQUEST_USERS_PAYING } from '@/store/action-types'
import { Chart } from 'chart.js'
import pickerMixin from '@/components/Picker/pickerMixin'
export default {
  name: 'StatisticUsersPaying',
  mixins: [reactiveData, pickerMixin],
  components: {
    BarChart
  },
  props: {
    totalPurchase: {
      type: Object
    }
  },
  data: () => ({
    users: true,
    active: true,
    payment: true,
    legend: '',
    options: {
      tooltips: { enabled: false },
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        animateRotate: false
      }
    },

    payments_country_form: {
      start_date: '',
      end_date: ''
    },
    type: 2,
    button_period: [
      {
        id: 0,
        class: 'btn-default',
        title: 'Неделя',
        active: 0,
        date: moment()
          .subtract(1, 'weeks')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 1,
        class: 'btn-default',
        title: '1 мес.',
        active: 0,
        date: moment()
          .subtract(1, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 2,
        class: 'btn-default',
        title: '2 мес.',
        active: 0,
        date: moment()
          .subtract(2, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 3,
        class: 'btn-default',
        title: '3 мес.',
        active: 1,
        date: moment()
          .subtract(3, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 4,
        class: 'btn-default',
        title: '4 мес.',
        active: 0,
        date: moment()
          .subtract(4, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 5,
        class: 'btn-default',
        title: '5 мес.',
        active: 0,
        date: moment()
          .subtract(5, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 6,
        class: 'btn-default',
        title: '6 мес.',
        active: 0,
        date: moment()
          .subtract(6, 'months')
          .startOf('day')
          .format('YYYY-MM-DD')
      },
      {
        id: 7,
        class: 'btn-white',
        title: 'Все',
        active: 0,
        date: moment()
          .subtract(18, 'months')
          .startOf('day')
      }
    ],
    datepicker_dates: {
      date_from: moment()
        .subtract(3, 'months')
        .startOf('day')
        .toString(),
      date_to: moment()
        .endOf('day')
        .toString()
    },
    datacollection: {
      labels: [],
      datasets: [
        {
          label: '',
          backgroundColor: [
            '#d9d9d9',
            '#6666ff',
            '#8cd98c',
            '#b3b3b3',
            '#ffcc00',
            '#ff9980',
            '#ff99cc',
            '#ff6666',
            '#ff944d'
          ],
          datalabels: {
            display: true
          },
          data: []
        }
      ]
    },
    optionsPaying: {
      scales: {
        yAxes: [
          {
            stacked: false,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }
        ],
        xAxes: [
          {
            stacked: false,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }
        ]
      },
      offset: true,
      legend: {
        display: true,
        position: 'right',
        labels: {
          generateLabels: function(chart) {
            const dataset = chart.data.datasets[0]
            const arcOpts = chart.options.elements.arc

            //iterate the labels to return legend item interface
            return chart.data.labels.map(function(label, i) {
              return {
                text: label,
                //lines below to emulate the default legend behaviour
                fillStyle: Chart.helpers.getValueAtIndexOrDefault(
                  dataset.backgroundColor,
                  i,
                  arcOpts.backgroundColor
                ),
                strokeStyle: Chart.helpers.getValueAtIndexOrDefault(
                  dataset.borderColor,
                  i,
                  arcOpts.borderColor
                ),
                lineWidth: Chart.helpers.getValueAtIndexOrDefault(
                  dataset.borderWidth,
                  i,
                  arcOpts.borderWidth
                ),
                hidden: isNaN(dataset.data[i]) || chart.getDatasetMeta(0).data[i].hidden,
                index: i
              }
            })
          }
        }
      },
      tooltips: {
        enabled: true
      },
      plugins: {
        datalabels: {
          display: false,
          font: {
            size: 12
          },
          padding: {
            top: 0,
            bottom: -10
          },
          anchor: 'end',
          align: 'end',
          rotation: 0,
          clamp: true,
          formatter: (value, ctx) => {
            return `${value}`
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 25
        }
      }
    }
  }),
  beforeMount() {
    this.getTimeLabels()
  },

  computed: {
    datePickerControlTraffic: {
      get() {
        const date = {
          startDate: this.datepicker_dates.date_from,
          endDate: this.datepicker_dates.date_to
        }
        return date
      },
      set({ startDate, endDate }) {
        this.datepicker_dates.date_from = new Date(startDate)
        this.datepicker_dates.date_to = new Date(endDate)
        this.payments_country_form.start_date = this.datepicker_dates.date_from
        this.payments_country_form.end_date = this.datepicker_dates.date_to
      }
    },
    activeCircle: function() {
      let proc = ((this.usersPaying.active_period / this.usersPaying.total_period) * 100).toFixed(2)
      return `${proc}`
    },
    paymentCircle: function() {
      let proc = ((this.usersPaying.paying_period / this.usersPaying.total_period) * 100).toFixed(2)
      return `${proc}`
    }
  },
  methods: {
    ...mapActions({
      getPaymentByTime: REQUEST_USERS_PAYING
    }),
    setType(type = 1) {
      this.type = type
      this.getData()
    },
    getlabels() {
      let lab = []
      this.totalPurchase.labels.forEach(el => {
        lab.push(el)
      })
      return lab
    },
    getDatsest() {
      let data = []
      for (let el in this.totalPurchase.data) {
        data.push(this.totalPurchase.data[el])
      }
      return data
    },
    newDataset() {
      // let datas = []
      // let counter = 0
      // const colors = [
      //   '#d9d9d9',
      //   '#6666ff',
      //   '#8cd98c',
      //   '#b3b3b3',
      //   '#ffcc00',
      //   '#ff9980',
      //   '#ff99cc',
      //   '#ff6666',
      //   '#ff944d'
      // ]
      // for (let el in this.totalPurchase.data) {
      //   let data = {
      //     label: el,
      //     backgroundColor: colors[counter],
      //     barThickness: 50,
      //     borderWidth: 1,
      //     pointBorderColor: colors[counter],
      //     datalabels: {
      //       display: true
      //     },
      //     data: [this.totalPurchase.data[el]]
      //   }
      //   ++counter
      //   datas.push(data)
      // }
      // console.log(datas)
      // return datas
    },
    getTimeLabels() {
      this.datacollection.labels = this.totalPurchase.labels
      // this.datacollection.datasets = this.newDataset()
      this.datacollection.datasets[0].data = this.getDatsest()
    }
    // buttonPeriodClickHandler(event) {
    //   if (event.target.tagName.toLowerCase() !== 'button') {
    //     return false
    //   }
    //   this.button_period.map(el => {
    //     el.active = 0
    //     return el
    //   })
    //   if (event.target.dataset.type) {
    //     this.getData()
    //     return
    //   }
    //   if (!event.target.dataset.id) {
    //     return false
    //   }
    //   const button = this.button_period.find(el => el.id === +event.target.dataset.id)
    //   if (button.active) {
    //     return false
    //   }
    //   button.active = 1
    //   this.datepicker_dates.date_from = button.date.toLocaleString()
    //   this.datepicker_dates.date_to = button.date_to
    //     ? button.date_to.toLocaleString()
    //     : moment()
    //         .endOf('day')
    //         .toLocaleString()
    //   this.getData()
    // },
    // getData() {
    //   let dateThreeMounth = {
    //     date_from: moment(this.datepicker_dates.date_from).format('YYYY-MM-DD'),
    //     date_to: moment(this.datepicker_dates.date_to).format('YYYY-MM-DD')
    //   }
    //   this.getPaymentByTime(dateThreeMounth).then(response => {
    //     this.getTimeLabels()
    //   })
    // }
  }
}
</script>

<style lang="scss">
@import './Statistic.scss';
.payments-time {
  padding: 16px;
}
.payments-time__wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.payments-time__input {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  background-color: #41cac0;
  border-color: #41cac0;
  color: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  &--left {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &--right {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.btn-sum-active {
  background-color: #ff6c60;
  color: #fff;
}

.btn-default {
  background-color: #bec3c7;
  border-color: #bec3c7;
  color: #fff;
}

.btn-danger {
  background-color: #ff6c60;
  border-color: #ff6c60;
  color: #fff;
}

.btn-white {
  background-clip: padding-box;
  border-color: rgba(150, 160, 180, 0.3);
  background-color: #fff;
}

.btn-count-active {
  background-color: #57c8f2;
  color: #fff;
}

.btn-primary {
  border-radius: 4px;
  background-color: #41cac0;
  border-color: #41cac0;
  color: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn.btn-danger.btn-white {
  color: #2a3542;
}
.doughnut-chart {
  width: 120px;
  height: 120px;
}
.doughnut-chart__group {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.doughnut-chart__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.doughnut-chart__title {
  color: #41cac0;
  font-size: 13px;
  &--red {
    color: #ff6c60;
  }
  &--percent {
    top: 55px;
    position: absolute;
  }
}
.doughnut-chart__count {
  font-weight: 700;
}
.chart__edit-group {
  display: flex;
  padding: 5px;
}
.chart__btn-group {
  display: flex;
}
.chart__edit-text {
  margin: 0 5px 0 15px;
  font-weight: 700;
  font-size: 13px;
}
</style>
