<template>
  <div class="setting__table">
    <div class="setting__table-header">
      <span class="setting__header-item">Название группы</span>
      <span class="setting__header-item">Коды стран</span>
      <span class="setting__header-item">Оплата за сообщение</span>
      <div class="setting__header-item setting__header-item--gender">
        <span class="setting__header-text">Стоимость сообщения</span>
        <div class="setting__header-list">
          <span class="setting__list-item"><i class="fa fa-mars"></i></span>
          <span class="setting__list-item"><i class="fa fa-venus"></i></span>
        </div>
      </div>
      <span class="setting__header-item"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OperatorSettingsHeaderTablet'
}
</script>

<style lang="scss" scoped>
@import 'PaymentsSetting';
</style>
