<template>
  <div class="marketMedia-wrap">
    <span>Соц. медиа</span>
  </div>
</template>
<script>
export default {
  name: 'marketMedia'
}
</script>
