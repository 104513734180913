<template>
  <Popup
    :visible="visible"
    @close="close"
    description="Редактировать уведомление"
    modalClass="popup--add-category popup--medium"
  >
    <form @submit.prevent="sendData">
      <div class="popup__item">
        <span class="popup__item-title">Тип пуша</span>
        <input type="number" class="popup__input default-input" v-model.number="type_push" required />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Системный заголовок</span>
        <input type="text" class="popup__input default-input" v-model="title" />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Системное сообщение</span>
        <textarea class="popup__input default-input" v-model="body" />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Внутренний заголовок</span>
        <input type="text" class="popup__input default-input" v-model="data_title" />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Внутреннее сообщение</span>
        <input type="text" class="popup__input default-input" v-model="data_body" />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Тип сообщения</span>
        <input type="number" class="popup__input default-input" v-model.number="type_msg" />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Изменить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { PUSH_UPDATE_POPUP } from '@/constants/event'
import { mapActions, mapGetters } from 'vuex'
import { GET_CURRENT_LANGUAGES } from '@/store/getter-types'
import { REQUEST_UPDATE_PUSHES } from '@/store/action-types'

export default {
  name: 'PushAddPopup',
  data() {
    return {
      type_push: null,
      type_msg: null,
      title: null,
      body: null,
      data_title: null,
      data_body: null,
      id: null
    }
  },
  components: {
    Popup
  },
  mixins: [ModalMixin],

  computed: {
    ...mapGetters({
      lang: GET_CURRENT_LANGUAGES
    })
  },
  mounted() {
    this.$root.$on(PUSH_UPDATE_POPUP, push => {
      this.type_push = push.type_push
      this.type_msg = push.type_msg
      this.title = push.title
      this.body = push.body
      this.data_title = push.data_title
      this.data_body = push.data_body
      this.id = push.id
      this.open()
    })
  },
  methods: {
    ...mapActions({
      updatePushes: REQUEST_UPDATE_PUSHES
    }),
    sendData() {
      let payload = {
        lang: this.lang,
        type_push: this.type_push,
        title: this.title,
        body: this.body,
        data_title: this.data_title,
        data_body: this.data_body,
        id: this.id,
        type_msg: this.type_msg
      }
      this.updatePushes(payload)
      this.type_push = null
      this.type_msg = null
      this.title = null
      this.body = null
      this.data_title = null
      this.data_body = null
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.popup__item:first-child {
  margin-top: 10px;
}
</style>
