<template>
  <div>
    <OperatorSettingsHeader :defaultSetting="defaultSetting" />
    <!--    <OperatorSettingsHeaderTablet />-->
    <OperatorsSettingsList :settings="settings" />
    <AddPaymentSettingPopup />
    <DelPaymentSetting />
    <EditPaymentOtherSettings />
  </div>
</template>

<script>
import OperatorSettingsHeaderTablet from '@/components/Operators/Settings/OperatorSettingsHeaderTablet'
import OperatorsSettingsList from '@/components/Operators/Settings/OperatorsSettingsList'
import { mapGetters } from 'vuex'
import store from '@/store'
import { REQUEST_PAYMENTS_SETTING } from '@/store/action-types'
import { GET_PAYMENTS_SETTING, GET_DEFAULT_PAYMENT_SETTING } from '@/store/getter-types'
import OperatorSettingsHeader from '../../components/Operators/Settings/OperatorSettingsHeader'
import AddPaymentSettingPopup from '../../components/Modals/OperatorsPopup/Payements/AddPaymentSettingPopup'
import DelPaymentSetting from '../../components/Modals/OperatorsPopup/Payements/DelPaymentSetting'
import EditPaymentOtherSettings from '../../components/Modals/OperatorsPopup/Payements/EditPaymentOtherSettings'
export default {
  name: 'operatorsSettings',
  components: {
    EditPaymentOtherSettings,
    DelPaymentSetting,
    AddPaymentSettingPopup,
    OperatorSettingsHeader,
    OperatorSettingsHeaderTablet,
    OperatorsSettingsList
  },
  data: () => ({
    errorMessage: false
  }),
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_PAYMENTS_SETTING).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      settings: GET_PAYMENTS_SETTING,
      defaultSetting: GET_DEFAULT_PAYMENT_SETTING
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>
