<template>
  <div class="ev-cat__item-content">
    <div class="ev-cat__item-name">
      <span class="ev-cat__item-title"> {{ eventref.value }} </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EventRefBookItem',
  props: {
    eventref: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss"></style>
