<template>
  <div class="operator__statistic__controll-center">
    <div class="operator__statistic__controll-left">
      <button
        v-for="(button, index) in filterButtons"
        :key="index"
        :disabled="button.emitValue === status"
        @click="
          button.emitKey === 'onlineFilterHandler'
            ? $emit(button.emitKey)
            : $emit(button.emitKey, { key: button.emitValue })
        "
        class="user-active__btn user-active__btn--online"
        :class="{
          'user-active__btn--default':
            button.emitKey === 'onlineFilterHandler' && side === 'left'
              ? !online
              : !(status === button.emitValue)
        }"
      >
        {{ button.buttonText }}
      </button>
    </div>
    <div class="operator__statistic__controll-right">
      <button
        v-for="(button, index) in filterButtonsRight"
        :key="index"
        :disabled="button.emitValue === status"
        @click="
          button.emitKey === 'onlineFilterHandlerRight'
            ? $emit(button.emitKey)
            : $emit(button.emitKey, { key: button.emitValue })
        "
        class="user-active__btn user-active__btn--online"
        :class="{
          'user-active__btn--default':
            button.emitKey === 'onlineFilterHandlerRight' && side === 'right'
              ? !onlineRight
              : !(status === button.emitValue)
        }"
      >
        {{ button.buttonText }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatisticCenterFilters',
  props: {
    status: {
      type: String
    },
    online: {
      type: Boolean
    },
    onlineRight: {
      type: Boolean
    },
    side: {
      type: String
    }
  },
  data: () => ({
    filterButtons: [
      { emitKey: 'baseFilterHandler', emitValue: 'all-day', buttonText: 'Все за сутки' },
      { emitKey: 'baseFilterHandler', emitValue: 'dialogues', buttonText: 'Состоявшиеся диалоги' },
      { emitKey: 'onlineFilterHandler', buttonText: 'Онлайн оператора' }
    ],
    filterButtonsRight: [
      { emitKey: 'baseFilterHandler', emitValue: 'not-processed', buttonText: 'Необработанные сообщения' },
      { emitKey: 'baseFilterHandler', emitValue: 'unhandled-calls', buttonText: 'Необработанные звонки' },
      {
        emitKey: 'baseFilterHandler',
        emitValue: 'unhandled-calls-delayed',
        buttonText: 'Отвечено с задержкой'
      },
      { emitKey: 'onlineFilterHandlerRight', buttonText: 'Онлайн оператора и пользователя' }
    ]
  })
}
</script>

<style lang="scss">
.operator__statistic__controll-center {
  display: flex;
  justify-content: space-between;
  width: 70%;
  align-items: center;
}
</style>
