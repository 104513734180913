<template>
  <Popup
    :visible="visible"
    @close="close"
    title=""
    description="Добавить/Изменить изображение"
    modalClass="popup"
  >
    <form @submit.prevent="sendData">
      <div>
        <img class="avatar" v-if="isAvatar" :src="preview" alt="" />
        <img
          v-else
          class="avatar"
          :src="
            getFile({
              type: 'image',
              file: file,
              width: `auto`,
              height: `auto`
            })
          "
          alt=""
        />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <div class="addfile-btn">
            <input
              class="addfile-btn__send-input"
              id="event-file-input"
              type="file"
              ref="uploadImageCategory"
              @change="fileUploadHandler"
              required
            />
            <label
              class="addfile-btn__send-label"
              for="event-file-input"
              :class="{
                'avatar-invalid': !isAvatar
              }"
            >
              <!-- V Если файл в инпуте V -->
              <template v-if="isAvatar">
                <span class="addfile-btn__send-file"></span>
              </template>
              <i class="fa fa-paperclip"></i>
            </label>
          </div>
          <button type="submit" class="popup__btn btn-green">Изменить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import getImgLink from '@/components/mixins/getImgCdnLink'
import { EVENT_SUBCATEGORY_IMAGE_POPUP } from '@/constants/event'
import { mapActions, mapGetters } from 'vuex'
import { GET_CURRENT_LANGUAGES } from '@/store/getter-types'
import { REQUEST_UPDATE_SUBCATEGORY_EVENT } from '@/store/action-types'

export default {
  name: 'EventSubCategoryImagePopup',
  data() {
    return {
      id: '',
      file: null,
      preview: null,
      isAvatar: false
    }
  },
  components: {
    Popup
  },
  mixins: [ModalMixin, getImgLink],

  computed: {
    ...mapGetters({
      lang: GET_CURRENT_LANGUAGES
    })
  },
  mounted() {
    this.$root.$on(EVENT_SUBCATEGORY_IMAGE_POPUP, eventcat => {
      this.open()
      this.id = eventcat.id
      this.file = eventcat.image
    })
  },
  methods: {
    ...mapActions({
      updateImage: REQUEST_UPDATE_SUBCATEGORY_EVENT
    }),
    closePopup() {
      this.file = null
      this.id = ''
      this.isAvatar = false
      this.close()
    },
    fileUploadHandler() {
      const imageCategory = this.$refs.uploadImageCategory.files[0]
      this.preview = URL.createObjectURL(imageCategory)
      this.isAvatar = true
      this.file = imageCategory
    },
    sendData() {
      const formData = new FormData()
      if (this.file && this.isAvatar) {
        formData.append('image', this.file)
      }
      this.updateImage({ data: formData, id: this.id })
      this.closePopup()
    }
  }
}
</script>

<style lang="scss">
.avatar {
  width: 100%;
  height: 50%;
}
</style>
