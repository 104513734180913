<template>
  <div>
    <table class="gifts-event-table">
      <thead>
        <tr>
          <th>Нумерация валюты</th>
          <th>Коммисия</th>
          <th>Стоимость без комиссии</th>
          <th>Стоимость с коммисией</th>
          <th>Для регионов</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(gift, index) in allGiftsEvent" :key="index">
          <td>
            <p class="gifts-event-table__description">Нумерация валюты</p>
            <p>{{ gift.currency }}</p>
          </td>
          <td class="gifts-event-table__one-column">
            <p class="gifts-event-table__description">Коммисия</p>
            <p>{{ gift.percent }}%</p>
          </td>
          <td>
            <p class="gifts-event-table__description">Стоимость без комиссии</p>
            <p>
              <span
                v-for="(deposit, index) in gift.deposit_values"
                :key="index"
                class="gifts-event-table__deposit-values"
                >{{ deposit }}{{ gift.name }}</span
              >
            </p>
          </td>
          <td>
            <p class="gifts-event-table__description">Стоимость с коммисией</p>
            <p>
              <span
                v-for="(deposit, index) in gift.total_deposit_values"
                :key="index"
                class="gifts-event-table__total-deposit"
                >{{ deposit }}{{ gift.name }}</span
              >
            </p>
          </td>
          <td>
            <p class="gifts-event-table__description">Для регионов</p>
            <p>
              <span v-for="(lang, index) in gift.locales" :key="index">{{ lang }} </span>
            </p>
          </td>
          <td>
            <p class="gifts-event-list-button gifts-event-list__button">
              <span @click="$root.$emit(GIFTS_ENEVT_UPDATE_PERCENT_GREAT_EVENT, gift.id, gift.percent)">
                <i
                  class="fas fa-percent gifts-event-list-button__item gifts-event-list-button__item--percent"
                  title="Задать процент"
                ></i>
              </span>
              <span @click="$root.$emit(GIFTS_ENEVT_UPDATE_GREAT_EVENT, gift)">
                <i
                  class="fa fa-edit gifts-event-list-button__item gifts-event-list-button__item--update"
                  title="Редактировать"
                ></i>
              </span>
              <span @click="$root.$emit(GIFTS_ENEVT_DETELE_GREAT_EVENT, gift.id)">
                <i
                  class="fa fa-times-circle gifts-event-list-button__item gifts-event-list-button__item--delete"
                  title="Удалить"
                ></i>
              </span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <GiftsEventPopupUpdateGiftEventGreat />
    <GiftsEventPopupUpdatePercentGreat />
    <GiftsEventPopupRemoveGreat />
  </div>
</template>

<script>
import {
  GIFTS_ENEVT_UPDATE_PERCENT_GREAT_EVENT,
  GIFTS_ENEVT_UPDATE_GREAT_EVENT,
  GIFTS_ENEVT_DETELE_GREAT_EVENT
} from '@/constants/event'

import GiftsEventPopupUpdateGiftEventGreat from '../Modals/GiftsEventPopup/GiftsEventPopupUpdateGiftEventGreat'
import GiftsEventPopupUpdatePercentGreat from '../Modals/GiftsEventPopup/GiftsEventPopupUpdatePercentGreat'
import GiftsEventPopupRemoveGreat from '../Modals/GiftsEventPopup/GiftsEventPopupRemoveGreat'

export default {
  name: 'GiftsEventTable',
  components: {
    GiftsEventPopupUpdateGiftEventGreat,
    GiftsEventPopupUpdatePercentGreat,
    GiftsEventPopupRemoveGreat
  },

  props: {
    allGiftsEvent: {
      type: Array
    }
  },

  data() {
    return {
      GIFTS_ENEVT_UPDATE_PERCENT_GREAT_EVENT,
      GIFTS_ENEVT_UPDATE_GREAT_EVENT,
      GIFTS_ENEVT_DETELE_GREAT_EVENT
    }
  }
}
</script>

<style lang="scss">
.gifts-event-list-button__item {
  cursor: pointer;
  margin-right: 10px;
}
.gifts-event-table {
  width: 100%;

  border-spacing: 0;
  border-collapse: collapse;

  border-radius: 4px;

  margin-bottom: 25px;
}

.gifts-event-table__currency {
  text-align: left;
}

.gifts-event-table__total-deposit:not(:last-child),
.gifts-event-table__deposit-values:not(:last-child) {
  margin-right: 10px;
}

.gifts-event-list-button__item--percent {
  color: #39b2a9;
}

.gifts-event-list-button__item--update {
  color: #689bcc;
}

.gifts-event-list-button__item--delete {
  color: #ff4a4f;
}

//

tr > th {
  padding: 10px;
  margin-bottom: 10px;

  color: #797979;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 700;

  border-bottom: 10px solid #f1f2f7;

  background-color: #fff;
}

tr > td {
  padding: 10px;
  text-align: center;

  background-color: #fff;

  border-bottom: 10px solid #f1f2f7;
}
</style>
