<template>
  <div>
    <div class="" v-for="el in stat.rates" :key="el">
      <div class="table-statistic__total-row total-payout">
        <span>
          Выплачено: {{ isNamber(stat.total) }}<i class="moderator-payments__icon fas fa-ruble-sign"></i> (за
          пероиод {{ formateDate(el.acts_from) }} — {{ formateDate(el.acts_to) }}), дата выплаты:
          {{ formateDate(stat.payment.created_date) }}
        </span>
      </div>
      <div class="table-statistic__total-row total-dop_payout">
        Доплата: {{ isNamber(stat.payment_dop.amount) }}
        <i class="moderator-payments__icon fas fa-ruble-sign"></i> (за пероиод
        {{ formateDate(el.acts_from) }} — {{ formateDate(el.acts_to) }}), дата начисления:
        {{ formateDate(stat.payment_dop.created_date) }}
      </div>
      <div class="table-statistic__total-row total-earned">
        Заработано: {{ isNamber(stat.total) - isNamber(stat.payment_dop.amount) }}
        <i class="moderator-payments__icon fas fa-ruble-sign"></i> (за пероиод
        {{ formateDate(el.acts_from) }} — {{ formateDate(el.acts_to) }}), дата подсчёта:
        {{ formateDate(stat.payment.date) }}
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'Test',
  props: {
    stat: {
      type: Object
    }
  },
  data() {
    return {
      isChevron: true,
      isPayment: true
    }
  },
  methods: {
    openRate() {
      this.isChevron = !this.isChevron
    },
    openPayment() {
      this.isPayment = !this.isPayment
    },
    formateDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },
    isNamber(data) {
      return Math.trunc(data)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'ModeratorStatistic';
.table-statistic__total-row {
  border-top: 1px solid #ddd;
  background-color: #d9edf7;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}
.table-statistic {
  &__item {
    &--more-rate {
      width: 11%;
      align-items: flex-start;
    }
    .rate-title {
      color: rgb(3, 3, 36);
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
}
.rate-activ {
  &:hover {
    cursor: pointer;
  }
}
.total-payout {
  background-color: #dff0d8;
  padding-left: 12px;
  font-weight: bold;
  color: #000000b5;
}
.total-dop_payout,
.total-earned {
  background-color: #fcf8e3;
  padding-left: 40px;
  font-weight: bold;
  color: #000000b5;
}
.moderator-payments__icon {
  font-size: 12px;
  margin-right: 6px;
}
</style>
