<template>
  <div class="operators__wrap">
    <OperatorsPaymentsHeaders />
    <OperatorsPaymentsFilters />
    <ul class="operators__list" v-if="isLoaded && operators.length">
      <OperatorsPayments v-for="operator in operators" :key="operator.id" :operator="operator" />
    </ul>
    <BillOperatorPopup />
    <BillAllOperatorsPopup />
    <PrizeOperatorPopup />
    <PayOperatorPopup />
    <div class="operators__error" v-if="!operators.length && isLoaded">
      <span class="operators__error-text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="operators__error">
      <span class="operators__error-text">Ошибка при загрузке </span>
    </div>
  </div>
</template>
<script>
import OperatorsPayments from '@/components/Operators/Payments/OperatorsPayments'
import OperatorsPaymentsHeaders from '@/components/Operators/Payments/OperatorsPaymentsHeaders'
import OperatorsPaymentsFilters from '@/components/Operators/Payments/OperatorsPaymentsFilters'
import BillOperatorPopup from '@/components/Modals/OperatorsPopup/Payements/BillOperatorPopup'
import { mapGetters } from 'vuex'
import { REQUEST_OPERATOR_PAYOUT } from '@/store/action-types'
import { GET_OPERATOR_PAYOUT } from '@/store/getter-types'
import store from '@/store'
import BillAllOperatorsPopup from '@/components/Modals/OperatorsPopup/Payements/BillAllOperatorsPopup'
import PrizeOperatorPopup from '@/components/Modals/OperatorsPopup/Payements/PrizeOperatorPopup'
import PayOperatorPopup from '@/components/Modals/OperatorsPopup/Payements/PayOperatorPopup'

export default {
  name: 'operatorsPayments',
  components: {
    PayOperatorPopup,
    PrizeOperatorPopup,
    BillAllOperatorsPopup,
    BillOperatorPopup,
    OperatorsPaymentsHeaders,
    OperatorsPaymentsFilters,
    OperatorsPayments
  },
  data: () => ({
    errorMessage: false,
    isLoaded: false
  }),

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_OPERATOR_PAYOUT).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },

  computed: {
    ...mapGetters({
      operators: GET_OPERATOR_PAYOUT
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
.operators {
  &__wrap {
    padding: 0 16px;
  }
  &__list {
    margin-top: 20px;
    padding: 0 20px;
  }
}
.operators__error {
  margin-top: 20px;
  padding: 0 16px 0 16px;
  min-height: 64px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;

  &-text {
    margin: 0 auto;
  }
  &--big {
    width: 100%;
    margin: 0;
  }
}

.operators-payments__btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  &--success {
    background-color: #78cd51;
    border-color: #78cd51;
    color: #fff;
  }
  &--setting {
    padding: 4px 8px;
    background-color: #ff6c60;
    border-color: #ff6c60;
    color: #fff;
  }
  &--statistic {
    background-color: #58c9f3;
    border-color: #58c9f3;
    color: #fff;
  }
  &--pay {
    background-color: #78cd51;
    border-color: #78cd51;
    color: #fff;
    padding: 0 5px;
  }
  &--award {
    background-color: #f1c500;
    border-color: #f1c500;
    color: #fff;
    width: 30px;
    height: 30px;
    padding: 3px;
  }
}
</style>
