<template>
  <div v-if="employee" class="employee" v-click-outside="closeOutsidePermission">
    <!-- :class="{ 'opacity--medium': !employee.is_active }" -->
    <div class="employee__field employee__field--avatar">
      <div class="employee__avatar">
        <img
          v-if="employee.avatar"
          :src="getFile({ type: 'image', file: employee.avatar, width: 100 })"
          alt=""
        />
        <img v-else src="@/assets/img/anonymous_user.png" alt="" />
      </div>
      <span class="employee__name">
        <!-- <span class="employee__mobtitle">Ф.И.О.:</span> -->
        <span class="employee__text">{{ employee.first_name }} </span>
        <span class="employee__text">{{ employee.last_name }}</span>
      </span>
    </div>
    <div class="employee__field">
      <span class="employee__mobtitle">Email:</span>
      <span class="employee__text">
        {{ employee.email }}
      </span>
    </div>
    <div class="employee__field" v-if="employee.position">
      <span class="employee__mobtitle">Должность:</span>
      <span class="employee__text">{{ employee.position.position_name }}</span>
    </div>
    <div class="employee__field" v-else>
      Должность не задана
    </div>
    <div class="employee__field">
      <span class="employee__mobtitle">Статус:</span>
      <span class="employee__text">{{ isActiveEmployee }}</span>
    </div>
    <div class="employee__field employee__field--btns">
      <!-- :disabled="!employee.is_active" -->
      <!-- :class="{ opacity: !employee.is_active }" -->
      <button
        @click="$root.$emit(EDIT_EMPLOYEE_POPUP_EVENT, employee)"
        class="employee__btn btn-blue employee__btn--blue"
      >
        <i class="fa fa-edit"></i>
      </button>
      <!-- :disabled="!employee.is_active" -->
      <!-- :class="{ opacity: !employee.is_active }" -->
      <button
        @click="$root.$emit(DELETE_EMPLOYEE_POPUP_EVENT, employee.id)"
        class="employee__btn btn-red employee__btn--red"
      >
        <i class="fa fa-times-circle"></i>
      </button>
    </div>
  </div>
  <div v-else class="error">Ошибка подгрузки пользователя</div>
</template>
<script>
import getImgLink from '@/components/mixins/getImgCdnLink'
import { DELETE_EMPLOYEE_POPUP_EVENT, EDIT_EMPLOYEE_POPUP_EVENT } from '@/constants/event'
export default {
  name: 'Employee',
  data: () => ({
    permListOpen: false,
    DELETE_EMPLOYEE_POPUP_EVENT,
    EDIT_EMPLOYEE_POPUP_EVENT
  }),
  props: {
    employee: {
      type: Object
    }
  },
  computed: {
    isActiveEmployee() {
      return this.employee.is_active ? 'Активен' : 'Неактивен'
    }
  },
  mixins: [getImgLink],
  methods: {
    closeOutsidePermission() {
      this.permListOpen = false
    }
  }
}
</script>
<style lang="scss">
@import './Employee.scss';
</style>
