<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Редактирование доступа сотрудника"
    modalClass="popup--medium-permission"
  >
    <form @submit.prevent="editPermissionHandler">
      <div class="filters__group">
        <div class="filters__item">
          <div class="select">
            <span class="select__title">Группа:</span>
            <div class="select__body">
              <button type="button" class="select__field" @click="clickClose('permissions')">
                <span class="select__field-value">{{ defaultFilter({ key: 'permissions' }) }}</span>
                <i class="select__field-icon fa fa-angle-down"></i>
              </button>
              <ul class="select__list" v-if="permissionsSelectOpen" @mouseleave="mouseLeave">
                <li
                  class="select__item"
                  v-for="perm in permissions_group"
                  :key="perm.id"
                  @click="
                    selectFilterBuilder({
                      key: 'permissions',
                      value: perm
                    })
                  "
                >
                  <span class="select__text">
                    {{ perm.name }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Название доступа</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="editNamepermission"
          ref="editnamepermission"
          required
        />
      </div>
      <div class="popup__item">
        <span class="popup__item-title">Уровень доступа</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="editPermissionLevel"
          ref="editpermissionlevel"
          required
        />
      </div>
      <!-- <div class="popup__item">
        <span class="popup__item-title">Значение департамента</span>
        <input
          type="text"
          class="popup__input default-input"
          v-model="editPermissionValue"
          ref="editpermissionvalue"
          required
        />
      </div> -->
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">
            Изменить
          </button>
        </div>
      </div>
    </form>
  </Popup>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_UPDATE_PERMISSION } from '@/store/action-types'
import { GET_FILTERS_PERMISSION_DEFAULT, GET_TMP_PERMISSION_FILERS } from '@/store/getter-types'
import { SET_TMP_FILTERS_PERMISSION, SET_RESET_PERMISSION_FILTERS } from '@/store/mutation-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import translitiration from '@/components/mixins/translitiration'
import { EDIT_PERMISSION_POPUP_EVENT } from '@/constants/event'

export default {
  name: 'EditDepartmentPopup',
  components: {
    Popup
  },
  data: () => ({
    permission: {
      permission_name: '',
      permission_level: '',
      permission: '',
      permission_group: '',
      id: ''
    },
    permissionsSelectOpen: false
  }),
  mixins: [ModalMixin, translitiration],
  props: {
    permissions_group: {
      type: Array
    }
  },

  computed: {
    ...mapGetters({
      defaultFilters: GET_FILTERS_PERMISSION_DEFAULT,
      tmpFilter: GET_TMP_PERMISSION_FILERS
    }),
    editNamepermission: {
      get() {
        return this.permission.permission_name
      },

      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.editnamepermission.value = name
        this.permission.permission_name = name
      }
    },

    editPermissionLevel: {
      get() {
        return this.permission.permission_level
      },

      set(level) {
        level = level.replace(/\D/g, '')
        this.$refs.editpermissionlevel.value = level
        this.permission.permission_level = level
      }
    },

    editPermissionValue: {
      get() {
        return this.permission.permission
      },

      set(value) {
        value = value.replace(/\d/g, '')
        this.$refs.editpermissionvalue.value = value
        this.permission.permission = value
      }
    }
  },

  methods: {
    ...mapActions({
      updateRequestPermission: REQUEST_UPDATE_PERMISSION
    }),

    ...mapMutations({
      resetFilters: SET_RESET_PERMISSION_FILTERS,
      addTmpFilters: SET_TMP_FILTERS_PERMISSION
    }),
    setPerm() {
      for (let el of this.permissions_group) {
        for (let key in el) {
          if (el[key] === this.permission.permission_group) {
            this.selectFilterBuilder({ key: 'permissions', value: el })
          }
        }
      }
    },
    mouseLeave() {
      this.closeList()
    },
    closeList() {
      this.permissionsSelectOpen = false
    },

    clickClose(options) {
      let newOptions = this[`${options}SelectOpen`]
      this.closeList()
      this[`${options}SelectOpen`] = !newOptions
    },
    defaultFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : this.defaultFilters[key].name
    },

    defaultNameFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : ''
    },
    defaultSortFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current ? current : ''
    },

    defaultValueFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current !== undefined ? current : ''
    },

    selectFilterBuilder(filterItem) {
      const { key } = filterItem
      switch (key) {
        case 'permissions':
          this.addTmpFilters({ ...filterItem })
          break
        default:
          break
      }
      this[`${key}SelectOpen`] = false
    },

    closePopup() {
      this.permission.permission_name = ''
      this.permission.permission_level = ''
      this.permission.permission = ''
      this.permission.permission_group = ''
      this.permission.id = ''
      this.resetFilters()
      this.close()
    },

    editPermissionHandler() {
      const editPermission = {
        permissionData: {
          permission_name: this.permission.permission_name,
          permission_level: this.permission.permission_level,
          permission: this.translite(this.permission.permission_name).replace('|_', ''),
          permission_group: this.defaultValueFilter({ key: 'permissions' })
        },
        id: this.permission.id
      }
      this.updateRequestPermission(editPermission)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(EDIT_PERMISSION_POPUP_EVENT, permission => {
      this.permission.permission_name = permission.permission_name
      this.permission.permission_level = permission.permission_level
      this.permission.permission = permission.permission
      this.permission.id = permission.id
      this.permission.permission_group = permission.permission_group
      this.setPerm()
      this.open()
    })
  }
}
</script>

<style lang="scss" scoped>
.form_new-permission {
  margin: 6% auto;
}
.filters__item {
  margin: 25px 0;
}
.select__field,
.select__list {
  width: 150px;
}
</style>
