<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Совершить выплату выбранным модераторам?"
    modalClass="popup"
  >
    <div class="popup__item">
      <div class="popup__item-wraper" v-if="noPrizePays.length">
        <span>Выберите модераторов из списка:</span>
        <button class="popup__btn popup__btn-edit" @click.prevent="selectAllModerators()">
          Выбрать всех
        </button>
        <ul class="popup__moderator-list">
          <li class="popup__moderator-item" v-for="moderator in noPrizePays" :key="moderator.id">
            <input
              class="popup__moderator-checkbox"
              type="checkbox"
              :id="moderator.id"
              :value="{ moderator_id: moderator.id, pay_id: moderator.pay_id }"
              v-model="checkedModerators"
            />
            <label :for="moderator.id"
              >{{ moderator.first_name }} {{ moderator.last_name }}, c
              {{ toLocaleDateUserWithoutTime(moderator.payout_period_from) }} по
              {{ toLocaleDateUserWithoutTime(moderator.payout_period_to) }}</label
            >
          </li>
        </ul>
      </div>
      <div v-else class="popup__item-wraper popup__item--error">
        Нет подходящих модераторов для оплаты
      </div>
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="billHandler()">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Нет
        </button>
      </div>
      <span class="popup__item--error" v-if="isError">Необходимо выбрать модератора</span>
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_EXECUTE_MODERATORS } from '@/store/action-types'
import { GET_MODERATOR_TMP_ROLES, GET_MODERATOR_PAYOUT } from '@/store/getter-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { BILL_ALL_MODERATOR_POPUP_EVENT, ERROR_MODERATOR_PAY_EVENT } from '@/constants/event'
import toLocaleDateTime from '@/components/mixins/toLocaleformat'
import moment from 'moment'

export default {
  name: 'BillAllModeratorPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin, toLocaleDateTime],

  data: () => ({
    BILL_ALL_MODERATOR_POPUP_EVENT,
    ERROR_MODERATOR_PAY_EVENT,
    checkedModerators: [],
    date: null,
    time: null,
    isError: false,
    isErrorDate: false
  }),
  computed: {
    ...mapGetters({
      tmpFilter: GET_MODERATOR_TMP_ROLES,
      moderators: GET_MODERATOR_PAYOUT
    }),
    noPrizePays() {
      let tableHeaders = []
      this.moderators.forEach(f => {
        f.pays.forEach(m => {
          if (!m.payout_date && m.settlement_date) {
            let shortName = m.settlement_date.split('_')
            let twoWeeksOld = moment(shortName[0])
              .add(14, 'days')
              .startOf('day')
              .toISOString()
            if (moment().isSameOrAfter(twoWeeksOld)) {
              tableHeaders.push({
                id: f.id,
                pay_id: m.id,
                first_name: f.first_name,
                last_name: f.last_name,
                payout_period_from: m.payout_period_from,
                payout_period_to: m.payout_period_to
              })
            }
          }
        })
      })
      return tableHeaders
    }
  },
  watch: {
    checkedModerators: function() {
      if (this.checkedModerators.length) this.isError = false
    }
  },

  methods: {
    ...mapActions({
      billModerator: REQUEST_EXECUTE_MODERATORS
    }),
    selectAllModerators() {
      this.checkedModerators = []
      this.noPrizePays.forEach(f => {
        this.checkedModerators.push({ moderator_id: f.id, pay_id: f.pay_id })
      })
    },
    closePopup() {
      this.checkedModerators = []
      this.isError = false
      this.close()
    },
    billHandler() {
      if (this.checkedModerators.length) {
        let formData = {
          data: {
            pays: this.checkedModerators
          },
          active: null
        }
        if (this.tmpFilter.status.name && this.tmpFilter.status.name !== 'Все') {
          formData.active = {
            is_active: this.tmpFilter.status.value
          }
        }
        if (!this.isErrorDate) {
          this.billModerator(formData).then(response => {
            let errorArr = []
            response.data.results.forEach(f => {
              if (f.status === 3 || f.status === 4) {
                errorArr.push({
                  info: this.noPrizePays.find(n => n.pay_id === f.pay_id),
                  status: f.status
                })
              }
            })
            if (errorArr.length) {
              this.$root.$emit(ERROR_MODERATOR_PAY_EVENT, errorArr)
            }
            this.closePopup()
          })
        }
      } else {
        this.isError = true
      }
    }
  },

  mounted() {
    this.$root.$on(BILL_ALL_MODERATOR_POPUP_EVENT, () => {
      this.open()
    })
  }
}
</script>
<style lang="scss">
@import 'SettingsPayment';
</style>
