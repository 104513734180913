const SET_USERS = 'SET_USERS'
const SET_CURRENT_USER = 'SET_CURRENT_USER'
const SET_DATE_FILTER = 'SET_DATE_FILTER'
const SET_TMP_FILTERS = 'SET_TMP_FILTERS'
const SET_RESET_FILTERS = 'SET_RESET_FILTERS'
const SET_USER_DEPOSIT = 'SET_USER_DEPOSIT'
const SET_USER_PAYOUTS = 'SET_USER_PAYOUTS'
const SET_USER_PURCHASES = 'SET_USER_PURCHASES'
const SET_USER_PHOTOS = 'SET_USER_PHOTOS'
const SET_USER_CHATS = 'SET_USER_CHATS'
const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES'
const SET_CHAT_OLD_MESSAGES = 'SET_CHAT_OLD_MESSAGES'
const SET_UTM_FILTER = 'SET_UTM_FILTER'
const SET_CURRENT_FILTER = 'SET_CURRENT_FILTER'
const SET_USER_COUNTS = 'SET_USER_COUNTS'
const SET_USER_FILTER_COUNTS = 'SET_USER_FILTER_COUNTS'
const SET_ALL_USERS = 'SET_ALL_USERS'
const SET_UPDATE_PHOTO_STATUS = 'SET_UPDATE_PHOTO_STATUS'
const SET_USERCHATS_STYLE = 'SET_USERCHATS_STYLE'
const SET_TRANSACTION_KIND = 'SET_TRANSACTION_KIND'
const SET_USER_CHATS_CLEAR = 'SET_USER_CHATS_CLEAR'

export {
  SET_USERS,
  SET_USER_COUNTS,
  SET_CURRENT_USER,
  SET_TRANSACTION_KIND,
  SET_DATE_FILTER,
  SET_TMP_FILTERS,
  SET_RESET_FILTERS,
  SET_USER_DEPOSIT,
  SET_USER_PAYOUTS,
  SET_USER_PURCHASES,
  SET_USER_PHOTOS,
  SET_USER_CHATS,
  SET_CHAT_MESSAGES,
  SET_CHAT_OLD_MESSAGES,
  SET_UTM_FILTER,
  SET_CURRENT_FILTER,
  SET_USER_FILTER_COUNTS,
  SET_ALL_USERS,
  SET_UPDATE_PHOTO_STATUS,
  SET_USERCHATS_STYLE,
  SET_USER_CHATS_CLEAR
}
