<template>
  <div class="statistics">
    <h2 class="statistics__title">Статистика проверки фото</h2>
    <StatisticModeratorsPhotoCheck
      v-if="isLoadedModeratorsPhotoCheck"
      :moderatorsPhotoCheck="moderatorsPhotoCheck"
    />
    <div v-if="isLoadedModeratorsPhotoCheckError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>
    <div v-if="errorMessage" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке </span>
    </div>
    <h2 class="statistics__title">Статистика проверки видео</h2>
    <StatisticVideoModaration
      v-if="isLoadedModeratorsVideoCheck"
      :moderatorsVideoCheck="moderatorsVideoCheck"
    />
    <div v-if="isLoadedModeratorsVideoCheckError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>
    <div v-if="errorMessage" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке </span>
    </div>
  </div>
</template>
<script>
import StatisticModeratorsPhotoCheck from '@/components/Statistic/StatisticModeratorsPhotoCheck'
import StatisticVideoModaration from '@/components/Statistic/StatisticVideoModaration'
import { mapGetters } from 'vuex'
import { REQUEST_MODERATORS_PHOTO_CHECK, REQUEST_MODARATION_VIDEO } from '@/store/action-types'
import { GET_MODERATORS_PHOTO_CHECK, GET_MODERATORS_VIDEO_CHECK } from '@/store/getter-types'
import store from '@/store'
import moment from 'moment'

export default {
  name: 'StatisticsModeration',
  components: {
    StatisticModeratorsPhotoCheck,
    StatisticVideoModaration
  },
  data: () => ({
    errorMessage: false,
    isLoadedModeratorsPhotoCheckError: false,
    isLoadedModeratorsPhotoCheck: false,
    isLoadedModeratorsVideoCheckError: false,
    isLoadedModeratorsVideoCheck: false,
    countCityDefault: {
      city_count: 20
    }
  }),
  beforeRouteEnter(to, from, next) {
    let date = {
      date_from: moment()
        .subtract(14, 'days')
        .startOf('day')
        .format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      delta: new Date().getTimezoneOffset() / -60
    }
    store
      .dispatch(REQUEST_MODERATORS_PHOTO_CHECK, { date_from: date.date_to, delta: date.delta })
      .then(response => {
        next(vm => {
          if (!response.status) {
            vm.isLoadedModeratorsPhotoCheckError = true
          } else {
            vm.isLoadedModeratorsPhotoCheck = true
          }
          store
            .dispatch(REQUEST_MODARATION_VIDEO, { date_from: date.date_to, delta: date.delta })
            .then(res => {
              if (!res.status) {
                vm.isLoadedModeratorsVideoCheckError = true
              } else {
                vm.isLoadedModeratorsVideoCheck = true
              }
            })
        })
      })
  },

  computed: {
    ...mapGetters({
      moderatorsPhotoCheck: GET_MODERATORS_PHOTO_CHECK,
      moderatorsVideoCheck: GET_MODERATORS_VIDEO_CHECK
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/vue2-daterange-picker.scss';
.statistics {
  width: 100%;
  &__title {
    margin: 10px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #c9cdd7;
  }
  &__error {
    margin-top: 20px;
    padding: 0 16px 0 16px;
    min-height: 64px;
    border-radius: 2px;

    position: relative;
    display: flex;
    align-items: center;
    background-color: white;

    &-text {
      margin: 0 auto;
    }
  }
}
.regenterstats-chart {
  height: 290px;
}
.table-condensed {
  td:hover {
    background: #318bcc;
    color: #fff;
  }
  .today {
    background: #48a7eb;
    color: #fff;
  }
}
</style>
