const REQUEST_AUTH = 'REQUEST_AUTH'
const REQUEST_PROFILE_ID = 'REQUEST_PROFILE_ID'
const REQUEST_LOGOUT = 'REQUEST_LOGOUT'
const REQUEST_REFRESH_TOKEN = 'REQUEST_REFRESH_TOKEN'
const REQUEST_DEPARTMENT = 'REQUEST_DEPARTMENT'
const REQUEST_PERMISSION_USER = 'REQUEST_PERMISSION_USER'

//users
const REQUEST_APPLY_FILTERS = 'users/REQUEST_APPLY_FILTERS'
const REQUEST_UPDATE_VIP_CALL = 'users/REQUEST_UPDATE_VIP_CALL'
const REQUEST_ID_USER = 'users/REQUEST_ID_USER'
const REQUEST_USER_VERIFICATION = 'users/REQUEST_USER_VERIFICATION'
const REQUEST_GET_VERIFY_AGE = 'users/REQUEST_GET_VERIFY_AGE'
const REQUEST_BLOCK_MAIN_PHOTO = 'users/REQUEST_BLOCK_MAIN_PHOTO'
const REQUEST_UPDATE_VIP = 'users/REQUEST_UPDATE_VIP'
const REQUEST_BAN_USER = 'users/REQUEST_BAN_USER'
const REQUEST_DELETE_USER = 'users/REQUEST_DELETE_USER'
const REQUEST_UPDATE_POINTS = 'users/REQUEST_UPDATE_POINTS'
const REQUEST_HIDE_USER_APP = 'users/REQUEST_HIDE_USER_APP'
const REQUEST_HIDE_USER_ADMIN = 'users/REQUEST_HIDE_USER_ADMIN'
const REQUEST_UPDATE_RATING = 'users/REQUEST_UPDATE_RATING'
const REQUEST_UPDATE_GENDER = 'users/REQUEST_UPDATE_GENDER'
const REQUEST_USER_DEPOSIT = 'users/REQUEST_USER_DEPOSIT'
const REQUEST_USER_PAYOUTS = 'users/REQUEST_USER_PAYOUTS'
const REQUEST_USER_TRANSACTION_KIND = 'users/REQUEST_USER_TRANSACTION_KIND'
const REQUEST_USER_PHOTOS = 'users/REQUEST_USER_PHOTOS'
const REQUEST_USER_CHATS = 'users/REQUEST_USER_CHATS'
const REQUEST_CHAT_MESSAGES = 'users/REQUEST_CHAT_MESSAGES'
const REQUEST_CHAT_OLD_MESSAGES = 'users/REQUEST_CHAT_OLD_MESSAGES'
const REQUEST_DELETE_VIP = 'users/REQUEST_DELETE_VIP'
const REQUEST_DELETE_PHOTO = 'users/REQUEST_DELETE_PHOTO'
const REQUEST_FILTER_UTM = 'users/REQUEST_FILTER_UTM'
const REQUEST_VISIBLE_MAP_USER = 'users/REQUEST_VISIBLE_MAP_USER'
const REQUEST_ALL_USERS = 'users/REQUEST_ALL_USERS'
const REQUEST_USER_PURCHASES = 'users/REQUEST_USER_PURCHASES'
const REQUEST_UPDATE_USER_PHOTO_STATUS = 'users/REQUEST_UPDATE_USER_PHOTO_STATUS'
const REQUEST_SEND_ALERT_TO_USER = 'users/REQUEST_SEND_ALERT_TO_USER'
// view mobile app
const REQUEST_VIEWS = 'view/REQUEST_VIEWS'
const REQUEST_CREATE_VIEW = 'view/REQUEST_CREATE_VIEW'
const REQUEST_DELETE_VIEW_RECORD = 'view/REQUEST_DELETE_VIEW_RECORD'

// events
const REQUEST_ADD_CATEGORY_EVENT = 'events/REQUEST_ADD_CATEGORY_EVENT'
const REQUEST_UPDATE_CATEGORY_EVENT = 'events/REQUEST_UPDATE_CATEGORY_EVENT'
const REQUEST_DELETE_CATEGORY_EVENT = 'events/REQUEST_DELETE_CATEGORY_EVENT'
const REQUEST_ADD_SUBCATEGORY_EVENT = 'events/REQUEST_ADD_SUBCATEGORY_EVENT'
const REQUEST_UPDATE_SUBCATEGORY_EVENT = 'events/REQUEST_UPDATE_SUBCATEGORY_EVENT'
const REQUEST_DELETE_SUBCATEGORY_EVENT = 'events/REQUEST_DELETE_SUBCATEGORY_EVENT'
const REQUEST_ALL_CATEGORIES = 'events/REQUEST_ALL_CATEGORIES'
const REQUEST_ALL_EVENT_REFBOOK = 'events/REQUEST_ALL_EVENT_REFBOOK'
const REQUEST_ADD_EVENT_REFBOOK = 'events/REQUEST_ADD_EVENT_REFBOOK'
const REQUEST_UPDATE_EVENT_REFBOOK = 'events/REQUEST_UPDATE_EVENT_REFBOOK'
const REQUEST_DELETE_EVENT_REFBOOK = 'events/REQUEST_DELETE_EVENT_REFBOOK'
const REQUEST_EVENTS = 'events/REQUEST_EVENTS'
const REQUEST_ALL_EVENTS = 'events/REQUEST_ALL_EVENTS'
const REQUEST_PAGE_USERS = 'events/REQUEST_PAGE_USERS'
const REQUEST_DELETE_EVENT = 'events/REQUEST_DELETE_EVENT'
const REQUEST_EVENT_BY_ID = 'events/REQUEST_EVENT_BY_ID'

// languages
const REQUEST_ALL_LANGUAGES = 'languages/REQUEST_ALL_LANGUAGES'
const REQUEST_DELETED_LANGUAGES = 'languages/REQUEST_DELETED_LANGUAGES'
const REQUEST_CREATE_NEW_LANGUAGES = 'languages/REQUEST_CREATE_NEW_LANGUAGES'

// FAQ
const REQUEST_ALL_CATEGORY_BY_LANG = 'faq/REQUEST_ALL_CATEGORY_BY_LANG'
const REQUEST_CONTENT_BY_ID = 'faq/REQUEST_CONTENT_BY_ID'
const REQUEST_CREATE_NEW_CATEGORY = 'faq/REQUEST_CREATE_NEW_CATEGORY'
const REQUEST_DELETE_CATEGORY_BY_ID = 'faq/REQUEST_DELETE_CATEGORY_BY_ID'
const REQUEST_DATELE_QUESTION_BY_ID = 'faq/REQUEST_DATELE_QUESTION_BY_ID'
const REQUEST_UPDATE_QUESTION_BY_ID = 'faq/REQUEST_UPDATE_QUESTION_BY_ID'
const REQUEST_UPDATE_CATEGORY_BY_ID = 'faq/REQUEST_UPDATE_CATEGORY_BY_ID'
const REQUEST_ADD_NEW_CONTENT_BY_CATEGORY = 'faq/REQUEST_ADD_NEW_CONTENT_BY_CATEGORY'

//control employee
const REQUEST_EMPLOYEE = 'employee/REQUEST_EMPLOYEE'
const REQUEST_ALL_EMPLOYEE = 'employee/REQUEST_ALL_EMPLOYEE'
const REQUEST_ALL_PERMISISONS = 'employee/REQUEST_ALL_PERMISISONS'
const REQUEST_ALL_POSITIONS = 'employee/REQUEST_ALL_POSITIONS'
const REQUEST_DELETE_EMPLOYEE = 'employee/REQUEST_DELETE_EMPLOYEE'
const REQUEST_EDIT_EMPLOYEE = 'employee/REQUEST_EDIT_EMPLOYEE'
const REQUEST_CREATE_EMPLOYEE = 'employee/REQUEST_CREATE_EMPLOYEE'
const REQUEST_CHECK_EMAIL = 'employee/REQUEST_CHECK_EMAIL'
const REQUEST_EMPLOYEE_FILTER = 'employee/REQUEST_EMPLOYEE_FILTER'

//control departments
const REQUEST_CONTROL_DEPARTMENTS = 'departments/REQUEST_CONTROL_DEPARTMENTS'
const REQUEST_CREATE_DEPARTMENT = 'departments/REQUEST_CREATE_DEPARTMENT'
const REQUEST_DELETE_DEPARTMENT = 'departments/REQUEST_DELETE_DEPARTMENT'
const REQUEST_UPDATE_DEPARTMENT = 'departments/REQUEST_UPDATE_DEPARTMENT'
const REQUEST_DEPARTMENTS_BY_FILTER = 'departments/REQUEST_DEPARTMENTS_BY_FILTER'

//control permissions
const REQUEST_CONTROL_PERMISSIONS = 'permissions/REQUEST_CONTROL_PERMISSIONS'
const REQUEST_CREATE_PERMISSION = 'permissions/REQUEST_CREATE_PERMISSION'
const REQUEST_DELETE_PERMISSION = 'permissions/REQUEST_DELETE_PERMISSION'
const REQUEST_UPDATE_PERMISSION = 'permissions/REQUEST_UPDATE_PERMISSION'
const REQUEST_PERMISSIONS_GROUP = 'permissions/REQUEST_PERMISSIONS_GROUP'

//control positions
const REQUEST_CONTROL_POSITIONS = 'positions/REQUEST_CONTROL_POSITIONS'
const REQUEST_CREATE_POSITION = 'positions/REQUEST_CREATE_POSITION'
const REQUEST_DELETE_POSITION = 'positions/REQUEST_DELETE_POSITION'
const REQUEST_UPDATE_POSITION = 'positions/REQUEST_UPDATE_POSITION'
//achievements
const REQUEST_ACHIEVEMENTS = 'achievements/REQUEST_ACHIEVEMENTS'
const REQUEST_EDIT_ACHIEVEMENTS = 'achievements/REQUEST_EDIT_ACHIEVEMENTS'
const REQUEST_DELETE_ACHIEVEMENT = 'achievements/REQUEST_DELETE_ACHIEVEMENT'
const REQUEST_UPDATE_ACHIEVEMENTS = 'achievements/REQUEST_UPDATE_ACHIEVEMENTS'
//cause points
const REQUEST_CAUSE_POINTS = 'causePoints/REQUEST_CAUSE_POINTS'
const REQUEST_ADD_CAUSE_POINTS = 'causePoints/REQUEST_ADD_CAUSE_POINTS'
const REQUEST_DELETE_CAUSE_POINTS = 'causePoints/REQUEST_DELETE_CAUSE_POINTS'
const REQUEST_UPDATE_CAUSE_POINTS = 'causePoints/REQUEST_UPDATE_CAUSE_POINTS'

// Gifts
const REQUEST_GET_ALL_GIFTS = 'gifts/REQUEST_GET_ALL_GIFTS'
const REQUEST_DELETE_GIFTS_BY_ID = 'gifts/REQUEST_DELETE_GIFTS_BY_ID'
const REQUEST_CREATE_NEW_GIFTS = 'gifts/REQUEST_CREATE_NEW_GIFTS'
const REQUEST_UPDATE_GIFTS_BY_ID = 'gifts/REQUEST_UPDATE_GIFTS_BY_ID'
// Gifts Events
const REQUEST_ALL_GIFTS_EVENTS = 'giftEvent/REQUEST_ALL_GIFTS_EVENTS'
const REQUEST_NEW_GIFTS_EVENTS = 'giftEvent/REQUEST_NEW_GIFTS_EVENTS'
const REQUEST_DELETE_GIFTS_EVENTS = 'giftEvent/REQUEST_DELETE_GIFTS_EVENTS'
const REQUEST_UPDATE_GIFTS_EVENTS = 'giftEvent/REQUEST_UPDATE_GIFTS_EVENTS'
const REQUEST_UPDATE_PERCENT_GIFTS_EVENTS = 'giftEvent/REQUEST_UPDATE_PERCENT_GIFTS_EVENTS'
//Reason Delete
const REQUEST_ADD_REASON_DELETE = 'reasons/REQUEST_ADD_REASON_DELETE'
const REQUEST_UPDATE_REASON_DELETE = 'reasons/REQUEST_UPDATE_REASON_DELETE'
const REQUEST_DELETE_REASON_DELETE = 'reasons/REQUEST_DELETE_REASON_DELETE'
const REQUEST_REASON_DELETE = 'reasons/REQUEST_REASON_DELETE'
//Reason Block
const REQUEST_ADD_REASON_BLOCK = 'reasons/REQUEST_ADD_REASON_BLOCK'
const REQUEST_UPDATE_REASON_BLOCK = 'reasons/REQUEST_UPDATE_REASON_BLOCK'
const REQUEST_DELETE_REASON_BLOCK = 'reasons/REQUEST_DELETE_REASON_BLOCK'
const REQUEST_REASON_BLOCK = 'reasons/REQUEST_REASON_BLOCK'
//Support contact types
const REQUEST_ADD_SUPPORT_CONTACT_TYPES = 'supportContacts/REQUEST_ADD_SUPPORT_CONTACT_TYPES'
const REQUEST_DELETE_SUPPORT_CONTACT_TYPES = 'supportContacts/REQUEST_DELETE_SUPPORT_CONTACT_TYPES'
const REQUEST_EDIT_CONTACT_TYPES = 'supportContacts/REQUEST_EDIT_CONTACT_TYPES'
const REQUEST_SUPPORT_CONTACT_TYPES = 'supportContacts/REQUEST_SUPPORT_CONTACT_TYPES'
//Market Utm
const REQUEST_ADD_MARKET_UTM = 'market/REQUEST_ADD_MARKET_UTM'
const REQUEST_UPDATE_MARKET_UTM = 'market/REQUEST_UPDATE_MARKET_UTM'
const REQUEST_DELETE_MARKET_UTM = 'market/REQUEST_DELETE_MARKET_UTM'
const REQUEST_MARKET_UTM = 'market/REQUEST_MARKET_UTM'
//Push
const REQUEST_PUSHES = 'push/REQUEST_PUSHES'
const REQUEST_ADD_PUSHES = 'push/REQUEST_ADD_PUSHES'
const REQUEST_UPDATE_PUSHES = 'push/REQUEST_UPDATE_PUSHES'
const REQUEST_DELETE_PUSHES = 'push/REQUEST_DELETE_PUSHES'
//Auto messages
const REQUEST_ADD_AUTO_MESSAGES = 'autoMessages/REQUEST_ADD_AUTO_MESSAGES'
const REQUEST_DELETE_AUTO_MESSAGES = 'autoMessages/REQUEST_DELETE_AUTO_MESSAGES'
const REQUEST_AUTO_MESSAGES_PAGINATION = 'autoMessages/REQUEST_AUTO_MESSAGES_PAGINATION'
const REQUEST_CHANGE_AUTO_MESSAGES = 'autoMessages/REQUEST_CHANGE_AUTO_MESSAGES'
const REQUEST_AUTO_MESSAGES = 'autoMessages/REQUEST_AUTO_MESSAGES'

//AutoMessagesList
const REQUEST_AUTO_MESSAGES_LIST = 'autoMessagesList/REQUEST_AUTO_MESSAGES_LIST'
const REQUEST_AUTO_MESSAGES_LIST_APPLY_FILTERS = 'autoMessagesList/REQUEST_AUTO_MESSAGES_LIST_APPLY_FILTERS'

//operators
const REQUEST_ALL_OPERATORS = 'operators/REQUEST_ALL_OPERATORS'
const REQUEST_DELETE_OPERATORS = 'operators/REQUEST_DELETE_OPERATORS'
const REQUEST_DELETE_OPERATOR_USER = 'operators/REQUEST_DELETE_OPERATOR_USER'
const REQUEST_CHANGE_WEIGHT_SENDING = 'operators/REQUEST_CHANGE_WEIGHT_SENDING'
const REQUEST_CHANGE_TYPE_MESSAGE = 'operators/REQUEST_CHANGE_TYPE_MESSAGE'
const REQUEST_EDIT_OPERATORS = 'operators/REQUEST_EDIT_OPERATORS'
const REQUEST_CREATE_OPERATORS = 'operators/REQUEST_CREATE_OPERATORS'
const REQUEST_FIND_USERS = 'operators/REQUEST_FIND_USERS'
const REQUEST_OPERATOR_STATISTIC = 'operators/REQUEST_OPERATOR_STATISTIC'
const REQUEST_OPERATOR_PAYOUT = 'operators/REQUEST_OPERATOR_PAYOUT'
const REQUEST_CHANGE_COUNTRY_OPERATOR = 'operators/REQUEST_CHANGE_COUNTRY_OPERATOR'
const REQUEST_BILLING_OPERATORS = 'operators/REQUEST_BILLING_OPERATORS'
const REQUEST_PRIZE_OPERATORS = 'operators/REQUEST_PRIZE_OPERATORS'
const REQUEST_EXECUTE_OPERATORS = 'operators/REQUEST_EXECUTE_OPERATORS'
const REQUEST_EDIT_OPERATORS_SEARCH_OPTIONS = 'operators/REQUEST_EDIT_OPERATORS_SEARCH_OPTIONS'
const REQUEST_EDIT_OPERATOR_AGE = 'operators/REQUEST_EDIT_OPERATOR_AGE'
const REQUEST_EDIT_OPERATOR_NAME = 'operators/REQUEST_EDIT_OPERATOR_NAME'
const REQUEST_EDIT_OPERATOR_SEARCH = 'operators/REQUEST_EDIT_OPERATOR_SEARCH'
const REQUEST_EDIT_OPERATORS_AUTO_MESSAGE = 'operators/REQUEST_EDIT_OPERATORS_AUTO_MESSAGE'
const REQUEST_OPERATORS_BANNER = 'operators/REQUEST_OPERATORS_BANNER'
const REQUEST_EDIT_OPERATORS_BANNER = 'operators/REQUEST_EDIT_OPERATORS_BANNER'
const REQUEST_DELETE_OPERATORS_BANNER = 'operators/REQUEST_DELETE_OPERATORS_BANNER'
const REQUEST_CHANGE_OPERATORS_BANNER = 'operators/REQUEST_CHANGE_OPERATORS_BANNER'
const REQUEST_OPERATORS_AUTO_MESSAGE = 'operators/REQUEST_OPERATORS_AUTO_MESSAGE'
const REQUEST_EDIT_AUTO_MESSAGE_DELAY = 'operators/REQUEST_EDIT_AUTO_MESSAGE_DELAY'
const REQUEST_CHANGE_OPERATORS_AUTO_MESSAGE = 'operators/REQUEST_CHANGE_OPERATORS_AUTO_MESSAGE'
const REQUEST_DELETE_OPERATORS_AUTO_MESSAGE = 'operators/REQUEST_DELETE_OPERATORS_AUTO_MESSAGE'
const REQUEST_EDIT_LOCATION_SETTING = 'operators/REQUEST_EDIT_LOCATION_SETTING'
const REQUEST_TRANSFER_OPERATOR = 'operators/REQUEST_TRANSFER_OPERATOR'
const REQUEST_GET_OPERATOR_INFO_ON_SHIFT = 'operators/REQUEST_GET_OPERATOR_INFO_ON_SHIFT'
const REQUEST_FILTERS_OPERATORS = 'operators/REQUEST_FILTERS_OPERATORS'

//calendar
const REQUEST_SHIFT_OPTIONS = 'calendar/REQUEST_SHIFT_OPTIONS'
const REQUEST_CALENDAR_EVENTS = 'calendar/REQUEST_CALENDAR_EVENTS'
const REQUEST_ADD_CALENDAR_EVENT = 'calendar/REQUEST_ADD_CALENDAR_EVENT'
const REQUEST_DELL_MODERATOR_EVENT = 'calendar/REQUEST_DELL_MODERATOR_EVENT'
const REQUEST_ADD_MODERATOR_EVENT = 'calendar/REQUEST_ADD_MODERATOR_EVENT'
const REQUEST_NEW_SHIFT_OPTIONS = 'calendar/REQUEST_NEW_SHIFT_OPTIONS'
const REQUEST_CHANGE_OPERATOR_EVENT = 'calendar/REQUEST_CHANGE_OPERATOR_EVENT'
const REQUEST_MOD_OPERATOR_EVENTS = 'calendar/REQUEST_MOD_OPERATOR_EVENTS'

//payments setting
const REQUEST_PAYMENTS_SETTING = 'operatorPayments/REQUEST_PAYMENTS_SETTING'
const REQUEST_DELETE_PAYMENTS_SETTING = 'operatorPayments/REQUEST_DELETE_PAYMENTS_SETTING'
const REQUEST_EDIT_PAYMENTS_SETTING = 'operatorPayments/REQUEST_EDIT_PAYMENTS_SETTING'
const REQUEST_CREATE_PAYMENTS_SETTING = 'operatorPayments/REQUEST_CREATE_PAYMENTS_SETTING'
const REQUEST_EDIT_DEFAULT_SETTING = 'operatorPayments/REQUEST_EDIT_DEFAULT_SETTING'
const REQUEST_CREATE_DEFAULT_SETTING = 'operatorPayments/REQUEST_CREATE_DEFAULT_SETTING'

//moderator
const REQUEST_MODERATOR_PAYMENTS_SETTING = 'moderatorPayments/REQUEST_MODERATOR_PAYMENTS_SETTING'
const REQUEST_MODERATOR_EDIT_PAYMENTS_SETTING = 'moderatorPayments/REQUEST_MODERATOR_EDIT_PAYMENTS_SETTING'
const REQUEST_MODERATOR_CREATE_PAYMENTS_SETTING =
  'moderatorPayments/REQUEST_MODERATOR_CREATE_PAYMENTS_SETTING'
const REQUEST_ALL_MODERATORS = 'moderatorPayments/REQUEST_ALL_MODERATORS'
const REQUEST_MODERATOR_STATISTIC = 'moderatorPayments/REQUEST_MODERATOR_STATISTIC'
const REQUEST_MODERATOR_PAYOUT = 'moderatorPayments/REQUEST_MODERATOR_PAYOUT'
const REQUEST_BILLING_MODERATORS = 'moderatorPayments/REQUEST_BILLING_MODERATORS'
const REQUEST_PRIZE_MODERATORS = 'moderatorPayments/REQUEST_PRIZE_MODERATORS'
const REQUEST_EXECUTE_MODERATORS = 'moderatorPayments/REQUEST_EXECUTE_MODERATORS'
const REQUEST_DELETE_MODERATOR_PAYMENTS_SETTING =
  'moderatorPayments/REQUEST_DELETE_MODERATOR_PAYMENTS_SETTING'
const REQUEST_MODERATOR_DEL_PAYMENTS_ACTIVE = 'moderatorPayments/REQUEST_MODERATOR_DEL_PAYMENTS_ACTIVE'
const REQUEST_CORRECTION_MODERATORS = 'moderatorPayments/REQUEST_CORRECTION_MODERATORS'
//moderator Rating
const REQUEST_MODERATORS_RATING = 'moderatorRating/REQUEST_MODERATORS_RATING'
const REQUEST_MODERATOR_SETTINGS_SPEED = 'moderatorRating/REQUEST_MODERATOR_SETTINGS_SPEED'
const REQUEST_MODERATOR_SETTINGS_QUALITY = 'moderatorRating/REQUEST_MODERATOR_SETTINGS_QUALITY'
const REQUEST_UPDATE_MODERATOR_SETTINGS_QUALITY = 'moderatorRating/REQUEST_UPDATE_MODERATOR_SETTINGS_QUALITY'
const REQUEST_UPDATE_MODERATOR_SETTINGS_SPEED = 'moderatorRating/REQUEST_UPDATE_MODERATOR_SETTINGS_SPEED'

//purchases
const REQUEST_PURCHASES = 'purchases/REQUEST_PURCHASES'
const REQUEST_EDIT_PURCHASES = 'purchases/REQUEST_EDIT_PURCHASES'
const REQUEST_DELETE_PURCHASES = 'purchases/REQUEST_DELETE_PURCHASES'
const REQUEST_UPDATE_PURCHASES = 'purchases/REQUEST_UPDATE_PURCHASES'

//statistics
const REQUEST_APPS_FLYER = 'statistics/REQUEST_APPS_FLYER'
const REQUEST_ROYAL_USERS_INSTALL_IOS = 'statistics/REQUEST_ROYAL_USERS_INSTALL_IOS'
const REQUEST_APPS_FLYER_IOS = 'statistics/REQUEST_APPS_FLYER_IOS'
const REQUEST_ROYAL_USERS_INSTALL = 'statistics/REQUEST_ROYAL_USERS_INSTALL'
const REQUEST_USERS_ACTIVITY = 'statistics/REQUEST_USERS_ACTIVITY'
const REQUEST_PURCHASES_POINTS = 'statistics/REQUEST_PURCHASES_POINTS'
const REQUEST_USERS_COUNT = 'statistics/REQUEST_USERS_COUNT'
const REQUEST_USERS_ONLINE_BY_CITY = 'statistics/REQUEST_USERS_ONLINE_BY_CITY'
const REQUEST_PURCHASES_MARKET_PLACES = 'statistics/REQUEST_PURCHASES_MARKET_PLACES'
const REQUEST_PURCHASES_BY_CITY = 'statistics/REQUEST_PURCHASES_BY_CITY'
const REQUEST_PURCHASES_BY_COUNTRY = 'statistics/REQUEST_PURCHASES_BY_COUNTRY'
const REQUEST_PURCHASES_BY_TIME = 'statistics/REQUEST_PURCHASES_BY_TIME'
const REQUEST_PURCHASES_BY_PAYERS_PAYMENTS = 'statistics/REQUEST_PURCHASES_BY_PAYERS_PAYMENTS'
const REQUEST_USERS_ONLINE_ONE_DAY = 'statistics/REQUEST_USERS_ONLINE_ONE_DAY'
const REQUEST_USERS_ONLINE_ONE_DAY_SITE_AND_APP = 'statistics/REQUEST_USERS_ONLINE_ONE_DAY_SITE_AND_APP'
const REQUEST_NEW_USER_ACTIVE_BY_DATE = 'statistics/REQUEST_NEW_USER_ACTIVE_BY_DATE'
const REQUEST_MODERATORS_PHOTO_CHECK = 'statistics/REQUEST_MODERATORS_PHOTO_CHECK'
const REQUEST_PURCHASE_TOTAL = 'statistics/REQUEST_PURCHASE_TOTAL'
const REQUEST_USERS_GENDER = 'statistics/REQUEST_USERS_GENDER'
const REQUEST_ACTIVE_NEW_USER = 'statistics/REQUEST_ACTIVE_NEW_USER'
const REQUEST_COUNTERS_MESSAGES_OPERATORS = 'statistics/REQUEST_COUNTERS_MESSAGES_OPERATORS'
const REQUEST_STATISTICS_PROFILE_MESSAGES = 'statistics/REQUEST_STATISTICS_PROFILE_MESSAGES'
const REQUEST_STATISTICS_PROFILE_MESSAGES_PAGINATION =
  'statistics/REQUEST_STATISTICS_PROFILE_MESSAGES_PAGINATION'
const REQUEST_USERS_PAYING = 'statistics/REQUEST_USERS_PAYING'
const REQUEST_USERS_AGE = 'statistics/REQUEST_USERS_AGE'
const REQUEST_USER_LIFETIME = 'statistics/REQUEST_USER_LIFETIME'
const REQUSET_REGS_NEW_USERS = 'statistics/REQUSET_REGS_NEW_USERS'
const REQUEST_OPERATORS_MESSAGE = 'statistics/REQUEST_OPERATORS_MESSAGE'
const REQUEST_OPERATORS_CASHES = 'statistics/REQUEST_OPERATORS_CASHES'
const REQUEST_MODARATION_VIDEO = 'statistics/REQUEST_MODARATION_VIDEO'
const REQUEST_PURCHASES_VIP_STATUS = 'statistics/REQUEST_PURCHASES_VIP_STATUS'
const REQUEST_PURCHASES_BY_DATE = 'statistics/REQUEST_PURCHASES_BY_DATE'
const REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS =
  'statistics/REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS'
const REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS_PAGINATION =
  'statistics/REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS_PAGINATION'
const REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS = 'statistics/REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS'
const REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS_PAGINATION =
  'statistics/REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS_PAGINATION'
const REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES = 'statistics/REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES'
const REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES_PAGINATION =
  'statistics/REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES_PAGINATION'
const REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD = 'statistics/REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD'
const REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD_PAGINATION =
  'statistics/REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD_PAGINATION'
//User claims
const REQUEST_ALL_USERS_CLAIMS = 'userclaims/REQUEST_ALL_USERS_CLAIMS'
const REQUSET_ADD_NEW_CLAIM = 'userclaims/REQUSET_ADD_NEW_CLAIM'
const REQUSET_DEL_CLAIM = 'userclaims/REQUSET_DEL_CLAIM'
const REQUEST_EDIT_CLAIM = 'userclaims/REQUEST_EDIT_CLAIM'

// USER STATISTIC
const REQUEST_USER_STATISTIC_ADVANCED = 'userStatistic/REQUEST_USER_STATISTIC_ADVANCED'
const REQUEST_STATISTIC_USER_BY_DATE = 'userStatistic/REQUEST_STATISTIC_USER_BY_DATE'
const REQUEST_STATISTIC_USER_BY_DATE_PAGINATION = 'userStatistic/REQUEST_STATISTIC_USER_BY_DATE_PAGINATION'

export {
  REQUEST_AUTH,
  REQUEST_PROFILE_ID,
  REQUEST_LOGOUT,
  REQUEST_REFRESH_TOKEN,
  REQUEST_DEPARTMENT,
  REQUEST_PERMISSION_USER,
  //users
  REQUEST_APPLY_FILTERS,
  REQUEST_UPDATE_VIP_CALL,
  REQUEST_ID_USER,
  REQUEST_USER_VERIFICATION,
  REQUEST_GET_VERIFY_AGE,
  REQUEST_BLOCK_MAIN_PHOTO,
  REQUEST_UPDATE_VIP,
  REQUEST_BAN_USER,
  REQUEST_DELETE_USER,
  REQUEST_UPDATE_POINTS,
  REQUEST_HIDE_USER_APP,
  REQUEST_HIDE_USER_ADMIN,
  REQUEST_UPDATE_RATING,
  REQUEST_UPDATE_GENDER,
  REQUEST_USER_DEPOSIT,
  REQUEST_USER_PAYOUTS,
  REQUEST_USER_TRANSACTION_KIND,
  REQUEST_USER_PHOTOS,
  REQUEST_USER_CHATS,
  REQUEST_CHAT_MESSAGES,
  REQUEST_CHAT_OLD_MESSAGES,
  REQUEST_DELETE_VIP,
  REQUEST_DELETE_PHOTO,
  REQUEST_FILTER_UTM,
  REQUEST_VISIBLE_MAP_USER,
  REQUEST_ALL_USERS,
  REQUEST_USER_PURCHASES,
  REQUEST_UPDATE_USER_PHOTO_STATUS,
  REQUEST_SEND_ALERT_TO_USER,
  //user claims
  REQUEST_ALL_USERS_CLAIMS,
  REQUSET_ADD_NEW_CLAIM,
  REQUEST_EDIT_CLAIM,
  REQUSET_DEL_CLAIM,
  //view mobile
  REQUEST_VIEWS,
  REQUEST_CREATE_VIEW,
  REQUEST_DELETE_VIEW_RECORD,
  //events
  REQUEST_ADD_CATEGORY_EVENT,
  REQUEST_UPDATE_CATEGORY_EVENT,
  REQUEST_DELETE_CATEGORY_EVENT,
  REQUEST_ADD_SUBCATEGORY_EVENT,
  REQUEST_UPDATE_SUBCATEGORY_EVENT,
  REQUEST_DELETE_SUBCATEGORY_EVENT,
  REQUEST_ALL_CATEGORIES,
  REQUEST_ALL_EVENT_REFBOOK,
  REQUEST_ADD_EVENT_REFBOOK,
  REQUEST_UPDATE_EVENT_REFBOOK,
  REQUEST_DELETE_EVENT_REFBOOK,
  REQUEST_EVENTS,
  REQUEST_ALL_EVENTS,
  REQUEST_EVENT_BY_ID,
  REQUEST_DELETE_EVENT,
  REQUEST_PAGE_USERS,
  // Languages
  REQUEST_ALL_LANGUAGES,
  REQUEST_DELETED_LANGUAGES,
  REQUEST_CREATE_NEW_LANGUAGES,
  // FAQ
  REQUEST_ALL_CATEGORY_BY_LANG,
  REQUEST_CONTENT_BY_ID,
  REQUEST_CREATE_NEW_CATEGORY,
  REQUEST_DELETE_CATEGORY_BY_ID,
  REQUEST_DATELE_QUESTION_BY_ID,
  REQUEST_UPDATE_QUESTION_BY_ID,
  REQUEST_UPDATE_CATEGORY_BY_ID,
  REQUEST_ADD_NEW_CONTENT_BY_CATEGORY,
  //control employee
  REQUEST_EMPLOYEE,
  REQUEST_ALL_EMPLOYEE,
  REQUEST_ALL_PERMISISONS,
  REQUEST_EMPLOYEE_FILTER,
  REQUEST_ALL_POSITIONS,
  REQUEST_DELETE_EMPLOYEE,
  REQUEST_EDIT_EMPLOYEE,
  REQUEST_CREATE_EMPLOYEE,
  REQUEST_CHECK_EMAIL,
  //control departments
  REQUEST_CONTROL_DEPARTMENTS,
  REQUEST_CREATE_DEPARTMENT,
  REQUEST_DEPARTMENTS_BY_FILTER,
  REQUEST_DELETE_DEPARTMENT,
  REQUEST_UPDATE_DEPARTMENT,
  //control permissions
  REQUEST_CONTROL_PERMISSIONS,
  REQUEST_CREATE_PERMISSION,
  REQUEST_DELETE_PERMISSION,
  REQUEST_UPDATE_PERMISSION,
  REQUEST_PERMISSIONS_GROUP,
  //control positions
  REQUEST_CONTROL_POSITIONS,
  REQUEST_CREATE_POSITION,
  REQUEST_DELETE_POSITION,
  REQUEST_UPDATE_POSITION,
  //achievements
  REQUEST_ACHIEVEMENTS,
  REQUEST_EDIT_ACHIEVEMENTS,
  REQUEST_DELETE_ACHIEVEMENT,
  REQUEST_UPDATE_ACHIEVEMENTS,
  //cause points
  REQUEST_CAUSE_POINTS,
  REQUEST_ADD_CAUSE_POINTS,
  REQUEST_DELETE_CAUSE_POINTS,
  REQUEST_UPDATE_CAUSE_POINTS,
  // Gifts
  REQUEST_GET_ALL_GIFTS,
  REQUEST_DELETE_GIFTS_BY_ID,
  REQUEST_CREATE_NEW_GIFTS,
  REQUEST_UPDATE_GIFTS_BY_ID,
  // Gifts Events
  REQUEST_ALL_GIFTS_EVENTS,
  REQUEST_NEW_GIFTS_EVENTS,
  REQUEST_DELETE_GIFTS_EVENTS,
  REQUEST_UPDATE_GIFTS_EVENTS,
  REQUEST_UPDATE_PERCENT_GIFTS_EVENTS,
  //Reason Delete
  REQUEST_ADD_REASON_DELETE,
  REQUEST_UPDATE_REASON_DELETE,
  REQUEST_DELETE_REASON_DELETE,
  REQUEST_REASON_DELETE,
  //Reason Block
  REQUEST_ADD_REASON_BLOCK,
  REQUEST_UPDATE_REASON_BLOCK,
  REQUEST_DELETE_REASON_BLOCK,
  REQUEST_REASON_BLOCK,
  // Support Contacts types
  REQUEST_ADD_SUPPORT_CONTACT_TYPES,
  REQUEST_DELETE_SUPPORT_CONTACT_TYPES,
  REQUEST_EDIT_CONTACT_TYPES,
  REQUEST_SUPPORT_CONTACT_TYPES,
  //Market Utm
  REQUEST_ADD_MARKET_UTM,
  REQUEST_UPDATE_MARKET_UTM,
  REQUEST_DELETE_MARKET_UTM,
  REQUEST_MARKET_UTM,
  //Push
  REQUEST_PUSHES,
  REQUEST_ADD_PUSHES,
  REQUEST_UPDATE_PUSHES,
  REQUEST_DELETE_PUSHES,
  //Auto messages
  REQUEST_ADD_AUTO_MESSAGES,
  REQUEST_DELETE_AUTO_MESSAGES,
  REQUEST_AUTO_MESSAGES_PAGINATION,
  REQUEST_CHANGE_AUTO_MESSAGES,
  REQUEST_AUTO_MESSAGES,
  //AutoMessagesList
  REQUEST_AUTO_MESSAGES_LIST,
  REQUEST_AUTO_MESSAGES_LIST_APPLY_FILTERS,
  //operators
  REQUEST_ALL_OPERATORS,
  REQUEST_DELETE_OPERATORS,
  REQUEST_DELETE_OPERATOR_USER,
  REQUEST_EDIT_OPERATORS,
  REQUEST_CHANGE_WEIGHT_SENDING,
  REQUEST_TRANSFER_OPERATOR,
  REQUEST_CHANGE_TYPE_MESSAGE,
  REQUEST_CREATE_OPERATORS,
  REQUEST_FIND_USERS,
  REQUEST_CHANGE_COUNTRY_OPERATOR,
  REQUEST_OPERATOR_STATISTIC,
  REQUEST_OPERATOR_PAYOUT,
  REQUEST_BILLING_OPERATORS,
  REQUEST_PRIZE_OPERATORS,
  REQUEST_EXECUTE_OPERATORS,
  REQUEST_EDIT_OPERATORS_SEARCH_OPTIONS,
  REQUEST_EDIT_OPERATOR_AGE,
  REQUEST_EDIT_OPERATOR_NAME,
  REQUEST_EDIT_OPERATOR_SEARCH,
  REQUEST_EDIT_OPERATORS_AUTO_MESSAGE,
  REQUEST_OPERATORS_BANNER,
  REQUEST_EDIT_OPERATORS_BANNER,
  REQUEST_DELETE_OPERATORS_BANNER,
  REQUEST_CHANGE_OPERATORS_BANNER,
  REQUEST_OPERATORS_AUTO_MESSAGE,
  REQUEST_EDIT_AUTO_MESSAGE_DELAY,
  REQUEST_CHANGE_OPERATORS_AUTO_MESSAGE,
  REQUEST_DELETE_OPERATORS_AUTO_MESSAGE,
  REQUEST_EDIT_LOCATION_SETTING,
  REQUEST_GET_OPERATOR_INFO_ON_SHIFT,
  REQUEST_FILTERS_OPERATORS,
  //calendar
  REQUEST_SHIFT_OPTIONS,
  REQUEST_CALENDAR_EVENTS,
  REQUEST_ADD_CALENDAR_EVENT,
  REQUEST_DELL_MODERATOR_EVENT,
  REQUEST_ADD_MODERATOR_EVENT,
  REQUEST_NEW_SHIFT_OPTIONS,
  REQUEST_CHANGE_OPERATOR_EVENT,
  REQUEST_MOD_OPERATOR_EVENTS,
  //payments setting
  REQUEST_PAYMENTS_SETTING,
  REQUEST_DELETE_PAYMENTS_SETTING,
  REQUEST_EDIT_PAYMENTS_SETTING,
  REQUEST_CREATE_PAYMENTS_SETTING,
  REQUEST_EDIT_DEFAULT_SETTING,
  REQUEST_CREATE_DEFAULT_SETTING,
  //moderator
  REQUEST_MODERATOR_PAYMENTS_SETTING,
  REQUEST_MODERATOR_EDIT_PAYMENTS_SETTING,
  REQUEST_MODERATOR_CREATE_PAYMENTS_SETTING,
  REQUEST_ALL_MODERATORS,
  REQUEST_MODERATOR_STATISTIC,
  REQUEST_MODERATOR_PAYOUT,
  REQUEST_BILLING_MODERATORS,
  REQUEST_PRIZE_MODERATORS,
  REQUEST_EXECUTE_MODERATORS,
  REQUEST_DELETE_MODERATOR_PAYMENTS_SETTING,
  REQUEST_MODERATOR_DEL_PAYMENTS_ACTIVE,
  REQUEST_CORRECTION_MODERATORS,
  REQUEST_MODERATOR_SETTINGS_SPEED,
  REQUEST_MODERATOR_SETTINGS_QUALITY,
  REQUEST_UPDATE_MODERATOR_SETTINGS_QUALITY,
  REQUEST_UPDATE_MODERATOR_SETTINGS_SPEED,
  //moderator rating
  REQUEST_MODERATORS_RATING,
  //purchases
  REQUEST_PURCHASES,
  REQUEST_EDIT_PURCHASES,
  REQUEST_DELETE_PURCHASES,
  REQUEST_UPDATE_PURCHASES,
  //statistics
  REQUEST_APPS_FLYER,
  REQUEST_COUNTERS_MESSAGES_OPERATORS,
  REQUEST_STATISTICS_PROFILE_MESSAGES,
  REQUEST_STATISTICS_PROFILE_MESSAGES_PAGINATION,
  REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS,
  REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS_PAGINATION,
  REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS,
  REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS_PAGINATION,
  REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES,
  REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES_PAGINATION,
  REQUEST_APPS_FLYER_IOS,
  REQUEST_ROYAL_USERS_INSTALL,
  REQUEST_ROYAL_USERS_INSTALL_IOS,
  REQUEST_PURCHASES_POINTS,
  REQUEST_USERS_ACTIVITY,
  REQUEST_USERS_COUNT,
  REQUEST_MODARATION_VIDEO,
  REQUEST_USERS_ONLINE_BY_CITY,
  REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD,
  REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD_PAGINATION,
  REQUEST_PURCHASES_MARKET_PLACES,
  REQUEST_PURCHASES_BY_CITY,
  REQUEST_PURCHASES_BY_COUNTRY,
  REQUEST_PURCHASES_BY_TIME,
  REQUEST_PURCHASE_TOTAL,
  REQUEST_PURCHASES_BY_DATE,
  REQUEST_PURCHASES_BY_PAYERS_PAYMENTS,
  REQUEST_USERS_ONLINE_ONE_DAY,
  REQUEST_USERS_ONLINE_ONE_DAY_SITE_AND_APP,
  REQUEST_NEW_USER_ACTIVE_BY_DATE,
  REQUEST_OPERATORS_MESSAGE,
  REQUEST_OPERATORS_CASHES,
  REQUEST_MODERATORS_PHOTO_CHECK,
  REQUEST_USERS_GENDER,
  REQUEST_PURCHASES_VIP_STATUS,
  REQUEST_ACTIVE_NEW_USER,
  REQUEST_USERS_PAYING,
  REQUEST_USERS_AGE,
  REQUEST_USER_LIFETIME,
  REQUSET_REGS_NEW_USERS,
  // USER STATISTIC
  REQUEST_USER_STATISTIC_ADVANCED,
  REQUEST_STATISTIC_USER_BY_DATE,
  REQUEST_STATISTIC_USER_BY_DATE_PAGINATION
}
