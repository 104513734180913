<template>
  <div class="interfaceGift">
    <gifts-header />
    <gifts :allGifts="allGifts" v-if="isLoaded && allGifts.length" />
    <gifts-popup-add />
    <div class="app-no-gift" v-if="!allGifts.length && isLoaded">
      <span class="app-no-gift__text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="app-no-gift">
      <span class="app-no-gift__text">Ошибка при загрузке подарков</span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types'
import store from '@/store'
import { REQUEST_GET_ALL_GIFTS } from '@/store/action-types'
import { GET_ALL_GIFTS } from '@/store/getter-types'

import Gifts from '@/components/Gifts/Gifts'
import GiftsHeader from '../../components/Gifts/GiftsHeader.vue'
import GiftsPopupAdd from '../../components/Modals/GiftsPopup/GiftsPopupAdd.vue'
export default {
  name: 'interfaceGift',

  components: {
    Gifts,
    GiftsHeader,
    GiftsPopupAdd
  },
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_GET_ALL_GIFTS).then(response => {
      next(vm => {
        if (response) {
          vm.setCurrentLang('RU')
        }
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  methods: {
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    })
  },

  computed: {
    ...mapGetters({ allGifts: GET_ALL_GIFTS })
  }
}
</script>

<style lang="scss">
.interfaceGift {
  margin: 0 auto;
  max-width: 1140px;
}

.app-no-gift {
  margin: 0 16px;
  padding: 0 16px 0 16px;
  min-height: 64px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;

  &__text {
    margin: 0 auto;
  }
}
</style>
