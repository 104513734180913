<template>
  <div class="ticket">
    <div class="ticket__field">
      <span class="ticket__mobtitle">Тип пуша</span>
      <span class="ticket__text">{{ push.type_push }}</span>
    </div>
    <div class="ticket__field">
      <span class="ticket__mobtitle">Системный заголовок</span>
      <span class="ticket__text">{{ push.title }}</span>
    </div>
    <div class="ticket__field">
      <span class="ticket__mobtitle">Системное сообщение</span>
      <span class="ticket__text">{{ push.body }}</span>
    </div>
    <div class="ticket__field">
      <span class="ticket__mobtitle">Внутренний заголовок</span>
      <span class="ticket__text">{{ push.data_title }}</span>
    </div>
    <div class="ticket__field">
      <span class="ticket__mobtitle">Внутреннее сообщение</span>
      <span class="ticket__text">{{ push.data_body }}</span>
    </div>
    <div class="ticket__field">
      <span class="ticket__mobtitle">Тип сообщения</span>
      <span class="ticket__text">{{ push.type_msg }}</span>
    </div>
    <div class="ticket__field">
      <button class="ev-cat__item-btn ev-cat__item-btn--blue" @click="$root.$emit(PUSH_UPDATE_POPUP, push)">
        <i class="ev-cat__item-btn-icon fa fa-edit"></i>
      </button>
      <button class="ev-cat__item-btn ev-cat__item-btn--red" @click="$root.$emit(PUSH_DELETE_POPUP, push.id)">
        <i class="ev-cat__item-btn-icon fa fa-times-circle"></i>
      </button>
    </div>
  </div>
</template>
<script>
import { PUSH_DELETE_POPUP, PUSH_UPDATE_POPUP } from '@/constants/event'
export default {
  data() {
    return {
      PUSH_DELETE_POPUP,
      PUSH_UPDATE_POPUP
    }
  },
  name: 'Push',
  props: {
    push: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss">
@import './Ticket.scss';
</style>
