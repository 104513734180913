<template>
  <div class="auto-messages__header">
    <span class="auto-messages__title title-big">Автосообщения</span>
    <div class="auto-messages__btns">
      <select class="auto-messages__select" v-model="select" @change="getReasonsByLang(select)">
        <option v-for="lang in languages" :key="lang.value" :value="lang.value">
          {{ lang.name }}
        </option>
      </select>
      <button
        class="filters__btn filters__btn--left"
        title="Назад"
        @click.prevent="newPage(previousLink)"
        :class="{ 'success-btn__disabled': !previousLink }"
        :disabled="!previousLink"
      >
        <i class="fas fa-chevron-left"></i>
      </button>
      <button
        class="filters__btn filters__btn--right"
        title="Вперед"
        @click.prevent="newPage(nextLink)"
        :class="{ 'success-btn__disabled': !nextLink }"
        :disabled="!nextLink"
      >
        <i class="fas fa-chevron-right"></i>
      </button>
      <button class="auto-messages__btn-add" @click="$root.$emit(AUTO_MESSAGE_ADD_POPUP, select, pageNow)">
        <i class="auto-messages__btn-txt fa fa-plus-square"></i>
        <span class="auto-messages__btn-title">Добавить</span>
      </button>
    </div>
  </div>
</template>

<script>
import { AUTO_MESSAGE_ADD_POPUP } from '@/constants/event'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_AUTO_MESSAGES, REQUEST_AUTO_MESSAGES_PAGINATION } from '@/store/action-types'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types.js'
import {
  GET_LANGUAGES,
  GET_CURRENT_LANGUAGES,
  GET_AUTO_MESSAGES_COUNT,
  GET_AUTO_MESSAGES_PAGINATION_NEXT,
  GET_AUTO_MESSAGES_PAGINATION_PREVIOUS,
  GET_AUTO_MESSAGES_PAGE
} from '@/store/getter-types'

export default {
  name: 'AutoMessagesHeader',
  data() {
    return { AUTO_MESSAGE_ADD_POPUP, select: 'RU' }
  },
  methods: {
    ...mapActions({
      messagesByLang: REQUEST_AUTO_MESSAGES,
      updateTickets: REQUEST_AUTO_MESSAGES_PAGINATION
    }),
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    }),
    getReasonsByLang(value) {
      const data = {
        lang: value,
        page: 1
      }
      this.messagesByLang(data)
      this.setCurrentLang(value)
    },
    newPage(link) {
      this.updateTickets(link)
    }
  },
  computed: {
    ...mapGetters({
      languages: GET_LANGUAGES,
      currentLang: GET_CURRENT_LANGUAGES,
      count: GET_AUTO_MESSAGES_COUNT,
      nextLink: GET_AUTO_MESSAGES_PAGINATION_NEXT,
      previousLink: GET_AUTO_MESSAGES_PAGINATION_PREVIOUS,
      pageNow: GET_AUTO_MESSAGES_PAGE
    })
    // selected: {
    //   get() {
    //     return this.currentLang
    //   },
    //   set(newValue) {
    //     this.setCurrentLang(newValue)
    //   }
    // }
  },
  mounted() {
    this.setCurrentLang(this.select)
  }
}
</script>
<style lang="scss">
.filters__btn {
  display: flex;
  padding: 7px 8px 6px 8px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  background-color: #58c9f3;
  &--left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &--right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 10px;
  }
  &:disabled {
    background-color: #e8e8e8;
    border-color: #bec3c7;
    cursor: not-allowed;
    opacity: 0.65;
    box-shadow: none;
    color: #adadad;
  }
}
</style>
