<template>
  <div class="user-active">
    <div class="user-active__menu">
      <div class="user-active__left">
        <span class="user-active__left-text">Дата:</span>
        <div class="datepicker-traffic-source">
          <datepicker
            class="datepicker-online"
            v-model="photos_review_stats_form.date"
            input-class="datepicker__input"
            :language="ru"
          >
          </datepicker>
          <i class="select__field-icon fa fa-angle-down"></i>
        </div>
        <button class="user-active__btn" @click="geModerationPhotosStats">
          Показать
        </button>
      </div>
      <ServerTimeFilter :utcFlag="photos_review_stats_form.utc" @changeUtc="changeUtc($event)" />
    </div>
    <div>
      <div v-if="photos_review_stats_main.datasets.length > 0">
        <h5 class="moderation-stats__title--main" style="color:#3a9215; margin-top: 20px">
          <strong>Главная</strong>
        </h5>
        <BarChart
          :chart-data="photos_review_stats_main"
          :options="photos_review_stats_opt"
          class="regenterstats-chart"
        >
        </BarChart>
      </div>
      <div v-if="photos_review_stats_inner.datasets.length > 0">
        <h5 style="color:#1670a9; margin-top: 20px">
          <strong>Внутренняя</strong>
        </h5>
        <BarChart
          :chart-data="photos_review_stats_inner"
          :options="photos_review_stats_opt"
          class="regenterstats-chart"
        >
        </BarChart>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from './BarChart'
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import { ru } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import 'chartjs-plugin-datalabels'
import { REQUEST_MODERATORS_PHOTO_CHECK } from '@/store/action-types'
import { mapActions } from 'vuex'
import ServerTimeFilter from '@/components/Statistic/Servertimefilter/ServerTimeFilter'
export default {
  name: 'StatisticModeratorsPhotoCheck',
  components: {
    BarChart,
    Datepicker,
    ServerTimeFilter
  },
  props: {
    moderatorsPhotoCheck: {
      type: Object
    },
    moderatorsVideoCheck: {
      type: Object
    }
  },
  data: () => ({
    ru: ru,
    photos_review_stats_main: {
      labels: [],
      datasets: []
    },
    photos_review_stats_inner: {
      labels: [],
      datasets: []
    },
    photos_review_stats_deferred: {
      labels: [],
      datasets: []
    },
    video_review_stats_main: {
      labels: [],
      datasets: []
    },
    video_review_stats_inner: {
      labels: [],
      datasets: []
    },
    video_review_stats_deferred: {
      labels: [],
      datasets: []
    },
    photos_review_stats_opt: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'label',
        intersect: false,
        callbacks: {
          label: function(tooltipItem, data) {
            const tooltip = data.datasets[tooltipItem.datasetIndex]
            const value = tooltip.data[tooltipItem.index]

            return value === 0 ? null : tooltip.label + ': ' + value
          }
        }
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              autoSkip: false,
              callback: function(tick) {
                return /^[0-9]{2}:(00)$/.test(tick.substring(0, tick.length - 3))
                  ? tick.substring(0, tick.length - 3)
                  : ''
              },
              maxRotation: 0
            },
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            barThickness: 'flex'
          }
        ],
        yAxes: [
          {
            stacked: false,
            ticks: {
              maxTicksLimit: 8,
              beginAtZero: true,
              maxRotation: 0
            }
          }
        ]
      },
      plugins: {
        datalabels: {
          display: false
        }
      }
    },
    photos_review_stats_form: {
      date: moment().format('YYYY-MM-DD'),
      main_photo: 0,
      deferred: 0,
      utc: 1
    },
    video_review_stats_form: {
      date: moment().format('YYYY-MM-DD'),
      main_photo: 0,
      deferred: 0,
      utc: 1
    },
    photos_review_stats_loading: false
  }),
  watch: {
    'photos_review_stats_form.utc': function() {
      this.geModerationPhotosStats()
    }
  },
  mounted() {
    this.reLoaded()
    this.setDefaultOnlineDate()
  },
  methods: {
    ...mapActions({
      getModerationPhotos: REQUEST_MODERATORS_PHOTO_CHECK
    }),
    setDefaultOnlineDate: function() {
      this.photos_review_stats_form.date = moment().format('YYYY-MM-DD')
      this.video_review_stats_form.date = moment().format('YYYY-MM-DD')
    },
    geModerationPhotosStats() {
      let newData = {
        date_from: moment(this.photos_review_stats_form.date).format('YYYY-MM-DD')
      }
      if (this.photos_review_stats_form.utc) newData.delta = new Date().getTimezoneOffset() / -60
      this.getModerationPhotos(newData).then(response => {
        if (response.status) {
          this.reLoaded()
        }
      })
    },
    getPhotosReviewStatsColors(index) {
      let colors = [
        '58, 146, 21',
        '148, 67, 164',
        '228, 62, 52',
        '150, 100, 60',
        '182, 114, 0',
        '67, 140, 76',
        '123, 25, 134',
        '185, 129, 72',
        '158, 146, 21',
        '248, 67, 164',
        '228, 162, 52',
        '149, 137, 146',
        '182, 114, 110',
        '167, 140, 176',
        '123, 125, 34',
        '185, 139, 172',
        '38, 46, 21',
        '98, 97, 164',
        '28, 62, 152',
        '49, 137, 14',
        '82, 114, 50',
        '127, 120, 86',
        '163, 95, 134',
        '125, 29, 22'
      ]
      // eslint-disable-next-line no-prototype-builtins
      if (!colors.hasOwnProperty(index)) {
        colors = colors.slice(1)
        index = Math.ceil(index % colors.length)
      }

      return colors[index]
    },
    getPhotosReviewStatsAdminName(admin_id, admins = {}) {
      // eslint-disable-next-line no-prototype-builtins
      if (admins.hasOwnProperty(admin_id)) {
        return admins[admin_id]
      }
      return admin_id
    },
    reLoaded() {
      this.photos_review_stats_main.labels = []
      this.photos_review_stats_main.datasets = []
      this.photos_review_stats_inner.labels = []
      this.photos_review_stats_inner.datasets = []
      this.photos_review_stats_deferred.labels = []
      this.photos_review_stats_deferred.datasets = []
      this.video_review_stats_main.labels = []
      this.video_review_stats_main.datasets = []
      this.video_review_stats_deferred.labels = []
      this.video_review_stats_deferred.datasets = []
      this.video_review_stats_inner.labels = []
      this.video_review_stats_inner.datasets = []

      for (let index in this.moderatorsPhotoCheck) {
        const labels = this.moderatorsPhotoCheck[index].labels
        const period = this.moderatorsPhotoCheck[index].data
        let datasets = []
        let stats = {}

        if (index === 'main') {
          stats = this.photos_review_stats_main
        } else if (index === 'inner') {
          stats = this.photos_review_stats_inner
        } else if (index === 'deferred') {
          stats = this.photos_review_stats_deferred
        } else {
          return false
        }
        for (let key in period) {
          // eslint-disable-next-line no-prototype-builtins
          if (period.hasOwnProperty(key)) {
            stats.labels.push(key)

            for (let adm in period[key]) {
              // eslint-disable-next-line no-prototype-builtins
              if (period[key].hasOwnProperty(adm)) {
                if (!labels.find(f => f === adm)) {
                  continue
                }
                let data_idx = datasets.indexOf(adm)
                if (data_idx === -1) {
                  data_idx = datasets.push(adm) - 1
                }
                // eslint-disable-next-line no-prototype-builtins
                if (!stats.datasets.hasOwnProperty(data_idx)) {
                  let dataset = {}
                  if (adm === 'Не проверенно') {
                    dataset = {
                      borderColor: `rgba(151,187,205,1)`,
                      backgroundColor: `rgba(151,187,205,0.5)`,
                      label: this.getPhotosReviewStatsAdminName(adm, labels),
                      data: []
                    }
                  } else {
                    dataset = {
                      borderColor: `rgba(${this.getPhotosReviewStatsColors(data_idx)},1)`,
                      backgroundColor: `rgba(${this.getPhotosReviewStatsColors(data_idx)},0.5)`,
                      label: this.getPhotosReviewStatsAdminName(adm, labels),
                      data: []
                    }
                  }

                  stats.datasets[data_idx] = dataset
                }
                stats.datasets[data_idx].data.push(period[key][adm])
              }
            }
          }
        }
      }

      for (let index in this.moderatorsVideoCheck) {
        const labels = this.moderatorsVideoCheck[index].labels
        const period = this.moderatorsVideoCheck[index].data
        let datasets = []
        let stats = {}

        if (index === 'main') {
          stats = this.video_review_stats_main
        } else if (index === 'inner') {
          stats = this.video_review_stats_inner
        } else if (index === 'deferred') {
          stats = this.video_review_stats_deferred
        } else {
          return false
        }
        for (let key in period) {
          // eslint-disable-next-line no-prototype-builtins
          if (period.hasOwnProperty(key)) {
            stats.labels.push(key)

            for (let adm in period[key]) {
              // eslint-disable-next-line no-prototype-builtins
              if (period[key].hasOwnProperty(adm)) {
                if (!labels.find(f => f === adm)) {
                  continue
                }
                let data_idx = datasets.indexOf(adm)
                if (data_idx === -1) {
                  data_idx = datasets.push(adm) - 1
                }
                // eslint-disable-next-line no-prototype-builtins
                if (!stats.datasets.hasOwnProperty(data_idx)) {
                  let dataset = {}
                  if (adm === 'Не проверенно') {
                    dataset = {
                      borderColor: `rgba(151,187,205,1)`,
                      backgroundColor: `rgba(151,187,205,0.5)`,
                      label: this.getPhotosReviewStatsAdminName(adm, labels),
                      data: []
                    }
                  } else {
                    dataset = {
                      borderColor: `rgba(${this.getPhotosReviewStatsColors(data_idx)},1)`,
                      backgroundColor: `rgba(${this.getPhotosReviewStatsColors(data_idx)},0.5)`,
                      label: this.getPhotosReviewStatsAdminName(adm, labels),
                      data: []
                    }
                  }

                  stats.datasets[data_idx] = dataset
                }
                stats.datasets[data_idx].data.push(period[key][adm])
              }
            }
          }
        }
      }
    },
    changeUtc(number) {
      this.photos_review_stats_form.utc = number
    }
  }
}
</script>

<style lang="scss">
@import './Statistic.scss';
</style>
