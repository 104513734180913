<template>
  <Popup :visible="visible" @close="closePopup" :description="title" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="verifyHandler('approve')">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_VERIFY_EVENT } from '@/constants/event'
import { USER_DECLINE_BY_AGE } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_USER_VERIFICATION } from '@/store/action-types'

export default {
  name: 'UserVerifyPopup',
  components: {
    Popup
  },
  data() {
    return {
      id: '',
      userVerification: null
    }
  },
  mixins: [ModalMixin],
  computed: {
    title() {
      if (this.userVerification !== 2) {
        return 'Верифицировать пользователя?'
      } else {
        return 'Снять верификацию пользователя?'
      }
    }
  },

  methods: {
    ...mapActions({
      verifyUser: REQUEST_USER_VERIFICATION
    }),
    closePopup() {
      this.id = ''
      this.userVerification = false
      this.close()
    },
    verifyHandler(method) {
      let payload
      payload = {
        user_id: this.id,
        verification: this.userVerification !== 2 ? true : false
      }
      if (this.userVerification !== 2) {
        this.verifyUser(payload)
      } else {
        this.$root.$emit(USER_DECLINE_BY_AGE, payload)
      }
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(USER_VERIFY_EVENT, user => {
      this.userVerification = user.verification
      this.id = user.id
      this.open()
    })
  }
}
</script>
