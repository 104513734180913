<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    title=""
    description="Имя подкатегории"
    modalClass="popup--small"
  >
    <form @submit.prevent="sendData">
      <ul class="popup__list" v-if="currentCategory.length">
        <li class="popup__list-item" v-for="(lan, i) in langs" :key="i">
          <span>{{ lan.value }}</span>
          <input type="text" class="popup__input default-input" v-model="currentCategory[i].title" />
        </li>
      </ul>
      <div v-else>Ошибка загрузки названий</div>
      <div class="popup__item">
        <span class="toggle__desc">Свое событие</span>
        <label class="toggle">
          <input class="toggle__checkbox" type="checkbox" v-model="my_type" />
          <span class="toggle__toggler"></span>
        </label>
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Изменить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EVENT_SUBCATEGORY_NAME_POPUP } from '@/constants/event'
import { mapActions, mapGetters } from 'vuex'
import { GET_LANGUAGES, GET_MERGED_CATEGORIES } from '@/store/getter-types'
import { REQUEST_UPDATE_SUBCATEGORY_EVENT } from '@/store/action-types'

export default {
  name: 'EventCategoryPopup',
  data() {
    return {
      id: '',
      category: '',
      my_type: false,
      old_type: false,
      currentCategory: [],
      locale: []
    }
  },
  components: {
    Popup
  },
  mixins: [ModalMixin],
  computed: {
    ...mapGetters({
      langs: GET_LANGUAGES,
      categories: GET_MERGED_CATEGORIES
    })
  },
  mounted() {
    this.$root.$on(EVENT_SUBCATEGORY_NAME_POPUP, eventcat => {
      this.id = eventcat.id
      this.categories.map(m => {
        let catId = m.subcategories.find(f => f.id === this.id)
        if (catId) {
          this.category = m.id
          this.locale = catId.locale
        }
      })
      this.old_type = eventcat.my_type
      this.my_type = eventcat.my_type
      this.currentCategory = []
      this.langs.map(m => {
        let titleEvent = this.locale.find(f => f.lang === m.value)
        this.currentCategory.push({
          lang: m.value,
          title: titleEvent ? titleEvent.title : ''
        })
      })
      this.open()
    })
  },
  methods: {
    ...mapActions({
      updateName: REQUEST_UPDATE_SUBCATEGORY_EVENT
    }),
    closePopup() {
      this.id = ''
      this.my_type = false
      this.old_type = false
      this.locale = []
      this.close()
    },
    sendData() {
      let data = {}
      const formData = new FormData()
      if (this.my_type !== this.old_type) {
        data.my_type = this.my_type
      }
      data.locale = this.currentCategory.filter(f => f.title !== '')
      formData.append('data', JSON.stringify(data))
      console.log(this.id)
      this.updateName({ data: formData, id: this.id })
      this.closePopup()
    }
  }
}
</script>

<style lang="scss"></style>
