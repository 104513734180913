<template>
  <div>
    <div class="ev-cat__item">
      <div class="ev-cat__item-body">
        <div class="ev-cat__item-content">
          <div class="ev-cat__item-name">
            <span class="ev-cat__item-title">Показывать</span>
          </div>
          <div class="ev-cat__item-btns" v-if="eventref.available_for.length">
            <button
              class="ev-cat__item-btn ev-cat__item-btn--blue"
              @click="
                $root.$emit(EVENT_REFBOOK_UPDATE_POPUP, {
                  values: [...eventref.available_for],
                  array: 'available_for',
                  id: eventref.id,
                  lang: eventref.lang
                })
              "
            >
              <i class="ev-cat__item-btn-icon far fa-keyboard"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="ev-cat__item-sublist" v-if="eventref.available_for.length">
        <EventRefBookItem
          v-for="eventref in eventref.available_for"
          :key="eventref.key"
          :eventref="eventref"
        />
      </div>
      <div class="app-no-events" v-if="!eventref.available_for.length">
        <span class="app-no-events__text">Нет данных...</span>
      </div>
    </div>
    <div class="ev-cat__item">
      <div class="ev-cat__item-body">
        <div class="ev-cat__item-content">
          <div class="ev-cat__item-name">
            <span class="ev-cat__item-title">Сколько приглашенных</span>
          </div>
          <div class="ev-cat__item-btns" v-if="eventref.people_amount.length">
            <button
              class="ev-cat__item-btn ev-cat__item-btn--blue"
              @click="
                $root.$emit(EVENT_REFBOOK_UPDATE_POPUP, {
                  values: [...eventref.people_amount],
                  array: 'people_amount',
                  id: eventref.id,
                  lang: eventref.lang
                })
              "
            >
              <i class="ev-cat__item-btn-icon far fa-keyboard"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="ev-cat__item-sublist" v-if="eventref.people_amount.length">
        <EventRefBookItem
          v-for="eventref in eventref.people_amount"
          :key="eventref.key"
          :eventref="eventref"
        />
      </div>
      <div class="app-no-events" v-if="!eventref.people_amount.length">
        <span class="app-no-events__text">Нет данных...</span>
      </div>
    </div>
    <div class="ev-cat__item">
      <div class="ev-cat__item-body">
        <div class="ev-cat__item-content">
          <div class="ev-cat__item-name">
            <span class="ev-cat__item-title">Кто платит</span>
          </div>
          <div class="ev-cat__item-btns" v-if="eventref.people_amount.length">
            <button
              class="ev-cat__item-btn ev-cat__item-btn--blue"
              @click="
                $root.$emit(EVENT_REFBOOK_UPDATE_POPUP, {
                  values: [...eventref.who_pay],
                  array: 'who_pay',
                  id: eventref.id,
                  lang: eventref.lang
                })
              "
            >
              <i class="ev-cat__item-btn-icon far fa-keyboard"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="ev-cat__item-sublist" v-if="eventref.people_amount.length">
        <EventRefBookItem v-for="eventref in eventref.who_pay" :key="eventref.key" :eventref="eventref" />
      </div>
      <div class="app-no-events" v-if="!eventref.who_pay.length">
        <span class="app-no-events__text">Нет данных...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { EVENT_REFBOOK_UPDATE_POPUP } from '@/constants/event'
import EventRefBookItem from '@/components/Events/EventRefBookItem'
export default {
  data() {
    return {
      EVENT_REFBOOK_UPDATE_POPUP
    }
  },
  components: {
    EventRefBookItem
  },
  name: 'EventRefBook',
  props: {
    eventref: {
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.ev-cat__item-sublist {
  .ev-cat__item-sublist-select {
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    select {
      margin-left: 10px;
    }
  }
}
.ev-cat__item-title {
  font-weight: 600;
}
</style>
