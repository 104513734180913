<template>
  <ul class="sidebar__list">
    <ListItem
      v-for="item in list"
      :key="item.id"
      :item="item"
      :lvl="lvl"
      :height="getH"
      :class="{ 'sidebar__item--has-list': item.list }"
    >
      <template v-slot:list="{ list }">
        <MenuList class="sidebar__list-hidden" v-if="list" :list="list" :lvl="lvl + 1" />
      </template>
    </ListItem>
  </ul>
</template>

<script>
import ListItem from './ListItem.vue'
export default {
  name: 'MenuList',
  components: {
    ListItem
  },
  props: {
    list: {
      type: Array
    },
    lvl: {
      type: Number
    },
    counters: {
      type: Array
    }
  },
  computed: {
    getH: () => {
      return window.innerHeight - 1050 > 0 ? true : false
    }
  }
}
</script>
