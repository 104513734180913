import * as GetterTypes from './getter-types'

export default {
  [GetterTypes.GET_AUTO_MESSAGES_LIST]: ({ autoMessagesList }) => autoMessagesList,
  [GetterTypes.GET_MESSAGES_COUNT]: ({ messagesCount }) => messagesCount,
  [GetterTypes.GET_FILTERS_INFO_AUTO_MESSAGE]: ({ filtersUserInfo }) => filtersUserInfo,

  [GetterTypes.GET_FILTERS_COUNT]: ({ filterCount }) => filterCount,

  [GetterTypes.GET_FILTERS_DEFAULT_AUTO_MESSAGE]: ({ filterUsersDefault }) => filterUsersDefault,

  [GetterTypes.GET_TMP_FILERS_AUTO_MESSAGE]: ({ tmpUserFilters }) => tmpUserFilters,

  [GetterTypes.GET_CURRENT_FILERS_AUTO_MESSAGE]: ({ currentFilter }) => currentFilter,

  [GetterTypes.GET_USER_GENDER_AUTO_MESSAGE]: ({ userGender }) => value => {
    if (value !== undefined && value !== null) return userGender.find(item => item.value === value)
    else return ''
  },
  [GetterTypes.GET_FILTER_PAGE_AUTO_MESSAGE]: ({ searchFilters }) => searchFilters
}
