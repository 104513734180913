<template>
  <Popup :visible="visible" @close="closeModal" description="Рейтинг событий" modalClass="popup--small">
    <form @submit.prevent="addRatingHandler">
      <div class="popup__item">
        <span class="popup__item-title">Изменить рейтинг событий</span>
        <input
          type="text"
          class="popup__input default-input"
          ref="ratingvalue"
          v-model="ratingValue"
          v-tooltip="'Введите рейтинг от 1 до 10'"
        />
        <span v-if="error" class="popup__item-title">Введите рейтинг от 1 до 10</span>
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Добавить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_EVENTS_RATING_EVENT } from '@/constants/event'

export default {
  name: 'UserEventsRatingPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  props: {
    currentUser: {
      type: Object
    }
  },

  data: () => ({
    rating: '',
    error: false
  }),

  computed: {
    ratingValue: {
      get() {
        return this.rating
      },

      set(n) {
        n = n.replace(/[^0-9.]/g, '')
        this.$refs.ratingvalue.value = n
        this.rating = n
      }
    }
  },

  methods: {
    closeModal() {
      this.rating = ''
      this.error = false
      this.close()
    },
    addRatingHandler() {
      this.error = false
      const ratingDataUser = {
        rating: Number(this.rating)
      }

      if (ratingDataUser.rating < 1) {
        this.error = true
        this.$refs.ratingvalue.classList.value = this.$refs.ratingvalue.classList.value + ' popup__warning'
        return
      }
      if (ratingDataUser.rating > 10) {
        this.$refs.ratingvalue.classList.value = this.$refs.ratingvalue.classList.value + ' popup__warning'
        this.error = true
        return
      }

      this.rating = ''
      this.close()
    }
  },

  mounted() {
    this.$root.$on(USER_EVENTS_RATING_EVENT, () => this.open())
  }
}
</script>

<style lang="scss">
.popup__warning {
  border-color: red;
}
</style>
