var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'app-user-photo'},[_c('div',{staticClass:"app-user-photo__img"},[_c('div',{staticClass:"app-user-photo__badge-block-top"},[_c('div',{staticClass:"app-user-photo__badge",class:{
          postponed: _vm.photo.status === -1,
          verification: _vm.photo.status === 1,
          'forbidden-avatar': _vm.photo.status === 2,
          'forbidden-show': _vm.photo.status === 3,
          'deferred-block': _vm.photo.status === 4,
          'status-age': _vm.photo.status === 6
        }}),_c('div',{staticClass:"app-user-photo__badge-icon"},[(_vm.photo.status !== 0)?_c('FaIcon',{staticClass:"app-user-profile__gender-icon",attrs:{"iconClass":[
            _vm.photo.status === -1 ? 'fa fa-clock' : '',
            _vm.photo.status === 1 ? 'fa fa-user-check' : '',
            _vm.photo.status === 2 ? 'fa fa-user-times' : '',
            _vm.photo.status === 3 ? 'fa fa-user-slash' : '',
            _vm.photo.status === 4 ? 'fa fa-exclamation-triangle' : '',
            _vm.photo.status === 6 ? 'fa-solid fa-baby' : ''
          ]}}):_vm._e()],1)]),(_vm.photo.like_count)?_c('div',{staticClass:"app-user-photo__badge-block-bottom"},[_c('div',{staticClass:"app-user-photo__badge"}),_c('div',{staticClass:"app-user-photo__badge-icon"},[_c('i',{staticClass:"app-user-photo__badge-icon-i fa fa-heart"}),_c('span',{staticClass:"app-user-photo__badge-text"},[_vm._v(_vm._s(_vm.photo.like_count))])])]):_vm._e(),_c('img',{ref:"userImg",attrs:{"src":_vm.getFile({
          type: 'image',
          file: _vm.photo.content_id,
          width: "100%",
          height: "100%"
        }),"alt":""},on:{"click":function($event){return _vm.resizeImg()}}})]),_c('div',{staticClass:"app-user-photo__description"},[_c('div',{staticClass:"app-user-photo__employee"},[(_vm.photo.updated_at)?_c('span',{staticClass:"app-user-photo__moderator"},[_vm._v(_vm._s(new Date(_vm.photo.updated_at).toLocaleString('ru-RU')))]):_vm._e(),(_vm.photo.employee)?_c('span',{staticClass:"app-user-photo__moderator"},[(_vm.photo.employee.type === 'moderator')?_c('span',{staticClass:"app-user-photo__moderator--moderation"},[_vm._v("Модерация:")]):_vm._e(),(_vm.photo.employee.type === 'support')?_c('span',{staticClass:"app-user-photo__moderator--support"},[_vm._v("Поддержка:")]):_vm._e(),_vm._v(" "+_vm._s(_vm.photo.employee.handler_name)+" ")]):_vm._e()]),(!_vm.photo.is_main_photo)?_c('button',{staticClass:"app-user-photo__btn",on:{"click":function($event){return _vm.$root.$emit(_vm.USER_DELETE_PHOTO, {
          user_id: _vm.currentUser.id,
          photo_id: _vm.photo.id
        })}}},[_c('i',{staticClass:"app-user-photo__badge-icon-i fa fa-trash-alt"})]):_vm._e()]),(_vm.photo.employee.handler_name && _vm.permissions.includes(407))?_c('div',{staticClass:"app-user-photo__actions",class:{ 'app-user-photo__actions--descr-empty': _vm.photo.employee.id === null }},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Одобрить фотографию'),expression:"'Одобрить фотографию'"}],staticClass:"app-user-btns__btn app-user-photo__actions--approve",class:{ 'app-user-photo__actions--active opacity': _vm.photo.status === 1 },attrs:{"disabled":_vm.isCheckDisabled(_vm.photo.status === 1)},on:{"click":function($event){return _vm.$root.$emit(_vm.USER_PHOTO_ACTION_PHOTO_STATUS_EVENT, {
          id: _vm.photo.id,
          status: 1,
          user_id: _vm.currentUser.id,
          text: 'одобрено'
        })}}},[_c('i',{staticClass:"fa fa-user-check"})]),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Запретить для аватара'),expression:"'Запретить для аватара'"}],staticClass:"app-user-btns__btn app-user-photo__actions--avatar",class:{ 'app-user-photo__actions--active opacity': _vm.photo.status === 2 },attrs:{"disabled":_vm.isCheckDisabled(_vm.photo.status === 2)},on:{"click":function($event){return _vm.$root.$emit(_vm.USER_PHOTO_ACTION_PHOTO_STATUS_EVENT, {
          id: _vm.photo.id,
          status: 2,
          user_id: _vm.currentUser.id,
          text: 'запрещено для аватара'
        })}}},[_c('i',{staticClass:"fa fa-user-times"})]),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Запретить для показа'),expression:"'Запретить для показа'"}],staticClass:"app-user-btns__btn app-user-photo__actions--slash",class:{ 'app-user-photo__actions--active opacity': _vm.photo.status === 3 },attrs:{"disabled":_vm.isCheckDisabled(_vm.photo.status === 3)},on:{"click":function($event){return _vm.$root.$emit(_vm.USER_PHOTO_ACTION_PHOTO_STATUS_EVENT, {
          id: _vm.photo.id,
          status: 3,
          user_id: _vm.currentUser.id,
          text: 'запрещено для показа'
        })}}},[_c('i',{staticClass:"far fa-eye-slash"})]),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Временно заблокировать фото'),expression:"'Временно заблокировать фото'"}],staticClass:"app-user-btns__btn app-user-photo__actions--block",class:{ 'app-user-photo__actions--active opacity': _vm.photo.status === 4 },attrs:{"disabled":_vm.isCheckDisabled(_vm.photo.status === 4)},on:{"click":function($event){return _vm.$root.$emit(_vm.USER_PHOTO_ACTION_PHOTO_STATUS_EVENT, {
          id: _vm.photo.id,
          status: 4,
          user_id: _vm.currentUser.id,
          text: 'временная блокировка фото'
        })}}},[_c('i',{staticClass:"fa fa-exclamation-triangle"})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }