// 3.4 Платежный шлюз (PaymentGateway)

export default [
  {
    code: 0,
    name: 'ROYAL_PAY',
    description: 'Royal Pay'
  },
  {
    code: 1,
    name: 'GOOGLE_PAY',
    description: 'Google Pay'
  },
  {
    code: 2,
    name: 'APPLE_PAY',
    description: 'Apple Pay'
  }
]
