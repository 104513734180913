<template>
  <div class="user-active">
    <div class="user-active__menu">
      <div class="user-active__left">
        <span class="user-active__left-text">Дата:</span>
        <div class="datepicker-traffic-source">
          <i class="select__field-icon fa fa-angle-down"></i>
          <DateRangePicker
            :locale-data="picker.localeData"
            :opens="picker.localeData.position"
            :close-on-esc="true"
            :autoApply="true"
            :ranges="false"
            ref="traffic"
            v-model="datePickerControlTraffic"
          >
          </DateRangePicker>
        </div>
        <button class="user-active__btn" @click="getRegEnterStats">
          Показать
        </button>
      </div>
      <div class="user-active__right">
        <ServerTimeFilter :utcFlag="form.utc" @changeUtc="changeUtc($event)" />
        <div class="user-active__btn-group">
          <button
            class="user-active__btn user-active__btn--left user-active__btn--default"
            @click="form.type = 0"
            :class="{ 'user-active__btn--danger': form.type === 0 }"
          >
            14 дн.
          </button>
          <button
            class="user-active__btn user-active__btn--center user-active__btn--default"
            @click="form.type = 1"
            :class="{ 'user-active__btn--danger': form.type === 1 }"
          >
            28 дн.
          </button>
          <button
            class="user-active__btn user-active__btn--right user-active__btn--default"
            @click="form.type = 2"
            :class="{ 'user-active__btn--danger': form.type === 2 }"
          >
            42 дн.
          </button>
        </div>
      </div>
    </div>
    <Bar :chartData="sum_payment_stats" :options="sum_payment_stats_opt" class="regenterstats-chart"> </Bar>
  </div>
</template>

<script>
import Bar from './BarChart'
import 'chartjs-plugin-datalabels'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { REQUEST_PURCHASES_MARKET_PLACES } from '@/store/action-types'
import { mapActions } from 'vuex'
import ServerTimeFilter from '@/components/Statistic/Servertimefilter/ServerTimeFilter'
import pickerMixin from '@/components/Picker/pickerMixin'
export default {
  name: 'StatisticPayments',
  components: {
    Bar,
    DateRangePicker,
    ServerTimeFilter
  },
  props: {
    payments: {
      type: Object
    }
  },
  mixins: [pickerMixin],
  data: () => ({
    statsShowLabels: true,
    loading: false,
    form: {
      reg_date: moment().format('YYYY-MM-DD'),
      type: 0,
      utc: 1
    },
    sum_payment_stats: {
      labels: [],
      datasets: [
        {
          borderColor: 'rgba(255,108,96,0)',
          backgroundColor: 'rgba(42,74,255,0.5)',
          fill: true,
          label: 'Google',
          title: 'GOOGLE_PAY',
          data: [],
          datalabels: {
            display: true
          }
        },
        {
          borderColor: 'rgba(151,187,205,0)',
          backgroundColor: 'rgba(255,35,45,0.5)',
          fill: true,
          label: 'iTunes',
          title: 'APPLE_PAY',
          data: [],
          datalabels: {
            display: true
          }
        },
        {
          borderColor: 'rgba(108,202,201,0)',
          backgroundColor: 'rgba(108,202,201,0.5)',
          fill: true,
          label: 'ROYAL PAY',
          title: 'ROYAL_PAY',
          data: [],
          datalabels: {
            display: true
          }
        }
      ],
      date_from: 0,
      date_to: 0,
      currentDay: '',
      delta: 0
    },
    datepicker_dates: {
      date_from: moment()
        .subtract(14, 'days')
        .startOf('day')
        .toString(),
      date_to: moment()
        .endOf('day')
        .toString()
    },
    sum_payment_stats_labels: true,
    sum_payment_stats_opt: {
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              suggestedMax: 10,
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }
        ],
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function(value) {
                return moment(value, 'YYYY-MM-DD').format('DD.MM')
              }
            },
            gridLines: {
              display: false
            }
          }
        ]
      },
      legend: {
        display: true
      },
      tooltips: {
        mode: 'label',
        itemSort: function(a, b) {
          return b.datasetIndex - a.datasetIndex
        },
        enabled: true,
        callbacks: {
          label: function(tooltipItems, data) {
            return data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.yLabel.toFixed(2)
          }
        }
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let datasets = ctx.chart.data.datasets
            let firstIndex = null
            let sum = 0

            for (let [index, value] of Object.entries(datasets)) {
              if (ctx.chart.isDatasetVisible(index)) {
                firstIndex = parseInt(index)

                sum += parseInt(value.data[ctx.dataIndex])
              }
            }

            return ctx.datasetIndex === firstIndex ? sum.toLocaleString() : ''
          },
          padding: {
            top: 0,
            bottom: -10
          },
          anchor: 'end',
          align: 'end'
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      height: 200,
      onClick: (ent, item) => {
        if (item.length != 0) {
          const currentDay = item[0]['_model'].label
          const currentDate = new Date(currentDay).setUTCHours(0, 0, 0)
          const endDate = new Date(currentDay).setUTCHours(23, 59, 59)
          const delta = item[0]['_chart'].config.data.delta
          window.location = `/users/?page=0&purchase_date=${currentDate / 1000},${endDate /
            1000}&delta=${delta}&userDeleted=Неважно`
        }
      }
    }
  }),
  watch: {
    'form.type': function() {
      this.getEnterStatsType()
    },
    'form.utc': function() {
      this.getRegEnterStats()
    }
  },
  computed: {
    datePickerControlTraffic: {
      get() {
        const date = {
          startDate: moment()
            .subtract(14, 'days')
            .startOf('day'),
          endDate: moment().endOf('day')
        }
        return date
      },
      set({ startDate, endDate }) {
        this.datepicker_dates.date_from = new Date(startDate)
        this.datepicker_dates.date_to = new Date(endDate)
      }
    }
  },

  mounted() {
    this.reLoaded()
  },
  methods: {
    ...mapActions({
      getNewPayments: REQUEST_PURCHASES_MARKET_PLACES
    }),
    reLoaded() {
      let labels_color = []
      let labels_format = []
      for (let label of this.payments.labels) {
        let day = moment(label, 'YYYY.MM.DD').format('dd')

        if (day === 'Su' || day === 'Sa') {
          labels_color.push('#ff6c60')
        } else {
          labels_color.push('#666')
        }

        labels_format.push(moment(label, 'YYYY.MM.DD').format('YYYY-MM-DD'))
      }
      this.sum_payment_stats.labels = labels_format
      this.sum_payment_stats_opt.scales.xAxes[0].ticks.fontColor = labels_color
      this.sum_payment_stats.datasets.forEach(f => {
        f.data = this.payments.sum[f.title]
      })
      this.sum_payment_stats.date_from = this.datepicker_dates.date_from
      this.sum_payment_stats.date_to = this.datepicker_dates.date_to
      this.sum_payment_stats.delta = this.form.utc
        ? `${this.form.utc ? new Date().getTimezoneOffset() / -60 : 0}`
        : 0
    },
    ClickHandler: function(event, item) {},
    getEnterStatsType() {
      let startDate = null
      if (this.form.type === 2) {
        startDate = moment()
          .subtract(42, 'days')
          .startOf('day')
          .format('YYYY-MM-DD')
      } else if (this.form.type === 1) {
        startDate = moment()
          .subtract(28, 'days')
          .startOf('day')
          .format('YYYY-MM-DD')
      } else {
        startDate = moment()
          .subtract(14, 'days')
          .startOf('day')
          .format('YYYY-MM-DD')
      }

      this.form.reg_date = moment(this.form.reg_date).format('YYYY-MM-DD')
      this.$refs.traffic.start = new Date(startDate)
      this.$refs.traffic.end = new Date(this.form.reg_date)
      this.datepicker_dates.date_from = new Date(startDate)
      this.datepicker_dates.date_to = new Date(this.form.reg_date)

      let newData = {
        date_from: startDate,
        date_to: this.form.reg_date
      }
      if (this.form.utc) newData.delta = new Date().getTimezoneOffset() / -60
      this.getNewPayments(newData).then(response => {
        this.reLoaded()
      })
    },
    getRegEnterStats() {
      let start = moment(this.datepicker_dates.date_from).format('YYYY-MM-DD')
      let end = moment(this.datepicker_dates.date_to).format('YYYY-MM-DD')
      let newData = {
        date_from: start,
        date_to: end
      }
      if (this.form.utc) newData.delta = new Date().getTimezoneOffset() / -60

      this.getNewPayments(newData).then(response => {
        this.reLoaded()
      })
    },
    changeUtc(number) {
      this.form.utc = number
    }
  }
}
</script>

<style scoped></style>
