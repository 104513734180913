<template>
  <section ref="filtersWrap" class="filters-wrap filters-wrap">
    <form @submit.prevent="submitDepartmentHandler" class="filters " :class="{ opened: mobileFilterOpen }">
      <div class="filters__items">
        <div class="filters__item-wrapp">
          <span class="filters__item-text">Права доступа</span>
        </div>
      </div>
      <button type="button" class="filters__new-ticket" @click="showNewDepartmentModal">
        <i class="filters__new-ticket-icon fa fa-plus-square"></i>
        <span class="filters__button-text">Добавить</span>
      </button>
    </form>
  </section>
</template>

<script>
import { NEW_PERMISSION_POPUP_EVENT } from '@/constants/event'
import { REQUEST_DEPARTMENTS_BY_FILTER, REQUEST_CONTROL_DEPARTMENTS } from '@/store/action-types'
import { mapActions } from 'vuex'
export default {
  name: 'Filters',
  data: () => ({
    statusFilterOpen: false,
    appealFilterOpen: false,
    priorityFilterOpen: false,
    mobileFilterOpen: false,

    departmentFilters: {
      department_id: '',
      department_name: ''
    }
  }),

  computed: {
    departmentID: {
      get() {
        return this.departmentFilters.department_id
      },
      set(id) {
        // id = id.replace(/\D/g, '')
        this.$refs.departmentid.value = id
        this.departmentFilters.department_id = id
      }
    },
    departmentName: {
      get() {
        return this.departmentFilters.department_name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.departmentname.value = name
        this.departmentFilters.department_name = name
      }
    }
  },

  methods: {
    ...mapActions({
      applyFilters: REQUEST_DEPARTMENTS_BY_FILTER,
      resetFilters: REQUEST_CONTROL_DEPARTMENTS
    }),
    showNewDepartmentModal() {
      this.$root.$emit(NEW_PERMISSION_POPUP_EVENT)
    },

    resetFiltershandler() {
      this.departmentFilters = {}
      this.resetFilters()
    },

    submitDepartmentHandler() {
      const departmentFiltersData = {
        id: this.departmentFilters.department_id,
        name: this.departmentFilters.department_name
      }

      this.applyFilters(departmentFiltersData)

      // for (let key in departmentFiltersData) {
      //   if (departmentFiltersData[key]) {
      //     this.departmentFilters.department_id = ''
      //     this.departmentFilters.department_name = ''
      //     break
      //   }
      // }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$emit('filtersHeight', this.$refs.filtersWrap.clientHeight)
    })
  }
}
</script>

<style lang="scss" scoped>
@import './Filters.scss';
.filters__item-text {
  font-size: 20px;
  font-weight: 700;
  color: #717171;
}
.filters__item-wrapp {
  display: flex;
  align-items: center;
}
</style>
