<template>
  <div class="claim">
    <div class="claim__field">
      <div class="claim__avatar">
        <img
          v-if="message._source.operator_user.user_avatar.content_id"
          :src="
            getImgLinkCropped(message._source.operator_user.user_avatar.content_id, 480, {
              ...message._source.operator_user.user_avatar.crop
            })
          "
          alt=""
        />
        <img v-else src="../../assets/img/anonymous_user.png" alt="" />
      </div>
      <div class="claim__user-info">
        <div class="claim__name">
          <router-link
            v-if="user_permissions.includes(103)"
            :to="{ name: 'UserItem', params: { userId: message._source.operator_user.user_id } }"
            class="app-user__name-link"
            target="_blank"
          >
            <span class="claim__text claim__user-link">{{ message._source.operator_user.user_name }}</span>
          </router-link>
          <div v-else class="app-user__name-link">
            <span class="claim__text claim__user-link">{{ message._source.operator_user.user_name }}</span>
          </div>
          <div class="claim__age">({{ message._source.operator_user.age }})</div>
          <i
            :class="{
              'fa fa-mars app-user__gender-icon--male': message._source.operator_user.user_gender === 1,
              'fa fa-venus app-user__gender-icon--female': message._source.operator_user.user_gender === 2
            }"
          ></i>
        </div>
      </div>
    </div>
    <div class="claim__field">
      <i class="fas fa-arrow-right claim__to-right"></i>
    </div>
    <div class="claim__field">
      <div class="claim__avatar">
        <img
          v-if="message._source.user.user_avatar.content_id !== '61543a209d5494963d03c64e.jpg'"
          :src="
            getImgLinkCropped(message._source.user.user_avatar.content_id, 480, {
              ...message._source.user.user_avatar.crop
            })
          "
          alt=""
        />
        <img v-else src="../../assets/img/anonymous_user.png" alt="" />
      </div>
      <div class="claim__user-info">
        <div class="claim__name">
          <router-link
            v-if="user_permissions.includes(103)"
            :to="{ name: 'UserItem', params: { userId: message._source.user.user_id } }"
            class="app-user__name-link"
            target="_blank"
          >
            <span class="claim__text claim__user-link">{{ message._source.user.user_name }}</span>
          </router-link>
          <div v-else class="app-user__name-link">
            <span class="claim__text claim__user-link">{{ message._source.user.user_name }}</span>
          </div>
          <div class="claim__age">({{ message._source.user.age }})</div>
          <i
            :class="{
              'fa fa-mars app-user__gender-icon--male': message._source.user.user_gender === 1,
              'fa fa-venus app-user__gender-icon--female': message._source.user.user_gender === 2
            }"
          ></i>
        </div>
      </div>
    </div>
    <div
      class="claim__field"
      v-if="
        message._source.message.message_content_type === 3 ||
          message._source.message.message_content_type === 4
      "
    >
      <div>
        <!-- <span>{{ message.content.text.original.messageText }}</span> -->
        <audio controls :src="getMediaLink(message._source.message.message_content.DataID, 'audio')"></audio>
        <!-- <span>{{ message.content.text.original.audioTitle }}</span> -->
      </div>
    </div>
    <div class="claim__field" v-if="message._source.message.message_content_type === 0">
      <div class="claim__message">
        <p class="claim__message--content">{{ message._source.message.message_content.Text.Original }}</p>
      </div>
    </div>
    <div class="claim__field" v-if="message._source.message.message_content_type === 1">
      <div class="claim__message-img">
        <img
          class="claim__message-picture"
          v-if="message._source.message.message_content.DataID"
          @click="resizeImg(message._source.message.message_id)"
          :ref="message._source.message.message_id"
          :src="
            getFile({
              type: 'image',
              file: message._source.message.message_content.DataID
            })
          "
          alt=""
        />
        <img v-else class="no-img" />
      </div>
    </div>
    <div class="claim__field" v-if="message._source.message.error">
      Автосообщение отсутвует!
    </div>
    <div class="claim__field">
      <div class="claim__dialog">
        <p class="claim__dialog-status" v-if="message._source.count > 1">
          {{ `${message._source.message.error ? message._source.message.error : 'Диалог начался'}` }}
        </p>
        <p class="claim__dialog-status" v-else>Диалог не начался</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dateFormat from '@/components/mixins/toLocaleformat'
import getImgCdnLink from '@/components/mixins/getImgCdnLink'
import BigPicture from 'bigpicture'
import { GET_USER_GENDER, GET_PERMISSIONS_USER } from '@/store/getter-types'
import moment from 'moment'
export default {
  name: 'messageItem',
  props: {
    message: {
      type: Object
    }
  },
  data: () => ({
    versionsListOpen: false,
    employeeBan: '',
    employeeVerificate: ''
  }),

  computed: {
    ...mapGetters({
      gender: GET_USER_GENDER,
      user_permissions: GET_PERMISSIONS_USER
    })
  },
  mixins: [dateFormat, getImgCdnLink],
  methods: {
    close() {
      this.versionsListOpen = false
    },
    isVip(vip) {
      return vip > new Date().getTime() / 1000.0 ? true : false
    },
    resizeImg(id) {
      BigPicture({ el: this.$refs[id] })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './messageItem.scss';
.app-user__item-value--small,
.app-user__item-icon--small {
  font-size: 12px;
  width: 20px;
}
.info-item--bold {
  //width: 100%;
  color: #428bca;
  font-weight: 700;
  padding-top: 2px;
  //margin-top: 5px;
  border-top: 1px solid #ccc;
}

.app-user__icons-small {
  align-items: flex-start;
}
.app-user__name {
  width: 45%;
}
.app-user__wrapper {
  width: 30%;
  font-size: 20px;
  display: flex;
}

.app-user {
  margin-top: 15px;
  &__gender-icon {
    font-size: 17px;
    margin-right: 2px;
    &--male {
      color: $blue;
    }
    &--female {
      color: #ff99cc;
      margin-right: 5px;
    }
    &--question {
      color: $grey;
      margin-right: 5px;
    }
  }
}

.app-user__ban {
  // position: absolute;
  // left: 15%;
  font-size: 11px;
  margin-right: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-photo {
    &-verificate {
      display: flex;
      align-items: center;
      color: #78cd51;
      font-size: 20px;
    }
    &-times {
      span {
        font-size: 20px;
        color: #ffa837;
      }
    }
    &-clock {
      span {
        color: #e48800;
        font-size: 20px;
      }
    }
    &-slash {
      span {
        color: #ec1515;
        font-size: 20px;
      }
    }
    &-triangle {
      span {
        font-size: 20px;
        color: #41cac0;
      }
    }
    &-banned {
      span {
        color: #ec1515;
        font-size: 20px;
      }
    }
    &-baby {
      span {
        color: #ff8adc;
        font-size: 20px;
      }
    }
  }
}
.app-user-profile__ban-icon {
  margin-right: 2px;
  color: #b5430d;
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.app-user-profile__ban-text {
  display: block;
  margin-top: 4px;
  font-size: 11px;
  color: #b5430d;
  text-align: center;
  margin-right: -30px;
}
.app-user_confidential-wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20px;
}
</style>
