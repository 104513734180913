const SET_AUTO_MESSAGES_LIST = 'SET_AUTO_MESSAGES_LIST'
const SET_MESSAGES_COUNT = 'SET_MESSAGES_COUNT'
const SET_CURRENT_FILTER_AUTO_MESSAGE = 'SET_CURRENT_FILTER_AUTO_MESSAGE'
const SET_TMP_FILTERS_AUTO_MESSAGE = 'SET_TMP_FILTERS_AUTO_MESSAGE'
const SET_RESET_FILTERS_AUTO_MESSAGE = 'SET_RESET_FILTERS_AUTO_MESSAGE'
const SET_AUTO_MESSAGES_LIST_FILTER = 'SET_AUTO_MESSAGES_LIST_FILTER'
const SET_AUTO_MESSAGES_LIST_FILTER_COUNT = 'SET_AUTO_MESSAGES_LIST_FILTER_COUNT'

export {
  SET_AUTO_MESSAGES_LIST,
  SET_MESSAGES_COUNT,
  SET_CURRENT_FILTER_AUTO_MESSAGE,
  SET_TMP_FILTERS_AUTO_MESSAGE,
  SET_RESET_FILTERS_AUTO_MESSAGE,
  SET_AUTO_MESSAGES_LIST_FILTER,
  SET_AUTO_MESSAGES_LIST_FILTER_COUNT
}
