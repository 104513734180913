<template>
  <!-- <div class="table-header-rights"> -->
  <ul class="table-header">
    <li class="table-header__item table-header__item--employee">
      <span class="table-header__item-text">Группа</span>
    </li>
    <li class="table-header__item table-header__item--employee">
      <span class="table-header__item-text">Описание</span>
    </li>
    <li class="table-header__item table-header__item--employee">
      <span class="table-header__item-text">Уровень доступа</span>
    </li>
    <!-- <li class="table-header__item table-header__item--employee">
        <span class="table-header__item-text table-header__item-text-select">Должность</span>
      </li> -->
  </ul>
  <!-- </div> -->
</template>
<script>
export default {
  name: 'PermissonTableHeader'
}
</script>
<style lang="scss" scoped>
@import './Permisson-table-header.scss';
.table-header-rights {
  width: 100%;
  position: relative;
}
</style>
