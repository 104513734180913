<template>
  <div class="event-spec__spec-block spec-block">
    <div class="spec-block__head">
      <div class="spec-block__head-img">
        <img
          v-if="
            !currentEvent.event_image.banner.own ||
              currentEvent.event_image.banner.id === `60d5bde2baf18cdc0678e8e4.jpg`
          "
          :src="
            getFile({
              type: 'image',
              file: currentEvent.event_image.banner.id,
              width: currentEvent.event_image.banner.crop.width
                ? currentEvent.event_image.banner.crop.width
                : ``,
              height: currentEvent.event_image.banner.crop.height
                ? currentEvent.event_image.banner.crop.height
                : ``
            })
          "
          alt=""
        />
        <img
          v-else
          :src="
            getFile({
              type: 'image',
              file: currentEvent.event_image.banner.id,
              width: currentEvent.event_image.banner.crop.width
                ? currentEvent.event_image.banner.crop.width
                : ``,
              height: currentEvent.event_image.banner.crop.height
                ? currentEvent.event_image.banner.crop.height
                : ``
            })
          "
          alt=""
        />
      </div>
    </div>
    <div class="spec-block__body">
      <div class="spec-block__item">
        <span class="spec-block__key">Кто платит:</span>
        <span class="spec-block__val">{{ currentEvent.event_payer === 1 ? 'Гость' : '-' }}</span>
      </div>
      <div class="spec-block__item">
        <span class="spec-block__key">Бюджет:</span>
        <span class="spec-block__val"
          >{{ currentEvent.event_budget.value }} {{ moneyIcon(currentEvent.event_budget.currency) }}</span
        >
      </div>
      <div class="spec-block__item">
        <span class="spec-block__key">Количество откликнувшихся:</span>
        <span class="spec-block__val">{{ currentEvent.event_count.wish }}</span>
      </div>
      <div v-if="currentEvent.event_count.wish">
        <div class="spec-block__item">
          <span class="spec-block__key">Гость</span>
          <span class="spec-block__val"></span>
        </div>
        <div class="spec-block__item">
          <span class="spec-block__key">ID гостя:</span>
          <span class="spec-block__val">{{ `-` }}</span>
        </div>
        <div class="spec-block__item">
          <span class="spec-block__key">Имя:</span>
          <span class="spec-block__val">{{ `-` }}</span>
        </div>
        <div class="spec-block__item">
          <span class="spec-block__key">Возраст:</span>
          <span class="spec-block__val">{{ `-` }}</span>
        </div>
        <div class="spec-block__item">
          <span class="spec-block__key">Email:</span>
          <span class="spec-block__val">{{ `-` }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="event-spec__spec-payment">
    <div class="event-spec__spec-block spec-block spec-payment">
        <div class="spec-block__body">
        <div class="spec-block__item">
            <span class="spec-block__key">Пополнение</span>
            <div class="spec-block__val">
            <span class="spec-block__key">ID транзакции: </span>
            {{ `3456894768` }}
            </div>
        </div>
        <div class="spec-block__item">
            <div class="spec-block__key">
            Карта:
            <span class="spec-block__val">{{ `220220*****435` }}</span>
            </div>
            <div class="spec-block__val">
            <span class="spec-block__key">Сумма: </span>
            {{ `3450 RUB` }}
            </div>
        </div>
        <div class="spec-block__item">
            <div class="spec-block__key">
            Дата создания:
            <span class="spec-block__val">{{ `22.06.2020` }}</span>
            </div>
            <div class="spec-block__val">
            <span class="spec-block__key">Дата обновления: </span
            >{{ `21.06.2020` }}
            </div>
        </div>
        <div class="spec-block__item">
            <div class="spec-block__key">
            Статус:
            <span class="spec-block__val event-spec-status-green">{{
                `Выполнено`
            }}</span>
            </div>
        </div>
        </div>
    </div>
    <div class="event-spec__spec-block spec-block spec-payment">
        <div class="spec-block__body">
        <div class="spec-block__item">
            <span class="spec-block__key">Вывод средств</span>
            <div class="spec-block__val">
            <span class="spec-block__key">ID транзакции: </span>
            {{ `3456894768` }}
            </div>
        </div>
        <div class="spec-block__item">
            <div class="spec-block__key">
            Карта:
            <span class="spec-block__val">{{ `220220*****435` }}</span>
            </div>
            <div class="spec-block__val">
            <span class="spec-block__key">Сумма: </span>
            {{ `3450 RUB` }}
            </div>
        </div>
        <div class="spec-block__item">
            <div class="spec-block__key">
            Дата создания:
            <span class="spec-block__val">{{ `22.06.2020` }}</span>
            </div>
            <div class="spec-block__val">
            <span class="spec-block__key">Дата обновления: </span
            >{{ `21.06.2020` }}
            </div>
        </div>
        <div class="spec-block__item">
            <div class="spec-block__key">
            Статус:
            <span class="spec-block__val event-spec-status-green">{{
                `Выполнено`
            }}</span>
            </div>
        </div>
        </div>
    </div>
    </div> -->
</template>
<script>
import getImgCdnLink from '@/components/mixins/getImgCdnLink'
import getIconCurrency from '@/components/mixins/getIconCurrency'

export default {
  name: 'EventSpec',
  props: {
    currentEvent: {
      type: Object
    }
  },
  mixins: [getImgCdnLink, getIconCurrency]
}
</script>
