import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_USER_STATISTIC_ADVANCED]: (state, payload) => {
    const { data } = payload
    state.userDatesList = data
  },
  [MutationTypes.SET_STATISTIC_USER_BY_DATE]: (state, payload) => {
    state.userStatistic = payload.data
  },
  [MutationTypes.SET_STATISTIC_USER_BY_DATE_PAGINATION]: (state, payload) => {
    state.userStatistic.push(...payload.data)
  }
}
