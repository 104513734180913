import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import RequestsTypesService from '@/services/RequestsTypesService'

export default {
  [ActionTypes.REQUEST_SUPPORT_CONTACT_TYPES]: async ({ rootGetters, commit }, lang = 'RU') => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(RequestsTypesService, [userToken]).getRequestsTypes(lang)
    const { data, status } = response
    if (status) {
      commit(MutationTypes.SET_SUPPORT_CONTACT_TYPES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },
  [ActionTypes.REQUEST_ADD_SUPPORT_CONTACT_TYPES]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(RequestsTypesService, [userToken]).addRequestsTypes(payload)
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_SUPPORT_CONTACT_TYPES, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_EDIT_CONTACT_TYPES]: async ({ rootGetters, commit, dispatch }, payload) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(RequestsTypesService, [userToken]).editRequestsTypes(payload)
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_SUPPORT_CONTACT_TYPES, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_DELETE_SUPPORT_CONTACT_TYPES]: async ({ rootGetters, commit, dispatch }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(RequestsTypesService, [userToken]).deleteRequestsTypes(id)
    const lang = rootGetters['languages/GET_CURRENT_LANGUAGES']
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_SUPPORT_CONTACT_TYPES, lang)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
