var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Popup',{attrs:{"visible":_vm.visible,"description":"Передать пользователя другому оператору","modalClass":"popup--add-user"},on:{"close":_vm.closePopup}},[_c('div',{staticClass:"popup__filter"},[_c('div',{staticClass:"popup__filter__operator"},[_c('div',{staticClass:"select"},[_c('div',{staticClass:"select__field select__field--sort"},[_c('span',{staticClass:"select__title"},[_vm._v("Чат с оператором:")]),_c('div',{staticClass:"select__body"},[_c('button',{staticClass:"select__field",attrs:{"type":"button"},on:{"click":function($event){return _vm.clickClose('operatorTransfer')}}},[_c('span',{staticClass:"select__field-value"},[_vm._v(_vm._s(_vm.defaultFilter({ key: 'operatorTransfer' })))]),_c('i',{staticClass:"select__field-icon fa fa-angle-down"})]),(_vm.operatorTransferSelectOpen)?_c('ul',{staticClass:"select__list",on:{"mouseleave":function($event){return _vm.clickClose('operatorTransfer')}}},_vm._l((_vm.currentOperators),function(operator){return _c('li',{key:operator.id,staticClass:"select__item",on:{"click":function($event){return _vm.selectFilterBuilder({
                    key: 'operatorTransfer',
                    value: operator
                  })}}},[_c('span',{staticClass:"select__text"},[_vm._v(" "+_vm._s(operator.name)+" ")])])}),0):_vm._e()]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.operator),expression:"errors.operator"}],staticClass:"popup_error"},[_vm._v(_vm._s(_vm.errors.operator))])])])])]),_c('div',{staticClass:"popup__item"},[_c('div',{staticClass:"popup__item-wrapp"},[_c('span',{staticClass:"popup__item-title"},[_vm._v("Пользователи")]),_c('div',{staticClass:"popup__item-input"},[_c('p',{staticClass:"popup__item-input-content"},[_vm._v("Выбрать всех")]),_c('input',{ref:"inputCheckBoxAll",staticClass:"checkAll",attrs:{"type":"checkbox"},on:{"click":function($event){return _vm.checkedAll()}}})])]),(_vm.currentUser.length)?_c('ul',{staticClass:"operator-profile__account-list"},[_c('li',{staticClass:"operator-profile__account-list-item"}),_vm._l((_vm.currentUser),function(current){return _c('li',{key:current.id,staticClass:"operator-profile__account-wrapper"},[_c('div',{staticClass:"operator-profile__account-info"},[_c('div',{staticClass:"operator__avatar"},[(current.user_avatar)?_c('img',{attrs:{"src":_vm.getFile({
                  type: 'image',
                  file: current.user_avatar.content_id,
                  width: 60,
                  height: 60
                }),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/img/anonymous_user.png"),"alt":""}})]),_c('span',{staticClass:"operator__name"},[_c('span',{staticClass:"operator__text"},[_vm._v(_vm._s(current.user_name)+" ")])])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pickedUsers),expression:"pickedUsers"}],ref:"inputCheckBox",refInFor:true,staticClass:"simple-checkbox",attrs:{"type":"checkbox"},domProps:{"value":current.id,"checked":Array.isArray(_vm.pickedUsers)?_vm._i(_vm.pickedUsers,current.id)>-1:(_vm.pickedUsers)},on:{"click":_vm.checked,"change":function($event){var $$a=_vm.pickedUsers,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=current.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.pickedUsers=$$a.concat([$$v]))}else{$$i>-1&&(_vm.pickedUsers=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.pickedUsers=$$c}}}})])})],2):_c('div',{staticClass:"popup__item-list"},[_c('span',{staticClass:"popup__no-data"},[_vm._v("Пользователи не найдены")])])]),_c('div',{staticClass:"popup__item"},[_c('div',{staticClass:"popup__btns popup__btns--right"},[(_vm.errors.emptyAccounts)?_c('span',{staticClass:"popup_error"},[_vm._v(_vm._s(_vm.errors.emptyAccounts))]):_vm._e(),_c('button',{staticClass:"popup__btn btn-green",attrs:{"type":"submit"},on:{"click":_vm.sendData}},[_vm._v("Передать")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }