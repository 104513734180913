<template>
  <div>
    <div class="moderators-statistic__header">
      <span class="moderators-statistic__header-title"
        >Статистика модератора — {{ currentModerator.name }} - ( сейчас {{ isActive }} )
      </span>
    </div>
    <router-link
      :to="{ name: 'moderator-payments' }"
      class="moderators-statistic__btn moderators-statistic__btn--default"
    >
      <i class="fas fa-arrow-circle-left"></i>
      Список модераторов
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ModeratorsStatisticHeaders',
  props: {
    currentModerator: {
      type: Object
    }
  },
  computed: {
    isActive() {
      return this.currentModerator.is_active === 1 ? 'активен' : ' неактивен'
    }
  }
}
</script>
<style lang="scss">
@import 'ModeratorStatistic';
</style>
