<template>
  <div v-if="true" class="operator-wrapper">
    <div class="operator-banner operator-wrapper-banner">
      <div class="operator-banner__field">
        <span class="operator-banner__mobtitle">№:</span>
        <span class="operator-banner__text">{{ number }}</span>
      </div>
      <div class="operator-banner__field operator-banner__btn--blue">
        <span class="operator-banner__mobtitle">Отправлять:</span>
        <!-- <span class="operator-banner__text"><i class="fa fa-check-square"></i></span> -->
        <input type="checkbox" v-model="message.enabled" @change="changeSend" />
      </div>
      <div class="operator-banner__field">
        <span class="operator-banner__mobtitle">Время задержки:</span>
        <span class="operator-banner__text">{{ message.delay_seconds }} секунд.</span>
      </div>
      <div class="operator-banner__field operator-banner__field--btns">
        <button class="operator-banner__btn btn-blue operator-banner__btn--blue" @click="editPopup">
          <i class="fa fa-edit"></i>
        </button>
        <button class="operator-banner__btn btn-red operator-banner__btn--red" @click="deletePopup">
          <i class="fa fa-times-circle"></i>
        </button>
      </div>
    </div>
  </div>
  <div v-else class="error">Ошибка подгрузки данных</div>
</template>
<script>
import { mapActions } from 'vuex'
import { REQUEST_CHANGE_OPERATORS_AUTO_MESSAGE } from '@/store/action-types'
export default {
  props: {
    message: {
      type: Object
    },
    number: {
      type: Number
    }
  },
  data() {
    return {
      bunnerObject: {}
    }
  },
  methods: {
    ...mapActions({
      changeAutoMesssage: REQUEST_CHANGE_OPERATORS_AUTO_MESSAGE
    }),
    editPopup() {
      const key = 2
      this.$root.$emit('OPERATOR_EDIT_AUTO_MESSAGE', this.message, key)
    },
    deletePopup() {
      const key = 3
      this.$root.$emit('OPERATOR_EDIT_AUTO_MESSAGE', this.message.id, key)
    },
    changeSend() {
      const data = {
        payload: {
          delay_seconds: this.message.delay_seconds,
          enabled: this.message.enabled
        },
        id: this.message.id
      }
      this.changeAutoMesssage(data)
    }
  }
}
</script>
