<template>
  <div class="purchase__header">
    <h1 class="purchase__title">Цифровые покупки</h1>
    <div class="purchase__wrap">
      <!--      <select-->
      <!--        class="ev-cat__select"-->
      <!--        v-model="selected"-->
      <!--        @change="getCategoriesByLang(selected)"-->
      <!--      >-->
      <!--        <option v-for="lang in languages" :key="lang.value" :value="lang.value">-->
      <!--          {{ lang.name }}-->
      <!--        </option>-->
      <!--      </select>-->
      <button type="button" class="purchase__btn-add" @click="$root.$emit(PURCHASE_ADD_POPUP_EVENT)">
        <i class="purchase__new-points-icon fa fa-plus-square"></i>
        <span class="purchase__btn-title">Добавить</span>
      </button>
    </div>
  </div>
</template>

<script>
import { PURCHASE_ADD_POPUP_EVENT } from '@/constants/event'
// import { mapActions, mapGetters, mapMutations } from "vuex";
// import { REQUEST_ACHIEVEMENTS } from "@/store/action-types";
// import { SET_CURRENT_LANGUAGES } from "@/store/mutation-types.js";
// import { GET_LANGUAGES, GET_CURRENT_LANGUAGES } from "@/store/getter-types";
export default {
  name: 'PurchasesHeader',
  data() {
    return {
      PURCHASE_ADD_POPUP_EVENT
    }
  }
  // methods: {
  //   ...mapActions({
  //     categoryByLang: REQUEST_ACHIEVEMENTS,
  //   }),
  //   ...mapMutations({
  //     setCurrentLang: SET_CURRENT_LANGUAGES,
  //   }),
  //   getCategoriesByLang(value) {
  //     this.categoryByLang(value);
  //   },
  // },
  // computed: {
  //   ...mapGetters({
  //     languages: GET_LANGUAGES,
  //     currentLang: GET_CURRENT_LANGUAGES,
  //   }),
  //   selected: {
  //     get() {
  //       return this.currentLang;
  //     },
  //     set(newValue) {
  //       this.setCurrentLang(newValue);
  //     },
  //   },
  // },
}
</script>

<style lang="scss">
.ev-cat__select {
  margin-right: 10px;
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
  color: gray;
  cursor: pointer;
  border: 1px solid #e2e2e4;
  box-shadow: none;
  outline: none;
  @media (max-width: 510px) {
    padding: 3px;
    font-size: 12px;
  }
}
</style>
