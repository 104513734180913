import * as RootGetterTypes from './getter-types'
export default {
  [RootGetterTypes.GET_LOADING]: ({ loading }) => loading,

  [RootGetterTypes.GET_USER]: ({ user }) => user.userData,

  [RootGetterTypes.GET_TOKEN]: ({ user }) => user.token,

  [RootGetterTypes.GET_LOGIN]: ({ user }) => user.loggedIn,

  [RootGetterTypes.GET_PERMISSIONS_USER]: ({ permissionsUser }) => permissionsUser,

  [RootGetterTypes.GET_PERMISSIONS]: (_, getters) =>
    getters[RootGetterTypes.GET_USER] ? getters[RootGetterTypes.GET_USER].permissions : []
}
