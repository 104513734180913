<template>
  <Popup :visible="visible" @close="closePopup" :description="title" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="verifyHandler('approve')">
          Да
        </button>
        <button class="popup__btn btn-red" @click="closePopup">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_PANEL_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_HIDE_USER_ADMIN } from '@/store/action-types'
export default {
  name: 'UserHideAdminPopup',
  components: {
    Popup
  },
  data() {
    return {
      id: '',
      incognito: false
    }
  },
  mixins: [ModalMixin],
  computed: {
    title() {
      if (!this.incognito) {
        return 'Скрыть в админ панели?'
      } else {
        return 'Показывать в админ панели?'
      }
    }
  },
  methods: {
    ...mapActions({
      hideUser: REQUEST_HIDE_USER_ADMIN
    }),
    closePopup() {
      this.id = ''
      this.incognito = false
      this.close()
    },
    verifyHandler() {
      const payload = {
        user_id: this.id,
        enable: this.incognito ? false : true
      }
      this.hideUser(payload)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(USER_PANEL_EVENT, user => {
      this.open()
      this.id = user.id
      this.incognito = user.incognito_admin
    })
  }
}
</script>
