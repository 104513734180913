import * as ActionTypes from './actions-types'
import * as MutationTypes from './mutations-types'
import * as RootMutationTypes from '@/store/mutation-types'
import LangService from '@/services/LangService'

export default {
  [ActionTypes.REQUEST_ALL_LANGUAGES]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(LangService, [userToken]).getAllLanguage()
    const { data } = response
    if (data) {
      commit(MutationTypes.SET_ALL_LANGUAGES, data)
      // commit(MutationTypes.SET_CURRENT_LANGUAGES, data[0].value);
      // commit(RootMutationTypes.SET_CURRENT_LANGUAGE_BY_ID, data[0].id, { root: true })
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_CREATE_NEW_LANGUAGES]: async ({ rootGetters, commit, dispatch }, newLang) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(LangService, [userToken]).createLanguage(newLang)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_LANGUAGES)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_DELETED_LANGUAGES]: async ({ rootGetters, commit, dispatch }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(LangService, [userToken]).deleteLanguageById(id)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_ALL_LANGUAGES)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  }
}
