import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_REASON_DELETE]: (state, payload) => {
    let sortArr = payload.sort((a, b) => (a.reason_code > b.reason_code ? 1 : -1))
    state.reasonDelete = sortArr
  },
  [MutationTypes.SET_REASON_BLOCK_USER]: (state, payload) => {
    let sortArr = payload.sort((a, b) => (a.reason_code > b.reason_code ? 1 : -1))
    state.reasonBlockUser = sortArr
  },
  [MutationTypes.SET_REASON_BLOCK_PHOTO]: (state, payload) => {
    let sortArr = payload.sort((a, b) => (a.reason_code > b.reason_code ? 1 : -1))
    state.reasonBlockPhoto = sortArr
  },
  [MutationTypes.SET_REASON_DELETE_ADMIN]: (state, payload) => {
    let sortArr = payload.sort((a, b) => (a.reason_code > b.reason_code ? 1 : -1))
    state.reasonDeleteAdmin = sortArr
  }
}
