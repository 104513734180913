export default {
  permissions: [],
  permissions_group: [],
  tmpUserFilters: {
    permissions: {}
  },
  filterUsersDefault: {
    permissions: {
      name: 'Выберите группу',
      value: 'Неважно',
      id: 'Неважно'
    }
  }
}
