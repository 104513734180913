import * as GetterTypes from './getter-types'
export default {
  [GetterTypes.GET_APPS_FLYER]: ({ appsFlyer }) => appsFlyer,
  [GetterTypes.GET_APPS_FLYER_IOS]: ({ appsFlyerIos }) => appsFlyerIos,
  [GetterTypes.GET_ROYAL_USERS_INSTALL]: ({ loyalInstal }) => loyalInstal,
  [GetterTypes.GET_ROYAL_USERS_INSTALL_IOS]: ({ loyalInstalIos }) => loyalInstalIos,
  [GetterTypes.GET_USERS_ACTIVITY]: ({ usersActivity }) => usersActivity,
  [GetterTypes.GET_USERS_COUNT]: ({ usersCount }) => usersCount,
  [GetterTypes.GET_USERS_ONLINE_BY_CITY]: ({ usersOnlineByCity }) => usersOnlineByCity,
  [GetterTypes.GET_USERS_ONLINE_ONE_DAY]: ({ usersOnlineOneDay }) => usersOnlineOneDay,
  [GetterTypes.GET_USERS_ONLINE_ONE_DAY_SITE_AND_APP]: ({ usersOnlineOneDaySource }) =>
    usersOnlineOneDaySource,
  [GetterTypes.GET_PURCHASES_MARKET_PLACES]: ({ purchasesMarketPlaces }) => purchasesMarketPlaces,
  [GetterTypes.GET_PURCHASES_BY_CITY]: ({ purchasesByCity }) => purchasesByCity,
  [GetterTypes.GET_PURCHASES_BY_COUNTRY]: ({ purchasesByCountry }) => purchasesByCountry,
  [GetterTypes.GET_PURCHASES_BY_TIME]: ({ purchasesByTime }) => purchasesByTime,
  [GetterTypes.GET_NEW_USER_ACTIVE_BY_DATE]: ({ newUserActiveByDate }) => newUserActiveByDate,
  [GetterTypes.GET_MODERATORS_PHOTO_CHECK]: ({ statsModeratorPhotoCheck }) => statsModeratorPhotoCheck,
  [GetterTypes.GET_MODERATORS_VIDEO_CHECK]: ({ videoModaration }) => videoModaration,
  [GetterTypes.GET_USERS_GENDER]: ({ usersGender }) => usersGender,
  [GetterTypes.GET_PURCHASES_BY_PAYERS_PAYMENTS]: ({ purchasesByPayersPayments }) =>
    purchasesByPayersPayments,
  [GetterTypes.GET_ACTIVE_NEW_USER]: ({ newUserActive }) => newUserActive,
  [GetterTypes.GET_USERS_PAYING]: ({ usersPaying }) => usersPaying,
  [GetterTypes.GET_USERS_AGE]: ({ usersAge }) => usersAge,
  [GetterTypes.GET_USER_LIFETIME]: ({ usersLifetime }) => usersLifetime,
  [GetterTypes.GET_PURCHASE_TOTAL]: ({ purchaseTotal }) => purchaseTotal,
  [GetterTypes.GET_REGS_NEW_USERS]: ({ activityFromSite }) => activityFromSite,
  [GetterTypes.GET_OPERATORS_MESSAGE]: ({ operatorMessages }) => operatorMessages,
  [GetterTypes.GET_OPERATORS_CASHES]: ({ operatorCaches }) => operatorCaches,
  [GetterTypes.GET_PURCHASES_POINTS]: ({ ballsPurchases }) => ballsPurchases,
  [GetterTypes.GET_PURCHASES_VIP_STATUS]: ({ vipStatusPurchases }) => vipStatusPurchases,
  [GetterTypes.GET_PURCHASES_BY_DATE]: ({ purchasesByDate }) => purchasesByDate,
  [GetterTypes.GET_COUNTERS_MESSAGES_OPERATORS]: ({ messageCounters }) => messageCounters,
  [GetterTypes.GET_STATISTICS_PROFILE_MESSAGES]: ({ messageStatistics }) => messageStatistics
}
