<template>
  <div class="event unapproved-event">
    <div class="event__field">
      <div class="event__user-avatar">
        <img
          :src="
            getFile({
              type: 'image',
              file: event.event_user.user_avatar.content_id,
              width: 100
            })
          "
          alt=""
        />
      </div>
      <div class="event__user-name">
        <router-link
          v-if="user_permissions.includes(103)"
          :to="{
            name: 'UserItem',
            params: { userId: event.event_user.id }
          }"
          class="event__user-name-link"
        >
          <span class="event__user-name-text">{{ event.event_user.user_name }}, </span>
          <span class="event__user-name-text">({{ event.event_user.age || '-' }})</span>
        </router-link>
        <div v-else class="event__user-name-link">
          <span class="event__user-name-text">{{ event.event_user.user_name }}, </span>
          <span class="event__user-name-text">({{ event.event_user.age || '-' }})</span>
        </div>
        <div class="event__user-location">
          <span class="event__user-location-text">{{ event.event_user.user_city }}</span>
        </div>
        <div class="event__name">
          <p class="event__name--text">{{ event.event_subtitle }}</p>
        </div>
        <!-- <span class="event__user-events-score">
          <FaIcon :iconClass="'fa fa-star'" class="event__user-name-icon" v-tooltip="'Рейтинг'" />
          <span class="event__user-name-count">{{ `-` }}</span>
        </span>
        <span class="event__user-events-score">
          <FaIcon
            :iconClass="'fa fa-calendar-plus'"
            class="event__user-name-icon"
            v-tooltip="'Созданные события'"
          />
          <span class="event__user-name-count">{{ event.event_count.outbid }}</span>
        </span>
        <span class="event__user-events-score">
          <FaIcon
            :iconClass="'fa fa-calendar-check'"
            class="event__user-name-icon"
            v-tooltip="'Посещенные события'"
          />
          <span class="event__user-name-count">{{ event.event_count.invite }}</span>
        </span> -->
      </div>
    </div>
    <div class="event__field">
      <div class="event__img">
        <img
          v-if="
            !event.event_image.avatar.own || event.event_image.avatar.id === `60d5bde2baf18cdc0678e8e4.jpg`
          "
          :src="
            getFile({
              type: 'image',
              file: event.event_image.avatar.id,
              width: 120,
              height: 60
            })
          "
          alt=""
        />
        <img
          v-else
          :src="
            getFile({
              type: 'image',
              file: event.event_image.avatar.id,
              width: 120,
              height: 60
            })
          "
          alt=""
        />
      </div>
    </div>
    <div class="event__field">
      <div class="event__desc">
        <span class="event__icons-item">
          <FaIcon
            :iconClass="'fa fa-clock'"
            class="event__icons-item-icon event__icons-item-icon--date"
            v-tooltip="'Дата события'"
          />
          <span class="event__icons-item-value">{{ toLocaleDateTime(event.event_date) }}</span>
        </span>
        <span class="event__icons-item">
          <FaIcon
            :iconClass="'fa fa-map-marked-alt'"
            class="event__icons-item-icon"
            v-tooltip="'Место события'"
          />
          <span class="event__icons-item-value">{{ event.event_location.address }}</span>
        </span>
      </div>
    </div>
    <div class="event__field">
      <div class="event__desc">
        <span>
          <FaIcon
            :iconClass="'fa fa-info-circle'"
            class="event__icons-item-icon"
            v-tooltip="'Статус события'"
          />{{ event.event_status === 1 ? 'Создано' : '-' }}</span
        >
      </div>
    </div>
    <div class="event__field">
      <div class="event__desc">
        <span>
          <FaIcon
            :iconClass="'fa fa-users'"
            class="event__icons-item-icon"
            v-tooltip="'Кол-во приглашенных'"
          />{{ event.event_people.amount }}</span
        >
      </div>
    </div>
    <div class="event__field">
      <div class="event__desc">
        <span>
          <FaIcon
            :iconClass="'fa fa-users'"
            class="event__icons-item-icon"
            v-tooltip="'Кол-во откликнувшихся'"
          />{{ event.event_count.wish }}</span
        >
      </div>
    </div>
    <div class="event__field">
      <div class="event__desc">
        <span class="event__icons-item">
          <FaIcon
            :iconClass="[
              event.event_deposit.currency === 0 ? 'fa fa-dollar-sign' : '',
              event.event_deposit.currency === 1 ? 'fas fa-euro-sign' : '',
              event.event_deposit.currency === 2 ? 'fas fa-ruble-sign' : '',
              event.event_deposit.currency === 3 ? 'fas fa-pound-sign' : ''
            ]"
            class="event__icons-item-icon event__icons-item-icon--date"
            v-tooltip="'Депозит'"
          />

          <!--          <FaIcon-->
          <!--            :iconClass="'fa fa-dollar-sign'"-->
          <!--            class="event__icons-item-icon event__icons-item-icon&#45;&#45;date"-->
          <!--            v-tooltip="'Депозит'"-->
          <!--          />-->
          <span class="event__icons-item-value">{{ event.event_deposit.Value_with_tax }}</span>
        </span>
      </div>
    </div>
    <div class="event__field">
      <div class="event__desc" v-if="event.event_category">
        <span>{{ event.event_category.name }}</span>
      </div>
      <div class="event__desc" v-else>
        <span>-</span>
      </div>
    </div>
    <div class="event__field">
      <div class="event__actions">
        <button
          :disabled="getFilters.active === 0 || event.event_active === false"
          class="event__actions-btn event__actions-btn--decline btn-iocn--red"
          :class="{
            'btn-iocn--disabled': getFilters.active === 0 || event.event_active === false
          }"
          v-tooltip="'Отправить в архив'"
          @click="$root.$emit(EVENT_DELETE_POPUP, event.event_id)"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            class="event__actions-btn-icon"
          >
            <path
              d="M11 8L15.6 3.5C16.1 2.9 16.1 2 15.6 1.4L14.6 0.4C14-0.1 13.1-0.1 12.5 0.4L8 5 3.5 0.4C2.9-0.1 2-0.1 1.4 0.4L0.4 1.4C-0.1 2-0.1 2.9 0.4 3.5L5 8 0.4 12.5C-0.1 13.1-0.1 14 0.4 14.6L1.4 15.6C2 16.1 2.9 16.1 3.5 15.6L8 11 12.5 15.6C13.1 16.1 14 16.1 14.6 15.6L15.6 14.6C16.1 14 16.1 13.1 15.6 12.5L11 8Z"
              fill="white"
            />
          </svg>
        </button>
        <router-link
          class="event__actions-pen"
          title="Страница события"
          :to="{
            name: 'EventInfo',
            params: { eventId: event.event_id, event }
          }"
        >
          <FaIcon :iconClass="'fas fa-pencil-alt'" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from '@/components/mixins/toLocaleDateAndTime'
import toLocaleformat from '@/components/mixins/toLocaleformat'
import getImgCdnLink from '@/components/mixins/getImgCdnLink'
import { mapGetters } from 'vuex'
import { GET_CATEGORIES_BY_LANG, GET_EVENTS_FILTERS, GET_PERMISSIONS_USER } from '@/store/getter-types'
import { EVENT_DELETE_POPUP } from '@/constants/event'
export default {
  name: 'Event',
  data() {
    return {
      EVENT_DELETE_POPUP
    }
  },
  props: {
    event: {
      required: true,
      type: Object
    }
  },
  mixins: [dateFormat, toLocaleformat, getImgCdnLink],
  computed: {
    ...mapGetters({
      getAllCategories: GET_CATEGORIES_BY_LANG,
      getFilters: GET_EVENTS_FILTERS,
      user_permissions: GET_PERMISSIONS_USER
    })
  }
}
</script>

<style lang="scss">
.event__name--text {
  color: #ff665c;
  font-size: 12px;
  font-weight: bold;
}
</style>
