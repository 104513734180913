<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Передать пользователя другому оператору"
    modalClass="popup--add-user"
  >
    <div class="popup__filter">
      <!-- <label class="popup__item-title popup__filter-item">
        Кому:
        <select v-model="pickedOperator">
          <option
            v-for="operator in filteredOperators"
            v-bind:key="operator.id"
            v-bind:value="`${operator.first_name} ${operator.last_name}`"
          >
            {{ `${operator.first_name} ${operator.last_name}` }}
          </option>
        </select>
        <input
          type="text"
          class="popup__input default-input popup__filter-input"
          v-model="pickedOperator"
          readonly="readonly"
        />
      </label> -->
      <div class="popup__filter__operator">
        <div class="select">
          <div class="select__field select__field--sort">
            <span class="select__title">Чат с оператором:</span>
            <div class="select__body">
              <button type="button" class="select__field" @click="clickClose('operatorTransfer')">
                <span class="select__field-value">{{ defaultFilter({ key: 'operatorTransfer' }) }}</span>
                <i class="select__field-icon fa fa-angle-down"></i>
              </button>
              <ul
                class="select__list"
                v-if="operatorTransferSelectOpen"
                @mouseleave="clickClose('operatorTransfer')"
              >
                <li
                  class="select__item"
                  v-for="operator in currentOperators"
                  :key="operator.id"
                  @click="
                    selectFilterBuilder({
                      key: 'operatorTransfer',
                      value: operator
                    })
                  "
                >
                  <span class="select__text"> {{ operator.name }} </span>
                </li>
              </ul>
            </div>
            <span class="popup_error" v-show="errors.operator">{{ errors.operator }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="popup__item">
      <div class="popup__item-wrapp">
        <span class="popup__item-title">Пользователи</span>
        <div class="popup__item-input">
          <p class="popup__item-input-content">Выбрать всех</p>
          <input @click="checkedAll()" class="checkAll" ref="inputCheckBoxAll" type="checkbox" />
        </div>
      </div>
      <ul class="operator-profile__account-list" v-if="currentUser.length">
        <li class="operator-profile__account-list-item"></li>
        <li class="operator-profile__account-wrapper" v-for="current in currentUser" :key="current.id">
          <div class="operator-profile__account-info">
            <div class="operator__avatar">
              <img
                v-if="current.user_avatar"
                :src="
                  getFile({
                    type: 'image',
                    file: current.user_avatar.content_id,
                    width: 60,
                    height: 60
                  })
                "
                alt=""
              />
              <img v-else src="@/assets/img/anonymous_user.png" alt="" />
            </div>
            <span class="operator__name">
              <span class="operator__text">{{ current.user_name }} </span>
            </span>
          </div>
          <input
            :value="current.id"
            v-model="pickedUsers"
            ref="inputCheckBox"
            @click="checked"
            class="simple-checkbox"
            type="checkbox"
          />
        </li>
      </ul>
      <div v-else class="popup__item-list">
        <span class="popup__no-data">Пользователи не найдены</span>
      </div>
    </div>
    <div class="popup__item">
      <div class="popup__btns popup__btns--right">
        <span class="popup_error" v-if="errors.emptyAccounts">{{ errors.emptyAccounts }}</span>
        <button type="submit" @click="sendData" class="popup__btn btn-green">Передать</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { TRANSFER_OPERATOR_TO_ANOTHER } from '@/constants/event'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_TRANSFER_OPERATOR } from '@/store/action-types'
import {
  GET_OPERATOR_USERS,
  GET_CURRENT_OPERATOR_USER,
  GET_CURRENT_OPERATOR,
  GET_OPERATORS,
  GET_TMP_DEFAULT_FILTERS_OPERATOR,
  GET_TMP_FILTERS_OPERATOR
} from '@/store/getter-types'
import {
  SET_OPERATOR_CURRENT_USER,
  SET_OPERATOR_USERS,
  SET_TMP_FILTERS_OPERATORS,
  SET_RESET_FILTERS_OPERATORS_OPERATOR
} from '@/store/mutation-types'
import getImgCdnLink from '@/components/mixins/getImgCdnLink'

export default {
  name: 'TransferOperatorPopup',
  data() {
    return {
      errors: {
        operator: '',
        emptyAccounts: ''
      },
      filters: {
        user_id: '',
        email: ''
      },
      pickedOperator: null,
      filteredOperators: [],
      pickedUsers: [],
      operatorTransferSelectOpen: false
    }
  },
  components: {
    Popup
  },
  mixins: [ModalMixin, getImgCdnLink],
  mounted() {
    this.$root.$on(TRANSFER_OPERATOR_TO_ANOTHER, operator => {
      if (operator) {
        this.userCurrent = operator.id
      }
      this.operators.filter(el => {
        if (el.id != this.currentOper.id && !el.is_deleted) {
          this.filteredOperators.push(el)
        }
      })
      this.open()
    })
  },
  computed: {
    ...mapGetters({
      users: GET_OPERATOR_USERS,
      operators: GET_OPERATORS,
      currentOper: GET_CURRENT_OPERATOR,
      currentUser: GET_CURRENT_OPERATOR_USER,
      defaultFilterOperator: GET_TMP_DEFAULT_FILTERS_OPERATOR,
      tmpFilterOperator: GET_TMP_FILTERS_OPERATOR
    }),

    currentOperators() {
      let newData = {}
      for (let [index, key] of this.filteredOperators.entries()) {
        let data = {
          name: `${key.first_name} ${key.last_name}`,
          value: key.id
        }
        newData[index] = data
      }
      return newData
    },

    userID: {
      get() {
        return this.filters.user_id
      },
      set(id) {
        this.$refs.userid.value = id
        this.filters.user_id = id
      }
    }
  },
  methods: {
    ...mapActions({
      moveUser: REQUEST_TRANSFER_OPERATOR
    }),
    ...mapMutations({
      setCurrentUser: SET_OPERATOR_CURRENT_USER,
      setUsers: SET_OPERATOR_USERS,
      addTmpFilters: SET_TMP_FILTERS_OPERATORS,
      resetFilter: SET_RESET_FILTERS_OPERATORS_OPERATOR
    }),
    closePopup() {
      this.pickedUsers = []
      this.filteredOperators = []
      this.pickedOperator = ''
      this.errors.emptyAccounts = ''
      this.errors.operator = ''
      this.resetFilter()
      this.close()
    },

    clickClose(options) {
      let newOptions = this[`${options}SelectOpen`]
      this[`${options}SelectOpen`] = !newOptions
    },

    defaultFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilterOperator[key].name
      return current ? current : this.defaultFilterOperator[key].name
    },

    defaultValueFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilterOperator[key].value
      return current !== undefined ? current : ''
    },

    selectFilterBuilder(filterItem) {
      this.errors.operator = ''
      const { key } = filterItem
      switch (key) {
        case 'operatorTransfer':
          this.addTmpFilters({ ...filterItem })
          break

        default:
          break
      }
      this[`${key}SelectOpen`] = false
    },

    checked() {
      if (this.$refs.inputCheckBox.some(el => el.checked)) this.errors.emptyAccounts = ''
      else this.errors.emptyAccounts = 'Вы не выбрали аккаунт(ы) для передачи!'
      if (this.$refs.inputCheckBox.every(el => el.checked)) this.$refs.inputCheckBoxAll.checked = true
      else this.$refs.inputCheckBoxAll.checked = false
    },

    checkedAll() {
      let allCheck = document.querySelector('.checkAll')
      let elems = document.querySelectorAll('.simple-checkbox')
      if (allCheck.checked == true) {
        for (let i = 0; i <= elems.length - 1; ++i) {
          if (elems[i].checked) continue
          else {
            elems[i].checked = true
            this.pickedUsers.push(elems[i].value)
          }
        }
      } else {
        for (let i = 0; i < elems.length; i++) {
          elems[i].checked = false
        }
        this.pickedUsers = []
      }
      if (this.$refs.inputCheckBox.some(el => el.checked)) this.errors.emptyAccounts = ''
      else this.errors.emptyAccounts = 'Вы не выбрали аккаунт(ы) для передачи!'
    },

    sendData() {
      let data = {}
      this.operators.filter(user => {
        if (user.id == this.defaultValueFilter({ key: 'operatorTransfer' })) {
          data = {
            operator_id_from: this.currentOper.id,
            operator_id_to: this.defaultValueFilter({ key: 'operatorTransfer' }),
            users: this.pickedUsers
          }
        }
      })
      if (!this.defaultValueFilter({ key: 'operatorTransfer' })) {
        this.errors.operator = 'Вы не выбрали оператора!'
      }
      if (this.pickedUsers.length === 0) {
        this.errors.emptyAccounts = 'Вы не выбрали аккаунт(ы) для передачи!'
      }
      if (!this.errors.emptyAccounts && !this.errors.operator) {
        this.moveUser(data).then(res => {
          if (res.status) {
            this.$root.$emit('changeOperators', 'change')
            this.$root.$emit('closePopup')
          }
          this.closePopup()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars.scss';
@import '@/components/Modals/OperatorsPopup/checkBox.scss';
.popup_error {
  color: #ff0000;
  margin-right: auto;
}

.select__field {
  width: 280px;
}

.operator-profile-info {
  font-size: 14px;
  padding: 16px;
}
.operator-profile {
  width: 100%;
  background-color: $white;
  border-radius: 4px;
  @media (max-width: 560px) {
    width: 100%;
  }
  // .operator-profile__header
  &__header {
    padding: 20px 0 94px 0;
    position: relative;
    background-color: $blue;
    text-align: center;
    border-radius: 4px 4px 0 0;
  }
  // .operator-profile__name
  &__name {
    display: block;
    margin-bottom: 2px;
    color: $white;
    font-weight: 700;
    font-size: 22px;
  }
  // .operator-profile__email
  &__email {
    display: block;
    color: $white;
    font-size: 15px;
  }
  // .operator-profile__avatar-wrap
  &__avatar-wrap {
    display: flex;
    width: 128px;
    height: 128px;
    left: 50%;
    margin-left: -64px;
    bottom: -45px;
    position: absolute;
    border-radius: 50%;
    background-color: $white;
  }
  &__account-info {
    display: flex;
    align-items: center;
  }
  // .operator-profile__avatar-edit
  &__avatar-edit {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 2;
    right: 0;
  }
  // .operator-profile__avatar-edit-input
  &__avatar-edit-input {
    display: none;
  }
  // .operator-profile__avatar-edit-label
  &__avatar-edit-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: $white;
    cursor: pointer;
  }
  // .operator-profile__avatar
  &__avatar {
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
  }
  &__avatar img {
    object-fit: fill;
    max-height: 128px;
  }
  // .operator-profile__edit-btn
  &__edit-btn {
    color: $white;
    position: absolute;
    bottom: 10px;
    left: 16px;
    outline: none;
  }
  // .operator-profile__edit-btn-icon
  &__edit-btn-icon {
    font-size: 17px;
  }
  // .operator-profile__body
  &__body {
    padding: 64px 16px 16px 16px;
  }
  // .operator-profile__row
  &__row {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    &:nth-child(2) {
      margin-bottom: 4px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__account-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 12px;
    width: 586px;
    border: 1px solid #ddd;
    @media (max-width: 1020px) {
      width: 100%;
    }
  }
}

.popup__item {
  // margin-top: 10px;
  &:nth-child(2) {
    margin: 30px 0;
  }
}
.popup__input {
  margin-top: 5px;
}

.popup__item-wrapp {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
}

.popup--add-user {
  width: 100%;
  overflow: hidden;
}
.popup__filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
}
.popup__filter-item {
  margin-right: 10px;
}

.popup__filter-btn {
  padding-top: 20px;
}

.popup__item-input {
  display: flex;
  align-items: center;
  &-content {
    font-size: 14px;
    color: #a6aec0;
    margin-right: 6px;
  }
}

.popup__item-list {
  max-height: 500px;
  overflow-y: auto;

  font-size: 13px;
  background-color: #e7e8ec;
  justify-content: center;
  padding: 5px;
  &::-webkit-scrollbar {
    z-index: 0;
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(232, 64, 63);
    border-radius: 10px;
  }
}

.operator-profile__account-list {
  max-height: 475px;
  overflow-y: auto;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    z-index: 0;
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(232, 64, 63);
    border-radius: 10px;
  }
}

.popup__item-user {
  border: 1px solid white;
  background-color: white;
  margin-top: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &:first-child {
    margin-top: 0;
  }
  &--active {
    border-color: #1caadc;
  }
}
.popup__user-item {
  width: 25%;
}

.popup__user-text {
  color: inherit;
}
</style>
