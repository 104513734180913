<script>
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  mounted() {
    this.renderChartPie()
  },
  methods: {
    renderChartPie: function() {
      this.renderChart(this.chartData, this.options)
    }
  },
  computed: {
    reRenderDataLabels() {
      return this.chartData.datasets[0].data
    }
  },
  watch: {
    reRenderDataLabels() {
      this.renderChartPie()
    }
  }
}
</script>
