<template>
  <div
    @click="getStats(date.date)"
    class="sublist-item-date__avatar"
    :class="{ active: active === date.date }"
    v-if="
      date.counters['Диалоги'] ||
        date.counters['Автосообщения'] ||
        date.counters['Входящие'] ||
        date.counters['Исходящие']
    "
  >
    <p class="sublist-item__name">{{ date.date }}</p>
    <div class="sublist-item__counters">
      <FaIcon :iconClass="'sublist-item-date__chats far fa-list-alt'" v-tooltip="'Количество диалогов'">
        {{ date.counters['Диалоги'] }}
      </FaIcon>
      <FaIcon :iconClass="'sublist-item-date__automessage fas fa-robot'" v-tooltip="'Автосообщения'">
        {{ date.counters['Автосообщения'] }}
      </FaIcon>
      <FaIcon :iconClass="'sublist-item-date__outgoing fas fas fa-arrow-down'" v-tooltip="'Входящие'">
        {{ date.counters['Входящие'] }}
      </FaIcon>
      <FaIcon :iconClass="'sublist-item-date__incoming fas fas fa-arrow-up'" v-tooltip="'Исходящие'">
        {{ date.counters['Исходящие'] }}
      </FaIcon>
    </div>
  </div>
</template>
<script>
import getImgLink from '@/components/mixins/getImgCdnLink'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_STATISTIC_USER_BY_DATE } from '@/store/action-types'
import { GET_ACTIVE_OPERATOR } from '@/store/getter-types'
import { SET_ACTIVE_OPERATOR, SET_STATISTICS_PROFILE_MESSAGES } from '@/store/mutation-types'

export default {
  name: 'TimeListItem',

  data() {
    return {}
  },

  mixins: [getImgLink],

  props: {
    date: {
      type: Object
    },
    user_id: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      active: GET_ACTIVE_OPERATOR
    })
  },
  methods: {
    ...mapActions({
      sendData: REQUEST_STATISTIC_USER_BY_DATE
    }),
    ...mapMutations({
      setActive: SET_ACTIVE_OPERATOR,
      clearUsers: SET_STATISTICS_PROFILE_MESSAGES
    }),

    avatar(id) {
      //Не у всех юзеров есть кроп
      if (this.account.avatar.crop.x === 0 || this.account.avatar.crop.y === 0) {
        return this.getFile({ file: id, type: 'image' })
      } else {
        return this.getImgLinkCropped(id, 480, {
          ...this.account.avatar.crop
        })
      }
    },
    getStats(currentDate, id) {
      if (this.active !== currentDate) {
        this.setActive(currentDate)
        this.$root.$emit('set_id_account', currentDate, true, id)
      } else {
        this.setActive('')
      }
    }
  }
}
</script>
<style lang="scss">
.active {
  background-color: #c4f1ff;
  position: relative;
}

.sublist-item-account-date {
  display: flex;
  margin-top: 4px;
  padding: 4px 6px;
  &__online {
    position: absolute;
    &-inner {
      min-width: 14px;
      width: 14px;
      height: 14px;
      border: 4px solid transparent;
      border-radius: 50%;
      position: relative;
      border-color: #78cd51;
      background-color: #78cd51;
    }
  }
  &__photo {
    width: 60px;
    height: 60px;
    border-radius: 40px;
  }
}

.sublist-item-date {
  display: flex;
  transition: all 0.4s ease;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #c4f1ff;
  }
  &__avatar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    transition: all 0.4s ease;
    width: 100%;
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: #c4f1ff;
    }
    // &:hover {
    //   background-color: #e6e6e6;
    // }
    img {
      width: 40px;
      height: 40px;
    }
  }
  &__btn-statistic {
    border-radius: 6px;
    background-color: #58c9f3;
    border-color: #58c9f3;
    color: #fff;
    padding: 10px;
  }
  &__counters {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  &__automessage {
    color: #ffa600;
  }
  &__incoming {
    color: #ff3a3a;
  }
  &__outgoing {
    color: #58c9f3;
  }
  &__chats {
    color: #b6b6b6;
  }
}
</style>
