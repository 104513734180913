const REQUEST_APPS_FLYER = 'REQUEST_APPS_FLYER'
const REQUEST_APPS_FLYER_IOS = 'REQUEST_APPS_FLYER_IOS'
const REQUEST_USERS_ACTIVITY = 'REQUEST_USERS_ACTIVITY'
const REQUEST_USERS_COUNT = 'REQUEST_USERS_COUNT'
const REQUEST_USERS_ONLINE_BY_CITY = 'REQUEST_USERS_ONLINE_BY_CITY'
const REQUEST_PURCHASES_MARKET_PLACES = 'REQUEST_PURCHASES_MARKET_PLACES'
const REQUEST_PURCHASES_BY_CITY = 'REQUEST_PURCHASES_BY_CITY'
const REQUEST_PURCHASES_BY_COUNTRY = 'REQUEST_PURCHASES_BY_COUNTRY'
const REQUEST_PURCHASES_BY_TIME = 'REQUEST_PURCHASES_BY_TIME'
const REQUEST_PURCHASES_BY_PAYERS_PAYMENTS = 'REQUEST_PURCHASES_BY_PAYERS_PAYMENTS'
const REQUEST_USERS_ONLINE_ONE_DAY = 'REQUEST_USERS_ONLINE_ONE_DAY'
const REQUEST_USERS_ONLINE_ONE_DAY_SITE_AND_APP = 'REQUEST_USERS_ONLINE_ONE_DAY_SITE_AND_APP'
const REQUEST_NEW_USER_ACTIVE_BY_DATE = 'REQUEST_NEW_USER_ACTIVE_BY_DATE'
const REQUEST_MODERATORS_PHOTO_CHECK = 'REQUEST_MODERATORS_PHOTO_CHECK'
const REQUEST_USERS_GENDER = 'REQUEST_USERS_GENDER'
const REQUEST_ACTIVE_NEW_USER = 'REQUEST_ACTIVE_NEW_USER'
const REQUEST_USERS_PAYING = 'REQUEST_USERS_PAYING'
const REQUEST_USERS_AGE = 'REQUEST_USERS_AGE'
const REQUEST_USER_LIFETIME = 'REQUEST_USER_LIFETIME'
const REQUEST_ROYAL_USERS_INSTALL = 'REQUEST_ROYAL_USERS_INSTALL'
const REQUEST_ROYAL_USERS_INSTALL_IOS = 'REQUEST_ROYAL_USERS_INSTALL_IOS'
const REQUSET_REGS_NEW_USERS = 'REQUSET_REGS_NEW_USERS'
const REQUEST_OPERATORS_MESSAGE = 'REQUEST_OPERATORS_MESSAGE'
const REQUEST_OPERATORS_CASHES = 'REQUEST_OPERATORS_CASHES'
const REQUEST_PURCHASE_TOTAL = 'REQUEST_PURCHASE_TOTAL'
const REQUEST_MODARATION_VIDEO = 'REQUEST_MODARATION_VIDEO'
const REQUEST_PURCHASES_POINTS = 'REQUEST_PURCHASES_POINTS'
const REQUEST_PURCHASES_VIP_STATUS = 'REQUEST_PURCHASES_VIP_STATUS'
const REQUEST_PURCHASES_BY_DATE = 'REQUEST_PURCHASES_BY_DATE'
const REQUEST_COUNTERS_MESSAGES_OPERATORS = 'REQUEST_COUNTERS_MESSAGES_OPERATORS'
const REQUEST_STATISTICS_PROFILE_MESSAGES = 'REQUEST_STATISTICS_PROFILE_MESSAGES'
const REQUEST_STATISTICS_PROFILE_MESSAGES_PAGINATION = 'REQUEST_STATISTICS_PROFILE_MESSAGES_PAGINATION'
const REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS = 'REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS'
const REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS_PAGINATION =
  'REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS_PAGINATION'
const REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS = 'REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS'
const REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS_PAGINATION =
  'REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS_PAGINATION'
const REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES = 'REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES'
const REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES_PAGINATION =
  'REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES_PAGINATION'
const REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD = 'REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD'
const REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD_PAGINATION =
  'REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD_PAGINATION'
export {
  REQUEST_APPS_FLYER,
  REQUEST_APPS_FLYER_IOS,
  REQUEST_PURCHASES_BY_DATE,
  REQUEST_USERS_ACTIVITY,
  REQUEST_PURCHASES_POINTS,
  REQUEST_USERS_COUNT,
  REQUEST_USERS_ONLINE_BY_CITY,
  REQUEST_PURCHASES_MARKET_PLACES,
  REQUEST_PURCHASES_BY_CITY,
  REQUEST_PURCHASES_VIP_STATUS,
  REQUEST_PURCHASES_BY_COUNTRY,
  REQUEST_COUNTERS_MESSAGES_OPERATORS,
  REQUEST_STATISTICS_PROFILE_MESSAGES,
  REQUEST_STATISTICS_PROFILE_MESSAGES_PAGINATION,
  REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS,
  REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS_PAGINATION,
  REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS,
  REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS_PAGINATION,
  REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES,
  REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES_PAGINATION,
  REQUEST_PURCHASES_BY_TIME,
  REQUEST_PURCHASES_BY_PAYERS_PAYMENTS,
  REQUEST_USERS_ONLINE_ONE_DAY,
  REQUEST_USERS_ONLINE_ONE_DAY_SITE_AND_APP,
  REQUEST_NEW_USER_ACTIVE_BY_DATE,
  REQUEST_MODERATORS_PHOTO_CHECK,
  REQUEST_PURCHASE_TOTAL,
  REQUEST_USERS_GENDER,
  REQUEST_OPERATORS_MESSAGE,
  REQUEST_OPERATORS_CASHES,
  REQUEST_ACTIVE_NEW_USER,
  REQUEST_USERS_PAYING,
  REQUEST_USERS_AGE,
  REQUEST_USER_LIFETIME,
  REQUEST_ROYAL_USERS_INSTALL,
  REQUEST_ROYAL_USERS_INSTALL_IOS,
  REQUSET_REGS_NEW_USERS,
  REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD_PAGINATION,
  REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD,
  REQUEST_MODARATION_VIDEO
}
