<template>
  <form @submit.prevent="loginHandler">
    <div class="login">
      <div class="login__header">
        <span class="login__header-text">SKYLOVE ADMIN</span>
      </div>
      <div class="login__body">
        <div class="login__item">
          <input
            class="login__input"
            type="email"
            placeholder="Email"
            required
            autofocus
            ref="userMail"
            v-model="usermail"
          />
        </div>
        <div class="login__item">
          <input
            class="login__input"
            type="password"
            placeholder="Пароль"
            required
            ref="userPassword"
            v-model="password"
          />
        </div>
        <div class="login__error" v-if="error.length > 0">
          {{ error }}
        </div>
        <div class="login__item">
          <button type="submit" class="login__btn">
            <span class="login__btn-text">Вход</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_AUTH, REQUEST_PERMISSION_USER } from '@/store/action-types'
import { GET_PERMISSIONS_USER, GET_USER } from '@/store/getter-types'
import Permissions from '@/constants/permissions.js'
export default {
  name: 'Login',

  data: () => ({
    mail: '',
    password: '',
    error: ''
  }),

  computed: {
    ...mapGetters({
      permissions: GET_PERMISSIONS_USER,
      user: GET_USER
    }),
    usermail: {
      get() {
        return this.mail
      },
      set(mail) {
        this.$refs.userMail.value = mail
        this.mail = mail
      }
    },

    userpassword: {
      get() {
        return this.password
      },
      set(password) {
        this.$refs.userPassword.value = password
        this.password = password
      }
    }
  },

  methods: {
    ...mapActions({
      auth: REQUEST_AUTH,
      requestPermission: REQUEST_PERMISSION_USER
    }),
    loginHandler() {
      const loginUserData = {
        email: this.mail,
        password: this.password
      }

      this.auth(loginUserData).then(response => {
        if (!response.error) {
          this.requestPermission().then(response => {
            let rout = []
            if (
              // this.permissions.length === 1 && this.permissions.includes(50)
              this.user.position_code === 'operator'
            ) {
              this.error = 'У вас нет доступа!'
              window.location = 'https://operator.skylove.su/login'
              return
            } else if (this.user.position_code === 'moderator') {
              window.location = `${process.env.VUE_APP_DEPLOY_URL}/moderator/photo`
              return
            }
            this.permissions.map(m => {
              this.$router.options.routes.map(f => {
                if (f.meta && f.meta.permission_lvl) {
                  f.meta.permission_lvl.map(item => {
                    if (item === m) {
                      rout.push(f.path)
                    }
                  })
                }
              })
            })
            if (rout.length) {
              let unique = [...new Set(rout)]
              let isOperator =
                // this.permissions.length === 4 &&
                // this.permissions.includes(45) &&
                // this.permissions.includes(30)
                this.permissions.includes(Permissions.UserListWithTransition) ||
                this.permissions.includes(Permissions.UserListWithoutTransition)
              if (isOperator) {
                unique = unique.filter(f => f !== '/' || f !== '/users')
              }
              let arrRoutes = []
              this.$router.options.routes.map(m => {
                arrRoutes.push(m.path)
              })
              unique.sort((a, b) => arrRoutes.indexOf(a) - arrRoutes.indexOf(b))
              if (isOperator) {
                this.$router.push({ path: unique[1] })
              } else {
                this.$router.push({ path: unique[0] })
              }
              // return this.$router.push({ path: '/users' })
            }
          })
        } else {
          this.error = 'Неверный логин или пароль'
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import './Login.scss';
</style>
