<template>
  <div class="lang-wrap">
    <div class="lang">
      <LanguagesHeader />
      <div class="lang__table">
        <span>Язык</span>
        <span>Код языка по ISO</span>
      </div>
      <div class="lang__list" v-if="isLoaded && langs.length">
        <LanguagesItem v-for="lang in langs" :key="lang.id" :lang="lang" />
      </div>
      <div class="lang__no-reasons" v-if="!langs.length && isLoaded">
        <span class="lang__text">Нет данных...</span>
      </div>
      <div v-if="errorMessage" class="lang__no-reasons">
        <span class="lang__text">Ошибка при загрузке языков!</span>
      </div>
    </div>
    <LanguageDelPopup />
    <LanguageAddPopup />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { GET_LANGUAGES } from '@/store/getter-types'
import { REQUEST_ALL_LANGUAGES } from '@/store/action-types'
import LanguagesHeader from '@/components/Lang/LanguagesHeader'
import LanguagesItem from '@/components/Lang/LanguagesItem'
import LanguageDelPopup from '@/components/Modals/LangPopup/LanguageDelPopup'
import LanguageAddPopup from '@/components/Modals/LangPopup/LanguageAddPopup'
import store from '@/store'

export default {
  name: 'interfaceLanguages',
  components: {
    LanguagesHeader,
    LanguagesItem,
    LanguageDelPopup,
    LanguageAddPopup
  },
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_ALL_LANGUAGES).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      langs: GET_LANGUAGES
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
@import '@/components/Lang/Languages.scss';

.lang__no-reasons {
  min-height: 64px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
}
.lang__text {
  margin: 0 auto;
}
.lang-wrap {
  padding: 16px;
  max-width: 1140px;
  margin: 0 auto;
  .lang__item-name {
    &:first-child {
      padding-left: 30px;
      width: 50%;
    }
    &:nth-child(2) {
      width: 50%;
    }
  }
  .lang__item-content {
    justify-content: flex-start;
  }
}
</style>
