<template>
  <div class="analytics">
    <span>Страница Аналитики</span>
  </div>
</template>
<script>
export default {
  name: 'Supportanalytics'
}
</script>
