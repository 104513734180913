<template>
  <div v-if="currentUser.count.chat" class="app-user__chats">
    <div class="user-chats">
      <ul class="user-chats__list" ref="viewScrollContent" @scroll="scroll($event)">
        <li
          class="user-chats__item"
          ref="userItem"
          v-for="chat in chats"
          :key="chat.chat_id"
          @click="changeSelected(chat)"
          :class="{
            selected: selectedChat == chat
          }"
        >
          <div class="user-chats__user">
            <div class="user-chats__image">
              <div class="app-user-photo__badge-block-top" v-if="chat.user.avatar.status !== null">
                <div
                  class="app-user-photo__badge"
                  :class="{
                    postponed: chat.user.avatar.status === -1,
                    verification: chat.user.avatar.status === 1,
                    'forbidden-avatar': chat.user.avatar.status === 2,
                    'forbidden-show': chat.user.avatar.status === 3,
                    'deferred-block': chat.user.avatar.status === 4,
                    'status-age': chat.user.avatar.status === 6
                  }"
                ></div>
                <div class="app-user-photo__badge-icon">
                  <FaIcon
                    v-if="chat.user.avatar.status !== 0 || chat.user.avatar.status !== null"
                    :iconClass="[
                      chat.user.avatar.status === -1 ? 'fa fa-clock' : '',
                      chat.user.avatar.status === 1 ? 'fa fa-user-check' : '',
                      chat.user.avatar.status === 2 ? 'fa fa-user-times' : '',
                      chat.user.avatar.status === 3 ? 'fa fa-user-slash' : '',
                      chat.user.avatar.status === 4 ? 'fa fa-exclamation-triangle' : '',
                      chat.user.avatar.status === 6 ? 'fa-solid fa-baby' : ''
                    ]"
                    class="app-user-profile__gender-icon"
                  />
                </div>
              </div>
              <div class="user-chats__unread" v-if="chat.messages_count && chat.messages_count.unread">
                {{ chat.messages_count.unread > 99 ? '99+' : chat.messages_count.unread }}
              </div>
              <img
                class="user-chats__avatar"
                :src="
                  getFile({
                    type: 'image',
                    file: chat.user.avatar.id,
                    width: 150,
                    height: 100
                  })
                "
                alt=""
              />
            </div>
            <div class="user-chats__text">
              <div class="user-chats__companion">
                <a
                  v-if="isOperator"
                  :href="`${link}/users/${chat.user.id}/`"
                  target="_blank"
                  class="user-chats__companion-name"
                  >{{ chat.user.name }} ({{ chat.user.age }})</a
                >
                <span v-else class="user-chats__companion-name"
                  >{{ chat.user.name }} ({{ chat.user.age }})</span
                >
                <span class="user-chats__count user-chats__companion-message">{{
                  chat.messages_count ? chat.messages_count.in : 0
                }}</span>
              </div>
              <div class="user-chats__current-user">
                <span class="user-chats__current-user-name">{{ currentUser.name }}</span>
                <span class="user-chats__count user-chats__current-user-message">{{
                  chat.messages_count ? chat.messages_count.out : 0
                }}</span>
              </div>
              <!-- <div class="user-chats__counters">
                <span class="user-chats__message-title">Последнее сообщение: </span>
                <span class="user-chats__message-time">28.03.2022, 13:07</span>
              </div> -->
            </div>
          </div>
        </li>
      </ul>
    </div>
    <AppUserChat
      v-if="chats.length"
      :messagesChat="messagesChat"
      :currentUser="currentUser"
      :selectedChat="selectedChat"
      :key="newCount"
    />
  </div>
</template>
<script>
import getImgLink from '@/components/mixins/getImgCdnLink'
import AppUserChat from '@/components/App-user/AppUserChat'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_CHAT_MESSAGES, REQUEST_USER_CHATS } from '@/store/action-types'
import {
  GET_CHAT_MESSAGES,
  GET_USER_CHATS,
  GET_PERMISSIONS_USER,
  GET_FILTER_PAGE
} from '@/store/getter-types'
import { SET_CHAT_MESSAGES } from '@/store/mutation-types'

export default {
  name: 'AppUserChats',
  components: {
    AppUserChat
  },
  props: {
    currentUser: {
      type: Object
    }
  },
  data: () => ({
    selectedChat: null,
    isLoaded: false,
    newCount: 0,
    disableScroll: false,
    loadDataByScroll: true,
    loadingUsers: false,
    currentPage: {},
    page_counts: 0,
    link: `${process.env.VUE_APP_DEPLOY_URL}`
  }),
  mixins: [getImgLink],
  computed: {
    ...mapGetters({
      messagesChat: GET_CHAT_MESSAGES,
      chats: GET_USER_CHATS,
      permission_lvl: GET_PERMISSIONS_USER,
      get_new_page: GET_FILTER_PAGE
    }),
    isOperator: function() {
      if (this.permission_lvl && this.permission_lvl.includes(103)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    selectedChat: function() {
      this.newCount = this.newCount + 1
    }
  },
  methods: {
    ...mapActions({
      newChatMessage: REQUEST_CHAT_MESSAGES,
      getNewChats: REQUEST_USER_CHATS
    }),
    ...mapMutations({
      deleteOld: SET_CHAT_MESSAGES
    }),
    changeSelected(chat) {
      this.selectedChat = chat
      let data = {
        id: chat.chat_id,
        user: this.currentUser.id,
        limit: 1500,
        start: 0
      }
      this.deleteOld([])
      this.newChatMessage(data)
    },
    // pageCount() {
    //   let chats = this.currentUser.count.chat
    //   this.pagae_count = 0
    //   while (chats > 0) {
    //     chats = chats - 30
    //     this.pagae_count++
    //   }
    //   return this.pagae_count
    //Было в условии if (response.data.chats.length === 0)
    //&& data.page <= this.pageCount()
    // },
    scroll(event) {
      if (!this.disableScroll) {
        const list = this.$refs.viewScrollContent
        const scrollBottom = list.scrollHeight - (list.scrollTop + list.offsetHeight)
        const scrollTop = list.scrollTop

        const firstNodeOffset = this.$refs.userItem[0].offsetTop
        const firstNodeScrollHeight = this.$refs.userItem[0].scrollHeight
        if (scrollBottom + firstNodeOffset < firstNodeOffset + firstNodeScrollHeight) {
          this.disableScroll = true
          if (this.loadDataByScroll && !this.loadingUsers) {
            let data = this.get_new_page
            if (!this.get_new_page.page) {
              data.page = 0
            }
            data.page = ++this.get_new_page.page
            this.loadingUsers = true
            let payload = {
              page: data.page,
              id: this.currentUser.id
            }
            this.getNewChats(payload).then(response => {
              if (response.data.chats.length === 0) {
                this.loadDataByScroll = false
              }
              this.$nextTick(() => {
                let newbottom = list.scrollHeight - (list.offsetHeight + scrollTop + scrollBottom)
                list.scrollTo({
                  bottom: newbottom
                })
                this.loadingUsers = false
                this.disableScroll = false
              })
            })
          }
        }
      }
    }
  }
  // beforeMount() {
  //   this.pageCount()
  // }
}
</script>
<style lang="scss">
@import './AppUserPhoto';
.user-chats {
  width: 48%;
  @media (max-width: 1020px) {
    margin-top: 12px;
    width: 100%;
  }
}
.user-chats__image {
  width: 100%;
  height: 198px;
  position: relative;
}
.user-chats__unread {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 30px;
  max-width: 30px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: forestgreen;
  color: white;
  font-weight: 500;
  font-size: 12px;
  border-radius: 10px;
}
.user-chats__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // max-height: 530px;
  max-height: 100%;
  overflow: auto;
}
.user-chats__text {
  padding: 15px 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;

  color: #505d70;
}
.user-chats__item {
  border-radius: 5px;
  margin: 5px;
  background-color: white;
  display: flex;
  flex-direction: row;
  border: 2px solid transparent;
}

.user-chats__user {
  width: 250px;
}
.user-chats__avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.user-chats__companion,
.user-chats__current-user,
.user-chats__counters {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.user-chats__count {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

.user-chats__companion-message {
  color: #b64c4c;
}

.user-chats__current-user-message {
  color: #288f98;
}
.user-chats__companion-name {
  text-decoration: none;
  color: inherit;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
}
.user-chats__current-user-name {
  text-decoration: none;
  color: inherit;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
}

.selected {
  border: 2px solid #1caadc;
}
</style>
