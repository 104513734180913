<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    title=""
    description=""
    modalClass="popup--medium popup--employee popup--scroll"
  >
    <form class="employee-profile" @submit.prevent="createEmployeeSubmitHandler" autocomplete="off">
      <div class="employee-profile__header">
        <span class="employee-profile__name"> Новый сотрудник</span>
        <div class="employee-profile__avatar-wrap">
          <div class="employee-profile__avatar">
            <img v-if="isAvatar" :src="preview" alt="" />
            <img v-else src="@/assets/img/anonymous_user.png" alt="" />
          </div>
          <div class="employee-profile__avatar-edit">
            <input
              class="employee-profile__avatar-edit-input"
              type="file"
              id="avatar-input"
              accept=".jpg, .jpeg, .png"
              ref="downloadavatar"
              @change="fileUploadHandler"
            />
            <label class="employee-profile__avatar-edit-label" for="avatar-input">
              <i class="fa fa-pen"></i>
            </label>
          </div>
        </div>
      </div>
      <div class="employee-profile__body"></div>
      <div class="employee-profile__edit">
        <div class="employee-profile__edit-body">
          <div class="employee-profile__edit-group">
            <label for="employee-name" class="employee-profile__edit-text">Имя</label>
            <input
              id="employee-name"
              type="text"
              class="employee-profile__edit-input"
              v-model="createEmployeename"
              ref="createemployeename"
              required
            />
          </div>
          <div class="employee-profile__edit-group">
            <label for="employee-lastname" class="employee-profile__edit-text">Фамилия</label>
            <input
              id="employee-lastname"
              type="text"
              class="employee-profile__edit-input"
              v-model="createEmployeeLastname"
              ref="createemployeelastname"
              required
            />
          </div>
          <div class="employee-profile__edit-group">
            <label for="employee-email" class="employee-profile__edit-text">Email</label>
            <input
              id="employee-email"
              type="email"
              class="employee-profile__edit-input"
              v-model="createEmployeeEmail"
              ref="createemployeeemail"
              readonly
              onfocus="if (this.hasAttribute('readonly')) { this.removeAttribute('readonly'); this.blur(); this.focus();}"
              autocomplete="off"
              required
            />
          </div>
          <div class="employee-profile__edit-group">
            <label for="employee-password" class="employee-profile__edit-text">Пароль</label>
            <div class="employee-password__wrapper" v-if="!viewPassword">
              <input
                id="employee-password"
                type="password"
                class="employee-profile__edit-input"
                v-model="createEmployeePassword"
                readonly
                onfocus="if (this.hasAttribute('readonly')) { this.removeAttribute('readonly'); this.blur(); this.focus();}"
                autocomplete="new-password"
                ref="createemployeepassword"
              />
              <span @click="viewPassword = !viewPassword" class="employee-password__icon"
                ><i class="fa fa-eye"></i
              ></span>
            </div>
            <div class="employee-password__wrapper" v-if="viewPassword">
              <input
                id="employee-password"
                type="text"
                class="employee-profile__edit-input"
                v-model="createEmployeePassword"
                readonly
                onfocus="if (this.hasAttribute('readonly')) { this.removeAttribute('readonly'); this.blur(); this.focus();}"
                autocomplete="new-password"
                ref="createemployeepassword"
              />
              <span @click="viewPassword = !viewPassword" class="employee-password__icon"
                ><i class="fa fa-eye"></i
              ></span>
            </div>
          </div>
          <div class="employee-profile__edit-group">
            <span class="employee-profile__edit-select-title">Должность</span>
            <div class="employee-profile__edit-select">
              <button
                type="button"
                class="employee-profile__edit-value"
                @click="positionOptions = !positionOptions"
              >
                <span class="employee-profile__edit-value-text">{{
                  defaultEmployeePossition({ key: 'position' })
                }}</span>
                <i class="fa fa-angle-down employee-profile__edit-select-icon"></i>
              </button>
              <ul
                class="employee-profile__edit-select-list"
                v-if="positionOptions"
                @mouseleave="positionOptions = !positionOptions"
                :class="{ active: positionOptions }"
              >
                <li
                  v-for="position in employeePositions"
                  :key="position.id"
                  @click="selectItemHandler({ key: 'position', value: position })"
                  class="employee-profile__edit-select-item"
                >
                  <span class="employee-profile__edit-select-text">{{ position.position_name }}</span>
                </li>
              </ul>
              <p v-if="error" class="error">{{ error }}</p>
            </div>
          </div>
          <div v-if="isModerator" class="employee-profile__edit-group employee-profile__edit-group--togle">
            <span class="employee-profile__edit-text">Ночная смена</span>
            <label class="toggle">
              <input class="toggle__checkbox" type="checkbox" v-model="night_shift" />
              <span class="toggle__toggler"></span>
            </label>
          </div>
          <div class="employee-profile__edit-group employee-profile__edit-group--btns">
            <button
              type="submit"
              class="employee-profile__edit-action-btn employee-profile__edit-action-btn--save"
            >
              <span class="employee-profile__edit-action-btn-text">Добавить</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import {
  SET_EMPLOYEE_SETTINGS,
  SET_CLEAR_EMPLOYEE_DATA,
  SET_TMP_ROLE_EMPLOYEE,
  SET_TMP_ROLE_EMPLOYEE_RESET
} from '@/store/mutation-types'
import { REQUEST_CREATE_EMPLOYEE, REQUEST_CHECK_EMAIL } from '@/store/action-types'
import {
  GET_EMPLOYEE_ROLES,
  GET_EMPLOYEE_ROLES_DEFAULT,
  GET_TMP_FILTER_INFO_ROLES,
  GET_EMPLOYEE_TMP_ROLES,
  GET_EMPLOYEE_POSITIONS
} from '@/store/getter-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EMPLOYEE_POPUP_EVENT, EMAIL_ERROR_POPUP } from '@/constants/event'
import store from '@/store'

export default {
  name: 'NewEmployeePopup',
  data: () => ({
    night_shift: false,
    // currentEmployee: null,
    positionOptions: false,
    viewPassword: false,
    isModerator: false,
    checkPosition: null,
    isAvatar: false,
    preview: null,
    error: '',

    employeeData: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      position: '',
      file: ''
    },
    EMAIL_ERROR_POPUP
  }),
  components: {
    Popup
  },
  computed: {
    ...mapGetters({
      employeeRoles: GET_EMPLOYEE_ROLES,
      employeePositions: GET_EMPLOYEE_POSITIONS,
      employeeRolesDefault: GET_TMP_FILTER_INFO_ROLES,
      employeeTmpRoles: GET_EMPLOYEE_TMP_ROLES
    }),

    createEmployeename: {
      get() {
        return this.employeeData.first_name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.createemployeename.value = name
        this.employeeData.first_name = name
      }
    },
    createEmployeeLastname: {
      get() {
        return this.employeeData.last_name
      },
      set(lastname) {
        lastname = lastname.replace(/\d/g, '')
        this.$refs.createemployeelastname.value = lastname
        this.employeeData.last_name = lastname
      }
    },
    createEmployeeEmail: {
      get() {
        return this.employeeData.email
      },
      set(email) {
        this.$refs.createemployeeemail.value = email
        this.employeeData.email = email
      }
    },
    createEmployeePassword: {
      get() {
        return this.employeeData.password
      },
      set(password) {
        this.$refs.createemployeepassword.value = password
        this.employeeData.password = password
      }
    }
  },
  mixins: [ModalMixin],
  watch: {
    checkPosition: function() {
      if (
        this.defaultEmployeePossition({ key: 'position' }) === `605852be8e3f5266db67597c` ||
        this.defaultEmployeePossition({ key: 'position' }) === `605852be8e3f5266db67597d`
      ) {
        this.isModerator = true
      } else {
        this.isModerator = false
        this.night_shift = false
      }
    }
  },

  methods: {
    ...mapMutations({
      // addTmpEmployeeSettings: SET_TMP_ROLE_EMPLOYEE
      addTmpEmployeeSettings: SET_TMP_ROLE_EMPLOYEE,
      reset: SET_TMP_ROLE_EMPLOYEE_RESET
    }),
    ...mapActions({
      createNewEmployee: REQUEST_CREATE_EMPLOYEE,
      checkEmail: REQUEST_CHECK_EMAIL
    }),

    defaultEmployeePossition(settings) {
      const { key } = settings
      const current = this.employeeTmpRoles[key].position_name
      this.checkPosition = this.employeeTmpRoles[key].position_name
      return current ? current : this.employeeRolesDefault[key].position_name
    },

    defaultEmployeePossitionValue(settings) {
      const { key } = settings
      const current = this.employeeTmpRoles[key].position_value
      return current ? current : this.employeeRolesDefault[key].position_value
    },

    selectItemHandler(selectItem) {
      this.error = ''
      const { key } = selectItem
      switch (key) {
        case 'position':
          this.addTmpEmployeeSettings({ ...selectItem })
          break
        default:
          break
      }
      this[`${key}Options`] = false
    },
    closePopup() {
      this.night_shift = false
      this.positionOptions = false
      store.commit(SET_CLEAR_EMPLOYEE_DATA)
      this.employeeData.first_name = ''
      this.employeeData.last_name = ''
      this.employeeData.email = ''
      this.employeeData.password = ''
      this.employeeData.position = ''
      this.employeeData.file = ''
      this.preview = null
      this.isAvatar = false
      this.viewPassword = false
      this.checkPosition = ''
      this.error = ''
      this.reset()
      this.close()
    },

    fileUploadHandler() {
      const avatar = this.$refs.downloadavatar.files[0]
      this.preview = URL.createObjectURL(avatar)
      this.isAvatar = true
      this.employeeData.file = avatar
    },

    createEmployeeSubmitHandler() {
      const newEmployeeData = {
        profile: {
          email: this.employeeData.email,
          password: this.employeeData.password,
          first_name: this.employeeData.first_name,
          last_name: this.employeeData.last_name,
          night_shift: this.night_shift,
          position: this.defaultEmployeePossitionValue({ key: 'position' }),
          avatar: this.employeeData.file
        }
      }

      const formData = new FormData()
      const dataProfile = newEmployeeData.profile
      if (dataProfile.avatar) {
        formData.append('avatar', dataProfile.avatar)
      }
      if (dataProfile.password) {
        formData.append('password', dataProfile.password)
      }
      if (dataProfile.first_name) {
        formData.append('first_name', dataProfile.first_name)
      }
      if (dataProfile.last_name) {
        formData.append('last_name', dataProfile.last_name)
      }
      if (dataProfile.position) {
        formData.append('position', dataProfile.position)
      }
      if (dataProfile.night_shift) {
        formData.append('night_shift', dataProfile.night_shift)
      }
      if (dataProfile.email) {
        formData.append('email', dataProfile.email)
        this.checkEmail({ email: this.employeeData.email }).then(response => {
          if (response.data.exist) {
            this.$root.$emit(this.EMAIL_ERROR_POPUP)
          } else {
            if (!this.defaultEmployeePossitionValue({ key: 'position' })) {
              this.error = 'Выберите должность сотрудника!'
            } else {
              this.createNewEmployee(formData).then(response => {
                if (response.status) {
                  this.$root.$emit('ChangeEmployee', 'newEmployee')
                }
                if (response.error === 0) {
                  this.closePopup()
                }
              })
            }
          }
        })
      }
    }
  },
  beforeDestroy() {
    this.closePopup()
  },

  mounted() {
    this.$root.$on(EMPLOYEE_POPUP_EVENT, () => this.open())
  }
}
</script>
<style lang="scss">
@import './EditEmployee.scss';

.error {
  color: #ff3939;
  font-size: 14px;
}
</style>
