export default {
  methods: {
    moneyIcon(value) {
      let valueText = null
      switch (value) {
        case 0:
          valueText = '$'
          break
        case 1:
          valueText = '€'
          break
        case 2:
          valueText = '₽'
          break
        case 3:
          valueText = '£'
          break
        default:
          break
      }
      return valueText
    }
  }
}
