<template>
  <div class="ev-cat__item">
    <div class="ev-cat__item-body" :class="{ 'ev-cat__item-body--active': showCategory }">
      <button
        class="ev-cat__item-arrow"
        @click="eventcat.subcategories == 0 ? showCategory : (showCategory = !showCategory)"
        :class="{ 'ev-cat__item-arrow--up': showCategory }"
      >
        <i class="fas fa-sort-down"></i>
      </button>
      <div class="ev-cat__item-content">
        <div class="ev-cat__item-name">
          <div class="ev-cat__item-img">
            <img
              :src="
                getFile({
                  type: 'image',
                  file: eventcat.image,
                  width: 100
                })
              "
              alt=""
            />
          </div>
          <span class="ev-cat__item-title">{{ eventcat.locale.title }}</span>
        </div>
        <div class="ev-cat__item-btns">
          <button
            class="ev-cat__item-btn ev-cat__item-btn--green"
            @click="$root.$emit(EVENT_SUBCATEGORY_ADD_POPUP, eventcat.id)"
          >
            <i class="ev-cat__item-btn-icon far fa-plus-square"></i>
          </button>
          <button
            class="ev-cat__item-btn ev-cat__item-btn--yellow"
            @click="$root.$emit(EVENT_CATEGORY_IMAGE_POPUP, eventcat)"
          >
            <i class="ev-cat__item-btn-icon far fa-image"></i>
          </button>
          <button
            class="ev-cat__item-btn ev-cat__item-btn--blue"
            @click="$root.$emit(EVENT_CATEGORY_NAME_POPUP, eventcat)"
          >
            <i class="ev-cat__item-btn-icon far fa-keyboard"></i>
          </button>
          <button
            class="ev-cat__item-btn ev-cat__item-btn--red"
            @click="$root.$emit(EVENT_CATEGORY_DELETE, eventcat.id)"
          >
            <i class="ev-cat__item-btn-icon far fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="ev-cat__item-sublist" v-if="showCategory">
      <EventSubCategory
        v-for="eventsubcat in eventcat.subcategories"
        :key="eventsubcat.id"
        :eventsubcat="eventsubcat"
      />
    </div>
  </div>
</template>
<script>
import {
  EVENT_CATEGORY_NAME_POPUP,
  EVENT_CATEGORY_IMAGE_POPUP,
  EVENT_SUBCATEGORY_ADD_POPUP,
  EVENT_SUBCATEGORY_NAME_POPUP,
  EVENT_SUBCATEGORY_IMAGE_POPUP,
  EVENT_CATEGORY_DELETE
} from '@/constants/event'
import EventSubCategory from '@/components/Events/EventSubCategory'
import getImgLink from '@/components/mixins/getImgCdnLink'
export default {
  data() {
    return {
      showCategory: false,
      EVENT_CATEGORY_NAME_POPUP,
      EVENT_CATEGORY_IMAGE_POPUP,
      EVENT_SUBCATEGORY_ADD_POPUP,
      EVENT_SUBCATEGORY_NAME_POPUP,
      EVENT_SUBCATEGORY_IMAGE_POPUP,
      EVENT_CATEGORY_DELETE
    }
  },
  name: 'EventCategory',
  props: {
    eventcat: {
      required: true,
      type: Object
    }
  },
  components: {
    EventSubCategory
  },
  mixins: [getImgLink]
}
</script>

<style lang="scss"></style>
