const GET_AUTO_MESSAGES_LIST = 'GET_AUTO_MESSAGES_LIST'
const GET_MESSAGES_COUNT = 'GET_MESSAGES_COUNT'
const GET_FILTERS_DEFAULT_AUTO_MESSAGE = 'GET_FILTERS_DEFAULT_AUTO_MESSAGE'
const GET_TMP_FILERS_AUTO_MESSAGE = 'GET_TMP_FILERS_AUTO_MESSAGE'
const GET_CURRENT_FILERS_AUTO_MESSAGE = 'GET_CURRENT_FILERS_AUTO_MESSAGE'
const GET_USER_GENDER_AUTO_MESSAGE = 'GET_USER_GENDER_AUTO_MESSAGE'
const GET_FILTER_PAGE_AUTO_MESSAGE = 'GET_FILTER_PAGE_AUTO_MESSAGE'
const GET_FILTERS_INFO_AUTO_MESSAGE = 'GET_FILTERS_INFO_AUTO_MESSAGE'
const GET_FILTERS_COUNT = 'GET_FILTERS_COUNT'

export {
  GET_AUTO_MESSAGES_LIST,
  GET_MESSAGES_COUNT,
  GET_FILTERS_DEFAULT_AUTO_MESSAGE,
  GET_TMP_FILERS_AUTO_MESSAGE,
  GET_CURRENT_FILERS_AUTO_MESSAGE,
  GET_USER_GENDER_AUTO_MESSAGE,
  GET_FILTER_PAGE_AUTO_MESSAGE,
  GET_FILTERS_INFO_AUTO_MESSAGE,
  GET_FILTERS_COUNT
}
