import Request from '../helpers/Request'
import BaseService from './BaseService'

class PositionService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getPositions() {
    const possitionsUrl = `/api/v1/management/positions/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }

    return await Request.get(possitionsUrl, { headers })
  }

  async createPosition(position) {
    const positionAddUrl = '/api/v1/management/positions/add/'
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.post(positionAddUrl, position, { headers })
  }

  async deletePositionById(id) {
    const employeeDestroyUrl = `/api/v1/management/positions/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(employeeDestroyUrl, { headers })
  }

  async updatePosition(position) {
    const positionUpdateUrl = `/api/v1/management/positions/${position.id}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.put(positionUpdateUrl, position.data, {
      headers
    })
  }
}

export default PositionService
