<template>
  <div>
    <div class="app-user-wrap" v-if="!errorMessage && isLoaded">
      <AppUserBtns v-if="permisisons.includes(105)" :currentUser="currentuser" :userPhotos="userPhotos" />
      <AppUserHeader :currentUser="currentuser" :chats="userChats.chats" />
      <div class="app-user-body">
        <div class="app-user-body__info">
          <AppUserInfo :currentUser="currentuser" />
          <AppUserMap :currentUser="currentuser" />
        </div>
        <AppUserPushes :currentUser="currentuser" />
        <AppUserPay
          :userDeposit="userDeposit"
          :userPayouts="userPayouts"
          :errorMessageDeposit="errorMessageDeposit"
          :errorMessagePayouts="errorMessagePayouts"
        />
      </div>
      <div class="app-user-gallery-wrap">
        <div
          v-if="!errorMessagePhotos && userPhotos.length"
          :class="{
            'app-user-gallery_active': this.stylesChat.isopened,
            'app-user-gallery': !this.stylesChat.isopened
          }"
          @mousewheel="mouseHandler"
          ref="gallery"
        >
          <AppUserPhoto
            v-for="photo in userPhotos"
            :photo="photo"
            :currentUser="currentuser"
            :key="photo.id"
          />
        </div>
        <div
          v-else-if="!errorMessagePhotos && !userPhotos.length"
          class="app-user__error app-user__error--big"
        >
          <span class="app-user__error-text">Нет фотографий</span>
        </div>
        <div v-else class="app-user__error app-user__error--big">
          <span class="app-user__error-text">Ошибка при загрузке фотографий</span>
        </div>
      </div>
      <div v-if="permisisons.includes(104) && userChats.length">
        <div class="app-user__chats" v-if="!errorMessageChats">
          <AppUserChats :chats="userChats" :currentUser="currentuser" v-if="isLoadedChat" />
        </div>
        <div v-else class="app-user__error app-user__error--big">
          <span class="app-user__error-text">Ошибка при загрузке чатов</span>
        </div>
      </div>

      <UserVerifyPopup />
      <UserBlockPhotoPopup />
      <UserVipPopup />
      <UserScorePopup />
      <UserMapPopup />
      <UserGenderPopup />
      <UserProfileBanPopup />
      <UserDeviceBanPopup />
      <UserDeletePopup />
      <UserPanelPopup />
      <UserAppPopup />
      <UserRatingPopup />
      <UserGetVerify />
      <UserEventsRatingPopup />
      <UserPhotoDelPopup />
      <UserBuyListPopup />
      <UesrHistoryTransaction />
      <UserAlertPopup />
      <UserHideByMapPopup />
      <UserActionApproveStatusPopup />
      <DeclineVerifyByAge />
      <UserCallOnlyVipPopup />
    </div>
    <div class="app-user__error" v-if="!isLoaded && !errorMessage">
      <span class="app-user__error-text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="app-user__error">
      <span class="app-user__error-text">Ошибка при загрузке пользователя</span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import 'leaflet/dist/leaflet.css'
import {
  REQUEST_ID_USER,
  REQUEST_USER_DEPOSIT,
  REQUEST_USER_PAYOUTS,
  REQUEST_USER_PURCHASES,
  REQUEST_USER_PHOTOS,
  REQUEST_USER_CHATS,
  REQUEST_USER_TRANSACTION_KIND
} from '@/store/action-types'
import {
  GET_CURRENT_USER,
  GET_USER_DEPOSIT,
  GET_USER_PAYOUTS,
  GET_USER_PHOTOS,
  GET_USER_CHATS,
  GET_PERMISSIONS_USER,
  GET_USERCHATS_STYLES,
  GET_TRANSACTION_KIND
} from '@/store/getter-types'
import { SET_USERCHATS_STYLE, SET_USER_CHATS_CLEAR } from '@/store/mutation-types'
import AppUserBtns from '@/components/App-user/App-user-btns'
import AppUserHeader from '@/components/App-user/App-user-header'
import AppUserInfo from '@/components/App-user/App-user-info'
import AppUserMap from '@/components/App-user/App-user-map'
import AppUserPhoto from '@/components/App-user/AppUserPhoto'
import UserVerifyPopup from '@/components/Modals/UserPopup/UserVerifyPopup'
import UserGetVerify from '@/components/Modals/UserPopup/UserGetVerify'
import UserBlockPhotoPopup from '@/components/Modals/UserPopup/UserBlockPhotoPopup'
import UesrHistoryTransaction from '@/components/Modals/UserPopup/UesrHistoryTransaction'
import UserVipPopup from '@/components/Modals/UserPopup/UservipPopup'
import UserScorePopup from '@/components/Modals/UserPopup/UserScorePopup'
import DeclineVerifyByAge from '@/components/Modals/UserPopup/DeclineVerifyByAge'
import UserMapPopup from '@/components/Modals/UserPopup/UserMapPopup'
import UserGenderPopup from '@/components/Modals/UserPopup/UserGenderPopup'
import UserCallOnlyVipPopup from '@/components/Modals/UserPopup/UserCallOnlyVipPopup'
import UserProfileBanPopup from '@/components/Modals/UserPopup/UserProfileBanPopup'
import UserDeviceBanPopup from '@/components/Modals/UserPopup/UserDeviceBanPopup'
import UserDeletePopup from '@/components/Modals/UserPopup/UserDeletePopup'
import UserPanelPopup from '@/components/Modals/UserPopup/UserPanelPopup'
import UserAppPopup from '@/components/Modals/UserPopup/UserAppPopup'
import UserRatingPopup from '@/components/Modals/UserPopup/UserRatingPopup'
import UserEventsRatingPopup from '@/components/Modals/UserPopup/UserEventsRatingPopup'
import UserPhotoDelPopup from '@/components/Modals/UserPopup/UserPhotoDelPopup'
import UserHideByMapPopup from '@/components/Modals/UserPopup/UserHideByMapPopup'
import AppUserPay from '@/components/App-user/AppUserPay'
import AppUserChats from '@/components/App-user/AppUserChats'
import UserBuyListPopup from '@/components/Modals/UserPopup/UserBuyListPopup'
import UserAlertPopup from '@/components/Modals/UserPopup/UserAlertPopup'
import AppUserPushes from '@/components/App-user/AppUserPushes'
import UserActionApproveStatusPopup from '@/components/Modals/UserPopup/UserActionApproveStatusPopup'
import store from '@/store'

export default {
  name: 'UserItem',
  components: {
    UserBuyListPopup,
    UserRatingPopup,
    AppUserBtns,
    AppUserHeader,
    AppUserInfo,
    AppUserMap,
    UserAlertPopup,
    AppUserPhoto,
    DeclineVerifyByAge,
    UserVerifyPopup,
    UserBlockPhotoPopup,
    UserVipPopup,
    UserScorePopup,
    UserMapPopup,
    UserGenderPopup,
    UserProfileBanPopup,
    UserDeviceBanPopup,
    UserDeletePopup,
    UserPanelPopup,
    UserAppPopup,
    UserEventsRatingPopup,
    AppUserChats,
    UesrHistoryTransaction,
    UserCallOnlyVipPopup,
    UserGetVerify,
    AppUserPay,
    UserPhotoDelPopup,
    UserHideByMapPopup,
    AppUserPushes,
    UserActionApproveStatusPopup
  },

  data: () => ({
    errorMessage: false,
    errorMessageDeposit: false,
    errorMessagePayouts: false,
    errorMessagePurchases: false,
    errorMessagePhotos: false,
    errorMessageChats: false,
    errorMessageChat: false,
    isLoaded: false,
    isLoadedChat: false
  }),

  methods: {
    ...mapMutations({
      setStyles: SET_USERCHATS_STYLE,
      setClearChats: SET_USER_CHATS_CLEAR
    }),
    mouseHandler($event) {
      const target = this.$refs.gallery,
        toLeft = $event.deltaY < 0 && target.scrollLeft > 0,
        toRight = $event.deltaY > 0 && target.scrollLeft < target.scrollWidth - target.clientWidth

      if (toLeft || toRight) {
        $event.preventDefault()
        target.scrollLeft += $event.deltaY
      }
    }
  },

  computed: {
    ...mapGetters({
      currentuser: GET_CURRENT_USER,
      userDeposit: GET_USER_DEPOSIT,
      userPayouts: GET_USER_PAYOUTS,
      userPhotos: GET_USER_PHOTOS,
      userTransaction: GET_TRANSACTION_KIND,
      userChats: GET_USER_CHATS,
      permisisons: GET_PERMISSIONS_USER,
      stylesChat: GET_USERCHATS_STYLES
    })
  },
  beforeRouteLeave(to, from, next) {
    this.setClearChats()
    next()
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_ID_USER, to.params.userId).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
        store.dispatch(REQUEST_USER_DEPOSIT, to.params.userId).then(responseData => {
          if (!responseData.status) {
            vm.errorMessageDeposit = true
          }
        })
        store.dispatch(REQUEST_USER_PAYOUTS, to.params.userId).then(responseData => {
          if (!responseData.status) {
            vm.errorMessagePayouts = true
          }
        })
        store.dispatch(REQUEST_USER_PURCHASES, to.params.userId)
        store.dispatch(REQUEST_USER_PHOTOS, to.params.userId).then(responseData => {
          if (!responseData.status) {
            vm.errorMessagePhotos = true
          }
        })
        store.dispatch(REQUEST_USER_CHATS, { id: to.params.userId }).then(responseData => {
          if (!responseData.data) {
            vm.errorMessageChats = true
          } else {
            vm.isLoadedChat = true
          }
        })
        store.dispatch(REQUEST_USER_TRANSACTION_KIND, to.params.userId).then(responseData => {
          if (!responseData.data) {
            vm.errorUserTransaction = true
          }
        })
      })
    })
  },
  beforeMount() {
    this.$root.$emit('routeName', this.$route.name)
    // const design = {
    //   windowChat: {
    //     backgroundColor: '#fff',
    //     borderRadius: '4px',
    //     fontSize: '13px',
    //     width: '53%',
    //     padding: '16px',
    //     margin: '0 0 0 auto',
    //     position: 'absolute',
    //     right: '16px',
    //     top: '92.5%',
    //     height: '64vh'
    //   },
    //   photoLine: {
    //     backgroundColor: '#fff',
    //     borderRadius: '4px',
    //     marginBottom: '8px',
    //     padding: '16px',
    //     width: '45%'
    //   },
    //   heightChat: {
    //     height: '750px'
    //   }
    // }
    // this.setStyles(design)
  }
}
</script>
<style lang="scss">
.app-user-wrap {
  padding: 16px;
}
.app-user__chats {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 126px);
  @media (max-width: 1020px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.app-user-body {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  flex-wrap: nowrap;
  @media (max-width: 1020px) {
    flex-direction: column;
    min-width: 100%;
  }
  &__info {
    min-width: 600px;
    width: 40%;
    @media (max-width: 1020px) {
      width: 100%;
      min-width: 100%;
    }
  }
}

.app-user__error {
  margin: 10px 16px;
  padding: 0 16px 0 16px;
  min-height: 64px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;

  &-text {
    margin: 0 auto;
  }
  &--big {
    width: 100%;
    margin: 0;
  }
}

// @import '@/assets/scss/vars.scss';
</style>
