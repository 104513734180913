<template>
  <div>
    <div v-if="isLoaded">
      <OperatorsAutoMessagesHeader />
      <AutoMessages
        v-for="(message, index) in operatorAutoMessage"
        :key="index"
        :message="message"
        :number="index + 1"
      />
      <OperatorEditAutoMessage />
    </div>
    <div v-if="errorMessage" class="operators__error">
      <span class="operators__error-text">Ошибка при загрузке Авто сообщений</span>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import { GET_OPERATORS_AUTO_MESSAGE } from '@/store/getter-types'
import { REQUEST_OPERATORS_AUTO_MESSAGE } from '@/store/action-types'
import AutoMessages from '@/components/Operators/OperatorsAutoMessages/AutoMessages'
import OperatorEditAutoMessage from '@/components/Modals/OperatorsPopup/OperatorEditAutoMessage'
import OperatorsAutoMessagesHeader from '@/components/Operators/OperatorsAutoMessages/OperatorsAutoMessagesHeader'
export default {
  name: 'operatorsAutoMessages',
  components: {
    AutoMessages,
    OperatorEditAutoMessage,
    OperatorsAutoMessagesHeader
  },
  data: () => ({
    errorMessage: false,
    isLoaded: false
  }),
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_OPERATORS_AUTO_MESSAGE).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      operatorAutoMessage: GET_OPERATORS_AUTO_MESSAGE
    })
  }
}
</script>
<style lang="scss">
@import '@/components/Operators/Banners/Banner.scss';
.table-header-banner {
  &__item-event {
    padding: 7px 5px 9px 5px;
    &:nth-child(1) {
      width: 15%;
      padding: 7px 5px 9px 16px;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 55%;
    }
  }
}
.operator-banner {
  &__field {
    &:nth-child(1) {
      width: 15%;
      color: #070442;
      justify-content: flex-start;
      padding-left: 15px;
      @media (max-width: 1020px) {
        width: 100%;
      }
    }
    &:nth-child(2) {
      width: 30%;
      @media (max-width: 1020px) {
        width: 100%;
      }
    }
    &:nth-child(3) {
      width: 40%;
      @media (max-width: 1020px) {
        width: 100%;
      }
    }
    &:nth-child(4) {
      width: 15%;
      justify-content: flex-end;
      @media (max-width: 1020px) {
        justify-content: flex-end;
        width: 100%;
      }
    }
  }
}
</style>
