<template>
  <div class="statistics">
    <h2 class="statistics__title">
      Статистика плательщиков и количество покупок
    </h2>
    <StatisticUserPayments v-if="isLoadedPaymentsByPayers" :paymentsByPayers="paymentsByPayers" />
    <div v-if="isLoadedPaymentsByPayersError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Сумма покупок</h2>
    <StatisticPayments v-if="isLoadedPayments" :payments="payments" />
    <div v-if="isLoadedPaymentsError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Статистика платежей по городам</h2>
    <StatisticPaymentsByCity v-if="isLoadedPaymentsByCity" :paymentByCity="paymentByCity" />
    <div v-if="isLoadedPaymentsByCityError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Статистика платежей по странам</h2>
    <StatisticPaymentsByCountry v-if="isLoadedPaymentsByCountry" :paymentByCountry="paymentByCountry" />
    <div v-if="isLoadedPaymentsByCountryError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">
      Статистика по времени платежей
    </h2>
    <StatisticPurchasesByTime v-if="isLoadedPurchases" :purchasesByTime="purchasesByTime" />
    <div v-if="isLoadedPurchasesError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Статистика платящих пользователей</h2>
    <StatisticUsersPaying v-if="isLoadedUsersPaying" :usersPaying="usersPaying" />
    <div v-if="isLoadedUsersPayingError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <div v-if="errorMessage" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке </span>
    </div>
  </div>
</template>
<script>
import StatisticPaymentsByCity from '@/components/Statistic/StatisticPaymentsByCity'
import StatisticPaymentsByCountry from '@/components/Statistic/StatisticPaymentsByCountry'
import StatisticPayments from '@/components/Statistic/StatisticPayments'
import StatisticUserPayments from '@/components/Statistic/StatisticUserPayments'
import StatisticPurchasesByTime from '@/components/Statistic/StatisticPurchasesByTime'
import StatisticUsersPaying from '@/components/Statistic/StatisticUsersPaying'
import { mapGetters } from 'vuex'
import {
  REQUEST_PURCHASES_MARKET_PLACES,
  REQUEST_PURCHASES_BY_CITY,
  REQUEST_PURCHASES_BY_COUNTRY,
  REQUEST_PURCHASES_BY_TIME,
  REQUEST_PURCHASES_BY_PAYERS_PAYMENTS,
  REQUEST_USERS_PAYING
} from '@/store/action-types'

import {
  GET_PURCHASES_BY_PAYERS_PAYMENTS,
  GET_PURCHASES_MARKET_PLACES,
  GET_PURCHASES_BY_CITY,
  GET_PURCHASES_BY_COUNTRY,
  GET_PURCHASES_BY_TIME,
  GET_USERS_PAYING
} from '@/store/getter-types'
import store from '@/store'
import moment from 'moment'

export default {
  name: 'StatisticsPaymentsUser',
  components: {
    StatisticUsersPaying,
    StatisticUserPayments,
    StatisticPayments,
    StatisticPaymentsByCity,
    StatisticPaymentsByCountry,
    StatisticPurchasesByTime
  },
  data: () => ({
    errorMessage: false,
    isLoadedPayments: false,
    isLoadedPaymentsError: false,
    isLoadedPurchases: false,
    isLoadedPurchasesError: false,
    isLoadedPaymentsByPayers: false,
    isLoadedPaymentsByPayersError: false,
    isLoadedPaymentsByCity: false,
    isLoadedPaymentsByCityError: false,
    isLoadedPaymentsByCountry: false,
    isLoadedPaymentsByCountryError: false,
    isLoadedUsersPaying: false,
    isLoadedUsersPayingError: false,
    countCityDefault: {
      city_count: 20
    }
  }),
  beforeRouteEnter(to, from, next) {
    let date = {
      date_from: moment()
        .subtract(14, 'days')
        .startOf('day')
        .format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      delta: new Date().getTimezoneOffset() / -60
    }
    let dateToday = {
      date_from: moment().format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      delta: new Date().getTimezoneOffset() / -60,
      city_count: 20
    }
    let dateTodayCity = {
      date_from: moment().format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      delta: new Date().getTimezoneOffset() / -60,
      city_count: 20
    }
    let dateThreeMounth = {
      date_from: moment()
        .subtract(3, 'months')
        .startOf('day')
        .format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD')
    }
    next(vm => {
      store.dispatch(REQUEST_PURCHASES_MARKET_PLACES, date).then(response => {
        if (!response.status) {
          vm.isLoadedPaymentsError = true
        } else {
          vm.isLoadedPayments = true
        }
      })
      store.dispatch(REQUEST_PURCHASES_BY_CITY, dateTodayCity).then(response => {
        if (!response.status) {
          vm.isLoadedPaymentsByCityError = true
        } else {
          vm.isLoadedPaymentsByCity = true
        }
      })
      store.dispatch(REQUEST_PURCHASES_BY_COUNTRY, dateToday).then(response => {
        if (!response.status) {
          vm.isLoadedPaymentsByCountryError = true
        } else {
          vm.isLoadedPaymentsByCountry = true
        }
      })
      store.dispatch(REQUEST_PURCHASES_BY_TIME, dateThreeMounth).then(response => {
        if (!response.status) {
          vm.isLoadedPurchasesError = true
        } else {
          vm.isLoadedPurchases = true
        }
      })
      store.dispatch(REQUEST_PURCHASES_BY_PAYERS_PAYMENTS, date).then(response => {
        if (!response.status) {
          vm.isLoadedPaymentsByPayersError = true
        } else {
          vm.isLoadedPaymentsByPayers = true
        }
      })
      store.dispatch(REQUEST_USERS_PAYING, dateThreeMounth).then(response => {
        if (!response.status) {
          vm.isLoadedUsersPayingError = true
        } else {
          vm.isLoadedUsersPaying = true
        }
      })
    })
  },

  computed: {
    ...mapGetters({
      paymentsByPayers: GET_PURCHASES_BY_PAYERS_PAYMENTS,
      payments: GET_PURCHASES_MARKET_PLACES,
      paymentByCity: GET_PURCHASES_BY_CITY,
      paymentByCountry: GET_PURCHASES_BY_COUNTRY,
      purchasesByTime: GET_PURCHASES_BY_TIME,
      usersPaying: GET_USERS_PAYING
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>
<style lang="scss">
.statistics {
  width: 100%;
  &__title {
    margin: 10px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #c9cdd7;
  }
  &__error {
    margin-top: 20px;
    padding: 0 16px 0 16px;
    min-height: 64px;
    border-radius: 2px;

    position: relative;
    display: flex;
    align-items: center;
    background-color: white;

    &-text {
      margin: 0 auto;
    }
  }
}
.regenterstats-chart {
  height: 290px;
}
.table-condensed {
  td:hover {
    background: #318bcc;
    color: #fff;
  }
  .today {
    background: #48a7eb;
    color: #fff;
  }
}
</style>
