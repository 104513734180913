<template>
  <div
    v-show="
      account.counters['Автосообщения'] || account.counters['Входящие'] || account.counters['Исходящие']
    "
  >
    <li class="sublist-item-account">
      <router-link
        v-if="user_permissions.includes(103)"
        :to="{ name: 'UserItem', params: { userId: account.id } }"
        class="app-user__name-link"
        target="_blank"
      >
        <div class="sublist-item-account__online" v-if="operatorInShift && account.online">
          <div class="sublist-item-account__online-inner"></div>
        </div>
        <img
          class="sublist-item-account__photo"
          v-if="account.avatar"
          :src="avatar(account.avatar.content_id)"
          alt=""
        />
        <img class="sublist-item-account__photo" v-else src="@/assets/img/anonymous_user.png" alt="" />
      </router-link>
      <div v-else class="app-user__name-not-link">
        <div class="sublist-item-account__online" v-if="operatorInShift && account.online">
          <div class="sublist-item-account__online-inner"></div>
        </div>
        <img
          class="sublist-item-account__photo"
          v-if="account.avatar"
          :src="avatar(account.avatar.content_id)"
          alt=""
        />
        <img class="sublist-item-account__photo" v-else src="@/assets/img/anonymous_user.png" alt="" />
      </div>
      <!-- <img v-if="account.user_avatar" :src="avatar(account.user_avatar.content_id)" alt="" />
      <img v-else src="@/assets/img/anonymous_user.png" alt="" /> -->
      <div
        @click="getStats(currentAccount, operID, date.delta, account.user_name)"
        class="sublist-item__avatar"
        :class="{ active: active === currentAccount }"
      >
        <!-- <img v-if="account.user_avatar" :src="avatar(account.user_avatar.content_id)" alt="" />
        <img v-else src="@/assets/img/anonymous_user.png" alt="" /> -->
        <p class="sublist-item__name">{{ account.user_name }}</p>
        <div class="sublist-item__counters">
          <FaIcon :iconClass="'far fa-list-alt sublist-item__chats'" v-tooltip="'Количество диалогов'">
            {{ account.counters['Диалоги'] }}
          </FaIcon>
          <FaIcon :iconClass="'sublist-item__automessage fas fa-robot'" v-tooltip="'Автосообщения'">
            {{ account.counters['Автосообщения'] }}
          </FaIcon>
          <FaIcon :iconClass="'sublist-item__outgoing fas fas fa-arrow-down'" v-tooltip="'Входящие'">
            {{ account.counters['Входящие'] }}
          </FaIcon>
          <FaIcon :iconClass="'sublist-item__incoming fas fas fa-arrow-up'" v-tooltip="'Исходящие'">
            {{ account.counters['Исходящие'] }}
          </FaIcon>
        </div>
      </div>
    </li>
  </div>
</template>
<script>
import getImgLink from '@/components/mixins/getImgCdnLink'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { REQUEST_STATISTICS_PROFILE_MESSAGES } from '@/store/action-types'
import {
  GET_ACTIVE_OPERATOR,
  GET_STATISTICS_PROFILE_MESSAGES,
  GET_PERMISSIONS_USER
} from '@/store/getter-types'
import { SET_ACTIVE_OPERATOR, SET_STATISTICS_PROFILE_MESSAGES } from '@/store/mutation-types'
import moment from 'moment'

export default {
  name: 'operatorAccountItem',

  data() {
    return {
      currentAccount: this.account.id
    }
  },

  mixins: [getImgLink],

  props: {
    account: {
      type: Object
    },
    counter: {
      type: Object
    },
    operID: {
      type: String
    },
    date: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      active: GET_ACTIVE_OPERATOR,
      profileStatistic: GET_STATISTICS_PROFILE_MESSAGES,
      user_permissions: GET_PERMISSIONS_USER
    }),
    operatorInShift: function() {
      const currentDate = moment().format('YYYY-MM-DD')
      if (currentDate === this.date.date_from) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions({
      sendData: REQUEST_STATISTICS_PROFILE_MESSAGES
    }),
    ...mapMutations({
      setActive: SET_ACTIVE_OPERATOR,
      clearUsers: SET_STATISTICS_PROFILE_MESSAGES
    }),

    avatar(id) {
      //Не у всех юзеров есть кроп
      if (this.account.avatar.crop.x === 0 || this.account.avatar.crop.y === 0) {
        return this.getFile({ file: id, type: 'image' })
      } else {
        return this.getImgLinkCropped(id, 480, {
          ...this.account.avatar.crop
        })
      }
    },
    getStats(id, oper_id, delta = 0, userName) {
      if (this.active !== this.currentAccount) {
        let date = {
          // date_from: moment()
          //   .subtract(14, 'days')
          //   .startOf('day')
          //   .format('YYYY-MM-DD'),
          date_to: moment(this.date.date_from).format('YYYY-MM-DD'),
          delta: delta ? this.date.delta : delta
        }
        this.setActive(this.currentAccount)
        this.$root.$emit('set_id_account', id, true, oper_id, userName)
        // this.sendData({
        //   date_from: date.date_to,
        //   // date_to: date.date_to,
        //   delta: date.delta,
        //   user_id: id,
        //   operator_id: this.operID
        //   // page: 0
        // }).then(res => {
        //   this.$root.$emit('set_id_account', id, res.status, oper_id)
        // })
      } else {
        this.setActive('')
        this.$root.$emit('set_id_account', id, true, oper_id, userName)
      }
    }
  }
}
</script>
<style lang="scss">
.active {
  background-color: #c4f1ff;
  position: relative;
  --width: 0;

  // &::after {
  //   position: absolute;
  //   // top: 15px;
  //   content: '';
  //   display: inline-block;
  //   top: 24%;
  //   height: 1em;
  //   width: var(--width);
  //   border-bottom: 2px solid rgb(0, 0, 0);
  //   right: -14%;
  //   // right: -40px;
  // }
}

.sublist-item-account {
  display: flex;
  margin-top: 4px;
  position: relative;
  &__online {
    position: absolute;
    &-inner {
      min-width: 14px;
      width: 14px;
      height: 14px;
      border: 4px solid transparent;
      border-radius: 50%;
      position: relative;
      border-color: #78cd51;
      background-color: #78cd51;
    }
  }
  &__photo {
    width: 60px;
    height: 60px;
    border-radius: 40px;
  }
  &::before {
    position: absolute;
    content: '';
    top: 33px;
    display: inline-block;
    width: 8px;
    height: 2px;
    background-color: #000000;
    left: -8px;
  }
}

.sublist-item {
  display: flex;
  transition: all 0.4s ease;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #c4f1ff;
  }
  &__avatar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    transition: all 0.4s ease;
    width: 100%;
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: #c4f1ff;
    }
    // &:hover {
    //   background-color: #e6e6e6;
    // }
    img {
      width: 40px;
      height: 40px;
    }
  }
  &__btn-statistic {
    border-radius: 6px;
    background-color: #58c9f3;
    border-color: #58c9f3;
    color: #fff;
    padding: 10px;
  }
  &__counters {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  &__automessage {
    color: #ffa600;
  }
  &__incoming {
    color: #ff3a3a;
  }
  &__outgoing {
    color: #58c9f3;
  }
  &__chats {
    color: #b6b6b6;
  }
}
</style>
