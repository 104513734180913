export default {
  eventsCategory: [],
  eventsSubCategory: [],
  mergedCategories: [],
  eventsCategoryLang: [],
  events: [],
  currentEvent: {},
  eventRefBook: {},
  filtersEvents: {},
  eventsCount: 0,
  tmpDateFilter: {
    register: {},
    enter: {},
    payment: {}
  },

  tmpEventFilters: {
    gender: {},
    status: {},
    active: {},
    own: {}
  },

  filterEventsDefault: {
    gender: {
      name: 'Неважно',
      value: 'Неважно',
      id: 'Неважно'
    },

    status: {
      name: 'Неважно',
      value: 'Неважно',
      id: 'Неважно'
    },

    active: {
      name: 'Неважно',
      value: 'Неважно',
      id: 'Неважно'
    },

    own: {
      name: 'Неважно',
      value: 'Неважно',
      id: 'Неважно'
    }
  },

  filtersEventsInfo: {
    gender: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Мужской',
        value: 1,
        id: 1
      },
      {
        name: 'Женский',
        value: 2,
        id: 2
      }
    ],
    status: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Не проверено',
        value: 0,
        id: 0
      },
      {
        name: 'Одобрено',
        value: 1,
        id: 1
      },
      {
        name: 'Отклонено ',
        value: 2,
        id: 2
      }
    ],
    active: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'В архиве',
        value: 0,
        id: 0
      },
      {
        name: 'Активное',
        value: 1,
        id: 1
      }
    ],
    own: [
      {
        name: 'Неважно',
        value: 'Неважно',
        id: 'Неважно'
      },
      {
        name: 'Нет',
        value: 0,
        id: 0
      },
      {
        name: 'Да',
        value: 1,
        id: 1
      }
    ]
  }
}
