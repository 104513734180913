<template>
  <div class="operators__wrap">
    <OperatorsStatisticHeaders :currentOperator="currentOperator" />
    <OperatorsStatisticTableHeader />
    <OperatorsStatistic v-if="!errorMessage" :currentOperator="currentOperator" :statistic="statistic" />
    <div v-else class="operators__error">
      <span class="operators__error-text">Ошибка при загрузке статистики операторов</span>
    </div>
  </div>
</template>
<script>
import OperatorsStatisticHeaders from '@/components/Operators/OperatorStatistic/OperatorsStatisticHeaders'
import OperatorsStatisticTableHeader from '@/components/Operators/OperatorStatistic/OperatorsStatisticTableHeader'
import OperatorsStatistic from '@/components/Operators/OperatorStatistic/OperatorsStatistic'
import { mapGetters } from 'vuex'
import { REQUEST_OPERATOR_STATISTIC } from '@/store/action-types'
import { GET_OPERATOR_STATISTIC, GET_CURRENT_OPERATOR } from '@/store/getter-types'
import store from '@/store'

export default {
  name: 'operatorsStatistic',
  components: {
    OperatorsStatisticHeaders,
    OperatorsStatisticTableHeader,
    OperatorsStatistic
  },
  data: () => ({
    errorMessage: false
  }),

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_OPERATOR_STATISTIC, to.params.operator_id).then(response => {
      next(vm => {
        if (!response.status || response.data.stats.length === 0) {
          vm.errorMessage = true
        }
      })
    })
  },

  computed: {
    ...mapGetters({
      statistic: GET_OPERATOR_STATISTIC,
      currentOperator: GET_CURRENT_OPERATOR
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
.operators {
  &__wrap {
    padding: 0 16px;
  }
  &__list {
    margin-top: 20px;
    padding: 0 20px;
  }
}

.operators-payments__btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  &--success {
    background-color: #78cd51;
    border-color: #78cd51;
    color: #fff;
  }
  &--setting {
    padding: 4px 8px;
    background-color: #ff6c60;
    border-color: #ff6c60;
    color: #fff;
  }
  &--statistic {
    background-color: #58c9f3;
    border-color: #58c9f3;
    color: #fff;
  }
  &--pay {
    background-color: #78cd51;
    border-color: #78cd51;
    color: #fff;
    padding: 0 5px;
  }
  &--award {
    background-color: #f1c500;
    border-color: #f1c500;
    color: #fff;
    width: 30px;
    height: 30px;
    padding: 3px;
  }
}
</style>
