import * as GetterTYpes from './getter-types'
export default {
  [GetterTYpes.GET_OPERATORS]: ({ operators }) =>
    operators.sort((prev, next) => prev.accounts.length - next.accounts.length).reverse(),

  [GetterTYpes.GET_CURRENT_OPERATOR]: ({ currentOperator }) => currentOperator,

  [GetterTYpes.GET_OPERATOR_STATISTIC]: ({ operatorStatistic }) => operatorStatistic,

  [GetterTYpes.GET_OPERATOR_PAYOUT]: ({ operatorPayout }) => operatorPayout,

  [GetterTYpes.GET_CURRENT_OPERATOR_USER]: ({ currentOperatorUser }) => currentOperatorUser,

  [GetterTYpes.GET_OPERATOR_SEARCH_OPTION]: ({ searchOptions }) => searchOptions,

  [GetterTYpes.GET_OPERATOR_USERS]: ({ operatorUsers }) => operatorUsers,

  [GetterTYpes.GET_OPERATORS_CURRENCY]: ({ currency }) => currency,

  [GetterTYpes.GET_OPERATOR_ROLES]: ({ operatorRoles }) => operatorRoles,

  [GetterTYpes.GET_OPERATOR_ROLES_DEFAULT]: ({ operatorRolesDefault }) => operatorRolesDefault,

  [GetterTYpes.GET_OPERATOR_TMP_ROLES]: ({ operatorTmpRoles }) => operatorTmpRoles,

  [GetterTYpes.GET_OPERATOR_BANNER]: ({ operatorBanner }) => operatorBanner,

  [GetterTYpes.GET_OPERATORS_AUTO_MESSAGE]: ({ operatorAutoMessage }) => operatorAutoMessage,

  [GetterTYpes.GET_ACTIVE_OPERATOR]: ({ activeOper }) => activeOper,

  [GetterTYpes.GET_TMP_DEFAULT_FILTERS_OPERATOR]: ({ operatorFilterDefault }) => operatorFilterDefault,

  [GetterTYpes.GET_TMP_FILTERS_OPERATOR]: ({ operatorFilter }) => operatorFilter
}
