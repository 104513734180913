<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    title=""
    description=""
    modalClass="popup--medium popup--purchase popup--scroll"
  >
    <form class="purchase-profile" @submit.prevent="createPurchaseSubmitHandler">
      <span class="purchase-profile__edit-text purchase-profile__header"
        >Добавить предупреждение пользователю</span
      >
      <div class="purchase-profile__edit">
        <div class="purchase-profile__edit-body">
          <div class="purchase-profile__edit-group">
            <label for="target-value" class="purchase-profile__edit-text">Заголовок</label>
            <input
              id="target-value"
              type="text"
              class="purchase-profile__edit-input"
              v-model="title"
              ref="createpurchasetargetvalue"
            />
          </div>
          <div class="purchase-profile__edit-group">
            <label for="purchase-price" class="purchase-profile__edit-text">Сообщение</label>
            <textarea
              id="purchase-price"
              type="text"
              class="purchase-profile__edit-input-message"
              v-model="message"
              ref="createpurchaseprice"
            />
          </div>
          <!-- <div class="purchase-profile__edit-group">
            <label for="purchase-countrycode" class="purchase-profile__edit-text">Язык</label>
            <select class="purchase-profile__select" v-model="selected">
              <option v-for="lang in lang" :key="lang.value" :value="lang.value">
                {{ lang.name }}
              </option>
            </select>
          </div> -->
          <div class="purchase-profile__edit-group purchase-profile__edit-group--btns">
            <button
              type="submit"
              class="purchase-profile__edit-action-btn purchase-profile__edit-action-btn--save"
            >
              <span class="purchase-profile__edit-action-btn-text">Добавить</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapActions } from 'vuex'
import { REQUSET_ADD_NEW_CLAIM } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { CLAIM_ADD_POPUP_EVENT } from '@/constants/event'

export default {
  name: 'UserClaimAddPopup',
  data: () => ({
    CLAIM_ADD_POPUP_EVENT,
    lang: '',
    title: '',
    message: ''
  }),
  components: {
    Popup
  },
  mixins: [ModalMixin],

  methods: {
    ...mapActions({
      createNewClaim: REQUSET_ADD_NEW_CLAIM
    }),

    closePopup() {
      this.lang = ''
      this.title = ''
      this.message = ''
      this.close()
    },

    createPurchaseSubmitHandler() {
      const data = {
        lang: this.lang,
        title: this.title,
        message: this.message
      }
      this.createNewClaim(data)
      this.closePopup()
    }
  },

  mounted() {
    this.$root.$on(CLAIM_ADD_POPUP_EVENT, id_lang => {
      this.lang = id_lang
      this.open()
    })
  }
}
</script>
<style lang="scss" scoped>
@import './UserClaimAddPopup.scss';
.purchase__header {
  margin-left: 10px;
}
</style>
