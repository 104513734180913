export default {
  computed: {
    videoCall() {
      let text, attachment_time, icon
      if (this.message.content.attachment_time === '' && this.message.sender_id === this.userId) {
        text = 'Нет ответа'
        attachment_time = ''
        icon = 'outgoing'
      }
      if (this.message.content.attachment_time === '00:00:00' && this.message.content.content_type === 8) {
        text = 'Пропущенный видеозвонок'
        attachment_time = ''
        icon = 'missed'
      }
      if (this.message.content.attachment_time !== '00:00:00' && this.message.sender_id === this.userId) {
        text = 'Видеозвонок'
        attachment_time = this.message.content.attachment_time
        icon = 'outgoing'
      }
      if (this.message.content.attachment_time !== '00:00:00' && this.message.sender_id !== this.userId) {
        text = 'Видеозвонок'
        attachment_time = this.message.content.attachment_time
        icon = 'incoming'
      }
      if (this.message.content.content_type === 9 && this.message.sender_id !== this.userId) {
        text = 'Отмененный видеозвонок'
        attachment_time = ''
        icon = 'cancelled'
      }
      if (this.message.content.content_type === 10 && this.message.sender_id === this.userId) {
        text = 'Отмененный видеозвонок'
        attachment_time = ''
        icon = 'cancelled'
      }
      return {
        text,
        attachment_time,
        icon
      }
    }
  }
}
