import Request from '../helpers/Request'
import BaseService from './BaseService'

class LangService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getAllLanguage() {
    let newDate = new Date()
    const getAllLanguageUrl = `/api/v1/localization/lang/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
      // 'Cache-Control': 'no-cache'
    }
    return await Request.get(getAllLanguageUrl, {
      headers,
      params: { newDate }
    })
  }

  async createLanguage(lang) {
    const createLanguageUrl = `/api/v1/localization/lang/add/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }
    return await Request.post(createLanguageUrl, lang, { headers })
  }

  async deleteLanguageById(languagesId) {
    const deleteLanguageByIdUrl = `/api/v1/localization/lang/${languagesId}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteLanguageByIdUrl, { headers })
  }
}

export default LangService
