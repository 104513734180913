<template>
  <div class="search-operator">
    <SearchOperatorHeader />
    <div class="search-operator__fileds">
      <ul class="search-operator__list">
        <li v-for="(oper, ind) in operator.accounts" class="search-operator__items" :key="ind">
          <div class="search-operator__user">
            <img
              class="search-operator__img"
              v-if="oper.account.user_avatar"
              :src="
                getFile({
                  type: 'image',
                  file: oper.account.user_avatar.content_id,
                  width: 80,
                  height: 80
                })
              "
              alt=""
            />
            <img v-else src="@/assets/img/anonymous_user.png" alt="" />
            <div class="search-operator__name">
              <router-link
                :to="{ name: 'UserItem', params: { userId: oper.account.id } }"
                class="app-user__name-link"
                target="_blank"
              >
                <span :key="oper.account.user_name + oper.account.age"
                  >{{ oper.account.user_name }} ({{ oper.account.age }})<br
                /></span>
              </router-link>
              <!-- <span :key="oper.account.user_name + oper.account.age"
                >{{ oper.account.user_name }} ({{ oper.account.age }})<br
              /></span> -->
              <span>Город: {{ oper.account.user_city }}</span>
            </div>
            <button
              @click.prevent="
                $root.$emit('EDIT_USER_OPERATOR_NAME', { operator: operator.id, account: oper.account })
              "
            >
              <i class="search-operator__name-icon fa fa-pencil-alt"></i>
            </button>
          </div>
          <div class="search-operator__user-search">
            <span
              >Возраст: от {{ oper.account.user_search.age_min }} до
              {{ oper.account.user_search.age_max }}</span
            >
            <span>Пол: {{ genderText(oper.account.user_search.gender) }}</span>
          </div>
          <ul class="search-operator__search-list">
            <SearchItem
              v-for="search in oper.searches"
              :search="search"
              :key="search.gender + search.age_max + search.age_min + search.country_code"
              :location="oper.account.user_location.point"
              :user_id="oper.account.id"
            />
          </ul>
          <div class="search-operator__item-checkbox">
            <input type="checkbox" id="checkbox" v-model="oper.auto_message" />
            <div @click="openPopup(oper)" class="search-operator-div-block"></div>
            <span v-show="oper.auto_message" class="search-operator-message">
              {{ oper.auto_message_delay }} мин.</span
            >
          </div>
          <div class="search-operator__item-btns">
            <button
              class="operator__btn btn-blue operator__btn--blue"
              @click.prevent="
                $root.$emit('EDIT_USER_OPERATOR_POPUP_EVENT', {
                  operator: operator.id,
                  account: oper.account,
                  searches: oper.searches,
                  weight_sending: oper.weight_sending,
                  auto_message_use: oper.auto_message_use
                })
              "
            >
              <i class="fa fa-edit"></i>
            </button>
            <button
              class="operator__btn btn-red operator__btn--red"
              @click.prevent="
                $root.$emit('DEL_OPERATOR_USER_POPUP_EVENT', {
                  id: oper.account.id,
                  operator: operator.id,
                  title: 'del'
                })
              "
            >
              <i class="fa fa-times-circle"></i>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SearchOperatorHeader from './SearchOperatorHeader'
import getImgLinkFront from '@/components/mixins/getImgCdnLink'
import SearchItem from './SearchItem'
export default {
  name: 'SearchOperator',
  components: { SearchItem, SearchOperatorHeader },
  props: {
    operator: {
      type: Object
    }
  },
  mixins: [getImgLinkFront],
  methods: {
    genderText(value) {
      let valueText = null
      switch (value) {
        case 0:
          valueText = 'Неопределенный'
          break
        case 1:
          valueText = 'Мужской'
          break
        case 2:
          valueText = 'Женский'
          break
        default:
          break
      }
      return valueText
    },
    openPopup(data) {
      this.$root.$emit('EDIT_AUTO_MESSAGE_DELAY', data, this.operator.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'Operators';
.search-operator__img {
  width: 80px;
}
.app-user__name-link {
  color: #505d70;
  margin-right: 6px;
  a {
    text-decoration: none;
  }
}
.search-operator__name {
  margin-left: 10px;
}
//search-operator__user
//search-operator__search-list

.search-operator__item-btns {
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

.search-operator__name-icon {
  font-size: 12px;
  margin-left: 5px;

  .search-operator-message {
    margin-left: 5px;
    font-size: 13px;
  }
}
.search-operator-div-block {
  width: 120px;
  height: 50px;
  position: absolute;
}
</style>
