<template>
  <!-- [ {} ] - массив как динамический ключ item ["item"] -->
  <li
    :class="[
      {
        sidebar__item: lvl < 1,
        [`sidebar__item-${item.itemClass}`]: item.itemClass,
        expanded: item.isOpen && list
      }
    ]"
  >
    <div @click="openItemsHandler">
      <router-link
        v-if="item.itemLink"
        :to="item.itemLink"
        class="sidebar__link sidebar__link-main"
        :class="{ 'sidebar__link-hidden': list }"
      >
        <i
          class="sidebar__link-icon sidebar__link-icon-hidden"
          :class="item.itemIcon"
          v-if="item.itemIcon"
        ></i>
        <span class="sidebar__link-text sidebar__link-text-main">
          <span v-if="item.ItemStatusType" :class="`status status-${item.ItemStatusType}`"></span>
          {{ item.itemText }}
        </span>
      </router-link>
      <a
        v-else-if="item.itemLinkURL"
        :href="item.itemLinkURL"
        class="sidebar__link sidebar__link-main"
        :class="{ 'sidebar__link-hidden': list }"
      >
        <i
          class="sidebar__link-icon sidebar__link-icon-hidden"
          :class="item.itemIcon"
          v-if="item.itemIcon"
        ></i>
        <span class="sidebar__link-text sidebar__link-text-main">
          <span v-if="item.ItemStatusType" :class="`status status-${item.ItemStatusType}`"></span>
          {{ item.itemText }}
        </span>
      </a>
      <span
        v-else
        :to="item.itemLink"
        class="sidebar__link sidebar__link-main"
        :class="{ 'sidebar__link-hidden': list }"
      >
        <i
          class="sidebar__link-icon sidebar__link-icon-hidden"
          :class="item.itemIcon"
          v-if="item.itemIcon"
        ></i>
        <span class="sidebar__link-text sidebar__link-text-main">
          <span v-if="item.ItemStatusType" :class="`status status-${item.ItemStatusType}`"></span>
          {{ item.itemText }}
        </span>
      </span>
    </div>

    <template v-if="item.isOpen">
      <slot name="list" :list="list" :open="item.isOpen"></slot>
    </template>
  </li>
</template>
<script>
import { mapMutations } from 'vuex'
import { SET_OPENED_MENU_ITEM, SET_OPENED_MENU_ITEM_CLOSE } from '@/store/mutation-types'

export default {
  name: 'ListItem',
  props: {
    item: {
      type: Object
    },

    lvl: {
      type: Number
    },
    height: {
      type: Boolean
    }
  },
  computed: {
    list() {
      if (this.item.list) return this.item.list.items
      else return false
    }
  },
  mounted() {
    if (!this.height) {
      this.item.isOpen = false
      if (this.$el.classList.length > 1) {
        this.$el.style.height = '46px'
      }
    }
    if (this.height) {
      if (this.$el.classList.length > 1) {
        let child = this.$el.querySelectorAll('.sidebar__list .sidebar__list-hidden')
        if (child[0]) {
          let count = child[0].children.length
          this.$el.style.height = `${35 + 35 * count + 15}` + `px`
        }
      }
    }
  },
  updated() {
    if (!this.item.isOpen && this.$el.classList.length) {
      this.$el.style.height = '45px'
    }
    if (this.item.isOpen && this.list) {
      this.$el.style.height = `${35 + 35 * this.item.list.items.length + 15}` + `px`
    }
  },
  methods: {
    openItemsHandler() {
      this.item.isOpen = !this.item.isOpen
      if (this.height) return
      this.setOpenedMenuItemClose(this.item)
      this.setOpenedMenuItem(this.item)
    },
    ...mapMutations({
      setOpenedMenuItem: SET_OPENED_MENU_ITEM,
      setOpenedMenuItemClose: SET_OPENED_MENU_ITEM_CLOSE
    })
  }
}
</script>
