<template>
  <div ref="appUsersWrapper" class="app-users-wrap">
    <Filters
      :count="count"
      :countFilter="countFilter"
      :picked_date="this.$route.query.purchase_date"
      :city="this.$route.query.user_city"
      @toTop="toTop"
    />
    <MessageListTableHeader />
    <div ref="viewScrollContent" class="app-messages" v-if="messages.length" @scroll="scroll($event)">
      <!-- <AppUser v-for="user in users" :key="user.id" :user="user" ref="userItem" /> -->
      <MessageItem
        v-for="message in messages"
        :key="message._source.operator_user.user_id"
        :message="message"
        ref="userItem"
      />
    </div>
    <div v-else-if="isLoaded && !messages.length" class="app-no-users">
      <span class="app-no-users__text">Автосообщения по фильтрам не найдены</span>
    </div>
    <div v-else class="app-no-users">
      <span class="app-no-users__text">Ошибка при загрузке автосообщений</span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { REQUEST_AUTO_MESSAGES_LIST, REQUEST_FILTER_UTM } from '@/store/action-types'
import MessageItem from '@/components/AutoMessagesList/messageItem'
import MessageListTableHeader from '@/components/AutoMessagesList/messageList-table-header'
import {
  GET_CURRENT_FILERS_AUTO_MESSAGE,
  GET_FILTERS_INFO_AUTO_MESSAGE,
  GET_USER_COUNTS,
  GET_USER_FILTER_COUNTS,
  GET_AUTO_MESSAGES_LIST
} from '@/store/getter-types'
import { SET_TMP_FILTERS, SET_DATE_FILTER } from '@/store/mutation-types'
import Filters from '@/components/AutoMessagesList/Filters/Filters'

import store from '@/store'

export default {
  name: 'autoMessagesList',
  components: {
    Filters,
    MessageItem,
    MessageListTableHeader
  },

  data: () => ({
    isLoaded: false,
    errorMessage: false,
    filterHeight: 0,
    disableScroll: false,
    loadDataByScroll: true,
    loadingUsers: false,
    page_counts: 0
  }),

  beforeRouteEnter(to, from, next) {
    // if (
    //   store.getters[GET_PERMISSIONS_USER].length === 1 &&
    //   store.getters[GET_PERMISSIONS_USER].includes(50)
    // ) {
    //   window.location = `${process.env.VUE_APP_DEPLOY_URL}/moderator/photo`
    // } else {
    const delta = new Date().getTimezoneOffset() / -60
    store.dispatch(REQUEST_AUTO_MESSAGES_LIST, { delta: delta, page: 0 }).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
        store.dispatch(REQUEST_FILTER_UTM).then(response => {
          if (!response.status) {
            vm.errorMessage = true
          } else {
            vm.isLoaded = true
          }
        })
      })
    })
    // }
  },

  computed: {
    ...mapGetters({
      filters: GET_CURRENT_FILERS_AUTO_MESSAGE,
      count: GET_USER_COUNTS,
      countFilter: GET_USER_FILTER_COUNTS,
      allFilters: GET_FILTERS_INFO_AUTO_MESSAGE,
      messages: GET_AUTO_MESSAGES_LIST
    })
  },
  watch: {
    filters: function() {
      this.loadDataByScroll = true
      this.disableScroll = false
      this.disableGetUsers = true
    }
  },

  methods: {
    ...mapActions({
      applyFilters: REQUEST_AUTO_MESSAGES_LIST
    }),
    ...mapMutations({
      addTmpFilters: SET_TMP_FILTERS,
      dateFilter: SET_DATE_FILTER
    }),
    toTop() {
      this.$refs.viewScrollContent.scrollTop = 0
    },
    scroll(event) {
      const delta = new Date().getTimezoneOffset() / -60
      let data = this.filters
      this.page_counts = Math.round(this.count / 30)
      if (!this.disableScroll) {
        const list = this.$refs.viewScrollContent
        const scrollBottom = list.scrollHeight - (list.scrollTop + list.offsetHeight)
        const scrollTop = list.scrollTop

        const firstNodeOffset = this.$refs.userItem[0]._vnode.elm.offsetTop
        const firstNodeScrollHeight = this.$refs.userItem[0]._vnode.elm.scrollHeight
        if (scrollBottom + firstNodeOffset < firstNodeOffset + firstNodeScrollHeight) {
          this.disableScroll = true
          if (this.loadDataByScroll && !this.loadingUsers) {
            if (!this.filters.page) {
              data.page = 0
              this.disableScroll = false
            }
            data.page = ++this.filters.page
            this.loadingUsers = true
            data.delta = delta
            this.applyFilters(data).then(response => {
              if (response.data.user.length === 0) {
                this.loadDataByScroll = false
              }
              this.$nextTick(() => {
                let newbottom = list.scrollHeight - (list.offsetHeight + scrollTop + scrollBottom)
                list.scrollTo({
                  bottom: newbottom
                })
                this.loadingUsers = false
                this.disableScroll = false
              })
            })
          }
        }
      }
    }
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)

    if (this.$route.query.purchase_date) {
      const date = this.$route.query.purchase_date.split(',')
      this.dateFilter({
        key: 'purchase_date',
        timeStampDateObject: {
          startDate: date[0],
          endDate: date[1]
        }
      })
    } else {
      Object.keys(this.allFilters).forEach(item => {
        Object.keys(this.$route.query).forEach((key, index) => {
          if (item == key) {
            let value = this.$route.query[key]
            let data = {}
            for (const el of this.allFilters[key]) {
              if (el.value == value) {
                data = {
                  key: key,
                  value: el
                }
              }
            }
            return this.addTmpFilters(data)
          }
        })
      })
    }
    const { user_city } = this.$route.query
    this.$root.$emit('submitFilter', { city: user_city })
  }

  // updated() {
  //   console.log(document.querySelector('.app-users'))
  //   console.log(document.querySelectorAll('.filters-user-wrap')[0].offsetHeight)
  //   console.log(document.querySelectorAll('.table-header-wrapper-user')[0].offsetHeight)
  //   let summ = document.querySelectorAll('.filters-user-wrap')[0].offsetHeight + document.querySelectorAll('.table-header-wrapper-user')[0].offsetHeight + 10;
  //   document.querySelector('.app-users').style.paddingTop = document.querySelectorAll('.filters-user-wrap')[0].offsetHeight + document.querySelectorAll('.table-header-wrapper-user')[0].offsetHeight + 1000;
  //   console.log(document.querySelector('.app-users'))
  // }
}
</script>
<style lang="scss">
.app-no-users,
.app-messages {
  height: calc(100vh - 102px); // почему 102 не знаю
  padding: 129px 16px 0 16px;
  position: relative;
  overflow-y: auto;
  scroll-behavior: smooth;
  @media (max-width: 3904px) {
    padding-top: 230px;
  }
  @media (max-width: 3494px) {
    padding-top: 145px;
  }
  @media (max-width: 2456px) {
    padding-top: 200px;
  }
  @media (max-width: 2064px) {
    padding-top: 200px;
  }
  @media (max-width: 2177px) {
    padding-top: 200px;
  }

  @media (max-width: 1618px) {
    padding-top: 208px;
  }
  @media (max-width: 1570px) {
    padding-top: 270px;
  }
  @media (max-width: 1341px) {
    padding-top: 270px;
  }
  @media (max-width: 1215px) {
    padding-top: 266px;
  }
  @media (max-width: 1131px) {
    padding-top: 266px;
  }
  @media (max-width: 1020px) {
    padding-top: 75px;
  }
}

.app-messages::-webkit-scrollbar {
  width: 0;
}

.app-messages {
  z-index: 0;
  &::-webkit-scrollbar {
    z-index: 0;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(232, 64, 63);
    border-radius: 10px;
  }
}

.app-no-users {
  min-height: 64px;
  margin: 0 16px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  &__text {
    margin: 0 auto;
    padding: 16px;
  }
}
</style>
