import * as RootMutationTypes from './mutation-types'
export default {
  [RootMutationTypes.SET_LOADER]: (state, payload) => {
    state.loading = payload
  },

  [RootMutationTypes.SET_LOGIN_USER]: (state, payload) => {
    state.user.loggedIn = payload
  },

  [RootMutationTypes.SET_USER_TOKEN]: (state, payload) => {
    state.user = {
      ...state.user,
      token: payload
    }
  },

  [RootMutationTypes.SET_USER_DATA]: (state, payload) => {
    state.user.userData = payload
  },

  [RootMutationTypes.LOGOUT]: state => {
    state.user.token = ''
  },

  [RootMutationTypes.SET_OPENED_MENU_ITEM]: (state, payload) => {
    if (!payload.list) return
    state.openedMenuItem = payload
  },

  [RootMutationTypes.SET_OPENED_MENU_ITEM_CLOSE]: (state, payload) => {
    const { openedMenuItem } = state
    if (openedMenuItem.list) {
      if (openedMenuItem.list.items.includes(payload) || openedMenuItem.id === payload.id) return
    }
    state.openedMenuItem.isOpen = false
  },

  [RootMutationTypes.SET_PERMISSION_USER]: (state, payload) => {
    state.permissionsUser = payload
  }
}
