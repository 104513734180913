<template>
  <div class="events-wrap" ref="viewScrollContent" @scroll="scroll($event)">
    <EventFilters @toTop="toTop" :count="count" />
    <EventTableHeader />
    <div class="events" v-if="events.length && isLoaded">
      <Event v-for="event in events" :key="event.event_id" :event="event" ref="userItem" />
    </div>
    <div class="app-no-events" v-if="!events.length && isLoaded">
      <span class="app-no-events__text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="app-no-events">
      <span class="app-no-events__text">Ошибка при загрузке событий</span>
    </div>
    <EventDeletePopup />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { GET_EVENTS, GET_EVENTS_FILTERS, GET_EVENT_COUNTS } from '@/store/getter-types'
import { REQUEST_PAGE_USERS, REQUEST_EVENTS } from '@/store/action-types'
import Event from '@/components/Events/Event'
import EventTableHeader from '@/components/Events/EventTableHeader'
import EventFilters from '@/components/Events/Filters/EventFilters'
import store from '@/store'
import EventDeletePopup from '@/components/Modals/EventPopup/EventDeletePopup'
export default {
  name: 'EventsList',
  components: {
    EventDeletePopup,
    Event,
    EventTableHeader,
    EventFilters
  },
  data() {
    return {
      isEmpty: false,
      event_id: null,
      errorMessage: false,
      isLoaded: false,
      disableScroll: false,
      loadDataByScroll: true,
      loadingUsers: false,
      page_counts: 0
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_EVENTS).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      events: GET_EVENTS,
      filters: GET_EVENTS_FILTERS,
      count: GET_EVENT_COUNTS
    })
  },
  watch: {
    filters: function() {
      this.loadDataByScroll = true
      this.disableScroll = false
    }
  },
  methods: {
    ...mapActions({
      applyFilters: REQUEST_PAGE_USERS
    }),
    toTop() {
      this.$refs.viewScrollContent.scrollTop = 0
    },
    scroll(event) {
      if (!this.disableScroll) {
        const list = this.$refs.viewScrollContent
        const scrollBottom = list.scrollHeight - (list.scrollTop + list.offsetHeight)
        const scrollTop = list.scrollTop

        const firstNodeOffset = this.$refs.userItem[0]._vnode.elm.offsetTop
        const firstNodeScrollHeight = this.$refs.userItem[0]._vnode.elm.scrollHeight
        if (scrollBottom + firstNodeOffset < firstNodeOffset + firstNodeScrollHeight) {
          this.disableScroll = true
          if (this.loadDataByScroll && !this.loadingUsers) {
            let data = this.filters
            if (!this.filters.page) {
              data.page = 0
            }
            data.page = ++this.filters.page
            this.loadingUsers = true
            this.applyFilters(data).then(response => {
              if (response.data.length === 0) {
                this.loadDataByScroll = false
              }
              this.$nextTick(() => {
                let newbottom = list.scrollHeight - (list.offsetHeight + scrollTop + scrollBottom)
                list.scrollTo({
                  bottom: newbottom
                })
                this.loadingUsers = false
                this.disableScroll = false
              })
            })
          }
        }
      }
    }
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
.events-wrap {
  overflow-y: auto;
  height: calc(100vh - 102px); // почему 102 не знаю
  position: relative;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    z-index: 0;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(232, 64, 63);
    border-radius: 10px;
  }
}

.events {
  padding: 0 16px;
  font-size: 14px;
  position: relative;
  overflow-y: auto;
  @media (max-width: 3904px) {
    margin-top: 142px;
  }
  @media (max-width: 3494px) {
    margin-top: 142px;
  }
  @media (max-width: 2456px) {
    margin-top: 142px;
  }
  @media (max-width: 2064px) {
    margin-top: 142px;
  }
  @media (max-width: 2177px) {
    margin-top: 142px;
  }
  @media (max-width: 1813px) {
    margin-top: 142px;
  }

  @media (max-width: 1618px) {
    margin-top: 203px;
  }
  @media (max-width: 1341px) {
    margin-top: 228px;
  }
  @media (max-width: 1215px) {
    margin-top: 237px;
  }
  @media (max-width: 1131px) {
    margin-top: 237px;
  }
  @media (max-width: 1020px) {
    margin-top: 75px;
  }
}

.app-no-events {
  margin: 0 16px;
  padding: 0 16px 0 16px;
  min-height: 64px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  &__text {
    margin: 0 auto;
  }
  @media (max-width: 3904px) {
    margin-top: 142px;
  }
  @media (max-width: 3494px) {
    margin-top: 142px;
  }
  @media (max-width: 2456px) {
    margin-top: 142px;
  }
  @media (max-width: 2064px) {
    margin-top: 142px;
  }
  @media (max-width: 2177px) {
    margin-top: 142px;
  }
  @media (max-width: 1813px) {
    margin-top: 142px;
  }

  @media (max-width: 1618px) {
    margin-top: 203px;
  }
  @media (max-width: 1341px) {
    margin-top: 228px;
  }
  @media (max-width: 1215px) {
    margin-top: 237px;
  }
  @media (max-width: 1131px) {
    margin-top: 230px;
  }
  @media (max-width: 1020px) {
    margin-top: 75px;
  }
}

@import '@/components/Events/Event.scss';
</style>
