<template>
  <div class="lang__item">
    <div class="lang__item-body">
      <div class="lang__item-content">
        <div class="lang__item-name">
          <span class="lang__item-title">{{ lang.name }}</span>
        </div>
        <div class="lang__item-name">
          <span class="lang__item-title">{{ lang.value }}</span>
        </div>
        <div class="lang__item-btns">
          <button class="lang__item-btn lang__item-btn--red" @click="$root.$emit(LANG_DELETE_POPUP, lang.id)">
            <i class="lang__item-btn-icon fa fa-times-circle"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LANG_DELETE_POPUP } from '@/constants/event'
export default {
  data() {
    return {
      LANG_DELETE_POPUP
    }
  },
  name: 'LanguagesItem',
  props: {
    lang: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped></style>
