<template>
  <div class="moderators-rating__filter">
    <form class="moderators-rating__form" @submit.prevent="applyUserFiltersHanlder">
      <div class="moderators-rating__item">
        <span class="moderators-rating__title">Статус модераторов:</span>
        <div class="moderators-rating__input select">
          <div class="select__body">
            <button
              type="button"
              class="select__field select__field--moderator"
              @click="statusSelectOpen = !statusSelectOpen"
            >
              <span class="select__field-value">{{ defaultFilter({ key: 'status' }) }}</span>
              <i class="select__field-icon fa fa-angle-down"></i>
            </button>
            <ul class="select__list" v-if="statusSelectOpen" @mouseleave="mouseLeave">
              <li
                class="select__item"
                v-for="status in moderatorRoles.status"
                :key="status.id"
                @click="
                  selectFilterBuilder({
                    key: 'status',
                    value: status
                  })
                "
              >
                <span class="select__text"> {{ status.name }} </span>
              </li>
            </ul>
          </div>
        </div>
        <button class="moderators-rating__btn moderators-rating__btn--success">
          Показать
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import {
  GET_MODERATOR_RATING_ROLES,
  GET_MODERATOR_ROLES_RATING_DEFAULT,
  GET_MODERATOR_RATING_TMP_ROLES
} from '@/store/getter-types'
import { SET_MODERATOR_RATING_SETTINGS } from '@/store/mutation-types'
import { REQUEST_MODERATORS_RATING } from '@/store/action-types'

export default {
  name: 'ModeratorsPaymentsFilters',
  data: () => ({
    statusSelectOpen: false,
    noPayments: false
  }),
  computed: {
    ...mapGetters({
      moderatorRoles: GET_MODERATOR_RATING_ROLES,
      defaultFilters: GET_MODERATOR_ROLES_RATING_DEFAULT,
      tmpFilter: GET_MODERATOR_RATING_TMP_ROLES
    })
  },
  methods: {
    ...mapActions({
      getRating: REQUEST_MODERATORS_RATING
    }),
    ...mapMutations({
      addTmpModeratorSettings: SET_MODERATOR_RATING_SETTINGS
    }),
    defaultFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : this.defaultFilters[key].name
    },

    defaultValueFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current || current === false ? current : this.defaultFilters[key].value
    },

    selectFilterBuilder(filterItem) {
      const { key } = filterItem
      switch (key) {
        case 'status':
          this.addTmpModeratorSettings({ ...filterItem })
          break
        default:
          break
      }
      this[`${key}SelectOpen`] = false
    },

    close() {
      this.statusSelectOpen = false
    },
    mouseLeave() {
      this.close()
    },

    applyUserFiltersHanlder() {
      const userFilterData = {}
      if (this.defaultFilter({ key: 'status' }) !== 'Все') {
        userFilterData.is_active = this.defaultValueFilter({ key: 'status' })
      }
      this.close()
      this.getRating(userFilterData)
    }
  },
  mounted() {
    const data = {
      key: 'status',
      value: {
        id: 1,
        name: 'Активные',
        value: 1
      }
    }
    this.addTmpModeratorSettings(data)
  }
}
</script>
<style lang="scss">
@import 'ModeratorStatistic';
</style>
