import Request from '../helpers/Request'
import BaseService from './BaseService'

class UserStatisticService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getDateListById(data) {
    const getDateListByIdUrl = `/api/v1/stats/operator_stat/counters-user-operator-correspondence/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getDateListByIdUrl, {
      headers,
      params: data
    })
  }

  async getStatsByDate(data) {
    const getStatsByDatedUrl = `/api/v1/stats/operator_stat/user-operator-correspondence/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.get(getStatsByDatedUrl, {
      headers,
      params: data
    })
  }
}

export default UserStatisticService
