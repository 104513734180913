import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import UserStatisticService from '@/services/UserStatisticService'

export default {
  [ActionTypes.REQUEST_USER_STATISTIC_ADVANCED]: async ({ rootGetters, commit }, id) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserStatisticService, [userToken]).getDateListById(id)
    const { data, status } = response
    if (status) {
      commit(MutationTypes.SET_USER_STATISTIC_ADVANCED, data)
    }
    // commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_STATISTIC_USER_BY_DATE]: async ({ rootGetters, commit }, date) => {
    // commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserStatisticService, [userToken]).getStatsByDate(date)
    const { data, status } = response
    if (status) {
      commit(MutationTypes.SET_STATISTIC_USER_BY_DATE, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },
  [ActionTypes.REQUEST_STATISTIC_USER_BY_DATE_PAGINATION]: async ({ rootGetters, commit }, date) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(UserStatisticService, [userToken]).getStatsByDate(date)
    const { data, status } = response
    if (status) {
      commit(MutationTypes.SET_STATISTIC_USER_BY_DATE_PAGINATION, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
