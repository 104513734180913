<template>
  <Popup :visible="visible" @close="close" description="Удалить начисление баллов?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteAchievementEvent">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { POINTS_DELETE_POPUP_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_DELETE_CAUSE_POINTS } from '@/store/action-types'

export default {
  name: 'CausePointsDeletePopUp',
  components: {
    Popup
  },
  mixins: [ModalMixin],
  data() {
    return {
      idMale: '',
      idFemale: '',
      idTwo: ''
    }
  },
  methods: {
    ...mapActions({
      deleteCausePoints: REQUEST_DELETE_CAUSE_POINTS
    }),
    deleteAchievementEvent() {
      if (this.idMale) this.deleteCausePoints(this.idMale)
      if (this.idFemale) this.deleteCausePoints(this.idFemale)
      if (this.idTwo) this.deleteCausePoints(this.idTwo)
      this.idMale = ''
      this.idFemale = ''
      this.idTwo = ''
      this.close()
    }
  },

  mounted() {
    this.$root.$on(POINTS_DELETE_POPUP_EVENT, eventcat => {
      this.idMale = eventcat.filterMalePoint[0].id
      this.idFemale = eventcat.filterFemalePoint[0].id
      if (eventcat && eventcat.filterTwoGenderPoint[0]) {
        this.idTwo = eventcat.filterTwoGenderPoint[0].id
      }
      this.open()
    })
  }
}
</script>
