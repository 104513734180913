const GET_CONTROL_POSITIONS = 'GET_CONTROL_POSITIONS'
const GET_FILTERS_DEFAULT_POSITIONS = 'GET_FILTERS_DEFAULT_POSITIONS'
const GET_FILTERS_POSITIONS_INFO = 'GET_FILTERS_POSITIONS_INFO'
const GET_TMP_POSITIONS_FILTERS = 'GET_TMP_POSITIONS_FILTERS'

export {
  GET_CONTROL_POSITIONS,
  GET_FILTERS_DEFAULT_POSITIONS,
  GET_FILTERS_POSITIONS_INFO,
  GET_TMP_POSITIONS_FILTERS
}
