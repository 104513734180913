<template>
  <div class="statistics">
    <h2 class="statistics__title">Покупки за все время</h2>
    <StatisticPurchaseTotal v-if="isLoadedTotalPurchase" :totalPurchase="totalPurchase" />
    <div v-if="isLoadedTotalPurchaseError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Покупки по датам</h2>
    <StatisticPurchasesByDate v-if="isLoadedPurchasesByDate" :purchasesByDate="purchasesByDate" />
    <div v-if="isLoadedPurchasesByDateError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Покупка баллов</h2>
    <StatisticPurchasePoints v-if="isLoadedPurchasesBalls" :purchasesBalls="purchasesBalls" />
    <div v-if="isLoadedPurchasesBallsError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <h2 class="statistics__title">Покупка VIP статуса</h2>
    <StatisticPurchasesVip v-if="isLoadedPurchasesVip" :purchasesVip="purchasesVip" />
    <div v-if="isLoadedPurchasesVipError" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке графика</span>
    </div>

    <div v-if="errorMessage" class="statistics__error">
      <span class="statistics__error-text">Ошибка при загрузке </span>
    </div>
  </div>
</template>
<script>
import StatisticPurchaseTotal from '@/components/Statistic/StatisticPurchaseTotal'
import StatisticPurchasePoints from '@/components/Statistic/StatisticPurchasePoints'
import StatisticPurchasesVip from '@/components/Statistic/StatisticPurchasesVip'
import StatisticPurchasesByDate from '@/components/Statistic/StatisticPurchasesByDate'
import { mapGetters } from 'vuex'
import {
  REQUEST_PURCHASE_TOTAL,
  REQUEST_PURCHASES_POINTS,
  REQUEST_PURCHASES_VIP_STATUS,
  REQUEST_PURCHASES_BY_DATE
} from '@/store/action-types'

import {
  GET_PURCHASE_TOTAL,
  GET_PURCHASES_POINTS,
  GET_PURCHASES_VIP_STATUS,
  GET_PURCHASES_BY_DATE
} from '@/store/getter-types'
import store from '@/store'
import moment from 'moment'

export default {
  name: 'StatisticsTotalPurchases',
  components: {
    StatisticPurchaseTotal,
    StatisticPurchasePoints,
    StatisticPurchasesVip,
    StatisticPurchasesByDate
  },
  data: () => ({
    errorMessage: false,
    isLoadedTotalPurchase: false,
    isLoadedTotalPurchaseError: false,
    isLoadedPurchasesBalls: false,
    isLoadedPurchasesBallsError: false,
    isLoadedPurchasesVipError: false,
    isLoadedPurchasesVip: false,
    isLoadedPurchasesByDateError: false,
    isLoadedPurchasesByDate: false
  }),
  beforeRouteEnter(to, from, next) {
    let date = {
      date_from: moment()
        .subtract(14, 'days')
        .startOf('day')
        .format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
      delta: new Date().getTimezoneOffset() / -60
    }
    // let dateToday = {
    //   date_from: moment().format('YYYY-MM-DD'),
    //   date_to: moment().format('YYYY-MM-DD'),
    //   delta: new Date().getTimezoneOffset() / -60,
    //   city_count: 20
    // }
    // let dateTodayCity = {
    //   date_from: moment().format('YYYY-MM-DD'),
    //   date_to: moment().format('YYYY-MM-DD'),
    //   delta: new Date().getTimezoneOffset() / -60,
    //   city_count: 20
    // }
    // let dateThreeMounth = {
    //   date_from: moment()
    //     .subtract(3, 'months')
    //     .startOf('day')
    //     .format('YYYY-MM-DD'),
    //   date_to: moment().format('YYYY-MM-DD')
    // }
    next(vm => {
      store.dispatch(REQUEST_PURCHASE_TOTAL).then(response => {
        if (!response.status) {
          vm.isLoadedTotalPurchaseError = true
        } else {
          vm.isLoadedTotalPurchase = true
        }
      })
      store.dispatch(REQUEST_PURCHASES_POINTS, date).then(response => {
        if (!response.status) {
          vm.isLoadedPurchasesBallsError = true
        } else {
          vm.isLoadedPurchasesBalls = true
        }
      })
      store.dispatch(REQUEST_PURCHASES_VIP_STATUS, date).then(response => {
        if (!response.status) {
          vm.isLoadedPurchasesVipError = true
        } else {
          vm.isLoadedPurchasesVip = true
        }
      })
      store.dispatch(REQUEST_PURCHASES_BY_DATE, date).then(response => {
        if (!response.status) {
          vm.isLoadedPurchasesByDateError = true
        } else {
          vm.isLoadedPurchasesByDate = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      totalPurchase: GET_PURCHASE_TOTAL,
      purchasesBalls: GET_PURCHASES_POINTS,
      purchasesVip: GET_PURCHASES_VIP_STATUS,
      purchasesByDate: GET_PURCHASES_BY_DATE
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>
<style lang="scss">
.statistics {
  width: 100%;
  &__title {
    margin: 10px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #c9cdd7;
  }
  &__error {
    margin-top: 20px;
    padding: 0 16px 0 16px;
    min-height: 64px;
    border-radius: 2px;

    position: relative;
    display: flex;
    align-items: center;
    background-color: white;

    &-text {
      margin: 0 auto;
    }
  }
}
.regenterstats-chart {
  height: 290px;
}
.table-condensed {
  td:hover {
    background: #318bcc;
    color: #fff;
  }
  .today {
    background: #48a7eb;
    color: #fff;
  }
}
</style>
