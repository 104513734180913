<template>
  <ul class="table-statistic__list" :class="{ 'table-statistic__list--close': isHide }">
    <OperatorStatisticListItem
      v-for="(stats, index) in statistic.list_data"
      :statistic="stats"
      :key="index"
    />
  </ul>
</template>

<script>
import OperatorStatisticListItem from '@/components/Operators/OperatorStatistic/OperatorStatisticListItem'

export default {
  name: 'OperatorStatisticList',
  components: {
    OperatorStatisticListItem
  },
  props: {
    isHide: {
      type: Boolean
    },
    statistic: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.table-statistic__list {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1.5s ease;
}
.table-statistic__list--close {
  max-height: 800px;
  transition: max-height 2s cubic-bezier(0.3, -0.29, 0.65, 0.71);
}
</style>
