const REQUEST_SHIFT_OPTIONS = 'REQUEST_SHIFT_OPTIONS'
const REQUEST_CALENDAR_EVENTS = 'REQUEST_CALENDAR_EVENTS'
const REQUEST_ADD_CALENDAR_EVENT = 'REQUEST_ADD_CALENDAR_EVENT'
const REQUEST_DELL_MODERATOR_EVENT = 'REQUEST_DELL_MODERATOR_EVENT'
const REQUEST_ADD_MODERATOR_EVENT = 'REQUEST_ADD_MODERATOR_EVENT'
const REQUEST_NEW_SHIFT_OPTIONS = 'REQUEST_NEW_SHIFT_OPTIONS'
const REQUEST_MOD_OPERATOR_EVENTS = 'REQUEST_MOD_OPERATOR_EVENTS'
const REQUEST_CHANGE_OPERATOR_EVENT = 'REQUEST_CHANGE_OPERATOR_EVENT'

export {
  REQUEST_SHIFT_OPTIONS,
  REQUEST_CALENDAR_EVENTS,
  REQUEST_ADD_CALENDAR_EVENT,
  REQUEST_MOD_OPERATOR_EVENTS,
  REQUEST_DELL_MODERATOR_EVENT,
  REQUEST_ADD_MODERATOR_EVENT,
  REQUEST_NEW_SHIFT_OPTIONS,
  REQUEST_CHANGE_OPERATOR_EVENT
}
