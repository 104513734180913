<template>
  <div>
    <div class="gifts-event-header">
      <h1 class="gifts-event-header__title">
        Стоимость подарков событий
      </h1>
      <button
        type="button"
        class="gifts-event-header__btn-add"
        @click="$root.$emit(GIFTS_ENEVT_ADD_NEW_EVENT)"
      >
        <i class="gifts-event-header__new-gift fa fa-plus-square"></i>
        <span class="gifts-event-header__button-text">Добавить</span>
      </button>
    </div>
    <GiftsEventPopupAddNew />
  </div>
</template>

<script>
import { GIFTS_ENEVT_ADD_NEW_EVENT } from '@/constants/event'
import GiftsEventPopupAddNew from '../Modals/GiftsEventPopup/GiftsEventPopupAddNew'
export default {
  name: 'GiftsEventHeader',
  components: {
    GiftsEventPopupAddNew
  },

  data() {
    return { GIFTS_ENEVT_ADD_NEW_EVENT }
  }
}
</script>

<style lang="scss">
@import './GiftsEventHeader.scss';

.gifts__title-icon {
  margin-right: 10px;
}
</style>
