<template>
  <div class="user-active">
    <div class="user-active__menu">
      <div class="user-active__left">
        <span class="user-active__left-text">Дата:</span>
        <div class="datepicker-traffic-source">
          <i class="select__field-icon fa fa-angle-down"></i>
          <DateRangePicker
            :locale-data="picker.localeData"
            :opens="picker.localeData.position"
            :close-on-esc="true"
            :autoApply="true"
            :ranges="false"
            ref="traffic"
            v-model="datePickerControlTraffic"
          >
          </DateRangePicker>
        </div>
        <button class="user-active__btn" @click="getRegEnterStats">
          Показать
        </button>
      </div>
      <div class="user-active__right">
        <div class="user-active__btn-group">
          <button
            class="user-active__btn user-active__btn--default"
            @click="changeStatsShowLabels"
            :class="{
              'user-active__btn--primary': get_enter_stats_show_labels
            }"
          >
            Значения
          </button>
        </div>
        <ServerTimeFilter :utcFlag="form.utc" @changeUtc="changeUtc($event)" />

        <div class="user-active__btn-group">
          <button
            class="user-active__btn user-active__btn--left user-active__btn--default"
            @click="form.type = 0"
            :class="{ 'user-active__btn--danger': form.type === 0 }"
          >
            14 дн.
          </button>
          <button
            class="user-active__btn user-active__btn--center user-active__btn--default"
            @click="form.type = 1"
            :class="{ 'user-active__btn--danger': form.type === 1 }"
          >
            28 дн.
          </button>
          <button
            class="user-active__btn user-active__btn--right user-active__btn--default"
            @click="form.type = 2"
            :class="{ 'user-active__btn--danger': form.type === 2 }"
          >
            42 дн.
          </button>
        </div>
      </div>
    </div>
    <LineChart :chart-data="get_enter_stats" :options="get_enter_stats_opt" class="regenterstats-chart">
    </LineChart>
  </div>
</template>

<script>
import LineChart from './LineChart'
import 'chartjs-plugin-datalabels'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { REQUEST_PURCHASES_BY_PAYERS_PAYMENTS } from '@/store/action-types'
import { mapActions } from 'vuex'
import ServerTimeFilter from '@/components/Statistic/Servertimefilter/ServerTimeFilter'
import pickerMixin from '@/components/Picker/pickerMixin'
export default {
  name: 'StatisticUserPayments',
  props: {
    paymentsByPayers: {
      type: Object
    }
  },
  components: {
    DateRangePicker,
    LineChart,
    ServerTimeFilter
  },
  mixins: [pickerMixin],
  data: () => ({
    statsShowLabels: true,
    loading: false,
    form: {
      reg_date: moment().format('YYYY-MM-DD'),
      type: 0,
      utc: 1
    },
    datepicker_dates: {
      date_from: moment()
        .subtract(14, 'days')
        .startOf('day')
        .toString(),
      date_to: moment()
        .endOf('day')
        .toString()
    },
    get_enter_stats_show_labels: true,
    get_enter_stats: {
      labels: [],
      datasets: [
        {
          borderColor: 'rgb(71,180,60)',
          backgroundColor: 'rgba(123, 180, 99, 0.4)',
          pointBackgroundColor: 'rgb(71,180,60)',
          fill: true,
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverBorderWidth: 2,
          label: 'Плательщики',
          data: [],
          datalabels: {
            align: 'left',
            display: true
          }
        },
        {
          borderColor: 'rgb(65,126,205)',
          backgroundColor: 'rgba(151,187,205,0.4)',
          pointBackgroundColor: 'rgb(65,126,205)',
          fill: true,
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverBorderWidth: 2,
          label: 'Количество покупок',
          data: [],
          datalabels: {
            align: 'top',
            display: true
          }
        }
      ]
    },
    get_enter_stats_opt: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMax: 10,
              beginAtZero: true
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              fontColor: []
            }
          }
        ]
      },
      tooltips: {
        mode: 'label',
        itemSort: function(a, b) {
          return b.datasetIndex - a.datasetIndex
        },
        enabled: true
      },
      elements: {
        point: {
          radius: 4,
          hoverRadius: 6
        }
      },
      plugins: {
        datalabels: {
          display: false,
          offset: 8,
          backgroundColor: function(context) {
            return context.dataset.backgroundColor
          },
          borderRadius: 4,
          borderColor: 'white',
          borderWidth: 1,
          color: '#fff',
          font: {
            weight: 'bold'
          },
          formatter: Math.round
        }
      }
    }
  }),
  watch: {
    'form.type': function() {
      this.getEnterStatsType()
    },
    'form.utc': function() {
      this.getRegEnterStats()
    },
    get_enter_stats_show_labels: function() {
      this.setRegEnterStatsShowLabels()
    }
  },
  computed: {
    datePickerControlTraffic: {
      get() {
        const date = {
          startDate: moment()
            .subtract(14, 'days')
            .startOf('day'),
          endDate: moment().endOf('day')
        }
        return date
      },
      set({ startDate, endDate }) {
        this.datepicker_dates.date_from = new Date(startDate)
        this.datepicker_dates.date_to = new Date(endDate)
      }
    }
  },
  mounted() {
    this.reLoaded()
  },
  methods: {
    ...mapActions({
      getNewPayments: REQUEST_PURCHASES_BY_PAYERS_PAYMENTS
    }),
    reLoaded() {
      let labels_color = []
      let labels_format = []
      for (let label of this.paymentsByPayers.labels) {
        let day = moment(label, 'YYYY.MM.DD').format('dd')

        if (day === 'Su' || day === 'Sa') {
          labels_color.push('#ff6c60')
        } else {
          labels_color.push('#666')
        }

        labels_format.push(moment(label, 'YYYY.MM.DD').format('DD.MM'))
      }
      this.get_enter_stats.labels = labels_format
      this.get_enter_stats_opt.scales.xAxes[0].ticks.fontColor = labels_color
      this.get_enter_stats.datasets.forEach(f => {
        if (f.label === 'Плательщики') {
          f.data = this.paymentsByPayers.payers_count
        } else if (f.label === 'Количество покупок') {
          f.data = this.paymentsByPayers.payments_count
        }
      })
    },
    getEnterStatsType() {
      let startDate = null
      if (this.form.type === 2) {
        startDate = moment()
          .subtract(42, 'days')
          .startOf('day')
          .format('YYYY-MM-DD')
      } else if (this.form.type === 1) {
        startDate = moment()
          .subtract(28, 'days')
          .startOf('day')
          .format('YYYY-MM-DD')
      } else {
        startDate = moment()
          .subtract(14, 'days')
          .startOf('day')
          .format('YYYY-MM-DD')
      }

      this.form.reg_date = moment(this.form.reg_date).format('YYYY-MM-DD')
      this.$refs.traffic.start = new Date(startDate)
      this.$refs.traffic.end = new Date(this.form.reg_date)
      this.datepicker_dates.date_from = new Date(startDate)
      this.datepicker_dates.date_to = new Date(this.form.reg_date)

      let newData = {
        date_from: startDate,
        date_to: this.form.reg_date
      }
      if (this.form.utc) newData.delta = new Date().getTimezoneOffset() / -60
      this.getNewPayments(newData).then(response => {
        this.reLoaded()
      })
    },
    getRegEnterStats() {
      let start = moment(this.datepicker_dates.date_from).format('YYYY-MM-DD')
      let end = moment(this.datepicker_dates.date_to).format('YYYY-MM-DD')
      let newData = {
        date_from: start,
        date_to: end
      }
      if (this.form.utc) newData.delta = new Date().getTimezoneOffset() / -60

      this.getNewPayments(newData).then(response => {
        this.reLoaded()
      })
    },
    changeStatsShowLabels() {
      this.statsShowLabels = !this.statsShowLabels
      this.get_enter_stats_show_labels = !this.get_enter_stats_show_labels
    },
    setRegEnterStatsShowLabels: function() {
      let datasets = this.get_enter_stats.datasets
      for (let dataset of datasets) {
        dataset.datalabels.display = this.get_enter_stats_show_labels
      }
      this.get_enter_stats.datasets = datasets
    },
    changeUtc(number) {
      this.form.utc = number
    }
  }
}
</script>

<style lang="scss">
@import './Statistic.scss';
</style>
