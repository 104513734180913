<template>
  <div class="online-country">
    <div class="online-country__wrapper">
      <div>
        <button
          class="user-active__btn user-active__btn--default user-active__btn--left "
          @click="payments_country_form.count = 1"
          :class="{
            'user-active__btn--danger': payments_country_form.count === 1
          }"
          :disabled="payments_country_loading === true"
        >
          20 Гор.
        </button>
        <button
          class="user-active__btn user-active__btn--default user-active__btn--center "
          @click="payments_country_form.count = 2"
          :class="{
            'user-active__btn--danger': payments_country_form.count === 2
          }"
          :disabled="payments_country_loading === true"
        >
          40 Гор.
        </button>
        <button
          class="user-active__btn user-active__btn--default user-active__btn--right "
          @click="payments_country_form.count = 3"
          :class="{
            'user-active__btn--danger': payments_country_form.count === 3
          }"
          :disabled="payments_country_loading === true"
        >
          60 Гор.
        </button>
      </div>
    </div>
    <div>
      <BarChart
        :chart-data="online_statistic_per_city"
        :options="online_statistic_per_city_opt"
        class="regenterstats-chart"
      ></BarChart>
    </div>
  </div>
</template>

<script>
import 'chartjs-plugin-datalabels'
import BarChart from '@/components/Statistic/BarChart'
import { mapActions } from 'vuex'
import { REQUEST_USERS_ONLINE_BY_CITY } from '@/store/action-types'

export default {
  name: 'StatisticOnlineByCity',
  components: {
    BarChart
  },
  props: {
    onlineByCity: {
      type: Object
    }
  },
  data: () => ({
    payments_country_loading: false,
    payments_country_form: {
      count: 1
    },
    online_statistic_per_city: {
      labels: [''],
      datasets: [
        {
          borderColor: 'rgba(255,108,96,0)',
          backgroundColor: 'rgba(255,108,96,0.5)',
          fill: true,
          label: 'Женщины',
          data: []
        },
        {
          borderColor: 'rgba(151,187,205,0)',
          backgroundColor: 'rgba(151,187,205,0.5)',
          fill: true,
          label: 'Мужчины',
          data: []
        }
      ]
    },
    online_statistic_per_city_opt: {
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              suggestedMax: 10,
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }
        ],
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            },
            maxBarThickness: 100
          }
        ]
      },
      legend: {
        display: true
      },
      tooltips: {
        enabled: true,
        mode: 'label',
        itemSort: function(a, b) {
          return b.datasetIndex - a.datasetIndex
        },
        callbacks: {
          label: function(tooltipItems, data) {
            return data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.yLabel
          }
        }
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let datasets = ctx.chart.data.datasets
            let firstIndex = null
            let sum = 0

            for (let [index, value] of Object.entries(datasets)) {
              if (ctx.chart.isDatasetVisible(index)) {
                firstIndex = parseInt(index)

                sum += parseInt(value.data[ctx.dataIndex])
              }
            }

            return ctx.datasetIndex === firstIndex ? sum.toLocaleString() : ''
          },
          padding: {
            top: 0,
            bottom: -10
          },
          anchor: 'end',
          align: 'end'
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      height: 200
    },
    online_statistic_per_city_form: {
      type: 0
    }
  }),
  watch: {
    'payments_country_form.count': function() {
      this.getPaymentsCountryStatistic()
    }
  },
  mounted() {
    this.reLoaded()
  },

  methods: {
    ...mapActions({
      getOnlineByCity: REQUEST_USERS_ONLINE_BY_CITY
    }),
    reLoaded() {
      this.online_statistic_per_city.datasets[0].data = this.onlineByCity.FEMALE
      this.online_statistic_per_city.datasets[1].data = this.onlineByCity.MALE
      this.online_statistic_per_city.labels = this.onlineByCity.CITY
    },

    getPaymentsCountryStatistic() {
      let count = 0
      switch (this.payments_country_form.count) {
        case 1:
          count = 20
          break
        case 2:
          count = 40
          break
        case 3:
          count = 60
          break
        default:
          break
      }
      this.getOnlineByCity({ city_count: count }).then(response => this.reLoaded())
    }
  }
}
</script>

<style lang="scss">
@import './Statistic.scss';
.online-country {
  padding: 16px;
}
.online-country__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
</style>
