const country = [
  {
    name: 'РОССИЯ',
    id: 'РФ',
    value: 'RU'
  },
  {
    name: 'УКРАИНА',
    id: 'УКРАИНА',
    value: 'UA'
  },
  {
    name: 'БЕЛАРУСЬ',
    id: 'Беларусь',
    value: 'BY'
  },
  {
    name: 'СОЕДИНЕННЫЕ ШТАТЫ',
    id: 'США',
    value: 'US'
  },
  {
    name: 'СОЕДИНЕННОЕ КОРОЛЕВСТВО (Великобритания)',
    id: 'Соединенное Королевство',
    value: 'GB'
  },
  {
    name: 'ГЕРМАНИЯ',
    id: 'Германия',
    value: 'DE'
  },
  {
    name: 'ФРАНЦИЯ',
    id: 'ФРАНЦИЯ',
    value: 'FR'
  },
  {
    name: 'НИГЕРИЯ',
    id: 'Нигерия',
    value: 'NG'
  },
  {
    name: 'АБХАЗИЯ',
    id: 'Республика Абхазия',
    value: 'AB'
  },
  {
    name: 'АВСТРАЛИЯ',
    id: 'АВСТРАЛИЯ',
    value: 'AU'
  },
  {
    name: 'АВСТРИЯ',
    id: 'Австрийская Республика',
    value: 'AT'
  },
  {
    name: 'АЗЕРБАЙДЖАН',
    id: 'Республика Азербайджан',
    value: 'AZ'
  },
  {
    name: 'АЛБАНИЯ',
    id: 'Республика Албания',
    value: 'AL'
  },
  {
    name: 'АЛЖИР',
    id: 'Алжирская Народная Демократическая Республика',
    value: 'DZ'
  },
  {
    name: 'АМЕРИКАНСКОЕ САМОА',
    id: 'АМЕРИКАНСКОЕ САМОА',
    value: 'AS'
  },
  {
    name: 'АНГИЛЬЯ',
    id: 'АНГИЛЬЯ',
    value: 'AI'
  },
  {
    name: 'АНГОЛА',
    id: 'Республика Ангола',
    value: 'AO'
  },
  {
    name: 'АНДОРРА',
    id: 'Княжество Андорра',
    value: 'AD'
  },
  {
    name: 'АНТАРКТИДА',
    id: 'АНТАРКТИДА',
    value: 'AQ'
  },
  {
    name: 'АНТИГУА И БАРБУДА',
    id: 'АНТИГУА И БАРБУДА',
    value: 'AG'
  },
  {
    name: 'АРГЕНТИНА',
    id: 'Аргентинская Республика',
    value: 'AR'
  },
  {
    name: 'АРМЕНИЯ',
    id: 'Республика Армения',
    value: 'AM'
  },
  {
    name: 'АРУБА',
    id: 'АРУБА',
    value: 'AW'
  },
  {
    name: 'АФГАНИСТАН',
    id: 'Переходное Исламское Государство Афганистан',
    value: 'AF'
  },
  {
    name: 'БАГАМЫ',
    id: 'Содружество Багамы',
    value: 'BS'
  },
  {
    name: 'БАНГЛАДЕШ',
    id: 'Народная Республика Бангладеш',
    value: 'BD'
  },
  {
    name: 'БАРБАДОС',
    id: 'БАРБАДОС',
    value: 'BB'
  },
  {
    name: 'БАХРЕЙН',
    id: 'Королевство Бахрейн',
    value: 'BH'
  },
  {
    name: 'БЕЛАРУСЬ',
    id: 'Республика Беларусь',
    value: 'BY'
  },
  {
    name: 'БЕЛИЗ',
    id: 'БЕЛИЗ',
    value: 'BZ'
  },
  {
    name: 'БЕЛЬГИЯ',
    id: 'Королевство Бельгии',
    value: 'BE'
  },
  {
    name: 'БЕНИН',
    id: 'Республика Бенин',
    value: 'BJ'
  },
  {
    name: 'БЕРМУДЫ',
    id: 'БЕРМУДЫ',
    value: 'BM'
  },
  {
    name: 'БОЛГАРИЯ',
    id: 'Республика Болгария',
    value: 'BG'
  },
  {
    name: 'БОЛИВИЯ, МНОГОНАЦИОНАЛЬНОЕ ГОСУДАРСТВО',
    id: 'Многонациональное Государство Боливия',
    value: 'BO'
  },
  {
    name: 'БОНЭЙР, СИНТ-ЭСТАТИУС И САБА',
    id: 'БОНЭЙР, СИНТ-ЭСТАТИУС И САБА',
    value: 'BQ'
  },
  {
    name: 'БОСНИЯ И ГЕРЦЕГОВИНА',
    id: 'БОСНИЯ И ГЕРЦЕГОВИНА',
    value: 'BA'
  },
  {
    name: 'БОТСВАНА',
    id: 'Республика Ботсвана',
    value: 'BW'
  },
  {
    name: 'БРАЗИЛИЯ',
    id: 'Федеративная Республика Бразилия',
    value: 'BR'
  },
  {
    name: 'БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ',
    id: 'БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ',
    value: 'IO'
  },
  {
    name: 'БРУНЕЙ-ДАРУССАЛАМ',
    id: 'БРУНЕЙ-ДАРУССАЛАМ',
    value: 'BN'
  },
  {
    name: 'БУРКИНА-ФАСО',
    id: 'БУРКИНА-ФАСО',
    value: 'BF'
  },
  {
    name: 'БУРУНДИ',
    id: 'Республика Бурунди',
    value: 'BI'
  },
  {
    name: 'БУТАН',
    id: 'Королевство Бутан',
    value: 'BT'
  },
  {
    name: 'ВАНУАТУ',
    id: 'Республика Вануату',
    value: 'VU'
  },
  {
    name: 'ВЕНГРИЯ',
    id: 'Венгрия',
    value: 'HU'
  },
  {
    name: 'ВЕНЕСУЭЛА (БОЛИВАРИАНСКАЯ РЕСПУБЛИКА)',
    id: 'Боливарианская Республика Венесуэла',
    value: 'VE'
  },
  {
    name: 'ВИРГИНСКИЕ ОСТРОВА (БРИТАНСКИЕ)',
    id: 'Британские Виргинские острова',
    value: 'VG'
  },
  {
    name: 'ВИРГИНСКИЕ ОСТРОВА (США)',
    id: 'Виргинские острова Соединенных Штатов',
    value: 'VI'
  },
  {
    name: 'ВЬЕТНАМ',
    id: 'Социалистическая Республика Вьетнам',
    value: 'VN'
  },
  {
    name: 'ГАБОН',
    id: 'Габонская Республика',
    value: 'GA'
  },
  {
    name: 'ГАИТИ',
    id: 'Республика Гаити',
    value: 'HT'
  },
  {
    name: 'ГАЙАНА',
    id: 'Кооперативная Республика Гайана',
    value: 'GY'
  },
  {
    name: 'ГАМБИЯ',
    id: 'Исламская Республика Гамбия',
    value: 'GM'
  },
  {
    name: 'ГАНА',
    id: 'Республика Гана',
    value: 'GH'
  },
  {
    name: 'ГВАДЕЛУПА',
    id: 'ГВАДЕЛУПА',
    value: 'GP'
  },
  {
    name: 'ГВАТЕМАЛА',
    id: 'Республика Гватемала',
    value: 'GT'
  },
  {
    name: 'ГВИНЕЯ',
    id: 'Гвинейская Республика',
    value: 'GN'
  },
  {
    name: 'ГВИНЕЯ-БИСАУ',
    id: 'Республика Гвинея-Бисау',
    value: 'GW'
  },
  {
    name: 'ГЕРМАНИЯ',
    id: 'Федеративная Республика Германия',
    value: 'DE'
  },
  {
    name: 'ГЕРНСИ',
    id: 'ГЕРНСИ',
    value: 'GG'
  },
  {
    name: 'ГИБРАЛТАР',
    id: 'ГИБРАЛТАР',
    value: 'GI'
  },
  {
    name: 'ГОНДУРАС',
    id: 'Республика Гондурас',
    value: 'HN'
  },
  {
    name: 'ГОНКОНГ',
    id: 'Специальный административный район Гонконг Китайской Народной Республики',
    value: 'HK'
  },
  {
    name: 'ГРЕНАДА',
    id: 'ГРЕНАДА',
    value: 'GD'
  },
  {
    name: 'ГРЕНЛАНДИЯ',
    id: 'ГРЕНЛАНДИЯ',
    value: 'GL'
  },
  {
    name: 'ГРЕЦИЯ',
    id: 'Греческая Республика',
    value: 'GR'
  },
  {
    name: 'ГРУЗИЯ',
    id: 'ГРУЗИЯ',
    value: 'GE'
  },
  {
    name: 'ГУАМ',
    id: 'ГУАМ',
    value: 'GU'
  },
  {
    name: 'ДАНИЯ',
    id: 'Королевство Дания',
    value: 'DK'
  },
  {
    name: 'ДЖЕРСИ',
    id: 'ДЖЕРСИ',
    value: 'JE'
  },
  {
    name: 'ДЖИБУТИ',
    id: 'Республика Джибути',
    value: 'DJ'
  },
  {
    name: 'ДОМИНИКА',
    id: 'Содружество Доминики',
    value: 'DM'
  },
  {
    name: 'ДОМИНИКАНСКАЯ РЕСПУБЛИКА',
    id: 'ДОМИНИКАНСКАЯ РЕСПУБЛИКА',
    value: 'DO'
  },
  {
    name: 'ЕГИПЕТ',
    id: 'Арабская Республика Египет',
    value: 'EG'
  },
  {
    name: 'ЗАМБИЯ',
    id: 'Республика Замбия',
    value: 'ZM'
  },
  {
    name: 'ЗАПАДНАЯ САХАРА',
    id: 'ЗАПАДНАЯ САХАРА',
    value: 'EH'
  },
  {
    name: 'ЗИМБАБВЕ',
    id: 'Республика Зимбабве',
    value: 'ZW'
  },
  {
    name: 'ИЗРАИЛЬ',
    id: 'Государство Израиль',
    value: 'IL'
  },
  {
    name: 'ИНДИЯ',
    id: 'Республика Индия',
    value: 'IN'
  },
  {
    name: 'ИНДОНЕЗИЯ',
    id: 'Республика Индонезия',
    value: 'ID'
  },
  {
    name: 'ИОРДАНИЯ',
    id: 'Иорданское Хашимитское Королевство',
    value: 'JO'
  },
  {
    name: 'ИРАК',
    id: 'Республика Ирак',
    value: 'IQ'
  },
  {
    name: 'ИРАН (ИСЛАМСКАЯ РЕСПУБЛИКА)',
    id: 'Исламская Республика Иран',
    value: 'IR'
  },
  {
    name: 'ИРЛАНДИЯ',
    id: 'ИРЛАНДИЯ',
    value: 'IE'
  },
  {
    name: 'ИСЛАНДИЯ',
    id: 'Республика Исландия',
    value: 'IS'
  },
  {
    name: 'ИСПАНИЯ',
    id: 'Королевство Испания',
    value: 'ES'
  },
  {
    name: 'ИТАЛИЯ',
    id: 'Итальянская Республика',
    value: 'IT'
  },
  {
    name: 'ЙЕМЕН',
    id: 'Йеменская Республика',
    value: 'YE'
  },
  {
    name: 'КАБО-ВЕРДЕ',
    id: 'Республика Кабо-Верде',
    value: 'CV'
  },
  {
    name: 'КАЗАХСТАН',
    id: 'Республика Казахстан',
    value: 'KZ'
  },
  {
    name: 'КАМБОДЖА',
    id: 'Королевство Камбоджа',
    value: 'KH'
  },
  {
    name: 'КАМЕРУН',
    id: 'Республика Камерун',
    value: 'CM'
  },
  {
    name: 'КАНАДА',
    id: 'КАНАДА',
    value: 'CA'
  },
  {
    name: 'КАТАР',
    id: 'Государство Катар',
    value: 'QA'
  },
  {
    name: 'КЕНИЯ',
    id: 'Республика Кения',
    value: 'KE'
  },
  {
    name: 'КИПР',
    id: 'Республика Кипр',
    value: 'CY'
  },
  {
    name: 'КИРГИЗИЯ',
    id: 'Киргизская Республика',
    value: 'KG'
  },
  {
    name: 'КИРИБАТИ',
    id: 'Республика Кирибати',
    value: 'KI'
  },
  {
    name: 'КИТАЙ',
    id: 'Китайская Народная Республика',
    value: 'CN'
  },
  {
    name: 'КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА',
    id: 'КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА',
    value: 'CC'
  },
  {
    name: 'КОЛУМБИЯ',
    id: 'Республика Колумбия',
    value: 'CO'
  },
  {
    name: 'КОМОРЫ',
    id: 'Союз Коморы',
    value: 'KM'
  },
  {
    name: 'КОНГО',
    id: 'Республика Конго',
    value: 'CG'
  },
  {
    name: 'КОНГО, ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    id: 'Демократическая Республика Конго',
    value: 'CD'
  },
  {
    name: 'КОРЕЯ, НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    id: 'Корейская Народно-Демократическая Республика',
    value: 'KP'
  },
  {
    name: 'КОРЕЯ, РЕСПУБЛИКА',
    id: 'Республика Корея',
    value: 'KR'
  },
  {
    name: 'КОСТА-РИКА',
    id: 'Республика Коста-Рика',
    value: 'CR'
  },
  {
    name: 'КОТ Д`ИВУАР',
    id: 'Республика Кот д`Ивуар',
    value: 'CI'
  },
  {
    name: 'КУБА',
    id: 'Республика Куба',
    value: 'CU'
  },
  {
    name: 'КУВЕЙТ',
    id: 'Государство Кувейт',
    value: 'KW'
  },
  {
    name: 'КЮРАСАО',
    id: 'КЮРАСАО',
    value: 'CW'
  },
  {
    name: 'ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    id: 'ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    value: 'LA'
  },
  {
    name: 'ЛАТВИЯ',
    id: 'Латвийская Республика',
    value: 'LV'
  },
  {
    name: 'ЛЕСОТО',
    id: 'Королевство Лесото',
    value: 'LS'
  },
  {
    name: 'ЛИБЕРИЯ',
    id: 'Республика Либерия',
    value: 'LR'
  },
  {
    name: 'ЛИВАН',
    id: 'Ливанская Республика',
    value: 'LB'
  },
  {
    name: 'ЛИВИЯ',
    id: 'Государство Ливия',
    value: 'LY'
  },
  {
    name: 'ЛИТВА',
    id: 'Литовская Республика',
    value: 'LT'
  },
  {
    name: 'ЛИХТЕНШТЕЙН',
    id: 'Княжество Лихтенштейн',
    value: 'LI'
  },
  {
    name: 'ЛЮКСЕМБУРГ',
    id: 'Великое Герцогство Люксембург',
    value: 'LU'
  },
  {
    name: 'МАВРИКИЙ',
    id: 'Республика Маврикий',
    value: 'MU'
  },
  {
    name: 'МАВРИТАНИЯ',
    id: 'Исламская Республика Мавритания',
    value: 'MR'
  },
  {
    name: 'МАДАГАСКАР',
    id: 'Республика Мадагаскар',
    value: 'MG'
  },
  {
    name: 'МАЙОТТА',
    id: 'МАЙОТТА',
    value: 'YT'
  },
  {
    name: 'МАКАО',
    id: 'Специальный административный район Макао Китайской Народной Республики',
    value: 'MO'
  },
  {
    name: 'МАЛАВИ',
    id: 'Республика Малави',
    value: 'MW'
  },
  {
    name: 'МАЛАЙЗИЯ',
    id: 'МАЛАЙЗИЯ',
    value: 'MY'
  },
  {
    name: 'МАЛИ',
    id: 'Республика Мали',
    value: 'ML'
  },
  {
    name: 'МАЛЫЕ ТИХООКЕАНСКИЕ ОТДАЛЕННЫЕ ОСТРОВА СОЕДИНЕННЫХ ШТАТОВ',
    id: 'МАЛЫЕ ТИХООКЕАНСКИЕ ОТДАЛЕННЫЕ ОСТРОВА СОЕДИНЕННЫХ ШТАТОВ',
    value: 'UM'
  },
  {
    name: 'МАЛЬДИВЫ',
    id: 'Мальдивская Республика',
    value: 'MV'
  },
  {
    name: 'МАЛЬТА',
    id: 'Республика Мальта',
    value: 'MT'
  },
  {
    name: 'МАРОККО',
    id: 'Королевство Марокко',
    value: 'MA'
  },
  {
    name: 'МАРТИНИКА',
    id: 'МАРТИНИКА',
    value: 'MQ'
  },
  {
    name: 'МАРШАЛЛОВЫ ОСТРОВА',
    id: 'Республика Маршалловы Острова',
    value: 'MH'
  },
  {
    name: 'МЕКСИКА',
    id: 'Мексиканские Соединенные Штаты',
    value: 'MX'
  },
  {
    name: 'МИКРОНЕЗИЯ, ФЕДЕРАТИВНЫЕ ШТАТЫ',
    id: 'Федеративные Штаты Микронезии',
    value: 'FM'
  },
  {
    name: 'МОЗАМБИК',
    id: 'Республика Мозамбик',
    value: 'MZ'
  },
  {
    name: 'МОЛДОВА, РЕСПУБЛИКА',
    id: 'Республика Молдова',
    value: 'MD'
  },
  {
    name: 'МОНАКО',
    id: 'Княжество Монако',
    value: 'MC'
  },
  {
    name: 'МОНГОЛИЯ',
    id: 'МОНГОЛИЯ',
    value: 'MN'
  },
  {
    name: 'МОНТСЕРРАТ',
    id: 'МОНТСЕРРАТ',
    value: 'MS'
  },
  {
    name: 'МЬЯНМА',
    id: 'Республика Союза Мьянма',
    value: 'MM'
  },
  {
    name: 'НАМИБИЯ',
    id: 'Республика Намибия',
    value: 'NA'
  },
  {
    name: 'НАУРУ',
    id: 'Республика Науру',
    value: 'NR'
  },
  {
    name: 'НЕПАЛ',
    id: 'Непал',
    value: 'NP'
  },
  {
    name: 'НИГЕР',
    id: 'Республика Нигер',
    value: 'NE'
  },
  {
    name: 'НИГЕРИЯ',
    id: 'Федеративная Республика Нигерия',
    value: 'NG'
  },
  {
    name: 'НИДЕРЛАНДЫ',
    id: 'Королевство Нидерландов',
    value: 'NL'
  },
  {
    name: 'НИКАРАГУА',
    id: 'Республика Никарагуа',
    value: 'NI'
  },
  {
    name: 'НИУЭ',
    id: 'Ниуэ',
    value: 'NU'
  },
  {
    name: 'НОВАЯ ЗЕЛАНДИЯ',
    id: 'НОВАЯ ЗЕЛАНДИЯ',
    value: 'NZ'
  },
  {
    name: 'НОВАЯ КАЛЕДОНИЯ',
    id: 'НОВАЯ КАЛЕДОНИЯ',
    value: 'NC'
  },
  {
    name: 'НОРВЕГИЯ',
    id: 'Королевство Норвегия',
    value: 'NO'
  },
  {
    name: 'ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ',
    id: 'ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ',
    value: 'AE'
  },
  {
    name: 'ОМАН',
    id: 'Султанат Оман',
    value: 'OM'
  },
  {
    name: 'ОСТРОВА КАЙМАН',
    id: 'ОСТРОВА КАЙМАН',
    value: 'KY'
  },
  {
    name: 'ОСТРОВА КУКА',
    id: 'ОСТРОВА КУКА',
    value: 'CK'
  },
  {
    name: 'ОСТРОВА ТЕРКС И КАЙКОС',
    id: 'ОСТРОВА ТЕРКС И КАЙКОС',
    value: 'TC'
  },
  {
    name: 'ОСТРОВ БУВЕ',
    id: 'ОСТРОВ БУВЕ',
    value: 'BV'
  },
  {
    name: 'ОСТРОВ МЭН',
    id: 'ОСТРОВ МЭН',
    value: 'IM'
  },
  {
    name: 'ОСТРОВ НОРФОЛК',
    id: 'ОСТРОВ НОРФОЛК',
    value: 'NF'
  },
  {
    name: 'ОСТРОВ РОЖДЕСТВА',
    id: 'ОСТРОВ РОЖДЕСТВА',
    value: 'CX'
  },
  {
    name: 'ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД',
    id: 'ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД',
    value: 'HM'
  },
  {
    name: 'ПАКИСТАН',
    id: 'Исламская Республика Пакистан',
    value: 'PK'
  },
  {
    name: 'ПАЛАУ',
    id: 'Республика Палау',
    value: 'PW'
  },
  {
    name: 'ПАЛЕСТИНА, ГОСУДАРСТВО',
    id: 'Государство Палестина',
    value: 'PS'
  },
  {
    name: 'ПАНАМА',
    id: 'Республика Панама',
    value: 'PA'
  },
  {
    name: 'ПАПСКИЙ ПРЕСТОЛ (ГОСУДАРСТВО-ГОРОД ВАТИКАН)',
    id: 'ПАПСКИЙ ПРЕСТОЛ (ГОСУДАРСТВО-ГОРОД ВАТИКАН)',
    value: 'VA'
  },
  {
    name: 'ПАПУА НОВАЯ ГВИНЕЯ',
    id: 'Независимое Государство Папуа Новая Гвинея',
    value: 'PG'
  },
  {
    name: 'ПАРАГВАЙ',
    id: 'Республика Парагвай',
    value: 'PY'
  },
  {
    name: 'ПЕРУ',
    id: 'Республика Перу',
    value: 'PE'
  },
  {
    name: 'ПИТКЕРН',
    id: 'ПИТКЕРН',
    value: 'PN'
  },
  {
    name: 'ПОЛЬША',
    id: 'Республика Польша',
    value: 'PL'
  },
  {
    name: 'ПОРТУГАЛИЯ',
    id: 'Португальская Республика',
    value: 'PT'
  },
  {
    name: 'ПУЭРТО-РИКО',
    id: 'ПУЭРТО-РИКО',
    value: 'PR'
  },
  {
    name: 'РЕЮНЬОН',
    id: 'РЕЮНЬОН',
    value: 'RE'
  },
  {
    name: 'РОССИЯ',
    id: 'Российская Федерация',
    value: 'RU'
  },
  {
    name: 'РУАНДА',
    id: 'Руандийская Республика',
    value: 'RW'
  },
  {
    name: 'РУМЫНИЯ',
    id: 'РУМЫНИЯ',
    value: 'RO'
  },
  {
    name: 'САМОА',
    id: 'Независимое Государство Самоа',
    value: 'WS'
  },
  {
    name: 'САН-МАРИНО',
    id: 'Республика Сан-Марино',
    value: 'SM'
  },
  {
    name: 'САН-ТОМЕ И ПРИНСИПИ',
    id: 'Демократическая Республика Сан-Томе и Принсипи',
    value: 'ST'
  },
  {
    name: 'САУДОВСКАЯ АРАВИЯ',
    id: 'Королевство Саудовская Аравия',
    value: 'SA'
  },
  {
    name: 'СВЯТАЯ ЕЛЕНА, ОСТРОВ ВОЗНЕСЕНИЯ, ТРИСТАН-ДА-КУНЬЯ',
    id: 'СВЯТАЯ ЕЛЕНА, ОСТРОВ ВОЗНЕСЕНИЯ, ТРИСТАН-ДА-КУНЬЯ',
    value: 'SH'
  },
  {
    name: 'СЕВЕРНАЯ МАКЕДОНИЯ',
    id: 'Республика Северная Македония',
    value: 'MK'
  },
  {
    name: 'СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА',
    id: 'Содружество Северных Марианских островов',
    value: 'MP'
  },
  {
    name: 'СЕЙШЕЛЫ',
    id: 'Республика Сейшелы',
    value: 'SC'
  },
  {
    name: 'СЕН-БАРТЕЛЕМИ',
    id: 'СЕН-БАРТЕЛЕМИ',
    value: 'BL'
  },
  {
    name: 'СЕН-МАРТЕН (ФРАНЦУЗСКАЯ ЧАСТЬ)',
    id: 'СЕН-МАРТЕН (ФРАНЦУЗСКАЯ ЧАСТЬ)',
    value: 'MF'
  },
  {
    name: 'СЕН-МАРТЕН (нидерландская часть)',
    id: 'СЕН-МАРТЕН (нидерландская часть)',
    value: 'SX'
  },
  {
    name: 'СЕНЕГАЛ',
    id: 'Республика Сенегал',
    value: 'SN'
  },
  {
    name: 'СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ',
    id: 'СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ',
    value: 'VC'
  },
  {
    name: 'СЕНТ-КИТС И НЕВИС',
    id: 'СЕНТ-КИТС И НЕВИС',
    value: 'KN'
  },
  {
    name: 'СЕНТ-ЛЮСИЯ',
    id: 'СЕНТ-ЛЮСИЯ',
    value: 'LC'
  },
  {
    name: 'СЕНТ-ПЬЕР И МИКЕЛОН',
    id: 'СЕНТ-ПЬЕР И МИКЕЛОН',
    value: 'PM'
  },
  {
    name: 'СЕРБИЯ',
    id: 'Республика Сербия',
    value: 'RS'
  },
  {
    name: 'СИНГАПУР',
    id: 'Республика Сингапур',
    value: 'SG'
  },
  {
    name: 'СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА',
    id: 'СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА',
    value: 'SY'
  },
  {
    name: 'СЛОВАКИЯ',
    id: 'Словацкая Республика',
    value: 'SK'
  },
  {
    name: 'СЛОВЕНИЯ',
    id: 'Республика Словения',
    value: 'SI'
  },
  {
    name: 'СОЕДИНЕННОЕ КОРОЛЕВСТВО',
    id: 'Соединенное Королевство Великобритании и Северной Ирландии',
    value: 'GB'
  },
  {
    name: 'СОЕДИНЕННЫЕ ШТАТЫ',
    id: 'Соединенные Штаты Америки',
    value: 'US'
  },
  {
    name: 'СОЛОМОНОВЫ ОСТРОВА',
    id: 'СОЛОМОНОВЫ ОСТРОВА',
    value: 'SB'
  },
  {
    name: 'СОМАЛИ',
    id: 'Федеративная Республика Сомали',
    value: 'SO'
  },
  {
    name: 'СУДАН',
    id: 'Республика Судан',
    value: 'SD'
  },
  {
    name: 'СУРИНАМ',
    id: 'Республика Суринам',
    value: 'SR'
  },
  {
    name: 'СЬЕРРА-ЛЕОНЕ',
    id: 'Республика Сьерра-Леоне',
    value: 'SL'
  },
  {
    name: 'ТАДЖИКИСТАН',
    id: 'Республика Таджикистан',
    value: 'TJ'
  },
  {
    name: 'ТАИЛАНД',
    id: 'Королевство Таиланд',
    value: 'TH'
  },
  {
    name: 'ТАЙВАНЬ (КИТАЙ)',
    id: 'ТАЙВАНЬ (КИТАЙ)',
    value: 'TW'
  },
  {
    name: 'ТАНЗАНИЯ, ОБЪЕДИНЕННАЯ РЕСПУБЛИКА',
    id: 'Объединенная Республика Танзания',
    value: 'TZ'
  },
  {
    name: 'ТИМОР-ЛЕСТЕ',
    id: 'Демократическая Республика Тимор-Лесте',
    value: 'TL'
  },
  {
    name: 'ТОГО',
    id: 'Тоголезская Республика',
    value: 'TG'
  },
  {
    name: 'ТОКЕЛАУ',
    id: 'ТОКЕЛАУ',
    value: 'TK'
  },
  {
    name: 'ТОНГА',
    id: 'Королевство Тонга',
    value: 'TO'
  },
  {
    name: 'ТРИНИДАД И ТОБАГО',
    id: 'Республика Тринидад и Тобаго',
    value: 'TT'
  },
  {
    name: 'ТУВАЛУ',
    id: 'ТУВАЛУ',
    value: 'TV'
  },
  {
    name: 'ТУНИС',
    id: 'Тунисская Республика',
    value: 'TN'
  },
  {
    name: 'ТУРКМЕНИСТАН',
    id: 'ТУРКМЕНИСТАН',
    value: 'TM'
  },
  {
    name: 'ТУРЦИЯ',
    id: 'Турецкая Республика',
    value: 'TR'
  },
  {
    name: 'УГАНДА',
    id: 'Республика Уганда',
    value: 'UG'
  },
  {
    name: 'УЗБЕКИСТАН',
    id: 'Республика Узбекистан',
    value: 'UZ'
  },
  {
    name: 'УОЛЛИС И ФУТУНА',
    id: 'УОЛЛИС И ФУТУНА',
    value: 'WF'
  },
  {
    name: 'УРУГВАЙ',
    id: 'Восточная Республика Уругвай',
    value: 'UY'
  },
  {
    name: 'ФАРЕРСКИЕ ОСТРОВА',
    id: 'ФАРЕРСКИЕ ОСТРОВА',
    value: 'FO'
  },
  {
    name: 'ФИДЖИ',
    id: 'Республика Фиджи',
    value: 'FJ'
  },
  {
    name: 'ФИЛИППИНЫ',
    id: 'Республика Филиппины',
    value: 'PH'
  },
  {
    name: 'ФИНЛЯНДИЯ',
    id: 'Финляндская Республика',
    value: 'FI'
  },
  {
    name: 'ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)',
    id: 'ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)',
    value: 'FK'
  },
  {
    name: 'ФРАНЦИЯ',
    id: 'Французская Республика',
    value: 'FR'
  },
  {
    name: 'ФРАНЦУЗСКАЯ ГВИАНА',
    id: 'ФРАНЦУЗСКАЯ ГВИАНА',
    value: 'GF'
  },
  {
    name: 'ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ',
    id: 'ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ',
    value: 'PF'
  },
  {
    name: 'ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ',
    id: 'ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ',
    value: 'TF'
  },
  {
    name: 'ХОРВАТИЯ',
    id: 'Республика Хорватия',
    value: 'HR'
  },
  {
    name: 'ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА',
    id: 'ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА',
    value: 'CF'
  },
  {
    name: 'ЧАД',
    id: 'Республика Чад',
    value: 'TD'
  },
  {
    name: 'ЧЕРНОГОРИЯ',
    id: 'ЧЕРНОГОРИЯ',
    value: 'ME'
  },
  {
    name: 'ЧЕХИЯ',
    id: 'Чешская Республика',
    value: 'CZ'
  },
  {
    name: 'ЧИЛИ',
    id: 'Республика Чили',
    value: 'CL'
  },
  {
    name: 'ШВЕЙЦАРИЯ',
    id: 'Швейцарская Конфедерация',
    value: 'CH'
  },
  {
    name: 'ШВЕЦИЯ',
    id: 'Королевство Швеция',
    value: 'SE'
  },
  {
    name: 'ШПИЦБЕРГЕН И ЯН МАЙЕН',
    id: 'ШПИЦБЕРГЕН И ЯН МАЙЕН',
    value: 'SJ'
  },
  {
    name: 'ШРИ-ЛАНКА',
    id: 'Демократическая Социалистическая Республика Шри-Ланка',
    value: 'LK'
  },
  {
    name: 'ЭКВАДОР',
    id: 'Республика Эквадор',
    value: 'EC'
  },
  {
    name: 'ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ',
    id: 'Республика Экваториальная Гвинея',
    value: 'GQ'
  },
  {
    name: 'ЭЛАНДСКИЕ ОСТРОВА',
    id: 'ЭЛАНДСКИЕ ОСТРОВА',
    value: 'AX'
  },
  {
    name: 'ЭЛЬ-САЛЬВАДОР',
    id: 'Республика Эль-Сальвадор',
    value: 'SV'
  },
  {
    name: 'ЭРИТРЕЯ',
    id: 'Государство Эритрея',
    value: 'ER'
  },
  {
    name: 'ЭСВАТИНИ',
    id: 'Королевство Эсватини',
    value: 'SZ'
  },
  {
    name: 'ЭСТОНИЯ',
    id: 'Эстонская Республика',
    value: 'EE'
  },
  {
    name: 'ЭФИОПИЯ',
    id: 'Федеративная Демократическая Республика Эфиопия',
    value: 'ET'
  },
  {
    name: 'ЮЖНАЯ АФРИКА',
    id: 'Южно-Африканская Республика',
    value: 'ZA'
  },
  {
    name: 'ЮЖНАЯ ДЖОРДЖИЯ И ЮЖНЫЕ САНДВИЧЕВЫ ОСТРОВА',
    id: 'ЮЖНАЯ ДЖОРДЖИЯ И ЮЖНЫЕ САНДВИЧЕВЫ ОСТРОВА',
    value: 'GS'
  },
  {
    name: 'ЮЖНАЯ ОСЕТИЯ',
    id: 'Республика Южная Осетия',
    value: 'OS'
  },
  {
    name: 'ЮЖНЫЙ СУДАН',
    id: 'Республика Южный Судан',
    value: 'SS'
  },
  {
    name: 'ЯМАЙКА',
    id: 'ЯМАЙКА',
    value: 'JM'
  },
  {
    name: 'ЯПОНИЯ',
    id: 'ЯПОНИЯ',
    value: 'JP'
  }
]
const countryUser = [
  {
    name: 'РОССИЯ',
    id: 'РФ',
    value: 'RU'
  },
  {
    name: 'УКРАИНА',
    id: 'УКРАИНА',
    value: 'UA'
  },
  {
    name: 'БЕЛАРУСЬ',
    id: 'Беларусь',
    value: 'BY'
  },
  {
    name: 'СОЕДИНЕННЫЕ ШТАТЫ',
    id: 'США',
    value: 'US'
  },
  {
    name: 'СОЕДИНЕННОЕ КОРОЛЕВСТВО (Великобритания)',
    id: 'Соединенное Королевство',
    value: 'GB'
  },
  {
    name: 'ГЕРМАНИЯ',
    id: 'Германия',
    value: 'DE'
  },
  {
    name: 'ФРАНЦИЯ',
    id: 'ФРАНЦИЯ',
    value: 'FR'
  },
  {
    name: 'НИГЕРИЯ',
    id: 'Нигерия',
    value: 'NG'
  },
  {
    name: 'АБХАЗИЯ',
    id: 'Республика Абхазия',
    value: 'AB'
  },
  {
    name: 'АВСТРАЛИЯ',
    id: 'АВСТРАЛИЯ',
    value: 'AU'
  },
  {
    name: 'АВСТРИЯ',
    id: 'Австрийская Республика',
    value: 'AT'
  },
  {
    name: 'АЗЕРБАЙДЖАН',
    id: 'Республика Азербайджан',
    value: 'AZ'
  },
  {
    name: 'АЛБАНИЯ',
    id: 'Республика Албания',
    value: 'AL'
  },
  {
    name: 'АЛЖИР',
    id: 'Алжирская Народная Демократическая Республика',
    value: 'DZ'
  },
  {
    name: 'АМЕРИКАНСКОЕ САМОА',
    id: 'АМЕРИКАНСКОЕ САМОА',
    value: 'AS'
  },
  {
    name: 'АНГИЛЬЯ',
    id: 'АНГИЛЬЯ',
    value: 'AI'
  },
  {
    name: 'АНГОЛА',
    id: 'Республика Ангола',
    value: 'AO'
  },
  {
    name: 'АНДОРРА',
    id: 'Княжество Андорра',
    value: 'AD'
  },
  {
    name: 'АНТАРКТИДА',
    id: 'АНТАРКТИДА',
    value: 'AQ'
  },
  {
    name: 'АНТИГУА И БАРБУДА',
    id: 'АНТИГУА И БАРБУДА',
    value: 'AG'
  },
  {
    name: 'АРГЕНТИНА',
    id: 'Аргентинская Республика',
    value: 'AR'
  },
  {
    name: 'АРМЕНИЯ',
    id: 'Республика Армения',
    value: 'AM'
  },
  {
    name: 'АРУБА',
    id: 'АРУБА',
    value: 'AW'
  },
  {
    name: 'АФГАНИСТАН',
    id: 'Переходное Исламское Государство Афганистан',
    value: 'AF'
  },
  {
    name: 'БАГАМЫ',
    id: 'Содружество Багамы',
    value: 'BS'
  },
  {
    name: 'БАНГЛАДЕШ',
    id: 'Народная Республика Бангладеш',
    value: 'BD'
  },
  {
    name: 'БАРБАДОС',
    id: 'БАРБАДОС',
    value: 'BB'
  },
  {
    name: 'БАХРЕЙН',
    id: 'Королевство Бахрейн',
    value: 'BH'
  },
  {
    name: 'БЕЛАРУСЬ',
    id: 'Республика Беларусь',
    value: 'BY'
  },
  {
    name: 'БЕЛИЗ',
    id: 'БЕЛИЗ',
    value: 'BZ'
  },
  {
    name: 'БЕЛЬГИЯ',
    id: 'Королевство Бельгии',
    value: 'BE'
  },
  {
    name: 'БЕНИН',
    id: 'Республика Бенин',
    value: 'BJ'
  },
  {
    name: 'БЕРМУДЫ',
    id: 'БЕРМУДЫ',
    value: 'BM'
  },
  {
    name: 'БОЛГАРИЯ',
    id: 'Республика Болгария',
    value: 'BG'
  },
  {
    name: 'БОЛИВИЯ, МНОГОНАЦИОНАЛЬНОЕ ГОСУДАРСТВО',
    id: 'Многонациональное Государство Боливия',
    value: 'BO'
  },
  {
    name: 'БОНЭЙР, СИНТ-ЭСТАТИУС И САБА',
    id: 'БОНЭЙР, СИНТ-ЭСТАТИУС И САБА',
    value: 'BQ'
  },
  {
    name: 'БОСНИЯ И ГЕРЦЕГОВИНА',
    id: 'БОСНИЯ И ГЕРЦЕГОВИНА',
    value: 'BA'
  },
  {
    name: 'БОТСВАНА',
    id: 'Республика Ботсвана',
    value: 'BW'
  },
  {
    name: 'БРАЗИЛИЯ',
    id: 'Федеративная Республика Бразилия',
    value: 'BR'
  },
  {
    name: 'БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ',
    id: 'БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ',
    value: 'IO'
  },
  {
    name: 'БРУНЕЙ-ДАРУССАЛАМ',
    id: 'БРУНЕЙ-ДАРУССАЛАМ',
    value: 'BN'
  },
  {
    name: 'БУРКИНА-ФАСО',
    id: 'БУРКИНА-ФАСО',
    value: 'BF'
  },
  {
    name: 'БУРУНДИ',
    id: 'Республика Бурунди',
    value: 'BI'
  },
  {
    name: 'БУТАН',
    id: 'Королевство Бутан',
    value: 'BT'
  },
  {
    name: 'ВАНУАТУ',
    id: 'Республика Вануату',
    value: 'VU'
  },
  {
    name: 'ВЕНГРИЯ',
    id: 'Венгрия',
    value: 'HU'
  },
  {
    name: 'ВЕНЕСУЭЛА (БОЛИВАРИАНСКАЯ РЕСПУБЛИКА)',
    id: 'Боливарианская Республика Венесуэла',
    value: 'VE'
  },
  {
    name: 'ВИРГИНСКИЕ ОСТРОВА (БРИТАНСКИЕ)',
    id: 'Британские Виргинские острова',
    value: 'VG'
  },
  {
    name: 'ВИРГИНСКИЕ ОСТРОВА (США)',
    id: 'Виргинские острова Соединенных Штатов',
    value: 'VI'
  },
  {
    name: 'ВЬЕТНАМ',
    id: 'Социалистическая Республика Вьетнам',
    value: 'VN'
  },
  {
    name: 'ГАБОН',
    id: 'Габонская Республика',
    value: 'GA'
  },
  {
    name: 'ГАИТИ',
    id: 'Республика Гаити',
    value: 'HT'
  },
  {
    name: 'ГАЙАНА',
    id: 'Кооперативная Республика Гайана',
    value: 'GY'
  },
  {
    name: 'ГАМБИЯ',
    id: 'Исламская Республика Гамбия',
    value: 'GM'
  },
  {
    name: 'ГАНА',
    id: 'Республика Гана',
    value: 'GH'
  },
  {
    name: 'ГВАДЕЛУПА',
    id: 'ГВАДЕЛУПА',
    value: 'GP'
  },
  {
    name: 'ГВАТЕМАЛА',
    id: 'Республика Гватемала',
    value: 'GT'
  },
  {
    name: 'ГВИНЕЯ',
    id: 'Гвинейская Республика',
    value: 'GN'
  },
  {
    name: 'ГВИНЕЯ-БИСАУ',
    id: 'Республика Гвинея-Бисау',
    value: 'GW'
  },
  {
    name: 'ГЕРМАНИЯ',
    id: 'Федеративная Республика Германия',
    value: 'DE'
  },
  {
    name: 'ГЕРНСИ',
    id: 'ГЕРНСИ',
    value: 'GG'
  },
  {
    name: 'ГИБРАЛТАР',
    id: 'ГИБРАЛТАР',
    value: 'GI'
  },
  {
    name: 'ГОНДУРАС',
    id: 'Республика Гондурас',
    value: 'HN'
  },
  {
    name: 'ГОНКОНГ',
    id: 'Специальный административный район Гонконг Китайской Народной Республики',
    value: 'HK'
  },
  {
    name: 'ГРЕНАДА',
    id: 'ГРЕНАДА',
    value: 'GD'
  },
  {
    name: 'ГРЕНЛАНДИЯ',
    id: 'ГРЕНЛАНДИЯ',
    value: 'GL'
  },
  {
    name: 'ГРЕЦИЯ',
    id: 'Греческая Республика',
    value: 'GR'
  },
  {
    name: 'ГРУЗИЯ',
    id: 'ГРУЗИЯ',
    value: 'GE'
  },
  {
    name: 'ГУАМ',
    id: 'ГУАМ',
    value: 'GU'
  },
  {
    name: 'ДАНИЯ',
    id: 'Королевство Дания',
    value: 'DK'
  },
  {
    name: 'ДЖЕРСИ',
    id: 'ДЖЕРСИ',
    value: 'JE'
  },
  {
    name: 'ДЖИБУТИ',
    id: 'Республика Джибути',
    value: 'DJ'
  },
  {
    name: 'ДОМИНИКА',
    id: 'Содружество Доминики',
    value: 'DM'
  },
  {
    name: 'ДОМИНИКАНСКАЯ РЕСПУБЛИКА',
    id: 'ДОМИНИКАНСКАЯ РЕСПУБЛИКА',
    value: 'DO'
  },
  {
    name: 'ЕГИПЕТ',
    id: 'Арабская Республика Египет',
    value: 'EG'
  },
  {
    name: 'ЗАМБИЯ',
    id: 'Республика Замбия',
    value: 'ZM'
  },
  {
    name: 'ЗАПАДНАЯ САХАРА',
    id: 'ЗАПАДНАЯ САХАРА',
    value: 'EH'
  },
  {
    name: 'ЗИМБАБВЕ',
    id: 'Республика Зимбабве',
    value: 'ZW'
  },
  {
    name: 'ИЗРАИЛЬ',
    id: 'Государство Израиль',
    value: 'IL'
  },
  {
    name: 'ИНДИЯ',
    id: 'Республика Индия',
    value: 'IN'
  },
  {
    name: 'ИНДОНЕЗИЯ',
    id: 'Республика Индонезия',
    value: 'ID'
  },
  {
    name: 'ИОРДАНИЯ',
    id: 'Иорданское Хашимитское Королевство',
    value: 'JO'
  },
  {
    name: 'ИРАК',
    id: 'Республика Ирак',
    value: 'IQ'
  },
  {
    name: 'ИРАН (ИСЛАМСКАЯ РЕСПУБЛИКА)',
    id: 'Исламская Республика Иран',
    value: 'IR'
  },
  {
    name: 'ИРЛАНДИЯ',
    id: 'ИРЛАНДИЯ',
    value: 'IE'
  },
  {
    name: 'ИСЛАНДИЯ',
    id: 'Республика Исландия',
    value: 'IS'
  },
  {
    name: 'ИСПАНИЯ',
    id: 'Королевство Испания',
    value: 'ES'
  },
  {
    name: 'ИТАЛИЯ',
    id: 'Итальянская Республика',
    value: 'IT'
  },
  {
    name: 'ЙЕМЕН',
    id: 'Йеменская Республика',
    value: 'YE'
  },
  {
    name: 'КАБО-ВЕРДЕ',
    id: 'Республика Кабо-Верде',
    value: 'CV'
  },
  {
    name: 'КАЗАХСТАН',
    id: 'Республика Казахстан',
    value: 'KZ'
  },
  {
    name: 'КАМБОДЖА',
    id: 'Королевство Камбоджа',
    value: 'KH'
  },
  {
    name: 'КАМЕРУН',
    id: 'Республика Камерун',
    value: 'CM'
  },
  {
    name: 'КАНАДА',
    id: 'КАНАДА',
    value: 'CA'
  },
  {
    name: 'КАТАР',
    id: 'Государство Катар',
    value: 'QA'
  },
  {
    name: 'КЕНИЯ',
    id: 'Республика Кения',
    value: 'KE'
  },
  {
    name: 'КИПР',
    id: 'Республика Кипр',
    value: 'CY'
  },
  {
    name: 'КИРГИЗИЯ',
    id: 'Киргизская Республика',
    value: 'KG'
  },
  {
    name: 'КИРИБАТИ',
    id: 'Республика Кирибати',
    value: 'KI'
  },
  {
    name: 'КИТАЙ',
    id: 'Китайская Народная Республика',
    value: 'CN'
  },
  {
    name: 'КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА',
    id: 'КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА',
    value: 'CC'
  },
  {
    name: 'КОЛУМБИЯ',
    id: 'Республика Колумбия',
    value: 'CO'
  },
  {
    name: 'КОМОРЫ',
    id: 'Союз Коморы',
    value: 'KM'
  },
  {
    name: 'КОНГО',
    id: 'Республика Конго',
    value: 'CG'
  },
  {
    name: 'КОНГО, ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    id: 'Демократическая Республика Конго',
    value: 'CD'
  },
  {
    name: 'КОРЕЯ, НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    id: 'Корейская Народно-Демократическая Республика',
    value: 'KP'
  },
  {
    name: 'КОРЕЯ, РЕСПУБЛИКА',
    id: 'Республика Корея',
    value: 'KR'
  },
  {
    name: 'КОСТА-РИКА',
    id: 'Республика Коста-Рика',
    value: 'CR'
  },
  {
    name: 'КОТ Д`ИВУАР',
    id: 'Республика Кот д`Ивуар',
    value: 'CI'
  },
  {
    name: 'КУБА',
    id: 'Республика Куба',
    value: 'CU'
  },
  {
    name: 'КУВЕЙТ',
    id: 'Государство Кувейт',
    value: 'KW'
  },
  {
    name: 'КЮРАСАО',
    id: 'КЮРАСАО',
    value: 'CW'
  },
  {
    name: 'ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    id: 'ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА',
    value: 'LA'
  },
  {
    name: 'ЛАТВИЯ',
    id: 'Латвийская Республика',
    value: 'LV'
  },
  {
    name: 'ЛЕСОТО',
    id: 'Королевство Лесото',
    value: 'LS'
  },
  {
    name: 'ЛИБЕРИЯ',
    id: 'Республика Либерия',
    value: 'LR'
  },
  {
    name: 'ЛИВАН',
    id: 'Ливанская Республика',
    value: 'LB'
  },
  {
    name: 'ЛИВИЯ',
    id: 'Государство Ливия',
    value: 'LY'
  },
  {
    name: 'ЛИТВА',
    id: 'Литовская Республика',
    value: 'LT'
  },
  {
    name: 'ЛИХТЕНШТЕЙН',
    id: 'Княжество Лихтенштейн',
    value: 'LI'
  },
  {
    name: 'ЛЮКСЕМБУРГ',
    id: 'Великое Герцогство Люксембург',
    value: 'LU'
  },
  {
    name: 'МАВРИКИЙ',
    id: 'Республика Маврикий',
    value: 'MU'
  },
  {
    name: 'МАВРИТАНИЯ',
    id: 'Исламская Республика Мавритания',
    value: 'MR'
  },
  {
    name: 'МАДАГАСКАР',
    id: 'Республика Мадагаскар',
    value: 'MG'
  },
  {
    name: 'МАЙОТТА',
    id: 'МАЙОТТА',
    value: 'YT'
  },
  {
    name: 'МАКАО',
    id: 'Специальный административный район Макао Китайской Народной Республики',
    value: 'MO'
  },
  {
    name: 'МАЛАВИ',
    id: 'Республика Малави',
    value: 'MW'
  },
  {
    name: 'МАЛАЙЗИЯ',
    id: 'МАЛАЙЗИЯ',
    value: 'MY'
  },
  {
    name: 'МАЛИ',
    id: 'Республика Мали',
    value: 'ML'
  },
  {
    name: 'МАЛЫЕ ТИХООКЕАНСКИЕ ОТДАЛЕННЫЕ ОСТРОВА СОЕДИНЕННЫХ ШТАТОВ',
    id: 'МАЛЫЕ ТИХООКЕАНСКИЕ ОТДАЛЕННЫЕ ОСТРОВА СОЕДИНЕННЫХ ШТАТОВ',
    value: 'UM'
  },
  {
    name: 'МАЛЬДИВЫ',
    id: 'Мальдивская Республика',
    value: 'MV'
  },
  {
    name: 'МАЛЬТА',
    id: 'Республика Мальта',
    value: 'MT'
  },
  {
    name: 'МАРОККО',
    id: 'Королевство Марокко',
    value: 'MA'
  },
  {
    name: 'МАРТИНИКА',
    id: 'МАРТИНИКА',
    value: 'MQ'
  },
  {
    name: 'МАРШАЛЛОВЫ ОСТРОВА',
    id: 'Республика Маршалловы Острова',
    value: 'MH'
  },
  {
    name: 'МЕКСИКА',
    id: 'Мексиканские Соединенные Штаты',
    value: 'MX'
  },
  {
    name: 'МИКРОНЕЗИЯ, ФЕДЕРАТИВНЫЕ ШТАТЫ',
    id: 'Федеративные Штаты Микронезии',
    value: 'FM'
  },
  {
    name: 'МОЗАМБИК',
    id: 'Республика Мозамбик',
    value: 'MZ'
  },
  {
    name: 'МОЛДОВА, РЕСПУБЛИКА',
    id: 'Республика Молдова',
    value: 'MD'
  },
  {
    name: 'МОНАКО',
    id: 'Княжество Монако',
    value: 'MC'
  },
  {
    name: 'МОНГОЛИЯ',
    id: 'МОНГОЛИЯ',
    value: 'MN'
  },
  {
    name: 'МОНТСЕРРАТ',
    id: 'МОНТСЕРРАТ',
    value: 'MS'
  },
  {
    name: 'МЬЯНМА',
    id: 'Республика Союза Мьянма',
    value: 'MM'
  },
  {
    name: 'НАМИБИЯ',
    id: 'Республика Намибия',
    value: 'NA'
  },
  {
    name: 'НАУРУ',
    id: 'Республика Науру',
    value: 'NR'
  },
  {
    name: 'НЕПАЛ',
    id: 'Непал',
    value: 'NP'
  },
  {
    name: 'НИГЕР',
    id: 'Республика Нигер',
    value: 'NE'
  },
  {
    name: 'НИГЕРИЯ',
    id: 'Федеративная Республика Нигерия',
    value: 'NG'
  },
  {
    name: 'НИДЕРЛАНДЫ',
    id: 'Королевство Нидерландов',
    value: 'NL'
  },
  {
    name: 'НИКАРАГУА',
    id: 'Республика Никарагуа',
    value: 'NI'
  },
  {
    name: 'НИУЭ',
    id: 'Ниуэ',
    value: 'NU'
  },
  {
    name: 'НОВАЯ ЗЕЛАНДИЯ',
    id: 'НОВАЯ ЗЕЛАНДИЯ',
    value: 'NZ'
  },
  {
    name: 'НОВАЯ КАЛЕДОНИЯ',
    id: 'НОВАЯ КАЛЕДОНИЯ',
    value: 'NC'
  },
  {
    name: 'НОРВЕГИЯ',
    id: 'Королевство Норвегия',
    value: 'NO'
  },
  {
    name: 'ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ',
    id: 'ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ',
    value: 'AE'
  },
  {
    name: 'ОМАН',
    id: 'Султанат Оман',
    value: 'OM'
  },
  {
    name: 'ОСТРОВА КАЙМАН',
    id: 'ОСТРОВА КАЙМАН',
    value: 'KY'
  },
  {
    name: 'ОСТРОВА КУКА',
    id: 'ОСТРОВА КУКА',
    value: 'CK'
  },
  {
    name: 'ОСТРОВА ТЕРКС И КАЙКОС',
    id: 'ОСТРОВА ТЕРКС И КАЙКОС',
    value: 'TC'
  },
  {
    name: 'ОСТРОВ БУВЕ',
    id: 'ОСТРОВ БУВЕ',
    value: 'BV'
  },
  {
    name: 'ОСТРОВ МЭН',
    id: 'ОСТРОВ МЭН',
    value: 'IM'
  },
  {
    name: 'ОСТРОВ НОРФОЛК',
    id: 'ОСТРОВ НОРФОЛК',
    value: 'NF'
  },
  {
    name: 'ОСТРОВ РОЖДЕСТВА',
    id: 'ОСТРОВ РОЖДЕСТВА',
    value: 'CX'
  },
  {
    name: 'ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД',
    id: 'ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД',
    value: 'HM'
  },
  {
    name: 'ПАКИСТАН',
    id: 'Исламская Республика Пакистан',
    value: 'PK'
  },
  {
    name: 'ПАЛАУ',
    id: 'Республика Палау',
    value: 'PW'
  },
  {
    name: 'ПАЛЕСТИНА, ГОСУДАРСТВО',
    id: 'Государство Палестина',
    value: 'PS'
  },
  {
    name: 'ПАНАМА',
    id: 'Республика Панама',
    value: 'PA'
  },
  {
    name: 'ПАПСКИЙ ПРЕСТОЛ (ГОСУДАРСТВО-ГОРОД ВАТИКАН)',
    id: 'ПАПСКИЙ ПРЕСТОЛ (ГОСУДАРСТВО-ГОРОД ВАТИКАН)',
    value: 'VA'
  },
  {
    name: 'ПАПУА НОВАЯ ГВИНЕЯ',
    id: 'Независимое Государство Папуа Новая Гвинея',
    value: 'PG'
  },
  {
    name: 'ПАРАГВАЙ',
    id: 'Республика Парагвай',
    value: 'PY'
  },
  {
    name: 'ПЕРУ',
    id: 'Республика Перу',
    value: 'PE'
  },
  {
    name: 'ПИТКЕРН',
    id: 'ПИТКЕРН',
    value: 'PN'
  },
  {
    name: 'ПОЛЬША',
    id: 'Республика Польша',
    value: 'PL'
  },
  {
    name: 'ПОРТУГАЛИЯ',
    id: 'Португальская Республика',
    value: 'PT'
  },
  {
    name: 'ПУЭРТО-РИКО',
    id: 'ПУЭРТО-РИКО',
    value: 'PR'
  },
  {
    name: 'РЕЮНЬОН',
    id: 'РЕЮНЬОН',
    value: 'RE'
  },
  {
    name: 'РОССИЯ',
    id: 'Российская Федерация',
    value: 'RU'
  },
  {
    name: 'РУАНДА',
    id: 'Руандийская Республика',
    value: 'RW'
  },
  {
    name: 'РУМЫНИЯ',
    id: 'РУМЫНИЯ',
    value: 'RO'
  },
  {
    name: 'САМОА',
    id: 'Независимое Государство Самоа',
    value: 'WS'
  },
  {
    name: 'САН-МАРИНО',
    id: 'Республика Сан-Марино',
    value: 'SM'
  },
  {
    name: 'САН-ТОМЕ И ПРИНСИПИ',
    id: 'Демократическая Республика Сан-Томе и Принсипи',
    value: 'ST'
  },
  {
    name: 'САУДОВСКАЯ АРАВИЯ',
    id: 'Королевство Саудовская Аравия',
    value: 'SA'
  },
  {
    name: 'СВЯТАЯ ЕЛЕНА, ОСТРОВ ВОЗНЕСЕНИЯ, ТРИСТАН-ДА-КУНЬЯ',
    id: 'СВЯТАЯ ЕЛЕНА, ОСТРОВ ВОЗНЕСЕНИЯ, ТРИСТАН-ДА-КУНЬЯ',
    value: 'SH'
  },
  {
    name: 'СЕВЕРНАЯ МАКЕДОНИЯ',
    id: 'Республика Северная Македония',
    value: 'MK'
  },
  {
    name: 'СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА',
    id: 'Содружество Северных Марианских островов',
    value: 'MP'
  },
  {
    name: 'СЕЙШЕЛЫ',
    id: 'Республика Сейшелы',
    value: 'SC'
  },
  {
    name: 'СЕН-БАРТЕЛЕМИ',
    id: 'СЕН-БАРТЕЛЕМИ',
    value: 'BL'
  },
  {
    name: 'СЕН-МАРТЕН (ФРАНЦУЗСКАЯ ЧАСТЬ)',
    id: 'СЕН-МАРТЕН (ФРАНЦУЗСКАЯ ЧАСТЬ)',
    value: 'MF'
  },
  {
    name: 'СЕН-МАРТЕН (нидерландская часть)',
    id: 'СЕН-МАРТЕН (нидерландская часть)',
    value: 'SX'
  },
  {
    name: 'СЕНЕГАЛ',
    id: 'Республика Сенегал',
    value: 'SN'
  },
  {
    name: 'СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ',
    id: 'СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ',
    value: 'VC'
  },
  {
    name: 'СЕНТ-КИТС И НЕВИС',
    id: 'СЕНТ-КИТС И НЕВИС',
    value: 'KN'
  },
  {
    name: 'СЕНТ-ЛЮСИЯ',
    id: 'СЕНТ-ЛЮСИЯ',
    value: 'LC'
  },
  {
    name: 'СЕНТ-ПЬЕР И МИКЕЛОН',
    id: 'СЕНТ-ПЬЕР И МИКЕЛОН',
    value: 'PM'
  },
  {
    name: 'СЕРБИЯ',
    id: 'Республика Сербия',
    value: 'RS'
  },
  {
    name: 'СИНГАПУР',
    id: 'Республика Сингапур',
    value: 'SG'
  },
  {
    name: 'СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА',
    id: 'СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА',
    value: 'SY'
  },
  {
    name: 'СЛОВАКИЯ',
    id: 'Словацкая Республика',
    value: 'SK'
  },
  {
    name: 'СЛОВЕНИЯ',
    id: 'Республика Словения',
    value: 'SI'
  },
  {
    name: 'СОЕДИНЕННОЕ КОРОЛЕВСТВО',
    id: 'Соединенное Королевство Великобритании и Северной Ирландии',
    value: 'GB'
  },
  {
    name: 'СОЕДИНЕННЫЕ ШТАТЫ',
    id: 'Соединенные Штаты Америки',
    value: 'US'
  },
  {
    name: 'СОЛОМОНОВЫ ОСТРОВА',
    id: 'СОЛОМОНОВЫ ОСТРОВА',
    value: 'SB'
  },
  {
    name: 'СОМАЛИ',
    id: 'Федеративная Республика Сомали',
    value: 'SO'
  },
  {
    name: 'СУДАН',
    id: 'Республика Судан',
    value: 'SD'
  },
  {
    name: 'СУРИНАМ',
    id: 'Республика Суринам',
    value: 'SR'
  },
  {
    name: 'СЬЕРРА-ЛЕОНЕ',
    id: 'Республика Сьерра-Леоне',
    value: 'SL'
  },
  {
    name: 'ТАДЖИКИСТАН',
    id: 'Республика Таджикистан',
    value: 'TJ'
  },
  {
    name: 'ТАИЛАНД',
    id: 'Королевство Таиланд',
    value: 'TH'
  },
  {
    name: 'ТАЙВАНЬ (КИТАЙ)',
    id: 'ТАЙВАНЬ (КИТАЙ)',
    value: 'TW'
  },
  {
    name: 'ТАНЗАНИЯ, ОБЪЕДИНЕННАЯ РЕСПУБЛИКА',
    id: 'Объединенная Республика Танзания',
    value: 'TZ'
  },
  {
    name: 'ТИМОР-ЛЕСТЕ',
    id: 'Демократическая Республика Тимор-Лесте',
    value: 'TL'
  },
  {
    name: 'ТОГО',
    id: 'Тоголезская Республика',
    value: 'TG'
  },
  {
    name: 'ТОКЕЛАУ',
    id: 'ТОКЕЛАУ',
    value: 'TK'
  },
  {
    name: 'ТОНГА',
    id: 'Королевство Тонга',
    value: 'TO'
  },
  {
    name: 'ТРИНИДАД И ТОБАГО',
    id: 'Республика Тринидад и Тобаго',
    value: 'TT'
  },
  {
    name: 'ТУВАЛУ',
    id: 'ТУВАЛУ',
    value: 'TV'
  },
  {
    name: 'ТУНИС',
    id: 'Тунисская Республика',
    value: 'TN'
  },
  {
    name: 'ТУРКМЕНИСТАН',
    id: 'ТУРКМЕНИСТАН',
    value: 'TM'
  },
  {
    name: 'ТУРЦИЯ',
    id: 'Турецкая Республика',
    value: 'TR'
  },
  {
    name: 'УГАНДА',
    id: 'Республика Уганда',
    value: 'UG'
  },
  {
    name: 'УЗБЕКИСТАН',
    id: 'Республика Узбекистан',
    value: 'UZ'
  },
  {
    name: 'УОЛЛИС И ФУТУНА',
    id: 'УОЛЛИС И ФУТУНА',
    value: 'WF'
  },
  {
    name: 'УРУГВАЙ',
    id: 'Восточная Республика Уругвай',
    value: 'UY'
  },
  {
    name: 'ФАРЕРСКИЕ ОСТРОВА',
    id: 'ФАРЕРСКИЕ ОСТРОВА',
    value: 'FO'
  },
  {
    name: 'ФИДЖИ',
    id: 'Республика Фиджи',
    value: 'FJ'
  },
  {
    name: 'ФИЛИППИНЫ',
    id: 'Республика Филиппины',
    value: 'PH'
  },
  {
    name: 'ФИНЛЯНДИЯ',
    id: 'Финляндская Республика',
    value: 'FI'
  },
  {
    name: 'ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)',
    id: 'ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)',
    value: 'FK'
  },
  {
    name: 'ФРАНЦИЯ',
    id: 'Французская Республика',
    value: 'FR'
  },
  {
    name: 'ФРАНЦУЗСКАЯ ГВИАНА',
    id: 'ФРАНЦУЗСКАЯ ГВИАНА',
    value: 'GF'
  },
  {
    name: 'ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ',
    id: 'ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ',
    value: 'PF'
  },
  {
    name: 'ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ',
    id: 'ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ',
    value: 'TF'
  },
  {
    name: 'ХОРВАТИЯ',
    id: 'Республика Хорватия',
    value: 'HR'
  },
  {
    name: 'ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА',
    id: 'ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА',
    value: 'CF'
  },
  {
    name: 'ЧАД',
    id: 'Республика Чад',
    value: 'TD'
  },
  {
    name: 'ЧЕРНОГОРИЯ',
    id: 'ЧЕРНОГОРИЯ',
    value: 'ME'
  },
  {
    name: 'ЧЕХИЯ',
    id: 'Чешская Республика',
    value: 'CZ'
  },
  {
    name: 'ЧИЛИ',
    id: 'Республика Чили',
    value: 'CL'
  },
  {
    name: 'ШВЕЙЦАРИЯ',
    id: 'Швейцарская Конфедерация',
    value: 'CH'
  },
  {
    name: 'ШВЕЦИЯ',
    id: 'Королевство Швеция',
    value: 'SE'
  },
  {
    name: 'ШПИЦБЕРГЕН И ЯН МАЙЕН',
    id: 'ШПИЦБЕРГЕН И ЯН МАЙЕН',
    value: 'SJ'
  },
  {
    name: 'ШРИ-ЛАНКА',
    id: 'Демократическая Социалистическая Республика Шри-Ланка',
    value: 'LK'
  },
  {
    name: 'ЭКВАДОР',
    id: 'Республика Эквадор',
    value: 'EC'
  },
  {
    name: 'ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ',
    id: 'Республика Экваториальная Гвинея',
    value: 'GQ'
  },
  {
    name: 'ЭЛАНДСКИЕ ОСТРОВА',
    id: 'ЭЛАНДСКИЕ ОСТРОВА',
    value: 'AX'
  },
  {
    name: 'ЭЛЬ-САЛЬВАДОР',
    id: 'Республика Эль-Сальвадор',
    value: 'SV'
  },
  {
    name: 'ЭРИТРЕЯ',
    id: 'Государство Эритрея',
    value: 'ER'
  },
  {
    name: 'ЭСВАТИНИ',
    id: 'Королевство Эсватини',
    value: 'SZ'
  },
  {
    name: 'ЭСТОНИЯ',
    id: 'Эстонская Республика',
    value: 'EE'
  },
  {
    name: 'ЭФИОПИЯ',
    id: 'Федеративная Демократическая Республика Эфиопия',
    value: 'ET'
  },
  {
    name: 'ЮЖНАЯ АФРИКА',
    id: 'Южно-Африканская Республика',
    value: 'ZA'
  },
  {
    name: 'ЮЖНАЯ ДЖОРДЖИЯ И ЮЖНЫЕ САНДВИЧЕВЫ ОСТРОВА',
    id: 'ЮЖНАЯ ДЖОРДЖИЯ И ЮЖНЫЕ САНДВИЧЕВЫ ОСТРОВА',
    value: 'GS'
  },
  {
    name: 'ЮЖНАЯ ОСЕТИЯ',
    id: 'Республика Южная Осетия',
    value: 'OS'
  },
  {
    name: 'ЮЖНЫЙ СУДАН',
    id: 'Республика Южный Судан',
    value: 'SS'
  },
  {
    name: 'ЯМАЙКА',
    id: 'ЯМАЙКА',
    value: 'JM'
  },
  {
    name: 'ЯПОНИЯ',
    id: 'ЯПОНИЯ',
    value: 'JP'
  }
]

export { country }
export { countryUser }
