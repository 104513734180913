<template>
  <div class="">
    <div class="operator__statistic__controll">
      <div class="user-active__left">
        <button class="user-active__btn" @click="$router.push('/statistics-advanced-operators')">
          Операторы расширенная
        </button>
      </div>
      <ServerTimeFilter :utcFlag="video_review_stats_form.utc" @changeUtc="changeUtc($event)" />
    </div>
    <div class="container vue">
      <div class="container-list">
        <transition name="slide">
          <ul class="list">
            <li class="sublist-item-account-date" v-for="item in dateList" :key="item.date">
              <TimeList
                ref="el"
                :user_id="user_id"
                :date="item"
                @geModerationPhotosStats="geModerationPhotosStats($event)"
              />
            </li>
          </ul>
        </transition>
      </div>

      <div class="operator__statistic">
        <ul class="chart-list" ref="viewScrollContent" v-if="resStatus" @scroll="scroll($event)">
          <li class="chart-item-user" v-for="(stat, idx) in profileStatisticUpdate" :key="`${idx}`">
            <!-- :account_id="index" -->
            <StatisticOnlyUserAndOperator
              v-for="item in stat"
              :profileStats="item"
              :key="item.info.id"
              :user_id="accountId"
              :operID="oper_id"
              :validOper_id="item.operator_id"
              :operatorInfo="item.account_info"
              :date="date"
              ref="chart"
            />
            <!-- :status="statusFilter" -->
          </li>
        </ul>
        <div v-else class="chart-list">
          <p class="chart-list__error">
            Выберите аккаунт что бы посмотреть график!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  REQUEST_STATISTIC_USER_BY_DATE,
  REQUEST_USER_STATISTIC_ADVANCED,
  REQUEST_STATISTIC_USER_BY_DATE_PAGINATION
} from '@/store/action-types'
import {
  GET_COUNTERS_MESSAGES_OPERATORS,
  GET_USER_STATISTIC_ADVANCED,
  GET_ACTIVE_OPERATOR,
  GET_STATISTIC_USER_BY_DATE
} from '@/store/getter-types'
import { SET_ACTIVE_OPERATOR, SET_STATISTICS_PROFILE_MESSAGES } from '@/store/mutation-types'
import { ru } from 'vuejs-datepicker/dist/locale'
import StatisticOnlyUserAndOperator from '@/components/Statistic/StatisticOnlyUserAndOperator'
import 'chartjs-plugin-datalabels'
import TimeList from '@/components/Statistic/UserAdvanced/TimeList'
import moment from 'moment'
import store from '@/store'
import getImgLink from '@/components/mixins/getImgCdnLink'
import ServerTimeFilter from '@/components/Statistic/Servertimefilter/ServerTimeFilter'
import pickerMixin from '@/components/Picker/pickerMixin'

export default {
  name: 'AdvancedStatisticsOperators',
  components: {
    TimeList,
    StatisticOnlyUserAndOperator,
    ServerTimeFilter
  },
  mixins: [pickerMixin, getImgLink],
  data() {
    return {
      ru: ru,
      load: false,
      online: true,
      openOper: true,
      isDropped: false,
      user_info: null,
      user_id: '',
      page: 0,
      accountId: null,
      oper_id: null,
      // statusFilter: 'dialogues',
      resStatus: true,
      disableScroll: false,
      loadDataByScroll: true,
      loadingUsers: false,
      operName: '',
      video_review_stats_form: {
        date: moment().format('YYYY-MM-DD'),
        main_video: 0,
        deferred: 0,
        utc: 1
      },
      date: {}
    }
  },
  watch: {
    'video_review_stats_form.utc': function() {
      // this.statusFilter = null
      this.page = 0
      this.loadDataByScroll = true
      this.disableScroll = false
      this.loadingUsers = false
      let list = document.getElementsByClassName('chart-list')
      list.scrollTop = 0
      this.geModerationPhotosStats()
    },
    active: function() {
      // this.statusFilter = null
      this.page = 0
      this.loadDataByScroll = true
      this.disableScroll = false
      this.loadingUsers = false
      let list = document.getElementsByClassName('chart-list')
      list.scrollTop = 0
      this.geModerationPhotosStats()
    },
    statusFilter: function() {
      this.page = 0
      this.loadDataByScroll = true
      this.disableScroll = false
      this.loadingUsers = false
      let list = document.getElementsByClassName('chart-list')
      list.scrollTop = 0
    }
  },
  methods: {
    ...mapActions({
      getNewUsers: REQUEST_USER_STATISTIC_ADVANCED,
      sendData: REQUEST_STATISTIC_USER_BY_DATE,
      paginationStats: REQUEST_STATISTIC_USER_BY_DATE_PAGINATION
      // unhandleCalls: REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS,
      // unhadledCallsPagination: REQUEST_STATISTIC_PROFILE_MESSAGE_UNHANDLED_CALLS_PAGINATION,
      // dialogs: REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS,
      // dialogsPagination: REQUEST_STATISTIC_PROFILE_MESSAGE_DIALOGS_PAGINATION,
      // unreadMessage: REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES,
      // unreadMessagePagination: REQUEST_STATISTIC_PROFILE_UNREAD_MESSAGES_PAGINATION,
      // not_processed_delaeyd: REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD,
      // not_processed_delaeyd_pagination: REQUEST_STATISTIC_NOT_PROCESSED_WITH_DALAEYD_PAGINATION
    }),
    ...mapMutations({
      setActive: SET_ACTIVE_OPERATOR,
      graphic: SET_STATISTICS_PROFILE_MESSAGES
    }),
    dropIt() {
      this.isDropped = !this.isDropped
    },
    accord(el) {
      el = !el
    },
    fullName(name, last_name) {
      return `${name} ${last_name}`
    },
    geModerationPhotosStats() {
      let newData = {
        date_from: this.accountId,
        page: 0,
        user_id: this.user_id
      }
      if (this.video_review_stats_form.utc) newData.delta = new Date().getTimezoneOffset() / -60

      this.getNewUsers(newData)

      this.sendData(newData)
    },
    scroll(event) {
      let data = {
        date_from: this.accountId,
        user_id: this.user_id,
        delta: this.video_review_stats_form.utc ? new Date().getTimezoneOffset() / -60 : 0
      }
      if (!this.disableScroll) {
        const list = this.$refs.viewScrollContent
        const scrollBottom = list.scrollHeight - (list.scrollTop + list.offsetHeight)
        const scrollTop = list.scrollTop

        const firstNodeOffset = this.$refs.chart[0]._vnode.elm.offsetTop
        const firstNodeScrollHeight = this.$refs.chart[0]._vnode.elm.scrollHeight
        if (scrollBottom + firstNodeOffset < firstNodeOffset + firstNodeScrollHeight) {
          this.disableScroll = true
          if (this.loadDataByScroll && !this.loadingUsers) {
            if (!this.page) {
              data.page = 0
              this.disableScroll = false
            }
            data.page = ++this.page
            this.loadingUsers = true
            this.paginationStats(data).then(res => {
              if (res.data.data.length === 0) {
                this.loadDataByScroll = false
              }
              this.$nextTick(() => {
                let newbottom = list.scrollHeight - (list.offsetHeight + scrollTop + scrollBottom)
                list.scrollTo({
                  bottom: newbottom
                })
                this.loadingUsers = false
                this.disableScroll = false
              })
            })
          }
        }
      }
    },
    changeUtc(number) {
      this.video_review_stats_form.utc = number
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_USER_STATISTIC_ADVANCED, { user_id: to.params.id }).then(response => {
      const { status } = response
      if (status) {
        next(vm => {
          vm.user_id = to.params.id
          vm.isLoaded = status
          // vm.geModerationPhotosStats()
          vm.accountId = vm.dateList[0].date
          vm.setActive(vm.dateList[0].date)
        })
      }
    })
  },
  computed: {
    ...mapGetters({
      operators: GET_COUNTERS_MESSAGES_OPERATORS,
      profileStatistic: GET_STATISTIC_USER_BY_DATE,
      dateList: GET_USER_STATISTIC_ADVANCED,
      active: GET_ACTIVE_OPERATOR
    }),
    profileStatisticUpdate() {
      return this.profileStatistic
    }
  },
  mounted() {
    this.$root.$on('set_id_account', (date, status, user_id) => {
      this.oper_id = user_id
      this.accountId = date
      this.resStatus = status
    })
    this.accountId = this.dateList[0].date
    this.setActive(this.dateList[0].date)
  },
  beforeDestroy() {
    this.setActive('')
  }
}
</script>

<style lang="scss">
.container-list {
  max-width: 500px;
}
.container {
  display: flex;
  margin: 0 1% 0 1%;
  background-color: #fff;
}

.operator__statistic__controll {
  margin: 1% 1% 0 1%;
  background-color: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.chart-item-user {
  &:nth-child(2n) {
    background-color: #f7f7f7;
  }
}

.chart-list {
  overflow: hidden;
  overflow-y: auto;
  max-height: 85vh;
  min-height: 20vh;
  &::-webkit-scrollbar {
    background-color: #fff;
    z-index: 0;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(232, 64, 63);
    border-radius: 10px;
  }
}

.chart-list__error {
  margin: 10% 0;
  text-align: center;
}

.list {
  padding: 0;
  margin: 0;
  width: 450px;
  list-style-type: none;
  transform-origin: top;
  transition: transform 0.4s ease-in-out;
  &-header {
    padding: 20px 60px;
    width: 100%;
    text-align: center;
    background: #12d4ee;
    color: #eee;
    cursor: default;
  }
  &-item {
    padding: 10px;
    background: white;
    transition: 0.4s ease-in-out;
    border-bottom: solid thin #eee;
    display: flex;
    flex-direction: column;

    &__wrapp {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      &-name {
        position: relative;
        margin-left: 8px;
        margin-right: 16px;
      }
    }

    &__avatar {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin: 6px 0;
      img {
        width: 60px;
      }
    }
  }
}
.sublist {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transform-origin: top;
  transition: transform 0.4s ease-in-out;
  padding: 0 7px;
  line-height: 20px;
  color: #369;
  border-left: 2px solid rgb(100, 100, 100);
  &-item {
    &::before {
      position: relative;
      top: 40px;
      height: 1em;
      width: 19px;
      color: white;

      border-bottom: 2px solid rgb(0, 0, 0);
      content: '';
      display: inline-block;
      left: -18px;
    }

    &:last-child::before {
      border-left: 1px solid rgb(0, 0, 0);
    }
    padding: 10px;
    background: white;
    border-bottom: solid thin #eee;
    &__name {
      margin: 0;
    }
  }
}

.operator__statistic {
  width: 75vw;
  height: 100%;
  padding: 5px;
  background-color: #fff;
  position: sticky;
  top: 20px;
}

.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}
.table-condensed {
  td:hover {
    background: #318bcc;
    color: #fff;
  }
  .today {
    background: #48a7eb;
    color: #fff;
  }
}
</style>
