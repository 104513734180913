<template>
  <footer class="footer">
    <span class="footer__text">2022 © Skylove.</span>
    <button class="btn-top" v-if="isHidden && windowTop > 100" @click="top">
      <FaIcon :iconClass="'fas fa-arrow-up'" />
    </button>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {
      windowTop: 0
    }
  },
  computed: {
    isHidden() {
      if (
        this.$route.name === 'Users' ||
        this.$route.name === 'autoMessage' ||
        this.$route.name === 'Mainpage' ||
        this.$route.name === 'EventsList'
      ) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    top() {
      this.$emit('toTop')
    },
    onScroll(e) {
      this.windowTop = e.target.scrollTop
    }
  },
  mounted() {
    let el = document.getElementsByClassName('simplebar-content-wrapper')[0]
    el.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    let el = document.getElementsByClassName('simplebar-content-wrapper')[0]
    el.removeEventListener('scroll', this.onScroll)
  }
}
</script>
<style lang="scss">
@import './Footer.scss';
.btn-top {
  background-color: #a2a2a2a8;
  border-radius: 60px;
  position: absolute;
  bottom: 10px;
  right: 30px;
  color: #ffffff;
}
</style>
