<template>
  <div>
    <div class="table-statistic__row table-statistic__total-row rate-activ" @click="openRate">
      <span v-show="isChevron">
        <i class="fa fa-chevron-circle-down"></i>
      </span>
      <span v-show="!isChevron">
        <i class="fa fa-chevron-circle-up"></i>
      </span>
      Текущая на период ставка
    </div>
    <div
      class="table-statistic__row table-statistic__total-row"
      v-if="!isChevron"
      v-for="el in stat.rates"
      :key="el"
    >
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Начало действие ставки</span>
        <span>{{ formateDate(el.acts_from) }}</span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Конец действия ставки</span>
        <span>{{ formateDate(el.acts_to) }}</span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Оплата фотографий ГЛАВНАЯ</span>
        <span
          >{{ el.main_photo_price.toFixed(2) }} <i class="moderator-payments__icon fas fa-ruble-sign"></i
        ></span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Оплата фотографий ВНУТРЕННЯЯ</span>
        <span>{{ el.inner_photo_price }} <i class="moderator-payments__icon fas fa-ruble-sign"></i></span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Оплата Видео ГЛАВНАЯ</span>
        <span>{{ el.main_video_price }} <i class="moderator-payments__icon fas fa-ruble-sign"></i></span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Оплата за проверку событий</span>
        <span>{{ el.event_price }} <i class="moderator-payments__icon fas fa-ruble-sign"></i></span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Оплата за редактирование событий</span>
        <span>{{ el.event_edited_price }} <i class="moderator-payments__icon fas fa-ruble-sign"></i></span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Оплата за баны</span>
        <span>{{ el.ban_price.toFixed(2) }} <i class="moderator-payments__icon fas fa-ruble-sign"></i></span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Оплата ночных смен</span>
        <span>{{ el.night_pay }} <i class="moderator-payments__icon fas fa-ruble-sign"></i></span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Оплата дневных смен</span>
        <span>{{ el.light_pay }} <i class="moderator-payments__icon fas fa-ruble-sign"></i></span>
      </span>
    </div>
    <div class="table-statistic__row table-statistic__total-row" v-if="!isChevron"></div>
    <div class="table-statistic__row table-statistic__total-row rate-activ" @click="openPayment">
      <span v-show="isPayment">
        <i class="fa fa-chevron-circle-down"></i>
      </span>
      <span v-show="!isPayment">
        <i class="fa fa-chevron-circle-up"></i>
      </span>
      Выплаты
    </div>
    <div class="table-statistic__row table-statistic__total-row" v-if="!isPayment">
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Итоговая сумма за платежный период</span>
        <span>{{ isNamber(stat.total) }} <i class="moderator-payments__icon fas fa-ruble-sign"></i></span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Сумма зарплаты</span>
        <span v-if="stat.payment.amount"
          >{{ stat.payment.amount }} <i class="moderator-payments__icon fas fa-ruble-sign"></i
        ></span>
        <span v-else>Не посчитана</span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Сумма зарплаты рассчитана</span>
        <span>{{ formateDate(stat.payment.date) }}</span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Дата перевода зарплаты</span>
        <span>{{ formateDate(stat.payment.created_date) }}</span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Сумма доплаты</span>
        <span v-if="stat.payment_dop.amount"
          >{{ isNamber(stat.payment_dop.amount) }} <i class="moderator-payments__icon fas fa-ruble-sign"></i
        ></span>
        <span v-else>Не постчина</span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Сумма доплаты рассчитана</span>
        <span>{{ formateDate(stat.payment_dop.date) }}</span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Дата перевода доплаты</span>
        <span>{{ formateDate(stat.payment_dop.created_date) }}</span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Сумма премии</span>
        <span>{{ isNamber(stat.premiums) }} <i class="moderator-payments__icon fas fa-ruble-sign"></i></span>
      </span>
      <span class="table-statistic__item table-statistic__item--more-rate">
        <span class="rate-title">Описание премии</span>
        <span>{{ stat.premiums_description }}</span>
      </span>
    </div>
    <div class="table-statistic__row table-statistic__total-row" v-if="!isPayment"></div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    stat: {
      type: Object
    }
  },
  data() {
    return {
      isChevron: true,
      isPayment: true
    }
  },
  methods: {
    openRate() {
      this.isChevron = !this.isChevron
    },
    openPayment() {
      this.isPayment = !this.isPayment
    },
    formateDate(date) {
      return moment(date).format('DD.MM.YYYY (HH:mm:ss)')
    },
    isNamber(data) {
      return Math.trunc(data)
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'ModeratorStatistic';
.table-statistic__total-row {
  border-top: 1px solid #ddd;
  background-color: #d9edf7;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}
.table-statistic {
  &__item {
    &--more-rate {
      width: 11%;
      align-items: flex-start;
    }
    .rate-title {
      color: rgb(3, 3, 36);
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
}
.rate-activ {
  &:hover {
    cursor: pointer;
  }
}
</style>
