<template>
  <div class="control-departments-wrap">
    <Filters />
    <div class="control-departments-list" v-if="departments.length && isLoaded">
      <Department v-for="department in departments" :key="department.id" :department="department" />
    </div>
    <div
      class="control-departments-list control-departments-list--error"
      v-if="!departments.length && isLoaded"
    >
      <span class="control-departments-list__text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="control-departments-list control-departments-list--error">
      <span class="control-departments-list__text">Ошибка при загрузке департментов</span>
    </div>
    <NewDepartmentPopup />
    <EditDepartmentPopup />
    <DeleteDepartmentPopup />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { REQUEST_CONTROL_DEPARTMENTS } from '@/store/action-types'
import { GET_CONTROL_DEPARTMENTS } from '@/store/getter-types'
import Filters from '@/components/Controls/Departments/Filters/Filters'
import Department from '@/components/Controls/Departments/Department'
import NewDepartmentPopup from '@/components/Modals/DepartmentPopup/NewDepartmentPopup'
import EditDepartmentPopup from '@/components/Modals/DepartmentPopup/EditDepartmentPopup'
import DeleteDepartmentPopup from '@/components/Modals/DepartmentPopup/DeleteDepartmentPopup'
import store from '@/store'

export default {
  name: 'managmentDepartments',
  components: {
    Filters,
    Department,
    NewDepartmentPopup,
    EditDepartmentPopup,
    DeleteDepartmentPopup
  },

  data: () => ({
    errorMessage: false,
    isLoaded: false
  }),

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_CONTROL_DEPARTMENTS).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },

  computed: {
    ...mapGetters({
      departments: GET_CONTROL_DEPARTMENTS
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
    this.$root.$on('filtersHeight', h => {
      const margin = `${h - 12}` + 'px'
      document.querySelector('.control-departments-list').style.marginTop = margin
      document.querySelector('.simplebar-vertical').style.marginTop = `${h + 50}` + 'px'
    })
  }
}
</script>

<style lang="scss">
.control-departments {
  &-wrap {
    padding: 16px 16px;
    max-width: 1172px;
    margin: 0 auto;
  }
  &-list {
    position: relative;
    @media (max-width: 1020px) {
      margin-top: 70px;
    }
    &__text {
      margin: 0 auto;
    }
  }
  &-list--error {
    position: relative;
    display: flex;
    min-height: 64px;
    align-items: center;
    background-color: white;
    @media (max-width: 1020px) {
      margin-top: 70px;
    }
  }
}
</style>
