const GET_MODERATORS_RATING = 'GET_MODERATORS_RATING'
const GET_MODERATOR_SETTINGS_SPEED = 'GET_MODERATOR_SETTINGS_SPEED'
const GET_MODERATOR_SETTINGS_QUALITY = 'GET_MODERATOR_SETTINGS_QUALITY'
const GET_MODERATOR_RATING_ROLES = 'GET_MODERATOR_RATING_ROLES'
const GET_MODERATOR_ROLES_RATING_DEFAULT = 'GET_MODERATOR_ROLES_RATING_DEFAULT'
const GET_MODERATOR_RATING_TMP_ROLES = 'GET_MODERATOR_RATING_TMP_ROLES'

export {
  GET_MODERATORS_RATING,
  GET_MODERATOR_SETTINGS_SPEED,
  GET_MODERATOR_SETTINGS_QUALITY,
  GET_MODERATOR_RATING_ROLES,
  GET_MODERATOR_ROLES_RATING_DEFAULT,
  GET_MODERATOR_RATING_TMP_ROLES
}
