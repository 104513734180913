const GET_USERS = 'GET_USERS'
const GET_VALUE_PICKER = 'GET_VALUE_PICKER'
const GET_FILTERS_INFO = 'GET_FILTERS_INFO'
const GET_FILTERS_DEFAULT = 'GET_FILTERS_DEFAULT'
const GET_TMP_FILERS = 'GET_TMP_FILERS'
const GET_CURRENT_USER = 'GET_CURRENT_USER'
const GET_USER_GENDER = 'GET_USER_GENDER'
const GET_USER_DEPOSIT = 'GET_USER_DEPOSIT'
const GET_USER_PAYOUTS = 'GET_USER_PAYOUTS'
const GET_USER_PURCHASES = 'GET_USER_PURCHASES'
const GET_USER_PHOTOS = 'GET_USER_PHOTOS'
const GET_USER_CHATS = 'GET_USER_CHATS'
const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES'
const GET_CURRENT_FILERS = 'GET_CURRENT_FILERS'
const GET_USER_COUNTS = 'GET_USER_COUNTS'
const GET_USER_FILTER_COUNTS = 'GET_USER_FILTER_COUNTS'
const GET_FILTER_PAGE = 'GET_FILTER_PAGE'
const GET_USERCHATS_STYLES = 'GET_USERCHATS_STYLES'
const GET_TRANSACTION_KIND = 'GET_TRANSACTION_KIND'

export {
  GET_USERS,
  GET_USER_COUNTS,
  GET_CURRENT_USER,
  GET_TRANSACTION_KIND,
  GET_VALUE_PICKER,
  GET_FILTERS_INFO,
  GET_FILTERS_DEFAULT,
  GET_TMP_FILERS,
  GET_USER_GENDER,
  GET_USER_DEPOSIT,
  GET_USER_PAYOUTS,
  GET_USER_PURCHASES,
  GET_USER_PHOTOS,
  GET_USER_CHATS,
  GET_CHAT_MESSAGES,
  GET_CURRENT_FILERS,
  GET_USER_FILTER_COUNTS,
  GET_FILTER_PAGE,
  GET_USERCHATS_STYLES
}
