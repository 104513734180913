<template>
  <Popup :visible="visible" @close="close" title="" description="Ошибка:" modalClass="popup--small">
    <form class="modal-update__question">
      <h2 class="pop-up__title pop-up__title--error">
        Нельзя сохранить данные! Пользователь занят другим оператором.
      </h2>
      <div class="pop-up__btn-wrapper">
        <button type="button" @click="closeModal" class="button button--back button--center button--error">
          <span class="button__text">
            Закрыть
          </span>
        </button>
      </div>
    </form>
  </Popup>
</template>

<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import dateFormat from '@/components/mixins/toLocaleDateAndTime'
import { OPERATOR_NO_CLOSE_POPUP } from '@/constants/event'
export default {
  name: 'OperatorNoClosePopup',
  components: {
    Popup
  },
  mixins: [dateFormat, ModalMixin],

  mounted() {
    this.$root.$on(OPERATOR_NO_CLOSE_POPUP, () => this.open())
  },
  methods: {
    closeModal() {
      this.close()
    }
  }
}
</script>

<style lang="scss">
.button--center {
  display: flex;
  margin: 0 auto;
}
.button--error {
  color: #fff;
  background-color: #f67a6e;
  margin: 10px auto 0 auto;
  outline: none;
  padding: 4px;
  min-width: 80px;
  height: 34px;
  border-radius: 4px;
  align-items: center;
}
.button__text {
  display: flex;
  margin: 0 auto;
}
</style>
