<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    description="Добавить подкатегорию"
    modalClass="popup--medium"
  >
    <form @submit.prevent="sendData">
      <ul class="popup__list" v-if="currentCategory.length">
        <li class="popup__list-item" v-for="(lan, i) in langs" :key="i">
          <span>{{ lan.value }}</span>
          <input required type="text" class="popup__input default-input" v-model="currentCategory[i].title" />
        </li>
      </ul>
      <div v-else>Ошибка загрузки названий</div>
      <div class="popup__item">
        <span class="toggle__desc">Свое событие</span>
        <label class="toggle">
          <input class="toggle__checkbox" type="checkbox" v-model="my_type" />
          <span class="toggle__toggler"></span>
        </label>
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <div class="addfile-btn">
            <input
              class="addfile-btn__send-input"
              id="event-file-input"
              type="file"
              ref="uploadImageCategory"
              @change="fileUploadHandler"
              required
            />
            <label
              class="addfile-btn__send-label"
              for="event-file-input"
              :class="{
                'avatar-invalid': !file
              }"
            >
              <!-- V Если файл в инпуте V -->
              <template v-if="file">
                <span class="addfile-btn__send-file"></span>
              </template>
              <i class="fa fa-paperclip"></i>
            </label>
          </div>
          <button type="submit" class="popup__btn btn-green">Добавить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EVENT_SUBCATEGORY_ADD_POPUP } from '@/constants/event'
import { mapActions, mapGetters } from 'vuex'
import { GET_LANGUAGES, GET_MERGED_CATEGORIES } from '@/store/getter-types'
import { REQUEST_ADD_SUBCATEGORY_EVENT } from '@/store/action-types'

export default {
  name: 'EventSubCategoryAddPopup',
  data() {
    return {
      category: '',
      file: null,
      my_type: false,
      currentCategory: []
    }
  },
  components: {
    Popup
  },
  mixins: [ModalMixin],

  computed: {
    ...mapGetters({
      langs: GET_LANGUAGES,
      categories: GET_MERGED_CATEGORIES // удалить
    })
  },
  mounted() {
    this.$root.$on(EVENT_SUBCATEGORY_ADD_POPUP, eventcat => {
      this.currentCategory = []
      this.langs.map(m => {
        this.currentCategory.push({
          lang: m.value,
          title: ''
        })
      })
      this.category = eventcat
      this.open()
    })
  },
  methods: {
    ...mapActions({
      addCategory: REQUEST_ADD_SUBCATEGORY_EVENT
    }),
    fileUploadHandler() {
      const imageCategory = this.$refs.uploadImageCategory.files[0]
      this.file = imageCategory
    },
    closePopup() {
      this.file = null
      this.my_type = false
      this.category = ''
      this.close()
    },
    sendData() {
      const formData = new FormData()
      let data = {
        category: this.category,
        my_type: this.my_type,
        locale: []
      }
      data.locale = this.currentCategory.filter(f => f.title !== '')
      if (this.file) {
        formData.append('image', this.file)
      }
      formData.append('data', JSON.stringify(data))
      this.addCategory(formData)
      this.closePopup()
    }
  }
}
</script>

<style lang="scss" scoped>
.popup__item:first-child {
  margin-top: 10px;
}
</style>
