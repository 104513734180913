import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_PERMISSIONS]: (state, payload) => {
    state.permissions = payload
  },
  [MutationTypes.SET_PERMISSIONS_GROUP]: (state, payload) => {
    const groups = []
    for (let perm of payload) {
      let data = {
        id: perm.id,
        name: perm.name,
        value: perm.id
      }
      groups.push(data)
    }
    state.permissions_group = groups
  },
  [MutationTypes.SET_TMP_FILTERS_PERMISSION]: (state, payload) => {
    const { key, value } = payload
    const newObject = {
      key,
      ...value
    }
    state.tmpUserFilters[key] = newObject
  },
  [MutationTypes.SET_RESET_PERMISSION_FILTERS]: state => {
    for (const field in state.tmpUserFilters) {
      state.tmpUserFilters[field] = {}
    }
  }
}
