<template>
  <Popup
    :visible="visible"
    @close="close"
    description="Добавить свое событие"
    modalClass="popup--add-category popup--medium"
  >
    <form @submit.prevent="sendData">
      <EventRefBookAddItem v-for="(eventarr, index) in eventarray" :key="index" :eventarr="eventarr" />
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Добавить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import EventRefBookAddItem from '@/components/Modals/EventPopup/EventRefBookAddItem'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EVENT_REFBOOK_ADD_POPUP } from '@/constants/event'
import { mapActions, mapGetters } from 'vuex'
import { GET_CURRENT_LANGUAGES } from '@/store/getter-types'
import { REQUEST_ADD_EVENT_REFBOOK, REQUEST_UPDATE_EVENT_REFBOOK } from '@/store/action-types'

export default {
  name: 'EventRefBookAddPopup',
  data() {
    return {
      eventarray: {
        available_for: {
          name: 'Показывать',
          payload: []
        },
        people_amount: {
          name: 'Сколько приглашенных',
          payload: []
        },
        who_pay: {
          name: 'Кто платит',
          payload: []
        }
      },
      lang: '',
      id: ''
    }
  },
  components: {
    Popup,
    EventRefBookAddItem
  },
  mixins: [ModalMixin],

  // computed: {
  //   ...mapGetters({
  //     lang: GET_CURRENT_LANGUAGES
  //   })
  // },
  mounted() {
    this.$root.$on(EVENT_REFBOOK_ADD_POPUP, (eventref, select) => {
      this.lang = select
      this.id = eventref.id
      this.eventarray.who_pay.payload = [...eventref.who_pay]
      this.eventarray.people_amount.payload = [...eventref.people_amount]
      this.eventarray.available_for.payload = [...eventref.available_for]
      this.open()
    })
  },
  methods: {
    ...mapActions({
      addRefBook: REQUEST_ADD_EVENT_REFBOOK,
      update: REQUEST_UPDATE_EVENT_REFBOOK
    }),
    sendData() {
      if (this.id === '' || this.id === null || this.id === undefined) {
        const data = {
          lang: this.lang,
          who_pay: this.eventarray.who_pay.payload,
          available_for: this.eventarray.available_for.payload,
          people_amount: this.eventarray.people_amount.payload
        }
        this.addRefBook(data).then(res => {
          if (res.status) {
            this.eventarray.available_for.payload = []
            this.eventarray.people_amount.payload = []
            this.eventarray.who_pay.payload = []
            this.lang = ''
          }
        })
      } else {
        const data = {
          lang: this.lang,
          who_pay: this.eventarray.who_pay.payload,
          available_for: this.eventarray.available_for.payload,
          people_amount: this.eventarray.people_amount.payload,
          id: this.id
        }
        this.update(data)
      }
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.popup__item:first-child {
  margin-top: 10px;
}
</style>
