<template>
  <div v-if="languages" class="operator-wrapper">
    <div class="operator-banner operator-wrapper-banner">
      <div class="operator-banner__field">
        <span class="operator-banner__mobtitle">Локаль:</span>
        <span class="operator-banner__text">{{ languages }}</span>
      </div>
      <div class="operator-banner__field">
        <span class="operator-banner__mobtitle">Заголовок:</span>
        <span class="operator-banner__text">{{ bunnerObject.title }}</span>
      </div>
      <div class="operator-banner__field">
        <span class="operator-banner__mobtitle">Текст под заголовком:</span>
        <span class="operator-banner__text">{{ bunnerObject.description }}</span>
      </div>
      <div class="operator-banner__field">
        <span class="operator-banner__mobtitle">Текст на кнопке:</span>
        <span class="operator-banner__text">{{ bunnerObject.button_text }}</span>
      </div>
      <div class="operator-banner__field operator-banner__field--btns">
        <button class="operator-banner__btn btn-blue operator-banner__btn--blue" @click="editPopup">
          <i class="fa fa-edit"></i>
        </button>
        <button
          class="operator-banner__btn btn-red operator-banner__btn--red"
          @click="deletePopup"
          v-show="bunnerObject.id"
        >
          <i class="fa fa-times-circle"></i>
        </button>
      </div>
    </div>
  </div>
  <div v-else class="error">Ошибка подгрузки данных</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { GET_OPERATOR_BANNER } from '@/store/getter-types'
export default {
  props: {
    languages: {
      type: String
    },
    languagesId: {
      type: String
    },
    device: {
      type: String
    }
  },
  data() {
    return {
      bunnerObject: {}
    }
  },
  mounted() {
    this.setBanner()
  },
  watch: {
    getBanner() {
      this.bunnerObject = {}
      this.setBanner()
    }
  },
  computed: {
    ...mapGetters({
      getBanner: GET_OPERATOR_BANNER
    })
  },
  methods: {
    setBanner() {
      const phone = this.getBanner.filter(i => i.device === this.device)
      const lang = phone.find(i => i.lang.name === this.languages)
      if (lang) {
        this.bunnerObject = lang
      }
    },
    editPopup() {
      this.$root.$emit('EDIT_OPERATOR_BANNER', this.bunnerObject, this.languagesId, this.device)
    },
    deletePopup() {
      this.$root.$emit('DELETE_OPERATOR_BANNER', this.bunnerObject.id)
    }
  }
}
</script>
