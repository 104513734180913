<template>
  <div class="gifts-wrapper">
    <ul class="gifts-list">
      <li class="gifts-list__item" v-for="gift in allGifts" :key="gift.id">
        <img
          :src="`https://cdn.skylove.su/image/${gift.image}`"
          width="80"
          height="80"
          class="gifts-list__images"
        />

        <p class="gifts-list__price">Цена: {{ gift.price }}</p>

        <p class="gifts-list__button list-button">
          <button type="button" class="list-button__item" @click="$root.$emit(GIFT_UPDATE_POPUP_EVENT, gift)">
            <i class="fa fa-edit list-button__icon--edit"></i>
          </button>

          <button
            type="button"
            class="list-button__item"
            @click="$root.$emit(GIFT_DELETE_POPUP_EVENT, gift.id)"
          >
            <i class="fa fa-times-circle list-button__icon--delete"></i>
          </button>
        </p>
      </li>
    </ul>
    <GiftsPopupUpdate />
    <GiftsPopupDelete />
  </div>
</template>
<script>
import GiftsPopupDelete from '@/components/Modals/GiftsPopup/GiftsPopupDelete.vue'
import { GIFT_DELETE_POPUP_EVENT, GIFT_UPDATE_POPUP_EVENT } from '@/constants/event'
import GiftsPopupUpdate from '@/components/Modals/GiftsPopup/GiftsPopupUpdate.vue'
export default {
  components: { GiftsPopupDelete, GiftsPopupUpdate },
  name: 'Gifts',

  data() {
    return { GIFT_DELETE_POPUP_EVENT, GIFT_UPDATE_POPUP_EVENT }
  },

  props: {
    allGifts: {
      type: Array
    }
  }
}
</script>

<style lang="scss">
@import './Gifts.scss';
</style>
