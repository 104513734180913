import { country } from '@/constants/country'
export default {
  operators: [],
  operatorUsers: [],
  currentOperator: {},
  currentOperatorUser: [],
  searchOptions: [],
  operatorStatistic: {},
  operatorPayout: [],
  activeOper: '',
  currency: [
    {
      name: '$',
      value: 0,
      id: 0
    },
    {
      name: '€',
      value: 1,
      id: 1
    },
    {
      name: '₽',
      value: 2,
      id: 2
    },
    {
      name: '£',
      value: 3,
      id: 3
    }
  ],

  operatorTmpRoles: {
    currency: {},
    status: {},
    country: {},
    langs: {}
  },

  operatorFilter: {
    operatorTransfer: {},
    activity: {},
    employeeDeleted: {}
  },

  operatorFilterDefault: {
    operatorTransfer: {
      name: 'Выберите оператора',
      value: ''
    },
    employeeDeleted: {
      position_name: 'Неважно',
      value: -1
    },
    activity: {
      position_name: 'Активные',
      value: true
    }
  },

  operatorRoles: {
    currency: [
      {
        name: '$',
        value: 0,
        id: 0
      },
      {
        name: '€',
        value: 1,
        id: 1
      },
      {
        name: '₽',
        value: 2,
        id: 2
      },
      {
        name: '£',
        value: 3,
        id: 3
      }
    ],
    status: [
      {
        name: 'Все',
        value: -1,
        id: -1
      },
      {
        name: 'Активные',
        value: true,
        id: 1
      },
      {
        name: 'Неактивные',
        value: false,
        id: 2
      }
    ],
    activity: [
      {
        position_name: 'Неважно',
        value: -1
      },
      {
        position_name: 'Активные',
        value: true
      },
      {
        position_name: 'Неактивные',
        value: false
      }
    ],
    employeeDeleted: [
      {
        position_name: 'Неважно',
        value: -1
      },
      {
        position_name: 'Да',
        value: true
      },
      {
        position_name: 'Нет',
        value: false
      }
    ],
    country
  },

  operatorRolesDefault: {
    currency: {
      name: 'Выберите валюту',
      value: '999'
    },
    status: {
      name: 'Активные',
      value: true
    },
    country: {
      name: 'Выберите страну',
      value: 0
    },
    langs: {
      name: 'Выберите язык',
      value: 0
    }
  },
  operatorBanner: [],
  operatorAutoMessage: []
}
