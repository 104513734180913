<template>
  <Popup :visible="visible" @close="close" description="Удалить оператора" modalClass="popup--small">
    <form @submit.prevent="deleteModerator">
      <h2 v-if="!closeShift" class="pop-up__title">
        Вы уверены что хотите удалить оператора из смены?
      </h2>
      <h2 v-if="closeShift" class="pop-up__title">
        Вы уверены что хотите удалить оператора из смены? Это приведет к закрытию смены
      </h2>
      <div class="pop-up__btn-wrapper">
        <button class="button button--add">Удалить</button>
        <button type="button" @click="closeModal" class="button button--back">
          Отмена
        </button>
      </div>
    </form>
  </Popup>
</template>

<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { mapActions } from 'vuex'
import {
  CALENDAR_DEL_MODERATOR_POPUP,
  CALENDAR_CLOSE_POPUP,
  CALENDAR_NO_CLOSE_POPUP
} from '@/constants/event'
import { REQUEST_DELL_MODERATOR_EVENT } from '@/store/action-types'

export default {
  name: 'CalendarDelModeratorPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data() {
    return {
      userId: '',
      closeShift: null,
      CALENDAR_CLOSE_POPUP,
      CALENDAR_NO_CLOSE_POPUP
    }
  },
  mounted() {
    this.$root.$on(CALENDAR_DEL_MODERATOR_POPUP, prop => {
      this.userId = prop.userId
      this.scheduleId = prop.scheduleId
      ;(this.closeShift = prop.endShift), this.open()
    })
  },
  methods: {
    ...mapActions({
      delModeration: REQUEST_DELL_MODERATOR_EVENT
    }),
    closeModal() {
      this.userId = ''
      this.scheduleId = ''
      this.closeShift = null
      this.close()
    },
    deleteModerator() {
      const formData = {
        userId: {
          operator_id: this.userId
        },
        scheduleId: this.scheduleId
      }
      this.delModeration(formData).then(response => {
        if (response.status) this.$emit('my-event')
      })

      if (this.closeShift) {
        this.$root.$emit(this.CALENDAR_CLOSE_POPUP)
      } else {
        this.$root.$emit(this.CALENDAR_NO_CLOSE_POPUP, formData.userId.operator_id)
      }
      this.closeModal()
    }
  }
}
</script>

<style lang="scss">
@import './CalendarPopup.scss';
</style>
