<template>
  <Popup :visible="visible" @close="closeModal" description="Новая категория" modalClass="popup--medium">
    <form @submit.prevent="addNewCategoryHandler" class="modal-update__question">
      <div class="form-group">
        <label for="title" class="popup__item-title">Название</label>
        <textarea
          type="text"
          id="title"
          v-model="newCategory.title"
          name="title"
          class="popup__input default-input"
          placeholder="Название"
          required
        ></textarea>
      </div>

      <div class="form-group">
        <a for="icon" class="icon-select popup__item-title"> Выбранная иконка</a>

        <span v-show="currentIcon">
          <i :class="getCurrentIconModal"></i>
        </span>

        <ul class="icon-select-list">
          <li
            v-for="(icon, index) in listIcons"
            :key="index"
            class="icon-select-list__item"
            :class="{
              'icon-select-list__item--active': index === currentIndexIco
            }"
            @click="changeIconHandler(icon, index)"
          >
            <i :class="icon"></i>
          </li>
        </ul>
      </div>

      <div class="popup-footer">
        <button class="button button--add">Добавить</button>
        <button type="button" @click="closeModal" class="button button--back">
          Отмена
        </button>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { FAQ_POPUP_NEW_CATEGORY_EVENT } from '@/constants/event'
import { GET_CURRENT_LANGUAGES } from '@/store/getter-types'
import { REQUEST_CREATE_NEW_CATEGORY } from '@/store/action-types'

export default {
  name: 'FaqPopupAddNewCategory',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data() {
    return {
      newCategory: {},
      newCategoryData: {},
      currentIcon: null,
      isIconSelect: false,
      currentIndexIco: null
    }
  },

  computed: {
    ...mapGetters({
      lang: GET_CURRENT_LANGUAGES
    }),
    getCurrentIconModal() {
      return this.currentIcon
    }
  },

  props: {
    allLang: {
      type: Array
    },
    listIcons: {
      type: Array
    }
  },

  mounted() {
    this.$root.$on(FAQ_POPUP_NEW_CATEGORY_EVENT, () => {
      this.open()
    })
  },

  methods: {
    closeModal() {
      this.currentIcon = null
      this.currentIndexIco = null
      this.isIconSelect = false
      this.newCategory = {}
      this.newCategoryData = {}
      this.close()
    },

    changeIconHandler(icon, index) {
      this.currentIndexIco = index
      this.currentIcon = icon
      this.isIconSelect = false
    },

    ...mapActions({ createNewCategory: REQUEST_CREATE_NEW_CATEGORY }),

    addNewCategoryHandler() {
      const newCategory = {
        lang: this.lang,
        title: this.newCategory.title,
        icon: this.currentIcon
      }
      this.createNewCategory(newCategory)

      this.closeModal()
    }
  }
}
</script>

<style lang="scss">
@import './FaqPopupStyle';
</style>
