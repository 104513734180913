<template>
  <section ref="filtersWrap" class="filters-wrap">
    <!-- <simplebar
      class="filters app-users-filters"
      v-click-outside="close"
      :class="{ opened: mobileFilterOpen }"
    > -->
    <!-- v-click-outside="close" -->
    <div class="filters app-users-filters" ref="mobilefilters" :class="{ opened: mobileFilterOpen }">
      <div class="filters__mobile-nav">
        <button type="button" class="filters__link" @click="mobileFilterOpen = !mobileFilterOpen">
          <i class="filters__icon fa fa-filter"></i>
          <span class="filters__button-text">Фильтры</span>
        </button>
        <button v-if="isMobile && windowTop > 200" @click="toTop" class="btn-to-top--mobile">
          <FaIcon :iconClass="'fas fa-arrow-up'" />
        </button>
      </div>
      <form class="filters__items" @submit.prevent="applyUserFiltersHanlder">
        <div class="filters__group">
          <div class="filters__item">
            <span class="filters__item-title">ID события:</span>
            <input
              type="text"
              class="filters__input filters__input--event"
              v-model="filterEventId"
              ref="filtereventid"
            />
          </div>
          <div class="filters__item">
            <span class="filters__item-title">ID пользователя:</span>
            <input type="text" class="filters__input filters__input--event" v-model="userID" ref="userid" />
          </div>
        </div>
        <div class="filters__group">
          <div class="filters__item">
            <span class="filters__item-title">ID категории:</span>
            <input
              type="text"
              class="filters__input filters__input--event"
              v-model="filterCategoryId"
              ref="filtercategoryid"
            />
          </div>
          <div class="filters__item">
            <span class="filters__item-title">Город:</span>
            <input type="text" class="filters__input" v-model="filterCity" ref="filtercity" />
          </div>
        </div>
        <div class="filters__group">
          <div class="filters__item">
            <div class="select">
              <span class="select__title">Пол:</span>
              <div class="select__body">
                <button type="button" class="select__field" @click="clickClose('gender')">
                  <span class="select__field-value">{{ defaultFilter({ key: 'gender' }) }}</span>
                  <i class="select__field-icon fa fa-angle-down"></i>
                </button>
                <ul class="select__list" @mouseleave="mouseleave" v-if="genderSelectOpen">
                  <li
                    class="select__item"
                    v-for="gender in usersHeader.gender"
                    :key="gender.id"
                    @click="
                      selectFilterBuilder({
                        key: 'gender',
                        value: gender
                      })
                    "
                  >
                    <span class="select__text"> {{ gender.name }} </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="filters__item">
            <div class="select select--big">
              <span class="select__title">Статус:</span>
              <div class="select__body">
                <button type="button" class="select__field select--big__field" @click="clickClose('status')">
                  <span class="select__field-value">{{ defaultFilter({ key: 'status' }) }}</span>
                  <i class="select__field-icon fa fa-angle-down"></i>
                </button>
                <ul class="select__list" @mouseleave="mouseleave" v-if="statusSelectOpen">
                  <li
                    class="select__item"
                    v-for="status in usersHeader.status"
                    :key="status.id"
                    @click="
                      selectFilterBuilder({
                        key: 'status',
                        value: status
                      })
                    "
                  >
                    <span class="select__text">{{ status.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="filters__group filters__group--big">
          <div class="filters__item">
            <div class="select">
              <span class="select__title">Активный:</span>
              <div class="select__body">
                <button type="button" class="select__field" @click="clickClose('active')">
                  <span class="select__field-value">{{ defaultFilter({ key: 'active' }) }}</span>
                  <i class="select__field-icon fa fa-angle-down"></i>
                </button>
                <ul class="select__list" @mouseleave="mouseleave" v-if="activeSelectOpen">
                  <li
                    class="select__item"
                    v-for="active in usersHeader.active"
                    :key="active.id"
                    @click="
                      selectFilterBuilder({
                        key: 'active',
                        value: active
                      })
                    "
                  >
                    <span class="select__text">{{ active.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="filters__item">
            <div class="select">
              <span class="select__title">Пользовательское:</span>
              <div class="select__body">
                <button type="button" class="select__field" @click="clickClose('own')">
                  <span class="select__field-value">{{ defaultFilter({ key: 'own' }) }}</span>
                  <i class="select__field-icon fa fa-angle-down"></i>
                </button>
                <ul class="select__list" @mouseleave="mouseleave" v-if="ownSelectOpen">
                  <li
                    class="select__item"
                    v-for="own in usersHeader.own"
                    :key="own.id"
                    @click="
                      selectFilterBuilder({
                        key: 'own',
                        value: own
                      })
                    "
                  >
                    <span class="select__text">{{ own.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="filters__group filters__group--dates">
          <div class="filters__item">
            <div class="calendar-select">
              <span class="select__title">Дата создания:</span>
              <i class="select__field-icon fa fa-angle-down"></i>
              <DateRangePicker
                :locale-data="picker.localeData"
                :opens="picker.localeData.position"
                :close-on-esc="true"
                :autoApply="true"
                :ranges="false"
                ref="picker"
                v-model="datePickerControl"
              />
            </div>
          </div>
        </div>
        <div class="filters__group filters__group--text">
          <div class="filters__item">
            <p class="filters__text">
              События <span class="filters__text--count">({{ count }})</span>
            </p>
          </div>
        </div>
        <div class="filters__buttons">
          <button type="button" @click="resetFiltersHandler" class="filters__btn-clear">
            <i class="fa fa-eraser"></i>
          </button>
          <button type="submit" class="filters__btn-search">
            <span>Поиск</span>
          </button>
        </div>
      </form>
      <button
        v-if="!mobileFilterOpen && windowTop > 200 && !isMobile"
        @click="toTop"
        class="btn-to-top-filters"
      >
        <FaIcon :iconClass="'fas fa-arrow-up'" />
      </button>
      <!-- </simplebar> -->
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import {
  GET_EVENT_FILTERS_INFO,
  GET_EVENT_FILTERS_DEFAULT,
  GET_EVENT_TMP_FILERS,
  GET_EVENT_VALUE_PICKER
} from '@/store/getter-types'
import {
  SET_EVENT_RESET_FILTERS,
  SET_EVENT_TMP_FILTERS,
  SET_EVENT_DATE_FILTER,
  SET_EVENTS,
  SET_EVENTS_FILTERS
} from '@/store/mutation-types'
import { REQUEST_EVENTS, REQUEST_ALL_EVENTS } from '@/store/action-types'
import DateRangePicker from 'vue2-daterange-picker'
import timeFormat from '@/components/mixins/timeFormat'
import pickerMixin from '@/components/Picker/pickerMixin'
// import simplebar from "simplebar-vue";
export default {
  name: 'EventFilters',
  components: {
    DateRangePicker
    // simplebar
  },
  props: [`count`],
  mixins: [timeFormat, pickerMixin],
  data: () => ({
    statusSelectOpen: false,
    genderSelectOpen: false,
    activeSelectOpen: false,
    ownSelectOpen: false,
    windowTop: 0,

    mobileFilterOpen: false,
    filters: {
      user_id: '',
      event_id: '',
      category_id: '',
      city: ''
    }
  }),

  watch: {
    mobileFilterOpen: function() {
      if (!this.mobileFilterOpen) this.$refs.mobilefilters.scrollTop = 0
    }
  },

  computed: {
    ...mapGetters({
      usersHeader: GET_EVENT_FILTERS_INFO,
      defaultFilters: GET_EVENT_FILTERS_DEFAULT,
      tmpFilter: GET_EVENT_TMP_FILERS,
      getDatePickerValues: GET_EVENT_VALUE_PICKER
    }),

    userID: {
      get() {
        return this.filters.user_id
      },
      set(id) {
        this.$refs.userid.value = id
        this.filters.user_id = id
      }
    },

    filterEventId: {
      get() {
        return this.filters.event_id
      },
      set(id) {
        this.$refs.filtereventid.value = id
        this.filters.event_id = id
      }
    },

    filterCategoryId: {
      get() {
        return this.filters.category_id
      },
      set(id) {
        this.$refs.filtercategoryid.value = id
        this.filters.category_id = id
      }
    },

    isMobile() {
      return window.matchMedia('(max-width: 1025px)').matches ? true : false
    },

    filterCity: {
      get() {
        return this.filters.city
      },
      set(city) {
        city = city.replace(/\d/g, '')
        this.$refs.filtercity.value = city
        this.filters.city = city
      }
    },

    datePickerControl: {
      get() {
        const registerDate = this.getDatePickerValues.register
        const date = {
          startDate: moment(registerDate.startDate, 'DD.MM.YY').unix(),
          endDate: moment(registerDate.endDate, 'DD.MM.YY').unix()
        }
        return date
      },
      set({ startDate, endDate }) {
        const timestampStartDate = Math.round(new Date(startDate).getTime() / 1000.0)
        const timestampEndDate = Math.round(new Date(endDate).getTime() / 1000.0)
        const timeStampDateObject = {
          startDate: timestampStartDate,
          endDate: timestampEndDate
        }
        this.dateFilter({ timeStampDateObject, key: 'register' })
      }
    }
  },

  methods: {
    ...mapMutations({
      resetFilters: SET_EVENT_RESET_FILTERS,
      addTmpFilters: SET_EVENT_TMP_FILTERS,
      dateFilter: SET_EVENT_DATE_FILTER,
      delEvent: SET_EVENTS,
      saveFilters: SET_EVENTS_FILTERS
    }),

    ...mapActions({
      applyFilters: REQUEST_EVENTS,
      getAllEvent: REQUEST_ALL_EVENTS
    }),

    toTop() {
      this.$emit('toTop')
    },

    mouseleave() {
      this.close()
    },

    close() {
      this.statusSelectOpen = false
      this.activeSelectOpen = false
      this.genderSelectOpen = false
      this.ownSelectOpen = false
    },

    defaultFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : this.defaultFilters[key].name
    },

    defaultNameFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].name
      return current ? current : ''
    },

    defaultValueFilter(filterObject) {
      const { key } = filterObject
      const current = this.tmpFilter[key].value
      return current !== undefined ? current : ''
    },

    selectFilterBuilder(filterItem) {
      const { key } = filterItem
      switch (key) {
        case 'gender':
          this.addTmpFilters({ ...filterItem })
          break
        case 'status':
          this.addTmpFilters({ ...filterItem })
          break
        case 'active':
          this.addTmpFilters({ ...filterItem })
          break
        case 'own':
          this.addTmpFilters({ ...filterItem })
          break
        default:
          break
      }
      this[`${key}SelectOpen`] = false
    },

    resetFiltersHandler() {
      this.resetFilters()
      this.resetData(this.filters)
      this.$refs.picker.start = null
      this.$refs.picker.end = null
      // this.delEvent([]);
      // this.applyFilters(eventFilterData);
      this.saveFilters({})
      this.getAllEvent()
      this.mobileFilterOpen = !this.mobileFilterOpen
    },

    resetData(data) {
      for (const key in data) {
        const item = data[key]
        if (item) {
          switch (item.constructor) {
            case Object:
              this.resetData(item)
              break
            default:
              data[key] = ''
          }
        }
      }
    },

    onScroll(e) {
      this.windowTop = e.target.scrollTop
    },

    applyUserFiltersHanlder() {
      const eventFilterData = {
        event_id: this.filters.event_id,
        'event_user.id': this.filters.user_id,
        'event_category.id': this.filters.category_id,
        'event_user.user_city': this.filters.city,
        'event_user.user_gender': this.defaultValueFilter({ key: 'gender' }),
        event_active: this.defaultValueFilter({ key: 'active' }),
        event_status: this.defaultValueFilter({ key: 'status' }),
        event_own: this.defaultValueFilter({ key: 'own' }),
        create_from: this.getDatePickerValues.register.startDate,
        create_to: this.getDatePickerValues.register.endDate
      }
      Object.keys(eventFilterData).forEach(
        key =>
          (eventFilterData[key] === '' ||
            eventFilterData[key] === 'Неважно' ||
            eventFilterData[key] === undefined) &&
          delete eventFilterData[key]
      )
      if (Object.keys(eventFilterData).length !== 0) {
        this.applyFilters(eventFilterData)
        this.saveFilters(eventFilterData)
      } else {
        this.getAllEvent()
        this.saveFilters({})
      }
      this.mobileFilterOpen = !this.mobileFilterOpen
    },
    clickClose(options) {
      let newOptions = this[`${options}SelectOpen`]
      this.close()
      this[`${options}SelectOpen`] = !newOptions
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.$emit('filtersHeight', this.$refs.filtersWrap.clientHeight)
    })
    setTimeout(() => {
      let el = document.getElementsByClassName('events-wrap')[0]
      el.addEventListener('scroll', this.onScroll)
    }, 1000)

    this.$root.$on('openaside', value => {
      if (this.mobileFilterOpen) this.mobileFilterOpen = !this.mobileFilterOpen
    })
  }
}
</script>

<style lang="scss">
@import './EventFilters.scss';
.btn-to-top-filters {
  background-color: #a2a2a2a8;
  border-radius: 60px;
  color: #ffffff;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.btn-to-top--mobile {
  background-color: #a2a2a2a8;
  border-radius: 60px;
  color: #ffffff;
  position: absolute;
  right: 18px;
  top: 20px;
}

.filters__group--text {
  margin-top: 25px;
  border-left: 1px solid #ddd;
  padding-left: 5px;
}

.filters__text {
  align-items: center;
  font-size: 18px;
  line-height: 34px;
  font-weight: 700;
  &--count {
    color: #318bcc;
  }
}
</style>
