<template>
  <Popup :visible="visible" @close="close" title="" description="">
    <l-map
      class="app-user-map__popup"
      @click="$root.$emit(USER_MAP_EVENT)"
      ref="myMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      style="height: 500px"
    >
      <l-tile-layer :url="url" />
      <l-marker :lat-lng="marker"> </l-marker>
    </l-map>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_MAP_EVENT } from '@/constants/event'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'

export default {
  name: 'UserMapPopup',
  components: {
    Popup,
    LMap,
    LTileLayer,
    LMarker
  },
  data() {
    return {
      USER_MAP_EVENT,
      zoom: 15,
      center: latLng(0, 0),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      marker: latLng(0, 0),
      mapOptions: {
        zoomSnap: 0.5
      }
    }
  },
  mixins: [ModalMixin],

  mounted() {
    this.$root.$on(USER_MAP_EVENT, currentUser => {
      this.center = latLng(currentUser.location.lat, currentUser.location.lng)
      this.marker = latLng(currentUser.location.lat, currentUser.location.lng)
      this.open()
    })
  }
}
</script>

<style lang="scss">
.app-user-map__popup {
  margin-top: 10px;
  width: 40%;
  min-width: 1280px;
  border-radius: 4px;
  //  ЗАГЛУШКА
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  @media (max-width: 1750px) {
    margin-right: 0;
    margin-bottom: 8px;
  }
  @media (max-width: 1020px) {
    min-width: 100%;
  }
  @media (max-width: 912px) {
    min-width: 330px;
  }
}
</style>
