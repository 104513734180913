<template>
  <div class="app-user-header">
    <div class="app-user-profile">
      <div class="app-user-verification" v-if="currentUser.verification === 2">
        <i class="app-user-verification__badge-icon-i fa fa-shield-alt"></i>
      </div>
      <div v-if="currentUser.delete_info.reason_code == -1" class="app-user-profile__online">
        <div
          class="app-user-profile__online-inner"
          :class="{
            'app-user-profile__online-inner-off': dateOnline >= 300,
            'app-user-profile__online-inner-recently': dateOnline > 300 && dateOnline < 900,
            'app-user-profile__online-inner-on': dateOnline <= 300
          }"
        ></div>
      </div>
      <div class="app-user-profile__avatar">
        <img :src="getImgLinkCropped(currentUser.avatar.id, 480, { ...currentUser.avatar.crop })" alt="" />
      </div>
      <div class="app-user-profile__info">
        <div class="app-user-profile__name-block">
          <span class="app-user-profile__gender">
            <FaIcon
              :iconClass="[
                currentUser.gender === 0 ? 'fa fa-genderless app-user-profile__gender-icon-male' : '',
                currentUser.gender === 1 ? 'fa fa-mars app-user-profile__gender-icon-male' : '',
                currentUser.gender === 2 ? 'fa fa-venus app-user-profile__gender-icon-female' : ''
              ]"
              class="app-user-profile__gender-icon"
            />
          </span>
          <span class="app-user-profile__name">{{ currentUser.name }}, {{ currentUser.age || 0 }}</span>
          <span v-if="currentUser.is_operator" class="app-user-profile__name-operator">
            <FaIcon :iconClass="'fa-solid fa-street-view'" v-tooltip="'Оператор'" />
          </span>
        </div>
        <div class="app-user-profile__address-block">
          <span class="app-user-profile__address">{{ currentUser.city }}</span>
        </div>
      </div>
    </div>
    <div class="app-user-header-info">
      <div class="app-user-header-info__item">
        <div class="app-user-header-info__item-inner">
          <FaIcon
            :iconClass="'fas fa-crown'"
            class="app-user-header-info__icon app-user-header-info__icon--vip"
            v-tooltip="'Статус VIP'"
          />
          <span class="app-user-header-info__text">{{
            currentUser.vip.enabled === true ? toLocaleDateUser(currentUser.vip.expire) : 0
          }}</span>
        </div>
      </div>
      <div class="app-user-header-info__item">
        <div class="app-user-header-info__item-inner">
          <FaIcon
            :iconClass="'fas fa-list-alt'"
            class="app-user-header-info__icon app-user-header-info__icon--chats"
            v-tooltip="'Количество чатов'"
          />
          <span class="app-user-header-info__text">{{ currentUser.count.chat }}</span>
        </div>
      </div>
      <div class="app-user-header-info__item">
        <div class="app-user-header-info__item-inner">
          <FaIcon
            :iconClass="'fas fa-arrow-down'"
            class="app-user-header-info__icon app-user-header-info__icon--chats"
            v-tooltip="'Кол-во входящих сообщений'"
          />
          <span class="app-user-header-info__text">{{ currentUser.count.total_in_messages }}</span>
        </div>
      </div>
      <div class="app-user-header-info__item">
        <div class="app-user-header-info__item-inner">
          <FaIcon
            :iconClass="'fas fa-arrow-up'"
            class="app-user-header-info__icon app-user-header-info__icon--chats"
            v-tooltip="'Кол-во исходящих сообщений'"
          />
          <span class="app-user-header-info__text">{{ currentUser.count.total_out_messages }}</span>
        </div>
      </div>
      <div class="app-user-header-info__item">
        <div class="app-user-header-info__item-inner">
          <FaIcon
            :iconClass="'fas fa-exclamation-triangle'"
            class="app-user-header-info__icon app-user-header-info__icon--warnings"
            v-tooltip="'Количество предупреждений'"
          />
          <span class="app-user-header-info__text">{{ currentUser.count.claim }}</span>
        </div>
      </div>
      <div class="app-user-header-info__item" v-if="currentUser.incognito_admin">
        <div class="app-user-header-info__item-inner">
          <FaIcon
            :iconClass="'fa-user-secret'"
            class="app-user-header-info__icon app-user-header-info__icon--incognito"
            v-tooltip="'Пользователь скрыт в админ панели'"
          />
          <span class="app-user-header-info__text">Скрыт в админ панели</span>
        </div>
      </div>
      <div class="app-user-header-info__item" v-if="currentUser.incognito_app">
        <div class="app-user-header-info__item-inner">
          <FaIcon
            :iconClass="'fa fa-info-circle'"
            class="app-user-header-info__icon app-user-header-info__icon--orange"
            v-tooltip="'Пользователь скрыт в приложении'"
          />
          <span class="app-user-header-info__text">Скрыт в приложении</span>
        </div>
      </div>
      <div
        class="app-user-header-info__item app-user-header-info__item--deleted"
        v-if="currentUser.is_deleted"
      >
        <div class="app-user-header-info__item-inner">
          <FaIcon
            :iconClass="'fa fa-trash'"
            class="app-user-header-info__icon app-user-header-info__icon--delete"
            v-tooltip="'Пользователь удален'"
          />
          <span class="app-user-header-info__text app-user-header-info__text--ml ">Удален</span>
          <span class="app-user-header-info__text"
            >Причина удаления: {{ currentUser.delete_info.reason_text }}</span
          >
        </div>
        <div
          class="app-user-header-info__item-inner app-user-header-info__item-inner--row"
          v-if="currentUser.delete_info.admin.id"
        >
          <span class="app-user-header-info__text">Пользователь удалён администратором </span>
          <span class="app-user-header-info__text"
            >Дата удаления: {{ toLocaleDateUser(currentUser.delete_info.date) }}</span
          >
          <span class="app-user-header-info__text"
            >Удалил: {{ deleteAdminInfo }} ({{ currentUser.delete_info.admin.position }})</span
          >
        </div>
      </div>
    </div>
    <div
      class="app-user-header-info__item"
      v-if="
        currentUser.confidential.map_visible === false && currentUser.confidential.vip_map_invisible === true
      "
    >
      <div class="app-user-header-info__item-inner">
        <FaIcon
          :iconClass="'fa fa-info-circle'"
          class="app-user-header-info__icon app-user-header-info__icon--green"
          v-tooltip="'Пользователь скрыт на карте'"
        />
        <span class="app-user-header-info__text">Скрыт на карте</span>
      </div>
    </div>

    <div class="app-user-profile">
      <div v-if="currentUser.ban" class="app-user-profile__info">
        <div class="app-user-profile__address-block app-user-profile__blocked">
          <div class="app-user-profile__address">
            <span><i class="fas fa-exclamation-triangle"></i> Пользователь заблокирован</span>
          </div>
          <div class="app-user-profile__address">
            Дата начала блокировки:
            {{ toLocaleDateUser(currentUser.ban_info.date_from) }}
          </div>
          <div class="app-user-profile__address">
            Дата окончания блокировки:
            {{ toLocaleDateUser(currentUser.ban) }}
          </div>
          <div class="app-user-profile__address">Бан выдан: {{ banDevice }}</div>
          <div class="app-user-profile__address">
            Бан выдал: {{ adminInfo }} ({{ currentUser.ban_info.admin.position }})
          </div>
          <!-- <div class="app-user-profile__address">
            Причина: Лох
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getImgCdnLink from '@/components/mixins/getImgCdnLink'
import dateFormat from '@/components/mixins/toLocaleformat'
import { mapGetters } from 'vuex'
import { GET_USER_GENDER } from '@/store/getter-types'
import moment from 'moment'
export default {
  name: 'AppUserHeader',
  props: {
    currentUser: {
      type: Object
    },
    chats: {
      type: Array
    }
  },
  mixins: [dateFormat, getImgCdnLink],
  computed: {
    ...mapGetters({
      gender: GET_USER_GENDER
    }),
    dateOnline() {
      let date1 = Date.parse(moment()) / 1000
      let date2 = Date.parse(moment(this.currentUser.online.lastVisit)) / 1000
      return date1 - date2
    },

    banDevice() {
      if (this.currentUser.ban_info.by_device) return 'по устройству'
      else return 'по аккаунту'
    },

    adminInfo() {
      return `${this.currentUser.ban_info.admin.first_name} ${this.currentUser.ban_info.admin.last_name}`
    },

    deleteAdminInfo() {
      return `${this.currentUser.delete_info.admin.first_name} ${this.currentUser.delete_info.admin.last_name}`
    }
  }
}
</script>

<style lang="scss">
@import './App-user-header.scss';
.app-user-verification {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 2px;
  font-size: 11px;
  line-height: 1;
  color: #78cd51;
  background-color: #fff;
  border: 2px solid #78cd51;
  border-radius: 10px;
}
.app-user-header-info__icon--delete {
  color: red;
}
</style>
