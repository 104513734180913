var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auto-messages__item"},[_c('div',{staticClass:"auto-messages__item-body"},[_c('div',{staticClass:"auto-messages__item-content"},[_c('div',{staticClass:"auto-messages__item-name"},[_c('i',{staticClass:"auto-messages__item-title",class:{
            'fa fa-mars auto-messages__male': _vm.message.user_gender === 1,
            'fa fa-venus auto-messages__female': _vm.message.user_gender === 2,
            'fa fa-genderless auto-messages__no-gender': _vm.message.user_gender === 0
          }})]),_c('div',{staticClass:"auto-messages__item-name"},[_c('i',{staticClass:"auto-messages__item-title",class:{
            'fa fa-mars auto-messages__male': _vm.message.defendant_gender === 1,
            'fa fa-venus auto-messages__female': _vm.message.defendant_gender === 2,
            'fa fa-genderless auto-messages__no-gender': _vm.message.defendant_gender === 0
          }})]),_c('div',{staticClass:"auto-messages__item-name auto-messages__item-name--text"},[_c('span',{staticClass:"auto-messages__item-title"},[_vm._v(_vm._s(_vm.message.message_text))])]),_c('div',{staticClass:"auto-messages__item-btns"},[_c('button',{staticClass:"auto-messages__item-btn auto-messages__item-btn--blue",on:{"click":function($event){return _vm.$root.$emit(_vm.AUTO_MESSAGE_CHANGE_POPUP, _vm.message, _vm.currentLang, _vm.currentPage)}}},[_c('i',{staticClass:"auto-messages__item-btn-icon fa fa-edit"})]),_c('button',{staticClass:"auto-messages__item-btn auto-messages__item-btn--red",on:{"click":function($event){return _vm.$root.$emit(_vm.AUTO_MESSAGE_DELETE_POPUP, _vm.message.id, _vm.currentLang, _vm.currentPage)}}},[_c('i',{staticClass:"auto-messages__item-btn-icon fa fa-times-circle"})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }