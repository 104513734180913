/* eslint-disable vue/return-in-computed-property */
<template>
  <div class="view-item">
    <span class="view-item__field">{{ item.country_code ? item.country_code : '-' }}</span>
    <span class="view-item__field">{{ item.lang ? item.lang.name : '-' }}</span>
    <span class="view-item__field">{{ item.gender ? getGender : '-' }}</span>
    <span class="view-item__field">{{ item.age_min ? getAge : '-' }}</span>
    <span class="view-item__field">{{ item.page !== undefined ? getPage : '-' }}</span>
    <button
      class="view-item__field view-item__field--delete"
      @click="$root.$emit(VIEW_DELETE_POPUP_RECORD, item.id)"
    >
      <i class="fa fa-times-circle"></i>
    </button>
  </div>
</template>
<script>
import { pageCodes } from '@/constants/FirstScreen/page'
import { genderCodes } from '@/constants/gender'
import { VIEW_DELETE_POPUP_RECORD } from '@/constants/event'
export default {
  name: 'ViewControlItem',
  props: {
    item: {
      type: Object
    }
  },
  data: () => ({
    VIEW_DELETE_POPUP_RECORD
  }),

  computed: {
    getAge() {
      return `${this.item.age_min} - ${this.item.age_max}`
    },
    // eslint-disable-next-line vue/return-in-computed-property
    getGender() {
      switch (this.item.gender) {
        case genderCodes.MALE:
          return 'мужской'
        case genderCodes.FEMALE:
          return 'женский'
        default:
          break
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    getPage() {
      switch (this.item.page) {
        case pageCodes.FIRST_SCREEN_COMPASS_PEOPLE_AROUND:
          return 'Компас - Люди рядом'
        case pageCodes.FIRST_SCREEN_NEW_PEOPLE:
          return 'Компас - Новые лица'
        case pageCodes.FIRST_SCREEN_POPULAR:
          return 'Компас - Популярные'
        case pageCodes.FIRST_SCREEN_LAST_SEEN_RECENTLY:
          return 'Компас - Были недавно'
        case pageCodes.FIRST_SCREEN_MAP:
          return 'Компас - Карта'
        case pageCodes.FIRST_SCREEN_LIKES:
          return 'Лайки'
        case pageCodes.FIRST_SCREEN_EVENTS:
          return 'События'
        case pageCodes.FIRST_SCREEN_MESSAGES:
          return 'Сообщения'
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.view-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 10px 10px 10px 0;
  &__field {
    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 21%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 20%;
      padding-left: 0.7%;
    }
    &:nth-child(5) {
      width: 19.5%;
    }
    &--delete {
      color: #f67a6e;
      background-color: #fff;
      position: absolute;
      right: 10px;
    }
  }
}
</style>
