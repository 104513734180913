<template>
  <Popup :visible="visible" @close="close" :description="title" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="deleteHandler()">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close()">Нет</button>
      </div>
    </div>
  </Popup>
</template>
<script>
import { mapActions } from 'vuex'
import { REQUEST_VISIBLE_MAP_USER } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_HIDE_MAP } from '@/constants/event'
export default {
  name: 'UserDeviceBanPopup',
  components: {
    Popup
  },
  data: () => ({
    id: '',
    visibleUser: false
  }),
  mixins: [ModalMixin],

  computed: {
    title() {
      if (!this.visibleUser) {
        return 'Показать пользователя на карте?'
      } else {
        return 'Скрыть пользователя на карте?'
      }
    }
  },

  methods: {
    ...mapActions({
      sendUserVisible: REQUEST_VISIBLE_MAP_USER
    }),
    deleteHandler() {
      const payload = {
        user_id: this.id,
        visible: this.visibleUser ? false : true
      }
      this.sendUserVisible(payload)
      this.id = ''
      this.visible = false
      this.close()
    }
  },

  mounted() {
    this.$root.$on(USER_HIDE_MAP, user => {
      if (user.confidential.map_visible === false && user.confidential.vip_map_invisible === true)
        this.visibleUser = false
      else this.visibleUser = true
      this.id = user.id
      this.open()
    })
  }
}
</script>
