const SET_MODERATORS_RATING = 'SET_MODERATORS_RATING'
const SET_MODERATOR_SETTINGS_QUALITY = 'SET_MODERATOR_SETTINGS_QUALITY'
const SET_MODERATOR_SETTINGS_SPEED = 'SET_MODERATOR_SETTINGS_SPEED'
const SET_CLEAR_MODERATOR_RATING_DATA = 'SET_CLEAR_MODERATOR_RATING_DATA'
const SET_MODERATOR_RATING_SETTINGS = 'SET_MODERATOR_RATING_SETTINGS'

export {
  SET_MODERATORS_RATING,
  SET_MODERATOR_SETTINGS_QUALITY,
  SET_MODERATOR_SETTINGS_SPEED,
  SET_CLEAR_MODERATOR_RATING_DATA,
  SET_MODERATOR_RATING_SETTINGS
}
