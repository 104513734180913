const GET_OPERATORS = 'GET_OPERATORS'
const GET_OPERATOR_ROLES = 'GET_OPERATOR_ROLES'
const GET_OPERATOR_TMP_ROLES = 'GET_OPERATOR_TMP_ROLES'
const GET_OPERATOR_ROLES_DEFAULT = 'GET_OPERATOR_ROLES_DEFAULT'
const GET_OPERATORS_CURRENCY = 'GET_OPERATORS_CURRENCY'
const GET_OPERATOR_USERS = 'GET_OPERATOR_USERS'
const GET_CURRENT_OPERATOR_USER = 'GET_CURRENT_OPERATOR_USER'
const GET_OPERATOR_SEARCH_OPTION = 'GET_OPERATOR_SEARCH_OPTION'
const GET_OPERATOR_STATISTIC = 'GET_OPERATOR_STATISTIC'
const GET_CURRENT_OPERATOR = 'GET_CURRENT_OPERATOR'
const GET_OPERATOR_PAYOUT = 'GET_OPERATOR_PAYOUT'
const GET_OPERATOR_BANNER = 'GET_OPERATOR_BANNER'
const GET_OPERATORS_AUTO_MESSAGE = 'GET_OPERATORS_AUTO_MESSAGE'
const GET_ACTIVE_OPERATOR = 'GET_ACTIVE_OPERATOR'
const GET_TMP_DEFAULT_FILTERS_OPERATOR = 'GET_TMP_DEFAULT_FILTERS_OPERATOR'
const GET_TMP_FILTERS_OPERATOR = 'GET_TMP_FILTERS_OPERATOR'

export {
  GET_OPERATORS,
  GET_OPERATOR_ROLES,
  GET_OPERATOR_TMP_ROLES,
  GET_OPERATOR_ROLES_DEFAULT,
  GET_OPERATORS_CURRENCY,
  GET_OPERATOR_USERS,
  GET_ACTIVE_OPERATOR,
  GET_CURRENT_OPERATOR_USER,
  GET_OPERATOR_SEARCH_OPTION,
  GET_OPERATOR_STATISTIC,
  GET_CURRENT_OPERATOR,
  GET_OPERATOR_PAYOUT,
  GET_OPERATOR_BANNER,
  GET_OPERATORS_AUTO_MESSAGE,
  GET_TMP_DEFAULT_FILTERS_OPERATOR,
  GET_TMP_FILTERS_OPERATOR
}
