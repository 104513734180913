import Request from '../helpers/Request'
import BaseService from './BaseService'

class AchievementsService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getAllAchievements(lang) {
    const getAllAchievementsUrl = `/api/v1/localization/achievement/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Cache-Control': 'no-cache'
    }
    return await Request.get(getAllAchievementsUrl, {
      headers,
      params: { lang }
    })
  }

  async editAchievement(achievement) {
    const achievementEditUrl = `/api/v1/localization/achievement/add/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Content-Type': 'multipart/form-data'
    }

    return await Request.post(achievementEditUrl, achievement, { headers })
  }

  async deleteAchievement(id) {
    const deleteAchievementUrl = `/api/v1/localization/achievement/${id}/destroy/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    return await Request.delete(deleteAchievementUrl, { headers })
  }

  async updateAchievement(achievement) {
    const updateAchievementUrl = `/api/v1/localization/achievement/${achievement.get('id')}/update/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`,
      'Content-Type': 'multipart/form-data'
    }
    return await Request.put(updateAchievementUrl, achievement, { headers })
  }
}

export default AchievementsService
