<template>
  <Popup :visible="visible" @close="close" description="Удалить цифровую покупку?" modalClass="popup--small">
    <div class="popup__item">
      <div class="popup__btns popup__btns--center">
        <button class="popup__btn btn-green" @click="delHandler()">
          Да
        </button>
        <button class="popup__btn btn-red" @click="close">
          Нет
        </button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions } from 'vuex'
import { REQUEST_DELETE_PURCHASES } from '@/store/action-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { PURCHASE_DELETE_POPUP_EVENT } from '@/constants/event'

export default {
  name: 'PurchasesDeletePopUp',
  components: {
    Popup
  },
  data: () => ({
    PURCHASE_DELETE_POPUP_EVENT,
    id: null
  }),
  mixins: [ModalMixin],

  methods: {
    ...mapActions({
      deletePurchase: REQUEST_DELETE_PURCHASES
    }),

    delHandler() {
      this.deletePurchase({ purchase_id: this.id })
      this.id = null
      this.close()
    }
  },

  mounted() {
    this.$root.$on(PURCHASE_DELETE_POPUP_EVENT, id => {
      this.id = id
      this.open()
    })
  }
}
</script>
