<template>
  <div class="support-contact-wrap">
    <div class="support-contact">
      <TypesSupportContactHeader />
      <div class="support-contact__table">
        <span>Номер обращения</span>
        <span>Обращение</span>
      </div>
      <div class="support-contact__list" v-if="isLoaded && supportContacts.length">
        <TypesSupportContactItem v-for="contact in supportContacts" :key="contact.id" :contact="contact" />
      </div>
      <div class="support-contact__no-reasons" v-if="!supportContacts.length && isLoaded">
        <span class="support-contact__text">Нет данных...</span>
      </div>
      <div v-if="errorMessage" class="support-contact__no-reasons">
        <span class="support-contact__text"
          >Ошибка при загрузке типов обращений в техническую поддержку!</span
        >
      </div>
    </div>
    <SupportContactTypeDelPopup />
    <SupportContactTypeAddPopup />
    <SupportContactsEditPopup />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { GET_SUPPORT_CONTACT_TYPES } from '@/store/getter-types'
import { SET_CURRENT_LANGUAGES } from '@/store/mutation-types'
import { REQUEST_SUPPORT_CONTACT_TYPES } from '@/store/action-types'
import TypesSupportContactHeader from '@/components/TypesSupportContact/TypesSupportContactHeader'
import TypesSupportContactItem from '@/components/TypesSupportContact/TypesSupportContactItem'
import SupportContactTypeDelPopup from '@/components/Modals/SupportContactsPopup/SupportContactTypeDelPopup'
import SupportContactTypeAddPopup from '@/components/Modals/SupportContactsPopup/SupportContactTypeAddPopup'
import SupportContactsEditPopup from '@/components/Modals/SupportContactsPopup/SupportContactsEditPopup'

import store from '@/store'
export default {
  name: 'SupportContact',
  components: {
    TypesSupportContactHeader,
    TypesSupportContactItem,
    SupportContactTypeDelPopup,
    SupportContactsEditPopup,
    SupportContactTypeAddPopup
  },
  data() {
    return {
      errorMessage: false,
      isLoaded: false
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_SUPPORT_CONTACT_TYPES).then(response => {
      next(vm => {
        if (response) {
          vm.setCurrentLang('RU')
        }
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      supportContacts: GET_SUPPORT_CONTACT_TYPES
    })
  },
  methods: {
    ...mapMutations({
      setCurrentLang: SET_CURRENT_LANGUAGES
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
  }
}
</script>

<style lang="scss">
@import '@/components/TypesSupportContact/TypeSupportContact.scss';

.support-contact__no-reasons {
  min-height: 64px;
  border-radius: 2px;

  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
}
.support-contact__text {
  margin: 0 auto;
}
.support-contact-wrap {
  padding: 16px;
  max-width: 1140px;
  margin: 0 auto;
  .support-contact__item-name {
    &:first-child {
      padding-left: 30px;
      width: 50%;
    }
    &:nth-child(2) {
      width: 50%;
    }
  }
  .support-contact__item-content {
    justify-content: flex-start;
  }
}
</style>
