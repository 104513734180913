<template>
  <form @submit.prevent="addSetting">
    <div class="setting__table-add" v-if="addOpen">
      <div class="setting__add-item">
        <input type="text" class="setting__add-input" v-model="categoryCountry" ref="category" required />
      </div>
      <div class="setting__add-item">
        <input
          type="text"
          class="setting__add-input"
          :value="this.country_codes.join(', ')"
          ref="codes"
          disabled
          required
        />
        <button
          class="setting__edit-btn"
          @click.prevent="$root.$emit('ADD_CODE_OPTION_POPUP_EVENT', country_codes)"
        >
          <i class="fa fa-edit"></i>
        </button>
      </div>
      <div class="setting__add-item">
        <input
          type="number"
          class="setting__add-input"
          v-model="free_messages"
          ref="coefficient"
          step="any"
          required
        />
      </div>
      <div class="setting__add-item">
        <input
          type="number"
          class="setting__add-input"
          v-model="coefficientSetting"
          ref="coefficient"
          step="any"
          required
        />
        <svg
          class="svg-inline--fa fa-ruble-sign fa-w-12 setting__table-icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="ruble-sign"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
          ></path>
        </svg>
      </div>

      <div class="setting__add-item">
        <input
          type="number"
          class="setting__add-input"
          v-model="meetingPrice"
          ref="coefficient"
          step="any"
          required
        />
        <svg
          class="svg-inline--fa fa-ruble-sign fa-w-12 setting__table-icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="ruble-sign"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"
          ></path>
        </svg>
      </div>

      <div class="setting__add-item setting__add-item--gender">
        <input type="number" class="setting__add-input" v-model="manSetting" ref="man" min="-1000" required />
        <svg
          v-tooltip="'Баллов Skylove'"
          class="app-user__item-icon app-user__item-icon--small app-user__item-icon--slcoins"
          width="21"
          height="16"
          viewBox="0 0 27 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.80449 22.9982L2.8042 23L12.833 23L12.4997 25H16.4997L16.8333 22.9982C19.3028 22.9634 21.2558 22.4073 22.6921 21.3301C24.1862 20.2168 25.0016 18.6299 25.1383 16.5693C25.2067 15.4951 25.0065 14.5771 24.5378 13.8154C24.0788 13.0439 23.4342 12.5117 22.6042 12.2187C24.9186 11.2812 26.1491 9.68945 26.2956 7.44336C26.4128 5.62695 25.8122 4.21582 24.4938 3.20996C23.472 2.43032 22.0423 1.94989 20.2049 1.76868L20.4997 0H16.4997L16.219 1.68413L6.19193 1.67365L6.17609 1.76868C8.01349 1.94988 9.44313 2.43031 10.465 3.20996C11.7834 4.21582 12.384 5.62695 12.2668 7.44336C12.1203 9.68945 10.8898 11.2812 8.57536 12.2188C9.40544 12.5117 10.05 13.0439 10.509 13.8154C10.9777 14.5771 11.1779 15.4951 11.1095 16.5693C10.9728 18.6299 10.1574 20.2168 8.66325 21.3301C7.22694 22.4073 5.27402 22.9634 2.80449 22.9982ZM13.7272 19.0449L14.6208 13.9326L18.3122 13.9473C19.8356 14.0254 20.4606 14.8799 20.1872 16.5107C20.07 17.2822 19.6989 17.9023 19.0739 18.3711C18.4489 18.8301 17.6676 19.0596 16.7301 19.0596L13.7272 19.0449ZM17.5505 10.5781L15.1921 10.5635L16.0563 5.6416L18.7223 5.65625C20.6169 5.66601 21.4469 6.47168 21.2126 8.07324C20.9489 9.69434 19.7282 10.5293 17.5505 10.5781ZM0 19.0532L0.893555 13.9409L4.58496 13.9556C6.1084 14.0337 6.7334 14.8882 6.45996 16.519C6.34277 17.2905 5.97168 17.9106 5.34668 18.3794C4.72168 18.8384 3.94043 19.0679 3.00293 19.0679L0 19.0532ZM3.82324 10.5864L1.46484 10.5718L2.3291 5.6499L4.99512 5.66455C6.88965 5.67432 7.71973 6.47998 7.48535 8.08154C7.22168 9.70264 6.00098 10.5376 3.82324 10.5864Z"
            fill="#A6AEC0"
          />
        </svg>
        <input
          type="number"
          class=" setting__add-input"
          v-model="womanSetting"
          ref="woman"
          min="-1000"
          required
        />
        <svg
          v-tooltip="'Баллов Skylove'"
          class="app-user__item-icon app-user__item-icon--small app-user__item-icon--slcoins"
          width="21"
          height="16"
          viewBox="0 0 27 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.80449 22.9982L2.8042 23L12.833 23L12.4997 25H16.4997L16.8333 22.9982C19.3028 22.9634 21.2558 22.4073 22.6921 21.3301C24.1862 20.2168 25.0016 18.6299 25.1383 16.5693C25.2067 15.4951 25.0065 14.5771 24.5378 13.8154C24.0788 13.0439 23.4342 12.5117 22.6042 12.2187C24.9186 11.2812 26.1491 9.68945 26.2956 7.44336C26.4128 5.62695 25.8122 4.21582 24.4938 3.20996C23.472 2.43032 22.0423 1.94989 20.2049 1.76868L20.4997 0H16.4997L16.219 1.68413L6.19193 1.67365L6.17609 1.76868C8.01349 1.94988 9.44313 2.43031 10.465 3.20996C11.7834 4.21582 12.384 5.62695 12.2668 7.44336C12.1203 9.68945 10.8898 11.2812 8.57536 12.2188C9.40544 12.5117 10.05 13.0439 10.509 13.8154C10.9777 14.5771 11.1779 15.4951 11.1095 16.5693C10.9728 18.6299 10.1574 20.2168 8.66325 21.3301C7.22694 22.4073 5.27402 22.9634 2.80449 22.9982ZM13.7272 19.0449L14.6208 13.9326L18.3122 13.9473C19.8356 14.0254 20.4606 14.8799 20.1872 16.5107C20.07 17.2822 19.6989 17.9023 19.0739 18.3711C18.4489 18.8301 17.6676 19.0596 16.7301 19.0596L13.7272 19.0449ZM17.5505 10.5781L15.1921 10.5635L16.0563 5.6416L18.7223 5.65625C20.6169 5.66601 21.4469 6.47168 21.2126 8.07324C20.9489 9.69434 19.7282 10.5293 17.5505 10.5781ZM0 19.0532L0.893555 13.9409L4.58496 13.9556C6.1084 14.0337 6.7334 14.8882 6.45996 16.519C6.34277 17.2905 5.97168 17.9106 5.34668 18.3794C4.72168 18.8384 3.94043 19.0679 3.00293 19.0679L0 19.0532ZM3.82324 10.5864L1.46484 10.5718L2.3291 5.6499L4.99512 5.66455C6.88965 5.67432 7.71973 6.47998 7.48535 8.08154C7.22168 9.70264 6.00098 10.5376 3.82324 10.5864Z"
            fill="#A6AEC0"
          />
        </svg>
      </div>
      <div class="setting__add-item">
        <button class="setting__delete-btn" @click.prevent="closeRow">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    <button
      v-if="!addOpen"
      class="setting__btn setting__btn--add"
      @click.prevent="addOpen = !addOpen"
      key="add"
    >
      <span class="setting__btn-text">Добавить</span>
      <i class="setting__btn-icon fa fa-plus"></i>
    </button>
    <button type="submit" v-else class="setting__btn setting__btn--save" key="save">
      <span class="setting__btn-text">Сохранить</span>
      <i class="setting__btn-icon fa fa-save"></i>
    </button>
  </form>
</template>

<script>
import { ADD_CODE_OPTION_POPUP_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_CREATE_PAYMENTS_SETTING } from '@/store/action-types'

export default {
  name: 'OperatorsSettingAdd',
  data() {
    return {
      ADD_CODE_OPTION_POPUP_EVENT,
      country_category: '',
      country_codes: [],
      female_point_price: null,
      male_point_price: null,
      coefficient: '',
      free_messages: null,
      meeting_minute_price: null,
      addOpen: false
    }
  },
  computed: {
    categoryCountry: {
      get() {
        return this.country_category
      },
      set(value) {
        this.$refs.category.value = value
        this.country_category = value
      }
    },

    meetingPrice: {
      get() {
        return this.meeting_minute_price
      },
      set(value) {
        this.meeting_minute_price = value
      }
    },

    coefficientSetting: {
      get() {
        return this.coefficient
      },
      set(value) {
        // value = value.replace(/[^\d.]/gi, "");
        // this.$refs.coefficient.value = value;
        this.coefficient = value
      }
    },
    manSetting: {
      get() {
        return this.male_point_price
      },
      set(value) {
        // this.$refs.man.value = value;
        this.male_point_price = value
      }
    },
    womanSetting: {
      get() {
        return this.female_point_price
      },
      set(value) {
        // this.$refs.woman.value = value;
        this.female_point_price = value
      }
    }
  },
  watch: {
    country_codes: function() {
      if (this.country_codes.length) {
        this.$refs.codes.style.borderColor = 'rgba(118, 118, 118, 0.3)'
      }
    }
  },
  methods: {
    ...mapActions({
      addPaymentSetting: REQUEST_CREATE_PAYMENTS_SETTING
    }),
    closeRow() {
      this.country_category = ''
      this.country_codes = []
      this.coefficient = ''
      this.female_point_price = null
      this.male_point_price = null
      this.addOpen = false
    },
    addSetting() {
      if (this.country_codes.length) {
        let newData = {
          country_category: this.country_category,
          country_codes: this.country_codes,
          coefficient: +this.coefficient,
          female_point_price: +this.female_point_price,
          male_point_price: +this.male_point_price,
          meeting_minute_price: +this.meeting_minute_price,
          free_messages: +this.free_messages
        }
        this.addPaymentSetting(newData)
        this.closeRow()
      } else {
        this.$refs.codes.style.borderColor = 'rgba(255, 0, 0, 0.6)'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'PaymentsSetting';
</style>
