import * as MutationTypes from './mutation-types'
export default {
  [MutationTypes.SET_DEFAULT_PAYMENT_MODERATOR_OPTION]: (state, payload) => {
    state.paymentDefaultSetting = payload.reverse()
  },

  [MutationTypes.SET_MODERATORS]: (state, payload) => {
    state.moderators = payload
  },

  [MutationTypes.SET_CURRENT_MODERATOR]: (state, payload) => {
    state.currentModerator = payload
  },

  [MutationTypes.SET_MODERATOR_STATISTIC]: (state, payload) => {
    state.moderatorStatistic = payload.reverse()
  },

  [MutationTypes.SET_MODERATOR_PAYOUT]: (state, payload) => {
    state.moderatorPayout = payload
  },

  [MutationTypes.SET_SEARCH_MODERATOR_OPTIONS]: (state, payload) => {
    state.searchOptions = payload
  },

  [MutationTypes.SET_ADD_MODERATOR_SEARCH_OPTIONS]: (state, payload) => {
    state.searchOptions.push(payload)
  },

  [MutationTypes.SET_EDIT_MODERATOR_SEARCH_OPTIONS]: (state, payload) => {
    let indexOption = state.searchOptions.findIndex(f => f === payload.old)
    state.searchOptions.splice(indexOption, 1, payload.new)
  },

  [MutationTypes.SET_DEL_MODERATOR_SEARCH_OPTIONS]: (state, payload) => {
    state.searchOptions = state.searchOptions.filter(f => f !== payload)
  },

  [MutationTypes.SET_MODERATOR_SETTINGS]: (state, payload) => {
    const { key, value } = payload
    const settingObj = {
      key,
      ...value
    }
    state.moderatorTmpRoles[key] = settingObj
  },
  [MutationTypes.SET_CLEAR_MODERATOR_DATA]: state => {
    for (const key in state.moderatorTmpRoles) {
      state.moderatorTmpRoles[key] = {}
    }
  }
}
