<template>
  <div class="ticket">
    <div class="ticket__field">
      <span class="ticket__mobtitle">Заголовок</span>
      <span class="ticket__text">{{ claim.title }}</span>
    </div>
    <div class="ticket__field">
      <span class="ticket__mobtitle">Сообщение</span>
      <span class="ticket__text">{{ claim.message }}</span>
    </div>
    <div class="ticket__field">
      <button
        class="ticket__item-btn ticket__item-btn--blue"
        @click="$root.$emit(CLAIM_EDIT_POPUP_EVENT, claim)"
      >
        <i class="ticket__item-btn-icon fa fa-edit"></i>
      </button>
      <button class="ticket__item-btn ticket__item-btn--red" @click="$root.$emit(CLAIM_DELETE_POPUP, claim)">
        <i class="ticket__item-btn-icon fa fa-times-circle"></i>
      </button>
    </div>
  </div>
</template>
<script>
import { CLAIM_DELETE_POPUP, CLAIM_EDIT_POPUP_EVENT } from '@/constants/event'
export default {
  data() {
    return {
      CLAIM_DELETE_POPUP,
      CLAIM_EDIT_POPUP_EVENT
    }
  },
  name: 'UserCliam',
  props: {
    claim: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
@import './User-claim.scss';
</style>
