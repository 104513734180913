<template>
  <Popup :visible="visible" @close="close" description="Удалить элемент?" modalClass="popup--small">
    <form @submit.prevent="sendContentHandler">
      <div class="popup__item">
        <div class="popup__btns popup__btns--center">
          <button class="popup__btn btn-green">Да</button>
          <button class="popup__btn btn-red" type="button" @click="close">
            Нет
          </button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import { mapActions } from 'vuex'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { FAQ_POPUP_REMOVE_ELEMENT_QUESTION } from '@/constants/event'

import { REQUEST_DATELE_QUESTION_BY_ID } from '@/store/action-types'

export default {
  name: 'FaqPopupRemoveElementQuestionById',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data() {
    return {
      objByDeleted: null
    }
  },

  mounted() {
    this.$root.$on(FAQ_POPUP_REMOVE_ELEMENT_QUESTION, id => {
      this.objByDeleted = id
      this.open()
    })
  },

  computed: {},

  methods: {
    closeModal() {
      this.close()
    },

    ...mapActions({
      deleteQuestionById: REQUEST_DATELE_QUESTION_BY_ID
    }),
    sendContentHandler() {
      this.deleteQuestionById(this.objByDeleted)
      this.objByDeleted = null
      this.close()
    }
  }
}
</script>

<style lang="scss">
@import './FaqPopupStyle';
</style>
