const SET_APPS_FLYER = 'SET_APPS_FLYER'
const SET_REQUEST_APPS_FLYER_IOS = 'SET_REQUEST_APPS_FLYER_IOS'
const SET_USERS_ACTIVITY = 'SET_USERS_ACTIVITY'
const SET_USERS_COUNT = 'SET_USERS_COUNT'
const SET_USERS_ONLINE_BY_CITY = 'SET_USERS_ONLINE_BY_CITY'
const SET_PURCHASES_MARKET_PLACES = 'SET_PURCHASES_MARKET_PLACES'
const SET_PURCHASES_BY_CITY = 'SET_PURCHASES_BY_CITY'
const SET_PURCHASES_BY_COUNTRY = 'SET_PURCHASES_BY_COUNTRY'
const SET_PURCHASES_BY_TIME = 'SET_PURCHASES_BY_TIME'
const SET_PURCHASES_BY_PAYERS_PAYMENTS = 'SET_PURCHASES_BY_PAYERS_PAYMENTS'
const SET_USERS_ONLINE_ONE_DAY = 'SET_USERS_ONLINE_ONE_DAY'
const SET_USERS_ONLINE_ONE_DAY_SITE_AND_APP = 'SET_USERS_ONLINE_ONE_DAY_SITE_AND_APP'
const SET_NEW_USER_ACTIVE_BY_DATE = 'SET_NEW_USER_ACTIVE_BY_DATE'
const SET_MODERATORS_PHOTO_CHECK = 'SET_MODERATORS_PHOTO_CHECK'
const SET_USERS_GENDER = 'SET_USERS_GENDER'
const SET_NEW_USER_ACTIVE = 'SET_NEW_USER_ACTIVE'
const SET_USERS_PAYING = 'SET_USERS_PAYING'
const SET_USERS_AGE = 'SET_USERS_AGE'
const SET_USER_LIFETIME = 'SET_USER_LIFETIME'
const SET_REGS_NEW_USERS = 'SET_REGS_NEW_USERS'
const SET_ROYAL_USERS_INSTALL = 'SET_ROYAL_USERS_INSTALL'
const SET_ROYAL_USERS_INSTALL_IOS = 'SET_ROYAL_USERS_INSTALL_IOS'
const SET_OPERATORS_MESSAGE = 'SET_OPERATORS_MESSAGE'
const SET_OPERATORS_CASHES = 'SET_OPERATORS_CASHES'
const SET_PURCHASE_TOTAL = 'SET_PURCHASE_TOTAL'
const SET_MODERATOTS_VIDEO_CHECK = 'SET_MODERATOTS_VIDEO_CHECK'
const SET_PURCHASES_POINTS = 'SET_PURCHASES_POINTS'
const SET_PURCHASES_VIP_STATUS = 'SET_PURCHASES_VIP_STATUS'
const SET_PURCHASES_BY_DATE = 'SET_PURCHASES_BY_DATE'
const SET_COUNTERS_MESSAGES_OPERATORS = 'SET_COUNTERS_MESSAGES_OPERATORS'
const SET_STATISTICS_PROFILE_MESSAGES = 'SET_STATISTICS_PROFILE_MESSAGES'
const SET_SEASON_OPERATOR = 'SET_SEASON_OPERATOR'
const SET_STATISTICS_PROFILE_MESSAGES_PAGINATION = 'SET_STATISTICS_PROFILE_MESSAGES_PAGINATION'

export {
  SET_APPS_FLYER,
  SET_REQUEST_APPS_FLYER_IOS,
  SET_PURCHASES_POINTS,
  SET_USERS_ACTIVITY,
  SET_USERS_COUNT,
  SET_PURCHASES_BY_DATE,
  SET_PURCHASE_TOTAL,
  SET_OPERATORS_MESSAGE,
  SET_OPERATORS_CASHES,
  SET_USERS_ONLINE_BY_CITY,
  SET_COUNTERS_MESSAGES_OPERATORS,
  SET_STATISTICS_PROFILE_MESSAGES,
  SET_PURCHASES_MARKET_PLACES,
  SET_PURCHASES_BY_CITY,
  SET_PURCHASES_BY_COUNTRY,
  SET_PURCHASES_BY_TIME,
  SET_PURCHASES_BY_PAYERS_PAYMENTS,
  SET_MODERATOTS_VIDEO_CHECK,
  SET_USERS_ONLINE_ONE_DAY,
  SET_PURCHASES_VIP_STATUS,
  SET_NEW_USER_ACTIVE_BY_DATE,
  SET_MODERATORS_PHOTO_CHECK,
  SET_USERS_GENDER,
  SET_NEW_USER_ACTIVE,
  SET_USERS_PAYING,
  SET_USERS_ONLINE_ONE_DAY_SITE_AND_APP,
  SET_STATISTICS_PROFILE_MESSAGES_PAGINATION,
  SET_USERS_AGE,
  SET_USER_LIFETIME,
  SET_REGS_NEW_USERS,
  SET_ROYAL_USERS_INSTALL,
  SET_ROYAL_USERS_INSTALL_IOS,
  SET_SEASON_OPERATOR
}
