import Request from '../helpers/Request'
import BaseService from './BaseService'

class AutoMessagesListService extends BaseService {
  constructor(token) {
    super(token)
  }

  async getAllAutoMessages(formData) {
    const getAllAutoMessagesUrl = `/api/v1/auto-messages/`
    const headers = {
      Authorization: `Bearer ${this.userToken}`
    }

    Object.keys(formData).forEach(
      key =>
        (formData[key] === '' || formData[key] === undefined || formData[key] === 'Неважно') &&
        delete formData[key]
    )

    return await Request.get(getAllAutoMessagesUrl, { headers, params: formData })
  }
}

export default AutoMessagesListService
