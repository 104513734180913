<template>
  <li class="operator-payments__table-row">
    <div class="operator-payments__table-item">
      {{ toLocaleDateUserWithoutTime(item.payout_period_from) }}
      {{ item.payout_period_to ? `- ${toLocaleDateUserWithoutTime(item.payout_period_to)}` : '' }}
    </div>
    <div class="operator-payments__table-item">
      {{ item.stats ? item.stats.in : item.in_messages }}
    </div>
    <div class="operator-payments__table-item">
      {{ item.stats ? item.stats.average_time : item.average_time }}
    </div>
    <div class="operator-payments__table-item">
      {{ item.stats ? item.stats.meeting_duration : item.meeting_duration }}
    </div>
    <div class="operator-payments__table-item">
      {{ item.stats ? item.stats.meeting_sum : item.meeting_sum }} {{ moneyIcon(item.currency) }}
    </div>
    <div class="operator-payments__table-item operator-payments__table-item--award">
      <span class="operator-payments__item-text operator-payments__award-text"
        >{{ item.prize_sum }} {{ moneyIcon(item.currency) }}</span
      >
      <div
        v-if="item.payout_period_to && !item.payout_date && item.prize_sum === 0"
        class="operator-payments__award-btn operator-payments__award-btn--active"
      >
        <button
          @click="
            $root.$emit(PRIZE_OPERATOR_POPUP_EVENT, {
              operator: operator.id,
              pay: item.pay_id
            })
          "
          class="operators-payments__btn operators-payments__btn--award"
        >
          <i class="fas fa-coins"></i>
        </button>
      </div>
      <div
        v-if="item.prize_sum"
        class="operator-payments__award-btn operator-payments__award-btn--active operator-payments__award-btn--info"
        v-tooltip="`Описание: ${item.prize_description ? item.prize_description : '-'}`"
      >
        <span>
          <i class="fa fa-info"></i>
        </span>
      </div>
    </div>
    <div class="operator-payments__table-item">
      {{ item.stats ? item.stats.pay : item.pay_sum }}
      {{ moneyIcon(item.currency) }}
    </div>
    <div class="operator-payments__table-item operator-payments__table-item--withbtn">
      <span class="operator-payments__item-text">{{
        item.payout_date ? toLocaleDateUserWithoutTime(item.payout_date) : 'Нет'
      }}</span>
      <button
        v-if="!item.payout_period_to"
        @click="
          $root.$emit(BILL_OPERATOR_POPUP_EVENT, {
            operator: operator.id,
            pay: item.pay_id
          })
        "
        class="operators-payments__btn operators-payments__btn--pay"
      >
        <i class="fas fa-dollar-sign"></i>
        <span> Рассчитать</span>
      </button>
      <button
        v-if="item.payout_period_to && !item.payout_date"
        @click="
          $root.$emit(PAY_OPERATOR_POPUP_EVENT, {
            operator: operator.id,
            pay: item.pay_id
          })
        "
        class="operators-payments__btn operators-payments__btn--pay"
      >
        <i class="fas fa-dollar-sign"></i>
        <span> Оплата</span>
      </button>
      <div
        v-if="item.transaction_status !== null"
        v-tooltip="statusText(item.transaction_status)"
        class="operator-payments__status-text"
      >
        <i
          :class="{
            'fas fa-exclamation-circle': item.transaction_status === 0,
            'fa fa-ban': item.transaction_status === 3,
            'fa fa-check': item.transaction_status === 2,
            'fa fa-exclamation-triangle': item.transaction_status === 4,
            'fa fa-spinner fa-spin': item.transaction_status === 1
          }"
        ></i>
      </div>
    </div>
  </li>
</template>

<script>
import { mapActions } from 'vuex'
import getTransactionStatus from '@/components/mixins/getTransactionStatus'
import getIconCurrency from '@/components/mixins/getIconCurrency'
import { REQUEST_BILLING_OPERATORS } from '@/store/action-types'
import {
  BILL_OPERATOR_POPUP_EVENT,
  PRIZE_OPERATOR_POPUP_EVENT,
  PAY_OPERATOR_POPUP_EVENT
} from '@/constants/event'
import toLocaleDateUserWithoutTime from '@/components/mixins/toLocaleformat'
export default {
  name: 'OperatorPaymentsItem',
  props: {
    operator: {
      type: Object
    },
    item: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  data: () => ({
    BILL_OPERATOR_POPUP_EVENT,
    PRIZE_OPERATOR_POPUP_EVENT,
    PAY_OPERATOR_POPUP_EVENT,
    nightShift: false
  }),
  mixins: [getTransactionStatus, getIconCurrency, toLocaleDateUserWithoutTime],
  methods: {
    ...mapActions({
      bill: REQUEST_BILLING_OPERATORS
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'OperatorsPayments';
</style>
