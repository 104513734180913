const REQUEST_ALL_CATEGORY_BY_LANG = 'REQUEST_ALL_CATEGORY_BY_LANG'
const REQUEST_CONTENT_BY_ID = 'REQUEST_CONTENT_BY_ID'
const REQUEST_DELETE_CATEGORY_BY_ID = 'REQUEST_DELETE_CATEGORY_BY_ID'
const REQUEST_CREATE_NEW_CATEGORY = 'REQUEST_CREATE_NEW_CATEGORY'
const REQUEST_CATEGORY_BY_CURRENT_LANG = 'REQUEST_CATEGORY_BY_CURRENT_LANG'
const REQUEST_DATELE_QUESTION_BY_ID = 'REQUEST_DATELE_QUESTION_BY_ID'
const REQUEST_UPDATE_QUESTION_BY_ID = 'REQUEST_UPDATE_QUESTION_BY_ID'
const REQUEST_UPDATE_CATEGORY_BY_ID = 'REQUEST_UPDATE_CATEGORY_BY_ID'

// Content
const REQUEST_ADD_NEW_CONTENT_BY_CATEGORY = 'REQUEST_ADD_NEW_CONTENT_BY_CATEGORY'

export {
  REQUEST_ALL_CATEGORY_BY_LANG,
  REQUEST_CONTENT_BY_ID,
  REQUEST_DELETE_CATEGORY_BY_ID,
  REQUEST_CREATE_NEW_CATEGORY,
  REQUEST_CATEGORY_BY_CURRENT_LANG,
  REQUEST_ADD_NEW_CONTENT_BY_CATEGORY,
  REQUEST_DATELE_QUESTION_BY_ID,
  REQUEST_UPDATE_QUESTION_BY_ID,
  REQUEST_UPDATE_CATEGORY_BY_ID
}
