<template>
  <div class="control-staff-wrap">
    <Filters />
    <EmployeeTableHeader />
    <div class="control-staff-list" v-if="employees.length && isLoaded">
      <Employee v-for="employee in employees" :key="employee.id" :employee="employee" />
    </div>
    <CreateEmployeePopup />
    <EditEmployeePopup />
    <DeleteEmployeePopup />
    <EmailErrorPopup />
    <div class="control-staff-list control-staff-list--error" v-if="!employees.length && isLoaded">
      <span class="control-staff-list__text">Нет данных...</span>
    </div>
    <div v-if="errorMessage" class="control-staff-list control-staff-list--error">
      <span class="control-staff-list__text">Ошибка при загрузке сотрудников</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { REQUEST_EMPLOYEE } from '@/store/action-types'
import { GET_EPLOYEES } from '@/store/getter-types'
import EmployeeTableHeader from '@/components/Controls/Employee/TableHeader/EmployeeTableHeader'
import Filters from '@/components/Controls/Employee/Filters/Filters'
import Employee from '@/components/Controls/Employee/Employee'
import CreateEmployeePopup from '@/components/Modals/EmployeePopup/NewEmployeePopup'
import EditEmployeePopup from '@/components/Modals/EmployeePopup/EditEmployeePopup'
import DeleteEmployeePopup from '@/components/Modals/EmployeePopup/DeleteEmployeePopup'
import store from '@/store'
import EmailErrorPopup from '../../components/Modals/EmployeePopup/EmailErrorPopup'
export default {
  name: 'managementEmployee',
  components: {
    EmailErrorPopup,
    EmployeeTableHeader,
    Filters,
    Employee,
    CreateEmployeePopup,
    EditEmployeePopup,
    DeleteEmployeePopup
  },

  data: () => ({
    errorMessage: false,
    isLoaded: false
  }),

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_EMPLOYEE).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  },

  computed: {
    ...mapGetters({
      employees: GET_EPLOYEES
    })
  },
  mounted() {
    this.$root.$emit('routeName', this.$route.name)
    // this.$root.$on('filtersHeight', h => {
    //   const margin = `${h + 34}` + 'px'
    //   document.querySelector('.control-staff-list').style.marginTop = margin
    //   document.querySelector('.simplebar-vertical').style.marginTop = `${h + 50}` + 'px'
    // })
  }
}
</script>

<style lang="scss" scoped>
.control-staff {
  &-wrap {
    padding: 16px;
  }
  &-list {
    position: relative;
    margin-top: 25px;
    @media (max-width: 1451px) {
      margin-top: 165px;
    }
    @media (max-width: 1343px) {
      margin-top: 192px;
    }
    @media (max-width: 1020px) {
      margin-top: 70px;
    }
    &__text {
      margin: 0 auto;
    }
  }

  &-list--error {
    position: relative;
    display: flex;
    min-height: 64px;
    align-items: center;
    background-color: white;
    // top: 128px;
    @media (max-width: 1020px) {
      margin-top: 70px;
    }
  }
}
</style>
