<template>
  <Popup :visible="visible" @close="closePopup" description="Начислить премию модераторам?" modalClass="">
    <div class="popup__item">
      <span>Выберите модераторов из списка:</span>
      <div class="popup__item-wraper" v-if="noPrizePays.length">
        <button class="popup__btn popup__btn-edit" @click.prevent="selectAllModerators()">
          Выбрать всех
        </button>
        <ul class="popup__moderator-list">
          <li class="popup__moderator-item" v-for="moderator in noPrizePays" :key="moderator.id">
            <input
              class="popup__moderator-checkbox"
              type="checkbox"
              :id="moderator.id"
              :value="moderator.id"
              v-model="checkedModerators"
            />
            <label :for="moderator.id"
              >{{ moderator.first_name }} {{ moderator.last_name }}, c
              {{ toLocaleDateUserWithoutTime(moderator.payout_period_from) }} по
              {{ toLocaleDateUserWithoutTime(moderator.payout_period_to) }}</label
            >
          </li>
        </ul>
      </div>
      <span v-else class="popup__moderator-list popup__item--error"
        >Нет подходящих модераторов для начисления премии</span
      >
      <form @submit.prevent="prizeHandler" class="popup__form">
        <div class="popup__form-item">
          <label for="achievement-name">Премия(%):</label>
          <input required id="achievement-name" class="popup__item-date" type="number" v-model="percentage" />
        </div>
        <div class="">
          <label for="achievement-name">Описание:</label>
          <input class="popup__item-date" type="text" v-model="description" />
        </div>
        <div class="popup__btns popup__btns--center">
          <button class="popup__btn btn-green" type="submit">
            Да
          </button>
          <button class="popup__btn btn-red" @click.prevent="closePopup">
            Нет
          </button>
        </div>
      </form>
      <span class="popup__item--error" v-if="isError">Необходимо выбрать модератора</span>
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { REQUEST_PRIZE_MODERATORS } from '@/store/action-types'
import { GET_MODERATOR_TMP_ROLES, GET_MODERATOR_PAYOUT } from '@/store/getter-types'
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { PRIZE_ALL_MODERATOR_POPUP_EVENT } from '@/constants/event'
import toLocaleDateTime from '@/components/mixins/toLocaleformat'

export default {
  name: 'PrizeAllModeratorPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin, toLocaleDateTime],

  data: () => ({
    PRIZE_ALL_MODERATOR_POPUP_EVENT,
    checkedModerators: [],
    description: null,
    percentage: null,
    isError: false
  }),
  computed: {
    ...mapGetters({
      tmpFilter: GET_MODERATOR_TMP_ROLES,
      moderators: GET_MODERATOR_PAYOUT
    }),
    noPrizePays() {
      let tableHeaders = []
      this.moderators.forEach(f => {
        f.pays.forEach(m => {
          if (m.settlement_date && !m.prize_sum && !m.payout_date)
            tableHeaders.push({
              id: m.id,
              first_name: f.first_name,
              last_name: f.last_name,
              payout_period_from: m.payout_period_from,
              payout_period_to: m.payout_period_to
            })
        })
      })
      return tableHeaders
    }
  },
  watch: {
    checkedModerators: function() {
      if (this.checkedModerators.length) this.isError = false
    }
  },

  methods: {
    ...mapActions({
      prizeModerator: REQUEST_PRIZE_MODERATORS
    }),
    closePopup() {
      this.checkedModerators = []
      this.percentage = null
      this.description = null
      this.isError = false
      this.close()
    },
    selectAllModerators() {
      this.checkedModerators = []
      this.noPrizePays.forEach(f => {
        this.checkedModerators.push(f.id)
      })
    },
    prizeHandler() {
      if (this.checkedModerators.length) {
        let formData = {
          data: {
            prize_many: {
              percentage: +this.percentage,
              prize_description: this.description,
              pay_ids: this.checkedModerators
            }
          },
          active: null
        }
        if (this.tmpFilter.status.name && this.tmpFilter.status.name !== 'Все') {
          formData.active = {
            is_active: this.tmpFilter.status.value
          }
        }
        this.prizeModerator(formData)
        this.closePopup()
      } else {
        this.isError = true
      }
    }
  },

  mounted() {
    this.$root.$on(PRIZE_ALL_MODERATOR_POPUP_EVENT, () => {
      this.open()
    })
  }
}
</script>
<style lang="scss">
@import 'SettingsPayment';
</style>
