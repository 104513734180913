<template>
  <div class="ul_list" v-if="online && isOnline">
    <li
      class="list-item"
      v-show="
        operator.counters['Автосообщения'] || operator.counters['Входящие'] || operator.counters['Исходящие']
      "
    >
      <div class="list-item__avatar">
        <div class="list-item__wrapp">
          <div class="list-item__wrapp__name">
            <div class="operator__field operator__field--online">
              <div v-if="operatorInShift === 'Online'" class="operator__online">
                <div class="operator__online-inner"></div>
              </div>
            </div>
            <img
              class="list-item__wrapp__operator_avatar"
              v-if="operator.avatar"
              :src="getFile({ type: 'image', file: operator.avatar })"
              alt=""
            />
            <img v-else src="@/assets/img/anonymous_user.png" alt="" />
            <p class="list-item__wrapp-name">{{ operator.first_name }} {{ operator.last_name }}</p>
          </div>
          <div class="list-item__wrapp-counters">
            <FaIcon :iconClass="'sublist-item__chats far fa-list-alt'" v-tooltip="'Диалоги'">
              {{ operator.counters['Диалоги'] }}
            </FaIcon>
            <FaIcon :iconClass="'sublist-item__automessage fas fa-robot'" v-tooltip="'Автосообщения'">
              {{ operator.counters['Автосообщения'] }}
            </FaIcon>
            <FaIcon :iconClass="'sublist-item__outgoing fas fas fa-arrow-down'" v-tooltip="'Входящие'">
              {{ operator.counters['Входящие'] }}
            </FaIcon>
            <FaIcon :iconClass="'sublist-item__incoming fas fas fa-arrow-up'" v-tooltip="'Исходящие'">
              {{ operator.counters['Исходящие'] }}
            </FaIcon>
          </div>
        </div>
        <div>
          <button
            class="btn_stats"
            v-if="!isOpen && operator.accounts.length !== 0"
            key="open"
            @click="isOpen = !isOpen"
            title="Показать"
          >
            <i class="fa fa-angle-up"></i>
          </button>
          <button class="btn_stats" v-else key="close" @click="isOpen = !isOpen" title="Скрыть">
            <i class="fa fa-angle-down"></i>
          </button>
        </div>
      </div>
      <transition name="slide">
        <ul class="sublist" v-if="isOpen">
          <OperatorAccountItem
            v-for="item in operator.accounts"
            :key="item.id"
            :account="item"
            :operID="operator.id"
            :counter="counter"
            :date="date"
          />
        </ul>
      </transition>
    </li>
  </div>
</template>
<script>
import OperatorAccountItem from '@/views/operators/operatorAccountItem'
import getImgLink from '@/components/mixins/getImgCdnLink'
import { mapGetters } from 'vuex'
import { GET_ACTIVE_OPERATOR } from '@/store/getter-types'

export default {
  name: 'operatorMain',
  components: {
    OperatorAccountItem
  },
  props: {
    operator: {
      type: Object
    },
    openOper: {
      type: Boolean
    },
    counter: {
      type: Object
    },
    date: {
      type: Object
    },
    online: {
      type: Boolean
    },
    isOnline: {
      type: Boolean
    },
    status: {
      type: String
    }
  },
  data() {
    return {
      isOpen: this.openOper,
      automessage: ''
    }
  },
  computed: {
    ...mapGetters({
      active: GET_ACTIVE_OPERATOR
    }),
    operatorInShift: function() {
      return this.online ? 'Online' : 'Offline'
    }
  },
  watch: {
    openOper: function(newVal, oldVal) {
      if (this.status === 'all-day') {
        for (let account of this.operator.accounts) {
          if (account.id === this.active) {
            return (this.isOpen = newVal)
          } else {
            return (this.isOpen = newVal)
          }
        }
      } else if (this.status !== 'all-day') {
        return (this.isOpen = true)
      }
    }
  },
  mixins: [getImgLink],
  methods: {
    dropIt() {
      this.isDropped = !this.isDropped
    },
    accord(el) {
      el = !el
    },
    fullName(name, last_name) {
      return `${name} ${last_name}`
    },
    findCounterAutoMessage() {
      for (let el in this.counter) {
        if (this.operator.id === el) {
          this.automessage = this.counter[el]['Автосообщения']
          return this.counter[el]['Автосообщения']
        }
      }
    },
    findCounterIncoming() {
      for (let el in this.counter) {
        if (this.operator.id === el) {
          return this.counter[el]['Входящие']
        }
      }
    },
    findCounterOutgoing() {
      for (let el in this.counter) {
        if (this.operator.id === el) {
          return this.counter[el]['Исходящие']
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.operator__online {
  position: absolute;
  left: 0px;
  bottom: 13px;
}
.operator__field--online {
  position: relative;
}
.operator__online-inner {
  min-width: 14px;
  width: 14px;
  height: 14px;
  border: 4px solid transparent;
  border-radius: 50%;
  border-color: #78cd51;
  background-color: #78cd51;
}
.badge {
  position: absolute;
  top: 2px;
  right: -43px;
  padding: 2px 7px 2px 6px;
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  color: #fff;
  background-color: #ff6c60;
  border-radius: 10px;
}
.list-item__wrapp {
  &__name {
    display: flex;
    align-items: center;
  }
  &-counters {
    display: flex;
    width: 47%;
    justify-content: flex-end;
    margin-right: 8px;
    span {
      margin: 0 6px;
    }
  }
  &__operator_avatar {
    border-radius: 50px;
  }
}
</style>
