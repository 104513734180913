<template>
  <Popup :visible="visible" @close="close" description="Добавить баллы" modalClass="popup--small">
    <form @submit.prevent="addScoreHandler">
      <div class="popup__item">
        <span class="popup__item-title">Количество баллов</span>
        <input
          type="text"
          class="popup__input default-input"
          ref="scorevalue"
          v-model="scoreValue"
          required
        />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--right">
          <button type="submit" class="popup__btn btn-green">Добавить</button>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { USER_SCORE_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'
import { REQUEST_UPDATE_POINTS, REQUEST_USER_TRANSACTION_KIND } from '@/store/action-types'

export default {
  name: 'UserPointsPopup',
  components: {
    Popup
  },
  mixins: [ModalMixin],

  data: () => ({
    score: '',
    id: ''
  }),

  computed: {
    scoreValue: {
      get() {
        return this.scrore
      },

      set(n) {
        n = n.replace(/[^\d-]/g, '')
        this.$refs.scorevalue.value = n
        this.score = n
      }
    }
  },

  methods: {
    ...mapActions({
      updatePoints: REQUEST_UPDATE_POINTS,
      updateTransactionList: REQUEST_USER_TRANSACTION_KIND
    }),
    addScoreHandler() {
      const payload = {
        user_id: this.id,
        points: +this.score
      }
      this.updatePoints(payload).then(res => {
        if (res.status) this.updateTransactionList(payload.user_id)
      })
      this.score = ''
      this.id = ''
      this.close()
    }
  },

  mounted() {
    this.$root.$on(USER_SCORE_EVENT, user => {
      this.open()
      this.id = user.id
    })
  }
}
</script>
