<template>
  <div class="view-control__header">
    <h1 class="view-control__header--title">
      Управление первым экраном после регистрации
    </h1>
    <button class="view-control__header--button" @click="$root.$emit(VIEW_ADD_POPUP_RECORD)">
      <span>Добавить новую запись</span>
    </button>
  </div>
</template>
<script>
import { VIEW_ADD_POPUP_RECORD } from '@/constants/event'
export default {
  name: 'ViewControl',
  data: () => ({
    VIEW_ADD_POPUP_RECORD
  })
}
</script>
