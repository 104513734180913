<template>
  <Popup
    :visible="visible"
    @close="closePopup"
    title=""
    modalClass="popup--medium popup--employee popup--scroll"
  >
    <form class="employee-profile" @submit.prevent="employeeSubmitHandler" v-if="employee">
      <div class="employee-profile__header">
        <span class="employee-profile__name">{{ employee.first_name }} {{ employee.last_name }}</span>
        <span class="employee-profile__email">{{ employee.email }}</span>
        <div class="employee-profile__avatar-wrap">
          <div class="employee-profile__avatar">
            <img v-if="isAvatar" :src="preview" alt="" />
            <img
              v-else-if="employee.avatar"
              :src="
                getFile({
                  type: 'image',
                  file: employee.avatar,
                  width: `128`,
                  height: `128`
                })
              "
              class="chat__message-body--img"
              alt=""
            />
            <img v-else src="@/assets/img/anonymous_user.png" alt="" />
          </div>
          <div class="employee-profile__avatar-edit">
            <input
              class="employee-profile__avatar-edit-input"
              type="file"
              id="avatar-input"
              accept=".jpg, .jpeg, .png"
              ref="downloadavatar"
              @change="fileUploadHandler"
            />
            <label class="employee-profile__avatar-edit-label" for="avatar-input">
              <i class="fa fa-pen"></i>
            </label>
          </div>
        </div>
      </div>
      <div class="employee-profile__edit">
        <div class="employee-profile__edit-body">
          <div class="employee-profile__edit-group">
            <label for="employee-name" class="employee-profile__edit-text">Имя</label>
            <input
              id="employee-name"
              type="text"
              class="employee-profile__edit-input"
              v-model="employeeName"
              ref="employeename"
              required
            />
          </div>
          <div class="employee-profile__edit-group">
            <label for="employee-lastname" class="employee-profile__edit-text">Фамилия</label>
            <input
              id="employee-lastname"
              type="text"
              class="employee-profile__edit-input"
              v-model="employeeLastname"
              ref="employeelastname"
              required
            />
          </div>
          <div class="employee-profile__edit-group">
            <label for="employee-email" class="employee-profile__edit-text">Email</label>
            <input
              id="employee-email"
              type="email"
              class="employee-profile__edit-input"
              v-model="employeeEmail"
              ref="employeeemail"
              readonly
              onfocus="if (this.hasAttribute('readonly')) { this.removeAttribute('readonly'); this.blur(); this.focus();}"
              autocomplete="off"
              required
            />
          </div>
          <div class="employee-profile__edit-group">
            <label for="employee-password" class="employee-profile__edit-text">Пароль</label>
            <div class="employee-password__wrapper" v-if="!viewPassword">
              <input
                id="employee-password"
                type="password"
                class="employee-profile__edit-input"
                v-model="employeePassword"
                readonly
                onfocus="if (this.hasAttribute('readonly')) { this.removeAttribute('readonly'); this.blur(); this.focus();}"
                autocomplete="new-password"
                ref="employeepassword"
              />
              <span @click="viewPassword = !viewPassword" class="employee-password__icon"
                ><i class="fa fa-eye"></i
              ></span>
            </div>
            <div class="employee-password__wrapper" v-if="viewPassword">
              <input
                id="employee-password"
                type="text"
                class="employee-profile__edit-input"
                v-model="employeePassword"
                readonly
                onfocus="if (this.hasAttribute('readonly')) { this.removeAttribute('readonly'); this.blur(); this.focus();}"
                autocomplete="new-password"
                ref="employeepassword"
              />
              <span @click="viewPassword = !viewPassword" class="employee-password__icon"
                ><i class="fa fa-eye"></i
              ></span>
            </div>
          </div>
          <div class="employee-profile__edit-group">
            <span class="employee-profile__edit-select-title">Должность</span>
            <div class="employee-profile__edit-select">
              <button
                type="button"
                class="employee-profile__edit-value"
                @click="positionOptions = !positionOptions"
              >
                <span class="employee-profile__edit-value-text">{{
                  defaultEmployeePosition({ key: 'position' })
                }}</span>
                <i class="fa fa-angle-down employee-profile__edit-select-icon"></i>
              </button>
              <ul
                class="employee-profile__edit-select-list"
                v-if="positionOptions"
                @mouseleave="positionOptions = !positionOptions"
                :class="{ active: positionOptions }"
              >
                <li
                  v-for="position in filteredPositions"
                  :key="position.id"
                  class="employee-profile__edit-select-item"
                  @click="selectItemHandler({ key: 'position', value: position })"
                >
                  <span class="employee-profile__edit-select-text">{{ position.position_name }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="checkadmin" class="employee-profile__edit-group employee-profile__edit-group--togle">
            <span class="employee-profile__edit-text">Ночная смена</span>
            <label class="toggle">
              <input class="toggle__checkbox" type="checkbox" v-model="night_shift" />
              <span class="toggle__toggler"></span>
            </label>
          </div>
          <div v-if="checkadmin" class="employee-profile__edit-group employee-profile__edit-group--togle">
            <span class="employee-profile__edit-text">{{ isActiveEmployee }}</span>
            <label class="toggle">
              <input class="toggle__checkbox" type="checkbox" v-model="employeeData.is_active_employee" />
              <span class="toggle__toggler"></span>
            </label>
          </div>
          <div class="employee-profile__edit-group employee-profile__edit-group--btns" v-if="checkadmin">
            <button
              type="submit"
              class="employee-profile__edit-action-btn employee-profile__edit-action-btn--save"
            >
              <span class="employee-profile__edit-action-btn-text">Изменить</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </Popup>
</template>
<script>
import {
  GET_EMPLOYEE_ROLES,
  GET_EMPLOYEE_TMP_ROLES,
  GET_USER,
  GET_CURRENT_EMPLOYEE,
  GET_EMPLOYEE_POSITIONS,
  GET_TMP_FILTER_INFO_ROLES
} from '@/store/getter-types'
import {
  SET_TMP_ROLE_EMPLOYEE,
  SET_TMP_ROLE_EMPLOYEE_RESET,
  SET_CURRENT_EMPLOYEE,
  SET_UPDATE_EMPLOYEE_FIELD
} from '@/store/mutation-types'
import { REQUEST_EDIT_EMPLOYEE, REQUEST_CHECK_EMAIL } from '@/store/action-types'

import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import { EDIT_EMPLOYEE_POPUP_EVENT, EMAIL_ERROR_POPUP } from '@/constants/event'
import getImgLink from '@/components/mixins/getImgCdnLink'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Permissions from '@/constants/permissions.js'

export default {
  name: 'EditEmployeePopup',
  components: {
    Popup
  },
  mixins: [ModalMixin, getImgLink],

  data: () => ({
    night_shift: false,
    preview: null,
    isAvatar: false,
    positionOptions: false,
    viewPassword: false,
    isModerator: false,
    // checkPosition: null,
    employeeData: {
      file: null,
      email: null,
      first_name: null,
      last_name: null,
      password: null,
      position: null,
      is_active_employee: null
    },
    EMAIL_ERROR_POPUP
  }),
  computed: {
    ...mapGetters({
      employeeRoles: GET_EMPLOYEE_ROLES,
      employeePositions: GET_EMPLOYEE_POSITIONS,
      employeeRolesDefault: GET_TMP_FILTER_INFO_ROLES,
      employeeTmpRoles: GET_EMPLOYEE_TMP_ROLES,
      user: GET_USER,
      employee: GET_CURRENT_EMPLOYEE
    }),

    filteredPositions() {
      return this.employeePositions.filter(el => el.position_name !== 'Неважно')
    },

    isActiveEmployee() {
      return this.employeeData.is_active_employee ? 'Сотрудник активен' : 'Сотрудник неактивен'
    },

    employeeEmail: {
      get() {
        return this.employeeData.email || this.employeeData.email === ''
          ? this.employeeData.email
          : this.employee.email
      },
      set(email) {
        this.$refs.employeeemail.value = email
        this.employeeData.email = email
      }
    },
    employeeName: {
      get() {
        return this.employeeData.first_name || this.employeeData.first_name === ''
          ? this.employeeData.first_name
          : this.employee.first_name
      },
      set(name) {
        name = name.replace(/\d/g, '')
        this.$refs.employeename.value = name
        this.employeeData.first_name = name
      }
    },

    employeeLastname: {
      get() {
        return this.employeeData.last_name || this.employeeData.last_name === ''
          ? this.employeeData.last_name
          : this.employee.last_name
      },
      set(lastname) {
        lastname = lastname.replace(/\d/g, '')
        this.$refs.employeelastname.value = lastname
        this.employeeData.last_name = lastname
      }
    },

    employeePassword: {
      get() {
        return this.employeeData.password
      },
      set(password) {
        this.$refs.employeepassword.value = password
        this.employeeData.password = password
      }
    },

    checkadmin() {
      const check = this.user.permissions.includes(Permissions.ManagementEmployees)
      return check
    }
  },
  // watch: {
  //   checkPosition() {
  //     if (
  //       this.defaultEmployeeValue({ key: 'position' }) === `605852be8e3f5266db67597c` ||
  //       this.defaultEmployeeValue({ key: 'position' }) === `605852be8e3f5266db67597d`
  //     ) {
  //       this.isModerator = true
  //     } else {
  //       this.isModerator = false
  //     }
  //   }
  // },
  beforeDestroy() {
    this.closePopup()
  },
  mounted() {
    this.$root.$on(EDIT_EMPLOYEE_POPUP_EVENT, employee => {
      this.setcurrentEmpoloyee(employee)
      this.night_shift = employee.is_night_shift
      this.employeeData.is_active_employee = employee.is_active
      this.employeeData.position = employee.position
      // if (
      //   this.defaultEmployeeValue({ key: 'position' }) === `605852be8e3f5266db67597c` ||
      //   this.defaultEmployeeValue({ key: 'position' }) === `605852be8e3f5266db67597d`
      // ) {
      //   this.isModerator = true
      // }
      this.open()
    })
  },

  methods: {
    ...mapMutations({
      // addTmpEmployeeSettings: SET_EMPLOYEE_SETTINGS,
      addTmpEmployeeSettings: SET_TMP_ROLE_EMPLOYEE,
      setcurrentEmpoloyee: SET_CURRENT_EMPLOYEE,
      reset: SET_TMP_ROLE_EMPLOYEE_RESET,
      updateEmployeeFiled: SET_UPDATE_EMPLOYEE_FIELD
    }),
    ...mapActions({
      editEmployee: REQUEST_EDIT_EMPLOYEE,
      checkEmail: REQUEST_CHECK_EMAIL
    }),

    fileUploadHandler() {
      const avatar = this.$refs.downloadavatar.files[0]
      this.preview = URL.createObjectURL(avatar)
      this.isAvatar = true
      this.employeeData.file = avatar
    },

    defaultEmployeePosition(settings) {
      const { key } = settings
      this.employeeData.position = this.employeeTmpRoles[key].position_name

      let name = 'Не назначено'
      if (this.employee.position) {
        name = this.employee.position.position_name
        this.checkPosition = this.employeeRoles[key].position_name
      }

      return this.employeeData.position ? this.employeeData.position : name
    },

    defaultEmployeeValue(settings) {
      const { key } = settings
      const current = this.employeeTmpRoles[key].position_value
      return current ? current : this.employeeRolesDefault[key].id
    },

    selectItemHandler(selectItem) {
      const { key } = selectItem
      switch (key) {
        case 'position':
          this.addTmpEmployeeSettings({ ...selectItem })
          break
        default:
          break
      }
      this[`${key}Options`] = false
    },

    closePopup() {
      this.night_shift = false
      this.employeeData.email = null
      this.employeeData.password = null
      this.employeeData.first_name = null
      this.employeeData.last_name = null
      this.employeeData.file = null
      this.employeeData.position = null
      this.employeeData.is_active_employee = null
      // store.commit(SET_CLEAR_EMPLOYEE_DATA)
      this.preview = null
      this.isAvatar = false
      this.positionOptions = false
      this.viewPassword = false
      // this.checkPosition = null
      this.isModerator = false
      this.reset()
      this.close()
    },
    employeeSubmitHandler() {
      const { employee } = this

      const employeeEditData = {
        profile: {
          is_night_shift:
            employee.is_night_shift !== this.night_shift ? this.night_shift : employee.is_night_shift,
          email: employee.email === this.employeeData.email ? '' : this.employeeData.email,
          password: this.employeeData.password,
          avatar: employee.avatar === this.employeeData.file ? '' : this.employeeData.file,
          first_name:
            employee.first_name === this.employeeData.first_name ? '' : this.employeeData.first_name,
          last_name: employee.last_name === this.employeeData.last_name ? '' : this.employeeData.last_name,
          position:
            this.defaultEmployeeValue({ key: 'position' }) === this.employee.position.id
              ? ''
              : this.defaultEmployeeValue({ key: 'position' }),
          is_active: this.employeeData.is_active_employee
        },
        employee_id: employee.id
      }
      const formData = new FormData()
      const dataProfile = employeeEditData.profile
      const id = employeeEditData.employee_id
      if (dataProfile.avatar) {
        formData.append('avatar', dataProfile.avatar)
      }
      formData.append('is_active', dataProfile.is_active)
      if (dataProfile.password) {
        formData.append('password', dataProfile.password)
      }
      if (dataProfile.first_name) {
        formData.append('first_name', dataProfile.first_name)
      }
      if (dataProfile.last_name) {
        formData.append('last_name', dataProfile.last_name)
      }
      if (dataProfile.position) {
        formData.append('position', dataProfile.position)
      }
      if (dataProfile.is_night_shift) {
        formData.append('is_night_shift', dataProfile.is_night_shift)
      }
      if (dataProfile.email) {
        formData.append('email', dataProfile.email)
        this.checkEmail({ email: dataProfile.email }).then(response => {
          if (response.data.exist) {
            this.$root.$emit(this.EMAIL_ERROR_POPUP)
          } else {
            const editData = { formData, id }
            this.editEmployee(editData).then(res => {
              if (res.status) {
                this.$root.$emit('ChangeEmployee', 'editEmployee')
              }
            })
            this.closePopup()
          }
        })
      } else {
        const editData = { formData, id }
        this.editEmployee(editData).then(res => {
          if (res.status) {
            this.$root.$emit('ChangeEmployee', 'editEmployee')
          }
        })
        this.closePopup()
      }
    }
  }
}
</script>

<style lang="scss">
@import './EditEmployee.scss';
</style>
