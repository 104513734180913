<template>
  <Popup :visible="visible" @close="closePopUp" description="Редактирование" modalClass="popup--small">
    <form @submit.prevent="updateGift" class="popup__form">
      <div class="popup__avatar-wrap">
        <div class="popup__avatar">
          <img v-if="isAvatar" :src="preview" alt="" />
          <img :src="uriCurrentImage" alt="" v-else />
        </div>
        <div class="popup__avatar-edit">
          <input
            class="popup__avatar-edit-input"
            type="file"
            id="avatar-input"
            accept=".jpg, .jpeg, .png"
            ref="downloadavatar"
            @change="fileUploadHandler"
            name="image"
          />
          <label class="popup__avatar-edit-label" for="avatar-input">
            <i class="fa fa-pen"></i>
          </label>
        </div>
      </div>
      <div class="popup__item">
        <label for="achievement-name" class="popup__item-title popup__name">Цена</label>
        <input
          type="number"
          name="price"
          id="achievement-name"
          class="popup__input default-input"
          v-model.number="price"
          required
        />
      </div>
      <div class="popup__item">
        <div class="popup__btns popup__btns--center">
          <button type="submit" class="popup__btn btn-green">Да</button>
          <button @click="closePopUp" class="popup__btn btn-red">Нет</button>
        </div>
      </div>
    </form>
  </Popup>
</template>

<script>
import Popup from '@/components/Modals/Popup'
import ModalMixin from '@/components/Modals/mixins/modalMixin'
import getImgCdnLink from '@/components/mixins/getImgCdnLink'
import { REQUEST_UPDATE_GIFTS_BY_ID } from '@/store/action-types'
import { GIFT_UPDATE_POPUP_EVENT } from '@/constants/event'
import { mapActions } from 'vuex'

export default {
  name: 'GiftsPopupUpdate',
  components: {
    Popup
  },
  mixins: [ModalMixin, getImgCdnLink],
  data() {
    return {
      price: '',
      icon: null,
      isAvatar: false,
      gift: {},
      uriCurrentImage: '',
      giftId: null,
      preview: null
    }
  },
  methods: {
    ...mapActions({
      updateGiftById: REQUEST_UPDATE_GIFTS_BY_ID
    }),

    fileUploadHandler() {
      const avatar = this.$refs.downloadavatar.files[0]
      this.preview = URL.createObjectURL(avatar)
      this.isAvatar = true
      this.icon = avatar
    },

    updateGift() {
      const formData = new FormData()

      if (this.price) formData.append('price', Number(this.price))

      if (this.icon) formData.append('image', this.icon)
      const newGift = {
        id: this.giftId,
        data: formData
      }
      this.updateGiftById(newGift)

      this.price = ''
      this.icon = null
      this.isAvatar = false
      this.close()
    },
    closePopUp() {
      this.price = ''
      this.icon = null
      this.isAvatar = false
      this.close()
    }
  },
  mounted() {
    this.$root.$on(GIFT_UPDATE_POPUP_EVENT, gifts => {
      this.giftId = gifts.id
      this.gift = gifts
      this.price = gifts.price
      this.uriCurrentImage = this.getImgLinkOriginal(gifts.image)
      this.open()
    })
  }
}
</script>

<style lang="scss">
.popup {
  &__avatar-wrap {
    display: flex;
    width: 128px;
    height: 128px;
    margin: 0 auto;
    border-radius: 50%;
    background-color: white;
  }
  // .popup__avatar-edit
  &__avatar-edit {
    width: 40px;
    height: 40px;
    z-index: 2;
    right: 0;
  }
  // .popup__avatar-edit-input
  &__avatar-edit-input {
    display: none;
  }
  // .popup__avatar-edit-label
  &__avatar-edit-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
  }
  // .popup__avatar
  &__avatar {
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    align-items: center;
    display: flex;
    height: 100%;
  }
  &__avatar img {
    display: block;
    max-width: 100%;
  }
  &__input {
    margin-left: 1px;
    height: 30px;
    width: 100%;
  }
}
</style>
