const GET_APPS_FLYER = 'GET_APPS_FLYER'
const GET_APPS_FLYER_IOS = 'GET_APPS_FLYER_IOS'
const GET_ROYAL_USERS_INSTALL = 'GET_ROYAL_USERS_INSTALL'
const GET_ROYAL_USERS_INSTALL_IOS = 'GET_ROYAL_USERS_INSTALL_IOS'
const GET_USERS_ACTIVITY = 'GET_USERS_ACTIVITY'
const GET_USERS_COUNT = 'GET_USERS_COUNT'
const GET_USERS_ONLINE_BY_CITY = 'GET_USERS_ONLINE_BY_CITY'
const GET_PURCHASES_MARKET_PLACES = 'GET_PURCHASES_MARKET_PLACES'
const GET_PURCHASES_BY_CITY = 'GET_PURCHASES_BY_CITY'
const GET_PURCHASES_BY_COUNTRY = 'GET_PURCHASES_BY_COUNTRY'
const GET_PURCHASES_BY_TIME = 'GET_PURCHASES_BY_TIME'
const GET_PURCHASES_BY_PAYERS_PAYMENTS = 'GET_PURCHASES_BY_PAYERS_PAYMENTS'
const GET_USERS_ONLINE_ONE_DAY = 'GET_USERS_ONLINE_ONE_DAY'
const GET_USERS_ONLINE_ONE_DAY_SITE_AND_APP = 'GET_USERS_ONLINE_ONE_DAY_SITE_AND_APP'
const GET_NEW_USER_ACTIVE_BY_DATE = 'GET_NEW_USER_ACTIVE_BY_DATE'
const GET_MODERATORS_PHOTO_CHECK = 'GET_MODERATORS_PHOTO_CHECK'
const GET_USERS_GENDER = 'GET_USERS_GENDER'
const GET_ACTIVE_NEW_USER = 'GET_ACTIVE_NEW_USER'
const GET_USERS_PAYING = 'GET_USERS_PAYING'
const GET_USERS_AGE = 'GET_USERS_AGE'
const GET_USER_LIFETIME = 'GET_USER_LIFETIME'
const GET_REGS_NEW_USERS = 'GET_REGS_NEW_USERS'
const GET_OPERATORS_MESSAGE = 'GET_OPERATORS_MESSAGE'
const GET_OPERATORS_CASHES = 'GET_OPERATORS_CASHES'
const GET_PURCHASE_TOTAL = 'GET_PURCHASE_TOTAL'
const GET_MODERATORS_VIDEO_CHECK = 'GET_MODERATORS_VIDEO_CHECK'
const GET_PURCHASES_POINTS = 'GET_PURCHASES_POINTS'
const GET_PURCHASES_VIP_STATUS = 'GET_PURCHASES_VIP_STATUS'
const GET_PURCHASES_BY_DATE = 'GET_PURCHASES_BY_DATE'
const GET_COUNTERS_MESSAGES_OPERATORS = 'GET_COUNTERS_MESSAGES_OPERATORS'
const GET_STATISTICS_PROFILE_MESSAGES = 'GET_STATISTICS_PROFILE_MESSAGES'
const GET_SEASON_OPERATOR = 'GET_SEASON_OPERATOR'

export {
  GET_APPS_FLYER,
  GET_APPS_FLYER_IOS,
  GET_USERS_ACTIVITY,
  GET_USERS_COUNT,
  GET_USERS_ONLINE_BY_CITY,
  GET_PURCHASES_MARKET_PLACES,
  GET_PURCHASES_BY_CITY,
  GET_COUNTERS_MESSAGES_OPERATORS,
  GET_STATISTICS_PROFILE_MESSAGES,
  GET_PURCHASE_TOTAL,
  GET_PURCHASES_BY_COUNTRY,
  GET_PURCHASES_BY_TIME,
  GET_OPERATORS_MESSAGE,
  GET_OPERATORS_CASHES,
  GET_PURCHASES_BY_PAYERS_PAYMENTS,
  GET_MODERATORS_VIDEO_CHECK,
  GET_USERS_ONLINE_ONE_DAY,
  GET_PURCHASES_VIP_STATUS,
  GET_USERS_ONLINE_ONE_DAY_SITE_AND_APP,
  GET_NEW_USER_ACTIVE_BY_DATE,
  GET_MODERATORS_PHOTO_CHECK,
  GET_USERS_GENDER,
  GET_ACTIVE_NEW_USER,
  GET_SEASON_OPERATOR,
  GET_USERS_PAYING,
  GET_USERS_AGE,
  GET_PURCHASES_POINTS,
  GET_USER_LIFETIME,
  GET_REGS_NEW_USERS,
  GET_ROYAL_USERS_INSTALL,
  GET_PURCHASES_BY_DATE,
  GET_ROYAL_USERS_INSTALL_IOS
}
