import * as ActionTypes from './action-types'
import * as MutationTypes from './mutation-types'
import * as RootMutationTypes from '@/store/mutation-types'
import PurchasesService from '@/services/PurchasesService'

export default {
  [ActionTypes.REQUEST_PURCHASES]: async ({ rootGetters, commit }) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(PurchasesService, [userToken]).getAllPurchases()

    let { data } = response
    if (data) {
      commit(MutationTypes.SET_PURCHASES, data)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_EDIT_PURCHASES]: async ({ rootGetters, commit, dispatch }, purchaseData) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(PurchasesService, [userToken]).editPurchase(purchaseData)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_PURCHASES)
    }

    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  },

  [ActionTypes.REQUEST_DELETE_PURCHASES]: async ({ rootGetters, commit, dispatch }, purchase) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(PurchasesService, [userToken]).deletePurchase(purchase)

    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_PURCHASES)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })

    return response
  },

  [ActionTypes.REQUEST_UPDATE_PURCHASES]: async ({ rootGetters, commit, dispatch }, purchase) => {
    commit(RootMutationTypes.SET_LOADER, true, { root: true })
    const userToken = rootGetters.GET_TOKEN
    const response = await Reflect.construct(PurchasesService, [userToken]).updatePurchase(purchase)
    const { status } = response
    if (status) {
      await dispatch(ActionTypes.REQUEST_PURCHASES)
    }
    commit(RootMutationTypes.SET_LOADER, false, { root: true })
    return response
  }
}
