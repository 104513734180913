<template>
  <div class="app-user-info-body app-user-info-body--pay" v-click-outside="close">
    <div class="app-user-info__item-title">
      <span class="app-user-info__key app-user-info__key--pay">
        <FaIcon
          :iconClass="'fas fa-ruble-sign'"
          class="app-user-info__key-icon app-user-info__key-icon--gift"
        />
        <span class="app-user-info__key-text">Выводы:</span>
      </span>

      <span class="app-user-info__value app-user-info__value--pay">{{ payoutsSum(0) }} $</span>
      <span class="app-user-info__value app-user-info__value--pay">{{ payoutsSum(1) }} €</span>
      <span class="app-user-info__value app-user-info__value--pay">{{ payoutsSum(2) }} ₽</span>
      <span class="app-user-info__value app-user-info__value--pay">{{ payoutsSum(3) }} £</span>
      <div class="app-user-info__angle">
        <button
          v-if="!openPayout"
          key="open"
          @click="$emit('changeActive', 'payout')"
          title="Показать историю"
        >
          <i class="fa fa-angle-down"></i>
        </button>
        <button v-else key="close" @click="$emit('changeActive', 'payout')" title="Скрыть историю">
          <i class="fa fa-angle-up"></i>
        </button>
      </div>
    </div>
    <transition name="fade">
      <div class="app-user-info__list" v-if="openPayout && userPayouts.length">
        <div
          class="app-user-info__pay"
          v-for="payout in userPayouts"
          :key="payout.id"
          :payout="payout"
          :class="{
            'app-user-info__pay--cancel': payout.status === 3,
            'app-user-info__pay--ok': payout.status === 2,
            'app-user-info__pay--error': payout.status === 4,
            'app-user-info__pay--pending': payout.status === 1
          }"
        >
          <div v-if="payout.status !== 0" class="app-user-info__pay-icon">
            <i
              :class="{
                'fa fa-ban': payout.status === 3,
                'fa fa-check': payout.status === 2,
                'fa fa-exclamation-triangle': payout.status === 4,
                'fa fa-spinner fa-spin': payout.status === 1
              }"
            ></i>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--id">
            <span class="app-user-info__key-text">ID транзакции: </span>
            <span class="app-user-info__item-value">{{ payout.id }}</span>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--pay-id">
            <span class="app-user-info__key-text">RoyalPay id: </span>
            <span class="app-user-info__item-value">{{ payout.royal_pay_id }}</span>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--pay-id">
            <span class="app-user-info__key-text">Статус: </span>
            <span class="app-user-info__item-value">{{ statusText(payout.status) }}</span>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--pay-id">
            <span class="app-user-info__key-text">Создано: </span>
            <span class="app-user-info__item-value">{{ toLocaleDateUser(payout.create) }}</span>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--pay-id">
            <span class="app-user-info__key-text">Обновлено: </span>
            <span class="app-user-info__item-value">{{ toLocaleDateUser(payout.update) }}</span>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--system">
            <span class="app-user-info__key-text">Платежная система: </span>
            <span class="app-user-info__item-value">{{ paymentSystemText(payout.payment_system) }}</span>
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--pay">
            <span class="app-user-info__key-text">Сумма транзакции при создании: </span>
            <span class="app-user-info__item-value"
              >{{ Number(payout.amount_pay) }} {{ moneyIcon(payout.currency) }}</span
            >
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--merchant">
            <span class="app-user-info__key-text">Комиссия RoyalPay: </span>
            <span class="app-user-info__item-value"
              >{{ Number(payout.amount_merchant) }} {{ moneyIcon(payout.currency) }}</span
            >
          </div>
          <div class="app-user-info__pay-item app-user-info__pay-item--client">
            <span class="app-user-info__key-text">Сумма зачисленная пользователю: </span>
            <span class="app-user-info__item-value"
              >{{ Number(payout.amount_client) }} {{ moneyIcon(payout.currency) }}</span
            >
          </div>
        </div>
      </div>
      <div v-else-if="openPayout && !userPayouts.length" class="app-user-info__list">
        <span class="app-user-info__no-data">Выводы отсутствуют</span>
      </div>
    </transition>
  </div>
</template>

<script>
import dateFormat from '@/components/mixins/toLocaleformat'
import getImgLink from '@/components/mixins/getImgCdnLink'
import getIconCurrency from '@/components/mixins/getIconCurrency'
import getTransactionStatus from '@/components/mixins/getTransactionStatus'
export default {
  name: 'AppUserPayouts',
  props: {
    userPayouts: {
      type: Array
    },
    changeActive: {
      type: Function
    },
    openPayout: {
      type: Boolean
    },
    // statusText: {
    //   type: Function
    // },
    paymentSystemText: {
      type: Function
    }
  },
  mixins: [dateFormat, getImgLink, getIconCurrency, getTransactionStatus],

  methods: {
    close() {
      this.versionOpen = false
    },
    payoutsSum(currency) {
      let filterArr = this.userPayouts.filter(f => f.currency === currency)
      let sumCurrency = null
      filterArr.map(m => {
        sumCurrency = (sumCurrency * 100 + Number(m.amount_pay) * 100) / 100
      })
      if (sumCurrency) {
        return sumCurrency.toFixed(2)
      } else {
        return 0
      }
    }
  }
}
</script>

<style lang="scss">
@import './App-user-info';
</style>
