<template>
  <div class="view-control-wrap">
    <ViewControlHeader />
    <ViewControlTableHeader />
    <div class="view-control__content" v-if="records.length && isLoaded">
      <ViewControlItem v-for="record in records" :key="record.id" :item="record" />
    </div>
    <div class="view-control__error" v-if="errorMessage">
      <span class="view-control__error-text">Ошибка при загрузке записей</span>
    </div>
    <ViewControlPopup />
    <ViewDeletePopup />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { REQUEST_VIEWS } from '@/store/action-types'
import { GET_RECORDS } from '@/store/getter-types'
import ViewControlHeader from '@/components/ViewControl/ViewControlHeader'
import ViewControlTableHeader from '@/components/ViewControl/ViewHeaderTable'
import ViewControlItem from '@/components/ViewControl/ViewControlItem'
import ViewControlPopup from '@/components/Modals/ViewsMobilePopup/ViewPopup'
import ViewDeletePopup from '@/components/Modals/ViewsMobilePopup/ViewDeletePopup'
import store from '@/store'
export default {
  name: 'FirstScreen',
  components: {
    ViewControlHeader,
    ViewControlTableHeader,
    ViewControlItem,
    ViewControlPopup,
    ViewDeletePopup
  },
  data: () => ({
    errorMessage: false,
    isLoaded: false
  }),

  computed: {
    ...mapGetters({
      records: GET_RECORDS
    })
  },

  beforeRouteEnter(to, from, next) {
    store.dispatch(REQUEST_VIEWS).then(response => {
      next(vm => {
        if (!response.status) {
          vm.errorMessage = true
        } else {
          vm.isLoaded = true
        }
      })
    })
  }
}
</script>
<style lang="scss">
.view-control {
  &__header {
    padding: 16px;
    margin: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    &--title {
      font-size: 27px;
      color: #717171;
      font-weight: 700;
      letter-spacing: 0.8px;
    }
    &--button {
      display: flex;
      padding: 7px 8px 6px 8px;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      background-color: #78cd51;
      border-radius: 4px;
    }
  }
  &__content {
    position: relative;
    top: 46px;
    background: #fff;
    margin: 0 16px;
    border-radius: 4px;
  }
  &__error {
    margin: 16px;
    min-height: 64px;
    border-radius: 2px;

    position: relative;
    top: 50px;
    display: flex;
    align-items: center;
    background-color: white;

    &-text {
      margin: 0 auto;
    }
  }
}
</style>
